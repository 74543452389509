import React from 'react';
import { Modal, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { Icon, useIntl, Button, Input, notifySuccess, whiteText } from 'Common';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { registerPartner } from 'Actions/partner';
import PartnerRegistration, { IPartnerRegistration } from 'Entities/PartnerRegistration';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import Error from 'Entities/Error';
import { EMPTY_FIELD_ERROR, SOLAR_STAFF_OFFER } from 'Lib/helpers/consts';
import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { emailValidate } from 'Lib/helpers/utils';
import PartnerDetails from 'Entities/PartnerDetails';
import { ContentLayout } from './ContentLayout';

import s from './SolarStaffRegistration.module.pcss';

const listItem = (text: string) => (
    <div>
        {text}
    </div>
);

const eulaLink = (text: string) => (
    <a
        href={SOLAR_STAFF_OFFER}
        className={s.eula}
        target="_blank"
        rel="noopener noreferrer"
    >
        {text}
    </a>
);

const { TabPane } = Tabs;

type FormValues = IPartnerRegistration;

interface Props {
    isClientPage?: boolean;
}

export const SolarStaffRegistration = ({ isClientPage }: Props) => {
    const intl = useIntl();
    const { currentLocale } = intl;

    const dispatch = useDispatch();
    const history = useHistory();

    const onBack = () => {
        const route = isClientPage ? RoutePath.Clients : RoutePath.Partner;
        history.push(linkPathBuilder(currentLocale, route));
    };

    const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        const req = new PartnerRegistration({ ...values });
        const notValid = req.validate();

        if (notValid.length > 0) {
            const errors: FormikErrors<FormValues> = notValid.reduce((prev, cur) => {
                return { ...prev, [cur]: EMPTY_FIELD_ERROR };
            }, {} as any);
            helpers.setErrors(errors);
            return;
        }

        dispatch(registerPartner([req.serialize()], {
            error: (error: Error) => {
                helpers.setFieldError('email', apiErrorCodeTranslate(intl, error.errorCode));
                helpers.setSubmitting(false);
            },
            result: (result: PartnerDetails) => {
                if (!result.isSolarStaffRegistrationComplete) {
                    notifySuccess(intl.getMessage('confirmation_link_desc', { email: values.email, b: whiteText }), 'registration_success');
                }
                onBack();
            },
        }));
    };

    const initialValues: FormValues = {
        email: '',
    };

    return (
        <Modal
            visible
            wrapClassName="modal"
            onCancel={onBack}
            maskClosable={false}
            maskStyle={{ background: 'white' }}
            footer={null}
            closeIcon={<Icon icon="close_big" />}
            width={640}
            centered
        >
            <div className={s.info}>
                <Tabs className="tabs tabs--info" defaultActiveKey="1">
                    <TabPane tab={intl.getMessage('partner_private_person')} key="1">
                        <Formik
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                        >
                            {({
                                setFieldValue,
                                handleSubmit,
                                values,
                                errors,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <div className={s.content}>
                                        {intl.getMessage('partner_private_person_desc')}
                                    </div>
                                    <div className={s.form}>
                                        <Input
                                            name="email"
                                            size="large"
                                            type="email"
                                            value={values.email}
                                            onChange={(v) => setFieldValue('email', v)}
                                            placeholder={intl.getMessage('email')}
                                            error={!!errors.email}
                                            validate={(v) => emailValidate(v)}
                                        />
                                    </div>
                                    <div className={cn(s.bottom, s.bottom_desc)}>
                                        <div className={cn(s.desc, s.desc_legal)}>
                                            {intl.getMessage('partners_registration_disclaimer', { a: eulaLink })}
                                        </div>
                                        <div className={s.buttons}>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                size="medium"
                                                disabled={isSubmitting}
                                            >
                                                {intl.getMessage('sign_up')}
                                            </Button>
                                            <Button
                                                type="link"
                                                size="medium"
                                                onClick={onBack}
                                            >
                                                {intl.getMessage('cancel')}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </TabPane>
                    <TabPane tab={intl.getMessage('partner_entities')} key="2">
                        <ContentLayout isClientPage={isClientPage}>
                            <div className={s.title}>
                                {intl.getMessage('billing_documents')}
                            </div>
                            <div className={s.desc}>
                                {intl.getMessage('partner_entities_docs', { item: listItem })}
                            </div>
                            <div className={s.title}>
                                {intl.getMessage('partner_details_entities')}
                            </div>
                            <div className={s.desc}>
                                {intl.getMessage('partner_entities_details', { item: listItem })}
                            </div>
                            <div className={s.title}>
                                {intl.getMessage('bank_details')}
                            </div>
                            <div className={s.desc}>
                                {intl.getMessage('partner_bank_details', { item: listItem })}
                            </div>
                        </ContentLayout>
                    </TabPane>
                    <TabPane tab={intl.getMessage('partner_entrepreneur')} key="3">
                        <ContentLayout isClientPage={isClientPage}>
                            <div className={s.title}>
                                {intl.getMessage('billing_documents')}
                            </div>
                            <div className={s.desc}>
                                {intl.getMessage('partner_entrepreneur_docs', { item: listItem })}
                            </div>
                            <div className={s.title}>
                                {intl.getMessage('bank_details')}
                            </div>
                            <div className={s.desc}>
                                {intl.getMessage('partner_bank_details', { item: listItem })}
                            </div>
                        </ContentLayout>
                    </TabPane>
                </Tabs>
            </div>
        </Modal>
    );
};
