import DatePeriod, { IDatePeriod } from './DatePeriod';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IComparativeIncome {
    income_change_percent?: number;
    income_forecast_change_percent?: number;
    next_income?: number;
    previous_income?: number;
    previous_period?: IDatePeriod;
    recent_income: number;
    recent_period: IDatePeriod;
}

export default class ComparativeIncome {
    readonly _income_change_percent: number | undefined;

    /**
     * Description: Income change % = 100% * (recent - prev) / prev, or null if previous_income is zero, RUB
     * Example: -30
     */
    get incomeChangePercent(): number | undefined {
        return this._income_change_percent;
    }

    readonly _income_forecast_change_percent: number | undefined;

    /**
     * Description: Income forecast change % = 100% * (next - recent) / recent, or null if recent_income is zero, RUB
     * Example: 50
     */
    get incomeForecastChangePercent(): number | undefined {
        return this._income_forecast_change_percent;
    }

    readonly _next_income: number | undefined;

    /** */
    get nextIncome(): number | undefined {
        return this._next_income;
    }

    readonly _previous_income: number | undefined;

    /**
     * Description: Total income over same previous period, RUB
     * Example: 100
     */
    get previousIncome(): number | undefined {
        return this._previous_income;
    }

    readonly _previous_period: DatePeriod | undefined;

    get previousPeriod(): DatePeriod | undefined {
        return this._previous_period;
    }

    readonly _recent_income: number;

    /** */
    get recentIncome(): number {
        return this._recent_income;
    }

    static recentIncomeValidate(recentIncome: number): boolean {
        return typeof recentIncome === 'number';
    }

    readonly _recent_period: DatePeriod;

    get recentPeriod(): DatePeriod {
        return this._recent_period;
    }

    constructor(props: IComparativeIncome) {
        if (typeof props.income_change_percent === 'number') {
            this._income_change_percent = props.income_change_percent;
        }
        if (typeof props.income_forecast_change_percent === 'number') {
            this._income_forecast_change_percent = props.income_forecast_change_percent;
        }
        if (typeof props.next_income === 'number') {
            this._next_income = props.next_income;
        }
        if (typeof props.previous_income === 'number') {
            this._previous_income = props.previous_income;
        }
        if (props.previous_period) {
            this._previous_period = new DatePeriod(props.previous_period);
        }
        this._recent_income = props.recent_income;
        this._recent_period = new DatePeriod(props.recent_period);
    }

    serialize(): IComparativeIncome {
        const data: IComparativeIncome = {
            recent_income: this._recent_income,
            recent_period: this._recent_period.serialize(),
        };
        if (typeof this._income_change_percent !== 'undefined') {
            data.income_change_percent = this._income_change_percent;
        }
        if (typeof this._income_forecast_change_percent !== 'undefined') {
            data.income_forecast_change_percent = this._income_forecast_change_percent;
        }
        if (typeof this._next_income !== 'undefined') {
            data.next_income = this._next_income;
        }
        if (typeof this._previous_income !== 'undefined') {
            data.previous_income = this._previous_income;
        }
        if (typeof this._previous_period !== 'undefined') {
            data.previous_period = this._previous_period.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            recent_period: this._recent_period.validate().length === 0,
            previous_period: !this._previous_period ? true : this._previous_period.validate().length === 0,
            next_income: !this._next_income ? true : typeof this._next_income === 'number',
            recent_income: typeof this._recent_income === 'number',
            previous_income: !this._previous_income ? true : typeof this._previous_income === 'number',
            income_change_percent: !this._income_change_percent ? true : typeof this._income_change_percent === 'number',
            income_forecast_change_percent: !this._income_forecast_change_percent ? true : typeof this._income_forecast_change_percent === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IComparativeIncome): ComparativeIncome {
        return new ComparativeIncome(props);
    }

    readonly keys: { [key: string]: string } = {
        incomeChangePercent: 'income_change_percent',
        incomeForecastChangePercent: 'income_forecast_change_percent',
        nextIncome: 'next_income',
        previousIncome: 'previous_income',
        previousPeriod: 'previous_period',
        recentIncome: 'recent_income',
        recentPeriod: 'recent_period',
        }
;

    mergeDeepWith(props: Partial<ComparativeIncome>): ComparativeIncome {
        const updateData: Partial<IComparativeIncome> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ComparativeIncome) => {
            const updateKey = this.keys[key] as keyof IComparativeIncome;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IComparativeIncome, keyof IComparativeIncome>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ComparativeIncome({ ...this.serialize(), ...updateData });
    }
}
