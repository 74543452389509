import { takeEvery, put, call } from 'redux-saga/effects';

import TariffsApi from 'Apis/tariffs';
import {
    TariffActions,
    getAllTariffs as getAllTariffsAct,
    getTariffsList as getTariffsListAct,
    saveTariffsList,
    getTariffsForServer as getTariffsForServerAct,
} from 'Actions/tariff';
import Tariff, { ITariff } from 'Entities/Tariff';

import { errorChecker, addError } from './utils';

function* getTariffList(action: ReturnType<typeof getTariffsListAct>) {
    const { payload } = action;
    const response: ITariff[] = yield call(TariffsApi.listTariffsForDistribution, payload);
    const { error, result } = errorChecker<ITariff[]>(response);
    if (result) {
        const tariffEntities = result.map((t) => new Tariff(t));
        yield put(saveTariffsList(tariffEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* getAllTariffs(action: ReturnType<typeof getAllTariffsAct>) {
    const response: ITariff[] = yield call(TariffsApi.listTariffs);
    const { error, result } = errorChecker<ITariff[]>(response);
    if (result) {
        const tariffEntities = result.map((t) => new Tariff(t));
        yield put(saveTariffsList(tariffEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getTariffsForServer(action: ReturnType<typeof getTariffsForServerAct>) {
    const { payload } = action;
    const response: ITariff[] = yield call(TariffsApi.listTariffsForServer, ...payload);
    const { error, result } = errorChecker<ITariff[]>(response);

    if (result) {
        const serverTariffs = result.map((t) => new Tariff(t));
        yield put(saveTariffsList(serverTariffs));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* tariffSaga(): Generator {
    yield takeEvery<ReturnType<typeof getTariffsListAct>>(
        TariffActions.GetTariffs, getTariffList,
    );
    yield takeEvery<ReturnType<typeof getAllTariffsAct>>(
        TariffActions.GetAllTariffs, getAllTariffs,
    );
    yield takeEvery<ReturnType<typeof getTariffsForServerAct>>(
        TariffActions.GetTariffsForServer, getTariffsForServer,
    );
}

export default tariffSaga;
