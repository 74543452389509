import serversApi from 'Apis/servers';
import { IIpMove } from 'Entities/IpMove';
import { IIpRemove } from 'Entities/IpRemove';
import Server from 'Entities/Server';
import ServerConfigurationUpdate from 'Entities/ServerConfigurationUpdate';
import { IServerUpdate } from 'Entities/ServerUpdate';

import { actionCreator } from '.';

export enum ServerActions {
    GetServerList = 'GetServerList',
    SaveServerList = 'SaveServerList',
    GetServerForTenant = 'GetServerForTenant',
    SaveServerTenantList = 'SaveServerTenantList',
    GetServer = 'GetServer',
    SaveServer = 'SaveServer',
    StartServer = 'StartServer',
    RebootServer = 'RebootServer',
    ResetServer = 'ResetServer',
    ShutDownServer = 'ShutDownServer',
    DestroyServer = 'DestroyServer',
    AddIp = 'AddIp',
    RemoveIp = 'RemoveIp',
    MoveIp = 'MoveIp',
    UpdateServer = 'UpdateServer',
    DeleteServer = 'DeleteServer',
    DeleteServerLocal = 'DeleteServerLocal',
    UpdateServerBackupPolicy = 'UpdateServerBackupPolicy',
    UpdateConfiguration = 'UpdateConfiguration',
    ServerClone = 'ServerClone',
    UpdatePassword = 'UpdatePassword',
    GrantSupportAccess = 'GrantSupportAccess',
    GrantConsoleAccess = 'GrantConsoleAccess',
    GetServersLastOperations = 'GetServersLastOperations',
    UpdateServerPublicKeys = 'UpdateServerPublicKeys',
    ForceInstall = 'ForceInstall',
    CancelInstallation = 'CancelInstallation',
    Install = 'Install',
    Reinstall = 'Reinstall',
    DeleteServers = 'DeleteServers',
    RebootServers = 'RebootServers',
    DestroyServers = 'DestroyServers',
    ResetServers = 'ResetServers',
    ShutDownServers = 'ShutDownServers',
    AddPublicKeys = 'AddPublicKeys',
    StartServers = 'StartServers',
    UpdateServerKeys = 'UpdateServerKeys',
    UpdateServerRecommendations = 'UpdateServerRecommendations'
}

const getServerList = actionCreator(ServerActions.GetServerList);
const saveServerList = actionCreator<Server[]>(ServerActions.SaveServerList);

type GetServerForTenant = Parameters<typeof serversApi.listServersForTenant>;
const getServerForTenant = actionCreator<GetServerForTenant>(
    ServerActions.GetServerForTenant,
);
const saveServerTenantList = actionCreator<Server[]>(ServerActions.SaveServerTenantList);

const getServer = actionCreator<{ serverId: number; tenantId: number }>(ServerActions.GetServer);
const saveServer = actionCreator<Server>(ServerActions.SaveServer);

const startServer = actionCreator<Server>(ServerActions.StartServer);
const rebootServer = actionCreator<Server>(ServerActions.RebootServer);
const resetServer = actionCreator<Server>(ServerActions.ResetServer);
const shutDownServer = actionCreator<Server>(ServerActions.ShutDownServer);
const destroyServer = actionCreator<Server>(ServerActions.DestroyServer);

const addIp = actionCreator<Server>(ServerActions.AddIp);
interface RemoveIp {
    remove: IIpRemove;
    server: Server;
}
const removeIp = actionCreator<RemoveIp>(ServerActions.RemoveIp);
interface MoveIp {
    move: IIpMove;
    server: Server;
    toTenantId: number;
}
const moveIp = actionCreator<MoveIp>(ServerActions.MoveIp);

interface UpdateServer {
    server: Server;
    update: IServerUpdate;
}
const updateServer = actionCreator<UpdateServer>(ServerActions.UpdateServer);

interface UpdateConfiguration {
    server: Server;
    serverConfigurationUpdate: ServerConfigurationUpdate;
}
const updateConfiguration = actionCreator<UpdateConfiguration>(ServerActions.UpdateConfiguration);

interface DeleteServer {
    server: Server;
    code?: string;
}
const deleteServer = actionCreator<DeleteServer>(ServerActions.DeleteServer);

const deleteServerLocal = actionCreator<number>(ServerActions.DeleteServerLocal);

type UpdateServerBackupPolicy = Parameters<typeof serversApi.updateBackupPolicy>;
const updateServerBackupPolicy = actionCreator<UpdateServerBackupPolicy>(
    ServerActions.UpdateServerBackupPolicy,
);

type ServerClone = Parameters<typeof serversApi.cloneServer>;
const serverClone = actionCreator<ServerClone>(ServerActions.ServerClone);

type UpdatePassword = Parameters<typeof serversApi.updatePassword>;
const updatePassword = actionCreator<UpdatePassword>(ServerActions.UpdatePassword);

interface GrantAccess {
    server: Server;
    grant: boolean;
    code?: string;
}
const grantSupportAccess = actionCreator<GrantAccess>(ServerActions.GrantSupportAccess);

const grantConsoleAccess = actionCreator<GrantAccess>(ServerActions.GrantConsoleAccess);

const getServersLastOperations = actionCreator(ServerActions.GetServersLastOperations);

type UpdateServerPublicKeys = Parameters<typeof serversApi.updatePublicKeys>;
const updateServerPublicKeys = actionCreator<UpdateServerPublicKeys>(
    ServerActions.UpdateServerPublicKeys,
);

type ForceInstall = Parameters<typeof serversApi.forceInstallServer>;
const forceInstall = actionCreator<ForceInstall>(ServerActions.ForceInstall);

type UpdateServerRecommendations = Parameters<typeof serversApi.updateRecommendationsSubscriptionForServer>;
const updateServerRecommendations = actionCreator<UpdateServerRecommendations>(
    ServerActions.UpdateServerRecommendations,
);

type CancelInstallation = Parameters<typeof serversApi.cancelServerInstallation>;
const cancelInstallation = actionCreator<CancelInstallation>(ServerActions.CancelInstallation);

type Install = Parameters<typeof serversApi.installServer>;
const install = actionCreator<Install>(
    ServerActions.Install,
);
type Reinstall = Parameters<typeof serversApi.reinstallServer>;
const reinstall = actionCreator<Reinstall>(
    ServerActions.Reinstall,
);

type DeleteServers = {
    data: Parameters<typeof serversApi.deleteServersBatch>;
    notificationId?: string;
};
const deleteServers = actionCreator<DeleteServers>(
    ServerActions.DeleteServers,
);

type RebootServers = {
    data: Parameters<typeof serversApi.rebootServersBatch>;
    notificationId?: string;
};
const rebootServers = actionCreator<RebootServers>(
    ServerActions.RebootServers,
);

type DestroyServers = {
    data: Parameters<typeof serversApi.destroyServersBatch>;
    notificationId?: string;
};
const destroyServers = actionCreator<DestroyServers>(
    ServerActions.DestroyServers,
);

type ResetServers = {
    data: Parameters<typeof serversApi.resetServersBatch>;
    notificationId?: string;
};
const resetServers = actionCreator<ResetServers>(
    ServerActions.ResetServers,
);

type ShutDownServers = {
    data: Parameters<typeof serversApi.shutdownServersBatch>;
    notificationId?: string;
};
const shutDownServers = actionCreator<ShutDownServers>(
    ServerActions.ShutDownServers,
);

type StartServers = {
    data: Parameters<typeof serversApi.startServersBatch>;
    notificationId?: string;
};
const startServers = actionCreator<StartServers>(
    ServerActions.StartServers,
);

type UpdateServerKeys = {
    data: Parameters<typeof serversApi.updatePublicKeysBatch>;
    notificationId?: string;
};
const updateServerKeys = actionCreator<UpdateServerKeys>(
    ServerActions.UpdateServerKeys,
);

type AddPublicKeys = {
    data: Parameters<typeof serversApi.addPublicKeysBatch>;
    notificationId?: string;
};
const addPublicKeys = actionCreator<AddPublicKeys>(
    ServerActions.AddPublicKeys,
);

export {
    getServerList,
    saveServerList,
    getServerForTenant,
    saveServerTenantList,
    getServer,
    saveServer,
    startServer,
    rebootServer,
    resetServer,
    shutDownServer,
    destroyServer,
    addIp,
    removeIp,
    moveIp,
    updateServer,
    deleteServer,
    deleteServerLocal,
    updateServerBackupPolicy,
    updateConfiguration,
    serverClone,
    updatePassword,
    grantSupportAccess,
    grantConsoleAccess,
    getServersLastOperations,
    updateServerPublicKeys,
    forceInstall,
    cancelInstallation,
    install,
    reinstall,
    deleteServers,
    destroyServers,
    resetServers,
    shutDownServers,
    rebootServers,
    startServers,
    updateServerKeys,
    addPublicKeys,
    updateServerRecommendations,
};
