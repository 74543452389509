import React, { FC, useEffect, useState } from 'react';

import { InnerPageLayout, useIntl } from 'Common';
import { useTitle } from 'Hooks';
import theme from 'Lib/theme';

import { Header, Info } from './components';

const Profile: FC = () => {
    const intl = useIntl();

    useTitle(intl.getMessage('profile_page_title'));
    const [openEditModal, setOpenEditModal] = useState(false);
    const handleOpenEditModal = (state: boolean) => setOpenEditModal(state);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);

        if (params.get('testsentryerror')) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            myUndefinedFunction();
        }
    }, []);

    return (
        <InnerPageLayout header={<Header />} className={theme.content.with_actions}>
            <Info
                openEditModal={openEditModal}
                setOpenEditModal={handleOpenEditModal}
            />
        </InnerPageLayout>
    );
};

export default Profile;
