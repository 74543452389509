import Profile from 'Entities/Profile';
import ProfileLoginAttempt from 'Entities/ProfileLoginAttempt';
import ProfileApi from 'Apis/profile';
import AccountApi from 'Apis/account';
import MiscApiApi from 'Apis/miscApi';
import Configuration from 'Entities/Configuration';
import PayerInfo from 'Entities/PayerInfo';
import CountriesPhoneCodesResponse from 'Entities/CountriesPhoneCodesResponse';

import { actionCreator } from '.';

export enum ProfileActions {
    GetProfileInfo = 'GetProfileInfo',
    SaveProfileInfo = 'SaveProfileInfo',
    GetLoginAttempts = 'GetLoginAttempts',
    SaveLoginAttempts = 'SaveLoginAttempts',
    ClearLoginAttempts = 'ClearLoginAttempts',
    UpdateProfileSettings = 'UpdateProfileSettings',
    SendPhoneConfirmationCode = 'SendPhoneConfirmationCode',
    ConfirmPhoneWithCode = 'ConfirmPhoneWithCode',
    SavePayerInfo = 'SavePayerInfo',
    UpdatePayerInfo = 'UpdatePayerInfo',
    UpdateProfile = 'UpdateProfile',
    GetTrialPeriodActivationStatus = 'GetTrialPeriodActivationStatus',
    GetPhoneCodes = 'GetPhoneCodes',
    SavePhoneCodes = 'SavePhoneCodes',
    AddClientReviewKey = 'AddClientReviewKey',
    GetConfiguration = 'GetConfiguration',
    SaveConfiguration = 'SaveConfiguration',
    UpdateTariffRecommendations = 'UpdateTariffRecommendations',
    UpdateClientApiKey = 'UpdateClientApiKey',
}

const getProfileInfo = actionCreator<{ onlyInfo?: boolean }>(ProfileActions.GetProfileInfo);
const saveProfileInfo = actionCreator<Profile>(ProfileActions.SaveProfileInfo);

type GetLoginAttempts = Parameters<typeof ProfileApi.getLoginAttempts>;
const getLoginAttempts = actionCreator<GetLoginAttempts>(
    ProfileActions.GetLoginAttempts,
);
const saveLoginAttempts = actionCreator<ProfileLoginAttempt[]>(ProfileActions.SaveLoginAttempts);
const clearLoginAttempts = actionCreator(ProfileActions.ClearLoginAttempts);

type UpdateProfileSettings = Parameters<typeof ProfileApi.updateProfile>;
const updateProfileSettings = actionCreator<UpdateProfileSettings>(
    ProfileActions.UpdateProfileSettings,
);
type SendPhoneConfirmationCode = Parameters<typeof ProfileApi.sendPhoneConfirmationCode>;
const sendPhoneConfirmationCode = actionCreator<SendPhoneConfirmationCode>(
    ProfileActions.SendPhoneConfirmationCode,
);
type ConfirmPhoneWithCode = Parameters<typeof ProfileApi.confirmPhoneWithCode>;
const confirmPhoneWithCode = actionCreator<ConfirmPhoneWithCode>(
    ProfileActions.ConfirmPhoneWithCode,
);
const savePayerInfo = actionCreator<PayerInfo[]>(
    ProfileActions.SavePayerInfo,
);
type UpdatePayerInfo = Parameters<typeof AccountApi.updateAccountPayer>;
const updatePayerInfo = actionCreator<UpdatePayerInfo>(ProfileActions.UpdatePayerInfo);

type UpdateProfile = Parameters<typeof ProfileApi.updateProfile>;
const updateProfile = actionCreator<UpdateProfile>(ProfileActions.UpdateProfile);

type GetTrialPeriodActivationStatus = Parameters<typeof ProfileApi.getTrialPeriodActivationStatus>;
const getTrialPeriodActivationStatus = actionCreator<GetTrialPeriodActivationStatus>(
    ProfileActions.GetTrialPeriodActivationStatus,
);

type GetPhoneCodes = Parameters<typeof MiscApiApi.getCountriesPhoneCodes>;
const getPhoneCodes = actionCreator<GetPhoneCodes>(
    ProfileActions.GetPhoneCodes,
);
const savePhoneCodes = actionCreator<CountriesPhoneCodesResponse>(ProfileActions.SavePhoneCodes);

type AddClientReviewKey = Parameters<typeof ProfileApi.addClientReviewKey>;
const addClientReviewKey = actionCreator<AddClientReviewKey>(
    ProfileActions.AddClientReviewKey,
);

type GetConfiguration = Parameters<typeof MiscApiApi.getConfiguration>;
const getConfiguration = actionCreator<GetConfiguration>(ProfileActions.GetConfiguration);
const saveConfiguration = actionCreator<Configuration>(ProfileActions.SaveConfiguration);

type UpdateTariffRecommendations = Parameters<typeof ProfileApi.updateRecommendationsSubscriptionForClient>;
const updateTariffRecommendations = actionCreator<UpdateTariffRecommendations>(
    ProfileActions.UpdateTariffRecommendations,
);

type UpdateClientApiKey = Parameters<typeof ProfileApi.updateClientApiKey>;
const updateClientApiKey = actionCreator<UpdateClientApiKey>(
    ProfileActions.UpdateClientApiKey,
);

export {
    getProfileInfo,
    saveProfileInfo,
    getLoginAttempts,
    saveLoginAttempts,
    clearLoginAttempts,
    updateProfileSettings,
    sendPhoneConfirmationCode,
    confirmPhoneWithCode,
    savePayerInfo,
    updateProfile,
    updatePayerInfo,
    getTrialPeriodActivationStatus,
    getPhoneCodes,
    savePhoneCodes,
    addClientReviewKey,
    getConfiguration,
    saveConfiguration,
    updateTariffRecommendations,
    updateClientApiKey,
};
