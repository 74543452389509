import React, { FC, useContext } from 'react';
import cn from 'classnames';

import { useIntl, Select, Option } from 'Common';

import { ServerListContext } from 'Components/ServersList/factories';
import s from './Filters.module.pcss';

interface TenantSelectProps {
    transparent?: boolean;
}

const TenantSelect: FC<TenantSelectProps> = ({ transparent }) => {
    const { tenantFilter, onTenantFilterChange, tenantIds } = useContext(ServerListContext);
    const intl = useIntl();
    const count = tenantFilter?.length || 0;
    const text = intl.getPlural('choosen_projects', count, { count });

    return (
        <Select
            id="project"
            mode="multiple"
            size="medium"
            value={tenantFilter}
            onChange={(v) => onTenantFilterChange(v)}
            className={cn(s.select, 'select--single', { 'select--transparent': transparent })}
            placeholder={intl.getMessage('choose_projects')}
            block={!transparent}
            tagRender={(props) => {
                const { value: v } = props;
                if (tenantFilter?.length > 0 && v === tenantFilter[0]) {
                    return (
                        <div className={s.value}>
                            {text}
                        </div>
                    );
                }
                return null as any;
            }}
            showArrow
        >
            {tenantIds.map((tenant) => {
                return !tenant ? null : (
                    <Option value={tenant.id} key={tenant.id}>
                        {tenant.description}
                    </Option>
                );
            })}
        </Select>
    );
};

export default TenantSelect;
