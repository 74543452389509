import React, { FC } from 'react';
import cn from 'classnames';

import { Icon } from 'Common';
import theme from 'Lib/theme';

import s from './Star.module.pcss';

interface StarProps {
    value: number;
    highlighted: number;
    star: number;
    setStar: () => void;
}

const Star: FC<StarProps> = ({ value, highlighted, star, setStar }) => (
    <Icon
        icon="star"
        dataAttrs={{ 'data-order': `${value}` }}
        color={cn({
            [theme.color.sun]: highlighted >= value,
        }, {
            [theme.color.sun]: !highlighted && star >= value,
        })}
        className={s.icon}
        onClick={setStar}
    />
);

export default Star;
