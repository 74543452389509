// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITariffPrice {
    backup_price_gib: number;
    cpu_price: number;
    disk_price_gib: number;
    fixed_price_month: number;
    ip_price: number;
    memory_price_gib: number;
    rx_price_gib: number;
    tx_price_gib: number;
}

export default class TariffPrice {
    readonly _backup_price_gib: number;

    /**
     * Description: Price for usage of 1 Gib of backup storage over the included in tariff
     * Example: 0.5
     */
    get backupPriceGib(): number {
        return this._backup_price_gib;
    }

    static backupPriceGibValidate(backupPriceGib: number): boolean {
        return typeof backupPriceGib === 'number';
    }

    readonly _cpu_price: number;

    /**
     * Description: Price for monthly usage of 1 CPU-unit on non-fixed tariff
     * Example: 3
     */
    get cpuPrice(): number {
        return this._cpu_price;
    }

    static cpuPriceValidate(cpuPrice: number): boolean {
        return typeof cpuPrice === 'number';
    }

    readonly _disk_price_gib: number;

    /**
     * Description: Price for monthly usage of 1GiB of disk on non-fixed tariff
     * Example: 3
     */
    get diskPriceGib(): number {
        return this._disk_price_gib;
    }

    static diskPriceGibValidate(diskPriceGib: number): boolean {
        return typeof diskPriceGib === 'number';
    }

    readonly _fixed_price_month: number;

    /**
     * Description: Price for monthly usage on fixed tariff
     * Example: 500
     */
    get fixedPriceMonth(): number {
        return this._fixed_price_month;
    }

    static fixedPriceMonthValidate(fixedPriceMonth: number): boolean {
        return typeof fixedPriceMonth === 'number';
    }

    readonly _ip_price: number;

    /**
     * Description: Price for monthly usage of 1 public address over the included in tariff
     * Example: 80
     */
    get ipPrice(): number {
        return this._ip_price;
    }

    static ipPriceValidate(ipPrice: number): boolean {
        return typeof ipPrice === 'number';
    }

    readonly _memory_price_gib: number;

    /**
     * Description: Price for monthly usage of 1GiB of memory on non-fixed tariff
     * Example: 280
     */
    get memoryPriceGib(): number {
        return this._memory_price_gib;
    }

    static memoryPriceGibValidate(memoryPriceGib: number): boolean {
        return typeof memoryPriceGib === 'number';
    }

    readonly _rx_price_gib: number;

    /**
     * Description: Price for usage of 1 Gib of received traffic over the included in tariff
     * Example: 0.2
     */
    get rxPriceGib(): number {
        return this._rx_price_gib;
    }

    static rxPriceGibValidate(rxPriceGib: number): boolean {
        return typeof rxPriceGib === 'number';
    }

    readonly _tx_price_gib: number;

    /**
     * Description: Price for usage of 1 Gib of transferred traffic over the included in tariff
     * Example: 0.6
     */
    get txPriceGib(): number {
        return this._tx_price_gib;
    }

    static txPriceGibValidate(txPriceGib: number): boolean {
        return typeof txPriceGib === 'number';
    }

    constructor(props: ITariffPrice) {
        this._backup_price_gib = props.backup_price_gib;
        this._cpu_price = props.cpu_price;
        this._disk_price_gib = props.disk_price_gib;
        this._fixed_price_month = props.fixed_price_month;
        this._ip_price = props.ip_price;
        this._memory_price_gib = props.memory_price_gib;
        this._rx_price_gib = props.rx_price_gib;
        this._tx_price_gib = props.tx_price_gib;
    }

    serialize(): ITariffPrice {
        const data: ITariffPrice = {
            backup_price_gib: this._backup_price_gib,
            cpu_price: this._cpu_price,
            disk_price_gib: this._disk_price_gib,
            fixed_price_month: this._fixed_price_month,
            ip_price: this._ip_price,
            memory_price_gib: this._memory_price_gib,
            rx_price_gib: this._rx_price_gib,
            tx_price_gib: this._tx_price_gib,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            fixed_price_month: typeof this._fixed_price_month === 'number',
            memory_price_gib: typeof this._memory_price_gib === 'number',
            disk_price_gib: typeof this._disk_price_gib === 'number',
            cpu_price: typeof this._cpu_price === 'number',
            ip_price: typeof this._ip_price === 'number',
            rx_price_gib: typeof this._rx_price_gib === 'number',
            tx_price_gib: typeof this._tx_price_gib === 'number',
            backup_price_gib: typeof this._backup_price_gib === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITariffPrice): TariffPrice {
        return new TariffPrice(props);
    }

    readonly keys: { [key: string]: string } = {
        backupPriceGib: 'backup_price_gib',
        cpuPrice: 'cpu_price',
        diskPriceGib: 'disk_price_gib',
        fixedPriceMonth: 'fixed_price_month',
        ipPrice: 'ip_price',
        memoryPriceGib: 'memory_price_gib',
        rxPriceGib: 'rx_price_gib',
        txPriceGib: 'tx_price_gib',
        }
;

    mergeDeepWith(props: Partial<TariffPrice>): TariffPrice {
        const updateData: Partial<ITariffPrice> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TariffPrice) => {
            const updateKey = this.keys[key] as keyof ITariffPrice;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITariffPrice, keyof ITariffPrice>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TariffPrice({ ...this.serialize(), ...updateData });
    }
}
