import cn from 'classnames';
import { FormikErrors } from 'formik';
import React, { FC } from 'react';

import { useIntl, Checkbox, Input, Button } from 'Common';
import { DistributionFamily } from 'Entities/DistributionFamily';
import ServerSetup from 'Entities/ServerSetup';
import FormHelper, { NewServerFormValues } from 'Lib/helpers/newServer';
import { passwordValidate } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import s from './Password.module.pcss';

interface PasswordProps {
    password: string;
    sendPassword: boolean;
    openSupportAccess: boolean;
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
    setSubmitting: (isSubmitting: boolean) => void;
    errors: FormikErrors<NewServerFormValues>;
    values: NewServerFormValues;
}

const Password: FC<PasswordProps> = ({
    values, password, sendPassword, openSupportAccess, setFieldValue, errors, setSubmitting,
}) => {
    const intl = useIntl();

    const onPasswordGen = () => {
        setSubmitting(false);
        setFieldValue('password', FormHelper.generatePassword());
    };

    const onChange = (e: string) => {
        setSubmitting(false);
        setFieldValue('password', e);
    };

    const validatePassword = (e: string) => {
        return ServerSetup.passwordValidate(e)
        && passwordValidate(e, ServerSetup.passwordMinLength);
    };

    return (
        <>
            <div className={s.wrap}>
                <Input
                    size="large"
                    name="password"
                    type="password"
                    error={!!errors.password}
                    onChange={onChange}
                    wrapperClassName={s.password}
                    placeholder={intl.getMessage('password_enter')}
                    value={password}
                    autoComplete="new-password"
                    validate={validatePassword}
                />
                <Button
                    type="icon"
                    className={cn(theme.button.generate, s.generate)}
                    icon="generate"
                    onClick={onPasswordGen}
                    title={intl.getMessage('title_generate_password')}
                />
            </div>

            <div className={theme.color.gray}>
                {intl.getMessage('password_notice', { min: ServerSetup.passwordMinLength })}
            </div>

            <Checkbox
                id="sendPassword"
                name="sendPassword"
                handleChange={(e) => setFieldValue('send_password', e.target.checked)}
                checked={sendPassword}
                className={s.checkbox}
            >
                {intl.getMessage('server_password_send')}
            </Checkbox>

            {values.distribution_name !== DistributionFamily.WINDOWS && (
                <Checkbox
                    id="openSupportAccess"
                    name="openSupportAccess"
                    handleChange={(e) => setFieldValue('open_support_access', e.target.checked)}
                    checked={openSupportAccess}
                >
                    {intl.getMessage('support_access_title')}
                </Checkbox>
            )}
        </>
    );
};

export default Password;
