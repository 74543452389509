import React, { FC, useContext } from 'react';

import { useIntl, CommonModalLayout, Select, Option } from 'Common';
import { SERVER_VIEW } from 'Lib/helpers/consts';
import { ServerListContext } from 'Components/ServersList/factories';
import { TenantSelect, SortDropdown } from '.';

import s from './ModalFilters.module.pcss';

const MOBILE_VIEW_TYPES = [SERVER_VIEW.COMPACT, SERVER_VIEW.EXPAND];

interface ModalFiltersProps {
    handleClose: () => void;
}

const ModalFilters: FC<ModalFiltersProps> = ({
    handleClose,
}) => {
    const { serverView, setViewType } = useContext(ServerListContext);
    const intl = useIntl();
    const viewSelectValue = !MOBILE_VIEW_TYPES.includes(serverView) ? SERVER_VIEW.COMPACT : serverView;

    return (
        <CommonModalLayout
            visible
            title={intl.getMessage('servers_filters_title')}
            buttonText={intl.getMessage('apply')}
            handleSubmit={handleClose}
            handleClose={handleClose}
        >
            <div className={s.wrap}>
                <div className={s.group}>
                    <div className={s.title}>
                        {intl.getMessage('servers_filters_sort')}
                    </div>
                    <div className={s.select}>
                        <SortDropdown modalMode />
                    </div>
                    <div className={s.select}>
                        <TenantSelect />
                    </div>
                </div>
                <div className={s.group}>
                    <div className={s.title}>
                        {intl.getMessage('servers_filters_view_type')}
                    </div>
                    <Select
                        id="view"
                        size="medium"
                        onChange={(v) => setViewType(v)}
                        value={viewSelectValue}
                        block
                    >
                        <Option value={SERVER_VIEW.COMPACT}>
                            {intl.getMessage('server_view_list')}
                        </Option>
                        <Option value={SERVER_VIEW.EXPAND}>
                            {intl.getMessage('server_view_expand')}
                        </Option>
                    </Select>
                </div>
            </div>
        </CommonModalLayout>
    );
};

export default ModalFilters;
