import React, { ReactNode } from 'react';
import { Divider } from 'antd';
import cn from 'classnames';

import { RoutePath } from 'Lib/helpers/routes';
import { Link, useIntl, Button } from 'Common';
import { SUPPORT_MAIL } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import s from './SolarStaffRegistration.module.pcss';

const supportLink = (text: string) => (
    <Link to={RoutePath.CreateTicket}>
        {text}
    </Link>
);

const supportEmail = (text: string) => (
    <a
        href={`mailto:${SUPPORT_MAIL}`}
        className={cn(
            theme.link.link,
            theme.link.blue,
        )}
    >
        {text}
    </a>
);

interface Props {
    children: ReactNode;
    isClientPage?: boolean;
}

export const ContentLayout = ({ children, isClientPage }: Props) => {
    const intl = useIntl();

    return (
        <>
            <div className={s.content}>
                <div className={s.item}>
                    <div className={s.number} />
                    <div className={s.text}>
                        <div className={s.desc}>
                            {intl.getMessage('partner_send_documents', {
                                link: supportLink,
                            })}
                        </div>
                        {children}
                    </div>
                </div>
                <Divider
                    className={cn(
                        theme.divider.divider,
                        theme.divider.divider_dashed,
                        s.divider,
                    )}
                />
                <div className={s.item}>
                    <div className={s.number} />
                    <div className={s.text}>
                        <div className={s.desc}>
                            {intl.getMessage('partner_docs_will_be_sent')}
                        </div>
                    </div>
                </div>
                <Divider
                    className={cn(
                        theme.divider.divider,
                        theme.divider.divider_dashed,
                        s.divider,
                    )}
                />
                <div className={s.item}>
                    <div className={s.number} />
                    <div className={s.text}>
                        <div className={s.desc}>
                            {intl.getMessage('partner_docs_sent_original', {
                                link: supportEmail,
                                support: SUPPORT_MAIL,
                            })}
                        </div>
                        <div className={s.desc}>
                            {intl.getMessage('partner_done')}
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.bottom}>
                <div className={s.buttons}>
                    <Link to={RoutePath.CreateTicket}>
                        <Button
                            type="primary"
                            size="medium"
                        >
                            {intl.getMessage('send_documents')}
                        </Button>
                    </Link>
                    <Link to={isClientPage ? RoutePath.Clients : RoutePath.Partner}>
                        <Button
                            type="link"
                            size="medium"
                        >
                            {intl.getMessage('cancel')}
                        </Button>
                    </Link>
                </div>
            </div>
        </>
    );
};
