import React, { FC } from 'react';
import cn from 'classnames';

import { Link, DashboardHeader, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import Tenant from 'Entities/Tenant';
import theme from 'Lib/theme';

interface HeaderProps {
    tenant: Tenant;
}

const Header: FC<HeaderProps> = ({ tenant }) => {
    return (
        <DashboardHeader
            navigation={(
                <>
                    <Link to={RoutePath.ProjectsList} className={theme.header.back}>
                        <Button
                            type="icon"
                            size="medium"
                            icon="home"
                        />
                    </Link>
                    <div className={cn(theme.header.title, theme.header.title_tenant)}>
                        {tenant.description}
                    </div>
                </>
            )}
        />
    );
};

export default Header;
