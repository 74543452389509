import { Action } from 'store/actions';
import {
    SupportActions,
    saveTickets,
    saveTicket,
    saveTicketsInfo,
} from 'Actions/support';
import Ticket from 'Entities/Ticket';

import { SupportStore, supportInitialState as initialState } from '../initialStates';

const reducer = (state: SupportStore = initialState, action: Action): SupportStore => {
    switch (action.type as SupportActions) {
        case (SupportActions.SaveTickets): {
            const { payload } = action as ReturnType<typeof saveTickets>;
            const { tickets: stateTickets } = state;
            let tickets: Ticket[] = [...payload];
            if (stateTickets !== null) {
                tickets = [...stateTickets, ...payload];
            }
            return { ...state, tickets };
        }
        case (SupportActions.SaveTicket): {
            const { payload } = action as ReturnType<typeof saveTicket>;
            if (state.tickets) {
                const data = [payload, ...state.tickets];
                return { ...state, currentTicket: payload, tickets: data };
            }
            return { ...state, currentTicket: payload };
        }
        case (SupportActions.SaveCurrentTicket): {
            const { payload } = action as ReturnType<typeof saveTicket>;
            return { ...state, currentTicket: payload };
        }
        case (SupportActions.ClearTicket): {
            return { ...state, currentTicket: null };
        }
        case (SupportActions.ClearTickets): {
            return { ...state, tickets: null };
        }
        case (SupportActions.SaveTicketsInfo): {
            const { payload } = action as ReturnType<typeof saveTicketsInfo>;
            return { ...state, ticketsInfo: payload };
        }
        default:
            return state;
    }
};

export default reducer;
