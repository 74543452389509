import React, { FC } from 'react';

import { Checkbox, useIntl } from 'Common';

interface ModalServersCheckboxProps {
    checked: boolean;
    handleChange: (checked: boolean) => void;
}

const ModalServersCheckbox: FC<ModalServersCheckboxProps> = ({ checked, handleChange }) => {
    const intl = useIntl();

    return (
        <div className="modal__checkbox">
            <Checkbox
                id="no_more_showing"
                name="no_more_showing"
                checked={checked}
                handleChange={(e) => handleChange(e.target.checked)}
            >
                {intl.getMessage('no_more_showing')}
            </Checkbox>
        </div>
    );
};

export default ModalServersCheckbox;
