import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, useIntl, DetailedComparativeValues } from 'Common';
import ComparativeIncome from 'Entities/ComparativeIncome';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import DashboardLink from './DashboardLink';
import s from './Stats.module.pcss';

interface ClientsProps {
    accounts: number;
    comparativeIncome: ComparativeIncome;
}

const Clients: FC<ClientsProps> = ({
    accounts,
    comparativeIncome,
}) => {
    const intl = useIntl();

    const {
        incomeChangePercent,
        recentIncome,
        recentPeriod,
        previousIncome,
        previousPeriod,
    } = comparativeIncome;

    const roundedRecentIncome = Math.round(recentIncome);

    return (
        <div className={s.block}>
            {accounts > 0 ? (
                <>
                    <div className={s.info}>
                        <Icon icon="profile" className={cn(s.icon, s.profile)} />
                        <div className={theme.score.label}>
                            {intl.getMessage('dashboard_reseller_income')}
                        </div>
                        <div className={cn(s.val, s.val_main)}>
                            <span className={s.valInner}>
                                {intl.getMessage('price_simple', { value: roundedRecentIncome })}
                            </span>
                            <DetailedComparativeValues
                                diff={incomeChangePercent}
                                recentValue={recentIncome}
                                recentPeriod={recentPeriod}
                                previousValue={previousIncome}
                                previousPeriod={previousPeriod}
                                increasingLabel={intl.getMessage('dashboard_reseller_income_increased')}
                                decreasingLabel={intl.getMessage('dashboard_reseller_income_decreased')}
                                withCurrency
                            />
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={cn(s.header, s.header_between)}>
                        <div className={s.title}>
                            {intl.getMessage('clients')}
                        </div>
                    </div>
                    <div className={s.body}>
                        <div className={s.label}>
                            {intl.getMessage('dashboard_no_clients')}
                        </div>
                    </div>
                </>
            )}
            <DashboardLink to={RoutePath.Clients} />
        </div>
    );
};

export default Clients;
