import React, { FC } from 'react';
import { Modal } from 'antd';
import cn from 'classnames';

import { Icon } from 'Common';
import { NEW_USER_NOTICES_LIST } from 'Lib/helpers/consts';

import s from './Notice.module.pcss';

interface NoticeOwnProps {
    visible: boolean;
    name: string;
    handleClose: () => void;
    modalClassName?: string;
}

type NoticeProps = NoticeOwnProps;

const Notice: FC<NoticeProps> = ({
    visible,
    name,
    handleClose,
    modalClassName,
    children,
}) => {
    const { SERVERS, PROJECTS, PARTNER, NOTICES, ACCOUNT } = NEW_USER_NOTICES_LIST;

    const pointerClassNames = cn(
        s.pointer,
        { [s.pointer_server]: name === SERVERS },
        { [s.pointer_projects]: name === PROJECTS },
        { [s.pointer_partner]: name === PARTNER },
        { [s.pointer_notices]: name === NOTICES },
        { [s.pointer_account]: name === ACCOUNT },
        { [s.pointer_dark]: name === 'dark' },
    );

    return (
        <Modal
            visible={visible}
            wrapClassName={modalClassName}
            onCancel={handleClose}
            closeIcon={<Icon icon="close_big" />}
            title={null}
            footer={null}
            width={240}
        >
            <div className={s.content}>
                {children}
                <div className={pointerClassNames}>
                    <div className={s.inner} />
                    <div className={s.outer} />
                </div>
            </div>
        </Modal>
    );
};

export default Notice;
