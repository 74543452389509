// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsZoneCreate {
    host: string;
}

export default class DnsZoneCreate {
    readonly _host: string;

    /**
     * Description: Host
     * Example: www.example.com
     */
    get host(): string {
        return this._host;
    }

    static hostValidate(host: string): boolean {
        return typeof host === 'string' && !!host.trim();
    }

    constructor(props: IDnsZoneCreate) {
        this._host = props.host.trim();
    }

    serialize(): IDnsZoneCreate {
        const data: IDnsZoneCreate = {
            host: this._host,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            host: typeof this._host === 'string' && !!this._host.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsZoneCreate): DnsZoneCreate {
        return new DnsZoneCreate(props);
    }

    readonly keys: { [key: string]: string } = {
        host: 'host',
        }
;

    mergeDeepWith(props: Partial<DnsZoneCreate>): DnsZoneCreate {
        const updateData: Partial<IDnsZoneCreate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsZoneCreate) => {
            const updateKey = this.keys[key] as keyof IDnsZoneCreate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsZoneCreate, keyof IDnsZoneCreate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsZoneCreate({ ...this.serialize(), ...updateData });
    }
}
