// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum TriggerRuleType {
    DAILY_EXPENSE = 'DAILY_EXPENSE',
    LOAD_AVERAGE = 'LOAD_AVERAGE',
    DISK_FREE_SPACE = 'DISK_FREE_SPACE',
    CPU_USAGE = 'CPU_USAGE',
    PING = 'PING',
    INODES_USE = 'INODES_USE',
    HTTP = 'HTTP',
    RECEIVED_TRAFFIC_USAGE = 'RECEIVED_TRAFFIC_USAGE',
    TRANSFERRED_TRAFFIC_USAGE = 'TRANSFERRED_TRAFFIC_USAGE',
    MIN_BALANCE = 'MIN_BALANCE',
    HTTPS_SSL_CERT = 'HTTPS_SSL_CERT',
    DOMAIN_EXPIRATION = 'DOMAIN_EXPIRATION',
    VM_CREATE = 'VM_CREATE',
    VM_REBOOT = 'VM_REBOOT',
    VM_START = 'VM_START',
    VM_SHUTOFF = 'VM_SHUTOFF',
    VM_DELETE = 'VM_DELETE',
    VM_MEMORY_CHANGE = 'VM_MEMORY_CHANGE',
    VM_DISK_INCREASE = 'VM_DISK_INCREASE',
    VM_PASSWORD_CHANGE = 'VM_PASSWORD_CHANGE',
    VM_MEMORY_DIFFERENT = 'VM_MEMORY_DIFFERENT',
    VM_OUT_OF_MEMORY = 'VM_OUT_OF_MEMORY',
    VM_LINUX_KERNEL_EVENT = 'VM_LINUX_KERNEL_EVENT',
    VM_ACPI_MEMORY_HP_ERROR = 'VM_ACPI_MEMORY_HP_ERROR',
    VM_REVERT_TO_SNAPSHOT = 'VM_REVERT_TO_SNAPSHOT',
    VM_REVERT_TO_BACKUP = 'VM_REVERT_TO_BACKUP'
}
