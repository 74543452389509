import React, { FC } from 'react';

import { SetupActions } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import Server from 'Entities/Server';
import Tenant from 'Entities/Tenant';

import CommonHeader from './CommonHeader';
import s from '../Header.module.pcss';

interface SetupStateProps {
    server: Server;
    progress: OperationProgress;
    tenant: Tenant;
    compact?: boolean;
}

const SetupState: FC<SetupStateProps> = ({
    server,
    progress,
    tenant,
    compact,
}) => {
    return (
        <CommonHeader
            server={server}
            tenant={tenant}
        >
            <div className={s.setupWrap}>
                <SetupActions
                    server={server}
                    progress={progress}
                    compact={compact}
                />
            </div>
        </CommonHeader>
    );
};

export default SetupState;
