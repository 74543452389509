/* eslint-disable react/require-default-props */ // TODO remove rule v.abdulmyanov
import React, { useEffect, useState, useContext } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { Button, useIntl } from 'Common';
import AreaChart from 'Common/Chart/AreaChart';
import { CHART_RANGE_MEAN, ONE_BYTE, RESOURCES_TYPES } from 'Lib/helpers/consts';
import { IServerJsonStats } from 'Entities/ServerJsonStats';
import theme from 'Lib/theme';
import { IAccountExpense } from 'Entities/AccountExpense';
import PartnerStats from 'Entities/PartnerStats';

import SelectLines from './SelectLines';
import { ChartContext, IChartContext } from '../ChartContext';

import s from './ChartCard.module.pcss';

interface ChartCardProps<T> {
    verticalZoom: boolean;
    setVerticalZoom: (v: boolean) => void;
    currentLines: Set<keyof T>;
    setCurrentLines: (v: Set<keyof T>) => void;
    inModal?: boolean;
    handleModal?: () => void;
}

const ChartCard = <T extends IServerJsonStats | PartnerStats | IAccountExpense,>({
    verticalZoom,
    setVerticalZoom,
    currentLines,
    setCurrentLines,
    inModal,
    handleModal,
}: ChartCardProps<T>) => {
    const intl = useIntl();

    const {
        data,
        chart,
    } = useContext(ChartContext as React.Context<IChartContext<T>>);

    const {
        type,
        xAxisKey,
        lines,
        drawGrid,
        drawYAxis,
        drawXAxis,
        syncId,
        name,
        size,
        tabs,
        mean,
        valueRange,
        unit,
        dynamicValueRangeMax,
        tickCount,
        setGlobalZoom,
        initialRange,
        setRange,
        serverEvents,
        serverAddedMillis,
        initialEmpty,
    } = chart;

    const [meanValue, setMeanValue] = useState<[number | null, number | null]>([null, null]);

    const customValueRange = valueRange || dynamicValueRangeMax;
    const chartWithoutVerticalZoom = type === RESOURCES_TYPES.MEMORY || type === RESOURCES_TYPES.DISK_SPACE;

    const firstValue = meanValue[0]?.toFixed(2) || '';
    const secondValue = meanValue[1]?.toFixed(2) || '';

    const tooltipTitles = {
        CPU: intl.getMessage('mean_cpu', { percent: `${firstValue}%`, cpuNum: secondValue }),
        PUBLIC_NETWORK: intl.getMessage('mean_traffic', { recived: firstValue, transfered: secondValue }),
    };

    useEffect(() => {
        if (!mean || data.length === 0) {
            return;
        }

        const xAxisStart = (data)[0][xAxisKey];
        const xAxisEnd = (data)[data.length - 1][xAxisKey];

        if (mean === CHART_RANGE_MEAN.CPU) {
            let cpuUsagePercentOverall = 0;
            let count = 0;
            (data).forEach((rData) => {
                const currentTime = rData[xAxisKey];
                if (xAxisStart <= currentTime! && currentTime! <= xAxisEnd) {
                    cpuUsagePercentOverall += (rData as IServerJsonStats).CPU_TOTAL!;
                    count += 1;
                }
            });
            const cpuUsagePercent = count === 0 ? 0 : cpuUsagePercentOverall / count;
            setMeanValue([cpuUsagePercent, cpuUsagePercent / 100]);
        }
        if (mean && mean === CHART_RANGE_MEAN.PUBLIC_NETWORK) {
            let overallReceived = 0;
            let overallTransferred = 0;
            let prevTime: number | null = null;
            (data).forEach((rData) => {
                const currentTime = (rData)[xAxisKey] as unknown as number;
                if (xAxisStart <= rData[xAxisKey] && rData[xAxisKey] <= xAxisEnd) {
                    if (prevTime) {
                        const interval = (currentTime - prevTime) / 1000;
                        overallReceived += (rData as IServerJsonStats).RX_PUBLIC_MBITS! * interval;
                        overallTransferred += (rData as IServerJsonStats).TX_PUBLIC_MBITS! * interval;
                    }
                }
                prevTime = currentTime;
            });
            setMeanValue([
                (overallReceived) / ONE_BYTE,
                (overallTransferred) / ONE_BYTE,
            ]);
        }
    }, [data]);

    const onCurrentLinesSelect = (line: keyof T) => {
        const newLines = new Set(currentLines);
        if (newLines.has(line)) {
            newLines.delete(line);
        } else {
            newLines.add(line);
        }
        setCurrentLines(newLines);
    };

    const getVerticalZoomTitle = () => {
        if (!customValueRange || chartWithoutVerticalZoom) {
            return intl.getMessage('title_precise_values_disabled');
        }

        return verticalZoom ? intl.getMessage('title_precise_values_hide') : intl.getMessage('title_precise_values_show');
    };

    return (
        <div>
            <div className={s.top}>
                <div className={cn(s.chartName, { [s.chartName_tabs]: !inModal && tabs })}>
                    {name}
                </div>
                {mean && (
                    <div className={s.mean}>
                        <Tooltip
                            placement="right"
                            title={tooltipTitles[mean]}
                            overlayClassName="tooltip tooltip_chart"
                        >
                            <div
                                className={cn(
                                    theme.Badge.badge,
                                    theme.Badge.question,
                                    s.badge,
                                )}
                            />
                        </Tooltip>
                    </div>
                )}
                {data.length > 1 && (
                    <div className={s.actions}>
                        {lines.length > 1 && (
                            <SelectLines<T>
                                lines={lines}
                                currentLines={currentLines}
                                onCurrentLinesSelect={onCurrentLinesSelect}
                            />
                        )}
                        <Button
                            type="icon"
                            icon={verticalZoom ? 'zoom_out' : 'zoom_in'}
                            onClick={() => setVerticalZoom(!verticalZoom)}
                            disabled={!customValueRange || chartWithoutVerticalZoom}
                            title={getVerticalZoomTitle()}
                            tooltipClassName="tooltip_modal"
                            showDisabledTitle
                        />
                        <div className={s.maximize}>
                            <Button
                                type="icon"
                                icon={inModal ? 'minimize' : 'maximize'}
                                title={inModal ? intl.getMessage('title_close') : intl.getMessage('title_fullscreen')}
                                onClick={handleModal}
                            />
                        </div>
                    </div>
                )}
            </div>

            <AreaChart
                size={inModal ? 'large' : size}
                type={type}
                xAxisKey={xAxisKey}
                data={data}
                lines={lines}
                drawGrid={inModal ? true : drawGrid}
                drawYAxis={drawYAxis}
                drawXAxis={drawXAxis}
                syncId={inModal ? undefined : syncId}
                valueRange={valueRange}
                unit={unit}
                dynamicValueRangeMax={dynamicValueRangeMax}
                tickCount={tickCount}
                setGlobalZoom={inModal ? undefined : setGlobalZoom}
                initialRange={initialRange}
                setRange={setRange}
                serverEvents={serverEvents}
                serverAddedMillis={serverAddedMillis}
                currentLines={currentLines}
                verticalZoom={verticalZoom}
                initialEmpty={initialEmpty}
            />
        </div>
    );
};

export default ChartCard;
