import React, { useEffect, useState, useMemo } from 'react';
import { AreaChart as AreaChartLib, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, ReferenceArea, ReferenceDot } from 'recharts';
import dayjs from 'dayjs';

import { useIntl } from 'Common';
import { IServerJsonStats } from 'Entities/ServerJsonStats';
import PartnerStats from 'Entities/PartnerStats';
import { IAccountExpense } from 'Entities/AccountExpense';
import Notification from 'Entities/Notification';
import { CHART_RANGE_MEAN, ADJUST_NUMBER, RESOURCES_TYPES } from 'Lib/helpers/consts';
import { getCssColors } from 'Lib/helpers/helpers';
import { Range } from 'Lib/helpers/utils';

import { EmptyChart, CustomTick, Tooltip as ChartTooltip, AreaStripes, EventTrigger } from './components';
import s from './Chart.module.pcss';

const TOOLTIP_OFFSET = 130;

enum CHART_TYPE {
    MINUTE = 'MINUTE',
    HOUR = 'HOUR',
    DAY = 'DAY',
}

const CHART_SIZES = {
    small: 62,
    medium: 140,
    large: 240,
};

export const zoomInitialValues = {
    refAreaLeft: '',
    refAreaRight: '',
    left: 'dataMin',
    right: 'dataMax',
};

export interface IChartLine<T> {
    id: keyof T;
    color: string;
    label: string;
}

export type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

export type ChartSizeType = 'small' | 'medium' | 'large';
export interface AreaChartProps<T extends PartnerStats | IServerJsonStats | IAccountExpense> {
    type: RESOURCES_TYPES;
    xAxisKey: KeysMatching<T, number>;
    boundaryXAxisTicks?: number[];
    data: T[];
    lines: IChartLine<T>[];
    adjust?: typeof ADJUST_NUMBER[keyof typeof ADJUST_NUMBER];
    drawGrid?: boolean;
    drawYAxis?: boolean;
    drawXAxis?: boolean;
    size?: ChartSizeType;
    syncId?: string;
    name?: string;
    tabs?: boolean;
    mean?: CHART_RANGE_MEAN;
    valueRange?: number[];
    unit: string;
    dynamicValueRangeMax?: (max: number) => number;
    tickCount?: number;
    globalZoom?: typeof zoomInitialValues;
    setGlobalZoom?: (zoom: typeof zoomInitialValues) => void;
    initialRange?: Range;
    setRange?: (value: Range) => void;
    serverEvents?: Notification[] | null;
    serverAddedMillis?: number;
    currentLines?: Set<keyof T>;
    verticalZoom?: boolean;
    initialEmpty?: boolean;
}
export type Data<T extends string | number | symbol> = Record<T, number>[];
const AreaChart = <T extends PartnerStats | IServerJsonStats | IAccountExpense>({
    data,
    xAxisKey,
    boundaryXAxisTicks,
    lines,
    drawGrid,
    drawYAxis,
    drawXAxis,
    size,
    syncId,
    valueRange,
    unit,
    dynamicValueRangeMax,
    setGlobalZoom,
    initialRange,
    setRange,
    serverEvents,
    serverAddedMillis,
    currentLines,
    verticalZoom,
    initialEmpty,
}: AreaChartProps<T>) => {
    const intl = useIntl();
    const chartHeight = size ? CHART_SIZES[size] : CHART_SIZES.medium;
    const customValueRange = valueRange || dynamicValueRangeMax;
    const chartColors = getCssColors();

    const initialZoom = useMemo(() => initialRange, []);
    const [zoom, setZoom] = useState(zoomInitialValues);
    const [emptyData, setEmptyData] = useState(initialEmpty || false);

    useEffect(() => {
        const lastElement = data[data.length - 1];

        if (initialEmpty || !lastElement) {
            return;
        }
        const ids: (keyof T)[] = lines.map((line) => line.id);
        const empty = ids.every((id) => lastElement[id] === null);
        setEmptyData(empty);
    }, [data]);

    const getRangeFromRangeToDiff = (range: Range | undefined) => {
        if (!range) {
            return null;
        }

        return range.toDiff('week');
    };

    const getChartType = (): CHART_TYPE => {
        const diff = getRangeFromRangeToDiff(initialRange);
        if (diff && diff > 0) {
            return CHART_TYPE.DAY;
        }

        return CHART_TYPE.HOUR;
    };

    const getMinValueRange = () => {
        return valueRange ? valueRange[0] : 0;
    };

    const getMaxValueRange = (dataMax: number) => {
        if (dynamicValueRangeMax) {
            return dynamicValueRangeMax(dataMax);
        }

        return valueRange ? valueRange[1] : 'auto';
    };

    const onZoom = () => {
        let { refAreaLeft, refAreaRight } = zoom;
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setZoom({ ...zoom, refAreaLeft: '', refAreaRight: '' });
            setGlobalZoom?.({ ...zoom, refAreaLeft: '', refAreaRight: '' });
            return;
        }
        if (refAreaLeft > refAreaRight) {
            [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
        }
        const newZoom = {
            refAreaLeft: '',
            refAreaRight: '',
            left: refAreaLeft,
            right: refAreaRight,
        };
        setZoom(newZoom);
        setGlobalZoom?.(newZoom);

        if (setRange !== undefined) {
            const zoomRange = new Range(dayjs(refAreaLeft), dayjs(refAreaRight));
            setRange(zoomRange);
        }
    };

    const onZoomReset = () => {
        setZoom(zoomInitialValues);
        setGlobalZoom?.(zoomInitialValues);

        if (initialZoom && setRange) {
            setRange(initialZoom);
        }
    };

    const isDailyChart = getChartType() === CHART_TYPE.DAY;
    const { refAreaLeft } = zoom;

    return (
        <div
            className={s.wrapper}
            onDoubleClick={onZoomReset}
        >
            {data.length <= 1 || emptyData ? (
                <EmptyChart size={size} />
            ) : (
                <ResponsiveContainer width="100%" height={chartHeight}>
                    <AreaChartLib
                        data={data}
                        syncId={syncId}
                        onMouseDown={(e: any) => setZoom({ ...zoom, refAreaLeft: e?.activeLabel })}
                        onMouseMove={(e: any) => refAreaLeft && setZoom({ ...zoom, refAreaRight: e?.activeLabel })}
                        onMouseUp={onZoom}
                        margin={{ top: 5, right: 0, bottom: 0, left: 0 }}
                    >
                        <defs>
                            {lines.map(({ id, color }) => (
                                <linearGradient
                                    id={`${id}${size}`}
                                    key={id as string}
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop offset="10%" stopColor={color} stopOpacity={0.1} />
                                    <stop offset="90%" stopOpacity={0.1} className={s.gradient} />
                                </linearGradient>
                            ))}
                        </defs>
                        {drawGrid && (
                            <CartesianGrid className={s.grid} />
                        )}
                        {serverAddedMillis && (
                            <ReferenceArea
                                x2={serverAddedMillis}
                                shape={(props) => <AreaStripes {...props} />}
                            />
                        )}
                        {lines.map(({ id, color, label }) => {
                            const visible = currentLines ? currentLines.has(id) : true;

                            return (
                                <Area
                                    type="monotone"
                                    key={id as string}
                                    dataKey={visible ? id as string : ''}
                                    stroke={color}
                                    fill={`url(#${id}${size})`}
                                    name={label}
                                    fillOpacity={1}
                                    strokeWidth={1.2}
                                    activeDot={false}
                                />
                            );
                        })}
                        {serverEvents && serverEvents.map((event) => (
                            <ReferenceDot
                                key={`${event.id}dot`}
                                x={event.timeAddedMillis}
                                y={0}
                                shape={(props) => <EventTrigger size={size} event={event} {...props} />}
                            />
                        ))}
                        {drawXAxis && (
                            <XAxis
                                allowDataOverflow
                                axisLine={false}
                                tickLine={false}
                                tickCount={8}
                                type="number"
                                dataKey={xAxisKey as string}
                                domain={['dataMin', 'dataMax']}
                                tickFormatter={(value) => {
                                    if (boundaryXAxisTicks) {
                                        return dayjs(value).format(intl.getMessage('time_format_short_month'));
                                    }

                                    if (isDailyChart) {
                                        return dayjs(value).format(intl.getMessage('time_format_day_short'));
                                    }

                                    return dayjs(value).format(intl.getMessage('time_format_hour'));
                                }}
                                className={s.axis}
                                ticks={boundaryXAxisTicks}
                                interval={boundaryXAxisTicks ? 'preserveStartEnd' : 'preserveEnd'}
                            />
                        )}
                        {drawYAxis && (
                            <YAxis
                                domain={!verticalZoom ? [
                                    getMinValueRange(),
                                    customValueRange ? (dataMax: number) => getMaxValueRange(dataMax) : 'auto',
                                ] : undefined}
                                tickLine={false}
                                unit={unit}
                                mirror
                                type="number"
                                tick={(props) => <CustomTick unit={unit} {...props} />}
                                scale="auto"
                                tickFormatter={(value) => {
                                    let yLabel = value;
                                    if (value < 1) {
                                        yLabel = value.toFixed(2);
                                    }
                                    if (value < 0.1) {
                                        yLabel = value.toFixed(2);
                                    }
                                    if (value < 0.01) {
                                        yLabel = value.toFixed(3);
                                    }
                                    if (value < 0.001) {
                                        yLabel = value.toFixed(4);
                                    }
                                    if (value < 0.0001) {
                                        yLabel = value.toFixed(0);
                                    }

                                    if (yLabel === '0.0' || yLabel === '0' || value === 0) {
                                        yLabel = '';
                                    }

                                    return yLabel;
                                }}
                            />
                        )}
                        <Tooltip
                            cursor={{ stroke: chartColors.wave, strokeWidth: 1 }}
                            position={{ y: size !== 'small' ? TOOLTIP_OFFSET : 0 }}
                            content={(
                                <ChartTooltip
                                    unit={unit}
                                    xAxisKey={xAxisKey as string}
                                />
                            )}
                            isAnimationActive={false}
                        />
                        {zoom.refAreaLeft && zoom.refAreaRight ? (
                            <ReferenceArea x1={zoom.refAreaLeft} x2={zoom.refAreaRight} strokeOpacity={0.3} />
                        ) : null}
                    </AreaChartLib>
                </ResponsiveContainer>
            )}
        </div>
    );
};

export default AreaChart;
