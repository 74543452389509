import React, { FC, ReactElement } from 'react';
import { Col, Layout, Row } from 'antd';
import cn from 'classnames';

import theme from 'Lib/theme';
import { BackHeader } from 'Common';
import { LinkParams, RoutePath } from 'Lib/helpers/routes';

import s from './FormPageLayout.module.pcss';

type linkType = {
    link: RoutePath;
    name: string;
};

interface FormPageLayoutProps {
    title: string;
    titleMod?: string;
    back: RoutePath;
    banner?: ReactElement;
    type?: string;
    link?: ReactElement;
    linkParams?: LinkParams;
}

const { Content } = Layout;

const FormPageLayout: FC<FormPageLayoutProps> = ({
    title,
    titleMod,
    type,
    children,
    back,
    banner,
    link,
    linkParams,
}) => {
    return (
        <Layout>
            <BackHeader type="narrow" link={back} linkParams={linkParams} />
            <Content className={cn(theme.content.content, theme.content.form)}>
                <Row gutter={[{ xs: 24, lg: 48 }, 32]} justify="center">
                    <Col span={24} md={banner ? 16 : 24}>
                        <div className={cn(s.wrap, { [s.wrap_narrow]: type === 'narrow' })}>
                            <div className={cn(s.title, { [s[titleMod || '']]: titleMod })}>
                                {title}
                            </div>
                            {children}
                        </div>
                    </Col>
                    {banner && (
                        <Col span={24} md={8}>
                            {banner}
                        </Col>
                    )}
                </Row>

                {link && (
                    <div className={s.link}>
                        {link}
                    </div>
                )}
            </Content>
        </Layout>
    );
};

export default FormPageLayout;
