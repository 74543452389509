import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useTitle } from 'Hooks';
import { useIntl, InnerPageLayout, ListLayout, EmptyPageLayout, LoaderPageLayout, Icon, notifyError, Loader, whiteText, notifySuccess } from 'Common';
import PublicKey from 'Entities/PublicKey';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { getPublicKeys } from 'Actions/publicKeys';
import { removeDoneProgress } from 'Actions/batchOperationProgress';
import { BATCH_PUBLIC_KEYS_NOTIFICATION_ID } from 'Lib/helpers/consts';
import { Store } from 'Store';
import theme from 'Lib/theme';
import s from './PublicKeys.module.pcss';

import { Header, Key, ModalAddKey } from './components';

interface PublicKeysProps {
    publicKeys: PublicKey[] | null;
    tenants: Store['tenant'];
    batchOperation: Store['batchOperationProgress'];
}

const PublicKeys: FC<PublicKeysProps> = ({
    publicKeys,
    tenants,
    batchOperation,
}) => {
    const intl = useIntl();
    useTitle(intl.getMessage('public_keys_page_title'));
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const { progress, servers, type, errors, publicKeys: batchPublicKeys } = batchOperation;

    const batchOperationInProgress = progress !== null
        && servers.length > 0
        && batchPublicKeys?.length === 1
        && type === SecurityActionType.SERVER_PUBLIC_KEY;

    useEffect(() => {
        dispatch(getPublicKeys());
    }, []);

    useEffect(() => {
        if (type === SecurityActionType.SERVER_PUBLIC_KEY && progress === 100) {
            if (errors.length === 0) {
                notifySuccess((
                    <div className={theme.notification.text}>
                        {intl.getPlural('batch_add_public_key_success', servers.length, { b: whiteText })}
                    </div>
                ), undefined, {
                    className: theme.notification.customNotification,
                });
            } else {
                notifyError((
                    <div className={theme.notification.text}>
                        {intl.getPlural('batch_add_public_key_error', errors.length, { b: whiteText })}
                        <div className={s.serversList}>
                            {errors.map(({ id, name }) => (
                                <div key={id} className={s.serverItem}>
                                    {name}
                                </div>
                            ))}
                        </div>
                    </div>
                ), undefined, {
                    duration: 0,
                    className: theme.notification.customNotification,
                });
            }
            dispatch(removeDoneProgress());
        } else if (batchOperationInProgress) {
            notifyError((
                <div className={theme.notification.wrapper}>
                    <div className={theme.notification.text}>
                        {intl.getMessage('batch_add_public_key_loader', { b: whiteText })}
                    </div>
                    <div className={theme.color.blue}>
                        <Loader circle mini className={theme.notification.loader} />
                    </div>
                </div>
            ), undefined,
            {
                key: BATCH_PUBLIC_KEYS_NOTIFICATION_ID,
                duration: 0,
                className: 'notification_event',
            });
        }
    }, [progress]);

    const getContent = () => {
        if (publicKeys === null) {
            return <LoaderPageLayout />;
        }

        if (publicKeys.length > 0) {
            return (
                <ListLayout
                    title={intl.getMessage('keys')}
                    length={publicKeys.length}
                >
                    <div
                        className={cn(
                            theme.card.labels,
                            theme.card.labels_withActions,
                            theme.card.labels_key,
                        )}
                    >
                        <div className={theme.card.label}>
                            {intl.getMessage('public_keys_name')}
                        </div>
                        <div className={theme.card.label}>
                            {intl.getMessage('tenant')}
                        </div>
                        <div className={theme.card.label}>
                            {intl.getMessage('public_keys_type')}
                        </div>
                        <div className={theme.card.label}>
                            {intl.getMessage('public_keys_to_new_servers')}
                        </div>
                        <div className={theme.card.label}>
                            <Icon icon="history" className={theme.card.dateIcon} />
                        </div>
                    </div>
                    {publicKeys.map((key) => {
                        const tenant = tenants.get(key.tenantId);
                        return (
                            <Key
                                key={key.id}
                                tenant={tenant}
                                item={key}
                            />
                        );
                    })}
                </ListLayout>
            );
        }

        return (
            <EmptyPageLayout
                title={intl.getMessage('public_keys_empty_title')}
                desc={intl.getMessage('public_keys_empty_desc')}
                linkText={intl.getMessage('add_key')}
                handleClick={() => setOpenModal(true)}
            />
        );
    };

    return (
        <InnerPageLayout
            header={<Header openModal={() => setOpenModal(true)} />}
            className={theme.content.with_actions}
        >
            {getContent()}

            {openModal && (
                <ModalAddKey
                    visible={openModal}
                    handleClose={() => setOpenModal(false)}
                />
            )}
        </InnerPageLayout>
    );
};

const selectPublicKeys = (store: Store) => store.publicKeys;
const selectTenants = (store: Store) => store.tenant;
const selectBatchOperation = (store: Store) => store.batchOperationProgress;

const selector = createSelector(
    [
        selectPublicKeys,
        selectTenants,
        selectBatchOperation,
    ],
    (
        pk,
        tenants,
        batchOperation,
    ) => ({
        publicKeys: pk === null ? null : Array.from(pk.values()),
        tenants,
        batchOperation,
    }),
);
const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});
export default connect(mapStoreToProps)(PublicKeys);
