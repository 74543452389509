import React, { FC, useState } from 'react';

import { useIntl } from 'Common';
import PayerInfo from 'Entities/PayerInfo';
import { IUpdatePayerRequest } from 'Entities/UpdatePayerRequest';
import { IUpdateCorporation } from 'Entities/UpdateCorporation';
import theme from 'Lib/theme';

import Field from './Field';
import Submit from './Submit';

interface CorporationFormProps {
    payer: PayerInfo;
    handleClose: () => void;
    handleSubmit: (data: IUpdatePayerRequest, setErrors: (errors: string[]) => void) => void;
}

const CorporationForm: FC<CorporationFormProps> = ({ payer, handleClose, handleSubmit }) => {
    const intl = useIntl();
    const [state, setState] = useState<IUpdateCorporation>({
        corporation_legal_address: payer.corporationInfo!.legalAddress,
        corporation_post_address: payer.corporationInfo!.postAddress,
        corporation_head_position: payer.corporationInfo!.headPosition,
        corporation_head_name: payer.corporationInfo!.headName,
        corporation_operation_basis: payer.corporationInfo!.operationBasis,
        corporation_accounting_email: payer.corporationInfo!.accountingEmail,
        corporation_iec: payer.corporationInfo!.iec,
    });
    const [errors, setErrors] = useState<Set<string>>(new Set());
    const onChange = (field: keyof typeof state) => (value: string) => {
        if (errors.has(field)) {
            errors.delete(field);
            setErrors(new Set(errors));
        }
        setState({ ...state, [field]: value });
    };

    return (
        <div className={theme.modal.form}>
            <Field
                id="type"
                label={intl.getMessage('credentials_account_type')}
                value={intl.getMessage('credentials_legal_entity')}
                disabled
            />
            <Field
                id="inn"
                label={intl.getMessage('credentials_vat')}
                value={payer.corporationInfo!.inn}
                disabled
            />

            <div className={theme.modal.subtitle}>
                {intl.getMessage('credentials_company_info')}
            </div>

            <Field
                id="companyName"
                label={intl.getMessage('credentials_company_name')}
                value={payer.fullName}
                disabled
            />
            <Field
                id="iec"
                label={intl.getMessage('credentials_iec')}
                value={payer.corporationInfo!.iec}
                disabled
            />
            <Field
                id="legalAddress"
                label={intl.getMessage('credentials_legal_address')}
                value={state.corporation_legal_address!}
                onChange={onChange('corporation_legal_address')}
                error={errors.has('corporation_legal_address')}
            />
            <Field
                id="correspondenceAddress"
                label={intl.getMessage('credentials_correspondence_address')}
                value={state.corporation_post_address!}
                onChange={onChange('corporation_post_address')}
                error={errors.has('corporation_post_address')}
            />
            <Field
                id="headPosition"
                label={intl.getMessage('credentials_head_position')}
                value={state.corporation_head_position!}
                onChange={onChange('corporation_head_position')}
                error={errors.has('corporation_head_position')}
            />
            <Field
                id="headName"
                label={intl.getMessage('credentials_head_name')}
                value={state.corporation_head_name!}
                onChange={onChange('corporation_head_name')}
                error={errors.has('corporation_head_name')}
            />
            <Field
                id="basis"
                label={intl.getMessage('credentials_operation_basis')}
                value={state.corporation_operation_basis!}
                onChange={onChange('corporation_operation_basis')}
                error={errors.has('corporation_operation_basis')}
            />
            <Field
                id="correspondenceEmail"
                label={intl.getMessage('credentials_accounting_correspondence_email')}
                value={state.corporation_accounting_email!}
                onChange={onChange('corporation_accounting_email')}
                error={errors.has('corporation_accounting_email')}
            />
            <Field
                id="governmentContract"
                label={intl.getMessage('credentials_government_contract_identifier')}
                value={payer.corporationInfo!.governmentContractId!}
                disabled
            />
            <Submit
                handleSubmit={() => handleSubmit(
                    { corporation: state }, (e) => setErrors(new Set(e)),
                )}
                handleClose={handleClose}
            />
        </div>
    );
};

export default CorporationForm;
