import React, { FC, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { createSelector } from 'reselect';

import { getResellerCompletePayments, getResellerProcessingPayments } from 'Actions/reseller';
import { useIntl, InnerPageLayout, EmptyPageLayout, FinanceTable } from 'Common';
import { useOffsetLoader, useTitle } from 'Lib/hooks/hooks';
import { RoutePath } from 'Lib/helpers/routes';
import ResellerPayment from 'Entities/ResellerPayment';
import { Store } from 'Store';
import theme from 'Lib/theme';

import Header from './Header';

interface FinanceProps {
    complete: ResellerPayment[];
    incomplete: ResellerPayment[];
}

const Finance: FC<FinanceProps> = ({ complete, incomplete }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    useTitle(intl.getMessage('clients_finance_page_title'));

    useOffsetLoader(complete, (offset: number, limit: number) => {
        return getResellerCompletePayments([offset * limit, limit]);
    });

    useEffect(() => {
        dispatch(getResellerProcessingPayments());
    }, []);

    const getContent = () => {
        if (incomplete.length === 0 && complete.length === 0) {
            return (
                <EmptyPageLayout
                    title={intl.getMessage('partners_empty_finance')}
                    desc={intl.getMessage('partners_empty_finance_desc')}
                    linkText={intl.getMessage('partners_order_payment')}
                    link={RoutePath.ClientsOrderPayment}
                />
            );
        }

        return (
            <FinanceTable
                incomplete={incomplete}
                complete={complete}
            />
        );
    };

    return (
        <InnerPageLayout header={<Header />} className={theme.content.with_actions}>
            {getContent()}
        </InnerPageLayout>
    );
};

const selectResellerPayments = (store: Store) => ({
    complete: store.reseller.paymentsComplete,
    incomplete: store.reseller.paymentsIncomplete,
});

const selector = createSelector([selectResellerPayments], (payments) => ({ ...payments }));

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Finance);
