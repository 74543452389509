import StatsEntity, { IStatsEntity } from './StatsEntity';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IComparativeExpenses {
    expense_amount: number;
    expense_diff: number;
    expense_entity?: IStatsEntity;
    expense_forecast: number;
}

export default class ComparativeExpenses {
    readonly _expense_amount: number;

    /**
     * Description: Expense over certain period, RUB
     * Example: 64
     */
    get expenseAmount(): number {
        return this._expense_amount;
    }

    static expenseAmountValidate(expenseAmount: number): boolean {
        return typeof expenseAmount === 'number';
    }

    readonly _expense_diff: number;

    /**
     * Description: How much forecast differs from expense, RUB
     * Example: 12
     */
    get expenseDiff(): number {
        return this._expense_diff;
    }

    static expenseDiffValidate(expenseDiff: number): boolean {
        return typeof expenseDiff === 'number';
    }

    readonly _expense_entity: StatsEntity | undefined;

    get expenseEntity(): StatsEntity | undefined {
        return this._expense_entity;
    }

    readonly _expense_forecast: number;

    /**
     * Description: Expense forecast over certain period, RUB
     * Example: 76
     */
    get expenseForecast(): number {
        return this._expense_forecast;
    }

    static expenseForecastValidate(expenseForecast: number): boolean {
        return typeof expenseForecast === 'number';
    }

    constructor(props: IComparativeExpenses) {
        this._expense_amount = props.expense_amount;
        this._expense_diff = props.expense_diff;
        if (props.expense_entity) {
            this._expense_entity = new StatsEntity(props.expense_entity);
        }
        this._expense_forecast = props.expense_forecast;
    }

    serialize(): IComparativeExpenses {
        const data: IComparativeExpenses = {
            expense_amount: this._expense_amount,
            expense_diff: this._expense_diff,
            expense_forecast: this._expense_forecast,
        };
        if (typeof this._expense_entity !== 'undefined') {
            data.expense_entity = this._expense_entity.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            expense_entity: !this._expense_entity ? true : this._expense_entity.validate().length === 0,
            expense_forecast: typeof this._expense_forecast === 'number',
            expense_amount: typeof this._expense_amount === 'number',
            expense_diff: typeof this._expense_diff === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IComparativeExpenses): ComparativeExpenses {
        return new ComparativeExpenses(props);
    }

    readonly keys: { [key: string]: string } = {
        expenseAmount: 'expense_amount',
        expenseDiff: 'expense_diff',
        expenseEntity: 'expense_entity',
        expenseForecast: 'expense_forecast',
        }
;

    mergeDeepWith(props: Partial<ComparativeExpenses>): ComparativeExpenses {
        const updateData: Partial<IComparativeExpenses> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ComparativeExpenses) => {
            const updateKey = this.keys[key] as keyof IComparativeExpenses;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IComparativeExpenses, keyof IComparativeExpenses>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ComparativeExpenses({ ...this.serialize(), ...updateData });
    }
}
