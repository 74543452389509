import qs from 'qs';
import { Locale } from 'Lib/intl';
import { NOTIFICATIONS_STATE, TICKETS_STATE, TICKETS_SUBJECT, RESOURCES_TYPES } from 'Lib/helpers/consts';

const BasicPath = '/:lang';
const pathBuilder = (path: string) => (`${BasicPath}${path}`);

export enum RoutePath {
    AdminLogin = 'AdminLogin',
    AddAccount = 'AddAccount',
    AddFunds = 'AddFunds',
    AuthRecoveryPassword = 'AuthRecoveryPassword',
    AuthRegistration = 'AuthRegistration',
    AuthRegistrationConfirm = 'AuthRegistrationConfirm',
    AuthResetPassword = 'AuthResetPassword',
    Billing = 'Billing',
    Clients = 'Clients',
    ClientsAdd = 'ClientsAdd',
    ClientsAddFunds = 'ClientsAddFunds',
    ClientAddFunds = 'ClientAddFunds',
    ClientsFinance = 'ClientsFinance',
    ClientsOrderPayment = 'ClientsOrderPayment',
    ClientsTransferPayment = 'ClientsTransferPayment',
    Confirm = 'Confirm',
    Console = 'Console',
    CreateTicket = 'CreateTicket',
    CreateTicketWithSubject = 'CreateTicketWithSubject',
    CreateDeleteClientTicket = 'CreateDeleteClientTicket',
    Dashboard = 'Dashboard',
    DnsPtr = 'DnsPtr',
    DnsZones = 'DnsZones',
    DnsZonesRecords = 'DnsZonesRecords',
    EditTrigger = 'EditTrigger',
    Expenses = 'Expenses',
    Documents = 'Documents',
    NewProject = 'NewProject',
    NewServer = 'NewServer',
    NewProjectBackToPrevPage = 'NewProjectBackToPrevPage',
    NewServerFromTenant = 'NewServerFromTenant',
    NewTrigger = 'NewTrigger',
    NotificationRecipients = 'NotificationRecipients',
    Notifications = 'Notifications',
    NotificationUserSettings = 'NotificationUserSettings',
    Onboarding = 'Onboarding',
    OnboardingServer = 'OnboardingServer',
    OnboardingRequisites = 'OnboardingRequisites',
    OnboardingAddFunds = 'OnboardingAddFunds',
    OnboardingAddFundsServer = 'OnboardingAddFundsServer',
    Partner = 'Partner',
    PartnerFinance = 'PartnerFinance',
    PartnerOrderPayment = 'PartnerOrderPayment',
    PartnerStats = 'PartnerStats',
    PartnerTransferPayment = 'PartnerTransferPayment',
    Profile = 'Profile',
    ProfileModal = 'ProfileModal',
    ProfileEditModal = 'ProfileEditModal',
    ProfileHistory = 'ProfileHistory',
    ProfileSettings = 'ProfileSettings',
    ProjectAccess = 'ProjectAccess',
    ProjectBackup = 'ProjectBackup',
    ProjectsList = 'ProjectsList',
    PublicKeys = 'PublicKeys',
    Root = 'Root',
    Server = 'Server',
    ServerChartModal = 'ServerChartModal',
    ServerTariff = 'ServerTariff',
    ServerAccess = 'ServerAccess',
    ServerBackup = 'ServerBackup',
    ServerHistory = 'ServerHistory',
    ServerReinstall = 'ServerReinstall',
    ServerClone = 'ServerClone',
    ServersList = 'ServersList',
    Snapshots = 'Snapshots',
    SocialAuthConfirm = 'SocialAuthConfirm',
    TicketChat = 'TicketChat',
    Tickets = 'Tickets',
    Trial = 'Trial',
    Triggers = 'Triggers',
    Vnc = 'Vnc',
    NotFound = 'NotFound',
    ChangeLog = 'ChangeLog',
}
const Routes: Record<RoutePath, string> = {
    AdminLogin: pathBuilder('/admin/login'),
    AddAccount: pathBuilder('/add_account'),
    AddFunds: pathBuilder('/account/add_funds'),
    AuthRecoveryPassword: pathBuilder('/recovery_password'),
    AuthRegistration: pathBuilder('/registration'),
    AuthRegistrationConfirm: pathBuilder('/confirm/:userId/:code'),
    AuthResetPassword: pathBuilder('/reset_password/:userId/:code'),
    Billing: pathBuilder('/account'),
    Clients: pathBuilder('/clients'),
    ClientsAdd: pathBuilder('/clients/add'),
    ClientsAddFunds: pathBuilder('/clients/funds'),
    ClientAddFunds: pathBuilder('/clients/funds/:clientId'),
    ClientsFinance: pathBuilder('/clients/finance'),
    ClientsOrderPayment: pathBuilder('/clients/order'),
    ClientsTransferPayment: pathBuilder('/clients/transfer'),
    Confirm: pathBuilder('confirm/:clientId/:code'),
    Console: pathBuilder('/servers/:serverId/console'),
    CreateTicket: pathBuilder('/tickets/new'),
    CreateTicketWithSubject: pathBuilder('/tickets/new/:subject'),
    CreateDeleteClientTicket: pathBuilder('/tickets/new/:subject/:email'),
    Dashboard: pathBuilder('/dashboard'),
    DnsPtr: pathBuilder('/dns/ptr'),
    DnsZones: pathBuilder('/dns/zones'),
    DnsZonesRecords: pathBuilder('/dns/zones/:zoneId/records'),
    EditTrigger: pathBuilder('/triggers/edit/:id'),
    Expenses: pathBuilder('/expenses'),
    Documents: pathBuilder('/documents'),
    NewProject: pathBuilder('/projects/new'),
    NewProjectBackToPrevPage: pathBuilder('/projects/new/:back'),
    NewServer: pathBuilder('/servers/new'),
    NewServerFromTenant: pathBuilder('/servers/new/:tenantId'),
    NewTrigger: pathBuilder('/triggers/new'),
    NotificationRecipients: pathBuilder('/notifications/recipients'),
    Notifications: pathBuilder('/notifications/list/:unread'),
    NotificationUserSettings: pathBuilder('/notifications/:userId/settings'),
    Onboarding: pathBuilder('/onboarding'),
    OnboardingAddFunds: pathBuilder('/onboarding/add_funds'),
    OnboardingAddFundsServer: pathBuilder('/onboarding/add_funds/server'),
    OnboardingRequisites: pathBuilder('/onboarding/requisites'),
    OnboardingServer: pathBuilder('/onboarding/server'),
    Partner: pathBuilder('/partner'),
    PartnerFinance: pathBuilder('/partner/finance'),
    PartnerOrderPayment: pathBuilder('/partner/payment/order'),
    PartnerStats: pathBuilder('/partner/stats'),
    PartnerTransferPayment: pathBuilder('/partner/payment/transfer'),
    Profile: pathBuilder('/profile'),
    ProfileEditModal: pathBuilder('/profile/edit/:openEditModal'),
    ProfileHistory: pathBuilder('/profile/history'),
    ProfileModal: pathBuilder('/profile/phone/:openModal'),
    ProfileSettings: pathBuilder('/profile/settings'),
    ProjectAccess: pathBuilder('/project/:tenantId/access'),
    ProjectBackup: pathBuilder('/project/:tenantId/backup'),
    ProjectsList: pathBuilder('/projects'),
    PublicKeys: pathBuilder('/public_keys'),
    Root: BasicPath,
    Server: pathBuilder('/server/:tenantId/:serverId'),
    ServerChartModal: pathBuilder('/server/:tenantId/:serverId/:resourceType'),
    ServerTariff: pathBuilder('/servers/:serverId/tariff'),
    ServerAccess: pathBuilder('/servers/:serverId/access'),
    ServerBackup: pathBuilder('/servers/:serverId/backup'),
    ServerHistory: pathBuilder('/servers/:serverId/history'),
    ServerReinstall: pathBuilder('/servers/:serverId/reinstall'),
    ServerClone: pathBuilder('/servers/:serverId/clone'),
    ServersList: pathBuilder('/servers'),
    Snapshots: pathBuilder('/servers/:serverId/snapshots'),
    SocialAuthConfirm: pathBuilder('/login/social'),
    TicketChat: pathBuilder('/tickets/chat/:id'),
    Tickets: pathBuilder('/tickets/:open'),
    Trial: pathBuilder('/account/trial'),
    Triggers: pathBuilder('/triggers'),
    Vnc: pathBuilder('/servers/:serverId/vnc'),
    NotFound: pathBuilder('/not_found'),
    ChangeLog: pathBuilder('/changelog'),
};

export enum LinkParamsKeys {
    TenantId = 'tenantId',
    ServerId = 'serverId',
    ZoneId = 'zoneId',
    UserId = 'userId',
    ClientId = 'clientId',
    Token = 'code',
    Id='id',
    OpenModal='openModal',
    OpenEditModal='openEditModal',
    Open='open',
    Unread='unread',
    TicketsSubject='subject',
    ResourceType='resourceType',
    Email='email',
    Back='back',
}
export enum QueryParams {
    Error = 'error',
    Success = 'success',
    InvoiceId = 'srv_invoice_id',
    From = 'from',
    Till = 'till',
    Sort = 'sort',
    Tenants = 'tenants',
    Scroll = 'scroll',
    Order = 'order',
    TenantId = 'tenantId',
    ServerId = 'serverId',
    ShowBanner = 'showBanner',
}

export type LinkParams = Partial<{
    [LinkParamsKeys.TenantId]: number | string;
    [LinkParamsKeys.ServerId]: number | string;
    [LinkParamsKeys.ZoneId]: number | string;
    [LinkParamsKeys.UserId]: number | string;
    [LinkParamsKeys.Token]: number | string;
    [LinkParamsKeys.ClientId]: number | string;
    [LinkParamsKeys.Id]: number | string;
    [LinkParamsKeys.OpenModal]: boolean;
    [LinkParamsKeys.OpenEditModal]: boolean;
    [LinkParamsKeys.Open]: TICKETS_STATE;
    [LinkParamsKeys.Unread]: NOTIFICATIONS_STATE;
    [LinkParamsKeys.ResourceType]: RESOURCES_TYPES;
    [LinkParamsKeys.Back]: true;
    [LinkParamsKeys.TicketsSubject]: TICKETS_SUBJECT;
    [LinkParamsKeys.Email]: string;
}>;

export type QueryParamsType = Partial<Record<QueryParams, string | number | boolean>>;

const linkPathBuilder = (
    lang: Locale,
    route: RoutePath,
    params?: LinkParams,
    query?: QueryParamsType,
) => {
    let path = Routes[route].replace(BasicPath, `/${lang}`);
    if (params) {
        Object.keys(params).forEach((key) => {
            path = path.replace(`:${key}`, String(params[key as LinkParamsKeys]));
        });
    }
    if (query) {
        path += `?${qs.stringify(query)}`;
    }
    return path;
};

export enum GuestRoutePath {
    Kb = 'Kb',
    KbArticle = 'KbArticle',
    Documents = 'Documents',
    Tariffs = 'Tariffs',
    Backups = 'Backups',
    Monitoring = 'Monitoring',
    DataCenter = 'DataCenter',
    ECommerce = 'ECommerce',
    Developers = 'Developers',
    Administrators = 'Administrators',
    Studios = 'Studios',
    About = 'About',
    Payment = 'Payment',
    Partner = 'Partner',
    Network = 'Network',
    Welcome = 'Welcome',
}

const GuestRoutes: Record<GuestRoutePath, string> = {
    Kb: pathBuilder('/kb/index.html'),
    KbArticle: pathBuilder('/kb/:slug.html'),
    Documents: '/documents/:document.pdf',
    Tariffs: pathBuilder('/tariffs.html'),
    Backups: pathBuilder('/backups.html'),
    Monitoring: pathBuilder('/monitoring.html'),
    DataCenter: pathBuilder('/data-center.html'),
    ECommerce: pathBuilder('/e-commerce.html'),
    Developers: pathBuilder('/developers.html'),
    Administrators: pathBuilder('/administrators.html'),
    Studios: pathBuilder('/studios.html'),
    About: pathBuilder('/about.html'),
    Payment: pathBuilder('/payment.html'),
    Partner: pathBuilder('/partner.html'),
    Network: pathBuilder('/network.html'),
    Welcome: pathBuilder('/welcome.html'),
};

export enum GuestLinkParamsKeys {
    Slug = 'slug',
    Document = 'document',
}

export type GuestLinkParams = Partial<{
    [GuestLinkParamsKeys.Slug]: string;
    [GuestLinkParamsKeys.Document]: string;
}>;

const externalLinkBuilder = (
    lang: Locale,
    route: GuestRoutePath,
    params?: GuestLinkParams,
    query?: string,
) => {
    let path = GuestRoutes[route].replace(BasicPath, `/${lang}`);

    if (params) {
        Object.keys(params).forEach((key) => {
            path = path.replace(`:${key}`, String(params[key as GuestLinkParamsKeys]));
        });
    }

    const url = new URL(path, WEBSITE_GUEST_BASE_URL);
    if (query) {
        url.search = query;
    }
    return url.href;
};

export { Routes, linkPathBuilder, externalLinkBuilder };
