import React, { FC, useState, useEffect } from 'react';
import { Slider, Input, Tooltip } from 'antd';
import cn from 'classnames';

import { toGb, toMb } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

import s from './Tariff.module.pcss';

export interface TariffRowProps {
    data: {
        label: string;
        min: number;
        max: number;
        step: number;
        desc?: string;
        getTransMessage?: (value: number) => string;
        value: number;
        setValue: (value: number) => void;
        type: string;
        isFloatable?: boolean;
        noSlider?: string;
    };
    newServer?: boolean;
}

const invalidFloatableValue = (v: string) => {
    return v.endsWith('.')
        || v.endsWith(',')
        || (v.endsWith('0') && (v.includes('.') || v.includes(',')));
};

const TariffRow: FC<TariffRowProps> = ({ data, newServer }) => {
    const {
        label, min, max, desc, step, getTransMessage, value, setValue, type, isFloatable, noSlider,
    } = data;

    const [localValue, setLocalValue] = useState(value.toString());
    useEffect(() => {
        if (value > max || value < min) {
            return;
        }
        setLocalValue(value.toString());
    }, [value]);
    const getValue = () => {
        if (getTransMessage) {
            return getTransMessage(value);
        }

        return null;
    };

    const onChange = (v: number) => {
        setValue(v);
    };

    const onBlur = () => {
        let newValue = value;
        if (localValue.length > 0 && invalidFloatableValue(localValue)) {
            newValue = parseFloat(`${localValue}0`.replace(',', '.'));
        }
        if (newValue < min || !newValue || Number.isNaN(newValue)) {
            newValue = min;
        } else if (newValue > max) {
            newValue = max;
        } else if (!isFloatable && newValue % step !== 0) {
            const remainder = newValue % step;
            newValue = remainder > step / 2 ? newValue + step - remainder : newValue - remainder;
        } else if (isFloatable) {
            const mbValue = Math.round(toMb(newValue));
            const mbStep = Math.round(toMb(step));
            if (mbValue % mbStep !== 0) {
                const remainder = mbValue % mbStep;
                const counted = toGb(
                    remainder > mbStep / 2 ? mbValue + mbStep - remainder : mbValue - remainder,
                );
                newValue = counted;
            } else {
                const newValueTail = String(newValue).split('.')[1];
                // 4 - because we * 1000, then all numbers after e-4 should be rounded
                if (newValueTail?.length > 4) {
                    newValue = parseFloat(newValue.toFixed(4));
                }
            }
        }
        setValue(newValue);
        setLocalValue(newValue.toString());
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: v } = e.target;
        setLocalValue(v);
        if (!v) {
            return;
        }
        if (!(isFloatable && invalidFloatableValue(v))) {
            let parsedValue = parseFloat(v.replace(',', '.'));
            if (!isFloatable) {
                parsedValue = Math.round(parsedValue);
            }
            if (parsedValue >= min) {
                setValue(parsedValue);
            }
        }
    };

    return (
        <div
            className={cn(s.row, s.row_slider, { [s.row_new]: newServer })}
        >
            <div className={s.wrap}>
                <div className={s.left}>
                    <div className={s.label}>
                        {label}

                        {desc && (
                            <Tooltip
                                placement="right"
                                overlayClassName="tooltip tooltip_large"
                                title={desc}
                            >
                                <div
                                    className={cn(
                                        theme.Badge.badge,
                                        theme.Badge.question,
                                        s.badge,
                                    )}
                                />
                            </Tooltip>
                        )}
                    </div>

                    <div className={cn(s.slider, { [s.no_slider]: !!noSlider })} data-slider={type}>
                        {!noSlider ? (
                            <Slider
                                min={min}
                                max={max}
                                step={step || 1}
                                className="slider"
                                value={Number.isNaN(value) ? min : value}
                                onChange={onChange}
                                tooltipVisible={false}
                            />
                        ) : (
                            noSlider
                        )}
                    </div>
                </div>

                <Input
                    type="text"
                    disabled={!!noSlider}
                    value={localValue}
                    className={cn('input', s.input)}
                    onBlur={onBlur}
                    onChange={onInputChange}
                    suffix={getValue()}
                />
            </div>
        </div>
    );
};

export default TariffRow;
