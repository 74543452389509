import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Row, Col } from 'antd';

import { getResellerClients } from 'Actions/reseller';
import { resellerLogin } from 'Actions/user';
import { useIntl, Select, Option } from 'Common';
import Account from 'Entities/Account';
import Profile from 'Entities/Profile';
import AccountDetails from 'Entities/AccountDetails';
import ResellerClient from 'Entities/ResellerClient';
import { roundNumber } from 'Lib/helpers/helpers';
import { Store } from 'Store';

import s from './SystemSettings.module.pcss';
import ThemeSelector from './ThemeSelector';

interface SystemSettingsProps {
    account: Account;
    details: AccountDetails;
    clients: Store['reseller']['clients'];
    profile: Profile;
}

const SystemSettings: FC<SystemSettingsProps> = ({
    account, details, clients, profile,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [client, setClient] = useState<number>(account?.clientId);
    useEffect(() => {
        if (profile?.reseller || MAIN_TOKEN) {
            dispatch(getResellerClients([MAIN_TOKEN || AUTH_TOKEN]));
        }
    }, [profile]);

    if (!account || !details || !clients) {
        return null;
    }
    const clientsAvailable = clients?.length > 0;
    const balanceRound = roundNumber(details.balance);

    const onClientChange = (value: number) => {
        if (value !== account.clientId) {
            setClient(value);
            dispatch(resellerLogin([value]));
        }
    };

    const getClientMessage = (c: ResellerClient) => {
        const { balance, accountBlocked, emailNotConfirmed } = c;

        if (accountBlocked) {
            return intl.getMessage('account_blocked');
        }

        if (emailNotConfirmed) {
            return intl.getMessage('email_not_confirmed');
        }

        return intl.getMessage('money', { value: balance });
    };

    return (
        <Row gutter={[24, 0]}>
            <Col span={24} lg={13} xl={12}>
                <div className={s.title}>
                    {intl.getMessage('settings_account_system')}
                </div>

                {clientsAvailable && (
                    <div className={s.select}>
                        <div className={s.label}>
                            {intl.getMessage('account')}
                        </div>
                        <Select
                            id="formType"
                            size="medium"
                            value={client}
                            onChange={onClientChange}
                            className="select_dm-cloud"
                            block
                        >
                            <Option value={account.clientId}>
                                {account.email}
                                <span className={s.funds}>
                                    {intl.getMessage('money', { value: balanceRound })}
                                </span>
                            </Option>
                            {clients.map((c) => (
                                <Option
                                    value={c.clientId}
                                    key={c.clientId}
                                    disabled={c.accountBlocked || c.emailNotConfirmed}
                                >
                                    {c.email}
                                    <span className={s.funds}>
                                        {getClientMessage(c)}
                                    </span>
                                </Option>
                            ))}
                        </Select>
                    </div>
                )}

                <ThemeSelector />
            </Col>
        </Row>
    );
};

interface SystemSettingsContainerProps {
    account: Account | null;
    details: AccountDetails | null;
    clients: Store['reseller']['clients'];
    profile: Profile | null;
}

const SystemSettingsContainer: FC<SystemSettingsContainerProps> = ({
    account, details, clients, profile,
}) => {
    if (!account || !details || !profile) {
        return null;
    }
    return (
        <SystemSettings
            account={account}
            details={details}
            clients={clients}
            profile={profile}
        />
    );
};

const selectAccountDetails = (store: Store) => store.account.details;
const selectAccount = (store: Store) => store.account.account;
const selectResellerClients = (store: Store) => store.reseller.clients;
const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector(
    [selectAccountDetails, selectAccount, selectResellerClients, selectProfile],
    (details, account, clients, profile) => ({
        details, account, clients, profile,
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(SystemSettingsContainer);
