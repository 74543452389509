import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link, Select, Option } from 'Common';
import { NewServerFormValues } from 'Lib/helpers/newServer';
import { RoutePath } from 'Lib/helpers/routes';
import Tenant from 'Entities/Tenant';
import theme from 'Lib/theme';

import s from './FormGroup.module.pcss';

interface TenantSelectProps {
    tenants: Tenant[];
    values: NewServerFormValues;
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
}

const TenantSelect: FC<TenantSelectProps> = ({
    tenants,
    values,
    setFieldValue,
}) => {
    const intl = useIntl();

    return (
        <Select
            value={values.tenant_id}
            onChange={(e) => setFieldValue('tenant_id', e)}
            id="tenant_id"
            size="big"
            className={s.tenant}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Link
                        to={RoutePath.NewProjectBackToPrevPage}
                        props={{ back: true }}
                        className={cn(theme.link.link, theme.link.option)}
                    >
                        {intl.getMessage('projectlist_create')}
                    </Link>
                </>
            )}
            block
        >
            {tenants.map((t) => (
                <Option key={t.id} value={t.id} className="option option_server">
                    {t.description}
                </Option>
            ))}
        </Select>
    );
};

export default TenantSelect;
