// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerCloneAsReseller {
    from_client_id: number;
    name: string;
    snapshot_id?: number;
    to_client_id: number;
    to_tenant_id: number;
}

export default class ServerCloneAsReseller {
    readonly _from_client_id: number;

    /**
     * Description: Source client - reseller or sub-client. Expected to be the same as requester
     * Example: 185816
     */
    get fromClientId(): number {
        return this._from_client_id;
    }

    static fromClientIdValidate(fromClientId: number): boolean {
        return typeof fromClientId === 'number';
    }

    readonly _name: string;

    /**
     * Description: Clone server name
     * Example: Copy dev_backend
     */
    get name(): string {
        return this._name;
    }

    static get nameMinLength() {
        return 0;
    }

    static get nameMaxLength() {
        return 70;
    }

    static nameValidate(name: string): boolean {
        return (name.length > 0) && (name.length <= 70);
    }

    readonly _snapshot_id: number | undefined;

    /**
     * Description: Snapshot identifier
     * Example: 681
     */
    get snapshotId(): number | undefined {
        return this._snapshot_id;
    }

    readonly _to_client_id: number;

    /**
     * Description: Destination client - reseller or sub-client
     * Example: 185816
     */
    get toClientId(): number {
        return this._to_client_id;
    }

    static toClientIdValidate(toClientId: number): boolean {
        return typeof toClientId === 'number';
    }

    readonly _to_tenant_id: number;

    /**
     * Description: Destination tenant
     * Example: 19935
     */
    get toTenantId(): number {
        return this._to_tenant_id;
    }

    static toTenantIdValidate(toTenantId: number): boolean {
        return typeof toTenantId === 'number';
    }

    constructor(props: IServerCloneAsReseller) {
        this._from_client_id = props.from_client_id;
        this._name = props.name.trim();
        if (typeof props.snapshot_id === 'number') {
            this._snapshot_id = props.snapshot_id;
        }
        this._to_client_id = props.to_client_id;
        this._to_tenant_id = props.to_tenant_id;
    }

    serialize(): IServerCloneAsReseller {
        const data: IServerCloneAsReseller = {
            from_client_id: this._from_client_id,
            name: this._name,
            to_client_id: this._to_client_id,
            to_tenant_id: this._to_tenant_id,
        };
        if (typeof this._snapshot_id !== 'undefined') {
            data.snapshot_id = this._snapshot_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: (this._name.length > 0) && (this._name.length <= 70),
            snapshot_id: !this._snapshot_id ? true : typeof this._snapshot_id === 'number',
            from_client_id: typeof this._from_client_id === 'number',
            to_client_id: typeof this._to_client_id === 'number',
            to_tenant_id: typeof this._to_tenant_id === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerCloneAsReseller): ServerCloneAsReseller {
        return new ServerCloneAsReseller(props);
    }

    readonly keys: { [key: string]: string } = {
        fromClientId: 'from_client_id',
        name: 'name',
        snapshotId: 'snapshot_id',
        toClientId: 'to_client_id',
        toTenantId: 'to_tenant_id',
        }
;

    mergeDeepWith(props: Partial<ServerCloneAsReseller>): ServerCloneAsReseller {
        const updateData: Partial<IServerCloneAsReseller> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerCloneAsReseller) => {
            const updateKey = this.keys[key] as keyof IServerCloneAsReseller;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerCloneAsReseller, keyof IServerCloneAsReseller>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerCloneAsReseller({ ...this.serialize(), ...updateData });
    }
}
