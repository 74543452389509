import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, Button } from 'Common';
import Server from 'Entities/Server';
import ServerPasswordUpdate from 'Entities/ServerPasswordUpdate';
import theme from 'Lib/theme';

import s from './ChangePassword.module.pcss';
import ChangePasswordForm from './ChangePasswordForm';

interface ChangePassProps {
    server: Server;
}

const ChangePass: FC<ChangePassProps> = ({ server }) => {
    const intl = useIntl();
    const [formVisible, setFormVisible] = useState(false);
    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.plate,
                theme.card.rounded,
                s.changePass,
            )}
        >
            <div className={s.inner}>
                <div className={s.title}>
                    {intl.getMessage('server_change_pass_title')}
                </div>
                <div className={s.info}>
                    {intl.getMessage('server_change_pass_info', { min: ServerPasswordUpdate.passwordMinLength })}
                </div>
                {!formVisible ? (
                    <Button
                        onClick={() => setFormVisible(true)}
                        type="outlined"
                        size="medium"
                    >
                        {intl.getMessage('change_password')}
                    </Button>
                ) : (<ChangePasswordForm server={server} close={() => setFormVisible(false)} />)}
            </div>
        </div>
    );
};

export default ChangePass;
