import React, { FC } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import cn from 'classnames';

import { ListLayout, useIntl } from 'Common';
import Server from 'Entities/Server';
import { Store } from 'Store';
import theme from 'Lib/theme';

import ServerPtr from './ServerPtr';

interface ProjectOwnProps {
    name: string;
    id: number;
}

interface ProjectStoreProps {
    servers: Server[];
}

type ProjectProps = ProjectOwnProps & ProjectStoreProps;

const Project: FC<ProjectProps> = ({ name, servers }) => {
    const intl = useIntl();
    if (servers.length < 1) {
        return null;
    }
    let ipNum = 0;
    servers.forEach((s) => {
        ipNum += s.ipCount;
    });
    if (ipNum === 0) {
        return null;
    }
    return (
        <ListLayout
            title={name}
            length={ipNum}
            lengthTitle={intl.getPlural('ptr_records', ipNum)}
        >
            <div
                className={cn(
                    theme.card.labels,
                    theme.card.labels_withActions,
                    theme.card.labels_ptr,
                )}
            >
                <div className={theme.card.label}>
                    {intl.getMessage('server')}
                </div>
                <div className={theme.card.label}>
                    {intl.getMessage('ptr_public_ip')}
                </div>
            </div>
            {servers.map((server) => (
                <ServerPtr key={server.id} id={server.id} />
            ))}
        </ListLayout>
    );
};

const selector = (store: Store, ownProps: ProjectOwnProps) => {
    const servers: Server[] = [];
    store.server.forEach((s) => {
        if (s.tenantId === ownProps.id) {
            servers.push(s);
        }
    });
    return servers;
};
const selectServers = createSelector([selector], (list) => list);

const mapStoreToProps = (store: Store, ownProps: ProjectOwnProps) => ({
    servers: selectServers(store, ownProps),
});

export default connect(mapStoreToProps)(Project);
