import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getPartnerCompletePayments, getPartnerProcessingPayments } from 'Actions/partner';
import { useIntl, InnerPageLayout, EmptyPageLayout, FinanceTable } from 'Common';
import { useOffsetLoader, useTitle } from 'Hooks';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import { Store } from 'Store';

import Header from './Header';
import GeneralAmount from './GeneralAmount';

const selectPartnerPayments = (store: Store) => ({
    complete: store.partner.paymentsComplete,
    incomplete: store.partner.paymentsIncomplete,
    details: store.partner.details,
});

const selector = createSelector([selectPartnerPayments], (payments) => ({ ...payments }));

const Finance: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    useTitle(intl.getMessage('partner_finance_page_title'));
    const { complete, incomplete, details } = useSelector(selector);

    useEffect(() => {
        dispatch(getPartnerProcessingPayments());
    }, []);

    useOffsetLoader(complete, (offset: number, limit: number) => {
        return getPartnerCompletePayments([offset * limit, limit]);
    });

    if (!incomplete || !complete) {
        return null;
    }

    const getContent = () => {
        if (incomplete.length === 0 && complete.length === 0) {
            return (
                <EmptyPageLayout
                    title={intl.getMessage('partners_empty_finance')}
                    desc={intl.getMessage('partners_empty_finance_desc')}
                    linkText={intl.getMessage('partners_order_payment')}
                    link={RoutePath.PartnerOrderPayment}
                />
            );
        }

        return (
            <>
                {typeof details?.totalPaid === 'number' && (
                    <GeneralAmount totalPaid={details.totalPaid} />
                )}
                <FinanceTable
                    incomplete={incomplete}
                    complete={complete}
                />
            </>
        );
    };

    return (
        <InnerPageLayout
            header={<Header />}
            className={theme.content.with_actions}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

export default Finance;
