import Snapshot from 'Entities/Snapshot';

interface ISnapshotTreeBuilder {
    snapshots: Snapshot[];
}
export interface SnapshotTreeElem {
    snap: Snapshot;
    children: SnapshotTreeElem[];
}

export default class SnapshotTreeBuilder {
    snapshots: Snapshot[];

    alreadyIn: number[] = [];

    snapTree: SnapshotTreeElem[] = [];

    constructor(props: ISnapshotTreeBuilder) {
        this.snapshots = props.snapshots.sort(
            (a, b) => {
                // parsing time for sorting in ascend order
                if (a.timeAdded < b.timeAdded) {
                    return -1;
                }
                if (a.timeAdded > b.timeAdded) {
                    return 1;
                }
                return 0;
            },
        );
    }

    private childReducer = (
        parentChildren: SnapshotTreeElem[],
    ) => (st: SnapshotTreeElem[], snap: Snapshot) => {
        if (this.alreadyIn.indexOf(snap.id) + 1) {
            return st;
        }
        this.alreadyIn.push(snap.id);

        const array: SnapshotTreeElem[] = [];
        const filtered = this.snapshots
            .filter((sf) => sf.parentSnapshotId === snap.id);
        const children = filtered.slice(1).reduce(this.childReducer(array), array);
        const spliced = filtered.splice(0).reduce(this.parentReducer, []);

        parentChildren.push(...spliced);
        st.push({ snap, children });
        return st;
    };

    private parentReducer = (st: SnapshotTreeElem[], snap: Snapshot) => {
        if (this.alreadyIn.indexOf(snap.id) + 1) {
            return st;
        }
        this.alreadyIn.push(snap.id);

        const array: SnapshotTreeElem[] = [];
        const filtered = this.snapshots
            .filter((sf) => sf.parentSnapshotId === snap.id);
        const children = filtered.slice(1).reduce(this.childReducer(array), array);

        st.push({ snap, children: children.reverse() });
        return st;
    };

    public toTree = () => {
        this.alreadyIn = [];
        this.snapTree = [];
        this.snapshots.reduce(this.parentReducer, this.snapTree);
        return this.snapTree;
    };
}
