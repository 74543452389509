import React, { FC, LegacyRef } from 'react';
import { Formik, FormikHelpers } from 'formik';
import HCaptcha from '@hcaptcha/react-hcaptcha';

import { useTitle } from 'Hooks';
import { Button, Input, Link, useIntl, Loader } from 'Common';
import LoginForm from 'Entities/LoginForm';
import { RoutePath } from 'Lib/helpers/routes';
import { emailValidate } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import s from './Auth.module.pcss';
import Captcha from './Captcha';
import { LoginFormValues } from './Login';

interface LoginFormProps {
    formOnSubmit: (values: LoginFormValues, formikHelpers: FormikHelpers<LoginFormValues>) => void;
    captchaRef: LegacyRef<HCaptcha>;
}

const LoginFormContainer: FC<LoginFormProps> = React.memo(({
    formOnSubmit,
    captchaRef,
}) => {
    const intl = useIntl();
    useTitle(intl.getMessage('login_page_title'));

    return (
        <Formik
            initialValues={{ username: '', password: '', captcha: '', remember: true }}
            onSubmit={formOnSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                isSubmitting,
                submitForm,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={s.email}>
                        <Input
                            noValidationIcon
                            autoComplete="email"
                            error={!!errors.username}
                            name="username"
                            onChange={(e) => setFieldValue('username', e)}
                            placeholder={intl.getMessage('email')}
                            size="large"
                            type="email"
                            validate={(str) => (
                                LoginForm.usernameValidate(str) && emailValidate(str)
                            )}
                            value={values.username}
                        />
                    </div>

                    <div className={s.password}>
                        <Input
                            noValidationIcon
                            autoComplete="current-password"
                            error={!!errors.password}
                            errorMessage={errors.password}
                            name="password"
                            onChange={(e) => setFieldValue('password', e)}
                            placeholder={intl.getMessage('password')}
                            size="large"
                            type="password"
                            validate={LoginForm.passwordValidate}
                            value={values.password}
                            wrapperClassName={theme.form.group_last}
                        />
                    </div>
                    <Captcha
                        invisible
                        captchaRef={captchaRef}
                        setValue={(e) => {
                            setFieldValue('captcha', e);
                            submitForm();
                        }}
                    />
                    <div className={s.actions}>
                        <Button
                            size="medium"
                            type="primary"
                            htmlType="submit"
                            className={theme.button.with_icon}
                            disabled={isSubmitting || !touched}
                        >
                            {isSubmitting && (
                                <Loader
                                    className={s.loading}
                                    circle
                                    inButton
                                />
                            )}
                            {intl.getMessage('login_button')}
                        </Button>

                        <Link
                            to={RoutePath.AuthRecoveryPassword}
                            className={s.link}
                        >
                            {intl.getMessage('login_recovery_link')}
                        </Link>
                    </div>
                </form>
            )}
        </Formik>
    );
});

export default LoginFormContainer;
