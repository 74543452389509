import { EntityType } from './EntityType';
import ServerInfo, { IServerInfo } from './ServerInfo';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IStatsEntity {
    entity_type: EntityType;
    server_info?: IServerInfo;
}

export default class StatsEntity {
    readonly _entity_type: EntityType;

    get entityType(): EntityType {
        return this._entity_type;
    }

    static entityTypeValidate(entityType: EntityType): boolean {
        return Object.keys(EntityType).includes(entityType);
    }

    readonly _server_info: ServerInfo | undefined;

    get serverInfo(): ServerInfo | undefined {
        return this._server_info;
    }

    constructor(props: IStatsEntity) {
        this._entity_type = props.entity_type;
        if (props.server_info) {
            this._server_info = new ServerInfo(props.server_info);
        }
    }

    serialize(): IStatsEntity {
        const data: IStatsEntity = {
            entity_type: this._entity_type,
        };
        if (typeof this._server_info !== 'undefined') {
            data.server_info = this._server_info.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            server_info: !this._server_info ? true : this._server_info.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IStatsEntity): StatsEntity {
        return new StatsEntity(props);
    }

    readonly keys: { [key: string]: string } = {
        entityType: 'entity_type',
        serverInfo: 'server_info',
        }
;

    mergeDeepWith(props: Partial<StatsEntity>): StatsEntity {
        const updateData: Partial<IStatsEntity> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof StatsEntity) => {
            const updateKey = this.keys[key] as keyof IStatsEntity;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IStatsEntity, keyof IStatsEntity>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new StatsEntity({ ...this.serialize(), ...updateData });
    }
}
