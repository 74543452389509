import generatePass from 'password-generator';
import qs from 'qs';

import { BackupPolicyFrequency } from 'Entities/BackupPolicyFrequency';
import Tenant from 'Entities/Tenant';
import ServerSetup, { IServerSetup } from 'Entities/ServerSetup';
import { DistributionFamily } from 'Entities/DistributionFamily';
import ServerDistribution from 'Entities/ServerDistribution';
import Server from 'Entities/Server';
import { passwordValidate } from './utils';
import { SERVER_QUERY_PARAMS } from './consts';

export interface NewServerFormValues extends IServerSetup {
    tenant_id: number;
    distribution_name: DistributionFamily;
    monthly: boolean;
    noChangeIpCount: boolean;
    code?: string;
    qsIsFixed: boolean;
    haveQsParams: boolean;
    parsedSearch: Partial<IServerSetup>;
    prevValues?: NewServerFormValues;
}

export default class NewServerCreateHelper {
    static getInitialValues(
        tenants: Tenant[],
        distr: ServerDistribution[],
        initializer?: {
            tenantId?: string;
            server?: Server;
        },
        searchInitial: qs.ParsedQs = {},
    ): NewServerFormValues {
        const tenantId = initializer?.tenantId || initializer?.server?.tenantId;
        const server = initializer?.server;

        let selectedDist = server?.distribution.id || distr[0].id;
        if (searchInitial[SERVER_QUERY_PARAMS.WINDOWS] === 'true') {
            selectedDist = distr.find((d) => d.type === DistributionFamily.WINDOWS)!.id;
        }
        const currentDistribution = distr.find((d) => d.id === selectedDist)
            || server?.distribution;

        return {
            backup_frequency: server?.backupPolicy.frequency || BackupPolicyFrequency.HOUR_72,
            backup_quantity: server?.backupPolicy.quantity || 1,
            code: '',
            distribution_id: selectedDist,
            distribution_name: currentDistribution?.type || DistributionFamily.UBUNTU,
            monthly: true,

            cpu_cores: server?.cpuCores || 1,
            disk_gib: server?.diskGib || 16,
            memory_mib: server?.memoryMib || 512,
            ip_count: 1,
            tariff_id: server?.tariff?.tariffId || -1,

            parsedSearch: {
                cpu_cores: Number(searchInitial[SERVER_QUERY_PARAMS.CPU]),
                disk_gib: Number(searchInitial[SERVER_QUERY_PARAMS.DISK]),
                memory_mib: Number(searchInitial[SERVER_QUERY_PARAMS.MEMORY]),
                ip_count: searchInitial[SERVER_QUERY_PARAMS.IP]
                    && !Number.isNaN(Number(searchInitial[SERVER_QUERY_PARAMS.IP]))
                    ? Number(searchInitial[SERVER_QUERY_PARAMS.IP]) : 1,
            },

            name: server?.name || searchInitial?.name as string || '',
            noChangeIpCount: !!server,
            open_support_access: server?.supportAccess || false,
            password: '',
            public_key_ids: server?.publicKeys || [],
            send_password: true,
            tenant_id: tenantId ? Number(tenantId) : tenants[0]?.id,

            qsIsFixed: searchInitial[SERVER_QUERY_PARAMS.FIXED] === 'true',
            haveQsParams: Object.keys(searchInitial).length > 0,
        };
    }

    static readonly defaultStep = 1;

    static countMaxDisk = (
        memory: number,
        modificator: number,
        maxDisk: number,
        minDisk: number,
    ) => {
        const currentMaxDisk = (memory * modificator) / 1024;

        if (currentMaxDisk > maxDisk) {
            return maxDisk;
        }

        if (currentMaxDisk < minDisk) {
            return minDisk;
        }

        return currentMaxDisk;
    };

    static countMinMemory = (disk: number, modificator: number, step: number) => {
        return (Math.ceil(((disk * 1024) / step) / modificator)) * step;
    };

    static availableBackupQuantities = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    static readonly passGenRegExp = /[A-Za-z\d\?\-\!\.\(\)\~\[\]?]/;

    static readonly passwordMinLength = ServerSetup.passwordMinLength;

    static readonly generatedPasswordMaxLength = 16;

    static generatePassword = () => {
        const passwordLength = 10;
        let password = '';
        while (!passwordValidate(password, NewServerCreateHelper.passwordMinLength)) {
            password = generatePass(passwordLength, false, NewServerCreateHelper.passGenRegExp);
        }
        return password;
    };
}
