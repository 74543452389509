// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IIndividualInfo {
    address?: string;
    first_name: string;
    last_name: string;
}

export default class IndividualInfo {
    readonly _address: string | undefined;

    /**
     * Description: Payer registration address, if known
     * Example: 123456, Россия, Москва, ул. Правды, д.11, корп.11
     */
    get address(): string | undefined {
        return this._address;
    }

    readonly _first_name: string;

    /**
     * Description: Payer first name
     * Example: ЕЛЕНА
     */
    get firstName(): string {
        return this._first_name;
    }

    static firstNameValidate(firstName: string): boolean {
        return typeof firstName === 'string' && !!firstName.trim();
    }

    readonly _last_name: string;

    /**
     * Description: Payer last name
     * Example: КАРЦЕВА
     */
    get lastName(): string {
        return this._last_name;
    }

    static lastNameValidate(lastName: string): boolean {
        return typeof lastName === 'string' && !!lastName.trim();
    }

    constructor(props: IIndividualInfo) {
        if (typeof props.address === 'string') {
            this._address = props.address.trim();
        }
        this._first_name = props.first_name.trim();
        this._last_name = props.last_name.trim();
    }

    serialize(): IIndividualInfo {
        const data: IIndividualInfo = {
            first_name: this._first_name,
            last_name: this._last_name,
        };
        if (typeof this._address !== 'undefined') {
            data.address = this._address;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            first_name: typeof this._first_name === 'string' && !!this._first_name.trim(),
            last_name: typeof this._last_name === 'string' && !!this._last_name.trim(),
            address: !this._address ? true : typeof this._address === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IIndividualInfo): IndividualInfo {
        return new IndividualInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        address: 'address',
        firstName: 'first_name',
        lastName: 'last_name',
        }
;

    mergeDeepWith(props: Partial<IndividualInfo>): IndividualInfo {
        const updateData: Partial<IIndividualInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof IndividualInfo) => {
            const updateKey = this.keys[key] as keyof IIndividualInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IIndividualInfo, keyof IIndividualInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new IndividualInfo({ ...this.serialize(), ...updateData });
    }
}
