import ExpenseSection, { IExpenseSection } from './ExpenseSection';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IExpenseSummary {
    expenses: IExpenseSection[];
    overall_amount: number;
    report_time?: string;
    report_time_millis: number;
    summary_id: number;
}

export default class ExpenseSummary {
    readonly _expenses: ExpenseSection[];

    /** */
    get expenses(): ExpenseSection[] {
        return this._expenses;
    }

    readonly _overall_amount: number;

    /**
     * Description: Overall expended amount, for report date, RUB
     * Example: 45.34
     */
    get overallAmount(): number {
        return this._overall_amount;
    }

    static overallAmountValidate(overallAmount: number): boolean {
        return typeof overallAmount === 'number';
    }

    readonly _report_time: string | undefined;

    /** */
    get reportTime(): string | undefined {
        return this._report_time;
    }

    readonly _report_time_millis: number;

    /**
     * Description: Expense date aligned by whole Date, UTC millis, or zero if date is null
     * Example: 1588280400000
     */
    get reportTimeMillis(): number {
        return this._report_time_millis;
    }

    static reportTimeMillisValidate(reportTimeMillis: number): boolean {
        return typeof reportTimeMillis === 'number';
    }

    readonly _summary_id: number;

    /** */
    get summaryId(): number {
        return this._summary_id;
    }

    static summaryIdValidate(summaryId: number): boolean {
        return typeof summaryId === 'number';
    }

    constructor(props: IExpenseSummary) {
        this._expenses = props.expenses.map((p) => new ExpenseSection(p));
        this._overall_amount = props.overall_amount;
        if (typeof props.report_time === 'string') {
            this._report_time = props.report_time.trim();
        }
        this._report_time_millis = props.report_time_millis;
        this._summary_id = props.summary_id;
    }

    serialize(): IExpenseSummary {
        const data: IExpenseSummary = {
            expenses: this._expenses.map((p) => p.serialize()),
            overall_amount: this._overall_amount,
            report_time_millis: this._report_time_millis,
            summary_id: this._summary_id,
        };
        if (typeof this._report_time !== 'undefined') {
            data.report_time = this._report_time;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            summary_id: typeof this._summary_id === 'number',
            report_time: !this._report_time ? true : typeof this._report_time === 'string',
            report_time_millis: typeof this._report_time_millis === 'number',
            overall_amount: typeof this._overall_amount === 'number',
            expenses: this._expenses.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IExpenseSummary): ExpenseSummary {
        return new ExpenseSummary(props);
    }

    readonly keys: { [key: string]: string } = {
        expenses: 'expenses',
        overallAmount: 'overall_amount',
        reportTime: 'report_time',
        reportTimeMillis: 'report_time_millis',
        summaryId: 'summary_id',
        }
;

    mergeDeepWith(props: Partial<ExpenseSummary>): ExpenseSummary {
        const updateData: Partial<IExpenseSummary> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ExpenseSummary) => {
            const updateKey = this.keys[key] as keyof IExpenseSummary;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IExpenseSummary, keyof IExpenseSummary>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ExpenseSummary({ ...this.serialize(), ...updateData });
    }
}
