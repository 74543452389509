import React, { ReactElement } from 'react';
import cn from 'classnames';

import { Icon } from 'Common';

import s from './CustomSelect.module.pcss';

interface CustomOptionProps<T> {
    value: T;
    label: ReactElement;
    onSelect: (v: T) => void;
    currentValue: T;
    disabled: boolean;
    withAction: boolean | undefined;
}

const CustomOption = <T,>({
    value,
    label,
    onSelect,
    currentValue,
    disabled,
    withAction,
}: CustomOptionProps<T>) => {
    const checkIcon = currentValue === value;

    return (
        <div
            role="button"
            className={cn(s.option, { [s.disabled]: disabled })}
            onClick={() => !disabled && onSelect(value)}
            tabIndex={0}
        >
            {label}
            {!withAction && checkIcon && (
                <div className={s.suffixIcon}>
                    <Icon icon="check" className={s.checkIcon} />
                </div>
            )}
        </div>
    );
};

export default CustomOption;
