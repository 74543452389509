// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPasswordReset {
    email: string;
}

export default class PasswordReset {
    readonly _email: string;

    /**
     * Description: Email
     * Example: whitebox@google.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    constructor(props: IPasswordReset) {
        this._email = props.email.trim();
    }

    serialize(): IPasswordReset {
        const data: IPasswordReset = {
            email: this._email,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            email: typeof this._email === 'string' && !!this._email.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPasswordReset): PasswordReset {
        return new PasswordReset(props);
    }

    readonly keys: { [key: string]: string } = {
        email: 'email',
        }
;

    mergeDeepWith(props: Partial<PasswordReset>): PasswordReset {
        const updateData: Partial<IPasswordReset> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PasswordReset) => {
            const updateKey = this.keys[key] as keyof IPasswordReset;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPasswordReset, keyof IPasswordReset>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PasswordReset({ ...this.serialize(), ...updateData });
    }
}
