/* eslint-disable class-methods-use-this */
import { WebTheme } from 'Entities/WebTheme';
import { ISavedUserAccount } from 'Actions/user';

export default class AppConfigStorage {
    private LOCAL_STORAGE_THEME_KEY = '_4gtj8mliaz0mo';

    private LOCAL_STORAGE_ADMIN_TOKEN = 'asAdmin';

    private LOCAL_STORAGE_TOKEN = 'token';

    private LOCAL_STORAGE_MAIN_TOKEN = 'mainToken';

    private LOCAL_STORAGE_CONFIRM_EMAIL = '_confirm_email';

    private LOCAL_STORAGE_ACCOUNTS = '_accounts';

    private LOCAL_STORAGE_LAST_CHANGELOG = '_change_log';

    private setStorageItem(key: string, value: string) {
        window.localStorage.setItem(key, value);
    }

    private getStorageItem(key: string) {
        return window.localStorage.getItem(key);
    }

    private removeStorageItem(key: string) {
        window.localStorage.removeItem(key);
    }

    private removeAccountEntry(account: ISavedUserAccount) {
        const prevValues = this.getAccounts() || [];
        return prevValues.filter((v) => v.id !== account.id);
    }

    setTheme(theme: WebTheme) {
        this.setStorageItem(this.LOCAL_STORAGE_THEME_KEY, theme);
    }

    getTheme(): WebTheme {
        return this.getStorageItem(this.LOCAL_STORAGE_THEME_KEY) as WebTheme || WebTheme.LIGHT;
    }

    removeTheme() {
        this.removeStorageItem(this.LOCAL_STORAGE_THEME_KEY);
    }

    setAdminToken(token: string) {
        this.setStorageItem(this.LOCAL_STORAGE_ADMIN_TOKEN, token);
    }

    getAdminToken() {
        return this.getStorageItem(this.LOCAL_STORAGE_ADMIN_TOKEN);
    }

    removeAdminToken() {
        this.removeStorageItem(this.LOCAL_STORAGE_ADMIN_TOKEN);
    }

    setAuthToken(token: string) {
        this.setStorageItem(this.LOCAL_STORAGE_TOKEN, token);
    }

    getAuthToken() {
        return this.getStorageItem(this.LOCAL_STORAGE_TOKEN);
    }

    removeAuthToken() {
        this.removeStorageItem(this.LOCAL_STORAGE_TOKEN);
    }

    setMainToken(token: string) {
        this.setStorageItem(this.LOCAL_STORAGE_MAIN_TOKEN, token);
    }

    getMainToken() {
        return this.getStorageItem(this.LOCAL_STORAGE_MAIN_TOKEN);
    }

    removeMainToken() {
        this.removeStorageItem(this.LOCAL_STORAGE_MAIN_TOKEN);
    }

    setEmailConfirmed(value: boolean) {
        this.setStorageItem(this.LOCAL_STORAGE_CONFIRM_EMAIL, value.toString());
    }

    isEmailConfirmed() {
        return this.getStorageItem(this.LOCAL_STORAGE_CONFIRM_EMAIL);
    }

    getAccounts(): ISavedUserAccount[] | null {
        const value = this.getStorageItem(this.LOCAL_STORAGE_ACCOUNTS);
        return value && JSON.parse(value);
    }

    saveAccount(account: ISavedUserAccount) {
        const filteredAccounts = this.removeAccountEntry(account);
        this.setStorageItem(this.LOCAL_STORAGE_ACCOUNTS, JSON.stringify([...filteredAccounts, account]));
    }

    removeAccount(account: ISavedUserAccount) {
        const filteredAccounts = this.removeAccountEntry(account);
        this.setStorageItem(this.LOCAL_STORAGE_ACCOUNTS, JSON.stringify(filteredAccounts));
    }

    clearAccountsList() {
        this.removeStorageItem(this.LOCAL_STORAGE_ACCOUNTS);
    }

    setLastChangeLogDate(date: string) {
        this.setStorageItem(this.LOCAL_STORAGE_LAST_CHANGELOG, date);
    }

    getLastChangeLogDate(): string | null {
        return this.getStorageItem(this.LOCAL_STORAGE_LAST_CHANGELOG);
    }

    removeLastChangeLogDate() {
        this.removeStorageItem(this.LOCAL_STORAGE_LAST_CHANGELOG);
    }
}
