import { Action } from 'store/actions';
import {
    DnsActions,
    saveDnsZones,
    saveDnsZone,
    deleteDnsZoneLocal,
    saveDnsSettings,
    saveDnsRecords,
} from 'Actions/dns';
import DnsZone from 'Entities/DnsZone';
import DnsSettings from 'Entities/DnsSettings';
import DnsRecord from 'Entities/DnsRecord';
import { DNS_ZONE_SORT } from 'Lib/helpers/consts';

import { DnsStore, dnsInitialState as initialState } from '../initialStates';

const sortZones = (createdIds: number[], zones: Map<number, DnsZone>) => {
    const name = [...createdIds];
    name.sort((idA, idB) => {
        return zones.get(idA)!.host > zones.get(idB)!.host ? 1 : -1;
    });
    const records = [...createdIds];
    records.sort((idA, idB) => {
        return zones.get(idB)!.recordsCount - zones.get(idA)!.recordsCount;
    });
    const sortedZones = new Map();
    sortedZones.set(DNS_ZONE_SORT.CREATED, createdIds);
    sortedZones.set(DNS_ZONE_SORT.NAME, name);
    sortedZones.set(DNS_ZONE_SORT.RECORDS, records);
    return sortedZones;
};

const reducer = (state: DnsStore = initialState, action: Action): DnsStore => {
    switch (action.type as DnsActions) {
        case DnsActions.SaveDnsZones: {
            const { payload } = action as ReturnType<typeof saveDnsZones>;
            const zones = new Map();
            const created: number[] = [];
            payload.forEach((s) => {
                zones.set(s.id, s);
                created.push(s.id);
            });

            const sortedZones = sortZones(created, zones);
            return { ...state, zones, sortedZones };
        }

        case DnsActions.SaveDnsZone: {
            const { payload } = action as ReturnType<typeof saveDnsZone>;
            const zones = new Map<number, DnsZone>(state.zones!);
            zones.set(payload.id, payload);

            const created = [payload.id, ...state.sortedZones!.get(DNS_ZONE_SORT.CREATED)!];
            const sortedZones = sortZones(created, zones);

            return { ...state, zones, sortedZones };
        }
        case DnsActions.SaveDnsRecords: {
            const { payload } = action as ReturnType<typeof saveDnsRecords>;
            const records = new Map<number, DnsRecord>();
            payload.forEach((r) => {
                records.set(r.id, r);
            });
            return { ...state, records };
        }

        case DnsActions.DeleteDnsZoneLocal: {
            const { payload } = action as ReturnType<typeof deleteDnsZoneLocal>;
            const zones = new Map<number, DnsZone>(state.zones!);
            zones.delete(payload.dnsZoneId);
            const created: number[] = [];
            zones.forEach((s) => {
                created.push(s.id);
            });
            const sortedZones = sortZones(created, zones);
            return { ...state, zones, sortedZones };
        }

        case DnsActions.SaveDnsSettings: {
            const { payload } = action as ReturnType<typeof saveDnsSettings>;
            const settings: DnsSettings = payload;
            return { ...state, settings };
        }

        default:
            return state;
    }
};

export default reducer;
