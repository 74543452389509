import AccountServersComparativeExpenses, { IAccountServersComparativeExpenses } from './AccountServersComparativeExpenses';
import { ClientWarning } from './ClientWarning';
import ComparativeIncome, { IComparativeIncome } from './ComparativeIncome';
import Notification, { INotification } from './Notification';
import ResellerDailyStats, { IResellerDailyStats } from './ResellerDailyStats';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountDashboard {
    balance: number;
    balance_left_days: number;
    can_create_server: boolean;
    client_triggers_count: number;
    monthly_servers_cost: number;
    new_notifications: INotification[];
    new_notifications_count: number;
    partner_active_clients: number;
    partner_balance: number;
    partner_comparative_income: IComparativeIncome;
    partner_overall_clients: number;
    partner_ref_percent: number;
    partner_test_clients: number;
    partner_today_clients: number;
    recipients_count: number;
    reseller_balance: number;
    reseller_clients_count: number;
    reseller_comparative_income: IComparativeIncome;
    reseller_daily_stats: IResellerDailyStats[];
    reseller_overall_income: number;
    running_servers_count: number;
    server_comparative_expenses_daily: IAccountServersComparativeExpenses;
    server_comparative_expenses_monthly: IAccountServersComparativeExpenses;
    servers_count: number;
    warnings?: ClientWarning[];
}

export default class AccountDashboard {
    readonly _balance: number;

    /**
     * Description: Client balance
     * Example: 330.56
     */
    get balance(): number {
        return this._balance;
    }

    static balanceValidate(balance: number): boolean {
        return typeof balance === 'number';
    }

    readonly _balance_left_days: number;

    /**
     * Description: Days before end of the balance including credit limit
     * Example: 12
     */
    get balanceLeftDays(): number {
        return this._balance_left_days;
    }

    static balanceLeftDaysValidate(balanceLeftDays: number): boolean {
        return typeof balanceLeftDays === 'number';
    }

    readonly _can_create_server: boolean;

    /**
     * Description: Is server creating available
     * Example: true
     */
    get canCreateServer(): boolean {
        return this._can_create_server;
    }

    static canCreateServerValidate(canCreateServer: boolean): boolean {
        return typeof canCreateServer === 'boolean';
    }

    readonly _client_triggers_count: number;

    /**
     * Description: Client triggers count
     * Example: 8
     */
    get clientTriggersCount(): number {
        return this._client_triggers_count;
    }

    static clientTriggersCountValidate(clientTriggersCount: number): boolean {
        return typeof clientTriggersCount === 'number';
    }

    readonly _monthly_servers_cost: number;

    /**
     * Description: Monthly servers cost
     * Example: 2190
     */
    get monthlyServersCost(): number {
        return this._monthly_servers_cost;
    }

    static monthlyServersCostValidate(monthlyServersCost: number): boolean {
        return typeof monthlyServersCost === 'number';
    }

    readonly _new_notifications: Notification[];

    /** */
    get newNotifications(): Notification[] {
        return this._new_notifications;
    }

    readonly _new_notifications_count: number;

    /**
     * Description: New (not seen) notifications count
     * Example: 2
     */
    get newNotificationsCount(): number {
        return this._new_notifications_count;
    }

    static newNotificationsCountValidate(newNotificationsCount: number): boolean {
        return typeof newNotificationsCount === 'number';
    }

    readonly _partner_active_clients: number;

    /**
     * Description: Still active clients, registered for partner's referral
     * Example: 12
     */
    get partnerActiveClients(): number {
        return this._partner_active_clients;
    }

    static partnerActiveClientsValidate(partnerActiveClients: number): boolean {
        return typeof partnerActiveClients === 'number';
    }

    readonly _partner_balance: number;

    /**
     * Description: Partner balance
     * Example: 120.8
     */
    get partnerBalance(): number {
        return this._partner_balance;
    }

    static partnerBalanceValidate(partnerBalance: number): boolean {
        return typeof partnerBalance === 'number';
    }

    readonly _partner_comparative_income: ComparativeIncome;

    get partnerComparativeIncome(): ComparativeIncome {
        return this._partner_comparative_income;
    }

    readonly _partner_overall_clients: number;

    /**
     * Description: Overall registrations for partner's referral
     * Example: 120
     */
    get partnerOverallClients(): number {
        return this._partner_overall_clients;
    }

    static partnerOverallClientsValidate(partnerOverallClients: number): boolean {
        return typeof partnerOverallClients === 'number';
    }

    readonly _partner_ref_percent: number;

    /**
     * Description: Partner's referral percent
     * Example: 15
     */
    get partnerRefPercent(): number {
        return this._partner_ref_percent;
    }

    static partnerRefPercentValidate(partnerRefPercent: number): boolean {
        return typeof partnerRefPercent === 'number';
    }

    readonly _partner_test_clients: number;

    /**
     * Description: Test active clients, registered for partner's referral
     * Example: 24
     */
    get partnerTestClients(): number {
        return this._partner_test_clients;
    }

    static partnerTestClientsValidate(partnerTestClients: number): boolean {
        return typeof partnerTestClients === 'number';
    }

    readonly _partner_today_clients: number;

    /**
     * Description: Today's registrations for partner's referral
     * Example: 8
     */
    get partnerTodayClients(): number {
        return this._partner_today_clients;
    }

    static partnerTodayClientsValidate(partnerTodayClients: number): boolean {
        return typeof partnerTodayClients === 'number';
    }

    readonly _recipients_count: number;

    /**
     * Description: Recipients count
     * Example: 2
     */
    get recipientsCount(): number {
        return this._recipients_count;
    }

    static recipientsCountValidate(recipientsCount: number): boolean {
        return typeof recipientsCount === 'number';
    }

    readonly _reseller_balance: number;

    /**
     * Description: Reseller balance
     * Example: 7370.5
     */
    get resellerBalance(): number {
        return this._reseller_balance;
    }

    static resellerBalanceValidate(resellerBalance: number): boolean {
        return typeof resellerBalance === 'number';
    }

    readonly _reseller_clients_count: number;

    /**
     * Description: Reseller clients count
     * Example: 3
     */
    get resellerClientsCount(): number {
        return this._reseller_clients_count;
    }

    static resellerClientsCountValidate(resellerClientsCount: number): boolean {
        return typeof resellerClientsCount === 'number';
    }

    readonly _reseller_comparative_income: ComparativeIncome;

    get resellerComparativeIncome(): ComparativeIncome {
        return this._reseller_comparative_income;
    }

    readonly _reseller_daily_stats: ResellerDailyStats[];

    /** */
    get resellerDailyStats(): ResellerDailyStats[] {
        return this._reseller_daily_stats;
    }

    readonly _reseller_overall_income: number;

    /**
     * Description: All time income for reseller, RUB
     * Example: 214540.2
     */
    get resellerOverallIncome(): number {
        return this._reseller_overall_income;
    }

    static resellerOverallIncomeValidate(resellerOverallIncome: number): boolean {
        return typeof resellerOverallIncome === 'number';
    }

    readonly _running_servers_count: number;

    /**
     * Description: Running servers count
     * Example: 4
     */
    get runningServersCount(): number {
        return this._running_servers_count;
    }

    static runningServersCountValidate(runningServersCount: number): boolean {
        return typeof runningServersCount === 'number';
    }

    readonly _server_comparative_expenses_daily: AccountServersComparativeExpenses;

    get serverComparativeExpensesDaily(): AccountServersComparativeExpenses {
        return this._server_comparative_expenses_daily;
    }

    readonly _server_comparative_expenses_monthly: AccountServersComparativeExpenses;

    get serverComparativeExpensesMonthly(): AccountServersComparativeExpenses {
        return this._server_comparative_expenses_monthly;
    }

    readonly _servers_count: number;

    /**
     * Description: Servers count
     * Example: 12
     */
    get serversCount(): number {
        return this._servers_count;
    }

    static serversCountValidate(serversCount: number): boolean {
        return typeof serversCount === 'number';
    }

    readonly _warnings: ClientWarning[] | undefined;

    /**
     * Description: Warnings to show to client
     * Example: PHONE_UNKNOWN,NO_SERVERS
     */
    get warnings(): ClientWarning[] | undefined {
        return this._warnings;
    }

    constructor(props: IAccountDashboard) {
        this._balance = props.balance;
        this._balance_left_days = props.balance_left_days;
        this._can_create_server = props.can_create_server;
        this._client_triggers_count = props.client_triggers_count;
        this._monthly_servers_cost = props.monthly_servers_cost;
        this._new_notifications = props.new_notifications.map((p) => new Notification(p));
        this._new_notifications_count = props.new_notifications_count;
        this._partner_active_clients = props.partner_active_clients;
        this._partner_balance = props.partner_balance;
        this._partner_comparative_income = new ComparativeIncome(props.partner_comparative_income);
        this._partner_overall_clients = props.partner_overall_clients;
        this._partner_ref_percent = props.partner_ref_percent;
        this._partner_test_clients = props.partner_test_clients;
        this._partner_today_clients = props.partner_today_clients;
        this._recipients_count = props.recipients_count;
        this._reseller_balance = props.reseller_balance;
        this._reseller_clients_count = props.reseller_clients_count;
        this._reseller_comparative_income = new ComparativeIncome(props.reseller_comparative_income);
        this._reseller_daily_stats = props.reseller_daily_stats.map((p) => new ResellerDailyStats(p));
        this._reseller_overall_income = props.reseller_overall_income;
        this._running_servers_count = props.running_servers_count;
        this._server_comparative_expenses_daily = new AccountServersComparativeExpenses(props.server_comparative_expenses_daily);
        this._server_comparative_expenses_monthly = new AccountServersComparativeExpenses(props.server_comparative_expenses_monthly);
        this._servers_count = props.servers_count;
        if (props.warnings) {
            this._warnings = props.warnings;
        }
    }

    serialize(): IAccountDashboard {
        const data: IAccountDashboard = {
            balance: this._balance,
            balance_left_days: this._balance_left_days,
            can_create_server: this._can_create_server,
            client_triggers_count: this._client_triggers_count,
            monthly_servers_cost: this._monthly_servers_cost,
            new_notifications: this._new_notifications.map((p) => p.serialize()),
            new_notifications_count: this._new_notifications_count,
            partner_active_clients: this._partner_active_clients,
            partner_balance: this._partner_balance,
            partner_comparative_income: this._partner_comparative_income.serialize(),
            partner_overall_clients: this._partner_overall_clients,
            partner_ref_percent: this._partner_ref_percent,
            partner_test_clients: this._partner_test_clients,
            partner_today_clients: this._partner_today_clients,
            recipients_count: this._recipients_count,
            reseller_balance: this._reseller_balance,
            reseller_clients_count: this._reseller_clients_count,
            reseller_comparative_income: this._reseller_comparative_income.serialize(),
            reseller_daily_stats: this._reseller_daily_stats.map((p) => p.serialize()),
            reseller_overall_income: this._reseller_overall_income,
            running_servers_count: this._running_servers_count,
            server_comparative_expenses_daily: this._server_comparative_expenses_daily.serialize(),
            server_comparative_expenses_monthly: this._server_comparative_expenses_monthly.serialize(),
            servers_count: this._servers_count,
        };
        if (typeof this._warnings !== 'undefined') {
            data.warnings = this._warnings;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            balance: typeof this._balance === 'number',
            balance_left_days: typeof this._balance_left_days === 'number',
            servers_count: typeof this._servers_count === 'number',
            running_servers_count: typeof this._running_servers_count === 'number',
            can_create_server: typeof this._can_create_server === 'boolean',
            server_comparative_expenses_daily: this._server_comparative_expenses_daily.validate().length === 0,
            server_comparative_expenses_monthly: this._server_comparative_expenses_monthly.validate().length === 0,
            monthly_servers_cost: typeof this._monthly_servers_cost === 'number',
            new_notifications_count: typeof this._new_notifications_count === 'number',
            new_notifications: this._new_notifications.reduce((result, p) => result && p.validate().length === 0, true),
            client_triggers_count: typeof this._client_triggers_count === 'number',
            recipients_count: typeof this._recipients_count === 'number',
            partner_overall_clients: typeof this._partner_overall_clients === 'number',
            partner_today_clients: typeof this._partner_today_clients === 'number',
            partner_active_clients: typeof this._partner_active_clients === 'number',
            partner_test_clients: typeof this._partner_test_clients === 'number',
            partner_ref_percent: typeof this._partner_ref_percent === 'number',
            partner_balance: typeof this._partner_balance === 'number',
            partner_comparative_income: this._partner_comparative_income.validate().length === 0,
            reseller_clients_count: typeof this._reseller_clients_count === 'number',
            reseller_balance: typeof this._reseller_balance === 'number',
            reseller_overall_income: typeof this._reseller_overall_income === 'number',
            reseller_comparative_income: this._reseller_comparative_income.validate().length === 0,
            reseller_daily_stats: this._reseller_daily_stats.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountDashboard): AccountDashboard {
        return new AccountDashboard(props);
    }

    readonly keys: { [key: string]: string } = {
        balance: 'balance',
        balanceLeftDays: 'balance_left_days',
        canCreateServer: 'can_create_server',
        clientTriggersCount: 'client_triggers_count',
        monthlyServersCost: 'monthly_servers_cost',
        newNotifications: 'new_notifications',
        newNotificationsCount: 'new_notifications_count',
        partnerActiveClients: 'partner_active_clients',
        partnerBalance: 'partner_balance',
        partnerComparativeIncome: 'partner_comparative_income',
        partnerOverallClients: 'partner_overall_clients',
        partnerRefPercent: 'partner_ref_percent',
        partnerTestClients: 'partner_test_clients',
        partnerTodayClients: 'partner_today_clients',
        recipientsCount: 'recipients_count',
        resellerBalance: 'reseller_balance',
        resellerClientsCount: 'reseller_clients_count',
        resellerComparativeIncome: 'reseller_comparative_income',
        resellerDailyStats: 'reseller_daily_stats',
        resellerOverallIncome: 'reseller_overall_income',
        runningServersCount: 'running_servers_count',
        serverComparativeExpensesDaily: 'server_comparative_expenses_daily',
        serverComparativeExpensesMonthly: 'server_comparative_expenses_monthly',
        serversCount: 'servers_count',
        warnings: 'warnings',
        }
;

    mergeDeepWith(props: Partial<AccountDashboard>): AccountDashboard {
        const updateData: Partial<IAccountDashboard> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountDashboard) => {
            const updateKey = this.keys[key] as keyof IAccountDashboard;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountDashboard, keyof IAccountDashboard>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountDashboard({ ...this.serialize(), ...updateData });
    }
}
