import AccountApi from 'Apis/account';
import { IAccountExpense } from 'Entities/AccountExpense';
import Account from 'Entities/Account';
import AccountDetails from 'Entities/AccountDetails';
import AccountCreditCard from 'Entities/AccountCreditCard';
import AccountDocument from 'Entities/AccountDocument';
import AccountDashboard from 'Entities/AccountDashboard';
import AccountExpensesDetailed from 'Entities/AccountExpensesDetailed';
import Server from 'Entities/Server';

import { actionCreator } from '.';

export enum AccountActions {
    GetAccount = 'GetAccount',
    SaveAccount = 'SaveAccount',
    GetAccountDetails = 'GetAccountDetails',
    SaveAccountDetails = 'SaveAccountDetails',
    GetCreditCards = 'GetCreditCards',
    SaveCreditCards = 'SaveCreditCards',
    DeleteCreditCard = 'DeleteCreditCard',
    DeleteCreditCardLocal = 'DeleteCreditCardLocal',
    GetDocuments = 'GetDocuments',
    SaveDocuments = 'SaveDocuments',
    UpdateAutoTopUpSettings = 'UpdateAutoTopUpSettings',
    ReorderAccountCreditCards = 'ReorderAccountCreditCards',
    GetAccountInvoice = 'GetAccountInvoice',
    CancelInvoice = 'CancelInvoice',
    GetAccountVerificationAct = 'GetAccountVerificationAct',
    GetAccountDashboard = 'GetAccountDashboard',
    SaveAccountDashboard = 'SaveAccountDashboard',
    ClearDashboard = 'ClearDashboard',
    CreateAccountPayer = 'CreateAccountPayer',
    CreateAccountInvoice = 'CreateAccountInvoice',
    CreateAccountNoCashInvoice = 'CreateAccountNoCashInvoice',
    CreateAccountCardInvoice = 'CreateAccountCardInvoice',
    ActivateCoupon = 'ActivateCoupon',
    GetAccountExpensesDetailed = 'GetAccountExpensesDetailed',
    SaveAccountExpensesDetailed = 'SaveAccountExpensesDetailed',
    GetAccountExpensesJSON = 'GetAccountExpensesJSON',
    SaveAccountExpensesJSON = 'SaveAccountExpensesJSON',
    GetAccountableServers = 'GetAccountableServers',
    SaveAccountableServers = 'SaveAccountableServers',
}

const saveAccount = actionCreator<Account>(AccountActions.SaveAccount);

const getAccountDetails = actionCreator(AccountActions.GetAccountDetails);
const saveAccountDetails = actionCreator<AccountDetails>(AccountActions.SaveAccountDetails);

const getCreditCards = actionCreator(AccountActions.GetCreditCards);
const saveCreditCards = actionCreator<AccountCreditCard[]>(AccountActions.SaveCreditCards);

type DeleteCreditCard = {
    cardId: number;
};
const deleteCreditCard = actionCreator<DeleteCreditCard>(AccountActions.DeleteCreditCard);
const deleteCreditCardLocal = actionCreator<DeleteCreditCard>(AccountActions.DeleteCreditCardLocal);

const getDocuments = actionCreator(AccountActions.GetDocuments);
const saveDocuments = actionCreator<AccountDocument[]>(
    AccountActions.SaveDocuments,
);

type UpdateAutoTopUpSettings = Parameters<typeof AccountApi.updateAccountAutoTopUpSettings>;
const updateAutoTopUpSettings = actionCreator<UpdateAutoTopUpSettings>(
    AccountActions.UpdateAutoTopUpSettings,
);

type ReorderAccountCreditCards = Parameters<typeof AccountApi.reorderAccountCreditCards>;
const reorderAccountCreditCards = actionCreator<ReorderAccountCreditCards>(
    AccountActions.ReorderAccountCreditCards,
);

interface GetAccountInvoice {
    req: Parameters<typeof AccountApi.getAccountInvoice>;
    callback?: () => void;
}
const getAccountInvoice = actionCreator<GetAccountInvoice>(
    AccountActions.GetAccountInvoice,
);
type CancelInvoice = Parameters<typeof AccountApi.cancelAccountInvoice>;
const cancelInvoice = actionCreator<CancelInvoice>(AccountActions.CancelInvoice);

type GetAccountVerificationAct = Parameters<typeof AccountApi.getAccountVerificationAct>;
const getAccountVerificationAct = actionCreator<GetAccountVerificationAct>(
    AccountActions.GetAccountVerificationAct,
);

type GetAccountDashboard = Parameters<typeof AccountApi.getAccountDashboard>;
const getAccountDashboard = actionCreator<GetAccountDashboard>(AccountActions.GetAccountDashboard);

type SaveAccountDashboard = AccountDashboard;
const saveAccountDashboard = actionCreator<SaveAccountDashboard>(
    AccountActions.SaveAccountDashboard,
);

const clearDashboard = actionCreator(AccountActions.ClearDashboard);

type CreateAccountPayer = Parameters<typeof AccountApi.createAccountPayer>;
const createAccountPayer = actionCreator<CreateAccountPayer>(AccountActions.CreateAccountPayer);

type СreateAccountInvoice = Parameters<typeof AccountApi.createAccountInvoice>;
const createAccountInvoice = actionCreator<СreateAccountInvoice>(
    AccountActions.CreateAccountInvoice,
);
type CreateAccountNoCashInvoice = Parameters<typeof AccountApi.createAccountNoCashInvoice>;
const createAccountNoCashInvoice = actionCreator<CreateAccountNoCashInvoice>(
    AccountActions.CreateAccountNoCashInvoice,
);
type CreateAccountCardInvoice = Parameters<typeof AccountApi.createAccountInvoiceWithCreditCard>;
const createAccountCardInvoice = actionCreator<CreateAccountCardInvoice>(
    AccountActions.CreateAccountCardInvoice,
);
type ActivateCoupon = Parameters<typeof AccountApi.activateCoupon>;
const activateCoupon = actionCreator<ActivateCoupon>(
    AccountActions.ActivateCoupon,
);

type GetAccountExpensesDetailed = Parameters<typeof AccountApi.getAccountExpensesDetailed>;
const getAccountExpensesDetailed = actionCreator<GetAccountExpensesDetailed>(
    AccountActions.GetAccountExpensesDetailed,
);

const saveAccountExpensesDetailed = actionCreator<AccountExpensesDetailed>(
    AccountActions.SaveAccountExpensesDetailed,
);

type GetAccountExpensesJSON = Parameters<typeof AccountApi.getAccountExpensesStats>;
const getAccountExpensesJSON = actionCreator<GetAccountExpensesJSON>(
    AccountActions.GetAccountExpensesJSON,
);

const saveAccountExpensesJSON = actionCreator<IAccountExpense[]>(
    AccountActions.SaveAccountExpensesJSON,
);

type GetAccountableServers = Parameters<typeof AccountApi.getClientAccountableServers>;
const getAccountableServers = actionCreator<GetAccountableServers>(
    AccountActions.GetAccountableServers,
);

const saveAccountableServers = actionCreator<Server[]>(
    AccountActions.SaveAccountableServers,
);

export {
    saveAccount,
    getAccountDetails,
    saveAccountDetails,
    getCreditCards,
    saveCreditCards,
    deleteCreditCard,
    deleteCreditCardLocal,
    getDocuments,
    saveDocuments,
    updateAutoTopUpSettings,
    reorderAccountCreditCards,
    getAccountInvoice,
    cancelInvoice,
    getAccountVerificationAct,
    getAccountDashboard,
    saveAccountDashboard,
    clearDashboard,
    createAccountPayer,
    createAccountInvoice,
    createAccountNoCashInvoice,
    createAccountCardInvoice,
    activateCoupon,
    getAccountExpensesDetailed,
    saveAccountExpensesDetailed,
    getAccountExpensesJSON,
    saveAccountExpensesJSON,
    getAccountableServers,
    saveAccountableServers,
};
