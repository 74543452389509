import React, { FC } from 'react';
import { Dropdown, Tooltip } from 'antd';
import cn from 'classnames';

import { Checkbox, Input, useIntl } from 'Common';
import { RecurringSubscriptionRebillSettings } from 'Entities/RecurringSubscriptionRebillSettings';
import { currencySymbol } from 'Lib/helpers/consts';

import { FormValues } from './AutoTopUpForm';
import s from './AutoTopUp.module.pcss';

interface OptionProps {
    title: string;
    setting: RecurringSubscriptionRebillSettings;
    amount: number;
    initialAmount: number;
    settingField: keyof FormValues;
    amountField: keyof FormValues;
    errorMessage?: string;
    cardForm?: boolean;
    autoInvoice?: boolean;
    validAmount: (value: number) => boolean;
    setFieldValue: (
        key: keyof FormValues,
        value: number | string | RecurringSubscriptionRebillSettings,
    ) => void;
    disabledOption?: boolean;
}

const Option: FC<OptionProps> = ({
    title,
    setting,
    amount,
    initialAmount,
    settingField,
    amountField,
    errorMessage,
    cardForm,
    autoInvoice,
    validAmount,
    setFieldValue,
    disabledOption,
}) => {
    const intl = useIntl();

    const handleSettingChange = (value: RecurringSubscriptionRebillSettings) => {
        if (
            value === RecurringSubscriptionRebillSettings.CHARGE_FULL_BALANCE
            || value === RecurringSubscriptionRebillSettings.DISABLED
        ) {
            setFieldValue(amountField, initialAmount);
            setFieldValue(settingField, value);
        } else {
            setFieldValue(settingField, value);
        }
    };

    const setFullBalance = () => {
        handleSettingChange(RecurringSubscriptionRebillSettings.CHARGE_FULL_BALANCE);
    };

    const setChargeAmount = () => {
        handleSettingChange(RecurringSubscriptionRebillSettings.CHARGE_AMOUNT);
    };

    const handleAmountChange = (value: string) => {
        if (Number(value)) {
            setFieldValue(amountField, Number(value));
        } else {
            setFieldValue(amountField, value);
        }
    };

    const checked = setting !== RecurringSubscriptionRebillSettings.DISABLED;
    const disabled = setting === RecurringSubscriptionRebillSettings.DISABLED;

    const amountInput = (
        <Input
            type="number"
            size="small"
            name={amountField}
            className="input input_small"
            wrapperClassName={s.group}
            suffix={currencySymbol.RUB}
            value={amount}
            onChange={handleAmountChange}
            validate={() => validAmount(amount)}
            disabled={disabledOption || disabled}
            error={!!errorMessage}
            noValidationIcon
        />
    );

    const dropdownContent = () => {
        if (setting === RecurringSubscriptionRebillSettings.CHARGE_AMOUNT) {
            return (
                <div className={s.dropdown}>
                    <button
                        type="button"
                        className={s.dropdownLink}
                        onClick={setFullBalance}
                    >
                        {intl.getMessage('billing_auto_top_up_full_balance')}
                    </button>
                </div>
            );
        }

        return (
            <div className={s.dropdown}>
                <button
                    type="button"
                    className={s.dropdownLink}
                    onClick={setChargeAmount}
                >
                    {intl.getMessage('billing_auto_top_charge_amount')}
                </button>
            </div>
        );
    };

    const getDropdown = () => {
        if (cardForm) {
            return amountInput;
        }

        return (
            <Dropdown
                overlay={dropdownContent}
                placement="bottomLeft"
                trigger={['click']}
                disabled={disabled}
            >
                <div className={s.group}>
                    {setting === RecurringSubscriptionRebillSettings.CHARGE_FULL_BALANCE ? (
                        <div className={cn('input input_small', s.full)}>
                            {intl.getMessage('billing_auto_top_up_full_balance')}
                        </div>
                    ) : (
                        amountInput
                    )}
                </div>
            </Dropdown>
        );
    };

    const getCheckbox = () => {
        if (autoInvoice) {
            return title;
        }

        return (
            <Checkbox
                name="topUpSettingsPartner"
                checked={checked}
                handleChange={(e) => {
                    handleSettingChange(e.target.checked
                        ? RecurringSubscriptionRebillSettings.CHARGE_AMOUNT
                        : RecurringSubscriptionRebillSettings.DISABLED);
                }}
                disabled={disabledOption}
            >
                {title}
            </Checkbox>
        );
    };

    if (disabledOption) {
        return (
            <Tooltip
                title={intl.getMessage('billing_auto_top_up_no_cards_saved')}
                placement="bottomLeft"
            >
                <div className={s.row}>
                    <div className={s.input}>
                        <div className={s.label}>
                            {getCheckbox()}
                        </div>
                        {getDropdown()}
                    </div>
                    <div className={s.error}>
                        {errorMessage}
                    </div>
                </div>
            </Tooltip>
        );
    }

    return (
        <div className={s.row}>
            <div className={s.input}>
                <div className={s.label}>
                    {getCheckbox()}
                </div>
                {getDropdown()}
            </div>
            <div className={s.error}>
                {errorMessage}
            </div>
        </div>
    );
};

export default Option;
