import React, { FC, useState } from 'react';
import cn from 'classnames';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useIntl, Icon, Button, Link } from 'Common';
import { Store } from 'Store';
import { ClientType } from 'Entities/ClientType';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import { EditCredentials } from '../Credentials';
import s from './Profile.module.pcss';

interface ProfileDetailsProps {
    openEditModal: boolean;
    setOpenEditModal: (v: boolean) => void;
}

const selectProfilePayer = (store: Store) => store.profile.profilePayer;
const selector = createSelector(
    [selectProfilePayer],
    (payers) => {
        const payer = payers?.find((p) => p.active) || null;
        return { payer };
    },
);

const ProfileDetails: FC<ProfileDetailsProps> = ({ openEditModal, setOpenEditModal }) => {
    const intl = useIntl();
    const profile = useSelector(selector);

    const [showFullCredentials, setShowFullCredentials] = useState(false);

    const { payer } = profile;

    if (!payer) {
        return (
            <div className={s.profileDetails}>
                <div className={s.title}>
                    {intl.getMessage('profile_details_title')}
                </div>
                <div className={s.column}>
                    <div className={s.item}>
                        <Link
                            to={RoutePath.OnboardingRequisites}
                            className={s.itemInnerwrapper}
                        >
                            <div className={s.label}>
                                {intl.getMessage('profile_details_title')}
                            </div>
                            <div className={cn(s.value, s.notice)}>
                                {intl.getMessage('add_requisites')}
                            </div>
                            <div className={s.action}>
                                <Icon icon="edit" className={s.icon} />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={s.profileDetails}>
                <div className={s.title}>
                    {intl.getMessage('profile_details_title')}
                </div>

                <div className={s.column}>
                    {payer.clientType === ClientType.INDIVIDUAL
                        && payer.individualInfo && (
                        <>
                            <div className={s.item}>
                                <div
                                    className={s.itemInnerwrapper}
                                    onClick={() => setOpenEditModal(true)}
                                >
                                    <div className={s.label}>
                                        {intl.getMessage('credentials_private_person')}
                                    </div>
                                    <div className={s.value}>
                                        {payer.fullName}
                                    </div>
                                    <div className={s.action}>
                                        <Icon icon="edit" className={s.icon} />
                                    </div>
                                </div>
                                <Divider className={cn(theme.divider.divider, s.divider)} />
                            </div>
                            <div className={s.item}>
                                <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                    <div className={s.label}>
                                        {intl.getMessage('credentials_address')}
                                    </div>
                                    <div className={s.value}>
                                        {payer.individualInfo.address}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {payer.clientType === ClientType.CORPORATION
                        && payer.corporationInfo && (
                        <>
                            <div className={s.item}>
                                <div
                                    className={s.itemInnerwrapper}
                                    onClick={() => setOpenEditModal(true)}
                                >
                                    <div className={s.label}>
                                        {intl.getMessage('credentials_legal_entity')}
                                    </div>
                                    <div className={s.value}>
                                        {payer.fullName}
                                    </div>
                                    <div className={s.action}>
                                        <Icon icon="edit" className={s.icon} />
                                    </div>
                                </div>
                                <Divider className={cn(theme.divider.divider, s.divider)} />
                            </div>
                            <div className={cn(s.item, { [s.space]: showFullCredentials })}>
                                <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                    <div className={s.label}>
                                        {intl.getMessage('credentials_vat')}
                                    </div>
                                    <div className={s.value}>
                                        {payer.corporationInfo.inn}
                                    </div>
                                </div>
                                {showFullCredentials && (
                                    <Divider className={cn(theme.divider.divider, s.divider)} />
                                )}
                            </div>
                            {showFullCredentials && (
                                <>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_iec')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.corporationInfo.iec}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_legal_address')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.corporationInfo.legalAddress}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_correspondence_address')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.corporationInfo.postAddress}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_head_name')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.corporationInfo.headName}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_operation_basis')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.corporationInfo.operationBasis}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_accounting_correspondence_email')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.corporationInfo.accountingEmail}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={cn(s.item, s.space)}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_government_contract_identifier')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.corporationInfo.governmentContractId}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <Button
                                type="link"
                                onClick={() => setShowFullCredentials(!showFullCredentials)}
                                className={s.button}
                            >
                                {showFullCredentials
                                    ? intl.getMessage('credentials_hide')
                                    : intl.getMessage('credentials_show')}
                            </Button>
                        </>
                    )}
                    {payer.clientType === ClientType.SOLE_TRADER
                        && payer.soleTraderInfo && (
                        <>
                            <div className={s.item}>
                                <div
                                    className={s.itemInnerwrapper}
                                    onClick={() => setOpenEditModal(true)}
                                >
                                    <div className={s.label}>
                                        {intl.getMessage('credentials_sole_proprietor_short')}
                                    </div>
                                    <div className={s.value}>
                                        {payer.soleTraderInfo.trademark}
                                    </div>
                                    <div className={s.action}>
                                        <Icon icon="edit" className={s.icon} />
                                    </div>
                                </div>
                                <Divider className={cn(theme.divider.divider, s.divider)} />
                            </div>
                            <div className={cn(s.item, { [s.space]: !showFullCredentials })}>
                                <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                    <div className={s.label}>
                                        {intl.getMessage('credentials_vat')}
                                    </div>
                                    <div className={s.value}>
                                        {payer.soleTraderInfo.inn}
                                    </div>
                                </div>
                                {showFullCredentials && (
                                    <Divider className={cn(theme.divider.divider, s.divider)} />
                                )}
                            </div>
                            {showFullCredentials && (
                                <>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_psrnsp')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.soleTraderInfo.ogrnip}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_legal_address')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.soleTraderInfo.legalAddress}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={s.item}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_correspondence_address')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.soleTraderInfo.postAddress}
                                            </div>
                                        </div>
                                        <Divider className={cn(theme.divider.divider, s.divider)} />
                                    </div>
                                    <div className={cn(s.item, s.space)}>
                                        <div className={cn(s.itemInnerwrapper, s.no_action)}>
                                            <div className={s.label}>
                                                {intl.getMessage('credentials_accounting_correspondence_email')}
                                            </div>
                                            <div className={s.value}>
                                                {payer.soleTraderInfo.accountingEmail}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            <Button
                                type="link"
                                onClick={() => setShowFullCredentials(!showFullCredentials)}
                                className={s.button}
                            >
                                {showFullCredentials
                                    ? intl.getMessage('credentials_hide')
                                    : intl.getMessage('credentials_show')}
                            </Button>
                        </>
                    )}
                    {payer.clientType === ClientType.NON_RESIDENT
                        && payer.nonResidentInfo && (
                        <>
                            <div className={s.item}>
                                <div
                                    className={s.itemInnerwrapper}
                                    onClick={() => setOpenEditModal(true)}
                                >
                                    <div className={s.label}>
                                        {intl.getMessage('credentials_non_resident')}
                                    </div>
                                    <div className={s.value}>
                                        {payer.nonResidentInfo.name}
                                    </div>
                                    <div className={s.action}>
                                        <Icon icon="edit" className={s.icon} />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            {openEditModal && (
                <EditCredentials
                    payer={payer}
                    visible={openEditModal}
                    handleClose={() => setOpenEditModal(false)}
                />
            )}
        </>
    );
};

export default ProfileDetails;
