import React, { FC } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link, Icon, ServerIndicator, useIntl } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { getServerIcon, setServerSearch } from 'Lib/helpers/utils';
import OperationProgress from 'Entities/OperationProgress';
import Server from 'Entities/Server';
import { Store } from 'Store';
import theme from 'Lib/theme';
import { OperationType } from 'Entities/OperationType';

interface ServerOwnProps {
    server: Server;
    search: string;
}

interface ServerStoreProps {
    operationProgress: OperationProgress | null;
}

type ServerItemProps = ServerStoreProps & ServerOwnProps;

export const SERVER_ITEM_ID_PREFIX = '_search_server_';

const ServerItem: FC<ServerItemProps> = ({ server, operationProgress, search }) => {
    const intl = useIntl();
    const {
        id, state, distribution, name, tenantId, ipv4Addresses, ipv6Addresses, privateAddress,
    } = server;

    const ips = [...ipv4Addresses, ...ipv6Addresses, privateAddress];

    const filteredIp = ips.filter((v) => v.host.includes(search)).map((v) => v.host);

    let ipsSearch = '';
    if (search === '') {
        ipsSearch = intl.getPlural('ip_count', ips.length);
    }
    if (search !== '' && (filteredIp.length > 1)) {
        ipsSearch = intl.getPlural('ip_count_search', filteredIp.length);
    }
    if (search !== '' && filteredIp.length === 1) {
        [ipsSearch] = filteredIp;
    }

    return (
        <Link
            id={`${tenantId}${SERVER_ITEM_ID_PREFIX}${id}`}
            stop
            disabled={operationProgress?.type === OperationType.INSTALL}
            to={RoutePath.Server}
            props={{ serverId: id, tenantId }}
            className={theme.search.server}
            onClick={() => setServerSearch(id)}
        >
            <ServerIndicator state={state} progress={operationProgress} />
            <Icon icon={getServerIcon(distribution.type)} />
            <div
                className={cn(
                    theme.search.name,
                    theme.search.name_server,
                )}
            >
                {name}
            </div>
            <div className={theme.search.id}>
                {ipsSearch}
            </div>
        </Link>
    );
};

const selectProgress = (store: Store, op: ServerOwnProps) => (
    store.operationProgress.commonOperations.get(op?.server.id)
);
const selectInstallingProgress = (store: Store, op: ServerOwnProps) => {
    const { id } = op.server;
    const allProgress = store.installingServers.progress;
    let progress: OperationProgress | null = null;
    allProgress?.forEach((t) => {
        if (t.serverId === id) {
            progress = t;
        }
    });
    return progress;
};

const selector = createSelector([
    selectProgress, selectInstallingProgress,
], (progress, installingProgress) => ({
    operationProgress: progress || installingProgress,
}));

const mapStateToProps = (store: Store, ownProps: ServerOwnProps) => {
    return { ...selector(store, ownProps) };
};

export default connect(mapStateToProps)(ServerItem);
