import React, { FC, useEffect, useState } from 'react';
import { Popover, Tooltip } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { Button, Icon, Loader, notifyError, notifySuccess, useIntl, whiteText } from 'Common';
import { IBatchErrorServer, SERVER_OPERATION_NOTIFICATION } from 'Lib/helpers/consts';
import theme from 'Lib/theme';
import { ActionButton } from 'Common/Server/Actions/components';
import { destroyServers, rebootServers, resetServers, shutDownServers } from 'Actions/server';
import ServerEntity from 'Entities/Server';
import { useUISettings } from 'Hooks';
import { OperationType } from 'Entities/OperationType';
import { ModalServersRemoveType } from 'Components/ServersList/components/Filters/component/ModalServersRemove';
import ServersApi from 'Apis/servers';
import { ServerState } from 'Entities/ServerState';
import ServersCommonPublicKeys, { IServersCommonPublicKeys } from 'Entities/ServersCommonPublicKeys';
import { DistributionFamily } from 'Entities/DistributionFamily';
import { errorChecker } from 'Lib/helpers/utils';
import { ModalUpdateKeys } from './component/ModalUpdateKeys';
import {
    RebootServersModal,
    DestroyServersModal,
    ResetServersModal,
    ShutDownServersModal,
    DisabledServersControls,
    ModalServersRemove,
} from './component';

interface ActionsSelectProps {
    compact?: boolean;
    servers: Set<ServerEntity>;
    clearSelection: () => void;
}

const ActionsSelect: FC<ActionsSelectProps> = ({
    servers,
    clearSelection,
}) => {
    const intl = useIntl();
    const { operationNotifications } = useUISettings();
    const dispatch = useDispatch();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalUpdateKeys, setOpenModalUpdateKeys] = useState(false);
    const [visiblePopover, setVisiblePopover] = useState(false);
    const [availableKeys, setAvailableKeys] = useState<ServersCommonPublicKeys>();

    const callBacker = (props: {
        loaderTitle: JSX.Element;
        allSuccess: JSX.Element;
        withErrors: (serverNames: string[]) => JSX.Element;
        notificationId: string;
    }) => {
        return () => {
            notifyError((
                <div className={theme.notification.wrapper}>
                    <div className={theme.notification.text}>
                        {props.loaderTitle}
                    </div>
                    <div className={theme.color.blue}>
                        <Loader circle mini className={theme.notification.loader} />
                    </div>
                </div>
            ), undefined, {
                key: props.notificationId,
                duration: 0,
                className: cn(theme.notification.customNotification, 'notification_event'),
            });
            return (errorServers?: IBatchErrorServer[]) => {
                if (!errorServers) {
                    notifySuccess(props.allSuccess, undefined, {
                        className: theme.notification.customNotification,
                    });
                } else {
                    const names = errorServers.map((sr) => sr.name);
                    notifyError(props.withErrors(names));
                }
            };
        };
    };

    const [openOperationModal, setOpenOperationModal] = useState({
        [SERVER_OPERATION_NOTIFICATION.REBOOT]: false,
        [SERVER_OPERATION_NOTIFICATION.RESET]: false,
        [SERVER_OPERATION_NOTIFICATION.SHUTDOWN]: false,
        [SERVER_OPERATION_NOTIFICATION.DESTROY]: false,
    });

    const closeModal = (modal: keyof typeof openOperationModal) => () => {
        setOpenOperationModal({ ...openOperationModal, [modal]: false });
    };

    const serversValues = Array.from(servers.values());
    const { tenantId } = serversValues[0];
    const serversIds = serversValues
        .map((server) => server.id);
    const hasServersEnabled = (serversValues
        .filter((i) => i.state === ServerState.RUNNING)).length > 0;
    const hasServersDisabled = (serversValues
        .filter((i) => i.state === ServerState.SHUTOFF)).length > 0;

    const isSameTenant = new Set(serversValues.map((i) => i.tenantId)).size === 1;
    const windowsDistribution = (serversValues.some((i) => i.distribution.type === DistributionFamily.WINDOWS));

    useEffect(() => {
        let canUpdate = true;
        const loadPublicKeys = async () => {
            const response = await ServersApi.getServersCommonPublicKeys(
                tenantId,
                { server_ids: serversIds },
            );
            if (!canUpdate) {
                return;
            }
            const { result } = errorChecker<IServersCommonPublicKeys>(response);
            if (result) {
                setAvailableKeys(new ServersCommonPublicKeys(result));
            } else {
                setAvailableKeys(undefined);
            }
        };
        if (isSameTenant) {
            loadPublicKeys();
        }
        return () => {
            canUpdate = false;
        };
    }, [servers]);

    const onOperation: Record<SERVER_OPERATION_NOTIFICATION, () => void> = {
        [SERVER_OPERATION_NOTIFICATION.REBOOT]: () => {
            dispatch(rebootServers({
                data: [tenantId, { server_ids: serversIds }],
                notificationId: OperationType.REBOOT,
            }, {
                result: callBacker({
                    loaderTitle: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_reboot_loader', servers.size, { b: whiteText })}
                        </div>
                    ),
                    allSuccess: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_reboot_success', servers.size, { b: whiteText })}
                        </div>
                    ),
                    withErrors: (serverNames) => (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_reboot_error', serverNames.length, { b: whiteText })}
                        </div>
                    ),
                    notificationId: OperationType.REBOOT,
                }),
            }));
            setVisiblePopover(false);
            setOpenOperationModal(
                { ...openOperationModal, [SERVER_OPERATION_NOTIFICATION.REBOOT]: false },
            );
            clearSelection();
        },
        [SERVER_OPERATION_NOTIFICATION.RESET]: () => {
            dispatch(resetServers({
                data: [tenantId, { server_ids: serversIds }],
                notificationId: OperationType.RESET,
            }, {
                result: callBacker({
                    loaderTitle: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_reset_loader', servers.size, { b: whiteText })}
                        </div>
                    ),
                    allSuccess: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_reset_success', servers.size, { b: whiteText })}
                        </div>
                    ),
                    withErrors: (serverNames) => (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_reset_error', serverNames.length, { b: whiteText })}
                        </div>
                    ),
                    notificationId: OperationType.RESET,
                }),
            }));
            setVisiblePopover(false);
            setOpenOperationModal(
                { ...openOperationModal, [SERVER_OPERATION_NOTIFICATION.RESET]: false },
            );
            clearSelection();
        },
        [SERVER_OPERATION_NOTIFICATION.SHUTDOWN]: () => {
            dispatch(shutDownServers({
                data: [tenantId, { server_ids: serversIds }],
                notificationId: OperationType.SHUTDOWN,
            }, {
                result: callBacker({
                    loaderTitle: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_shut_down_loader', servers.size, { b: whiteText })}
                        </div>
                    ),
                    allSuccess: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_shut_down_success', servers.size, { b: whiteText })}
                        </div>
                    ),
                    withErrors: (serverNames) => (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_shut_down_error', serverNames.length, { b: whiteText })}
                        </div>
                    ),
                    notificationId: OperationType.SHUTDOWN,
                }),
            }));
            setVisiblePopover(false);
            setOpenOperationModal(
                { ...openOperationModal, [SERVER_OPERATION_NOTIFICATION.SHUTDOWN]: false },
            );
            clearSelection();
        },
        [SERVER_OPERATION_NOTIFICATION.DESTROY]: () => {
            dispatch(destroyServers({
                data: [tenantId, { server_ids: serversIds }],
                notificationId: OperationType.DESTROY,
            }, {
                result: callBacker({
                    loaderTitle: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_destroy_loader', servers.size, { b: whiteText })}
                        </div>
                    ),
                    allSuccess: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_destroy_success', servers.size, { b: whiteText })}
                        </div>
                    ),
                    withErrors: (serverNames) => (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_destroy_error', serverNames.length, { b: whiteText })}
                        </div>
                    ),
                    notificationId: OperationType.DESTROY,
                }),
            }));
            setVisiblePopover(false);
            setOpenOperationModal(
                { ...openOperationModal, [SERVER_OPERATION_NOTIFICATION.DESTROY]: false },
            );
            clearSelection();
        },
    };

    const checkModalShow = (modal: SERVER_OPERATION_NOTIFICATION) => () => {
        if (!operationNotifications[modal]) {
            onOperation[modal]();
        } else {
            setOpenOperationModal({ ...openOperationModal, [modal]: true });
        }
    };

    return (
        <Popover
            content={(
                <div className={theme.popover.popover}>
                    {hasServersEnabled && !hasServersDisabled ? (
                        <>
                            <ActionButton
                                id="on_reboot_servers"
                                handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.REBOOT)}
                                title={intl.getMessage('server_soft_reboot')}
                            />
                            <ActionButton
                                id="on_reset_servers"
                                handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.RESET)}
                                title={intl.getMessage('server_force_reboot')}
                            />
                            <ActionButton
                                id="on_shutdown_servers"
                                handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.SHUTDOWN)}
                                title={intl.getMessage('server_soft_shutdown')}
                            />
                            <ActionButton
                                id="on_destroy_servers"
                                handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.DESTROY)}
                                title={intl.getMessage('server_force_shutdown')}
                            />
                        </>
                    ) : null}

                    {hasServersDisabled && !hasServersEnabled
                        ? (
                            <DisabledServersControls
                                close={() => setVisiblePopover(false)}
                                serversIds={serversIds}
                                servers={servers}
                                tenantId={tenantId}
                                clearSelection={clearSelection}
                            />
                        ) : null}

                    <div className={theme.popover.divider} />
                    {isSameTenant && !windowsDistribution ? (
                        <ActionButton
                            id="server_update_keys"
                            handler={() => setOpenModalUpdateKeys(true)}
                            title={intl.getMessage('server_update_keys')}
                        />
                    ) : (
                        <Tooltip
                            placement="right"
                            title={intl.getMessage('server_update_keys_tooltip')}
                            overlayClassName="tooltip tooltip_notice"
                        >
                            <div className={cn(theme.popover.item, theme.popover.disabled)}>
                                {intl.getMessage('server_update_keys')}
                            </div>
                        </Tooltip>
                    )}
                    <ActionButton
                        id="server_delete"
                        handler={() => setOpenModalDelete(true)}
                        title={intl.getMessage('delete')}
                        className={theme.popover.item_danger}
                    />
                    {openModalUpdateKeys && (
                        <ModalUpdateKeys
                            onClose={() => {
                                setOpenModalUpdateKeys(false);
                                clearSelection();
                            }}
                            visible={openModalUpdateKeys}
                            servers={servers}
                            availableKeys={availableKeys}
                            id={tenantId}
                        />
                    )}
                    {openModalDelete && (
                        <ModalServersRemove
                            onClose={() => setOpenModalDelete(false)}
                            type={ModalServersRemoveType.SERVER}
                            visible={openModalDelete}
                            setVisiblePopover={setVisiblePopover}
                            clearSelection={clearSelection}
                            servers={servers}
                        />
                    )}
                    {openOperationModal.rebootNotification && (
                        <RebootServersModal
                            visible
                            close={closeModal(SERVER_OPERATION_NOTIFICATION.REBOOT)}
                            handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.REBOOT]}
                            servers={servers}
                        />
                    )}
                    {openOperationModal.resetNotification && (
                        <ResetServersModal
                            visible
                            close={closeModal(SERVER_OPERATION_NOTIFICATION.RESET)}
                            handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.RESET]}
                            clearSelection={clearSelection}
                            servers={servers}
                        />
                    )}
                    {openOperationModal.shutdownNotification && (
                        <ShutDownServersModal
                            visible
                            close={closeModal(SERVER_OPERATION_NOTIFICATION.SHUTDOWN)}
                            handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.SHUTDOWN]}
                            clearSelection={clearSelection}
                            servers={servers}
                        />
                    )}
                    {openOperationModal.destroyNotification && (
                        <DestroyServersModal
                            visible
                            close={closeModal(SERVER_OPERATION_NOTIFICATION.DESTROY)}
                            handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.DESTROY]}
                            clearSelection={clearSelection}
                            servers={servers}
                        />
                    )}
                </div>
            )}
            placement="bottomRight"
            trigger="click"
            overlayClassName="popover"
            visible={visiblePopover}
            onVisibleChange={(e) => setVisiblePopover(e)}
        >
            <Button
                size="medium"
                type="border"
                className={cn(
                    theme.actions.action,
                    theme.button.action,
                )}
            >
                <div className={theme.actions.title}>
                    {intl.getMessage('title_select_action')}
                </div>
                <Icon icon="down" className={theme.actions.down} />
            </Button>
        </Popover>
    );
};

export default ActionsSelect;
