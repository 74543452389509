import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { rollbackSnapshot } from 'Actions/snapshots';
import { useIntl, CodeInput, Warning, CommonModalLayout } from 'Common';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { useSecurityActions } from 'Lib/hooks/hooks';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';

interface ModalRestoreProps {
    visible: boolean;
    close: () => void;
    tenantId: number;
    serverId: number;
    snapId: number;
}

const ModalRollback: FC<ModalRestoreProps> = ({
    visible, close, tenantId, serverId, snapId,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const history = useHistory();
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityActions(SecurityActionType.REVERT_TO_SNAPSHOT);
    const handleOk = () => {
        const link = linkPathBuilder(intl.currentLocale, RoutePath.Server, { tenantId, serverId });
        if (shouldConfirmAction && !codeSent) {
            sendConfirmationCode({
                tenantId,
                serverId,
            });
        }
        if (!shouldConfirmAction) {
            dispatch(rollbackSnapshot([tenantId, serverId, snapId, {}]));
            history.push(link);
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(rollbackSnapshot(
                [tenantId, serverId, snapId, {
                    security_code: code,
                }],
                {
                    error: () => setCodeError(true),
                    result: () => (
                        history.push(link)
                    ),
                },
            ));
        }
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('snapshots_restore_title')}
            handleSubmit={handleOk}
            handleClose={close}
            submitButtonId="restore_snapshot"
            buttonText={intl.getMessage('restore')}
            width={600}
            centered
        >
            <div className="modal__desc modal__desc--blue">
                {intl.getMessage('snapshots_restore_link')}
            </div>
            <div className="modal__desc">
                {intl.getMessage('snapshots_restore_desc')}
            </div>
            <div className="modal__desc">
                <Warning text={intl.getMessage('snapshots_restore_info')} type="info" />
            </div>
            {(codeSent && deliveryMessage) && (
                <>
                    <div className="modal__desc modal__desc--gray">
                        {deliveryMessage}
                    </div>
                    <CodeInput
                        value={code}
                        setValue={(e) => setCode(e)}
                        codeError={codeError}
                        setCodeError={(e) => setCodeError(e)}
                        onSendAgain={() => sendConfirmationCode({
                            tenantId,
                            serverId,
                        })}
                    />
                </>
            )}
        </CommonModalLayout>
    );
};

export default ModalRollback;
