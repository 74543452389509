import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { EmptyPageLayout, Icon, useIntl } from 'Common';
import { useClickOutside } from 'Hooks';
import Server from 'Entities/Server';
import CommonPublicKey from 'Entities/CommonPublicKey';
import { RoutePath } from 'Lib/helpers/routes';
import { PublicKeyInstallationState } from 'Entities/PublicKeyInstallationState';
import Key from './Key';

import s from './KeysSelect.module.pcss';

type OnChangeArg = { serverIds: number[]; pkId: number; status: PublicKeyInstallationState }[];
export type InitialState = Map<number, { status: PublicKeyInstallationState; serverIds: number[] }>;

interface KeysSelectProps {
    servers: Set<Server>;
    availableKeys?: CommonPublicKey[] | null;
    updateData: OnChangeArg;
    onChange: (data: OnChangeArg) => void;
    getKeysForChange: (data: InitialState) => void;
}

const KeysSelect: FC<KeysSelectProps> = ({
    servers,
    availableKeys,
    onChange,
    updateData,
    getKeysForChange,
}) => {
    const serversArray = Array.from(servers.values());
    const [touchCheckbox, setTouchCheckbox] = useState(false);
    const initialState = useMemo(() => {
        const initialSelect = serversArray.reduce<InitialState>((prev, serv) => {
            serv.publicKeys.forEach((pkId) => {
                if (prev.has(pkId)) {
                    prev.get(pkId)!.serverIds.push(serv.id);
                } else {
                    prev.set(pkId, { status: PublicKeyInstallationState.SOME_SERVERS, serverIds: [serv.id] });
                }
            });
            return prev;
        }, (new Map()) as InitialState);

        availableKeys?.forEach((pk) => {
            if (initialSelect.has(pk.key.id)) {
                const data = { ...initialSelect.get(pk.key.id)! };
                if (data.serverIds.length === servers.size) {
                    data.status = PublicKeyInstallationState.ALL_SERVERS;
                    initialSelect.set(pk.key.id, data);
                }
            } else {
                initialSelect.set(pk.key.id, { status: PublicKeyInstallationState.NO_SERVERS, serverIds: [] });
            }
        });
        return initialSelect;
    }, [servers, availableKeys]);

    const intl = useIntl();
    const listRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);
    const [visibleDropdown, setVisibleDropdown] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState(initialState);

    const countKeysChange = updateData?.length || 0;

    const handleDropdownVisibility = (state: boolean) => {
        setVisibleDropdown(state);
    };

    const checkTouchCheckbox = () => {
        if (updateData?.length > 0) {
            setTouchCheckbox(true);
        }
    };

    useEffect(() => {
        getKeysForChange(selectedKeys);
    }, [selectedKeys]);

    useEffect(() => {
        checkTouchCheckbox();
    }, [updateData]);

    const handleChangeSelect = (statusType: PublicKeyInstallationState, id: number) => {
        const pkData = { ...selectedKeys.get(id)! };
        const newSelectedKeys = new Map(selectedKeys);
        switch (statusType) {
            case PublicKeyInstallationState.ALL_SERVERS:
            case PublicKeyInstallationState.SOME_SERVERS:
                pkData.status = PublicKeyInstallationState.NO_SERVERS;
                break;
            case PublicKeyInstallationState.NO_SERVERS:
                pkData.status = PublicKeyInstallationState.ALL_SERVERS;
                break;
        }
        newSelectedKeys.set(id, pkData);
        setSelectedKeys(newSelectedKeys);
        const changedServers: OnChangeArg = [];
        initialState.forEach(({ status, serverIds }, key) => {
            if (status !== newSelectedKeys.get(key)!.status) {
                changedServers.push({ serverIds, status, pkId: key });
            }
        });
        onChange(changedServers);
    };

    useClickOutside([selectRef, listRef], () => handleDropdownVisibility(false));

    const keysList = (
        <div ref={listRef} className={s.list}>
            { availableKeys && availableKeys.length > 0 ? (
                availableKeys.map((k) => (
                    <Key
                        key={k.key.id}
                        id={k.key.id}
                        title={k.key.title}
                        ownerUserName={k.key.title}
                        type={k.key.type}
                        selectedKeys={selectedKeys}
                        handleChangeSelect={handleChangeSelect}
                    />
                ))
            ) : (
                <EmptyPageLayout
                    desc={intl.getMessage('create_key_description')}
                    linkText={intl.getMessage('create_key')}
                    link={RoutePath.PublicKeys}
                    type="dropdown"
                />
            )}
        </div>
    );
    return (
        <Dropdown
            overlay={keysList}
            placement="bottomLeft"
            visible={visibleDropdown}
            trigger={['click']}
        >
            <div>
                {visibleDropdown ? (
                    <div
                        className={cn(s.select, s.select_open)}
                        ref={selectRef}
                    >
                        {intl.getMessage('modal_select_keys')}
                        <Icon icon="down" className={s.arrow} />
                    </div>
                ) : (
                    <button
                        type="button"
                        className={s.select}
                        onClick={(e) => {
                            e.stopPropagation();
                            setVisibleDropdown(true);
                        }}
                    >
                        {touchCheckbox && countKeysChange > 0 ? (
                            intl.getPlural('modal_change_keys', countKeysChange)
                        ) : (
                            intl.getMessage('modal_select_keys')
                        )}
                        <Icon icon="down" className={s.arrow} />
                    </button>
                )}
            </div>
        </Dropdown>
    );
};

export default KeysSelect;
