import React, { FC, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { useOffsetLoader, useTitle } from 'Hooks';
import { clearTickets, listTickets } from 'Actions/support';
import { useIntl, LoaderPageLayout, InnerPageLayout } from 'Common';
import { TICKETS_STATE } from 'Lib/helpers/consts';
import { Store } from 'Store';
import Ticket from 'Entities/Ticket';
import theme from 'Lib/theme';

import { List, Header } from './components';

const TICKETS_POLLING_TIMEOUT = 240000;

const { TabPane } = Tabs;

interface TicketsProps {
    tickets: Ticket[] | null;
}

const Tickets: FC<TicketsProps> = ({ tickets }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('support_page_title'));

    const dispatch = useDispatch();
    const { params: { open } } = useRouteMatch<{ open: TICKETS_STATE }>();
    const [currentOpen, setCurrentOpen] = useState(open);
    const { reset } = useOffsetLoader(tickets, (offset, limit) => listTickets([
        undefined,
        currentOpen === TICKETS_STATE.OPEN,
        undefined,
        offset * limit,
        limit,
    ]));

    useEffect(() => {
        if (open !== currentOpen) {
            setCurrentOpen(open);
            dispatch(clearTickets());
            reset();
        }
    }, [open]);

    useEffect(() => {
        window.scrollTo({ top: 0 });

        const timer = setInterval(
            () => {
                dispatch(clearTickets());
                reset();
            },
            TICKETS_POLLING_TIMEOUT,
        );
        return () => {
            clearInterval(timer);
        };
    }, []);

    if (!tickets) {
        return (
            <InnerPageLayout
                header={<Header />}
                className={theme.content.with_actions}
            >
                <LoaderPageLayout />
            </InnerPageLayout>
        );
    }

    const activeKey = open === TICKETS_STATE.CLOSED
        ? TICKETS_STATE.CLOSED : TICKETS_STATE.OPEN;

    return (
        <InnerPageLayout
            header={<Header />}
            className={theme.content.with_actions}
        >
            <Tabs
                className="tabs tabs--hidden-bar"
                activeKey={activeKey}
            >
                <TabPane key={TICKETS_STATE.OPEN}>
                    <List reset={reset} unread />
                </TabPane>
                <TabPane key={TICKETS_STATE.CLOSED}>
                    <List reset={reset} />
                </TabPane>
            </Tabs>
        </InnerPageLayout>
    );
};

const selectTickets = (store: Store) => store.support.tickets;

const selector = createSelector([selectTickets],
    (tickets) => ({ tickets: tickets ? Array.from(tickets.values()) : null }));

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Tickets);
