import React, { FC } from 'react';

import { useIntl, DeleteModalLayout } from 'Common';

interface DeleteCardProps {
    visible: boolean;
    handleClose: () => void;
    handleDelete: () => void;
}

const DeleteCard: FC<DeleteCardProps> = ({ visible, handleClose, handleDelete }) => {
    const intl = useIntl();

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('delete_card')}
            handleDelete={handleDelete}
            handleClose={handleClose}
        >
            <div className="modal__desc">
                {intl.getMessage('delete_card_desc')}
            </div>
        </DeleteModalLayout>
    );
};

export default DeleteCard;
