import React, { FC, useState } from 'react';

import { useIntl, Link, DashboardHeader, Button } from 'Common';
import AccountDetails from 'Entities/AccountDetails';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import cn from 'classnames';

import ModalAct from './ModalAct';

interface HeaderProps {
    details: AccountDetails | null;
}

const Header: FC<HeaderProps> = ({ details }) => {
    const intl = useIntl();
    const [openModal, setOpenModal] = useState(false);

    if (!details) {
        return null;
    }

    const { showBillingDocuments } = details;

    return (
        <>
            <DashboardHeader
                navigation={(
                    <>
                        <Link
                            className={theme.header.link}
                            to={RoutePath.Billing}
                        >
                            {intl.getMessage('menu_billing')}
                        </Link>
                        <Link
                            to={RoutePath.Expenses}
                            className={theme.header.link}
                        >
                            {intl.getMessage('billing_expenses')}
                        </Link>
                        <div className={cn(theme.header.link, theme.header.active)}>
                            {intl.getMessage('billing_documents')}
                        </div>
                    </>
                )}
                actions={showBillingDocuments && (
                    <Button
                        type="primary"
                        size="medium"
                        onClick={() => setOpenModal(true)}
                    >
                        {intl.getMessage('generate_act')}
                    </Button>
                )}
            />
            {openModal && showBillingDocuments && (
                <ModalAct
                    visible={openModal}
                    close={() => setOpenModal(false)}
                />
            )}
        </>
    );
};

export default Header;
