// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISoleTraderInfo {
    accounting_email?: string;
    inn: string;
    legal_address: string;
    ogrnip: string;
    post_address: string;
    trademark: string;
}

export default class SoleTraderInfo {
    readonly _accounting_email: string | undefined;

    /**
     * Description: Sole trader accounting email
     * Example: kartseva.elena@rambler.ru
     */
    get accountingEmail(): string | undefined {
        return this._accounting_email;
    }

    readonly _inn: string;

    /**
     * Description: Sole trader INN number
     * Example: 781640172105
     */
    get inn(): string {
        return this._inn;
    }

    static innValidate(inn: string): boolean {
        return typeof inn === 'string' && !!inn.trim();
    }

    readonly _legal_address: string;

    /**
     * Description: Sole trader legal address
     * Example: 123456, Россия, Москва, ул. Правды, д.11, корп.1, кв.11
     */
    get legalAddress(): string {
        return this._legal_address;
    }

    static legalAddressValidate(legalAddress: string): boolean {
        return typeof legalAddress === 'string' && !!legalAddress.trim();
    }

    readonly _ogrnip: string;

    /**
     * Description: Sole trader PSRNSP (OGRNIP)
     * Example: 313784708700646
     */
    get ogrnip(): string {
        return this._ogrnip;
    }

    static ogrnipValidate(ogrnip: string): boolean {
        return typeof ogrnip === 'string' && !!ogrnip.trim();
    }

    readonly _post_address: string;

    /**
     * Description: Sole trader address for correspondence
     * Example: 123456, Россия, Москва, ул. Правды, д.11, корп.1, кв.11
     */
    get postAddress(): string {
        return this._post_address;
    }

    static postAddressValidate(postAddress: string): boolean {
        return typeof postAddress === 'string' && !!postAddress.trim();
    }

    readonly _trademark: string;

    /**
     * Description: Sole trader trademark
     * Example: КАРЦЕВА ЕЛЕНА ВИКТОРОВНА
     */
    get trademark(): string {
        return this._trademark;
    }

    static trademarkValidate(trademark: string): boolean {
        return typeof trademark === 'string' && !!trademark.trim();
    }

    constructor(props: ISoleTraderInfo) {
        if (typeof props.accounting_email === 'string') {
            this._accounting_email = props.accounting_email.trim();
        }
        this._inn = props.inn.trim();
        this._legal_address = props.legal_address.trim();
        this._ogrnip = props.ogrnip.trim();
        this._post_address = props.post_address.trim();
        this._trademark = props.trademark.trim();
    }

    serialize(): ISoleTraderInfo {
        const data: ISoleTraderInfo = {
            inn: this._inn,
            legal_address: this._legal_address,
            ogrnip: this._ogrnip,
            post_address: this._post_address,
            trademark: this._trademark,
        };
        if (typeof this._accounting_email !== 'undefined') {
            data.accounting_email = this._accounting_email;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            inn: typeof this._inn === 'string' && !!this._inn.trim(),
            trademark: typeof this._trademark === 'string' && !!this._trademark.trim(),
            ogrnip: typeof this._ogrnip === 'string' && !!this._ogrnip.trim(),
            legal_address: typeof this._legal_address === 'string' && !!this._legal_address.trim(),
            post_address: typeof this._post_address === 'string' && !!this._post_address.trim(),
            accounting_email: !this._accounting_email ? true : typeof this._accounting_email === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISoleTraderInfo): SoleTraderInfo {
        return new SoleTraderInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        accountingEmail: 'accounting_email',
        inn: 'inn',
        legalAddress: 'legal_address',
        ogrnip: 'ogrnip',
        postAddress: 'post_address',
        trademark: 'trademark',
        }
;

    mergeDeepWith(props: Partial<SoleTraderInfo>): SoleTraderInfo {
        const updateData: Partial<ISoleTraderInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SoleTraderInfo) => {
            const updateKey = this.keys[key] as keyof ISoleTraderInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISoleTraderInfo, keyof ISoleTraderInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SoleTraderInfo({ ...this.serialize(), ...updateData });
    }
}
