import { takeEvery, put, call, delay } from 'redux-saga/effects';

import ProfileApi from 'Apis/profile';
import AccountApi from 'Apis/account';
import MiscApiApi from 'Apis/miscApi';
import {
    ProfileActions,
    getProfileInfo as getProfileInfoAct,
    saveProfileInfo,
    getLoginAttempts as getLoginAttemptsAct,
    saveLoginAttempts,
    sendPhoneConfirmationCode as sendPhoneConfirmationCodeAct,
    confirmPhoneWithCode as confirmPhoneWithCodeAct,
    savePayerInfo,
    updateProfile as updateProfileAct,
    updatePayerInfo as updatePayerInfoAct,
    getTrialPeriodActivationStatus as getTrialPeriodActivationStatusAct,
    getPhoneCodes as getPhoneCodesAct,
    savePhoneCodes,
    addClientReviewKey as addClientReviewKeyAct,
    getConfiguration as getConfigurationAct,
    saveConfiguration,
    updateTariffRecommendations as updateTariffRecommendationsAct,
    updateClientApiKey as updateClientApiKeyAct,
} from 'Actions/profile';
import { getAccountDetails } from 'Actions/account';
import { ActionType } from 'Actions/.';
import Profile, { IProfile } from 'Entities/Profile';
import ProfileLoginAttempt, { IProfileLoginAttempt } from 'Entities/ProfileLoginAttempt';
import { IProfileTrialPeriodActivationStatusResponse } from 'Entities/ProfileTrialPeriodActivationStatusResponse';
import { ProfileTrialPeriodActivationStatus } from 'Entities/ProfileTrialPeriodActivationStatus';
import PayerInfo, { IPayerInfo } from 'Entities/PayerInfo';
import CountriesPhoneCodesResponse, { ICountriesPhoneCodesResponse } from 'Entities/CountriesPhoneCodesResponse';
import Configuration, { IConfiguration } from 'Entities/Configuration';
import { IApiKey } from 'Entities/ApiKey';

import { errorChecker, addError } from './utils';

function* getPayerInfo(type: ActionType) {
    const payerInfoResp: IPayerInfo[] = yield call(AccountApi.getAccountPayers);
    const { result, error } = errorChecker<IPayerInfo[]>(payerInfoResp);
    if (result) {
        yield put(savePayerInfo(result.map((r) => new PayerInfo(r))));
    }
    if (error) {
        yield addError(error, type);
    }
}

function* getProfileInfo(action: ReturnType<typeof getProfileInfoAct>) {
    const { onlyInfo } = action.payload || {};
    const profileResp: IProfile = yield call(ProfileApi.getProfile);
    const { result: profileResult, error: profileError } = errorChecker<IProfile>(profileResp);
    if (profileResult) {
        yield put(saveProfileInfo(new Profile(profileResult)));
    }
    if (profileError) {
        yield addError(profileError, action.type);
    }
    if (!onlyInfo) {
        yield getPayerInfo(action.type);
    }
}

function* getLoginAttempts(action: ReturnType<typeof getLoginAttemptsAct>) {
    const { payload } = action;
    const response: IProfileLoginAttempt[] = yield call(ProfileApi.getLoginAttempts, ...payload);
    const { result, error } = errorChecker<IProfileLoginAttempt[]>(response);
    if (result) {
        const loginAttempts = result.map((t) => new ProfileLoginAttempt(t));
        yield put(saveLoginAttempts(loginAttempts));
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* sendPhoneConfirmationCode(action: ReturnType<typeof sendPhoneConfirmationCodeAct>) {
    const { payload, callback } = action;
    const response: number = yield call(ProfileApi.sendPhoneConfirmationCode, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (error && callback?.error) {
        callback?.error(error);
    }
    if (result && callback?.result) {
        callback?.result();
    }
}

function* confirmPhoneWithCode(action: ReturnType<typeof confirmPhoneWithCodeAct>) {
    const { payload, callback } = action;
    const response: number = yield call(ProfileApi.confirmPhoneWithCode, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getProfileInfoAct({ onlyInfo: true }));
        if (callback?.result) {
            callback.result();
        }
    }
    if (error && callback?.error) {
        callback.error(error);
    }
}

function* updateProfile(action: ReturnType<typeof updateProfileAct>) {
    const { payload, callback } = action;
    const response: number = yield call(ProfileApi.updateProfile, ...payload);
    const { result, error } = errorChecker<number>(response);
    if (result) {
        yield put(getProfileInfoAct({ onlyInfo: true }));
        if (callback?.result) {
            callback.result();
        }
    }
    if (error && callback?.error) {
        callback.error(error);
    }
}

function* updatePayerInfo(action: ReturnType<typeof updatePayerInfoAct>) {
    const { payload } = action;
    const response: IPayerInfo = yield call(AccountApi.updateAccountPayer, ...payload);
    const { result, error } = errorChecker<IPayerInfo>(response);
    if (result) {
        yield getPayerInfo(action.type);
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getTrialPeriodActivationStatus(
    action: ReturnType<typeof getTrialPeriodActivationStatusAct>,
) {
    const { callback } = action;
    const response: IProfileTrialPeriodActivationStatusResponse = yield call(
        ProfileApi.getTrialPeriodActivationStatus,
    );
    const { result, error } = errorChecker<IProfileTrialPeriodActivationStatusResponse>(response);
    if (result) {
        switch (result.trial_status) {
            case ProfileTrialPeriodActivationStatus.PENDING:
                yield delay(1000);
                yield put(getTrialPeriodActivationStatusAct([], callback));
                return;
            case ProfileTrialPeriodActivationStatus.SUCCESS:
                callback?.result!();
                break;
            case ProfileTrialPeriodActivationStatus.DISABLED:
            case ProfileTrialPeriodActivationStatus.NONE:
                callback?.error!();
                break;
        }
    }
    yield put(getProfileInfoAct());
    yield put(getAccountDetails());
    if (error) {
        yield addError(error, action.type);
    }
}

function* getPhoneCodes(action: ReturnType<typeof getPhoneCodesAct>) {
    const response: ICountriesPhoneCodesResponse = yield call(MiscApiApi.getCountriesPhoneCodes);
    const { result, error } = errorChecker<ICountriesPhoneCodesResponse>(response);
    if (result) {
        yield put(savePhoneCodes(new CountriesPhoneCodesResponse(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* addClientReviewKey(action: ReturnType<typeof addClientReviewKeyAct>) {
    const { payload } = action;
    const response: number = yield call(ProfileApi.addClientReviewKey, ...payload);
    const { error } = errorChecker<number>(response);
    if (error) {
        yield addError(error, action.type);
    }
}

function* getConfiguration(action: ReturnType<typeof getConfigurationAct>) {
    const response: IConfiguration = yield call(MiscApiApi.getConfiguration);
    const { result, error } = errorChecker<IConfiguration>(response);
    if (result) {
        yield put(saveConfiguration(new Configuration(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateTariffRecommendations(action: ReturnType<typeof updateTariffRecommendationsAct>) {
    const { payload } = action;
    const response: number = yield call(ProfileApi.updateRecommendationsSubscriptionForClient, ...payload);
    const { result, error } = errorChecker<number>(response);

    if (result) {
        yield put(getProfileInfoAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateClientApiKey(action: ReturnType<typeof updateClientApiKeyAct>) {
    const { callback } = action;
    const response: IApiKey = yield call(ProfileApi.updateClientApiKey);
    const { result, error } = errorChecker<IApiKey>(response);

    if (result) {
        if (callback?.result) {
            callback.result(result);
        }
    }
    if (error && callback?.error) {
        callback.error(error);
    }
}

function* profileSaga(): Generator {
    yield takeEvery<ReturnType<typeof getProfileInfoAct>>(
        ProfileActions.GetProfileInfo, getProfileInfo,
    );
    yield takeEvery<ReturnType<typeof getLoginAttemptsAct>>(
        ProfileActions.GetLoginAttempts, getLoginAttempts,
    );
    yield takeEvery<ReturnType<typeof sendPhoneConfirmationCodeAct>>(
        ProfileActions.SendPhoneConfirmationCode,
        sendPhoneConfirmationCode,
    );
    yield takeEvery<ReturnType<typeof confirmPhoneWithCodeAct>>(
        ProfileActions.ConfirmPhoneWithCode,
        confirmPhoneWithCode,
    );
    yield takeEvery<ReturnType<typeof updateProfileAct>>(
        ProfileActions.UpdateProfile, updateProfile,
    );
    yield takeEvery<ReturnType<typeof updatePayerInfoAct>>(
        ProfileActions.UpdatePayerInfo, updatePayerInfo,
    );
    yield takeEvery<ReturnType<typeof getTrialPeriodActivationStatusAct>>(
        ProfileActions.GetTrialPeriodActivationStatus,
        getTrialPeriodActivationStatus,
    );
    yield takeEvery<ReturnType<typeof getPhoneCodesAct>>(
        ProfileActions.GetPhoneCodes, getPhoneCodes,
    );
    yield takeEvery<ReturnType<typeof addClientReviewKeyAct>>(
        ProfileActions.AddClientReviewKey, addClientReviewKey,
    );
    yield takeEvery<ReturnType<typeof getConfigurationAct>>(
        ProfileActions.GetConfiguration, getConfiguration,
    );
    yield takeEvery<ReturnType<typeof updateTariffRecommendationsAct>>(
        ProfileActions.UpdateTariffRecommendations, updateTariffRecommendations,
    );
    yield takeEvery<ReturnType<typeof updateClientApiKeyAct>>(
        ProfileActions.UpdateClientApiKey, updateClientApiKey,
    );
}

export default profileSaga;
