import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import ServerDistribution, { IServerDistribution } from 'Entities/ServerDistribution';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class DistributionsApi {
    static async listDistributions(optionalToken?: string): Promise<IServerDistribution[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/distributions`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
