import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import AccountDetails from 'Entities/AccountDetails';
import { Store } from 'Store';

import AutoTopUpForm from './AutoTopUpForm';

const selectProfile = (store: Store) => store.profile.info;
const selectProfilePayer = (store: Store) => store.profile.profilePayer;

const selector = createSelector(
    [selectProfile, selectProfilePayer],
    (profile, payers) => {
        const payer = payers?.find((p) => p.active);
        return { profile, payer };
    },
);

interface AutoTopUpContainerProps {
    details: AccountDetails | null;
    setOpenEditModal?: (state: boolean) => void;
}

const AutoTopUp: FC<AutoTopUpContainerProps> = ({
    details,
    setOpenEditModal,
}) => {
    const { payer, profile } = useSelector(selector);

    if (!details || !payer || !profile) {
        return null;
    }
    return (
        <AutoTopUpForm
            details={details}
            payer={payer}
            profile={profile}
            setOpenEditModal={setOpenEditModal}
        />
    );
};

export default AutoTopUp;
