import BackupPolicy, { IBackupPolicy } from './BackupPolicy';
import TenantResourceLimits, { ITenantResourceLimits } from './TenantResourceLimits';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITenant {
    backup_policy: IBackupPolicy;
    description: string;
    guests: number;
    id: number;
    is_default: boolean;
    limits: ITenantResourceLimits;
    owner_user_email: string;
    owner_user_id: number;
}

export default class Tenant {
    readonly _backup_policy: BackupPolicy;

    get backupPolicy(): BackupPolicy {
        return this._backup_policy;
    }

    readonly _description: string;

    /**
     * Description: Description
     * Example: Default project
     */
    get description(): string {
        return this._description;
    }

    static descriptionValidate(description: string): boolean {
        return typeof description === 'string' && !!description.trim();
    }

    readonly _guests: number;

    /** */
    get guests(): number {
        return this._guests;
    }

    static guestsValidate(guests: number): boolean {
        return typeof guests === 'number';
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 24997
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _is_default: boolean;

    /**
     * Description: Is default tenant
     * Example: true
     */
    get isDefault(): boolean {
        return this._is_default;
    }

    static isDefaultValidate(isDefault: boolean): boolean {
        return typeof isDefault === 'boolean';
    }

    readonly _limits: TenantResourceLimits;

    get limits(): TenantResourceLimits {
        return this._limits;
    }

    readonly _owner_user_email: string;

    /**
     * Description: Owner user email
     * Example: admin@serveroid.com
     */
    get ownerUserEmail(): string {
        return this._owner_user_email;
    }

    static ownerUserEmailValidate(ownerUserEmail: string): boolean {
        return typeof ownerUserEmail === 'string' && !!ownerUserEmail.trim();
    }

    readonly _owner_user_id: number;

    /**
     * Description: Owner user id
     * Example: 37132
     */
    get ownerUserId(): number {
        return this._owner_user_id;
    }

    static ownerUserIdValidate(ownerUserId: number): boolean {
        return typeof ownerUserId === 'number';
    }

    constructor(props: ITenant) {
        this._backup_policy = new BackupPolicy(props.backup_policy);
        this._description = props.description.trim();
        this._guests = props.guests;
        this._id = props.id;
        this._is_default = props.is_default;
        this._limits = new TenantResourceLimits(props.limits);
        this._owner_user_email = props.owner_user_email.trim();
        this._owner_user_id = props.owner_user_id;
    }

    serialize(): ITenant {
        const data: ITenant = {
            backup_policy: this._backup_policy.serialize(),
            description: this._description,
            guests: this._guests,
            id: this._id,
            is_default: this._is_default,
            limits: this._limits.serialize(),
            owner_user_email: this._owner_user_email,
            owner_user_id: this._owner_user_id,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            description: typeof this._description === 'string' && !!this._description.trim(),
            owner_user_id: typeof this._owner_user_id === 'number',
            owner_user_email: typeof this._owner_user_email === 'string' && !!this._owner_user_email.trim(),
            is_default: typeof this._is_default === 'boolean',
            backup_policy: this._backup_policy.validate().length === 0,
            limits: this._limits.validate().length === 0,
            guests: typeof this._guests === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITenant): Tenant {
        return new Tenant(props);
    }

    readonly keys: { [key: string]: string } = {
        backupPolicy: 'backup_policy',
        description: 'description',
        guests: 'guests',
        id: 'id',
        isDefault: 'is_default',
        limits: 'limits',
        ownerUserEmail: 'owner_user_email',
        ownerUserId: 'owner_user_id',
        }
;

    mergeDeepWith(props: Partial<Tenant>): Tenant {
        const updateData: Partial<ITenant> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Tenant) => {
            const updateKey = this.keys[key] as keyof ITenant;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITenant, keyof ITenant>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Tenant({ ...this.serialize(), ...updateData });
    }
}
