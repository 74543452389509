import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, useIntl } from 'Common';
import { roundDiff, roundNumber } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

interface DiffProps {
    value: number;
    diff?: number;
    list?: boolean;
}

const Diff: FC<DiffProps> = ({ value, diff, list }) => {
    const intl = useIntl();

    const getDiffValue = (v: number | undefined) => {
        if (v === undefined) {
            return 0;
        }

        const roundedDiff = roundDiff(v);

        return v > 0 ? `+${roundedDiff}` : roundedDiff;
    };

    return (
        <div className={cn(theme.score.value, { [theme.score.value_list]: list })}>
            <span
                className={cn(
                    theme.score.valueInner,
                    theme.common.textOverflow,
                    { [theme.color.grayHeaders]: value === 0 },
                )}
            >
                {intl.getMessage('price_simple', { value: roundNumber(value) })}
            </span>
            {diff ? (
                <div
                    className={cn(
                        theme.score.diff,
                        { [theme.score.diff_negative]: diff > 0 },
                        { [theme.score.diff_up]: diff > 0 },
                        { [theme.score.diff_positive]: diff < 0 },
                    )}
                >
                    <span className={theme.score.percent}>
                        {intl.getMessage('price_simple', { value: getDiffValue(diff) })}
                    </span>
                    <Icon
                        className={theme.score.diffIcon}
                        icon="arrow_down"
                    />
                </div>
            ) : (
                <div className={theme.score.diff}>
                    <span className={theme.score.percent}>
                        {intl.getMessage('price_simple', { value: 0 })}
                    </span>
                </div>
            )}
        </div>
    );
};

export default Diff;
