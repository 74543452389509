import React, { FC } from 'react';
import { RoutePath } from 'Lib/helpers/routes';

import { useIntl, Link, DashboardHeader, Button } from 'Common';
import theme from 'Lib/theme';

const EmptyHeader: FC = () => {
    const intl = useIntl();

    return (
        <DashboardHeader
            mobile
            navigation={(
                <>
                    <Link to={RoutePath.ServersList} className={theme.header.back}>
                        <Button
                            type="icon"
                            size="medium"
                            icon="home"
                        />
                    </Link>
                    <div className={theme.header.title}>
                        {intl.getMessage('server')}
                    </div>
                </>
            )}
        />
    );
};

export default EmptyHeader;
