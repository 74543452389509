// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IReferralInfo {
    ref_id: number;
    referrer_reg_discount: number;
    referrer_reg_discount_enabled: boolean;
}

export default class ReferralInfo {
    readonly _ref_id: number;

    /**
     * Description: Referral's id
     * Example: 7635
     */
    get refId(): number {
        return this._ref_id;
    }

    static refIdValidate(refId: number): boolean {
        return typeof refId === 'number';
    }

    readonly _referrer_reg_discount: number;

    /**
     * Description: REFERRER_REG_DISCOUNT value for clients with this refId, if discount enabled
     * Example: 15
     */
    get referrerRegDiscount(): number {
        return this._referrer_reg_discount;
    }

    static referrerRegDiscountValidate(referrerRegDiscount: number): boolean {
        return typeof referrerRegDiscount === 'number';
    }

    readonly _referrer_reg_discount_enabled: boolean;

    /**
     * Description: If clients with this refId are subjected for REFERRER_REG_DISCOUNT
     * Example: true
     */
    get referrerRegDiscountEnabled(): boolean {
        return this._referrer_reg_discount_enabled;
    }

    static referrerRegDiscountEnabledValidate(referrerRegDiscountEnabled: boolean): boolean {
        return typeof referrerRegDiscountEnabled === 'boolean';
    }

    constructor(props: IReferralInfo) {
        this._ref_id = props.ref_id;
        this._referrer_reg_discount = props.referrer_reg_discount;
        this._referrer_reg_discount_enabled = props.referrer_reg_discount_enabled;
    }

    serialize(): IReferralInfo {
        const data: IReferralInfo = {
            ref_id: this._ref_id,
            referrer_reg_discount: this._referrer_reg_discount,
            referrer_reg_discount_enabled: this._referrer_reg_discount_enabled,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            ref_id: typeof this._ref_id === 'number',
            referrer_reg_discount_enabled: typeof this._referrer_reg_discount_enabled === 'boolean',
            referrer_reg_discount: typeof this._referrer_reg_discount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IReferralInfo): ReferralInfo {
        return new ReferralInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        refId: 'ref_id',
        referrerRegDiscount: 'referrer_reg_discount',
        referrerRegDiscountEnabled: 'referrer_reg_discount_enabled',
        }
;

    mergeDeepWith(props: Partial<ReferralInfo>): ReferralInfo {
        const updateData: Partial<IReferralInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ReferralInfo) => {
            const updateKey = this.keys[key] as keyof IReferralInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IReferralInfo, keyof IReferralInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ReferralInfo({ ...this.serialize(), ...updateData });
    }
}
