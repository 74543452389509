import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, DashboardHeader, Link } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

const Header: FC = () => {
    const intl = useIntl();

    return (
        <DashboardHeader
            navigation={(
                <>
                    <div className={cn(theme.header.link, theme.header.active)}>
                        {intl.getMessage('partners')}
                    </div>
                    <Link
                        to={RoutePath.PartnerFinance}
                        className={theme.header.link}
                    >
                        {intl.getMessage('partners_payments')}
                    </Link>
                </>
            )}
        />
    );
};

export default Header;
