import { Action } from 'store/actions';
import {
    OperationProgressActions,
    saveServerOperationProgress,
    saveServerOperationProgressBatch,
    removeDoneProgress,
} from 'Actions/operationProgress';
import { OperationType } from 'Entities/OperationType';

import { OperationProgressStore, operationProgressInitialState as initialState } from '../initialStates';

const reducer = (
    state: OperationProgressStore = initialState,
    action: Action,
): OperationProgressStore => {
    switch (action.type as OperationProgressActions) {
        case OperationProgressActions.SaveServerOperationProgress: {
            const { payload } = action as ReturnType<typeof saveServerOperationProgress>;

            const { commonOperations, backupOperations } = state;

            if (payload.type === OperationType.DOWNLOAD_BACKUP) {
                const op = new Map(backupOperations);
                op.set(payload.serverId, payload);

                return {
                    ...state,
                    backupOperations: op,
                };
            }

            const op = new Map(commonOperations);
            op.set(payload.serverId, payload);
            return {
                ...state,
                commonOperations: op,
            };
        }
        case OperationProgressActions.SaveServerOperationProgressBatch: {
            const { payload } = action as ReturnType<typeof saveServerOperationProgressBatch>;
            const op = new Map(state.commonOperations);
            payload.forEach((o) => {
                op.set(o.serverId, o);
            });
            return {
                ...state,
                commonOperations: op,
            };
        }
        case OperationProgressActions.RemoveDoneProgress: {
            const { payload } = action as ReturnType<typeof removeDoneProgress>;

            if (payload.type === OperationType.DOWNLOAD_BACKUP) {
                const op = new Map(state.backupOperations);
                op.delete(payload.serverId);

                return {
                    ...state,
                    backupOperations: op,
                };
            }

            const op = new Map(state.commonOperations);
            op.delete(payload.serverId);
            return {
                ...state,
                commonOperations: op,
            };
        }
        default:
            return state;
    }
};

export default reducer;
