import React, { FC } from 'react';
import { Input } from 'antd';
import cn from 'classnames';

import { Icon } from 'Common';
import theme from 'Lib/theme';

interface FieldProps {
    id: string;
    label: string;
    value: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
    error?: boolean;
}

const Field: FC<FieldProps> = ({ id, label, value, disabled, onChange, error }) => {
    return (
        <label htmlFor={id} className={theme.modal.group}>
            <div className={theme.modal.label}>
                {label}
            </div>
            <Input
                id={id}
                size="large"
                placeholder={label}
                className={cn('input', 'input_medium', { input_error: error })}
                value={value}
                onChange={(e) => onChange && onChange(e.target.value)}
                suffix={disabled ? <Icon icon="lock" className={theme.color.gray} /> : ''}
                disabled={disabled}
            />
        </label>
    );
};

export default Field;
