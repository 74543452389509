import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, DashboardHeader, Link, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import ModalImportZone from './ModalImportZone';
import s from './Zone.module.pcss';

interface HeaderProps {
    openModal: () => void;
}

const Header: FC<HeaderProps> = ({ openModal }) => {
    const intl = useIntl();
    const [openImport, setOpenImport] = useState(false);
    return (
        <>
            <DashboardHeader
                navigation={(
                    <>
                        <div className={cn(theme.header.link, theme.header.active)}>
                            {intl.getMessage('menu_dns')}
                        </div>
                        <Link
                            to={RoutePath.DnsPtr}
                            className={theme.header.link}
                        >
                            {intl.getMessage('dns_ptr_title')}
                        </Link>
                    </>
                )}
                actions={(
                    <>
                        <Button
                            type="link"
                            size="small"
                            className={cn(theme.button.link_black, s.importBtn)}
                            onClick={() => setOpenImport(true)}
                        >
                            {intl.getMessage('import')}
                        </Button>
                        <Button
                            type="primary"
                            size="medium"
                            onClick={openModal}
                        >
                            {intl.getMessage('dns_zone_add_short')}
                        </Button>
                    </>
                )}
            />
            {openImport && (
                <ModalImportZone visible={openImport} handleClose={() => setOpenImport(false)} />
            )}
        </>
    );
};

export default Header;
