// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IBatchSecurityCodeSendRequest {
    server_ids: number[];
    tenant_id: number;
}

export default class BatchSecurityCodeSendRequest {
    readonly _server_ids: number[];

    /** */
    get serverIds(): number[] {
        return this._server_ids;
    }

    static serverIdsValidate(serverIds: number[]): boolean {
        return serverIds.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    readonly _tenant_id: number;

    /**
     * Description: Tenant identifier
     * Example: 19934
     */
    get tenantId(): number {
        return this._tenant_id;
    }

    static tenantIdValidate(tenantId: number): boolean {
        return typeof tenantId === 'number';
    }

    constructor(props: IBatchSecurityCodeSendRequest) {
        this._server_ids = props.server_ids;
        this._tenant_id = props.tenant_id;
    }

    serialize(): IBatchSecurityCodeSendRequest {
        const data: IBatchSecurityCodeSendRequest = {
            server_ids: this._server_ids,
            tenant_id: this._tenant_id,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            tenant_id: typeof this._tenant_id === 'number',
            server_ids: this._server_ids.reduce((result, p) => result && typeof p === 'number', true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IBatchSecurityCodeSendRequest): BatchSecurityCodeSendRequest {
        return new BatchSecurityCodeSendRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        serverIds: 'server_ids',
        tenantId: 'tenant_id',
        }
;

    mergeDeepWith(props: Partial<BatchSecurityCodeSendRequest>): BatchSecurityCodeSendRequest {
        const updateData: Partial<IBatchSecurityCodeSendRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof BatchSecurityCodeSendRequest) => {
            const updateKey = this.keys[key] as keyof IBatchSecurityCodeSendRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IBatchSecurityCodeSendRequest, keyof IBatchSecurityCodeSendRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new BatchSecurityCodeSendRequest({ ...this.serialize(), ...updateData });
    }
}
