import React, { FC } from 'react';
import { useIntl, Button } from 'Common';

import s from './Submit.module.pcss';

interface SubmitProps {
    id?: string;
    title?: string;
    disabled?: boolean;
    handleSubmit?: () => void;
}

const Submit: FC<SubmitProps> = ({ title, disabled, id, handleSubmit }) => {
    const intl = useIntl();

    return (
        <div className={s.actions}>
            <Button
                id={id || 'onboarding'}
                type="primary"
                size="big"
                htmlType={handleSubmit ? 'button' : 'submit'}
                onClick={handleSubmit}
                disabled={disabled}
            >
                {title || intl.getMessage('onboaring_continue')}
            </Button>
        </div>
    );
};

export default Submit;
