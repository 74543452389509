import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, IpList, ServerInfo, Comment } from 'Common';
import ServerEntity from 'Entities/Server';

import { Main } from './components';
import s from './Body.module.pcss';

interface BodyProps {
    server: ServerEntity;
    loading: boolean;
    installing: boolean;
}

const Body: FC<BodyProps> = ({ server, loading, installing }) => {
    const intl = useIntl();

    return (
        <div className={s.body}>
            <div className={s.info}>
                <Main server={server} installing={installing} />
                <ServerInfo
                    server={server}
                    loading={loading}
                    serverPage
                />
            </div>
            {!installing && (
                <>
                    <div className={s.ips}>
                        <div className={cn(s.row, s.row_ips)}>
                            <div className={cn(s.label, s.label_ip)}>
                                {intl.getMessage('server_ip_list_ips')}
                            </div>
                            <IpList
                                server={server}
                                progress={loading}
                                type="compact"
                            />
                        </div>
                    </div>
                    <Comment server={server} loading={loading} />
                </>
            )}
        </div>
    );
};

export default Body;
