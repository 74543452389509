import React, { FC } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';

import { useIntl } from 'Common';
import ProfileLoginAttempt from 'Entities/ProfileLoginAttempt';
import theme from 'Lib/theme';

import s from './Session.module.pcss';

interface SessionProps {
    attempt: ProfileLoginAttempt;
}

const Session: FC<SessionProps> = ({ attempt }) => {
    const intl = useIntl();
    const time = dayjs(attempt.timeAddedMillis);
    const formattedTime = time.format(intl.getMessage('time_format_short_month_year'));

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
            )}
        >
            <div className={cn(theme.card.grid, theme.card.grid_session)}>
                <div className={theme.common.textOverflow}>
                    {attempt.successful ? (
                        intl.getMessage('history_success')
                    ) : (
                        intl.getMessage('history_fail')
                    )}
                </div>
                <div className={theme.common.textOverflow}>
                    {attempt.ip}
                </div>
                <div className={s.date}>
                    {formattedTime}
                </div>
            </div>
        </div>
    );
};

export default Session;
