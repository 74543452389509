import { DnsRecordType } from './DnsRecordType';
import { DnsTemplateProvider } from './DnsTemplateProvider';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsTemplateRecord {
    data: string;
    host: string;
    priority?: number;
    template_provider: DnsTemplateProvider;
    ttl_seconds: number;
    type: DnsRecordType;
}

export default class DnsTemplateRecord {
    readonly _data: string;

    /**
     * Description: Points to
     * Example: ASPMX.L.GOOGLE.COM.
     */
    get data(): string {
        return this._data;
    }

    static dataValidate(data: string): boolean {
        return typeof data === 'string' && !!data.trim();
    }

    readonly _host: string;

    /**
     * Description: Host or @ for root
     * Example: @
     */
    get host(): string {
        return this._host;
    }

    static hostValidate(host: string): boolean {
        return typeof host === 'string' && !!host.trim();
    }

    readonly _priority: number | undefined;

    /**
     * Description: Priority. Not null only for MX
     * Example: 1
     */
    get priority(): number | undefined {
        return this._priority;
    }

    readonly _template_provider: DnsTemplateProvider;

    get templateProvider(): DnsTemplateProvider {
        return this._template_provider;
    }

    static templateProviderValidate(templateProvider: DnsTemplateProvider): boolean {
        return Object.keys(DnsTemplateProvider).includes(templateProvider);
    }

    readonly _ttl_seconds: number;

    /**
     * Description: Ttl in seconds
     * Example: 3600
     */
    get ttlSeconds(): number {
        return this._ttl_seconds;
    }

    static ttlSecondsValidate(ttlSeconds: number): boolean {
        return typeof ttlSeconds === 'number';
    }

    readonly _type: DnsRecordType;

    get type(): DnsRecordType {
        return this._type;
    }

    static typeValidate(type: DnsRecordType): boolean {
        return Object.keys(DnsRecordType).includes(type);
    }

    constructor(props: IDnsTemplateRecord) {
        this._data = props.data.trim();
        this._host = props.host.trim();
        if (typeof props.priority === 'number') {
            this._priority = props.priority;
        }
        this._template_provider = props.template_provider;
        this._ttl_seconds = props.ttl_seconds;
        this._type = props.type;
    }

    serialize(): IDnsTemplateRecord {
        const data: IDnsTemplateRecord = {
            data: this._data,
            host: this._host,
            template_provider: this._template_provider,
            ttl_seconds: this._ttl_seconds,
            type: this._type,
        };
        if (typeof this._priority !== 'undefined') {
            data.priority = this._priority;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            host: typeof this._host === 'string' && !!this._host.trim(),
            data: typeof this._data === 'string' && !!this._data.trim(),
            ttl_seconds: typeof this._ttl_seconds === 'number',
            priority: !this._priority ? true : typeof this._priority === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsTemplateRecord): DnsTemplateRecord {
        return new DnsTemplateRecord(props);
    }

    readonly keys: { [key: string]: string } = {
        data: 'data',
        host: 'host',
        priority: 'priority',
        templateProvider: 'template_provider',
        ttlSeconds: 'ttl_seconds',
        type: 'type',
        }
;

    mergeDeepWith(props: Partial<DnsTemplateRecord>): DnsTemplateRecord {
        const updateData: Partial<IDnsTemplateRecord> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsTemplateRecord) => {
            const updateKey = this.keys[key] as keyof IDnsTemplateRecord;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsTemplateRecord, keyof IDnsTemplateRecord>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsTemplateRecord({ ...this.serialize(), ...updateData });
    }
}
