import { IDnsRecordCreate } from 'Entities/DnsRecordCreate';
import DnsZone from 'Entities/DnsZone';
import { FormikErrors } from 'formik';

export { default as ARecordForm } from './ARecordForm';
export { default as AaaaRecordForm } from './AaaaRecordForm';
export { default as CnameRecordForm } from './CnameRecordForm';
export { default as MxRecordForm } from './MxRecordForm';
export { default as SrvRecordForm } from './SrvRecordForm';
export { default as TxtRecordForm } from './TxtRecordForm';
export { default as Buttons } from './Buttons';

export type RecordFormValues = Omit<IDnsRecordCreate, 'port' | 'priority' | 'weight'> & {
    isEdited: boolean;
    port?: string;
    priority?: string;
    weight?: string;
};
export interface RecordFormProps {
    values: RecordFormValues;
    setFieldValue: (key: keyof RecordFormValues, value: any) => void;
    errors: FormikErrors<RecordFormValues>;
    onClose: () => void;
    zone: DnsZone;
}

export const onBlur = (
    zone: string, setValue: (...args: any) => void,
) => (e: React.FocusEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === zone || value.endsWith(`.${zone}`)) {
        setValue('host', `${value}.`);
    }
};
