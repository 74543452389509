import React, { FC } from 'react';
import dayjs from 'dayjs';

import { EmptyPageLayout, InnerPageLayout, Link, mediumText, useIntl } from 'Common';
import Server from 'Entities/Server';
import { DATE_FORMAT } from 'Lib/helpers/consts';
import { RoutePath } from 'Lib/helpers/routes';

import { EmptyHeader } from '.';

interface DeletedProps {
    server: Server;
}

const Deleted: FC<DeletedProps> = ({ server }) => {
    const intl = useIntl();
    const deleteTime = dayjs(server.timeDeleted, DATE_FORMAT);
    const restoreTime = dayjs(server.timeUntilRestoreAvailable, DATE_FORMAT);
    const formattedDeleted = deleteTime.format(intl.getMessage('time_format_date'));
    const formattedRestore = restoreTime.format(intl.getMessage('time_format_date'));

    const supportLink = (text: string) => (
        <Link to={RoutePath.CreateTicket}>
            {text}
        </Link>
    );

    const desc = server.timeUntilRestoreAvailable ? (
        intl.getMessage('empty_server_restore', {
            b: mediumText,
            server_name: server.name,
            delete_date: formattedDeleted,
            restore_date: formattedRestore,
            a: supportLink,
        })
    ) : (
        intl.getMessage('empty_server_deleted', {
            b: mediumText,
            server_name: server.name,
            delete_date: formattedDeleted,
        })
    );

    return (
        <InnerPageLayout header={<EmptyHeader />}>
            <EmptyPageLayout
                title={intl.getMessage('empty_server_title')}
                linkText={intl.getMessage('create_new_server')}
                link={RoutePath.NewServer}
                imageType="servers"
                desc={desc}
            />
        </InnerPageLayout>
    );
};

export default Deleted;
