import React, { FC, useState } from 'react';

import { useIntl } from 'Common';
import theme from 'Lib/theme';
import PayerInfo from 'Entities/PayerInfo';
import { IUpdatePayerRequest } from 'Entities/UpdatePayerRequest';
import { IUpdateSoleTrader } from 'Entities/UpdateSoleTrader';

import Field from './Field';
import Submit from './Submit';

interface SoleTraderFormProps {
    payer: PayerInfo;
    handleClose: () => void;
    handleSubmit: (data: IUpdatePayerRequest, setErrors: (errors: string[]) => void) => void;
}

const SoleTraderForm: FC<SoleTraderFormProps> = ({ payer, handleClose, handleSubmit }) => {
    const intl = useIntl();
    const [state, setState] = useState<IUpdateSoleTrader>({
        sole_legal_address: payer.soleTraderInfo!.legalAddress,
        sole_post_address: payer.soleTraderInfo!.postAddress,
        sole_accounting_email: payer.soleTraderInfo!.accountingEmail,
    });
    const [errors, setErrors] = useState<Set<string>>(new Set());
    const onChange = (field: keyof typeof state) => (value: string) => {
        if (errors.has(field)) {
            errors.delete(field);
            setErrors(new Set(errors));
        }
        setState({ ...state, [field]: value });
    };

    return (
        <div className={theme.modal.form}>
            <Field
                id="type"
                label={intl.getMessage('credentials_account_type')}
                value={intl.getMessage('credentials_sole_proprietor')}
                disabled
            />
            <Field
                id="companyName"
                label={intl.getMessage('credentials_company_name')}
                value={payer.soleTraderInfo!.trademark}
                disabled
            />
            <Field
                id="inn"
                label={intl.getMessage('credentials_vat')}
                value={payer.soleTraderInfo!.inn}
                disabled
            />
            <Field
                id="psrnsp"
                label={intl.getMessage('credentials_psrnsp')}
                value={payer.soleTraderInfo!.ogrnip}
                disabled
            />
            <Field
                id="legalAddress"
                label={intl.getMessage('credentials_legal_address')}
                value={state.sole_legal_address}
                onChange={onChange('sole_legal_address')}
                error={errors.has('sole_legal_address')}
            />
            <Field
                id="correspondenceAddress"
                label={intl.getMessage('credentials_correspondence_address')}
                value={state.sole_post_address}
                onChange={onChange('sole_post_address')}
                error={errors.has('sole_post_address')}
            />
            <Field
                id="correspondenceEmail"
                label={intl.getMessage('credentials_accounting_correspondence_email')}
                value={state.sole_accounting_email!}
                onChange={onChange('sole_accounting_email')}
                error={errors.has('sole_accounting_email')}
            />
            <Submit
                handleSubmit={() => handleSubmit(
                    { sole_trader: state }, (e) => setErrors(new Set(e)),
                )}
                handleClose={handleClose}
            />
        </div>
    );
};

export default SoleTraderForm;
