import React, { FC, useEffect, useState } from 'react';
import { Divider } from 'antd';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { createSelector } from 'reselect';

import { useTitle, useEnter } from 'Hooks';
import { clearNotificationSettings, getNotificationsSettings, getTriggerRules, updateNotificationSettings } from 'Actions/notifications';
import {
    useIntl,
    FormPageLayout,
    NotificationSettingsHeader,
    RangePicker,
    Link,
    Button,
} from 'Common';
import { EventNotificationType } from 'Entities/EventNotificationType';
import SmsDeliveryPeriod, { ISmsDeliveryPeriod } from 'Entities/SmsDeliveryPeriod';
import { NotificationType } from 'Entities/NotificationType';
import NotificationSettings from 'Entities/NotificationSettings';
import Trigger from 'Entities/Trigger';
import { INotificationDeliverySettings } from 'Entities/NotificationDeliverySettings';
import theme from 'Lib/theme';
import { checkDayEnd } from 'Lib/helpers/helpers';
import { RoutePath, linkPathBuilder } from 'Lib/helpers/routes';
import { Store } from 'Store';

import s from './UserSettings.module.pcss';
import SettingsRow from './SettingsRow';

interface UserSettingsStoreProps {
    settings: Store['notifications']['settings'];
    triggers: Store['notifications']['triggers'];
}
type StateKeys = 'email' | 'sms';
interface UserSettingsProps {
    settings: NotificationSettings;
    triggers: Trigger[];
    userId: number;
}
const UserSettings: FC<UserSettingsProps> = ({
    settings, triggers, userId,
}) => {
    const intl = useIntl();
    useTitle(intl.getMessage('notification_user_settings_page_title'));
    const dispatch = useDispatch();
    const history = useHistory();

    const userTime = settings?.smsDeliveryPeriods
        .find((dp) => dp.eventType !== EventNotificationType.BILLING) || {} as SmsDeliveryPeriod;
    const billingTime = settings?.smsDeliveryPeriods
        .find((dp) => dp.eventType === EventNotificationType.BILLING) || {} as SmsDeliveryPeriod;
    const timeFormat = 'H:m';
    const userTimeFrom = dayjs(`${userTime.timeFromHours}:${userTime.timeFromMinutes}`, timeFormat);
    const billingTimeFrom = dayjs(`${billingTime.timeFromHours}:${billingTime.timeFromMinutes}`, timeFormat);

    const userToString = checkDayEnd(`${userTime.timeToHours}:${userTime.timeToMinutes}`);
    const userTimeTo = dayjs(userToString, timeFormat);

    const billingToString = checkDayEnd(`${billingTime.timeToHours}:${billingTime.timeToMinutes}`);
    const billingTimeTo = dayjs(billingToString, timeFormat);

    const [userRange, setUserRange] = useState<[Dayjs, Dayjs]>([userTimeFrom, userTimeTo]);
    const [billingRange, setBillingRange] = useState<[Dayjs, Dayjs]>([
        billingTimeFrom, billingTimeTo,
    ]);

    const initialSystemSettings = {
        [NotificationType.SERVER_CREATE]: { sms: false, email: false },
        [NotificationType.SERVER_DELETE]: { sms: false, email: false },
        [NotificationType.SERVER_START]: { sms: false, email: false },
        [NotificationType.SERVER_SHUTOFF]: { sms: false, email: false },
        [NotificationType.SERVER_REBOOT]: { sms: false, email: false },
        [NotificationType.SERVER_MEMORY_CHANGE]: { sms: false, email: false },
        [NotificationType.SERVER_DISK_INCREASE]: { sms: false, email: false },
        [NotificationType.SERVER_OUT_OF_MEMORY]: { sms: false, email: false },
        [NotificationType.SERVER_LINUX_KERNEL_EVENT]: { sms: false, email: false },
        [NotificationType.SERVER_ACPI_MEMORY_HP_ERROR]: { sms: false, email: false },
        [NotificationType.SERVER_REVERT_TO_SNAPSHOT]: { sms: false, email: false },
        [NotificationType.SERVER_REVERT_TO_BACKUP]: { sms: false, email: false },
    };
    type InitialSystemSettingsKeys = keyof typeof initialSystemSettings;

    const initialBalanceSettings = {
        [NotificationType.BALANCE_REPLENISHED]: { sms: false, email: false },
        [NotificationType.BALANCE_GOT_NEGATIVE]: { sms: false, email: false },
        [NotificationType.BALANCE_GOT_NEGATIVE_24H]: { sms: false, email: false },
        [NotificationType.BALANCE_GOT_NEGATIVE_72H]: { sms: false, email: false },
    };
    type InitialBalanceSettingsKeys = keyof typeof initialBalanceSettings;

    const initalTriggerSettings: any = {};

    triggers?.forEach((t) => {
        initalTriggerSettings[t.id] = { sms: false, email: false };
    });

    settings.deliverySettings.forEach((ds) => {
        if (initialSystemSettings[ds.notificationType as InitialSystemSettingsKeys]) {
            initialSystemSettings[ds.notificationType as InitialSystemSettingsKeys] = {
                sms: ds.deliverySms,
                email: ds.deliveryEmail,
            };
        }
        if (initialBalanceSettings[ds.notificationType as InitialBalanceSettingsKeys]) {
            initialBalanceSettings[ds.notificationType as InitialBalanceSettingsKeys] = {
                sms: ds.deliverySms,
                email: ds.deliveryEmail,
            };
        }
        if (initalTriggerSettings[ds.ruleId!]) {
            initalTriggerSettings[ds.ruleId!] = {
                sms: ds.deliverySms,
                email: ds.deliveryEmail,
            };
        }
    });

    const [systemCheck, setSystemCheck] = useState(initialSystemSettings);
    const [billingCheck, setBillingCheck] = useState(initialBalanceSettings);
    const [triggerCheck, setTriggerCheck] = useState(initalTriggerSettings);

    const onSystemChange = (
        key: NotificationType | number | 'all',
    ) => (dataKey: StateKeys) => (value: boolean) => {
        if (key === 'all') {
            const newSystemCheck = { ...systemCheck };
            Object.keys(newSystemCheck).forEach((k) => {
                newSystemCheck[k as InitialSystemSettingsKeys] = {
                    ...newSystemCheck[k as InitialSystemSettingsKeys], [dataKey]: value,
                };
            });
            setSystemCheck(newSystemCheck);
            return;
        }
        const data = systemCheck[key as InitialSystemSettingsKeys];
        setSystemCheck({ ...systemCheck, [key]: { ...data, [dataKey]: value } });
    };

    const onBillingChange = (
        key: NotificationType | number | 'all',
    ) => (dataKey: StateKeys) => (value: boolean) => {
        if (key === 'all') {
            const newBalanceCheck = { ...billingCheck };
            Object.keys(newBalanceCheck).forEach((k) => {
                newBalanceCheck[k as InitialBalanceSettingsKeys] = {
                    ...newBalanceCheck[k as InitialBalanceSettingsKeys], [dataKey]: value,
                };
            });
            setBillingCheck(newBalanceCheck);
            return;
        }
        const data = billingCheck[key as InitialBalanceSettingsKeys];
        setBillingCheck({ ...billingCheck, [key]: { ...data, [dataKey]: value } });
    };

    const onTriggerChange = (
        key: number | 'all',
    ) => (dataKey: StateKeys) => (value: boolean) => {
        if (key === 'all') {
            const newTriggerCheck = { ...triggerCheck };
            Object.keys(newTriggerCheck).forEach((k) => {
                newTriggerCheck[k] = { ...newTriggerCheck[k], [dataKey]: value };
            });
            setTriggerCheck(newTriggerCheck);
            return;
        }
        const data = triggerCheck[key];
        setTriggerCheck({ ...triggerCheck, [key]: { ...data, [dataKey]: value } });
    };

    const onSubmit = () => {
        const smsDeliveryPeriod: ISmsDeliveryPeriod[] = settings.smsDeliveryPeriods.map((sd) => {
            const data = sd.serialize();
            let [from, to] = userRange;
            if (data.event_type === EventNotificationType.BILLING) {
                [from, to] = billingRange;
            }
            return {
                ...data,
                time_from_hours: from.hour(),
                time_from_minutes: from.minute(),
                time_to_hours: to.hour(),
                time_to_minutes: to.minute(),
            };
        });
        const deliverySettings: INotificationDeliverySettings[] = [];

        Object.keys(systemCheck).forEach((k) => {
            const { sms, email } = systemCheck[k as InitialSystemSettingsKeys];
            deliverySettings.push({
                event_type: EventNotificationType.SYSTEM,
                delivery_email: email,
                delivery_sms: sms,
                notification_type: k as InitialSystemSettingsKeys,
            });
        });
        Object.keys(billingCheck).forEach((k) => {
            const { sms, email } = billingCheck[k as InitialBalanceSettingsKeys];
            deliverySettings.push({
                event_type: EventNotificationType.BILLING,
                delivery_email: email,
                delivery_sms: sms,
                notification_type: k as InitialBalanceSettingsKeys,
            });
        });
        Object.keys(triggerCheck).forEach((k: string) => {
            const { sms, email } = triggerCheck[k];
            deliverySettings.push({
                event_type: EventNotificationType.TRIGGER,
                delivery_email: email,
                delivery_sms: sms,
                rule_id: Number(k),
            });
        });
        dispatch(updateNotificationSettings([
            userId,
            {
                delivery_settings: deliverySettings,
                sms_delivery_periods: smsDeliveryPeriod,
            },
        ]));
        history.push(linkPathBuilder(intl.currentLocale, RoutePath.NotificationRecipients));
    };

    useEnter(onSubmit);

    const allSystem = Object.keys(systemCheck).reduce<{ sms: boolean; email: boolean }>(
        (prevAll, key) => {
            const sms = prevAll.sms && systemCheck[key as keyof typeof systemCheck].sms;
            const email = prevAll.email && systemCheck[key as keyof typeof systemCheck].email;
            return { sms, email };
        },
        { sms: true, email: true },
    );

    const allBilling = Object.keys(billingCheck).reduce<{ sms: boolean; email: boolean }>(
        (prevAll, key) => {
            const sms = prevAll.sms && billingCheck[key as keyof typeof billingCheck].sms;
            const email = prevAll.email && billingCheck[key as keyof typeof billingCheck].email;
            return { sms, email };
        },
        { sms: true, email: true },
    );

    const allTriggers = Object.keys(triggerCheck).reduce<{ sms: boolean; email: boolean }>(
        (prevAll, key) => {
            const sms = prevAll.sms && triggerCheck[key as keyof typeof triggerCheck].sms;
            const email = prevAll.email && triggerCheck[key as keyof typeof triggerCheck].email;
            return { sms, email };
        },
        { sms: true, email: true },
    );

    return (
        <FormPageLayout
            title={intl.getMessage('notifications_user_settings')}
            back={RoutePath.NotificationRecipients}
        >
            <div>
                <div className={s.title}>
                    {intl.getMessage('notifications_schedule_title')}
                </div>
                <div className={s.desc}>
                    {intl.getMessage('notifications_schedule_desc')}
                </div>
                <div className={s.row}>
                    <div className={s.schedule}>
                        {intl.getMessage('notifications_schedule_triggers')}
                    </div>
                    <RangePicker
                        className="picker picker_medium picker_time"
                        bordered={false}
                        value={userRange}
                        format="HH:mm"
                        picker="time"
                        mode={['time', 'time']}
                        onChange={(e: any) => setUserRange(e)}
                    />
                </div>

                <div className={s.row}>
                    <div className={s.schedule}>
                        {intl.getMessage('notifications_schedule_balance')}
                    </div>
                    <RangePicker
                        className="picker picker_medium picker_time"
                        bordered={false}
                        value={billingRange}
                        format="HH:mm"
                        picker="time"
                        mode={['time', 'time']}
                        onChange={(e: any) => setBillingRange(e)}
                    />
                </div>
            </div>
            <Divider
                className={cn(theme.divider.divider, theme.divider.divider_large)}
            />
            <div>
                <div className={s.title}>
                    {intl.getMessage('notifications_user_settings_system')}
                </div>
                <div className={s.desc}>
                    {intl.getMessage('notifications_user_settings_system_desc')}
                </div>
                <NotificationSettingsHeader title={intl.getMessage('notifications_user_settings_label')} />
                <Divider className={cn(theme.divider.divider, theme.divider.divider_dashed)} />
                <SettingsRow
                    title={intl.getMessage('settings_security_all')}
                    onChange={onSystemChange('all')}
                    value={allSystem}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_install')}
                    value={systemCheck.SERVER_CREATE}
                    onChange={onSystemChange(NotificationType.SERVER_CREATE)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_remove')}
                    value={systemCheck.SERVER_DELETE}
                    onChange={onSystemChange(NotificationType.SERVER_DELETE)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_turn_off')}
                    value={systemCheck.SERVER_SHUTOFF}
                    onChange={onSystemChange(NotificationType.SERVER_SHUTOFF)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_reboot')}
                    value={systemCheck.SERVER_REBOOT}
                    onChange={onSystemChange(NotificationType.SERVER_REBOOT)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_memory')}
                    value={systemCheck.SERVER_MEMORY_CHANGE}
                    onChange={onSystemChange(NotificationType.SERVER_MEMORY_CHANGE)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_disk')}
                    value={systemCheck.SERVER_DISK_INCREASE}
                    onChange={onSystemChange(NotificationType.SERVER_DISK_INCREASE)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_oom')}
                    value={systemCheck.SERVER_OUT_OF_MEMORY}
                    onChange={onSystemChange(NotificationType.SERVER_OUT_OF_MEMORY)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_dmesg')}
                    value={systemCheck.SERVER_LINUX_KERNEL_EVENT}
                    onChange={onSystemChange(NotificationType.SERVER_LINUX_KERNEL_EVENT)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_memory_error')}
                    value={systemCheck.SERVER_ACPI_MEMORY_HP_ERROR}
                    onChange={onSystemChange(NotificationType.SERVER_ACPI_MEMORY_HP_ERROR)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_revert_to_snapshop')}
                    value={systemCheck.SERVER_REVERT_TO_SNAPSHOT}
                    onChange={onSystemChange(NotificationType.SERVER_REVERT_TO_SNAPSHOT)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_revert_to_backup')}
                    value={systemCheck.SERVER_REVERT_TO_BACKUP}
                    onChange={onSystemChange(NotificationType.SERVER_REVERT_TO_BACKUP)}
                />
            </div>
            <Divider
                className={cn(theme.divider.divider, theme.divider.divider_large)}
            />
            <div>
                <div className={s.title}>
                    {intl.getMessage('balance')}
                </div>
                <div className={s.desc}>
                    {intl.getMessage('notifications_user_settings_balance_desc')}
                </div>

                <NotificationSettingsHeader title={intl.getMessage('notifications_user_settings_label')} />

                <Divider className={cn(theme.divider.divider, theme.divider.divider_dashed)} />

                <SettingsRow
                    title={intl.getMessage('settings_security_all')}
                    onChange={onBillingChange('all')}
                    value={allBilling}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_add_funds')}
                    value={billingCheck[NotificationType.BALANCE_REPLENISHED]}
                    onChange={onBillingChange(NotificationType.BALANCE_REPLENISHED)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_negative_balance')}
                    value={billingCheck[NotificationType.BALANCE_GOT_NEGATIVE]}
                    onChange={onBillingChange(NotificationType.BALANCE_GOT_NEGATIVE)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_negative_24_hours')}
                    value={billingCheck[NotificationType.BALANCE_GOT_NEGATIVE_24H]}
                    onChange={onBillingChange(NotificationType.BALANCE_GOT_NEGATIVE_24H)}
                />
                <SettingsRow
                    title={intl.getMessage('notifications_user_settings_negative_3_days')}
                    value={billingCheck[NotificationType.BALANCE_GOT_NEGATIVE_72H]}
                    onChange={onBillingChange(NotificationType.BALANCE_GOT_NEGATIVE_72H)}
                />
            </div>
            <Divider
                className={cn(theme.divider.divider, theme.divider.divider_large)}
            />
            {triggers?.length === 0 ? (
                <div>
                    <div className={s.title}>
                        {intl.getMessage('notifications_user_settings_custom')}
                    </div>
                    <div className={s.desc}>
                        {intl.getMessage('notifications_user_settings_custom_desc_no')}
                    </div>
                    <Link
                        className={cn(
                            theme.button.button,
                            theme.button.desktop_medium,
                            theme.button.link,
                        )}
                        to={RoutePath.NewTrigger}
                    >
                        {intl.getMessage('triggers_create')}
                    </Link>
                </div>
            ) : (
                <div>
                    <div className={s.title}>
                        {intl.getMessage('notifications_user_settings_custom')}
                    </div>
                    <div className={s.desc}>
                        {intl.getMessage('notifications_user_settings_custom_desc')}
                    </div>
                    <NotificationSettingsHeader title={intl.getMessage('notifications_user_settings_label')} />
                    <Divider className={cn(theme.divider.divider, theme.divider.divider_dashed)} />
                    <SettingsRow
                        title={intl.getMessage('settings_security_all')}
                        onChange={onTriggerChange('all')}
                        value={allTriggers}
                    />
                    {triggers?.map((t) => (
                        <SettingsRow
                            key={t.id}
                            title={t.name}
                            value={triggerCheck[t.id]}
                            onChange={onTriggerChange(t.id)}
                        />
                    ))}
                </div>
            )}
            <div className={s.actions}>
                <Button
                    type="primary"
                    size="big"
                    onClick={onSubmit}
                    inGroup
                >
                    {intl.getMessage('save_changes')}
                </Button>
                <Button
                    type="link"
                    size="big"
                    onClick={() => history.goBack()}
                >
                    {intl.getMessage('cancel')}
                </Button>
            </div>
        </FormPageLayout>
    );
};

const UserSettingsContainer: FC<UserSettingsStoreProps> = ({ settings, triggers }) => {
    const dispatch = useDispatch();
    const match = useRouteMatch<{ userId: string }>();
    const { params: { userId } } = match;

    useEffect(() => {
        dispatch(getTriggerRules());
        if (userId && Number(userId)) {
            dispatch(getNotificationsSettings([Number(userId)]));
        }
        return () => {
            dispatch(clearNotificationSettings());
        };
    }, [userId]);

    if (!userId || !settings || !triggers) {
        return null;
    }

    return (
        <UserSettings
            key={settings.deliverySettings.length}
            settings={settings}
            triggers={triggers}
            userId={Number(userId)}
        />
    );
};

const selectSettings = (store: Store) => {
    return store.notifications.settings;
};

const selectTriggers = (store: Store) => {
    return store.notifications.triggers;
};

const selector = createSelector(
    [selectSettings, selectTriggers],
    (settings, triggers) => ({ settings, triggers }),
);

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(UserSettingsContainer);
