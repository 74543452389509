import React, { FC, useState } from 'react';

import { useIntl } from 'Common';
import theme from 'Lib/theme';
import PayerInfo from 'Entities/PayerInfo';
import { IUpdatePayerRequest } from 'Entities/UpdatePayerRequest';
import { IUpdateIndividual } from 'Entities/UpdateIndividual';

import Field from './Field';
import Submit from './Submit';

interface IndividualFormProps {
    payer: PayerInfo;
    handleClose: () => void;
    handleSubmit: (data: IUpdatePayerRequest, setErrors: (errors: string[]) => void) => void;
}

const IndividualForm: FC<IndividualFormProps> = ({ payer, handleClose, handleSubmit }) => {
    const intl = useIntl();
    const [state, setState] = useState<IUpdateIndividual>({
        individual_first_name: payer.individualInfo!.firstName,
        individual_last_name: payer.individualInfo!.lastName,
        individual_address: payer.individualInfo!.address,
    });
    const [errors, setErrors] = useState<Set<string>>(new Set());
    const onChange = (field: keyof typeof state) => (value: string) => {
        if (errors.has(field)) {
            errors.delete(field);
            setErrors(new Set(errors));
        }
        setState({ ...state, [field]: value });
    };

    return (
        <div className={theme.modal.form}>
            <Field
                id="type"
                label={intl.getMessage('credentials_account_type')}
                value={intl.getMessage('credentials_private_person')}
                disabled
            />
            <Field
                id="name"
                label={intl.getMessage('credentials_name')}
                value={state.individual_first_name || ''}
                onChange={onChange('individual_first_name')}
                error={errors.has('individual_first_name')}
            />
            <Field
                id="lastName"
                label={intl.getMessage('credentials_last_name')}
                value={state.individual_last_name || ''}
                onChange={onChange('individual_last_name')}
                error={errors.has('individual_last_name')}
            />
            <Field
                id="address"
                label={intl.getMessage('credentials_address')}
                value={state.individual_address || ''}
                onChange={onChange('individual_address')}
            />
            <Submit
                handleSubmit={
                    () => handleSubmit({ individual: state }, (e) => setErrors(new Set(e)))
                }
                handleClose={handleClose}
            />
        </div>
    );
};

export default IndividualForm;
