import React, { FC } from 'react';

import { Checkbox, Icon } from 'Common';
import { PublicKeyInstallationState } from 'Entities/PublicKeyInstallationState';
import theme from 'Lib/theme';
import cn from 'classnames';
import { InitialState } from './KeysSelect';

import s from './KeysSelect.module.pcss';

interface KeyProps {
    checked?: boolean;
    id: number;
    title: string;
    ownerUserName?: string;
    type?: string;
    selectKeys?: () => void;
    selectedKeys: InitialState;
    handleChangeSelect: (status: PublicKeyInstallationState, id: number) => void;
}

const Key: FC<KeyProps> = ({
    id,
    title,
    ownerUserName,
    type,
    selectedKeys,
    handleChangeSelect,
}) => {
    const { status } = selectedKeys.get(id)!;
    const checkStatus = (st: PublicKeyInstallationState) => {
        switch (st) {
            case PublicKeyInstallationState.ALL_SERVERS:
            case PublicKeyInstallationState.SOME_SERVERS:
                return true;
            case PublicKeyInstallationState.NO_SERVERS:
                return false;
        }
    };

    return (
        <Checkbox
            name={String(id)}
            checked={checkStatus(status)}
            handleChange={() => handleChangeSelect(status, id)}
            labelClassName={s.label}
            className={s.wrap}
            overflow
            customIcon={
                status === PublicKeyInstallationState.SOME_SERVERS ? <Icon icon="uncheck" className={s.customIcon} /> : undefined
            }
        >
            <div className={s.keyWrapper}>
                <div>
                    <div className={cn(theme.common.textOverflow, s.keyName)}>{title}</div>
                    <div className={cn(theme.common.textOverflow, s.keyUserName)}>{ownerUserName}</div>
                </div>
                <div className={s.keyType}>{type}</div>
            </div>
        </Checkbox>
    );
};

export default Key;
