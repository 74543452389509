import React, { FC, useContext } from 'react';
import { Row } from 'antd';
import cn from 'classnames';

import { InnerPageLayout, EmptyPageLayout, useIntl } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { SERVER_VIEW } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import { Filters, Header, ListRender } from './components';
import { ServerListContext } from './factories';

const ServerList: FC = React.memo(() => {
    const intl = useIntl();
    const {
        filteredProgress,
        filteredWorking,
        serverView,
    } = useContext(ServerListContext);

    const { height } = document.body.getBoundingClientRect();

    const allServers = [...filteredProgress, ...filteredWorking];

    const getContent = () => {
        if (allServers.length > 0) {
            return (
                <>
                    <Filters
                        servers={allServers}
                        serversLength={
                            (filteredProgress?.length || 0) + (filteredWorking?.length || 0)
                        }
                    />
                    <Row gutter={serverView === SERVER_VIEW.EXPAND ? [0, 24] : [48, 0]}>
                        <ListRender
                            servers={allServers}
                            height={height}
                        />
                    </Row>
                </>
            );
        }

        return (
            <EmptyPageLayout
                title={intl.getMessage('empty_servers_title')}
                desc={intl.getMessage('empty_servers_desc')}
                linkText={intl.getMessage('create_server')}
                link={RoutePath.NewServer}
                imageType="servers"
            />
        );
    };

    return (
        <InnerPageLayout
            header={<Header />}
            className={cn(theme.content.with_actions)}
        >
            {getContent()}
        </InnerPageLayout>
    );
});

export default ServerList;
