import ComparativeExpenses, { IComparativeExpenses } from './ComparativeExpenses';
import ExpenseSummary, { IExpenseSummary } from './ExpenseSummary';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountExpensesDetailed {
    daily_expenses: IExpenseSummary[];
    expenses_report_download_link?: string;
    overall_expense: IExpenseSummary;
    overall_server_expense: IComparativeExpenses;
    server_expenses: IComparativeExpenses[];
}

export default class AccountExpensesDetailed {
    readonly _daily_expenses: ExpenseSummary[];

    /** */
    get dailyExpenses(): ExpenseSummary[] {
        return this._daily_expenses;
    }

    readonly _expenses_report_download_link: string | undefined;

    /** */
    get expensesReportDownloadLink(): string | undefined {
        return this._expenses_report_download_link;
    }

    readonly _overall_expense: ExpenseSummary;

    get overallExpense(): ExpenseSummary {
        return this._overall_expense;
    }

    readonly _overall_server_expense: ComparativeExpenses;

    get overallServerExpense(): ComparativeExpenses {
        return this._overall_server_expense;
    }

    readonly _server_expenses: ComparativeExpenses[];

    /** */
    get serverExpenses(): ComparativeExpenses[] {
        return this._server_expenses;
    }

    constructor(props: IAccountExpensesDetailed) {
        this._daily_expenses = props.daily_expenses.map((p) => new ExpenseSummary(p));
        if (typeof props.expenses_report_download_link === 'string') {
            this._expenses_report_download_link = props.expenses_report_download_link.trim();
        }
        this._overall_expense = new ExpenseSummary(props.overall_expense);
        this._overall_server_expense = new ComparativeExpenses(props.overall_server_expense);
        this._server_expenses = props.server_expenses.map((p) => new ComparativeExpenses(p));
    }

    serialize(): IAccountExpensesDetailed {
        const data: IAccountExpensesDetailed = {
            daily_expenses: this._daily_expenses.map((p) => p.serialize()),
            overall_expense: this._overall_expense.serialize(),
            overall_server_expense: this._overall_server_expense.serialize(),
            server_expenses: this._server_expenses.map((p) => p.serialize()),
        };
        if (typeof this._expenses_report_download_link !== 'undefined') {
            data.expenses_report_download_link = this._expenses_report_download_link;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            overall_server_expense: this._overall_server_expense.validate().length === 0,
            server_expenses: this._server_expenses.reduce((result, p) => result && p.validate().length === 0, true),
            overall_expense: this._overall_expense.validate().length === 0,
            daily_expenses: this._daily_expenses.reduce((result, p) => result && p.validate().length === 0, true),
            expenses_report_download_link: !this._expenses_report_download_link ? true : typeof this._expenses_report_download_link === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountExpensesDetailed): AccountExpensesDetailed {
        return new AccountExpensesDetailed(props);
    }

    readonly keys: { [key: string]: string } = {
        dailyExpenses: 'daily_expenses',
        expensesReportDownloadLink: 'expenses_report_download_link',
        overallExpense: 'overall_expense',
        overallServerExpense: 'overall_server_expense',
        serverExpenses: 'server_expenses',
        }
;

    mergeDeepWith(props: Partial<AccountExpensesDetailed>): AccountExpensesDetailed {
        const updateData: Partial<IAccountExpensesDetailed> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountExpensesDetailed) => {
            const updateKey = this.keys[key] as keyof IAccountExpensesDetailed;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountExpensesDetailed, keyof IAccountExpensesDetailed>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountExpensesDetailed({ ...this.serialize(), ...updateData });
    }
}
