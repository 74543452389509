/* eslint-disable no-else-return */
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import Icons from 'Lib/theme/Icons';
import store from 'Store';

import BasicRoute from './BasicRoute';
import { ErrorBoundary, ErrorPage } from './Errors';

const ErrorCatcher: FC = ({ children }) => {
    if (DEV) {
        return (
            <ErrorBoundary>
                {children}
            </ErrorBoundary>
        );
    } else {
        return (
            <Sentry.ErrorBoundary fallback={<ErrorPage />}>
                {children}
            </Sentry.ErrorBoundary>
        );
    }
};

const App: FC = () => {
    return (
        <ErrorCatcher>
            <Provider store={store}>
                <BrowserRouter>
                    <BasicRoute />
                    <Icons />
                </BrowserRouter>
            </Provider>
        </ErrorCatcher>
    );
};

export default App;
