import React, { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { getAllTariffs } from 'Actions/tariff';
import { InnerPageLayout, useIntl, ServerNotExist, Actions, SetupActions } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import ServerEntity from 'Entities/Server';
import { OperationStatus } from 'Entities/OperationStatus';
import { getServer, getServerList } from 'Actions/server';
import { Store } from 'Store';
import { useServerSearchSave, useTitle } from 'Hooks';
import theme from 'Lib/theme';

import { Body, ServerName, Resources, InstallingResources, Header, Loading, Deleted } from './components';

import s from './Server.module.pcss';

interface ServerStoreProps {
    server?: ServerEntity;
    operationProgress?: OperationProgress;
    installProgress?: Map<number, OperationProgress>;
    installingServers: Map<number, ServerEntity>;
}
type ServerOwnProps = RouteComponentProps<{ serverId: string; tenantId: string }>;
type ServerProps = ServerStoreProps & ServerOwnProps;

const Server: FC<ServerProps> = ({
    match,
    server,
    operationProgress,
    installingServers,
    installProgress,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { params: { serverId, tenantId } } = match;
    const servId = Number(serverId);
    const tenId = Number(tenantId);
    const correctLink = !Number.isNaN(servId) && !Number.isNaN(tenId);
    const [deletedServer, setDeletedServer] = useState<ServerEntity | null>(null);
    const [loading, setLoading] = useState(server === undefined);

    const pageTitle = server?.name
        ? intl.getMessage('server_page_title', { value: server.name })
        : intl.getMessage('page_title');

    useTitle(pageTitle);
    useServerSearchSave(servId, !!deletedServer);

    useEffect(() => {
        window.scrollTo({ top: 0 });
        dispatch(getAllTariffs());
        dispatch(getServerList());
        if (correctLink) {
            dispatch(getServer({ serverId: servId, tenantId: tenId }, {
                result: () => {
                    setLoading(false);
                },
                error: (se?: ServerEntity) => {
                    if (se) {
                        setDeletedServer(se);
                    }
                    setLoading(false);
                },
            }));
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return (
            <Loading />
        );
    }

    if (deletedServer) {
        return (
            <Deleted server={deletedServer} />
        );
    }

    const currentServer = server || installingServers.get(servId);

    if (!currentServer || !correctLink) {
        return <ServerNotExist />;
    }

    const currentInstallProgress = installProgress
        && Array.from(installProgress.values()).find((op) => op.serverId === currentServer.id);
    const installing = !!currentInstallProgress;

    const operationInProgress = operationProgress?.status === OperationStatus.PENDING || installing;

    const getActions = () => {
        if (currentInstallProgress) {
            return (
                <SetupActions
                    server={currentServer}
                    progress={currentInstallProgress}
                />
            );
        }

        return (
            <Actions
                server={currentServer}
                size="medium"
                operationType={operationProgress?.type}
            />
        );
    };

    return (
        <InnerPageLayout
            header={(
                <Header
                    server={currentServer}
                    progress={operationProgress}
                    installingProgress={currentInstallProgress}
                />
            )}
            className={cn(theme.content.with_actions, theme.content.chartPage)}
        >
            <div className={s.block}>
                <div className={s.info}>
                    <div className={s.header}>
                        <ServerName server={currentServer} loading={operationInProgress} />

                        <div className={s.action}>
                            {getActions()}
                        </div>
                    </div>
                    <Body
                        server={currentServer}
                        loading={operationInProgress}
                        installing={installing}
                    />
                </div>
            </div>
            {installing ? (
                <InstallingResources />
            ) : (
                <Resources
                    server={currentServer}
                    progress={operationProgress}
                    installing={installing}
                />
            )}
        </InnerPageLayout>
    );
};

const selectServer = (store: Store, ownProps: ServerOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return store.server.get(Number(serverId));
};

const selectProgress = (store: Store, ownProps: ServerOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return store.operationProgress.commonOperations.get(Number(serverId));
};

const selectInstallingProgress = (store: Store) => store.installingServers.progress;
const selectInstallingServers = (store: Store) => store.installingServers.servers;

const selector = createSelector([
    selectServer,
    selectProgress,
    selectInstallingServers,
    selectInstallingProgress,
], (server, progress, installingServers, installProgress) => ({
    server,
    operationProgress: progress,
    installingServers,
    installProgress,
}));

const mapStateToProps = (store: Store, ownProps: ServerOwnProps) => {
    return { ...selector(store, ownProps) };
};
export default withRouter(connect(mapStateToProps)(Server));
