import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, DashboardHeader, Link, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

interface HeaderProps {
    profileReseller?: boolean;
}

const Header: FC<HeaderProps> = ({ profileReseller }) => {
    const intl = useIntl();

    return (
        <DashboardHeader
            navigation={(
                <>
                    <div className={cn(
                        theme.header.link,
                        { [theme.header.active]: profileReseller },
                        { [theme.header.link_notActive]: !profileReseller },
                    )}
                    >
                        {intl.getMessage('clients')}
                    </div>
                    {profileReseller && (
                        <Link
                            to={RoutePath.ClientsFinance}
                            className={theme.header.link}
                        >
                            {intl.getMessage('partners_payments')}
                        </Link>
                    )}
                </>
            )}
            actions={(
                profileReseller && (
                    <Link to={RoutePath.ClientsAdd} className={theme.header.button}>
                        <Button
                            type="primary"
                            size="medium"
                        >
                            {intl.getMessage('clients_add')}
                        </Button>
                    </Link>
                )
            )}
        />
    );
};

export default Header;
