import dayjs from 'dayjs';
import { Translator } from 'Lib/intl';
import { VmOperationStep } from 'Entities/VmOperationStep';
import { OperationType } from 'Entities/OperationType';
import { InvoiceStatus } from 'Entities/InvoiceStatus';
import { DnsRecordType } from 'Entities/DnsRecordType';
import { NotificationPayload } from 'Entities/NotificationPayload';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { ServerState } from 'Entities/ServerState';
import { CardAccounts } from 'Entities/CardAccounts';
import { ResourceUsageType } from 'Entities/ResourceUsageType';
import { ACT_RANGE_TYPE, DATE_FORMAT, DATE_RANGE_TYPES, SERVER_VIEW, TICKETS_SUBJECT } from 'Lib/helpers/consts';
import { whiteText } from 'Common';

import { triggerValueMatcher } from './utils';

export const stepTranslate = (intl: Translator, step: VmOperationStep) => {
    const message: Record<VmOperationStep, string> = {
        [VmOperationStep.START]: intl.getMessage('server_operation_start'),
        [VmOperationStep.ERROR]: intl.getMessage('server_operation_error'),
        [VmOperationStep.DONE]: intl.getMessage('server_operation_done'),
        [VmOperationStep.RESOURCE_ALLOCATE]: intl.getMessage('server_operation_resource_allocate'),
        [VmOperationStep.VOLUME_COPY]: intl.getMessage('server_operation_volume_copy'),
        [VmOperationStep.CONFIGURING]: intl.getMessage('server_operation_configuring'),
        [VmOperationStep.VM_CREATE]: intl.getMessage('server_operation_vm_create'),
        [VmOperationStep.VM_START]: intl.getMessage('server_operation_vm_start'),
        [VmOperationStep.VM_SHUTDOWN]: intl.getMessage('server_operation_vm_shutdown'),
        [VmOperationStep.VM_REBOOT]: intl.getMessage('server_operation_vm_reboot'),
        [VmOperationStep.SNAPSHOT_REVERT]: intl.getMessage('server_operation_snapshot_revert'),
        [VmOperationStep.SNAPSHOT_UPDATE_IPS]: intl.getMessage('server_operation_snapshot_update_ips'),
        [VmOperationStep.REMOVE_SNAPSHOT]: intl.getMessage('server_operation_remove_snapshot'),
        [VmOperationStep.CREATE_SNAPSHOT]: intl.getMessage('server_operation_create_snapshot'),
        [VmOperationStep.CHANGE_CPU_CORES_CHANGE]: intl.getMessage('server_operation_change_cpu_cores_change'),
        [VmOperationStep.PREPARE_BACKUP]: intl.getMessage('server_operation_prepare_backup'),
        [VmOperationStep.SERVICE_SHUTDOWN]: intl.getMessage('server_operation_service_shutdown'),
        [VmOperationStep.CHANGE_DISK]: intl.getMessage('server_operation_change_disk'),
        [VmOperationStep.CHANGE_MEMORY]: intl.getMessage('server_operation_change_memory'),
    };
    return message[step];
};
export const operationTypeTranslate = (intl: Translator, type: OperationType) => {
    const message: Record<OperationType, string> = {
        [OperationType.INSTALL]: intl.getMessage('operation_type_install'),
        [OperationType.CLONE]: intl.getMessage('operation_type_clone'),
        [OperationType.REINSTALL]: intl.getMessage('operation_type_reinstall'),
        [OperationType.DELETE]: intl.getMessage('operation_type_delete'),
        [OperationType.UPDATE]: intl.getMessage('operation_type_update'),
        [OperationType.START]: intl.getMessage('operation_type_start'),
        [OperationType.RESET]: intl.getMessage('operation_type_reset'),
        [OperationType.REBOOT]: intl.getMessage('operation_type_reboot'),
        [OperationType.DESTROY]: intl.getMessage('operation_type_destroy'),
        [OperationType.SHUTDOWN]: intl.getMessage('operation_type_shutdown'),
        [OperationType.PASSWORD_CHANGE]: intl.getMessage('operation_type_password_change'),
        [OperationType.PUBLIC_KEY_CHANGE]: intl.getMessage('operation_type_public_key_change'),
        [OperationType.MEMORY_CHANGE]: intl.getMessage('operation_type_memory_change'),
        [OperationType.DISK_CHANGE]: intl.getMessage('operation_type_disk_change'),
        [OperationType.TARIFF_CHANGE]: intl.getMessage('operation_type_tariff_change'),
        [OperationType.BACKUP_POLICY_CHANGE]: intl.getMessage('operation_type_backup_policy_change'),
        [OperationType.CPU_CORES_CHANGE]: intl.getMessage('operation_type_cpu_cores_change'),
        [OperationType.IP_ADD]: intl.getMessage('operation_type_ip_add'),
        [OperationType.IP_DELETE]: intl.getMessage('operation_type_ip_delete'),
        [OperationType.REVERT_TO_BACKUP]: intl.getMessage('operation_type_revert_to_backup'),
        [OperationType.DOWNLOAD_BACKUP]: intl.getMessage('operation_type_download_backup'),
        [OperationType.REVERT_TO_SNAPSHOT]: intl.getMessage('operation_type_revert_to_snapshot'),
        [OperationType.CREATE_SNAPSHOT]: intl.getMessage('operation_type_create_snapshot'),
        [OperationType.DELETE_SNAPSHOT]: intl.getMessage('operation_type_delete_snapshot'),
        [OperationType.DOWNLOAD_SNAPSHOT]: intl.getMessage('operation_type_download_snapshot'),
        [OperationType.UNSUPPORTED]: intl.getMessage('operation_type_unsupported'),
    };
    return message[type];
};

export const getDnsLabel = (intl: Translator, type: DnsRecordType) => {
    const message: Record<DnsRecordType, string> = {
        [DnsRecordType.A]: intl.getMessage('dns_ipv4'),
        [DnsRecordType.AAAA]: intl.getMessage('dns_ipv6'),
        [DnsRecordType.CNAME]: intl.getMessage('dns_value'),
        [DnsRecordType.MX]: intl.getMessage('dns_value'),
        [DnsRecordType.TXT]: intl.getMessage('dns_value'),
        [DnsRecordType.SRV]: intl.getMessage('dns_value'),
    };
    return message[type];
};

export const apiErrorCodeTranslate = (
    intl: Translator, value: ApiErrorCode, prop?: Record<string, any>,
) => {
    const props = { ...{ value: '', value_second: '', white: whiteText }, ...prop };
    const errorMessages: Record<ApiErrorCode, string> = {
        [ApiErrorCode.BAD_REQUEST]: intl.getMessage('error_code_bad_request', props),
        [ApiErrorCode.BONUS_COUPON_ALREADY_USE_OTHER_BONUS]: intl.getMessage('error_code_bonus_coupon_already_use_other_bonus', props),
        [ApiErrorCode.BONUS_COUPON_ALREADY_USE_TEST_BONUS]: intl.getMessage('error_code_bonus_coupon_already_use_test_bonus', props),
        [ApiErrorCode.BONUS_COUPON_DISABLED_FOR_ACCOUNT]: intl.getMessage('error_code_bonus_coupon_disabled_for_account', props),
        [ApiErrorCode.BONUS_COUPON_EXPIRED]: intl.getMessage('error_code_bonus_coupon_expired', props),
        [ApiErrorCode.BONUS_COUPON_INVALID]: intl.getMessage('error_code_bonus_coupon_invalid', props),
        [ApiErrorCode.BONUS_COUPON_PHONE_REQUIRED]: intl.getMessage('error_code_bonus_coupon_phone_required', props),
        [ApiErrorCode.BONUS_COUPON_TRIAL_PERIOD_REQUIRED]: intl.getMessage('error_code_bonus_coupon_trial_period_required', props),
        [ApiErrorCode.CANNOT_READ_PARTITION_TABLE]: intl.getMessage('error_code_cannot_read_partition_table', props),
        [ApiErrorCode.CAPTCHA_INVALID]: intl.getMessage('error_code_captcha_invalid', props),
        [ApiErrorCode.CAPTCHA_REQUIRED]: intl.getMessage('error_code_captcha_required', props),
        [ApiErrorCode.CLIENT_ALREADY_IN_TENANT]: intl.getMessage('error_code_client_already_in_tenant', props),
        [ApiErrorCode.CLIENT_EMAIL_NOT_CONFIRMED]: intl.getMessage('error_code_client_email_not_confirmed', props),
        [ApiErrorCode.CLIENT_RECIPIENT_COUNT_EXCEED]: intl.getMessage('error_code_client_recipient_count_exceed', props),
        [ApiErrorCode.CLIENT_RECIPIENT_DUPLICATE]: intl.getMessage('error_code_client_recipient_duplicate', props),
        [ApiErrorCode.CLIENT_TRIGGER_COUNT_EXCEED]: intl.getMessage('error_code_client_trigger_count_exceed', props),
        [ApiErrorCode.CLUSTER_OVER_CAPACITY]: intl.getMessage('error_code_cluster_over_capacity', props),
        [ApiErrorCode.CLUSTER_OVER_LIMIT_PENDING_SETUP]: intl.getMessage('error_code_cluster_over_limit_pending_setup', props),
        [ApiErrorCode.CLUSTER_OVER_MEMORY]: intl.getMessage('error_code_cluster_over_memory', props),
        [ApiErrorCode.CREDIT_CARDS_LIMIT_EXCEED]: intl.getMessage('error_code_credit_cards_limit_exceed', props),
        [ApiErrorCode.DNS_DISABLED_NEGATIVE_BALANCE]: intl.getMessage('error_code_dns_disabled_negative_balance', props),
        [ApiErrorCode.DNS_RECORD_ALREADY_EXIST]: intl.getMessage('error_code_dns_record_already_exist', props),
        [ApiErrorCode.DNS_RECORD_VALUE_EXIST]: intl.getMessage('error_code_dns_record_value_exist', props),
        [ApiErrorCode.DNS_RECORDS_COUNT_EXCEED]: intl.getMessage('error_code_dns_records_count_exceed', props),
        [ApiErrorCode.DNS_VALIDATION_ERROR]: intl.getMessage('error_code_dns_validation_error', props),
        [ApiErrorCode.DNS_ZONE_ALREADY_EXIST]: intl.getMessage('error_code_dns_zone_already_exist', props),
        [ApiErrorCode.DNS_ZONES_COUNT_EXCEED]: intl.getMessage('error_code_dns_zones_count_exceed', props),
        [ApiErrorCode.DOWNLOAD_BACKUP_NOT_ENOUGH_BALANCE]: intl.getMessage('error_code_download_backup_not_enough_balance', props),
        [ApiErrorCode.DOWNLOAD_SNAPSHOT_NOT_ENOUGH_BALANCE]: intl.getMessage('error_code_download_snapshot_not_enough_balance', props),
        [ApiErrorCode.EMAIL_ALREADY_EXIST]: intl.getMessage('error_code_email_already_exist', props),
        [ApiErrorCode.FIELD_LENGTH_MAX_256]: intl.getMessage('error_code_field_length_max_256', props),
        [ApiErrorCode.FIELD_LENGTH_MIN_8]: intl.getMessage('error_code_field_length_min_8', props),
        [ApiErrorCode.FIELD_REQUIRED]: intl.getMessage('error_code_field_required', props),
        [ApiErrorCode.FIELD_WRONG_STRING]: intl.getMessage('error_code_field_wrong_string', props),
        [ApiErrorCode.FIELD_WRONG_VALUE]: intl.getMessage('error_code_field_wrong_value', props),
        [ApiErrorCode.HTTP_TRIGGER_RESOLVE_NOT_FOUND]: intl.getMessage('error_code_http_trigger_resolve_not_found', props),
        [ApiErrorCode.INET_IP_GUEST_ADD]: intl.getMessage('error_code_inet_ip_guest_add', props),
        [ApiErrorCode.INET_IP_GUEST_REMOVE]: intl.getMessage('error_code_inet_ip_guest_remove', props),
        [ApiErrorCode.INET_IP_LIMIT_EXCEEDED]: intl.getMessage('error_code_inet_ip_limit_exceeded', props),
        [ApiErrorCode.INET_IP_MIN_VALUE]: intl.getMessage('error_code_inet_ip_min_value', props),
        [ApiErrorCode.LOGIN_BAD_CREDENTIALS]: intl.getMessage('error_code_login_bad_credentials', props),
        [ApiErrorCode.LOGIN_SOCIAL_REJECTED]: intl.getMessage('error_code_login_social_rejected', props),
        [ApiErrorCode.LOGIN_TOO_MANY_ATTEMPTS]: intl.getMessage('error_code_login_too_many_attempts', props),
        [ApiErrorCode.LOGIN_USER_DISABLED]: intl.getMessage('error_code_login_user_disabled', props),
        [ApiErrorCode.LOGIN_USER_LOCKED]: intl.getMessage('error_code_login_user_locked', props),
        [ApiErrorCode.LOGIN_USER_NOT_CONFIRMED]: intl.getMessage('error_code_login_user_not_confirmed', props),
        [ApiErrorCode.NET_NO_INET_IP]: intl.getMessage('error_code_net_no_inet_ip', props),
        [ApiErrorCode.NET_NO_LOCAL_IP]: intl.getMessage('error_code_net_no_local_ip', props),
        [ApiErrorCode.NOT_ENOUGH_BALANCE]: intl.getMessage('error_code_not_enough_balance', props),
        [ApiErrorCode.NOT_OWNER]: intl.getMessage('error_code_not_owner', props),
        [ApiErrorCode.OBJECT_NOT_FOUND]: intl.getMessage('error_code_object_not_found', props),
        [ApiErrorCode.OPERATION_ALREADY_STARTED]: intl.getMessage('error_code_operation_already_started', props),
        [ApiErrorCode.OPERATION_NOT_SUPPORTED]: intl.getMessage('error_code_operation_not_supported', props),
        [ApiErrorCode.PARTITION_RESIZE_FAILED]: intl.getMessage('error_code_partition_resize_failed', props),
        [ApiErrorCode.PARTITION_TABLE_MODIFIED]: intl.getMessage('error_code_partition_table_modified', props),
        [ApiErrorCode.RATE_LIMIT_EXCEED]: intl.getMessage('error_code_rate_limit_exceed', props),
        [ApiErrorCode.RECIPIENT_EMAIL_OR_PHONE_REQUIRED]: intl.getMessage('error_code_recipient_email_or_phone_required', props),
        [ApiErrorCode.RECONFIGURATION_DENIED]: intl.getMessage('error_code_reconfiguration_denied', props),
        [ApiErrorCode.SECURITY_CODE_REQUIRED]: intl.getMessage('error_code_security_code_required', props),
        [ApiErrorCode.SECURITY_CODE_WRONG]: intl.getMessage('error_code_security_code_wrong', props),
        [ApiErrorCode.SERVICE_API_UNAVAILABLE]: intl.getMessage('error_code_service_api_unavailable', props),
        [ApiErrorCode.SETUP_NOT_ENOUGH_BALANCE_EMAIL_NOT_CONFIRMED]: intl.getMessage('error_code_setup_not_enough_balance_email_not_confirmed', props),
        [ApiErrorCode.SETUP_NOT_ENOUGH_BALANCE]: intl.getMessage('error_code_setup_not_enough_balance', props),
        [ApiErrorCode.SFDISK_OR_RESIZEPART_MISSED]: intl.getMessage('error_code_sfdisk_or_resizepart_missed', props),
        [ApiErrorCode.SNAPSHOT_ACTIONS_DISABLED]: intl.getMessage('error_code_snapshot_actions_disabled', props),
        [ApiErrorCode.SNAPSHOT_LIMIT_EXCEEDED]: intl.getMessage('error_code_snapshot_limit_exceeded', props),
        [ApiErrorCode.SNAPSHOT_NONSTANDARD_VM]: intl.getMessage('error_code_snapshot_nonstandard_vm', props),
        [ApiErrorCode.TENANT_MAX_CLIENTS]: intl.getMessage('error_code_tenant_max_clients', props),
        [ApiErrorCode.TRIAL_PERIOD_DISABLED]: intl.getMessage('error_code_trial_period_disabled', props),
        [ApiErrorCode.UNKNOWN]: intl.getMessage('error_code_unknown', props),
        [ApiErrorCode.UNSUPPORTED_KERNEL_VERSION]: intl.getMessage('error_code_unsupported_kernel_version', props),
        [ApiErrorCode.VM_CLONE_NOT_FINISHED]: intl.getMessage('error_code_vm_clone_not_finished', props),
        [ApiErrorCode.VM_DESTROY_DISABLED]: intl.getMessage('error_code_vm_destroy_disabled', props),
        [ApiErrorCode.VM_LIMIT_EXCEED]: intl.getMessage('error_code_vm_limit_exceed', props),
        [ApiErrorCode.VM_MEMORY_LIMIT_EXCEED]: intl.getMessage('error_code_vm_memory_limit_exceed', props),
        [ApiErrorCode.VM_NOT_FOUND]: intl.getMessage('error_code_vm_not_found', props),
        [ApiErrorCode.VM_PASSWORD_WRONG]: intl.getMessage('error_code_vm_password_wrong', props),
        [ApiErrorCode.VM_REBOOT_NOT_COMPLETE]: intl.getMessage('error_code_vm_reboot_not_complete', props),
        [ApiErrorCode.VM_REINSTALL_CLONE_NOT_FINISHED]: intl.getMessage('error_code_vm_reinstall_clone_not_finished', props),
        [ApiErrorCode.VM_REMOVE_PROTECTED_SNAPSHOT]: intl.getMessage('error_code_vm_remove_protected_snapshot', props),
        [ApiErrorCode.VM_RESOURCE_CHANGE_WRONG]: intl.getMessage('error_code_vm_resource_change_wrong', props),
        [ApiErrorCode.VM_SERVICE_MODE]: intl.getMessage('error_code_vm_service_mode', props),
        [ApiErrorCode.VM_SHUTDOWN_NOT_COMPLETE]: intl.getMessage('error_code_vm_shutdown_not_complete', props),
        [ApiErrorCode.VM_SHUTOFF_CLIENT_DEBT]: intl.getMessage('error_code_vm_shutoff_client_debt', props),
        [ApiErrorCode.VM_SHUTOFF_REQUIRED]: intl.getMessage('error_code_vm_shutoff_required', props),
        [ApiErrorCode.VM_VOLUME_CHANGE_WRONG]: intl.getMessage('error_code_vm_volume_change_wrong', props),
        [ApiErrorCode.VM_VOLUME_UNDER_BACKUP_MERGING]: intl.getMessage('error_code_vm_volume_under_backup_merging', props),
        [ApiErrorCode.VM_VOLUME_UNDER_OPERATION]: intl.getMessage('error_code_vm_volume_under_operation', props),
        [ApiErrorCode.VM_WINDOWS_CREATE_TEST_DISABLED]: intl.getMessage('error_code_vm_windows_create_test_disabled', props),
        [ApiErrorCode.OBSOLETE_DISTRIBUTION]: intl.getMessage('error_code_obsolete_distribution', props),
        [ApiErrorCode.DNS_ZONE_PUNYCODE_FORBIDDEN]: intl.getMessage('error_code_dns_zone_punycode_forbidden', props),
        [ApiErrorCode.DNS_IMPORT_INVALID_CONFIG]: intl.getMessage('error_code_dns_import_invalid_config', props),
        [ApiErrorCode.PROFILE_NEW_PASSWORD_MATCH_OLD]: intl.getMessage('error_code_profile_new_password_match_old', props),
        [ApiErrorCode.INVOICE_INVALID_CREDIT_CARD]: intl.getMessage('error_code_invoice_invalid_credit_card', props),
        [ApiErrorCode.SOLAR_STAFF_REGISTRATION_INCOMPLETE]: intl.getMessage('error_code_solar_staff_registration_incomplete', props),
        [ApiErrorCode.SOLAR_STAFF_EMAIL_ALREADY_EXIST]: intl.getMessage('error_code_solar_staff_email_already_exist', props),
        [ApiErrorCode.SOLAR_STAFF_ACCOUNT_VERIFICATION_REQUIRED]: intl.getMessage('error_code_solar_staff_account_verification_required', props),
    };

    return errorMessages[value];
};

export const invoiceStatusTranslate = (intl: Translator, status: InvoiceStatus) => {
    const messages: Record<InvoiceStatus, string> = {
        [InvoiceStatus.SUCCESS]: intl.getMessage('invoice_status_success'),
        [InvoiceStatus.PROCESS]: intl.getMessage('invoice_status_process'),
        [InvoiceStatus.ERROR]: intl.getMessage('invoice_status_error'),
        [InvoiceStatus.CANCEL]: intl.getMessage('invoice_status_cancel'),
        [InvoiceStatus.SUCCESS_TRANSIT]: intl.getMessage('invoice_status_success_transit'),
        [InvoiceStatus.VOIDED]: intl.getMessage('invoice_status_success'),
    };
    return messages[status];
};

type TriggerMessagesOptionalKeys = 'prev' | 'new' | 'name' | 'value' | 'min' | 'date' | 'resolved' | 'url';
export const triggerTypeTranslate = (
    intl: Translator,
    type: TriggerRuleType,
    values?: Partial<Record<TriggerMessagesOptionalKeys, any>>,
) => {
    const safeValues: Record<TriggerMessagesOptionalKeys, any> = {
        prev: values?.prev ?? '',
        new: values?.new ?? '',
        name: values?.name ?? '',
        value: values?.value ?? '',
        min: values?.min ?? '',
        date: values?.date ? dayjs(values.date, DATE_FORMAT).format(intl.getMessage('time_format_date')) : '',
        resolved: values?.resolved ?? false,
        url: values?.url ?? '',
    };
    const messages: Record<TriggerRuleType, string> = {
        [TriggerRuleType.HTTPS_SSL_CERT]: safeValues.resolved
            ? intl.getMessage('trigger_rule_https_resolved', safeValues)
            : intl.getMessage('trigger_rule_https', { value: safeValues.date }),
        [TriggerRuleType.DAILY_EXPENSE]: intl.getMessage('trigger_rule_daily_expense', safeValues),
        [TriggerRuleType.LOAD_AVERAGE]: safeValues.resolved
            ? intl.getMessage('trigger_rule_load_average_resolved', safeValues)
            : intl.getMessage('trigger_rule_load_average', safeValues),
        [TriggerRuleType.DISK_FREE_SPACE]: intl.getMessage('trigger_rule_disk_free_space', safeValues),
        [TriggerRuleType.CPU_USAGE]: safeValues.resolved
            ? intl.getMessage('trigger_rule_cpu_usage_resolved', safeValues)
            : intl.getMessage('trigger_rule_cpu_usage', safeValues),
        [TriggerRuleType.PING]: safeValues.resolved
            ? intl.getMessage('trigger_rule_ping_resolved', safeValues)
            : intl.getMessage('trigger_rule_ping', safeValues),
        [TriggerRuleType.INODES_USE]: intl.getMessage('trigger_rule_inodes_use', safeValues),
        [TriggerRuleType.HTTP]: safeValues.resolved
            ? intl.getMessage('trigger_rule_http_resolved', safeValues)
            : intl.getMessage('trigger_rule_http', safeValues),
        [TriggerRuleType.RECEIVED_TRAFFIC_USAGE]: intl.getMessage('trigger_rule_received_traffic_usage', safeValues),
        [TriggerRuleType.TRANSFERRED_TRAFFIC_USAGE]: intl.getMessage('trigger_rule_transferred_traffic_usage', safeValues),
        [TriggerRuleType.MIN_BALANCE]: intl.getMessage('trigger_rule_min_balance', safeValues),
        [TriggerRuleType.DOMAIN_EXPIRATION]: safeValues.resolved
            ? intl.getMessage('trigger_rule_domain_expiration_resolved', safeValues)
            : intl.getMessage('trigger_rule_domain_expiration', safeValues),
        [TriggerRuleType.VM_CREATE]: intl.getMessage('trigger_rule_vm_create', safeValues),
        [TriggerRuleType.VM_REBOOT]: intl.getMessage('trigger_rule_vm_reboot', safeValues),
        [TriggerRuleType.VM_START]: intl.getMessage('trigger_rule_vm_start', safeValues),
        [TriggerRuleType.VM_SHUTOFF]: intl.getMessage('trigger_rule_vm_shutoff', safeValues),
        [TriggerRuleType.VM_DELETE]: intl.getMessage('trigger_rule_vm_delete', safeValues),
        [TriggerRuleType.VM_MEMORY_CHANGE]: intl.getMessage('trigger_rule_vm_memory_change', safeValues),
        [TriggerRuleType.VM_DISK_INCREASE]: intl.getMessage('trigger_rule_vm_disk_increase', safeValues),
        [TriggerRuleType.VM_PASSWORD_CHANGE]: intl.getMessage('trigger_rule_vm_password_change', safeValues),
        [TriggerRuleType.VM_MEMORY_DIFFERENT]: intl.getMessage('trigger_rule_vm_memory_different', safeValues),
        [TriggerRuleType.VM_OUT_OF_MEMORY]: intl.getMessage('trigger_rule_vm_out_of_memory', safeValues),
        [TriggerRuleType.VM_LINUX_KERNEL_EVENT]: intl.getMessage('trigger_rule_vm_linux_kernel_event', safeValues),
        [TriggerRuleType.VM_ACPI_MEMORY_HP_ERROR]: intl.getMessage('trigger_rule_vm_acpi_memory_hp_error', safeValues),
        [TriggerRuleType.VM_REVERT_TO_SNAPSHOT]: intl.getMessage('trigger_rule_vm_revert_to_snapshot', safeValues),
        [TriggerRuleType.VM_REVERT_TO_BACKUP]: intl.getMessage('trigger_rule_vm_revert_to_backup', safeValues),
    };
    return messages[type];
};

export const triggerTypeTranslateDesc = (
    intl: Translator,
    type: TriggerRuleType,
    values?: Partial<Record<TriggerMessagesOptionalKeys, any>>,
) => {
    const safeValues: Record<TriggerMessagesOptionalKeys, any> = {
        prev: values?.prev ?? '',
        new: values?.new ?? '',
        name: values?.name ?? '',
        value: values?.value ?? '',
        min: values?.min ?? '',
        date: values?.date ?? '',
        resolved: values?.resolved ?? false,
        url: values?.url ?? '',
    };
    const messages: Record<TriggerRuleType, string> = {
        [TriggerRuleType.HTTPS_SSL_CERT]: '',
        [TriggerRuleType.DAILY_EXPENSE]: '',
        [TriggerRuleType.LOAD_AVERAGE]: '',
        [TriggerRuleType.DISK_FREE_SPACE]: '',
        [TriggerRuleType.CPU_USAGE]: '',
        [TriggerRuleType.PING]: '',
        [TriggerRuleType.INODES_USE]: '',
        [TriggerRuleType.HTTP]: intl.getMessage('trigger_rule_http_desc', safeValues),
        [TriggerRuleType.RECEIVED_TRAFFIC_USAGE]: '',
        [TriggerRuleType.TRANSFERRED_TRAFFIC_USAGE]: '',
        [TriggerRuleType.MIN_BALANCE]: '',
        [TriggerRuleType.DOMAIN_EXPIRATION]: '',
        [TriggerRuleType.VM_CREATE]: `${intl.getMessage('trigger_rule_vm_create_desc_login', safeValues)}${safeValues.value ? ` ${intl.getMessage('trigger_rule_vm_create_desc_ip', safeValues)}` : ''}`,
        [TriggerRuleType.VM_REBOOT]: '',
        [TriggerRuleType.VM_START]: '',
        [TriggerRuleType.VM_SHUTOFF]: '',
        [TriggerRuleType.VM_DELETE]: '',
        [TriggerRuleType.VM_MEMORY_CHANGE]: '',
        [TriggerRuleType.VM_DISK_INCREASE]: '',
        [TriggerRuleType.VM_PASSWORD_CHANGE]: '',
        [TriggerRuleType.VM_MEMORY_DIFFERENT]: intl.getMessage('trigger_rule_vm_memory_different_desc'),
        [TriggerRuleType.VM_OUT_OF_MEMORY]: intl.getPlural('trigger_rule_vm_out_of_memory_desc', values?.value || 0, safeValues),
        [TriggerRuleType.VM_LINUX_KERNEL_EVENT]: intl.getMessage('trigger_rule_vm_linux_kernel_event_desc'),
        [TriggerRuleType.VM_ACPI_MEMORY_HP_ERROR]: intl.getMessage('trigger_rule_vm_acpi_memory_hp_error_desc'),
        [TriggerRuleType.VM_REVERT_TO_SNAPSHOT]: '',
        [TriggerRuleType.VM_REVERT_TO_BACKUP]: '',
    };
    return messages[type];
};

type TriggerDescriptionKeys = 'value' | 'min' | 'url' | 'b';
export const triggerDescriptionTranslate = (
    intl: Translator,
    type: TriggerRuleType,
    values?: Partial<Record<TriggerDescriptionKeys, any>>,
) => {
    const safeValues: Record<TriggerDescriptionKeys, any> = {
        value: values?.value ?? '',
        min: values?.min ?? '',
        url: values?.url ?? '',
        b: values?.b ?? '',
    };
    const messages: Record<TriggerRuleType, string> = {
        [TriggerRuleType.HTTPS_SSL_CERT]: intl.getMessage('trigger_desc_https', { ...safeValues, min: intl.getPlural('more_than_minutes', safeValues.min, safeValues) }),
        [TriggerRuleType.CPU_USAGE]: intl.getMessage('trigger_desc_cpu_usage', { ...safeValues, min: intl.getPlural('more_than_minutes', safeValues.min, safeValues) }),
        [TriggerRuleType.DAILY_EXPENSE]: intl.getMessage('trigger_desc_daily_expense', { ...safeValues, value: intl.getMessage('money', safeValues) }),
        [TriggerRuleType.DISK_FREE_SPACE]: intl.getMessage('trigger_desc_disk_free_space', safeValues),
        [TriggerRuleType.HTTP]: intl.getMessage('trigger_desc_http', { ...safeValues, min: intl.getPlural('more_than_minutes', safeValues.min, safeValues) }),
        [TriggerRuleType.INODES_USE]: intl.getMessage('trigger_desc_inodes_use', safeValues),
        [TriggerRuleType.LOAD_AVERAGE]: intl.getMessage('trigger_desc_load_average', { ...safeValues, min: intl.getPlural('more_than_minutes', safeValues.min, safeValues) }),
        [TriggerRuleType.MIN_BALANCE]: intl.getMessage('trigger_desc_min_balance', { ...safeValues, value: intl.getMessage('money', safeValues) }),
        [TriggerRuleType.PING]: intl.getMessage('trigger_desc_ping', { ...safeValues, min: intl.getPlural('more_than_minutes', safeValues.min, safeValues) }),
        [TriggerRuleType.RECEIVED_TRAFFIC_USAGE]: intl.getMessage('trigger_desc_received_traffic_usage', safeValues),
        [TriggerRuleType.TRANSFERRED_TRAFFIC_USAGE]: intl.getMessage('trigger_desc_transferred_traffic_usage', safeValues),
        [TriggerRuleType.DOMAIN_EXPIRATION]: intl.getMessage('trigger_desc_domain_expiration', safeValues),
        [TriggerRuleType.VM_ACPI_MEMORY_HP_ERROR]: '',
        [TriggerRuleType.VM_CREATE]: '',
        [TriggerRuleType.VM_DELETE]: '',
        [TriggerRuleType.VM_DISK_INCREASE]: '',
        [TriggerRuleType.VM_LINUX_KERNEL_EVENT]: '',
        [TriggerRuleType.VM_MEMORY_CHANGE]: '',
        [TriggerRuleType.VM_MEMORY_DIFFERENT]: '',
        [TriggerRuleType.VM_OUT_OF_MEMORY]: '',
        [TriggerRuleType.VM_PASSWORD_CHANGE]: '',
        [TriggerRuleType.VM_REBOOT]: '',
        [TriggerRuleType.VM_SHUTOFF]: '',
        [TriggerRuleType.VM_START]: '',
        [TriggerRuleType.VM_REVERT_TO_SNAPSHOT]: '',
        [TriggerRuleType.VM_REVERT_TO_BACKUP]: '',
    };
    return messages[type];
};

export const triggerFormTypeTranslate = (
    intl: Translator,
    type: TriggerRuleType,
) => {
    const messages: Record<TriggerRuleType, string | null> = {
        [TriggerRuleType.HTTPS_SSL_CERT]: intl.getMessage('trigger_type_https'),
        [TriggerRuleType.CPU_USAGE]: intl.getMessage('trigger_type_cpu_usage'),
        [TriggerRuleType.DAILY_EXPENSE]: intl.getMessage('trigger_type_daily_expense'),
        [TriggerRuleType.DISK_FREE_SPACE]: intl.getMessage('trigger_type_disk_free_space'),
        [TriggerRuleType.HTTP]: intl.getMessage('trigger_type_http'),
        [TriggerRuleType.INODES_USE]: intl.getMessage('trigger_type_inodes_use'),
        [TriggerRuleType.LOAD_AVERAGE]: intl.getMessage('trigger_type_load_average'),
        [TriggerRuleType.MIN_BALANCE]: intl.getMessage('trigger_type_min_balance'),
        [TriggerRuleType.PING]: intl.getMessage('trigger_type_ping'),
        [TriggerRuleType.RECEIVED_TRAFFIC_USAGE]: intl.getMessage('trigger_type_received_traffic_usage'),
        [TriggerRuleType.TRANSFERRED_TRAFFIC_USAGE]: intl.getMessage('trigger_type_transferred_traffic_usage'),
        [TriggerRuleType.DOMAIN_EXPIRATION]: intl.getMessage('trigger_type_domain_expiration'),
        [TriggerRuleType.VM_ACPI_MEMORY_HP_ERROR]: null,
        [TriggerRuleType.VM_CREATE]: null,
        [TriggerRuleType.VM_DELETE]: null,
        [TriggerRuleType.VM_DISK_INCREASE]: null,
        [TriggerRuleType.VM_LINUX_KERNEL_EVENT]: null,
        [TriggerRuleType.VM_MEMORY_CHANGE]: null,
        [TriggerRuleType.VM_MEMORY_DIFFERENT]: null,
        [TriggerRuleType.VM_OUT_OF_MEMORY]: null,
        [TriggerRuleType.VM_PASSWORD_CHANGE]: null,
        [TriggerRuleType.VM_REBOOT]: null,
        [TriggerRuleType.VM_SHUTOFF]: null,
        [TriggerRuleType.VM_START]: null,
        [TriggerRuleType.VM_REVERT_TO_SNAPSHOT]: null,
        [TriggerRuleType.VM_REVERT_TO_BACKUP]: null,
    };
    return messages[type];
};

export const triggerTranslator = (
    intl: Translator,
    type: TriggerRuleType,
    payload: NotificationPayload,
) => {
    return triggerTypeTranslate(intl, type, triggerValueMatcher(type, payload));
};

export const triggerDescTranslator = (
    intl: Translator,
    type: TriggerRuleType,
    payload: NotificationPayload,
) => {
    return triggerTypeTranslateDesc(intl, type, triggerValueMatcher(type, payload));
};

export const securityActionTranslate = (intl: Translator, step: SecurityActionType) => {
    const message: Record<SecurityActionType, string> = {
        [SecurityActionType.DOWNLOAD_BACKUP]: intl.getMessage('security_action_download_backup'),
        [SecurityActionType.DOWNLOAD_SNAPSHOT]: intl.getMessage('security_action_download_snapshot'),
        [SecurityActionType.REVERT_TO_BACKUP]: intl.getMessage('security_action_revert_backup'),
        [SecurityActionType.REVERT_TO_SNAPSHOT]: intl.getMessage('security_action_revert_snapshot'),
        [SecurityActionType.SERVER_CONSOLE_ACCESS]: intl.getMessage('security_action_console'),
        [SecurityActionType.SERVER_DELETE]: intl.getMessage('security_action_delete'),
        [SecurityActionType.SERVER_PASSWORD]: intl.getMessage('security_action_password'),
        [SecurityActionType.SERVER_PUBLIC_KEY]: intl.getMessage('security_action_public_keys'),
        [SecurityActionType.SERVER_REINSTALL]: intl.getMessage('security_action_reinstall'),
        [SecurityActionType.TENANT_SHARE_ACCESS]: intl.getMessage('security_action_tenant_share_access'),
    };
    return message[step];
};

export const serverStateTranslate = (intl: Translator, state: ServerState) => {
    const message: Record<ServerState, string> = {
        [ServerState.BLOCKED]: intl.getMessage('blocked'),
        [ServerState.CRASHED]: intl.getMessage('crashed'),
        [ServerState.INSTALLING]: intl.getMessage('installing'),
        [ServerState.PENDING_INSTALL]: intl.getMessage('pending_install'),
        [ServerState.NO_STATE]: intl.getMessage('no_state'),
        [ServerState.PAUSED]: intl.getMessage('paused'),
        [ServerState.REBOOT]: intl.getMessage('reboot'),
        [ServerState.RUNNING]: intl.getMessage('running'),
        [ServerState.SHUTDOWN]: intl.getMessage('shutdown'),
        [ServerState.SHUTOFF]: intl.getMessage('shutoff'),
    };
    return message[state];
};

export enum SERVER_SORT {
    BY_NAME = 'BY_NAME',
    BY_CREATED = 'BY_CREATED',
    BY_CONSUMPTION = 'BY_CONSUMPTION',
    BY_MEMORY = 'BY_MEMORY',
    BY_STORAGE = 'BY_STORAGE',
    BY_STATUS = 'BY_STATUS',
}

export const serverSortTranslate = (intl: Translator, step: SERVER_SORT) => {
    const message: Record<SERVER_SORT, string> = {
        [SERVER_SORT.BY_NAME]: intl.getMessage('sort_by_name'),
        [SERVER_SORT.BY_CREATED]: intl.getMessage('sort_by_created'),
        [SERVER_SORT.BY_CONSUMPTION]: intl.getMessage('sort_by_consumption'),
        [SERVER_SORT.BY_MEMORY]: intl.getMessage('sort_by_memory'),
        [SERVER_SORT.BY_STORAGE]: intl.getMessage('sort_by_storage'),
        [SERVER_SORT.BY_STATUS]: intl.getMessage('sort_by_status'),
    };
    return message[step];
};

export enum SERVER_SELECT {
    DEFAULT = 'DEFAULT',
    BY_ACTIVE = 'BY_ACTIVE',
    BY_INACTIVE = 'BY_INACTIVE',
}

export const serverSelectTranslate = (intl: Translator, step: SERVER_SELECT) => {
    const message: Record<SERVER_SELECT, string> = {
        [SERVER_SELECT.DEFAULT]: intl.getMessage('select_default'),
        [SERVER_SELECT.BY_ACTIVE]: intl.getMessage('select_by_active'),
        [SERVER_SELECT.BY_INACTIVE]: intl.getMessage('select_by_inactive'),
    };
    return message[step];
};

export const getServerViewTitle = (intl: Translator, type: SERVER_VIEW) => {
    const icon: Record<SERVER_VIEW, string> = {
        [SERVER_VIEW.LIST]: intl.getMessage('title_compact_view'),
        [SERVER_VIEW.COMPACT]: intl.getMessage('title_tiles_view'),
        [SERVER_VIEW.EXPAND]: intl.getMessage('title_expand_view'),
    };
    return icon[type];
};

export const getAccountTypeText = (intl: Translator, type: CardAccounts) => {
    const message: Partial<Record<CardAccounts, string>> = {
        [CardAccounts.SERVICE_RENTAL_FEE]: intl.getMessage('service_rental_fee'),
        [CardAccounts.SERVICE_CPU]: intl.getMessage('service_cpu'),
        [CardAccounts.SERVICE_MEMORY]: intl.getMessage('service_memory'),
        [CardAccounts.SERVICE_STORAGE]: intl.getMessage('service_storage'),
        [CardAccounts.SERVICE_IP]: intl.getMessage('service_ip'),
        [CardAccounts.SERVICE_TRAFFIC]: intl.getMessage('service_traffic_transferred'),
        [CardAccounts.SERVICE_TRAFFIC_RECEIVED]: intl.getMessage('service_traffic_received'),
        [CardAccounts.SERVICE_SMS]: intl.getMessage('service_sms'),
        [CardAccounts.SERVICE_BACKUP]: intl.getMessage('service_backup'),
        [CardAccounts.SERVICE_RESTORE]: intl.getMessage('service_restore'),
        [CardAccounts.SERVICE_TRANSFER]: intl.getMessage('service_transfer'),
        [CardAccounts.SERVICE_SUPPORT]: intl.getMessage('service_support'),
        [CardAccounts.SERVICE_RESELLING]: intl.getMessage('service_reselling'),
        [CardAccounts.SERVICE_WIN_RDP_SVCS_SAL]: intl.getMessage('service_win_rdp_svcs_sal'),
        [CardAccounts.SERVICE_MSSQL_SERVER]: intl.getMessage('service_mssql_server'),
        [CardAccounts.SERVICE_MSSQL_SERVICE_WEB]: intl.getMessage('service_mssql_server_web'),
        [CardAccounts.SERVICE_SOFTWARE_LICENSE]: intl.getMessage('service_software_license'),
    };
    return message[type];
};

export enum Subjects {
    COMMON = 'COMMON',
    DOCUMENTS = 'DOCUMENTS',
    ERRORS = 'ERRORS',
    SUGGESTIONS = 'SUGGESTIONS',
    OTHER = 'OTHER',
}

export const subjectTranslate = (intl: Translator, type: Subjects) => {
    const message: Record<Subjects, string> = {
        [Subjects.COMMON]: intl.getMessage('support_subject_common'),
        [Subjects.DOCUMENTS]: intl.getMessage('support_subject_documents'),
        [Subjects.ERRORS]: intl.getMessage('support_subject_errors'),
        [Subjects.SUGGESTIONS]: intl.getMessage('support_subject_suggestions'),
        [Subjects.OTHER]: intl.getMessage('support_subject_other'),
    };
    return message[type];
};

export const getRangeLabel = (intl: Translator, type: DATE_RANGE_TYPES) => {
    const icon: Record<DATE_RANGE_TYPES, string> = {
        [DATE_RANGE_TYPES.TODAY]: intl.getMessage('today'),
        [DATE_RANGE_TYPES.HOUR_3]: intl.getPlural('count_hour', 3),
        [DATE_RANGE_TYPES.DAY]: intl.getMessage('day'),
        [DATE_RANGE_TYPES.WEEK]: intl.getMessage('week'),
        [DATE_RANGE_TYPES.MONTH]: intl.getPlural('month', 1),
        [DATE_RANGE_TYPES.QUARTER]: intl.getPlural('month', 3),
        [DATE_RANGE_TYPES.YEAR]: intl.getMessage('year'),
    };
    return icon[type];
};

export const getActRangeLabel = (intl: Translator, type: ACT_RANGE_TYPE) => {
    const icon: Record<ACT_RANGE_TYPE, string> = {
        [ACT_RANGE_TYPE.QUARTER_1]: intl.getMessage('count_quarter', { value: 1 }),
        [ACT_RANGE_TYPE.QUARTER_2]: intl.getMessage('count_quarter', { value: 2 }),
        [ACT_RANGE_TYPE.QUARTER_3]: intl.getMessage('count_quarter', { value: 3 }),
        [ACT_RANGE_TYPE.QUARTER_4]: intl.getMessage('count_quarter', { value: 4 }),
        [ACT_RANGE_TYPE.HALF_YEAR_1]: intl.getMessage('count_half_year', { value: 1 }),
        [ACT_RANGE_TYPE.HALF_YEAR_2]: intl.getMessage('count_half_year', { value: 2 }),
        [ACT_RANGE_TYPE.YEAR]: intl.getMessage('year'),
    };
    return icon[type];
};

export const getTicketSubject = (intl: Translator, subject: TICKETS_SUBJECT) => {
    const message: Record<TICKETS_SUBJECT, string> = {
        [TICKETS_SUBJECT.EMAIL_EDIT]: intl.getMessage('support_subject_edit_email'),
        [TICKETS_SUBJECT.GENERAL_QUESTIONS]: intl.getMessage('support_subject_common'),
        [TICKETS_SUBJECT.DELETE_CLIENT]: intl.getMessage('support_subject_client_delete'),
        [TICKETS_SUBJECT.EDIT_PROFILE]: intl.getMessage('support_subject_edit_profile'),
        [TICKETS_SUBJECT.OPEN_STUDIO_ACCESS]: intl.getMessage('support_subject_open_studio_access'),
    };
    return message[subject];
};

export const getResourcesLabel = (intl: Translator, recource: ResourceUsageType) => {
    const message: Record<ResourceUsageType, string> = {
        [ResourceUsageType.CPU]: intl.getMessage('tariff_recommendations_cpu'),
        [ResourceUsageType.DISK]: intl.getMessage('tariff_recommendations_disk'),
        [ResourceUsageType.MEMORY]: intl.getMessage('tariff_recommendations_memory'),
    };
    return message[recource];
};
