import { BackupPolicyFrequency } from './BackupPolicyFrequency';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerSetup {
    backup_frequency: BackupPolicyFrequency;
    backup_quantity: number;
    cpu_cores: number;
    disk_gib: number;
    distribution_id: number;
    ip_count: number;
    memory_mib: number;
    name: string;
    open_support_access: boolean;
    password?: string;
    public_key_ids: number[];
    send_password: boolean;
    tariff_id: number;
}

export default class ServerSetup {
    readonly _backup_frequency: BackupPolicyFrequency;

    get backupFrequency(): BackupPolicyFrequency {
        return this._backup_frequency;
    }

    static backupFrequencyValidate(backupFrequency: BackupPolicyFrequency): boolean {
        return Object.keys(BackupPolicyFrequency).includes(backupFrequency);
    }

    readonly _backup_quantity: number;

    /**
     * Description: Number of preserved backups
     * Example: 1
     */
    get backupQuantity(): number {
        return this._backup_quantity;
    }

    static backupQuantityValidate(backupQuantity: number): boolean {
        return typeof backupQuantity === 'number';
    }

    readonly _cpu_cores: number;

    /**
     * Description: CPU cores. Configurable only for non-fixed tariffs
     * Example: 1
     */
    get cpuCores(): number {
        return this._cpu_cores;
    }

    static cpuCoresValidate(cpuCores: number): boolean {
        return typeof cpuCores === 'number';
    }

    readonly _disk_gib: number;

    /**
     * Description: Disk capacity in gib. Configurable only for non-fixed tariffs
     * Example: 16
     */
    get diskGib(): number {
        return this._disk_gib;
    }

    static diskGibValidate(diskGib: number): boolean {
        return typeof diskGib === 'number';
    }

    readonly _distribution_id: number;

    /**
     * Description: Distribution identifier
     * Example: 28684
     */
    get distributionId(): number {
        return this._distribution_id;
    }

    static distributionIdValidate(distributionId: number): boolean {
        return typeof distributionId === 'number';
    }

    readonly _ip_count: number;

    /**
     * Description: Public IP addresses count. Configurable only for non-fixed tariffs
     * Example: 1
     */
    get ipCount(): number {
        return this._ip_count;
    }

    static ipCountValidate(ipCount: number): boolean {
        return typeof ipCount === 'number';
    }

    readonly _memory_mib: number;

    /**
     * Description: Memory in mib. Configurable only for non-fixed tariffs
     * Example: 512
     */
    get memoryMib(): number {
        return this._memory_mib;
    }

    static memoryMibValidate(memoryMib: number): boolean {
        return typeof memoryMib === 'number';
    }

    readonly _name: string;

    /**
     * Description: Name
     * Example: dev_backend
     */
    get name(): string {
        return this._name;
    }

    static get nameMinLength() {
        return 0;
    }

    static get nameMaxLength() {
        return 70;
    }

    static nameValidate(name: string): boolean {
        return (name.length > 0) && (name.length <= 70);
    }

    readonly _open_support_access: boolean;

    /**
     * Description: Allow support access
     * Example: true
     */
    get openSupportAccess(): boolean {
        return this._open_support_access;
    }

    static openSupportAccessValidate(openSupportAccess: boolean): boolean {
        return typeof openSupportAccess === 'boolean';
    }

    readonly _password: string | undefined;

    /**
     * Description: Superuser password
     * Example: ]u77EPd.
     */
    get password(): string | undefined {
        return this._password;
    }

    static get passwordMinLength() {
        return 8;
    }

    static get passwordMaxLength() {
        return 2147483647;
    }

    static passwordValidate(password: string | undefined): boolean {
        return (!password ? true : password.length >= 8) && (!password ? true : password.length <= 2147483647);
    }

    readonly _public_key_ids: number[];

    /**
     * Description: Public key identifiers
     * Example: 402
     */
    get publicKeyIds(): number[] {
        return this._public_key_ids;
    }

    static publicKeyIdsValidate(publicKeyIds: number[]): boolean {
        return publicKeyIds.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    readonly _send_password: boolean;

    /**
     * Description: Send password to email
     * Example: true
     */
    get sendPassword(): boolean {
        return this._send_password;
    }

    static sendPasswordValidate(sendPassword: boolean): boolean {
        return typeof sendPassword === 'boolean';
    }

    readonly _tariff_id: number;

    /**
     * Description: Tariff identifier
     * Example: 10
     */
    get tariffId(): number {
        return this._tariff_id;
    }

    static tariffIdValidate(tariffId: number): boolean {
        return typeof tariffId === 'number';
    }

    constructor(props: IServerSetup) {
        this._backup_frequency = props.backup_frequency;
        this._backup_quantity = props.backup_quantity;
        this._cpu_cores = props.cpu_cores;
        this._disk_gib = props.disk_gib;
        this._distribution_id = props.distribution_id;
        this._ip_count = props.ip_count;
        this._memory_mib = props.memory_mib;
        this._name = props.name.trim();
        this._open_support_access = props.open_support_access;
        if (typeof props.password === 'string') {
            this._password = props.password.trim();
        }
        this._public_key_ids = props.public_key_ids;
        this._send_password = props.send_password;
        this._tariff_id = props.tariff_id;
    }

    serialize(): IServerSetup {
        const data: IServerSetup = {
            backup_frequency: this._backup_frequency,
            backup_quantity: this._backup_quantity,
            cpu_cores: this._cpu_cores,
            disk_gib: this._disk_gib,
            distribution_id: this._distribution_id,
            ip_count: this._ip_count,
            memory_mib: this._memory_mib,
            name: this._name,
            open_support_access: this._open_support_access,
            public_key_ids: this._public_key_ids,
            send_password: this._send_password,
            tariff_id: this._tariff_id,
        };
        if (typeof this._password !== 'undefined') {
            data.password = this._password;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: (this._name.length > 0) && (this._name.length <= 70),
            distribution_id: typeof this._distribution_id === 'number',
            tariff_id: typeof this._tariff_id === 'number',
            public_key_ids: this._public_key_ids.reduce((result, p) => result && typeof p === 'number', true),
            memory_mib: typeof this._memory_mib === 'number',
            disk_gib: typeof this._disk_gib === 'number',
            ip_count: typeof this._ip_count === 'number',
            cpu_cores: typeof this._cpu_cores === 'number',
            open_support_access: typeof this._open_support_access === 'boolean',
            password: (!this._password ? true : this._password.length >= 8) && (!this._password ? true : this._password.length <= 2147483647),
            send_password: typeof this._send_password === 'boolean',
            backup_quantity: typeof this._backup_quantity === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerSetup): ServerSetup {
        return new ServerSetup(props);
    }

    readonly keys: { [key: string]: string } = {
        backupFrequency: 'backup_frequency',
        backupQuantity: 'backup_quantity',
        cpuCores: 'cpu_cores',
        diskGib: 'disk_gib',
        distributionId: 'distribution_id',
        ipCount: 'ip_count',
        memoryMib: 'memory_mib',
        name: 'name',
        openSupportAccess: 'open_support_access',
        password: 'password',
        publicKeyIds: 'public_key_ids',
        sendPassword: 'send_password',
        tariffId: 'tariff_id',
        }
;

    mergeDeepWith(props: Partial<ServerSetup>): ServerSetup {
        const updateData: Partial<IServerSetup> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerSetup) => {
            const updateKey = this.keys[key] as keyof IServerSetup;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerSetup, keyof IServerSetup>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerSetup({ ...this.serialize(), ...updateData });
    }
}
