import { Action } from 'store/actions';
import { DistributionActions, saveDistributionsList } from 'Actions/distribution';
import ServerDistribution from 'Entities/ServerDistribution';

import { DistributionStore, distributionInitialState as initialState } from '../initialStates';

const reducer = (state: DistributionStore = initialState, action: Action): DistributionStore => {
    switch (action.type as DistributionActions) {
        case DistributionActions.SaveDistributions: {
            const { payload } = action as ReturnType<typeof saveDistributionsList>;
            const tu = new Map<number, ServerDistribution>();
            payload.forEach((s) => {
                tu.set(s.id, s);
            });
            return tu;
        }
        default:
            return state;
    }
};

export default reducer;
