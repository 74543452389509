import React, { FC } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';

import { Checkbox, Icon, Link, useIntl } from 'Common';
import TicketEntity from 'Entities/Ticket';
import { RoutePath } from 'Lib/helpers/routes';

import s from './Ticket.module.pcss';

interface TicketProps {
    ticket: TicketEntity;
    checkList: Set<number>;
    setCheckList: (set: Set<number>) => void;
    disabledSelect: boolean;
    unread?: boolean;
}

const Ticket: FC<TicketProps> = ({ ticket, checkList, setCheckList, disabledSelect, unread }) => {
    const intl = useIntl();
    const {
        id,
        subject,
        unreadPostCount,
        lastActivityTimeMillis,
        posts,
    } = ticket;

    const isChecked = checkList.has(id);

    const onCheckChange = () => {
        if (checkList.has(id)) {
            checkList.delete(id);
        } else {
            checkList.add(id);
        }
        setCheckList(new Set(checkList));
    };

    const lastPost = posts && posts[posts.length - 1];

    return (
        <Link
            to={RoutePath.TicketChat}
            props={{ id }}
            key={id}
            className={cn(
                s.ticket,
                { [s.ticket_unread]: unread },
            )}
        >
            <div className={s.header}>
                {unread && (
                    <div className={s.checkbox}>
                        <div
                            className={s.checkboxWrap}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Checkbox
                                id={`ticket_${id}`}
                                name={`ticket_${id}`}
                                checked={isChecked}
                                handleChange={onCheckChange}
                                disabled={disabledSelect}
                            />
                        </div>
                    </div>
                )}
                <div
                    className={cn(
                        s.indicator,
                        { [s.indicator_unread]: unread },
                    )}
                >
                    {unread && unreadPostCount > 0 ? (
                        unreadPostCount
                    ) : (
                        <Icon
                            className={s.ico}
                            icon="ticket"
                        />
                    )}
                </div>
                <div className={s.text}>
                    <div className={s.wrap}>
                        <div className={s.subject}>
                            {subject}
                        </div>
                        <div className={s.date}>
                            {dayjs(lastActivityTimeMillis)
                                .format(intl.getMessage('time_format_short_month_year'))}
                        </div>
                    </div>
                    <div className={cn(s.message, s.message_desktop)}>
                        {lastPost?.contents}
                    </div>
                </div>
            </div>
            <div className={cn(s.message, s.message_mobile)}>
                {lastPost?.contents}
            </div>
        </Link>
    );
};
export default Ticket;
