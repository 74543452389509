import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FormikHelpers, FormikProps } from 'formik';

import { updateProfile } from 'Actions/profile';
import ProfileUpdate from 'Entities/ProfileUpdate';
import { useIntl, notifySuccess, notifyError, Input, FormModalLayout, Button } from 'Common';
import { passwordValidate } from 'Lib/helpers/utils';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import ApiError from 'Entities/Error';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { useEnter, useEscape } from 'Hooks';

import theme from 'Lib/theme';
import s from './ChangePassword.module.pcss';

interface FormValues {
    oldPassword: string;
    newPassword: string;
}

interface ChangePasswordProps {
    handleClose: () => void;
}

const ChangePassword: FC<ChangePasswordProps> = ({ handleClose }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!values.oldPassword) {
            helpers.setFieldError('oldPassword', EMPTY_FIELD_ERROR);
            helpers.setSubmitting(false);
        }
        if (!values.newPassword) {
            helpers.setFieldError('newPassword', EMPTY_FIELD_ERROR);
            helpers.setSubmitting(false);
        }

        if (!values.oldPassword || !values.newPassword) {
            return;
        }

        const valid = passwordValidate(values.newPassword, ProfileUpdate.newPasswordMinLength, true);

        if (!valid) {
            helpers.setFieldError('newPassword', EMPTY_FIELD_ERROR);
            helpers.setSubmitting(false);
            return;
        }

        dispatch(updateProfile([{
            current_password: values.oldPassword,
            new_password: values.newPassword,
        }], {
            result: () => {
                notifySuccess(intl.getMessage('password_changed'), 'password_changed');
                handleClose();
            },
            error: (error: ApiError) => {
                if (error.fields?.current_password) {
                    const code: ApiErrorCode = error.fields?.current_password[0]?.error_code;
                    notifyError(apiErrorCodeTranslate(intl, code));
                    helpers.setFieldError('oldPassword', apiErrorCodeTranslate(intl, code));
                }
                if (error.fields?.new_password) {
                    const code: ApiErrorCode = error.fields?.new_password[0]?.error_code;
                    notifyError(apiErrorCodeTranslate(intl, code));
                    helpers.setFieldError('newPassword', apiErrorCodeTranslate(intl, code));
                }
            },
        }));
    };

    useEscape(handleClose);

    const initialValues: FormValues = {
        oldPassword: '',
        newPassword: '',
    };

    return (
        <FormModalLayout
            visible
            title={intl.getMessage('change_password')}
            initialValues={initialValues}
            handleClose={handleClose}
            handleSubmit={onSubmit}
            width={480}
            buttonText={intl.getMessage('edit')}
            disabled={!initialValues.oldPassword || !initialValues.newPassword}
            noFooter
        >
            {({
                values,
                errors,
                dirty,
                handleSubmit,
                setFieldValue,
                setFieldError,
                setSubmitting,
            }: FormikProps<FormValues>) => {
                const handleApply = () => {
                    if (dirty) {
                        handleSubmit();
                    } else {
                        if (!values.oldPassword) {
                            setFieldError('oldPassword', EMPTY_FIELD_ERROR);
                            setSubmitting(false);
                        }
                        if (!values.newPassword) {
                            setFieldError('newPassword', EMPTY_FIELD_ERROR);
                            setSubmitting(false);
                        }
                    }
                };
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEnter(handleApply, [handleApply]);

                return (
                    <>
                        <div className={s.wrap}>
                            <Input
                                value={values.oldPassword}
                                id="oldPassword"
                                name="oldPassword"
                                autoComplete="current-password"
                                onChange={(v) => setFieldValue('oldPassword', v)}
                                placeholder={intl.getMessage('password_old')}
                                size="large"
                                type="password"
                                error={!!errors.oldPassword}
                                optional={false}
                            />
                        </div>
                        <div className={s.wrap}>
                            <Input
                                id="newPassword"
                                name="newPassword"
                                value={values.newPassword}
                                autoComplete="new-password"
                                error={!!errors.newPassword}
                                onChange={(v) => setFieldValue('newPassword', v)}
                                placeholder={intl.getMessage('password_new')}
                                size="large"
                                type="password"
                                optional={false}
                            />
                        </div>
                        <div className={s.desc}>
                            {intl.getMessage('settings_password_desc', { min: ProfileUpdate.newPasswordMinLength })}
                        </div>
                        <div className={theme.modal.footer}>
                            <Button
                                inGroup
                                type="primary"
                                size="big"
                                htmlType="submit"
                                disabled={!values.oldPassword || !values.newPassword}
                            >
                                {intl.getMessage('edit')}
                            </Button>
                            <Button
                                type="link"
                                size="big"
                                onClick={handleClose}
                            >
                                {intl.getMessage('cancel')}
                            </Button>
                        </div>
                    </>
                );
            }}
        </FormModalLayout>
    );
};

export default ChangePassword;
