import NotificationDeliverySettings, { INotificationDeliverySettings } from './NotificationDeliverySettings';
import SmsDeliveryPeriod, { ISmsDeliveryPeriod } from './SmsDeliveryPeriod';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface INotificationSettings {
    delivery_settings: INotificationDeliverySettings[];
    sms_delivery_periods: ISmsDeliveryPeriod[];
}

export default class NotificationSettings {
    readonly _delivery_settings: NotificationDeliverySettings[];

    /** */
    get deliverySettings(): NotificationDeliverySettings[] {
        return this._delivery_settings;
    }

    readonly _sms_delivery_periods: SmsDeliveryPeriod[];

    /** */
    get smsDeliveryPeriods(): SmsDeliveryPeriod[] {
        return this._sms_delivery_periods;
    }

    constructor(props: INotificationSettings) {
        this._delivery_settings = props.delivery_settings.map((p) => new NotificationDeliverySettings(p));
        this._sms_delivery_periods = props.sms_delivery_periods.map((p) => new SmsDeliveryPeriod(p));
    }

    serialize(): INotificationSettings {
        const data: INotificationSettings = {
            delivery_settings: this._delivery_settings.map((p) => p.serialize()),
            sms_delivery_periods: this._sms_delivery_periods.map((p) => p.serialize()),
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            sms_delivery_periods: this._sms_delivery_periods.reduce((result, p) => result && p.validate().length === 0, true),
            delivery_settings: this._delivery_settings.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: INotificationSettings): NotificationSettings {
        return new NotificationSettings(props);
    }

    readonly keys: { [key: string]: string } = {
        deliverySettings: 'delivery_settings',
        smsDeliveryPeriods: 'sms_delivery_periods',
        }
;

    mergeDeepWith(props: Partial<NotificationSettings>): NotificationSettings {
        const updateData: Partial<INotificationSettings> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof NotificationSettings) => {
            const updateKey = this.keys[key] as keyof INotificationSettings;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<INotificationSettings, keyof INotificationSettings>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new NotificationSettings({ ...this.serialize(), ...updateData });
    }
}
