import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import { WebTheme } from 'Entities/WebTheme';
import { Range } from 'Lib/helpers/utils';
import { Translator } from 'Lib/intl';
import {
    SERVER_OPERATION_NOTIFICATION,
    DATE_RANGE_TYPES,
    MIN_ROUND_VALUE,
} from './consts';
import { QueryParams } from './routes';

export const roundNumber = (num: number) => Math.round(num * 100) / 100;
export const roundDiff = (num: number) => {
    return num > MIN_ROUND_VALUE ? roundNumber(num) : Math.round(num * 1000) / 1000;
};

/*
Used for cheching if we got time equal to the end of the day
in format 24:0 (24 hours, 0 minutes) (H:m)
for dayjs it equals to 0:0 that is why we change 24:0 for 23:59
*/
export const checkDayEnd = (time: string) => {
    if (time === '24:0') {
        return '23:59';
    }
    return time;
};

export const isSystemEvent = (event: TriggerRuleType) => {
    return event.indexOf('VM_') > -1;
};

export const shouldShowUiNotification = (notification: SERVER_OPERATION_NOTIFICATION): boolean => {
    try {
        return !UserStorage.getServerOperationNotification(notification);
    } catch {
        return true;
    }
};

export const getCurrentTheme = (value: WebTheme): WebTheme => {
    if (value === WebTheme.AUTO) {
        const prefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        return prefersDark ? WebTheme.DARK : WebTheme.LIGHT;
    }

    return value;
};

export const toMb = (gb: number) => gb * 1024;

export const toGb = (mb: number) => mb / 1024;

export const getDateRangeMs = (range: Range) => {
    return { from: range.from.valueOf(), till: range.to.valueOf() };
};

const NOW_RANGE = 'now';
export const relativeFromValues: Record<string, DATE_RANGE_TYPES> = {
    [NOW_RANGE]: DATE_RANGE_TYPES.TODAY,
    '-3h': DATE_RANGE_TYPES.HOUR_3,
    '-1d': DATE_RANGE_TYPES.DAY,
    '-7d': DATE_RANGE_TYPES.WEEK,
    '-1M': DATE_RANGE_TYPES.MONTH,
    '-3M': DATE_RANGE_TYPES.QUARTER,
    '-1y': DATE_RANGE_TYPES.YEAR,
};

export const getRelativeRange = (type: DATE_RANGE_TYPES) => {
    const range: Record<DATE_RANGE_TYPES, Partial<Record<QueryParams, string>>> = {
        [DATE_RANGE_TYPES.TODAY]: { from: NOW_RANGE, till: NOW_RANGE },
        [DATE_RANGE_TYPES.HOUR_3]: { from: '-3h', till: NOW_RANGE },
        [DATE_RANGE_TYPES.DAY]: { from: '-1d', till: NOW_RANGE },
        [DATE_RANGE_TYPES.WEEK]: { from: '-7d', till: NOW_RANGE },
        [DATE_RANGE_TYPES.MONTH]: { from: '-1M', till: NOW_RANGE },
        [DATE_RANGE_TYPES.QUARTER]: { from: '-3M', till: NOW_RANGE },
        [DATE_RANGE_TYPES.YEAR]: { from: '-1y', till: NOW_RANGE },
    };
    return range[type];
};

const RELATIVE_DATE_FORMATS: Record<string, OpUnitType> = {
    h: 'hour',
    d: 'day',
    M: 'month',
    y: 'year',
};
const RELATIVE_DATE_REGEXP = /^-?(\d*)+(h|d|M|y)/;

export const parseRelativeDate = (date: string): Dayjs | null => {
    if (RELATIVE_DATE_REGEXP.test(date)) {
        const result = date.match(RELATIVE_DATE_REGEXP);

        if (!result) {
            return null;
        }

        const dateValue = parseInt(result[1], 10);
        const dateFormat = RELATIVE_DATE_FORMATS[result[2]];

        if (date.charAt(0) === '-') {
            const subtractedDate = dayjs().subtract(dateValue, dateFormat);
            return subtractedDate.isValid() ? subtractedDate : null;
        }

        const addedDate = dayjs().add(dateValue, dateFormat);
        return addedDate.isValid() ? addedDate : null;
    }

    if (date === NOW_RANGE) {
        return dayjs();
    }

    return null;
};

const VALUE_WITH_PX_REGEXP = /^(\d+)px$/;

interface IHeaderHeights {
    headerHeightMobile: number;
    headerHeight: number;
}

export const getHeaderHeight = (): IHeaderHeights => {
    const computedStyles = getComputedStyle(document.documentElement);
    const headerHeightMobile = computedStyles.getPropertyValue('--header-height-s').trim();
    const headerHeight = computedStyles.getPropertyValue('--header-height').trim();

    if (!headerHeightMobile || !headerHeight) {
        throw Error('Empty value for --header-height(-s) variable, check getHeaderHeight method');
    }

    const headerHeightMobileMatch = headerHeightMobile.match(VALUE_WITH_PX_REGEXP);
    const headerHeightMatch = headerHeight.match(VALUE_WITH_PX_REGEXP);

    if (!headerHeightMobileMatch || !headerHeightMatch) {
        throw Error('We need to use px for --header-height(-s) variable, check getHeaderHeight method');
    }

    return {
        headerHeightMobile: Number(headerHeightMobileMatch[1]),
        headerHeight: Number(headerHeightMatch[1]),
    };
};

export const getPriceValue = (value: number, intl: Translator) => {
    const HUNDRED_THOUSAND = 100000;
    const MILLION = 1000000;
    const TEN_MILLION = 10000000;

    if (value >= HUNDRED_THOUSAND && value < MILLION) {
        const trimmedValue = Number(String(value).slice(0, 3));

        return intl.getMessage('price_with_thousands', { value: trimmedValue });
    }

    if (value !== 0 && value % MILLION === 0 && value < TEN_MILLION) {
        const trimmedValue = Number(String(value).slice(0, 1));

        return intl.getMessage('price_with_million', { value: trimmedValue });
    }

    if (value > MILLION && value % MILLION !== 0 && value < TEN_MILLION) {
        const wholeValue = Number(String(value).slice(0, 1));
        const floatValue = Number(String(value).slice(1, 2));

        return intl.getMessage('price_with_million', { value: `${wholeValue}.${floatValue}` });
    }

    return intl.getMessage('price_simple', { value: Math.round(value) });
};
export interface ICssColors {
    blue: string;
    red: string;
    grass: string;
    purple: string;
    wave: string;
    bordersWhite: string;
    white: string;
    cloud: string;
}

export const getCssColors = (): ICssColors => {
    const computedStyles = getComputedStyle(document.documentElement);

    const colors: ICssColors = {
        blue: '',
        red: '',
        grass: '',
        purple: '',
        wave: '',
        bordersWhite: '',
        white: '',
        cloud: '',
    };

    const colorVariables: { name: keyof ICssColors; variable: string }[] = [
        { name: 'blue', variable: '--blue' },
        { name: 'red', variable: '--red' },
        { name: 'grass', variable: '--grass' },
        { name: 'purple', variable: '--purple' },
        { name: 'wave', variable: '--wave' },
        { name: 'bordersWhite', variable: '--borders-white' },
        { name: 'white', variable: '--white' },
        { name: 'cloud', variable: '--cloud' },
    ];

    colorVariables.forEach(({ name, variable }) => {
        const value = computedStyles.getPropertyValue(variable);
        if (!computedStyles.getPropertyValue(variable)) {
            throw Error(`Missing "${variable}" color, check getCssColors method`);
        }
        colors[name] = value.trim();
    });

    return colors;
};

export const onChangeCheckNumberOrFloat = (val: string) => {
    const re = /^[1-9]\d{0,7}([,.]\d{0,2})?$/gm;

    return re.test(val);
};

export const onSubmitCheckNumberOrFloat = (val: string) => {
    const re = /^[1-9]\d{0,7}([,.]\d{1,2})?$/gm;

    return re.test(val);
};

export const parseFloatWithComma = (val: string | number) => {
    if (typeof val === 'number') {
        return val;
    }
    return Number(val.indexOf(',') !== -1
        ? val.replace(',', '.')
        : val);
};
