import AccountAutoTopUpSettings, { IAccountAutoTopUpSettings } from './AccountAutoTopUpSettings';
import AccountLimits, { IAccountLimits } from './AccountLimits';
import { ClientType } from './ClientType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountDetails {
    account_limits: IAccountLimits;
    auto_top_up_settings: IAccountAutoTopUpSettings;
    balance: number;
    balance_left_days: number;
    charge_offer_amounts: number[];
    client_type?: ClientType;
    credit_limit: number;
    discount: number;
    monthly_cost: number;
    paid: boolean;
    show_billing_documents: boolean;
}

export default class AccountDetails {
    readonly _account_limits: AccountLimits;

    get accountLimits(): AccountLimits {
        return this._account_limits;
    }

    readonly _auto_top_up_settings: AccountAutoTopUpSettings;

    get autoTopUpSettings(): AccountAutoTopUpSettings {
        return this._auto_top_up_settings;
    }

    readonly _balance: number;

    /**
     * Description: User account balance
     * Example: 2430
     */
    get balance(): number {
        return this._balance;
    }

    static balanceValidate(balance: number): boolean {
        return typeof balance === 'number';
    }

    readonly _balance_left_days: number;

    /**
     * Description: Forecast for user's monthly expenses
     * Example: 59
     */
    get balanceLeftDays(): number {
        return this._balance_left_days;
    }

    static balanceLeftDaysValidate(balanceLeftDays: number): boolean {
        return typeof balanceLeftDays === 'number';
    }

    readonly _charge_offer_amounts: number[];

    /** */
    get chargeOfferAmounts(): number[] {
        return this._charge_offer_amounts;
    }

    static chargeOfferAmountsValidate(chargeOfferAmounts: number[]): boolean {
        return chargeOfferAmounts.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    readonly _client_type: ClientType | undefined;

    get clientType(): ClientType | undefined {
        return this._client_type;
    }

    readonly _credit_limit: number;

    /**
     * Description: User account credit limit
     * Example: 100
     */
    get creditLimit(): number {
        return this._credit_limit;
    }

    static creditLimitValidate(creditLimit: number): boolean {
        return typeof creditLimit === 'number';
    }

    readonly _discount: number;

    /**
     * Description: User's discount, percent
     * Example: 15
     */
    get discount(): number {
        return this._discount;
    }

    static discountValidate(discount: number): boolean {
        return typeof discount === 'number';
    }

    readonly _monthly_cost: number;

    /**
     * Description: Forecast for user's monthly expenses
     * Example: 1275
     */
    get monthlyCost(): number {
        return this._monthly_cost;
    }

    static monthlyCostValidate(monthlyCost: number): boolean {
        return typeof monthlyCost === 'number';
    }

    readonly _paid: boolean;

    /**
     * Description: Is paid client
     * Example: true
     */
    get paid(): boolean {
        return this._paid;
    }

    static paidValidate(paid: boolean): boolean {
        return typeof paid === 'boolean';
    }

    readonly _show_billing_documents: boolean;

    /**
     * Description: If client can see associated billing documents
     * Example: true
     */
    get showBillingDocuments(): boolean {
        return this._show_billing_documents;
    }

    static showBillingDocumentsValidate(showBillingDocuments: boolean): boolean {
        return typeof showBillingDocuments === 'boolean';
    }

    constructor(props: IAccountDetails) {
        this._account_limits = new AccountLimits(props.account_limits);
        this._auto_top_up_settings = new AccountAutoTopUpSettings(props.auto_top_up_settings);
        this._balance = props.balance;
        this._balance_left_days = props.balance_left_days;
        this._charge_offer_amounts = props.charge_offer_amounts;
        if (props.client_type) {
            this._client_type = props.client_type;
        }
        this._credit_limit = props.credit_limit;
        this._discount = props.discount;
        this._monthly_cost = props.monthly_cost;
        this._paid = props.paid;
        this._show_billing_documents = props.show_billing_documents;
    }

    serialize(): IAccountDetails {
        const data: IAccountDetails = {
            account_limits: this._account_limits.serialize(),
            auto_top_up_settings: this._auto_top_up_settings.serialize(),
            balance: this._balance,
            balance_left_days: this._balance_left_days,
            charge_offer_amounts: this._charge_offer_amounts,
            credit_limit: this._credit_limit,
            discount: this._discount,
            monthly_cost: this._monthly_cost,
            paid: this._paid,
            show_billing_documents: this._show_billing_documents,
        };
        if (typeof this._client_type !== 'undefined') {
            data.client_type = this._client_type;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            balance: typeof this._balance === 'number',
            credit_limit: typeof this._credit_limit === 'number',
            discount: typeof this._discount === 'number',
            monthly_cost: typeof this._monthly_cost === 'number',
            balance_left_days: typeof this._balance_left_days === 'number',
            account_limits: this._account_limits.validate().length === 0,
            auto_top_up_settings: this._auto_top_up_settings.validate().length === 0,
            charge_offer_amounts: this._charge_offer_amounts.reduce((result, p) => result && typeof p === 'number', true),
            show_billing_documents: typeof this._show_billing_documents === 'boolean',
            paid: typeof this._paid === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountDetails): AccountDetails {
        return new AccountDetails(props);
    }

    readonly keys: { [key: string]: string } = {
        accountLimits: 'account_limits',
        autoTopUpSettings: 'auto_top_up_settings',
        balance: 'balance',
        balanceLeftDays: 'balance_left_days',
        chargeOfferAmounts: 'charge_offer_amounts',
        clientType: 'client_type',
        creditLimit: 'credit_limit',
        discount: 'discount',
        monthlyCost: 'monthly_cost',
        paid: 'paid',
        showBillingDocuments: 'show_billing_documents',
        }
;

    mergeDeepWith(props: Partial<AccountDetails>): AccountDetails {
        const updateData: Partial<IAccountDetails> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountDetails) => {
            const updateKey = this.keys[key] as keyof IAccountDetails;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountDetails, keyof IAccountDetails>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountDetails({ ...this.serialize(), ...updateData });
    }
}
