import React, { FC } from 'react';
import cn from 'classnames';

import { RoutePath } from 'Lib/helpers/routes';
import { useIntl, Icon, Link, Button, DetailedComparativeValues } from 'Common';
import ResellerDetails from 'Entities/ResellerDetails';
import theme from 'Lib/theme';

import s from './Funds.module.pcss';

interface Funds {
    details: ResellerDetails | null;
}
const Funds: FC<Funds> = ({ details }) => {
    const intl = useIntl();
    if (!details) {
        return null;
    }

    const { comparativeIncome } = details;

    const {
        incomeChangePercent,
        recentIncome,
        recentPeriod,
        previousIncome,
        previousPeriod,
    } = comparativeIncome;

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.rounded,
                theme.card.wrapper,
                s.funds,
            )}
        >
            <div className={theme.score.wrapper}>
                <div className={theme.score.item}>
                    <Icon
                        icon="coins"
                        className={cn(
                            theme.score.icon,
                            theme.color.blue,
                        )}
                    />
                    <div className={theme.score.info}>
                        <div className={theme.score.label}>
                            {intl.getMessage('reseller_balance')}
                        </div>
                        <div className={theme.score.value}>
                            {intl.getMessage('price_simple', { value: Math.round(details.balance) })}
                        </div>
                    </div>
                </div>
                <div className={theme.score.item}>
                    <Icon
                        icon="forecast"
                        className={cn(
                            theme.score.icon,
                            theme.color.grass,
                        )}
                    />
                    <div className={theme.score.info}>
                        <div className={theme.score.label}>
                            {intl.getMessage('partners_income_forecast_score')}
                        </div>
                        <div className={theme.score.value}>
                            {intl.getMessage('price_simple', { value: details.monthlyIncomeForecast })}
                        </div>
                    </div>
                </div>
                <div className={theme.score.item}>
                    <Icon
                        icon="profile"
                        className={cn(
                            theme.score.icon,
                            theme.color.wave,
                        )}
                    />
                    <div className={theme.score.info}>
                        <div className={theme.score.label}>
                            {intl.getMessage('dashboard_reseller_income')}
                        </div>
                        <div className={theme.score.value}>
                            {intl.getMessage('price_simple', { value: comparativeIncome.recentIncome })}

                            <span className={s.diff}>
                                <DetailedComparativeValues
                                    diff={incomeChangePercent}
                                    recentValue={recentIncome}
                                    recentPeriod={recentPeriod}
                                    previousValue={previousIncome}
                                    previousPeriod={previousPeriod}
                                    increasingLabel={intl.getMessage('dashboard_reseller_income_increased')}
                                    decreasingLabel={intl.getMessage('dashboard_reseller_income_decreased')}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <Link
                    to={RoutePath.ClientsTransferPayment}
                    className={theme.score.link}
                >
                    <Button type="link">
                        {intl.getMessage('partners_transfer_to_main_account')}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default Funds;
