import { IOperationInfo } from 'Entities/OperationInfo';
import OperationProgress from 'Entities/OperationProgress';
import Server from 'Entities/Server';
import { actionCreator } from '.';

export enum InstallingServersActions {
    GetProgress = 'GetProgress',
    SaveProgress = 'SaveProgress',
    DeleteProgress = 'DeleteProgress',
    SaveInstallingServer = 'SaveInstallingServer',
    DeleteInstallingServer = 'DeleteInstallingServer',
}

interface GetProgress extends IOperationInfo {
    tenantId: number;
}
const getProgress = actionCreator<GetProgress>(InstallingServersActions.GetProgress);
interface SaveProgress {
    progress: OperationProgress;
}
const saveProgress = actionCreator<SaveProgress>(InstallingServersActions.SaveProgress);
interface DeleteProgress {
    progressId: number;
}
const deleteProgress = actionCreator<DeleteProgress>(InstallingServersActions.DeleteProgress);

const saveInstallingServer = actionCreator<Server>(InstallingServersActions.SaveInstallingServer);
const deleteInstallingServer = actionCreator<number>(
    InstallingServersActions.DeleteInstallingServer,
);

export {
    getProgress,
    saveProgress,
    deleteProgress,
    saveInstallingServer,
    deleteInstallingServer,
};
