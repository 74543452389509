import React, { FC, useState } from 'react';
import { Select } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { createDnsRecords } from 'Actions/dns';
import { useIntl } from 'Common';
import DnsSettings from 'Entities/DnsSettings';
import DnsRecordCreateList from 'Entities/DnsRecordCreateList';
import DnsZone from 'Entities/DnsZone';
import theme from 'Lib/theme';

import { Manual, MailService } from './index';
import s from './Form.module.pcss';

interface FormProps {
    setFormVisible: (v: boolean) => void;
    settings: DnsSettings;
    zone: DnsZone;
}

const FORM_TYPES = {
    MANUAL: 'manual',
    MAIL: 'mail',
};

const { Option } = Select;

const Form: FC<FormProps> = ({ setFormVisible, settings, zone }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [formType, setFormType] = useState(FORM_TYPES.MANUAL);

    const onAdd = (list: DnsRecordCreateList) => {
        const { tenantId, id } = zone;
        if (list.records.length > 0) {
            dispatch(createDnsRecords([tenantId, id, list.serialize()]));
        }
    };

    return (
        <div className={s.form}>
            <div className={s.title}>
                {intl.getMessage('records_add')}
            </div>

            <div className={cn(s.controls, s.controls_desktop)}>
                <button
                    type="button"
                    className={cn(
                        theme.radio.radio,
                        { [theme.radio.radio_active]: formType === FORM_TYPES.MAIL },
                    )}
                    onClick={() => setFormType(FORM_TYPES.MAIL)}
                >
                    {intl.getMessage('records_mail_service_template')}
                </button>
                <button
                    type="button"
                    className={cn(
                        theme.radio.radio,
                        { [theme.radio.radio_active]: formType === FORM_TYPES.MANUAL },
                    )}
                    onClick={() => setFormType(FORM_TYPES.MANUAL)}
                >
                    {intl.getMessage('records_manual_input')}
                </button>
            </div>

            <div className={cn(s.controls, s.controls_mobile)}>
                <div className={s.label}>
                    {intl.getMessage('records_input_type')}
                </div>
                <Select
                    id="formType"
                    size="large"
                    className="select select--setting select--block"
                    placeholder={intl.getMessage('records_input_type_placeholder')}
                    value={formType}
                    onChange={(value) => setFormType(value)}
                    suffixIcon={(
                        <svg className="icon select__arrow">
                            <use xlinkHref="#down" />
                        </svg>
                    )}
                    dropdownClassName="select-dropdown"
                >
                    <Option value={FORM_TYPES.MAIL}>
                        {intl.getMessage('records_mail_service_template')}
                    </Option>
                    <Option value={FORM_TYPES.MANUAL}>
                        {intl.getMessage('records_manual_input')}
                    </Option>
                </Select>
            </div>

            {formType === FORM_TYPES.MAIL ? (
                <MailService
                    settings={settings}
                    closeForm={() => setFormVisible(false)}
                    onAdd={onAdd}
                />
            ) : (
                <Manual
                    closeForm={() => setFormVisible(false)}
                    zone={zone}
                />
            )}
        </div>
    );
};

export default Form;
