import content from './Content.module.pcss';
import divider from './Divider.module.pcss';
import popover from './Popover.module.pcss';
import button from './Button.module.pcss';
import Badge from './Badge.module.pcss';
import radio from './Radio.module.pcss';
import card from './Card.module.pcss';
import link from './Link.module.pcss';
import color from './Color.module.pcss';
import modal from './Modal.module.pcss';
import header from './Header.module.pcss';
import loading from './Loading.module.pcss';
import edit from './Edit.module.pcss';
import mainHeader from './MainHeader.module.pcss';
import text from './Text.module.pcss';
import blocked from './Blocked.module.pcss';
import search from './Search.module.pcss';
import form from './Form.module.pcss';
import keysForm from './KeysForm.module.pcss';
import common from './Common.module.pcss';
import dotList from './DotList.module.pcss';
import skeleton from './Skeleton.module.pcss';
import actions from './Actions.module.pcss';
import score from './Score.module.pcss';
import notification from './Notification.module.pcss';
import dropdown from './Dropdown.module.pcss';

const theme = {
    content,
    divider,
    popover,
    button,
    Badge,
    card,
    radio,
    link,
    color,
    modal,
    header,
    loading,
    edit,
    mainHeader,
    text,
    blocked,
    search,
    form,
    keysForm,
    common,
    dotList,
    skeleton,
    actions,
    score,
    notification,
    dropdown,
};

export default theme;
