// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITenantCreate {
    description: string;
}

export default class TenantCreate {
    readonly _description: string;

    /**
     * Description: Project name
     * Example: Development
     */
    get description(): string {
        return this._description;
    }

    static get descriptionMinLength() {
        return 0;
    }

    static get descriptionMaxLength() {
        return 70;
    }

    static descriptionValidate(description: string): boolean {
        return (description.length > 0) && (description.length <= 70);
    }

    constructor(props: ITenantCreate) {
        this._description = props.description.trim();
    }

    serialize(): ITenantCreate {
        const data: ITenantCreate = {
            description: this._description,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            description: (this._description.length > 0) && (this._description.length <= 70),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITenantCreate): TenantCreate {
        return new TenantCreate(props);
    }

    readonly keys: { [key: string]: string } = {
        description: 'description',
        }
;

    mergeDeepWith(props: Partial<TenantCreate>): TenantCreate {
        const updateData: Partial<ITenantCreate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TenantCreate) => {
            const updateKey = this.keys[key] as keyof ITenantCreate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITenantCreate, keyof ITenantCreate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TenantCreate({ ...this.serialize(), ...updateData });
    }
}
