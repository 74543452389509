import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import { useIntl, DashboardHeader, Link, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { TICKETS_STATE } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

const Header: FC = () => {
    const intl = useIntl();
    const { params: { open } } = useRouteMatch<{ open: TICKETS_STATE }>();

    return (
        <DashboardHeader
            navigation={(
                <>
                    <Link
                        to={RoutePath.Tickets}
                        props={{ open: TICKETS_STATE.OPEN }}
                        className={cn(
                            theme.header.link,
                            { [theme.header.active]: open === TICKETS_STATE.OPEN },
                        )}
                    >
                        {intl.getMessage('support_new_messages')}
                    </Link>
                    <Link
                        to={RoutePath.Tickets}
                        props={{ open: TICKETS_STATE.CLOSED }}
                        className={cn(
                            theme.header.link,
                            { [theme.header.active]: open === TICKETS_STATE.CLOSED },
                        )}
                    >
                        {intl.getMessage('notifications_read')}
                    </Link>
                </>
            )}
            actions={(
                <Link to={RoutePath.CreateTicket}>
                    <Button
                        type="primary"
                        size="medium"
                    >
                        {intl.getMessage('create_ticket')}
                    </Button>
                </Link>
            )}
        />
    );
};

export default Header;
