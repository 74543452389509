import React, { FC } from 'react';
import cn from 'classnames';

import { Link } from 'Common';
import { QueryParamsType, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import moneyImage from 'Lib/assets/images/dashboard/money.png';
import money2xImage from 'Lib/assets/images/dashboard/money@2x.png';
import phoneImage from 'Lib/assets/images/dashboard/phone.png';
import phone2xImage from 'Lib/assets/images/dashboard/phone@2x.png';
import serverImage from 'Lib/assets/images/dashboard/server.png';
import server2xImage from 'Lib/assets/images/dashboard/server@2x.png';

import s from './Important.module.pcss';

export interface ImportantProps {
    color: 'red' | 'blue';
    desc: string;
    link: RoutePath;
    image?: 'phone' | 'server' | 'money';
    queryParams?: QueryParamsType;
}

const Important: FC<ImportantProps> = ({ color, desc, link, image, queryParams }) => {
    const getImage = () => {
        switch (image) {
            case 'money': {
                return (
                    <img
                        src={moneyImage}
                        srcSet={`${money2xImage} 2x`}
                        className={cn(s.image, s.image_money)}
                        alt="money"
                    />
                );
            }
            case 'phone': {
                return (
                    <img
                        src={phoneImage}
                        srcSet={`${phone2xImage} 2x`}
                        className={cn(s.image, s.image_phone)}
                        alt="phone"
                    />
                );
            }
            default: {
                return (
                    <img
                        src={serverImage}
                        srcSet={`${server2xImage} 2x`}
                        className={cn(s.image, s.image_server)}
                        alt="server"
                    />
                );
            }
        }
    };

    return (
        <Link
            to={link}
            className={cn(
                theme.card.card,
                theme.card.alert,
                theme.card.hover,
                theme.card[color],
            )}
            queryParams={queryParams}
        >
            <div className={s.desc}>
                {desc}
            </div>
            {getImage()}
        </Link>
    );
};

export default Important;
