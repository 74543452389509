// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPhoneConfirmationInfo {
    confirmation_code: string;
}

export default class PhoneConfirmationInfo {
    readonly _confirmation_code: string;

    /**
     * Description: Phone number confirmation code
     * Example: 120998
     */
    get confirmationCode(): string {
        return this._confirmation_code;
    }

    static confirmationCodeValidate(confirmationCode: string): boolean {
        return typeof confirmationCode === 'string' && !!confirmationCode.trim();
    }

    constructor(props: IPhoneConfirmationInfo) {
        this._confirmation_code = props.confirmation_code.trim();
    }

    serialize(): IPhoneConfirmationInfo {
        const data: IPhoneConfirmationInfo = {
            confirmation_code: this._confirmation_code,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            confirmation_code: typeof this._confirmation_code === 'string' && !!this._confirmation_code.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPhoneConfirmationInfo): PhoneConfirmationInfo {
        return new PhoneConfirmationInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        confirmationCode: 'confirmation_code',
        }
;

    mergeDeepWith(props: Partial<PhoneConfirmationInfo>): PhoneConfirmationInfo {
        const updateData: Partial<IPhoneConfirmationInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PhoneConfirmationInfo) => {
            const updateKey = this.keys[key] as keyof IPhoneConfirmationInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPhoneConfirmationInfo, keyof IPhoneConfirmationInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PhoneConfirmationInfo({ ...this.serialize(), ...updateData });
    }
}
