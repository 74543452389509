import React, { FC, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { useIntl, DetailedComparativeValues } from 'Common';
import PartnerDetails from 'Entities/PartnerDetails';
import { useClickOutside } from 'Lib/hooks/hooks';
import ComparativeStats from 'Entities/ComparativeStats';
import theme from 'Lib/theme';

import s from './Stats.module.pcss';

interface StatsProps {
    details: PartnerDetails;
    uniqueHits: ComparativeStats;
    registrations: ComparativeStats;
}

const Stats: FC<StatsProps> = ({ details, uniqueHits, registrations }) => {
    const intl = useIntl();
    const listRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLSpanElement>(null);
    const [showList, setShowList] = useState(false);
    const {
        activeClients,
        inactiveClients,
        testClients,
        referralUsers,
    } = details;

    useClickOutside([listRef, triggerRef], () => setShowList(false));

    const usersList = (
        <div className={s.dropdown} ref={listRef}>
            <div className={cn(s.users, theme.common.customScrollbar)}>
                {referralUsers.map((user) => (
                    <span key={user} className={s.user}>
                        <span className={theme.color.grayHeaders}>#</span>{user}
                    </span>
                ))}
            </div>
        </div>
    );

    return (
        <div className={s.cardsWrapper}>
            <div
                className={cn(
                    theme.card.card,
                    theme.card.rounded,
                    s.card,
                )}
            >
                <div className={cn(s.columns, s.columns_twoItems)}>
                    <div className={s.column}>
                        <div className={s.label}>
                            {intl.getMessage('partners_stats_unique_clients')}
                        </div>
                        <div className={s.valueWrapper}>
                            <div className={s.value}>
                                {uniqueHits.recentValue}
                            </div>
                            {uniqueHits.valueChangePercent && (
                                <div className={s.diff}>
                                    <DetailedComparativeValues
                                        diff={uniqueHits.valueChangePercent}
                                        recentValue={uniqueHits.recentValue}
                                        recentPeriod={uniqueHits.recentPeriod}
                                        previousValue={uniqueHits.previousValue}
                                        previousPeriod={uniqueHits.previousPeriod}
                                        increasingLabel={intl.getMessage('partner_unique_clients_increased')}
                                        decreasingLabel={intl.getMessage('partner_unique_clients_decreased')}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={s.column}>
                        <div className={s.label}>
                            {intl.getMessage('partners_stats_registrations')}
                        </div>
                        <div className={s.valueWrapper}>
                            {registrations.recentValue > 0 && (
                                <div className={s.value}>
                                    <Dropdown
                                        overlay={usersList}
                                        placement="bottomLeft"
                                        trigger={['click']}
                                        onVisibleChange={(v: boolean) => setShowList(v)}
                                        visible={showList}
                                    >
                                        <span
                                            className={cn(
                                                theme.link.link,
                                                theme.link.blue,
                                            )}
                                            ref={triggerRef}
                                        >
                                            {registrations.recentValue}&nbsp;
                                        </span>
                                    </Dropdown>
                                </div>
                            )}
                            {registrations.recentValue === 0 && (
                                <div className={s.value}>
                                    {registrations.recentValue}
                                </div>
                            )}
                            {registrations.valueChangePercent && (
                                <div className={s.diff}>
                                    <DetailedComparativeValues
                                        diff={registrations.valueChangePercent}
                                        recentValue={registrations.recentValue}
                                        recentPeriod={registrations.recentPeriod}
                                        previousValue={registrations.previousValue}
                                        previousPeriod={registrations.previousPeriod}
                                        increasingLabel={intl.getMessage('partner_registrations_increased')}
                                        decreasingLabel={intl.getMessage('partner_registrations_decreased')}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    theme.card.card,
                    theme.card.rounded,
                    s.card,
                )}
            >
                <div className={s.columns}>
                    <div className={s.column}>
                        <div className={s.label}>
                            {intl.getMessage('partners_stats_test')}
                        </div>
                        <div className={s.value}>
                            {testClients}
                        </div>
                    </div>
                    <div className={s.column}>
                        <div className={s.label}>
                            {intl.getMessage('partners_stats_active')}
                        </div>
                        <div className={s.value}>
                            {activeClients}
                        </div>
                    </div>
                    <div className={s.column}>
                        <div className={s.label}>
                            {intl.getMessage('partners_stats_not_active')}
                        </div>
                        <div className={s.value}>
                            {inactiveClients}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Stats;
