import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, ListLayout, Button, ModalCloseAccess } from 'Common';
import TenantUser from 'Entities/TenantUser';
import theme from 'Lib/theme';

import s from './UsersList.module.pcss';

interface ListProps {
    users: TenantUser[];
    owner: number;
    currentUser: string;
    showActionButtons: boolean;
}

const List: FC<ListProps> = ({ users, owner, currentUser, showActionButtons }) => {
    const intl = useIntl();
    const [showCloseAccess, setShowCloseAccess] = useState<TenantUser | null>(null);
    const sortedUsers = users.sort((a) => {
        const isOwner = a.userId === owner;
        const isCurrent = a.email === currentUser;
        return Number(isCurrent) - Number(isOwner);
    });
    return (
        <ListLayout>
            <div
                className={cn(
                    theme.card.labels,
                    theme.card.labels_projectAccess,
                    theme.card.labels_withActions,
                )}
            >
                <div className={theme.card.label}>
                    {intl.getMessage('user')}
                </div>
                <div className={theme.card.label}>
                    {intl.getMessage('project_access_params')}
                </div>
            </div>
            {sortedUsers.map((item) => {
                const statusMessageId = item.userId === owner
                    ? intl.getMessage('project_access_owner') : intl.getMessage('project_access_user');

                return (
                    <div
                        className={cn(
                            theme.card.card,
                            theme.card.list,
                            theme.card.with_actions,
                        )}
                        key={item.userId}
                    >
                        <div className={cn(theme.card.grid, theme.card.grid_projectAccess)}>
                            <div className={theme.common.textOverflow}>
                                {item.email}
                                {item.email === currentUser && (
                                    <span className={cn(s.you, theme.color.grayHeaders)}>
                                        {intl.getMessage('you')}
                                    </span>
                                )}
                            </div>
                            <div className={theme.card.actionsWrap}>
                                <div className={theme.common.textOverflow}>
                                    {statusMessageId}
                                </div>

                                {showActionButtons && item.userId !== owner && (
                                    <div className={theme.card.actions}>
                                        <Button
                                            type="icon"
                                            icon="delete"
                                            className={cn(
                                                theme.card.action,
                                                theme.card.action_hover,
                                                theme.card.action_withMobile,
                                            )}
                                            onClick={() => setShowCloseAccess(item)}
                                            title={intl.getMessage('title_delete_user')}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
            {showCloseAccess && (
                <ModalCloseAccess
                    visible={!!showCloseAccess}
                    user={showCloseAccess}
                    onClose={() => setShowCloseAccess(null)}
                />
            )}
        </ListLayout>
    );
};

export default List;
