import React, { FC } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';

import { updatePassword } from 'Actions/server';
import { useIntl, Checkbox, CodeInput, Input, Button, notifyError } from 'Common';
import { SecurityActionType } from 'Entities/SecurityActionType';
import Server from 'Entities/Server';
import Error from 'Entities/Error';
import ServerPasswordUpdate, { IServerPasswordUpdate } from 'Entities/ServerPasswordUpdate';
import FormHelper from 'Lib/helpers/newServer';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { externalLinkBuilder, GuestRoutePath } from 'Lib/helpers/routes';
import { useEscape, useSecurityActions } from 'Hooks';
import { passwordValidate } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import s from './ChangePassword.module.pcss';

interface ChangePassFormProps {
    server: Server;
    close: () => void;
}

const ChangePasswordForm: FC<ChangePassFormProps> = ({ server, close }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityActions(SecurityActionType.SERVER_PASSWORD);

    useEscape(close);

    const link = (
        <a
            href={externalLinkBuilder(intl.currentLocale, GuestRoutePath.KbArticle, { slug: 'manual-change-admin-password' })}
        >
            <Button
                type="link"
                className={cn(theme.link.link, theme.link.white)}
            >
                {intl.getMessage('how_to')}
            </Button>
        </a>
    );

    const handleApply = (
        v: IServerPasswordUpdate,
        helpers: FormikHelpers<IServerPasswordUpdate>,
    ) => {
        const valid = passwordValidate(v.password, ServerPasswordUpdate.passwordMinLength);
        if (!valid) {
            helpers.setFieldError('password', EMPTY_FIELD_ERROR);
            helpers.setSubmitting(false);
            return;
        }
        const { tenantId, id: serverId } = server;
        const reqEnt = new ServerPasswordUpdate(v);
        const result = () => {
            close();
        };

        const onError = (error: Error) => {
            if (error.errorCode !== 'UNKNOWN') {
                const { fields } = error;
                const { security_code: scError, password: pError } = fields;
                if (pError) {
                    helpers.setFieldError('password', EMPTY_FIELD_ERROR);
                }
                if (scError) {
                    helpers.setFieldError('security_code', EMPTY_FIELD_ERROR);
                }
            } else {
                notifyError(intl.getMessage('error_change_server_password'), undefined, { btn: link, duration: 0 });
            }
            helpers.setSubmitting(false);
        };

        if (!shouldConfirmAction) {
            const req = reqEnt.serialize();
            delete req.security_code;
            dispatch(updatePassword([tenantId, serverId, req], {
                result,
                error: onError,
            }));
            return;
        }
        if (!codeSent) {
            sendConfirmationCode({ tenantId, serverId });
            helpers.setSubmitting(false);
        } else {
            if (!reqEnt.securityCode) {
                helpers.setFieldError('security_code', EMPTY_FIELD_ERROR);
                return;
            }
            dispatch(updatePassword([tenantId, serverId, reqEnt.serialize()], {
                result,
                error: onError,
            }));
        }
    };

    const validatePasswordField = (e: string) => (
        ServerPasswordUpdate.passwordValidate(e)
        && passwordValidate(e, ServerPasswordUpdate.passwordMinLength)
    );

    const { tenantId, id: serverId } = server;

    return (
        <Formik
            initialValues={{ password: '', send_password: false, security_code: '' }}
            onSubmit={handleApply}
        >
            {({
                values,
                errors,
                setFieldValue,
                setFieldError,
                handleSubmit,
                handleChange,
                isSubmitting,
            }) => {
                const onPasswordGen = () => {
                    setFieldValue('password', FormHelper.generatePassword());
                };
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={s.passwordWrap}>
                            <Input
                                validateInstant
                                autoFocus
                                autoComplete="new-password"
                                error={!!errors.password}
                                errorMessage={errors.password}
                                name="password"
                                wrapperClassName={s.password}
                                onChange={(e) => setFieldValue('password', e)}
                                placeholder={intl.getMessage('password')}
                                size="large"
                                type="password"
                                validate={validatePasswordField}
                                value={values.password}
                            />
                            <Button
                                type="icon"
                                icon="generate"
                                onClick={onPasswordGen}
                                className={cn(s.generate, theme.button.generate)}
                                title={intl.getMessage('title_generate_password')}
                            />
                        </div>
                        <Checkbox
                            id="send_password"
                            name="send_password"
                            checked={values.send_password}
                            className={cn({ [s.info]: codeSent })}
                            handleChange={handleChange}
                        >
                            {intl.getMessage('server_password_send')}
                        </Checkbox>
                        {(codeSent && deliveryMessage) && (
                            <>
                                <div className={s.info}>
                                    {deliveryMessage}
                                </div>
                                <CodeInput
                                    value={values.security_code}
                                    setValue={(e) => setFieldValue('security_code', e)}
                                    codeError={!!errors.security_code}
                                    setCodeError={(e) => setFieldError('security_code', e ? EMPTY_FIELD_ERROR : '')}
                                    onSendAgain={() => sendConfirmationCode({ tenantId, serverId })}
                                />
                            </>
                        )}
                        <div className={s.buttons}>
                            <Button
                                inGroup
                                type="primary"
                                size="medium"
                                htmlType="submit"
                                disabled={isSubmitting}
                            >
                                {intl.getMessage('apply')}
                            </Button>
                            <Button
                                type="link"
                                size="medium"
                                onClick={() => close()}
                            >
                                {intl.getMessage('cancel')}
                            </Button>
                        </div>
                    </form>
                );
            }}
        </Formik>
    );
};

export default ChangePasswordForm;
