// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum VmOperationStep {
    START = 'START',
    DONE = 'DONE',
    ERROR = 'ERROR',
    RESOURCE_ALLOCATE = 'RESOURCE_ALLOCATE',
    VOLUME_COPY = 'VOLUME_COPY',
    CONFIGURING = 'CONFIGURING',
    VM_CREATE = 'VM_CREATE',
    VM_START = 'VM_START',
    VM_SHUTDOWN = 'VM_SHUTDOWN',
    VM_REBOOT = 'VM_REBOOT',
    SNAPSHOT_REVERT = 'SNAPSHOT_REVERT',
    SNAPSHOT_UPDATE_IPS = 'SNAPSHOT_UPDATE_IPS',
    REMOVE_SNAPSHOT = 'REMOVE_SNAPSHOT',
    CREATE_SNAPSHOT = 'CREATE_SNAPSHOT',
    CHANGE_CPU_CORES_CHANGE = 'CHANGE_CPU_CORES_CHANGE',
    PREPARE_BACKUP = 'PREPARE_BACKUP',
    SERVICE_SHUTDOWN = 'SERVICE_SHUTDOWN',
    CHANGE_MEMORY = 'CHANGE_MEMORY',
    CHANGE_DISK = 'CHANGE_DISK'
}
