import { createStore, applyMiddleware, combineReducers, Reducer, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import {
    userSaga,
    serverSaga,
    tenantSaga,
    tenantUserSaga,
    operationProgressSaga,
    dnsSaga,
    reverseDnsSaga,
    tariffSaga,
    distributionSaga,
    installingServersSaga,
    snapshotsSaga,
    serverBackupSaga,
    accountSaga,
    publicKeysSaga,
    serverStateEventsSaga,
    securitySaga,
    profileSaga,
    supportSaga,
    notificationsSaga,
    partnerSaga,
    resellerSaga,
    mainAccountSaga,
    newServerSaga,
    batchOperationProgressSaga,
} from './sagas';

import { Action } from './actions';
import UserReducer from './reducers/user';
import ServerReducer from './reducers/server';
import TenantReducer from './reducers/tenant';
import TenantUserReducer from './reducers/tenantUsers';
import OperationProgressReducer from './reducers/operationProgress';
import LocaleReducer from './reducers/locale';
import DnsReducer from './reducers/dns';
import ReverseDnsReduces from './reducers/reverseDns';
import TariffReducer from './reducers/tariff';
import DistributionReducer from './reducers/distribution';
import InstallingServersReducer from './reducers/installingServers';
import SnapshotsReducer from './reducers/snapshots';
import ServerBackupReducer from './reducers/serverBackup';
import AccountReducer from './reducers/account';
import PublicKeysReducer from './reducers/publicKeys';
import ServerStateEventsReducer from './reducers/serverStateEvents';
import SecurityReducer from './reducers/security';
import ProfileReducer from './reducers/profile';
import ErrorReducer from './reducers/error';
import NotificationsReducer from './reducers/notifications';
import SupportReducer from './reducers/support';
import UiReducer from './reducers/ui';
import PartnerReducer from './reducers/partner';
import ResellerReducer from './reducers/reseller';
import MainAccountReducer from './reducers/mainAccount';
import NewServerReducer from './reducers/newServer';
import BatchOperationProgressReducer from './reducers/batchOperationProgress';

import {
    UserStore,
    ServerStore,
    TenantStore,
    TenantUsersStore,
    OperationProgressStore,
    LocaleStore,
    DnsStore,
    ReverseDnsStore,
    TariffStore,
    DistributionStore,
    InstallingServersStore,
    SnapshotsStore,
    ServerBackupStore,
    AccountStore,
    PublicKeysStore,
    ServerStateEventStore,
    SecurityStore,
    ProfileStore,
    ErrorStore,
    NotificationsStore,
    SupportStore,
    UiStore,
    PartnerStore,
    ResellerStore,
    MainAccountStore,
    NewServerStore,
    BatchOperationProgressStore,
} from './initialStates';

export interface Store {
    account: AccountStore;
    dns: DnsStore;
    error: ErrorStore;
    installingServers: InstallingServersStore;
    intl: LocaleStore;
    notifications: NotificationsStore;
    operationProgress: OperationProgressStore;
    partner: PartnerStore;
    profile: ProfileStore;
    publicKeys: PublicKeysStore;
    reseller: ResellerStore;
    reverseDns: ReverseDnsStore;
    security: SecurityStore;
    server: ServerStore;
    serverBackup: ServerBackupStore;
    serverDistribution: DistributionStore;
    serverStateEvents: ServerStateEventStore;
    snapshots: SnapshotsStore;
    support: SupportStore;
    tariff: TariffStore;
    tenant: TenantStore;
    tenantUser: TenantUsersStore;
    ui: UiStore;
    user: UserStore;
    mainAccount: MainAccountStore;
    newServer: NewServerStore;
    batchOperationProgress: BatchOperationProgressStore;
}

const reducer: Reducer<Store, Action> = combineReducers<Store, Action>({
    user: UserReducer,
    server: ServerReducer,
    tenant: TenantReducer,
    tenantUser: TenantUserReducer,
    operationProgress: OperationProgressReducer,
    intl: LocaleReducer,
    dns: DnsReducer,
    reverseDns: ReverseDnsReduces,
    tariff: TariffReducer,
    serverDistribution: DistributionReducer,
    installingServers: InstallingServersReducer,
    snapshots: SnapshotsReducer,
    serverBackup: ServerBackupReducer,
    account: AccountReducer,
    publicKeys: PublicKeysReducer,
    serverStateEvents: ServerStateEventsReducer,
    security: SecurityReducer,
    profile: ProfileReducer,
    notifications: NotificationsReducer,
    support: SupportReducer,
    error: ErrorReducer,
    ui: UiReducer,
    partner: PartnerReducer,
    reseller: ResellerReducer,
    mainAccount: MainAccountReducer,
    newServer: NewServerReducer,
    batchOperationProgress: BatchOperationProgressReducer,
});

const userMiddleware = createSagaMiddleware();
const serverMiddleware = createSagaMiddleware();
const tenantMiddleware = createSagaMiddleware();
const tenantUserMiddleware = createSagaMiddleware();
const operationProgressMiddleware = createSagaMiddleware();
const dnsMiddleware = createSagaMiddleware();
const reverseDnsMiddleware = createSagaMiddleware();
const tariffMiddleware = createSagaMiddleware();
const distributionMiddleware = createSagaMiddleware();
const installingServersMiddleware = createSagaMiddleware();
const snapshotsMiddleware = createSagaMiddleware();
const serverBackupMiddleware = createSagaMiddleware();
const accountMiddleware = createSagaMiddleware();
const publicKeysMiddleware = createSagaMiddleware();
const serverStateEventsMiddleware = createSagaMiddleware();
const securityMiddleware = createSagaMiddleware();
const profileMiddleware = createSagaMiddleware();
const notificationsMiddleware = createSagaMiddleware();
const supportMiddleware = createSagaMiddleware();
const partnerMiddleware = createSagaMiddleware();
const resellerMiddleware = createSagaMiddleware();
const mainAccountMiddleware = createSagaMiddleware();
const newServerMiddleware = createSagaMiddleware();
const batchOperationProgressMiddleware = createSagaMiddleware();

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(
        userMiddleware,
        serverMiddleware,
        tenantMiddleware,
        tenantUserMiddleware,
        operationProgressMiddleware,
        dnsMiddleware,
        reverseDnsMiddleware,
        tariffMiddleware,
        distributionMiddleware,
        installingServersMiddleware,
        snapshotsMiddleware,
        serverBackupMiddleware,
        accountMiddleware,
        publicKeysMiddleware,
        serverStateEventsMiddleware,
        securityMiddleware,
        profileMiddleware,
        notificationsMiddleware,
        supportMiddleware,
        partnerMiddleware,
        resellerMiddleware,
        mainAccountMiddleware,
        newServerMiddleware,
        batchOperationProgressMiddleware,
    )),
);
userMiddleware.run(userSaga);
serverMiddleware.run(serverSaga);
tenantMiddleware.run(tenantSaga);
tenantUserMiddleware.run(tenantUserSaga);
operationProgressMiddleware.run(operationProgressSaga);
dnsMiddleware.run(dnsSaga);
dnsMiddleware.run(reverseDnsSaga);
tariffMiddleware.run(tariffSaga);
distributionMiddleware.run(distributionSaga);
installingServersMiddleware.run(installingServersSaga);
snapshotsMiddleware.run(snapshotsSaga);
serverBackupMiddleware.run(serverBackupSaga);
accountMiddleware.run(accountSaga);
publicKeysMiddleware.run(publicKeysSaga);
serverStateEventsMiddleware.run(serverStateEventsSaga);
securityMiddleware.run(securitySaga);
profileMiddleware.run(profileSaga);
notificationsMiddleware.run(notificationsSaga);
supportMiddleware.run(supportSaga);
partnerMiddleware.run(partnerSaga);
resellerMiddleware.run(resellerSaga);
mainAccountMiddleware.run(mainAccountSaga);
newServerMiddleware.run(newServerSaga);
batchOperationProgressMiddleware.run(batchOperationProgressSaga);

export default store;
