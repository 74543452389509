import React, { FC } from 'react';
import cn from 'classnames';

import theme from 'Lib/theme';

interface OptionButtonProps {
    handler: () => void;
    title: string;
    type?: string;
}

const OptionButton: FC<OptionButtonProps> = ({ handler, title, type }) => (
    <button
        type="button"
        className={cn(theme.popover.item, type)}
        onClick={handler}
    >
        {title}
    </button>
);

export default OptionButton;
