import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { useTitle } from 'Hooks';
import { InnerPageLayout, useIntl } from 'Common';
import Tenant from 'Entities/Tenant';
import TenantUser from 'Entities/TenantUser';
import { Store } from 'Store';
import theme from 'Lib/theme';

import Header from './Header';
import UsersList from './UsersList';

interface ProjectAccessStoreProps {
    data: {
        users: TenantUser[];
        owner: number;
        email: string;
        tenant: Tenant;
    } | null;
}
type ProjectAccessRouteProps = RouteComponentProps<{ tenantId: string }>;
type ProjectAccessProps = ProjectAccessRouteProps & ProjectAccessStoreProps;

const ProjectAccess: FC<ProjectAccessProps> = ({ data }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('project_access_page_title'));
    const currentUser = data?.users?.find((u) => u.email === data.email);
    const showActionButtons = currentUser?.userId === data?.owner;
    if (!data) {
        return null;
    }

    const { tenant: { limits } } = data;
    return data?.users?.length > 0 ? (
        <InnerPageLayout
            title={intl.getMessage('project_access')}
            tooltip={intl.getMessage('project_access_info', { value: limits.maxUsersInTenant })}
            className={theme.content.with_actions}
            header={(
                <Header
                    showActionButtons={showActionButtons}
                    currentUser={currentUser!}
                    tenant={data.tenant}
                    usersNum={data?.users?.length - 1}
                />
            )}
        >
            <UsersList
                users={data.users}
                owner={data.owner}
                currentUser={data.email}
                showActionButtons={showActionButtons}
            />
        </InnerPageLayout>
    ) : null;
};

const selectUsers = (store: Store) => store.tenantUser;
const selectTenant = (store: Store, op: ProjectAccessRouteProps) => {
    const { match: { params: { tenantId } } } = op;
    return store.tenant.get(Number(tenantId));
};
const selectCurrentUser = (store: Store) => store.user.email;
const dataSelect = createSelector([
    selectUsers,
    selectTenant,
    selectCurrentUser,
], (users, tenant, email) => {
    if (!tenant) {
        return null;
    }
    const tenantUsers = users.get(tenant.id)!;
    return {
        users: tenantUsers,
        owner: tenant.ownerUserId,
        tenant,
        email,
    };
});

const mapStateToProps = (store: Store, ownProps: ProjectAccessRouteProps) => ({
    data: dataSelect(store, ownProps),
});

export default withRouter(connect(mapStateToProps)(ProjectAccess));
