import React, { FC } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { cancelInstallation } from 'Actions/server';
import { useIntl, Link, Button, Icon } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import Server from 'Entities/Server';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

interface TrialHintProps {
    compact?: boolean;
    progress: OperationProgress;
    server: Server;
}

const TrialHint: FC<TrialHintProps> = ({ server, progress }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const onCancelInstall = () => {
        dispatch(cancelInstallation([server.tenantId, server.id, progress.id]));
    };

    return (
        <Popover
            content={(
                <div className={theme.popover.popover}>
                    <Link
                        className={theme.popover.item}
                        to={RoutePath.OnboardingAddFundsServer}
                    >
                        {intl.getMessage('add_funds_install')}
                    </Link>
                    <button
                        type="button"
                        className={theme.popover.item}
                        onClick={onCancelInstall}
                    >
                        {intl.getMessage('undo')}
                    </button>
                    <div className={theme.popover.divider} />
                    <div className={theme.popover.description}>
                        {intl.getMessage('trial_popover_desc')}
                    </div>
                </div>
            )}
            placement="bottomRight"
            trigger="click"
            overlayClassName="popover"
        >
            <Button
                size="medium"
                type="border"
                className={cn(
                    theme.actions.action,
                    theme.button.action,
                )}
                title={intl.getMessage('title_server_actions')}
            >
                <div className={cn(theme.actions.dot, theme.actions.dot_red)} />
                <div className={theme.actions.title}>
                    {intl.getMessage('server_operation_error')}
                </div>
                <Icon icon="down" className={theme.actions.down} />
            </Button>
        </Popover>
    );
};

export default TrialHint;
