import React, { FC, useState, createRef } from 'react';
import { Slider } from 'antd';
import cn from 'classnames';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';

import { useIntl, CommonModalLayout, Input } from 'Common';
import theme from 'Lib/theme';
import { Store } from 'Store';
import { updateResellerClient } from 'Actions/reseller';

import s from './Client.module.pcss';

interface AddDiscountModalProps {
    visible: boolean;
    handleClose: () => void;
    clientId: number;
    currentDiscount: number;
    title: string;
}

interface AddDiscountModalStoreProps {
    details: Store['reseller']['details'];
}

const selectResellerDetails = (store: Store) => store.reseller.details;

const selector = createSelector([selectResellerDetails], (details) => details);

const AddDiscountModal: FC<AddDiscountModalProps> = ({
    visible, handleClose, clientId, currentDiscount, title,
}) => {
    const intl = useIntl();
    const [discountError, setDiscountError] = useState(false);
    const [sliderLabelClicked, setSliderLabelClicked] = useState(false);
    const [value, setValue] = useState(String(currentDiscount));
    const dispatch = useDispatch();

    const details = useSelector(selector);

    const sliderRef = createRef<HTMLDivElement>();

    if (!details) {
        return null;
    }

    const MAX_DISCOUNT_VALUE = details.limits.maxClientDiscount;

    const handleSubmit = () => {
        const amount = parseFloat(String(value));
        if (amount <= 100 && amount >= -1 * MAX_DISCOUNT_VALUE) {
            dispatch(updateResellerClient([clientId, { margin_percent: amount }]));
            handleClose();
        } else {
            setDiscountError(true);
        }
    };

    const sliderOnChange = (v: number) => {
        if (v < 0 && Math.abs(v) > MAX_DISCOUNT_VALUE) {
            setDiscountError(true);
            setValue(String(-MAX_DISCOUNT_VALUE));
        } else {
            setSliderLabelClicked(false);
            setDiscountError(false);
            setValue(String(v));
        }
    };

    const inputOnChange = (e: string) => {
        const checkValidity = /[-?\d]/.test(e);

        const amount = Number(e);

        if (Number.isNaN(amount) && !checkValidity) {
            return;
        }

        if (amount < 0 && Math.abs(amount) > MAX_DISCOUNT_VALUE) {
            setSliderLabelClicked(true);
            setDiscountError(true);
            setValue(String(-MAX_DISCOUNT_VALUE));
        } else if (amount <= 100 || String(amount) === '' || e === '-') {
            setDiscountError(false);
            setValue(e);
        }
    };

    const handleBlur = () => {
        if (Math.abs(Number(value)) === MAX_DISCOUNT_VALUE) {
            setDiscountError(false);
        }

        if (!discountError) {
            setSliderLabelClicked(false);
        }
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={title}
            buttonText={intl.getMessage('save')}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            className="modal_clients"
            width={480}
        >
            <div className={s.sliderHeader}>
                {!sliderLabelClicked ? (
                    <button
                        type="button"
                        className={s.labelButton}
                        onClick={() => setSliderLabelClicked(true)}
                    >
                        {Number(value) < 0
                            ? intl.getMessage('discount')
                            : intl.getMessage('clients_margin')}
                        <span className={s.labelValue}>
                            {`${Math.abs(Number(value))}%`}
                        </span>
                    </button>
                ) : (
                    <Input
                        name="discountInput"
                        type="text"
                        size="large"
                        placeholder={String(value)}
                        value={value}
                        onChange={inputOnChange}
                        onFocus={(e) => e.target.select()}
                        onBlur={handleBlur}
                        autoFocus
                        validate={(e) => !!e}
                        error={discountError}
                    />
                )}
            </div>
            <div className={s.sliderWrapper} ref={sliderRef}>
                <Slider
                    step={1}
                    value={Number(value)}
                    min={-100}
                    max={100}
                    onChange={sliderOnChange}
                    className="discount_modal"
                    trackStyle={{ backgroundColor: 'transparent' }}
                />
                <div
                    className={s.trackPositiveRange}
                    style={{ width: `${Number(value) * 1.9}px`, display: `${Number(value) < 0 ? 'none' : 'block'}` }}
                />
                <div
                    className={s.trackNegativeRange}
                    style={{ width: `${Math.abs(Number(value)) * 1.95}px`, display: `${Number(value) > 0 ? 'none' : 'block'}` }}
                />
            </div>
            <div className={cn(s.modalDesc, theme.color.grayHeaders)}>
                {intl.getMessage('clients_margin_desc', { max: details.limits.maxClientDiscount })}
            </div>
        </CommonModalLayout>
    );
};

export default AddDiscountModal;
