import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { Store } from 'Store';
import { getResellerClients } from 'Actions/reseller';

import { AccountInfo, ProfileDetails, Settings } from '.';

const selectProfile = (store: Store) => store.profile.info;
const selectResellerClients = (store: Store) => store.reseller.clients;
const selector = createSelector(
    [selectProfile, selectResellerClients],
    (info, clients) => ({ info, clients }),
);

interface InfoProps {
    openEditModal: boolean;
    setOpenEditModal: (state: boolean) => void;
}

const Info: FC<InfoProps> = ({ setOpenEditModal, openEditModal }) => {
    const dispatch = useDispatch();
    const profile = useSelector(selector);

    const { info } = profile;

    useEffect(() => {
        if (info?.reseller) {
            dispatch(getResellerClients([AUTH_TOKEN]));
        }
    }, [info]);

    if (!info) {
        return null;
    }

    return (
        <>
            <AccountInfo setOpenEditModal={setOpenEditModal} />

            <ProfileDetails
                setOpenEditModal={setOpenEditModal}
                openEditModal={openEditModal}
            />

            <Settings />
        </>
    );
};

export default Info;
