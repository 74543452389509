import React, { FC } from 'react';
import { Affix, Grid, Tooltip } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';

import { useIntl, Button, DropdownRangePicker } from 'Common';
import { DATE_RANGE_TYPES, Zoom } from 'Lib/helpers/consts';
import { getHeaderHeight } from 'Lib/helpers/helpers';
import { Range } from 'Lib/helpers/utils';
import theme from 'Lib/theme';
import { getRangeLabel } from 'Lib/helpers/translationHelper';

import s from './Filters.module.pcss';

const { useBreakpoint } = Grid;

interface FiltersProps {
    range: Range;
    setRange: (value: Range) => void;
    loadingState: boolean;
    getResources: (range: Range, status: { update: boolean }) => void;
    rangeName: DATE_RANGE_TYPES | null;
    setRangeName: (name: DATE_RANGE_TYPES | null) => void;
    globalZoom?: Zoom;
}

const Filters: FC<FiltersProps> = ({
    range,
    setRange,
    loadingState,
    getResources,
    rangeName,
    setRangeName,
    globalZoom,
}) => {
    const intl = useIntl();
    const screens = useBreakpoint();
    const { headerHeight, headerHeightMobile } = getHeaderHeight();
    const currentOffset = screens.xl ? headerHeight : headerHeightMobile;

    const handleUpdate = () => {
        setRange(range.update({ to: dayjs() }));
        getResources(range, { update: true });
    };

    return (
        <Affix offsetTop={currentOffset}>
            <div className={s.header}>
                <div className={s.title}>
                    {intl.getMessage('server_resources')}
                    <Tooltip
                        placement="right"
                        title={intl.getMessage('server_resources_notice')}
                        overlayClassName="tooltip tooltip_notice"
                    >
                        <div
                            className={cn(
                                theme.Badge.badge,
                                theme.Badge.question,
                                s.badge,
                            )}
                        />
                    </Tooltip>
                </div>
                <div className={s.filters}>
                    <div className={s.picker}>
                        <DropdownRangePicker
                            mode="month"
                            range={range}
                            setDateRange={setRange}
                            disabled={loadingState}
                            rangeName={rangeName ? getRangeLabel(intl, rangeName) : undefined}
                            setRangeName={setRangeName}
                            borderless
                            onlyRanges
                            globalZoom={globalZoom}
                        />
                    </div>
                    <div className={s.wrap}>
                        <Button
                            type="icon"
                            size="medium"
                            icon="update"
                            title={intl.getMessage('title_update_data')}
                            className={theme.button.desktop_transparent}
                            disabled={loadingState}
                            onClick={handleUpdate}
                        />
                    </div>
                </div>
            </div>
        </Affix>
    );
};

export default Filters;
