import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { Button, Icon, useIntl } from 'Common';
import { ISavedUserAccount } from 'Actions/user';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import s from './ChangeAccount.module.pcss';

interface UserAccountProps {
    currentId: number;
    userAccount: ISavedUserAccount;
    reseller?: boolean;
    onLogin: (account: ISavedUserAccount) => void;
    removeAccount: (account: ISavedUserAccount) => void;
    rollbackAccount: (account: ISavedUserAccount) => void;
}

const UserAccount: FC<UserAccountProps> = ({
    currentId,
    userAccount,
    reseller,
    onLogin,
    removeAccount,
    rollbackAccount,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const [hidden, setHidden] = useState(false);
    const { id, email, authorized, token } = userAccount;
    const currentAccount = currentId === id;

    const onHide = () => {
        setHidden(true);
        removeAccount(userAccount);
    };

    const onShow = () => {
        setHidden(false);
        rollbackAccount(userAccount);
    };

    const cancelButton = (text: string) => (
        <Button
            type="link"
            className={s.cancel}
            onClick={(e) => {
                e.stopPropagation();
                onShow();
            }}
        >
            {text}
        </Button>
    );

    const getDescription = () => {
        if (!authorized && !currentAccount) {
            return intl.getMessage('unauthorized');
        }

        if (reseller) {
            return (
                <>
                    <span className={cn(theme.dotList.item, theme.dotList.item_small)}>
                        {intl.getMessage('studio')}
                    </span>
                    <span className={cn(theme.dotList.item, theme.dotList.item_small)}>
                        #{id}
                    </span>
                </>
            );
        }

        return `#${id}`;
    };

    const showDeleteButton = !currentAccount
        && !hidden
        && MAIN_TOKEN !== token;

    const content = (
        <>
            <div className={s.info}>
                <div className={s.email}>
                    {email}
                </div>
                {hidden ? (
                    <div className={s.desc}>
                        {intl.getMessage('account_hidden', { a: cancelButton })}
                    </div>
                ) : (
                    <div className={cn(s.desc, s.desc_lower)}>
                        {getDescription()}
                    </div>
                )}
            </div>
            {showDeleteButton && (
                <Button
                    type="icon"
                    icon="delete"
                    className={cn(
                        s.remove,
                        theme.button.small,
                        theme.button.bordersWhite,
                    )}
                    onClick={(e) => {
                        e.stopPropagation();
                        onHide();
                    }}
                    title={intl.getMessage('title_remove_from_list')}
                    tooltipClassName="tooltip_modal"
                />
            )}
            {currentAccount && (
                <Icon icon="check" className={s.check} />
            )}
        </>
    );

    if (authorized || currentAccount) {
        return (
            <div
                role="button"
                className={cn(s.account, { [s.account_disabled]: currentAccount })}
                onClick={() => !currentAccount && onLogin(userAccount)}
                tabIndex={0}
            >
                {content}
            </div>
        );
    }

    return (
        <div
            role="button"
            className={s.account}
            onClick={() => history.push(linkPathBuilder(intl.currentLocale, RoutePath.AddAccount))}
            tabIndex={0}
        >
            {content}
        </div>
    );
};

export default UserAccount;
