import React, { FC, useState, ChangeEvent, ClipboardEvent } from 'react';
import { Input } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useIntl, CommonModalLayout } from 'Common';
import Server from 'Entities/Server';
import { createSnapshot } from 'Actions/snapshots';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import SnapshotCreate from 'Entities/SnapshotCreate';
import { useEnter } from 'Hooks';

const { TextArea } = Input;
interface ModalNewSnapshotProps {
    visible: boolean;
    close: () => void;
    server: Server;
}

const ModalNewSnapshot: FC<ModalNewSnapshotProps> = ({ visible, close, server }) => {
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [description, setDescription] = useState('');
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleOk = () => {
        const { tenantId, id } = server;
        if (!name) {
            setNameError(true);
            return;
        }
        dispatch(createSnapshot([tenantId, id, { name, description }]));
        history.push(linkPathBuilder(
            intl.currentLocale, RoutePath.Server, { tenantId, serverId: id },
        ));
    };
    useEnter(handleOk);

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= SnapshotCreate.nameMaxLength) {
            setNameError(false);
            setName(e.target.value);
        }
    };
    const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const text = name + e.clipboardData.getData('text/plain');
        setName(text.slice(0, SnapshotCreate.nameMaxLength));
    };

    const onChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value.length <= SnapshotCreate.descriptionMaxLength) {
            setDescription(e.target.value);
        }
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('snapshots_create_modal')}
            handleSubmit={handleOk}
            handleClose={close}
            submitButtonId="createSnapshot"
            buttonText={intl.getMessage('snapshots_create_modal')}
            centered
        >
            <div className="modal__desc modal__desc--gray">
                {intl.getMessage('snapshots_desc_modal')}
            </div>
            <div className="modal__desc">
                <Input
                    type="text"
                    name="code"
                    size="large"
                    className={cn('input', { input_error: nameError })}
                    placeholder={intl.getPlural('snapshots_title_symbols', SnapshotCreate.nameMaxLength)}
                    value={name}
                    onChange={onChangeName}
                    onPaste={onPaste}
                    autoFocus
                />
                <div className="modal__label">
                    {intl.getMessage('required')}
                </div>
            </div>
            <div className="modal__desc">
                <TextArea
                    value={description}
                    onChange={onChangeDescription}
                    placeholder={intl.getMessage('comment')}
                    className="textarea textarea_gray textarea_comment"
                />
            </div>
        </CommonModalLayout>
    );
};

export default ModalNewSnapshot;
