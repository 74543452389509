import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { useIntl, Link, Icon, ModalToDefault, Button } from 'Common';
import { updateServerBackupPolicy } from 'Actions/server';
import { frequencyMessage, getServerIcon } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import BackupPolicy from 'Entities/BackupPolicy';
import Server from 'Entities/Server';
import theme from 'Lib/theme';
import { useTitle } from 'Hooks';

import s from './ServerBackup.module.pcss';

interface ServerBackupProps {
    server: Server;
    tenantBackupPolicy?: BackupPolicy;
    other?: boolean;
}

const ServerBackup: FC<ServerBackupProps> = ({ server, other, tenantBackupPolicy }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('servers_backup_page_title'));
    const {
        id,
        tenantId,
        name,
        backupPolicy: { frequency, quantity },
        distribution: { type },
    } = server;
    const numFreq = frequencyMessage[frequency];
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const handleReset = () => {
        if (tenantBackupPolicy) {
            dispatch(updateServerBackupPolicy(
                [tenantId, id, tenantBackupPolicy.serialize()],
            ));
        }
    };

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.with_actions,
            )}
        >
            <div className={cn(theme.card.grid, theme.card.grid_projectBackup)}>
                <div className={s.server}>
                    <Icon icon={getServerIcon(type)} className={s.icon} />
                    <span className={theme.common.textOverflow}>
                        {name}
                    </span>
                </div>

                <div className={s.actions}>
                    <div className={s.info}>
                        <div className={theme.dotList.item}>
                            {intl.getPlural('count_copy_full', quantity)}
                        </div>
                        <div className={theme.dotList.item}>
                            {intl.getPlural('count_hour_full', numFreq)}
                        </div>
                    </div>

                    <div className={theme.card.actions}>
                        <Link
                            to={RoutePath.ServerBackup}
                            props={{ serverId: id }}
                            className={cn(theme.card.action, theme.card.action_hover)}
                        >
                            <Button
                                type="icon"
                                icon="edit"
                                title={intl.getMessage('project_backup_setting_edit')}
                            />
                        </Link>
                        {other && (
                            <Button
                                type="icon"
                                icon="restore"
                                title={intl.getMessage('project_backup_setting_restore')}
                                className={cn(theme.card.action, theme.card.action_hover)}
                                onClick={() => setShowModal(true)}
                            />
                        )}
                    </div>
                </div>

                <Popover
                    trigger="click"
                    content={(
                        <div className={theme.popover.popover}>
                            <Link
                                to={RoutePath.ServerBackup}
                                props={{ serverId: id }}
                                className={theme.popover.item}
                            >
                                {intl.getMessage('edit')}
                            </Link>
                            {other && (
                                <button
                                    type="button"
                                    className={theme.popover.item}
                                    onClick={() => setShowModal(true)}
                                >
                                    {intl.getMessage('reset')}
                                </button>
                            )}
                        </div>
                    )}
                    placement="left"
                    overlayClassName="popover"
                >
                    <Button
                        type="icon"
                        icon="options"
                        className={cn(theme.card.action, theme.card.action_popover)}
                    />
                </Popover>
            </div>
            {showModal && (
                <ModalToDefault
                    onOk={handleReset}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

export default ServerBackup;
