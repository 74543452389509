import React, { FC, useState } from 'react';

import { useIntl, ServerHeaderLinks, DashboardHeader, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import Server from 'Entities/Server';

import ModalNewSnapshot from '../ModalNewSnapshot';

interface HeaderProps {
    server: Server;
}

const Header: FC<HeaderProps> = ({ server }) => {
    const intl = useIntl();
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <DashboardHeader
                navigation={(
                    <ServerHeaderLinks server={server} active={RoutePath.Snapshots} />
                )}
                actions={(
                    <Button
                        type="primary"
                        size="medium"
                        onClick={() => setShowModal(true)}
                    >
                        {intl.getMessage('snapshots_new')}
                    </Button>
                )}
            />

            {showModal && (
                <ModalNewSnapshot
                    visible
                    server={server}
                    close={() => setShowModal(false)}
                />
            )}
        </>
    );
};

export default Header;
