import { Action } from 'store/actions';
import {
    SecurityActions,
    saveAllSecuritySettings,
    saveDeliveryType,
} from 'Actions/security';
import SecurityActionSettings from 'Entities/SecurityActionSettings';

import { SecurityStore, securityInitialState as initialState } from '../initialStates';

const reducer = (state: SecurityStore = initialState, action: Action): SecurityStore => {
    switch (action.type as SecurityActions) {
        case SecurityActions.SaveAllSecuritySettings: {
            const { payload } = action as ReturnType<typeof saveAllSecuritySettings>;
            const settings: SecurityActionSettings = payload;
            return { ...state, settings };
        }
        case SecurityActions.SaveDeliveryType: {
            const { payload } = action as ReturnType<typeof saveDeliveryType>;
            return { ...state, deliveryType: payload };
        }

        default:
            return state;
    }
};

export default reducer;
