import React, { FC } from 'react';
import { FormikHelpers, Formik } from 'formik';

import { useIntl, Input } from 'Common';
import CreateNonResident, { ICreateNonResident } from 'Entities/CreateNonResident';
import { FormInnerProps } from '.';

const NonResidentForm: FC<FormInnerProps> = ({ handleSubmit: handle, Submit }) => {
    const intl = useIntl();
    const initial: ICreateNonResident = { non_resident_name: '' };

    const onSubmit = (
        values: ICreateNonResident,
        helpers: FormikHelpers<ICreateNonResident>,
    ) => handle({ non_resident: values }, helpers);

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}
        >
            {({
                setFieldValue,
                handleSubmit,
                values,
                errors,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Input
                        autoFocus
                        name="non_resident_name"
                        label={intl.getMessage('credentials_company_name')}
                        placeholder={intl.getMessage('credentials_company_name')}
                        size="middle"
                        type="text"
                        value={values.non_resident_name}
                        onChange={(e) => setFieldValue('non_resident_name', e)}
                        error={!!errors.non_resident_name}
                        validate={CreateNonResident.nonResidentNameValidate}
                    />
                    <Submit />
                </form>
            )}
        </Formik>
    );
};

export default NonResidentForm;
