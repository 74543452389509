import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { getCurrentTheme } from 'Lib/helpers/helpers';
import Profile from 'Entities/Profile';
import { WebTheme } from 'Entities/WebTheme';
import { Store } from 'Store';

import boxes from 'Lib/assets/images/boxes.png';
import boxes2x from 'Lib/assets/images/boxes@2x.png';
import boxesDark from 'Lib/assets/images/boxes_dark.png';
import boxes2xDark from 'Lib/assets/images/boxes_dark@2x.png';
import boxesGray from 'Lib/assets/images/boxes_gray.png';
import boxesGray2x from 'Lib/assets/images/boxes_gray@2x.png';
import boxesGrayDark from 'Lib/assets/images/boxes_gray_dark.png';
import boxesGrayDark2x from 'Lib/assets/images/boxes_gray_dark@2x.png';
import servers from 'Lib/assets/images/servers.png';
import servers2x from 'Lib/assets/images/servers@2x.png';
import serversDark from 'Lib/assets/images/servers_dark.png';
import servers2xDark from 'Lib/assets/images/servers_dark@2x.png';

import s from './EmptyPageLayout.module.pcss';

type PageType = 'dropdown' | 'phone' | 'search';
type ImageType = 'servers' | 'boxes';

interface EmptyPageStoreProps {
    profile: Profile | null;
}

interface EmptyPageOwnProps {
    title?: string;
    desc?: string;
    link?: RoutePath;
    linkText?: string;
    imageType?: ImageType;
    handleClick?: React.MouseEventHandler<HTMLElement>;
    className?: string;
    type?: PageType;
    border?: boolean;
    additionalLink?: ReactNode;
    actionClassName?: string;
    contentClassName?: string;
    contacts?: ReactNode;
    grayImg?: boolean;
}

type EmptyPageProps = EmptyPageStoreProps & EmptyPageOwnProps;

const EmptyPageLayout: FC<EmptyPageProps> = ({
    profile,
    title,
    desc,
    link,
    linkText,
    imageType,
    handleClick,
    className,
    type,
    border,
    additionalLink,
    actionClassName,
    contentClassName,
    contacts,
    grayImg,
}) => {
    let images = {
        servers,
        servers2x,
        boxes,
        boxes2x,
        boxesGray,
        boxesGray2x,
    };

    const currentTheme = profile?.webTheme && getCurrentTheme(profile.webTheme);

    if (currentTheme === WebTheme.DARK) {
        images = {
            servers: serversDark,
            servers2x: servers2xDark,
            boxes: boxesDark,
            boxes2x: boxes2xDark,
            boxesGray: boxesGrayDark,
            boxesGray2x: boxesGrayDark2x,
        };
    }

    const getLink = () => {
        if (!linkText) {
            return null;
        }

        if (link) {
            return (
                <Link to={link} className={s.link}>
                    <Button
                        type="link"
                        size="medium"
                        className={actionClassName}
                    >
                        {linkText}
                    </Button>
                </Link>
            );
        }

        return (
            <Button
                type="link"
                size="medium"
                onClick={handleClick}
                className={actionClassName}
            >
                {linkText}
            </Button>
        );
    };

    return (
        <div
            className={cn(
                s.wrap,
                { [s.wrap_dropdown]: type === 'dropdown' },
                { [s.wrap_phone]: type === 'phone' },
                { [s.wrap_search]: type === 'search' },
                { [s.wrap_border]: border },
                { [s.wrap_contacts]: contacts },
                className,
            )}
        >
            {imageType === 'servers' ? (
                <img
                    src={images.servers}
                    srcSet={`${images.servers2x} 2x`}
                    className={cn(s.image, s.image_servers)}
                    alt="servers"
                />
            ) : (
                <img
                    src={!grayImg ? images.boxes : images.boxesGray}
                    srcSet={grayImg ? `${images.boxesGray2x} 2x` : `${images.boxes2x} 2x`}
                    className={cn(
                        s.image,
                        { [s.image_dropdown]: type },
                    )}
                    alt="boxes"
                />
            )}
            <div className={cn(s.content, contentClassName)}>
                {title && (
                    <div className={s.title}>
                        {title}
                    </div>
                )}
                {desc && (
                    <div className={cn(
                        s.desc,
                        { [s.desc_gray]: grayImg },
                    )}
                    >
                        {desc}
                    </div>
                )}

                <div className={s.links}>
                    {getLink()}
                    {additionalLink && additionalLink}
                </div>
            </div>

            {contacts}
        </div>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([selectProfile], (profile) => ({ profile }));

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(EmptyPageLayout);
