import React, { FC, useState, useRef } from 'react';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { useIntl, Icon, Button } from 'Common';
import { useClickOutside } from 'Hooks';
import Server from 'Entities/Server';
import Tenant from 'Entities/Tenant';
import { handleServerSearch } from 'Lib/helpers/search';
import { TRANSITION_TIME } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import ServerList from './ServerList';
import SearchList from './SearchList';
import s from './ServersSelect.module.pcss';

interface ServersSelectProps {
    servers: Map<number, Server>;
    tenants: Map<number, Tenant>;
    selectedServers: Set<Server>;
    selectServers: (s: Server) => void;
    selectAllServers: () => void;
    selectTenantServers: (s: Server[], c: boolean) => void;
    clearSelection: () => void;
}

const ServersSelect: FC<ServersSelectProps> = ({
    servers,
    tenants,
    selectedServers,
    selectServers,
    selectAllServers,
    selectTenantServers,
    clearSelection,
}) => {
    const intl = useIntl();
    const listRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);
    const [visibleDropdown, setVisibleDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const clearSearch = () => {
        setTimeout(() => setSearchQuery(''), TRANSITION_TIME);
    };

    const handleDropdownVisibility = (state: boolean) => {
        setVisibleDropdown(state);

        if (!state) {
            clearSearch();
        }
    };

    useClickOutside([selectRef, listRef], () => handleDropdownVisibility(false));

    const commonResult = handleServerSearch(searchQuery, servers, intl.currentLocale);
    const initial: Record<string, Server[]> = { deleted: [], current: [] };
    const data = commonResult.reduce((prev, server) => {
        if (!server.timeDeletedMillis) {
            prev.current.push(server);
        } else {
            prev.deleted.push(server);
        }
        return prev;
    }, initial);
    const deleted = data.deleted.sort((a, b) => b.timeDeletedMillis! - a.timeDeletedMillis!);
    const searchResult = [...data.current, ...deleted];

    const serversList = (
        <div ref={listRef} className={s.list}>
            {searchQuery ? (
                <SearchList
                    servers={searchResult}
                    selectedServers={selectedServers}
                    selectServers={selectServers}
                />
            ) : (
                <ServerList
                    tenants={tenants}
                    servers={Array.from(servers.values())}
                    selectedServers={selectedServers}
                    selectServers={selectServers}
                    selectAllServers={selectAllServers}
                    selectTenantServers={selectTenantServers}
                />
            )}
        </div>
    );

    return (
        <Dropdown
            overlay={serversList}
            placement="bottomLeft"
            visible={visibleDropdown}
            trigger={['click']}
        >
            <div>
                {visibleDropdown ? (
                    <div
                        className={cn(s.select, s.select_open)}
                        ref={selectRef}
                    >
                        {searchQuery ? (
                            <Button
                                id="clearSearch"
                                type="icon"
                                className={cn(
                                    theme.button.for_input,
                                    theme.button.small,
                                    s.clear,
                                )}
                                icon="clear"
                                onClick={clearSearch}
                            />
                        ) : (
                            <Icon
                                icon="search"
                                className={s.icon}
                            />
                        )}
                        <input
                            type="text"
                            placeholder={intl.getMessage('search_placeholder')}
                            className={s.input}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            autoFocus
                        />
                        {selectedServers.size > 0 && (
                            <div className={s.badge}>
                                {intl.getPlural('selected_short', selectedServers.size)}

                                <Button
                                    id="clearSearch"
                                    type="icon"
                                    className={cn(
                                        theme.button.white,
                                        theme.button.close_badge,
                                        s.close,
                                    )}
                                    icon="close_small"
                                    onClick={clearSelection}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <button
                        type="button"
                        className={s.select}
                        onClick={(e) => {
                            e.stopPropagation();
                            setVisibleDropdown(true);
                        }}
                    >
                        {selectedServers.size > 0 ? (
                            intl.getPlural('server_selected', selectedServers.size)
                        ) : (
                            intl.getMessage('all_virtual_servers_short')
                        )}
                        <Icon icon="down" className={s.arrow} />
                    </button>
                )}
            </div>
        </Dropdown>
    );
};

export default ServersSelect;
