import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Button, Icon, Link, useIntl, ModalCloseAccess } from 'Common';
import theme from 'Lib/theme';
import { deleteTenant } from 'Actions/tenant';
import Tenant from 'Entities/Tenant';
import { RoutePath } from 'Lib/helpers/routes';
import { frequencyMessage } from 'Lib/helpers/utils';
import TenantUser from 'Entities/TenantUser';

import ModalRemoveProject from './ModalRemoveProject';
import Name from './Name';
import s from './Info.module.pcss';

interface InfoProps {
    currentUser: TenantUser | undefined;
    ownerUserId: number;
    users: TenantUser[];
    tenant: Tenant;
    isOwner: boolean;
    visibleList: boolean;
    setVisibleList: (v: boolean) => void;
    titleCursor: boolean;
}

const Info: FC<InfoProps> = ({
    tenant, isOwner, users, ownerUserId, currentUser, visibleList, setVisibleList, titleCursor,
}) => {
    const guestList = users.filter((i) => i.userId !== ownerUserId);
    const [openLeaveProject, setOpenLeaveProject] = useState<TenantUser | null>(null);

    const intl = useIntl();
    const dispatch = useDispatch();
    const { id, description, isDefault, backupPolicy, ownerUserEmail } = tenant;

    const [openModal, setOpenModal] = useState(false);
    const [editActive, setEditActive] = useState(false);

    const {
        quantity,
        frequency,
    } = backupPolicy;

    const onDeleteProject = () => {
        dispatch(deleteTenant(id));
    };

    return (
        <div className={s.wrap}>
            <div className={s.info}>
                <Icon icon="project" className={s.icon} />
                <Name
                    tenant={tenant}
                    isOwner={isOwner}
                    visibleList={visibleList}
                    setVisibleList={setVisibleList}
                    editActive={editActive}
                    setEditActive={setEditActive}
                    titleCursor={titleCursor}
                />
            </div>
            <div className={s.link}>
                <Link
                    to={RoutePath.ProjectBackup}
                    props={{ tenantId: id }}
                >
                    {intl.getPlural('count_copy', quantity)},&nbsp;
                    {intl.getPlural('count_hour_full', frequencyMessage[frequency])}
                </Link>
            </div>
            {isOwner ? (
                <div className={s.link}>
                    <Link
                        to={RoutePath.ProjectAccess}
                        props={{ tenantId: id }}
                    >
                        {intl.getPlural('project_users', guestList.length)}
                    </Link>
                </div>
            ) : (
                <div className={cn(theme.color.grayHeaders, s.ownerEmail)}>
                    {ownerUserEmail}
                </div>
            )}
            {isOwner && currentUser && (
                <div className={s.actions}>
                    {!isDefault ? (
                        <>
                            <Button
                                type="icon"
                                size="medium"
                                icon="edit"
                                className={cn(theme.edit.icon, s.visible)}
                                onClick={() => setEditActive(isOwner)}
                                title={intl.getMessage('project_title_edit')}
                            />
                            <Button
                                type="icon"
                                size="medium"
                                icon="delete"
                                onClick={() => setOpenModal(true)}
                                title={intl.getMessage('title_delete_project')}
                            />
                        </>
                    ) : (
                        <Button
                            type="icon"
                            size="medium"
                            icon="edit"
                            className={cn(theme.edit.icon, s.visible)}
                            onClick={() => setEditActive(isOwner)}
                            title={intl.getMessage('project_title_edit')}
                        />
                    )}
                </div>
            )}
            {openModal && (
                <ModalRemoveProject
                    visible
                    close={() => setOpenModal(false)}
                    onOk={onDeleteProject}
                    name={description}
                />
            )}
            {openLeaveProject && (
                <ModalCloseAccess
                    leave
                    visible={!!openLeaveProject}
                    user={openLeaveProject}
                    onClose={() => setOpenLeaveProject(null)}
                />
            )}
        </div>
    );
};

export default Info;
