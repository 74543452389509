import React, { FC } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { useIntl } from 'Common';
import Server from 'Entities/Server';
import theme from 'Lib/theme';

import s from '../Body.module.pcss';

interface CostTooltipProps {
    server: Server;
}

const CostTooltip: FC<CostTooltipProps> = ({ server }) => {
    const intl = useIntl();

    const {
        costForecast: {
            memoryCost,
            overallCost,
            fixedCost,
            cpuCostCurrent,
            cpuCostMax,
            diskCost,
            ipCost,
            rxTrafficCost,
            txTrafficCost,
            backupCost,
        },
        tariff,
        ipCount,
    } = server;

    const costHint = [
        {
            label: intl.getMessage('memory'),
            value: memoryCost,
        },
        {
            label: intl.getMessage('disk'),
            value: diskCost,
        },
        {
            label: intl.getMessage('processor'),
            value: cpuCostCurrent,
        },
        {
            label: intl.getMessage('new_server_inbound_traffic'),
            value: rxTrafficCost,
        },
        {
            label: intl.getMessage('new_server_outbound_traffic'),
            value: txTrafficCost,
        },
    ];

    const costHintFixed = [
        {
            label: intl.getMessage('new_server_inbound_traffic'),
            value: rxTrafficCost,
        },
        {
            label: intl.getMessage('new_server_outbound_traffic'),
            value: txTrafficCost,
        },
    ];

    const hintTooltip = tariff?.fixed ? costHintFixed : costHint;
    const mainCost = Math.round((overallCost - backupCost - ipCost) * 100) / 100;

    return (
        <Tooltip
            overlayClassName="tooltip_big"
            placement="right"
            title={(
                <div className={cn(theme.popover.popover, s.tooltip)}>
                    <div className={s.tooltip__head}>
                        <div className={cn(s.tooltip__row, s.tooltip__row_head)}>
                            <div className={cn(s.tooltip__col, s.tooltip__col_label)}>
                                {intl.getMessage('tariff')}
                            </div>
                            <div className={cn(s.tooltip__col, s.tooltip__col_value)}>
                                {intl.getMessage('price_simple', { value: tariff?.fixed ? fixedCost : mainCost })}
                            </div>
                        </div>
                    </div>
                    <div className={s.tooltip__body}>
                        {hintTooltip.map(({ label, value }) => {
                            if (value) {
                                return (
                                    <div
                                        key={label}
                                        className={s.tooltip__row}
                                    >
                                        <div className={cn(
                                            s.tooltip__col,
                                            s.tooltip__col_label,
                                        )}
                                        >
                                            {label}
                                        </div>
                                        <div className={cn(
                                            s.tooltip__col,
                                            s.tooltip__col_value,
                                        )}
                                        >
                                            {label === intl.getMessage('processor')
                                                ? intl.getMessage('price_simple_max', { value, max: cpuCostMax })
                                                : intl.getMessage('price_simple', { value })}
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                    <div
                        className={cn(
                            theme.popover.divider,
                            theme.popover.divider_large,
                        )}
                    />
                    <div className={s.tooltip__head}>
                        {ipCost > 0 && (
                            <div className={cn(s.tooltip__row, s.tooltip__row_head)}>
                                <div className={cn(s.tooltip__col, s.tooltip__col_label)}>
                                    {intl.getMessage('public_ip')} ({ipCount})
                                </div>
                                <div className={cn(s.tooltip__col, s.tooltip__col_value)}>
                                    {intl.getMessage('price_simple', { value: ipCost })}
                                </div>
                            </div>
                        )}
                        <div className={s.tooltip__row}>
                            <div className={cn(s.tooltip__col, s.tooltip__col_label)}>
                                {intl.getMessage('backup')}
                            </div>
                            <div className={cn(s.tooltip__col, s.tooltip__col_value)}>
                                {intl.getPlural('price', backupCost)}
                            </div>
                        </div>
                        {!tariff.fixed && (
                            <>
                                <div
                                    className={cn(
                                        theme.popover.divider,
                                        theme.popover.divider_large,
                                    )}
                                />
                                <div className={cn(s.tooltip__row, s.tooltip__row_notice)}>
                                    {intl.getMessage('flexible_tariff_notice')}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        >
            <div
                className={cn(
                    theme.Badge.badge,
                    theme.Badge.question,
                    s.badge,
                )}
            />
        </Tooltip>
    );
};

export default CostTooltip;
