// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITenantUser {
    email: string;
    tenant_id: number;
    user_id: number;
}

export default class TenantUser {
    readonly _email: string;

    /**
     * Description: Email
     * Example: user@serveroid.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    readonly _tenant_id: number;

    /**
     * Description: Tenant id
     * Example: 19934
     */
    get tenantId(): number {
        return this._tenant_id;
    }

    static tenantIdValidate(tenantId: number): boolean {
        return typeof tenantId === 'number';
    }

    readonly _user_id: number;

    /**
     * Description: User identifier id
     * Example: 2087
     */
    get userId(): number {
        return this._user_id;
    }

    static userIdValidate(userId: number): boolean {
        return typeof userId === 'number';
    }

    constructor(props: ITenantUser) {
        this._email = props.email.trim();
        this._tenant_id = props.tenant_id;
        this._user_id = props.user_id;
    }

    serialize(): ITenantUser {
        const data: ITenantUser = {
            email: this._email,
            tenant_id: this._tenant_id,
            user_id: this._user_id,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            tenant_id: typeof this._tenant_id === 'number',
            user_id: typeof this._user_id === 'number',
            email: typeof this._email === 'string' && !!this._email.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITenantUser): TenantUser {
        return new TenantUser(props);
    }

    readonly keys: { [key: string]: string } = {
        email: 'email',
        tenantId: 'tenant_id',
        userId: 'user_id',
        }
;

    mergeDeepWith(props: Partial<TenantUser>): TenantUser {
        const updateData: Partial<ITenantUser> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TenantUser) => {
            const updateKey = this.keys[key] as keyof ITenantUser;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITenantUser, keyof ITenantUser>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TenantUser({ ...this.serialize(), ...updateData });
    }
}
