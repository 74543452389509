import NotificationDeliverySettings, { INotificationDeliverySettings } from './NotificationDeliverySettings';
import SmsDeliveryPeriod, { ISmsDeliveryPeriod } from './SmsDeliveryPeriod';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface INotificationSettingsUpdate {
    delivery_settings?: INotificationDeliverySettings[];
    sms_delivery_periods?: ISmsDeliveryPeriod[];
}

export default class NotificationSettingsUpdate {
    readonly _delivery_settings: NotificationDeliverySettings[] | undefined;

    /** */
    get deliverySettings(): NotificationDeliverySettings[] | undefined {
        return this._delivery_settings;
    }

    readonly _sms_delivery_periods: SmsDeliveryPeriod[] | undefined;

    /** */
    get smsDeliveryPeriods(): SmsDeliveryPeriod[] | undefined {
        return this._sms_delivery_periods;
    }

    constructor(props: INotificationSettingsUpdate) {
        if (props.delivery_settings) {
            this._delivery_settings = props.delivery_settings.map((p) => new NotificationDeliverySettings(p));
        }
        if (props.sms_delivery_periods) {
            this._sms_delivery_periods = props.sms_delivery_periods.map((p) => new SmsDeliveryPeriod(p));
        }
    }

    serialize(): INotificationSettingsUpdate {
        const data: INotificationSettingsUpdate = {
        };
        if (typeof this._delivery_settings !== 'undefined') {
            data.delivery_settings = this._delivery_settings.map((p) => p.serialize());
        }
        if (typeof this._sms_delivery_periods !== 'undefined') {
            data.sms_delivery_periods = this._sms_delivery_periods.map((p) => p.serialize());
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            sms_delivery_periods: !this._sms_delivery_periods ? true : this._sms_delivery_periods.reduce((result, p) => result && p.validate().length === 0, true),
            delivery_settings: !this._delivery_settings ? true : this._delivery_settings.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: INotificationSettingsUpdate): NotificationSettingsUpdate {
        return new NotificationSettingsUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        deliverySettings: 'delivery_settings',
        smsDeliveryPeriods: 'sms_delivery_periods',
        }
;

    mergeDeepWith(props: Partial<NotificationSettingsUpdate>): NotificationSettingsUpdate {
        const updateData: Partial<INotificationSettingsUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof NotificationSettingsUpdate) => {
            const updateKey = this.keys[key] as keyof INotificationSettingsUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<INotificationSettingsUpdate, keyof INotificationSettingsUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new NotificationSettingsUpdate({ ...this.serialize(), ...updateData });
    }
}
