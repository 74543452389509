import React, { FC } from 'react';
import AccountBillingDocument from 'Entities/AccountBillingDocument';
import AccountInvoice from 'Entities/AccountInvoice';
import AccountDocument from 'Entities/AccountDocument';
import { AccountDocumentType } from 'Entities/AccountDocumentType';
import Document from './Document';
import Invoice from './Invoice';

interface PaymentProps {
    document: AccountDocument;
}

const Payment: FC<PaymentProps> = ({ document }) => {
    const {
        documentType,
    } = document;

    let currentDocument: AccountBillingDocument | AccountInvoice;
    if (documentType === AccountDocumentType.ACT) {
        currentDocument = document.act!;
    } else {
        currentDocument = document.invoice!;
    }
    if (!currentDocument) {
        return null;
    }

    if (documentType === AccountDocumentType.ACT) {
        return <Document act={document.act!} />;
    }
    return <Invoice invoice={document.invoice!} />;
};

export default Payment;
