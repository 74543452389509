// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IBackupDownloadLink {
    backup_id: string;
    download_link: string;
    expiry_time: string;
    expiry_time_millis: number;
}

export default class BackupDownloadLink {
    readonly _backup_id: string;

    /**
     * Description: Unique identifier
     * Example: ZGlmZi1iYWNrdXBzbmFwMjAyMDA0MTIwMDAwMTQtYmFja3Vwc25hcDIwMjAwNDE1MDAwMDEyLmx6NA==
     */
    get backupId(): string {
        return this._backup_id;
    }

    static backupIdValidate(backupId: string): boolean {
        return typeof backupId === 'string' && !!backupId.trim();
    }

    readonly _download_link: string;

    /**
     * Description: Download link
     * Example: https://download5.serveroid.com/?p=2345yhgde5678&b=76rfbj8765
     */
    get downloadLink(): string {
        return this._download_link;
    }

    static downloadLinkValidate(downloadLink: string): boolean {
        return typeof downloadLink === 'string' && !!downloadLink.trim();
    }

    readonly _expiry_time: string;

    /** */
    get expiryTime(): string {
        return this._expiry_time;
    }

    static expiryTimeValidate(expiryTime: string): boolean {
        return typeof expiryTime === 'string' && !!expiryTime.trim();
    }

    readonly _expiry_time_millis: number;

    /**
     * Description: Backup expiration time, UTC millis
     * Example: 1589284800000
     */
    get expiryTimeMillis(): number {
        return this._expiry_time_millis;
    }

    static expiryTimeMillisValidate(expiryTimeMillis: number): boolean {
        return typeof expiryTimeMillis === 'number';
    }

    constructor(props: IBackupDownloadLink) {
        this._backup_id = props.backup_id.trim();
        this._download_link = props.download_link.trim();
        this._expiry_time = props.expiry_time.trim();
        this._expiry_time_millis = props.expiry_time_millis;
    }

    serialize(): IBackupDownloadLink {
        const data: IBackupDownloadLink = {
            backup_id: this._backup_id,
            download_link: this._download_link,
            expiry_time: this._expiry_time,
            expiry_time_millis: this._expiry_time_millis,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            backup_id: typeof this._backup_id === 'string' && !!this._backup_id.trim(),
            download_link: typeof this._download_link === 'string' && !!this._download_link.trim(),
            expiry_time: typeof this._expiry_time === 'string' && !!this._expiry_time.trim(),
            expiry_time_millis: typeof this._expiry_time_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IBackupDownloadLink): BackupDownloadLink {
        return new BackupDownloadLink(props);
    }

    readonly keys: { [key: string]: string } = {
        backupId: 'backup_id',
        downloadLink: 'download_link',
        expiryTime: 'expiry_time',
        expiryTimeMillis: 'expiry_time_millis',
        }
;

    mergeDeepWith(props: Partial<BackupDownloadLink>): BackupDownloadLink {
        const updateData: Partial<IBackupDownloadLink> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof BackupDownloadLink) => {
            const updateKey = this.keys[key] as keyof IBackupDownloadLink;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IBackupDownloadLink, keyof IBackupDownloadLink>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new BackupDownloadLink({ ...this.serialize(), ...updateData });
    }
}
