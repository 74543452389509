import React, { FC, useEffect } from 'react';
import { Layout } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { useTitle } from 'Hooks';
import { getPtrRecords } from 'Actions/reverseDns';
import Tenant from 'Entities/Tenant';
import { Store } from 'Store';
import { useIntl, EmptyPageLayout } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import Header from './Header';
import Project from './Project';
import s from './ServerPtr.module.pcss';

interface PtrProps {
    tenantList: Tenant[];
    serversLength: number;
}

const { Content } = Layout;

const Ptr: FC<PtrProps> = ({ tenantList, serversLength }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('dns_zones_ptr_page_title'));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPtrRecords());
    }, []);

    return (
        <Layout>
            <Header />
            <Content className={theme.content.content}>
                {tenantList?.length > 0 && serversLength > 0 ? (
                    <>
                        <div className={s.desc}>
                            {intl.getMessage('ptr_desc')}
                        </div>
                        {tenantList.map((t) => (
                            <Project
                                name={t.description}
                                id={t.id}
                                key={t.id}
                            />
                        ))}
                    </>
                ) : (
                    <EmptyPageLayout
                        title={intl.getMessage('ptr_empty')}
                        desc={intl.getMessage('ptr_empty_desc')}
                        linkText={intl.getMessage('create_server')}
                        link={RoutePath.NewServer}
                    />
                )}
            </Content>
        </Layout>
    );
};

const selectTenantList = (store: Store) => store.tenant;
const selectServersLength = (store: Store) => store.server.size;

const tenantList = createSelector(
    selectTenantList,
    (map) => Array.from(map.values()),
);

const mapStateToProps = (store: Store) => ({
    tenantList: tenantList(store),
    serversLength: selectServersLength(store),
});

export default connect(mapStateToProps)(Ptr);
