import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link } from 'Common';
import Server from 'Entities/Server';
import { RoutePath } from 'Lib/helpers/routes';
import { frequencyMessage, getGb } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import s from './ServerInfo.module.pcss';

interface ServerInfoProps {
    server: Server;
    loading: boolean;
    serverPage?: boolean;
}

const ServerInfo: FC<ServerInfoProps> = ({ server, loading, serverPage }) => {
    const intl = useIntl();
    const {
        backupPolicy: {
            quantity,
            frequency,
        },
        snapshotsCount,
        memoryMib,
        cpuCores,
        diskGib,
        tariff,
        id,
        bandwidthMbits,
    } = server;

    const memoryValue = getGb(memoryMib);
    const memoryMessage = `${intl.getMessage('value_gb', { value: memoryValue })} RAM`;
    const coresMessage = intl.getPlural('core', cpuCores);
    const diskMessage = `${intl.getMessage('value_gb', { value: diskGib })} SSD`;
    const trafficMessage = tariff?.includedTrafficGib ? `${intl.getMessage('traffic_gb', { value: tariff.includedTrafficGib })}` : '';
    const bandwidthMessage = bandwidthMbits ? `${bandwidthMbits} ${intl.getMessage('unit_mbit_s')}` : '';

    return (
        <div className={cn(s.info, { [s.info_server]: serverPage })}>
            <div className={s.row}>
                <div className={s.label}>
                    {intl.getMessage('tariff')}
                </div>
                <div className={s.column}>
                    <Link
                        to={RoutePath.ServerTariff}
                        props={{ serverId: id }}
                        className={cn(
                            theme.link.link,
                            s.tariff,
                        )}
                        disabled={loading}
                    >
                        {tariff?.name}
                    </Link>

                    <span className={s.messages}>
                        <span className={s.message}>
                            {coresMessage}
                        </span>
                        <span className={s.message}>
                            {memoryMessage}
                        </span>
                        <span className={s.message}>
                            {diskMessage}
                        </span>
                        {trafficMessage && (
                            <span className={s.message}>
                                {trafficMessage}
                            </span>
                        )}
                        {serverPage && bandwidthMessage && (
                            <span className={s.message}>
                                {bandwidthMessage}
                            </span>
                        )}
                    </span>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.label}>
                    {intl.getMessage('backup')}
                </div>
                <div className={s.column}>
                    <Link
                        to={RoutePath.ServerBackup}
                        props={{ serverId: server.id }}
                        className={theme.link.link}
                        disabled={loading}
                    >
                        <span className={s.desktop}>
                            {intl.getPlural('count_copy_full', quantity)},&nbsp;
                            {intl.getPlural('count_hour_full', frequencyMessage[frequency])}
                        </span>
                        <span className={s.mobile}>
                            {intl.getPlural('count_copy', quantity)},&nbsp;
                            {intl.getPlural('count_hour', frequencyMessage[frequency])}
                        </span>
                    </Link>
                </div>
            </div>
            <div className={s.row}>
                <div className={s.label}>
                    {intl.getMessage('snapshots')}
                </div>
                <div className={s.column}>
                    <Link
                        to={RoutePath.Snapshots}
                        props={{ serverId: server.id }}
                        className={theme.link.link}
                        disabled={loading}
                    >
                        {intl.getPlural('snapshots_count', snapshotsCount)}
                    </Link>
                </div>
            </div>
            {serverPage && (
                <>
                    <div className={s.row}>
                        <div className={s.link}>
                            <Link
                                to={RoutePath.ServerAccess}
                                props={{ serverId: server.id }}
                                className={theme.link.link}
                                disabled={loading}
                            >
                                {intl.getMessage('new_server_access')}
                            </Link>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.link}>
                            <Link
                                to={RoutePath.Console}
                                props={{ serverId: server.id }}
                                className={theme.link.link}
                                disabled={loading}
                            >
                                {intl.getMessage('console')}
                            </Link>
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.link}>
                            <Link
                                to={RoutePath.ServerHistory}
                                props={{ serverId: server.id }}
                                className={theme.link.link}
                                disabled={loading}
                            >
                                {intl.getMessage('history')}
                            </Link>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ServerInfo;
