import React, { FC, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import registerApi from 'Apis/registration';
import { checkAuth } from 'Actions/user';
import { useIntl, Loader } from 'Common';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { errorChecker, saveCookieToken } from 'Lib/helpers/utils';
import { setAuthToken } from 'Lib/helpers/authHelper';

import { ErrorConfirmLink } from '../Errors';

const RegistrationConfirm: FC = () => {
    const intl = useIntl();
    const [error, setError] = useState(false);
    const dispatch = useDispatch();
    const { currentLocale: lang } = intl;
    const match = useRouteMatch<{ code: string }>();
    const history = useHistory();
    const { code } = match.params;

    useEffect(() => {
        let canUpdate = true;
        if (!code) {
            history.push(linkPathBuilder(lang, RoutePath.Root));
        } else {
            const loader = async () => {
                const response = await registerApi.confirmRegistration({ code });
                const { result } = errorChecker<{ token: string; cookie: string }>(response);
                if (result) {
                    AppConfigStorage.setEmailConfirmed(true);
                    if (result.cookie) {
                        saveCookieToken(result.cookie);
                    }
                    setAuthToken(result.token);
                    dispatch(checkAuth());
                    history.push(linkPathBuilder(lang, RoutePath.Dashboard));
                    return;
                }
                if (canUpdate) {
                    setError(true);
                }
            };
            loader();
        }
        return () => {
            canUpdate = false;
        };
    }, []);

    return error ? (
        <ErrorConfirmLink />
    ) : (
        <Loader full />
    );
};

export default RegistrationConfirm;
