import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Layout } from 'antd';
import cn from 'classnames';

import { InnerPageLayout, Loader } from 'Common';
import { DashboardHeader } from 'Ui/headers';
import { Routes, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import { useRouteMatch } from 'react-router-dom';
import { LOADER_PAGE_TIMEOUT } from 'Lib/helpers/consts';

import Sidebar from 'Components/Sidebar';

interface LoaderPageProps {
    page?: RoutePath;
    visible?: boolean;
    withHeader?: boolean;
}

const LoaderPageLayout: FC<LoaderPageProps> = ({ visible, withHeader }) => {
    const [loaderVisible, setLoaderVisible] = useState(visible);
    const match = useRouteMatch();
    const page = match.path;

    const fullPage = page === Routes[RoutePath.NewServer]
        || page === Routes[RoutePath.NewServerFromTenant]
        || page === Routes[RoutePath.NewProject]
        || page === Routes[RoutePath.NewServerFromTenant]
        || page === Routes[RoutePath.NewTrigger]
        || page === Routes[RoutePath.NewTrigger]
        || page === Routes[RoutePath.Trial]
        || page === Routes[RoutePath.PartnerOrderPayment]
        || page === Routes[RoutePath.PartnerTransferPayment]
        || page === Routes[RoutePath.EditTrigger]
        || page === Routes[RoutePath.CreateTicket]
        || page === Routes[RoutePath.ClientsOrderPayment]
        || page === Routes[RoutePath.ClientsTransferPayment]
        || page === Routes[RoutePath.AddFunds]
        || page === Routes[RoutePath.Onboarding]
        || page === Routes[RoutePath.OnboardingAddFunds]
        || page === Routes[RoutePath.OnboardingRequisites];
    const listPage = page === Routes[RoutePath.Notifications]
        || page === Routes[RoutePath.ServerHistory]
        || page === Routes[RoutePath.Documents];
    const smallListPage = page === Routes[RoutePath.PublicKeys]
        || page === Routes[RoutePath.DnsZonesRecords];
    const blocksPage = page === Routes[RoutePath.Tickets]
        || page === Routes[RoutePath.TicketChat]
        || page === Routes[RoutePath.DnsZones]
        || page === Routes[RoutePath.Profile]
        || page === Routes[RoutePath.Clients]
        || page === Routes[RoutePath.Partner]
        || page === Routes[RoutePath.Billing];

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaderVisible(true);
        }, LOADER_PAGE_TIMEOUT);
        return () => clearTimeout(timer);
    }, []);

    const getContent = () => {
        if (blocksPage) {
            return (
                <>
                    <div className={cn(theme.skeleton.block, theme.skeleton.medium)} />
                    <div className={cn(theme.skeleton.block, theme.skeleton.medium)} />
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                </>
            );
        }

        if (listPage) {
            return (
                <>
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                </>
            );
        }

        if (smallListPage) {
            return (
                <>
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                    <div className={theme.skeleton.block} />
                </>
            );
        }

        if (fullPage) {
            return <Loader full />;
        }

        if (page === Routes[RoutePath.Dashboard]) {
            return (
                <Row gutter={[24, 0]}>
                    <Col span={24} sm={12}>
                        <div className={cn(theme.skeleton.block, theme.skeleton.dashboard)} />
                    </Col>
                    <Col span={24} sm={12}>
                        <div className={cn(theme.skeleton.block, theme.skeleton.dashboard)} />
                    </Col>
                    <Col span={24} sm={12}>
                        <div className={cn(theme.skeleton.block, theme.skeleton.big)} />
                    </Col>
                    <Col span={24} sm={12}>
                        <div className={cn(theme.skeleton.block, theme.skeleton.big)} />
                    </Col>
                </Row>
            );
        }

        if (
            page === Routes[RoutePath.Expenses]
            || page === Routes[RoutePath.PartnerTransferPayment]
        ) {
            return (
                <>
                    <div className={theme.skeleton.block} />
                    <div className={cn(theme.skeleton.block, theme.skeleton.expenses)} />
                </>
            );
        }

        if (page === Routes[RoutePath.Triggers]) {
            return (
                <>
                    <div className={cn(theme.skeleton.block, theme.skeleton.trigger)} />
                    <div className={cn(theme.skeleton.block, theme.skeleton.trigger)} />
                </>
            );
        }

        return (
            <>
                <div className={cn(theme.skeleton.block, theme.skeleton.medium)} />
                <div className={cn(theme.skeleton.block, theme.skeleton.medium)} />
                <div className={cn(theme.skeleton.block, theme.skeleton.medium)} />
            </>
        );
    };

    if (!fullPage && loaderVisible && withHeader) {
        return (
            <Layout>
                <Sidebar loading />
                <InnerPageLayout
                    header={<DashboardHeader loading />}
                    className={theme.content.with_actions}
                >
                    {getContent()}
                </InnerPageLayout>
            </Layout>
        );
    }

    if (loaderVisible) {
        return getContent();
    }

    return null;
};

export default LoaderPageLayout;
