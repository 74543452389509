import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Switch, useIntl, Button, mediumText, Link, Input } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import PayerInfo from 'Entities/PayerInfo';
import Profile from 'Entities/Profile';
import { updateAutoTopUpSettings } from 'Actions/account';
import AccountDetails from 'Entities/AccountDetails';
import { ClientType } from 'Entities/ClientType';
import { IAccountAutoTopUpSettingsUpdate } from 'Entities/AccountAutoTopUpSettingsUpdate';
import { RecurringSubscriptionRebillSettings } from 'Entities/RecurringSubscriptionRebillSettings';
import { currencySymbol } from 'Lib/helpers/consts';
import theme from 'Lib/theme';
import { Store } from 'Store';

import CancelAutoTopUp from './CancelAutoTopUp';
import Option from './Option';
import s from './AutoTopUp.module.pcss';

export type FormValues = {
    active: boolean;
    minBalance: number;
    topUpAmountCard: number;
    topUpAmountPartner: number;
    topUpAmountReseller: number;
    topUpSettingsCard: RecurringSubscriptionRebillSettings;
    topUpSettingsPartner: RecurringSubscriptionRebillSettings;
    topUpSettingsReseller: RecurringSubscriptionRebillSettings;
};

interface AutoTopUpFormProps {
    details: AccountDetails;
    profile: Profile;
    payer: PayerInfo;
    setOpenEditModal?: (state: boolean) => void;
}

const selectCardsSize = (store: Store) => store.account.cards;

const selector = createSelector([selectCardsSize], (cards) => ({ cardsSize: cards ? cards.size : 0 }));

const AutoTopUpForm: FC<AutoTopUpFormProps> = ({ details, profile, payer, setOpenEditModal }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const { autoTopUpSettings, clientType, accountLimits } = details;
    const { reseller } = profile;
    const autoInvoice = clientType === ClientType.CORPORATION
        || clientType === ClientType.SOLE_TRADER;

    const { cardsSize } = useSelector(selector);

    const requisitesLink = (text: string) => {
        if (setOpenEditModal) {
            return (
                <Button
                    type="link"
                    onClick={() => setOpenEditModal(true)}
                    className={s.link}
                >
                    {text}
                </Button>
            );
        }
        return (
            <Link
                to={RoutePath.ProfileEditModal}
                props={{ openEditModal: true }}
            >
                {text}
            </Link>
        );
    };

    let labels = {
        title: intl.getMessage('billing_auto_top_up_title'),
        label: intl.getMessage('billing_auto_top_up_label'),
        badge: intl.getMessage('connected'),
        topUpAmount: intl.getMessage('billing_auto_top_up_from_card'),
        minBalance: intl.getMessage('billing_auto_top_up_min_balance'),
        turnedOff: intl.getMessage('billing_auto_top_up_turned_off'),
        titleModal: intl.getMessage('billing_auto_top_up_cancel_title'),
        descModal: intl.getMessage('billing_auto_top_up_cancel_desc'),
        desc: '',
    };
    if (autoInvoice) {
        labels = {
            title: intl.getMessage('billing_auto_invoice_title'),
            label: intl.getMessage('billing_auto_invoice_label'),
            badge: intl.getMessage('active'),
            topUpAmount: intl.getMessage('billing_auto_invoice_amount'),
            minBalance: intl.getMessage('billing_auto_invoice_min_balance'),
            turnedOff: intl.getMessage('billing_auto_invoice_turned_off'),
            titleModal: intl.getMessage('billing_auto_invoice_cancel_title'),
            descModal: intl.getMessage('billing_auto_invoice_cancel_desc'),
            desc: (payer.corporationInfo || payer.soleTraderInfo)?.accountingEmail
                ? intl.getMessage('billing_auto_invoice_top_up_accounting_email', { email: (payer.corporationInfo || payer.soleTraderInfo)!.accountingEmail || '', link: requisitesLink, b: mediumText })
                : intl.getMessage('billing_auto_invoice_top_up_desc', { email: profile.email, link: requisitesLink, b: mediumText }),
        };
    }

    const validAmount = (value: number) => {
        return value <= accountLimits.maxTopUpAmount
            && value >= accountLimits.minTopUpAmount;
    };

    const validMinBalance = (value: number) => {
        return value <= accountLimits.maxTopUpBalance
            && value >= accountLimits.minTopUpBalance;
    };

    const getBalanceError = (balance: number) => {
        if (balance > accountLimits.maxTopUpBalance) {
            return intl.getMessage('billing_auto_top_up_max_balance_error', { value: accountLimits.maxTopUpBalance });
        }

        if (balance < accountLimits.minTopUpBalance) {
            return intl.getMessage('billing_auto_top_up_min_balance_error', { value: accountLimits.minTopUpBalance });
        }

        return '';
    };

    const getAmountError = (amount: number) => {
        if (amount > accountLimits.maxTopUpAmount) {
            return intl.getMessage('billing_auto_top_up_max_amount_error', { value: accountLimits.maxTopUpAmount });
        }

        if (amount < accountLimits.minTopUpAmount) {
            return intl.getMessage('billing_auto_top_up_min_amount_error', { value: accountLimits.minTopUpAmount });
        }

        return '';
    };

    const getFormErrors = (
        values: FormValues,
    ) => {
        const errors = [];

        if (
            values.topUpSettingsCard === RecurringSubscriptionRebillSettings.CHARGE_AMOUNT
            && !validAmount(values.topUpAmountCard)
        ) {
            errors.push({ topUpAmountCard: getAmountError(values.topUpAmountCard) });
        }

        if (
            values.topUpSettingsPartner === RecurringSubscriptionRebillSettings.CHARGE_AMOUNT
            && !validAmount(values.topUpAmountPartner)
        ) {
            errors.push({ topUpAmountPartner: getAmountError(values.topUpAmountPartner) });
        }

        if (
            values.topUpSettingsReseller === RecurringSubscriptionRebillSettings.CHARGE_AMOUNT
            && !validAmount(values.topUpAmountReseller)
        ) {
            errors.push({ topUpAmountReseller: getAmountError(values.topUpAmountReseller) });
        }

        if (!validMinBalance(values.minBalance)) {
            errors.push({ minBalance: getBalanceError(values.minBalance) });
        }

        return errors;
    };

    const onSubmit = (
        values: FormValues,
        helpers: FormikHelpers<FormValues>,
    ) => {
        const formErrors = getFormErrors(values);

        if (formErrors.length > 0) {
            formErrors.forEach((error) => {
                helpers.setErrors(error);
            });
            helpers.setSubmitting(false);
            return;
        }

        const settings: IAccountAutoTopUpSettingsUpdate = {
            active: values.active,
            min_balance: values.minBalance,
            top_up_amount_card: values.topUpAmountCard,
            top_up_amount_partner: values.topUpAmountPartner,
            top_up_amount_reseller: values.topUpAmountReseller,
            top_up_settings_card: values.topUpSettingsCard,
            top_up_settings_partner: values.topUpSettingsPartner,
            top_up_settings_reseller: values.topUpSettingsReseller,
        };

        dispatch(updateAutoTopUpSettings([settings], {
            result: () => {
                helpers.setSubmitting(false);
            },
            error: () => {
                helpers.setSubmitting(false);
            },
        }));
    };

    const getInitialCardSettings = (setting: RecurringSubscriptionRebillSettings) => {
        if (autoInvoice) {
            return RecurringSubscriptionRebillSettings.CHARGE_AMOUNT;
        }

        return setting;
    };

    const initialValues = {
        active: autoTopUpSettings.active,
        minBalance: autoTopUpSettings.minBalance,
        topUpAmountCard: autoTopUpSettings.topUpAmountCard,
        topUpAmountPartner: autoTopUpSettings.topUpAmountPartner,
        topUpAmountReseller: autoTopUpSettings.topUpAmountReseller,
        topUpSettingsCard: getInitialCardSettings(autoTopUpSettings.topUpSettingsCard),
        topUpSettingsPartner: autoTopUpSettings.topUpSettingsPartner,
        topUpSettingsReseller: autoTopUpSettings.topUpSettingsReseller,
    };

    return (
        <>
            <div className={s.title}>
                {labels.title}
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {({
                    setFieldValue,
                    handleSubmit,
                    handleReset,
                    errors,
                    values,
                    isSubmitting,
                    dirty,
                }) => {
                    const {
                        active,
                        minBalance,
                        topUpAmountCard,
                        topUpAmountPartner,
                        topUpAmountReseller,
                        topUpSettingsCard,
                        topUpSettingsPartner,
                        topUpSettingsReseller,
                    } = values;

                    const everyOptionTurnedOff = [
                        topUpSettingsCard,
                        topUpSettingsPartner,
                        topUpSettingsReseller,
                    ].every((setting) => setting === RecurringSubscriptionRebillSettings.DISABLED);

                    return (
                        <form
                            onSubmit={handleSubmit}
                            className={cn(
                                theme.card.card,
                                theme.card.rounded,
                                { [theme.card.active]: dirty && active },
                            )}
                            noValidate
                        >
                            <div className={cn(s.header, { [s.header_invoice]: autoInvoice })}>
                                <Switch
                                    id="auto"
                                    checked={active}
                                    labelType="large"
                                    handleChange={(e) => {
                                        setFieldValue('active', e.target.checked);
                                        if (!e.target.checked) {
                                            setShowCancelModal(true);
                                        }
                                    }}
                                >
                                    {labels.label}
                                </Switch>
                                {active ? (
                                    <div
                                        className={cn(
                                            theme.Badge.badge,
                                            theme.Badge.blue,
                                            s.badge,
                                        )}
                                    >
                                        {labels.badge}
                                    </div>
                                ) : (
                                    <div
                                        className={cn(
                                            theme.Badge.badge,
                                            theme.Badge.yellow,
                                            s.badge,
                                        )}
                                    >
                                        {intl.getMessage('recommend')}
                                    </div>
                                )}
                            </div>
                            {active ? (
                                <>
                                    <div className={s.row}>
                                        <div className={s.input}>
                                            <div className={s.label}>
                                                {labels.minBalance}
                                            </div>
                                            <Input
                                                type="number"
                                                size="small"
                                                name="minBalance"
                                                className="input input_small"
                                                suffix={currencySymbol.RUB}
                                                value={minBalance}
                                                onChange={(v) => {
                                                    if (Number(v)) {
                                                        setFieldValue('minBalance', Number(v));
                                                    } else {
                                                        setFieldValue('minBalance', v);
                                                    }
                                                }}
                                                validate={() => validMinBalance(minBalance)}
                                                error={!!errors.minBalance}
                                                wrapperClassName={s.group}
                                                noValidationIcon
                                            />
                                        </div>
                                        {errors.minBalance && (
                                            <div className={s.error}>
                                                {errors.minBalance}
                                            </div>
                                        )}
                                    </div>

                                    {!autoInvoice && (
                                        <>
                                            <div className={s.divider} />

                                            <div className={s.subtitle}>
                                                {intl.getMessage('billing_auto_top_up_order')}

                                                {everyOptionTurnedOff && (
                                                    <span className={s.error}>
                                                        {intl.getMessage('billing_auto_top_up_options')}
                                                    </span>
                                                )}
                                            </div>

                                            <Option
                                                title={intl.getMessage('billing_auto_top_up_from_partner')}
                                                setting={topUpSettingsPartner}
                                                amount={topUpAmountPartner}
                                                initialAmount={autoTopUpSettings.topUpAmountPartner}
                                                settingField="topUpSettingsPartner"
                                                amountField="topUpAmountPartner"
                                                errorMessage={errors.topUpAmountPartner}
                                                validAmount={validAmount}
                                                setFieldValue={setFieldValue}
                                            />

                                            {reseller && (
                                                <Option
                                                    title={intl.getMessage('billing_auto_top_up_from_reseller')}
                                                    setting={topUpSettingsReseller}
                                                    amount={topUpAmountReseller}
                                                    initialAmount={autoTopUpSettings.topUpAmountReseller}
                                                    settingField="topUpSettingsReseller"
                                                    amountField="topUpAmountReseller"
                                                    errorMessage={errors.topUpAmountReseller}
                                                    validAmount={validAmount}
                                                    setFieldValue={setFieldValue}
                                                />
                                            )}
                                        </>
                                    )}

                                    <Option
                                        title={labels.topUpAmount}
                                        setting={topUpSettingsCard}
                                        amount={topUpAmountCard}
                                        initialAmount={autoTopUpSettings.topUpAmountCard}
                                        settingField="topUpSettingsCard"
                                        amountField="topUpAmountCard"
                                        errorMessage={errors.topUpAmountCard}
                                        validAmount={validAmount}
                                        setFieldValue={setFieldValue}
                                        autoInvoice={autoInvoice}
                                        cardForm
                                        disabledOption={cardsSize === 0 && !autoInvoice}
                                    />
                                    {autoInvoice && labels.desc && (
                                        <div className={s.desc}>
                                            {labels.desc}
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div className={cn(s.desc, s.desc_off)}>
                                    {labels.turnedOff}
                                </div>
                            )}
                            {dirty && active && (
                                <div className={s.actions}>
                                    <Button
                                        id={autoInvoice ? 'set_autopay_corp' : 'set_autopay'}
                                        type="primary"
                                        htmlType="submit"
                                        size="medium"
                                        disabled={isSubmitting || everyOptionTurnedOff}
                                        inGroup
                                    >
                                        {intl.getMessage('apply')}
                                    </Button>
                                    <Button
                                        type="link"
                                        size="medium"
                                        onClick={handleReset}
                                    >
                                        {intl.getMessage('cancel')}
                                    </Button>
                                </div>
                            )}
                            {showCancelModal && (
                                <CancelAutoTopUp
                                    visible
                                    handleClose={() => {
                                        setShowCancelModal(false);
                                        setFieldValue('active', true);
                                    }}
                                    handleSubmit={() => {
                                        handleSubmit();
                                        setShowCancelModal(false);
                                    }}
                                    titleModal={labels.titleModal}
                                    descModal={labels.descModal}
                                />
                            )}
                        </form>
                    );
                }}
            </Formik>

        </>
    );
};

export default AutoTopUpForm;
