import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import { useIntl } from 'Common';

import s from './FormGroup.module.pcss';

interface FormGroupProps {
    title: string;
    desc?: string;
    link?: ReactNode;
    price?: string;
    backup?: boolean;
}

const FormGroup: FC<FormGroupProps> = ({
    title,
    desc,
    link,
    price,
    children,
    backup,
}) => {
    const intl = useIntl();

    return (
        <div className={s.group}>
            <div className={s.card}>
                <div className={cn(s.title, { [s.title_backup]: backup })}>
                    {title}
                    {link && (
                        <div className={s.link}>
                            {link}
                        </div>
                    )}
                </div>
                {desc && (
                    <div className={s.desc}>
                        {desc}
                    </div>
                )}
                <div className={s.content}>
                    {children}
                </div>
            </div>
            {price && (
                <div className={s.footer}>
                    <span className={s.total}>
                        {intl.getMessage('total_cost')}
                    </span>
                    {price}
                </div>
            )}
        </div>
    );
};

export default FormGroup;
