import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, Button } from 'Common';
import { ISavedUserAccount } from 'Actions/user';
import theme from 'Lib/theme';

import s from './Profile.module.pcss';

interface UserAccountProps {
    currentAccount: boolean;
    userAccount: ISavedUserAccount;
}

const UserAccount: FC<UserAccountProps> = ({ currentAccount, userAccount }) => {
    const intl = useIntl();
    const [hidden, setHidden] = useState(false);

    const onHide = () => {
        setHidden(true);
        AppConfigStorage.removeAccount(userAccount);
    };

    const onShow = () => {
        setHidden(false);
        AppConfigStorage.saveAccount(userAccount);
    };

    const cancelButton = (text: string) => (
        <Button
            type="link"
            className={s.cancel}
            onClick={(e) => {
                e.stopPropagation();
                onShow();
            }}
        >
            {text}
        </Button>
    );

    const showDeleteButton = !currentAccount
        && !hidden
        && MAIN_TOKEN !== userAccount.token;

    return (
        <>
            <div className={s.accountOptionWrapper}>
                <span className={s.email}>{userAccount.email}</span>
                {hidden ? (
                    <div className={s.funds}>
                        {intl.getMessage('account_hidden', { a: cancelButton })}
                    </div>
                ) : (
                    <div className={s.funds}>
                        {userAccount.authorized || currentAccount ? `#${userAccount.id}` : intl.getMessage('unauthorized')}
                    </div>
                )}
            </div>
            <div className={s.hide}>
                {showDeleteButton && (
                    <Button
                        type="icon"
                        icon="delete"
                        className={cn(
                            s.remove,
                            theme.button.tiny,
                            theme.button.bordersWhite,
                        )}
                        onClick={(e) => {
                            e.stopPropagation();
                            onHide();
                        }}
                        title={intl.getMessage('title_remove_from_list')}
                        tooltipClassName="tooltip_dropdown"
                    />
                )}
            </div>
        </>
    );
};

export default UserAccount;
