import PartnerApi from 'Apis/partners';
import PartnerDetails from 'Entities/PartnerDetails';
import PartnerStats from 'Entities/PartnerStats';
import PartnerPayment from 'Entities/PartnerPayment';
import PartnerComparativeDetails from 'Entities/PartnerComparativeDetails';
import PartnerFinances from 'Entities/PartnerFinances';

import { actionCreator } from '.';

export enum PartnerActions {
    WithdrawalPayment = 'WithdrawalPayment',
    TransferPayment = 'TransferPayment',
    GetPartnerDetails = 'GetPartnerDetails',
    GetPartnerStats = 'GetPartnerStats',
    GetPartnerProcessingPayments = 'GetPartnerProcessingPayments',
    GetPartnerCompletePayments = 'GetPartnerCompletePayments',
    RegisterPartner = 'RegisterPartner',
    SavePartnerDetails = 'SavePartnerDetails',
    SavePartnerStats = 'SavePartnerStats',
    SavePartnerProcessingPayments = 'SavePartnerProcessingPayments',
    ClearPartnerCompletePayments = 'ClearPartnerCompletePayments',
    SavePartnerCompletePayments = 'SavePartnerCompletePayments',
    GetPartnerComparativeDetails = 'GetPartnerComparativeDetails',
    SavePartnerComparativeDetails = 'SavePartnerComparativeDetails',
}
type WithdrawalPayment = Parameters<typeof PartnerApi.doPartnerWithdrawalPayment>;
export const withdrawalPayment = actionCreator<WithdrawalPayment>(PartnerActions.WithdrawalPayment);

type TransferPayment = Parameters<typeof PartnerApi.doPartnerTransferPayment>;
export const transferPayment = actionCreator<TransferPayment>(PartnerActions.TransferPayment);

type GetPartnerDetails = Parameters<typeof PartnerApi.getPartnerDetails>;
export const getPartnerDetails = actionCreator<GetPartnerDetails>(PartnerActions.GetPartnerDetails);

export const savePartnerDetails = actionCreator<PartnerDetails>(PartnerActions.SavePartnerDetails);

type GetPartnerStats = Parameters<typeof PartnerApi.getPartnerStats>;
export const getPartnerStats = actionCreator<GetPartnerStats>(PartnerActions.GetPartnerStats);

export const savePartnerStats = actionCreator<PartnerStats[]>(PartnerActions.SavePartnerStats);

type GetPartnerProcessingPayments = Parameters<typeof PartnerApi.getPartnerProcessingPayments>;
export const getPartnerProcessingPayments = actionCreator<GetPartnerProcessingPayments>(
    PartnerActions.GetPartnerProcessingPayments,
);

type GetPartnerCompletePayments = Parameters<typeof PartnerApi.getPartnerCompletePayments>;
export const getPartnerCompletePayments = actionCreator<GetPartnerCompletePayments>(
    PartnerActions.GetPartnerCompletePayments,
);

export const savePartnerProcessingPayments = actionCreator<PartnerPayment[]>(
    PartnerActions.SavePartnerProcessingPayments,
);
export const savePartnerCompletePayments = actionCreator<PartnerFinances>(
    PartnerActions.SavePartnerCompletePayments,
);

export const clearPartnerCompletePayments = actionCreator(
    PartnerActions.ClearPartnerCompletePayments,
);

type RegisterPartner = Parameters<typeof PartnerApi.registerPartner>;
export const registerPartner = actionCreator<RegisterPartner>(PartnerActions.RegisterPartner);

type GetPartnerComparativeDetails = Parameters<
    typeof PartnerApi.getPartnerComparativeDetails
>;
export const getPartnerComparativeDetails = actionCreator<GetPartnerComparativeDetails>(
    PartnerActions.GetPartnerComparativeDetails,
);
export const savePartnerComparativeDetails = actionCreator<PartnerComparativeDetails>(
    PartnerActions.SavePartnerComparativeDetails,
);
