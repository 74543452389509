import { LoginType } from './LoginType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IProfileLoginAttempt {
    ip: string;
    login_type: LoginType;
    successful: boolean;
    time_added: string;
    time_added_millis: number;
}

export default class ProfileLoginAttempt {
    readonly _ip: string;

    /**
     * Description: Client's ip address for the login attempt
     * Example: 127.0.0.1
     */
    get ip(): string {
        return this._ip;
    }

    static ipValidate(ip: string): boolean {
        return typeof ip === 'string' && !!ip.trim();
    }

    readonly _login_type: LoginType;

    get loginType(): LoginType {
        return this._login_type;
    }

    static loginTypeValidate(loginType: LoginType): boolean {
        return Object.keys(LoginType).includes(loginType);
    }

    readonly _successful: boolean;

    /**
     * Description: If login attempt was successful
     * Example: true
     */
    get successful(): boolean {
        return this._successful;
    }

    static successfulValidate(successful: boolean): boolean {
        return typeof successful === 'boolean';
    }

    readonly _time_added: string;

    /** */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Login attempt date and time, UTC millis
     * Example: 1593064584178
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    constructor(props: IProfileLoginAttempt) {
        this._ip = props.ip.trim();
        this._login_type = props.login_type;
        this._successful = props.successful;
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
    }

    serialize(): IProfileLoginAttempt {
        const data: IProfileLoginAttempt = {
            ip: this._ip,
            login_type: this._login_type,
            successful: this._successful,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            ip: typeof this._ip === 'string' && !!this._ip.trim(),
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
            successful: typeof this._successful === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IProfileLoginAttempt): ProfileLoginAttempt {
        return new ProfileLoginAttempt(props);
    }

    readonly keys: { [key: string]: string } = {
        ip: 'ip',
        loginType: 'login_type',
        successful: 'successful',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        }
;

    mergeDeepWith(props: Partial<ProfileLoginAttempt>): ProfileLoginAttempt {
        const updateData: Partial<IProfileLoginAttempt> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ProfileLoginAttempt) => {
            const updateKey = this.keys[key] as keyof IProfileLoginAttempt;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IProfileLoginAttempt, keyof IProfileLoginAttempt>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ProfileLoginAttempt({ ...this.serialize(), ...updateData });
    }
}
