import React, { FC, useState } from 'react';
import cn from 'classnames';

import { Link, Icon, Checkbox, ServerIndicator } from 'Common';
import { COPY_BUTTON_TIMEOUT } from 'Lib/helpers/consts';
import { RoutePath } from 'Lib/helpers/routes';
import { getServerIcon } from 'Lib/helpers/utils';
import OperationProgress from 'Entities/OperationProgress';
import Server from 'Entities/Server';
import { CopyButton } from 'Ui/CopyButton';

import s from './ServerName.module.pcss';

interface ServerNameProps {
    server: Server;
    disabled?: boolean;
    compact?: boolean;
    project?: boolean;
    operationProgress?: OperationProgress;
    checked?: boolean;
    visibleCheckbox?: boolean;
    selectServer?: (s: Server) => void;
}

const ServerName: FC<ServerNameProps> = ({
    server,
    checked,
    visibleCheckbox,
    disabled,
    compact,
    project,
    operationProgress,
    selectServer,
}) => {
    const { id, tenantId, name, distribution } = server;
    const cleanDistributionName = distribution.description.replace(' x64', '');
    const [copyActive, setCopyActive] = useState(false);
    const handleCopy = () => {
        setCopyActive(true);
        setTimeout(() => {
            setCopyActive(false);
        }, COPY_BUTTON_TIMEOUT);
        navigator.clipboard.writeText(name);
    };

    const icon = (
        <Icon icon={getServerIcon(distribution.type)} className={s.icon} />
    );

    const content = (
        <>
            {icon}
            <div className={s.nameWrap}>
                <div className={s.title} title={name}>
                    {name}
                </div>
                {!disabled && (
                    <div className={cn(s.subtitles, { [s.subtitles_compact]: compact })}>
                        <div className={cn(s.subtitle, s.subtitle_id)}>
                            #{id}
                        </div>
                        <div className={cn(s.subtitle, s.subtitle_desc)}>
                            {cleanDistributionName}
                        </div>
                    </div>
                )}
            </div>
        </>
    );

    if (disabled) {
        return (
            <div className={cn(s.name, s.disable)}>
                {content}
            </div>
        );
    }

    return (
        <div
            className={cn(
                s.linkWrap,
                s.link,
                { [s.compact]: compact },
                { [s.project]: project },
                { [s.withCheckbox]: selectServer },
                { [s.visibleCheckbox]: visibleCheckbox },
            )}
        >
            {selectServer && (
                <div className={s.checkbox}>
                    <Checkbox
                        name="delete"
                        checked={!!checked}
                        handleChange={() => selectServer(server)}
                    />
                </div>
            )}
            <Link
                to={RoutePath.Server}
                props={{ serverId: id, tenantId }}
                className={s.name}
                stop
            >
                {project && (
                    <ServerIndicator state={server.state} progress={operationProgress} />
                )}
                {content}
            </Link>
            <div className={s.copy}>
                <CopyButton
                    background="cloud"
                    active={copyActive}
                    copy={handleCopy}
                />
            </div>
        </div>
    );
};

export default ServerName;
