import ResellersApi from 'Apis/resellers';
import ResellerDetails from 'Entities/ResellerDetails';
import ResellerClient from 'Entities/ResellerClient';
import ResellerPayment from 'Entities/ResellerPayment';

import { actionCreator } from '.';

export enum ResellerActions {
    SaveResellerDetails = 'SaveResellerDetails',
    SaveResellerClients = 'SaveResellerClients',
    SaveResellerProcessingPayments = 'SaveResellerProcessingPayments',
    SaveResellerCompletePayments = 'SaveResellerCompletePayments',
    GetResellerDetails = 'GetResellerDetails',
    GetResellerClients = 'GetResellerClients',
    GetResellerProcessingPayments = 'GetResellerProcessingPayments',
    GetResellerCompletePayments = 'GetResellerCompletePayments',
    ClearResellerCompletePayments = 'ClearResellerCompletePayments',
    AddResellerClient = 'AddResellerClient',
    TransferFromResellerToClient = 'TransferFromResellerToClient',
    UpdateResellerClient = 'UpdateResellerClient',
    DoResellerWithdrawalPayment = 'DoResellerWithdrawalPayment',
    DoResellerTransferPayment = 'DoResellerTransferPayment',
    ResellerServerClone = 'ResellerServerClone',
}

type GetResellerDetails = Parameters<typeof ResellersApi.getResellerDetails>;
export const getResellerDetails = actionCreator<GetResellerDetails>(
    ResellerActions.GetResellerDetails,
);

type SaveResellerDetails = ResellerDetails;
export const saveResellerDetails = actionCreator<SaveResellerDetails>(
    ResellerActions.SaveResellerDetails,
);

type GetResellerClients = Parameters<typeof ResellersApi.getResellerClients>;
export const getResellerClients = actionCreator<GetResellerClients>(
    ResellerActions.GetResellerClients,
);

type SaveResellerClients = ResellerClient[];
export const saveResellerClients = actionCreator<SaveResellerClients>(
    ResellerActions.SaveResellerClients,
);

type GetResellerProcessingPayments = Parameters<typeof ResellersApi.getResellerProcessingPayments>;
export const getResellerProcessingPayments = actionCreator<GetResellerProcessingPayments>(
    ResellerActions.GetResellerProcessingPayments,
);

type SaveResellerProcessingPayments = ResellerPayment[];
export const saveResellerProcessingPayments = actionCreator<SaveResellerProcessingPayments>(
    ResellerActions.SaveResellerProcessingPayments,
);

type GetResellerCompletePayments = Parameters<typeof ResellersApi.getResellerCompletePayments>;
export const getResellerCompletePayments = actionCreator<GetResellerCompletePayments>(
    ResellerActions.GetResellerCompletePayments,
);
export const clearResellerCompletePayments = actionCreator(
    ResellerActions.ClearResellerCompletePayments,
);

type SaveResellerCompletePayments = ResellerPayment[];
export const saveResellerCompletePayments = actionCreator<SaveResellerCompletePayments>(
    ResellerActions.SaveResellerCompletePayments,
);

type AddResellerClient = Parameters<typeof ResellersApi.addResellerClient>;
export const addResellerClient = actionCreator<AddResellerClient>(
    ResellerActions.AddResellerClient,
);

type TransferFromResellerToClient = Parameters<typeof ResellersApi.transferFromResellerToClient>;
export const transferFromResellerToClient = actionCreator<TransferFromResellerToClient>(
    ResellerActions.TransferFromResellerToClient,
);

type UpdateResellerClient = Parameters<typeof ResellersApi.updateResellerClient>;
export const updateResellerClient = actionCreator<UpdateResellerClient>(
    ResellerActions.UpdateResellerClient,
);

type DoResellerWithdrawalPayment = Parameters<typeof ResellersApi.doResellerWithdrawalPayment>;
export const doResellerWithdrawalPayment = actionCreator<DoResellerWithdrawalPayment>(
    ResellerActions.DoResellerWithdrawalPayment,
);

type DoResellerTransferPayment = Parameters<typeof ResellersApi.doResellerTransferPayment>;
export const doResellerTransferPayment = actionCreator<DoResellerTransferPayment>(
    ResellerActions.DoResellerTransferPayment,
);

type ResellerServerClone = Parameters<typeof ResellersApi.resellerCloneServer>;
export const resellerServerClone = actionCreator<ResellerServerClone>(ResellerActions.ResellerServerClone);
