import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, Icon, Loader } from 'Common';
import { COPY_BUTTON_TIMEOUT } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import s from './DownloadButton.module.pcss';

interface DownloadButtonProps {
    label: string;
    link?: string;
    sizeGib: number;
    loading?: boolean;
}

const DownloadButton: FC<DownloadButtonProps> = ({
    label,
    link,
    sizeGib,
    loading,
}) => {
    const intl = useIntl();
    const [copyActive, setCopyActive] = useState(false);

    if (loading || !link) {
        return (
            <div className={s.loading}>
                <div className={s.loader}>
                    <Loader inline />
                </div>
                {intl.getMessage('download_button_processing_link')}
            </div>
        );
    }

    const handleCopy = () => {
        navigator.clipboard?.writeText(link);

        setCopyActive(true);
        setTimeout(() => {
            setCopyActive(false);
        }, COPY_BUTTON_TIMEOUT);
    };

    return (
        <div className={s.wrap}>
            <a
                href={link}
                className={cn(
                    theme.button.button,
                    theme.button.primary,
                    s.button,
                    s.download,
                )}
                download
            >
                <Icon icon="download" className={s.icon} />
                {label}&nbsp;<span className={theme.color.morning}>{intl.getMessage('value_gb', { value: sizeGib })}</span>
            </a>
            <button
                type="button"
                className={cn(
                    theme.button.button,
                    s.button,
                    s.copy,
                )}
                onClick={handleCopy}
            >
                <Icon icon={copyActive ? 'check' : 'link'} className={cn(s.icon, { [s.icon_link]: !copyActive })} />
                {copyActive ? intl.getMessage('link_copied') : intl.getMessage('copy_link')}
            </button>
        </div>
    );
};

export default DownloadButton;
