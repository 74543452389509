import DnsRecord, { IDnsRecord } from './DnsRecord';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsZoneImportResult {
    host: string;
    records: IDnsRecord[];
    zone_id?: number;
}

export default class DnsZoneImportResult {
    readonly _host: string;

    /**
     * Description: DNS zone origin
     * Example: www.example.com
     */
    get host(): string {
        return this._host;
    }

    static hostValidate(host: string): boolean {
        return typeof host === 'string' && !!host.trim();
    }

    readonly _records: DnsRecord[];

    /** */
    get records(): DnsRecord[] {
        return this._records;
    }

    readonly _zone_id: number | undefined;

    /**
     * Description: Existing DNS zone identifier, if any
     * Example: 2755
     */
    get zoneId(): number | undefined {
        return this._zone_id;
    }

    constructor(props: IDnsZoneImportResult) {
        this._host = props.host.trim();
        this._records = props.records.map((p) => new DnsRecord(p));
        if (typeof props.zone_id === 'number') {
            this._zone_id = props.zone_id;
        }
    }

    serialize(): IDnsZoneImportResult {
        const data: IDnsZoneImportResult = {
            host: this._host,
            records: this._records.map((p) => p.serialize()),
        };
        if (typeof this._zone_id !== 'undefined') {
            data.zone_id = this._zone_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            zone_id: !this._zone_id ? true : typeof this._zone_id === 'number',
            host: typeof this._host === 'string' && !!this._host.trim(),
            records: this._records.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsZoneImportResult): DnsZoneImportResult {
        return new DnsZoneImportResult(props);
    }

    readonly keys: { [key: string]: string } = {
        host: 'host',
        records: 'records',
        zoneId: 'zone_id',
        }
;

    mergeDeepWith(props: Partial<DnsZoneImportResult>): DnsZoneImportResult {
        const updateData: Partial<IDnsZoneImportResult> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsZoneImportResult) => {
            const updateKey = this.keys[key] as keyof IDnsZoneImportResult;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsZoneImportResult, keyof IDnsZoneImportResult>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsZoneImportResult({ ...this.serialize(), ...updateData });
    }
}
