import React, { FC } from 'react';
import './Icon.pcss';

export type IconType =
    'main_logo' |
    'main_logo_dark' |
    'arrow_down' |
    'arrow' |
    'attention' |
    'attention_no_border' |
    'backup' |
    'check' |
    'uncheck' |
    'clear' |
    'close_big' |
    'close_small' |
    'comment' |
    'comment_filled' |
    'delete' |
    'down' |
    'edit' |
    'generate' |
    'history' |
    'monitoring' |
    'options' |
    'os_centos' |
    'os_debian' |
    'os_fedora' |
    'os_ubuntu' |
    'os_windows' |
    'os_suse' |
    'os_rocky' |
    'plus' |
    'profile' |
    'search' |
    'side' |
    'snapshot' |
    'social_vk' |
    'status_active' |
    'status_reinstall' |
    'status_restart' |
    'status_shutdown' |
    'storage' |
    'visibility_disable' |
    'visibility_enable' |
    'console' |
    'discount' |
    'transfer' |
    'arrow_long' |
    'arrow_middle' |
    'arrow_circle' |
    'key' |
    'cloud' |
    'phone' |
    'mail' |
    'link' |
    'coins' |
    'star' |
    'income' |
    'costs' |
    'notifications' |
    'visa' |
    'mastercard' |
    'drag' |
    'panel_logo' |
    'filters' |
    'subject' |
    'heading' |
    'mobile' |
    'burger' |
    'soften' |
    'image_placeholder' |
    'ticket' |
    'support' |
    'send' |
    'lock' |
    'new_trigger' |
    'download' |
    'check_circle' |
    'trial' |
    'language' |
    'support_find' |
    'support_create' |
    'support_manage' |
    'social_yandex' |
    'loading' |
    'status_action' |
    'view_compact' |
    'view_list' |
    'view_expand' |
    'server' |
    'trigger' |
    'project' |
    'copy' |
    'update' |
    'forecast' |
    'graphic' |
    'mode_dark' |
    'mode_light' |
    'file' |
    'restore' |
    'close_border' |
    'home' |
    'zoom_in' |
    'zoom_out' |
    'minimize' |
    'maximize' |
    'curve' |
    'popover' |
    'add_user' |
    'coin_fall';

const Icons: FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" className="icons">
        <symbol id="main_logo" viewBox="0 0 132 22" fillRule="evenodd" clipRule="evenodd">
            <path d="M120.383 12.1962L120.73 11.8492L120.763 11.8193C121.952 10.7519 122.695 9.20865 122.695 7.4895C122.695 4.27655 120.091 1.67193 116.878 1.67193C114.343 1.67193 112.186 3.29316 111.389 5.5553C110.875 5.46676 110.347 5.42062 109.807 5.42062C109.764 5.42062 109.72 5.42092 109.677 5.42152C110.575 2.29096 113.459 -1.49449e-07 116.878 0C121.014 1.80805e-07 124.367 3.35317 124.367 7.4895C124.367 9.7029 123.407 11.692 121.88 13.0631L121.565 13.3785L120.383 12.1962Z" fill="currentColor" />
            <path d="M119.188 11.0015L119.607 10.5828C120.44 9.83474 120.964 8.74956 120.964 7.54203C120.964 5.28544 119.134 3.4561 116.878 3.4561C115.16 3.4561 113.689 4.51622 113.085 6.01792C113.613 6.21796 114.118 6.46492 114.595 6.75372C114.922 5.80759 115.82 5.12803 116.878 5.12803C118.211 5.12803 119.292 6.20882 119.292 7.54203C119.292 8.25531 118.984 8.89459 118.49 9.33889L118.456 9.36883L118.006 9.81927L119.188 11.0015Z" fill="currentColor" />
            <path d="M114.189 10.6277L114.726 11.1643L114.725 11.1649L120.574 17.0135L120.575 17.0125L120.802 17.2398C121.55 18.0726 122.635 18.5964 123.843 18.5964C126.099 18.5964 127.929 16.7671 127.929 14.5105C127.929 12.8451 126.932 11.4125 125.503 10.7761C125.299 11.3006 125.048 11.802 124.755 12.275C125.636 12.6349 126.257 13.5002 126.257 14.5105C126.257 15.8437 125.176 16.9245 123.843 16.9245C123.129 16.9245 122.49 16.6173 122.046 16.1226L122.016 16.0892L121.474 15.5472L121.473 15.5481L115.963 10.0383L115.964 10.0378L115.4 9.47359C114.03 7.96687 112.054 7.02099 109.857 7.02099C105.72 7.02099 102.367 10.3742 102.367 14.5105C102.367 18.6468 105.72 22 109.857 22C112.07 22 114.059 21.0398 115.43 19.5133L115.708 19.2357L114.526 18.0535L114.216 18.3628L114.186 18.3961C113.119 19.5845 111.576 20.3281 109.857 20.3281C106.644 20.3281 104.039 17.7235 104.039 14.5105C104.039 11.2975 106.644 8.69292 109.857 8.69292C111.563 8.69292 113.096 9.42545 114.163 10.5984L114.189 10.6277Z" fill="currentColor" />
            <path d="M113.586 12.4155L112.933 11.7626C112.186 10.9406 111.108 10.4246 109.909 10.4246C107.653 10.4246 105.823 12.2539 105.823 14.5105C105.823 16.7671 107.653 18.5964 109.909 18.5964C111.117 18.5964 112.202 18.0726 112.95 17.2398L113.331 16.8588L112.149 15.6765L111.736 16.0893L111.706 16.1226C111.262 16.6173 110.622 16.9245 109.909 16.9245C108.576 16.9245 107.495 15.8437 107.495 14.5105C107.495 13.1773 108.576 12.0965 109.909 12.0965C110.617 12.0965 111.252 12.3992 111.696 12.8874L111.723 12.9168L112.12 13.3139L112.119 13.3145L117.968 19.1631L117.97 19.1614L118.322 19.5133C119.693 21.0398 121.682 22 123.895 22C128.032 22 131.385 18.6468 131.385 14.5105C131.385 11.1534 129.176 8.31213 126.132 7.36079C126.132 7.36511 126.132 7.36944 126.132 7.37377C126.132 7.37855 126.132 7.38334 126.132 7.38812C126.133 7.39707 126.133 7.40603 126.133 7.41498C126.133 7.98302 126.081 8.5391 125.983 9.07888C128.165 9.91802 129.713 12.0335 129.713 14.5105C129.713 17.7234 127.108 20.3281 123.895 20.3281C122.176 20.3281 120.633 19.5845 119.566 18.3961L119.536 18.3628L119.097 17.9241L119.095 17.9259L113.585 12.4161L113.586 12.4155Z" fill="currentColor" />
            <path d="M12.1024 7.87922C11.5865 5.25668 9.35087 3.77344 6.3414 3.77344C3.09546 3.77344 0.472918 5.38566 0.472918 8.33065C0.472918 11.9205 3.69736 12.5869 6.3199 13.2318C8.08259 13.6832 9.60883 14.1346 9.60883 15.6394C9.60883 17.1656 8.19007 17.8105 6.42738 17.8105C4.19177 17.8105 3.03097 16.7572 2.73002 14.93L0 15.7039C0.279451 18.2834 2.34309 20.3256 6.23391 20.3256C9.93127 20.3256 12.4893 18.6059 12.4893 15.4889C12.4893 12.0495 9.60883 11.2326 7.07227 10.6092C5.07312 10.1148 3.31042 9.7494 3.31042 8.20167C3.31042 6.99788 4.36374 6.24551 6.21242 6.24551C7.93212 6.24551 9.15741 7.25583 9.41536 8.65309L12.1024 7.87922Z" fill="black" />
            <path d="M14.0088 14.7795C14.0088 17.9825 16.1155 20.3256 19.6194 20.3256C22.1559 20.3256 24.3056 19.0573 24.7785 16.8862L22.3924 16.4562C22.1129 17.4236 21.0596 18.0685 19.6194 18.0685C18.0071 18.0685 16.8463 17.2301 16.6314 15.4029H24.7355C24.8 15.016 24.843 14.6076 24.843 14.2421C24.843 11.2111 22.6933 9.1475 19.5764 9.1475C16.3089 9.1475 14.0088 11.5336 14.0088 14.7795ZM19.5979 11.2971C21.0811 11.2971 22.0055 12.0065 22.3064 13.3608H16.7174C17.0183 12.0065 18.0071 11.2971 19.5979 11.2971Z" fill="black" />
            <path d="M26.9073 20.1106H29.4868V14.5001C29.4868 12.7159 30.6476 11.7056 32.0879 11.7056C32.4318 11.7056 32.8187 11.7486 33.2057 11.856L33.5066 9.34097C33.0982 9.23349 32.8402 9.1905 32.4533 9.1905C31.099 9.1905 30.1102 9.79239 29.4868 10.6952L29.3363 9.36247H26.9073V20.1106Z" fill="black" />
            <path d="M38.863 20.1106H41.7435L45.8063 9.36247H43.0978L40.3892 17.0581L37.6807 9.36247H34.8217L38.863 20.1106Z" fill="black" />
            <path d="M46.1847 14.7795C46.1847 17.9825 48.2913 20.3256 51.7952 20.3256C54.3317 20.3256 56.4814 19.0573 56.9543 16.8862L54.5682 16.4562C54.2887 17.4236 53.2354 18.0685 51.7952 18.0685C50.183 18.0685 49.0222 17.2301 48.8072 15.4029H56.9113C56.9758 15.016 57.0188 14.6076 57.0188 14.2421C57.0188 11.2111 54.8691 9.1475 51.7522 9.1475C48.4847 9.1475 46.1847 11.5336 46.1847 14.7795ZM51.7737 11.2971C53.2569 11.2971 54.1813 12.0065 54.4822 13.3608H48.8932C49.1941 12.0065 50.183 11.2971 51.7737 11.2971Z" fill="black" />
            <path d="M59.0831 20.1106H61.6626V14.5001C61.6626 12.7159 62.8234 11.7056 64.2637 11.7056C64.6076 11.7056 64.9945 11.7486 65.3815 11.856L65.6824 9.34097C65.274 9.23349 65.016 9.1905 64.6291 9.1905C63.2748 9.1905 62.286 9.79239 61.6626 10.6952L61.5122 9.36247H59.0831V20.1106Z" fill="black" />
            <path d="M71.9508 9.1475C68.6618 9.1475 66.2543 11.5121 66.2543 14.7365C66.2543 17.961 68.6618 20.3256 71.9508 20.3256C75.2397 20.3256 77.6473 17.961 77.6473 14.7365C77.6473 11.5121 75.2397 9.1475 71.9508 9.1475ZM71.9508 11.5121C73.7565 11.5121 74.9603 12.8019 74.9603 14.7365C74.9603 16.6712 73.7565 17.961 71.9508 17.961C70.1451 17.961 68.9413 16.6712 68.9413 14.7365C68.9413 12.8019 70.1451 11.5121 71.9508 11.5121Z" fill="black" />
            <path d="M82.495 5.98755C82.495 5.08471 81.8286 4.41833 80.9043 4.41833C79.98 4.41833 79.3136 5.08471 79.3136 5.98755C79.3136 6.8904 79.98 7.55678 80.9043 7.55678C81.8286 7.55678 82.495 6.8904 82.495 5.98755ZM79.6145 20.1106H82.1941V9.36247H79.6145V20.1106Z" fill="black" />
            <path d="M95.3612 20.1106V3.9884H92.7817V10.5878C91.9218 9.68491 90.7825 9.1475 89.4282 9.1475C86.4403 9.1475 84.1617 11.5121 84.1617 14.7365C84.1617 17.961 86.4403 20.3256 89.4282 20.3256C90.8255 20.3256 92.0078 19.7667 92.8676 18.7993L92.9966 20.1106H95.3612ZM89.8152 11.5121C91.5994 11.5121 92.7817 12.8019 92.7817 14.7365C92.7817 16.6712 91.5994 17.961 89.8152 17.961C88.031 17.961 86.8487 16.6712 86.8487 14.7365C86.8487 12.8019 88.031 11.5121 89.8152 11.5121Z" fill="black" />
        </symbol>

        <symbol id="main_logo_dark" viewBox="0 0 132 22" fillRule="evenodd" clipRule="evenodd">
            <path d="M120.383 12.1962L120.73 11.8492L120.763 11.8193C121.952 10.7519 122.695 9.20865 122.695 7.4895C122.695 4.27655 120.091 1.67193 116.878 1.67193C114.343 1.67193 112.186 3.29316 111.389 5.5553C110.875 5.46676 110.347 5.42062 109.807 5.42062C109.764 5.42062 109.72 5.42092 109.677 5.42152C110.575 2.29096 113.459 -1.49449e-07 116.878 0C121.014 1.80805e-07 124.367 3.35317 124.367 7.4895C124.367 9.7029 123.407 11.692 121.88 13.0631L121.565 13.3785L120.383 12.1962Z" fill="currentColor" />
            <path d="M119.188 11.0015L119.607 10.5828C120.44 9.83474 120.964 8.74956 120.964 7.54203C120.964 5.28544 119.134 3.4561 116.878 3.4561C115.16 3.4561 113.689 4.51622 113.085 6.01792C113.613 6.21796 114.118 6.46492 114.595 6.75372C114.922 5.80759 115.82 5.12803 116.878 5.12803C118.211 5.12803 119.292 6.20882 119.292 7.54203C119.292 8.25531 118.984 8.89459 118.49 9.33889L118.456 9.36883L118.006 9.81927L119.188 11.0015Z" fill="currentColor" />
            <path d="M114.189 10.6277L114.726 11.1643L114.725 11.1649L120.574 17.0135L120.575 17.0125L120.802 17.2398C121.55 18.0726 122.635 18.5964 123.843 18.5964C126.099 18.5964 127.929 16.7671 127.929 14.5105C127.929 12.8451 126.932 11.4125 125.503 10.7761C125.299 11.3006 125.048 11.802 124.755 12.275C125.636 12.6349 126.257 13.5002 126.257 14.5105C126.257 15.8437 125.176 16.9245 123.843 16.9245C123.129 16.9245 122.49 16.6173 122.046 16.1226L122.016 16.0892L121.474 15.5472L121.473 15.5481L115.963 10.0383L115.964 10.0378L115.4 9.47359C114.03 7.96687 112.054 7.02099 109.857 7.02099C105.72 7.02099 102.367 10.3742 102.367 14.5105C102.367 18.6468 105.72 22 109.857 22C112.07 22 114.059 21.0398 115.43 19.5133L115.708 19.2357L114.526 18.0535L114.216 18.3628L114.186 18.3961C113.119 19.5845 111.576 20.3281 109.857 20.3281C106.644 20.3281 104.039 17.7235 104.039 14.5105C104.039 11.2975 106.644 8.69292 109.857 8.69292C111.563 8.69292 113.096 9.42545 114.163 10.5984L114.189 10.6277Z" fill="currentColor" />
            <path d="M113.586 12.4155L112.933 11.7626C112.186 10.9406 111.108 10.4246 109.909 10.4246C107.653 10.4246 105.823 12.2539 105.823 14.5105C105.823 16.7671 107.653 18.5964 109.909 18.5964C111.117 18.5964 112.202 18.0726 112.95 17.2398L113.331 16.8588L112.149 15.6765L111.736 16.0893L111.706 16.1226C111.262 16.6173 110.622 16.9245 109.909 16.9245C108.576 16.9245 107.495 15.8437 107.495 14.5105C107.495 13.1773 108.576 12.0965 109.909 12.0965C110.617 12.0965 111.252 12.3992 111.696 12.8874L111.723 12.9168L112.12 13.3139L112.119 13.3145L117.968 19.1631L117.97 19.1614L118.322 19.5133C119.693 21.0398 121.682 22 123.895 22C128.032 22 131.385 18.6468 131.385 14.5105C131.385 11.1534 129.176 8.31213 126.132 7.36079C126.132 7.36511 126.132 7.36944 126.132 7.37377C126.132 7.37855 126.132 7.38334 126.132 7.38812C126.133 7.39707 126.133 7.40603 126.133 7.41498C126.133 7.98302 126.081 8.5391 125.983 9.07888C128.165 9.91802 129.713 12.0335 129.713 14.5105C129.713 17.7234 127.108 20.3281 123.895 20.3281C122.176 20.3281 120.633 19.5845 119.566 18.3961L119.536 18.3628L119.097 17.9241L119.095 17.9259L113.585 12.4161L113.586 12.4155Z" fill="currentColor" />
            <path d="M12.1024 7.87922C11.5865 5.25668 9.35087 3.77344 6.3414 3.77344C3.09546 3.77344 0.472918 5.38566 0.472918 8.33065C0.472918 11.9205 3.69736 12.5869 6.3199 13.2318C8.08259 13.6832 9.60883 14.1346 9.60883 15.6394C9.60883 17.1656 8.19007 17.8105 6.42738 17.8105C4.19177 17.8105 3.03097 16.7572 2.73002 14.93L0 15.7039C0.279451 18.2834 2.34309 20.3256 6.23391 20.3256C9.93127 20.3256 12.4893 18.6059 12.4893 15.4889C12.4893 12.0495 9.60883 11.2326 7.07227 10.6092C5.07312 10.1148 3.31042 9.7494 3.31042 8.20167C3.31042 6.99788 4.36374 6.24551 6.21242 6.24551C7.93212 6.24551 9.15741 7.25583 9.41536 8.65309L12.1024 7.87922Z" fill="white" />
            <path d="M14.0088 14.7795C14.0088 17.9825 16.1155 20.3256 19.6194 20.3256C22.1559 20.3256 24.3056 19.0573 24.7785 16.8862L22.3924 16.4562C22.1129 17.4236 21.0596 18.0685 19.6194 18.0685C18.0071 18.0685 16.8463 17.2301 16.6314 15.4029H24.7355C24.8 15.016 24.843 14.6076 24.843 14.2421C24.843 11.2111 22.6933 9.1475 19.5764 9.1475C16.3089 9.1475 14.0088 11.5336 14.0088 14.7795ZM19.5979 11.2971C21.0811 11.2971 22.0055 12.0065 22.3064 13.3608H16.7174C17.0183 12.0065 18.0071 11.2971 19.5979 11.2971Z" fill="white" />
            <path d="M26.9073 20.1106H29.4868V14.5001C29.4868 12.7159 30.6476 11.7056 32.0879 11.7056C32.4318 11.7056 32.8187 11.7486 33.2057 11.856L33.5066 9.34097C33.0982 9.23349 32.8402 9.1905 32.4533 9.1905C31.099 9.1905 30.1102 9.79239 29.4868 10.6952L29.3363 9.36247H26.9073V20.1106Z" fill="white" />
            <path d="M38.863 20.1106H41.7435L45.8063 9.36247H43.0978L40.3892 17.0581L37.6807 9.36247H34.8217L38.863 20.1106Z" fill="white" />
            <path d="M46.1847 14.7795C46.1847 17.9825 48.2913 20.3256 51.7952 20.3256C54.3317 20.3256 56.4814 19.0573 56.9543 16.8862L54.5682 16.4562C54.2887 17.4236 53.2354 18.0685 51.7952 18.0685C50.183 18.0685 49.0222 17.2301 48.8072 15.4029H56.9113C56.9758 15.016 57.0188 14.6076 57.0188 14.2421C57.0188 11.2111 54.8691 9.1475 51.7522 9.1475C48.4847 9.1475 46.1847 11.5336 46.1847 14.7795ZM51.7737 11.2971C53.2569 11.2971 54.1813 12.0065 54.4822 13.3608H48.8932C49.1941 12.0065 50.183 11.2971 51.7737 11.2971Z" fill="white" />
            <path d="M59.0831 20.1106H61.6626V14.5001C61.6626 12.7159 62.8234 11.7056 64.2637 11.7056C64.6076 11.7056 64.9945 11.7486 65.3815 11.856L65.6824 9.34097C65.274 9.23349 65.016 9.1905 64.6291 9.1905C63.2748 9.1905 62.286 9.79239 61.6626 10.6952L61.5122 9.36247H59.0831V20.1106Z" fill="white" />
            <path d="M71.9508 9.1475C68.6618 9.1475 66.2543 11.5121 66.2543 14.7365C66.2543 17.961 68.6618 20.3256 71.9508 20.3256C75.2397 20.3256 77.6473 17.961 77.6473 14.7365C77.6473 11.5121 75.2397 9.1475 71.9508 9.1475ZM71.9508 11.5121C73.7565 11.5121 74.9603 12.8019 74.9603 14.7365C74.9603 16.6712 73.7565 17.961 71.9508 17.961C70.1451 17.961 68.9413 16.6712 68.9413 14.7365C68.9413 12.8019 70.1451 11.5121 71.9508 11.5121Z" fill="white" />
            <path d="M82.495 5.98755C82.495 5.08471 81.8286 4.41833 80.9043 4.41833C79.98 4.41833 79.3136 5.08471 79.3136 5.98755C79.3136 6.8904 79.98 7.55678 80.9043 7.55678C81.8286 7.55678 82.495 6.8904 82.495 5.98755ZM79.6145 20.1106H82.1941V9.36247H79.6145V20.1106Z" fill="white" />
            <path d="M95.3612 20.1106V3.9884H92.7817V10.5878C91.9218 9.68491 90.7825 9.1475 89.4282 9.1475C86.4403 9.1475 84.1617 11.5121 84.1617 14.7365C84.1617 17.961 86.4403 20.3256 89.4282 20.3256C90.8255 20.3256 92.0078 19.7667 92.8676 18.7993L92.9966 20.1106H95.3612ZM89.8152 11.5121C91.5994 11.5121 92.7817 12.8019 92.7817 14.7365C92.7817 16.6712 91.5994 17.961 89.8152 17.961C88.031 17.961 86.8487 16.6712 86.8487 14.7365C86.8487 12.8019 88.031 11.5121 89.8152 11.5121Z" fill="white" />
        </symbol>

        <symbol id="arrow_down" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M12.093 19.997L12.09 20 7 14.913l1.298-1.297 2.883 2.88V5h1.835v11.486l2.872-2.87 1.298 1.297L12.096 20l-.003-.003z" />
        </symbol>

        <symbol id="arrow" viewBox="0 0 24 24" fill="currentColor">
            <path d="M10.602 5.44l5.543 5.542H4v2.036h12.145l-5.543 5.543L12.042 20l8-8-8-8-1.44 1.44z" />
        </symbol>

        <symbol id="attention" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M4.636 12a7.364 7.364 0 1 0 14.728 0 7.364 7.364 0 0 0-14.728 0zM12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18z" /><path d="M11.182 7.09h1.636v5.728h-1.636V7.091zm0 7.364h1.636v1.637h-1.636v-1.637z" />
        </symbol>

        <symbol id="attention_no_border" viewBox="0 0 16 16" fill="currentColor">
            <rect width="16" height="16" rx="8" fill="#FDED5C" />
            <path d="M7 4H8.71429V8.57143H7V4Z" fill="black" />
            <path d="M7 10.2857H8.71429V12H7V10.2857Z" fill="black" />
        </symbol>

        <symbol id="check" viewBox="0 0 24 24" fill="currentColor">
            <path d="M7 12.17l1.06-1.061 2.83 2.83 5.22-5.218 1.06 1.06-6.28 6.28L7 12.17z" />
        </symbol>

        <symbol id="uncheck" viewBox="0 0 24 24" fill="currentColor">
            <rect width="10" height="2" />
        </symbol>

        <symbol id="clear" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM14.8619 8L16 9.13807L13.1213 12.0167L15.9665 14.8619L14.8285 16L11.9833 13.1548L9.17153 15.9665L8.03346 14.8285L10.8452 12.0167L8 9.17155L9.13807 8.03347L11.9833 10.8787L14.8619 8Z" />
        </symbol>

        <symbol id="close_big" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M6.248 4.48L4.834 5.894l5.48 5.48-5.834 5.834 1.414 1.414 5.834-5.834 5.834 5.834 1.414-1.415-5.834-5.833 5.48-5.48-1.414-1.414-5.48 5.48-5.48-5.48z" />
        </symbol>

        <symbol id="close_small" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M8.186 6.814L7.125 7.875l3.814 3.814-4.125 4.125 1.061 1.061L12 12.75l4.125 4.125 1.06-1.06-4.124-4.126 3.814-3.814-1.06-1.06L12 10.628 8.186 6.814z" />
        </symbol>

        <symbol id="comment" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M12.505 17.875a6.375 6.375 0 1 0-4.508-1.867L6.13 17.875h6.375zm-7.323-2.057L1 20h11.505a8.5 8.5 0 1 0-7.323-4.182z" />
        </symbol>

        <symbol id="comment_filled" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M1 20L5.18238 15.8176C4.43508 14.5523 4.0052 13.0754 4.0052 11.5C4.0052 6.80558 7.81078 3 12.5052 3C17.1996 3 21.0052 6.80558 21.0052 11.5C21.0052 16.1944 17.1996 20 12.5052 20H1Z" />
        </symbol>

        <symbol id="delete" viewBox="0 0 24 24" fill="currentColor">
            <path d="M6.875 17.25c0 .965.785 1.75 1.75 1.75h7c.965 0 1.75-.785 1.75-1.75V8.5h-10.5v8.75zm7-11.375V5h-3.5v.875H6v1.75h12.25v-1.75h-4.375z" />
        </symbol>

        <symbol id="down" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 15.92l-.001.002-5.999-6L6.923 9 12 14.077 17.077 9l.923.923-5.999 5.999L12 15.92z" />
        </symbol>

        <symbol id="edit" viewBox="0 0 24 24" fill="currentColor">
            <path d="M16.404 5.485a1.655 1.655 0 0 0-2.34 0l-8.58 8.58a1.655 1.655 0 0 0 2.34 2.34l8.58-8.58a1.655 1.655 0 0 0 0-2.34zm3.354 10.963a.552.552 0 0 0-.552-.551h-7.721a.552.552 0 0 0 0 1.103h7.721a.552.552 0 0 0 .552-.552z" />
        </symbol>

        <symbol id="generate" viewBox="0 0 24 24" fill="currentColor">
            <path d="M20 18.373V5.627C20 4.73 19.27 4 18.373 4H5.627C4.73 4 4 4.73 4 5.627v12.746C4 19.27 4.73 20 5.627 20h12.746C19.27 20 20 19.27 20 18.373zm-12.447-.249a1.68 1.68 0 0 1-1.677-1.677 1.68 1.68 0 0 1 1.677-1.677 1.68 1.68 0 0 1 1.677 1.677 1.68 1.68 0 0 1-1.677 1.677zm0-8.894a1.68 1.68 0 0 1-1.677-1.677 1.68 1.68 0 0 1 1.677-1.677A1.68 1.68 0 0 1 9.23 7.553 1.68 1.68 0 0 1 7.553 9.23zm4.558 4.322a1.68 1.68 0 0 1-1.677-1.678 1.68 1.68 0 0 1 1.678-1.677 1.68 1.68 0 0 1 1.677 1.677 1.68 1.68 0 0 1-1.678 1.678zm4.336 4.572a1.68 1.68 0 0 1-1.677-1.677 1.68 1.68 0 0 1 1.677-1.677 1.68 1.68 0 0 1 1.677 1.677 1.68 1.68 0 0 1-1.677 1.677zm0-8.894a1.68 1.68 0 0 1-1.677-1.677 1.68 1.68 0 0 1 1.677-1.677 1.68 1.68 0 0 1 1.677 1.677 1.68 1.68 0 0 1-1.677 1.677z" />
        </symbol>

        <symbol id="history" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14zm0 2a9 9 0 1 0 0-18 9 9 0 0 0 0 18z" /><path d="M12.981 6.993h-1.963v5.465h.014l-.014.014 3.069 3.07 1.388-1.389-2.494-2.494V6.993z" />
        </symbol>

        <symbol id="monitoring" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M19 7H5v7h14V7zM3 5v11h18V5H3z" /><path d="M7 17H17V19H7z" />
        </symbol>

        <symbol id="options" viewBox="0 0 24 24" fill="currentColor">
            <circle cx="11.5" cy="19.5" r="1.5" transform="rotate(-90 11.5 19.5)" /><circle cx="11.5" cy="11.5" r="1.5" transform="rotate(-90 11.5 11.5)" /><circle cx="11.5" cy="3.5" r="1.5" transform="rotate(-90 11.5 3.5)" />
        </symbol>

        <symbol id="os_centos" viewBox="0 0 24 24" fill="currentColor">
            <path d="M6.301 7.21L4.708 8.802V4.689h4.111l-1.62 1.62-.122.123.122.122 3.762 3.763v.616h-.691L6.546 7.209l-.124-.124-.12.124zm4.66 2.617V4.689h-1.65L7.568 6.432l3.393 3.395zm-4.54-2.249L4.707 9.294v1.639h5.068L6.422 7.578zm6.638 2.224l3.37-3.37-1.764-1.767h-1.606v5.137zm3.862-3.37l-.124.122-3.738 3.742v.637h.655l3.73-3.732.122-.122.125.122 1.632 1.634V4.667h-4.17l1.644 1.644.124.12zM11.308 4.34v5.836l.69.69.714-.715V4.318H14.316L11.998 2 9.66 4.34H11.308zm2.898 6.593h5.117V9.329L17.566 7.57l-3.36 3.363zm-3.353 1.077l-.726-.726H4.36V9.64L2 12.002l2.359 2.36V12.678h5.827l.667-.667zm2.206 2.232v5.046h1.636l1.704-1.706-3.34-3.34zm-6.63 2.192l3.41-3.41H4.708v1.688l1.722 1.722zm11.137 0l1.757-1.759v-1.653h-5.164l3.407 3.412zm2.108-2.11l2.322-2.322-2.322-2.324V11.284h-5.817l-.718.718.673.673h5.862v1.649zm-2.108 2.6l-.122-.123-3.779-3.779h-.606v.729l3.71 3.71.122.122-.123.124-1.579 1.581h4.134v-4.121L17.691 16.8l-.125.123zm-4.854 2.713v-5.741l-.726-.727-.666.665v5.803H9.621L11.986 22l2.362-2.363h-1.636zm-5.635-2.052l.122-.123 3.773-3.772v-.666h-.641l-3.779 3.777-.122.123-.123-.123L4.706 15.2v4.09h4.072l-1.58-1.582-.121-.122zm3.895-3.404l-3.404 3.404 1.704 1.705h1.7v-5.109z" />
        </symbol>

        <symbol id="os_debian" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M11.644 20.548c.81.384 1.63.773 2.085 1.452-3.455-.439-6.285-1.557-8.175-4.693-3.16-5.244-1.46-12.87 4.721-14.877 1.097-.356 2.307-.47 3.454-.419 2.772.122 5.26 1.739 6.435 4.277 1.57 3.39.386 8.144-3.474 9.318-2.013.612-4.516.258-5.965-1.369-1.309-1.468-1.577-3.693-.79-5.4.835-1.358 2.332-2.137 3.551-2.372a4.156 4.156 0 0 1 1.6.28c-1.49.529-2.455 1.34-3.054 2.125-.262.258-.485.56-.648.887a2.216 2.216 0 0 0-.163 1.633c.718 2.402 3.647 3.275 5.7 2.072 1.952-1.144 2.744-3.662 1.976-5.745-.276-.751-.75-1.42-1.27-2.022C13.382.762 6 5.426 5.622 10.98c-.237 3.472 1.936 6.84 4.654 8.818.398.29.881.519 1.369.75z" />
        </symbol>

        <symbol id="os_fedora" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12.492 10.508h2.448a2.448 2.448 0 1 0-2.448-2.448v2.448zm-4.7 2.252h2.448v2.448a2.448 2.448 0 1 1-2.448-2.448z" /><path d="M12 1C6.477 1 2 5.477 2 11v10h10c5.523 0 10-4.477 10-10S17.523 1 12 1zm2.94 11.76h-2.448v2.448a4.7 4.7 0 1 1-4.7-4.7h2.448V8.06a4.7 4.7 0 1 1 4.7 4.7z" />
        </symbol>

        <symbol id="os_ubuntu" viewBox="0 0 24 24" fill="currentColor">
            <path d="M8.585 11.776a4.83 4.83 0 0 1 1.852-3.805L8.712 5.21a8.091 8.091 0 0 0-3.046 4.288 2.905 2.905 0 0 1 .087 4.835 8.09 8.09 0 0 0 3.03 4.058l1.604-2.852a4.82 4.82 0 0 1-1.802-3.764z" /><path d="M6.186 11.945a2.093 2.093 0 0 1-4.186 0 2.092 2.092 0 0 1 4.186 0zm11.753 5.058c.486 0 .945.119 1.347.33a8.048 8.048 0 0 0 2.199-4.995l-3.25-.063a4.836 4.836 0 0 1-4.814 4.335 4.82 4.82 0 0 1-1.95-.41l-1.616 2.826a8.055 8.055 0 0 0 3.566.828 8.09 8.09 0 0 0 1.617-.163 2.908 2.908 0 0 1 2.9-2.688z" /><path d="M20.03 19.91a2.091 2.091 0 1 1-4.182-.001 2.091 2.091 0 0 1 4.182 0zm-.522-13.445A2.907 2.907 0 0 1 14.93 3.84a8.03 8.03 0 0 0-1.51-.142 8.06 8.06 0 0 0-3.596.842L11.43 7.37a4.838 4.838 0 0 1 6.796 3.845l3.25-.1a8.038 8.038 0 0 0-1.968-4.65z" /><path d="M19.92 4.092a2.092 2.092 0 1 1-4.183-.001 2.092 2.092 0 0 1 4.183 0z" />
        </symbol>

        <symbol id="os_windows" viewBox="0 0 24 24" fill="currentColor">
            <path d="M2 4.667v6.666h8.803V3.5L2 4.667zm10.061-1.334v8h10.061V2L12.061 3.333zm0 9.333v8l10.061 1.333v-9.333H12.061zM2 12.666v6.667l8.803 1.166v-7.833H2z" />
        </symbol>

        <symbol id="os_suse" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M10.561 22C16.0838 22 20.561 17.5228 20.561 12C20.561 6.47719 16.0838 2 10.561 2C7.35699 2 4.50499 3.50678 2.6748 5.85045C3.3761 6.08325 4.27427 6.3709 5.06991 6.59821V4.99107C8.44786 5.25893 15.4896 6.57145 16.6324 9.67857C16.7461 9.98756 16.8512 10.2708 16.9478 10.5309L16.9479 10.5312C17.6323 12.3745 17.8841 13.0527 17.6552 13.4621C17.5219 13.7003 17.2258 13.8476 16.7572 14.0807C16.7169 14.1007 16.6753 14.1214 16.6324 14.1429C15.1146 14.9018 12.7485 15.9732 6.31991 11.8661C6.85563 13.2232 7.10857 13.8006 7.16813 13.9196C8.9092 15.3779 13.4181 17.5982 17.5253 14.8125C17.311 15.9911 16.8407 16.5536 16.6324 16.6875C15.6354 17.3422 12.6235 18.625 8.55205 18.5179C7.84193 18.4595 7.24411 18.3905 6.71898 18.3299L6.71594 18.3295C5.17207 18.1513 4.25585 18.0455 2.95644 18.4941C4.79054 20.6399 7.5169 22 10.561 22Z" />
            <path d="M13.1503 10.8393C13.6434 10.8393 14.0431 10.5795 14.0431 10.2589C14.0431 9.93841 13.6434 9.67857 13.1503 9.67857C12.6572 9.67857 12.2574 9.93841 12.2574 10.2589C12.2574 10.5795 12.6572 10.8393 13.1503 10.8393Z" />
            <path d="M12.6592 13.6071C14.2618 13.6071 15.561 12.308 15.561 10.7054C15.561 9.10275 14.2618 7.80357 12.6592 7.80357C11.0566 7.80357 9.75741 9.10275 9.75741 10.7054C9.75741 12.308 11.0566 13.6071 12.6592 13.6071ZM12.6592 12.8036C13.818 12.8036 14.7574 11.8642 14.7574 10.7054C14.7574 9.54655 13.818 8.60714 12.6592 8.60714C11.5004 8.60714 10.561 9.54655 10.561 10.7054C10.561 11.8642 11.5004 12.8036 12.6592 12.8036Z" />
        </symbol>

        <symbol id="os_rocky" viewBox="0 0 24 24" fill="currentColor">
            <path d="M21.4433 15.2975C21.8042 14.265 22 13.1558 22 12C22 6.4775 17.5225 2 12 2C6.4775 2 2 6.4775 2 12C2 14.7333 3.09583 17.2092 4.8725 19.0142L15.0167 8.87083L17.5208 11.375L21.4433 15.2975ZM19.6167 18.48L15.0167 13.88L7.8125 21.0833C9.08583 21.6717 10.5042 22 12 22C15.0508 22 17.7833 20.6333 19.6167 18.48Z" />
        </symbol>

        <symbol id="plus" viewBox="0 0 24 24" fill="currentColor">
            <path d="M11 6H13V18H11z" /><path transform="rotate(-90 6 13.059)" d="M6 13.059H8V25.058999999999997H6z" />
        </symbol>

        <symbol id="profile" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12.23 10.66c1.823 0 3.3-2.876 3.3-4.578C15.53 4.38 14.052 3 12.23 3 10.41 3 8.933 4.38 8.933 6.082s1.477 4.578 3.299 4.578zm0 10.34c3.442 0 6.232-2.612 6.232-5.362s-2.79-3.447-6.231-3.447S6 12.89 6 15.639C6 18.388 8.79 21 12.23 21z" />
        </symbol>

        <symbol id="search" viewBox="0 0 24 24" fill="currentColor">
            <path d="M14.5335 13.8268C15.4135 12.7865 15.944 11.4412 15.944 9.97201C15.944 6.67376 13.2703 4 9.97201 4C6.67376 4 4 6.67376 4 9.97201C4 13.2703 6.67376 15.944 9.97201 15.944C11.1848 15.944 12.3131 15.5825 13.2551 14.9614L18.2937 20L19.5002 18.7935L14.5335 13.8268ZM14.2377 9.97201C14.2377 12.3279 12.3279 14.2377 9.97201 14.2377C7.61612 14.2377 5.70629 12.3279 5.70629 9.97201C5.70629 7.61612 7.61612 5.70629 9.97201 5.70629C12.3279 5.70629 14.2377 7.61612 14.2377 9.97201Z" />
        </symbol>

        <symbol id="side" viewBox="0 0 24 24" fill="currentColor">
            <path d="M15.9203 12.0001L15.9215 12.0013L9.92287 18L9 17.0771L14.0772 12L9.0001 6.92287L9.92297 6L15.9217 11.9987L15.9203 12.0001Z" />
        </symbol>

        <symbol id="snapshot" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M20.316 8.105H4.106v7.79h16.21v-7.79zM2 6v12h20.421V6H2z" /><path d="M6.968 11.979a2.105 2.105 0 1 1 4.21 0 2.105 2.105 0 0 1-4.21 0z" />
        </symbol>

        <symbol id="social_vk" viewBox="0 0 24 24" fill="currentColor">
            <path d="M22.083 16.703c-.057-.094-.41-.854-2.113-2.416-1.781-1.635-1.542-1.37.604-4.197 1.307-1.722 1.83-2.774 1.666-3.224-.156-.428-1.117-.315-1.117-.315l-3.196.018s-.237-.031-.414.073c-.171.102-.282.339-.282.339s-.506 1.333-1.182 2.466c-1.424 2.391-1.994 2.517-2.227 2.369-.541-.346-.405-1.391-.405-2.133 0-2.317.354-3.283-.693-3.533-.349-.083-.604-.138-1.493-.147-1.141-.012-2.108.004-2.654.268-.365.176-.645.57-.474.591.212.028.69.128.944.47.329.44.317 1.431.317 1.431s.189 2.728-.44 3.067c-.432.233-1.024-.242-2.294-2.412-.65-1.11-1.142-2.34-1.142-2.34s-.095-.229-.263-.35a1.32 1.32 0 0 0-.492-.197l-3.04.019s-.456.013-.624.209c-.148.174-.012.535-.012.535s2.38 5.504 5.074 8.279c2.472 2.543 5.278 2.376 5.278 2.376h1.27s.385-.042.58-.25c.181-.193.175-.553.175-.553s-.025-1.686.767-1.935c.78-.244 1.783 1.63 2.845 2.352.803.546 1.414.426 1.414.426l2.841-.04s1.487-.09.782-1.246z" />
        </symbol>

        <symbol id="status_active" viewBox="0 0 24 24" fill="currentColor">
            <path d="M5 5.727c0-.549.562-.9 1.026-.64l12.107 6.773c.49.274.49 1.006 0 1.28L6.026 19.913c-.464.26-1.026-.091-1.026-.64V5.727z" />
        </symbol>

        <symbol id="status_reinstall" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19.76 8.174l-2.471 2.47-2.161-.772-.772-2.16 2.47-2.471a4.653 4.653 0 0 0-5.921 5.802c-.068.053-.133.11-.195.172L5.637 16.29a2.174 2.174 0 1 0 3.075 3.074l5.073-5.073c.062-.062.119-.127.172-.194a4.653 4.653 0 0 0 5.802-5.922z" />
        </symbol>

        <symbol id="status_restart" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M19.6 5.685c0-1.203-1.284-2.12-2.445-1.47l-11.3 6.322c-1.14.637-1.14 2.302 0 2.94l11.3 6.321c1.162.65 2.445-.266 2.445-1.47V5.685zm-2 .573v11.498l-10.276-5.75L17.6 6.259z" />
        </symbol>

        <symbol id="status_shutdown" viewBox="0 0 24 24" fill="currentColor">
            <path d="M11.283 11.973v-5.81a1.163 1.163 0 0 1 2.325 0v5.81a1.163 1.163 0 0 1-2.325 0zm5.77-5.269a.872.872 0 0 0-1.08 1.37 5.676 5.676 0 0 1 2.175 4.48 5.71 5.71 0 0 1-5.702 5.703 5.71 5.71 0 0 1-5.702-5.702c0-1.758.791-3.39 2.173-4.48a.872.872 0 0 0-1.08-1.369A7.41 7.41 0 0 0 5 12.555C5 16.66 8.34 20 12.445 20s7.446-3.34 7.446-7.445a7.412 7.412 0 0 0-2.839-5.85z" />
        </symbol>

        <symbol id="storage" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M21 7H2v11h19V7zM0 5v15h23V5H0z" /><path d="M4 9.286H6V15.715H4z" /><rect x="14" y="10.5" width="4" height="4" rx="2" />
        </symbol>

        <symbol id="visibility_disable" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M6.07675 11.0186L5.30088 11.4665C4.88614 11.706 4.35582 11.5639 4.11638 11.1491C3.87693 10.7344 4.01903 10.2041 4.43376 9.96464L5.77791 9.1886C5.82632 9.16065 5.87632 9.1379 5.92724 9.12017C5.94 9.11267 5.95302 9.10545 5.96629 9.09852C6.39087 8.877 6.91464 9.04161 7.13616 9.4662C7.63369 10.4198 9.41088 12.43 12.3523 12.4681C15.2937 12.43 17.0709 10.4198 17.5684 9.4662C17.7899 9.04161 18.3137 8.877 18.7383 9.09852C18.7844 9.1226 18.8275 9.15025 18.8674 9.18096C18.8719 9.18347 18.8764 9.18601 18.8809 9.1886L20.225 9.96464C20.6398 10.2041 20.7818 10.7344 20.5424 11.1491C20.303 11.5639 19.7726 11.706 19.3579 11.4665L18.614 11.037C18.188 11.6053 17.575 12.2431 16.7787 12.7966L17.2222 13.5647C17.4616 13.9794 17.3195 14.5097 16.9048 14.7492C16.4901 14.9886 15.9597 14.8465 15.7203 14.4318L15.2549 13.6258C14.6462 13.8742 13.9706 14.0595 13.2289 14.1469V15.1327C13.2289 15.6116 12.8407 15.9998 12.3618 15.9998C11.8829 15.9998 11.4947 15.6116 11.4947 15.1327V14.1492C10.607 14.0466 9.81358 13.804 9.11589 13.4803L8.56656 14.4318C8.32711 14.8465 7.79679 14.9886 7.38206 14.7492C6.96732 14.5097 6.82523 13.9794 7.06467 13.5647L7.63183 12.5823C6.969 12.0763 6.44978 11.5196 6.07675 11.0186Z" />
        </symbol>

        <symbol id="visibility_enable" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 11.9999C4.02485 11.6762 4.15136 11.3586 4.37852 11.0961L4.37907 11.0955C4.47595 10.9837 5.34608 9.99479 6.66752 9.0233C7.95858 8.07415 9.87032 7 12.0213 7C14.1723 7 16.084 8.07415 17.3751 9.0233C18.6965 9.99479 19.5666 10.9837 19.6635 11.0955L19.6676 11.1003C19.8904 11.3598 20.0171 11.6759 20.0422 11.9999C20.0171 12.324 19.8904 12.6402 19.6676 12.8997L19.6635 12.9045C19.5666 13.0163 18.6965 14.0052 17.3751 14.9767C16.084 15.9259 14.1723 17 12.0213 17C9.87032 17 7.95858 15.9259 6.66752 14.9767C5.34608 14.0052 4.47595 13.0163 4.37907 12.9045L4.37852 12.9039C4.15136 12.6414 4.02485 12.3237 4 11.9999ZM18.6435 11.9425C18.6588 11.9603 18.6715 11.9796 18.6815 11.9999C18.6715 12.0203 18.6588 12.0397 18.6435 12.0575C18.5147 12.2061 15.455 15.6908 12.0213 15.6908C8.58758 15.6908 5.52785 12.2061 5.39911 12.0575C5.38362 12.0397 5.37086 12.0202 5.36082 11.9999C5.37086 11.9797 5.38362 11.9603 5.39911 11.9425C5.52785 11.7939 8.58758 8.30924 12.0213 8.30924C15.455 8.30924 18.5147 11.7939 18.6435 11.9425Z" />
            <circle cx="12" cy="11" r="3" />
        </symbol>

        <symbol id="console" viewBox="0 0 24 24" fill="currentColor">
            <path d="M8.76 8.796l-.965.964 2.256 2.256-2.248 2.232.972.972 3.204-3.204-3.22-3.22zm7.684 5.537h-4.566v1.556h4.566v-1.556z" /><path d="M4 5v14h16.333V5H4zm14.778 12.444H5.556V6.556h13.222v10.888z" />
        </symbol>

        <symbol id="discount" viewBox="0 0 24 24" fill="currentColor">
            <path d="M21.003 10.856v.002H21l-9.856 9.856a.975.975 0 0 1-1.38 0l-6.48-6.48a.975.975 0 0 1 0-1.379l9.854-9.853.003-.002c.002 0 .005 0 .007.002a.007.007 0 0 0 .005.002h6.874c.539 0 .975.437.975.975v6.877zM10.8 13.2a2.974 2.974 0 1 0 4.205-4.205A2.974 2.974 0 0 0 10.8 13.2z" />
        </symbol>

        <symbol id="transfer" viewBox="0 0 24 24" fill="currentColor">
            <path d="M13.043 11.494l5.624-3.247L13.043 5v2.179H5.001v2.136h8.042v2.179zm-2.42 1.012L5 15.753 10.624 19v-2.178h8.043v-2.137h-8.043v-2.179z" />
        </symbol>

        <symbol id="drag" viewBox="0 0 24 24" fill="currentColor">
            <path d="M4 5h15v2H4zM4 11h15v2H4zM4 17h15v2H4z" />
        </symbol>

        <symbol id="backup" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M5.63604 18.364C7.10838 19.8363 9.04567 20.7526 11.1178 20.9567C13.19 21.1608 15.2688 20.64 17.0001 19.4832C18.7314 18.3264 20.008 16.6051 20.6125 14.6126C21.2169 12.62 21.1117 10.4795 20.3149 8.55585C19.5181 6.63215 18.0789 5.04425 16.2426 4.06271C14.4062 3.08117 12.2864 2.76672 10.2442 3.17293C8.202 3.57915 6.36384 4.6809 5.04291 6.29046C3.72197 7.90002 3 9.9178 3 12H4.96168C4.96168 10.3716 5.52629 8.79367 6.5593 7.53493C7.59232 6.2762 9.02983 5.41459 10.6269 5.09692C12.224 4.77924 13.8818 5.02515 15.3178 5.79275C16.7539 6.56035 17.8794 7.80215 18.5026 9.30655C19.1257 10.811 19.2079 12.4849 18.7353 14.0431C18.2626 15.6014 17.2642 16.9475 15.9103 17.8522C14.5564 18.7568 12.9306 19.164 11.3101 19.0044C9.68961 18.8448 8.17457 18.1283 7.02315 16.9768L5.63604 18.364Z" /><path d="M12.9817 6.99219H11.0181V12.4576H11.0321L11.0181 12.4717L14.0874 15.541L15.4759 14.1525L12.9817 11.6583V6.99219Z" />
        </symbol>

        <symbol id="arrow_long" viewBox="0 0 24 24" fill="currentColor">
            <path d="M17.871 7.5L21.9997 11.9994L21.9993 11.9999L22 12.0006L17.8712 16.5L17.1082 15.6684L19.9526 12.5687H2V11.3927H19.9169L17.1079 8.33157L17.871 7.5Z" />
        </symbol>

        <symbol id="arrow_middle" viewBox="0 0 24 24" fill="currentColor">
            <path d="M11.3334 6.00182L11.3316 6L8 9.39117L8.84949 10.2559L10.7361 8.33546L10.7361 16H11.9375L11.9375 8.34262L13.8172 10.2559L14.6667 9.39121L11.3351 6.00006L11.3334 6.00182Z" />
        </symbol>

        <symbol id="arrow_circle" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12 21C7.58172 21 4 17.4183 4 13C4 8.58172 7.58172 5 12 5C16.4183 5 20 8.58172 20 13C20 17.4183 16.4183 21 12 21ZM10.9365 13.6085V17H12.9918V13.6085H15.0888L11.9655 8.19877L8.84219 13.6085H10.9365Z" />
        </symbol>

        <symbol id="key" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M18.4648 12.2047C16.5879 14.0816 13.5448 14.0816 11.6678 12.2047C9.79091 10.3278 9.79091 7.28464 11.6678 5.4077C13.5448 3.53077 16.5879 3.53077 18.4648 5.4077C20.3418 7.28464 20.3418 10.3278 18.4648 12.2047ZM17.5303 8.1265C17.0376 8.6192 16.2387 8.6192 15.746 8.1265C15.2533 7.63381 15.2533 6.83499 15.746 6.34229C16.2387 5.84959 17.0376 5.8496 17.5303 6.34229C18.023 6.83499 18.023 7.63381 17.5303 8.1265Z" />
            <path d="M9.16401 12.5844C9.14454 12.6039 9.14034 12.6339 9.15371 12.658C9.1588 12.6671 9.16147 12.6775 9.16147 12.6879V13.718C9.16147 13.9145 9.00217 14.0738 8.80567 14.0738H8.11539C7.91888 14.0738 7.75959 14.2331 7.75959 14.4296L7.75959 15.1199C7.75959 15.3164 7.60029 15.4757 7.40378 15.4757L6.71351 15.4757C6.517 15.4757 6.35771 15.635 6.35771 15.8315L6.35771 16.5218C6.35771 16.7183 6.19841 16.8776 6.0019 16.8776L5.01823 16.8776C4.92387 16.8776 4.83337 16.9151 4.76664 16.9818L4.10421 17.6442C4.03749 17.711 4 17.8015 4 17.8958L4 19.6442C4 19.8407 4.1593 20 4.3558 20H5.93416C6.02852 20 6.11902 19.9625 6.18575 19.8958L11.9984 14.0832C12.1657 13.9159 12.1259 13.6348 11.9293 13.5032C11.627 13.3009 11.34 13.0663 11.0731 12.7993C10.8201 12.5464 10.5962 12.2754 10.4012 11.9905C10.2686 11.7966 9.98983 11.7586 9.8237 11.9248L9.16401 12.5844Z" />
        </symbol>

        <symbol id="cloud" viewBox="0 0 24 24" fill="currentColor">
            <path d="M12.3807 11.7361L11.1492 11.1499C11.7072 9.97761 12.5903 8.9903 13.6932 8.30537C14.5802 7.75451 15.5795 7.41788 16.612 7.31734C15.6895 5.63813 13.904 4.5 11.8528 4.5C9.29581 4.5 7.15188 6.26859 6.57757 8.64924C6.52736 8.64787 6.47696 8.64718 6.42641 8.64718C3.42948 8.64718 1 11.0767 1 14.0736C1 16.9387 3.22046 19.2852 6.03439 19.4861V19.5H17.2792C20.2762 19.5 22.7056 17.0705 22.7056 14.0736C22.7056 11.0767 20.2762 8.64718 17.2792 8.64718C15.1192 8.64718 13.254 9.90922 12.3807 11.7361Z" />
        </symbol>

        <symbol id="phone" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M7.47692 1C6.66123 1 6 1.66124 6 2.47692V19.8308C6 20.6465 6.66123 21.3077 7.47692 21.3077H16.5231C17.3388 21.3077 18 20.6465 18 19.8308V2.47692C18 1.66124 17.3388 1 16.5231 1H7.47692ZM12.0923 15.9538C11.2766 15.9538 10.6154 16.6151 10.6154 17.4308C10.6154 18.2465 11.2766 18.9077 12.0923 18.9077C12.908 18.9077 13.5692 18.2465 13.5692 17.4308C13.5692 16.6151 12.908 15.9538 12.0923 15.9538Z" />
        </symbol>

        <symbol id="mail" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M3.43284 6C2.64149 6 2 6.6415 2 7.43284V16.5672C2 17.3585 2.64149 18 3.43284 18H21.3433C22.1346 18 22.7761 17.3585 22.7761 16.5672V7.43284C22.7761 6.6415 22.1346 6 21.3433 6H3.43284ZM3.31606 7.857C3.47746 7.49575 3.90117 7.33375 4.26242 7.49516L12.3881 11.1258L20.5137 7.49516C20.8749 7.33375 21.2987 7.49575 21.4601 7.857C21.6215 8.21825 21.4595 8.64194 21.0982 8.80335L12.6839 12.5629C12.6772 12.566 12.6703 12.569 12.6635 12.5718C12.493 12.6428 12.2955 12.6481 12.1127 12.5718C12.1058 12.569 12.099 12.566 12.0922 12.5629L3.6779 8.80335C3.31665 8.64194 3.15465 8.21825 3.31606 7.857Z" />
        </symbol>

        <symbol id="link" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19.6032 4.39684C17.7383 2.53439 14.7051 2.53439 12.8439 4.39684L7.42735 9.81455C6.86228 10.3796 6.55168 11.1287 6.55168 11.9291C6.55168 12.7271 6.86348 13.4761 7.42616 14.0412C8.00795 14.6218 8.77372 14.9133 9.53948 14.9133C10.3041 14.9133 11.0674 14.6242 11.6504 14.0412L14.1855 11.5074C14.6526 11.0355 14.8855 10.4274 14.8855 9.81813C14.8855 9.20647 14.6526 8.59601 14.1855 8.12891L9.96119 12.352C9.73063 12.5849 9.35073 12.5849 9.11538 12.3496C8.96366 12.1979 8.94097 12.0211 8.94097 11.9291C8.94097 11.8359 8.96366 11.6579 9.11538 11.5062L14.5331 6.08606C15.4625 5.15544 16.9797 5.15544 17.9139 6.08606C18.8446 7.01669 18.8446 8.53389 17.9139 9.46452L12.4938 14.8846L9.46541 17.913C8.53479 18.8437 7.01759 18.8437 6.08696 17.913C5.15514 16.9824 5.15514 15.4652 6.08696 14.5346L6.20045 14.4199C5.66167 13.7031 5.36181 12.7964 5.36181 11.8813L4.39773 12.8454C2.53409 14.7078 2.53409 17.741 4.39773 19.6023C5.32836 20.5329 6.55287 21 7.77619 21C8.9995 21 10.224 20.5329 11.1546 19.6023L19.6032 11.1537C21.4668 9.29129 21.4668 6.26048 19.6032 4.39684Z" />
        </symbol>

        <symbol id="coins" viewBox="0 0 24 24" fill="currentColor">
            <path d="M14.9757 9.02432C14.9757 9.01219 14.9758 9.00005 14.9758 8.98789C14.9758 5.68087 12.2949 3 8.98789 3C5.68087 3 3 5.68087 3 8.98789C3 12.2949 5.68087 14.9758 8.98789 14.9758C9.00003 14.9758 9.01217 14.9757 9.02429 14.9757C9.02429 14.9757 9.02429 14.9757 9.02429 14.9757C11.9018 14.9585 14.2981 12.9118 14.8541 10.1947C14.9316 9.81632 14.9733 9.42498 14.9757 9.02432Z" />
            <path d="M15.0121 21C12.1916 21 9.82667 19.05 9.19176 16.4247C13.1385 16.3185 16.3185 13.1385 16.4247 9.1918C19.05 9.8267 21 12.1917 21 15.0121C21 18.3191 18.3191 21 15.0121 21Z" />
        </symbol>

        <symbol id="star" viewBox="0 0 24 24" fill="currentColor">
            <path d="M20.2266 8.98618L14.8899 8.21059L12.5043 3.37414C12.4391 3.24172 12.3319 3.13453 12.1995 3.06937C11.8674 2.90542 11.4638 3.04205 11.2978 3.37414L8.91216 8.21059L3.57546 8.98618C3.42833 9.0072 3.29381 9.07656 3.19082 9.18166C3.06631 9.30964 2.99769 9.48181 3.00006 9.66035C3.00242 9.83889 3.07557 10.0092 3.20343 10.1338L7.0646 13.8983L6.15238 19.214C6.13099 19.3376 6.14467 19.4648 6.19188 19.5811C6.23908 19.6973 6.31793 19.7981 6.41946 19.8718C6.521 19.9456 6.64117 19.9894 6.76634 19.9983C6.89151 20.0072 7.01668 19.9809 7.12765 19.9223L11.901 17.4127L16.6744 19.9223C16.8047 19.9917 16.9561 20.0148 17.1011 19.9896C17.4668 19.9265 17.7128 19.5797 17.6497 19.214L16.7375 13.8983L20.5987 10.1338C20.7037 10.0308 20.7731 9.8963 20.7941 9.74917C20.8509 9.38134 20.5944 9.04083 20.2266 8.98618Z" />
        </symbol>

        <symbol id="income" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM10.6706 12.7606V17H13.2398V12.7606H15.861L11.9569 5.99847L8.05273 12.7606H10.6706Z" />
        </symbol>

        <symbol id="costs" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M11.9473 2C6.42442 2 1.94727 6.47715 1.94727 12C1.94727 17.5228 6.42442 22 11.9473 22C17.4701 22 21.9473 17.5228 21.9473 12C21.9473 6.47715 17.4701 2 11.9473 2ZM10.6179 11.2394V7H13.187V11.2394H15.8083L11.9041 18.0015L8 11.2394H10.6179Z" />
        </symbol>

        <symbol id="notifications" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M16.9272 15.429L17.9574 17.1463C18.0127 17.2387 18.0145 17.3551 17.961 17.4487C17.9076 17.5423 17.808 17.6005 17.7 17.6005H6.29959C6.19159 17.6005 6.09259 17.5423 6.03917 17.4481C5.98576 17.3539 5.98697 17.2387 6.04278 17.1457L7.07301 15.4284C7.54822 14.6358 7.79964 13.7285 7.79964 12.8045V11.3002C7.79964 9.40117 9.06689 7.79492 10.7997 7.27709V6.20006C10.7997 5.53822 11.338 5 11.9998 5C12.6616 5 13.1999 5.53822 13.1999 6.20006V7.27709C14.9327 7.79492 16.2 9.40117 16.2 11.3002V12.8045C16.2 13.7285 16.4514 14.6364 16.9272 15.429Z" />
            <path d="M11.9997 19.5206C11.165 19.5206 10.4486 19.0274 10.1102 18.3205H13.8891C13.5507 19.0274 12.8343 19.5206 11.9997 19.5206Z" />
        </symbol>

        <symbol id="visa" viewBox="0 0 48 32" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M33.2421 10.5053C32.6105 10.2526 31.6 10 30.3368 10C27.1789 10 24.9053 11.5789 24.9053 13.8526C24.9053 15.5579 26.4842 16.4421 27.7474 17.0105C29.0105 17.5789 29.3895 17.9579 29.3895 18.4632C29.3895 19.2211 28.379 19.6 27.4947 19.6C26.2316 19.6 25.5368 19.4105 24.4632 18.9684L24.0211 18.7789L23.5789 21.3684C24.3368 21.6842 25.7263 22 27.1789 22C30.5263 22 32.7368 20.4211 32.7368 18.0211C32.7368 16.6947 31.9158 15.6842 30.0211 14.8632C29.9646 14.8349 29.9092 14.8073 29.8549 14.7802L29.8506 14.7781L29.8505 14.778C28.8151 14.2618 28.1895 13.9499 28.1895 13.4105C28.1895 12.9053 28.7579 12.4 30.0211 12.4C31.0947 12.4 31.8526 12.5895 32.4211 12.8421L32.7368 12.9684L33.2421 10.5053Z" />
            <path d="M18.5895 21.7474L20.6105 10.379H23.7684L21.8105 21.7474H18.5895Z" />
            <path d="M37.0316 11.2001C37.3474 10.4422 37.9158 10.379 38.6737 10.379H41.1368L43.7895 21.7474H40.7579L40.3789 19.8527H36.3368L35.7053 21.7474H32.2947L37.0316 11.2001ZM38.4842 14.6106C38.4842 14.6106 37.4105 17.3895 37.221 17.958V18.0211H39.8737C39.7474 17.3895 39.1158 14.5474 39.1158 14.5474L38.9263 13.4737C38.7368 13.9159 38.4842 14.6106 38.4842 14.6106Z" />
            <path d="M12.779 18.3368L12.4632 16.6947L12.4631 16.6943L11.3263 11.3264C11.1368 10.5685 10.5684 10.379 9.87368 10.379H4L4.56842 10.5053C5.87712 10.8121 7.05341 11.271 8.08228 11.848L10.8842 21.8105H14.2948L19.4105 10.4421H16L12.779 18.3368Z" />
        </symbol>

        <symbol id="mastercard" viewBox="0 0 48 32" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M24.1097 21.6672C25.8611 20.3949 27 18.3305 27 16.0001C27 13.6696 25.8612 11.6052 24.1097 10.3328C22.3582 11.6052 21.2194 13.6696 21.2194 16.0001C21.2194 18.3305 22.3582 20.3949 24.1097 21.6672Z" />
            <path d="M23.2917 22.1793C21.4796 20.7325 20.3161 18.5021 20.3161 16.0001C20.3161 13.498 21.4797 11.2676 23.2918 9.82073C22.3106 9.29693 21.19 9 20 9C16.134 9 13 12.134 13 16C13 19.866 16.134 23 20 23C21.1899 23 22.3105 22.7031 23.2917 22.1793Z" />
            <path d="M24.9277 22.1793C25.9088 22.7031 27.0294 23 28.2194 23C32.0853 23 35.2194 19.866 35.2194 16C35.2194 12.134 32.0853 9 28.2194 9C27.0294 9 25.9088 9.29693 24.9275 9.82073C26.7397 11.2676 27.9032 13.498 27.9032 16.0001C27.9032 18.5021 26.7397 20.7325 24.9277 22.1793Z" />
        </symbol>

        <symbol id="drag" viewBox="0 0 24 24" fill="currentColor">
            <rect x="4" y="5" width="15" height="2" />
            <rect x="4" y="11" width="15" height="2" />
            <rect x="4" y="17" width="15" height="2" />
        </symbol>

        <symbol id="card" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M3.45455 6C2.65128 6 2 6.65128 2 7.45455V17.2727C2 18.076 2.65128 18.7273 3.45455 18.7273H20.5455C21.3487 18.7273 22 18.076 22 17.2727V7.45455C22 6.65128 21.3487 6 20.5455 6H3.45455ZM20.1818 14H3.81818V16.1818H20.1818V14Z" />
        </symbol>

        <symbol id="panel_logo" viewBox="0 0 27 20" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M16.3779 11.0875L16.6933 10.772L16.7236 10.7448C17.804 9.77444 18.48 8.3715 18.48 6.80864C18.48 3.88777 16.1121 1.51994 13.1913 1.51994C10.8868 1.51994 8.92664 2.99378 8.20189 5.05027C7.73455 4.96978 7.25402 4.92784 6.7637 4.92784C6.72423 4.92784 6.68483 4.92811 6.64549 4.92865C7.46136 2.08269 10.0831 -1.35862e-07 13.1913 0C16.9516 1.64368e-07 19.9999 3.04833 19.9999 6.80864C19.9999 8.82082 19.127 10.6291 17.7393 11.8756L17.4526 12.1622L16.3779 11.0875Z" />
            <path d="M15.2918 10.0014L15.6725 9.62068C16.4295 8.94068 16.9057 7.95415 16.9057 6.85639C16.9057 4.80494 15.2427 3.14191 13.1913 3.14191C11.6296 3.14191 10.293 4.10565 9.7438 5.47084C10.2239 5.65269 10.6829 5.8772 11.1164 6.13974C11.4134 5.27963 12.2302 4.66185 13.1913 4.66185C14.4033 4.66185 15.3858 5.64438 15.3858 6.85639C15.3858 7.50483 15.1065 8.08599 14.6568 8.4899L14.6265 8.51712L14.217 8.92661L15.2918 10.0014Z" />
            <path d="M10.7473 9.66159L11.235 10.1493L11.2345 10.1499L16.5514 15.4668L16.5523 15.4659L16.759 15.6725C17.439 16.4296 18.4255 16.9058 19.5233 16.9058C21.5747 16.9058 23.2377 15.2428 23.2377 13.1913C23.2377 11.6774 22.332 10.375 21.0329 9.79645C20.8469 10.2733 20.6187 10.729 20.3529 11.159C21.1536 11.4863 21.7178 12.2729 21.7178 13.1913C21.7178 14.4034 20.7353 15.3859 19.5233 15.3859C18.8748 15.3859 18.2937 15.1066 17.8897 14.6569L17.8625 14.6266L17.3697 14.1338L17.3688 14.1347L12.3599 9.12577L12.3605 9.12524L11.8476 8.61236C10.6021 7.24261 8.8058 6.38272 6.80864 6.38272C3.04833 6.38272 0 9.43105 0 13.1914C0 16.9517 3.04833 20 6.80864 20C8.82082 20 10.6291 19.1271 11.8756 17.7394L12.128 17.487L11.0532 16.4123L10.772 16.6934L10.7448 16.7237C9.77444 17.8041 8.3715 18.4801 6.80864 18.4801C3.88777 18.4801 1.51994 16.1122 1.51994 13.1914C1.51994 10.2705 3.88777 7.90266 6.80864 7.90266C8.3599 7.90266 9.75348 8.56859 10.723 9.63488L10.7473 9.66159Z" />
            <path d="M10.1989 11.2868L9.60541 10.6933C8.92595 9.946 7.94596 9.47688 6.85639 9.47688C4.80494 9.47688 3.14191 11.1399 3.14191 13.1914C3.14191 15.2428 4.80494 16.9058 6.85639 16.9058C7.95415 16.9058 8.94068 16.4296 9.62068 15.6726L9.96709 15.3261L8.89234 14.2514L8.51712 14.6266L8.4899 14.6569C8.08599 15.1066 7.50483 15.3859 6.85639 15.3859C5.64438 15.3859 4.66185 14.4034 4.66185 13.1914C4.66185 11.9793 5.64438 10.9968 6.85639 10.9968C7.50005 10.9968 8.0773 11.272 8.48084 11.7158L8.50513 11.7425L8.86615 12.1035L8.8656 12.1041L14.1825 17.421L14.1841 17.4194L14.5041 17.7394C15.7505 19.1271 17.5588 20 19.571 20C23.3313 20 26.3796 16.9517 26.3796 13.1913C26.3796 10.1394 24.3716 7.55648 21.6047 6.69163C21.6047 6.69556 21.6048 6.69949 21.6048 6.70342C21.6048 6.70777 21.6048 6.71212 21.6048 6.71648C21.6049 6.72461 21.6049 6.73275 21.6049 6.74089C21.6049 7.25729 21.5583 7.76282 21.4693 8.25353C23.4524 9.01638 24.8597 10.9395 24.8597 13.1913C24.8597 16.1122 22.4919 18.48 19.571 18.48C18.0081 18.48 16.6052 17.8041 15.6349 16.7237L15.6076 16.6934L15.2089 16.2947L15.2073 16.2962L10.1984 11.2873L10.1989 11.2868Z" />
        </symbol>

        <symbol id="card" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M3.45455 6C2.65128 6 2 6.65128 2 7.45455V17.2727C2 18.076 2.65128 18.7273 3.45455 18.7273H20.5455C21.3487 18.7273 22 18.076 22 17.2727V7.45455C22 6.65128 21.3487 6 20.5455 6H3.45455ZM20.1818 14H3.81818V16.1818H20.1818V14Z" />
        </symbol>

        <symbol id="filters" viewBox="0 0 24 24" fill="currentColor">
            <rect x="7" y="15" width="2.25" height="3" />
            <rect x="7" y="6" width="2.25" height="7" />
            <rect width="2.25" height="3" transform="matrix(1 0 0 -1 14.875 9)" />
            <rect width="2.25" height="7" transform="matrix(1 0 0 -1 14.875 18)" />
        </symbol>

        <symbol id="subject" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M12.605 13.5698L11.5327 10.2673H11.4645L10.3901 13.5698H12.605Z" />
            <path d="M5 4C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V6C20 4.89543 19.1046 4 18 4H5ZM7.29688 17.0002H9.27415L9.92155 15.0102H13.0726L13.7188 17.0002H15.696L12.6875 8.27295H10.3097L7.29688 17.0002Z" />
        </symbol>

        <symbol id="heading" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <rect x="3" y="5" width="2" height="2" />
            <rect x="8" y="5" width="12" height="2" />
            <rect x="3" y="11" width="2" height="2" />
            <rect x="8" y="11" width="12" height="2" />
            <rect x="3" y="17" width="2" height="2" />
            <rect x="8" y="17" width="12" height="2" />
        </symbol>

        <symbol id="mobile" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M6 3L18.2143 3L18.2143 22L6 22L6 3ZM11.5418 9.10714L12.6728 9.10714V11.9671H15.5L15.5 13.0981H12.6728V15.8929H11.5418V13.0981H8.71429V11.9671H11.5418V9.10714Z" />
        </symbol>

        <symbol id="burger" viewBox="0 0 32 32" fill="currentColor">
            <rect x="8" y="11" width="16" height="2" />
            <rect x="8" y="19" width="16" height="2" />
        </symbol>

        <symbol id="soften" viewBox="0 0 24 24" fill="currentColor">
            <path d="M18.5329 16H20.4706C20.4706 11.5817 16.7835 8 12.2353 8C7.68707 8 4 11.5817 4 16H5.93772C5.93772 12.6213 8.75724 9.88235 12.2353 9.88235C15.7134 9.88235 18.5329 12.6213 18.5329 16Z" />
        </symbol>

        <symbol id="image_placeholder" viewBox="0 0 214 108">
            <path d="M78.7598 29.2002L0.759766 107.2H156.76L78.7598 29.2002Z" fill="#DEE4FF" />
            <path d="M156.76 50.7998L100.44 107.12H213.08L156.76 50.7998Z" fill="#DEE9FF" />
            <path d="M128.56 79L100.44 107.12H156.68L128.56 79Z" fill="#A0A9D2" />
            <circle cx="139.48" cy="18.1599" r="17.28" fill="#DEF6FC" />
        </symbol>

        <symbol id="ticket" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M21.2485 8.52045C20.5571 9.21187 19.436 9.21182 18.7446 8.52045C18.0533 7.82908 18.0532 6.70802 18.7446 6.0166L18.0143 5.28631C17.3229 5.97773 16.2019 5.97768 15.5105 5.28631C14.8191 4.59494 14.8191 3.47388 15.5105 2.78245L14.728 2L12.6415 4.08654L19.9966 11.4416L22.0831 9.35507L21.2485 8.52045Z" />
            <path d="M19.5271 11.9111L12.172 4.55602L4.55612 12.1719L11.9112 19.527L19.5271 11.9111ZM14.2064 14.2063C15.4164 12.9963 15.4163 11.0345 14.2064 9.82454C12.9964 8.61459 11.0346 8.61456 9.82465 9.82454C8.61466 11.0345 8.6147 12.9963 9.82465 14.2063C11.0346 15.4162 12.9964 15.4163 14.2064 14.2063Z" />
            <path d="M8.52056 21.2484C9.21198 20.557 9.21193 19.4359 8.52056 18.7445C7.82918 18.0531 6.70813 18.0531 6.0167 18.7445L5.28641 18.0142C5.97784 17.3228 5.97778 16.2017 5.28641 15.5104C4.59504 14.819 3.47398 14.819 2.78256 15.5104L2.00011 14.7279L4.08665 12.6414L11.4417 19.9964L9.35518 22.083L8.52056 21.2484Z" />
        </symbol>

        <symbol id="support" viewBox="0 0 24 24" fill="currentColor">
            <path d="M14.4545 10.5455H16.3305C15.8362 8.40993 13.9222 6.81818 11.6364 6.81818C9.35055 6.81818 7.43651 8.40993 6.9422 10.5455H8.81818C9.32026 10.5455 9.72727 10.9525 9.72727 11.4545V14.9545C9.72727 15.4566 9.32026 15.8636 8.81818 15.8636H5.90909C5.40701 15.8636 5 15.4566 5 14.9545V11.4545C5 11.4186 5.00208 11.3832 5.00614 11.3483C5.15694 7.8168 8.0677 5 11.6364 5C15.205 5 18.1158 7.8168 18.2666 11.3483C18.2706 11.3832 18.2727 11.4186 18.2727 11.4545V14.9545C18.2727 14.9995 18.2695 15.0436 18.2632 15.0868C18.1973 17.2592 16.4156 19 14.2273 19V17.8938C15.5318 17.8938 16.6373 17.0416 17.0176 15.8636H14.4545C13.9525 15.8636 13.5455 15.4566 13.5455 14.9545V11.4545C13.5455 10.9525 13.9525 10.5455 14.4545 10.5455Z" />
        </symbol>

        <symbol id="check_circle" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M8.86706 10.9524L8 11.8194L11.1806 15L16.3135 9.86706L15.4464 9L11.1806 13.2659L8.86706 10.9524Z" />
            <path d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19Z" />
        </symbol>

        <symbol id="new_trigger" viewBox="0 0 43 43" fill="currentColor">
            <rect x="17.9167" y="5.375" width="7.16667" height="32.25" fill="#246FFF" />
            <rect x="5.375" y="25.083" width="7.16667" height="32.25" transform="rotate(-90 5.375 25.083)" fill="#1C42EA" />
            <path d="M17.9167 25.0827V17.916H25.0834V25.0827L17.9167 25.0827Z" fill="white" />
        </symbol>

        <symbol id="lock" viewBox="0 0 24 24" fill="currentColor">
            <path d="M15.4149 7.47473V7.63392H16.9714V7.47473C16.9714 5.00341 14.968 3 12.4966 3C10.0253 3 8.0219 5.00341 8.0219 7.47473V7.63392H9.57832V7.47473C9.57832 5.863 10.8849 4.55643 12.4966 4.55643C14.1084 4.55643 15.4149 5.863 15.4149 7.47473Z" />
            <path d="M5 9.60977C5 8.96787 5.52037 8.44751 6.16227 8.44751H18.7148C19.3567 8.44751 19.877 8.96787 19.877 9.60977V19.8377C19.877 20.4796 19.3567 21 18.7148 21H6.16227C5.52037 21 5 20.4796 5 19.8377V9.60977ZM12.4966 12.283C11.6943 12.283 11.0438 12.9335 11.0438 13.7358C11.0438 14.3088 11.3755 14.8043 11.8574 15.0408V16.4672C11.8574 16.7881 12.1176 17.0483 12.4385 17.0483C12.7594 17.0483 13.0196 16.7881 13.0196 16.4672V15.0917C13.5636 14.8817 13.9495 14.3538 13.9495 13.7358C13.9495 12.9335 13.299 12.283 12.4966 12.283Z" />
        </symbol>

        <symbol id="send" viewBox="0 0 24 24" fill="currentColor">
            <path d="M20.971 3.64011C21.1072 3.26005 20.74 2.89277 20.3599 3.029L3.31671 9.1377C2.93591 9.27419 2.88634 9.79254 3.23437 9.99873L8.1303 12.8993C8.27908 12.9874 8.46387 12.9883 8.61349 12.9016L12.9693 10.3776C13.393 10.1321 13.8679 10.607 13.6224 11.0307L11.0984 15.3865C11.0117 15.5361 11.0126 15.7209 11.1007 15.8697L14.0013 20.7656C14.2075 21.1137 14.7258 21.0641 14.8623 20.6833L20.971 3.64011Z" />
        </symbol>

        <symbol id="download" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M11.8829 16.7722C11.9138 16.8031 11.9557 16.8205 11.9994 16.8204C12.0417 16.8206 12.0848 16.8046 12.1171 16.7722L16.8297 12.0515C16.8937 11.9874 16.8937 11.8836 16.8297 11.8195L16.1967 11.1854C16.1659 11.1546 16.1241 11.1372 16.0805 11.1372C16.0369 11.1372 15.9951 11.1546 15.9643 11.1854L12.6131 14.5424V6.36339C12.6131 6.27272 12.5396 6.19922 12.4489 6.19922H11.5511C11.4604 6.19922 11.3869 6.27272 11.3869 6.36339V14.5424L8.03568 11.1854C8.00489 11.1546 7.96309 11.1372 7.9195 11.1372C7.87591 11.1372 7.83411 11.1546 7.80331 11.1854L7.17026 11.8195C7.10628 11.8836 7.10628 11.9874 7.17026 12.0515L11.8829 16.7722Z" />
            <path d="M6.16417 17.6144C6.0735 17.6144 6 17.6879 6 17.7785V18.6763C6 18.767 6.0735 18.8405 6.16417 18.8405H17.8358C17.9265 18.8405 18 18.767 18 18.6763V17.7785C18 17.6879 17.9265 17.6144 17.8358 17.6144H6.16417Z" />
        </symbol>

        <symbol id="language" viewBox="0 0 24 24" fill="currentColor">
            <path d="M11.9871 14.8516C12.9297 14.8516 13.8341 14.7935 14.6739 14.6868C14.7806 13.847 14.8387 12.9426 14.8387 12C14.8387 7.58489 13.5638 4.00514 11.9905 4C11.987 4 11.9835 4.00001 11.9799 4.00002C11.9608 4.00015 11.9417 4.00081 11.9226 4.002V4.00036C7.53996 4.04191 4 7.60755 4 12C4 12.0584 4.00063 12.1166 4.00187 12.1747C4.25515 13.6683 7.73343 14.8516 11.9871 14.8516Z" />
            <path d="M4.38803 14.4683C5.17573 16.8992 7.09554 18.8204 9.52562 19.61C9.24057 19.1538 9.00216 18.629 8.8039 18.0728C8.56139 17.3924 8.36208 16.6171 8.21267 15.7744C7.37001 15.625 6.59468 15.4257 5.91433 15.1832C5.36258 14.9865 4.84166 14.7503 4.38803 14.4683Z" />
            <path d="M11.9904 20C11.9869 20 11.9834 20 11.9799 20C10.9214 19.9927 9.99833 18.3672 9.5085 15.9586C10.2997 16.0449 11.1321 16.0903 11.9871 16.0903C14.2996 16.0903 16.4467 15.7582 18.0599 15.1832C18.6248 14.9818 19.1574 14.739 19.6185 14.448C18.5844 17.6689 15.5644 20 12 20L12 19.9999C11.997 20 11.994 20 11.991 20C11.9908 20 11.9906 20 11.9904 20Z" />
            <path d="M20 12C20 8.43337 17.666 5.41187 14.442 4.37957C14.73 4.83818 14.9705 5.36679 15.1703 5.92723C15.7453 7.54037 16.0774 9.68753 16.0774 12C16.0774 12.855 16.032 13.6874 15.9457 14.4786C18.3597 13.9877 19.9871 13.0616 19.9871 12H20Z" />
        </symbol>

        <symbol id="support_manage" viewBox="0 0 28 28" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <rect x="3.7334" y="3.7334" width="13.7083" height="13.7083" fill="#B4C1FF" />
            <path d="M22.8669 11.9586H11.9586V22.8669H22.8669V11.9586ZM10.5586 10.5586V24.2669H24.2669V10.5586H10.5586Z" fill="#1C42EA" />
            <path d="M17.4419 10.5586H10.5586V17.4419H11.9586V11.9586H17.4419V10.5586Z" fill="black" />
        </symbol>

        <symbol id="support_create" viewBox="0 0 28 28" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <rect x="4.2002" y="4.375" width="19.6" height="13.7083" fill="#B4C1FF" />
            <path d="M16.3334 23.625L10.9785 14.35H21.6884L16.3334 23.625ZM16.3334 20.825L13.4034 15.75H19.2635L16.3334 20.825Z" fill="#1C42EA" />
            <path d="M19.5329 18.0829L21.6884 14.3496H10.9785L13.134 18.0829H14.7505L13.4034 15.7496H19.2635L17.9163 18.0829H19.5329Z" fill="black" />
        </symbol>

        <symbol id="support_find" viewBox="0 0 28 28" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <rect x="2.7998" y="10.208" width="14.9333" height="14.9333" fill="#B4C1FF" />
            <path d="M17.7041 16.4501C21.103 16.4501 23.8583 13.6948 23.8583 10.2959C23.8583 6.89705 21.103 4.14173 17.7041 4.14173C14.3052 4.14173 11.5499 6.89705 11.5499 10.2959C11.5499 13.6948 14.3052 16.4501 17.7041 16.4501ZM17.7041 17.7334C21.8117 17.7334 25.1416 14.4035 25.1416 10.2959C25.1416 6.18828 21.8117 2.8584 17.7041 2.8584C13.5965 2.8584 10.2666 6.18828 10.2666 10.2959C10.2666 14.4035 13.5965 17.7334 17.7041 17.7334Z" fill="#1C42EA" />
            <path d="M17.7333 16.4496C17.7236 16.4497 17.7138 16.4497 17.7041 16.4497C14.3053 16.4497 11.5499 13.6944 11.5499 10.2955C11.5499 10.2663 11.5501 10.2371 11.5506 10.208H10.2671C10.2668 10.2371 10.2666 10.2663 10.2666 10.2955C10.2666 14.4031 13.5965 17.733 17.7041 17.733C17.7138 17.733 17.7236 17.733 17.7333 17.733V16.4496Z" fill="black" />
        </symbol>

        <symbol id="social_yandex" viewBox="0 0 24 24" fill="currentColor">
            <path d="M16.6993 21H14.7899V14.2809H13.4461L10.4049 21H8L11.8194 14.104C11.8194 14.104 8.60154 12.6189 8.60154 8.94091C8.60154 5.26349 11.1658 3 13.318 3H16.6993V21ZM13.042 4.52569C12.7586 4.57197 10.7049 5.03334 10.7049 8.60529C10.7049 10.2359 11.114 11.2181 11.584 11.8087C12.0366 12.3772 12.7384 12.6895 13.465 12.6895H14.7364V4.52106H13.1023C13.082 4.52106 13.0623 4.52226 13.042 4.52569Z" />
        </symbol>

        <symbol id="loading" viewBox="0 0 24 24" fill="currentColor">
            <path d="M23.1563 12.8438C22.6898 12.8438 22.3125 12.4664 22.3125 12C22.3125 10.6078 22.0406 9.25781 21.5016 7.98516C20.983 6.75998 20.2325 5.64659 19.2914 4.70625C18.3521 3.76387 17.2385 3.01321 16.0125 2.49609C14.7422 1.95938 13.3922 1.6875 12 1.6875C11.5336 1.6875 11.1562 1.31016 11.1562 0.84375C11.1562 0.377344 11.5336 0 12 0C13.6195 0 15.1922 0.316406 16.6711 0.944531C18.1008 1.54688 19.3828 2.41406 20.4844 3.51562C21.5859 4.61719 22.4508 5.90156 23.0555 7.32891C23.6813 8.80781 23.9977 10.3805 23.9977 12C24 12.4664 23.6227 12.8438 23.1563 12.8438Z" />
        </symbol>

        <symbol id="status_action" viewBox="0 0 24 24" fill="currentColor">
            <rect x="10" y="5" width="3" height="8" />
            <rect x="10" y="16" width="3" height="3" />
        </symbol>

        <symbol id="view_compact" viewBox="0 0 24 24" fill="currentColor">
            <rect x="6" y="8" width="4" height="1.5" />
            <rect x="6" y="14" width="4" height="1.5" />
            <rect x="14" y="8" width="4" height="1.5" />
            <rect x="14" y="14" width="4" height="1.5" />
        </symbol>

        <symbol id="view_list" viewBox="0 0 24 24" fill="currentColor">
            <rect x="6" y="8" width="12" height="1.5" />
            <rect x="6" y="14" width="12" height="1.5" />
        </symbol>

        <symbol id="view_expand" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M16.4 9.6H7.6V14.4H16.4V9.6ZM6 8V16H18V8H6Z" />
        </symbol>

        <symbol id="server" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M13.5724 2.33394C12.7762 1.88869 11.7952 1.88869 10.999 2.33394L4.28667 6.08767C3.49047 6.53292 3 7.35577 3 8.24627V15.7537C3 16.6442 3.49047 17.4671 4.28667 17.9123L10.999 21.6661C11.7952 22.1113 12.7762 22.1113 13.5724 21.6661L20.2848 17.9123C21.081 17.4671 21.5714 16.6442 21.5714 15.7537V8.24627C21.5714 7.35577 21.081 6.53292 20.2848 6.08767L13.5724 2.33394ZM5.32857 8.39212C5.01703 8.22665 4.90298 7.84788 5.07382 7.54613C5.24466 7.24437 5.6357 7.1339 5.94724 7.29938L12.2806 10.6635L18.4019 7.30363C18.711 7.13396 19.1036 7.23914 19.2787 7.53855C19.4539 7.83797 19.3453 8.21824 19.0362 8.38791L12.929 11.74V19.2699C12.929 19.6141 12.641 19.8931 12.2857 19.8931C11.9304 19.8931 11.6424 19.6141 11.6424 19.2699V11.7458L5.32857 8.39212Z" />
        </symbol>

        <symbol id="trigger" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M11.7895 2C8.59204 2 6 4.59204 6 7.78947V16.2105C6 19.408 8.59204 22 11.7895 22C14.9869 22 17.5789 19.408 17.5789 16.2105V7.78947C17.5789 4.59204 14.9869 2 11.7895 2ZM11.7605 12.6431C14.2635 12.6431 16.2926 10.614 16.2926 8.11094C16.2926 5.60789 14.2635 3.57878 11.7605 3.57878C9.25742 3.57878 7.2283 5.60789 7.2283 8.11094C7.2283 10.614 9.25742 12.6431 11.7605 12.6431Z" />
        </symbol>

        <symbol id="project" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M1.31937 14.9152C0.714123 15.7429 0.999735 16.9161 1.91767 17.373L10.8668 21.8267C11.3311 22.0578 11.8768 22.0578 12.341 21.8267L21.2902 17.373C22.2081 16.9161 22.4937 15.7429 21.8885 14.9152L12.9393 2.67782C12.2784 1.77406 10.9294 1.77406 10.2685 2.67782L1.31937 14.9152ZM12.2933 4.80629C12.2933 4.50172 12.0464 4.25482 11.7418 4.25482C11.4372 4.25482 11.1903 4.50172 11.1903 4.80629L11.1903 19.1445C11.1903 19.4491 11.4372 19.696 11.7418 19.696C12.0464 19.696 12.2933 19.4491 12.2933 19.1445L12.2933 4.80629Z" />
        </symbol>

        <symbol id="copy" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M2 10.8172C2 9.6295 2.96283 8.66667 4.15054 8.66667H13.1828C14.3705 8.66667 15.3333 9.6295 15.3333 10.8172V19.8495C15.3333 21.0372 14.3705 22 13.1828 22H4.15054C2.96283 22 2 21.0372 2 19.8495V10.8172Z" />
            <path d="M8.66667 4.15054C8.66667 2.96283 9.6295 2 10.8172 2H19.8495C21.0372 2 22 2.96283 22 4.15054V13.1828C22 14.3705 21.0372 15.3333 19.8495 15.3333H17.4839V8.66667C17.4839 7.47896 16.521 6.51613 15.3333 6.51613H8.66667V4.15054Z" />
        </symbol>

        <symbol id="update" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M16.9431 8.62512C15.8656 6.991 14.0136 5.91266 11.9097 5.91266C8.80857 5.91266 6.25465 8.2557 5.92164 11.2681H4.00004C4.33995 7.19761 7.75143 4 11.9097 4C14.778 4 17.2909 5.52133 18.6856 7.80108L19.9278 7.21361L19.5481 11.7756L15.7809 9.17474L16.9431 8.62512Z" />
            <path d="M6.98468 15.3749C8.06225 17.009 9.91423 18.0873 12.0181 18.0873C15.1193 18.0873 17.6732 15.7443 18.0062 12.7319H19.9278C19.5879 16.8024 16.1764 20 12.0181 20C9.14984 20 6.63692 18.4787 5.24222 16.1989L4 16.7864L4.37973 12.2244L8.14688 14.8253L6.98468 15.3749Z" />
        </symbol>

        <symbol id="mode_dark" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M19.6549 14.7166C19.5635 14.6733 19.458 14.671 19.3647 14.71H19.3683C15.502 16.3092 11.0714 14.4713 9.4722 10.605C8.66108 8.644 8.70968 6.43263 9.60617 4.50915C9.69039 4.33108 9.61429 4.11846 9.43622 4.03424C9.34482 3.99099 9.23934 3.98863 9.14607 4.02764C4.90787 5.76865 2.88344 10.6157 4.6244 14.8539C6.36536 19.0921 11.2124 21.1166 15.4507 19.3756C17.3837 18.5816 18.9458 17.0873 19.8249 15.1915C19.9091 15.0134 19.8329 14.8008 19.6549 14.7166Z" />
        </symbol>

        <symbol id="forecast" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M12.3172 4.16642C12.2098 3.94453 11.8823 3.94453 11.775 4.16642C10.2283 7.36418 7.5375 10.023 4.15939 11.7368C3.94687 11.8446 3.94687 12.1554 4.15939 12.2632C7.5375 13.977 10.2283 16.6358 11.775 19.8336C11.8823 20.0555 12.2098 20.0555 12.3172 19.8336C13.8639 16.6358 16.5549 13.977 19.9328 12.2632C20.1453 12.1554 20.1453 11.8446 19.9328 11.7368C16.5547 10.0229 13.8638 7.36418 12.3172 4.16642Z" />
        </symbol>

        <symbol id="graphic" viewBox="0 0 24 24" fill="currentColor">
            <path d="M2 20H21.8788V8.84848L17.0303 4L9.75758 11.2727L5.87879 7.39394L2 11.2727V20Z" />
        </symbol>

        <symbol id="mode_light" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M6.9375 12C6.9375 14.7915 9.20852 17.0625 12 17.0625C14.7915 17.0625 17.0625 14.7915 17.0625 12C17.0625 9.20852 14.7915 6.9375 12 6.9375C9.20852 6.9375 6.9375 9.20852 6.9375 12Z" />
            <path d="M12 5.8125C11.6893 5.8125 11.4375 5.56064 11.4375 5.25V3.5625C11.4375 3.25186 11.6893 3 12 3C12.3107 3 12.5625 3.25186 12.5625 3.5625V5.25C12.5625 5.56064 12.3107 5.8125 12 5.8125Z" />
            <path d="M12 21C11.6893 21 11.4375 20.7481 11.4375 20.4375V18.75C11.4375 18.4394 11.6893 18.1875 12 18.1875C12.3107 18.1875 12.5625 18.4394 12.5625 18.75V20.4375C12.5625 20.7481 12.3107 21 12 21Z" />
            <path d="M20.4375 12.5625H18.75C18.4393 12.5625 18.1875 12.3106 18.1875 12C18.1875 11.6894 18.4393 11.4375 18.75 11.4375H20.4375C20.7482 11.4375 21 11.6894 21 12C21 12.3106 20.7482 12.5625 20.4375 12.5625Z" />
            <path d="M5.25 12.5625H3.5625C3.25182 12.5625 3 12.3106 3 12C3 11.6894 3.25182 11.4375 3.5625 11.4375H5.25C5.56068 11.4375 5.8125 11.6894 5.8125 12C5.8125 12.3106 5.56068 12.5625 5.25 12.5625Z" />
            <path d="M16.773 7.78944C16.2764 7.78944 16.0199 7.18454 16.3753 6.82918L17.5685 5.63595C17.7882 5.41629 18.1444 5.41626 18.364 5.63595C18.5837 5.8556 18.5837 6.21177 18.364 6.43143L17.1708 7.62467C17.0609 7.73453 16.9169 7.78944 16.773 7.78944Z" />
            <path d="M6.03378 18.5287C5.53716 18.5287 5.28066 17.9238 5.63606 17.5684L6.8293 16.3752C7.04902 16.1555 7.40519 16.1555 7.62478 16.3752C7.84447 16.5948 7.84447 16.951 7.62478 17.1707L6.4315 18.3639C6.32167 18.4737 6.17774 18.5287 6.03378 18.5287Z" />
            <path d="M17.9662 18.5287C17.8223 18.5287 17.6783 18.4738 17.5685 18.3639L16.3753 17.1707C16.1556 16.951 16.1556 16.5949 16.3753 16.3752C16.5949 16.1555 16.9511 16.1555 17.1707 16.3752L18.364 17.5685C18.7193 17.9237 18.4628 18.5287 17.9662 18.5287Z" />
            <path d="M7.22701 7.78942C7.08304 7.78942 6.93911 7.73451 6.82929 7.62468L5.63601 6.43144C5.41632 6.21175 5.41632 5.85562 5.63601 5.63596C5.85567 5.41627 6.21184 5.41627 6.43149 5.63596L7.62473 6.8292C7.98013 7.18452 7.72363 7.78942 7.22701 7.78942Z" />
        </symbol>

        <symbol id="file" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" fillRule="evenodd" clipRule="evenodd">
            <path d="M14.7136 3.2636C14.5448 3.09481 14.3159 3 14.0772 3H5.9C5.40294 3 5 3.40294 5 3.9V20.1C5 20.5971 5.40294 21 5.9 21H18.65C19.1471 21 19.55 20.5971 19.55 20.1V8.4728C19.55 8.23411 19.4552 8.00519 19.2864 7.8364L14.7136 3.2636ZM7.55 11.25C7.55 10.8358 7.88578 10.5 8.3 10.5H16.25C16.6642 10.5 17 10.8358 17 11.25C17 11.6642 16.6642 12 16.25 12H8.3C7.88578 12 7.55 11.6642 7.55 11.25ZM8.3 15.15C7.88578 15.15 7.55 15.4858 7.55 15.9C7.55 16.3142 7.88578 16.65 8.3 16.65H11.9C12.3142 16.65 12.65 16.3142 12.65 15.9C12.65 15.4858 12.3142 15.15 11.9 15.15H8.3Z" />
        </symbol>

        <symbol id="restore" viewBox="0 0 24 24" fill="currentColor">
            <path d="M13.4882 8.13258L18.2398 8.12085L15.8741 4L15.1792 5.20362C14.1537 4.70633 13.0025 4.42746 11.7863 4.42746C7.48603 4.42746 4 7.91349 4 12.2137C4 16.514 7.48603 20 11.7863 20C16.0865 20 19.5725 16.514 19.5725 12.2137C19.5725 12.1962 19.5725 12.1786 19.5724 12.1611H17.7836C17.7837 12.1786 17.7838 12.1962 17.7838 12.2137C17.7838 15.5261 15.0986 18.2113 11.7863 18.2113C8.47392 18.2113 5.78874 15.5261 5.78874 12.2137C5.78874 8.90138 8.47392 6.21619 11.7863 6.21619C12.6767 6.21619 13.5218 6.41024 14.2816 6.75835L13.4882 8.13258Z" />
        </symbol>

        <symbol id="home" viewBox="0 0 24 24" fill="currentColor">
            <path d="M11.8043 4L5.00141 10.8029H5V20H9.28698V15.065H14.3715V20H18.6087V10.8029H18.6073L11.8043 4Z" />
        </symbol>

        <symbol id="close_border" viewBox="0 0 24 24" fill="currentColor">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.54982 5C5.69389 5 5 5.69389 5 6.54982V17.4502C5 18.3061 5.69389 19 6.54982 19H17.4502C18.3061 19 19 18.3061 19 17.4502V6.54982C19 5.69389 18.3061 5 17.4502 5H6.54982ZM8.91608 9.49277L9.58707 8.82288L12 11.232L14.4129 8.82288L15.0839 9.49277L12.671 11.9019L15.2804 14.5072L14.6095 15.1771L12 12.5718L9.39054 15.1771L8.71956 14.5072L11.329 11.9019L8.91608 9.49277Z" />
        </symbol>

        <symbol id="zoom_in" viewBox="0 0 24 24" fill="currentColor">
            <path d="M16.2083 11.848L17.3176 11.9927C17.3521 11.7284 17.3699 11.4587 17.3699 11.1849C17.3699 10.9111 17.3521 10.6415 17.3176 10.3771L16.2083 10.5218C16.2365 10.7384 16.2511 10.9597 16.2511 11.1849C16.2511 11.4102 16.2365 11.6315 16.2083 11.848Z" />
            <path d="M15.8672 9.24595L16.9005 8.81742C16.692 8.3146 16.4191 7.84524 16.0921 7.41964L15.205 8.10131C15.4732 8.45028 15.6966 8.83468 15.8672 9.24595Z" />
            <path d="M14.2686 7.16487L14.9502 6.27781C14.5246 5.95076 14.0553 5.67783 13.5525 5.46932L13.1239 6.50271C13.5352 6.67325 13.9196 6.8967 14.2686 7.16487Z" />
            <path d="M11.848 6.1616L11.9927 5.05228C11.7284 5.01779 11.4587 5 11.1849 5C10.9111 5 10.6415 5.01779 10.3771 5.05228L10.5218 6.1616C10.7384 6.13336 10.9597 6.11872 11.1849 6.11872C11.4102 6.11872 11.6315 6.13336 11.848 6.1616Z" />
            <path d="M9.24595 6.50271L8.81742 5.46932C8.3146 5.67783 7.84524 5.95076 7.41964 6.27781L8.10131 7.16487C8.45028 6.8967 8.83468 6.67325 9.24595 6.50271Z" />
            <path d="M7.16487 8.10131L6.27781 7.41964C5.95076 7.84524 5.67783 8.3146 5.46932 8.81742L6.50271 9.24595C6.67325 8.83468 6.8967 8.45028 7.16487 8.10131Z" />
            <path d="M5 11.1849C5 10.9111 5.01779 10.6415 5.05228 10.3771L6.1616 10.5218C6.13336 10.7384 6.11872 10.9597 6.11872 11.1849C6.11872 11.4102 6.13336 11.6315 6.1616 11.848L5.05228 11.9927C5.01779 11.7284 5 11.4587 5 11.1849Z" />
            <path d="M6.50271 13.1239L5.46932 13.5525C5.67783 14.0553 5.95076 14.5246 6.27781 14.9502L7.16487 14.2686C6.8967 13.9196 6.67325 13.5352 6.50271 13.1239Z" />
            <path d="M8.10131 15.205L7.41964 16.0921C7.84524 16.4191 8.3146 16.692 8.81742 16.9005L9.24595 15.8672C8.83468 15.6966 8.45028 15.4732 8.10131 15.205Z" />
            <path d="M10.5218 16.2083L10.3771 17.3176C10.6415 17.3521 10.9111 17.3699 11.1849 17.3699C11.4587 17.3699 11.7284 17.3521 11.9927 17.3176L11.848 16.2083C11.6315 16.2365 11.4102 16.2511 11.1849 16.2511C10.9597 16.2511 10.7384 16.2365 10.5218 16.2083Z" />
            <path d="M13.1239 15.8672L13.5525 16.9005C14.0553 16.692 14.5246 16.4191 14.9502 16.0921L14.2686 15.205C13.9196 15.4732 13.5352 15.6966 13.1239 15.8672Z" />
            <path d="M15.205 14.2686L16.0921 14.9502C16.4191 14.5246 16.692 14.0553 16.9005 13.5525L15.8672 13.1239C15.6966 13.5352 15.4732 13.9196 15.205 14.2686Z" />
            <path d="M15.9326 15.9989C15.6258 16.3054 15.6258 16.8024 15.9326 17.1089L17.5951 18.7701C17.9018 19.0766 18.3992 19.0766 18.706 18.7701C19.0127 18.4636 19.0127 17.9666 18.706 17.6601L17.0435 15.9989C16.7367 15.6923 16.2394 15.6923 15.9326 15.9989Z" />
        </symbol>

        <symbol id="zoom_out" viewBox="0 0 24 24" fill="currentColor">
            <path d="M13.5255 12.1253L9 12.1253L11.2627 14.388L13.5255 12.1253Z" />
            <path d="M9 10.0625L13.5255 10.0625L11.2627 7.7998L9 10.0625Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.1602 17.3343C14.5494 17.3214 17.2866 14.5499 17.2738 11.1439C17.2612 7.73784 14.5031 4.98716 11.1139 5.00005C7.72438 5.01291 4.98721 7.78447 5.00004 11.1905C5.01288 14.5965 7.77097 17.3472 11.1602 17.3343ZM15.7858 11.1429C15.7858 13.707 13.7071 15.7857 11.1429 15.7857C8.57873 15.7857 6.50005 13.707 6.50005 11.1429C6.50005 8.57868 8.57873 6.49999 11.1429 6.49999C13.7071 6.49999 15.7858 8.57868 15.7858 11.1429Z" />
            <path d="M15.8709 15.9989C15.5656 16.3054 15.5656 16.8024 15.8709 17.1089L17.5238 18.7701C17.8287 19.0766 18.3234 19.0766 18.6284 18.7701C18.9334 18.4636 18.9334 17.9666 18.6284 17.6601L16.9752 15.9989C16.6703 15.6923 16.1758 15.6923 15.8709 15.9989Z" />
        </symbol>

        <symbol id="minimize" viewBox="0 0 24 24" fill="currentColor">
            <path d="M13.7 7L18.1 11.4H13.7V7Z" />
            <path d="M10.4 7L6 11.4H10.4V7Z" />
            <path d="M13.7 18L18.1 13.6H13.7V18Z" />
            <path d="M10.4 18L6 13.6H10.4V18Z" />
        </symbol>

        <symbol id="maximize" viewBox="0 0 24 24" fill="currentColor">
            <path d="M19.1477 9.70164L14.9461 5.5H19.1477V9.70164Z" />
            <path d="M5 9.70164L9.20164 5.5H5V9.70164Z" />
            <path d="M19.1477 15.2984L14.9461 19.5H19.1477V15.2984Z" />
            <path d="M5 15.2984L9.20164 19.5H5V15.2984Z" />
        </symbol>

        <symbol id="curve" viewBox="0 0 24 24" fill="currentColor">
            <path d="M18.5329 16H20.4706C20.4706 11.5817 16.7835 8 12.2353 8C7.68707 8 4 11.5817 4 16H5.93772C5.93772 12.6213 8.75724 9.88235 12.2353 9.88235C15.7134 9.88235 18.5329 12.6213 18.5329 16Z" />
        </symbol>

        <symbol id="popover" viewBox="0 0 48 48" fill="currentColor">
            <path d="M7.70158 36.0151H4.96077C4.46946 34.2984 4.20635 32.4853 4.20635 30.6107C4.20635 28.1621 4.6553 25.8183 5.47539 23.6572L7.92319 24.5861C7.21397 26.455 6.82447 28.4844 6.82447 30.6107C6.82447 32.504 7.13326 34.3204 7.70158 36.0151Z" fill="#525252" />
            <path d="M42.6583 36.0151H39.9175C40.4858 34.3204 40.7946 32.504 40.7946 30.6107C40.7946 28.4844 40.4051 26.455 39.6959 24.5861L42.1437 23.6572C42.9637 25.8183 43.4127 28.1621 43.4127 30.6107C43.4127 32.4853 43.1496 34.2984 42.6583 36.0151Z" fill="#525252" />
            <path d="M41.69 22.5632L39.3032 23.6391C37.6012 19.8635 34.5567 16.8191 30.7812 15.1171L31.8571 12.7303C36.2149 14.6947 39.7256 18.2054 41.69 22.5632Z" fill="#525252" />
            <path d="M30.7631 12.2766L29.8342 14.7244C27.9652 14.0152 25.9359 13.6257 23.8095 13.6257C21.6831 13.6257 19.6538 14.0152 17.7849 14.7244L16.856 12.2766C19.0171 11.4565 21.3609 11.0076 23.8095 11.0076C26.2582 11.0076 28.602 11.4565 30.7631 12.2766Z" fill="#525252" />
            <path d="M15.7619 12.7303L16.8379 15.1171C13.0623 16.8191 10.0178 19.8635 8.31589 23.6391L5.92907 22.5632C7.89347 18.2054 11.4042 14.6947 15.7619 12.7303Z" fill="#525252" />
            <path d="M30.7631 12.2766L29.8342 14.7244C27.9652 14.0152 25.9359 13.6257 23.8095 13.6257C21.6831 13.6257 19.6538 14.0152 17.7849 14.7244L16.856 12.2766C19.0171 11.4565 21.3609 11.0076 23.8095 11.0076C26.2582 11.0076 28.602 11.4565 30.7631 12.2766Z" fill="#1C42EA" />
            <path d="M7.70158 36.0151H4.96077C4.46946 34.2984 4.20635 32.4853 4.20635 30.6107C4.20635 28.1621 4.6553 25.8183 5.47539 23.6572L7.92319 24.5861C7.21397 26.455 6.82447 28.4844 6.82447 30.6107C6.82447 32.504 7.13326 34.3204 7.70158 36.0151Z" fill="#246FFF" />
            <path d="M15.7619 12.7303L16.8379 15.1171C13.0623 16.8191 10.0178 19.8635 8.31589 23.6391L5.92907 22.5632C7.89347 18.2054 11.4042 14.6947 15.7619 12.7303Z" fill="#246FFF" />
            <path d="M5.47539 23.6572C4.6553 25.8183 4.20635 28.1621 4.20635 30.6108C4.20635 32.4853 4.46946 34.2984 4.96077 36.0151H7.70158C7.13326 34.3204 6.82447 32.5041 6.82447 30.6108C6.82447 28.4844 7.21397 26.4551 7.92319 24.5861L5.47539 23.6572Z" fill="#57D5F3" />
        </symbol>

        <symbol id="add_user" viewBox="0 0 24 24" fill="currentColor">
            <path d="M14.9167 6H16.0833V13H14.9167V6Z" />
            <path d="M12 10.1177V8.95099L19 8.95099V10.1177L12 10.1177Z" />
            <path d="M6.5 11.5319C7.81575 11.5319 8.88235 9.45529 8.88235 8.22594C8.88235 6.99659 7.81575 6 6.5 6C5.18427 6 4.11765 6.99659 4.11765 8.22594C4.11765 9.45529 5.18427 11.5319 6.5 11.5319Z" />
            <path d="M6.5 19C8.9853 19 11 17.1136 11 15.1277C11 13.1418 8.9853 12.6383 6.5 12.6383C4.01472 12.6383 2 13.1418 2 15.1277C2 17.1136 4.01472 19 6.5 19Z" />
        </symbol>
        <symbol id="coin_fall" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path d="M14.5309 15.7914L14.0562 17.563C13.869 18.2618 11.0205 18.1058 7.69392 17.2145C4.36735 16.3231 1.82245 15.034 2.00971 14.3351L2.48439 12.5636C2.67165 11.8647 5.52016 12.0208 8.84672 12.9121C12.1733 13.8035 14.7182 15.0926 14.5309 15.7914Z" />
            <path d="M9.1364 8.66487L9.61108 10.4364C9.79834 11.1353 12.6469 10.9792 15.9734 10.0879C19.3 9.19653 21.8449 7.90742 21.6576 7.20856L21.1829 5.43701C20.9957 4.73815 18.1472 4.8942 14.8206 5.78555C11.494 6.6769 8.94914 7.96601 9.1364 8.66487Z" />
        </symbol>

    </svg>
);

export default Icons;
