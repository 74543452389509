// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ILoginForm {
    password: string;
    remember: boolean;
    username: string;
}

export default class LoginForm {
    readonly _password: string;

    /**
     * Description: Password
     * Example: 12345678
     */
    get password(): string {
        return this._password;
    }

    static passwordValidate(password: string): boolean {
        return typeof password === 'string' && !!password.trim();
    }

    readonly _remember: boolean;

    /** */
    get remember(): boolean {
        return this._remember;
    }

    static rememberValidate(remember: boolean): boolean {
        return typeof remember === 'boolean';
    }

    readonly _username: string;

    /**
     * Description: Client email
     * Example: user@serveroid.com
     */
    get username(): string {
        return this._username;
    }

    static usernameValidate(username: string): boolean {
        return typeof username === 'string' && !!username.trim();
    }

    constructor(props: ILoginForm) {
        this._password = props.password.trim();
        this._remember = props.remember;
        this._username = props.username.trim();
    }

    serialize(): ILoginForm {
        const data: ILoginForm = {
            password: this._password,
            remember: this._remember,
            username: this._username,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            username: typeof this._username === 'string' && !!this._username.trim(),
            password: typeof this._password === 'string' && !!this._password.trim(),
            remember: typeof this._remember === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ILoginForm): LoginForm {
        return new LoginForm(props);
    }

    readonly keys: { [key: string]: string } = {
        password: 'password',
        remember: 'remember',
        username: 'username',
        }
;

    mergeDeepWith(props: Partial<LoginForm>): LoginForm {
        const updateData: Partial<ILoginForm> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof LoginForm) => {
            const updateKey = this.keys[key] as keyof ILoginForm;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ILoginForm, keyof ILoginForm>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new LoginForm({ ...this.serialize(), ...updateData });
    }
}
