import React from 'react';
import cn from 'classnames';

import theme from 'Lib/theme';

const mediumText = (text: string) => (
    <span
        className={cn(
            theme.color.black,
            theme.text.medium,
        )}
    >
        {text}
    </span>
);

export default mediumText;
