import { EventNotificationType } from './EventNotificationType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISmsDeliveryPeriod {
    event_type: EventNotificationType;
    time_from_hours: number;
    time_from_minutes: number;
    time_to_hours: number;
    time_to_minutes: number;
}

export default class SmsDeliveryPeriod {
    readonly _event_type: EventNotificationType;

    get eventType(): EventNotificationType {
        return this._event_type;
    }

    static eventTypeValidate(eventType: EventNotificationType): boolean {
        return Object.keys(EventNotificationType).includes(eventType);
    }

    readonly _time_from_hours: number;

    /**
     * Description: Sms delivery hours from
     * Example: 10
     */
    get timeFromHours(): number {
        return this._time_from_hours;
    }

    static get timeFromHoursMinValue() {
        return 0;
    }

    static get timeFromHoursMaxValue() {
        return 24;
    }

    static timeFromHoursValidate(timeFromHours: number): boolean {
        return timeFromHours >= 0 && timeFromHours <= 24;
    }

    readonly _time_from_minutes: number;

    /** */
    get timeFromMinutes(): number {
        return this._time_from_minutes;
    }

    static get timeFromMinutesMinValue() {
        return 0;
    }

    static get timeFromMinutesMaxValue() {
        return 59;
    }

    static timeFromMinutesValidate(timeFromMinutes: number): boolean {
        return timeFromMinutes >= 0 && timeFromMinutes <= 59;
    }

    readonly _time_to_hours: number;

    /**
     * Description: Sms delivery hours to
     * Example: 22
     */
    get timeToHours(): number {
        return this._time_to_hours;
    }

    static get timeToHoursMinValue() {
        return 0;
    }

    static get timeToHoursMaxValue() {
        return 24;
    }

    static timeToHoursValidate(timeToHours: number): boolean {
        return timeToHours >= 0 && timeToHours <= 24;
    }

    readonly _time_to_minutes: number;

    /** */
    get timeToMinutes(): number {
        return this._time_to_minutes;
    }

    static get timeToMinutesMinValue() {
        return 0;
    }

    static get timeToMinutesMaxValue() {
        return 59;
    }

    static timeToMinutesValidate(timeToMinutes: number): boolean {
        return timeToMinutes >= 0 && timeToMinutes <= 59;
    }

    constructor(props: ISmsDeliveryPeriod) {
        this._event_type = props.event_type;
        this._time_from_hours = props.time_from_hours;
        this._time_from_minutes = props.time_from_minutes;
        this._time_to_hours = props.time_to_hours;
        this._time_to_minutes = props.time_to_minutes;
    }

    serialize(): ISmsDeliveryPeriod {
        const data: ISmsDeliveryPeriod = {
            event_type: this._event_type,
            time_from_hours: this._time_from_hours,
            time_from_minutes: this._time_from_minutes,
            time_to_hours: this._time_to_hours,
            time_to_minutes: this._time_to_minutes,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            time_from_hours: this._time_from_hours >= 0 && this._time_from_hours <= 24,
            time_from_minutes: this._time_from_minutes >= 0 && this._time_from_minutes <= 59,
            time_to_hours: this._time_to_hours >= 0 && this._time_to_hours <= 24,
            time_to_minutes: this._time_to_minutes >= 0 && this._time_to_minutes <= 59,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISmsDeliveryPeriod): SmsDeliveryPeriod {
        return new SmsDeliveryPeriod(props);
    }

    readonly keys: { [key: string]: string } = {
        eventType: 'event_type',
        timeFromHours: 'time_from_hours',
        timeFromMinutes: 'time_from_minutes',
        timeToHours: 'time_to_hours',
        timeToMinutes: 'time_to_minutes',
        }
;

    mergeDeepWith(props: Partial<SmsDeliveryPeriod>): SmsDeliveryPeriod {
        const updateData: Partial<ISmsDeliveryPeriod> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SmsDeliveryPeriod) => {
            const updateKey = this.keys[key] as keyof ISmsDeliveryPeriod;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISmsDeliveryPeriod, keyof ISmsDeliveryPeriod>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SmsDeliveryPeriod({ ...this.serialize(), ...updateData });
    }
}
