import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import { FormikHelpers } from 'formik';
import cn from 'classnames';

import ResellersApi from 'Apis/resellers';
import TenantApi from 'Apis/tenants';
import { serverClone } from 'Actions/server';
import { getResellerClients, resellerServerClone } from 'Actions/reseller';
import { getSnapshotList } from 'Actions/snapshots';
import { useIntl, FormModalLayout, Input, Select, Option } from 'Common';
import Server from 'Entities/Server';
import ServerClone from 'Entities/ServerClone';
import { IServerCloneAsReseller } from 'Entities/ServerCloneAsReseller';
import Tenant, { ITenant } from 'Entities/Tenant';
import { DistributionFamily } from 'Entities/DistributionFamily';
import Error from 'Entities/Error';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { REGULAR_CHARACTER_LIMIT } from 'Lib/helpers/consts';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { errorChecker } from 'Lib/helpers/utils';
import { Store } from 'Store';
import theme from 'Lib/theme';

import ModalWindowsClone from './ModalWindowsClone';

const SNAPSHOTS_CURRENT_STATE = 'current';

const selectProfile = (store: Store) => store.profile.info;
const selectAccount = (store: Store) => store.account.account;
const selectMainAccount = (store: Store) => store.mainAccount.account;
const selectTenant = (store: Store) => Array.from(store.tenant.values());
const selectServers = (store: Store) => Array.from(store.server.values());
const selectSnapshots = (store: Store) => Array.from(store.snapshots.snapshots.values());
const selectResellerClients = (store: Store) => store.reseller.clients;

const selector = createSelector([
    selectProfile,
    selectAccount,
    selectMainAccount,
    selectTenant,
    selectServers,
    selectSnapshots,
    selectResellerClients,
], (profile, account, mainAccount, tenants, servers, snapshots, clients) => ({
    profile,
    account,
    mainAccount,
    tenants,
    servers,
    snapshots,
    clients,
}));

interface ModalCloneServerProps {
    server: Server;
    close: () => void;
}

interface FormValues {
    name: string;
    to_tenant_id: number;
    snapshot_id: number | typeof SNAPSHOTS_CURRENT_STATE;
    from_client_id: number;
    to_client_id: number;
}

const ModalCloneServer: FC<ModalCloneServerProps> = ({
    close,
    server,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        profile,
        account,
        mainAccount,
        snapshots,
        tenants,
        servers,
        clients,
    } = useSelector(selector);
    const { tenantId, id, distribution } = server;
    const [clientTenants, setClientTenants] = useState(tenants);
    const resellerClone = profile?.reseller || MAIN_TOKEN;

    useEffect(() => {
        if (resellerClone) {
            dispatch(getResellerClients([MAIN_TOKEN || AUTH_TOKEN]));
        }
    }, []);

    useEffect(() => {
        dispatch(getSnapshotList([tenantId, id]));
    }, [tenantId, id]);

    if (!account) {
        return null;
    }

    if (distribution.type === DistributionFamily.WINDOWS) {
        return <ModalWindowsClone close={close} />;
    }

    const handleNameError = (
        error: Error,
        helpers: FormikHelpers<FormValues>,
    ) => {
        const { fields } = error;
        if (fields.name) {
            helpers.setFieldError('name', apiErrorCodeTranslate(intl, fields.name.error_code));
        }
    };

    const saveTenantsList = (
        result: ITenant[],
        setFieldValue: (field: string, value: number, shouldValidate?: boolean) => void,
    ) => {
        const tenantList: Tenant[] = [];
        result.forEach((rs) => {
            tenantList.push(new Tenant(rs));
        });
        setClientTenants(tenantList);
        setFieldValue('to_tenant_id', tenantList[0].id);
    };

    const getClientTenants = async (
        clientId: number,
        setFieldValue: (field: string, value: number, shouldValidate?: boolean) => void,
    ) => {
        if (MAIN_TOKEN && clientId === mainAccount?.clientId) {
            const resp = await TenantApi.listTenants(MAIN_TOKEN);
            const { result } = errorChecker<ITenant[]>(resp);
            if (result) {
                saveTenantsList(result, setFieldValue);
            }
        } else if (clientId === account.clientId && profile?.reseller) {
            setClientTenants(tenants);
            setFieldValue('to_tenant_id', tenantId);
        } else {
            const resp = await ResellersApi.getResellerClientTenants(clientId);
            const { result } = errorChecker<ITenant[]>(resp);
            if (result) {
                saveTenantsList(result, setFieldValue);
            }
        }
    };

    const handleSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        helpers.setSubmitting(true);

        const commonData = {
            name: values.name,
            snapshot_id: values.snapshot_id !== SNAPSHOTS_CURRENT_STATE ? values.snapshot_id : undefined,
            to_tenant_id: values.to_tenant_id,
        };

        if (values.to_client_id !== account.clientId) {
            const resellerData: IServerCloneAsReseller = {
                ...commonData,
                from_client_id: values.from_client_id,
                to_client_id: values.to_client_id,
            };

            dispatch(resellerServerClone([tenantId, id, resellerData], {
                result: () => {
                    close();
                    history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
                    helpers.setSubmitting(false);
                },
                error: (error: Error) => {
                    helpers.setSubmitting(false);
                    handleNameError(error, helpers);
                },
            }));
        } else {
            dispatch(serverClone([tenantId, id, commonData], {
                result: () => {
                    close();
                    history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
                    helpers.setSubmitting(false);
                },
                error: (error: Error) => {
                    helpers.setSubmitting(false);
                    handleNameError(error, helpers);
                },
            }));
        }
    };

    const templateName = `${server.name} - copy`;
    const probableCopies = servers.filter((s) => s.name.includes(templateName));

    const initialValues: FormValues = {
        name: `${templateName}${probableCopies.length > 0 ? ` (${probableCopies.length})` : ''}`,
        to_tenant_id: tenantId,
        snapshot_id: SNAPSHOTS_CURRENT_STATE,
        from_client_id: account.clientId,
        to_client_id: account.clientId,
    };

    return (
        <FormModalLayout
            visible
            initialValues={initialValues}
            title={intl.getMessage('clone_server')}
            buttonText={intl.getMessage('clone')}
            handleSubmit={handleSubmit}
            handleClose={close}
            width={480}
        >
            {({
                values,
                errors,
                setFieldValue,
            }) => (
                <>
                    <div className={cn(theme.modal.desc, theme.modal.desc_notice)}>
                        {intl.getMessage('modal_clone_server')}
                    </div>
                    <div className={theme.modal.desc}>
                        <div className={theme.modal.label}>
                            {intl.getPlural('server_name_symbols', REGULAR_CHARACTER_LIMIT)}
                        </div>
                        <Input
                            type="text"
                            name="name"
                            size="large"
                            placeholder={intl.getMessage('server_name_title')}
                            onChange={(v) => setFieldValue('name', v)}
                            value={values.name}
                            error={!!errors.name}
                            validate={(v) => ServerClone.nameValidate(v)}
                            autoFocus
                        />
                    </div>

                    <div className={theme.modal.desc}>
                        <div className={theme.modal.label}>
                            {intl.getMessage('modal_clone_server_choose_object')}
                        </div>
                        <Select
                            size="big"
                            onChange={(v) => setFieldValue('snapshot_id', v)}
                            value={values.snapshot_id}
                            error={!!errors.snapshot_id}
                            dropdownClassName="select-dropdown"
                            block
                        >
                            <Option value={SNAPSHOTS_CURRENT_STATE}>
                                {intl.getMessage('snapshots_current_state')}
                            </Option>
                            {snapshots.map((s) => (
                                <Option value={s.id} key={s.id}>
                                    {s.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    {resellerClone && (
                        <div className={theme.modal.desc}>
                            <div className={theme.modal.label}>
                                {intl.getMessage('modal_clone_account')}
                            </div>
                            <Select
                                size="big"
                                value={values.to_client_id}
                                error={!!errors.to_client_id}
                                onChange={(v) => {
                                    getClientTenants(v, setFieldValue);
                                    setFieldValue('to_client_id', v);
                                }}
                                dropdownClassName="select-dropdown"
                                center
                                block
                            >
                                {mainAccount ? (
                                    <Option value={mainAccount.clientId} className="option option_align">
                                        {mainAccount.email}

                                        <span className={cn(theme.Badge.badge, theme.Badge.option)}>
                                            {intl.getMessage('studio')}
                                        </span>
                                    </Option>
                                ) : (
                                    <Option value={account.clientId} className="option option_align">
                                        {account.email}

                                        <span className={cn(theme.Badge.badge, theme.Badge.option)}>
                                            {intl.getMessage('current_account')}
                                        </span>
                                    </Option>
                                )}
                                {clients.map((client) => {
                                    if (client.accountBlocked || client.emailNotConfirmed) {
                                        return null;
                                    }

                                    return (
                                        <Option value={client.clientId} key={client.clientId} className="option option_align">
                                            {client.email}

                                            <span className={cn(theme.Badge.badge, theme.Badge.option)}>
                                                {client.clientId === account.clientId ? (
                                                    intl.getMessage('current_account')
                                                ) : (
                                                    intl.getMessage('clients_client')
                                                )}
                                            </span>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}

                    <div className={theme.modal.desc}>
                        <div className={theme.modal.label}>
                            {intl.getMessage('modal_clone_server_choose_project')}
                        </div>
                        <Select
                            size="big"
                            value={values.to_tenant_id}
                            error={!!errors.to_tenant_id}
                            onChange={(v) => setFieldValue('to_tenant_id', v)}
                            dropdownClassName="select-dropdown"
                            block
                        >
                            {clientTenants.filter((t) => t.id !== account.clientId).map((t) => (
                                <Option value={t.id} key={t.id}>
                                    {t.description}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </>
            )}
        </FormModalLayout>
    );
};

export default ModalCloneServer;
