import React, { FC } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { Divider } from 'antd';

import { useIntl, Link } from 'Common';
import { updateServerRecommendations } from 'Actions/server';
import { RoutePath } from 'Lib/helpers/routes';

import theme from 'Lib/theme';
import s from './RecommendationsChooseContent.module.pcss';
import { ContentType } from './RecommendationsChooseContent';

interface RecommendationsCancelledContentProps {
    tenantId: number;
    serverId: number;
    setContentType: (type: ContentType) => void;
    serverListPage: boolean;
}

const RecommendationsCancelledContent: FC<RecommendationsCancelledContentProps> = ({
    tenantId, serverId, setContentType, serverListPage,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const turnOnServerRecommendations = (idTenant: number, idServer: number, refresh = true) => {
        dispatch(updateServerRecommendations([idTenant, idServer, refresh]));
    };

    return (
        <div className={cn(theme.popover.popover, s.popover, { [s.fullWidth]: !serverListPage })}>
            <div className={s.descWrapper}>
                <div className={s.desc}>
                    {intl.getMessage('cancel_recommendations_title')}
                    <div className={s.inlineButtonWrapper}>
                        <button
                            type="button"
                            className={cn(s.button, s.inlineButton)}
                            onClick={() => {
                                turnOnServerRecommendations(tenantId, serverId);
                                setContentType(ContentType.SERVER_DEFAULT);
                            }}
                        >
                            {intl.getMessage('cancel')}
                        </button>
                    </div>
                </div>
            </div>
            <div className={s.dividerWrapper}>
                <Divider className={s.divider} />
            </div>
            <Link to={RoutePath.Profile}>
                <button
                    type="button"
                    className={s.button}
                >
                    {intl.getMessage('set_recommendations_button')}
                </button>
            </Link>
        </div>
    );
};

export default RecommendationsCancelledContent;
