// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountLimits {
    current_servers_count: number;
    max_active_subscriptions: number;
    max_cash_payment_amount: number;
    max_current_servers_count: number;
    max_nocash_payment_amount: number;
    max_overall_memory_mib: number;
    max_overall_servers_count: number;
    max_top_up_amount: number;
    max_top_up_balance: number;
    min_cash_payment_amount: number;
    min_nocash_payment_amount: number;
    min_top_up_amount: number;
    min_top_up_balance: number;
    overall_memory_mib: number;
    overall_servers_count: number;
}

export default class AccountLimits {
    readonly _current_servers_count: number;

    /**
     * Description: Client's current number of servers
     * Example: 1
     */
    get currentServersCount(): number {
        return this._current_servers_count;
    }

    static currentServersCountValidate(currentServersCount: number): boolean {
        return typeof currentServersCount === 'number';
    }

    readonly _max_active_subscriptions: number;

    /**
     * Description: Constant value for maximum number of active subscriptions
     * Example: 3
     */
    get maxActiveSubscriptions(): number {
        return this._max_active_subscriptions;
    }

    static maxActiveSubscriptionsValidate(maxActiveSubscriptions: number): boolean {
        return typeof maxActiveSubscriptions === 'number';
    }

    readonly _max_cash_payment_amount: number;

    /**
     * Description: Maximum possible payment amount, RUB
     * Example: 100
     */
    get maxCashPaymentAmount(): number {
        return this._max_cash_payment_amount;
    }

    static maxCashPaymentAmountValidate(maxCashPaymentAmount: number): boolean {
        return typeof maxCashPaymentAmount === 'number';
    }

    readonly _max_current_servers_count: number;

    /**
     * Description: Constant value for maximum number of current servers for test client, -1 for paid clients
     * Example: 3
     */
    get maxCurrentServersCount(): number {
        return this._max_current_servers_count;
    }

    static maxCurrentServersCountValidate(maxCurrentServersCount: number): boolean {
        return typeof maxCurrentServersCount === 'number';
    }

    readonly _max_nocash_payment_amount: number;

    /**
     * Description: Maximum possible payment amount via cashless payment systems, RUB
     * Example: 200
     */
    get maxNocashPaymentAmount(): number {
        return this._max_nocash_payment_amount;
    }

    static maxNocashPaymentAmountValidate(maxNocashPaymentAmount: number): boolean {
        return typeof maxNocashPaymentAmount === 'number';
    }

    readonly _max_overall_memory_mib: number;

    /**
     * Description: Constant value for maximum overall server memory for test client, -1 for paid clients
     * Example: 8192
     */
    get maxOverallMemoryMib(): number {
        return this._max_overall_memory_mib;
    }

    static maxOverallMemoryMibValidate(maxOverallMemoryMib: number): boolean {
        return typeof maxOverallMemoryMib === 'number';
    }

    readonly _max_overall_servers_count: number;

    /**
     * Description: Constant value for maximum overall number of servers for test client, -1 for paid clients
     * Example: 6
     */
    get maxOverallServersCount(): number {
        return this._max_overall_servers_count;
    }

    static maxOverallServersCountValidate(maxOverallServersCount: number): boolean {
        return typeof maxOverallServersCount === 'number';
    }

    readonly _max_top_up_amount: number;

    /**
     * Description: Maximum payment amount for Automatic top-up feature, RUB
     * Example: 200
     */
    get maxTopUpAmount(): number {
        return this._max_top_up_amount;
    }

    static maxTopUpAmountValidate(maxTopUpAmount: number): boolean {
        return typeof maxTopUpAmount === 'number';
    }

    readonly _max_top_up_balance: number;

    /**
     * Description: Maximum balance threshold for Automatic top-up feature, RUB
     * Example: 100
     */
    get maxTopUpBalance(): number {
        return this._max_top_up_balance;
    }

    static maxTopUpBalanceValidate(maxTopUpBalance: number): boolean {
        return typeof maxTopUpBalance === 'number';
    }

    readonly _min_cash_payment_amount: number;

    /**
     * Description: Minimal possible payment amount, RUB
     * Example: 10
     */
    get minCashPaymentAmount(): number {
        return this._min_cash_payment_amount;
    }

    static minCashPaymentAmountValidate(minCashPaymentAmount: number): boolean {
        return typeof minCashPaymentAmount === 'number';
    }

    readonly _min_nocash_payment_amount: number;

    /**
     * Description: Minimal possible payment amount via cashless payment systems, RUB
     * Example: 10
     */
    get minNocashPaymentAmount(): number {
        return this._min_nocash_payment_amount;
    }

    static minNocashPaymentAmountValidate(minNocashPaymentAmount: number): boolean {
        return typeof minNocashPaymentAmount === 'number';
    }

    readonly _min_top_up_amount: number;

    /**
     * Description: Minimal payment amount for Automatic top-up feature, RUB
     * Example: 10
     */
    get minTopUpAmount(): number {
        return this._min_top_up_amount;
    }

    static minTopUpAmountValidate(minTopUpAmount: number): boolean {
        return typeof minTopUpAmount === 'number';
    }

    readonly _min_top_up_balance: number;

    /**
     * Description: Minimal balance threshold for Automatic top-up feature, RUB
     * Example: 1
     */
    get minTopUpBalance(): number {
        return this._min_top_up_balance;
    }

    static minTopUpBalanceValidate(minTopUpBalance: number): boolean {
        return typeof minTopUpBalance === 'number';
    }

    readonly _overall_memory_mib: number;

    /**
     * Description: Overall memory allocated for client's installed servers. When trying to install new server, add its memory to this value and check limits
     * Example: 8192
     */
    get overallMemoryMib(): number {
        return this._overall_memory_mib;
    }

    static overallMemoryMibValidate(overallMemoryMib: number): boolean {
        return typeof overallMemoryMib === 'number';
    }

    readonly _overall_servers_count: number;

    /**
     * Description: Client's overall number of servers
     * Example: 4
     */
    get overallServersCount(): number {
        return this._overall_servers_count;
    }

    static overallServersCountValidate(overallServersCount: number): boolean {
        return typeof overallServersCount === 'number';
    }

    constructor(props: IAccountLimits) {
        this._current_servers_count = props.current_servers_count;
        this._max_active_subscriptions = props.max_active_subscriptions;
        this._max_cash_payment_amount = props.max_cash_payment_amount;
        this._max_current_servers_count = props.max_current_servers_count;
        this._max_nocash_payment_amount = props.max_nocash_payment_amount;
        this._max_overall_memory_mib = props.max_overall_memory_mib;
        this._max_overall_servers_count = props.max_overall_servers_count;
        this._max_top_up_amount = props.max_top_up_amount;
        this._max_top_up_balance = props.max_top_up_balance;
        this._min_cash_payment_amount = props.min_cash_payment_amount;
        this._min_nocash_payment_amount = props.min_nocash_payment_amount;
        this._min_top_up_amount = props.min_top_up_amount;
        this._min_top_up_balance = props.min_top_up_balance;
        this._overall_memory_mib = props.overall_memory_mib;
        this._overall_servers_count = props.overall_servers_count;
    }

    serialize(): IAccountLimits {
        const data: IAccountLimits = {
            current_servers_count: this._current_servers_count,
            max_active_subscriptions: this._max_active_subscriptions,
            max_cash_payment_amount: this._max_cash_payment_amount,
            max_current_servers_count: this._max_current_servers_count,
            max_nocash_payment_amount: this._max_nocash_payment_amount,
            max_overall_memory_mib: this._max_overall_memory_mib,
            max_overall_servers_count: this._max_overall_servers_count,
            max_top_up_amount: this._max_top_up_amount,
            max_top_up_balance: this._max_top_up_balance,
            min_cash_payment_amount: this._min_cash_payment_amount,
            min_nocash_payment_amount: this._min_nocash_payment_amount,
            min_top_up_amount: this._min_top_up_amount,
            min_top_up_balance: this._min_top_up_balance,
            overall_memory_mib: this._overall_memory_mib,
            overall_servers_count: this._overall_servers_count,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            max_active_subscriptions: typeof this._max_active_subscriptions === 'number',
            max_current_servers_count: typeof this._max_current_servers_count === 'number',
            current_servers_count: typeof this._current_servers_count === 'number',
            max_overall_servers_count: typeof this._max_overall_servers_count === 'number',
            overall_servers_count: typeof this._overall_servers_count === 'number',
            max_overall_memory_mib: typeof this._max_overall_memory_mib === 'number',
            overall_memory_mib: typeof this._overall_memory_mib === 'number',
            min_cash_payment_amount: typeof this._min_cash_payment_amount === 'number',
            max_cash_payment_amount: typeof this._max_cash_payment_amount === 'number',
            min_nocash_payment_amount: typeof this._min_nocash_payment_amount === 'number',
            max_nocash_payment_amount: typeof this._max_nocash_payment_amount === 'number',
            min_top_up_amount: typeof this._min_top_up_amount === 'number',
            max_top_up_amount: typeof this._max_top_up_amount === 'number',
            min_top_up_balance: typeof this._min_top_up_balance === 'number',
            max_top_up_balance: typeof this._max_top_up_balance === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountLimits): AccountLimits {
        return new AccountLimits(props);
    }

    readonly keys: { [key: string]: string } = {
        currentServersCount: 'current_servers_count',
        maxActiveSubscriptions: 'max_active_subscriptions',
        maxCashPaymentAmount: 'max_cash_payment_amount',
        maxCurrentServersCount: 'max_current_servers_count',
        maxNocashPaymentAmount: 'max_nocash_payment_amount',
        maxOverallMemoryMib: 'max_overall_memory_mib',
        maxOverallServersCount: 'max_overall_servers_count',
        maxTopUpAmount: 'max_top_up_amount',
        maxTopUpBalance: 'max_top_up_balance',
        minCashPaymentAmount: 'min_cash_payment_amount',
        minNocashPaymentAmount: 'min_nocash_payment_amount',
        minTopUpAmount: 'min_top_up_amount',
        minTopUpBalance: 'min_top_up_balance',
        overallMemoryMib: 'overall_memory_mib',
        overallServersCount: 'overall_servers_count',
        }
;

    mergeDeepWith(props: Partial<AccountLimits>): AccountLimits {
        const updateData: Partial<IAccountLimits> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountLimits) => {
            const updateKey = this.keys[key] as keyof IAccountLimits;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountLimits, keyof IAccountLimits>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountLimits({ ...this.serialize(), ...updateData });
    }
}
