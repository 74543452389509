// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum ServerState {
    RUNNING = 'RUNNING',
    SHUTOFF = 'SHUTOFF',
    NO_STATE = 'NO_STATE',
    BLOCKED = 'BLOCKED',
    PAUSED = 'PAUSED',
    SHUTDOWN = 'SHUTDOWN',
    CRASHED = 'CRASHED',
    REBOOT = 'REBOOT',
    INSTALLING = 'INSTALLING',
    PENDING_INSTALL = 'PENDING_INSTALL'
}
