// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISnapshot {
    description: string;
    download_cost_forecast: number;
    download_size_gib: number;
    id: number;
    name: string;
    parent_snapshot_id?: number;
    time_added: string;
    time_added_millis: number;
}

export default class Snapshot {
    readonly _description: string;

    /** */
    get description(): string {
        return this._description;
    }

    static descriptionValidate(description: string): boolean {
        return typeof description === 'string' && !!description.trim();
    }

    readonly _download_cost_forecast: number;

    /**
     * Description: Forecast for server state snapshot download cost
     * Example: 580
     */
    get downloadCostForecast(): number {
        return this._download_cost_forecast;
    }

    static downloadCostForecastValidate(downloadCostForecast: number): boolean {
        return typeof downloadCostForecast === 'number';
    }

    readonly _download_size_gib: number;

    /**
     * Description: Download size in gib
     * Example: 16
     */
    get downloadSizeGib(): number {
        return this._download_size_gib;
    }

    static downloadSizeGibValidate(downloadSizeGib: number): boolean {
        return typeof downloadSizeGib === 'number';
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 681
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _name: string;

    /**
     * Description: Name
     * Example: snap-681-UmC
     */
    get name(): string {
        return this._name;
    }

    static nameValidate(name: string): boolean {
        return typeof name === 'string' && !!name.trim();
    }

    readonly _parent_snapshot_id: number | undefined;

    /** */
    get parentSnapshotId(): number | undefined {
        return this._parent_snapshot_id;
    }

    readonly _time_added: string;

    /** */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Time added, UTC millis
     * Example: 1593064584178
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    constructor(props: ISnapshot) {
        this._description = props.description.trim();
        this._download_cost_forecast = props.download_cost_forecast;
        this._download_size_gib = props.download_size_gib;
        this._id = props.id;
        this._name = props.name.trim();
        if (typeof props.parent_snapshot_id === 'number') {
            this._parent_snapshot_id = props.parent_snapshot_id;
        }
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
    }

    serialize(): ISnapshot {
        const data: ISnapshot = {
            description: this._description,
            download_cost_forecast: this._download_cost_forecast,
            download_size_gib: this._download_size_gib,
            id: this._id,
            name: this._name,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
        };
        if (typeof this._parent_snapshot_id !== 'undefined') {
            data.parent_snapshot_id = this._parent_snapshot_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            description: typeof this._description === 'string' && !!this._description.trim(),
            parent_snapshot_id: !this._parent_snapshot_id ? true : typeof this._parent_snapshot_id === 'number',
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
            download_size_gib: typeof this._download_size_gib === 'number',
            download_cost_forecast: typeof this._download_cost_forecast === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISnapshot): Snapshot {
        return new Snapshot(props);
    }

    readonly keys: { [key: string]: string } = {
        description: 'description',
        downloadCostForecast: 'download_cost_forecast',
        downloadSizeGib: 'download_size_gib',
        id: 'id',
        name: 'name',
        parentSnapshotId: 'parent_snapshot_id',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        }
;

    mergeDeepWith(props: Partial<Snapshot>): Snapshot {
        const updateData: Partial<ISnapshot> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Snapshot) => {
            const updateKey = this.keys[key] as keyof ISnapshot;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISnapshot, keyof ISnapshot>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Snapshot({ ...this.serialize(), ...updateData });
    }
}
