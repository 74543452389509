import React, { FC } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import { Button, useIntl } from 'Common';
import AccountInvoice from 'Entities/AccountInvoice';
import theme from 'Lib/theme';
import { invoiceStatusTranslate } from 'Lib/helpers/translationHelper';
import { cancelInvoice } from 'Actions/account';
import { InvoiceStatus } from 'Entities/InvoiceStatus';
import { PaymentSystemType } from 'Entities/PaymentSystemType';

interface DocumentProps {
    invoice: AccountInvoice;
}

const Document: FC<DocumentProps> = ({ invoice }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        invoiceId,
        downloadUrl,
        amount,
        status,
        number,
        timeAddedMillis,
        paymentSystem,
    } = invoice;
    const onCancel = () => {
        dispatch(cancelInvoice([invoiceId]));
    };
    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.with_actions,
            )}
        >
            <div className={cn(theme.card.grid, theme.card.grid_invoice)}>
                <div className={theme.common.textOverflow}>
                    {number}
                </div>
                <div className={theme.common.textOverflow}>
                    {invoiceStatusTranslate(intl, status)}
                </div>
                <div className={theme.common.textOverflow}>
                    {intl.getMessage('price_simple', { value: amount })}
                </div>
                <div className={theme.card.actionsWrap}>
                    <div className={theme.color.grayHeaders}>
                        {dayjs(timeAddedMillis).format(intl.getMessage('time_format_short_month_year'))}
                    </div>

                    <div className={theme.card.actions}>
                        <a
                            href={downloadUrl}
                            className={cn(theme.card.action, theme.card.action_hover)}
                            download
                        >
                            <Button
                                type="icon"
                                icon="download"
                                className={cn(theme.card.action, theme.card.action_hover)}
                                title={intl.getMessage('title_dowload_file_extension', { value: 'PDF' })}
                            />
                        </a>
                        {status === InvoiceStatus.PROCESS
                        && paymentSystem === PaymentSystemType.NON_CASH_PAYMENT && (
                            <Button
                                type="icon"
                                icon="close_small"
                                className={cn(theme.card.action, theme.card.action_hover)}
                                title={intl.getMessage('invoice_cancel')}
                                onClick={onCancel}
                            />
                        )}
                    </div>

                    <Popover
                        placement="leftTop"
                        trigger="click"
                        content={(
                            <div className={theme.popover.popover}>
                                <a
                                    href={downloadUrl}
                                    className={theme.popover.item}
                                    download
                                >
                                    {intl.getMessage('title_dowload_file_extension', { value: 'PDF' })}
                                </a>
                                {status === InvoiceStatus.PROCESS
                                && paymentSystem === PaymentSystemType.NON_CASH_PAYMENT && (
                                    <button
                                        type="button"
                                        onClick={onCancel}
                                        className={cn(
                                            theme.popover.item,
                                            theme.popover.item_danger,
                                        )}
                                    >
                                        {intl.getMessage('invoice_cancel')}
                                    </button>
                                )}
                            </div>
                        )}
                        overlayClassName="popover"
                    >
                        <Button
                            type="icon"
                            icon="options"
                            className={cn(theme.card.action, theme.card.action_popover)}
                        />
                    </Popover>
                </div>
            </div>
        </div>
    );
};

export default Document;
