// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum PaymentSystemType {
    NON_CASH_PAYMENT = 'NON_CASH_PAYMENT',
    DENGI_ONLINE = 'DENGI_ONLINE',
    PLATRON = 'PLATRON',
    PLIMUS = 'PLIMUS',
    PAYPAL = 'PAYPAL',
    PAYONLINE = 'PAYONLINE',
    CLOUD_PAYMENTS = 'CLOUD_PAYMENTS'
}
