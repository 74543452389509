import React, { FC, useState, ClipboardEvent, KeyboardEvent } from 'react';
import { Popover } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { updateServer } from 'Actions/server';
import { useIntl, Button, TextArea } from 'Common';
import Server from 'Entities/Server';
import ServerUpdate from 'Entities/ServerUpdate';
import theme from 'Lib/theme';
import { useEscape } from 'Hooks';

import s from './Comment.module.pcss';

interface CommentProps {
    server: Server;
    loading?: boolean;
}

interface FormValues {
    comment: string;
}

const Comment: FC<CommentProps> = ({ server, loading }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const { comment } = server;

    useEscape(() => setVisible(false));

    const onSave = (
        values: FormValues,
        helpers: FormikHelpers<FormValues>,
    ) => {
        dispatch(updateServer({ server, update: values }));
        setVisible(false);
        helpers.setSubmitting(false);
    };

    const commentContent = (
        <div className={theme.popover.popover}>
            <Formik
                // hack for reinitialize
                initialValues={!visible ? { comment: ' ' } : { comment: comment || '' }}
                enableReinitialize
                onSubmit={onSave}
            >
                {({
                    values,
                    handleSubmit,
                    isSubmitting,
                    setValues,
                    errors,
                }) => {
                    const onDelete = () => {
                        setVisible(false);
                        setValues({ comment: '' });
                        dispatch(updateServer({ server, update: { comment: '' } }));
                    };
                    const onCommentChange = (v: string) => {
                        if (v.length <= ServerUpdate.commentMaxLength) {
                            setValues({ comment: v });
                        }
                    };
                    const onEditCommentKeyPressed = (e: KeyboardEvent<HTMLTextAreaElement>) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleSubmit();
                        }
                    };
                    const onPaste = (e: ClipboardEvent<HTMLTextAreaElement>) => {
                        e.preventDefault();
                        const text = e.clipboardData.getData('text/plain');
                        setValues({ comment: text.slice(0, ServerUpdate.commentMaxLength) });
                    };

                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <TextArea
                                autoSize
                                className={cn('textarea_small textarea_light', s.textarea)}
                                wrapperClassName={s.wrapper}
                                error={!!errors.comment}
                                name="comment"
                                onChange={onCommentChange}
                                onKeyDown={onEditCommentKeyPressed}
                                onPaste={onPaste}
                                placeholder={intl.getMessage('server_comment_placeholder')}
                                validate={ServerUpdate.commentValidate}
                                value={values.comment}
                            />
                            {values.comment && values.comment.trim()
                                && values.comment !== comment && (
                                <Button
                                    inGroup
                                    type="primary"
                                    htmlType="submit"
                                    disabled={isSubmitting}
                                    className={cn('commentSubmit', s.save)}
                                >
                                    {intl.getMessage('save')}
                                </Button>
                            )}
                            {comment && (
                                <Button
                                    type="link"
                                    className={cn(
                                        'commentDelete',
                                        theme.link.link,
                                        theme.link.white,
                                        s.delete,
                                    )}
                                    onClick={onDelete}
                                >
                                    {intl.getMessage('delete')}
                                </Button>
                            )}
                        </form>
                    );
                }}
            </Formik>
        </div>
    );

    const iconName = comment ? 'comment_filled' : 'comment';

    if (loading) {
        return (
            <Button
                size="medium"
                type="icon"
                icon={iconName}
                iconClassName={s.icon}
                disabled
                className={s.button}
                title={intl.getMessage('title_server_comment')}
            />
        );
    }

    return (
        <Popover
            content={commentContent}
            placement="leftBottom"
            trigger="click"
            visible={visible}
            onVisibleChange={(v: boolean) => setVisible(v)}
        >
            <Button
                size="medium"
                type="icon"
                icon={iconName}
                iconClassName={s.icon}
                className={s.button}
                title={intl.getMessage('title_server_comment')}
            />
        </Popover>
    );
};

export default Comment;
