import CommonPublicKey, { ICommonPublicKey } from './CommonPublicKey';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServersCommonPublicKeys {
    common_keys: ICommonPublicKey[];
}

export default class ServersCommonPublicKeys {
    readonly _common_keys: CommonPublicKey[];

    /** */
    get commonKeys(): CommonPublicKey[] {
        return this._common_keys;
    }

    constructor(props: IServersCommonPublicKeys) {
        this._common_keys = props.common_keys.map((p) => new CommonPublicKey(p));
    }

    serialize(): IServersCommonPublicKeys {
        const data: IServersCommonPublicKeys = {
            common_keys: this._common_keys.map((p) => p.serialize()),
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            common_keys: this._common_keys.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServersCommonPublicKeys): ServersCommonPublicKeys {
        return new ServersCommonPublicKeys(props);
    }

    readonly keys: { [key: string]: string } = {
        commonKeys: 'common_keys',
        }
;

    mergeDeepWith(props: Partial<ServersCommonPublicKeys>): ServersCommonPublicKeys {
        const updateData: Partial<IServersCommonPublicKeys> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServersCommonPublicKeys) => {
            const updateKey = this.keys[key] as keyof IServersCommonPublicKeys;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServersCommonPublicKeys, keyof IServersCommonPublicKeys>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServersCommonPublicKeys({ ...this.serialize(), ...updateData });
    }
}
