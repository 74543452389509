import { Action } from 'Actions/.';
import {
    PartnerActions,
    savePartnerDetails,
    savePartnerProcessingPayments,
    savePartnerCompletePayments,
    savePartnerStats,
    savePartnerComparativeDetails,
} from 'Actions/partner';
import PartnerPayment from 'Entities/PartnerPayment';

import { PartnerStore, partnerInitialState as initialState } from '../initialStates';

const reducer = (state: PartnerStore = initialState, action: Action): PartnerStore => {
    switch (action.type as PartnerActions) {
        case PartnerActions.SavePartnerDetails: {
            const { payload } = action as ReturnType<typeof savePartnerDetails>;
            return { ...state, details: payload };
        }
        case PartnerActions.SavePartnerComparativeDetails: {
            const { payload } = action as ReturnType<typeof savePartnerComparativeDetails>;
            return { ...state, comparativeDetails: payload };
        }
        case PartnerActions.SavePartnerProcessingPayments: {
            const { payload } = action as ReturnType<typeof savePartnerProcessingPayments>;
            return { ...state, paymentsIncomplete: payload };
        }
        case PartnerActions.SavePartnerCompletePayments: {
            const { payload } = action as ReturnType<typeof savePartnerCompletePayments>;
            let payments: PartnerPayment[] = [...payload.completePayments];
            if (state.paymentsComplete !== null) {
                payments = [...state.paymentsComplete, ...payload.completePayments];
            }
            return { ...state, paymentsComplete: payments };
        }
        case PartnerActions.ClearPartnerCompletePayments: {
            return { ...state, paymentsComplete: null };
        }
        case PartnerActions.SavePartnerStats: {
            const { payload } = action as ReturnType<typeof savePartnerStats>;
            return { ...state, stats: payload };
        }

        default:
            return state;
    }
};

export default reducer;
