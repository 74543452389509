import React, { FC } from 'react';

import { useIntl, Button, Link } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';

import s from './Create.module.pcss';

const Create: FC = () => {
    const intl = useIntl();

    return (
        <div className={s.create}>
            <Link to={RoutePath.NewServer}>
                <Button
                    type="primary"
                    block
                >
                    {intl.getMessage('create_server')}
                </Button>
            </Link>
        </div>
    );
};

export default Create;
