import React, { FC } from 'react';
import { Button } from 'antd';
import cn from 'classnames';

import { useIntl, DashboardHeader } from 'Common';

import theme from 'Lib/theme';

interface HeaderProps {
    openModal: () => void;
}

const Header: FC<HeaderProps> = ({ openModal }) => {
    const intl = useIntl();

    return (
        <>
            <DashboardHeader
                title={intl.getMessage('public_keys')}
                actions={(
                    <Button
                        type="primary"
                        size="large"
                        className={cn(
                            theme.button.button,
                            theme.button.primary,
                            theme.button.desktop_medium,
                        )}
                        onClick={openModal}
                    >
                        {intl.getMessage('add_key')}
                    </Button>
                )}
            />
        </>
    );
};

export default Header;
