import React, { FC } from 'react';

import { DashboardHeader, ServerHeaderLinks } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import Server from 'Entities/Server';

interface HeaderProps {
    server?: Server;
}

const Header: FC<HeaderProps> = ({ server }) => {
    if (!server) {
        return null;
    }
    return (
        <DashboardHeader
            navigation={(
                <ServerHeaderLinks server={server} active={RoutePath.Console} />
            )}
        />
    );
};

export default Header;
