import { IRecipientDeliverySettings } from 'Entities/RecipientDeliverySettings';
import NotificationRecipient from 'Entities/NotificationRecipient';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import { ITriggerCreate } from 'Entities/TriggerCreate';
import Trigger from 'Entities/Trigger';

export default class NewTrigger {
    static getInitialValues = (
        type: TriggerRuleType,
        recipients: NotificationRecipient[],
        trigger?: Trigger,
    ) => {
        const serialized = trigger?.serialize();
        const variants = serialized?.recipient_settings.map((v) => ({
            ...v,
            email: recipients.find((r) => r.id === v.recipient_id)?.email,
        }));
        if (variants) {
            recipients.forEach((r) => {
                if (!variants.find((v) => v.recipient_id === r.id)) {
                    variants.push({
                        email: r.email,
                        recipient_id: r.id,
                        delivery_email: false,
                        delivery_sms: false,
                    });
                }
            });
        }
        const getProps = (t: TriggerRuleType) => {
            switch (t) {
                case TriggerRuleType.DAILY_EXPENSE:
                    return { value: serialized?.value || 500 };
                case TriggerRuleType.LOAD_AVERAGE:
                    return {
                        value: serialized?.value || 5,
                        period_minutes: serialized?.period_minutes || 30,
                    };
                case TriggerRuleType.DISK_FREE_SPACE:
                    return { value: serialized?.value || 80 };
                case TriggerRuleType.CPU_USAGE:
                    return {
                        value: serialized?.value || 70,
                        period_minutes: serialized?.period_minutes || 30,
                    };
                case TriggerRuleType.PING:
                    return { period_minutes: serialized?.period_minutes || 5 };
                case TriggerRuleType.INODES_USE:
                    return { value: serialized?.value || 70 };
                case TriggerRuleType.MIN_BALANCE: {
                    return { value: serialized?.value || 50 };
                }
                case TriggerRuleType.HTTPS_SSL_CERT: {
                    return {
                        value: serialized?.value || 30,
                        period_minutes: serialized?.period_minutes || 90,
                    };
                }
                case TriggerRuleType.DOMAIN_EXPIRATION: {
                    return { value: serialized?.value || 30 };
                }
                default:
                    return {};
            }
        };
        return {
            value: serialized?.value || 10,
            period_minutes: serialized?.period_minutes || 5,
            ...getProps(type),
            type: serialized?.type ?? type,
            name: serialized?.name ?? '',
            disabled: serialized?.disabled ?? false,
            servers_scope: serialized?.servers_scope ?? [] as number[],

            recipient_settings: variants ?? recipients.map((r) => {
                return {
                    delivery_email: true,
                    delivery_sms: true,
                    recipient_id: r.id,
                } as IRecipientDeliverySettings;
            }),
            url: serialized?.url ?? '',
        } as ITriggerCreate;
    };

    static validate = (
        values: ITriggerCreate,
        setErrors: (errors: Partial<Record<NewTriggerFormKeys, string>>) => void,
        checkKeys: NewTriggerFormKeys[],
    ) => {
        const errors: Partial<Record<NewTriggerFormKeys, string>> = {};
        if (!values.name) {
            errors.name = 'error';
        }
        checkKeys.forEach((k) => {
            if (!values[k]) {
                errors[k] = 'error';
            }
        });
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return false;
        }
        return true;
    };
}

export type NewTriggerFormKeys = keyof ReturnType<typeof NewTrigger.getInitialValues>;
