import React, { FC, useEffect } from 'react';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import cn from 'classnames';

import { useTitle } from 'Hooks';
import { getNotificationRecipients } from 'Actions/notifications';
import { useIntl, InnerPageLayout, ListLayout } from 'Common';
import NotificationRecipient from 'Entities/NotificationRecipient';
import Profile from 'Entities/Profile';
import theme from 'Lib/theme';
import { Store } from 'Store';

import { Header, Recipient } from './components';

interface RecipientsStoreProps {
    profile: Profile | null;
    recipients: NotificationRecipient[] | null;
}

const Recipients: FC<RecipientsStoreProps> = ({ profile, recipients }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('notification_recipients_page_title'));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getNotificationRecipients());
    }, []);

    return (
        <InnerPageLayout
            header={<Header recipientsNum={recipients?.length} />}
            className={theme.content.with_actions}
        >
            <ListLayout
                title={intl.getMessage('notifications_recipients')}
                info={intl.getMessage('notifications_recipients_desc', { value: profile?.profileLimits.maxRecipientsCount || '' })}
                length={recipients?.length}
            >
                {recipients?.length && (
                    <div
                        className={cn(
                            theme.card.labels,
                            theme.card.labels_withActions,
                            theme.card.labels_recipient,
                        )}
                    >
                        <div className={theme.card.label}>
                            {intl.getMessage('name')}
                        </div>
                        <div className={theme.card.label}>
                            {intl.getMessage('email')}
                        </div>
                        <div className={theme.card.label}>
                            {intl.getMessage('phone')}
                        </div>
                    </div>
                )}

                {recipients?.map((r) => (
                    <Recipient
                        key={r.id}
                        recipient={r}
                    />
                ))}
            </ListLayout>
        </InnerPageLayout>
    );
};

const selectRecipients = (store: Store) => store.notifications.recipients;
const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector(
    [selectRecipients, selectProfile],
    (recipients, profile) => ({ recipients, profile }),
);

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(Recipients);
