// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IMarkTicketsReadRequest {
    ticket_ids: number[];
}

export default class MarkTicketsReadRequest {
    readonly _ticket_ids: number[];

    /** */
    get ticketIds(): number[] {
        return this._ticket_ids;
    }

    static ticketIdsValidate(ticketIds: number[]): boolean {
        return ticketIds.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    constructor(props: IMarkTicketsReadRequest) {
        this._ticket_ids = props.ticket_ids;
    }

    serialize(): IMarkTicketsReadRequest {
        const data: IMarkTicketsReadRequest = {
            ticket_ids: this._ticket_ids,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            ticket_ids: this._ticket_ids.reduce((result, p) => result && typeof p === 'number', true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IMarkTicketsReadRequest): MarkTicketsReadRequest {
        return new MarkTicketsReadRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        ticketIds: 'ticket_ids',
        }
;

    mergeDeepWith(props: Partial<MarkTicketsReadRequest>): MarkTicketsReadRequest {
        const updateData: Partial<IMarkTicketsReadRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof MarkTicketsReadRequest) => {
            const updateKey = this.keys[key] as keyof IMarkTicketsReadRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IMarkTicketsReadRequest, keyof IMarkTicketsReadRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new MarkTicketsReadRequest({ ...this.serialize(), ...updateData });
    }
}
