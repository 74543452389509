import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Button, useIntl } from 'Common';
import theme from 'Lib/theme';
import { deleteCreditCard } from 'Actions/account';
import AccountCreditCard from 'Entities/AccountCreditCard';

import DeleteCard from './DeleteCard';
import CardPlaceholder from './CardPlaceholder';

interface CreditCardProps {
    card: AccountCreditCard;
    draggable: number;
    setDraggable: (id: number) => () => void;
}

const CreditCard: FC<CreditCardProps> = ({ card, draggable, setDraggable }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { creditCardId } = card;
    const [openCardModal, setOpenCardModal] = useState(false);

    const handleDeleteCard = () => {
        dispatch(deleteCreditCard({ cardId: creditCardId }));
    };
    return (
        <div
            id={String(creditCardId)}
            draggable={draggable === creditCardId}
            className={cn(theme.card.card, theme.card.list, theme.card.row)}
        >
            <CardPlaceholder card={card} />

            <div className={theme.card.actions}>
                <Button
                    type="icon"
                    icon="delete"
                    className={theme.card.action}
                    onClick={() => setOpenCardModal(true)}
                    title={intl.getMessage('title_card_delete')}
                />
                <Button
                    type="icon"
                    icon="drag"
                    className={theme.card.action}
                    onMouseDown={setDraggable(creditCardId)}
                    onTouchStart={setDraggable(creditCardId)}
                    title={intl.getMessage('title_card_drag')}
                />
            </div>

            {openCardModal && (
                <DeleteCard
                    visible={openCardModal}
                    handleClose={() => setOpenCardModal(false)}
                    handleDelete={handleDeleteCard}
                />
            )}
        </div>
    );
};

export default CreditCard;
