import React, { FC } from 'react';
import cn from 'classnames';

import { DashboardHeader, InnerPageLayout } from 'Common';
import theme from 'Lib/theme';

const Loading: FC = () => {
    return (
        <InnerPageLayout
            header={<DashboardHeader loading />}
            className={theme.content.with_actions}
        >
            <div className={cn(theme.skeleton.block, theme.skeleton.medium)} />
            <div className={cn(theme.skeleton.block, theme.skeleton.medium)} />
            <div className={theme.skeleton.block} />
            <div className={theme.skeleton.block} />
        </InnerPageLayout>
    );
};

export default Loading;
