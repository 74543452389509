import { DnsRecordType } from './DnsRecordType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsRecord {
    data: string;
    host: string;
    id: number;
    port?: number;
    priority?: number;
    time_created: string;
    time_created_millis: number;
    ttl_seconds: number;
    type: DnsRecordType;
    weight?: number;
    zone_id: number;
}

export default class DnsRecord {
    readonly _data: string;

    /**
     * Description: Points to
     * Example: xmpp-server.l.google.com
     */
    get data(): string {
        return this._data;
    }

    static dataValidate(data: string): boolean {
        return typeof data === 'string' && !!data.trim();
    }

    readonly _host: string;

    /**
     * Description: Host or @ for root
     * Example: _xmpp-server._tcp
     */
    get host(): string {
        return this._host;
    }

    static hostValidate(host: string): boolean {
        return typeof host === 'string' && !!host.trim();
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 207
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _port: number | undefined;

    /**
     * Description: Port. Only for SRV
     * Example: 5269
     */
    get port(): number | undefined {
        return this._port;
    }

    readonly _priority: number | undefined;

    /** */
    get priority(): number | undefined {
        return this._priority;
    }

    readonly _time_created: string;

    /** */
    get timeCreated(): string {
        return this._time_created;
    }

    static timeCreatedValidate(timeCreated: string): boolean {
        return typeof timeCreated === 'string' && !!timeCreated.trim();
    }

    readonly _time_created_millis: number;

    /**
     * Description: Time created, UTC millis
     * Example: 1593064584178
     */
    get timeCreatedMillis(): number {
        return this._time_created_millis;
    }

    static timeCreatedMillisValidate(timeCreatedMillis: number): boolean {
        return typeof timeCreatedMillis === 'number';
    }

    readonly _ttl_seconds: number;

    /**
     * Description: Ttl in seconds
     * Example: 3600
     */
    get ttlSeconds(): number {
        return this._ttl_seconds;
    }

    static ttlSecondsValidate(ttlSeconds: number): boolean {
        return typeof ttlSeconds === 'number';
    }

    readonly _type: DnsRecordType;

    get type(): DnsRecordType {
        return this._type;
    }

    static typeValidate(type: DnsRecordType): boolean {
        return Object.keys(DnsRecordType).includes(type);
    }

    readonly _weight: number | undefined;

    /** */
    get weight(): number | undefined {
        return this._weight;
    }

    readonly _zone_id: number;

    /**
     * Description: Dns zone unique identifier
     * Example: 2755
     */
    get zoneId(): number {
        return this._zone_id;
    }

    static zoneIdValidate(zoneId: number): boolean {
        return typeof zoneId === 'number';
    }

    constructor(props: IDnsRecord) {
        this._data = props.data.trim();
        this._host = props.host.trim();
        this._id = props.id;
        if (typeof props.port === 'number') {
            this._port = props.port;
        }
        if (typeof props.priority === 'number') {
            this._priority = props.priority;
        }
        this._time_created = props.time_created.trim();
        this._time_created_millis = props.time_created_millis;
        this._ttl_seconds = props.ttl_seconds;
        this._type = props.type;
        if (typeof props.weight === 'number') {
            this._weight = props.weight;
        }
        this._zone_id = props.zone_id;
    }

    serialize(): IDnsRecord {
        const data: IDnsRecord = {
            data: this._data,
            host: this._host,
            id: this._id,
            time_created: this._time_created,
            time_created_millis: this._time_created_millis,
            ttl_seconds: this._ttl_seconds,
            type: this._type,
            zone_id: this._zone_id,
        };
        if (typeof this._port !== 'undefined') {
            data.port = this._port;
        }
        if (typeof this._priority !== 'undefined') {
            data.priority = this._priority;
        }
        if (typeof this._weight !== 'undefined') {
            data.weight = this._weight;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            zone_id: typeof this._zone_id === 'number',
            host: typeof this._host === 'string' && !!this._host.trim(),
            data: typeof this._data === 'string' && !!this._data.trim(),
            weight: !this._weight ? true : typeof this._weight === 'number',
            port: !this._port ? true : typeof this._port === 'number',
            ttl_seconds: typeof this._ttl_seconds === 'number',
            priority: !this._priority ? true : typeof this._priority === 'number',
            time_created: typeof this._time_created === 'string' && !!this._time_created.trim(),
            time_created_millis: typeof this._time_created_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsRecord): DnsRecord {
        return new DnsRecord(props);
    }

    readonly keys: { [key: string]: string } = {
        data: 'data',
        host: 'host',
        id: 'id',
        port: 'port',
        priority: 'priority',
        timeCreated: 'time_created',
        timeCreatedMillis: 'time_created_millis',
        ttlSeconds: 'ttl_seconds',
        type: 'type',
        weight: 'weight',
        zoneId: 'zone_id',
        }
;

    mergeDeepWith(props: Partial<DnsRecord>): DnsRecord {
        const updateData: Partial<IDnsRecord> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsRecord) => {
            const updateKey = this.keys[key] as keyof IDnsRecord;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsRecord, keyof IDnsRecord>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsRecord({ ...this.serialize(), ...updateData });
    }
}
