import React, { FC, FocusEvent, KeyboardEvent, ClipboardEvent, useState, ChangeEvent } from 'react';
import { Input as InputControl, Tooltip } from 'antd';
import { InputProps as InputControlProps } from 'antd/lib/input';
import cn from 'classnames';

import { useIntl, Icon } from 'Common';
import { AT_LEAST_ONE_REQUIRED, EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

interface InputPropsBasic {
    autoComplete?: InputControlProps['autoComplete'];
    autoFocus?: InputControlProps['autoFocus'];
    className?: string;
    errorMessageClassName?: string;
    description?: string;
    disabled?: boolean;
    error: boolean;
    errorMessage?: string;
    id?: string;
    inputMode?: InputControlProps['inputMode'];
    label?: string;
    wrapperClassName?: string;
    name: string;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onChange?: (data: string) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
    pattern?: InputControlProps['pattern'];
    placeholder?: string;
    prefix?: InputControlProps['prefix'];
    size: InputControlProps['size'];
    suffix?: InputControlProps['suffix'];
    type: InputControlProps['type'];
    noValidationIcon?: boolean;
    validateMessage?: string;
    validateInstant?: boolean;
    value: string | number | undefined;
    cloud?: boolean;
    rightDesktop?: boolean;
}

interface InputPropsOptional extends InputPropsBasic {
    optional: true;
    validate?: (value: string) => boolean;
}
interface InputPropsOptionalFalse extends InputPropsBasic {
    optional: false;
    validate?: (value: string) => boolean;
}
interface InputPropsValidate extends InputPropsBasic {
    validate: (value: string) => boolean;
    optional?: boolean;
}

const Input: FC<InputPropsOptional | InputPropsOptionalFalse | InputPropsValidate> = ({
    autoComplete,
    autoFocus,
    className,
    description,
    disabled,
    error,
    errorMessage,
    errorMessageClassName,
    id,
    inputMode,
    label,
    wrapperClassName,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onPaste,
    optional,
    pattern,
    placeholder,
    prefix,
    size,
    suffix,
    type,
    noValidationIcon,
    validate,
    validateMessage,
    validateInstant,
    value,
    cloud,
    rightDesktop,
}) => {
    const intl = useIntl();
    const [inputType, setInputType] = useState(type);
    const [valid, setValid] = useState<boolean | null>(null);
    const inputClass = cn(
        'input',
        { input_big: size === 'large' },
        { input_medium: size === 'middle' },
        { input_small: size === 'small' },
        { input_error: error || !!errorMessage },
        { 'input_dm-cloud': cloud },
        { 'input_right-desktop': rightDesktop },
        className,
    );

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (validate && !noValidationIcon && String(value).length === 0) {
            setValid(null);
        } else if (validate && !noValidationIcon) {
            setValid(value !== undefined && validate(String(value)));
        }
        if (onBlur) {
            onBlur(e);
        }
    };

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        if (type !== 'password') {
            setValid(null);
        }
        if (onFocus) {
            onFocus(e);
        }
    };

    const showPassword = () => {
        if (inputType === 'password') {
            setInputType('text');
        } else {
            setInputType('password');
        }
    };

    const showPasswordIcon = () => {
        const icon = inputType === 'password' ? 'visibility_disable' : 'visibility_enable';
        const title = inputType === 'password' ? intl.getMessage('title_show_symbols') : intl.getMessage('title_hide_symbols');

        return (
            <Tooltip
                placement="bottom"
                overlayClassName="tooltip tooltip_small"
                title={title}
                arrowPointAtCenter
            >
                <span>
                    <Icon
                        icon={icon}
                        className={theme.form.reveal}
                        onClick={showPassword}
                    />
                </span>
            </Tooltip>
        );
    };

    const validSuffix = (
        <>
            {!!suffix && suffix}
            {(type === 'password') && showPasswordIcon()}
            {((typeof valid === 'boolean' && !valid) || error)
                && <Icon icon="close_small" className={cn(theme.color.red, theme.form.suffix)} />}
            {(typeof valid === 'boolean' && (valid && !error))
                && <Icon icon="check" className={cn(theme.color.blue, theme.form.suffix)} />}
        </>
    );

    let descriptionView = null;
    if (optional) {
        descriptionView = (
            <div className={theme.form.label}>
                {intl.getMessage('optional_field')}
            </div>
        );
    }
    if (description) {
        descriptionView = (
            <div className={theme.form.label}>
                {description} {optional && intl.getMessage('optional_field')}
            </div>
        );
    }
    if (validateMessage && (typeof valid === 'boolean' && !valid)) {
        descriptionView = (
            <div className={theme.form.label}>
                {validateMessage} {optional && intl.getMessage('optional_field')}
            </div>
        );
    }
    if (errorMessage && errorMessage !== EMPTY_FIELD_ERROR) {
        descriptionView = (
            <div className={cn(theme.form.label, errorMessageClassName)}>
                {errorMessage === AT_LEAST_ONE_REQUIRED ? intl.getMessage('at_least_one_required') : errorMessage} {optional && intl.getMessage('optional_field')}
            </div>
        );
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const v = e.target.value;
        if (validateInstant && validate) {
            setValid(v !== undefined && validate(String(v)));
        }
        if (onChange) {
            onChange(e.target.value ? e.target.value : '');
        }
    };

    return (
        <label htmlFor={id || name} className={cn(theme.form.group, wrapperClassName)}>
            {label && (
                <div className={theme.form.label}>
                    {label}
                </div>
            )}
            <InputControl
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                className={inputClass}
                disabled={disabled}
                formNoValidate
                id={id || name}
                inputMode={inputMode}
                name={name}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                onKeyDown={onKeyDown}
                onPaste={onPaste}
                pattern={pattern}
                placeholder={placeholder}
                prefix={prefix}
                size="large"
                suffix={validSuffix}
                type={inputType}
                value={value}
                data-valid={typeof valid === 'boolean' ? valid : ''}
                data-error={error}
            />
            {descriptionView}
        </label>
    );
};

export default Input;
