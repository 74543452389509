import React, { FC } from 'react';

import { useIntl, CommonModalLayout } from 'Common';

interface ModalBackupProps {
    onClose: () => void;
    onOk: () => void;
}

const ModalToDefault: FC<ModalBackupProps> = ({ onClose, onOk }) => {
    const intl = useIntl();

    const handleOk = () => {
        onOk();
        onClose();
    };

    return (
        <CommonModalLayout
            visible
            title={intl.getMessage('backup_drop_setting_title')}
            handleSubmit={handleOk}
            handleClose={onClose}
            buttonText={intl.getMessage('reset')}
            width={600}
        >
            <div className="modal__desc">
                {intl.getMessage('backup_drop_setting_desc')}
            </div>
        </CommonModalLayout>
    );
};

export default ModalToDefault;
