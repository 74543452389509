import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import serversApi from 'Apis/servers';
import { useIntl, Button, Select, Option } from 'Common';
import { BackupPolicyFrequency } from 'Entities/BackupPolicyFrequency';
import BackupPolicy, { IBackupPolicy } from 'Entities/BackupPolicy';
import Server from 'Entities/Server';
import ServerCostForecast, { IServerCostForecast } from 'Entities/ServerCostForecast';
import { frequencyMessage, errorChecker } from 'Lib/helpers/utils';
import { useEnter, useEscape } from 'Lib/hooks/hooks';
import { Store } from 'Store';
import theme from 'Lib/theme';

import ModalToDefault from './ModalToDefault';
import style from './BackupSetting.module.pcss';

interface BackupPolicyStoreProps{
    tenantBackupPolicy: BackupPolicy | undefined;
}
interface BackupPolicyOwnProps {
    backupPolicy: BackupPolicy;
    onSubmit: (policy: IBackupPolicy) => void;
    title: string;
    tenantId: number;
    server?: Server;
}
type BackupPolicyProps = BackupPolicyStoreProps & BackupPolicyOwnProps;

const variants = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const BackupSetting: FC<BackupPolicyProps> = ({
    backupPolicy, onSubmit, title, tenantId, server, tenantBackupPolicy,
}) => {
    const intl = useIntl();
    const [localPolicy, setLocalPolicy] = useState({
        quantity: backupPolicy.quantity,
        frequency: backupPolicy.frequency,
    });
    const [price, setPrice] = useState(0);

    useEffect(() => {
        if ((localPolicy.quantity !== backupPolicy.quantity)
        || (localPolicy.frequency !== backupPolicy.frequency)) {
            setLocalPolicy({
                quantity: backupPolicy.quantity,
                frequency: backupPolicy.frequency,
            });
        }
    }, [backupPolicy]);

    useEffect(() => {
        let canceled = false;
        const loadPrice = async () => {
            let response = null;
            if (server) {
                const { id, diskGib, memoryMib, cpuCores } = server;
                response = await serversApi.calculateCostForecastForServer(tenantId, id, {
                    backup_frequency: localPolicy.frequency,
                    backup_quantity: localPolicy.quantity,
                    disk_gib: diskGib,
                    memory_mib: memoryMib,
                    cpu_cores: cpuCores,
                });
            } else {
                response = await serversApi.calculateCostForecastForDraft(tenantId, {
                    backup_frequency: localPolicy.frequency,
                    backup_quantity: localPolicy.quantity,
                });
            }
            const { result } = errorChecker<IServerCostForecast>(response);
            if (!canceled && result) {
                const sPrice = new ServerCostForecast(result);
                setPrice(sPrice.backupCost);
            } else {
                // TODO: add error handler
                setPrice(0);
            }
        };
        loadPrice();
        return () => {
            canceled = true;
        };
    }, [localPolicy]);

    const [showToDefault, setShowToDefault] = useState(false);
    let toDefault = null;

    if (server
        && tenantBackupPolicy
        && (
            (server.backupPolicy.frequency !== tenantBackupPolicy.frequency)
            || (server.backupPolicy.quantity !== tenantBackupPolicy.quantity)
        )
    ) {
        toDefault = (
            <Button
                type="link"
                size="medium"
                onClick={() => setShowToDefault(true)}
                className={style.setting_default}
            >
                {intl.getMessage('backup_to_tenant_settings')}
            </Button>
        );
    }

    const handleChange = (field: keyof typeof localPolicy) => (value: string | number) => {
        setLocalPolicy({ ...localPolicy, [field]: value });
    };

    const onCancel = () => {
        setLocalPolicy({
            quantity: backupPolicy.quantity,
            frequency: backupPolicy.frequency,
        });
    };

    const submit = () => {
        onSubmit(localPolicy);
    };

    useEnter(submit, [submit]);
    useEscape(onCancel);

    const intervalOptions = Object.keys(frequencyMessage).map((key) => (
        <Option value={key} key={key}>
            {intl.getPlural('count_hour', frequencyMessage[key as BackupPolicyFrequency])}
        </Option>
    ));
    const quantityCopy = variants.map((i) => (
        <Option value={i} key={i}>
            {intl.getPlural('count_copy', i)}
        </Option>
    ));

    const policiesDiffer = (localPolicy.quantity !== backupPolicy.quantity)
    || (localPolicy.frequency !== backupPolicy.frequency);

    return (
        <div className={cn(style.setting, { [style.setting_active]: policiesDiffer })}>
            <div className={cn(style.setting__row, style['setting__row--top'])}>
                <div className={style.setting__title}>
                    {title}
                </div>
                <div
                    className={cn(
                        theme.Badge.badge,
                        theme.Badge.blue,
                        style.setting__cost,
                    )}
                >
                    {intl.getPlural('project_backup_setting_cost', price)}
                </div>
                {toDefault}
            </div>
            <div className={style.setting__row}>
                <div className={style.setting__subtitle}>
                    {intl.getMessage('backup_setting_keep_copy')}
                </div>
                <div className={style.setting__select}>
                    <Select
                        value={localPolicy.quantity}
                        onChange={handleChange('quantity')}
                        showScroll
                        setting
                    >
                        {quantityCopy}
                    </Select>
                </div>
            </div>
            <div className={style.setting__row}>
                <div className={style.setting__subtitle}>
                    {intl.getMessage('backup_setting_interval')}
                </div>
                <div className={style.setting__select}>
                    <Select
                        value={localPolicy.frequency}
                        onChange={handleChange('frequency')}
                        setting
                    >
                        {intervalOptions}
                    </Select>
                </div>
            </div>
            <div className={cn(style.setting__row, style.setting__desc)}>
                <div>
                    {intl.getPlural(
                        'project_backup_setting_copies_creation_frequency',
                        frequencyMessage[localPolicy.frequency],
                    )}
                </div>
                <div>
                    {intl.getPlural(
                        'project_backup_setting_copy_storage_time',
                        frequencyMessage[localPolicy.frequency] * localPolicy.quantity,
                    )}
                </div>
                <div>
                    {intl.getPlural(
                        'project_backup_setting_max_quantity',
                        localPolicy.quantity,
                    )}
                </div>
                <div>
                    {intl.getMessage('project_backup_setting_info')}
                </div>
            </div>
            {policiesDiffer && (
                <div className={style.setting__row}>
                    <Button
                        type="primary"
                        size="medium"
                        onClick={submit}
                        inGroup
                    >
                        {intl.getMessage('apply')}
                    </Button>
                    <Button
                        type="link"
                        size="medium"
                        onClick={onCancel}
                    >
                        {intl.getMessage('cancel')}
                    </Button>
                </div>
            )}
            {showToDefault && tenantBackupPolicy && (
                <ModalToDefault
                    onClose={() => setShowToDefault(false)}
                    onOk={() => onSubmit(tenantBackupPolicy.serialize())}
                />
            )}
        </div>
    );
};

const selectTenantPolicy = (store: Store, { tenantId }: BackupPolicyOwnProps) => (
    store.tenant.get(tenantId)?.backupPolicy
);

const selector = createSelector(
    [selectTenantPolicy], (tenantBackupPolicy) => ({
        tenantBackupPolicy,
    }),
);

const mapStoreToProps = (store: Store, ownProps: BackupPolicyOwnProps) => (
    { ...selector(store, ownProps) }
);

export default connect(mapStoreToProps)(BackupSetting);
