import React, { FC } from 'react';

import { useIntl, Link, Button, ServerSearch } from 'Common';
import Server from 'Entities/Server';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import ConsoleLink from './ConsoleLink';
import HeaderLink from './HeaderLink';

interface ServerHeaderLinksProps {
    server: Server;
    active: RoutePath;
}

const ServerHeaderLinks: FC<ServerHeaderLinksProps> = ({ server, active }) => {
    const intl = useIntl();

    const links = [
        {
            url: RoutePath.ServerTariff,
            name: intl.getMessage('tariff_and_resources_title'),
        },
        {
            url: RoutePath.ServerBackup,
            name: intl.getMessage('backup'),
        },
        {
            url: RoutePath.Snapshots,
            name: intl.getMessage('snapshots'),
        },
        {
            url: RoutePath.ServerAccess,
            name: intl.getMessage('access'),
        },
    ];

    return (
        <>
            <Link to={RoutePath.ServersList} className={theme.header.back}>
                <Button
                    type="icon"
                    size="medium"
                    icon="home"
                />
            </Link>

            <div className={theme.header.search}>
                <ServerSearch server={server} />
            </div>

            {links.map((i) => (
                <HeaderLink
                    key={i.name}
                    server={server}
                    url={i.url}
                    name={i.name}
                    active={active === i.url}
                />
            ))}

            {active === RoutePath.Console ? (
                <HeaderLink name={intl.getMessage('console')} server={server} active />
            ) : (
                <ConsoleLink server={server} />
            )}

            <HeaderLink
                server={server}
                url={RoutePath.ServerHistory}
                name={intl.getMessage('history')}
                active={active === RoutePath.ServerHistory}
            />
        </>
    );
};

export default ServerHeaderLinks;
