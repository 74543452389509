import React, { FC, useState, useEffect } from 'react';
import { Input } from 'antd';
import Fuse from 'fuse.js';
import cn from 'classnames';

import { useIntl, EmptyPageLayout, Button } from 'Common';
import CountryPhoneCode from 'Entities/CountryPhoneCode';
import theme from 'Lib/theme';

import Country from './Country';
import s from './PhoneSearch.module.pcss';

const PHONE_SEARCH_HEADER_HEIGHT = 90;

interface PhoneSearchProps {
    currentCountry: CountryPhoneCode;
    codes: CountryPhoneCode[];
    setCountryValue: (value: CountryPhoneCode) => void;
    setVisibleDropdown: (e: boolean) => void;
}

type SearchEntityKeys =
    'countryName' |
    'countryCode' |
    'callingCode';

const PhoneSearch: FC<PhoneSearchProps> = ({
    currentCountry,
    codes,
    setCountryValue,
    setVisibleDropdown,
}) => {
    const intl = useIntl();
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (currentCountry.countryCode) {
            const country = document.getElementById(currentCountry.countryCode);
            const list = document.getElementById('list');
            if (list) {
                list.scrollTop = country ? country.offsetTop - PHONE_SEARCH_HEADER_HEIGHT : 0;
            }
        }
    }, []);

    const selectCountry = (country: CountryPhoneCode) => {
        setCountryValue(country);
        setVisibleDropdown(false);
    };

    const keys: SearchEntityKeys[] = [
        'countryName',
        'callingCode',
        'countryCode',
    ];
    const fuse = new Fuse(codes, {
        keys,
        threshold: 0.3,
    });
    const fuseResults = fuse.search(searchQuery);
    const searchResult: CountryPhoneCode[] = fuseResults.map((result) => result.item);

    const getSearchResultList = () => {
        if (searchResult.length === 0) {
            return (
                <EmptyPageLayout
                    desc={intl.getMessage('profile_search_country_empty')}
                    type="phone"
                />
            );
        }

        return (
            <div className={s.list} id="list">
                {searchResult.map((country) => (
                    <Country
                        key={country.countryCode}
                        country={country}
                        currentCountry={currentCountry}
                        selectCountry={selectCountry}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className={s.search}>
            <div className={s.header} id="searchInput">
                <Input
                    placeholder={intl.getMessage('profile_search_country')}
                    className={s.input}
                    bordered={false}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    autoFocus
                />
                {searchQuery && (
                    <Button
                        id="searchClear"
                        type="icon"
                        className={cn(theme.button.for_input, s.clear)}
                        onClick={() => setSearchQuery('')}
                        icon="clear"
                    />
                )}
            </div>
            {searchQuery ? (
                getSearchResultList()
            ) : (
                <div className={s.list} id="list">
                    {codes.map((country) => (
                        <Country
                            key={country.countryCode}
                            country={country}
                            currentCountry={currentCountry}
                            selectCountry={selectCountry}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default PhoneSearch;
