import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTitle } from 'Hooks';
import { addResellerClient } from 'Actions/reseller';
import { useIntl, FormPageLayout, Checkbox, Input } from 'Common';
import { Form } from 'Common/Requisites';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { ICreatePayerRequest } from 'Entities/CreatePayerRequest';
import Error from 'Entities/Error';
import ResellerClientAdd from 'Entities/ResellerClientAdd';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { emailValidate, passwordValidate } from 'Lib/helpers/utils';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { RoutePath, linkPathBuilder } from 'Lib/helpers/routes';

import s from './AddClient.module.pcss';
import Submit from './Submit';

const AddClient: FC = () => {
    const intl = useIntl();
    useTitle(intl.getMessage('clients_add_page_title'));
    const dispatch = useDispatch();
    const history = useHistory();
    const [outerValues, setOuterValues] = useState({
        email: '',
        password: '',
        newsletter_enabled: true,
    });
    const [outerError, setOuterError] = useState({
        email: '',
        password: '',
        newsletter_enabled: '',
    });
    const onChange = (field: keyof typeof outerValues) => (e: string | boolean) => {
        setOuterValues({ ...outerValues, [field]: e });
        setOuterError({ ...outerError, [field]: '' });
    };

    const validateEmail = (e: string) => {
        return ResellerClientAdd.emailValidate(e) && emailValidate(e);
    };
    const validatePassword = (e: string) => {
        return ResellerClientAdd.passwordValidate(e)
        && passwordValidate(e, ResellerClientAdd.passwordMinLength, true);
    };

    const onSubmit = (payer: ICreatePayerRequest) => {
        const reqEnt = new ResellerClientAdd({
            ...outerValues,
            new_payer: payer,
        });
        let haveError = false;
        const newOuterError = { ...outerError };
        if (!validateEmail(reqEnt.email)) {
            newOuterError.email = EMPTY_FIELD_ERROR;
            haveError = true;
        }
        if (!validatePassword(reqEnt.password)) {
            newOuterError.password = EMPTY_FIELD_ERROR;
            haveError = true;
        }
        if (haveError || reqEnt.validate().length > 0) {
            setOuterError(newOuterError);
            return;
        }
        dispatch(addResellerClient([reqEnt.serialize()], {
            error: (e: Error) => {
                if (e.fields?.email) {
                    const code: ApiErrorCode = e.fields?.email[0]?.error_code;
                    newOuterError.email = apiErrorCodeTranslate(intl, code);
                    haveError = true;
                }
                if (e.fields?.password) {
                    const code: ApiErrorCode = e.fields?.password[0]?.error_code;
                    newOuterError.password = apiErrorCodeTranslate(intl, code);
                    haveError = true;
                }
                if (haveError === true) {
                    setOuterError(newOuterError);
                }
            },
            result: () => {
                history.push(linkPathBuilder(intl.currentLocale, RoutePath.Clients));
            },
        }));
    };

    return (
        <FormPageLayout
            title={intl.getMessage('clients_create')}
            back={RoutePath.Clients}
        >
            <form id="outerForm" className={s.group} onSubmit={() => {}} noValidate>
                <div className={s.subtitle}>
                    {intl.getMessage('clients_create_common_data')}
                </div>
                <Input
                    autoComplete="new-email"
                    error={!!outerError.email}
                    errorMessage={outerError.email}
                    name="email"
                    onChange={onChange('email')}
                    placeholder={intl.getMessage('email')}
                    size="middle"
                    type="email"
                    validate={validateEmail}
                    value={outerValues.email}
                />
                <Input
                    autoComplete="new-password"
                    error={!!outerError.password}
                    errorMessage={outerError.password}
                    name="password"
                    onChange={onChange('password')}
                    placeholder={intl.getMessage('password')}
                    size="middle"
                    type="password"
                    validate={validatePassword}
                    value={outerValues.password}
                    wrapperClassName={s.password}
                />
                <div className={s.desc}>
                    {intl.getMessage('password_notice_easy', { min: ResellerClientAdd.passwordMinLength })}
                </div>
                <Checkbox
                    checked={outerValues.newsletter_enabled}
                    handleChange={(e) => onChange('newsletter_enabled')(e.target.checked)}
                    id="newsletters"
                    name="newsletters"
                >
                    {intl.getMessage('clients_create_newsletter')}
                </Checkbox>
            </form>
            <div className={s.group}>
                <div className={s.subtitle}>
                    {intl.getMessage('clients_create_client_info')}
                </div>
                <Form noTitle Submit={Submit} handleSubmit={onSubmit} />
            </div>
        </FormPageLayout>
    );
};

export default AddClient;
