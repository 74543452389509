import React, { FC, useState, createRef, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { createTicketPost } from 'Actions/support';
import { useIntl, Button, TextArea } from 'Common';
import Profile from 'Entities/Profile';
import { ITicketPostCreate } from 'Entities/TicketPostCreate';
import { fileValidator } from 'Lib/helpers/newTicket';

import s from './InputMessage.module.pcss';
import Attachments from '../Attachments';
import Avatar from '../Avatar';

interface InputMessageProps {
    ticketId: number;
    profile: Profile;
}
const InputMessage: FC<InputMessageProps> = ({ ticketId, profile }) => {
    const [message, setMessage] = useState('');
    const intl = useIntl();
    const dispatch = useDispatch();
    const inputRef = createRef<HTMLInputElement>();
    const [files, setFiels] = useState<File[]>([]);

    const attach = () => {
        inputRef?.current?.click();
    };

    const onFileAdd = (e: ChangeEvent<HTMLInputElement>) => {
        const max = profile.profileLimits.maxAttachmentsSizeBytes;
        const newFiles = [...files, ...Array.from(e.target.files!)];
        setFiels(fileValidator(intl, max, newFiles));
    };

    const handleSubmit = async () => {
        if (!message.trim() && !files.length) {
            return;
        }

        const data = new FormData();
        const params: ITicketPostCreate = {
            contents: message.trim() || intl.getPlural('support_attached_file', files.length),
        };

        data.set('params', JSON.stringify(params));
        files.forEach((f) => {
            data.append('attachments', f);
        });
        dispatch(createTicketPost([ticketId, data]));
        setFiels([]);
        setMessage('');
    };

    return (
        <div className={s.wrap}>
            <form className={s.inner} onSubmit={(e) => e.preventDefault()}>
                <div className={s.avatar}>
                    <Avatar type="client" icon="profile" />
                </div>
                <div className={s.form}>
                    <div className={s.input}>
                        <TextArea
                            name="message"
                            placeholder={intl.getMessage('enter_message_placeholder')}
                            value={message}
                            onChange={(v) => setMessage(v)}
                            className="textarea_chat"
                            wrapperClassName={s.group}
                            validate={() => true}
                            error={false}
                            autoSize
                            autoFocus
                        />
                    </div>
                    <div className={s.actions}>
                        <Button
                            type="primary"
                            size="medium"
                            onClick={handleSubmit}
                            className={s.send}
                            disabled={!message && files.length === 0}
                        >
                            {intl.getMessage('send')}
                        </Button>
                        <label htmlFor="attachedFiles" className={s.attach}>
                            {files.length > 0 ? (
                                <Attachments
                                    upload={files}
                                    ticketId={ticketId}
                                    onRemove={(f) => setFiels(f)}
                                    onAttach={attach}
                                />
                            ) : (
                                <Button
                                    type="icon"
                                    size="medium"
                                    icon="link"
                                    className={s.button}
                                />
                            )}
                            <Tooltip
                                placement="topRight"
                                overlayClassName="tooltip tooltip_small"
                                title={intl.getMessage('title_file_add')}
                            >
                                <input
                                    id="attachedFiles"
                                    ref={inputRef}
                                    multiple
                                    type="file"
                                    className={cn(
                                        s.files,
                                        { [s.files_hidden]: files.length > 0 },
                                    )}
                                    onChange={onFileAdd}
                                />
                            </Tooltip>
                        </label>
                    </div>
                </div>

            </form>
        </div>
    );
};

export default InputMessage;
