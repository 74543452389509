import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, Link } from 'Common';
import ComparativeExpenses from 'Entities/ComparativeExpenses';
import { getServerIcon } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import Diff from './Diff';
import s from '../Expenses.module.pcss';

interface ServerForecastProps {
    stats: ComparativeExpenses;
    isLink: boolean;
}

const ServerForecast: FC<ServerForecastProps> = ({ stats, isLink }) => {
    const { expenseForecast, expenseDiff, expenseEntity } = stats;

    if (!expenseEntity?.serverInfo) {
        return null;
    }

    const { serverInfo } = expenseEntity;
    const { id, tenantId, name, distribution } = serverInfo;

    const content = (
        <>
            <div className={s.serverName}>
                <Icon icon={getServerIcon(distribution)} className={s.distribution} />
                <div className={theme.common.textOverflow}>
                    {name}
                </div>
            </div>
            <Diff
                value={expenseForecast || 0}
                diff={expenseDiff}
                list
            />
        </>
    );

    if (isLink) {
        return (
            <Link
                to={RoutePath.Server}
                props={{ serverId: id, tenantId }}
                className={s.server}
            >
                {content}
            </Link>
        );
    }

    return (
        <div className={cn(s.server, s.server_deleted)}>
            {content}
        </div>
    );
};

export default ServerForecast;
