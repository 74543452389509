import React, { FC } from 'react';

import { CommonModalLayout, Icon, useIntl } from 'Common';

import s from './ChartCard.module.pcss';

interface LargeChartModalProps {
    handleClose: () => void;
}

const LargeChartModal: FC<LargeChartModalProps> = ({
    children,
    handleClose,
}) => {
    const intl = useIntl();

    return (
        <CommonModalLayout
            visible
            className="modal_chart"
            buttonText={intl.getMessage('billing_auto_invoice_cancel')}
            handleClose={handleClose}
            closeIcon={<Icon icon="minimize" />}
            width={930}
            centered
            noFooter
        >
            <div className={s.modalBody}>
                {children}
            </div>
        </CommonModalLayout>
    );
};

export default LargeChartModal;
