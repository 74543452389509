import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import Tariff, { ITariff } from 'Entities/Tariff';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class TariffsApi {
    static async listTariffs(): Promise<ITariff[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tariffs`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listTariffsForDistribution(distrib_id: number): Promise<ITariff[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tariffs/${distrib_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listTariffsForServer(tenant_id: number, server_id: number, optionalToken?: string): Promise<ITariff[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/tariffs`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
