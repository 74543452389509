import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, Icon } from 'Common';
import { COPY_BUTTON_TIMEOUT } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import s from './RefLink.module.pcss';

interface RefLinkProps {
    link: string;
}

const RefLink: FC<RefLinkProps> = ({ link }) => {
    const intl = useIntl();
    const [copyActive, setCopyActive] = useState(false);

    const handleCopy = () => {
        setCopyActive(true);
        setTimeout(() => {
            setCopyActive(false);
        }, COPY_BUTTON_TIMEOUT);
        navigator.clipboard.writeText(link);
    };

    return (
        <button
            type="button"
            className={cn(
                s.copy,
                { [s.copy_active]: copyActive },
            )}
            onClick={handleCopy}
        >
            <Icon
                icon={copyActive ? 'check' : 'link'}
                className={cn(
                    s.icon,
                    s.desktop,
                    { [s.icon_active]: copyActive },
                )}
            />
            <div className={s.link}>
                <div className={s.mediumText}>
                    <Icon
                        icon={copyActive ? 'check' : 'link'}
                        className={cn(
                            s.icon,
                            s.mobile,
                            { [s.icon_active]: copyActive },
                        )}
                    />
                    {link}
                </div>
                <div className={cn(s.flex, s.desktop)}>
                    {intl.getMessage('partners_ref_link_copy')}
                </div>
                <div className={s.mobile}>
                    <div className={s.mobileDesc}>
                        {intl.getMessage('partners_ref_link_copy')}
                    </div>
                    <div className={cn(theme.link.link, theme.link.blue)}>
                        {copyActive ? intl.getMessage('link_copied') : intl.getMessage('copy')}
                    </div>
                </div>
            </div>
            <div className={cn(theme.link.link, theme.link.blue, s.hiddenButton)}>
                {copyActive ? intl.getMessage('link_copied') : intl.getMessage('copy_partners_ref_link')}
            </div>
        </button>
    );
};

export default RefLink;
