import ComparativeStats, { IComparativeStats } from './ComparativeStats';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPartnerComparativeDetails {
    income_over_month: IComparativeStats;
    income_over_period: IComparativeStats;
    referrals_average_income: IComparativeStats;
    registrations: IComparativeStats;
    unique_hits: IComparativeStats;
}

export default class PartnerComparativeDetails {
    readonly _income_over_month: ComparativeStats;

    get incomeOverMonth(): ComparativeStats {
        return this._income_over_month;
    }

    readonly _income_over_period: ComparativeStats;

    get incomeOverPeriod(): ComparativeStats {
        return this._income_over_period;
    }

    readonly _referrals_average_income: ComparativeStats;

    get referralsAverageIncome(): ComparativeStats {
        return this._referrals_average_income;
    }

    readonly _registrations: ComparativeStats;

    get registrations(): ComparativeStats {
        return this._registrations;
    }

    readonly _unique_hits: ComparativeStats;

    get uniqueHits(): ComparativeStats {
        return this._unique_hits;
    }

    constructor(props: IPartnerComparativeDetails) {
        this._income_over_month = new ComparativeStats(props.income_over_month);
        this._income_over_period = new ComparativeStats(props.income_over_period);
        this._referrals_average_income = new ComparativeStats(props.referrals_average_income);
        this._registrations = new ComparativeStats(props.registrations);
        this._unique_hits = new ComparativeStats(props.unique_hits);
    }

    serialize(): IPartnerComparativeDetails {
        const data: IPartnerComparativeDetails = {
            income_over_month: this._income_over_month.serialize(),
            income_over_period: this._income_over_period.serialize(),
            referrals_average_income: this._referrals_average_income.serialize(),
            registrations: this._registrations.serialize(),
            unique_hits: this._unique_hits.serialize(),
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            income_over_period: this._income_over_period.validate().length === 0,
            income_over_month: this._income_over_month.validate().length === 0,
            referrals_average_income: this._referrals_average_income.validate().length === 0,
            unique_hits: this._unique_hits.validate().length === 0,
            registrations: this._registrations.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPartnerComparativeDetails): PartnerComparativeDetails {
        return new PartnerComparativeDetails(props);
    }

    readonly keys: { [key: string]: string } = {
        incomeOverMonth: 'income_over_month',
        incomeOverPeriod: 'income_over_period',
        referralsAverageIncome: 'referrals_average_income',
        registrations: 'registrations',
        uniqueHits: 'unique_hits',
        }
;

    mergeDeepWith(props: Partial<PartnerComparativeDetails>): PartnerComparativeDetails {
        const updateData: Partial<IPartnerComparativeDetails> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PartnerComparativeDetails) => {
            const updateKey = this.keys[key] as keyof IPartnerComparativeDetails;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPartnerComparativeDetails, keyof IPartnerComparativeDetails>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PartnerComparativeDetails({ ...this.serialize(), ...updateData });
    }
}
