// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IApiKey {
    api_key: string;
}

export default class ApiKey {
    readonly _api_key: string;

    /**
     * Description: API key
     * Example: 3024d3b0-868b-4812-bead-a744f574fcfb
     */
    get apiKey(): string {
        return this._api_key;
    }

    static apiKeyValidate(apiKey: string): boolean {
        return typeof apiKey === 'string' && !!apiKey.trim();
    }

    constructor(props: IApiKey) {
        this._api_key = props.api_key.trim();
    }

    serialize(): IApiKey {
        const data: IApiKey = {
            api_key: this._api_key,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            api_key: typeof this._api_key === 'string' && !!this._api_key.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IApiKey): ApiKey {
        return new ApiKey(props);
    }

    readonly keys: { [key: string]: string } = {
        apiKey: 'api_key',
        }
;

    mergeDeepWith(props: Partial<ApiKey>): ApiKey {
        const updateData: Partial<IApiKey> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ApiKey) => {
            const updateKey = this.keys[key] as keyof IApiKey;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IApiKey, keyof IApiKey>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ApiKey({ ...this.serialize(), ...updateData });
    }
}
