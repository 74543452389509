import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { notifyError, useIntl, Button, DownloadButton, CommonModalLayout } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import { deleteSnapshotProgress, downloadSnapshot, getSnapshotDownloadLink } from 'Actions/snapshots';
import { useSecurityActions } from 'Lib/hooks/hooks';
import { SecurityActionType } from 'Entities/SecurityActionType';
import CodeInput from 'Common/ui/controls/CodeInput';
import Error from 'Entities/Error';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import SnapshotDownloadLink from 'Entities/SnapshotDownloadLink';
import { OperationStatus } from 'Entities/OperationStatus';
import theme from 'Lib/theme';

interface ModalDownloadProps {
    visible: boolean;
    close: () => void;
    progress: OperationProgress | undefined;
    cost: number;
    snapId: number;
    tenantId: number;
    serverId: number;
    sizeGib: number;
}

const ModalDownload: FC<ModalDownloadProps> = ({
    visible, close, progress, snapId, tenantId, serverId, cost, sizeGib,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [snapshotDownloadLink, setSnapshotDownloadLink] = useState<SnapshotDownloadLink | null>(null);

    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityActions(SecurityActionType.DOWNLOAD_SNAPSHOT);

    const handleOk = () => {
        if (shouldConfirmAction && !codeSent) {
            sendConfirmationCode({
                tenantId,
                serverId,
            });
        }
        if (!shouldConfirmAction) {
            dispatch(downloadSnapshot([tenantId, serverId, snapId, {}]));
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(downloadSnapshot([tenantId, serverId, snapId, {
                security_code: code,
            }], {
                error: (e: Error) => {
                    if (e.errorCode === ApiErrorCode.DOWNLOAD_SNAPSHOT_NOT_ENOUGH_BALANCE) {
                        notifyError(
                            apiErrorCodeTranslate(intl, e.errorCode),
                            e.errorCode,
                            { duration: 0 },
                        );
                        close();
                        return;
                    }
                    setCodeError(true);
                },
                result: () => {
                    dispatch(getSnapshotDownloadLink([tenantId, serverId, snapId], {
                        result: (result) => {
                            setSnapshotDownloadLink(result);
                        },
                    }));
                },
            }));
        }
    };

    useEffect(() => {
        let canUpdate = true;
        dispatch(getSnapshotDownloadLink([tenantId, serverId, snapId], {
            result: (result) => {
                if (canUpdate) {
                    setSnapshotDownloadLink(result);
                }
            },
            error: () => {
                handleOk();
            },
        }));
        return () => {
            canUpdate = false;
        };
    }, []);

    const handleClose = () => {
        if (progress) {
            dispatch(deleteSnapshotProgress(snapId));
        }
        close();
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('snapshots_download_title')}
            handleClose={handleClose}
            width={480}
            noFooter
            centered
        >
            {shouldConfirmAction && !snapshotDownloadLink && !progress ? (
                <>
                    {(codeSent && deliveryMessage) && (
                        <>
                            <div
                                className={cn(
                                    theme.modal.desc,
                                    theme.modal.desc_notice,
                                )}
                            >
                                {deliveryMessage}
                            </div>
                            <CodeInput
                                autoFocus
                                value={code}
                                setValue={(e) => setCode(e)}
                                codeError={codeError}
                                setCodeError={(e) => setCodeError(e)}
                                onSendAgain={() => sendConfirmationCode({
                                    tenantId,
                                    serverId,
                                })}
                            />
                            <Button
                                type="primary"
                                size="medium"
                                onClick={handleOk}
                            >
                                {intl.getMessage('download')}
                            </Button>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div
                        className={cn(
                            theme.modal.desc,
                            theme.modal.desc_notice,
                        )}
                    >
                        {intl.getMessage('modal_upload_cost', { value: cost })}
                    </div>
                    <div className={theme.modal.desc}>
                        <DownloadButton
                            link={snapshotDownloadLink?.downloadLink}
                            label={intl.getMessage('snapshots_download_title')}
                            sizeGib={sizeGib}
                            loading={progress?.status === OperationStatus.PENDING}
                        />
                    </div>
                    {snapshotDownloadLink && (
                        <div
                            className={cn(
                                theme.modal.desc,
                                theme.modal.desc_notice,
                            )}
                        >
                            {intl.getMessage('snapshots_download_desc')}
                        </div>
                    )}
                </>
            )}
        </CommonModalLayout>
    );
};

export default ModalDownload;
