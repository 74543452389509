import React from 'react';

import theme from 'Lib/theme';

const whiteText = (text: string) => (
    <span className={theme.color.white}>
        {text}
    </span>
);

export default whiteText;
