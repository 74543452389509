import React, { FC, useRef, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { Icon, Link, ServerIndicator, useIntl } from 'Common';
import { Store } from 'Store';
import Server from 'Entities/Server';
import { getServerIcon } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import s from './Trigger.module.pcss';

interface ActionSpanStoreProps {
    servers: Map<number, Server>;
}

interface ActionSpanOwnProps {
    serversScope: number[] | undefined;
}

type ActionSpanProps = ActionSpanStoreProps & ActionSpanOwnProps;

const ActionSpan: FC<ActionSpanProps> = ({ servers, serversScope }) => {
    const intl = useIntl();
    const listRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLSpanElement>(null);
    const [showList, setShowList] = useState(false);

    if (!serversScope || serversScope.length === 0) {
        return (
            <div className={s.value}>
                {intl.getPlural('all_servers', 0)}
            </div>
        );
    }

    const filteredServers = serversScope.map((scope) => servers.get(scope));

    const serversList = (
        <div
            className={cn(
                s.dropdown,
                s.dropdown_servers,
                theme.common.customScrollbar,
            )}
            ref={listRef}
        >
            {filteredServers.map((server) => {
                if (server) {
                    return (
                        <Link
                            to={RoutePath.Server}
                            props={{ serverId: server.id, tenantId: server.tenantId }}
                            key={server.id}
                            className={s.link}
                        >
                            <ServerIndicator state={server.state} className={s.indicator} />
                            <Icon
                                className={s.icon}
                                icon={getServerIcon(server.distribution.type)}
                            />
                            <span className={theme.common.textOverflow} title={server.name}>
                                {server.name}
                            </span>
                        </Link>
                    );
                }

                return null;
            })}
        </div>
    );

    return (
        <>
            <div className={s.value}>
                <Dropdown
                    overlay={serversList}
                    placement="bottomLeft"
                    trigger={['click']}
                    onVisibleChange={(v: boolean) => setShowList(v)}
                    visible={showList}
                >
                    <span
                        className={cn(
                            theme.link.link,
                            theme.link.blue,
                        )}
                        ref={triggerRef}
                    >
                        {intl.getPlural('all_servers', serversScope.length)}
                    </span>
                </Dropdown>
            </div>
        </>
    );
};

const selectServers = (store: Store) => store.server;

const selector = createSelector(
    [selectServers],
    (servers) => ({ servers }),
);

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(ActionSpan);
