import React, { useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import qs from 'qs';

import { useIntl } from 'Common';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { IServerJsonStats } from 'Entities/ServerJsonStats';
import { RESOURCES_TYPES } from 'Lib/helpers/consts';

import LargeChartModal from '../ChartCard/LargeChartModal';
import { ChartContext, IChartContext } from '../ChartContext';
import ChartCard from '../ChartCard';

const ChartContainer = <T extends IServerJsonStats,>() => {
    const intl = useIntl();
    const history = useHistory();
    const { params } = useRouteMatch<{ resourceType: RESOURCES_TYPES }>();

    const { server, chart } = useContext(ChartContext as React.Context<IChartContext<T>>);
    const { type, valueRange, dynamicValueRangeMax } = chart;

    const openByDefault = params.resourceType === chart.type;
    const [modal, setModal] = useState(openByDefault);

    const customValueRange = valueRange || dynamicValueRangeMax;
    const chartWithoutVerticalZoom = type === RESOURCES_TYPES.MEMORY || type === RESOURCES_TYPES.DISK_SPACE;
    const [verticalZoom, setVerticalZoom] = useState(!!customValueRange && !chartWithoutVerticalZoom);

    const lineIds = chart.lines.map((line) => line.id);
    const [currentLines, setCurrentLines] = useState<Set<keyof T>>(new Set(lineIds));

    const getQueryParams = () => {
        const query = window.location.search.replace('?', '');
        return qs.parse(query);
    };

    const openModal = () => {
        setModal(true);

        history.replace(linkPathBuilder(
            intl.currentLocale,
            RoutePath.ServerChartModal,
            { serverId: server.id, tenantId: server.tenantId, resourceType: chart.type },
            getQueryParams(),
        ));
    };

    const closeModal = () => {
        setModal(false);

        history.replace(linkPathBuilder(
            intl.currentLocale,
            RoutePath.Server,
            { serverId: server.id, tenantId: server.tenantId },
            getQueryParams(),
        ));
    };

    return (
        <>
            <ChartCard
                verticalZoom={verticalZoom}
                setVerticalZoom={setVerticalZoom}
                currentLines={currentLines}
                setCurrentLines={setCurrentLines}
                handleModal={openModal}
            />

            {modal && (
                <LargeChartModal
                    handleClose={closeModal}
                >
                    <ChartCard
                        verticalZoom={verticalZoom}
                        setVerticalZoom={setVerticalZoom}
                        currentLines={currentLines}
                        setCurrentLines={setCurrentLines}
                        handleModal={closeModal}
                        inModal
                    />
                </LargeChartModal>
            )}
        </>
    );
};

export default ChartContainer;
