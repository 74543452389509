import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteSnapshot, createServerFromSnaphot } from 'Actions/snapshots';
import { useIntl, Button } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import SnapshotEnt from 'Entities/Snapshot';
import Server from 'Entities/Server';
import Tariff from 'Entities/Tariff';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import ModalDelSnapshot from '../ModalDelSnapshot';
import ModalRollback from '../ModalRollback';
import ModalDownload from '../ModalDownload';
import ModalCreateFromSnapshot from '../ModalCreateFromSpanshot';
import SnapshotLayout from '../SnapshotLayout';

import s from './Snapshot.module.pcss';

interface SnapshotProps {
    snapshot: SnapshotEnt;
    server: Server;
    disabled: boolean;
    progress: OperationProgress | undefined;
    tariff: Tariff | undefined;
}

const Snapshot: FC<SnapshotProps> = ({ snapshot, server, disabled, progress, tariff }) => {
    const history = useHistory();

    const [openDelModal, setOpenDelModal] = useState(false);
    const [openRestoreModal, setOpenRestoreModal] = useState(false);
    const [openDownloadModal, setOpenDownloadModal] = useState(false);
    const [openCreateFrom, setOpenCreateFrom] = useState(false);
    const [visible, setVisible] = useState(false);

    const dispatch = useDispatch();
    const intl = useIntl();

    const { name, timeAddedMillis, id, downloadSizeGib, description } = snapshot;
    const { tenantId, id: serverId } = server;

    const onDeleteSnap = (delChildren: boolean) => {
        dispatch(deleteSnapshot([
            tenantId,
            serverId,
            id,
            { delete_children: delChildren },
        ]));
    };
    const onCreateFrom = (toTenantId: number, newServerName: string) => {
        dispatch(createServerFromSnaphot([tenantId, serverId, {
            name: newServerName,
            to_tenant_id: toTenantId,
            snapshot_id: id,
        }]));
        history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
    };

    const parsedTime = dayjs(timeAddedMillis);
    const closeVisible = (f: (v: boolean) => void) => () => {
        setVisible(false);
        f(true);
    };

    const graySpan = (text: string) => (
        <span
            className={theme.color.concrete}
        >
            &nbsp;{text}
        </span>
    );

    return (
        <SnapshotLayout>
            <div className={s.info}>
                <div className={s.name}>
                    {name}
                </div>
                <div className={s.date}>
                    {parsedTime.format(intl.getMessage('time_format_full'))}
                </div>
            </div>
            <div className={s.right}>
                {!disabled && (
                    <Popover
                        content={(
                            <div className={theme.popover.popover}>
                                <button
                                    type="button"
                                    className={theme.popover.item}
                                    onClick={closeVisible(setOpenCreateFrom)}
                                >
                                    {intl.getMessage('snapshots_popover_create')}
                                </button>
                                <button
                                    type="button"
                                    className={theme.popover.item}
                                    onClick={closeVisible(setOpenRestoreModal)}
                                >
                                    {intl.getMessage('restore')}
                                </button>
                                <button
                                    type="button"
                                    className={theme.popover.item}
                                    onClick={closeVisible(setOpenDownloadModal)}
                                >
                                    {intl.getMessage('download_value_gb', {
                                        value: downloadSizeGib,
                                        gray: graySpan,
                                    })}
                                </button>
                                <button
                                    type="button"
                                    className={cn(theme.popover.item, theme.popover.item_danger)}
                                    onClick={closeVisible(setOpenDelModal)}
                                >
                                    {intl.getMessage('delete')}
                                </button>
                                {description && (
                                    <>
                                        <div className={theme.popover.divider} />
                                        <div className={theme.popover.description}>
                                            {description}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                        placement="right"
                        trigger="click"
                        visible={visible}
                        onVisibleChange={(v) => setVisible(v)}
                        overlayClassName="popover"
                    >
                        <Button
                            type="icon"
                            icon="options"
                            title={intl.getMessage('title_control_snapshot')}
                        />
                    </Popover>
                )}
            </div>
            {openDelModal && (
                <ModalDelSnapshot
                    name={name}
                    visible={openDelModal}
                    close={() => setOpenDelModal(false)}
                    onOk={onDeleteSnap}
                />
            )}
            {openRestoreModal && (
                <ModalRollback
                    visible={openRestoreModal}
                    close={() => setOpenRestoreModal(false)}
                    snapId={id}
                    tenantId={tenantId}
                    serverId={serverId}
                />
            )}
            {openDownloadModal && (
                <ModalDownload
                    visible={openDownloadModal}
                    close={() => setOpenDownloadModal(false)}
                    progress={progress}
                    snapId={id}
                    cost={snapshot.downloadCostForecast}
                    sizeGib={snapshot.downloadSizeGib}
                    tenantId={tenantId}
                    serverId={serverId}
                />
            )}
            {openCreateFrom && (
                <ModalCreateFromSnapshot
                    visible={openCreateFrom}
                    close={() => setOpenCreateFrom(false)}
                    onOk={onCreateFrom}
                    tariff={tariff}
                    server={server}
                />
            )}
        </SnapshotLayout>
    );
};

export default Snapshot;
