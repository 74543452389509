import Distribution from 'Entities/ServerDistribution';
import { actionCreator } from '.';

export enum DistributionActions {
    GetDistributions = 'GetDistributions',
    SaveDistributions = 'SaveDistributions',
}

const getDistributionsList = actionCreator(DistributionActions.GetDistributions);
const saveDistributionsList = actionCreator<Distribution[]>(DistributionActions.SaveDistributions);

export {
    getDistributionsList,
    saveDistributionsList,
};
