import './lib/ant/ant.less';
import React from 'react';
import ReactDOM from 'react-dom';
import { initSentry } from '@adg/sentry';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import App from './components/App';
import './lib/ant';
import './main.pcss';

dayjs.extend(timezone);
dayjs.extend(utc);

initSentry({
    dsnKey: SENTRY_DSN_KEY,
    appVersion: VERSION,
    sentryProject: SENTRY_PROJECT,
    host: 'serveroid.com',
});

ReactDOM.render(<App />, document.getElementById('app'));
