import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Row, Col } from 'antd';
import dayjs from 'dayjs';
import cn from 'classnames';

import { useTitle } from 'Hooks';
import { getAccountDashboard, clearDashboard } from 'Actions/account';
import { DashboardHeader, InnerPageLayout, useIntl, LoaderPageLayout, Link } from 'Common';
import { ClientWarning } from 'Entities/ClientWarning';
import AccountDashboard from 'Entities/AccountDashboard';
import PayerInfo from 'Entities/PayerInfo';
import Server from 'Entities/Server';
import { RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import theme from 'Lib/theme';

import log from 'Components/ChangeLog/log.json';

import {
    Important,
    ImportantProps,
    PersonalAccount,
    Servers,
    Notifications,
    Referral,
    Clients,
} from './components';

import s from './Dashboard.module.pcss';

interface DashboardProps {
    dashboard: AccountDashboard | null;
    profile: Store['profile']['info'];
    payer: PayerInfo[] | null;
    servers: Map<number, Server>;
}

const Dashboard: FC<DashboardProps> = ({ dashboard, profile, payer, servers }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('dashboard_page_title'));
    const dispatch = useDispatch();
    const [showChangeLogBadge, setShowChangeLogBadge] = useState(false);

    const getChangeLogBadge = () => {
        const lastReadDate = AppConfigStorage.getLastChangeLogDate();

        if (!lastReadDate) {
            setShowChangeLogBadge(true);
            return;
        }

        if (log.length > 0) {
            const lastEntry = log[0];
            const newLogsAvailable = dayjs(lastReadDate).isBefore(dayjs(lastEntry.timeAdded));
            setShowChangeLogBadge(newLogsAvailable);
        }
    };

    useEffect(() => {
        if (!dashboard) {
            dispatch(getAccountDashboard());
        }

        getChangeLogBadge();

        return () => {
            dispatch(clearDashboard());
        };
    }, []);

    const getContent = () => {
        if (!dashboard) {
            return <LoaderPageLayout />;
        }
        let importantProps: ImportantProps | null = null;
        if (dashboard.warnings?.includes(ClientWarning.PHONE_UNKNOWN)) {
            importantProps = {
                color: 'blue',
                desc: intl.getMessage('dashboard_important_telephone_desc'),
                link: RoutePath.Profile,
                image: 'phone',
            };
        }
        if (dashboard.warnings?.includes(ClientWarning.NO_RUNNING_SERVERS)) {
            importantProps = {
                color: 'blue',
                desc: intl.getMessage('dashboard_important_active_servers_desc'),
                link: RoutePath.ServersList,
                image: 'server',
            };
        }
        if (
            dashboard.warnings?.includes(ClientWarning.NO_SERVERS)
            && profile?.trialBonusAvailable
        ) {
            importantProps = {
                color: 'blue',
                desc: intl.getMessage('dashboard_important_trial_no_server_desc'),
                link: payer && payer.length > 0 ? RoutePath.OnboardingAddFunds : RoutePath.Onboarding,
                image: 'server',
                queryParams: { showBanner: true },
            };
        } else if (dashboard.warnings?.includes(ClientWarning.NO_SERVERS)) {
            importantProps = {
                color: 'blue',
                desc: intl.getMessage('dashboard_important_no_server_desc'),
                link: RoutePath.NewServer,
                image: 'server',
            };
        }
        if (dashboard.warnings?.includes(ClientWarning.BALANCE_DEPLETED)) {
            importantProps = {
                color: 'red',
                desc: intl.getMessage('dashboard_important_balance_desc'),
                link: RoutePath.AddFunds,
                image: 'money',
            };
        }
        if (dashboard.warnings?.includes(ClientWarning.BALANCE_LOW)) {
            importantProps = {
                color: 'red',
                desc: intl.getMessage('dashboard_important_low_balance_desc'),
                link: RoutePath.AddFunds,
                image: 'money',
            };
        }

        const reseller = profile?.reseller;

        return (
            <>
                {importantProps && (
                    <div className={s.important}>
                        <Important {...importantProps} />
                    </div>
                )}
                <Row gutter={[24, 0]}>
                    <Col span={24} lg={12}>
                        <div className={s.block}>
                            <PersonalAccount
                                balance={dashboard.balance}
                                balanceLeftDays={dashboard.balanceLeftDays}
                                depleted={
                                    !!dashboard.warnings?.includes(ClientWarning.BALANCE_DEPLETED)
                                }
                            />
                        </div>
                    </Col>
                    <Col span={24} lg={12}>
                        <Row gutter={[24, 24]}>
                            <Col span={24} lg={reseller ? 12 : 24}>
                                <Referral
                                    reseller={!!reseller}
                                    totalRegistrations={dashboard.partnerOverallClients}
                                    newRegistrations={dashboard.partnerTodayClients}
                                    comparativeIncome={dashboard.partnerComparativeIncome}
                                />
                            </Col>
                            {reseller && (
                                <Col span={24} lg={12}>
                                    <Clients
                                        accounts={dashboard.resellerClientsCount}
                                        comparativeIncome={dashboard.resellerComparativeIncome}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col span={24} lg={12}>
                        <div className={s.block}>
                            <Notifications
                                newNotifications={dashboard.newNotificationsCount}
                                notifications={dashboard.newNotifications}
                                servers={servers}
                            />
                        </div>
                    </Col>
                    <Col span={24} lg={12}>
                        <Servers
                            totalServersCount={dashboard.serversCount}
                            comparativeExpensesDaily={dashboard.serverComparativeExpensesDaily}
                            comparativeExpensesMonthly={
                                dashboard.serverComparativeExpensesMonthly
                            }
                        />
                    </Col>
                </Row>
            </>
        );
    };
    return (
        <InnerPageLayout
            header={(
                <DashboardHeader
                    navigation={(
                        <>
                            <div className={cn(theme.header.link, theme.header.active)}>
                                {intl.getMessage('menu_dashboard')}
                            </div>
                            <Link
                                to={RoutePath.ChangeLog}
                                className={theme.header.link}
                            >
                                {intl.getMessage('changelog_title')}
                                {showChangeLogBadge && (
                                    <div
                                        className={cn(
                                            theme.Badge.badge,
                                            theme.Badge.yellow,
                                            theme.Badge.header,
                                        )}
                                    />
                                )}
                            </Link>
                        </>
                    )}
                />
            )}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

const selectAccountDetails = (store: Store) => store.account.dashboard;
const selectServers = (store: Store) => store.server;
const selectProfileInfo = (store: Store) => store.profile.info;
const selectProfilePayer = (store: Store) => store.profile.profilePayer;

const selector = createSelector(
    [selectAccountDetails, selectProfileInfo, selectServers, selectProfilePayer],
    (dashboard, profile, servers, payer) => ({ dashboard, profile, servers, payer }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Dashboard);
