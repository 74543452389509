import React, { FC } from 'react';
import cn from 'classnames';
import s from './SnapshotLayout.module.pcss';

const SnapshotLayout: FC = ({ children }) => {
    return (
        <div className={s.snapshot}>
            <svg className={cn(s.icon, 'icon')}>
                <use xlinkHref="#snapshot" />
            </svg>
            {children}
        </div>
    );
};

export default SnapshotLayout;
