import React, { FC, useState, useEffect } from 'react';
import { Select } from 'antd';
import cn from 'classnames';

import { useIntl, Checkbox, Button } from 'Common';
import { useEnter } from 'Hooks';
import DnsSettings from 'Entities/DnsSettings';
import { DnsTemplateProvider } from 'Entities/DnsTemplateProvider';
import DnsTemplateRecord from 'Entities/DnsTemplateRecord';
import { DnsRecordType } from 'Entities/DnsRecordType';
import DnsRecordCreateList from 'Entities/DnsRecordCreateList';
import { secondsToHour } from 'Lib/helpers/utils';

import s from './Form.module.pcss';

const { Option } = Select;

interface MailServiceProps {
    settings: DnsSettings;
    closeForm: () => void;
    onAdd: (data: DnsRecordCreateList) => void;
}
const MailService: FC<MailServiceProps> = ({ settings, closeForm, onAdd }) => {
    const intl = useIntl();

    const [mailService, setMailService] = useState(DnsTemplateProvider.GOOGLE);
    const [includedRecordTypes, setIncludedRecordTypes] = useState<Set<DnsRecordType>>(new Set());

    const [templatesMap, setTemplatesMap] = useState(() => {
        const state = new Map<number, DnsTemplateRecord>();
        settings.templates.forEach((t, i) => {
            state.set(i, t);
        });
        return state;
    });

    useEffect(() => {
        const state = new Map<number, DnsTemplateRecord>();
        settings.templates.forEach((t, i) => {
            state.set(i, t);
        });
        setTemplatesMap(state);
    }, [settings]);

    const templates = Array.from(templatesMap.entries());
    const currentTemplates = templates.filter(([,t]) => (t.templateProvider === mailService));

    const onCheck = (type: DnsRecordType) => () => {
        const newSet = new Set(includedRecordTypes);
        if (newSet.has(type)) {
            newSet.delete(type);
        } else {
            newSet.add(type);
        }
        setIncludedRecordTypes(newSet);
    };

    const onChangeMailService = (value: DnsTemplateProvider) => {
        setMailService(value);
        setIncludedRecordTypes(new Set());
    };

    const onAddHandler = () => {
        const addedTemplates: DnsTemplateRecord[] = [];
        includedRecordTypes.forEach((type) => {
            const filteredTemplates = currentTemplates
                .map((([,t]) => t))
                .filter((t) => t.type === type);
            addedTemplates.push(...filteredTemplates);
        });

        const list = addedTemplates.map((t) => t.serialize());
        const newRecords = new DnsRecordCreateList({ records: list, rewrite: false });
        onAdd(newRecords);
        closeForm();
    };

    useEnter(onAddHandler, [onAddHandler]);

    return (
        <>
            <div className={s.wrap}>
                <div className={cn(s.group, s.group_select)}>
                    <div className={s.label}>
                        {intl.getMessage('dns_mail_service')}
                    </div>
                    <Select
                        id="mailService"
                        size="large"
                        className="select select--setting select--block"
                        value={mailService}
                        onChange={onChangeMailService}
                        suffixIcon={(
                            <svg className="icon select__arrow">
                                <use xlinkHref="#down" />
                            </svg>
                        )}
                        dropdownClassName="select-dropdown"
                    >
                        <Option value={DnsTemplateProvider.GOOGLE}>
                            {intl.getMessage('dns_mail_service_google')}
                        </Option>
                        <Option value={DnsTemplateProvider.YANDEX}>
                            {intl.getMessage('dns_mail_service_yandex')}
                        </Option>
                        <Option value={DnsTemplateProvider.MAIL_RU}>
                            {intl.getMessage('dns_mail_service_mail_ru')}
                        </Option>
                    </Select>
                </div>

                {Object.keys(DnsRecordType)
                    .sort((a, b) => a.length - b.length)
                    .map((type) => {
                        const typedTemplares = currentTemplates
                            .filter(([,t]) => t.type === DnsRecordType[type as DnsRecordType]);
                        return typedTemplares.length > 0 ? (
                            <div className={cn(s.group, s.group_border)} key={type}>
                                <div className={s.checkbox}>
                                    <Checkbox
                                        id={`add_${type}`}
                                        name={`add_${type}`}
                                        handleChange={onCheck(type as DnsRecordType)}
                                        checked={includedRecordTypes.has(type as DnsRecordType)}
                                    >
                                        {intl.getMessage('dns_add_typed_records', { type })}
                                    </Checkbox>
                                </div>
                                {typedTemplares.map(([id, t]) => (
                                    <div className={s.row} key={id}>
                                        <div className={cn(s.info, s.info_name)}>
                                            <div className={s.value}>
                                                {t.host}
                                            </div>
                                            <div className={cn(s.label, s.label_row)}>
                                                {intl.getMessage('dns_name')}
                                            </div>
                                        </div>
                                        <div className={cn(s.info, s.info_main)}>
                                            <div className={s.value}>
                                                {t.data}
                                            </div>
                                            <div className={cn(s.label, s.label_row)}>
                                                {intl.getMessage('dns_value')}
                                            </div>
                                        </div>
                                        <div className={s.right}>
                                            {t.priority && (
                                                <div className={s.info}>
                                                    <div className={s.value}>
                                                        {t.priority}
                                                    </div>
                                                    <div className={cn(s.label, s.label_row)}>
                                                        {intl.getMessage('dns_priority')}
                                                    </div>
                                                </div>
                                            )}
                                            <div className={s.info}>
                                                <div className={s.value}>
                                                    {intl.getMessage('hour_value', { hour: secondsToHour(t.ttlSeconds) })}
                                                </div>
                                                <div className={cn(s.label, s.label_row)}>
                                                    {intl.getMessage('dns_ttl')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : null;
                    })}
            </div>
            <div className={s.buttonContainer}>
                <Button
                    type="primary"
                    size="medium"
                    onClick={onAddHandler}
                    inGroup
                >
                    {intl.getMessage('add')}
                </Button>
                <Button
                    type="link"
                    size="medium"
                    onClick={closeForm}
                >
                    {intl.getMessage('cancel')}
                </Button>
            </div>
        </>
    );
};

export default MailService;
