import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link, ServerIndicator, Icon } from 'Common';
import Server from 'Entities/Server';
import OperationProgress from 'Entities/OperationProgress';
import { RoutePath } from 'Lib/helpers/routes';
import { getServerIcon } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

interface ServerItemProps {
    currentId: number;
    searchQuery: string;
    server: Server;
    operationProgress?: OperationProgress;
    handleClick: () => void;
}

const ServerItem: FC<ServerItemProps> = ({
    currentId,
    searchQuery,
    server,
    operationProgress,
    handleClick,
}) => {
    const intl = useIntl();
    const {
        id,
        tenantId,
        distribution,
        state,
        name,
        ipv4Addresses,
        ipv6Addresses,
        privateAddress,
    } = server;

    const ips = [...ipv4Addresses, ...ipv6Addresses, privateAddress];

    const filteredIp = ips.filter((v) => v.host.includes(searchQuery)).map((v) => v.host);

    let ipsSearch = '';
    if (searchQuery === '') {
        ipsSearch = intl.getPlural('ip_count', ips.length);
    }
    if (searchQuery !== '' && (filteredIp.length > 1)) {
        ipsSearch = intl.getPlural('ip_count_search', filteredIp.length);
    }
    if (searchQuery !== '' && filteredIp.length === 1) {
        [ipsSearch] = filteredIp;
    }

    return (
        <Link
            to={RoutePath.Server}
            props={{ serverId: id, tenantId }}
            className={cn(
                theme.search.server,
                theme.search.server_small,
            )}
            onClick={handleClick}
            stop
        >
            <ServerIndicator state={state} progress={operationProgress} />
            <Icon icon={getServerIcon(distribution.type)} />
            <div
                className={cn(
                    theme.search.name,
                    theme.search.name_small,
                )}
            >
                {name}
            </div>
            <div className={cn(theme.search.id, theme.search.id_desktop)}>
                {ipsSearch}
            </div>
            {currentId === id && (
                <Icon
                    icon="check"
                    className={cn(theme.search.check, theme.search.check_small)}
                />
            )}
        </Link>
    );
};

export default ServerItem;
