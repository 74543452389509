import { SecurityActionType } from './SecurityActionType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISnapshotDownload {
    actionType?: SecurityActionType;
    security_code?: string;
}

export default class SnapshotDownload {
    readonly _actionType: SecurityActionType | undefined;

    get actionType(): SecurityActionType | undefined {
        return this._actionType;
    }

    readonly _security_code: string | undefined;

    /** */
    get securityCode(): string | undefined {
        return this._security_code;
    }

    constructor(props: ISnapshotDownload) {
        if (props.actionType) {
            this._actionType = props.actionType;
        }
        if (typeof props.security_code === 'string') {
            this._security_code = props.security_code.trim();
        }
    }

    serialize(): ISnapshotDownload {
        const data: ISnapshotDownload = {
        };
        if (typeof this._actionType !== 'undefined') {
            data.actionType = this._actionType;
        }
        if (typeof this._security_code !== 'undefined') {
            data.security_code = this._security_code;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            security_code: !this._security_code ? true : typeof this._security_code === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISnapshotDownload): SnapshotDownload {
        return new SnapshotDownload(props);
    }

    readonly keys: { [key: string]: string } = {
        actionType: 'actionType',
        securityCode: 'security_code',
        }
;

    mergeDeepWith(props: Partial<SnapshotDownload>): SnapshotDownload {
        const updateData: Partial<ISnapshotDownload> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SnapshotDownload) => {
            const updateKey = this.keys[key] as keyof ISnapshotDownload;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISnapshotDownload, keyof ISnapshotDownload>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SnapshotDownload({ ...this.serialize(), ...updateData });
    }
}
