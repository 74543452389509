import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTitle } from 'Hooks';
import { checkAuth } from 'Actions/user';
import registerApi from 'Apis/registration';
import { useIntl, Loader, Input, Button, InnerPageLayout, BackHeader } from 'Common';
import PasswordRecovery, { IPasswordRecovery } from 'Entities/PasswordRecovery';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { errorChecker, passwordValidate, saveCookieToken } from 'Lib/helpers/utils';
import { setAuthToken } from 'Lib/helpers/authHelper';
import theme from 'Lib/theme';

import s from './Auth.module.pcss';
import { ErrorResetPassword } from '../Errors';

const ResetPassword: FC = () => {
    const intl = useIntl();
    useTitle(intl.getMessage('reset_password_page_title'));
    const history = useHistory();
    const dispatch = useDispatch();
    const [errorCode, setErrorCode] = useState({ checked: false, error: false });
    const match = useRouteMatch<{ userId: string; code: string }>();

    const { code } = match.params;

    useEffect(() => {
        let canUpdate = true;
        if (!code) {
            setErrorCode({ checked: true, error: true });
        } else {
            const loader = async () => {
                const response = await registerApi.validateRegistrationCode(code);
                const { error } = errorChecker<{ token: string; cookie: string }>(response);
                if (canUpdate) {
                    if (error) {
                        setErrorCode({ checked: true, error: true });
                    } else {
                        setErrorCode({ checked: true, error: false });
                    }
                }
            };
            loader();
        }
        return () => {
            canUpdate = false;
        };
    }, []);

    const validatePasswordField = (e: string) => (
        PasswordRecovery.passwordValidate(e)
        && passwordValidate(e, PasswordRecovery.passwordMinLength, true)
    );

    const onSubmit = async (v: IPasswordRecovery, helpers: FormikHelpers<IPasswordRecovery>) => {
        const reqEnt = new PasswordRecovery(v);
        if (reqEnt.validate().length === 0
            && passwordValidate(reqEnt.password, PasswordRecovery.passwordMinLength, true)
        ) {
            const response = await registerApi.recoverPassword(reqEnt.serialize());
            const { result, error } = errorChecker<{token: string; cookie: string}>(response);
            if (result) {
                if (result.cookie) {
                    saveCookieToken(result.cookie);
                }
                setAuthToken(result.token);
                dispatch(checkAuth());
                history.push(linkPathBuilder(intl.currentLocale, RoutePath.Dashboard));
            } else if (error) {
                const { fields } = error;
                if (fields.password) {
                    const passwordError = fields.password[0];
                    helpers.setFieldError('password', apiErrorCodeTranslate(intl, passwordError.error_code));
                }
            }
        } else {
            helpers.setFieldError('password', EMPTY_FIELD_ERROR);
        }
    };
    if (!errorCode.checked) {
        return <Loader full />;
    }
    if (errorCode.error) {
        return <ErrorResetPassword />;
    }
    return (
        <InnerPageLayout
            header={<BackHeader link={RoutePath.Root} />}
            className={theme.content.auth}
        >
            <div className={cn(s.auth__panel, s.auth__panel_recovery)}>
                <div className={cn(s.auth__title, s['auth__title--registration'])}>
                    {intl.getMessage('reset_password_title')}
                </div>
                <div className={cn(s.auth__text, s['auth__text--top'])}>
                    {intl.getMessage('password_notice_easy', { min: PasswordRecovery.passwordMinLength })}
                </div>
                <Formik
                    initialValues={{ password: '', code: match.params.code } as IPasswordRecovery}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <div className={s.auth__group}>
                                <Input
                                    validateInstant
                                    autoFocus
                                    autoComplete="new-password"
                                    error={!!errors.password}
                                    errorMessage={errors.password}
                                    name="password"
                                    onChange={(e) => setFieldValue('password', e)}
                                    placeholder={intl.getMessage('password')}
                                    size="large"
                                    type="password"
                                    validate={validatePasswordField}
                                    value={values.password}
                                />
                            </div>
                            <div className={s.auth__group}>
                                <Button
                                    size="medium"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={isSubmitting || !touched}
                                >
                                    {intl.getMessage('done')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </InnerPageLayout>
    );
};

export default ResetPassword;
