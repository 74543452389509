// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerCostForecast {
    backup_cost: number;
    cpu_cost_current: number;
    cpu_cost_max: number;
    daily_backup_cost: number;
    daily_cpu_cost_current: number;
    daily_disk_cost: number;
    daily_fixed_cost: number;
    daily_forecast_cost: number;
    daily_ip_cost: number;
    daily_memory_cost: number;
    daily_overall_cost: number;
    daily_rx_traffic_cost: number;
    daily_tariff_cost: number;
    daily_traffic_cost: number;
    daily_tx_traffic_cost: number;
    disk_cost: number;
    fixed_cost: number;
    forecast_cost: number;
    ip_cost: number;
    memory_cost: number;
    overall_cost: number;
    rx_traffic_cost: number;
    server_id: number;
    tariff_cost: number;
    traffic_cost: number;
    tx_traffic_cost: number;
}

export default class ServerCostForecast {
    readonly _backup_cost: number;

    /**
     * Description: Backup usage cost
     * Example: 60
     */
    get backupCost(): number {
        return this._backup_cost;
    }

    static backupCostValidate(backupCost: number): boolean {
        return typeof backupCost === 'number';
    }

    readonly _cpu_cost_current: number;

    /**
     * Description: Forecast for cpu usage cost at current load
     * Example: 32.21
     */
    get cpuCostCurrent(): number {
        return this._cpu_cost_current;
    }

    static cpuCostCurrentValidate(cpuCostCurrent: number): boolean {
        return typeof cpuCostCurrent === 'number';
    }

    readonly _cpu_cost_max: number;

    /**
     * Description: Forecast for cpu usage cost at 100% load
     * Example: 7200
     */
    get cpuCostMax(): number {
        return this._cpu_cost_max;
    }

    static cpuCostMaxValidate(cpuCostMax: number): boolean {
        return typeof cpuCostMax === 'number';
    }

    readonly _daily_backup_cost: number;

    /**
     * Description: Daily backup usage cost
     * Example: 2
     */
    get dailyBackupCost(): number {
        return this._daily_backup_cost;
    }

    static dailyBackupCostValidate(dailyBackupCost: number): boolean {
        return typeof dailyBackupCost === 'number';
    }

    readonly _daily_cpu_cost_current: number;

    /**
     * Description: Forecast for daily cpu usage cost at current load
     * Example: 1.07
     */
    get dailyCpuCostCurrent(): number {
        return this._daily_cpu_cost_current;
    }

    static dailyCpuCostCurrentValidate(dailyCpuCostCurrent: number): boolean {
        return typeof dailyCpuCostCurrent === 'number';
    }

    readonly _daily_disk_cost: number;

    /**
     * Description: Daily disk usage cost
     * Example: 0.8
     */
    get dailyDiskCost(): number {
        return this._daily_disk_cost;
    }

    static dailyDiskCostValidate(dailyDiskCost: number): boolean {
        return typeof dailyDiskCost === 'number';
    }

    readonly _daily_fixed_cost: number;

    /**
     * Description: Daily usage on fixed tariff
     * Example: 16.66
     */
    get dailyFixedCost(): number {
        return this._daily_fixed_cost;
    }

    static dailyFixedCostValidate(dailyFixedCost: number): boolean {
        return typeof dailyFixedCost === 'number';
    }

    readonly _daily_forecast_cost: number;

    /**
     * Description: Forecast for daily overall server usage cost, including resource (CPU, traffic) consumption
     * Example: 34.54
     */
    get dailyForecastCost(): number {
        return this._daily_forecast_cost;
    }

    static dailyForecastCostValidate(dailyForecastCost: number): boolean {
        return typeof dailyForecastCost === 'number';
    }

    readonly _daily_ip_cost: number;

    /**
     * Description: Daily IP usage cost
     * Example: 2.67
     */
    get dailyIpCost(): number {
        return this._daily_ip_cost;
    }

    static dailyIpCostValidate(dailyIpCost: number): boolean {
        return typeof dailyIpCost === 'number';
    }

    readonly _daily_memory_cost: number;

    /**
     * Description: Daily memory usage cost
     * Example: 28
     */
    get dailyMemoryCost(): number {
        return this._daily_memory_cost;
    }

    static dailyMemoryCostValidate(dailyMemoryCost: number): boolean {
        return typeof dailyMemoryCost === 'number';
    }

    readonly _daily_overall_cost: number;

    /**
     * Description: Daily overall server usage cost
     * Example: 33.47
     */
    get dailyOverallCost(): number {
        return this._daily_overall_cost;
    }

    static dailyOverallCostValidate(dailyOverallCost: number): boolean {
        return typeof dailyOverallCost === 'number';
    }

    readonly _daily_rx_traffic_cost: number;

    /** */
    get dailyRxTrafficCost(): number {
        return this._daily_rx_traffic_cost;
    }

    static dailyRxTrafficCostValidate(dailyRxTrafficCost: number): boolean {
        return typeof dailyRxTrafficCost === 'number';
    }

    readonly _daily_tariff_cost: number;

    /**
     * Description: Daily overall server usage cost without ip and backup usage
     * Example: 28.8
     */
    get dailyTariffCost(): number {
        return this._daily_tariff_cost;
    }

    static dailyTariffCostValidate(dailyTariffCost: number): boolean {
        return typeof dailyTariffCost === 'number';
    }

    readonly _daily_traffic_cost: number;

    /** */
    get dailyTrafficCost(): number {
        return this._daily_traffic_cost;
    }

    static dailyTrafficCostValidate(dailyTrafficCost: number): boolean {
        return typeof dailyTrafficCost === 'number';
    }

    readonly _daily_tx_traffic_cost: number;

    /** */
    get dailyTxTrafficCost(): number {
        return this._daily_tx_traffic_cost;
    }

    static dailyTxTrafficCostValidate(dailyTxTrafficCost: number): boolean {
        return typeof dailyTxTrafficCost === 'number';
    }

    readonly _disk_cost: number;

    /**
     * Description: Disk usage cost
     * Example: 24
     */
    get diskCost(): number {
        return this._disk_cost;
    }

    static diskCostValidate(diskCost: number): boolean {
        return typeof diskCost === 'number';
    }

    readonly _fixed_cost: number;

    /**
     * Description: Monthly usage on fixed tariff
     * Example: 500
     */
    get fixedCost(): number {
        return this._fixed_cost;
    }

    static fixedCostValidate(fixedCost: number): boolean {
        return typeof fixedCost === 'number';
    }

    readonly _forecast_cost: number;

    /**
     * Description: Forecast for overall server usage cost, including resource (CPU, traffic) consumption
     * Example: 1056.28
     */
    get forecastCost(): number {
        return this._forecast_cost;
    }

    static forecastCostValidate(forecastCost: number): boolean {
        return typeof forecastCost === 'number';
    }

    readonly _ip_cost: number;

    /**
     * Description: IP usage cost
     * Example: 80
     */
    get ipCost(): number {
        return this._ip_cost;
    }

    static ipCostValidate(ipCost: number): boolean {
        return typeof ipCost === 'number';
    }

    readonly _memory_cost: number;

    /**
     * Description: Memory usage cost
     * Example: 840
     */
    get memoryCost(): number {
        return this._memory_cost;
    }

    static memoryCostValidate(memoryCost: number): boolean {
        return typeof memoryCost === 'number';
    }

    readonly _overall_cost: number;

    /**
     * Description: Overall server usage cost
     * Example: 1004
     */
    get overallCost(): number {
        return this._overall_cost;
    }

    static overallCostValidate(overallCost: number): boolean {
        return typeof overallCost === 'number';
    }

    readonly _rx_traffic_cost: number;

    /**
     * Description: Forecast for rx traffic usage cost
     * Example: 0.02
     */
    get rxTrafficCost(): number {
        return this._rx_traffic_cost;
    }

    static rxTrafficCostValidate(rxTrafficCost: number): boolean {
        return typeof rxTrafficCost === 'number';
    }

    readonly _server_id: number;

    /**
     * Description: Server identifier or 0 for draft
     * Example: 51671
     */
    get serverId(): number {
        return this._server_id;
    }

    static serverIdValidate(serverId: number): boolean {
        return typeof serverId === 'number';
    }

    readonly _tariff_cost: number;

    /**
     * Description: Overall server usage cost without backup and ip usage 
     * Example: 864
     */
    get tariffCost(): number {
        return this._tariff_cost;
    }

    static tariffCostValidate(tariffCost: number): boolean {
        return typeof tariffCost === 'number';
    }

    readonly _traffic_cost: number;

    /**
     * Description: Forecast for traffic usage cost
     * Example: 0.07
     */
    get trafficCost(): number {
        return this._traffic_cost;
    }

    static trafficCostValidate(trafficCost: number): boolean {
        return typeof trafficCost === 'number';
    }

    readonly _tx_traffic_cost: number;

    /**
     * Description: Forecast for tx traffic usage cost
     * Example: 0.05
     */
    get txTrafficCost(): number {
        return this._tx_traffic_cost;
    }

    static txTrafficCostValidate(txTrafficCost: number): boolean {
        return typeof txTrafficCost === 'number';
    }

    constructor(props: IServerCostForecast) {
        this._backup_cost = props.backup_cost;
        this._cpu_cost_current = props.cpu_cost_current;
        this._cpu_cost_max = props.cpu_cost_max;
        this._daily_backup_cost = props.daily_backup_cost;
        this._daily_cpu_cost_current = props.daily_cpu_cost_current;
        this._daily_disk_cost = props.daily_disk_cost;
        this._daily_fixed_cost = props.daily_fixed_cost;
        this._daily_forecast_cost = props.daily_forecast_cost;
        this._daily_ip_cost = props.daily_ip_cost;
        this._daily_memory_cost = props.daily_memory_cost;
        this._daily_overall_cost = props.daily_overall_cost;
        this._daily_rx_traffic_cost = props.daily_rx_traffic_cost;
        this._daily_tariff_cost = props.daily_tariff_cost;
        this._daily_traffic_cost = props.daily_traffic_cost;
        this._daily_tx_traffic_cost = props.daily_tx_traffic_cost;
        this._disk_cost = props.disk_cost;
        this._fixed_cost = props.fixed_cost;
        this._forecast_cost = props.forecast_cost;
        this._ip_cost = props.ip_cost;
        this._memory_cost = props.memory_cost;
        this._overall_cost = props.overall_cost;
        this._rx_traffic_cost = props.rx_traffic_cost;
        this._server_id = props.server_id;
        this._tariff_cost = props.tariff_cost;
        this._traffic_cost = props.traffic_cost;
        this._tx_traffic_cost = props.tx_traffic_cost;
    }

    serialize(): IServerCostForecast {
        const data: IServerCostForecast = {
            backup_cost: this._backup_cost,
            cpu_cost_current: this._cpu_cost_current,
            cpu_cost_max: this._cpu_cost_max,
            daily_backup_cost: this._daily_backup_cost,
            daily_cpu_cost_current: this._daily_cpu_cost_current,
            daily_disk_cost: this._daily_disk_cost,
            daily_fixed_cost: this._daily_fixed_cost,
            daily_forecast_cost: this._daily_forecast_cost,
            daily_ip_cost: this._daily_ip_cost,
            daily_memory_cost: this._daily_memory_cost,
            daily_overall_cost: this._daily_overall_cost,
            daily_rx_traffic_cost: this._daily_rx_traffic_cost,
            daily_tariff_cost: this._daily_tariff_cost,
            daily_traffic_cost: this._daily_traffic_cost,
            daily_tx_traffic_cost: this._daily_tx_traffic_cost,
            disk_cost: this._disk_cost,
            fixed_cost: this._fixed_cost,
            forecast_cost: this._forecast_cost,
            ip_cost: this._ip_cost,
            memory_cost: this._memory_cost,
            overall_cost: this._overall_cost,
            rx_traffic_cost: this._rx_traffic_cost,
            server_id: this._server_id,
            tariff_cost: this._tariff_cost,
            traffic_cost: this._traffic_cost,
            tx_traffic_cost: this._tx_traffic_cost,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            server_id: typeof this._server_id === 'number',
            fixed_cost: typeof this._fixed_cost === 'number',
            memory_cost: typeof this._memory_cost === 'number',
            disk_cost: typeof this._disk_cost === 'number',
            cpu_cost_max: typeof this._cpu_cost_max === 'number',
            cpu_cost_current: typeof this._cpu_cost_current === 'number',
            ip_cost: typeof this._ip_cost === 'number',
            traffic_cost: typeof this._traffic_cost === 'number',
            rx_traffic_cost: typeof this._rx_traffic_cost === 'number',
            tx_traffic_cost: typeof this._tx_traffic_cost === 'number',
            backup_cost: typeof this._backup_cost === 'number',
            forecast_cost: typeof this._forecast_cost === 'number',
            overall_cost: typeof this._overall_cost === 'number',
            tariff_cost: typeof this._tariff_cost === 'number',
            daily_fixed_cost: typeof this._daily_fixed_cost === 'number',
            daily_memory_cost: typeof this._daily_memory_cost === 'number',
            daily_disk_cost: typeof this._daily_disk_cost === 'number',
            daily_cpu_cost_current: typeof this._daily_cpu_cost_current === 'number',
            daily_backup_cost: typeof this._daily_backup_cost === 'number',
            daily_traffic_cost: typeof this._daily_traffic_cost === 'number',
            daily_tx_traffic_cost: typeof this._daily_tx_traffic_cost === 'number',
            daily_rx_traffic_cost: typeof this._daily_rx_traffic_cost === 'number',
            daily_ip_cost: typeof this._daily_ip_cost === 'number',
            daily_forecast_cost: typeof this._daily_forecast_cost === 'number',
            daily_overall_cost: typeof this._daily_overall_cost === 'number',
            daily_tariff_cost: typeof this._daily_tariff_cost === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerCostForecast): ServerCostForecast {
        return new ServerCostForecast(props);
    }

    readonly keys: { [key: string]: string } = {
        backupCost: 'backup_cost',
        cpuCostCurrent: 'cpu_cost_current',
        cpuCostMax: 'cpu_cost_max',
        dailyBackupCost: 'daily_backup_cost',
        dailyCpuCostCurrent: 'daily_cpu_cost_current',
        dailyDiskCost: 'daily_disk_cost',
        dailyFixedCost: 'daily_fixed_cost',
        dailyForecastCost: 'daily_forecast_cost',
        dailyIpCost: 'daily_ip_cost',
        dailyMemoryCost: 'daily_memory_cost',
        dailyOverallCost: 'daily_overall_cost',
        dailyRxTrafficCost: 'daily_rx_traffic_cost',
        dailyTariffCost: 'daily_tariff_cost',
        dailyTrafficCost: 'daily_traffic_cost',
        dailyTxTrafficCost: 'daily_tx_traffic_cost',
        diskCost: 'disk_cost',
        fixedCost: 'fixed_cost',
        forecastCost: 'forecast_cost',
        ipCost: 'ip_cost',
        memoryCost: 'memory_cost',
        overallCost: 'overall_cost',
        rxTrafficCost: 'rx_traffic_cost',
        serverId: 'server_id',
        tariffCost: 'tariff_cost',
        trafficCost: 'traffic_cost',
        txTrafficCost: 'tx_traffic_cost',
        }
;

    mergeDeepWith(props: Partial<ServerCostForecast>): ServerCostForecast {
        const updateData: Partial<IServerCostForecast> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerCostForecast) => {
            const updateKey = this.keys[key] as keyof IServerCostForecast;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerCostForecast, keyof IServerCostForecast>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerCostForecast({ ...this.serialize(), ...updateData });
    }
}
