import { CreditCardType } from './CreditCardType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountCreditCard {
    credit_card_id: number;
    credit_card_type: CreditCardType;
    last_four_digits: string;
}

export default class AccountCreditCard {
    readonly _credit_card_id: number;

    /**
     * Description: Our credit card identifier
     * Example: 114
     */
    get creditCardId(): number {
        return this._credit_card_id;
    }

    static creditCardIdValidate(creditCardId: number): boolean {
        return typeof creditCardId === 'number';
    }

    readonly _credit_card_type: CreditCardType;

    get creditCardType(): CreditCardType {
        return this._credit_card_type;
    }

    static creditCardTypeValidate(creditCardType: CreditCardType): boolean {
        return Object.keys(CreditCardType).includes(creditCardType);
    }

    readonly _last_four_digits: string;

    /**
     * Description: Last four digits of credit card number
     * Example: 7867
     */
    get lastFourDigits(): string {
        return this._last_four_digits;
    }

    static lastFourDigitsValidate(lastFourDigits: string): boolean {
        return typeof lastFourDigits === 'string' && !!lastFourDigits.trim();
    }

    constructor(props: IAccountCreditCard) {
        this._credit_card_id = props.credit_card_id;
        this._credit_card_type = props.credit_card_type;
        this._last_four_digits = props.last_four_digits.trim();
    }

    serialize(): IAccountCreditCard {
        const data: IAccountCreditCard = {
            credit_card_id: this._credit_card_id,
            credit_card_type: this._credit_card_type,
            last_four_digits: this._last_four_digits,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            credit_card_id: typeof this._credit_card_id === 'number',
            last_four_digits: typeof this._last_four_digits === 'string' && !!this._last_four_digits.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountCreditCard): AccountCreditCard {
        return new AccountCreditCard(props);
    }

    readonly keys: { [key: string]: string } = {
        creditCardId: 'credit_card_id',
        creditCardType: 'credit_card_type',
        lastFourDigits: 'last_four_digits',
        }
;

    mergeDeepWith(props: Partial<AccountCreditCard>): AccountCreditCard {
        const updateData: Partial<IAccountCreditCard> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountCreditCard) => {
            const updateKey = this.keys[key] as keyof IAccountCreditCard;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountCreditCard, keyof IAccountCreditCard>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountCreditCard({ ...this.serialize(), ...updateData });
    }
}
