import React, { FC, useState } from 'react';
import { Radio } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { CommonModalLayout, useIntl, Input } from 'Common';
import { currencySymbol } from 'Lib/helpers/consts';
import {
    onChangeCheckNumberOrFloat,
    onSubmitCheckNumberOrFloat,
    parseFloatWithComma,
} from 'Lib/helpers/helpers';
import { transferFromResellerToClient } from 'Actions/reseller';
import { SourceBalance } from 'Entities/SourceBalance';
import { Store } from 'Store';

import theme from 'Lib/theme';
import s from './Client.module.pcss';

interface AddFundsModalProps {
    visible: boolean;
    title: string;
    handleClose: () => void;
    resellerAccountBalance: number;
    accountBalance: number;
    clientId: number;
    clientChargeOfferAmounts: number[];
}

const hideLabelPart = (text: string) => (
    <span className={s.radioLabel}>
        {text}
    </span>
);

const selectAccountDetails = (store: Store) => store.account.details;
const selector = createSelector([selectAccountDetails], (details) => ({ details }));

const AddFundsModal: FC<AddFundsModalProps> = ({
    visible,
    title,
    handleClose,
    resellerAccountBalance,
    accountBalance,
    clientId,
    clientChargeOfferAmounts,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [fundsAmount, setFundsAmount] = useState<string>('');
    const [balanceType, setBalanceType] = useState(SourceBalance.RESELLER_BALANCE);
    const [inputError, setInputError] = useState(false);

    const account = useSelector(selector);

    const { details } = account;

    if (!details) {
        return null;
    }

    const {
        accountLimits: { minCashPaymentAmount, maxCashPaymentAmount },
    } = details;

    const handleSubmit = () => {
        const amountParsed = parseFloatWithComma(fundsAmount);

        if (!amountParsed || !onSubmitCheckNumberOrFloat(fundsAmount)) {
            setInputError(true);
            return;
        }

        if (balanceType === SourceBalance.PERSONAL_BALANCE && accountBalance < amountParsed) {
            setInputError(true);
        } else if (balanceType === SourceBalance.RESELLER_BALANCE
            && resellerAccountBalance < amountParsed) {
            setInputError(true);
        } else if (amountParsed < minCashPaymentAmount
            || amountParsed > maxCashPaymentAmount) {
            setInputError(true);
        } else {
            dispatch(transferFromResellerToClient([clientId, {
                source_balance: balanceType,
                amount: parseFloat(String(amountParsed)),
            }], {
                result: () => {
                    handleClose();
                },
            }));
        }
    };

    const resellerBtnLabel = (
        <>
            <span>
                {intl.getMessage('clients_reseller_account_modal_radio', { hide: hideLabelPart })}
            </span>
            <span className={cn(
                theme.text.medium,
                { [s.radioValue]: balanceType === SourceBalance.PERSONAL_BALANCE },
            )}
            >
                {intl.getMessage('money', { value: Math.round(resellerAccountBalance) })}
            </span>
        </>
    );

    const balanceBtnLabel = (
        <>
            <span>
                {intl.getMessage('client_menu_billing_amount', { hide: hideLabelPart })}
            </span>
            <span className={cn(
                theme.text.medium,
                { [s.radioValue]: balanceType === SourceBalance.RESELLER_BALANCE },
            )}
            >
                {intl.getMessage('money', { value: Math.round(accountBalance) })}
            </span>
        </>
    );

    return (
        <CommonModalLayout
            visible={visible}
            title={title}
            buttonText={intl.getMessage('add_funds')}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            className="modal_clients"
            disabled={!fundsAmount}
            width={480}
        >
            <div className="clientsRadio">
                <Radio.Group
                    value={balanceType}
                    onChange={(e) => {
                        setBalanceType(e.target.value);
                        setInputError(false);
                    }}
                    buttonStyle="solid"
                >
                    <Radio.Button value={SourceBalance.RESELLER_BALANCE}>
                        <div className={cn(s.radioInner, theme.text.medium)}>
                            {resellerBtnLabel}
                        </div>
                    </Radio.Button>
                    <Radio.Button value={SourceBalance.PERSONAL_BALANCE}>
                        <div className={cn(s.radioInner, theme.text.medium)}>
                            {balanceBtnLabel}
                        </div>
                    </Radio.Button>
                </Radio.Group>
            </div>
            <div className={cn(s.inputLabel, theme.text.medium)}>
                {intl.getMessage('billing_add_funds_amount_title')}
            </div>
            <Input
                name="addFundsAmount"
                type="text"
                size="large"
                placeholder={
                    intl.getMessage('billing_add_funds_amount_placeholder',
                        { min: minCashPaymentAmount, max: maxCashPaymentAmount })
                }
                suffix={currencySymbol.RUB}
                value={fundsAmount}
                error={inputError}
                validate={(e) => !!e}
                onChange={(e) => {
                    if (onChangeCheckNumberOrFloat(e) || e === '') {
                        setInputError(false);
                        setFundsAmount(e);
                    }
                }}
                autoFocus
            />
            <div className={s.addFundsButtons}>
                {clientChargeOfferAmounts.map((amount) => (
                    <button
                        type="button"
                        key={amount}
                        className={cn(
                            theme.Badge.badge,
                            theme.Badge.subject,
                            s.modalButton,
                        )}
                        onClick={() => setFundsAmount(String(amount))}
                    >
                        {intl.getMessage('price_simple', { value: amount })}
                    </button>
                ))}
            </div>
        </CommonModalLayout>
    );
};

export default AddFundsModal;
