// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum SecurityActionType {
    SERVER_REINSTALL = 'SERVER_REINSTALL',
    SERVER_DELETE = 'SERVER_DELETE',
    SERVER_PUBLIC_KEY = 'SERVER_PUBLIC_KEY',
    SERVER_PASSWORD = 'SERVER_PASSWORD',
    SERVER_CONSOLE_ACCESS = 'SERVER_CONSOLE_ACCESS',
    REVERT_TO_SNAPSHOT = 'REVERT_TO_SNAPSHOT',
    REVERT_TO_BACKUP = 'REVERT_TO_BACKUP',
    DOWNLOAD_SNAPSHOT = 'DOWNLOAD_SNAPSHOT',
    DOWNLOAD_BACKUP = 'DOWNLOAD_BACKUP',
    TENANT_SHARE_ACCESS = 'TENANT_SHARE_ACCESS'
}
