import { takeEvery, put, call } from 'redux-saga/effects';

import TenantApi from 'Apis/tenants';
import {
    TenantUsersActions,
    getTenantsUsersList as getTenantsUsersListAct,
    saveTenantsUsersList,
} from 'Actions/tenantUsers';
import TenantUser, { ITenantUser } from 'Entities/TenantUser';

import { errorChecker, addError } from './utils';

function* getTenantUserList(action: ReturnType<typeof getTenantsUsersListAct>) {
    const response: ITenantUser[] = yield call(TenantApi.listTenantsUsers);
    const { error, result } = errorChecker<ITenantUser[]>(response);
    if (result) {
        const tenantEntities = result.map((t) => new TenantUser(t));
        yield put(saveTenantsUsersList(tenantEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* tenantUserSaga(): Generator {
    yield takeEvery<ReturnType<typeof getTenantsUsersListAct>>(
        TenantUsersActions.GetTenantsUsers, getTenantUserList,
    );
}

export default tenantUserSaga;
