import React, { FC, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Button, useIntl } from 'Common';
import { Store } from 'Store';
import { NEW_USER_NOTICES_LIST, REFERRAL_DISCOUNT } from 'Lib/helpers/consts';

import serversImage from 'Lib/assets/images/notices/servers.png';
import servers2xImage from 'Lib/assets/images/notices/servers@2x.png';
import projectsImage from 'Lib/assets/images/notices/projects.png';
import projects2xImage from 'Lib/assets/images/notices/projects@2x.png';
import partnerImage from 'Lib/assets/images/notices/partner.png';
import partner2xImage from 'Lib/assets/images/notices/partner@2x.png';
import noticesImage from 'Lib/assets/images/notices/notices.png';
import notices2xImage from 'Lib/assets/images/notices/notices@2x.png';
import accountImage from 'Lib/assets/images/notices/account.png';
import account2xImage from 'Lib/assets/images/notices/account@2x.png';

import Notice from '../Notice';
import s from './NewUserNotices.module.pcss';

const selectUserLoggedIn = (st: Store) => (st.user.loggedIn);
const selector = createSelector(
    [selectUserLoggedIn],
    (loggedIn) => ({ loggedIn }),
);

interface NewUserNoticesProps {
    isAdmin: boolean | undefined;
}

const NewUserNotices: FC<NewUserNoticesProps> = ({ isAdmin }) => {
    const intl = useIntl();
    const { loggedIn } = useSelector(selector);

    const { SERVERS, PROJECTS, PARTNER, NOTICES, ACCOUNT } = NEW_USER_NOTICES_LIST;

    const [step, setStep] = useState(0);

    const [currentNotice, setCurrentNotice] = useState(SERVERS);

    const noticesList = {
        [SERVERS]: {
            img: serversImage,
            img2x: servers2xImage,
            title: intl.getMessage('new_user_sidebar_notice_servers'),
        },
        [PROJECTS]: {
            img: projectsImage,
            img2x: projects2xImage,
            title: intl.getMessage('new_user_sidebar_notice_projects'),
        },
        [NOTICES]: {
            img: noticesImage,
            img2x: notices2xImage,
            title: intl.getMessage('new_user_sidebar_notice_notices'),
        },
        [ACCOUNT]: {
            img: accountImage,
            img2x: account2xImage,
            title: intl.getMessage('new_user_sidebar_notice_account'),
        },
        [PARTNER]: {
            img: partnerImage,
            img2x: partner2xImage,
            title: intl.getMessage('new_user_sidebar_notice_partner', { discount: `${REFERRAL_DISCOUNT}%` }),
        },
    };

    const setLocalStorage = () => {
        UserStorage.setNewUserNotice(true);
    };

    const handleClose = () => {
        setLocalStorage();
        setCurrentNotice('');
    };

    useEffect(() => {
        const notices = Object.keys(noticesList);
        setCurrentNotice(notices[step]);
    }, [step]);

    const handleNext = () => {
        setStep(step + 1);
    };

    const show = currentNotice
        && loggedIn
        && !UserStorage.getNewUserNotice()
        && !!AppConfigStorage.isEmailConfirmed();

    if (show) {
        return (
            <Notice
                visible={show}
                handleClose={handleClose}
                modalClassName={
                    cn(
                        'modal',
                        'modal_notice',
                        'modal_notice-white',
                        `modal_notice-${currentNotice}`,
                        { admin: isAdmin },
                    )
                }
                name={currentNotice}
            >
                <img
                    src={noticesList[currentNotice].img}
                    srcSet={`${noticesList[currentNotice].img2x} 2x`}
                    className={s.image}
                    alt={currentNotice}
                />
                <div className={s.title}>
                    {noticesList[currentNotice].title}
                </div>
                <Button
                    type="link"
                    onClick={
                        currentNotice !== PARTNER ? handleNext : handleClose
                    }
                    className={s.link}
                >
                    {currentNotice !== PARTNER ? intl.getMessage('next') : intl.getMessage('done')}
                </Button>
            </Notice>
        );
    }

    return null;
};

export default NewUserNotices;
