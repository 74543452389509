import React, { FC } from 'react';

import { useIntl, Input } from 'Common';
import DnsRecordCreate from 'Entities/DnsRecordCreate';
import { ipV4Validate } from 'Lib/helpers/utils';

import TtlSelect from '../TtlSelect';

import { RecordFormProps, Buttons, onBlur } from '.';

const ARecordForm: FC<RecordFormProps> = (props) => {
    const { values, errors, setFieldValue, onClose, zone } = props;
    const intl = useIntl();
    const placeholder = intl.getMessage('dns_host_placeholder', { name: zone.host });

    return (
        <>
            <Input
                error={!!errors.host}
                errorMessage={errors.host}
                label={intl.getMessage('dns_name')}
                name="host"
                onChange={(e) => setFieldValue('host', e)}
                onBlur={onBlur(zone.host, setFieldValue)}
                placeholder={placeholder}
                size="middle"
                type="text"
                validate={DnsRecordCreate.hostValidate}
                value={values.host}
            />
            <Input
                error={!!errors.data}
                errorMessage={errors.data}
                label={intl.getMessage('dns_ipv4')}
                name="data"
                onChange={(e) => setFieldValue('data', e)}
                placeholder="93.184.216.34"
                size="middle"
                type="text"
                validate={(e) => DnsRecordCreate.dataValidate(e) && ipV4Validate(e)}
                value={values.data}
            />
            <TtlSelect
                error={!!errors.ttl_seconds}
                value={values.ttl_seconds}
                onChange={(ttlSeconds) => setFieldValue('ttl_seconds', ttlSeconds)}
            />
            <Buttons onClose={onClose} isEdited={values.isEdited} />
        </>
    );
};

export default ARecordForm;
