import React, { FC } from 'react';
import cn from 'classnames';
import { IconType } from 'Lib/theme/Icons';

import s from './Icon.module.pcss';

interface IconProps {
    icon: IconType;
    color?: string;
    className?: string;
    title?: string;
    dataAttrs?: Record<string, string>;
    onClick?: React.MouseEventHandler<SVGSVGElement>;
}

const Icon: FC<IconProps> = ({
    icon, color, className, title, dataAttrs, onClick,
}) => {
    const iconClass = cn(s.icon, color, className);

    return (
        <svg className={iconClass} onClick={onClick} {...dataAttrs}>
            {title && <title>{title}</title>}
            <use xlinkHref={`#${icon}`} />
        </svg>
    );
};

export default Icon;
export { IconType } from 'Lib/theme/Icons';
