// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICreateChargeFromCardInvoiceRequest {
    amount: number;
    card_id: number;
    description?: string;
}

export default class CreateChargeFromCardInvoiceRequest {
    readonly _amount: number;

    /**
     * Description: Charge amount
     * Example: 250
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    readonly _card_id: number;

    /**
     * Description: Credit card ID
     * Example: 324
     */
    get cardId(): number {
        return this._card_id;
    }

    static cardIdValidate(cardId: number): boolean {
        return typeof cardId === 'number';
    }

    readonly _description: string | undefined;

    /** */
    get description(): string | undefined {
        return this._description;
    }

    constructor(props: ICreateChargeFromCardInvoiceRequest) {
        this._amount = props.amount;
        this._card_id = props.card_id;
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
    }

    serialize(): ICreateChargeFromCardInvoiceRequest {
        const data: ICreateChargeFromCardInvoiceRequest = {
            amount: this._amount,
            card_id: this._card_id,
        };
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            amount: typeof this._amount === 'number',
            card_id: typeof this._card_id === 'number',
            description: !this._description ? true : typeof this._description === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICreateChargeFromCardInvoiceRequest): CreateChargeFromCardInvoiceRequest {
        return new CreateChargeFromCardInvoiceRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        amount: 'amount',
        cardId: 'card_id',
        description: 'description',
        }
;

    mergeDeepWith(props: Partial<CreateChargeFromCardInvoiceRequest>): CreateChargeFromCardInvoiceRequest {
        const updateData: Partial<ICreateChargeFromCardInvoiceRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CreateChargeFromCardInvoiceRequest) => {
            const updateKey = this.keys[key] as keyof ICreateChargeFromCardInvoiceRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICreateChargeFromCardInvoiceRequest, keyof ICreateChargeFromCardInvoiceRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CreateChargeFromCardInvoiceRequest({ ...this.serialize(), ...updateData });
    }
}
