import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useIntl, DeleteModalLayout } from 'Common';
import { removeUserFromTenant } from 'Actions/tenant';
import { useEnter } from 'Hooks';
import TenantUser from 'Entities/TenantUser';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';

interface CloseAccessModalProps {
    leave?: boolean;
    visible: boolean;
    onClose: () => void;
    user: TenantUser | null;
}

const ModalCloseAccess: FC<CloseAccessModalProps> = ({ visible, onClose, user, leave }) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const { tenantId, userId, email } = user!;

    const onDelete = () => {
        dispatch(removeUserFromTenant({ parameters: [tenantId, userId], leave }));
        onClose();

        if (leave) {
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.ProjectsList));
        }
    };

    useEnter(onDelete, [onDelete]);

    const message = leave ? intl.getMessage('modal_project_access_leave', { email }) : intl.getMessage('modal_project_access_close', { email });
    const messageTitle = leave ? intl.getMessage('modal_project_access_leave_title') : intl.getMessage('modal_project_access_close_title');
    const buttonText = leave ? intl.getMessage('leave') : intl.getMessage('close_access');
    return (
        <DeleteModalLayout
            visible={visible}
            title={messageTitle}
            handleDelete={onDelete}
            handleClose={onClose}
            buttonText={buttonText}
        >
            {message}
        </DeleteModalLayout>
    );
};

export default ModalCloseAccess;
