// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum NotificationType {
    SERVER_CREATE = 'SERVER_CREATE',
    SERVER_DELETE = 'SERVER_DELETE',
    SERVER_START = 'SERVER_START',
    SERVER_SHUTOFF = 'SERVER_SHUTOFF',
    SERVER_REBOOT = 'SERVER_REBOOT',
    SERVER_MEMORY_CHANGE = 'SERVER_MEMORY_CHANGE',
    SERVER_DISK_INCREASE = 'SERVER_DISK_INCREASE',
    SERVER_PASSWORD_CHANGE = 'SERVER_PASSWORD_CHANGE',
    SERVER_MEMORY_DIFFERENT = 'SERVER_MEMORY_DIFFERENT',
    SERVER_OUT_OF_MEMORY = 'SERVER_OUT_OF_MEMORY',
    SERVER_LINUX_KERNEL_EVENT = 'SERVER_LINUX_KERNEL_EVENT',
    SERVER_ACPI_MEMORY_HP_ERROR = 'SERVER_ACPI_MEMORY_HP_ERROR',
    SERVER_REVERT_TO_SNAPSHOT = 'SERVER_REVERT_TO_SNAPSHOT',
    SERVER_REVERT_TO_BACKUP = 'SERVER_REVERT_TO_BACKUP',
    BALANCE_REPLENISHED = 'BALANCE_REPLENISHED',
    BALANCE_GOT_NEGATIVE = 'BALANCE_GOT_NEGATIVE',
    BALANCE_GOT_NEGATIVE_24H = 'BALANCE_GOT_NEGATIVE_24H',
    BALANCE_GOT_NEGATIVE_72H = 'BALANCE_GOT_NEGATIVE_72H',
    DAILY_EXPENSE = 'DAILY_EXPENSE',
    LOAD_AVERAGE = 'LOAD_AVERAGE',
    DISK_FREE_SPACE = 'DISK_FREE_SPACE',
    CPU_USAGE = 'CPU_USAGE',
    PING = 'PING',
    INODES_USE = 'INODES_USE',
    HTTP = 'HTTP',
    SSL_CERT = 'SSL_CERT',
    DOMAIN_EXPIRATION = 'DOMAIN_EXPIRATION',
    RECEIVED_TRAFFIC_USAGE = 'RECEIVED_TRAFFIC_USAGE',
    TRANSFERRED_TRAFFIC_USAGE = 'TRANSFERRED_TRAFFIC_USAGE',
    MIN_BALANCE = 'MIN_BALANCE'
}
