import React, { FC, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';

import { useTitle } from 'Hooks';
import { getAccountDetails, getCreditCards, getDocuments } from 'Actions/account';
import { useIntl, FormPageLayout, Select, Option } from 'Common';
import AccountDetails from 'Entities/AccountDetails';
import { PaymentSystemType } from 'Entities/PaymentSystemType';
import { ClientType } from 'Entities/ClientType';
import { Store } from 'Store';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import { CardForm, CashlessForm, CouponForm } from './components';

import s from './AddFunds.module.pcss';

interface AddFundsStoreProps {
    details: AccountDetails | null;
}
interface AddFundsOwnProps {
    noLayout?: boolean;
}
type AddFundsProps = AddFundsStoreProps & AddFundsOwnProps;
enum AdditionalPaymentType {
    COUPON = 'COUPON',
}
const PaymentType = {
    ...PaymentSystemType,
    ...AdditionalPaymentType,
};
type FormType = keyof typeof PaymentType;
interface FormProps {
    details: AccountDetails | null;
    isFirst: boolean;
    noLayout?: boolean;
}

const AddFunds: FC<AddFundsProps> = ({ details, noLayout }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('add_funds_page_title'));
    const dispatch = useDispatch();

    const payByCardAvailable = details?.clientType !== ClientType.CORPORATION
    && details?.clientType !== ClientType.SOLE_TRADER;
    const [formType, setFormType] = useState<FormType>(
        payByCardAvailable ? PaymentSystemType.CLOUD_PAYMENTS : PaymentSystemType.NON_CASH_PAYMENT,
    );
    useEffect(() => {
        dispatch(getAccountDetails());
        dispatch(getDocuments());
        dispatch(getCreditCards());
    }, []);

    useEffect(() => {
        const canCard = details?.clientType !== ClientType.CORPORATION
        && details?.clientType !== ClientType.SOLE_TRADER;
        setFormType(
            canCard ? PaymentSystemType.CLOUD_PAYMENTS : PaymentSystemType.NON_CASH_PAYMENT,
        );
    }, [details]);

    if (!details) {
        return null;
    }

    const getForm = (type: FormType) => {
        const components = {
            [PaymentType.CLOUD_PAYMENTS]: CardForm,
            [PaymentType.NON_CASH_PAYMENT]: CashlessForm,
            [PaymentType.COUPON]: CouponForm,
            [PaymentType.PLATRON]: null,
            [PaymentType.DENGI_ONLINE]: null,
            [PaymentType.PLIMUS]: null,
            [PaymentType.PAYPAL]: null,
            [PaymentType.PAYONLINE]: null,
        };
        const Elem = components[type];
        return Elem ? (
            <Elem
                details={details}
                isFirst={details.paid}
                noLayout={noLayout}
            />
        ) : null;
    };
    const content = (
        <>
            <div className={s.controls}>
                <div className={theme.form.label}>
                    {intl.getMessage('add_funds_type')}
                </div>
                <Select
                    block
                    id="payment-type-select"
                    size="big"
                    value={formType}
                    onChange={(value) => setFormType(value)}
                >
                    {payByCardAvailable && (
                        <>
                            <Option value={PaymentType.CLOUD_PAYMENTS} className="option">
                                {intl.getMessage('billing_credit_card')}
                            </Option>
                        </>
                    )}
                    <Option value={PaymentType.NON_CASH_PAYMENT} className="option">
                        {intl.getMessage('billing_cashless')}
                    </Option>
                    <Option value={PaymentType.COUPON} className="option">
                        {intl.getMessage('billing_coupon')}
                    </Option>
                </Select>
            </div>
            {getForm(formType)}
        </>
    );

    return noLayout ? (
        <div className={s.noLayout}>
            {content}
        </div>
    ) : (
        <FormPageLayout
            title={intl.getMessage('billing_add_funds')}
            back={RoutePath.Billing}
        >
            {content}
        </FormPageLayout>
    );
};

const selectAccountDetails = (store: Store) => store.account.details;

const selector = createSelector([
    selectAccountDetails,
], (details) => ({ details }));

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(AddFunds);
