import React, { FC, useEffect, createRef, useState, useRef } from 'react';
import RFB from '@novnc/novnc/core/rfb';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

import { useIntl, Button, notifyError } from 'Common';
import Server from 'Entities/Server';
import { Store } from 'Store';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';

import s from './Vnc.module.pcss';

type VncOwnProps = RouteComponentProps<{ serverId: string }>;
interface VncStoreProps {
    server: Server | undefined;
}
type VncProps = { server: Server } & VncOwnProps;

const Vnc: FC<VncProps> = ({ history, server }) => {
    const intl = useIntl();
    const ref = createRef<HTMLDivElement>();
    const [rfb, setRfb] = useState<typeof RFB>(null);
    const rfbRef = useRef(rfb);
    const { consoleUrl } = server;

    useEffect(() => {
        if (!server.consoleAccess || server.serviceMode) {
            history.push(linkPathBuilder(
                intl.currentLocale,
                RoutePath.Server,
                { serverId: server.id, tenantId: server.tenantId },
            ));
            notifyError(!server.consoleAccess
                ? intl.getMessage('server_console_no_access')
                : intl.getMessage('server_console_service_mode'));
        }
    }, [server]);
    useEffect(() => {
        if (ref.current && !rfb) {
            setRfb(new RFB(ref.current, consoleUrl, { wsProtocols: ['binary'] }));
        }
        return () => {
            rfbRef.current?.disconnect();
        };
    }, [ref, rfb]);
    return (
        <div id="vnc" ref={ref} className={s.vnc}>
            <Button
                size="medium"
                type="primary"
                htmlType="submit"
                className={s.button}
                onClick={() => rfb?.sendCtrlAltDel()}
            >
                {intl.getMessage('send_ctrl_alt_del')}
            </Button>
        </div>
    );
};

const VncContainer: FC<VncStoreProps & VncOwnProps> = (props) => {
    const { server } = props;
    if (!server) {
        return null;
    }
    return <Vnc {...props} server={server} />;
};

const selectServer = (store: Store, ownProps: VncOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return store.server.get(Number(serverId));
};
const selector = createSelector([selectServer], (server) => ({
    server,
}));
const mapStoreToProps = (store: Store, ownProps: VncOwnProps) => ({
    ...selector(store, ownProps),
});

export default withRouter(connect(mapStoreToProps)(VncContainer));
