import authApi from 'Apis/authorization';
import registerApi from 'Apis/registration';
import resellerLoginApi from 'Apis/resellerClientAuthorization';
import { actionCreator } from '.';

export enum UserActions {
    Login = 'Login',
    LoggedIn = 'LoggedIn',
    CheckAuth = 'CheckAuth',
    CheckAuthFailed = 'CheckAuthFailed',
    CheckAuthSuccess = 'CheckAuthSuccess',
    LogOut = 'LogOut',
    Register = 'Register',
    ResetPassword = 'ResetPassword',
    ResellerLogin = 'ResellerLogin',
    ResellerLogout = 'ResellerLogout',
    SocialLogin = 'SocialLogin',
    HaveNewPanelVersion = 'HaveNewPanelVersion',
    AddAccount = 'AddAccount',
    UserAccountLogin = 'UserAccountLogin',
    CheckSavedAccounts = 'CheckSavedAccounts',
    AddSocialAuthAccount = 'AddSocialAuthAccount',
}

export interface ILoggedIn {
    token: string;
    id: number;
    email?: string;
}
export interface ISavedUserAccount extends ILoggedIn {
    authorized: boolean;
}

type Login = Parameters<typeof authApi.login>;
const login = actionCreator<Login>(UserActions.Login);

const loggedIn = actionCreator<ILoggedIn>(UserActions.LoggedIn);
const checkAuth = actionCreator(UserActions.CheckAuth);
const logOut = actionCreator(UserActions.LogOut);

type Register = Parameters<typeof registerApi.register>;
const register = actionCreator<Register>(UserActions.Register);

type ResetPassword = Parameters<typeof registerApi.resetPassword>;
const resetPassword = actionCreator<ResetPassword>(UserActions.ResetPassword);

type ResellerLogin = Parameters<typeof resellerLoginApi.resellerLogin>;
const resellerLogin = actionCreator<ResellerLogin>(UserActions.ResellerLogin);

type ResellerLogout = Parameters<typeof resellerLoginApi.resellerLogout>;
const resellerLogout = actionCreator<ResellerLogout>(UserActions.ResellerLogout);

const checkAuthFailed = actionCreator(UserActions.CheckAuthFailed);
const checkAuthSuccess = actionCreator(UserActions.CheckAuthSuccess);

const haveNewPanelVersion = actionCreator(UserActions.HaveNewPanelVersion);

const addAccount = actionCreator<Login>(UserActions.AddAccount);
const userAccountLogin = actionCreator<ISavedUserAccount>(UserActions.UserAccountLogin);
const checkSavedAccounts = actionCreator(UserActions.CheckSavedAccounts);
const addSocialAuthAccount = actionCreator<{ token: string }>(UserActions.AddSocialAuthAccount);

export {
    login,
    loggedIn,
    checkAuth,
    checkAuthFailed,
    checkAuthSuccess,
    logOut,
    register,
    resetPassword,
    resellerLogin,
    resellerLogout,
    haveNewPanelVersion,
    addAccount,
    userAccountLogin,
    checkSavedAccounts,
    addSocialAuthAccount,
};
