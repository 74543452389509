// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IRegistration {
    email: string;
    password: string;
    ref_id?: number;
}

export default class Registration {
    readonly _email: string;

    /**
     * Description: Email
     * Example: whitebox@google.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    readonly _password: string;

    /**
     * Description: Password
     * Example: 1ef34eRs
     */
    get password(): string {
        return this._password;
    }

    static get passwordMinLength() {
        return 8;
    }

    static get passwordMaxLength() {
        return 2147483647;
    }

    static passwordValidate(password: string): boolean {
        return (password.length >= 8) && (password.length <= 2147483647);
    }

    readonly _ref_id: number | undefined;

    /**
     * Description: Referrer identifier
     * Example: 546
     */
    get refId(): number | undefined {
        return this._ref_id;
    }

    constructor(props: IRegistration) {
        this._email = props.email.trim();
        this._password = props.password.trim();
        if (typeof props.ref_id === 'number') {
            this._ref_id = props.ref_id;
        }
    }

    serialize(): IRegistration {
        const data: IRegistration = {
            email: this._email,
            password: this._password,
        };
        if (typeof this._ref_id !== 'undefined') {
            data.ref_id = this._ref_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            email: typeof this._email === 'string' && !!this._email.trim(),
            password: (this._password.length >= 8) && (this._password.length <= 2147483647),
            ref_id: !this._ref_id ? true : typeof this._ref_id === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IRegistration): Registration {
        return new Registration(props);
    }

    readonly keys: { [key: string]: string } = {
        email: 'email',
        password: 'password',
        refId: 'ref_id',
        }
;

    mergeDeepWith(props: Partial<Registration>): Registration {
        const updateData: Partial<IRegistration> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Registration) => {
            const updateKey = this.keys[key] as keyof IRegistration;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IRegistration, keyof IRegistration>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Registration({ ...this.serialize(), ...updateData });
    }
}
