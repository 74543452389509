import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class SocialLoginApi {
    static async exchangeForToken(exchange_code: string, xauthcookie?: string): Promise<{ token: string, cookie: string | null } | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            exchange_code: exchange_code,
        }
        return await fetch(`/auth/exchange_code?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Cookie': xauthcookie || '',
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return {
                    token: res.headers.get('x-auth-token')!,
                    cookie: res.headers.get('x-auth-cookie'),
                };
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async generalSocialLogin(provider_id: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/social/${provider_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async multiAccSocialLogin(provider_id: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/social/acc/${provider_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
