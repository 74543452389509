import React, { FC, ClipboardEvent } from 'react';
import { FormikErrors } from 'formik';

import { useIntl, Input } from 'Common';
import { NewServerFormValues } from 'Lib/helpers/newServer';
import ServerSetup from 'Entities/ServerSetup';

import s from './FormGroup.module.pcss';

interface NameProps {
    name: string;
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
    setSubmitting: (isSubmitting: boolean) => void;
    errors: FormikErrors<NewServerFormValues>;
}

const Name: FC<NameProps> = ({ name, setFieldValue, setSubmitting, errors }) => {
    const intl = useIntl();

    const onChange = (v: string) => {
        if (v.length <= ServerSetup.nameMaxLength) {
            setSubmitting(false);
            setFieldValue('name', v);
        }
    };

    const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const text = name + e.clipboardData.getData('text/plain');
        setFieldValue('name', text.slice(0, ServerSetup.nameMaxLength));
    };

    return (
        <Input
            validate={ServerSetup.nameValidate}
            name="name"
            type="text"
            size="large"
            placeholder={intl.getPlural('new_server_name_placeholder', ServerSetup.nameMaxLength)}
            value={name}
            onChange={onChange}
            onPaste={onPaste}
            error={!!errors.name}
            wrapperClassName={s.name}
            autoFocus
        />
    );
};

export default Name;
