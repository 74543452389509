import React, { FC } from 'react';

import { useIntl, DeleteModalLayout } from 'Common';

interface DeleteTriggerModalProps {
    visible: boolean;
    name: string;
    handleDelete: () => void;
    handleClose: () => void;
}

const DeleteTriggerModal: FC<DeleteTriggerModalProps> = ({
    visible,
    name,
    handleDelete,
    handleClose,
}) => {
    const intl = useIntl();

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('trigger_delete')}
            handleDelete={handleDelete}
            handleClose={handleClose}
        >
            <div className="modal__desc">
                {intl.getMessage('trigger_delete_desc', { name })}
            </div>
        </DeleteModalLayout>
    );
};

export default DeleteTriggerModal;
