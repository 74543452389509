import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, connect } from 'react-redux';
import { createSelector } from 'reselect';

import { getServerBackupsList } from 'Actions/serverBackup';
import { useIntl, ListLayout, Icon } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import ServerBackup from 'Entities/ServerBackup';
import Server from 'Entities/Server';
import theme from 'Lib/theme';
import { Store } from 'Store';

import Backup from './Backup';

interface ListStoreProps {
    backups: ServerBackup[];
    progress: Map<string, OperationProgress>;
    serverBackupProgress?: OperationProgress;
}
interface ListOwnProps {
    title: string;
    info: string;
    server: Server;
}
type ListProps = ListOwnProps & ListStoreProps;
const List: FC<ListProps> = ({ title, info, backups, server, progress, serverBackupProgress }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { tenantId, id } = server;

    useEffect(() => {
        if (server) {
            dispatch(getServerBackupsList([tenantId, id]));
        }
    }, [server]);

    if (backups.length === 0) {
        return null;
    }

    return (
        <ListLayout
            title={title}
            info={info}
        >
            <div
                className={cn(
                    theme.card.labels,
                    theme.card.labels_withActions,
                    theme.card.labels_serverBackup,
                )}
            >
                <div className={theme.card.label}>
                    <Icon icon="history" className={theme.card.dateIcon} />
                </div>
                <div className={theme.card.label}>
                    {intl.getMessage('size')}
                </div>
            </div>
            {backups.map((item) => (
                <Backup
                    key={item.id}
                    server={server}
                    backup={item}
                    progress={progress}
                    serverBackupProgress={serverBackupProgress}
                />
            ))}
        </ListLayout>
    );
};

const selectBackups = (store: Store) => store.serverBackup.backups;
const selectProgress = (store: Store) => store.serverBackup.progress;
const selectServerBackupProgress = (store: Store, op: ListOwnProps) => (
    store.operationProgress.backupOperations.get(op?.server.id)
);
const selector = createSelector(
    [selectBackups, selectProgress, selectServerBackupProgress],
    (backups, progress, serverBackupProgress) => {
        return {
            backups: Array.from(backups.values()),
            progress,
            serverBackupProgress,
        };
    },
);
const mapStoreToProps = (store: Store, op: ListOwnProps) => ({
    ...selector(store, op),
});
export default connect(mapStoreToProps)(List);
