import React, { FC, FocusEvent } from 'react';
import { Button as ButtonControl, Tooltip } from 'antd';
import { ButtonProps as ButtonControlProps } from 'antd/lib/button';
import cn from 'classnames';

import { Dots, Icon, IconType } from 'Common';
import theme from 'Lib/theme';

type ButtonSize = 'small' | 'medium' | 'big';
type ButtonType = 'primary' | 'icon' | 'link' | 'outlined' | 'border' | 'ghost' | 'input' | 'edit';
type ButtonHTMLType = 'submit' | 'button' | 'reset';
type ButtonShape = 'circle' | 'circle-outline' | 'round';

export interface ButtonProps {
    className?: string;
    danger?: boolean;
    dataAttrs?: {
        [key: string]: string;
    };
    disabled?: boolean;
    htmlType?: ButtonHTMLType;
    icon?: IconType | 'dots_loader';
    iconClassName?: string;
    id?: string;
    inGroup?: boolean;
    onClick?: React.MouseEventHandler<HTMLElement>;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    onMouseDown?: ButtonControlProps['onMouseDown'];
    onTouchStart?: ButtonControlProps['onTouchStart'];
    shape?: ButtonShape;
    size?: ButtonSize;
    showDisabledTitle?: boolean;
    title?: string;
    type: ButtonType;
    block?: boolean;
    tooltipClassName?: string;
}

const Button: FC<ButtonProps> = ({
    children,
    className,
    danger,
    dataAttrs,
    disabled,
    htmlType,
    icon,
    iconClassName,
    id,
    inGroup,
    onClick,
    onBlur,
    onMouseDown,
    onTouchStart,
    shape,
    size,
    showDisabledTitle,
    title,
    type,
    block,
    tooltipClassName,
}) => {
    const buttonClass = cn(
        theme.button.button,
        { [theme.button.desktop_big]: size === 'big' },
        { [theme.button.desktop_medium]: size === 'medium' },
        { [theme.button.desktop_small]: size === 'small' },
        { [theme.button.icon]: type === 'icon' },
        { [theme.button.link]: type === 'link' },
        { [theme.button.outlined]: type === 'outlined' },
        { [theme.button.border]: type === 'border' },
        { [theme.button.primary]: type === 'primary' },
        { [theme.button.ghost]: type === 'ghost' },
        { [theme.button.input]: type === 'input' },
        { [theme.button.edit]: type === 'edit' },
        { [theme.button.in_group]: inGroup },
        { [theme.button.link_danger]: danger },
        { [theme.button.block]: block },
        { [theme.button.circle]: shape === 'circle' },
        className,
    );

    const control = (
        <ButtonControl
            className={buttonClass}
            danger={danger}
            disabled={disabled}
            {...dataAttrs}
            htmlType={htmlType}
            icon={icon && (icon === 'dots_loader' ? <Dots className={iconClassName} /> : <Icon icon={icon} className={iconClassName} />)}
            id={id}
            onClick={onClick}
            onBlur={onBlur}
            onMouseDown={onMouseDown}
            onTouchStart={onTouchStart}
            shape={shape}
            type={type === 'link' ? 'link' : undefined}
        >
            {children}
        </ButtonControl>
    );

    if (title && (!disabled || showDisabledTitle)) {
        return (
            <Tooltip
                title={title}
                placement="bottomRight"
                overlayClassName={cn('tooltip tooltip_small', tooltipClassName)}
            >
                {disabled ? (
                    <div className={theme.button.disabledWrapper}>
                        {control}
                    </div>
                ) : control}
            </Tooltip>
        );
    }

    return control;
};

export default Button;
