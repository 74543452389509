// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITicketsInfo {
    open_tickets_count: number;
    unread_tickets_count: number;
}

export default class TicketsInfo {
    readonly _open_tickets_count: number;

    /**
     * Description: Unread tickets count
     * Example: 12
     */
    get openTicketsCount(): number {
        return this._open_tickets_count;
    }

    static openTicketsCountValidate(openTicketsCount: number): boolean {
        return typeof openTicketsCount === 'number';
    }

    readonly _unread_tickets_count: number;

    /**
     * Description: Unread tickets count
     * Example: 12
     */
    get unreadTicketsCount(): number {
        return this._unread_tickets_count;
    }

    static unreadTicketsCountValidate(unreadTicketsCount: number): boolean {
        return typeof unreadTicketsCount === 'number';
    }

    constructor(props: ITicketsInfo) {
        this._open_tickets_count = props.open_tickets_count;
        this._unread_tickets_count = props.unread_tickets_count;
    }

    serialize(): ITicketsInfo {
        const data: ITicketsInfo = {
            open_tickets_count: this._open_tickets_count,
            unread_tickets_count: this._unread_tickets_count,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            unread_tickets_count: typeof this._unread_tickets_count === 'number',
            open_tickets_count: typeof this._open_tickets_count === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITicketsInfo): TicketsInfo {
        return new TicketsInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        openTicketsCount: 'open_tickets_count',
        unreadTicketsCount: 'unread_tickets_count',
        }
;

    mergeDeepWith(props: Partial<TicketsInfo>): TicketsInfo {
        const updateData: Partial<ITicketsInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TicketsInfo) => {
            const updateKey = this.keys[key] as keyof ITicketsInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITicketsInfo, keyof ITicketsInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TicketsInfo({ ...this.serialize(), ...updateData });
    }
}
