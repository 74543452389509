// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerResourceLimits {
    max_backup_quantity: number;
    max_cpu_cores: number;
    max_disk_gib: number;
    max_ip_count: number;
    max_memory_mib: number;
    max_snapshots_count: number;
    memory_disk_rate: number;
    min_backup_quantity: number;
    min_cpu_cores: number;
    min_disk_gib: number;
    min_ip_count: number;
    min_memory_mib: number;
    step_memory_mib: number;
}

export default class ServerResourceLimits {
    readonly _max_backup_quantity: number;

    /**
     * Description: Max available backups copies
     * Example: 10
     */
    get maxBackupQuantity(): number {
        return this._max_backup_quantity;
    }

    static maxBackupQuantityValidate(maxBackupQuantity: number): boolean {
        return typeof maxBackupQuantity === 'number';
    }

    readonly _max_cpu_cores: number;

    /**
     * Description: Max available cpu cores
     * Example: 12
     */
    get maxCpuCores(): number {
        return this._max_cpu_cores;
    }

    static maxCpuCoresValidate(maxCpuCores: number): boolean {
        return typeof maxCpuCores === 'number';
    }

    readonly _max_disk_gib: number;

    /**
     * Description: Max available disk in Gib
     * Example: 128
     */
    get maxDiskGib(): number {
        return this._max_disk_gib;
    }

    static maxDiskGibValidate(maxDiskGib: number): boolean {
        return typeof maxDiskGib === 'number';
    }

    readonly _max_ip_count: number;

    /**
     * Description: Max available IPs
     * Example: 2
     */
    get maxIpCount(): number {
        return this._max_ip_count;
    }

    static maxIpCountValidate(maxIpCount: number): boolean {
        return typeof maxIpCount === 'number';
    }

    readonly _max_memory_mib: number;

    /**
     * Description: Max available memory in Mib
     * Example: 16384
     */
    get maxMemoryMib(): number {
        return this._max_memory_mib;
    }

    static maxMemoryMibValidate(maxMemoryMib: number): boolean {
        return typeof maxMemoryMib === 'number';
    }

    readonly _max_snapshots_count: number;

    /**
     * Description: Max available snapshots
     * Example: 10
     */
    get maxSnapshotsCount(): number {
        return this._max_snapshots_count;
    }

    static maxSnapshotsCountValidate(maxSnapshotsCount: number): boolean {
        return typeof maxSnapshotsCount === 'number';
    }

    readonly _memory_disk_rate: number;

    /**
     * Description: Upper bound for disk/memory ratio
     * Example: 32
     */
    get memoryDiskRate(): number {
        return this._memory_disk_rate;
    }

    static memoryDiskRateValidate(memoryDiskRate: number): boolean {
        return typeof memoryDiskRate === 'number';
    }

    readonly _min_backup_quantity: number;

    /**
     * Description: Min available backups copies
     * Example: 1
     */
    get minBackupQuantity(): number {
        return this._min_backup_quantity;
    }

    static minBackupQuantityValidate(minBackupQuantity: number): boolean {
        return typeof minBackupQuantity === 'number';
    }

    readonly _min_cpu_cores: number;

    /**
     * Description: Min available cpu cores
     * Example: 1
     */
    get minCpuCores(): number {
        return this._min_cpu_cores;
    }

    static minCpuCoresValidate(minCpuCores: number): boolean {
        return typeof minCpuCores === 'number';
    }

    readonly _min_disk_gib: number;

    /**
     * Description: Min available disk in Gib
     * Example: 8
     */
    get minDiskGib(): number {
        return this._min_disk_gib;
    }

    static minDiskGibValidate(minDiskGib: number): boolean {
        return typeof minDiskGib === 'number';
    }

    readonly _min_ip_count: number;

    /** */
    get minIpCount(): number {
        return this._min_ip_count;
    }

    static minIpCountValidate(minIpCount: number): boolean {
        return typeof minIpCount === 'number';
    }

    readonly _min_memory_mib: number;

    /**
     * Description: Min available memory in Mib
     * Example: 512
     */
    get minMemoryMib(): number {
        return this._min_memory_mib;
    }

    static minMemoryMibValidate(minMemoryMib: number): boolean {
        return typeof minMemoryMib === 'number';
    }

    readonly _step_memory_mib: number;

    /**
     * Description: Memory increasing step in Mib
     * Example: 512
     */
    get stepMemoryMib(): number {
        return this._step_memory_mib;
    }

    static stepMemoryMibValidate(stepMemoryMib: number): boolean {
        return typeof stepMemoryMib === 'number';
    }

    constructor(props: IServerResourceLimits) {
        this._max_backup_quantity = props.max_backup_quantity;
        this._max_cpu_cores = props.max_cpu_cores;
        this._max_disk_gib = props.max_disk_gib;
        this._max_ip_count = props.max_ip_count;
        this._max_memory_mib = props.max_memory_mib;
        this._max_snapshots_count = props.max_snapshots_count;
        this._memory_disk_rate = props.memory_disk_rate;
        this._min_backup_quantity = props.min_backup_quantity;
        this._min_cpu_cores = props.min_cpu_cores;
        this._min_disk_gib = props.min_disk_gib;
        this._min_ip_count = props.min_ip_count;
        this._min_memory_mib = props.min_memory_mib;
        this._step_memory_mib = props.step_memory_mib;
    }

    serialize(): IServerResourceLimits {
        const data: IServerResourceLimits = {
            max_backup_quantity: this._max_backup_quantity,
            max_cpu_cores: this._max_cpu_cores,
            max_disk_gib: this._max_disk_gib,
            max_ip_count: this._max_ip_count,
            max_memory_mib: this._max_memory_mib,
            max_snapshots_count: this._max_snapshots_count,
            memory_disk_rate: this._memory_disk_rate,
            min_backup_quantity: this._min_backup_quantity,
            min_cpu_cores: this._min_cpu_cores,
            min_disk_gib: this._min_disk_gib,
            min_ip_count: this._min_ip_count,
            min_memory_mib: this._min_memory_mib,
            step_memory_mib: this._step_memory_mib,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            min_memory_mib: typeof this._min_memory_mib === 'number',
            max_memory_mib: typeof this._max_memory_mib === 'number',
            step_memory_mib: typeof this._step_memory_mib === 'number',
            min_disk_gib: typeof this._min_disk_gib === 'number',
            max_disk_gib: typeof this._max_disk_gib === 'number',
            memory_disk_rate: typeof this._memory_disk_rate === 'number',
            min_cpu_cores: typeof this._min_cpu_cores === 'number',
            max_cpu_cores: typeof this._max_cpu_cores === 'number',
            min_ip_count: typeof this._min_ip_count === 'number',
            max_ip_count: typeof this._max_ip_count === 'number',
            min_backup_quantity: typeof this._min_backup_quantity === 'number',
            max_backup_quantity: typeof this._max_backup_quantity === 'number',
            max_snapshots_count: typeof this._max_snapshots_count === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerResourceLimits): ServerResourceLimits {
        return new ServerResourceLimits(props);
    }

    readonly keys: { [key: string]: string } = {
        maxBackupQuantity: 'max_backup_quantity',
        maxCpuCores: 'max_cpu_cores',
        maxDiskGib: 'max_disk_gib',
        maxIpCount: 'max_ip_count',
        maxMemoryMib: 'max_memory_mib',
        maxSnapshotsCount: 'max_snapshots_count',
        memoryDiskRate: 'memory_disk_rate',
        minBackupQuantity: 'min_backup_quantity',
        minCpuCores: 'min_cpu_cores',
        minDiskGib: 'min_disk_gib',
        minIpCount: 'min_ip_count',
        minMemoryMib: 'min_memory_mib',
        stepMemoryMib: 'step_memory_mib',
        }
;

    mergeDeepWith(props: Partial<ServerResourceLimits>): ServerResourceLimits {
        const updateData: Partial<IServerResourceLimits> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerResourceLimits) => {
            const updateKey = this.keys[key] as keyof IServerResourceLimits;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerResourceLimits, keyof IServerResourceLimits>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerResourceLimits({ ...this.serialize(), ...updateData });
    }
}
