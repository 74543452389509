import React, { FC } from 'react';

import cn from 'classnames';

import theme from 'Lib/theme';

interface ActionButtonProps {
    id?: string;
    handler: () => void;
    title: string;
    className?: string;
    disabled?: boolean;
    tooltip?: string;
}

const ActionButton: FC<ActionButtonProps> = ({ id, handler, title, className, disabled, tooltip }) => (
    <button
        id={id}
        type="button"
        className={cn(theme.popover.item, className)}
        onClick={handler}
        disabled={disabled}
        title={tooltip}
    >
        {title}
    </button>
);

export default ActionButton;
