import React, { FC, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { Grid } from 'antd';

import { useIntl, EmptyPageLayout } from 'Common';
import Server from 'Entities/Server';
import { RoutePath } from 'Lib/helpers/routes';
import { SERVER_SELECT_SIZE } from 'Lib/helpers/consts';

import ServerItem from './ServerItem';
import s from './ServersSelect.module.pcss';

const { useBreakpoint } = Grid;

const ITEMS_IN_VIEW_DESKTOP = 9;
const ITEMS_IN_VIEW_MOBILE = 6;

type RowData = {
    servers: Server[];
    selectedServers: Set<Server>;
    selectServers: (s: Server) => void;
};

interface RowProps {
    style: object;
    index: number;
    data: RowData;
}

const Row: FC<RowProps> = ({ style, index, data }) => {
    const { servers, selectedServers, selectServers } = data;
    const server = servers[index];

    return (
        <div style={style}>
            <ServerItem
                server={server}
                checked={selectedServers.has(server)}
                selectServers={selectServers}
            />
        </div>
    );
};

interface SearchListProps {
    servers: Server[];
    selectedServers: Set<Server>;
    selectServers: (s: Server) => void;
}

const SearchList: FC<SearchListProps> = ({
    servers, selectedServers, selectServers,
}) => {
    const intl = useIntl();
    const screens = useBreakpoint();

    const data: RowData = useMemo(() => ({
        servers,
        selectedServers,
        selectServers,
    }), [servers, selectedServers]);

    let listSize = {
        width: SERVER_SELECT_SIZE.WIDTH_MOBILE,
        height: servers.length < ITEMS_IN_VIEW_DESKTOP
            ? servers.length * SERVER_SELECT_SIZE.ITEM_HEIGHT
            : SERVER_SELECT_SIZE.HEIGHT_MOBILE,
    };

    if (screens?.sm) {
        listSize = {
            ...listSize,
            width: SERVER_SELECT_SIZE.WIDTH_DESKTOP,
            height: servers.length < ITEMS_IN_VIEW_MOBILE
                ? servers.length * SERVER_SELECT_SIZE.ITEM_HEIGHT
                : SERVER_SELECT_SIZE.HEIGHT_DESKTOP,
        };
    }

    if (servers.length === 0) {
        return (
            <EmptyPageLayout
                desc={intl.getMessage('search_empty')}
                linkText={intl.getMessage('create_server')}
                link={RoutePath.NewServer}
                type="dropdown"
            />
        );
    }

    return (
        <List
            className={s.list}
            width={listSize.width}
            height={listSize.height}
            itemSize={() => SERVER_SELECT_SIZE.ITEM_HEIGHT}
            itemCount={servers.length}
            itemData={data}
        >
            {Row}
        </List>
    );
};

export default SearchList;
