import React, { FC } from 'react';
import { Row, Col } from 'antd';

import { Checkbox } from 'Common';

import s from './UserSettings.module.pcss';

type StateKeys = 'email' | 'sms';

interface SettingsRowProps {
    title: string;
    value?: Record<StateKeys, boolean>;
    onChange: (dataKey: StateKeys) => (value: boolean) => void;
}

const SettingsRow: FC<SettingsRowProps> = ({
    title,
    value,
    onChange,
}) => (
    <Row align="middle" gutter={[24, 16]} className="row row_nowrap">
        <Col flex="auto" className="column column_overflow">
            <div className={s.setting}>
                {title}
            </div>
        </Col>
        <Col>
            <Checkbox
                name={`${title}_email`}
                checked={!!value?.email}
                handleChange={(e) => onChange('email')(e.target.checked)}
            />
        </Col>
        <Col>
            <Checkbox
                name={`${title}_sms`}
                checked={!!value?.sms}
                handleChange={(e) => onChange('sms')(e.target.checked)}
            />
        </Col>
    </Row>
);

export default SettingsRow;
