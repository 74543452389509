import { setUser } from '@sentry/browser';

import { Action } from 'store/actions';
import { UserActions, ILoggedIn } from 'Actions/user';
import UserPreferencesStorage from 'Lib/helpers/userStorage';
import { setAuthToken } from 'Lib/helpers/authHelper';

import { UserStore, userInitialState as initialState } from '../initialStates';

const reducer = (state: UserStore = initialState, action: Action): UserStore => {
    switch (action.type as UserActions) {
        case UserActions.LoggedIn: {
            const { payload: { token, email, id } } = action as Action<ILoggedIn>;
            setAuthToken(token);
            const body = document.getElementsByTagName('body')[0];
            body.dataset.loggedAs = email || '';
            if (!DEV && email) {
                setUser({ email, id: String(id) });
            }

            UserStorage = new UserPreferencesStorage(id);

            return {
                ...state,
                token,
                isChecked: true,
                loggedIn: !!token,
                email: email ?? '',
                loading: false,
                userId: id,
            };
        }
        case UserActions.CheckAuthFailed: {
            AppConfigStorage.removeAuthToken();
            return { ...state, loading: false, token: '', isChecked: true };
        }
        case UserActions.CheckAuthSuccess: {
            return { ...state, isChecked: true };
        }
        case UserActions.HaveNewPanelVersion: {
            return { ...state, haveNewVersion: true };
        }
        default:
            return state;
    }
};

export default reducer;
