import React, { FC } from 'react';
import cn from 'classnames';

import Server from 'Entities/Server';
import Tenant from 'Entities/Tenant';
import { SERVER_VIEW } from 'Lib/helpers/consts';
import { useUISettings } from 'Lib/hooks/hooks';

import { ServerName } from '../ServerName';
import s from '../Header.module.pcss';

interface CommonHeaderProps {
    server: Server;
    tenant: Tenant;
    disabled?: boolean;
    className?: string;
}

const CommonHeader: FC<CommonHeaderProps> = ({
    children,
    server,
    tenant,
    disabled,
    className,
}) => {
    const { serverView } = useUISettings();

    return (
        <div
            className={cn(
                s.header,
                {
                    [s.header_compact]: serverView === SERVER_VIEW.COMPACT,
                    [s.header_list]: serverView === SERVER_VIEW.LIST,
                },
                className,
            )}
        >
            <ServerName
                server={server}
                tenant={tenant}
                disabled={disabled}
            />

            {children}
        </div>
    );
};

export default CommonHeader;
