import { takeEvery, put, call } from 'redux-saga/effects';

import {
    NewServerActions,
    calculateCostForecastForDraft as calculateCostForecastForDraftAct,
    saveCostForecastForDraft,
    getResourseLimits as getResourseLimitsAct,
    saveResourseLimints,
    calculateCostForecastForServer as calculateCostForecastForServerAct,
    saveCostForecastForServer,
} from 'Actions/newServer';

import { InplaceType } from 'Actions/.';
import ServerApi from 'Apis/servers';
import ResourceLimitsApi from 'Apis/resourceLimits';
import ServerCostForecast, { IServerCostForecast } from 'Entities/ServerCostForecast';
import ServerResourceLimits, { IServerResourceLimits } from 'Entities/ServerResourceLimits';

import { errorChecker, addError } from './utils';

function* calculateCostForecastForDraft(
    action: ReturnType<typeof calculateCostForecastForDraftAct>,
) {
    const { payload, callback } = action;
    const response: IServerCostForecast = yield call(
        ServerApi.calculateCostForecastForDraft, ...payload,
    );
    const { error, result } = errorChecker<IServerCostForecast>(response);
    if (result) {
        yield put(saveCostForecastForDraft(new ServerCostForecast(result)));
    }
    if (error && callback?.error) {
        callback?.error();
    }
}

function* calculateCostForecastForServer(
    action: ReturnType<typeof calculateCostForecastForServerAct>,
) {
    const { payload, callback } = action;
    const response: IServerCostForecast = yield call(
        ServerApi.calculateCostForecastForServer, ...payload,
    );
    const { error, result } = errorChecker<IServerCostForecast>(response);
    if (result) {
        yield put(saveCostForecastForServer(new ServerCostForecast(result)));
    }
    if (error && callback?.error) {
        callback?.error();
    }
}

const LimitsMap = new Map<string, ServerResourceLimits>();
function* getResourseLimits(
    action: ReturnType<typeof getResourseLimitsAct>,
) {
    const { payload, payload: [tenantId, distributionId] } = action;
    const key = `${distributionId}.${tenantId}`;
    if (LimitsMap.has(key)) {
        yield put(saveResourseLimints(LimitsMap.get(key)));
        return;
    }
    const response: IServerResourceLimits = yield call(
        ResourceLimitsApi.getResourceLimitsForDraft, ...payload,
    );
    const { error, result } = errorChecker<IServerResourceLimits>(response);
    if (result) {
        const limits = new ServerResourceLimits(result);
        LimitsMap.set(key, limits);
        yield put(saveResourseLimints(LimitsMap.get(key)));
    } else if (error) {
        yield addError(error, InplaceType.GetResourceLimits);
    }
}

function* serverSaga(): Generator {
    yield takeEvery<ReturnType<typeof calculateCostForecastForDraftAct>>(
        NewServerActions.CalculateCostForecastForDraft,
        calculateCostForecastForDraft,
    );
    yield takeEvery<ReturnType<typeof calculateCostForecastForServerAct>>(
        NewServerActions.CalculateCostForecastForServer,
        calculateCostForecastForServer,
    );
    yield takeEvery<ReturnType<typeof getResourseLimitsAct>>(
        NewServerActions.GetResourseLimits,
        getResourseLimits,
    );
}

export default serverSaga;
