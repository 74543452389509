import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { moveIp, removeIp } from 'Actions/server';
import { useIntl, notifySuccess, TerminalLink } from 'Common';
import Server from 'Entities/Server';
import IpAddress from 'Entities/IpAddress';
import { DistributionFamily } from 'Entities/DistributionFamily';
import theme from 'Lib/theme';

import { ModalIpMove, ModalIpRemove } from '../Modals';
import OptionButton from './OptionButton';
import styles from './IpList.module.pcss';

interface IpOptionsOwnProps {
    ip: IpAddress;
    server: Server;
    progress: boolean;
    header?: boolean;
}

type IpOptionsProps = IpOptionsOwnProps;

const IpOptions: FC<IpOptionsProps> = ({ ip, server, header, progress }) => {
    const { host, netmask, gateway } = ip;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [showConfirmRemove, setShowConfirmRemove] = useState(false);
    const [currentServer, setCurrentServer] = useState<Server | null>(null);

    const onCopy = () => {
        setVisible(false);
        notifySuccess(intl.getMessage('copied_ip'), 'copied_ip');
        navigator.clipboard.writeText(host);
    };
    const onMoveIp = () => {
        if (!currentServer) {
            return null;
        }

        const { id, tenantId } = currentServer;
        dispatch(moveIp({
            server,
            move: {
                ip_address: host,
                to_server_id: id,
            },
            toTenantId: tenantId,
        }));
        setCurrentServer(null);
    };

    const onDeleteConfirmed = () => {
        dispatch(removeIp({ server, remove: { ip_address: host } }));
        setShowConfirmRemove(false);
    };

    const onShowRemove = () => {
        setVisible(false);
        setShowConfirmRemove(true);
    };
    const onIpMove = (serverItem: Server) => () => {
        setVisible(false);
        setCurrentServer(serverItem);
    };

    const content = (
        <div className={theme.popover.popover}>
            <div className={theme.popover.info}>
                <div className={theme.popover.value}>
                    {netmask}
                </div>
                <div className={theme.popover.label}>
                    {intl.getMessage('netmask')}
                </div>
            </div>
            {gateway && (
                <div className={theme.popover.info}>
                    <div className={theme.popover.value}>
                        {gateway}
                    </div>
                    <div className={theme.popover.label}>
                        {intl.getMessage('gateway')}
                    </div>
                </div>
            )}
            <div className={theme.popover.divider} />
            <TerminalLink
                host={host}
                disabled={server.distribution.type === DistributionFamily.WINDOWS}
                handleClick={() => setVisible(false)}
            />
            <OptionButton
                handler={onCopy}
                title={intl.getMessage('server_ip_option_copy')}
            />

            {!progress && (
                <>
                    <OptionButton
                        handler={onIpMove(server)}
                        title={intl.getMessage('server_ip_option_other_server')}
                    />
                    <OptionButton
                        handler={onShowRemove}
                        title={intl.getMessage('delete')}
                        type={theme.popover.item_danger}
                    />
                </>
            )}
        </div>
    );
    return (
        <div
            key={host}
            className={cn(
                styles.ip,
                { [styles.ip_header]: header },
            )}
        >
            <Popover
                content={content}
                placement="bottom"
                trigger="click"
                visible={visible}
                onVisibleChange={(v: boolean) => setVisible(v)}
                overlayClassName="popover"
            >
                <span className={cn(theme.link.link, theme.link.blue)}>
                    {host}
                </span>
            </Popover>

            {showConfirmRemove && (
                <ModalIpRemove
                    visible={showConfirmRemove}
                    onClose={() => setShowConfirmRemove(false)}
                    onDelete={onDeleteConfirmed}
                />
            )}

            {currentServer && (
                <ModalIpMove
                    visible
                    onClose={() => setCurrentServer(null)}
                    onOk={onMoveIp}
                    currentServer={currentServer}
                    setCurrentServer={setCurrentServer}
                    server={server}
                />
            )}
        </div>
    );
};

export default IpOptions;
