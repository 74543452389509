import React, { FC, useState } from 'react';

import { useIntl, DeleteModalLayout, Checkbox } from 'Common';
import { useEnter } from 'Hooks';

interface ModalDelSnapshotProps {
    visible: boolean;
    close: () => void;
    onOk: (deleteChildren: boolean) => void;
    name: string;
}

const ModalDelSnapshot: FC<ModalDelSnapshotProps> = ({ visible, close, onOk, name }) => {
    const [delChildren, setDelChildren] = useState(false);
    const intl = useIntl();
    const handleOk = () => {
        onOk(delChildren);
        close();
    };
    useEnter(handleOk);

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('snapshots_del_modal')}
            buttonText={intl.getMessage('snapshots_del_modal')}
            submitButtonId="deleteSnapshot"
            handleDelete={handleOk}
            handleClose={close}
        >
            <div className="modal__desc modal__desc--gray">
                {intl.getMessage('snapshots_del_desc', { name })}
            </div>
            <Checkbox
                name="delete"
                checked={delChildren}
                handleChange={(e) => setDelChildren(e.target.checked)}
            >
                {intl.getMessage('snapshots_del_checkbox')}
            </Checkbox>
        </DeleteModalLayout>
    );
};

export default ModalDelSnapshot;
