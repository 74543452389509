import React, { FC } from 'react';
import { Layout } from 'antd';
import cn from 'classnames';

import { useIntl, Icon } from 'Common';
import { SIDEBAR_WIDTH } from 'Lib/helpers/consts';

import Skeleton from './Skeleton';
import s from './Client.module.pcss';

const { Sider } = Layout;

interface EmptySidebarProps {
    collapsed: boolean;
    handleSidebar: () => void;
}

const EmptySidebar: FC<EmptySidebarProps> = ({
    collapsed,
    handleSidebar,
}) => {
    const intl = useIntl();

    return (
        <Sider
            collapsed={collapsed}
            collapsedWidth={0}
            collapsible
            className="sidebar"
            onClick={handleSidebar}
            trigger={null}
            width={SIDEBAR_WIDTH}
        >
            <div
                className={cn(s.link, s.link_disabled)}
            >
                <div className={s.logo}>
                    <Icon icon="panel_logo" className={s.icon} />
                    <span className={s.name}>
                        {intl.getMessage('dashboard')}
                    </span>
                </div>
            </div>

            <Skeleton />
        </Sider>
    );
};

export default EmptySidebar;
