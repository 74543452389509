import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import BackupPolicyUpdate, { IBackupPolicyUpdate } from 'Entities/BackupPolicyUpdate';
import Tenant, { ITenant } from 'Entities/Tenant';
import TenantAddUser, { ITenantAddUser } from 'Entities/TenantAddUser';
import TenantCostForecast, { ITenantCostForecast } from 'Entities/TenantCostForecast';
import TenantCostForecastRequest, { ITenantCostForecastRequest } from 'Entities/TenantCostForecastRequest';
import TenantCreate, { ITenantCreate } from 'Entities/TenantCreate';
import TenantUpdate, { ITenantUpdate } from 'Entities/TenantUpdate';
import TenantUser, { ITenantUser } from 'Entities/TenantUser';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class TenantsApi {
    static async addUserToTenant(tenant_id: number, tenantadduser: ITenantAddUser, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const tenantadduserValid = new TenantAddUser(tenantadduser);
        haveError.push(...tenantadduserValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/users`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tenantadduserValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async calculateCostForecastForTenant(tenant_id: number, tenantcostforecastrequest: ITenantCostForecastRequest, optionalToken?: string): Promise<ITenantCostForecast | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const tenantcostforecastrequestValid = new TenantCostForecastRequest(tenantcostforecastrequest);
        haveError.push(...tenantcostforecastrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/forecast`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tenantcostforecastrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async createTenant(tenantcreate: ITenantCreate, optionalToken?: string): Promise<ITenant | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const tenantcreateValid = new TenantCreate(tenantcreate);
        haveError.push(...tenantcreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tenantcreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteTenant(tenant_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteUserFromTenant(tenant_id: number, user_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/users/${user_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getTenant(tenant_id: number, optionalToken?: string): Promise<ITenant | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listTenants(optionalToken?: string): Promise<ITenant[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listTenantsUsers(optionalToken?: string): Promise<ITenantUser[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/users`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateBackupPolicyForTenant(tenant_id: number, backuppolicyupdate: IBackupPolicyUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const backuppolicyupdateValid = new BackupPolicyUpdate(backuppolicyupdate);
        haveError.push(...backuppolicyupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/backup_policy`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(backuppolicyupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateTenant(tenant_id: number, tenantupdate: ITenantUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const tenantupdateValid = new TenantUpdate(tenantupdate);
        haveError.push(...tenantupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(tenantupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
