import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import qs from 'qs';

import { useTitle } from 'Hooks';
import { InnerPageLayout, useIntl } from 'Common';
import Profile from 'Entities/Profile';

import { Store } from 'Store';
import { Header, SecurityActions } from './components';
import s from './Settings.module.pcss';

interface SettingsProps {
    profile: Profile | null;
}

const Settings: FC<SettingsProps> = ({ profile }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('profile_setting_page_title'));

    useEffect(() => {
        const query = window.location.search.replace('?', '');
        const search = qs.parse(query);
        if (search.scroll) {
            window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
        }
    }, []);

    if (!profile) {
        return null;
    }

    return (
        <InnerPageLayout header={<Header />}>
            <div className={s.group}>
                <SecurityActions />
            </div>
        </InnerPageLayout>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([selectProfile], (profile) => ({ profile }));

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Settings);
