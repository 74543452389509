import { Action, ActionType } from 'store/actions';
import {
    UiActions,
    setOperationNotifications,
    setServerView,
    setSearchType,
} from 'Actions/ui';
import {
    SERVER_OPERATION_NOTIFICATION,
} from 'Lib/helpers/consts';
import { shouldShowUiNotification } from 'Lib/helpers/helpers';

import { UiStore, uiInitialState as initialState } from '../initialStates';

const reducer = (
    state: UiStore = initialState,
    action: Action,
): UiStore => {
    switch (action.type as ActionType) {
        case UiActions.ToggleSidebar: {
            return { ...state, sidebarOpen: !state.sidebarOpen };
        }
        case UiActions.OffOperationNotifications: {
            const { payload } = action as ReturnType<typeof setOperationNotifications>;
            const { operationNotifications } = state;
            const isShown = !operationNotifications[payload];
            if (isShown) {
                UserStorage.removeServerOperationNotification(payload);
            } else {
                UserStorage.setServerOperationNotification(payload, false);
            }
            return {
                ...state,
                operationNotifications: { ...operationNotifications, [payload]: isShown } };
        }
        case UiActions.SetServerView: {
            const { payload } = action as ReturnType<typeof setServerView>;
            UserStorage.setServerViewType(payload);
            return { ...state, serverView: payload };
        }
        case UiActions.SetSearchType: {
            const { payload } = action as ReturnType<typeof setSearchType>;
            UserStorage.setSearchType(payload);
            return { ...state, searchType: payload };
        }
        case UiActions.InitUiSettings: {
            return {
                ...state,
                serverView: UserStorage.getServerViewType(),
                searchType: UserStorage.getSearchType(),
                operationNotifications: {
                    [SERVER_OPERATION_NOTIFICATION.REBOOT]:
                        shouldShowUiNotification(SERVER_OPERATION_NOTIFICATION.REBOOT),
                    [SERVER_OPERATION_NOTIFICATION.RESET]:
                        shouldShowUiNotification(SERVER_OPERATION_NOTIFICATION.RESET),
                    [SERVER_OPERATION_NOTIFICATION.SHUTDOWN]:
                        shouldShowUiNotification(SERVER_OPERATION_NOTIFICATION.SHUTDOWN),
                    [SERVER_OPERATION_NOTIFICATION.DESTROY]:
                        shouldShowUiNotification(SERVER_OPERATION_NOTIFICATION.DESTROY),
                },
            };
        }
        default:
            return state;
    }
};

export default reducer;
