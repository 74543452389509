import { takeEvery, put, call } from 'redux-saga/effects';

import reverseDnsApi from 'Apis/reverseDns';
import {
    ReverseDnsActions,
    savePtrRecords,
    getPtrRecords as getPtrRecordsAct,
    deletePtrRecord as deletePtrRecordAct,
    createOrUpdatePtrRecord as createOrUpdatePtrRecordAct,
} from 'Actions/reverseDns';
import DnsPtrRecord, { IDnsPtrRecord } from 'Entities/DnsPtrRecord';

import { errorChecker, addError } from './utils';

function* getPtrRecords(action: ReturnType<typeof getPtrRecordsAct>) {
    const response: IDnsPtrRecord[] = yield call(reverseDnsApi.getPtrRecords);
    const { error, result } = errorChecker<IDnsPtrRecord[]>(response);
    if (result) {
        const dnsZoneEntities = result.map((s) => new DnsPtrRecord(s));
        yield put(savePtrRecords(dnsZoneEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* deletePtrRecord(action: ReturnType<typeof deletePtrRecordAct>) {
    const { payload, callback } = action;
    const response: number = yield call(reverseDnsApi.deletePtrRecord, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getPtrRecordsAct());
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* createOrUpdatePtrRecord(action: ReturnType<typeof createOrUpdatePtrRecordAct>) {
    const { payload, callback } = action;
    const response: number = yield call(reverseDnsApi.createPtrRecord, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getPtrRecordsAct());
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        if (callback?.error) {
            callback?.error(error);
        } else {
            yield addError(error, action.type);
        }
    }
}

function* reverseDnsSaga() {
    yield takeEvery<ReturnType<typeof getPtrRecordsAct>>(
        ReverseDnsActions.GetPtrRecords, getPtrRecords,
    );
    yield takeEvery<ReturnType<typeof deletePtrRecordAct>>(
        ReverseDnsActions.DeletePtrRecord, deletePtrRecord,
    );
    yield takeEvery<ReturnType<typeof createOrUpdatePtrRecordAct>>(
        ReverseDnsActions.CreateOrUpdatePtrRecord, createOrUpdatePtrRecord,
    );
}

export default reverseDnsSaga;
