import DatePeriod, { IDatePeriod } from './DatePeriod';
import StatsEntity, { IStatsEntity } from './StatsEntity';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IComparativeStats {
    forecast_change_diff?: number;
    forecast_change_percent?: number;
    forecast_value?: number;
    previous_period?: IDatePeriod;
    previous_value?: number;
    recent_period: IDatePeriod;
    recent_value: number;
    stats_entity?: IStatsEntity;
    value_change_diff?: number;
    value_change_percent?: number;
}

export default class ComparativeStats {
    readonly _forecast_change_diff: number | undefined;

    /**
     * Description: How much forecast_value differs from recent_value, or null if forecast_value is absent
     * Example: 35
     */
    get forecastChangeDiff(): number | undefined {
        return this._forecast_change_diff;
    }

    readonly _forecast_change_percent: number | undefined;

    /**
     * Description: Stats forecast change % = 100% * (forecast - recent) / recent, or null if recent_value is zero
     * Example: 50
     */
    get forecastChangePercent(): number | undefined {
        return this._forecast_change_percent;
    }

    readonly _forecast_value: number | undefined;

    /** */
    get forecastValue(): number | undefined {
        return this._forecast_value;
    }

    readonly _previous_period: DatePeriod | undefined;

    get previousPeriod(): DatePeriod | undefined {
        return this._previous_period;
    }

    readonly _previous_value: number | undefined;

    /**
     * Description: Total stats over same previous period, or null if inapplicable or impossible to compute
     * Example: 100
     */
    get previousValue(): number | undefined {
        return this._previous_value;
    }

    readonly _recent_period: DatePeriod;

    get recentPeriod(): DatePeriod {
        return this._recent_period;
    }

    readonly _recent_value: number;

    /** */
    get recentValue(): number {
        return this._recent_value;
    }

    static recentValueValidate(recentValue: number): boolean {
        return typeof recentValue === 'number';
    }

    readonly _stats_entity: StatsEntity | undefined;

    get statsEntity(): StatsEntity | undefined {
        return this._stats_entity;
    }

    readonly _value_change_diff: number | undefined;

    /**
     * Description: How much recent_value differs from previous_value, or null if previous_value is absent
     * Example: -30
     */
    get valueChangeDiff(): number | undefined {
        return this._value_change_diff;
    }

    readonly _value_change_percent: number | undefined;

    /**
     * Description: Stats change % = 100% * (recent - prev) / prev, or null if previous_value is absent, zero or percent is greater
     * Example: -30
     */
    get valueChangePercent(): number | undefined {
        return this._value_change_percent;
    }

    constructor(props: IComparativeStats) {
        if (typeof props.forecast_change_diff === 'number') {
            this._forecast_change_diff = props.forecast_change_diff;
        }
        if (typeof props.forecast_change_percent === 'number') {
            this._forecast_change_percent = props.forecast_change_percent;
        }
        if (typeof props.forecast_value === 'number') {
            this._forecast_value = props.forecast_value;
        }
        if (props.previous_period) {
            this._previous_period = new DatePeriod(props.previous_period);
        }
        if (typeof props.previous_value === 'number') {
            this._previous_value = props.previous_value;
        }
        this._recent_period = new DatePeriod(props.recent_period);
        this._recent_value = props.recent_value;
        if (props.stats_entity) {
            this._stats_entity = new StatsEntity(props.stats_entity);
        }
        if (typeof props.value_change_diff === 'number') {
            this._value_change_diff = props.value_change_diff;
        }
        if (typeof props.value_change_percent === 'number') {
            this._value_change_percent = props.value_change_percent;
        }
    }

    serialize(): IComparativeStats {
        const data: IComparativeStats = {
            recent_period: this._recent_period.serialize(),
            recent_value: this._recent_value,
        };
        if (typeof this._forecast_change_diff !== 'undefined') {
            data.forecast_change_diff = this._forecast_change_diff;
        }
        if (typeof this._forecast_change_percent !== 'undefined') {
            data.forecast_change_percent = this._forecast_change_percent;
        }
        if (typeof this._forecast_value !== 'undefined') {
            data.forecast_value = this._forecast_value;
        }
        if (typeof this._previous_period !== 'undefined') {
            data.previous_period = this._previous_period.serialize();
        }
        if (typeof this._previous_value !== 'undefined') {
            data.previous_value = this._previous_value;
        }
        if (typeof this._stats_entity !== 'undefined') {
            data.stats_entity = this._stats_entity.serialize();
        }
        if (typeof this._value_change_diff !== 'undefined') {
            data.value_change_diff = this._value_change_diff;
        }
        if (typeof this._value_change_percent !== 'undefined') {
            data.value_change_percent = this._value_change_percent;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            stats_entity: !this._stats_entity ? true : this._stats_entity.validate().length === 0,
            recent_period: this._recent_period.validate().length === 0,
            previous_period: !this._previous_period ? true : this._previous_period.validate().length === 0,
            recent_value: typeof this._recent_value === 'number',
            previous_value: !this._previous_value ? true : typeof this._previous_value === 'number',
            forecast_value: !this._forecast_value ? true : typeof this._forecast_value === 'number',
            value_change_diff: !this._value_change_diff ? true : typeof this._value_change_diff === 'number',
            forecast_change_diff: !this._forecast_change_diff ? true : typeof this._forecast_change_diff === 'number',
            value_change_percent: !this._value_change_percent ? true : typeof this._value_change_percent === 'number',
            forecast_change_percent: !this._forecast_change_percent ? true : typeof this._forecast_change_percent === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IComparativeStats): ComparativeStats {
        return new ComparativeStats(props);
    }

    readonly keys: { [key: string]: string } = {
        forecastChangeDiff: 'forecast_change_diff',
        forecastChangePercent: 'forecast_change_percent',
        forecastValue: 'forecast_value',
        previousPeriod: 'previous_period',
        previousValue: 'previous_value',
        recentPeriod: 'recent_period',
        recentValue: 'recent_value',
        statsEntity: 'stats_entity',
        valueChangeDiff: 'value_change_diff',
        valueChangePercent: 'value_change_percent',
        }
;

    mergeDeepWith(props: Partial<ComparativeStats>): ComparativeStats {
        const updateData: Partial<IComparativeStats> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ComparativeStats) => {
            const updateKey = this.keys[key] as keyof IComparativeStats;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IComparativeStats, keyof IComparativeStats>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ComparativeStats({ ...this.serialize(), ...updateData });
    }
}
