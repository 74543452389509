import React, { FC } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Layout } from 'antd';
import cn from 'classnames';

import { Button, Icon, useIntl } from 'Common';
import Profile from 'Entities/Profile';
import { WebTheme } from 'Entities/WebTheme';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { getCurrentTheme } from 'Lib/helpers/helpers';
import { Store } from 'Store';
import theme from 'Lib/theme';

import warning from 'Lib/assets/images/warning.png';
import warning2x from 'Lib/assets/images/warning@2x.png';
import warningDark from 'Lib/assets/images/warning_dark.png';
import warning2xDark from 'Lib/assets/images/warning_dark@2x.png';

import s from '../Errors.module.pcss';

const { Content } = Layout;

interface PageProps {
    profile: Profile | null;
}

const Page: FC<PageProps> = ({ profile }) => {
    const intl = useIntl();

    const refreshPage = () => {
        window.location.reload();
    };

    const rootLink = linkPathBuilder(intl.currentLocale, RoutePath.Dashboard);
    const supportLink = linkPathBuilder(intl.currentLocale, RoutePath.CreateTicket);

    let images = {
        warning,
        warning2x,
    };

    const currentTheme = profile?.webTheme && getCurrentTheme(profile.webTheme);

    if (currentTheme === WebTheme.DARK) {
        images = {
            warning: warningDark,
            warning2x: warning2xDark,
        };
    }

    return (
        <Layout>
            <div
                className={cn(
                    theme.mainHeader.header,
                    theme.mainHeader.header_simple,
                    theme.mainHeader.header_error,
                )}
            >
                <a href={rootLink} className={theme.mainHeader.link}>
                    <Icon icon="main_logo_dark" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_desktop, theme.mainHeader.logo_dark)} />
                    <Icon icon="panel_logo" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_mobile)} />
                </a>
                <div className={theme.mainHeader.subtitle}>
                    {intl.getMessage('header_subtitle')}
                </div>
            </div>
            <Content className={cn(theme.content.content, theme.content.error)}>
                <div className={s.content}>
                    <img
                        src={images.warning}
                        srcSet={`${images.warning2x} 2x`}
                        className={s.warning}
                        alt="warning"
                    />
                    <div className={s.title}>
                        {intl.getMessage('error_page_title')}
                    </div>
                    <div className={s.desc}>
                        {intl.getMessage('error_page_desc')}
                    </div>
                    <div>
                        <Button
                            type="link"
                            size="medium"
                            onClick={refreshPage}
                            className={s.link}
                        >
                            {intl.getMessage('refresh_page')}
                        </Button>
                        <a
                            href={supportLink}
                            className={cn(
                                theme.button.button,
                                theme.button.desktop_medium,
                                theme.button.link,
                                s.link,
                            )}
                        >
                            {intl.getMessage('support')}
                        </a>
                    </div>
                </div>
            </Content>
        </Layout>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([selectProfile], (profile) => ({ profile }));

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(Page);
