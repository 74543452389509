// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISecurityCodeSendRequest {
    server_id?: number;
    tenant_id: number;
}

export default class SecurityCodeSendRequest {
    readonly _server_id: number | undefined;

    /**
     * Description: Server identifier, if applicable
     * Example: 51671
     */
    get serverId(): number | undefined {
        return this._server_id;
    }

    readonly _tenant_id: number;

    /**
     * Description: Tenant identifier
     * Example: 19934
     */
    get tenantId(): number {
        return this._tenant_id;
    }

    static tenantIdValidate(tenantId: number): boolean {
        return typeof tenantId === 'number';
    }

    constructor(props: ISecurityCodeSendRequest) {
        if (typeof props.server_id === 'number') {
            this._server_id = props.server_id;
        }
        this._tenant_id = props.tenant_id;
    }

    serialize(): ISecurityCodeSendRequest {
        const data: ISecurityCodeSendRequest = {
            tenant_id: this._tenant_id,
        };
        if (typeof this._server_id !== 'undefined') {
            data.server_id = this._server_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            tenant_id: typeof this._tenant_id === 'number',
            server_id: !this._server_id ? true : typeof this._server_id === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISecurityCodeSendRequest): SecurityCodeSendRequest {
        return new SecurityCodeSendRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        serverId: 'server_id',
        tenantId: 'tenant_id',
        }
;

    mergeDeepWith(props: Partial<SecurityCodeSendRequest>): SecurityCodeSendRequest {
        const updateData: Partial<ISecurityCodeSendRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SecurityCodeSendRequest) => {
            const updateKey = this.keys[key] as keyof ISecurityCodeSendRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISecurityCodeSendRequest, keyof ISecurityCodeSendRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SecurityCodeSendRequest({ ...this.serialize(), ...updateData });
    }
}
