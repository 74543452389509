import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import s from './AddClient.module.pcss';

interface SubmitProps {
    id?: string;
    title?: string;
    disabled?: boolean;
    handleSubmit?: () => void;
}

const Submit: FC<SubmitProps> = ({ title, disabled, id, handleSubmit }) => {
    const intl = useIntl();

    return (
        <div className={s.actions}>
            <Button
                inGroup
                id={id || 'register_account'}
                type="primary"
                size="big"
                htmlType={handleSubmit ? 'button' : 'submit'}
                onClick={handleSubmit}
                disabled={disabled}
            >
                {title ?? intl.getMessage('create')}
            </Button>
            <Link to={RoutePath.Clients}>
                <Button
                    type="link"
                    className={cn(
                        theme.button.button,
                        theme.button.desktop_big,
                    )}
                >
                    {intl.getMessage('cancel')}
                </Button>
            </Link>
        </div>
    );
};

export default Submit;
