// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface INonResidentInfo {
    name: string;
}

export default class NonResidentInfo {
    readonly _name: string;

    /**
     * Description: Non resident corporate name
     * Example: Example Corp LLC
     */
    get name(): string {
        return this._name;
    }

    static nameValidate(name: string): boolean {
        return typeof name === 'string' && !!name.trim();
    }

    constructor(props: INonResidentInfo) {
        this._name = props.name.trim();
    }

    serialize(): INonResidentInfo {
        const data: INonResidentInfo = {
            name: this._name,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: typeof this._name === 'string' && !!this._name.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: INonResidentInfo): NonResidentInfo {
        return new NonResidentInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        name: 'name',
        }
;

    mergeDeepWith(props: Partial<NonResidentInfo>): NonResidentInfo {
        const updateData: Partial<INonResidentInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof NonResidentInfo) => {
            const updateKey = this.keys[key] as keyof INonResidentInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<INonResidentInfo, keyof INonResidentInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new NonResidentInfo({ ...this.serialize(), ...updateData });
    }
}
