import dayjs, { Dayjs, OpUnitType } from 'dayjs';
import Cookie from 'js-cookie';
import { ClipboardEvent } from 'react';

import { BackupPolicyFrequency } from 'Entities/BackupPolicyFrequency';
import { Translator } from 'Lib/intl';
import ApiError from 'Entities/Error';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import CountryPhoneCode from 'Entities/CountryPhoneCode';
import { NotificationPayload } from 'Entities/NotificationPayload';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import { IDailyExpense } from 'Entities/DailyExpense';
import { ILoadAverage } from 'Entities/LoadAverage';
import { IDiskFreeSpace } from 'Entities/DiskFreeSpace';
import { ICpuUsage } from 'Entities/CpuUsage';
import { IPing } from 'Entities/Ping';
import { IInodesUsage } from 'Entities/InodesUsage';
import { ITraffic } from 'Entities/Traffic';
import { IMinBalance } from 'Entities/MinBalance';
import { ServerState } from 'Entities/ServerState';
import { OperationType } from 'Entities/OperationType';
import { IHttp } from 'Entities/Http';
import { IServerCreate } from 'Entities/ServerCreate';
import { IServerMemoryChange } from 'Entities/ServerMemoryChange';
import { IServerDiskIncrease } from 'Entities/ServerDiskIncrease';
import { IPhoneInfo } from 'Entities/PhoneInfo';
import { IServerOutOfMemory } from 'Entities/ServerOutOfMemory';
import { DistributionFamily } from 'Entities/DistributionFamily';
import ServerResourceLimits from 'Entities/ServerResourceLimits';
import { EventNotificationLevel } from 'Entities/EventNotificationLevel';
import { ISslCert } from 'Entities/SslCert';
import { IDomainExpiration } from 'Entities/DomainExpiration';
import FormHelper from 'Lib/helpers/newServer';
import { IconType } from 'Common';
import theme from 'Lib/theme';

import {
    UNAUTHORIZED,
    COMPARE_TIME_FORMAT,
    LOCAL_STORAGE_COOKIE_NAME,
    SERVER_VIEW,
    BLOCK_SIZES,
    SERVER_SEARCH_MAX_HISTORY,
    REF_ID_COOKIE,
} from './consts';

type FrequencyMessage = Record<BackupPolicyFrequency, number>;
export const frequencyMessage: FrequencyMessage = {
    HOUR_3: 3,
    HOUR_6: 6,
    HOUR_12: 12,
    HOUR_24: 24,
    HOUR_72: 72,
};

export const secondsToHour = (seconds: number) => seconds / 3600;

export const emailValidate = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const passwordValidate = (password: string, minLength: number, easy = false): boolean => {
    const len = password.length >= minLength;
    const number = password.match(/([0-9])/g);
    const upper = password.match(/([A-Z])/g);
    const lower = password.match(/([a-z])/g);
    const special = easy || password.match(/([\?\-\!\.\(\)\~\[\]'`])/g);
    const onlyAvailable = easy || password.match(/^[0-9A-Za-z\?\-\!\.\(\)\~\[\]'`]+$/g);
    return Boolean(number && len && upper && lower && special && onlyAvailable);
};

export const nameValidate = (e: string) => {
    return /^[А-Яа-я]+(\-[А-Яа-я]+)*$/g.test(e) || /^[A-Za-z]+(\-[A-Za-z]+)*$/g.test(e);
};

const ip4Test = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
export const ipV4Validate = (e: string) => {
    return ip4Test.test(e);
};
const ipv6Test = /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/;
export const ipV6Validate = (e: string) => {
    return ipv6Test.test(e);
};

const hostnameTest = /^(([a-zA-Zа-яА-Я0-9]|[a-zA-Zа-яА-Я0-9][a-zA-Zа-яА-Я0-9\-]*[a-zA-Zа-яА-Я0-9])\.)*([A-Za-zА-Яа-я0-9]|[A-Za-zА-Яа-я0-9][A-Za-zА-Яа-я0-9\-]*[A-Za-zА-Яа-я0-9])$/;
export const hostValidate = (host: string) => {
    return hostnameTest.test(host);
};

// because backend does not want to meet our troubles
// we hack the ApiError;
interface ErrorCheck<T = any> {
    error?: ApiError;
    result?: T;
}
export function errorChecker<T = any>(response: Error | any): ErrorCheck<T> {
    if (typeof response !== 'object') {
        return { result: response };
    }
    if (response instanceof ApiError) {
        return { error: response };
    }
    if (response instanceof Error) {
        const message = JSON.parse(response.message);
        switch (message.status) {
            case '401':
                return { error: new ApiError({
                    error_code: UNAUTHORIZED,
                    fields: false,
                    message: message.xReqId,
                }) };
            default:
                return {
                    error: new ApiError({
                        error_code: ApiErrorCode.UNKNOWN,
                        fields: false,
                        message: message.xReqId,
                    }),
                };
        }
    }
    return { result: response };
}

type TriggerMessagesOptionalKeys = 'prev' | 'new' | 'name' | 'value' | 'min' | 'date' | 'resolved';

type TriggerMessages = (
    Record<TriggerRuleType, Partial<Record<TriggerMessagesOptionalKeys, number | string | boolean>>>
);
export const triggerValueMatcher = (type: TriggerRuleType, payload: NotificationPayload) => {
    const messages: TriggerMessages = {
        [TriggerRuleType.HTTPS_SSL_CERT]: {
            value: (payload as ISslCert).trigger_name,
            resolved: (payload as ISslCert).resolved,
            date: (payload as ISslCert).expiration_date,
        },
        [TriggerRuleType.DOMAIN_EXPIRATION]: {
            value: (payload as IDomainExpiration).domain,
            date: (payload as IDomainExpiration).expiration_date,
        },
        [TriggerRuleType.DAILY_EXPENSE]: { value: (payload as IDailyExpense).trigger_value },
        [TriggerRuleType.LOAD_AVERAGE]: {
            value: (payload as ILoadAverage).resolved
                ? (payload as ILoadAverage).use_value
                : (payload as ILoadAverage).trigger_value,
            min: (payload as ILoadAverage).trigger_period_minutes,
            resolved: (payload as ILoadAverage).resolved,
        },
        [TriggerRuleType.DISK_FREE_SPACE]: {
            value: (payload as IDiskFreeSpace).trigger_percent,
            resolved: (payload as IDiskFreeSpace).resolved,
        },
        [TriggerRuleType.CPU_USAGE]: {
            value: (payload as ICpuUsage).resolved
                ? (payload as ICpuUsage).use_percent
                : (payload as ICpuUsage).trigger_percent,
            min: (payload as ICpuUsage).trigger_period_minutes,
            resolved: (payload as ICpuUsage).resolved,
        },
        [TriggerRuleType.PING]: {
            resolved: (payload as IPing).resolved,
            value: (payload as IPing).ip_address,
        },
        [TriggerRuleType.INODES_USE]: {
            value: (payload as IInodesUsage).trigger_percent,
            resolved: (payload as IInodesUsage).resolved,
        },
        [TriggerRuleType.HTTP]: {
            value: (payload as IHttp).http_code,
            name: (payload as IHttp).trigger_name,
        },
        [TriggerRuleType.RECEIVED_TRAFFIC_USAGE]: {
            value: (payload as ITraffic).trigger_value_gib,
        },
        [TriggerRuleType.TRANSFERRED_TRAFFIC_USAGE]: {
            value: (payload as ITraffic).trigger_value_gib,
        },
        [TriggerRuleType.MIN_BALANCE]: {
            value: (payload as IMinBalance).trigger_value,
        },
        [TriggerRuleType.VM_CREATE]: {
            value: (payload as IServerCreate).ipv4_addresses ? (payload as IServerCreate).ipv4_addresses![0] : '',
            name: (payload as IServerCreate).username,
        },
        [TriggerRuleType.VM_REBOOT]: {},
        [TriggerRuleType.VM_START]: {},
        [TriggerRuleType.VM_REVERT_TO_SNAPSHOT]: {},
        [TriggerRuleType.VM_REVERT_TO_BACKUP]: {},
        [TriggerRuleType.VM_SHUTOFF]: {},
        [TriggerRuleType.VM_DELETE]: {},
        [TriggerRuleType.VM_MEMORY_CHANGE]: {
            prev: (payload as IServerMemoryChange).prev_memory_mib,
            new: (payload as IServerMemoryChange).new_memory_mib,
        },
        [TriggerRuleType.VM_DISK_INCREASE]: {
            value: (payload as IServerDiskIncrease).new_disk_gib,
        },
        [TriggerRuleType.VM_PASSWORD_CHANGE]: {},
        [TriggerRuleType.VM_MEMORY_DIFFERENT]: {},
        [TriggerRuleType.VM_OUT_OF_MEMORY]: {
            value: (payload as IServerOutOfMemory).processes_killed,
            name: (payload as IServerOutOfMemory).process_name,
        },
        [TriggerRuleType.VM_LINUX_KERNEL_EVENT]: {},
        [TriggerRuleType.VM_ACPI_MEMORY_HP_ERROR]: {},
        [TriggerRuleType.VM_REVERT_TO_BACKUP]: {},
        [TriggerRuleType.VM_REVERT_TO_SNAPSHOT]: {},
    };
    return messages[type];
};

export const getCookieToken = () => {
    return Cookie.get(LOCAL_STORAGE_COOKIE_NAME)
        || window.localStorage.getItem(LOCAL_STORAGE_COOKIE_NAME) || undefined;
};

export const saveCookieToken = (token: string) => {
    Cookie.set(LOCAL_STORAGE_COOKIE_NAME, token, { path: '/' });
    window.localStorage.setItem(LOCAL_STORAGE_COOKIE_NAME, token);
};

export const getRefId = () => {
    return Cookie.get(REF_ID_COOKIE);
};
export const saveRefId = (refId: string, del?: boolean) => {
    const date = new Date(Date.now() + (1 * 86400e3));
    let domain = window.location.host;
    if (domain.includes(':')) {
        [domain] = domain.split(':');
    }
    const splitedDomain = domain.split('.');
    if (splitedDomain.length > 2) {
        domain = splitedDomain.splice(-2).join('.');
    }
    if (del) {
        Cookie.remove(REF_ID_COOKIE, { domain, expires: date, path: '/' });
    } else {
        Cookie.set(REF_ID_COOKIE, refId, { domain, expires: date, path: '/' });
    }
};

type EntityWithKey<T extends string> = Record<T, number> & Record<string, any>;
export const normalizedByTime = <T extends string, Return extends EntityWithKey<T>>(
    timeKey: T, data: Return[], intl: Translator,
) => {
    const dates = data.map(
        (attempt) => dayjs(attempt[timeKey]).format(COMPARE_TIME_FORMAT),
    );
    const uniqueDates = [...new Set(dates)];
    return uniqueDates
        .map((date) => {
            const list: Return[] = data.filter((item) => {
                const comparer = dayjs(item[timeKey])
                    .format(COMPARE_TIME_FORMAT);
                return comparer === date;
            });
            const day = dayjs(date, COMPARE_TIME_FORMAT);
            const formattedDate = day.format(intl.getMessage('time_format_day'));

            return {
                id: date,
                title: formattedDate,
                list,
            };
        });
};

export const getServerIcon = (distribution: DistributionFamily) => {
    const icon: Partial<Record<DistributionFamily, IconType>> = {
        [DistributionFamily.DEBIAN]: 'os_debian',
        [DistributionFamily.UBUNTU]: 'os_ubuntu',
        [DistributionFamily.SUSE]: 'os_suse',
        [DistributionFamily.CENTOS]: 'os_centos',
        [DistributionFamily.FEDORA]: 'os_fedora',
        [DistributionFamily.WINDOWS]: 'os_windows',
        [DistributionFamily.ROCKY]: 'os_rocky',
    };
    return icon[distribution] as IconType;
};

export const getFullPhoneNumber = (callingCode: string, phoneNumber: string) => {
    return `${callingCode}${phoneNumber}`.replace(/[^0-9]/g, '');
};

const UNNEED_CHARS = /(\+|\s|\(|\)|\-)*/g;
export const onPhonePaste = (
    setValue: (v: IPhoneInfo, c: CountryPhoneCode) => void,
    codes: CountryPhoneCode[],
    defaultCode: CountryPhoneCode,
) => (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const regExpCodes = codes.map((c) => {
        const pattern = c.pattern!.replace(UNNEED_CHARS, '').replace(/\_/g, '\\d');
        return { country: c, regExp: new RegExp(`^${c.callingCode}${pattern}$`, 'g') };
    });

    const initialPhone = e.clipboardData.getData('text/plain');
    let pastedPhone = initialPhone.replace(UNNEED_CHARS, '');
    let probableCode = regExpCodes.filter((r) => r.regExp.test(pastedPhone));

    // Bike solution for Russian 8;
    if (probableCode.length === 0 && initialPhone.startsWith('8')) {
        pastedPhone = pastedPhone.replace(/^8/g, '7');
        probableCode = regExpCodes.filter((r) => r.regExp.test(pastedPhone));
    }

    if (probableCode.length > 0) {
        const isDefault = probableCode.find(
            (p) => p.country.countryName === defaultCode.countryName,
        );
        if (isDefault) {
            const { callingCode } = defaultCode;
            pastedPhone = pastedPhone.replace(new RegExp(`^${callingCode}`), '');
            setValue({
                calling_code: callingCode!,
                full_number: getFullPhoneNumber(callingCode!, pastedPhone),
            }, defaultCode);
        } else {
            const founded = probableCode[0].country;
            const { callingCode } = founded;
            pastedPhone = pastedPhone.replace(new RegExp(`^${callingCode}`), '');
            setValue({
                calling_code: callingCode!,
                full_number: getFullPhoneNumber(callingCode!, pastedPhone),
            }, founded);
        }
    }
};

export const getGb = (memory: number) => {
    return Math.round((memory / 1024) * 100) / 100;
};

export const getServerViewIcon = (type: SERVER_VIEW) => {
    const icon: Record<SERVER_VIEW, IconType> = {
        [SERVER_VIEW.LIST]: 'view_list',
        [SERVER_VIEW.COMPACT]: 'view_compact',
        [SERVER_VIEW.EXPAND]: 'view_expand',
    };
    return icon[type];
};

export const countServerNumber = (serverView: SERVER_VIEW, height: number) => {
    return Math.round(height / BLOCK_SIZES[serverView]) + 1;
};

export const orderServerState = (
    state: ServerState,
    operationType?: OperationType,
): number => {
    const states = {
        [ServerState.RUNNING]: 10,
        [ServerState.PAUSED]: 9,
        [ServerState.REBOOT]: 8,
        [ServerState.PENDING_INSTALL]: 7,
        [ServerState.INSTALLING]: 6,
        [ServerState.SHUTDOWN]: 4,
        [ServerState.SHUTOFF]: 3,
        [ServerState.BLOCKED]: 2,
        [ServerState.CRASHED]: 1,
        [ServerState.NO_STATE]: 0,
    };
    if (!operationType) {
        return states[state];
    }
    return 11;
};

interface LimitedServerOptions {
    cpu_cores: number;
    memory_mib: number;
    disk_gib: number;
    ip_count: number;
}
export const countLimits = (limits: ServerResourceLimits, values: LimitedServerOptions) => {
    const limited = { ...values };
    if (!limits) {
        return limited;
    }
    const { minCpuCores, minDiskGib, minIpCount, minMemoryMib } = limits;
    const { maxCpuCores, maxDiskGib, maxIpCount, maxMemoryMib, stepMemoryMib } = limits;
    if (limited.memory_mib % stepMemoryMib !== 0) {
        const remainder = limited.memory_mib % stepMemoryMib;
        const value = limited.memory_mib;
        limited.memory_mib = remainder > stepMemoryMib / 2
            ? value + stepMemoryMib - remainder : value - remainder;
    }

    const maxCountedDisk = FormHelper.countMaxDisk(
        limited.memory_mib,
        limits.memoryDiskRate,
        maxDiskGib,
        minDiskGib,
    );

    if (limited.cpu_cores < minCpuCores) {
        limited.cpu_cores = minCpuCores;
    }
    if (limited.disk_gib < minDiskGib) {
        limited.disk_gib = minDiskGib;
    }
    if (limited.ip_count < minIpCount) {
        limited.ip_count = minIpCount;
    }
    if (limited.memory_mib < minMemoryMib) {
        limited.memory_mib = minMemoryMib;
    }
    if (limited.cpu_cores > maxCpuCores) {
        limited.cpu_cores = maxCpuCores;
    }
    if (limited.disk_gib > maxCountedDisk) {
        limited.disk_gib = maxCountedDisk;
    }
    if (limited.ip_count > maxIpCount) {
        limited.ip_count = maxIpCount;
    }
    if (limited.memory_mib > maxMemoryMib) {
        limited.memory_mib = maxMemoryMib;
    }

    return limited;
};

export const setServerSearch = (id: number) => {
    const localSearch = UserStorage.getServerLastSearch();
    if (localSearch) {
        const localSearchList = JSON.parse(localSearch) as number[];
        if (!localSearchList.includes(id)) {
            localSearchList.unshift(id);
            if (localSearchList.length > SERVER_SEARCH_MAX_HISTORY) {
                localSearchList.pop();
            }
        } else {
            localSearchList.splice(localSearchList.indexOf(id), 1);
            localSearchList.unshift(id);
        }
        UserStorage.setServerLastSearch(localSearchList);
    } else {
        UserStorage.setServerLastSearch([id]);
    }
};

export const deleteServerSearch = (id: number) => {
    const localSearch = UserStorage.getServerLastSearch();
    if (localSearch) {
        const localSearchList = new Set(JSON.parse(localSearch) as number[]);
        localSearchList.delete(id);
        UserStorage.setServerLastSearch(Array.from(localSearchList.values()));
    } else {
        UserStorage.setServerLastSearch([]);
    }
};

export const getServerSearch = () => {
    const localSearch = UserStorage.getServerLastSearch();
    if (localSearch) {
        return (JSON.parse(localSearch) as number[]);
    }
    return [];
};

interface VisitsCount {
    times: number;
    firstHistory: number;
    weekTwo: boolean;
}
export const setVisitsCount = (weekTwo?: boolean) => {
    const history = UserStorage.getRateUs();
    if (history) {
        const visits: VisitsCount = JSON.parse(history);
        visits.times += 1;
        if (typeof weekTwo === 'boolean') {
            visits.weekTwo = weekTwo;
        }
        UserStorage.setRateUs(JSON.stringify(visits));
    } else {
        const visits: VisitsCount = { times: 1, firstHistory: Date.now(), weekTwo: false };
        UserStorage.setRateUs(JSON.stringify(visits));
    }
};

export const getVisitsCount = () => {
    const history = UserStorage.getRateUs();
    if (history) {
        const visits: VisitsCount = JSON.parse(history);
        return visits;
    }
    return { times: 0, firstHistory: Date.now(), weekTwo: false } as VisitsCount;
};

export const getNotificationIndicatorColor = (level: EventNotificationLevel) => {
    const color: Record<EventNotificationLevel, string> = {
        [EventNotificationLevel.OK]: theme.card.indicator_green,
        [EventNotificationLevel.INFO]: theme.card.indicator_morning,
        [EventNotificationLevel.WARN]: theme.card.indicator_yellow,
        [EventNotificationLevel.ALERT]: theme.card.indicator_red,
    };
    return color[level];
};

export class Range {
    private readonly _from: Dayjs;

    private readonly _to: Dayjs;

    constructor(from: Dayjs, to: Dayjs) {
        if (from > to) {
            throw new Error('invalid range given');
        }
        this._from = from;
        this._to = to;
    }

    get from() {
        return this._from;
    }

    get to() {
        return this._to;
    }

    withTo(to: Dayjs): Range {
        return new Range(this.from, to);
    }

    withFrom(from: Dayjs): Range {
        return new Range(from, this.to);
    }

    update({ from, to }: { from?: Dayjs; to?: Dayjs }) {
        return new Range(from || this.from, to || this.to);
    }

    toDiff(t: OpUnitType) {
        return this.to.diff(this.from, t);
    }

    areBoundsValid({ from, to }: { from: Dayjs; to: Dayjs }) {
        return from < to
            ? new Range(this.from, this.to)
            : new Range(this.to, this.from);
    }
}

/* TODO make support for all country codes */
export const formatPhoneNumber = (phoneNumber: string) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        return `+${match[1]} ${match[2]} ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phoneNumber;
};

export const getAvailableAmountBages = (balance: number, minAmount?: number) => {
    if (balance === 0) {
        return [];
    }

    const values = [Math.floor(balance)];

    [minAmount || 600, 1000, 2000].forEach((a) => {
        if (balance > a) {
            values.push(a);
        }
    });
    return values;
};
