import React, { FC, useState, ChangeEvent, ClipboardEvent } from 'react';
import cn from 'classnames';
import { createSelector } from 'reselect';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import qs from 'qs';

import { useTitle } from 'Hooks';
import { Store } from 'Store';
import { createTenant, createTenantWithServerUpdate } from 'Actions/tenant';
import { useIntl, Link, FormPageLayout, Button, notifySuccess } from 'Common';
import TenantCreate from 'Entities/TenantCreate';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';

import s from './NewProject.module.pcss';

const selectStoreServers = (store: Store) => store.server;

const selector = createSelector(
    [selectStoreServers],
    (servers) => ({ servers }),
);

const NewProject: FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [name, setName] = useState('');
    const [valid, setValid] = useState(true);
    const intl = useIntl();
    useTitle(intl.getMessage('new_project_page_title'));
    const { currentLocale } = intl;
    const { params: { back } } = useRouteMatch<{ back: string | undefined }>();

    const { servers } = useSelector(selector);

    const getQueryParams = () => {
        const query = window.location.search.replace('?', '');
        return qs.parse(query);
    };

    const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= TenantCreate.descriptionMaxLength) {
            setName(e.target.value);
        }
        if (!valid) {
            setValid(true);
        }
    };

    const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const text = name + e.clipboardData.getData('text/plain');
        setName(text.slice(0, TenantCreate.descriptionMaxLength));
    };
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const entity = new TenantCreate({ description: name });
        if (entity.validate().length > 0) {
            setValid(false);
            return;
        }

        const queryParams = getQueryParams();

        if (back && queryParams.serverId) {
            const server = servers.get(Number(queryParams.serverId))!;
            dispatch(createTenantWithServerUpdate({
                server,
                goToServer: (tenantId) => {
                    notifySuccess(intl.getMessage('new_project_created'));
                    history.push(
                        linkPathBuilder(
                            intl.currentLocale,
                            RoutePath.Server,
                            { serverId: server.id, tenantId },
                        ),
                    );
                },
                newTenant: [entity.serialize()],
            }));
        } else if (back && !queryParams.serverId) {
            dispatch(createTenant([entity.serialize()], {
                result: (newTenant) => {
                    notifySuccess(intl.getMessage('new_project_created'));
                    history.push(
                        linkPathBuilder(
                            intl.currentLocale,
                            RoutePath.NewServerFromTenant,
                            { tenantId: newTenant.id },
                        ),
                    );
                },
            }));
        } else {
            dispatch(createTenant([entity.serialize()], {
                result: () => {
                    notifySuccess(intl.getMessage('new_project_created'));
                    history.push(linkPathBuilder(currentLocale, RoutePath.ProjectsList));
                },
            }));
        }
    };
    return (
        <FormPageLayout
            title={intl.getMessage('projectlist_create')}
            type="narrow"
            back={RoutePath.ProjectsList}
        >
            <form onSubmit={handleSubmit}>
                <div className={s.desc}>
                    {intl.getMessage('projectlist_create_desc')}
                </div>
                <Input
                    required
                    type="text"
                    name="name"
                    size="large"
                    className={cn('input input_big', { input_error: !valid })}
                    placeholder={intl.getPlural('projectlist_name_symbols', TenantCreate.descriptionMaxLength)}
                    value={name}
                    onChange={onNameChange}
                    onPaste={onPaste}
                    autoFocus
                />
                <div className={s.footer}>
                    <Button
                        htmlType="submit"
                        type="primary"
                        size="big"
                        disabled={!valid || !name}
                        inGroup
                    >
                        {intl.getMessage('projectlist_create')}
                    </Button>

                    <Link to={RoutePath.ProjectsList}>
                        <Button
                            type="link"
                            size="big"
                        >
                            {intl.getMessage('cancel')}
                        </Button>
                    </Link>
                </div>
            </form>
        </FormPageLayout>
    );
};

export default NewProject;
