import React, { FC } from 'react';
import { Progress } from 'antd';
import { useIntl } from 'Common';
import SnapshotLayout from '../SnapshotLayout';
import s from './Restore.module.pcss';

interface RestoreProps {
    percent: number;
}

const Restore: FC<RestoreProps> = ({ percent }) => {
    const intl = useIntl();
    return (
        <SnapshotLayout>
            <div className={s.restore}>
                <div className={s.title}>
                    {intl.getMessage('snapshots_restore')}
                </div>
                <Progress
                    size="small"
                    percent={percent}
                    status="active"
                    showInfo={false}
                    className={s.progress}
                />
            </div>
        </SnapshotLayout>
    );
};

export default Restore;
