import React, { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { getPartnerStats, getPartnerComparativeDetails } from 'Actions/partner';
import { useIntl, Icon, Link, AreaChart, DropdownRangePicker, DetailedComparativeValues, zoomInitialValues } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import PartnerDetails from 'Entities/PartnerDetails';
import PartnerComparativeDetails from 'Entities/PartnerComparativeDetails';
import { AffiliatePayoutType } from 'Entities/AffiliatePayoutType';
import { Range } from 'Lib/helpers/utils';
import { ADJUST_NUMBER, REQUEST_DATE_FORMAT, RESOURCES_TYPES, DATE_RANGE_TYPES, BONUS_AMOUNT_PERCENT, Zoom } from 'Lib/helpers/consts';
import { getPriceValue, getCssColors } from 'Lib/helpers/helpers';
import { getRangeLabel } from 'Lib/helpers/translationHelper';
import { Store } from 'Store';
import RefLink from './RefLink';

import Stats from './Stats';
import s from './Info.module.pcss';

interface InfoProps {
    details: PartnerDetails;
    comparativeDetails: PartnerComparativeDetails;
}

const mediumTexts = (text: string) => (
    <div className={s.mediumText}>
        {text}
    </div>
);

enum STATISTIC_TABS {
    GENERAL_INCOME = 'GENERAL_INCOME',
    REFERRAL_AVG_INCOME = 'REFERRAL_AVG_INCOME'
}

const selectPartnerStats = (store: Store) => store.partner.stats;
const selectAccount = (store: Store) => store.account.account;

const selector = createSelector([
    selectPartnerStats,
    selectAccount,
], (stats, account) => ({ stats, account }));

const Info: FC<InfoProps> = ({ details, comparativeDetails }) => {
    const { stats, account } = useSelector(selector);
    const intl = useIntl();
    const today = dayjs().startOf('day');
    const [currentStatTab, setCurrentStatisticTab] = useState<string>(STATISTIC_TABS.GENERAL_INCOME);
    const ranges = new Range(today.add(-1, 'month'), today);
    const [range, setRange] = useState(ranges);
    const [zoom, setZoom] = useState(zoomInitialValues);
    const [rangeName, setRangeName] = useState<DATE_RANGE_TYPES | null>(DATE_RANGE_TYPES.MONTH);
    const dispatch = useDispatch();

    const chartColors = getCssColors();

    const getStats = () => {
        const { from: fday, to: tday } = range;
        dispatch(getPartnerStats([
            fday.format(REQUEST_DATE_FORMAT),
            tday.format(REQUEST_DATE_FORMAT),
        ]));
    };

    const getComparativeDetails = () => {
        const { from: fday, to: tday } = range;
        dispatch(getPartnerComparativeDetails([
            fday.format(REQUEST_DATE_FORMAT),
            tday.format(REQUEST_DATE_FORMAT),
        ]));
    };

    useEffect(() => {
        getStats();
        getComparativeDetails();
    }, [range]);

    const {
        incomeOverPeriod,
        referralsAverageIncome,
        uniqueHits,
        registrations,
    } = comparativeDetails;

    const {
        partnerLink,
    } = details;

    return (
        <>
            <div
                className={cn(
                    theme.card.card,
                    theme.card.rounded,
                    s.infoWrapper,
                    s.card,
                )}
            >
                <div className={theme.score.wrapper}>
                    <div className={theme.score.item}>
                        <Icon
                            icon="coins"
                            className={cn(
                                theme.score.icon,
                                theme.color.blue,
                            )}
                        />
                        <div className={theme.score.info}>
                            <div className={theme.score.label}>
                                {intl.getMessage('partners_affiliate_account_score')}
                            </div>
                            <div className={theme.score.value}>
                                {intl.getMessage('price_simple', { value: Math.round(details.balance) })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.infoLinkWrapper}>
                    <Link
                        to={RoutePath.PartnerTransferPayment}
                        className={cn(s.infoTransferLink, s.infoTransferLink_blue)}
                    >
                        <Icon
                            icon="arrow_circle"
                            className={s.icon}
                        />
                        <div className={s.infoTransferButton}>
                            <span className={s.desktop}>
                                {intl.getMessage('info_partners_transfer_to_main_account', { b: mediumTexts, value: BONUS_AMOUNT_PERCENT })}
                            </span>
                            <span className={s.mobile}>
                                {intl.getMessage('partners_transfer_to_main_account')}
                            </span>
                        </div>
                    </Link>
                    <Link
                        to={RoutePath.PartnerOrderPayment}
                        className={cn(s.infoTransferLink, s.infoTransferLink_gray)}
                    >
                        <Icon
                            icon="coin_fall"
                            className={s.icon}
                        />
                        <div className={s.infoTransferButton}>
                            <span className={s.desktop}>
                                {details.paymentType === AffiliatePayoutType.SOLAR_STAFF ? (
                                    intl.getMessage('partners_order_payment_solar_staff', { b: mediumTexts })
                                ) : (
                                    intl.getMessage('partners_order_payment_with_info', { b: mediumTexts })
                                )}
                            </span>
                            <span className={s.mobile}>
                                {intl.getMessage('partners_order_payment')}
                            </span>
                        </div>
                    </Link>
                </div>
                <RefLink link={partnerLink} />
            </div>
            <div className={s.statisticsFilter}>
                <div className={s.title}>
                    {intl.getMessage('partners_stats')}
                </div>
                <div>
                    <DropdownRangePicker
                        mode="month"
                        range={range}
                        setDateRange={setRange}
                        rangeName={rangeName ? getRangeLabel(intl, rangeName) : undefined}
                        setRangeName={setRangeName}
                        minDate={dayjs(account?.timeAddedMillis)}
                        globalZoom={zoom}
                        borderless
                        onlyRanges
                    />
                </div>
            </div>
            <div
                className={cn(
                    theme.card.card,
                    theme.card.rounded,
                    s.infoWrapper,
                    s.card,
                    s.statisticsWrapper,
                )}
            >
                <div className={s.statisticsHeader}>
                    <button
                        type="button"
                        className={cn(
                            s.statisticsHeaderItem,
                            { [s.statisticsHeaderItem_active]:
                                currentStatTab === 'GENERAL_INCOME' },
                        )}
                        onClick={() => setCurrentStatisticTab(STATISTIC_TABS.GENERAL_INCOME)}
                    >
                        <div className={s.buttonInnerWrapper}>
                            <div className={s.header}>
                                {intl.getMessage('partners_stats_total_income')}
                            </div>
                            <div className={s.value}>
                                {getPriceValue(incomeOverPeriod.recentValue, intl)}
                            </div>
                            {typeof incomeOverPeriod.valueChangePercent === 'number' && incomeOverPeriod.valueChangePercent > 0 && (
                                <div className={s.diff}>
                                    <DetailedComparativeValues
                                        diff={incomeOverPeriod.valueChangePercent}
                                        recentValue={incomeOverPeriod.recentValue}
                                        recentPeriod={incomeOverPeriod.recentPeriod}
                                        previousValue={incomeOverPeriod.previousValue}
                                        previousPeriod={incomeOverPeriod.previousPeriod}
                                        increasingLabel={intl.getMessage('partner_total_income_increased')}
                                        decreasingLabel={intl.getMessage('partner_total_income_decreased')}
                                        withCurrency
                                    />
                                </div>
                            )}
                        </div>
                    </button>
                    <button
                        type="button"
                        className={cn(s.statisticsHeaderItem,
                            { [s.statisticsHeaderItem_active]: currentStatTab === 'REFERRAL_AVG_INCOME' })}
                        onClick={() => setCurrentStatisticTab(STATISTIC_TABS.REFERRAL_AVG_INCOME)}
                    >
                        <div className={s.buttonInnerWrapper}>
                            <div className={s.header}>
                                {intl.getMessage('partners_stats_referrals_average_income')}
                            </div>
                            <div className={s.value}>
                                {intl.getMessage('price_simple', { value: Math.round(referralsAverageIncome.recentValue) })}
                            </div>
                            {typeof referralsAverageIncome.valueChangePercent === 'number' && referralsAverageIncome.valueChangePercent > 0 && (
                                <div className={s.diff}>
                                    <DetailedComparativeValues
                                        diff={referralsAverageIncome.valueChangePercent}
                                        recentValue={referralsAverageIncome.recentValue}
                                        recentPeriod={referralsAverageIncome.recentPeriod}
                                        previousValue={referralsAverageIncome.previousValue}
                                        previousPeriod={referralsAverageIncome.previousPeriod}
                                        increasingLabel={intl.getMessage('partner_average_income_increased')}
                                        decreasingLabel={intl.getMessage('partner_average_income_decreased')}
                                        withCurrency
                                    />
                                </div>
                            )}
                        </div>
                    </button>
                </div>
                <div className={s.statisticsContent}>
                    {stats && (
                        <AreaChart
                            xAxisKey="reportDateMillis"
                            data={stats}
                            type={RESOURCES_TYPES.EXPENSES}
                            lines={[{
                                id: currentStatTab === 'GENERAL_INCOME'
                                    ? 'amount'
                                    : 'referralAvgAmount',
                                color: chartColors.blue,
                                label: intl.getMessage('partners_stats_amount'),
                            }]}
                            unit=""
                            drawXAxis
                            drawYAxis
                            adjust={ADJUST_NUMBER.ZERO}
                            initialRange={range}
                            setRange={setRange}
                            globalZoom={zoom}
                            setGlobalZoom={(v: Zoom) => {
                                setZoom(v);
                                setRangeName(null);
                            }}
                        />
                    )}
                </div>
            </div>
            <Stats
                details={details}
                uniqueHits={uniqueHits}
                registrations={registrations}
            />
        </>
    );
};

export default Info;
