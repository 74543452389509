import React, { FC, useEffect, FormEvent, useRef, useMemo } from 'react';
import { notification } from 'antd';
import cn from 'classnames';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter, RouteComponentProps, useLocation, useRouteMatch } from 'react-router-dom';
import { createSelector } from 'reselect';
import qs from 'qs';

import { getDistributionsList } from 'Actions/distribution';
import { install, reinstall, ServerActions } from 'Actions/server';
import { useIntl, InnerPageLayout, Warning, notifyError, Button, whiteText, Loader, BackHeader } from 'Common';
import Error from 'Entities/Error';
import { SecurityActionType } from 'Entities/SecurityActionType';
import Server from 'Entities/Server';
import ServerCostForecastRequest from 'Entities/ServerCostForecastRequest';
import ServerDistribution from 'Entities/ServerDistribution';
import ServerReinstall from 'Entities/ServerReinstall';
import ServerSetup from 'Entities/ServerSetup';
import Tenant from 'Entities/Tenant';
import { DistributionFamily } from 'Entities/DistributionFamily';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { IOperationProgress } from 'Entities/OperationProgress';
import { EMPTY_FIELD_ERROR, IServerStorage, SERVER_QUERY_PARAMS } from 'Lib/helpers/consts';
import NewServerFormHelper, { NewServerFormValues } from 'Lib/helpers/newServer';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { RoutePath, linkPathBuilder, Routes } from 'Lib/helpers/routes';
import { passwordValidate } from 'Lib/helpers/utils';
import { useSecurityActions, useTitle } from 'Lib/hooks/hooks';
import theme from 'Lib/theme';
import { calculateCostForecastForDraft, calculateCostForecastForServer, getResourseLimits } from 'Actions/newServer';
import { addError } from 'Actions/error';

import { Store } from 'Store';

import {
    Distribution,
    Tariff,
    Name,
    Password,
    Backup,
    PublicKeys,
    FormGroup,
    TenantSelect,
    Result,
} from './components';

import styles from './NewServer.module.pcss';

interface NewServerStoreProps {
    tenants: Map<number, Tenant>;
    distributions: ServerDistribution[];
    server: Server | undefined;
    payers?: Store['profile']['profilePayer'];
    details?: Store['account']['details'];
    trialBonusAvailable?: boolean;
    trialActive?: boolean;
}
type NewServerOwnProps = RouteComponentProps<{
    serverId?: string;
    tenantId?: string;
}>;

type NewServerProps = NewServerStoreProps & NewServerOwnProps;
type NewServerFormProps = FormikProps<NewServerFormValues> & NewServerStoreProps;

type NewServerFormType = (
    tenantsArray: Tenant[],
    isReinstall?: boolean,
    id?: number,
) => FC<NewServerFormProps>;

const selectPrice = (store: Store) => store.newServer.price;
const selectResLimits = (store: Store) => store.newServer.resourseLimits;
const selectDistribution = (store: Store) => store.serverDistribution;
const newServerSelector = createSelector(
    [selectPrice, selectResLimits, selectDistribution],
    (price, resLimits, distributions) => ({
        price, resLimits, distributions: Array.from(distributions.values()),
    }),
);

const checkValid = (value: number, min: number, max: number) => {
    if (value < min || value > max) {
        return false;
    }

    return true;
};

const newServerForm: NewServerFormType = (tenantsArray, isReinstall, id) => ({
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    submitForm,
    setFieldError,
    setValues,
    errors,
}) => {
    const intl = useIntl();
    useTitle(intl.getMessage('new_server_page_title'));

    const toPrevState = useRef(() => {
        notification.close('prevServerValues');
        setValues(values.prevValues!);
    });

    useEffect(() => {
        toPrevState.current = () => {
            notification.close('prevServerValues');
            setValues(values.prevValues!);
        };
    }, [values.prevValues]);

    useEffect(() => {
        if (values.prevValues) {
            notifyError(intl.getMessage('new_server_used_previous_setup'), undefined, {
                key: 'prevServerValues',
                duration: 0,
                btn: (
                    <Button
                        type="link"
                        className={cn(theme.link.link, theme.link.white)}
                        onClick={() => {
                            toPrevState.current();
                        }}
                    >
                        {intl.getMessage('invoice_cancel')}
                    </Button>
                ),
            });
        }

        return () => {
            notification.close('prevServerValues');
        };
    }, []);

    const { price, resLimits, distributions } = useSelector(newServerSelector);
    const dispatch = useDispatch();
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityActions(SecurityActionType.SERVER_REINSTALL);

    useEffect(() => {
        if (distributions.length === 0
            || !distributions.find((d) => d.id === values.distribution_id)
        ) {
            return;
        }
        dispatch(getResourseLimits([values.tenant_id, values.distribution_id]));
    }, [values.tenant_id, values.distribution_id]);

    const onLoadPriceError = () => {
        notifyError(intl.getMessage('can_not_load_server_price'), 'can_not_load_server_price');
    };

    useEffect(() => {
        if (
            !resLimits
            || distributions.length === 0
            || !distributions.find((d) => d.id === values.distribution_id)
        ) {
            return;
        }

        if (
            !checkValid(values.memory_mib, resLimits.minMemoryMib, resLimits.maxMemoryMib)
            || !checkValid(values.disk_gib, resLimits.minDiskGib, resLimits.maxDiskGib)
            || !checkValid(values.ip_count, resLimits.minIpCount, resLimits.maxIpCount)
            || !checkValid(values.cpu_cores, resLimits.minCpuCores, resLimits.maxCpuCores)
        ) {
            return;
        }

        const reqEnt = new ServerCostForecastRequest({ ...values });
        if (values.tenant_id !== -1 && reqEnt.validate().length === 0) {
            const reqValues = reqEnt.serialize();
            if (reqValues.tariff_id === 0 || reqValues.tariff_id === -1) {
                delete reqValues.tariff_id;
            }
            // if reinstall
            if (id) {
                dispatch(calculateCostForecastForServer([values.tenant_id, id, reqValues], {
                    error: onLoadPriceError,
                }));
            } else {
                dispatch(calculateCostForecastForDraft([values.tenant_id, reqValues], {
                    error: onLoadPriceError,
                }));
            }
        } else {
            onLoadPriceError();
        }
    }, [
        values.backup_frequency,
        values.backup_quantity,
        values.cpu_cores,
        values.disk_gib,
        values.ip_count,
        values.memory_mib,
        values.tariff_id,
        values.tenant_id,
        resLimits,
    ]);

    const handleOk = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!shouldConfirmAction || !isReinstall) {
            submitForm();
            return;
        }
        if (shouldConfirmAction) {
            if (codeSent) {
                if (!values.code) {
                    setFieldError('code', EMPTY_FIELD_ERROR);
                    return;
                }
                submitForm();
            } else {
                sendConfirmationCode({
                    tenantId: values.tenant_id,
                    serverId: id,
                });
            }
        }
    };

    const getBackupPrice = () => {
        if (!price) {
            return undefined;
        }

        if (values.monthly) {
            return intl.getPlural('cost_month_full', price.backupCost);
        }

        return intl.getPlural('cost_day_full', price.dailyBackupCost);
    };

    const getTariffPrice = () => {
        if (!price) {
            return undefined;
        }

        if (values.monthly) {
            return intl.getPlural('cost_month_full', price.overallCost - price.backupCost);
        }

        const dailyCost = Math.round((price.dailyOverallCost - price.dailyBackupCost) * 100) / 100;
        return intl.getPlural('cost_day_full', dailyCost);
    };

    return (
        <form
            onSubmit={handleOk}
            className={styles.form}
            data-server-id={id}
            noValidate
        >
            <div className={styles.column}>
                <FormGroup
                    title={intl.getMessage('new_server_name_title')}
                >
                    <Name
                        name={values.name}
                        setFieldValue={setFieldValue}
                        setSubmitting={setSubmitting}
                        errors={errors}
                    />
                    {isReinstall ? (
                        <Warning text={intl.getMessage('warning_reinstall')} />
                    ) : (
                        <TenantSelect
                            tenants={tenantsArray}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    )}
                </FormGroup>

                <FormGroup
                    title={intl.getMessage('new_server_config_title')}
                >
                    <Distribution
                        setFieldValue={setFieldValue}
                        values={values}
                    />
                </FormGroup>

                <FormGroup
                    title={intl.getMessage('tariff')}
                    price={getTariffPrice()}
                >
                    <Tariff
                        values={values}
                        setValues={setValues}
                        setFieldValue={setFieldValue}
                        resLimits={resLimits}
                    />
                </FormGroup>

                <div className={styles.additional}>
                    {intl.getMessage('new_server_additional')}
                </div>

                <FormGroup
                    title={intl.getMessage('backup')}
                    price={getBackupPrice()}
                    backup
                >
                    <Backup
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                </FormGroup>

                <FormGroup
                    title={intl.getMessage('new_server_access')}
                >
                    <Password
                        password={values.password || ''}
                        sendPassword={values.send_password}
                        openSupportAccess={values.open_support_access}
                        setFieldValue={setFieldValue}
                        setSubmitting={setSubmitting}
                        errors={errors}
                        values={values}
                    />
                </FormGroup>

                {values.distribution_name !== DistributionFamily.WINDOWS && (
                    <PublicKeys
                        values={values}
                        setFieldValue={setFieldValue}
                    />
                )}
            </div>
            <div className={styles.column}>
                <Result
                    id={id}
                    errors={errors}
                    values={values}
                    price={price}
                    codeSent={codeSent}
                    deliveryMessage={deliveryMessage}
                    isSubmitting={isSubmitting}
                    isReinstall={!!isReinstall}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    sendConfirmationCode={sendConfirmationCode}
                />
            </div>
        </form>
    );
};

const NewServer: FC<NewServerProps> = ({
    tenants,
    server,
    distributions,
    match,
    payers,
    details,
    trialBonusAvailable,
    trialActive,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const routeMatch = useRouteMatch();
    const { currentLocale } = useIntl();
    const intl = useIntl();

    const tenantsArray = useMemo(() => Array.from(tenants.values()), [tenants]);

    useEffect(() => {
        window.scrollTo({ top: 0 });
        if (distributions.length === 0) {
            dispatch(getDistributionsList());
        }
    }, []);

    const { params: { tenantId }, path } = match;
    const { search } = location;

    const isReinstall = server && path === Routes.ServerReinstall;
    const isClone = server && path === Routes.ServerClone;

    if (!details) {
        return null;
    }

    const { accountLimits } = details;

    if (tenantsArray.length === 0 || distributions.length === 0) {
        return <Loader full />;
    }

    const addFundsLink = [
        <Button
            type="link"
            key="notifyLink"
            className={cn(theme.link.link, theme.link.white)}
            onClick={() => history.push(
                linkPathBuilder(
                    intl.currentLocale,
                    payers?.length === 0 ? RoutePath.Onboarding : RoutePath.AddFunds,
                ),
            )}
        >
            {payers?.length === 0 ? intl.getMessage('add_requisites_funds') : intl.getMessage('add_funds')}
        </Button>,
    ];

    const notifyVMLimit = () => notifyError(
        apiErrorCodeTranslate(intl, ApiErrorCode.VM_LIMIT_EXCEED, {
            value: accountLimits.maxCurrentServersCount,
            value_second: accountLimits.maxOverallServersCount,
            white: whiteText,
        }),
        ApiErrorCode.VM_LIMIT_EXCEED,
        {
            btn: addFundsLink,
            duration: 0,
        },
    );

    const notifyMemoryLimit = () => {
        const message = payers?.length === 0 ? intl.getMessage('error_code_vm_memory_limit_exceed_and_requisites', {
            value: accountLimits.maxOverallMemoryMib,
            white: whiteText,
        }) : apiErrorCodeTranslate(intl, ApiErrorCode.VM_MEMORY_LIMIT_EXCEED, {
            value: accountLimits.maxCurrentServersCount,
            value_second: accountLimits.maxOverallServersCount,
            white: whiteText,
        });

        notifyError(
            message,
            ApiErrorCode.VM_MEMORY_LIMIT_EXCEED,
            { btn: addFundsLink, duration: 0 },
        );
    };

    const onNew = async (values: NewServerFormValues) => {
        if (accountLimits
            && ((accountLimits.currentServersCount === accountLimits.maxCurrentServersCount)
            || (accountLimits.overallServersCount === accountLimits.maxOverallServersCount))) {
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
            notifyVMLimit();
            return;
        }
        if (accountLimits?.maxOverallMemoryMib !== -1
            && (
                values.memory_mib + accountLimits?.overallMemoryMib
            ) > accountLimits?.maxOverallMemoryMib) {
            notifyMemoryLimit();
            return;
        }

        const reqEnt = new ServerSetup(values);

        dispatch(install([values.tenant_id, reqEnt.serialize()], {
            result: (result: IOperationProgress) => {
                if (payers?.length === 0) {
                    history.push(linkPathBuilder(currentLocale, RoutePath.OnboardingServer));
                } else {
                    const valuesToSave: IServerStorage = {
                        tenant_id: values.tenant_id,
                        tariff_id: values.tariff_id,
                        send_password: values.send_password,
                        backup_frequency: values.backup_frequency,
                        backup_quantity: values.backup_quantity,
                        cpu_cores: values.cpu_cores,
                        disk_gib: values.disk_gib,
                        distribution_id: values.distribution_id,
                        distribution_name: values.distribution_name,
                        ip_count: values.ip_count,
                        memory_mib: values.memory_mib,
                        open_support_access: values.open_support_access,
                    };
                    UserStorage.setServerConfig(valuesToSave);

                    let url = linkPathBuilder(
                        currentLocale,
                        RoutePath.Server,
                        { serverId: result.server_id, tenantId: result.tenant_id },
                    );

                    if (trialActive && values.distribution_name === DistributionFamily.WINDOWS) {
                        url = linkPathBuilder(
                            currentLocale,
                            RoutePath.ServersList,
                        );
                    }

                    history.push(url);
                }
            },
            error: (error?: Error) => {
                if (error) {
                    if (error.errorCode === ApiErrorCode.VM_LIMIT_EXCEED) {
                        notifyVMLimit();
                        history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
                    }
                    if (error.errorCode === ApiErrorCode.VM_MEMORY_LIMIT_EXCEED) {
                        notifyMemoryLimit();
                    }
                    return;
                }
                if (payers?.length === 0) {
                    history.push(linkPathBuilder(currentLocale, RoutePath.OnboardingServer));
                } else {
                    if (trialBonusAvailable) {
                        history.push(linkPathBuilder(
                            currentLocale,
                            RoutePath.OnboardingAddFundsServer,
                        ));
                        return;
                    }
                    history.push(linkPathBuilder(currentLocale, RoutePath.AddFunds));
                }
            },
        }));
    };

    const onReinstall = async (
        values: NewServerFormValues,
        setFieldError: (field: string, message: string) => void,
    ) => {
        const reqEnt = new ServerReinstall({ ...values, security_code: values.code || undefined });
        dispatch(reinstall([values.tenant_id, server!.id, reqEnt.serialize()], {
            result: () => {
                history.push(linkPathBuilder(
                    currentLocale, RoutePath.Server, { tenantId: values.tenant_id, serverId: server!.id },
                ));
            },
            error: (error) => {
                if (error?.fields?.security_code) {
                    setFieldError('code', EMPTY_FIELD_ERROR);
                } else {
                    dispatch(addError({
                        error,
                        type: ServerActions.Reinstall,
                    }));
                }
            },
        }));
    };

    const formOnSubmit = async (
        values: NewServerFormValues & { code?: string },
        { setFieldError, setFieldValue }: FormikHelpers<NewServerFormValues>,
    ) => {
        const iserverSetup = { ...values };
        if (iserverSetup.password) {
            if (!passwordValidate(iserverSetup.password, NewServerFormHelper.passwordMinLength)) {
                setFieldError('password', EMPTY_FIELD_ERROR);
                return;
            }
        } else {
            delete iserverSetup.password;
        }
        if (!iserverSetup.name) {
            const distr = distributions.find((d) => d.id === iserverSetup.distribution_id);
            const name = distr ? `${distr.name} ${distr.version} - ${iserverSetup.memory_mib}` : '';
            iserverSetup.name = name;
            setFieldValue('name', name);
        }
        if (isReinstall) {
            onReinstall(iserverSetup, setFieldError);
        } else {
            onNew(iserverSetup);
        }
    };

    const getPrevValues = () => {
        const values = UserStorage.getServerConfig();

        if (!values) {
            return null;
        }

        const validDistribution = distributions.some((d) => (
            d.id === values.distribution_id && d.type === values.distribution_name
        ));

        if (validDistribution) {
            return values;
        }

        return null;
    };

    const searchInitial = search.replace('?', '')
        ? qs.parse(search.replace('?', '')) : {};

    const valuesFromStorage = getPrevValues();

    let initialValues = NewServerFormHelper.getInitialValues(
        tenantsArray,
        distributions,
        { tenantId, server },
        searchInitial,
    );

    const queryParamsNames = Object.values(SERVER_QUERY_PARAMS);
    const searchKeys = Object.keys(searchInitial);
    const validServerParams = searchKeys.length > 0
        && searchKeys.every((key) => queryParamsNames.includes(key as SERVER_QUERY_PARAMS));

    const newTenantAdded = tenants.has(Number(searchInitial.tenantId));

    if (valuesFromStorage && !isReinstall && !isClone && !validServerParams) {
        initialValues = {
            ...initialValues,
            ...valuesFromStorage,
            prevValues: initialValues,
        };
        if (!tenantsArray.find((t) => t.id === initialValues.tenant_id)) {
            initialValues.tenant_id = tenantsArray[0].id;
        }
    }

    if (searchInitial.tenantId && !newTenantAdded) {
        initialValues.tenant_id = Number(searchInitial.tenantId);
    }

    const onBack = (): RoutePath => {
        if (routeMatch.path === Routes[RoutePath.NewServerFromTenant]) {
            return RoutePath.ProjectsList;
        }
        return RoutePath.ServersList;
    };

    return (
        <InnerPageLayout
            header={<BackHeader title={intl.getMessage('create_server')} link={onBack} newServer />}
            className={theme.content.server}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={formOnSubmit}
                component={newServerForm(tenantsArray, isReinstall, (isReinstall ? server?.id : undefined))}
            />
        </InnerPageLayout>
    );
};

const selectServer = (store: Store, ownProps: NewServerOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return serverId ? store.server.get(Number(serverId)) : undefined;
};
const selectProfilePayer = (store: Store) => store.profile.profilePayer;
const selectProfileHasTrial = (store: Store) => store.profile.info?.trialBonusAvailable;
const selectProfileTrialActive = (store: Store) => store.profile.info?.trialBonusActive;
const selectAccountLimits = (store: Store) => store.account.details;
const selectDistr = (store: Store) => store.serverDistribution;
const tenantsSelect = (store: Store) => store.tenant;
const selector = createSelector(
    [
        tenantsSelect,
        selectDistr,
        selectServer,
        selectProfilePayer,
        selectAccountLimits,
        selectProfileHasTrial,
        selectProfileTrialActive,
    ],
    (
        tenants,
        distributions,
        server,
        payers,
        details,
        trialBonusAvailable,
        trialActive,
    ) => {
        return {
            tenants,
            distributions: Array.from(distributions.values()),
            server,
            details,
            payers,
            trialBonusAvailable,
            trialActive,
        };
    },
);
const mapStateStoreToProps = (store: Store, op: NewServerOwnProps) => selector(store, op);
export default withRouter(connect(mapStateStoreToProps)(NewServer));
