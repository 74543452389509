import React, { FC, useEffect } from 'react';
import cn from 'classnames';

import { Icon, Button, Modal } from 'Common/ui';
import { useIntl } from 'Common/intl';

interface CommonModalLayoutProps {
    visible: boolean;
    title?: React.ReactNode;
    submitButtonId?: string;
    buttonText?: string;
    className?: string;
    width?: number;
    handleClose: () => void;
    handleSubmit?: () => void;
    noFooter?: boolean;
    disabled?: boolean;
    buttonAttrs?: {
        [dataAttr: string]: string;
    };
    centered?: boolean;
    closeIcon?: React.ReactNode;
}

const CommonModalLayout: FC<CommonModalLayoutProps> = ({
    visible,
    children,
    title,
    submitButtonId,
    buttonText,
    className,
    width,
    handleClose,
    handleSubmit,
    noFooter,
    disabled,
    buttonAttrs,
    centered,
    closeIcon,
}) => {
    const intl = useIntl();

    useEffect(() => {
        const onEnter = (e: KeyboardEvent) => {
            if (e.key === 'Enter' && handleSubmit && !disabled) {
                handleSubmit();
            }
        };
        if (handleSubmit) {
            window.addEventListener('keyup', onEnter);
        }
        return () => {
            window.removeEventListener('keyup', onEnter);
        };
    }, [handleSubmit]);
    const footer = noFooter ? null : [
        <Button
            id={submitButtonId}
            type="primary"
            size="medium"
            key="submit"
            htmlType="submit"
            onClick={handleSubmit}
            disabled={disabled}
            inGroup
            {...buttonAttrs}
        >
            {buttonText}
        </Button>,
        <Button
            type="link"
            size="medium"
            key="cancel"
            onClick={handleClose}
        >
            {intl.getMessage('cancel')}
        </Button>,
    ];

    return (
        <Modal
            visible={visible}
            title={title}
            wrapClassName={cn('modal', className)}
            onCancel={handleClose}
            footer={footer}
            closeIcon={closeIcon || <Icon icon="close_big" />}
            width={width || 480}
            centered={centered}
        >
            {children}
        </Modal>
    );
};

export default CommonModalLayout;
