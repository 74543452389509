import { BackupPolicyFrequency } from './BackupPolicyFrequency';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerCostForecastRequest {
    backup_frequency?: BackupPolicyFrequency;
    backup_quantity?: number;
    cpu_cores?: number;
    disk_gib?: number;
    ip_count?: number;
    memory_mib?: number;
    tariff_id?: number;
}

export default class ServerCostForecastRequest {
    readonly _backup_frequency: BackupPolicyFrequency | undefined;

    get backupFrequency(): BackupPolicyFrequency | undefined {
        return this._backup_frequency;
    }

    readonly _backup_quantity: number | undefined;

    /**
     * Description: Number of preserved backups
     * Example: 1
     */
    get backupQuantity(): number | undefined {
        return this._backup_quantity;
    }

    readonly _cpu_cores: number | undefined;

    /**
     * Description: Cpu cores
     * Example: 2
     */
    get cpuCores(): number | undefined {
        return this._cpu_cores;
    }

    readonly _disk_gib: number | undefined;

    /**
     * Description: Disk capacity in Gib
     * Example: 16
     */
    get diskGib(): number | undefined {
        return this._disk_gib;
    }

    readonly _ip_count: number | undefined;

    /**
     * Description: IP address
     * Example: 1
     */
    get ipCount(): number | undefined {
        return this._ip_count;
    }

    readonly _memory_mib: number | undefined;

    /**
     * Description: Memory in Mib
     * Example: 1024
     */
    get memoryMib(): number | undefined {
        return this._memory_mib;
    }

    readonly _tariff_id: number | undefined;

    /**
     * Description: Tariff identifier, optional
     * Example: 1
     */
    get tariffId(): number | undefined {
        return this._tariff_id;
    }

    constructor(props: IServerCostForecastRequest) {
        if (props.backup_frequency) {
            this._backup_frequency = props.backup_frequency;
        }
        if (typeof props.backup_quantity === 'number') {
            this._backup_quantity = props.backup_quantity;
        }
        if (typeof props.cpu_cores === 'number') {
            this._cpu_cores = props.cpu_cores;
        }
        if (typeof props.disk_gib === 'number') {
            this._disk_gib = props.disk_gib;
        }
        if (typeof props.ip_count === 'number') {
            this._ip_count = props.ip_count;
        }
        if (typeof props.memory_mib === 'number') {
            this._memory_mib = props.memory_mib;
        }
        if (typeof props.tariff_id === 'number') {
            this._tariff_id = props.tariff_id;
        }
    }

    serialize(): IServerCostForecastRequest {
        const data: IServerCostForecastRequest = {
        };
        if (typeof this._backup_frequency !== 'undefined') {
            data.backup_frequency = this._backup_frequency;
        }
        if (typeof this._backup_quantity !== 'undefined') {
            data.backup_quantity = this._backup_quantity;
        }
        if (typeof this._cpu_cores !== 'undefined') {
            data.cpu_cores = this._cpu_cores;
        }
        if (typeof this._disk_gib !== 'undefined') {
            data.disk_gib = this._disk_gib;
        }
        if (typeof this._ip_count !== 'undefined') {
            data.ip_count = this._ip_count;
        }
        if (typeof this._memory_mib !== 'undefined') {
            data.memory_mib = this._memory_mib;
        }
        if (typeof this._tariff_id !== 'undefined') {
            data.tariff_id = this._tariff_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            tariff_id: !this._tariff_id ? true : typeof this._tariff_id === 'number',
            memory_mib: !this._memory_mib ? true : typeof this._memory_mib === 'number',
            disk_gib: !this._disk_gib ? true : typeof this._disk_gib === 'number',
            cpu_cores: !this._cpu_cores ? true : typeof this._cpu_cores === 'number',
            ip_count: !this._ip_count ? true : typeof this._ip_count === 'number',
            backup_quantity: !this._backup_quantity ? true : typeof this._backup_quantity === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerCostForecastRequest): ServerCostForecastRequest {
        return new ServerCostForecastRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        backupFrequency: 'backup_frequency',
        backupQuantity: 'backup_quantity',
        cpuCores: 'cpu_cores',
        diskGib: 'disk_gib',
        ipCount: 'ip_count',
        memoryMib: 'memory_mib',
        tariffId: 'tariff_id',
        }
;

    mergeDeepWith(props: Partial<ServerCostForecastRequest>): ServerCostForecastRequest {
        const updateData: Partial<IServerCostForecastRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerCostForecastRequest) => {
            const updateKey = this.keys[key] as keyof IServerCostForecastRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerCostForecastRequest, keyof IServerCostForecastRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerCostForecastRequest({ ...this.serialize(), ...updateData });
    }
}
