import React, { FC, useState, KeyboardEvent, useRef } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Button } from 'Common';
import Tenant from 'Entities/Tenant';
import TenantUpdate from 'Entities/TenantUpdate';
import { updateTenantDescription } from 'Actions/tenant';
import { useClickOutside } from 'Lib/hooks/hooks';
import theme from 'Lib/theme';
import { Input } from 'antd';

import s from './Info.module.pcss';

interface NameProps {
    tenant: Tenant;
    isOwner: boolean;
    visibleList: boolean;
    setVisibleList: (v: boolean) => void;
    editActive: boolean;
    setEditActive: (v: boolean) => void;
    titleCursor: boolean;
}

const Name: FC<NameProps> = ({
    tenant, isOwner, visibleList, setVisibleList, editActive, setEditActive, titleCursor,
}) => {
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const { id, description } = tenant;

    const [tempName, setTempName] = useState(description);

    useClickOutside(ref, () => setEditActive(false));

    const handleEdit = () => {
        if (tempName === description || tempName.length === 0) {
            setEditActive(false);
            setTempName(description);
            return;
        }
        let newDescriptionSized = tempName.trim();
        if (newDescriptionSized.length > TenantUpdate.descriptionMaxLength) {
            newDescriptionSized = newDescriptionSized.substring(
                0, TenantUpdate.descriptionMaxLength,
            );
        }
        dispatch(updateTenantDescription([id, { description: newDescriptionSized }]));
        setEditActive(false);
    };

    const onEditNameKeyPressed = (e: KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Enter':
                handleEdit();
                break;
            case 'Escape':
                setEditActive(false);
                break;
            default:
                break;
        }
    };

    return (
        <div className={theme.common.textOverflow}>
            {!editActive ? (
                <div
                    className={cn(
                        theme.edit.name,
                        { [theme.edit.edited]: isOwner },
                    )}
                >
                    <div
                        className={cn(theme.edit.title, s.name, { [s.editable]: titleCursor })}
                        onClick={() => setVisibleList(!visibleList)}
                    >
                        {description}
                    </div>
                </div>
            ) : (
                <div
                    className={theme.edit.name}
                    ref={ref}
                >
                    <Input
                        id="projectNameInput"
                        className={cn(theme.edit.title, s.name)}
                        name="project_name"
                        value={tempName}
                        onChange={(e) => setTempName(e.target.value)}
                        onKeyDown={onEditNameKeyPressed}
                        onFocus={(e) => e.target.select()}
                        autoFocus
                    />
                    <Button
                        id="projectNameEdit"
                        type="edit"
                        size="small"
                        onClick={handleEdit}
                        className={cn(theme.button.blue, theme.button.check)}
                        icon="check"
                    />
                    <Button
                        id="projectNameClose"
                        type="edit"
                        size="small"
                        className={theme.button.red}
                        onClick={() => setEditActive(false)}
                        icon="close_small"
                    />
                </div>
            )}
            <div className={s.id}>
                #{id}
            </div>
        </div>
    );
};

export default Name;
