import React, { FC, useEffect, useState, createRef } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Terminal } from 'xterm';
import { AttachAddon } from 'xterm-addon-attach';
import { Row, Col } from 'antd';

import { useServerInProgress, useServerNotExist, useTitle, useServerSearchSave } from 'Hooks';
import ServerApi from 'Apis/servers';
import { InnerPageLayout, LoaderPageLayout, notifyError, ServerNotExist, useIntl } from 'Common';
import Server from 'Entities/Server';
import { IServerConsoleState } from 'Entities/ServerConsoleState';
import { ServerState } from 'Entities/ServerState';
import { ConsoleStatus } from 'Entities/ConsoleStatus';
import Profile from 'Entities/Profile';
import { WebTheme } from 'Entities/WebTheme';
import { errorChecker } from 'Lib/helpers/utils';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { getCurrentTheme } from 'Lib/helpers/helpers';
import { Store } from 'Store';

import { Header, Sidebar, Info, Disabled } from '.';
import s from './Console.module.pcss';

type ConsoleOwnProps = RouteComponentProps<{ serverId: string }>;
interface ConsoleStoreProps {
    server: Server;
    profile: Profile | null;
}
type ConsoleProps = ConsoleStoreProps & ConsoleOwnProps;

const DEFAULT_COL_NUMBER = 80;
const TERMINAL_LETTER_WIDTH = 9; // px
const DEFAULT_TERMINAL_MARGIN = 30;

const Console: FC<ConsoleProps> = ({ server, profile }) => {
    const intl = useIntl();

    const pageTitle = server?.name
        ? intl.getMessage('server_console_page_title', { value: server.name })
        : intl.getMessage('page_title');
    useTitle(pageTitle);

    const history = useHistory();

    useServerSearchSave(server?.id);

    useEffect(() => {
        if (!server.consoleAccess || server.serviceMode) {
            history.push(linkPathBuilder(
                intl.currentLocale,
                RoutePath.Server,
                { serverId: server.id, tenantId: server.tenantId },
            ));
            notifyError(!server.consoleAccess
                ? intl.getMessage('server_console_no_access')
                : intl.getMessage('server_console_service_mode'));
        }
    }, [server]);

    const ref = createRef<HTMLDivElement>();
    const { consoleUrl, state } = server;

    let terminalTheme = {
        background: 'white',
        cursor: 'black',
        foreground: 'black',
        selection: '#1c42ea',
    };

    const currentTheme = profile?.webTheme && getCurrentTheme(profile.webTheme);

    if (currentTheme === WebTheme.DARK) {
        terminalTheme = {
            background: '#1e1e1e',
            cursor: '#fff',
            foreground: '#fff',
            selection: '#1c42ea',
        };
    }

    const [terminal] = useState(new Terminal({
        cursorBlink: true,
        theme: terminalTheme,
        cols: DEFAULT_COL_NUMBER,
    }));

    const getColumns = () => {
        if (ref.current) {
            const refWidth = parseInt(window.getComputedStyle(ref.current).width, 10);
            return Math.floor((refWidth - DEFAULT_TERMINAL_MARGIN) / TERMINAL_LETTER_WIDTH);
        }
        return DEFAULT_COL_NUMBER;
    };
    const [socket] = useState(new WebSocket(consoleUrl!));
    useEffect(() => {
        if (ref?.current) {
            const attach = new AttachAddon(socket);
            terminal.loadAddon(attach);
            terminal.open(ref.current);
            terminal.focus();
        }
    }, [ref]);

    useEffect(() => {
        const socketListner = () => {
            if (socket.readyState === 1) {
                socket.send('\n');
                socket.removeEventListener('open', socketListner);
            }
        };
        let messages = '';
        const messageListener = (ev: MessageEvent) => {
            const message: ArrayBuffer | string = ev.data;
            const parsedMessage = typeof message === 'string' ? message : new TextDecoder('utf-8').decode(new Uint8Array(message));
            messages += parsedMessage;
            if (messages.includes('login') || messages.includes('root')) {
                const elem = document.getElementById('console');
                if (elem) {
                    elem.dataset.connected = 'true';
                }
                socket.removeEventListener('message', messageListener);
            }
        };
        socket.addEventListener('open', socketListner);
        socket.addEventListener('message', messageListener);

        return () => {
            socket.removeEventListener('open', socketListner);
            socket.removeEventListener('message', messageListener);
            socket.close();
            messages = '';
        };
    }, []);

    useEffect(() => {
        let canUpdate = true;
        const { id, tenantId } = server;
        let interval: NodeJS.Timeout;
        let prevStat: IServerConsoleState | null = null;
        const loader = async () => {
            const res = await ServerApi.getServerConsoleStatus(tenantId, id);
            const { result } = errorChecker<IServerConsoleState>(res);
            if (result && canUpdate) {
                if (result.status !== ConsoleStatus.ONLINE) {
                    terminal.clear();
                    terminal.writeln('');
                    terminal.writeln(result.status);
                    terminal.blur();
                }
                if (result.status === ConsoleStatus.ONLINE && prevStat?.status !== result.status) {
                    terminal.reset();
                    terminal.focus();
                }
                prevStat = result;
                interval = setTimeout(loader, 10000);
            }
        };
        loader();
        return () => {
            canUpdate = false;
            if (interval) {
                clearTimeout(interval);
            }
        };
    }, []);

    useEffect(() => {
        const resizeHandler = () => {
            terminal.resize(getColumns(), terminal.rows);
        };
        /*
            setTimeout below fixes the problem of incorrect width of console
            container if window width is small when component mounts
            for now we don't have better solution
            v.abdulmyanov pls try to find smth else later to fix it (TODO)
        */
        setTimeout(resizeHandler, 0);
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <InnerPageLayout header={<Header server={server} />}>
            <Row gutter={[24, 0]}>
                <Col span={24} lg={18}>
                    <Info />
                    {state === ServerState.SHUTDOWN || state === ServerState.SHUTOFF ? (
                        <Disabled server={server} />
                    ) : (
                        <div className={s.wrapper}>
                            <div id="console" ref={ref} data-connected="false" />
                        </div>
                    )}
                </Col>
                <Col span={0} md={6}>
                    <Sidebar />
                </Col>
            </Row>
        </InnerPageLayout>
    );
};

interface ConsoleContainerStoreProps {
    server: Server | undefined;
    profile: Profile | null;
}
type ConsoleContainerProps = ConsoleContainerStoreProps & ConsoleOwnProps;

const ConsoleContainer: FC<ConsoleContainerProps> = (props) => {
    const { server, match: { params: { serverId } } } = props;
    const isProgress = useServerInProgress(Number(serverId));
    const noServer = useServerNotExist();

    if (noServer) {
        return <ServerNotExist />;
    }
    if (!server || isProgress) {
        return (
            <InnerPageLayout header={<Header server={server} />}>
                <LoaderPageLayout />
            </InnerPageLayout>
        );
    }
    return <Console {...props} server={server} />;
};

const selectServer = (store: Store, ownProps: ConsoleOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return store.server.get(Number(serverId));
};
const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector(
    [selectServer, selectProfile],
    (server, profile) => ({
        server, profile,
    }),
);

const mapStoreToProps = (store: Store, ownProps: ConsoleOwnProps) => ({
    ...selector(store, ownProps),
});

export default withRouter(connect(mapStoreToProps)(ConsoleContainer));
