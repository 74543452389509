// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum ApiErrorCode {
    FIELD_REQUIRED = 'FIELD_REQUIRED',
    FIELD_WRONG_VALUE = 'FIELD_WRONG_VALUE',
    FIELD_WRONG_STRING = 'FIELD_WRONG_STRING',
    FIELD_LENGTH_MIN_8 = 'FIELD_LENGTH_MIN_8',
    FIELD_LENGTH_MAX_256 = 'FIELD_LENGTH_MAX_256',
    UNKNOWN = 'UNKNOWN',
    BAD_REQUEST = 'BAD_REQUEST',
    NOT_OWNER = 'NOT_OWNER',
    OBJECT_NOT_FOUND = 'OBJECT_NOT_FOUND',
    CANNOT_READ_PARTITION_TABLE = 'CANNOT_READ_PARTITION_TABLE',
    CLIENT_EMAIL_NOT_CONFIRMED = 'CLIENT_EMAIL_NOT_CONFIRMED',
    CLUSTER_OVER_CAPACITY = 'CLUSTER_OVER_CAPACITY',
    CLUSTER_OVER_LIMIT_PENDING_SETUP = 'CLUSTER_OVER_LIMIT_PENDING_SETUP',
    CLUSTER_OVER_MEMORY = 'CLUSTER_OVER_MEMORY',
    DOWNLOAD_BACKUP_NOT_ENOUGH_BALANCE = 'DOWNLOAD_BACKUP_NOT_ENOUGH_BALANCE',
    DOWNLOAD_SNAPSHOT_NOT_ENOUGH_BALANCE = 'DOWNLOAD_SNAPSHOT_NOT_ENOUGH_BALANCE',
    INET_IP_GUEST_ADD = 'INET_IP_GUEST_ADD',
    INET_IP_GUEST_REMOVE = 'INET_IP_GUEST_REMOVE',
    INET_IP_LIMIT_EXCEEDED = 'INET_IP_LIMIT_EXCEEDED',
    INET_IP_MIN_VALUE = 'INET_IP_MIN_VALUE',
    NET_NO_LOCAL_IP = 'NET_NO_LOCAL_IP',
    NET_NO_INET_IP = 'NET_NO_INET_IP',
    OBSOLETE_DISTRIBUTION = 'OBSOLETE_DISTRIBUTION',
    OPERATION_ALREADY_STARTED = 'OPERATION_ALREADY_STARTED',
    OPERATION_NOT_SUPPORTED = 'OPERATION_NOT_SUPPORTED',
    PARTITION_RESIZE_FAILED = 'PARTITION_RESIZE_FAILED',
    PARTITION_TABLE_MODIFIED = 'PARTITION_TABLE_MODIFIED',
    RATE_LIMIT_EXCEED = 'RATE_LIMIT_EXCEED',
    RECONFIGURATION_DENIED = 'RECONFIGURATION_DENIED',
    SERVICE_API_UNAVAILABLE = 'SERVICE_API_UNAVAILABLE',
    SETUP_NOT_ENOUGH_BALANCE = 'SETUP_NOT_ENOUGH_BALANCE',
    SETUP_NOT_ENOUGH_BALANCE_EMAIL_NOT_CONFIRMED = 'SETUP_NOT_ENOUGH_BALANCE_EMAIL_NOT_CONFIRMED',
    SFDISK_OR_RESIZEPART_MISSED = 'SFDISK_OR_RESIZEPART_MISSED',
    SNAPSHOT_ACTIONS_DISABLED = 'SNAPSHOT_ACTIONS_DISABLED',
    SNAPSHOT_LIMIT_EXCEEDED = 'SNAPSHOT_LIMIT_EXCEEDED',
    SNAPSHOT_NONSTANDARD_VM = 'SNAPSHOT_NONSTANDARD_VM',
    TENANT_MAX_CLIENTS = 'TENANT_MAX_CLIENTS',
    UNSUPPORTED_KERNEL_VERSION = 'UNSUPPORTED_KERNEL_VERSION',
    VM_CLONE_NOT_FINISHED = 'VM_CLONE_NOT_FINISHED',
    VM_DESTROY_DISABLED = 'VM_DESTROY_DISABLED',
    VM_LIMIT_EXCEED = 'VM_LIMIT_EXCEED',
    VM_MEMORY_LIMIT_EXCEED = 'VM_MEMORY_LIMIT_EXCEED',
    VM_NOT_FOUND = 'VM_NOT_FOUND',
    VM_PASSWORD_WRONG = 'VM_PASSWORD_WRONG',
    VM_REBOOT_NOT_COMPLETE = 'VM_REBOOT_NOT_COMPLETE',
    VM_REINSTALL_CLONE_NOT_FINISHED = 'VM_REINSTALL_CLONE_NOT_FINISHED',
    VM_REMOVE_PROTECTED_SNAPSHOT = 'VM_REMOVE_PROTECTED_SNAPSHOT',
    VM_RESOURCE_CHANGE_WRONG = 'VM_RESOURCE_CHANGE_WRONG',
    VM_SERVICE_MODE = 'VM_SERVICE_MODE',
    VM_SHUTDOWN_NOT_COMPLETE = 'VM_SHUTDOWN_NOT_COMPLETE',
    VM_SHUTOFF_CLIENT_DEBT = 'VM_SHUTOFF_CLIENT_DEBT',
    VM_SHUTOFF_REQUIRED = 'VM_SHUTOFF_REQUIRED',
    VM_VOLUME_CHANGE_WRONG = 'VM_VOLUME_CHANGE_WRONG',
    VM_VOLUME_UNDER_OPERATION = 'VM_VOLUME_UNDER_OPERATION',
    VM_VOLUME_UNDER_BACKUP_MERGING = 'VM_VOLUME_UNDER_BACKUP_MERGING',
    VM_WINDOWS_CREATE_TEST_DISABLED = 'VM_WINDOWS_CREATE_TEST_DISABLED',
    DNS_VALIDATION_ERROR = 'DNS_VALIDATION_ERROR',
    DNS_ZONE_ALREADY_EXIST = 'DNS_ZONE_ALREADY_EXIST',
    DNS_ZONE_PUNYCODE_FORBIDDEN = 'DNS_ZONE_PUNYCODE_FORBIDDEN',
    DNS_RECORD_ALREADY_EXIST = 'DNS_RECORD_ALREADY_EXIST',
    DNS_RECORD_VALUE_EXIST = 'DNS_RECORD_VALUE_EXIST',
    DNS_ZONES_COUNT_EXCEED = 'DNS_ZONES_COUNT_EXCEED',
    DNS_RECORDS_COUNT_EXCEED = 'DNS_RECORDS_COUNT_EXCEED',
    DNS_DISABLED_NEGATIVE_BALANCE = 'DNS_DISABLED_NEGATIVE_BALANCE',
    INVOICE_INVALID_CREDIT_CARD = 'INVOICE_INVALID_CREDIT_CARD',
    EMAIL_ALREADY_EXIST = 'EMAIL_ALREADY_EXIST',
    CLIENT_ALREADY_IN_TENANT = 'CLIENT_ALREADY_IN_TENANT',
    CREDIT_CARDS_LIMIT_EXCEED = 'CREDIT_CARDS_LIMIT_EXCEED',
    PROFILE_NEW_PASSWORD_MATCH_OLD = 'PROFILE_NEW_PASSWORD_MATCH_OLD',
    SOLAR_STAFF_REGISTRATION_INCOMPLETE = 'SOLAR_STAFF_REGISTRATION_INCOMPLETE',
    SOLAR_STAFF_EMAIL_ALREADY_EXIST = 'SOLAR_STAFF_EMAIL_ALREADY_EXIST',
    SOLAR_STAFF_ACCOUNT_VERIFICATION_REQUIRED = 'SOLAR_STAFF_ACCOUNT_VERIFICATION_REQUIRED',
    BONUS_COUPON_INVALID = 'BONUS_COUPON_INVALID',
    BONUS_COUPON_EXPIRED = 'BONUS_COUPON_EXPIRED',
    BONUS_COUPON_ALREADY_USE_TEST_BONUS = 'BONUS_COUPON_ALREADY_USE_TEST_BONUS',
    BONUS_COUPON_ALREADY_USE_OTHER_BONUS = 'BONUS_COUPON_ALREADY_USE_OTHER_BONUS',
    BONUS_COUPON_DISABLED_FOR_ACCOUNT = 'BONUS_COUPON_DISABLED_FOR_ACCOUNT',
    BONUS_COUPON_PHONE_REQUIRED = 'BONUS_COUPON_PHONE_REQUIRED',
    BONUS_COUPON_TRIAL_PERIOD_REQUIRED = 'BONUS_COUPON_TRIAL_PERIOD_REQUIRED',
    LOGIN_BAD_CREDENTIALS = 'LOGIN_BAD_CREDENTIALS',
    LOGIN_USER_DISABLED = 'LOGIN_USER_DISABLED',
    LOGIN_USER_LOCKED = 'LOGIN_USER_LOCKED',
    LOGIN_TOO_MANY_ATTEMPTS = 'LOGIN_TOO_MANY_ATTEMPTS',
    LOGIN_USER_NOT_CONFIRMED = 'LOGIN_USER_NOT_CONFIRMED',
    LOGIN_SOCIAL_REJECTED = 'LOGIN_SOCIAL_REJECTED',
    CAPTCHA_REQUIRED = 'CAPTCHA_REQUIRED',
    CAPTCHA_INVALID = 'CAPTCHA_INVALID',
    CLIENT_RECIPIENT_COUNT_EXCEED = 'CLIENT_RECIPIENT_COUNT_EXCEED',
    CLIENT_RECIPIENT_DUPLICATE = 'CLIENT_RECIPIENT_DUPLICATE',
    RECIPIENT_EMAIL_OR_PHONE_REQUIRED = 'RECIPIENT_EMAIL_OR_PHONE_REQUIRED',
    CLIENT_TRIGGER_COUNT_EXCEED = 'CLIENT_TRIGGER_COUNT_EXCEED',
    HTTP_TRIGGER_RESOLVE_NOT_FOUND = 'HTTP_TRIGGER_RESOLVE_NOT_FOUND',
    SECURITY_CODE_REQUIRED = 'SECURITY_CODE_REQUIRED',
    SECURITY_CODE_WRONG = 'SECURITY_CODE_WRONG',
    NOT_ENOUGH_BALANCE = 'NOT_ENOUGH_BALANCE',
    TRIAL_PERIOD_DISABLED = 'TRIAL_PERIOD_DISABLED',
    DNS_IMPORT_INVALID_CONFIG = 'DNS_IMPORT_INVALID_CONFIG'
}
