import CreatePayerRequest, { ICreatePayerRequest } from './CreatePayerRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerClientAdd {
    email: string;
    new_payer: ICreatePayerRequest;
    newsletter_enabled: boolean;
    password: string;
}

export default class ResellerClientAdd {
    readonly _email: string;

    /**
     * Description: Sub-client's email
     * Example: myhomeworkstation@gmail.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    readonly _new_payer: CreatePayerRequest;

    get newPayer(): CreatePayerRequest {
        return this._new_payer;
    }

    readonly _newsletter_enabled: boolean;

    /**
     * Description: Subscribe sub-client for newsletter or do not
     * Example: true
     */
    get newsletterEnabled(): boolean {
        return this._newsletter_enabled;
    }

    static newsletterEnabledValidate(newsletterEnabled: boolean): boolean {
        return typeof newsletterEnabled === 'boolean';
    }

    readonly _password: string;

    /**
     * Description: Sub-client's password
     * Example: 1ef34eRs
     */
    get password(): string {
        return this._password;
    }

    static get passwordMinLength() {
        return 8;
    }

    static get passwordMaxLength() {
        return 2147483647;
    }

    static passwordValidate(password: string): boolean {
        return (password.length >= 8) && (password.length <= 2147483647);
    }

    constructor(props: IResellerClientAdd) {
        this._email = props.email.trim();
        this._new_payer = new CreatePayerRequest(props.new_payer);
        this._newsletter_enabled = props.newsletter_enabled;
        this._password = props.password.trim();
    }

    serialize(): IResellerClientAdd {
        const data: IResellerClientAdd = {
            email: this._email,
            new_payer: this._new_payer.serialize(),
            newsletter_enabled: this._newsletter_enabled,
            password: this._password,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            email: typeof this._email === 'string' && !!this._email.trim(),
            password: (this._password.length >= 8) && (this._password.length <= 2147483647),
            newsletter_enabled: typeof this._newsletter_enabled === 'boolean',
            new_payer: this._new_payer.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerClientAdd): ResellerClientAdd {
        return new ResellerClientAdd(props);
    }

    readonly keys: { [key: string]: string } = {
        email: 'email',
        newPayer: 'new_payer',
        newsletterEnabled: 'newsletter_enabled',
        password: 'password',
        }
;

    mergeDeepWith(props: Partial<ResellerClientAdd>): ResellerClientAdd {
        const updateData: Partial<IResellerClientAdd> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerClientAdd) => {
            const updateKey = this.keys[key] as keyof IResellerClientAdd;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerClientAdd, keyof IResellerClientAdd>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerClientAdd({ ...this.serialize(), ...updateData });
    }
}
