import React, { FC, useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import cn from 'classnames';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useTitle } from 'Hooks';
import { getResellerDetails, getResellerClients, transferFromResellerToClient } from 'Actions/reseller';
import { useIntl, FormPageLayout, Link, Icon, Button } from 'Common';
import { SourceBalance } from 'Entities/SourceBalance';
import { currencySymbol } from 'Lib/helpers/consts';
import { RoutePath, linkPathBuilder } from 'Lib/helpers/routes';
import { roundNumber } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';
import { Store } from 'Store';

import s from './AddClientFunds.module.pcss';

const { Option } = Select;

interface AddClientFundsStoreProps {
    details: Store['reseller']['details'];
    clients: Store['reseller']['clients'];
    account: Store['account']['details'];
}

const AddClientFunds: FC<AddClientFundsStoreProps> = ({
    details, clients, account,
}) => {
    const intl = useIntl();
    useTitle(intl.getMessage('clients_add_funds_page_title'));
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch<{ clientId: string }>();

    const { params: { clientId } } = match;

    useEffect(() => {
        dispatch(getResellerDetails());
        dispatch(getResellerClients([AUTH_TOKEN]));
    }, []);
    const [state, setState] = useState<{
        source: SourceBalance;
        client: number | null;
        amount: string | null;
    }>({
        source: SourceBalance.RESELLER_BALANCE,
        client: Number(clientId) || null,
        amount: null,
    });

    const onChange = (field: keyof typeof state, value: SourceBalance | number | string) => {
        setState({ ...state, [field]: value });
    };
    if (!account || !details || !clients) {
        return null;
    }

    const onSubmit = () => {
        if (state.amount && state.client) {
            dispatch(transferFromResellerToClient([state.client, {
                source_balance: state.source,
                amount: parseFloat(state.amount),
            }]));
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.Clients));
        }
    };

    return (
        <FormPageLayout
            title={intl.getMessage('clients_add_funds')}
            back={RoutePath.Clients}
        >
            <div className={s.group}>
                <div className={s.label}>
                    {intl.getMessage('clients_source')}
                </div>
                <Select
                    size="large"
                    className={cn('select select--big select--block', s.input)}
                    value={state.source}
                    onChange={(value) => onChange('source', value)}
                    suffixIcon={(<Icon icon="down" className="icon select__arrow" />)}
                    dropdownClassName="select-dropdown"
                >
                    <Option value={SourceBalance.RESELLER_BALANCE}>
                        {intl.getMessage('clients_reseller_account')}&nbsp;–&nbsp;
                        <span className={theme.color.blue}>
                            {intl.getMessage('money', { value: roundNumber(details.balance) })}
                        </span>
                    </Option>
                    <Option value={SourceBalance.PERSONAL_BALANCE}>
                        {intl.getMessage('credentials_client_account')}&nbsp;–&nbsp;
                        <span className={theme.color.blue}>
                            {intl.getMessage('money', { value: roundNumber(account.balance) })}
                        </span>
                    </Option>
                </Select>
            </div>
            <div className={s.group}>
                <div className={s.label}>
                    {intl.getMessage('clients_client')}
                </div>
                <Select
                    size="large"
                    className={cn('select select--big select--block', s.input)}
                    value={state.client || undefined}
                    onChange={(value) => onChange('client', value)}
                    placeholder={intl.getMessage('add_client_funds_client')}
                    suffixIcon={(<Icon icon="down" className="icon select__arrow" />)}
                    dropdownClassName="select-dropdown"
                >
                    {clients.map((c) => (
                        <Option value={c.clientId} key={c.clientId}>
                            {c.email}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className={s.group}>
                <div className={s.label}>
                    {intl.getMessage('amount')}
                </div>
                <Input
                    size="large"
                    className="input input_big"
                    placeholder={intl.getMessage('sum')}
                    onChange={(e) => onChange('amount', e.target.value)}
                    suffix={currencySymbol.RUB}
                    value={state.amount || undefined}
                />
            </div>
            <div className={s.text}>
                {intl.getMessage('clients_add_funds_desc')}
            </div>
            <div className={s.actions}>
                <Button
                    type="primary"
                    size="big"
                    disabled={!state.amount || !state.client}
                    onClick={onSubmit}
                    inGroup
                >
                    {intl.getMessage('add_funds')}
                </Button>
                <Link to={RoutePath.Clients}>
                    <Button
                        type="link"
                        size="big"
                    >
                        {intl.getMessage('cancel')}
                    </Button>
                </Link>
            </div>
        </FormPageLayout>
    );
};

const selectResellerDetails = (store: Store) => store.reseller.details;
const selectAccountDetails = (store: Store) => store.account.details;
const selectResellerClients = (store: Store) => store.reseller.clients;

const selector = createSelector(
    [selectResellerDetails, selectResellerClients, selectAccountDetails],
    (details, clients, account) => ({
        details,
        clients,
        account,
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(AddClientFunds);
