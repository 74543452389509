import React, { FC } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useIntl, Icon, Button, notifySuccess, Link } from 'Common';
import { externalLinkBuilder, GuestRoutePath, RoutePath, linkPathBuilder } from 'Lib/helpers/routes';
import { updateProfile } from 'Actions/profile';
import { TICKETS_SUBJECT } from 'Lib/helpers/consts';

import theme from 'Lib/theme';
import s from './ClientsEmpty.module.pcss';

const ClientsEmpty: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const hideClientSection = () => {
        dispatch(updateProfile([{ reseller_section_hidden: true }], {
            result: () => {
                notifySuccess(intl.getMessage('studio_section_hidden_notify_success'));
                history.push(linkPathBuilder(intl.currentLocale, RoutePath.Dashboard));
            },
        }));
    };

    return (
        <div className={cn(theme.content.content, s.wrapper)}>
            <div className={s.infoBlock}>
                <div className={cn(theme.text.medium, s.desc)}>
                    {intl.getMessage('dashboard_open_client_section_desc')}
                </div>
                <ul className={cn(s.advantageList, theme.color.gray)}>
                    <li className={s.item}>
                        <Icon icon="check" color={s.check} />
                        {intl.getMessage('dashboard_clients_spending_amount_cashback')}
                    </li>
                    <li className={s.item}>
                        <Icon icon="check" color={s.check} />
                        {intl.getMessage('dashboard_clients_discount_managing')}
                    </li>
                    <li className={s.item}>
                        <Icon icon="check" color={s.check} />
                        {intl.getMessage('dashboard_clients_account_balance_adding')}
                    </li>
                    <li className={s.item}>
                        <Icon icon="check" color={s.check} />
                        {intl.getMessage('dashboard_clients_cross_account_server_cloning')}
                    </li>
                </ul>
                <div className={s.actions}>
                    <Link
                        to={RoutePath.CreateTicketWithSubject}
                        props={{ subject: TICKETS_SUBJECT.OPEN_STUDIO_ACCESS }}
                        className={cn(theme.link.link, theme.link.blue, theme.text.medium)}
                    >
                        {intl.getMessage('dashboard_clients_get_access')}
                    </Link>
                    <div className={s.dot} />
                    <a
                        href={externalLinkBuilder(
                            intl.currentLocale,
                            GuestRoutePath.KbArticle,
                            { slug: 'studios' },
                        )}
                        className={theme.text.medium}
                    >
                        {intl.getMessage('how_working')}
                    </a>
                </div>
            </div>
            <div className={s.hideBlock}>
                <div className={cn(theme.color.grayHeaders, s.hideDesc)}>
                    {intl.getMessage('dashboard_clients_hide_desc')}
                </div>
                <div className={s.hideAction}>
                    <Button
                        type="link"
                        danger
                        onClick={hideClientSection}
                    >
                        {intl.getMessage('hide')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ClientsEmpty;
