import { DistributionFamily } from './DistributionFamily';
import { ServerState } from './ServerState';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerInfo {
    deleted: boolean;
    distribution: DistributionFamily;
    id: number;
    internal_id: string;
    name: string;
    state: ServerState;
    tenant_id: number;
}

export default class ServerInfo {
    readonly _deleted: boolean;

    /** */
    get deleted(): boolean {
        return this._deleted;
    }

    static deletedValidate(deleted: boolean): boolean {
        return typeof deleted === 'boolean';
    }

    readonly _distribution: DistributionFamily;

    get distribution(): DistributionFamily {
        return this._distribution;
    }

    static distributionValidate(distribution: DistributionFamily): boolean {
        return Object.keys(DistributionFamily).includes(distribution);
    }

    readonly _id: number;

    /** */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _internal_id: string;

    /**
     * Description: Server internal unique identifier
     * Example: vm51671
     */
    get internalId(): string {
        return this._internal_id;
    }

    static internalIdValidate(internalId: string): boolean {
        return typeof internalId === 'string' && !!internalId.trim();
    }

    readonly _name: string;

    /**
     * Description: Server name
     * Example: dev-one
     */
    get name(): string {
        return this._name;
    }

    static nameValidate(name: string): boolean {
        return typeof name === 'string' && !!name.trim();
    }

    readonly _state: ServerState;

    get state(): ServerState {
        return this._state;
    }

    static stateValidate(state: ServerState): boolean {
        return Object.keys(ServerState).includes(state);
    }

    readonly _tenant_id: number;

    /**
     * Description: Server tenant identifier
     * Example: 51671
     */
    get tenantId(): number {
        return this._tenant_id;
    }

    static tenantIdValidate(tenantId: number): boolean {
        return typeof tenantId === 'number';
    }

    constructor(props: IServerInfo) {
        this._deleted = props.deleted;
        this._distribution = props.distribution;
        this._id = props.id;
        this._internal_id = props.internal_id.trim();
        this._name = props.name.trim();
        this._state = props.state;
        this._tenant_id = props.tenant_id;
    }

    serialize(): IServerInfo {
        const data: IServerInfo = {
            deleted: this._deleted,
            distribution: this._distribution,
            id: this._id,
            internal_id: this._internal_id,
            name: this._name,
            state: this._state,
            tenant_id: this._tenant_id,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            tenant_id: typeof this._tenant_id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            internal_id: typeof this._internal_id === 'string' && !!this._internal_id.trim(),
            deleted: typeof this._deleted === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerInfo): ServerInfo {
        return new ServerInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        deleted: 'deleted',
        distribution: 'distribution',
        id: 'id',
        internalId: 'internal_id',
        name: 'name',
        state: 'state',
        tenantId: 'tenant_id',
        }
;

    mergeDeepWith(props: Partial<ServerInfo>): ServerInfo {
        const updateData: Partial<IServerInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerInfo) => {
            const updateKey = this.keys[key] as keyof IServerInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerInfo, keyof IServerInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerInfo({ ...this.serialize(), ...updateData });
    }
}
