// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsPtrDelete {
    ip_address: string;
}

export default class DnsPtrDelete {
    readonly _ip_address: string;

    /**
     * Description: Ip address
     * Example: 91.239.26.27
     */
    get ipAddress(): string {
        return this._ip_address;
    }

    static ipAddressValidate(ipAddress: string): boolean {
        return typeof ipAddress === 'string' && !!ipAddress.trim();
    }

    constructor(props: IDnsPtrDelete) {
        this._ip_address = props.ip_address.trim();
    }

    serialize(): IDnsPtrDelete {
        const data: IDnsPtrDelete = {
            ip_address: this._ip_address,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            ip_address: typeof this._ip_address === 'string' && !!this._ip_address.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsPtrDelete): DnsPtrDelete {
        return new DnsPtrDelete(props);
    }

    readonly keys: { [key: string]: string } = {
        ipAddress: 'ip_address',
        }
;

    mergeDeepWith(props: Partial<DnsPtrDelete>): DnsPtrDelete {
        const updateData: Partial<IDnsPtrDelete> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsPtrDelete) => {
            const updateKey = this.keys[key] as keyof IDnsPtrDelete;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsPtrDelete, keyof IDnsPtrDelete>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsPtrDelete({ ...this.serialize(), ...updateData });
    }
}
