import React, { FC, ChangeEvent } from 'react';
import cn from 'classnames';

import s from './Switch.module.pcss';

interface SwitchProps {
    id: string;
    checked: boolean;
    disabled?: boolean;
    labelType?: string;
    className?: string;
    fixedWidth?: boolean;
    small?: boolean;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const Switch: FC<SwitchProps> = ({
    id,
    checked,
    disabled,
    children,
    className,
    labelType,
    handleChange,
    fixedWidth,
    small,
}) => {
    const labelClass = cn(
        s.label,
        { [s.label_large]: labelType === 'large' },
        { [s.label_fixedWidth]: fixedWidth },
        { [s.small]: small },
    );

    return (
        <label htmlFor={id} className={cn(s.switch, className)}>
            <input
                id={id}
                type="checkbox"
                className={s.input}
                onChange={handleChange}
                checked={checked}
                disabled={disabled}
            />

            <div className={cn(s.handler, { [s.small]: small })} />

            <div className={labelClass}>
                {children}
            </div>
        </label>
    );
};

export default Switch;
