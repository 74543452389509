import React, { FC } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createAccountNoCashInvoice } from 'Actions/account';
import { useIntl, notifyError, Input } from 'Common';
import AccountDetails from 'Entities/AccountDetails';
import CreateNoCashInvoiceRequest from 'Entities/CreateNoCashInvoiceRequest';
import { RoutePath, linkPathBuilder } from 'Lib/helpers/routes';
import { currencySymbol, EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import {
    onChangeCheckNumberOrFloat,
    onSubmitCheckNumberOrFloat,
    parseFloatWithComma,
} from 'Lib/helpers/helpers';

import s from './Form.module.pcss';
import { useIsPayer } from './utils';
import Actions from './Actions';

interface FormProps {
    details: AccountDetails;
    noLayout?: boolean;
}
const CashlessForm: FC<FormProps> = ({ details, noLayout }) => {
    const intl = useIntl();
    const initial = {
        amount: '',
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const { canPay } = useIsPayer();

    const { accountLimits: { minNocashPaymentAmount, maxNocashPaymentAmount } } = details;

    const onPay = (values: typeof initial, helpers: FormikHelpers<typeof initial>) => {
        if (!canPay) {
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.Onboarding));
            return;
        }
        const { amount } = values;
        const amountParsed = parseFloatWithComma(amount);

        if (!amountParsed || amountParsed < minNocashPaymentAmount
            || amountParsed > maxNocashPaymentAmount || !onSubmitCheckNumberOrFloat(amount)) {
            helpers.setFieldError('amount', EMPTY_FIELD_ERROR);
            helpers.setSubmitting(false);
            return;
        }
        const reqEnt = new CreateNoCashInvoiceRequest({ amount: amountParsed });
        dispatch(createAccountNoCashInvoice([reqEnt.serialize()], {
            result: () => {
                history.push(linkPathBuilder(intl.currentLocale, RoutePath.Documents));
            },
            error: () => {
                history.push(linkPathBuilder(intl.currentLocale, RoutePath.Billing));
                notifyError(intl.getMessage('pay_failed'), 'pay_failed');
            },
        }));
    };

    return (
        <Formik
            initialValues={initial}
            onSubmit={onPay}
        >
            {({
                setFieldValue,
                handleSubmit,
                errors,
                isSubmitting,
                values,
            }) => (
                <form onSubmit={handleSubmit} className={s.form} noValidate>
                    <div className={cn(s.desc, s.desc_small)}>
                        {intl.getMessage('cashless_form_desc_1')}
                    </div>
                    <div className={cn(s.desc, s.desc_small)}>
                        {intl.getMessage('cashless_form_desc_2')}
                    </div>
                    <Input
                        autoFocus
                        error={!!errors.amount}
                        name="amount"
                        onChange={(e) => {
                            if (onChangeCheckNumberOrFloat(e) || e === '') {
                                setFieldValue('amount', e);
                            }
                        }}
                        placeholder={intl.getMessage('add_funds_placeholder', { min: minNocashPaymentAmount, max: maxNocashPaymentAmount })}
                        size="large"
                        suffix={currencySymbol.RUB}
                        type="text"
                        validate={(e) => !!e}
                        value={values.amount}
                    />
                    <Actions
                        isSubmitting={isSubmitting}
                        submitText={intl.getMessage('cashless_form_submit')}
                        noLayout={noLayout}
                        disabled={!values.amount}
                    />
                </form>
            )}
        </Formik>
    );
};

export default CashlessForm;
