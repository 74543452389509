import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { createDnsRecords } from 'Actions/dns';
import { useEnter } from 'Hooks';
import { useIntl, Select, Option, notifyError } from 'Common';
import DnsRecord from 'Entities/DnsRecord';
import DnsRecordCreateList from 'Entities/DnsRecordCreateList';
import { DnsRecordType } from 'Entities/DnsRecordType';
import DnsRecordCreate from 'Entities/DnsRecordCreate';
import DnsZone from 'Entities/DnsZone';
import Error from 'Entities/Error';
import { EMPTY_FIELD_ERROR, TTL_VALUE } from 'Lib/helpers/consts';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { ipV4Validate, ipV6Validate } from 'Lib/helpers/utils';

import {
    ARecordForm,
    AaaaRecordForm,
    CnameRecordForm,
    MxRecordForm,
    SrvRecordForm,
    TxtRecordForm,
    RecordFormProps,
    RecordFormValues,
} from './Forms';

import s from './Form.module.pcss';

interface ManualPropsNew {
    closeForm: () => void;
    zone: DnsZone;
}
interface ManualPropsEdited {
    closeForm: () => void;
    onEdit: (newRecord: DnsRecord, helpers: FormikHelpers<RecordFormValues>) => void;
    initialValue: DnsRecord;
    zone: DnsZone;
}
type ManualProps = ManualPropsNew | ManualPropsEdited;
const Manual: FC<ManualProps> = (props) => {
    const { closeForm, onEdit, initialValue } = props as ManualPropsEdited;
    const { zone } = props as ManualPropsNew;
    const isEdited = !!initialValue;

    const intl = useIntl();
    const dispatch = useDispatch();

    const serialized = initialValue?.serialize();
    const initialValues = serialized
        ? {
            ...serialized,
            isEdited: true,
            port: String(serialized.port),
            priority: String(serialized.priority),
            weight: String(serialized.weight),
        } : {
            type: DnsRecordType.A,
            host: '',
            data: '',
            ttl_seconds: TTL_VALUE[0],
            isEdited: false,
        };

    const onSubmitNew = (newRecord: DnsRecordCreate, helpers: FormikHelpers<RecordFormValues>) => {
        const errors: FormikErrors<RecordFormValues> = {};
        const newRecords = new DnsRecordCreateList({
            records: [newRecord.serialize()], rewrite: false,
        });
        const { tenantId, id } = zone;
        dispatch(createDnsRecords([tenantId, id, newRecords.serialize()], {
            result: () => {
                closeForm();
            },
            error: (error: Error) => {
                const errorKeys = Object.keys(error.fields);
                if (errorKeys.length > 0) {
                    errorKeys.forEach((key) => {
                        const fieldError = error.fields[key][0];
                        const data = key.split('.')[1] as keyof FormikErrors<RecordFormValues>;
                        if (data === 'pointsTo' as keyof FormikErrors<RecordFormValues>) {
                            errors.data = apiErrorCodeTranslate(intl, fieldError.error_code);
                        }
                        errors[data] = apiErrorCodeTranslate(intl, fieldError.error_code);
                    });
                    helpers.setErrors(errors);
                } else {
                    notifyError(apiErrorCodeTranslate(intl, error.errorCode));
                }
            },
        }));
    };

    const isExist = (e: string | undefined) => !!e && !Number.isNaN(Number(e));

    const onSubmit = (values: RecordFormValues, helpers: FormikHelpers<RecordFormValues>) => {
        const { port, priority, weight, host, type } = values;
        const newRecord = new DnsRecordCreate({
            ...values,
            port: isExist(port) ? Number(port) : undefined,
            priority: isExist(priority) ? Number(priority) : undefined,
            weight: isExist(weight) ? Number(weight) : undefined,
            host: type === DnsRecordType.SRV && host.includes('.@')
                ? host.replace('.@', '')
                : host,
        });
        const notValid = newRecord.validate();
        const errors: FormikErrors<RecordFormValues> = {};
        if (notValid.length > 0) {
            notValid.forEach((key) => {
                errors[key as keyof FormikErrors<RecordFormValues>] = EMPTY_FIELD_ERROR;
            });
        }
        if ((newRecord.type === DnsRecordType.SRV || newRecord.type === DnsRecordType.MX)
         && !DnsRecordCreate.priorityValidate(newRecord.priority)) {
            errors.priority = EMPTY_FIELD_ERROR;
        }
        if (newRecord.type === DnsRecordType.SRV) {
            if (!DnsRecordCreate.weightValidate(newRecord.weight)) {
                errors.weight = EMPTY_FIELD_ERROR;
            }
            if (!DnsRecordCreate.portValidate(newRecord.port)) {
                errors.port = EMPTY_FIELD_ERROR;
            }
        }
        if (!newRecord.host) {
            errors.host = EMPTY_FIELD_ERROR;
        }
        if ((!newRecord.data)
        || (newRecord.type === DnsRecordType.A && !ipV4Validate(newRecord.data))
        || (newRecord.type === DnsRecordType.AAAA && !ipV6Validate(newRecord.data))
        ) {
            errors.data = EMPTY_FIELD_ERROR;
        }
        if (Object.keys(errors).length !== 0) {
            helpers.setErrors(errors);
            return;
        }
        if (isEdited) {
            onEdit(
                new DnsRecord({ ...initialValue.serialize(), ...newRecord.serialize() }),
                helpers,
            );
            return;
        }
        onSubmitNew(newRecord, helpers);
    };
    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({
                handleSubmit,
                values,
                errors,
                setFieldValue,
                dirty,
            }) => {
                const subFormProps: RecordFormProps = {
                    values,
                    zone,
                    setFieldValue,
                    errors,
                    onClose: closeForm,
                };
                const getRecordForm = (value: DnsRecordType) => {
                    const components: Record<DnsRecordType, JSX.Element> = {
                        [DnsRecordType.A]: <ARecordForm {...subFormProps} />,
                        [DnsRecordType.AAAA]: <AaaaRecordForm {...subFormProps} />,
                        [DnsRecordType.CNAME]: <CnameRecordForm {...subFormProps} />,
                        [DnsRecordType.MX]: <MxRecordForm {...subFormProps} />,
                        [DnsRecordType.SRV]: <SrvRecordForm {...subFormProps} />,
                        [DnsRecordType.TXT]: <TxtRecordForm {...subFormProps} />,
                    };
                    return components[value];
                };
                const submitIfDirty = () => {
                    if (dirty) {
                        handleSubmit();
                    } else {
                        closeForm();
                    }
                };
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEnter(submitIfDirty, [submitIfDirty]);
                return (
                    <form className={s.wrap} noValidate onSubmit={handleSubmit}>
                        {!isEdited && (
                            <div className={s.group}>
                                <div className={s.label}>
                                    {intl.getMessage('dns_record_type')}
                                </div>
                                <Select
                                    block
                                    id="type"
                                    size="medium"
                                    placeholder={intl.getMessage('dns_record_type_placeholder')}
                                    className="select--setting"
                                    value={values.type}
                                    onChange={(v) => setFieldValue('type', v)}
                                >
                                    <Option value={DnsRecordType.A}>
                                        {DnsRecordType.A}
                                    </Option>
                                    <Option value={DnsRecordType.MX}>
                                        {DnsRecordType.MX}
                                    </Option>
                                    <Option value={DnsRecordType.CNAME}>
                                        {DnsRecordType.CNAME}
                                    </Option>
                                    <Option value={DnsRecordType.TXT}>
                                        {DnsRecordType.TXT}
                                    </Option>
                                    <Option value={DnsRecordType.SRV}>
                                        {DnsRecordType.SRV}
                                    </Option>
                                    <Option value={DnsRecordType.AAAA}>
                                        {DnsRecordType.AAAA}
                                    </Option>
                                </Select>
                            </div>
                        )}
                        {getRecordForm(values.type)}
                    </form>
                );
            }}
        </Formik>
    );
};

export default Manual;
