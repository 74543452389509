import React, { FC } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { cancelInstallation } from 'Actions/server';
import { useIntl, Button, Icon } from 'Common';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import Server from 'Entities/Server';
import Error from 'Entities/Error';
import OperationProgress from 'Entities/OperationProgress';
import Tenant from 'Entities/Tenant';
import { DistributionFamily } from 'Entities/DistributionFamily';
import theme from 'Lib/theme';

import CommonHeader from './CommonHeader';
import s from '../Header.module.pcss';

interface ErrorProps {
    server: Server;
    onboardingLink?: RoutePath | null;
    error: Error;
    progress: OperationProgress;
    compact?: boolean;
    tenant: Tenant;
    onClick: () => void;
}

const ErrorState: FC<ErrorProps> = ({
    server,
    error,
    onboardingLink,
    progress,
    tenant,
    onClick,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const onCancelInstall = () => {
        dispatch(cancelInstallation([server.tenantId, server.id, progress.id]));
    };

    const onMainClick = () => {
        if (onboardingLink) {
            history.push(linkPathBuilder(
                intl.currentLocale,
                onboardingLink,
                undefined,
                { showBanner: true },
            ));
            return;
        }
        onClick();
    };

    const balanceError = (error?.errorCode === ApiErrorCode.SETUP_NOT_ENOUGH_BALANCE
        || error?.errorCode === ApiErrorCode.NOT_ENOUGH_BALANCE);

    const popoverContent = (
        <div className={theme.popover.popover}>
            <button
                type="button"
                className={cn(theme.popover.item)}
                onClick={onMainClick}
            >
                {!onboardingLink
                    ? intl.getMessage('server_setup_start_short')
                    : intl.getMessage('server_install_retry')}
            </button>
            <button
                type="button"
                className={theme.popover.item}
                onClick={onCancelInstall}
            >
                {intl.getMessage('cancel')}
            </button>
            {!onboardingLink && error && (
                <>
                    <div className={theme.popover.divider} />
                    <div className={theme.popover.description}>
                        {error.errorCode
                            ? apiErrorCodeTranslate(intl, error.errorCode)
                            : intl.getMessage('server_setup_needed')}
                    </div>
                </>
            )}
            {onboardingLink && balanceError && (
                <>
                    <div className={theme.popover.divider} />
                    <div className={theme.popover.description}>
                        {server.distribution.type === DistributionFamily.WINDOWS ? (
                            intl.getMessage('server_install_error_popover_desc_win')
                        ) : (
                            intl.getMessage('server_install_error_popover_desc_not_win')
                        )}
                    </div>
                </>
            )}
            {onboardingLink && !balanceError && error && (
                <>
                    <div className={theme.popover.divider} />
                    <div className={theme.popover.description}>
                        {apiErrorCodeTranslate(intl, error.errorCode)}
                    </div>
                </>
            )}
        </div>
    );

    const content = (
        <Popover
            trigger="click"
            placement="bottomRight"
            content={popoverContent}
            overlayClassName="popover"
        >
            <Button
                size="medium"
                type="border"
                className={cn(
                    theme.button.action,
                    s.action,
                )}
            >
                <div className={s.dot} />
                <div className={s.title}>
                    {intl.getMessage('error')}
                </div>
                <Icon icon="down" className={s.down} />
            </Button>
        </Popover>
    );

    return (
        <CommonHeader
            server={server}
            tenant={tenant}
            className={s.header_trial}
            disabled
        >
            {content}
        </CommonHeader>
    );
};

export default ErrorState;
