import React, { FC, ChangeEvent } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';

import { useIntl, Button, Icon } from 'Common';
import { fileValidator } from 'Lib/helpers/newTicket';
import { SUPPORT_MAX_FILES, SUPPORT_MAX_FILE_SIZE_MB } from 'Lib/helpers/consts';
import { Store } from 'Store';
import theme from 'Lib/theme';

import s from './Files.module.pcss';

interface FilesProps {
    profile: Store['profile']['info'];
    files: File[];
    setFiles: (file: File[]) => void;
}

const Files: FC<FilesProps> = ({
    profile, files, setFiles,
}) => {
    const intl = useIntl();

    if (!profile) {
        return null;
    }

    const onFileAdd = (e: ChangeEvent<HTMLInputElement>) => {
        const max = profile.profileLimits.maxAttachmentsSizeBytes;
        const newFiles = [...files, ...Array.from(e.target.files!)];
        setFiles(fileValidator(intl, max, newFiles));
    };

    const getMbFromBytes = (size: number) => Math.round((size / 1024 / 1024) * 100) / 100;

    const getFileType = (file: File) => {
        const pointIndex = file.name.lastIndexOf('.') + 1;
        return pointIndex === 0 ? '' : file.name.substr(pointIndex);
    };

    const popoverContent = () => {
        return (
            <div className={theme.popover.popover}>
                {files.map((f, i) => (
                    <div
                        className={theme.popover.file}
                        key={`${f.lastModified} ${Math.random()} popover`}
                    >
                        <span className={theme.popover.fileName}>
                            {f.name}
                        </span>

                        <button
                            type="button"
                            className={theme.popover.fileAction}
                            onClick={() => {
                                const newFiles = [...files];
                                newFiles.splice(i, 1);
                                setFiles(newFiles);
                            }}
                        >
                            <Icon icon="delete" />
                        </button>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <>
            <label htmlFor="attachedFiles" className={s.label}>
                <Button
                    type="border"
                    className={cn(
                        theme.button.attach,
                        s.attach,
                    )}
                    icon="link"
                    iconClassName={s.attachIcon}
                    disabled={files.length === SUPPORT_MAX_FILES}
                    title={intl.getMessage('title_file_add')}
                >
                    <div>
                        <div className={s.attachTitle}>
                            {intl.getMessage('file_attachment')}
                        </div>
                        <div className={s.attachDesc}>
                            {intl.getPlural('file_requirements', SUPPORT_MAX_FILES, { size: SUPPORT_MAX_FILE_SIZE_MB })}
                        </div>
                    </div>
                </Button>
                <input
                    id="attachedFiles"
                    multiple
                    type="file"
                    className={s.input}
                    onChange={onFileAdd}
                    disabled={files.length === SUPPORT_MAX_FILES}
                />
            </label>
            {files.length > 0 && (
                <>
                    <div className={s.files}>
                        <Popover
                            content={popoverContent}
                            placement="bottomRight"
                            trigger="click"
                            overlayClassName="popover"
                        >
                            <div
                                className={cn(
                                    theme.Badge.badge,
                                    theme.Badge.attachment,
                                    theme.Badge.attachment_compact,
                                )}
                            >
                                {intl.getPlural('files_count', files.length)}
                            </div>
                        </Popover>
                    </div>
                    <div className={cn(s.files, s.files_desktop)}>
                        {files.map((f, i) => (
                            <div className={s.file} key={`${f.lastModified} ${Math.random()}`}>
                                <div className={s.fileName}>{f.name}</div>
                                <div className={s.fileDesc}>
                                    <span className={s.fileType}>
                                        {getFileType(f)}
                                    </span>
                                    <span className={s.fileSize}>
                                        {intl.getMessage('value_mb', { value: getMbFromBytes(f.size) })}
                                    </span>
                                </div>
                                <div
                                    className={s.fileDelete}
                                    onClick={() => {
                                        const newFiles = [...files];
                                        newFiles.splice(i, 1);
                                        setFiles(newFiles);
                                    }}
                                >
                                    <Icon icon="close_small" />
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default Files;
