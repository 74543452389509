import ComparativeExpenses, { IComparativeExpenses } from './ComparativeExpenses';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountServersComparativeExpenses {
    overall_comparative_expense: IComparativeExpenses;
    server_comparative_expenses: IComparativeExpenses[];
}

export default class AccountServersComparativeExpenses {
    readonly _overall_comparative_expense: ComparativeExpenses;

    get overallComparativeExpense(): ComparativeExpenses {
        return this._overall_comparative_expense;
    }

    readonly _server_comparative_expenses: ComparativeExpenses[];

    /** */
    get serverComparativeExpenses(): ComparativeExpenses[] {
        return this._server_comparative_expenses;
    }

    constructor(props: IAccountServersComparativeExpenses) {
        this._overall_comparative_expense = new ComparativeExpenses(props.overall_comparative_expense);
        this._server_comparative_expenses = props.server_comparative_expenses.map((p) => new ComparativeExpenses(p));
    }

    serialize(): IAccountServersComparativeExpenses {
        const data: IAccountServersComparativeExpenses = {
            overall_comparative_expense: this._overall_comparative_expense.serialize(),
            server_comparative_expenses: this._server_comparative_expenses.map((p) => p.serialize()),
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            overall_comparative_expense: this._overall_comparative_expense.validate().length === 0,
            server_comparative_expenses: this._server_comparative_expenses.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountServersComparativeExpenses): AccountServersComparativeExpenses {
        return new AccountServersComparativeExpenses(props);
    }

    readonly keys: { [key: string]: string } = {
        overallComparativeExpense: 'overall_comparative_expense',
        serverComparativeExpenses: 'server_comparative_expenses',
        }
;

    mergeDeepWith(props: Partial<AccountServersComparativeExpenses>): AccountServersComparativeExpenses {
        const updateData: Partial<IAccountServersComparativeExpenses> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountServersComparativeExpenses) => {
            const updateKey = this.keys[key] as keyof IAccountServersComparativeExpenses;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountServersComparativeExpenses, keyof IAccountServersComparativeExpenses>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountServersComparativeExpenses({ ...this.serialize(), ...updateData });
    }
}
