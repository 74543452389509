import React, { FC } from 'react';
import { Layout } from 'antd';
import cn from 'classnames';

import { Icon } from 'Common';

import s from './Loader.module.pcss';

interface LoaderProps {
    mini?: boolean;
    circle?: boolean;
    inButton?: boolean;
    full?: boolean;
    className?: string;
    inline?: boolean;
}
const Loader: FC<LoaderProps> = ({ mini, circle, inButton, full, className, inline }) => {
    if (inline) {
        return (
            <div className={cn(s.loader, s.mini)}>
                <Icon icon="loading" className={s.spin} />
            </div>
        );
    }

    return (
        <Layout
            id="loader"
            className={cn(
                s.loader,
                { [s.mini]: mini || circle },
                { [s.full]: full },
            )}
        >
            {circle ? (
                <Icon icon="loading" className={cn(s.spin, { [s.spin_button]: inButton }, className)} />
            ) : (
                <div className={s.brandLoader}>
                    <Icon icon="panel_logo" className={s.logo} />
                    <div className={s.bars}>
                        <div className={s.bar} />
                        <div className={s.bar} />
                        <div className={s.bar} />
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default Loader;
