// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPartnerLimits {
    min_withdrawal_amount: number;
}

export default class PartnerLimits {
    readonly _min_withdrawal_amount: number;

    /**
     * Description: Minimal possible withdrawal amount, RUB
     * Example: 10
     */
    get minWithdrawalAmount(): number {
        return this._min_withdrawal_amount;
    }

    static minWithdrawalAmountValidate(minWithdrawalAmount: number): boolean {
        return typeof minWithdrawalAmount === 'number';
    }

    constructor(props: IPartnerLimits) {
        this._min_withdrawal_amount = props.min_withdrawal_amount;
    }

    serialize(): IPartnerLimits {
        const data: IPartnerLimits = {
            min_withdrawal_amount: this._min_withdrawal_amount,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            min_withdrawal_amount: typeof this._min_withdrawal_amount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPartnerLimits): PartnerLimits {
        return new PartnerLimits(props);
    }

    readonly keys: { [key: string]: string } = {
        minWithdrawalAmount: 'min_withdrawal_amount',
        }
;

    mergeDeepWith(props: Partial<PartnerLimits>): PartnerLimits {
        const updateData: Partial<IPartnerLimits> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PartnerLimits) => {
            const updateKey = this.keys[key] as keyof IPartnerLimits;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPartnerLimits, keyof IPartnerLimits>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PartnerLimits({ ...this.serialize(), ...updateData });
    }
}
