import Account from 'Entities/Account';
import AccountCreditCard from 'Entities/AccountCreditCard';
import AccountDashboard from 'Entities/AccountDashboard';
import AccountDetails from 'Entities/AccountDetails';
import AccountDocument from 'Entities/AccountDocument';
import { IAccountExpense } from 'Entities/AccountExpense';
import AccountExpensesDetailed from 'Entities/AccountExpensesDetailed';
import Configuration from 'Entities/Configuration';
import CountriesPhoneCodesResponse from 'Entities/CountriesPhoneCodesResponse';
import DnsPtrRecord from 'Entities/DnsPtrRecord';
import DnsRecord from 'Entities/DnsRecord';
import DnsSettings from 'Entities/DnsSettings';
import DnsZone from 'Entities/DnsZone';
import Error from 'Entities/Error';
import Notification from 'Entities/Notification';
import { NotificationDeliveryType } from 'Entities/NotificationDeliveryType';
import NotificationRecipient from 'Entities/NotificationRecipient';
import NotificationSettings from 'Entities/NotificationSettings';
import OperationProgress from 'Entities/OperationProgress';
import PartnerComparativeDetails from 'Entities/PartnerComparativeDetails';
import PartnerDetails from 'Entities/PartnerDetails';
import PartnerPayment from 'Entities/PartnerPayment';
import PartnerStats from 'Entities/PartnerStats';
import PayerInfo from 'Entities/PayerInfo';
import Profile from 'Entities/Profile';
import ProfileLoginAttempts from 'Entities/ProfileLoginAttempt';
import PublicKey from 'Entities/PublicKey';
import ResellerClient from 'Entities/ResellerClient';
import ResellerDetails from 'Entities/ResellerDetails';
import ResellerPayment from 'Entities/ResellerPayment';
import SecurityActionSettings from 'Entities/SecurityActionSettings';
import { SecurityActionType } from 'Entities/SecurityActionType';
import Server from 'Entities/Server';
import ServerBackup from 'Entities/ServerBackup';
import ServerCostForecast from 'Entities/ServerCostForecast';
import ServerDistribution from 'Entities/ServerDistribution';
import ServerResourceLimits from 'Entities/ServerResourceLimits';
import ServerStateEvent from 'Entities/ServerStateEvent';
import Snapshot from 'Entities/Snapshot';
import Tariff from 'Entities/Tariff';
import Tenant from 'Entities/Tenant';
import TenantUser from 'Entities/TenantUser';
import Ticket from 'Entities/Ticket';
import TicketsInfo from 'Entities/TicketsInfo';
import Trigger from 'Entities/Trigger';
import ServerInfo from 'Entities/ServerInfo';
import AppConfig from 'Lib/helpers/appConfig';
import { DNS_ZONE_SORT, SEARCH_TYPE, SERVER_OPERATION_NOTIFICATION, SERVER_VIEW } from 'Lib/helpers/consts';
import Translator, { DEFAULT_LOCALE, messages, Locale, reactFormater } from 'Lib/intl';
import { ActionType } from './actions';

AppConfigStorage = new AppConfig();

export interface AccountStore {
    account: Account | null;
    details: AccountDetails | null;
    cards: Map<number, AccountCreditCard> | null;
    documents: AccountDocument[] | null;
    dashboard: AccountDashboard | null;
    detailedExpenses: AccountExpensesDetailed | null;
    accountExpensesJSON: IAccountExpense[] | null;
    servers: Map<number, Server>;
}
export const accountInitialState: AccountStore = {
    account: null,
    details: null,
    cards: null,
    documents: null,
    dashboard: null,
    detailedExpenses: null,
    accountExpensesJSON: null,
    servers: new Map<number, Server>(),
};

export interface BatchOperationProgressStore {
    servers: number[];
    errors: ServerInfo[];
    progress: number | null;
    publicKeys?: number[];
    type: SecurityActionType | null;
}
export const batchOperationProgressInitialState: BatchOperationProgressStore = {
    servers: [],
    progress: null,
    errors: [],
    publicKeys: [],
    type: null,
};

export type DistributionStore = Map<number, ServerDistribution>;
export const distributionInitialState: DistributionStore = new Map<number, ServerDistribution>();

export interface DnsStore {
    zones: Map<number, DnsZone> | null;
    sortedZones: Map<DNS_ZONE_SORT, number[]> | null;
    settings: DnsSettings | null;
    records: Map<number, DnsRecord> | null;
}
export const dnsInitialState: DnsStore = {
    zones: null,
    sortedZones: null,
    settings: null,
    records: new Map<number, DnsRecord>(),
};

type ErrorEntity = { error: Error; operation?: OperationProgress };
export type ErrorStore = Map<ActionType, ErrorEntity>;
export const errorInitialState: ErrorStore = new Map<ActionType, ErrorEntity>();

export type InstallingServersStore = {
    progress: Map<number, OperationProgress>;
    servers: Map<number, Server>;
};
export const installingServersInitialState: InstallingServersStore = {
    progress: new Map<number, OperationProgress>(),
    servers: new Map<number, Server>(),
};

export type LocaleStore = Translator<Locale>;
export const localesInitialState: LocaleStore = new Translator(
    DEFAULT_LOCALE,
    messages,
    DEFAULT_LOCALE,
    reactFormater,
);

export interface MainAccountStore {
    account: Account | null;
    details: AccountDetails | null;
    profile: Profile | null;
    clients: ResellerClient[] | null;
}
export const mainAccountInitialState: MainAccountStore = {
    account: null,
    details: null,
    profile: null,
    clients: null,
};

export type NewServerStore = {
    resourseLimits: ServerResourceLimits | null;
    price: ServerCostForecast | null;
};
export const newServerInitialState: NewServerStore = {
    resourseLimits: null,
    price: null,
};

export interface NotificationsStore {
    notifications: Notification[] | null;
    recipients: NotificationRecipient[];
    settings: NotificationSettings | null;
    triggers: Trigger[] | null;
}
export const notificationsInitialState: NotificationsStore = {
    notifications: null,
    recipients: [],
    settings: null,
    triggers: null,
};

export type OperationProgressStore = {
    commonOperations: Map<number, OperationProgress>;
    backupOperations: Map<number, OperationProgress>;
};
export const operationProgressInitialState: OperationProgressStore = {
    commonOperations: new Map<number, OperationProgress>(),
    backupOperations: new Map<number, OperationProgress>(),
};

export interface PartnerStore {
    details: PartnerDetails | null;
    comparativeDetails: PartnerComparativeDetails | null;
    paymentsComplete: PartnerPayment[] | null;
    paymentsIncomplete: PartnerPayment[] | null;
    stats: PartnerStats[] | null;
}

export const partnerInitialState: PartnerStore = {
    details: null,
    comparativeDetails: null,
    paymentsComplete: null,
    paymentsIncomplete: null,
    stats: null,
};

export interface ProfileStore {
    info: Profile | null;
    loginAttempts: ProfileLoginAttempts[];
    profilePayer: PayerInfo[] | null;
    phoneCodes: CountriesPhoneCodesResponse | null;
    configuration: Configuration | null;
}
export const profileInitialState: ProfileStore = {
    info: null,
    loginAttempts: [],
    profilePayer: null,
    phoneCodes: null,
    configuration: null,
};

export type PublicKeysStore = Map<number, PublicKey> | null;
export const publicKeysInitialState: PublicKeysStore = null;

export interface ResellerStore {
    details: ResellerDetails | null;
    paymentsComplete: ResellerPayment[];
    paymentsIncomplete: ResellerPayment[];
    clients: ResellerClient[];
}
export const resellerInitialState: ResellerStore = {
    details: null,
    paymentsComplete: [],
    paymentsIncomplete: [],
    clients: [],
};

export type ReverseDnsStore = Map<string, DnsPtrRecord> | null;
export const reverseDnsInitialState: ReverseDnsStore = new Map<string, DnsPtrRecord>();

export interface SecurityStore {
    settings: SecurityActionSettings | null;
    deliveryType: NotificationDeliveryType | null;
}
export const securityInitialState: SecurityStore = {
    settings: null,
    deliveryType: null,
};

export type ServerStore = Map<number, Server>;
export const serverInitialState: ServerStore = new Map<number, Server>();

export interface ServerBackupStore {
    backups: Map<string, ServerBackup>;
    progress: Map<string, OperationProgress>;
}
export const serverBackupInitialState: ServerBackupStore = {
    backups: new Map<string, ServerBackup>(),
    progress: new Map<string, OperationProgress>(),
};

export type ServerStateEventStore = ServerStateEvent[] | null;
export const serverStateEventInitialState: ServerStateEventStore = null;

export interface SnapshotsStore {
    snapshots: Map<number, Snapshot>;
    progress: Map<number, OperationProgress>;
}
export const snapshotsInitialState: SnapshotsStore = {
    snapshots: new Map<number, Snapshot>(),
    progress: new Map<number, OperationProgress>(),
};

export interface SupportStore {
    tickets: Ticket[] | null;
    currentTicket: Ticket | null;
    ticketsInfo: TicketsInfo | null;
}
export const supportInitialState: SupportStore = {
    tickets: null,
    currentTicket: null,
    ticketsInfo: null,
};

export type TariffStore = Map<number, Tariff>;
export const tariffInitialState: TariffStore = new Map<number, Tariff>();

export type TenantStore = Map<number, Tenant>;
export const tenantInitialState: TenantStore = new Map<number, Tenant>();

export type TenantUsersStore = Map<number, TenantUser[]>;
export const tenantUserInitialState: TenantUsersStore = new Map<number, TenantUser[]>();

export interface UiStore {
    sidebarOpen: boolean;
    operationNotifications: Record<SERVER_OPERATION_NOTIFICATION, boolean>;
    serverView: SERVER_VIEW;
    searchType: SEARCH_TYPE;
}
export const uiInitialState: UiStore = {
    sidebarOpen: false,
    operationNotifications: {
        [SERVER_OPERATION_NOTIFICATION.REBOOT]: true,
        [SERVER_OPERATION_NOTIFICATION.RESET]: true,
        [SERVER_OPERATION_NOTIFICATION.SHUTDOWN]: true,
        [SERVER_OPERATION_NOTIFICATION.DESTROY]: true,
    },
    serverView: SERVER_VIEW.EXPAND,
    searchType: SEARCH_TYPE.STRICT,
};

export interface UserStore {
    loggedIn: boolean;
    token: string | null;
    mainToken: string | null;
    email: string;
    userId?: number;
    loading: boolean;
    isChecked: boolean;
    haveNewVersion: boolean;
}
export const userInitialState: UserStore = {
    loggedIn: false,
    token: AppConfigStorage.getAuthToken(),
    mainToken: AppConfigStorage.getMainToken(),
    email: '',
    loading: !!AppConfigStorage.getAuthToken(),
    isChecked: false,
    haveNewVersion: false,
};
