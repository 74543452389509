import React, { FC } from 'react';
import { Input } from 'antd';

import { Modal, useIntl } from 'Common';
import Server from 'Entities/Server';
import OperationProgress from 'Entities/OperationProgress';

import List from './List';
import s from './Search.module.pcss';

interface CreateModalProps {
    server: Server;
    servers: Server[];
    searchQuery: string;
    operationProgress?: OperationProgress;
    setSearchQuery: (e: string) => void;
    handleClose: () => void;
}

const CreateModal: FC<CreateModalProps> = ({
    server,
    servers,
    searchQuery,
    operationProgress,
    setSearchQuery,
    handleClose,
}) => {
    const intl = useIntl();

    return (
        <Modal
            visible
            wrapClassName="modal modal_server-search"
            onCancel={handleClose}
            title={null}
            width={288}
            footer={null}
        >
            <div className={s.inputModal}>
                <Input
                    id="search"
                    type="text"
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="input input_medium input_borderless"
                    value={searchQuery}
                    placeholder={intl.getMessage('search_placeholder')}
                    autoComplete="off"
                    autoFocus
                />
            </div>
            <List
                server={server}
                servers={servers}
                searchQuery={searchQuery}
                operationProgress={operationProgress}
                handleClose={handleClose}
            />
        </Modal>
    );
};

export default CreateModal;
