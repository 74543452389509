import React, { FC } from 'react';
import cn from 'classnames';

import { Icon } from 'Common';
import s from './Warning.module.pcss';

interface WarningProps {
    text: string;
    className?: string;
    type?: 'warning' | 'info';
}

const Warning: FC<WarningProps> = ({ text, className, type }) => {
    return (
        <div
            className={cn(
                s.warning,
                className,
                { [s.warning_info]: type === 'info' },
            )}
        >
            <Icon icon="attention" className={s.icon} />
            {text}
        </div>
    );
};

export default Warning;
