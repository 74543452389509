// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITenantUpdate {
    description?: string;
    is_default?: boolean;
}

export default class TenantUpdate {
    readonly _description: string | undefined;

    /**
     * Description: Project name
     * Example: Development
     */
    get description(): string | undefined {
        return this._description;
    }

    static get descriptionMinLength() {
        return 0;
    }

    static get descriptionMaxLength() {
        return 70;
    }

    static descriptionValidate(description: string | undefined): boolean {
        return (!description ? true : description.length > 0) && (!description ? true : description.length <= 70);
    }

    readonly _is_default: boolean | undefined;

    /**
     * Description: Is default tenant
     * Example: true
     */
    get isDefault(): boolean | undefined {
        return this._is_default;
    }

    constructor(props: ITenantUpdate) {
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        if (typeof props.is_default === 'boolean') {
            this._is_default = props.is_default;
        }
    }

    serialize(): ITenantUpdate {
        const data: ITenantUpdate = {
        };
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._is_default !== 'undefined') {
            data.is_default = this._is_default;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            description: (!this._description ? true : this._description.length > 0) && (!this._description ? true : this._description.length <= 70),
            is_default: !this._is_default ? true : typeof this._is_default === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITenantUpdate): TenantUpdate {
        return new TenantUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        description: 'description',
        isDefault: 'is_default',
        }
;

    mergeDeepWith(props: Partial<TenantUpdate>): TenantUpdate {
        const updateData: Partial<ITenantUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TenantUpdate) => {
            const updateKey = this.keys[key] as keyof ITenantUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITenantUpdate, keyof ITenantUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TenantUpdate({ ...this.serialize(), ...updateData });
    }
}
