import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Popover } from 'antd';
import cn from 'classnames';

import { useIntl, Button } from 'Common';
import theme from 'Lib/theme';
import OperationProgress from 'Entities/OperationProgress';
import { cancelInstallation, forceInstall } from 'Actions/server';

interface SetupOptionsProps {
    progress: OperationProgress;
    onlyCancel?: boolean;
}

const SetupOptions: FC<SetupOptionsProps> = ({ progress, onlyCancel }) => {
    const intl = useIntl();
    const [openPopover, setOpenPopover] = useState(false);
    const dispatch = useDispatch();

    const { tenantId, serverId, id } = progress;

    const onForceInstall = () => {
        dispatch(forceInstall([tenantId, serverId, id]));
    };
    const onCancelInstall = () => {
        dispatch(cancelInstallation([tenantId, serverId, id]));
    };

    const options = (
        <div className={theme.popover.popover}>
            {!onlyCancel && (
                <button
                    type="button"
                    className={theme.popover.item}
                    onClick={onForceInstall}
                >
                    {intl.getMessage('install_forcibly')}
                </button>
            )}
            <button
                type="button"
                className={cn(theme.popover.item, theme.popover.item_danger)}
                onClick={onCancelInstall}
            >
                {intl.getMessage('undo')}
            </button>
        </div>
    );

    return (
        <>
            <Popover
                content={options}
                placement="leftTop"
                trigger="click"
                visible={openPopover}
                onVisibleChange={(v) => setOpenPopover(v)}
                overlayClassName="popover"
            >
                <Button
                    size="medium"
                    type="border"
                    icon="options"
                    className={theme.button.only_icon}
                />
            </Popover>
        </>
    );
};

export default SetupOptions;
