import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CodeInput, Loader, notifyError, notifySuccess, useIntl, whiteText } from 'Common';
import Server from 'Entities/Server';
import cn from 'classnames';
import { updateServerKeys } from 'Actions/server';
import { PublicKeyInstallationState } from 'Entities/PublicKeyInstallationState';
import { useSecurityBatchActions } from 'Hooks';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { OperationType } from 'Entities/OperationType';
import ServersCommonPublicKeys from 'Entities/ServersCommonPublicKeys';
import theme from 'Lib/theme';
import KeysSelect, { InitialState } from '../KeysSelect/KeysSelect';
import UpdateKeysModalLayout from '../UpdateKeysModalLayout';

import s from './ModalUpdateKeys.module.pcss';

interface ModalUpdateKeysProps {
    visible: boolean;
    onClose: () => void;
    servers: Set<Server>;
    availableKeys: ServersCommonPublicKeys | undefined;
    id: number;
}

interface FailedToUpdateServer {
    id: number;
    name: string;
}

const ModalUpdateKeys: FC<ModalUpdateKeysProps> = ({
    visible,
    onClose,
    servers,
    availableKeys,
    id,
}) => {
    const intl = useIntl();
    const [updateData, setUpdateData] = useState<any>();
    const [disabledStatus, setDisabledStatus] = useState<boolean>(true);
    const [updateKeys, setUpdateKeys] = useState<number[]>([]);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);

    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityBatchActions(SecurityActionType.SERVER_PUBLIC_KEY, true);

    const sIds = Array.from(servers).map((server) => server.id);

    const dispatch = useDispatch();

    const title = intl.getMessage('modal_update_keys');
    const buttonText = intl.getMessage('modal_update_keys_button');
    const desc = intl.getMessage('modal_update_keys_description');

    const checkData = () => {
        if (!updateData || !updateData.length) {
            setDisabledStatus(true);
        } else {
            setDisabledStatus(false);
        }
    };

    const callBacker = () => {
        onClose();
        notifyError((
            <div className={theme.notification.wrapper}>
                <div className={theme.notification.text}>
                    {intl.getPlural('batch_add_public_keys_loader', servers.size, {
                        b: whiteText,
                    })}
                </div>
                <div className={theme.color.blue}>
                    <Loader circle mini className={theme.notification.loader} />
                </div>
            </div>
        ), undefined, {
            key: OperationType.PUBLIC_KEY_CHANGE,
            duration: 0,
            className: cn(theme.notification.customNotification, 'notification_event'),
        });
        return (serversNames?: FailedToUpdateServer[]) => {
            if (!serversNames) {
                notifySuccess((
                    <div className={theme.notification.text}>
                        {intl.getPlural('batch_add_public_keys_success', servers.size, { b: whiteText })}
                    </div>
                ), undefined, {
                    className: theme.notification.customNotification,
                });
            } else {
                notifyError(
                    <div className={theme.notification.text}>
                        {intl.getPlural('batch_add_public_keys_error', serversNames.length, { b: whiteText })}
                        <div className={s.serversList}>
                            {serversNames.map(({ id: serverId, name }) => (
                                <div className={s.serverItem} key={serverId}>
                                    {name}
                                </div>
                            ))}
                        </div>
                    </div>,
                );
            }
        };
    };

    const getKeysForChange = (data: InitialState) => {
        const resultKeys: number[] = [];
        data.forEach((value, key) => {
            if (value.status === PublicKeyInstallationState.ALL_SERVERS) {
                resultKeys.push(key);
            }
        });
        if (resultKeys.length > 0) {
            setUpdateKeys(resultKeys);
        } else {
            setUpdateKeys([]);
        }
    };

    useEffect(() => {
        checkData();
    }, [updateData]);

    const handleOk = () => {
        if (shouldConfirmAction && !codeSent) {
            sendConfirmationCode({
                tenantId: id,
                serverIds: sIds,
            });
        }
        if (!shouldConfirmAction) {
            dispatch(updateServerKeys({
                data: [id,
                    {
                        server_ids: sIds,
                        public_keys: updateKeys,
                        actionType: SecurityActionType.SERVER_PUBLIC_KEY,
                    },
                ],
                notificationId: OperationType.PUBLIC_KEY_CHANGE,
            }, {
                result: callBacker,
            }));
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(updateServerKeys({
                data: [id,
                    {
                        server_ids: sIds,
                        public_keys: updateKeys,
                        security_code: code,
                        actionType: SecurityActionType.SERVER_PUBLIC_KEY,
                    },
                ],
                notificationId: OperationType.PUBLIC_KEY_CHANGE,
            }, {
                result: callBacker,
            }));
        }
    };

    return (
        <UpdateKeysModalLayout
            visible={visible}
            title={title}
            buttonText={buttonText}
            handleUpdate={handleOk}
            handleClose={onClose}
            disabled={disabledStatus}
        >
            <KeysSelect
                servers={servers}
                availableKeys={availableKeys?.commonKeys}
                updateData={updateData}
                onChange={(data) => setUpdateData(data)}
                getKeysForChange={getKeysForChange}
            />
            <div className={cn('modal__desc', s.description)}>
                {desc}
            </div>
            {(codeSent && deliveryMessage) && (
                <>
                    <div className="modal__desc modal__desc--gray">
                        {deliveryMessage}
                    </div>
                    <CodeInput
                        value={code}
                        setValue={(e) => setCode(e)}
                        codeError={codeError}
                        setCodeError={(e) => setCodeError(e)}
                        onSendAgain={() => sendConfirmationCode({
                            tenantId: id,
                            serverIds: sIds,
                        })}
                    />
                </>
            )}
        </UpdateKeysModalLayout>
    );
};

export default ModalUpdateKeys;
