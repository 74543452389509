import React, { Component, ReactNode } from 'react';

import { ErrorPage } from './ErrorPage';

export default class ErrorBoundary extends Component {
    state = {
        isError: false,
    };

    static getDerivedStateFromError(): { isError: boolean } {
        return { isError: true };
    }

    render(): ReactNode {
        const { isError } = this.state;
        const { children } = this.props;

        return isError ? <ErrorPage /> : children;
    }
}
