import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl } from 'Common';
import { externalLinkBuilder, GuestRoutePath } from 'Lib/helpers/routes';
import { TRIAL_AMOUNT } from 'Lib/helpers/consts';
import ReferralInfo from 'Entities/ReferralInfo';
import theme from 'Lib/theme';

import discountImage from 'Lib/assets/images/discount.png';
import discount2xImage from 'Lib/assets/images/discount@2x.png';

import s from './RefNotification.module.pcss';

interface RefNotificationProps {
    referralInfo: ReferralInfo;
}

const RefNotification: FC<RefNotificationProps> = ({ referralInfo }) => {
    const intl = useIntl();
    const { referrerRegDiscount } = referralInfo;

    return (
        <div className={s.notification}>
            <img
                className={s.img}
                src={discountImage}
                srcSet={`${discount2xImage} 2x`}
                alt={intl.getMessage('learn_more')}
            />
            <div className={s.desc}>
                {intl.getMessage('registration_referral', {
                    sum: intl.getPlural('price_full', TRIAL_AMOUNT),
                    discount: `${referrerRegDiscount}%`,
                })}
            </div>
            <a
                href={externalLinkBuilder(intl.currentLocale, GuestRoutePath.Partner)}
                className={cn(
                    theme.link.link,
                    theme.link.white,
                )}
                target="_blank"
                rel="noreferrer"
            >
                {intl.getMessage('learn_more')}
            </a>
        </div>
    );
};

export default RefNotification;
