import { RecurringSubscriptionRebillSettings } from './RecurringSubscriptionRebillSettings';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountAutoTopUpSettingsUpdate {
    active?: boolean;
    min_balance?: number;
    top_up_amount_card?: number;
    top_up_amount_partner?: number;
    top_up_amount_reseller?: number;
    top_up_settings_card?: RecurringSubscriptionRebillSettings;
    top_up_settings_partner?: RecurringSubscriptionRebillSettings;
    top_up_settings_reseller?: RecurringSubscriptionRebillSettings;
}

export default class AccountAutoTopUpSettingsUpdate {
    readonly _active: boolean | undefined;

    /**
     * Description: If top-up from credit card activated
     * Example: true
     */
    get active(): boolean | undefined {
        return this._active;
    }

    readonly _min_balance: number | undefined;

    /**
     * Description: User account balance threshold for auto top-up
     * Example: 100
     */
    get minBalance(): number | undefined {
        return this._min_balance;
    }

    readonly _top_up_amount_card: number | undefined;

    /**
     * Description: Top-up amount to charge from card
     * Example: 2430
     */
    get topUpAmountCard(): number | undefined {
        return this._top_up_amount_card;
    }

    readonly _top_up_amount_partner: number | undefined;

    /**
     * Description: Top-up amount to charge from partner balance
     * Example: 2430
     */
    get topUpAmountPartner(): number | undefined {
        return this._top_up_amount_partner;
    }

    readonly _top_up_amount_reseller: number | undefined;

    /**
     * Description: Top-up amount to charge from reseller balance
     * Example: 2430
     */
    get topUpAmountReseller(): number | undefined {
        return this._top_up_amount_reseller;
    }

    readonly _top_up_settings_card: RecurringSubscriptionRebillSettings | undefined;

    get topUpSettingsCard(): RecurringSubscriptionRebillSettings | undefined {
        return this._top_up_settings_card;
    }

    readonly _top_up_settings_partner: RecurringSubscriptionRebillSettings | undefined;

    get topUpSettingsPartner(): RecurringSubscriptionRebillSettings | undefined {
        return this._top_up_settings_partner;
    }

    readonly _top_up_settings_reseller: RecurringSubscriptionRebillSettings | undefined;

    get topUpSettingsReseller(): RecurringSubscriptionRebillSettings | undefined {
        return this._top_up_settings_reseller;
    }

    constructor(props: IAccountAutoTopUpSettingsUpdate) {
        if (typeof props.active === 'boolean') {
            this._active = props.active;
        }
        if (typeof props.min_balance === 'number') {
            this._min_balance = props.min_balance;
        }
        if (typeof props.top_up_amount_card === 'number') {
            this._top_up_amount_card = props.top_up_amount_card;
        }
        if (typeof props.top_up_amount_partner === 'number') {
            this._top_up_amount_partner = props.top_up_amount_partner;
        }
        if (typeof props.top_up_amount_reseller === 'number') {
            this._top_up_amount_reseller = props.top_up_amount_reseller;
        }
        if (props.top_up_settings_card) {
            this._top_up_settings_card = props.top_up_settings_card;
        }
        if (props.top_up_settings_partner) {
            this._top_up_settings_partner = props.top_up_settings_partner;
        }
        if (props.top_up_settings_reseller) {
            this._top_up_settings_reseller = props.top_up_settings_reseller;
        }
    }

    serialize(): IAccountAutoTopUpSettingsUpdate {
        const data: IAccountAutoTopUpSettingsUpdate = {
        };
        if (typeof this._active !== 'undefined') {
            data.active = this._active;
        }
        if (typeof this._min_balance !== 'undefined') {
            data.min_balance = this._min_balance;
        }
        if (typeof this._top_up_amount_card !== 'undefined') {
            data.top_up_amount_card = this._top_up_amount_card;
        }
        if (typeof this._top_up_amount_partner !== 'undefined') {
            data.top_up_amount_partner = this._top_up_amount_partner;
        }
        if (typeof this._top_up_amount_reseller !== 'undefined') {
            data.top_up_amount_reseller = this._top_up_amount_reseller;
        }
        if (typeof this._top_up_settings_card !== 'undefined') {
            data.top_up_settings_card = this._top_up_settings_card;
        }
        if (typeof this._top_up_settings_partner !== 'undefined') {
            data.top_up_settings_partner = this._top_up_settings_partner;
        }
        if (typeof this._top_up_settings_reseller !== 'undefined') {
            data.top_up_settings_reseller = this._top_up_settings_reseller;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            active: !this._active ? true : typeof this._active === 'boolean',
            min_balance: !this._min_balance ? true : typeof this._min_balance === 'number',
            top_up_amount_card: !this._top_up_amount_card ? true : typeof this._top_up_amount_card === 'number',
            top_up_amount_partner: !this._top_up_amount_partner ? true : typeof this._top_up_amount_partner === 'number',
            top_up_amount_reseller: !this._top_up_amount_reseller ? true : typeof this._top_up_amount_reseller === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountAutoTopUpSettingsUpdate): AccountAutoTopUpSettingsUpdate {
        return new AccountAutoTopUpSettingsUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        active: 'active',
        minBalance: 'min_balance',
        topUpAmountCard: 'top_up_amount_card',
        topUpAmountPartner: 'top_up_amount_partner',
        topUpAmountReseller: 'top_up_amount_reseller',
        topUpSettingsCard: 'top_up_settings_card',
        topUpSettingsPartner: 'top_up_settings_partner',
        topUpSettingsReseller: 'top_up_settings_reseller',
        }
;

    mergeDeepWith(props: Partial<AccountAutoTopUpSettingsUpdate>): AccountAutoTopUpSettingsUpdate {
        const updateData: Partial<IAccountAutoTopUpSettingsUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountAutoTopUpSettingsUpdate) => {
            const updateKey = this.keys[key] as keyof IAccountAutoTopUpSettingsUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountAutoTopUpSettingsUpdate, keyof IAccountAutoTopUpSettingsUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountAutoTopUpSettingsUpdate({ ...this.serialize(), ...updateData });
    }
}
