// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountBillingDocument {
    act_number?: string;
    amount: number;
    download_url: string;
    period_begin_date: string;
    period_end_date: string;
    utd_download_url: string;
    vat_number?: string;
}

export default class AccountBillingDocument {
    readonly _act_number: string | undefined;

    /**
     * Description: Document act number
     * Example: 20243
     */
    get actNumber(): string | undefined {
        return this._act_number;
    }

    readonly _amount: number;

    /**
     * Description: Document bill amount in RUB
     * Example: 1100
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    readonly _download_url: string;

    /**
     * Description: Act download link path
     * Example: https://localhost:8181/documents/a364001059ef15eb21e5dcb9a94ab1b9/act.pdf
     */
    get downloadUrl(): string {
        return this._download_url;
    }

    static downloadUrlValidate(downloadUrl: string): boolean {
        return typeof downloadUrl === 'string' && !!downloadUrl.trim();
    }

    readonly _period_begin_date: string;

    /** */
    get periodBeginDate(): string {
        return this._period_begin_date;
    }

    static periodBeginDateValidate(periodBeginDate: string): boolean {
        return typeof periodBeginDate === 'string' && !!periodBeginDate.trim();
    }

    readonly _period_end_date: string;

    /** */
    get periodEndDate(): string {
        return this._period_end_date;
    }

    static periodEndDateValidate(periodEndDate: string): boolean {
        return typeof periodEndDate === 'string' && !!periodEndDate.trim();
    }

    readonly _utd_download_url: string;

    /**
     * Description: UTD download link path
     * Example: https://localhost:8181/documents/a364001059ef15eb21e5dcb9a94ab1b9/utd.xls
     */
    get utdDownloadUrl(): string {
        return this._utd_download_url;
    }

    static utdDownloadUrlValidate(utdDownloadUrl: string): boolean {
        return typeof utdDownloadUrl === 'string' && !!utdDownloadUrl.trim();
    }

    readonly _vat_number: string | undefined;

    /**
     * Description: Document bill number
     * Example: 20200
     */
    get vatNumber(): string | undefined {
        return this._vat_number;
    }

    constructor(props: IAccountBillingDocument) {
        if (typeof props.act_number === 'string') {
            this._act_number = props.act_number.trim();
        }
        this._amount = props.amount;
        this._download_url = props.download_url.trim();
        this._period_begin_date = props.period_begin_date.trim();
        this._period_end_date = props.period_end_date.trim();
        this._utd_download_url = props.utd_download_url.trim();
        if (typeof props.vat_number === 'string') {
            this._vat_number = props.vat_number.trim();
        }
    }

    serialize(): IAccountBillingDocument {
        const data: IAccountBillingDocument = {
            amount: this._amount,
            download_url: this._download_url,
            period_begin_date: this._period_begin_date,
            period_end_date: this._period_end_date,
            utd_download_url: this._utd_download_url,
        };
        if (typeof this._act_number !== 'undefined') {
            data.act_number = this._act_number;
        }
        if (typeof this._vat_number !== 'undefined') {
            data.vat_number = this._vat_number;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            act_number: !this._act_number ? true : typeof this._act_number === 'string',
            vat_number: !this._vat_number ? true : typeof this._vat_number === 'string',
            amount: typeof this._amount === 'number',
            period_begin_date: typeof this._period_begin_date === 'string' && !!this._period_begin_date.trim(),
            period_end_date: typeof this._period_end_date === 'string' && !!this._period_end_date.trim(),
            download_url: typeof this._download_url === 'string' && !!this._download_url.trim(),
            utd_download_url: typeof this._utd_download_url === 'string' && !!this._utd_download_url.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountBillingDocument): AccountBillingDocument {
        return new AccountBillingDocument(props);
    }

    readonly keys: { [key: string]: string } = {
        actNumber: 'act_number',
        amount: 'amount',
        downloadUrl: 'download_url',
        periodBeginDate: 'period_begin_date',
        periodEndDate: 'period_end_date',
        utdDownloadUrl: 'utd_download_url',
        vatNumber: 'vat_number',
        }
;

    mergeDeepWith(props: Partial<AccountBillingDocument>): AccountBillingDocument {
        const updateData: Partial<IAccountBillingDocument> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountBillingDocument) => {
            const updateKey = this.keys[key] as keyof IAccountBillingDocument;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountBillingDocument, keyof IAccountBillingDocument>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountBillingDocument({ ...this.serialize(), ...updateData });
    }
}
