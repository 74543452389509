import Error, { IError } from './Error';
import { OperationStatus } from './OperationStatus';
import { OperationType } from './OperationType';
import { VmOperationStep } from './VmOperationStep';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IOperationProgress {
    error?: IError;
    id: number;
    result?: string;
    server_id: number;
    status: OperationStatus;
    step_name: VmOperationStep;
    step_order: number;
    tenant_id: number;
    total_steps: number;
    type: OperationType;
}

export default class OperationProgress {
    readonly _error: Error | undefined;

    get error(): Error | undefined {
        return this._error;
    }

    readonly _id: number;

    /**
     * Description: Operation unique identifier
     * Example: 4673
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _result: string | undefined;

    /**
     * Description: Operation result depending on operation
     * Example: 10.0.0.1
     */
    get result(): string | undefined {
        return this._result;
    }

    readonly _server_id: number;

    /**
     * Description: Server id
     * Example: 51671
     */
    get serverId(): number {
        return this._server_id;
    }

    static serverIdValidate(serverId: number): boolean {
        return typeof serverId === 'number';
    }

    readonly _status: OperationStatus;

    get status(): OperationStatus {
        return this._status;
    }

    static statusValidate(status: OperationStatus): boolean {
        return Object.keys(OperationStatus).includes(status);
    }

    readonly _step_name: VmOperationStep;

    get stepName(): VmOperationStep {
        return this._step_name;
    }

    static stepNameValidate(stepName: VmOperationStep): boolean {
        return Object.keys(VmOperationStep).includes(stepName);
    }

    readonly _step_order: number;

    /**
     * Description: Current step order
     * Example: 2
     */
    get stepOrder(): number {
        return this._step_order;
    }

    static stepOrderValidate(stepOrder: number): boolean {
        return typeof stepOrder === 'number';
    }

    readonly _tenant_id: number;

    /**
     * Description: Tenant id
     * Example: 1278
     */
    get tenantId(): number {
        return this._tenant_id;
    }

    static tenantIdValidate(tenantId: number): boolean {
        return typeof tenantId === 'number';
    }

    readonly _total_steps: number;

    /**
     * Description: Total step count
     * Example: 6
     */
    get totalSteps(): number {
        return this._total_steps;
    }

    static totalStepsValidate(totalSteps: number): boolean {
        return typeof totalSteps === 'number';
    }

    readonly _type: OperationType;

    get type(): OperationType {
        return this._type;
    }

    static typeValidate(type: OperationType): boolean {
        return Object.keys(OperationType).includes(type);
    }

    constructor(props: IOperationProgress) {
        if (props.error) {
            this._error = new Error(props.error);
        }
        this._id = props.id;
        if (typeof props.result === 'string') {
            this._result = props.result.trim();
        }
        this._server_id = props.server_id;
        this._status = props.status;
        this._step_name = props.step_name;
        this._step_order = props.step_order;
        this._tenant_id = props.tenant_id;
        this._total_steps = props.total_steps;
        this._type = props.type;
    }

    serialize(): IOperationProgress {
        const data: IOperationProgress = {
            id: this._id,
            server_id: this._server_id,
            status: this._status,
            step_name: this._step_name,
            step_order: this._step_order,
            tenant_id: this._tenant_id,
            total_steps: this._total_steps,
            type: this._type,
        };
        if (typeof this._error !== 'undefined') {
            data.error = this._error.serialize();
        }
        if (typeof this._result !== 'undefined') {
            data.result = this._result;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            server_id: typeof this._server_id === 'number',
            tenant_id: typeof this._tenant_id === 'number',
            step_order: typeof this._step_order === 'number',
            total_steps: typeof this._total_steps === 'number',
            result: !this._result ? true : typeof this._result === 'string',
            error: !this._error ? true : this._error.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IOperationProgress): OperationProgress {
        return new OperationProgress(props);
    }

    readonly keys: { [key: string]: string } = {
        error: 'error',
        id: 'id',
        result: 'result',
        serverId: 'server_id',
        status: 'status',
        stepName: 'step_name',
        stepOrder: 'step_order',
        tenantId: 'tenant_id',
        totalSteps: 'total_steps',
        type: 'type',
        }
;

    mergeDeepWith(props: Partial<OperationProgress>): OperationProgress {
        const updateData: Partial<IOperationProgress> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof OperationProgress) => {
            const updateKey = this.keys[key] as keyof IOperationProgress;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IOperationProgress, keyof IOperationProgress>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new OperationProgress({ ...this.serialize(), ...updateData });
    }
}
