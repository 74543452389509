// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPublicKeyCreate {
    content: string;
    install_default: boolean;
    shared: boolean;
    title: string;
}

export default class PublicKeyCreate {
    readonly _content: string;

    /**
     * Description: Public key content
     * Example: MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDFdSgx73/E4FcMNn3ySn6Ublygjf5jgKw1TYAXq8Xwl7umobO3TM3knOSvlI7vnueR/6KP6OQUsLFLtH9vRSArQy+wnSSruU/AOTMmuW6Xj4fsCY9flYjXztDO0kD2j25K4+Rz7Jkfl1foozfrfz1hpeLj0LIwmjJOHtn1P3TfMwIDAQAB
     */
    get content(): string {
        return this._content;
    }

    static contentValidate(content: string): boolean {
        return typeof content === 'string' && !!content.trim();
    }

    readonly _install_default: boolean;

    /**
     * Description: Should be installed by default to new servers
     * Example: true
     */
    get installDefault(): boolean {
        return this._install_default;
    }

    static installDefaultValidate(installDefault: boolean): boolean {
        return typeof installDefault === 'boolean';
    }

    readonly _shared: boolean;

    /** */
    get shared(): boolean {
        return this._shared;
    }

    static sharedValidate(shared: boolean): boolean {
        return typeof shared === 'boolean';
    }

    readonly _title: string;

    /**
     * Description: Title
     * Example: Personal key
     */
    get title(): string {
        return this._title;
    }

    static get titleMinLength() {
        return 0;
    }

    static get titleMaxLength() {
        return 70;
    }

    static titleValidate(title: string): boolean {
        return (title.length > 0) && (title.length <= 70);
    }

    constructor(props: IPublicKeyCreate) {
        this._content = props.content.trim();
        this._install_default = props.install_default;
        this._shared = props.shared;
        this._title = props.title.trim();
    }

    serialize(): IPublicKeyCreate {
        const data: IPublicKeyCreate = {
            content: this._content,
            install_default: this._install_default,
            shared: this._shared,
            title: this._title,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            title: (this._title.length > 0) && (this._title.length <= 70),
            content: typeof this._content === 'string' && !!this._content.trim(),
            shared: typeof this._shared === 'boolean',
            install_default: typeof this._install_default === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPublicKeyCreate): PublicKeyCreate {
        return new PublicKeyCreate(props);
    }

    readonly keys: { [key: string]: string } = {
        content: 'content',
        installDefault: 'install_default',
        shared: 'shared',
        title: 'title',
        }
;

    mergeDeepWith(props: Partial<PublicKeyCreate>): PublicKeyCreate {
        const updateData: Partial<IPublicKeyCreate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PublicKeyCreate) => {
            const updateKey = this.keys[key] as keyof IPublicKeyCreate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPublicKeyCreate, keyof IPublicKeyCreate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PublicKeyCreate({ ...this.serialize(), ...updateData });
    }
}
