import AccountBillingDocument, { IAccountBillingDocument } from './AccountBillingDocument';
import { AccountDocumentType } from './AccountDocumentType';
import AccountInvoice, { IAccountInvoice } from './AccountInvoice';
import AccountTransfer, { IAccountTransfer } from './AccountTransfer';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountDocument {
    act?: IAccountBillingDocument;
    document_id: string;
    document_type: AccountDocumentType;
    invoice?: IAccountInvoice;
    transfer?: IAccountTransfer;
}

export default class AccountDocument {
    readonly _act: AccountBillingDocument | undefined;

    get act(): AccountBillingDocument | undefined {
        return this._act;
    }

    readonly _document_id: string;

    /** */
    get documentId(): string {
        return this._document_id;
    }

    static documentIdValidate(documentId: string): boolean {
        return typeof documentId === 'string' && !!documentId.trim();
    }

    readonly _document_type: AccountDocumentType;

    get documentType(): AccountDocumentType {
        return this._document_type;
    }

    static documentTypeValidate(documentType: AccountDocumentType): boolean {
        return Object.keys(AccountDocumentType).includes(documentType);
    }

    readonly _invoice: AccountInvoice | undefined;

    get invoice(): AccountInvoice | undefined {
        return this._invoice;
    }

    readonly _transfer: AccountTransfer | undefined;

    get transfer(): AccountTransfer | undefined {
        return this._transfer;
    }

    constructor(props: IAccountDocument) {
        if (props.act) {
            this._act = new AccountBillingDocument(props.act);
        }
        this._document_id = props.document_id.trim();
        this._document_type = props.document_type;
        if (props.invoice) {
            this._invoice = new AccountInvoice(props.invoice);
        }
        if (props.transfer) {
            this._transfer = new AccountTransfer(props.transfer);
        }
    }

    serialize(): IAccountDocument {
        const data: IAccountDocument = {
            document_id: this._document_id,
            document_type: this._document_type,
        };
        if (typeof this._act !== 'undefined') {
            data.act = this._act.serialize();
        }
        if (typeof this._invoice !== 'undefined') {
            data.invoice = this._invoice.serialize();
        }
        if (typeof this._transfer !== 'undefined') {
            data.transfer = this._transfer.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            invoice: !this._invoice ? true : this._invoice.validate().length === 0,
            act: !this._act ? true : this._act.validate().length === 0,
            transfer: !this._transfer ? true : this._transfer.validate().length === 0,
            document_id: typeof this._document_id === 'string' && !!this._document_id.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountDocument): AccountDocument {
        return new AccountDocument(props);
    }

    readonly keys: { [key: string]: string } = {
        act: 'act',
        documentId: 'document_id',
        documentType: 'document_type',
        invoice: 'invoice',
        transfer: 'transfer',
        }
;

    mergeDeepWith(props: Partial<AccountDocument>): AccountDocument {
        const updateData: Partial<IAccountDocument> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountDocument) => {
            const updateKey = this.keys[key] as keyof IAccountDocument;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountDocument, keyof IAccountDocument>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountDocument({ ...this.serialize(), ...updateData });
    }
}
