import React, { FC, useEffect } from 'react';
import cn from 'classnames';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';

import AccountLimits from 'Entities/AccountLimits';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { removeError } from 'Actions/error';
import { useIntl, notifyError, Button, whiteText } from 'Common';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { Store } from 'Store';
import { linkPathBuilder, RoutePath, externalLinkBuilder, GuestRoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import Error from 'Entities/Error';
import OperationProgress from 'Entities/OperationProgress';
import { markOperationChecked, removeDoneProgress } from 'Actions/operationProgress';

interface ErrorNotificationsProps {
    errors: Store['error'];
    limits: AccountLimits;
    servers: Store['server'];
}
const ErrorNotifications: FC<ErrorNotificationsProps> = ({
    errors, limits, servers,
}) => {
    const intl = useIntl();
    const { currentLocale } = intl;
    const dispatch = useDispatch();

    const history = useHistory();
    const addFundsLink = [
        <Button
            type="link"
            key="notifyLink"
            className={cn(theme.link.link, theme.link.white)}
            onClick={() => history.push(linkPathBuilder(intl.currentLocale, RoutePath.AddFunds))}
        >
            {intl.getMessage('add_funds')}
        </Button>,
    ];

    useEffect(() => {
        const selectErrorResolver = (e: Error, operation?: OperationProgress) => {
            const onClose = operation ? () => {
                if (operation) {
                    dispatch(markOperationChecked(
                        [operation.tenantId, operation.id],
                    ));
                }
            } : undefined;
            switch (e.errorCode) {
                case ApiErrorCode.VM_LIMIT_EXCEED: {
                    notifyError(
                        apiErrorCodeTranslate(intl, e.errorCode, {
                            value_second: limits.maxOverallServersCount,
                            value: limits.maxCurrentServersCount,
                        }),
                        e.errorCode,
                        {
                            btn: addFundsLink,
                            duration: 0,
                        },
                    );
                    break;
                }
                case ApiErrorCode.VM_MEMORY_LIMIT_EXCEED: {
                    notifyError(
                        apiErrorCodeTranslate(intl, e.errorCode, {
                            value: limits.maxOverallMemoryMib,
                        }),
                        e.errorCode,
                        { btn: addFundsLink, duration: 0 },
                    );
                    break;
                }
                case ApiErrorCode.VM_SHUTDOWN_NOT_COMPLETE:
                case ApiErrorCode.VM_REBOOT_NOT_COMPLETE: {
                    const server = servers.get(operation?.serverId!);
                    notifyError(
                        apiErrorCodeTranslate(intl, e.errorCode, {
                            value: server?.name || '',
                            white: whiteText,
                        }),
                        e.errorCode,
                        {
                            duration: 0,
                            onClose,
                        },
                    );
                    break;
                }

                case ApiErrorCode.PARTITION_RESIZE_FAILED:
                case ApiErrorCode.SFDISK_OR_RESIZEPART_MISSED:
                case ApiErrorCode.CANNOT_READ_PARTITION_TABLE:
                case ApiErrorCode.PARTITION_TABLE_MODIFIED:
                case ApiErrorCode.UNSUPPORTED_KERNEL_VERSION: {
                    const server = servers.get(operation?.serverId!);
                    const kbLink = [
                        <a
                            key="notifyLink"
                            href={externalLinkBuilder(currentLocale, GuestRoutePath.KbArticle, { slug: 'block-device-resize-error' })}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                type="link"
                                className={cn(theme.link.link, theme.link.white)}
                            >
                                {intl.getMessage('how_to')}
                            </Button>
                        </a>,
                    ];
                    notifyError(
                        apiErrorCodeTranslate(intl, e.errorCode, {
                            value: server?.name || '',
                            white: whiteText,
                        }),
                        e.errorCode,
                        {
                            btn: kbLink,
                            duration: 0,
                            onClose,
                        },
                    );
                    break;
                }

                case ApiErrorCode.INET_IP_GUEST_ADD:
                case ApiErrorCode.INET_IP_GUEST_REMOVE: {
                    const kbLink = [
                        <a
                            key="notifyLink"
                            href={externalLinkBuilder(currentLocale, GuestRoutePath.KbArticle, { slug: 'how-to-add-and-remove-ip-address-manually' })}
                        >
                            <Button
                                type="link"
                                className={cn(theme.link.link, theme.link.white)}
                            >
                                {intl.getMessage('how_to')}
                            </Button>
                        </a>,
                    ];
                    notifyError(
                        apiErrorCodeTranslate(intl, e.errorCode, { value: e.message }),
                        e.errorCode,
                        {
                            onClose,
                            duration: 0,
                            btn: kbLink,
                        },
                    );
                    break;
                }

                default: {
                    notifyError(apiErrorCodeTranslate(intl, e.errorCode), e.errorCode, {
                        onClose,
                    });
                }
            }
            if (operation) {
                dispatch(removeDoneProgress({ serverId: operation.serverId, type: operation.type }));
            }
        };
        if (errors.size > 0) {
            Array.from(errors).forEach(([type, e]) => {
                selectErrorResolver(e.error, e.operation);
                dispatch(removeError(type));
            });
        }
    }, [errors, limits, servers]);

    return null;
};
const selectErrors = (s: Store) => (s.error);
const selectAccountLimits = (s: Store) => (s.account.details?.accountLimits!);
const selectServers = (s: Store) => (s.server);
const selector = createSelector([
    selectErrors, selectAccountLimits, selectServers,
], (e, limits, servers) => ({ errors: e, limits, servers }));
const mapStoreToProps = (store: Store) => selector(store);

export default connect(mapStoreToProps)(ErrorNotifications);
