import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, DashboardHeader, Link, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { NOTIFICATIONS_STATE } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

const Header: FC = () => {
    const intl = useIntl();

    return (
        <DashboardHeader
            navigation={(
                <>
                    <Link
                        to={RoutePath.Notifications}
                        props={{ unread: NOTIFICATIONS_STATE.UNREAD }}
                        className={theme.header.link}
                    >
                        {intl.getMessage('notifications_unread')}
                    </Link>
                    <Link
                        to={RoutePath.Notifications}
                        props={{ unread: NOTIFICATIONS_STATE.HISTORY }}
                        className={theme.header.link}
                    >
                        {intl.getMessage('notifications_read')}
                    </Link>
                    <div
                        className={cn(
                            theme.header.link,
                            theme.header.link_larger,
                            theme.header.active,
                        )}
                    >
                        {intl.getMessage('triggers_title')}
                    </div>
                    <Link
                        to={RoutePath.NotificationRecipients}
                        className={cn(
                            theme.header.link,
                            theme.header.link_larger,
                        )}
                    >
                        {intl.getMessage('notifications_recipients_settings')}
                    </Link>
                </>
            )}
            actions={(
                <Link to={RoutePath.NewTrigger} className={theme.header.button}>
                    <Button
                        type="primary"
                        size="medium"
                    >
                        {intl.getMessage('triggers_create')}
                    </Button>
                </Link>
            )}
        />
    );
};

export default Header;
