import React, { FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { getLoginAttempts, clearLoginAttempts } from 'Actions/profile';
import { Icon, InnerPageLayout, useIntl, EmptyPageLayout } from 'Common';
import ProfileLoginAttempt from 'Entities/ProfileLoginAttempt';
import { useOffsetLoader, useTitle } from 'Hooks';
import { Store } from 'Store';
import theme from 'Lib/theme';

import Header from './Header';
import Session from './Session';

interface HistoryProps {
    loginAttempts: ProfileLoginAttempt[];
}

const History: FC<HistoryProps> = ({ loginAttempts }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('profile_history_page_title'));
    const dispatch = useDispatch();
    useOffsetLoader(
        loginAttempts,
        (offset: number, limit: number) => getLoginAttempts([offset * limit, limit]),
    );

    useEffect(() => {
        return () => {
            dispatch(clearLoginAttempts());
        };
    }, []);

    return (
        <InnerPageLayout header={<Header />}>
            {loginAttempts.length > 0
                ? (
                    <>
                        <div
                            className={cn(
                                theme.card.labels,
                                theme.card.labels_session,
                            )}
                        >
                            <div className={theme.card.label}>
                                {intl.getMessage('action')}
                            </div>
                            <div className={theme.card.label}>
                                {intl.getMessage('history_ip')}
                            </div>
                            <div className={cn(theme.card.label, theme.card.label_last)}>
                                <Icon icon="history" className={theme.card.dateIcon} />
                            </div>
                        </div>
                        {loginAttempts.map((attempt) => (
                            <Session key={attempt.timeAddedMillis} attempt={attempt} />
                        ))}
                    </>
                ) : (
                    <EmptyPageLayout
                        title={intl.getMessage('profile_history_empty_title')}
                        desc={intl.getMessage('profile_history_empty_desc')}
                    />
                )}
        </InnerPageLayout>
    );
};

const selectLoginAttempts = (store: Store) => {
    return store.profile.loginAttempts;
};
const selector = createSelector(
    [selectLoginAttempts],
    (loginAttempts) => ({ loginAttempts }),
);

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(History);
