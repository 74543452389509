import { notifyError } from 'Common';
import { Translator } from 'Lib/intl';

export const fileValidator = (intl: Translator, max: number, newFiles: File[]) => {
    const validatedFiles: File[] = [];
    let currentSize = 0;
    let i = 0;
    while (i < 5 && newFiles[i]) {
        const file = newFiles[i];
        currentSize += file.size;
        if (currentSize > max) {
            notifyError(intl.getMessage('no_more_file_size'), 'no_more_file_size');
            break;
        }
        validatedFiles.push(file);
        i += 1;
    }
    if (i === 5 && newFiles.length > validatedFiles.length) {
        notifyError(intl.getMessage('no_more_file_length'), 'no_more_file_length');
    }
    return validatedFiles;
};
