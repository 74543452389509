import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class AdminClientAuthorizationApi {
    static async adminClientLogin(username: string, optionalToken?: string): Promise<{ token: string, cookie: string | null } | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            username: username,
        }
        return await fetch(`/api/v1/admin/client_login?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return {
                    token: res.headers.get('x-auth-token')!,
                    cookie: res.headers.get('x-auth-cookie'),
                };
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async adminClientLogout(optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/admin/client_logout`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
