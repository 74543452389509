import PhoneInfoUpdate, { IPhoneInfoUpdate } from './PhoneInfoUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface INotificationRecipientCreate {
    email?: string;
    name: string;
    phone?: IPhoneInfoUpdate;
}

export default class NotificationRecipientCreate {
    readonly _email: string | undefined;

    /**
     * Description: Recipient email
     * Example: mymail@gmail.com
     */
    get email(): string | undefined {
        return this._email;
    }

    readonly _name: string;

    /**
     * Description: Recipient name
     * Example: mymail@gmail.com
     */
    get name(): string {
        return this._name;
    }

    static get nameMinLength() {
        return 0;
    }

    static get nameMaxLength() {
        return 256;
    }

    static nameValidate(name: string): boolean {
        return (name.length > 0) && (name.length <= 256);
    }

    readonly _phone: PhoneInfoUpdate | undefined;

    get phone(): PhoneInfoUpdate | undefined {
        return this._phone;
    }

    constructor(props: INotificationRecipientCreate) {
        if (typeof props.email === 'string') {
            this._email = props.email.trim();
        }
        this._name = props.name.trim();
        if (props.phone) {
            this._phone = new PhoneInfoUpdate(props.phone);
        }
    }

    serialize(): INotificationRecipientCreate {
        const data: INotificationRecipientCreate = {
            name: this._name,
        };
        if (typeof this._email !== 'undefined') {
            data.email = this._email;
        }
        if (typeof this._phone !== 'undefined') {
            data.phone = this._phone.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: (this._name.length > 0) && (this._name.length <= 256),
            email: !this._email ? true : typeof this._email === 'string',
            phone: !this._phone ? true : this._phone.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: INotificationRecipientCreate): NotificationRecipientCreate {
        return new NotificationRecipientCreate(props);
    }

    readonly keys: { [key: string]: string } = {
        email: 'email',
        name: 'name',
        phone: 'phone',
        }
;

    mergeDeepWith(props: Partial<NotificationRecipientCreate>): NotificationRecipientCreate {
        const updateData: Partial<INotificationRecipientCreate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof NotificationRecipientCreate) => {
            const updateKey = this.keys[key] as keyof INotificationRecipientCreate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<INotificationRecipientCreate, keyof INotificationRecipientCreate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new NotificationRecipientCreate({ ...this.serialize(), ...updateData });
    }
}
