import React, { FC } from 'react';

import { useIntl, DeleteModalLayout } from 'Common';

interface DeletePtrRecordProps {
    visible: boolean;
    handleClose: () => void;
    onOk: () => void;
}

const DeletePtrRecord: FC<DeletePtrRecordProps> = ({ visible, handleClose, onOk }) => {
    const intl = useIntl();

    const handleDelete = () => {
        onOk();
        handleClose();
    };

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('ptr_delete')}
            handleClose={handleClose}
            handleDelete={handleDelete}
        >
            <div className="modal__desc">
                {intl.getMessage('ptr_delete_confirm')}
            </div>
        </DeleteModalLayout>
    );
};

export default DeletePtrRecord;
