import React, { FC } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { useIntl, Button } from 'Common';
import { SERVER_LOGIN } from 'Lib/helpers/consts';
import { GuestRoutePath, externalLinkBuilder } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import s from './Console.module.pcss';

const Info: FC = () => {
    const intl = useIntl();
    const { currentLocale } = intl;

    return (
        <div className={s.mobile}>
            <div className={s.heading}>
                {intl.getMessage('console')}
                <Tooltip
                    overlayClassName={cn('tooltip tooltip_title')}
                    placement="bottom"
                    title={(
                        <>
                            <div className={s.text}>
                                {intl.getMessage('server_console_password')}
                            </div>
                            <a href={externalLinkBuilder(currentLocale, GuestRoutePath.KbArticle, { slug: 'how-to-change-admin-password' })}>
                                <Button
                                    type="link"
                                    className={cn(theme.link.link, theme.link.white)}
                                >
                                    {intl.getMessage('restore_password')}
                                </Button>
                            </a>
                        </>
                    )}
                >
                    <div
                        className={cn(
                            theme.Badge.badge,
                            theme.Badge.question,
                            s.badge,
                        )}
                    />
                </Tooltip>
            </div>
            <div className={s.info}>
                <span className={s.label}>{SERVER_LOGIN}</span>
                &nbsp;–&nbsp;{intl.getMessage('server_console_login')}
            </div>
        </div>
    );
};

export default Info;
