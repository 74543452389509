import CreateCorporation, { ICreateCorporation } from './CreateCorporation';
import CreateIndividual, { ICreateIndividual } from './CreateIndividual';
import CreateNonResident, { ICreateNonResident } from './CreateNonResident';
import CreateSoleTrader, { ICreateSoleTrader } from './CreateSoleTrader';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICreatePayerRequest {
    corporation?: ICreateCorporation;
    individual?: ICreateIndividual;
    non_resident?: ICreateNonResident;
    sole_trader?: ICreateSoleTrader;
}

export default class CreatePayerRequest {
    readonly _corporation: CreateCorporation | undefined;

    get corporation(): CreateCorporation | undefined {
        return this._corporation;
    }

    readonly _individual: CreateIndividual | undefined;

    get individual(): CreateIndividual | undefined {
        return this._individual;
    }

    readonly _non_resident: CreateNonResident | undefined;

    get nonResident(): CreateNonResident | undefined {
        return this._non_resident;
    }

    readonly _sole_trader: CreateSoleTrader | undefined;

    get soleTrader(): CreateSoleTrader | undefined {
        return this._sole_trader;
    }

    constructor(props: ICreatePayerRequest) {
        if (props.corporation) {
            this._corporation = new CreateCorporation(props.corporation);
        }
        if (props.individual) {
            this._individual = new CreateIndividual(props.individual);
        }
        if (props.non_resident) {
            this._non_resident = new CreateNonResident(props.non_resident);
        }
        if (props.sole_trader) {
            this._sole_trader = new CreateSoleTrader(props.sole_trader);
        }
    }

    serialize(): ICreatePayerRequest {
        const data: ICreatePayerRequest = {
        };
        if (typeof this._corporation !== 'undefined') {
            data.corporation = this._corporation.serialize();
        }
        if (typeof this._individual !== 'undefined') {
            data.individual = this._individual.serialize();
        }
        if (typeof this._non_resident !== 'undefined') {
            data.non_resident = this._non_resident.serialize();
        }
        if (typeof this._sole_trader !== 'undefined') {
            data.sole_trader = this._sole_trader.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            individual: !this._individual ? true : this._individual.validate().length === 0,
            sole_trader: !this._sole_trader ? true : this._sole_trader.validate().length === 0,
            corporation: !this._corporation ? true : this._corporation.validate().length === 0,
            non_resident: !this._non_resident ? true : this._non_resident.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICreatePayerRequest): CreatePayerRequest {
        return new CreatePayerRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        corporation: 'corporation',
        individual: 'individual',
        nonResident: 'non_resident',
        soleTrader: 'sole_trader',
        }
;

    mergeDeepWith(props: Partial<CreatePayerRequest>): CreatePayerRequest {
        const updateData: Partial<ICreatePayerRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CreatePayerRequest) => {
            const updateKey = this.keys[key] as keyof ICreatePayerRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICreatePayerRequest, keyof ICreatePayerRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CreatePayerRequest({ ...this.serialize(), ...updateData });
    }
}
