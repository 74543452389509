import React, { FC, useRef, useState } from 'react';
import { Layout, Menu, Grid } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import cn from 'classnames';

import { resellerLogout } from 'Actions/user';
import { toggleSidebar } from 'Actions/ui';
import { Link, useIntl, Mask, Button } from 'Common';
import { RoutePath, Routes, externalLinkBuilder, GuestRoutePath } from 'Lib/helpers/routes';
import {
    SIDEBAR_WIDTH,
    TICKETS_STATE,
    NOTIFICATIONS_STATE,
} from 'Lib/helpers/consts';
import theme from 'Lib/theme';
import Profile from 'Entities/Profile';
import { Store } from 'Store';
import s from './Sidebar.module.pcss';

import { Client, EmptySidebar, Create, ScrollLabel, NewUserNotices } from './components';

const { Sider } = Layout;
const { Item: MenuItem, ItemGroup } = Menu;
const { useBreakpoint } = Grid;

interface SidebarStoreProps {
    sidebarOpen: boolean;
    newNotifications: number | undefined;
    todayClients: number | undefined;
    profile: Profile | null;
    ticketsInfo: Store['support']['ticketsInfo'];
    loading?: boolean;
}

type SidebarProps = SidebarStoreProps;

const Sidebar: FC<SidebarProps> = React.memo(({
    sidebarOpen,
    newNotifications,
    todayClients,
    profile,
    ticketsInfo,
    loading,
}) => {
    const intl = useIntl();
    const { currentLocale: lang } = intl;
    const dispatch = useDispatch();
    const screens = useBreakpoint();
    const sideBarRef = useRef<HTMLDivElement>(null);
    const [showTopLabel, setShowTopLabel] = useState(false);
    const [showBottomLabel, setShowBottomLabel] = useState(true);

    const routeMatch = useRouteMatch();
    const showResellerLogout = MAIN_TOKEN;

    const onExit = () => {
        AppConfigStorage.removeTheme();
        dispatch(resellerLogout());
    };

    const handleScrollLabel = () => {
        const sidebar = sideBarRef.current;
        if (sidebar) {
            const limitValue = 59;
            const { clientHeight, scrollHeight, scrollTop } = sidebar;
            const scrollBottom = scrollHeight - clientHeight - scrollTop;

            setShowTopLabel(scrollTop > limitValue);
            setShowBottomLabel(scrollBottom > limitValue);
        }
    };

    if (!Object.keys(screens).length) {
        return null;
    }
    const selectedKeys = [routeMatch.path];
    switch (routeMatch.path) {
        case Routes.Root:
            selectedKeys.push(Routes.Dashboard);
            break;
        case Routes.NotificationRecipients:
            selectedKeys.push(Routes.Notifications);
            break;
        case Routes.DnsPtr:
        case Routes.DnsZonesRecords:
            selectedKeys.push(Routes.DnsZones);
            break;
        case Routes.Expenses:
        case Routes.Documents:
            selectedKeys.push(Routes.Billing);
            break;
        case Routes.PartnerFinance:
        case Routes.PartnerStats:
            selectedKeys.push(Routes.Partner);
            break;
        case Routes.ClientsFinance:
            selectedKeys.push(Routes.Clients);
            break;
        case Routes.ProfileHistory:
        case Routes.ProfileSettings:
            selectedKeys.push(Routes.Profile);
            break;
        case Routes.Server:
        case Routes.ServerAccess:
        case Routes.ServerBackup:
        case Routes.Snapshots:
        case Routes.Console:
        case Routes.ServerHistory:
            selectedKeys.push(Routes.ServersList);
            break;
        case Routes.ProjectAccess:
        case Routes.ProjectBackup:
            selectedKeys.push(Routes.ProjectsList);
            break;
    }

    const handleSidebar = () => {
        if (!screens.xl) {
            dispatch(toggleSidebar());
        }
    };

    const getBadgeCount = (count: number) => (
        count >= 100 ? '99+' : count
    );

    if (loading) {
        return (
            <EmptySidebar
                collapsed={!sidebarOpen && !screens.xl}
                handleSidebar={handleSidebar}
            />
        );
    }

    return (
        <>
            <Sider
                collapsed={!sidebarOpen && !screens.xl}
                collapsedWidth={0}
                collapsible
                onClick={handleSidebar}
                className="sidebar"
                trigger={null}
                width={SIDEBAR_WIDTH}
            >
                <div
                    onScroll={handleScrollLabel}
                    className={cn(s.sidebar)}
                    ref={sideBarRef}
                >
                    <ScrollLabel
                        position="top"
                        sideBarRef={sideBarRef}
                        showLabel={showTopLabel}
                    />
                    <Client />
                    <Create />

                    {profile?.clientLoginAvailable && (
                        <Link to={RoutePath.AdminLogin} className={s.admin}>
                            {intl.getMessage('admin_login')}
                        </Link>
                    )}

                    <Menu
                        mode="inline"
                        className="sidebar__menu"
                        theme="dark"
                        selectedKeys={selectedKeys}
                    >
                        <ItemGroup title={intl.getMessage('menu_monitoring')}>
                            <MenuItem key={Routes.Dashboard}>
                                <Link to={RoutePath.Dashboard}>
                                    {intl.getMessage('menu_dashboard')}
                                </Link>
                            </MenuItem>
                            <MenuItem key={Routes.Notifications}>
                                <Link
                                    to={RoutePath.Notifications}
                                    props={{ unread: NOTIFICATIONS_STATE.UNREAD }}
                                >
                                    {intl.getMessage('notifications')}
                                </Link>
                                {!!newNotifications && (
                                    <span
                                        className={cn(
                                            theme.Badge.badge,
                                            theme.Badge.indicator,
                                            theme.Badge.morning,
                                        )}
                                    >
                                        {getBadgeCount(newNotifications)}
                                    </span>
                                )}
                            </MenuItem>
                        </ItemGroup>
                        <ItemGroup title={intl.getMessage('menu_manage')}>
                            <MenuItem key={Routes.ServersList}>
                                <Link to={RoutePath.ServersList}>
                                    {intl.getMessage('menu_servers')}
                                </Link>
                            </MenuItem>
                            <MenuItem key={Routes.ProjectsList}>
                                <Link to={RoutePath.ProjectsList}>
                                    {intl.getMessage('projects')}
                                </Link>
                            </MenuItem>
                            <MenuItem key={Routes.DnsZones}>
                                <Link to={RoutePath.DnsZones}>
                                    {intl.getMessage('menu_dns')}
                                </Link>
                            </MenuItem>
                        </ItemGroup>
                        <ItemGroup title={intl.getMessage('menu_finance')}>
                            <MenuItem key={Routes.Billing}>
                                <Link to={RoutePath.Billing}>
                                    {intl.getMessage('menu_billing')}
                                </Link>
                            </MenuItem>
                            <MenuItem key={Routes.Partner}>
                                <Link to={RoutePath.Partner}>
                                    {intl.getMessage('partners')}
                                </Link>
                                {!!todayClients && todayClients > 0 && (
                                    <span
                                        className={cn(
                                            theme.Badge.badge,
                                            theme.Badge.indicator,
                                            theme.Badge.yellow,
                                        )}
                                    >
                                        {getBadgeCount(todayClients)}
                                    </span>
                                )}
                            </MenuItem>
                            {profile && !profile.resellerSectionHidden && (
                                <MenuItem key={Routes.Clients}>
                                    <Link to={RoutePath.Clients}>
                                        {intl.getMessage('clients')}
                                    </Link>
                                </MenuItem>
                            )}
                        </ItemGroup>
                        <ItemGroup title={intl.getMessage('menu_account')}>
                            <MenuItem key={Routes.Profile}>
                                <Link to={RoutePath.Profile}>
                                    {intl.getMessage('menu_profile')}
                                </Link>
                            </MenuItem>
                            <MenuItem key={Routes.PublicKeys}>
                                <Link to={RoutePath.PublicKeys}>
                                    {intl.getMessage('public_keys')}
                                </Link>
                            </MenuItem>
                        </ItemGroup>
                        <ItemGroup title={intl.getMessage('menu_help')}>
                            <MenuItem key={Routes.Tickets}>
                                <Link to={RoutePath.Tickets} props={{ open: TICKETS_STATE.OPEN }}>
                                    {intl.getMessage('menu_support')}
                                </Link>
                                {typeof ticketsInfo?.unreadTicketsCount === 'number' && ticketsInfo?.unreadTicketsCount > 0 && (
                                    <span
                                        className={cn(
                                            theme.Badge.badge,
                                            theme.Badge.indicator,
                                            theme.Badge.blue,
                                        )}
                                    >
                                        {getBadgeCount(ticketsInfo.unreadTicketsCount)}
                                    </span>
                                )}
                            </MenuItem>
                            <MenuItem key="menu_kb">
                                <a
                                    href={externalLinkBuilder(lang, GuestRoutePath.Kb)}
                                >
                                    {intl.getMessage('menu_kb')}
                                </a>
                            </MenuItem>
                        </ItemGroup>
                        {showResellerLogout && (
                            <ItemGroup>
                                <MenuItem key="menu_exit">
                                    <Button
                                        type="link"
                                        onClick={onExit}
                                        className={cn(
                                            s.sidebar_exit,
                                            theme.link.link,
                                            theme.link.red,
                                        )}
                                    >
                                        {intl.getMessage('exit')}
                                    </Button>
                                </MenuItem>
                            </ItemGroup>
                        )}
                    </Menu>
                    <ScrollLabel
                        position="bottom"
                        sideBarRef={sideBarRef}
                        showLabel={showBottomLabel}
                    />
                </div>
            </Sider>
            <NewUserNotices isAdmin={profile?.clientLoginAvailable} />
            <Mask open={sidebarOpen} handle={handleSidebar} />
        </>
    );
});

const selectSidebarState = (store: Store) => store.ui.sidebarOpen;
const selectNewNotifications = (store: Store) => store.profile.info?.newNotificationsCount;
const selectPartnerNotification = (store: Store) => store.partner.details?.todayClients;
const selectProfile = (store: Store) => store.profile.info;
const selectTicketsInfo = (store: Store) => store.support.ticketsInfo;

const selector = createSelector([
    selectSidebarState,
    selectNewNotifications,
    selectPartnerNotification,
    selectTicketsInfo,
    selectProfile,
], (sidebarOpen, newNotifications, todayClients, ticketsInfo, profile) => {
    return {
        sidebarOpen,
        newNotifications,
        todayClients,
        profile,
        ticketsInfo,
    };
});

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Sidebar);
