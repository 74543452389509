// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerClone {
    name: string;
    snapshot_id?: number;
    to_tenant_id?: number;
}

export default class ServerClone {
    readonly _name: string;

    /**
     * Description: Clone server name
     * Example: Copy dev_backend
     */
    get name(): string {
        return this._name;
    }

    static get nameMinLength() {
        return 0;
    }

    static get nameMaxLength() {
        return 70;
    }

    static nameValidate(name: string): boolean {
        return (name.length > 0) && (name.length <= 70);
    }

    readonly _snapshot_id: number | undefined;

    /**
     * Description: Snapshot identifier
     * Example: 681
     */
    get snapshotId(): number | undefined {
        return this._snapshot_id;
    }

    readonly _to_tenant_id: number | undefined;

    /**
     * Description: Destination tenant
     * Example: 19935
     */
    get toTenantId(): number | undefined {
        return this._to_tenant_id;
    }

    constructor(props: IServerClone) {
        this._name = props.name.trim();
        if (typeof props.snapshot_id === 'number') {
            this._snapshot_id = props.snapshot_id;
        }
        if (typeof props.to_tenant_id === 'number') {
            this._to_tenant_id = props.to_tenant_id;
        }
    }

    serialize(): IServerClone {
        const data: IServerClone = {
            name: this._name,
        };
        if (typeof this._snapshot_id !== 'undefined') {
            data.snapshot_id = this._snapshot_id;
        }
        if (typeof this._to_tenant_id !== 'undefined') {
            data.to_tenant_id = this._to_tenant_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: (this._name.length > 0) && (this._name.length <= 70),
            snapshot_id: !this._snapshot_id ? true : typeof this._snapshot_id === 'number',
            to_tenant_id: !this._to_tenant_id ? true : typeof this._to_tenant_id === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerClone): ServerClone {
        return new ServerClone(props);
    }

    readonly keys: { [key: string]: string } = {
        name: 'name',
        snapshotId: 'snapshot_id',
        toTenantId: 'to_tenant_id',
        }
;

    mergeDeepWith(props: Partial<ServerClone>): ServerClone {
        const updateData: Partial<IServerClone> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerClone) => {
            const updateKey = this.keys[key] as keyof IServerClone;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerClone, keyof IServerClone>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerClone({ ...this.serialize(), ...updateData });
    }
}
