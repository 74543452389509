// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerClient {
    account_blocked: boolean;
    balance: number;
    charge_offer_amounts: number[];
    client_id: number;
    comment?: string;
    email: string;
    email_not_confirmed: boolean;
    margin_percent: number;
    starred: boolean;
}

export default class ResellerClient {
    readonly _account_blocked: boolean;

    /** */
    get accountBlocked(): boolean {
        return this._account_blocked;
    }

    static accountBlockedValidate(accountBlocked: boolean): boolean {
        return typeof accountBlocked === 'boolean';
    }

    readonly _balance: number;

    /**
     * Description: Sub-client's account balance
     * Example: 123780.2
     */
    get balance(): number {
        return this._balance;
    }

    static balanceValidate(balance: number): boolean {
        return typeof balance === 'number';
    }

    readonly _charge_offer_amounts: number[];

    /** */
    get chargeOfferAmounts(): number[] {
        return this._charge_offer_amounts;
    }

    static chargeOfferAmountsValidate(chargeOfferAmounts: number[]): boolean {
        return chargeOfferAmounts.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    readonly _client_id: number;

    /**
     * Description: Sub-client ID as contract number
     * Example: 47581
     */
    get clientId(): number {
        return this._client_id;
    }

    static clientIdValidate(clientId: number): boolean {
        return typeof clientId === 'number';
    }

    readonly _comment: string | undefined;

    /**
     * Description: Reseller's comment on a client
     * Example: Test comment for display
     */
    get comment(): string | undefined {
        return this._comment;
    }

    readonly _email: string;

    /**
     * Description: Sub-client's email
     * Example: myhomeworkstation@gmail.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    readonly _email_not_confirmed: boolean;

    /** */
    get emailNotConfirmed(): boolean {
        return this._email_not_confirmed;
    }

    static emailNotConfirmedValidate(emailNotConfirmed: boolean): boolean {
        return typeof emailNotConfirmed === 'boolean';
    }

    readonly _margin_percent: number;

    /**
     * Description: Sub-client's margin as percent, either positive or negative (latter for discount)
     * Example: 5
     */
    get marginPercent(): number {
        return this._margin_percent;
    }

    static marginPercentValidate(marginPercent: number): boolean {
        return typeof marginPercent === 'number';
    }

    readonly _starred: boolean;

    /** */
    get starred(): boolean {
        return this._starred;
    }

    static starredValidate(starred: boolean): boolean {
        return typeof starred === 'boolean';
    }

    constructor(props: IResellerClient) {
        this._account_blocked = props.account_blocked;
        this._balance = props.balance;
        this._charge_offer_amounts = props.charge_offer_amounts;
        this._client_id = props.client_id;
        if (typeof props.comment === 'string') {
            this._comment = props.comment.trim();
        }
        this._email = props.email.trim();
        this._email_not_confirmed = props.email_not_confirmed;
        this._margin_percent = props.margin_percent;
        this._starred = props.starred;
    }

    serialize(): IResellerClient {
        const data: IResellerClient = {
            account_blocked: this._account_blocked,
            balance: this._balance,
            charge_offer_amounts: this._charge_offer_amounts,
            client_id: this._client_id,
            email: this._email,
            email_not_confirmed: this._email_not_confirmed,
            margin_percent: this._margin_percent,
            starred: this._starred,
        };
        if (typeof this._comment !== 'undefined') {
            data.comment = this._comment;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            email: typeof this._email === 'string' && !!this._email.trim(),
            margin_percent: typeof this._margin_percent === 'number',
            client_id: typeof this._client_id === 'number',
            balance: typeof this._balance === 'number',
            email_not_confirmed: typeof this._email_not_confirmed === 'boolean',
            starred: typeof this._starred === 'boolean',
            comment: !this._comment ? true : typeof this._comment === 'string',
            account_blocked: typeof this._account_blocked === 'boolean',
            charge_offer_amounts: this._charge_offer_amounts.reduce((result, p) => result && typeof p === 'number', true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerClient): ResellerClient {
        return new ResellerClient(props);
    }

    readonly keys: { [key: string]: string } = {
        accountBlocked: 'account_blocked',
        balance: 'balance',
        chargeOfferAmounts: 'charge_offer_amounts',
        clientId: 'client_id',
        comment: 'comment',
        email: 'email',
        emailNotConfirmed: 'email_not_confirmed',
        marginPercent: 'margin_percent',
        starred: 'starred',
        }
;

    mergeDeepWith(props: Partial<ResellerClient>): ResellerClient {
        const updateData: Partial<IResellerClient> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerClient) => {
            const updateKey = this.keys[key] as keyof IResellerClient;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerClient, keyof IResellerClient>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerClient({ ...this.serialize(), ...updateData });
    }
}
