import React, { FC } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import cn from 'classnames';

import { Icon, ServerIndicator, Checkbox, useIntl } from 'Common';
import Server from 'Entities/Server';
import OperationProgress from 'Entities/OperationProgress';
import { getServerIcon } from 'Lib/helpers/utils';
import { DATE_FORMAT } from 'Lib/helpers/consts';
import { Store } from 'Store';

import s from './ServersSelect.module.pcss';

interface ServerOwnProps {
    checked: boolean;
    server: Server;
    selectServers: (server: Server) => void;
}

interface ServerStoreProps {
    operationProgress?: OperationProgress;
}

type ServerItemProps = ServerStoreProps & ServerOwnProps;

const ServerItem: FC<ServerItemProps> = ({
    checked,
    server,
    operationProgress,
    selectServers,
}) => {
    const intl = useIntl();
    const { id, state, distribution, name, timeDeleted } = server;

    return (
        <Checkbox
            name={String(id)}
            checked={checked}
            handleChange={() => selectServers(server)}
            labelClassName={s.label}
            className={s.wrap}
            overflow
        >
            <div className={s.server}>
                <ServerIndicator state={state} progress={operationProgress} />
                <Icon icon={getServerIcon(distribution.type)} />
                <div className={cn(s.name, { [s.name_deleted]: timeDeleted })}>
                    {name}
                </div>
                {timeDeleted && (
                    <div className={s.time} title={intl.getMessage('title_time_deleted')}>
                        {dayjs(timeDeleted, DATE_FORMAT).format(intl.getMessage('time_format_date_short'))}
                    </div>
                )}
            </div>
        </Checkbox>
    );
};

const selectProgress = (store: Store, op: ServerOwnProps) => (
    store.operationProgress.commonOperations.get(op?.server?.id)
);

const selector = createSelector([
    selectProgress,
], (progress) => ({
    operationProgress: progress,
}));

const mapStateToProps = (store: Store, ownProps: ServerOwnProps) => {
    return { ...selector(store, ownProps) };
};

export default connect(mapStateToProps)(ServerItem);
