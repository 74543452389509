import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, IconType } from 'Common';

import s from './Avatar.module.pcss';

interface AvatarProps {
    icon: IconType;
    type: 'support' | 'client';
}

const Avatar: FC<AvatarProps> = ({ icon, type }) => {
    return (
        <div
            className={cn(
                s.avatar,
                { [s.avatar_support]: type === 'support' },
                { [s.avatar_client]: type === 'client' },
            )}
        >
            <Icon icon={icon} />
        </div>
    );
};

export default Avatar;
