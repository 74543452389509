import {
    SEARCH_TYPE,
    SERVER_OPERATION_NOTIFICATION,
    SERVER_VIEW,
} from 'Lib/helpers/consts';
import { actionCreator } from '.';

export enum UiActions {
    ToggleSidebar = 'ToggleSidebar',
    OffOperationNotifications = 'OffOperationNotifications',
    SetServerView = 'SetServerView',
    SetSearchType = 'SetSearchType',
    InitUiSettings = 'InitUiSettings',
}

export const toggleSidebar = actionCreator(UiActions.ToggleSidebar);

export const setOperationNotifications = actionCreator<SERVER_OPERATION_NOTIFICATION>(
    UiActions.OffOperationNotifications,
);

export const setServerView = actionCreator<SERVER_VIEW>(
    UiActions.SetServerView,
);

export const setSearchType = actionCreator<SEARCH_TYPE>(UiActions.SetSearchType);

export const initUiSettings = actionCreator(UiActions.InitUiSettings);
