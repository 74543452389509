import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import cn from 'classnames';

import { useIntl, mediumText, Input } from 'Common';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import Trigger from 'Entities/Trigger';
import NotificationRecipient from 'Entities/NotificationRecipient';
import { ITriggerCreate } from 'Entities/TriggerCreate';
import NewTrigger, { NewTriggerFormKeys } from 'Lib/helpers/newTrigger';

import ActionSpan from './ActionSpan';
import TriggerNotifications from './TriggerNotifications';
import Submit from './Submit';
import s from '../NewTrigger.module.pcss';

interface TrafficTransferedUsageFormProps {
    trigger?: Trigger;
    type: TriggerRuleType;
    recipients: NotificationRecipient[];
    onSubmit: (
        keys: NewTriggerFormKeys[],
    ) => (values: ITriggerCreate, helpers: FormikHelpers<ITriggerCreate>) => Promise<void>;
}

const TrafficTransferedUsageForm: FC<TrafficTransferedUsageFormProps> = (
    { type, recipients, onSubmit, trigger },
) => {
    const intl = useIntl();

    return (
        <Formik
            initialValues={NewTrigger.getInitialValues(type, recipients, trigger)}
            onSubmit={onSubmit(['url', 'period_minutes'])}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={cn(s.group, s.group_input)}>
                        <Input
                            cloud
                            autoFocus
                            size="large"
                            placeholder={intl.getMessage('triggers_name')}
                            name="name"
                            type="text"
                            validate={Trigger.nameValidate}
                            error={!!errors.name}
                            value={values.name}
                            onChange={(e) => setFieldValue('name', e)}
                        />
                    </div>
                    <div className={s.group}>
                        <div className={s.text}>
                            <div>
                                {intl.getMessage('trigger_form_transferred_traffic_usage', { b: mediumText })}
                            </div>
                            <Input
                                cloud
                                rightDesktop
                                name="value"
                                size="small"
                                type="number"
                                wrapperClassName={s.input}
                                suffix={intl.getMessage('gb')}
                                error={!!errors.value}
                                validate={(e) => e !== '0'}
                                value={values.value}
                                onChange={(e) => setFieldValue('value', e.slice(0, 6))}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>

                    <ActionSpan
                        value={values.servers_scope!}
                        error={!!errors.servers_scope}
                        setValue={(e) => setFieldValue('servers_scope', e)}
                    />

                    <TriggerNotifications
                        value={values.recipient_settings}
                        handleChange={handleChange}
                        recipients={recipients}
                        setFieldValue={setFieldValue}
                    />

                    <Submit update={!!trigger} />
                </form>
            )}
        </Formik>
    );
};

export default TrafficTransferedUsageForm;
