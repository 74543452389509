// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccount {
    client_id: number;
    email: string;
    time_added: string;
    time_added_millis: number;
}

export default class Account {
    readonly _client_id: number;

    /**
     * Description: Identifier
     * Example: 101
     */
    get clientId(): number {
        return this._client_id;
    }

    static clientIdValidate(clientId: number): boolean {
        return typeof clientId === 'number';
    }

    readonly _email: string;

    /**
     * Description: Email
     * Example: user@serveroid.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    readonly _time_added: string;

    /**
     * Description: Account added time
     * Example: 2020-05-12T12:00:00Z
     */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Account added time, UTC millis
     * Example: 1577826000000
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    constructor(props: IAccount) {
        this._client_id = props.client_id;
        this._email = props.email.trim();
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
    }

    serialize(): IAccount {
        const data: IAccount = {
            client_id: this._client_id,
            email: this._email,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            client_id: typeof this._client_id === 'number',
            email: typeof this._email === 'string' && !!this._email.trim(),
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccount): Account {
        return new Account(props);
    }

    readonly keys: { [key: string]: string } = {
        clientId: 'client_id',
        email: 'email',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        }
;

    mergeDeepWith(props: Partial<Account>): Account {
        const updateData: Partial<IAccount> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Account) => {
            const updateKey = this.keys[key] as keyof IAccount;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccount, keyof IAccount>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Account({ ...this.serialize(), ...updateData });
    }
}
