import React, { FC, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useTitle } from 'Hooks';
import {
    getResellerDetails,
    getResellerClients,
} from 'Actions/reseller';
import { useIntl, InnerPageLayout, ListLayout, Button } from 'Common';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import { Store } from 'Store';

import s from './components/Client.module.pcss';
import { Header, Funds, Client, ClientsEmpty } from './components';

interface ClientsStoreProps {
    details: Store['reseller']['details'] | null;
    clients: Store['reseller']['clients'];
    profile: Store['profile']['info'];
}

const Clients: FC<ClientsStoreProps> = ({ details, clients, profile }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('clients_page_title'));
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (profile && profile.reseller) {
            dispatch(getResellerDetails());
            dispatch(getResellerClients([AUTH_TOKEN]));
        }
    }, []);

    if (!details && profile && profile.reseller) {
        return null;
    }

    if (profile && !profile.reseller) {
        return (
            <InnerPageLayout
                header={<Header profileReseller={profile.reseller} />}
            >
                <ClientsEmpty />
            </InnerPageLayout>
        );
    }

    return (
        <InnerPageLayout
            header={<Header profileReseller={profile?.reseller} />}
            className={theme.content.with_actions}
        >
            <Funds details={details} />
            <ListLayout
                title={intl.getMessage('clients_added')}
                length={clients.length}
            >
                {clients.length === 0 ? (
                    <Button
                        type="border"
                        className={cn(theme.button.with_icon, s.addClientBtn)}
                        icon="plus"
                        iconClassName={s.icon}
                        onClick={() => history.push(linkPathBuilder(intl.currentLocale, RoutePath.ClientsAdd))}
                    >
                        <div className={s.buttonText}>
                            {intl.getMessage('no_clients_added_btn_desc')}
                        </div>
                        <div className={s.addClientTextMobile}>
                            {intl.getMessage('clients_add')}
                        </div>
                    </Button>
                ) : (
                    <>
                        <div className={cn(theme.card.labels, theme.card.labels_client)}>
                            <div className={theme.card.label}>
                                {intl.getMessage('clients_client')}
                            </div>
                            <div className={theme.card.label}>
                                {intl.getMessage('balance')}
                            </div>
                            <div className={theme.card.label}>
                                {intl.getMessage('client_discount_rate_title')}
                            </div>
                            <div className={theme.card.label}>
                                {intl.getMessage('comment')}
                            </div>
                        </div>
                        {clients.map((c) => <Client key={c.clientId} client={c} />)}
                    </>
                )}
            </ListLayout>
        </InnerPageLayout>
    );
};

const selectResellerDetails = (store: Store) => store.reseller.details;
const selectResellerClients = (store: Store) => store.reseller.clients;
const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector(
    [selectResellerDetails, selectResellerClients, selectProfile], (details, clients, profile) => ({
        details,
        clients,
        profile,
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Clients);
