import { PaymentSystemType } from './PaymentSystemType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICreateInvoiceRequest {
    amount: number;
    description?: string;
    payment_error_url: string;
    payment_success_url: string;
    payment_system: PaymentSystemType;
    save_credit_card?: boolean;
}

export default class CreateInvoiceRequest {
    readonly _amount: number;

    /**
     * Description: Payment amount
     * Example: 250
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    readonly _description: string | undefined;

    /**
     * Description: Payment description
     * Example: Serveroid account refill
     */
    get description(): string | undefined {
        return this._description;
    }

    readonly _payment_error_url: string;

    /**
     * Description: Fail redirect url
     * Example: https://serveroid.com/payment_fail.html
     */
    get paymentErrorUrl(): string {
        return this._payment_error_url;
    }

    static paymentErrorUrlValidate(paymentErrorUrl: string): boolean {
        return typeof paymentErrorUrl === 'string' && !!paymentErrorUrl.trim();
    }

    readonly _payment_success_url: string;

    /**
     * Description: Success redirect url
     * Example: https://serveroid.com/payment_success.html
     */
    get paymentSuccessUrl(): string {
        return this._payment_success_url;
    }

    static paymentSuccessUrlValidate(paymentSuccessUrl: string): boolean {
        return typeof paymentSuccessUrl === 'string' && !!paymentSuccessUrl.trim();
    }

    readonly _payment_system: PaymentSystemType;

    get paymentSystem(): PaymentSystemType {
        return this._payment_system;
    }

    static paymentSystemValidate(paymentSystem: PaymentSystemType): boolean {
        return Object.keys(PaymentSystemType).includes(paymentSystem);
    }

    readonly _save_credit_card: boolean | undefined;

    /**
     * Description: Save credit card for further charges
     * Example: true
     */
    get saveCreditCard(): boolean | undefined {
        return this._save_credit_card;
    }

    constructor(props: ICreateInvoiceRequest) {
        this._amount = props.amount;
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        this._payment_error_url = props.payment_error_url.trim();
        this._payment_success_url = props.payment_success_url.trim();
        this._payment_system = props.payment_system;
        if (typeof props.save_credit_card === 'boolean') {
            this._save_credit_card = props.save_credit_card;
        }
    }

    serialize(): ICreateInvoiceRequest {
        const data: ICreateInvoiceRequest = {
            amount: this._amount,
            payment_error_url: this._payment_error_url,
            payment_success_url: this._payment_success_url,
            payment_system: this._payment_system,
        };
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._save_credit_card !== 'undefined') {
            data.save_credit_card = this._save_credit_card;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            amount: typeof this._amount === 'number',
            description: !this._description ? true : typeof this._description === 'string',
            payment_success_url: typeof this._payment_success_url === 'string' && !!this._payment_success_url.trim(),
            payment_error_url: typeof this._payment_error_url === 'string' && !!this._payment_error_url.trim(),
            save_credit_card: !this._save_credit_card ? true : typeof this._save_credit_card === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICreateInvoiceRequest): CreateInvoiceRequest {
        return new CreateInvoiceRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        amount: 'amount',
        description: 'description',
        paymentErrorUrl: 'payment_error_url',
        paymentSuccessUrl: 'payment_success_url',
        paymentSystem: 'payment_system',
        saveCreditCard: 'save_credit_card',
        }
;

    mergeDeepWith(props: Partial<CreateInvoiceRequest>): CreateInvoiceRequest {
        const updateData: Partial<ICreateInvoiceRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CreateInvoiceRequest) => {
            const updateKey = this.keys[key] as keyof ICreateInvoiceRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICreateInvoiceRequest, keyof ICreateInvoiceRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CreateInvoiceRequest({ ...this.serialize(), ...updateData });
    }
}
