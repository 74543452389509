// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICountryPhoneCode {
    calling_code?: string;
    country_code?: string;
    country_name?: string;
    pattern?: string;
}

export default class CountryPhoneCode {
    readonly _calling_code: string | undefined;

    /**
     * Description: Phone calling code without plus
     * Example: 7
     */
    get callingCode(): string | undefined {
        return this._calling_code;
    }

    readonly _country_code: string | undefined;

    /**
     * Description: ISO 2-code country code
     * Example: RU
     */
    get countryCode(): string | undefined {
        return this._country_code;
    }

    readonly _country_name: string | undefined;

    /**
     * Description: Country name
     * Example: Russian Federation
     */
    get countryName(): string | undefined {
        return this._country_name;
    }

    readonly _pattern: string | undefined;

    /**
     * Description: Phone number pattern, where '_' is place for digit
     * Example: ___ ___-__-__
     */
    get pattern(): string | undefined {
        return this._pattern;
    }

    constructor(props: ICountryPhoneCode) {
        if (typeof props.calling_code === 'string') {
            this._calling_code = props.calling_code.trim();
        }
        if (typeof props.country_code === 'string') {
            this._country_code = props.country_code.trim();
        }
        if (typeof props.country_name === 'string') {
            this._country_name = props.country_name.trim();
        }
        if (typeof props.pattern === 'string') {
            this._pattern = props.pattern.trim();
        }
    }

    serialize(): ICountryPhoneCode {
        const data: ICountryPhoneCode = {
        };
        if (typeof this._calling_code !== 'undefined') {
            data.calling_code = this._calling_code;
        }
        if (typeof this._country_code !== 'undefined') {
            data.country_code = this._country_code;
        }
        if (typeof this._country_name !== 'undefined') {
            data.country_name = this._country_name;
        }
        if (typeof this._pattern !== 'undefined') {
            data.pattern = this._pattern;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            country_code: !this._country_code ? true : typeof this._country_code === 'string',
            country_name: !this._country_name ? true : typeof this._country_name === 'string',
            calling_code: !this._calling_code ? true : typeof this._calling_code === 'string',
            pattern: !this._pattern ? true : typeof this._pattern === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICountryPhoneCode): CountryPhoneCode {
        return new CountryPhoneCode(props);
    }

    readonly keys: { [key: string]: string } = {
        callingCode: 'calling_code',
        countryCode: 'country_code',
        countryName: 'country_name',
        pattern: 'pattern',
        }
;

    mergeDeepWith(props: Partial<CountryPhoneCode>): CountryPhoneCode {
        const updateData: Partial<ICountryPhoneCode> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CountryPhoneCode) => {
            const updateKey = this.keys[key] as keyof ICountryPhoneCode;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICountryPhoneCode, keyof ICountryPhoneCode>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CountryPhoneCode({ ...this.serialize(), ...updateData });
    }
}
