import TariffsApi from 'Apis/tariffs';
import Tariff from 'Entities/Tariff';
import { actionCreator } from '.';

export enum TariffActions {
    GetTariffs = 'GetTariffs',
    SaveTariffs = 'SaveTariffs',
    GetAllTariffs = 'GetAllTariffs',
    GetTariffsForServer = 'GetTariffsForServer',
}

const getTariffsList = actionCreator<number>(TariffActions.GetTariffs);
const saveTariffsList = actionCreator<Tariff[]>(TariffActions.SaveTariffs);

const getAllTariffs = actionCreator(TariffActions.GetAllTariffs);

type GetTariffsForServer = Parameters<typeof TariffsApi.listTariffsForServer>;
const getTariffsForServer = actionCreator<GetTariffsForServer>(TariffActions.GetTariffsForServer);

export {
    getTariffsList,
    saveTariffsList,
    getAllTariffs,
    getTariffsForServer,
};
