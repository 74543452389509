import { useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { Store } from 'Store';

const selectPayerInfo = (store: Store) => store.profile.profilePayer;
const selectLang = (store: Store) => store.intl.currentLocale;
const selector = createSelector([selectPayerInfo, selectLang], (s, l) => ({
    havePayerInfo: s ? s.length > 0 : false,
    lang: l,
}));

export const useIsPayer = () => {
    const { havePayerInfo } = useSelector(selector, shallowEqual);
    return { canPay: havePayerInfo };
};
