import React, { FC } from 'react';

import { useIntl, EmptyPageLayout } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';

import { Contacts } from '..';

interface EmptyProps {
    type: 'history' | 'open' | 'checkbox_checked';
}

const Empty: FC<EmptyProps> = ({ type }) => {
    const intl = useIntl();

    if (type === 'open') {
        return (
            <EmptyPageLayout
                title={intl.getMessage('support_empty_title')}
                desc={intl.getMessage('support_empty_unread_desc')}
                link={RoutePath.CreateTicket}
                linkText={intl.getMessage('support_create_ticket')}
                contacts={<Contacts emptyPage />}
            />
        );
    }

    if (type === 'checkbox_checked') {
        return (
            <EmptyPageLayout
                desc={intl.getMessage('support_empty_checkbox_checked_desc')}
                border
                grayImg
            />
        );
    }

    return (
        <EmptyPageLayout
            title={intl.getMessage('support_empty_title')}
            desc={intl.getMessage('support_empty_read_desc')}
            link={RoutePath.CreateTicket}
            linkText={intl.getMessage('support_create_ticket')}
            contacts={<Contacts emptyPage />}
        />
    );
};

export default Empty;
