import SecurityActionSettingUpdate, { ISecurityActionSettingUpdate } from './SecurityActionSettingUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISecurityActionSettingsUpdate {
    security_code?: string;
    settings: ISecurityActionSettingUpdate[];
}

export default class SecurityActionSettingsUpdate {
    readonly _security_code: string | undefined;

    /** */
    get securityCode(): string | undefined {
        return this._security_code;
    }

    readonly _settings: SecurityActionSettingUpdate[];

    /** */
    get settings(): SecurityActionSettingUpdate[] {
        return this._settings;
    }

    constructor(props: ISecurityActionSettingsUpdate) {
        if (typeof props.security_code === 'string') {
            this._security_code = props.security_code.trim();
        }
        this._settings = props.settings.map((p) => new SecurityActionSettingUpdate(p));
    }

    serialize(): ISecurityActionSettingsUpdate {
        const data: ISecurityActionSettingsUpdate = {
            settings: this._settings.map((p) => p.serialize()),
        };
        if (typeof this._security_code !== 'undefined') {
            data.security_code = this._security_code;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            settings: this._settings.reduce((result, p) => result && p.validate().length === 0, true),
            security_code: !this._security_code ? true : typeof this._security_code === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISecurityActionSettingsUpdate): SecurityActionSettingsUpdate {
        return new SecurityActionSettingsUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        securityCode: 'security_code',
        settings: 'settings',
        }
;

    mergeDeepWith(props: Partial<SecurityActionSettingsUpdate>): SecurityActionSettingsUpdate {
        const updateData: Partial<ISecurityActionSettingsUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SecurityActionSettingsUpdate) => {
            const updateKey = this.keys[key] as keyof ISecurityActionSettingsUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISecurityActionSettingsUpdate, keyof ISecurityActionSettingsUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SecurityActionSettingsUpdate({ ...this.serialize(), ...updateData });
    }
}
