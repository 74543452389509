import { BackupPolicyFrequency } from './BackupPolicyFrequency';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IBackupPolicyUpdate {
    frequency: BackupPolicyFrequency;
    quantity: number;
}

export default class BackupPolicyUpdate {
    readonly _frequency: BackupPolicyFrequency;

    get frequency(): BackupPolicyFrequency {
        return this._frequency;
    }

    static frequencyValidate(frequency: BackupPolicyFrequency): boolean {
        return Object.keys(BackupPolicyFrequency).includes(frequency);
    }

    readonly _quantity: number;

    /**
     * Description: Number of preserved backups
     * Example: 1
     */
    get quantity(): number {
        return this._quantity;
    }

    static quantityValidate(quantity: number): boolean {
        return typeof quantity === 'number';
    }

    constructor(props: IBackupPolicyUpdate) {
        this._frequency = props.frequency;
        this._quantity = props.quantity;
    }

    serialize(): IBackupPolicyUpdate {
        const data: IBackupPolicyUpdate = {
            frequency: this._frequency,
            quantity: this._quantity,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            quantity: typeof this._quantity === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IBackupPolicyUpdate): BackupPolicyUpdate {
        return new BackupPolicyUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        frequency: 'frequency',
        quantity: 'quantity',
        }
;

    mergeDeepWith(props: Partial<BackupPolicyUpdate>): BackupPolicyUpdate {
        const updateData: Partial<IBackupPolicyUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof BackupPolicyUpdate) => {
            const updateKey = this.keys[key] as keyof IBackupPolicyUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IBackupPolicyUpdate, keyof IBackupPolicyUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new BackupPolicyUpdate({ ...this.serialize(), ...updateData });
    }
}
