import React, { FC, useEffect } from 'react';
import { Col, Divider, Layout, Row } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import cn from 'classnames';
import qs from 'qs';

import { useTitle } from 'Hooks';
import { useIntl, SimpleHeader, Link, Icon } from 'Common';
import Profile from 'Entities/Profile';
import { RoutePath, Routes } from 'Lib/helpers/routes';
import { toGb } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';
import { Store } from 'Store';
import boxesServerImage from 'Lib/assets/images/boxes_server.png';
import boxesServer2xImage from 'Lib/assets/images/boxes_server@2x.png';

import AddFunds from '../Billing/AddFunds';

import s from './OnboardingAddFunds.module.pcss';

const { Content } = Layout;

interface OnboardingAddFunds {
    profile: Profile | null;
}

const OnboardingAddFunds: FC<OnboardingAddFunds> = ({ profile }) => {
    const intl = useIntl();
    const route = useRouteMatch();
    const serverInstall = route.path === Routes.OnboardingAddFundsServer;

    const query = qs.parse(window.location.search.replace('?', ''));
    const showTrialBanner = serverInstall || query.showBanner === 'true';
    useTitle(intl.getMessage('onboarding_add_funds_page_title'));

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const trialMaxServersCount = profile?.profileLimits.maxTrialServersCount || 0;
    const trialMaxServersGb = toGb(profile?.profileLimits.maxTrialServersMemoryMib || 0);
    const trialBonusAmount = profile?.profileLimits.trialBonusAmount || 0;

    return (
        <Layout>
            <SimpleHeader />
            <Content className={cn(theme.content.content, theme.content.onboarding, s.content)}>
                <Row gutter={[48, 32]} justify="center">
                    <Col span={24} md={16}>
                        <div className={cn(
                            theme.card.card, theme.card.rounded, theme.card.onbording,
                        )}
                        >
                            <div className={s.title}>
                                {serverInstall ? (
                                    intl.getMessage('onboarding_add_funds_title')
                                ) : (
                                    intl.getMessage('billing_add_funds')
                                )}
                            </div>
                            <AddFunds noLayout />
                        </div>
                    </Col>
                    {showTrialBanner && profile?.trialBonusAvailable && (
                        <Col span={24} md={8}>
                            <div className={cn(
                                theme.card.card, theme.card.rounded, theme.card.blue, s.trialCard,
                            )}
                            >
                                <img
                                    src={boxesServerImage}
                                    srcSet={`${boxesServer2xImage} 2x`}
                                    className={s.trialImage}
                                    alt="boxes"
                                />
                                <div className={cn(s.trialText, s.trialDesc)}>
                                    {intl.getMessage('onboarding_trial_title')}
                                    &ensp;
                                    {intl.getPlural('onboarding_trial_desc', trialMaxServersCount, { gb: trialMaxServersGb, bonus: trialBonusAmount })}
                                </div>
                                <Divider
                                    className={cn(theme.divider.divider, s.divider)}
                                />
                                <div className={s.trial}>
                                    <Link
                                        className={cn(
                                            theme.link.link,
                                            theme.link.white,
                                            s.trialLink,
                                            s.trialText,
                                        )}
                                        to={RoutePath.Trial}
                                    >
                                        {intl.getMessage('trial')}
                                        <Icon className={s.trialArrow} icon="arrow" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </Content>
        </Layout>
    );
};

const selectProfilePayer = (store: Store) => store.profile.info;
const selector = createSelector([
    selectProfilePayer,
], (profile) => ({
    profile,
}));

const mapStateToProps = (store: Store) => {
    return { ...selector(store) };
};
export default connect(mapStateToProps)(OnboardingAddFunds);
