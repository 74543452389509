import React, { FC, useEffect, useState } from 'react';
import { Route, withRouter, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import qs from 'qs';

import { useIntl } from 'Common';
import { messages } from 'Lib/intl/__locales';
import { saveRefId } from 'Lib/helpers/utils';
import { getCssColors, getCurrentTheme, getHeaderHeight } from 'Lib/helpers/helpers';
import { WebTheme } from 'Entities/WebTheme';
import { Store } from 'Store';

import Routes from './Routes';

const selectProfile = (st: Store) => st.profile.info;
const selectUserLoggedIn = (s: Store) => (s.user.loggedIn);
const selector = createSelector(
    [selectProfile, selectUserLoggedIn],
    (profile, loggedIn) => ({ profile, loggedIn }),
);

const BasicRouteComponent: FC<RouteComponentProps<{lang: string}>> = React.memo(({
    location, history,
}) => {
    const { profile, loggedIn } = useSelector(selector);

    const intl = useIntl();
    const [containLocale, setContainLocale] = useState(false);

    const setTheme = (theme: WebTheme) => {
        AppConfigStorage.setTheme(theme);
        document.body.dataset.theme = theme;
    };

    const trackAffilateVisits = () => {
        const { search } = location;

        const query = qs.parse(search.replace('?', ''));

        if (query?.refid) {
            saveRefId(String(query.refid));
        }
    };

    const handleAutoTheme = (e: MediaQueryListEvent, profileTheme: WebTheme) => {
        if (profileTheme !== WebTheme.AUTO) {
            return;
        }

        if (e.matches) {
            setTheme(WebTheme.DARK);
        } else {
            setTheme(WebTheme.LIGHT);
        }
    };

    useEffect(() => {
        trackAffilateVisits();
    }, [location]);

    useEffect(() => {
        const { pathname, search, hash } = location;

        const maybeLang = pathname.length === 3 ? pathname.substring(1) : pathname.substring(1).split('/')[0];
        let contain = false;
        Object.keys(messages).forEach((m) => {
            if (m === maybeLang) {
                contain = true;
                setContainLocale(true);
            }
        });
        if (!contain) {
            history.replace(`/${intl.currentLocale}${location.pathname}${search}${hash}`);
            setContainLocale(true);
        }

        // If there is an error in the CSS variables, we will get error message on any page
        getHeaderHeight();
        getCssColors();
    }, []);

    useEffect(() => {
        if (!profile || !loggedIn) {
            return;
        }

        if (profile.webTheme === WebTheme.AUTO) {
            const currentTheme = getCurrentTheme(profile.webTheme);
            setTheme(currentTheme);

            const colorSchemeMedia = window.matchMedia('(prefers-color-scheme: dark)');

            if (colorSchemeMedia.addEventListener !== undefined) {
                colorSchemeMedia.addEventListener('change', (e) => {
                    handleAutoTheme(e, profile.webTheme);
                });
            } else {
                // Deprecated addListener for older versions of Safari
                colorSchemeMedia.addListener((e) => {
                    handleAutoTheme(e, profile.webTheme);
                });
            }
        } else {
            setTheme(profile.webTheme);
        }
    }, [profile?.webTheme, loggedIn]);

    useEffect(() => {
        if (loggedIn) {
            UserStorage.migrateUserSettings();
            UserStorage.removeObsoleteSettings();
        }
    }, [loggedIn]);

    return containLocale ? <Route path="/:lang" component={Routes} /> : null;
});

export default withRouter(BasicRouteComponent);
