import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Store } from 'Store';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useIntl } from 'Common';
import DnsNameServer from 'Entities/DnsNameServer';
import Profile from 'Entities/Profile';
import { WebTheme } from 'Entities/WebTheme';
import { getCurrentTheme } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

import dnsImage from 'Lib/assets/images/dns.png';
import dns2xImage from 'Lib/assets/images/dns@2x.png';
import dnsImageDark from 'Lib/assets/images/dns_dark.png';
import dns2xImageDark from 'Lib/assets/images/dns_dark@2x.png';

import s from '../Dns.module.pcss';

interface InfoOwnProps {
    nameServers: DnsNameServer[];
}

interface InfoStoreProps {
    profile: Profile | null;
}

type InfoProps = InfoStoreProps & InfoOwnProps;

const Info: FC<InfoProps> = ({ profile, nameServers }) => {
    const intl = useIntl();

    let images = {
        dnsImage,
        dns2xImage,
    };

    const currentTheme = profile?.webTheme && getCurrentTheme(profile.webTheme);

    if (currentTheme === WebTheme.DARK) {
        images = {
            dnsImage: dnsImageDark,
            dns2xImage: dns2xImageDark,
        };
    }

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.plate,
                theme.card.cloud,
                theme.card.rounded,
                s.info,
            )}
        >
            <div className={s.title}>
                {intl.getMessage('dns_info_desc')}
            </div>
            <div className={s.list}>
                {nameServers && nameServers.map(({ nameServer, ipAddress }) => (
                    <div className={s.row} key={nameServer}>
                        <div className={s.label}>
                            {nameServer}
                        </div>
                        <div className={s.value}>
                            {ipAddress}
                        </div>
                    </div>
                ))}
            </div>
            <img
                src={images.dnsImage}
                srcSet={`${images.dns2xImage} 2x`}
                className={s.image}
                alt="dns"
            />
        </div>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([selectProfile], (profile) => ({ profile }));

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(Info);
