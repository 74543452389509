import React, { FC, useState, ChangeEvent } from 'react';
import { Input } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { addUserToTenant } from 'Actions/tenant';
import { useIntl, CodeInput, CommonModalLayout, notifyError, notifySuccess } from 'Common';
import { emailValidate } from 'Lib/helpers/utils';
import { useSecurityActions } from 'Lib/hooks/hooks';
import { SecurityActionType } from 'Entities/SecurityActionType';
import Error from 'Entities/Error';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';

interface CloseAccessModalProps {
    visible: boolean;
    close: () => void;
    tenantId: number;
}

const ModalInvite: FC<CloseAccessModalProps> = ({ visible, close, tenantId }) => {
    const intl = useIntl();
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const dispatch = useDispatch();

    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityActions(SecurityActionType.TENANT_SHARE_ACCESS);
    const handleOk = () => {
        if (!emailValidate(email)) {
            setEmailError(true);
            return;
        }
        if (shouldConfirmAction && !codeSent) {
            sendConfirmationCode({
                tenantId,
            });
        }
        const result = () => {
            notifySuccess(intl.getMessage('user_invited', { email }), 'user_invited');
            close();
        };

        const onError = (error: Error) => {
            if (error.errorCode === ApiErrorCode.CLIENT_ALREADY_IN_TENANT) {
                close();
                notifyError(apiErrorCodeTranslate(
                    intl,
                    ApiErrorCode.CLIENT_ALREADY_IN_TENANT,
                ), ApiErrorCode.CLIENT_ALREADY_IN_TENANT);
            } else if (error.errorCode === ApiErrorCode.TENANT_MAX_CLIENTS) {
                close();
                notifyError(apiErrorCodeTranslate(
                    intl,
                    ApiErrorCode.TENANT_MAX_CLIENTS,
                ), ApiErrorCode.TENANT_MAX_CLIENTS);
            } else if (error.fields?.email
                && error.fields?.email[0].error_code === ApiErrorCode.OBJECT_NOT_FOUND) {
                notifyError(intl.getMessage('no_such_user'), ApiErrorCode.OBJECT_NOT_FOUND);
                setEmailError(true);
            } else {
                setCodeError(true);
            }
        };

        if (!shouldConfirmAction) {
            dispatch(addUserToTenant([tenantId, { email }], {
                error: onError,
                result,
            }));
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(addUserToTenant([tenantId, {
                email,
                security_code: code,
            }], {
                error: onError,
                result,
            }));
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        setEmailError(false);
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('invite')}
            handleSubmit={handleOk}
            handleClose={close}
            buttonText={intl.getMessage('send')}
        >
            <div className="modal__desc">
                {intl.getMessage('modal_invite_desc')}
            </div>
            <div className="modal__desc">
                <Input
                    type="email"
                    name="email"
                    size="large"
                    value={email}
                    placeholder={intl.getMessage('email')}
                    className={cn('input', { input_error: emailError })}
                    onChange={onChange}
                    autoFocus
                />
            </div>
            {(codeSent && deliveryMessage) && (
                <>
                    <div className="modal__desc modal__desc--gray">
                        {deliveryMessage}
                    </div>
                    <CodeInput
                        value={code}
                        setValue={(e) => setCode(e)}
                        codeError={codeError}
                        setCodeError={(e) => setCodeError(e)}
                        onSendAgain={() => sendConfirmationCode({
                            tenantId,
                        })}
                    />
                </>
            )}
        </CommonModalLayout>
    );
};

export default ModalInvite;
