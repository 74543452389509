import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Row, Col } from 'antd';
import cn from 'classnames';

import { Select, Option, Icon, useIntl } from 'Common';
import ServerDistribution from 'Entities/ServerDistribution';
import Server from 'Entities/Server';
import { NewServerFormValues } from 'Lib/helpers/newServer';
import { getServerIcon } from 'Lib/helpers/utils';
import { Store } from 'Store';
import { DistributionFamily } from 'Entities/DistributionFamily';
import theme from 'Lib/theme';

import s from './Distribution.module.pcss';

const normalizeDistributions = (list: ServerDistribution[]) => {
    const types = list.map((item) => item.type);
    const uniqueTypes = [...new Set(types)];
    return uniqueTypes
        .map((type) => {
            const versions = list.filter((item) => item.type === type);
            const title = versions && versions[0].name;

            return {
                id: type,
                title,
                versions,
            };
        });
};

interface DistributionStoreProps {
    distributions: ServerDistribution[];
    servers: Server[];
}
interface DistributionOwnProps {
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
    values: NewServerFormValues;
}

type DistributionProps = DistributionStoreProps & DistributionOwnProps;

const Distribution: FC<DistributionProps> = ({
    servers, distributions, setFieldValue, values,
}) => {
    const intl = useIntl();
    const nDistr = normalizeDistributions(distributions);
    const currentDistribution = nDistr.find((v) => v.id === values.distribution_name);
    const latestServer = servers[0];

    useEffect(() => {
        // we check if user have old distr in saved values
        if (values.prevValues
            && !currentDistribution?.versions.find((v) => v.id === values.distribution_id)) {
            setFieldValue('distribution_id', currentDistribution?.versions[0].id);
        }
        // If we took data from previous instalation
        // we don't need to set id here;
        if (!values.prevValues) {
            setFieldValue('distribution_id', currentDistribution?.versions[0].id);
        }
    }, [values.distribution_name]);

    const onDistributionNameChange = (v: DistributionFamily) => {
        setFieldValue('distribution_name', v);
        setFieldValue('prevValues', undefined);
    };

    const getBadge = (d: ServerDistribution) => {
        if (latestServer?.distribution.id === d.id) {
            return (
                <div className={cn(theme.Badge.badge, theme.Badge.last)}>
                    {intl.getMessage('latest_choice')}
                </div>
            );
        }
    };

    return (
        <div data-distribution="block">
            <Row gutter={[24, 0]}>
                <Col span={24} sm={12}>
                    <Select
                        value={values.distribution_name}
                        onChange={onDistributionNameChange}
                        className={s.select}
                        listHeight={300}
                        optionLabelProp="label"
                        size="big"
                        center
                        block
                    >
                        {nDistr.map((v) => (
                            <Option
                                key={v.id}
                                value={v.id}
                                className="option option_server"
                                label={(
                                    <>
                                        <Icon icon={getServerIcon(v.id)} className={s.icon} />
                                        {v.title}
                                    </>
                                )}
                            >
                                <Icon icon={getServerIcon(v.id)} className={s.icon} />
                                {v.title}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={24} sm={12}>
                    {currentDistribution && (
                        <Select
                            value={values.distribution_id}
                            onChange={(e) => setFieldValue('distribution_id', e)}
                            optionLabelProp="label"
                            size="big"
                            block
                        >
                            {currentDistribution.versions.map((v) => (
                                <Option
                                    key={v.id}
                                    value={v.id}
                                    label={(
                                        <>
                                            {v.version}&nbsp;
                                            <span className={theme.color.gray}>
                                                x{v.bitness}
                                            </span>
                                        </>
                                    )}
                                    className="option option_server"
                                >
                                    {v.version}

                                    {getBadge(v)}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Col>
            </Row>
        </div>
    );
};

const selectDistribution = (store: Store) => store.serverDistribution;
const selectServer = (store: Store) => store.server;

const selector = createSelector(
    [selectDistribution, selectServer],
    (distributions, servers) => ({
        distributions: Array.from(distributions.values()),
        servers: Array.from(servers.values()),
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Distribution);
