/* eslint-disable no-else-return */
import React, { FC, useEffect, ComponentClass, useState } from 'react';
import { Layout } from 'antd';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { connect, useSelector, shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { LoaderPageLayout, useIntl, DiscountNotice } from 'Common';
import { Routes, linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import { Locale } from 'Lib/intl';

import { Login, Registration, RecoveryPassword, RegistrationConfirm, ResetPassword, SocialAuthConfirm } from './Auth';
import { Billing, AddFunds, Trial, Documents, Expenses } from './Billing';
import { Clients, Finance as ClientsFinance, AddClient, AddClientFunds, OrderPayment as ClientsOrderPayment, TransferPayment as ClientsTransferPayment } from './Clients';
import Console from './Console';
import Dashboard from './Dashboard';
import { Zones, Ptr, Records } from './Dns';
import { ErrorNotifications, ErrorNotFound } from './Errors';
import NewProject from './NewProject';
import NewServer from './NewServer';
import { NotificationsContainer, Recipients as NotificationRecipients, UserSettings as NotificationUserSettings, Triggers, NewTrigger } from './Notifications';
import { Onboarding, OnboardingAddFunds } from './Onboarding';
import { Partner, Finance as PartnerFinance, OrderPayment, TransferPayment } from './Partner';
import { Profile, History as ProfileHistory, Settings as ProfileSettings } from './Profile';
import ProjectAccess from './ProjectAccess';
import ProjectBackup from './ProjectBackup';
import Projects from './Projects';
import PublicKeys from './PublicKeys';
import { Server, ServerTariff } from './Server';
import ServerAccess from './ServerAccess';
import ServerBackup from './ServerBackup';
import ServerHistory from './ServerHistory';
import ServersList from './ServersList';
import Sidebar from './Sidebar';
import Snapshots from './Snapshots';
import Alert from './Alert';
import { CreateTicket, Tickets, TicketChat } from './Support';
import Vnc from './Vnc';
import AdminLogin from './Admin';
import RateUs from './RateUs';
import AddAccount from './AddAccount';
import ChangeLog from './ChangeLog';

const withSidebar: (Component: FC<any> | ComponentClass<any>) => FC = (
    Component,
) => React.memo(() => {
    const haveNewVersion = useSelector((s: Store) => (s.user.haveNewVersion), shallowEqual);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        const onOnline = () => setIsOnline(true);
        const onOffline = () => setIsOnline(false);
        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);
        return () => {
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
        };
    }, []);
    return (
        <Layout>
            <Sidebar />
            <Component />
            {haveNewVersion && <Alert />}
            {!isOnline && <Alert online />}
        </Layout>
    );
});

const Loader = React.memo(() => (
    <LoaderPageLayout withHeader />
));

const withLoader: (Component: FC<any> | ComponentClass<any>) => FC = (Component) => () => {
    const loading = useSelector((s: Store) => s.user.loading, shallowEqual);
    if (loading) {
        return <Loader />;
    }

    return <Component />;
};

const withSafeRoute: (Component: FC<any> | ComponentClass<any>) => any = (Component) => {
    return withRouter(React.memo(({ match, history }: any) => {
        const { currentLocale } = useIntl();
        const { params } = match;
        useEffect(() => {
            if (params && Object.keys(params).length > 0) {
                Object.keys(params).forEach((key) => {
                    if (params[key] === 'undefined' || params[key] === 'null' || params[key] === 'NaN') {
                        history.push(linkPathBuilder(currentLocale, RoutePath.Root), {});
                    }
                });
            }
        }, []);

        return <Component />;
    }));
};

const AdminPrepared = React.memo(withSafeRoute(withLoader(AdminLogin)));
const AddClienPrepared = React.memo(withSafeRoute(withLoader(AddClient)));
const AddClientFundPrepared = React.memo(withSafeRoute(withLoader(AddClientFunds)));
const AddFundPrepared = React.memo(withSafeRoute(withLoader(AddFunds)));
const AddAccountPrepared = React.memo(withSafeRoute(withLoader(AddAccount)));
const BillingPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Billing))));
const ClientsFinancePrepared = React.memo(withSafeRoute(withLoader(withSidebar(ClientsFinance))));
const ClientsOrderPaymenPrepared = React.memo(withSafeRoute(withLoader(ClientsOrderPayment)));
const ClientsPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Clients))));
const ClientsTransferPaymenPrepared = React.memo(withSafeRoute(withLoader(ClientsTransferPayment)));
const ConsolePrepared = React.memo(withSafeRoute(withLoader(withSidebar(Console))));
const CreateTicketPrepared = React.memo(withSafeRoute(withLoader(CreateTicket)));
const DashboardPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Dashboard))));
const ExpensesPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Expenses))));
const DocumentsPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Documents))));
const NewProjectPrepared = React.memo(withSafeRoute(withLoader(NewProject)));
const NewServerPrepared = React.memo(withSafeRoute(withLoader(NewServer)));
const NewTriggerPrepared = React.memo(withSafeRoute(withLoader(NewTrigger)));
const NotificationRecipientsPrepared = React.memo(withSafeRoute(
    withLoader(withSidebar(NotificationRecipients)),
));
const NotificationsPrepared = React.memo(withSafeRoute(
    withLoader(withSidebar(NotificationsContainer)),
));
const NotificationUserSettingPrepared = React.memo(withSafeRoute(
    withLoader(NotificationUserSettings),
));
const OnboardingAddFundPrepared = React.memo(withSafeRoute(withLoader(OnboardingAddFunds)));
const OnboardingPrepared = React.memo(withSafeRoute(withLoader(Onboarding)));
const OrderPaymentPrepared = React.memo(withSafeRoute(withLoader(OrderPayment)));
const PartnerFinancePrepared = React.memo(withSafeRoute(withLoader(withSidebar(PartnerFinance))));
const PartnerPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Partner))));
const ProfileHistoryPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ProfileHistory))));
const ProfilePrepared = React.memo(withSafeRoute(withLoader(withSidebar(Profile))));
const ProfileSettingsPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ProfileSettings))));
const ProjectAccessPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ProjectAccess))));
const ProjectBackupPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ProjectBackup))));
const ProjectsListPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Projects))));
const PtrPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Ptr))));
const PublicKeysPrepared = React.memo(withSafeRoute(withLoader(withSidebar(PublicKeys))));
const RecordsPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Records))));
const RecoveryPasswordPrepared = React.memo(withSafeRoute(withLoader(RecoveryPassword)));
const RegistrationConfirmPrepared = React.memo(withSafeRoute(withLoader(RegistrationConfirm)));
const RegistrationPrepared = React.memo(withSafeRoute(withLoader(Registration)));
const ResetPasswordPrepared = React.memo(withSafeRoute(withLoader(ResetPassword)));
const ServerAccessPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ServerAccess))));
const ServerBackupPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ServerBackup))));
const ServerHistoryPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ServerHistory))));
const ServerPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Server))));
const ServerTariffPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ServerTariff))));
const ServersListPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ServersList))));
const SnapshotsPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Snapshots))));
const SocialAuthConfirmPrepared = React.memo(withSafeRoute(withLoader(SocialAuthConfirm)));
const TicketChatPrepared = React.memo(withSafeRoute(withLoader(withSidebar(TicketChat))));
const TicketsPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Tickets))));
const TransferPaymenPrepared = React.memo(withSafeRoute(withLoader(TransferPayment)));
const TriggersPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Triggers))));
const VncPrepared = React.memo(withSafeRoute(withLoader(Vnc)));
const ZonesPrepared = React.memo(withSafeRoute(withLoader(withSidebar(Zones))));
const ChangeLogPrepared = React.memo(withSafeRoute(withLoader(withSidebar(ChangeLog))));

const ErrorNotFoundPrepared = React.memo(withSafeRoute(withSidebar(ErrorNotFound)));

interface RouterComponentStoreProps {
    loggedIn: Store['user']['loggedIn'];
    loading: Store['user']['loading'];
    isChecked: boolean;
}
const AuthRoutes: FC = React.memo(() => {
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={Routes.AuthRegistration}
                    component={RegistrationPrepared}
                />
                <Route
                    exact
                    path={Routes.AuthRecoveryPassword}
                    component={RecoveryPasswordPrepared}
                />
                <Route
                    exact
                    path={Routes.AuthResetPassword}
                    component={ResetPasswordPrepared}
                />
                <Route
                    exact
                    path={Routes.AuthRegistrationConfirm}
                    component={RegistrationConfirmPrepared}
                />
                <Route
                    exact
                    path={Routes.SocialAuthConfirm}
                    component={SocialAuthConfirmPrepared}
                />
                <Route path={Routes.Root} component={Login} />
            </Switch>
            <ErrorNotifications />
        </>
    );
});

const AppRoutes: FC = React.memo(() => {
    const serverListRendered = <ServersListPrepared />;
    return (
        <>
            <Switch>
                <Route
                    exact
                    path={Routes.AuthRecoveryPassword}
                    component={RecoveryPasswordPrepared}
                />
                <Route
                    exact
                    path={Routes.AuthResetPassword}
                    component={ResetPasswordPrepared}
                />
                <Route
                    exact
                    path={Routes.ServersList}
                    render={() => serverListRendered}
                />
                <Route
                    exact
                    path={Routes.ProjectsList}
                    component={ProjectsListPrepared}
                />
                <Route
                    exact
                    path={Routes.NewServer}
                    component={NewServerPrepared}
                />
                <Route
                    exact
                    path={Routes.NewServerFromTenant}
                    component={NewServerPrepared}
                />
                <Route
                    exact
                    path={Routes.ServerReinstall}
                    component={NewServerPrepared}
                />
                <Route
                    exact
                    path={Routes.ServerClone}
                    component={NewServerPrepared}
                />
                <Route
                    exact
                    path={Routes.ProjectAccess}
                    component={ProjectAccessPrepared}
                />
                <Route
                    exact
                    path={Routes.ProjectBackup}
                    component={ProjectBackupPrepared}
                />
                <Route
                    exact
                    path={Routes.ServerBackup}
                    component={ServerBackupPrepared}
                />
                <Route
                    exact
                    path={Routes.ServerAccess}
                    component={ServerAccessPrepared}
                />
                <Route
                    exact
                    path={Routes.Server}
                    component={ServerPrepared}
                />
                <Route
                    exact
                    path={Routes.ServerChartModal}
                    component={ServerPrepared}
                />
                <Route
                    exact
                    path={Routes.ServerTariff}
                    component={ServerTariffPrepared}
                />
                <Route
                    exact
                    path={Routes.PublicKeys}
                    component={PublicKeysPrepared}
                />
                <Route
                    exact
                    path={Routes.Snapshots}
                    component={SnapshotsPrepared}
                />
                <Route
                    exact
                    path={Routes.NewProject}
                    component={NewProjectPrepared}
                />
                <Route
                    exact
                    path={Routes.NewProjectBackToPrevPage}
                    component={NewProjectPrepared}
                />
                <Route
                    exact
                    path={Routes.DnsZones}
                    component={ZonesPrepared}
                />
                <Route
                    exact
                    path={Routes.DnsZonesRecords}
                    component={RecordsPrepared}
                />
                <Route
                    exact
                    path={Routes.DnsPtr}
                    component={PtrPrepared}
                />
                <Route
                    exact
                    path={Routes.ServerHistory}
                    component={ServerHistoryPrepared}
                />
                <Route
                    exact
                    path={Routes.Console}
                    component={ConsolePrepared}
                />
                <Route
                    exact
                    path={Routes.Vnc}
                    component={VncPrepared}
                />
                <Route
                    exact
                    path={Routes.Dashboard}
                    component={DashboardPrepared}
                />
                <Route
                    exact
                    path={Routes.Root}
                    component={DashboardPrepared}
                />
                <Route
                    exact
                    path={Routes.Billing}
                    component={BillingPrepared}
                />
                <Route
                    exact
                    path={Routes.AddAccount}
                    component={AddAccountPrepared}
                />
                <Route
                    exact
                    path={Routes.AddFunds}
                    component={AddFundPrepared}
                />
                <Route
                    exact
                    path={Routes.Trial}
                    component={Trial}
                />
                <Route
                    exact
                    path={Routes.Documents}
                    component={DocumentsPrepared}
                />
                <Route
                    exact
                    path={Routes.Expenses}
                    component={ExpensesPrepared}
                />
                <Route
                    exact
                    path={Routes.Notifications}
                    component={NotificationsPrepared}
                />
                <Route
                    exact
                    path={Routes.NotificationRecipients}
                    component={NotificationRecipientsPrepared}
                />
                <Route
                    exact
                    path={Routes.NotificationUserSettings}
                    component={NotificationUserSettingPrepared}
                />
                <Route
                    exact
                    path={Routes.Triggers}
                    component={TriggersPrepared}
                />
                <Route
                    exact
                    path={Routes.NewTrigger}
                    component={NewTriggerPrepared}
                />
                <Route
                    exact
                    path={Routes.EditTrigger}
                    component={NewTriggerPrepared}
                />
                <Route
                    exact
                    path={Routes.Profile}
                    component={ProfilePrepared}
                />
                <Route
                    exact
                    path={Routes.ProfileModal}
                    component={ProfilePrepared}
                />
                <Route
                    exact
                    path={Routes.ProfileEditModal}
                    component={ProfilePrepared}
                />
                <Route
                    exact
                    path={Routes.ProfileSettings}
                    component={ProfileSettingsPrepared}
                />
                <Route
                    exact
                    path={Routes.ProfileHistory}
                    component={ProfileHistoryPrepared}
                />
                <Route
                    exact
                    path={Routes.Onboarding}
                    component={OnboardingPrepared}
                />
                <Route
                    exact
                    path={Routes.OnboardingRequisites}
                    component={OnboardingPrepared}
                />
                <Route
                    exact
                    path={Routes.Partner}
                    component={PartnerPrepared}
                />
                <Route
                    exact
                    path={Routes.PartnerFinance}
                    component={PartnerFinancePrepared}
                />
                <Route
                    exact
                    path={Routes.PartnerOrderPayment}
                    component={OrderPaymentPrepared}
                />
                <Route
                    exact
                    path={Routes.PartnerTransferPayment}
                    component={TransferPaymenPrepared}
                />
                <Route
                    exact
                    path={Routes.Clients}
                    component={ClientsPrepared}
                />
                <Route
                    exact
                    path={Routes.ClientsFinance}
                    component={ClientsFinancePrepared}
                />
                <Route
                    exact
                    path={Routes.ClientsAdd}
                    component={AddClienPrepared}
                />
                <Route
                    exact
                    path={Routes.ClientsOrderPayment}
                    component={ClientsOrderPaymenPrepared}
                />
                <Route
                    exact
                    path={Routes.ClientsTransferPayment}
                    component={ClientsTransferPaymenPrepared}
                />
                <Route
                    exact
                    path={Routes.ClientsAddFunds}
                    component={AddClientFundPrepared}
                />
                <Route
                    exact
                    path={Routes.ClientAddFunds}
                    component={AddClientFundPrepared}
                />
                <Route
                    exact
                    path={Routes.Onboarding}
                    component={OnboardingPrepared}
                />
                <Route
                    exact
                    path={Routes.OnboardingServer}
                    component={OnboardingPrepared}
                />
                <Route
                    exact
                    path={Routes.OnboardingAddFunds}
                    component={OnboardingAddFundPrepared}
                />
                <Route
                    exact
                    path={Routes.OnboardingAddFundsServer}
                    component={OnboardingAddFundPrepared}
                />
                <Route
                    key={Routes.CreateTicket}
                    exact
                    path={Routes.CreateTicket}
                    component={CreateTicketPrepared}
                />
                <Route
                    key={Routes.CreateTicketWithSubject}
                    exact
                    path={Routes.CreateTicketWithSubject}
                    component={CreateTicketPrepared}
                />
                <Route
                    key={Routes.CreateDeleteClientTicket}
                    exact
                    path={Routes.CreateDeleteClientTicket}
                    component={CreateTicketPrepared}
                />
                <Route
                    key={Routes.TicketChat}
                    exact
                    path={Routes.TicketChat}
                    component={TicketChatPrepared}
                />
                <Route
                    exact
                    key={Routes.Tickets}
                    path={Routes.Tickets}
                    component={TicketsPrepared}
                />
                <Route
                    exact
                    key={Routes.AdminLogin}
                    path={Routes.AdminLogin}
                    component={AdminPrepared}
                />
                <Route
                    exact
                    key={Routes.NotFound}
                    path={Routes.NotFound}
                    component={ErrorNotFoundPrepared}
                />
                <Route
                    exact
                    path={Routes.SocialAuthConfirm}
                    component={SocialAuthConfirmPrepared}
                />
                <Route
                    exact
                    path={Routes.ChangeLog}
                    component={ChangeLogPrepared}
                />
                <Redirect from={Routes.AuthRegistration} to={Routes.Dashboard} />
                <Redirect from={Routes.AuthRecoveryPassword} to={Routes.Dashboard} />
                <Redirect from={Routes.AuthResetPassword} to={Routes.Dashboard} />
                <Redirect from={Routes.AuthRegistrationConfirm} to={Routes.Dashboard} />
                <Redirect to={linkPathBuilder(Locale.ru, RoutePath.NotFound)} />
            </Switch>
            <ErrorNotifications />
            <RateUs />
            <DiscountNotice />
        </>
    );
});

const RouterComponent: FC<RouterComponentStoreProps> = React.memo((
    { loggedIn, loading, isChecked },
) => {
    if (!isChecked) {
        return null;
    }
    if (loggedIn || loading) {
        return <AppRoutes />;
    }
    return <AuthRoutes />;
});
const selectUserLoading = (s: Store) => (s.user.loading);
const selectUserLoggedIn = (s: Store) => (s.user.loggedIn);
const selectUserIsChecked = (s: Store) => (s.user.isChecked);

const selector = createSelector([
    selectUserLoading,
    selectUserLoggedIn,
    selectUserIsChecked,
], (
    loading,
    loggedIn,
    isChecked,
) => ({
    loading,
    loggedIn,
    isChecked,
}));

const mapStoreToRouterProps = (s: Store) => ({ ...selector(s) });
export default connect(mapStoreToRouterProps)(RouterComponent);
