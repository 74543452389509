import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Icon, Button, Modal } from 'Common';
import { SUPPORT_MAIL } from 'Lib/helpers/consts';
import PartnerPayment from 'Entities/PartnerPayment';
import theme from 'Lib/theme';

import s from './ModalAct.module.pcss';

interface ModalActProps {
    partnerPayment: PartnerPayment;
    onClose: () => void;
}

const ModalAct: FC<ModalActProps> = ({ partnerPayment, onClose }) => {
    const intl = useIntl();

    const supportLink = (text: string) => (
        <a
            href={`mailto:${SUPPORT_MAIL}`}
            className={cn(theme.link.link, theme.link.blue)}
        >
            {text}
        </a>
    );

    const downloadLink = (text: string) => (
        <a
            href={partnerPayment?.downloadLink}
            className={cn(theme.link.link, theme.link.blue)}
        >
            {text}
        </a>
    );

    return (
        <Modal
            visible
            wrapClassName="modal"
            onCancel={onClose}
            maskClosable={false}
            maskStyle={{ background: 'white' }}
            footer={(
                <Button
                    onClick={() => onClose()}
                    type="primary"
                    size="big"
                >
                    {intl.getMessage('close')}
                </Button>
            )}
            closeIcon={<Icon icon="close_big" />}
            width={640}
            title={intl.getMessage('partner_act_title')}
            centered
        >
            <div className={s.desc}>
                {intl.getMessage('partner_act_desc')}
            </div>
            <div className={s.list}>
                <div className={s.listItem}>
                    <div className={s.text}>
                        {intl.getMessage('partner_act_download', {
                            a: downloadLink,
                        })}
                    </div>
                </div>
                <div className={s.listItem}>
                    <div className={s.text}>
                        {intl.getMessage('partner_act_scan', {
                            a: supportLink,
                            support: SUPPORT_MAIL,
                        })}
                    </div>
                </div>
                <div className={s.listItem}>
                    <div className={s.text}>
                        {intl.getMessage('partner_act_send')}
                    </div>
                </div>
            </div>
            <div className={cn(s.desc, s.desc_bottom)}>
                {intl.getMessage('partner_act_final')}
            </div>
        </Modal>
    );
};

export default ModalAct;
