// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsZone {
    export_dns_zone_url: string;
    host: string;
    id: number;
    records_count: number;
    tenant_id: number;
    time_created: string;
    time_created_millis: number;
    time_updated: string;
    time_updated_millis: number;
}

export default class DnsZone {
    readonly _export_dns_zone_url: string;

    /**
     * Description: Tenant identifier
     * Example: /documents/dns/50214557/www.example.com.txt
     */
    get exportDnsZoneUrl(): string {
        return this._export_dns_zone_url;
    }

    static exportDnsZoneUrlValidate(exportDnsZoneUrl: string): boolean {
        return typeof exportDnsZoneUrl === 'string' && !!exportDnsZoneUrl.trim();
    }

    readonly _host: string;

    /**
     * Description: Host
     * Example: www.example.com
     */
    get host(): string {
        return this._host;
    }

    static hostValidate(host: string): boolean {
        return typeof host === 'string' && !!host.trim();
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 2755
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _records_count: number;

    /**
     * Description: DNS Records count for this zone
     * Example: 7
     */
    get recordsCount(): number {
        return this._records_count;
    }

    static recordsCountValidate(recordsCount: number): boolean {
        return typeof recordsCount === 'number';
    }

    readonly _tenant_id: number;

    /**
     * Description: Tenant identifier
     * Example: 14628
     */
    get tenantId(): number {
        return this._tenant_id;
    }

    static tenantIdValidate(tenantId: number): boolean {
        return typeof tenantId === 'number';
    }

    readonly _time_created: string;

    /** */
    get timeCreated(): string {
        return this._time_created;
    }

    static timeCreatedValidate(timeCreated: string): boolean {
        return typeof timeCreated === 'string' && !!timeCreated.trim();
    }

    readonly _time_created_millis: number;

    /**
     * Description: Time created, UTC millis
     * Example: 1593064584178
     */
    get timeCreatedMillis(): number {
        return this._time_created_millis;
    }

    static timeCreatedMillisValidate(timeCreatedMillis: number): boolean {
        return typeof timeCreatedMillis === 'number';
    }

    readonly _time_updated: string;

    /** */
    get timeUpdated(): string {
        return this._time_updated;
    }

    static timeUpdatedValidate(timeUpdated: string): boolean {
        return typeof timeUpdated === 'string' && !!timeUpdated.trim();
    }

    readonly _time_updated_millis: number;

    /**
     * Description: Time updated, UTC millis
     * Example: 1593064257246
     */
    get timeUpdatedMillis(): number {
        return this._time_updated_millis;
    }

    static timeUpdatedMillisValidate(timeUpdatedMillis: number): boolean {
        return typeof timeUpdatedMillis === 'number';
    }

    constructor(props: IDnsZone) {
        this._export_dns_zone_url = props.export_dns_zone_url.trim();
        this._host = props.host.trim();
        this._id = props.id;
        this._records_count = props.records_count;
        this._tenant_id = props.tenant_id;
        this._time_created = props.time_created.trim();
        this._time_created_millis = props.time_created_millis;
        this._time_updated = props.time_updated.trim();
        this._time_updated_millis = props.time_updated_millis;
    }

    serialize(): IDnsZone {
        const data: IDnsZone = {
            export_dns_zone_url: this._export_dns_zone_url,
            host: this._host,
            id: this._id,
            records_count: this._records_count,
            tenant_id: this._tenant_id,
            time_created: this._time_created,
            time_created_millis: this._time_created_millis,
            time_updated: this._time_updated,
            time_updated_millis: this._time_updated_millis,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            host: typeof this._host === 'string' && !!this._host.trim(),
            records_count: typeof this._records_count === 'number',
            time_created: typeof this._time_created === 'string' && !!this._time_created.trim(),
            time_created_millis: typeof this._time_created_millis === 'number',
            time_updated: typeof this._time_updated === 'string' && !!this._time_updated.trim(),
            time_updated_millis: typeof this._time_updated_millis === 'number',
            tenant_id: typeof this._tenant_id === 'number',
            export_dns_zone_url: typeof this._export_dns_zone_url === 'string' && !!this._export_dns_zone_url.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsZone): DnsZone {
        return new DnsZone(props);
    }

    readonly keys: { [key: string]: string } = {
        exportDnsZoneUrl: 'export_dns_zone_url',
        host: 'host',
        id: 'id',
        recordsCount: 'records_count',
        tenantId: 'tenant_id',
        timeCreated: 'time_created',
        timeCreatedMillis: 'time_created_millis',
        timeUpdated: 'time_updated',
        timeUpdatedMillis: 'time_updated_millis',
        }
;

    mergeDeepWith(props: Partial<DnsZone>): DnsZone {
        const updateData: Partial<IDnsZone> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsZone) => {
            const updateKey = this.keys[key] as keyof IDnsZone;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsZone, keyof IDnsZone>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsZone({ ...this.serialize(), ...updateData });
    }
}
