import UpdateCorporation, { IUpdateCorporation } from './UpdateCorporation';
import UpdateIndividual, { IUpdateIndividual } from './UpdateIndividual';
import UpdateNonResident, { IUpdateNonResident } from './UpdateNonResident';
import UpdateSoleTrader, { IUpdateSoleTrader } from './UpdateSoleTrader';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IUpdatePayerRequest {
    corporation?: IUpdateCorporation;
    individual?: IUpdateIndividual;
    non_resident?: IUpdateNonResident;
    sole_trader?: IUpdateSoleTrader;
}

export default class UpdatePayerRequest {
    readonly _corporation: UpdateCorporation | undefined;

    get corporation(): UpdateCorporation | undefined {
        return this._corporation;
    }

    readonly _individual: UpdateIndividual | undefined;

    get individual(): UpdateIndividual | undefined {
        return this._individual;
    }

    readonly _non_resident: UpdateNonResident | undefined;

    get nonResident(): UpdateNonResident | undefined {
        return this._non_resident;
    }

    readonly _sole_trader: UpdateSoleTrader | undefined;

    get soleTrader(): UpdateSoleTrader | undefined {
        return this._sole_trader;
    }

    constructor(props: IUpdatePayerRequest) {
        if (props.corporation) {
            this._corporation = new UpdateCorporation(props.corporation);
        }
        if (props.individual) {
            this._individual = new UpdateIndividual(props.individual);
        }
        if (props.non_resident) {
            this._non_resident = new UpdateNonResident(props.non_resident);
        }
        if (props.sole_trader) {
            this._sole_trader = new UpdateSoleTrader(props.sole_trader);
        }
    }

    serialize(): IUpdatePayerRequest {
        const data: IUpdatePayerRequest = {
        };
        if (typeof this._corporation !== 'undefined') {
            data.corporation = this._corporation.serialize();
        }
        if (typeof this._individual !== 'undefined') {
            data.individual = this._individual.serialize();
        }
        if (typeof this._non_resident !== 'undefined') {
            data.non_resident = this._non_resident.serialize();
        }
        if (typeof this._sole_trader !== 'undefined') {
            data.sole_trader = this._sole_trader.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            individual: !this._individual ? true : this._individual.validate().length === 0,
            sole_trader: !this._sole_trader ? true : this._sole_trader.validate().length === 0,
            corporation: !this._corporation ? true : this._corporation.validate().length === 0,
            non_resident: !this._non_resident ? true : this._non_resident.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IUpdatePayerRequest): UpdatePayerRequest {
        return new UpdatePayerRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        corporation: 'corporation',
        individual: 'individual',
        nonResident: 'non_resident',
        soleTrader: 'sole_trader',
        }
;

    mergeDeepWith(props: Partial<UpdatePayerRequest>): UpdatePayerRequest {
        const updateData: Partial<IUpdatePayerRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof UpdatePayerRequest) => {
            const updateKey = this.keys[key] as keyof IUpdatePayerRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IUpdatePayerRequest, keyof IUpdatePayerRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new UpdatePayerRequest({ ...this.serialize(), ...updateData });
    }
}
