import React, { FC } from 'react';

import { DeleteModalLayout, useIntl } from 'Common';
import theme from 'Lib/theme';

import s from './RemoveRecipientModal.module.pcss';

interface RemoveRecipientModalProps {
    visible: boolean;
    email: string;
    handleClose: () => void;
    handleDelete: () => void;
}

const RemoveRecipientModal: FC<RemoveRecipientModalProps> = ({
    visible,
    email,
    handleClose,
    handleDelete,
}) => {
    const intl = useIntl();

    const descriptionText = (text: string) => (
        <span className={s.description}>
            {text}
        </span>
    );

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('notifications_remove_recipient_title')}
            handleDelete={handleDelete}
            handleClose={handleClose}
        >
            <div className={theme.modal.desc}>
                {intl.getMessage('notifications_remove_recipient_desc', { email, b: descriptionText })}
            </div>
        </DeleteModalLayout>
    );
};

export default RemoveRecipientModal;
