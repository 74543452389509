import React, { FC } from 'react';

import { useIntl, DeleteModalLayout, mediumText } from 'Common';

interface ModalDeleteProps {
    visible: boolean;
    keyName: string;
    handleClose: () => void;
    handleSubmit: () => void;
}

const ModalDeleteKey: FC<ModalDeleteProps> = ({ visible, keyName, handleClose, handleSubmit }) => {
    const intl = useIntl();

    const handleDelete = () => {
        handleSubmit();
        handleClose();
    };

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('remove_key')}
            handleClose={handleClose}
            handleDelete={handleDelete}
        >
            <div className="modal__desc">
                {intl.getMessage('modal_remove_key_desc', { name: keyName, b: mediumText })}
            </div>
        </DeleteModalLayout>
    );
};

export default ModalDeleteKey;
