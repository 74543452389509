import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, Button } from 'Common';
import Server from 'Entities/Server';
import theme from 'Lib/theme';

import s from './CurrentState.module.pcss';
import ModalNewSnapshot from '../ModalNewSnapshot';

interface CurrentStateProps {
    server: Server;
    disabled: boolean;
}
const CurrentState: FC<CurrentStateProps> = ({ server, disabled }) => {
    const intl = useIntl();
    const [openModal, setOpenModal] = useState(false);
    return (
        <>
            <Button
                size="big"
                type="primary"
                className={theme.button.snapshot}
                disabled={disabled}
                onClick={() => setOpenModal(true)}
                iconClassName={s.icon}
                icon="monitoring"
            >
                <div>
                    <div className={cn(s.mobile, s.title)}>
                        {intl.getMessage('snapshots_create_modal')}
                    </div>
                    <div className={cn(s.mobile, s.desc)}>
                        {intl.getMessage('snapshots_current_state')}
                    </div>
                </div>
                <div className={s.desktop}>
                    {intl.getMessage('snapshots_create')}
                </div>
            </Button>
            {openModal && (
                <ModalNewSnapshot
                    visible
                    close={() => setOpenModal(false)}
                    server={server}
                />
            )}
        </>
    );
};

export default CurrentState;
