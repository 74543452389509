import React, { FC } from 'react';
import { Provider } from 'react-redux';

import Icons from 'Lib/theme/Icons';
import store from 'Store';

import Page from './Page';

const ErrorPage: FC = () => {
    return (
        <Provider store={store}>
            <Icons />
            <Page />
        </Provider>
    );
};

export default ErrorPage;
