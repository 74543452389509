import React, { FC } from 'react';

import { useIntl } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';

import ErrorLayout from './ErrorLayout';

const ErrorResetPassword: FC = () => {
    const intl = useIntl();

    return (
        <ErrorLayout
            code={404}
            title={intl.getMessage('error_page_invalid_link')}
            desc={intl.getMessage('error_page_invalid_link_reset')}
            link={RoutePath.AuthRecoveryPassword}
            linkText={intl.getMessage('recovery_password_alt')}
        />
    );
};

export default ErrorResetPassword;
