import React, { FC } from 'react';
import { Row, Col } from 'antd';

import { Checkbox } from 'Common';

import s from './NotificationSettingsTable.module.pcss';

interface NotificationSettingsRowProps {
    id: string;
    name: string;
    index: number;
    settings: any;
    handleChange: any;
}

const NotificationSettingsRow: FC<NotificationSettingsRowProps> = ({
    id, name, settings, index, handleChange,
}) => (
    <Row align="middle" gutter={[24, 16]} className="row row_nowrap">
        <Col flex="auto">
            <div className={s.setting}>
                {name}
            </div>
        </Col>
        <Col>
            <Checkbox
                id={`${id}.email`}
                name={`${id}.email`}
                checked={settings[index].email}
                handleChange={handleChange}
            />
        </Col>
        <Col>
            <Checkbox
                id={`${id}.sms`}
                name={`${id}.sms`}
                checked={settings[index].sms}
                handleChange={handleChange}
            />
        </Col>
    </Row>
);

export default NotificationSettingsRow;
