import { Action } from 'store/actions';

import {
    ServerBackupActions,
    saveServerBackupsList,
    saveBackupProgress,
    deleteBackupProgress,
} from 'Actions/serverBackup';
import ServerBackup from 'Entities/ServerBackup';

import { ServerBackupStore, serverBackupInitialState as initialState } from '../initialStates';

const reducer = (state: ServerBackupStore = initialState, action: Action): ServerBackupStore => {
    switch (action.type as ServerBackupActions) {
        case ServerBackupActions.SaveServerBackups: {
            const { payload } = action as ReturnType<typeof saveServerBackupsList>;
            const sb = new Map<string, ServerBackup>();
            payload.forEach((s) => {
                sb.set(s.id, s);
            });
            return { ...state, backups: sb };
        }
        case ServerBackupActions.SaveBackupProgress: {
            const {
                payload: {
                    backupId,
                    progress,
                },
            } = action as ReturnType<typeof saveBackupProgress>;
            const prog = new Map(state.progress);
            prog.set(backupId, progress);
            return { ...state, progress: prog };
        }
        case ServerBackupActions.DeleteBackupProgress: {
            const { payload } = action as ReturnType<typeof deleteBackupProgress>;
            const prog = new Map(state.progress);
            prog.delete(payload);
            return { ...state, progress: prog };
        }
        default:
            return state;
    }
};

export default reducer;
