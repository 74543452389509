import React, { FC, useContext } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useIntl, Comment, ServerInfo } from 'Common';
import { OperationStatus } from 'Entities/OperationStatus';
import OperationProgress from 'Entities/OperationProgress';
import ServerEntity from 'Entities/Server';
import { ServerState } from 'Entities/ServerState';
import Tenant from 'Entities/Tenant';
import { SERVER_VIEW } from 'Lib/helpers/consts';
import { Store } from 'Store';
import { ServerListContext } from 'Components/ServersList/factories';
import { Header, IpList, Subtitles } from './components';

import s from './Server.module.pcss';

interface ServerOwnProps {
    id: number;
    tenantId?: number;
    installing?: OperationProgress;
}
interface ServerStoreProps {
    server: ServerEntity | undefined;
    operationProgress?: OperationProgress;
    profile: Store['profile']['info'];
    payer: Store['profile']['profilePayer'];
    tenant: Tenant | undefined;
    batchServers: number[];
}

type ServerProps = ServerStoreProps & ServerOwnProps;

const Server: FC<ServerProps> = React.memo(({
    server,
    operationProgress,
    installing,
    profile,
    payer,
    tenant,
}) => {
    const { serverView } = useContext(ServerListContext);
    const intl = useIntl();
    if (!server) {
        return null;
    }
    const setupState = !!installing;
    const {
        id,
        state,
    } = server || {};
    if (setupState && !server) {
        return null;
    }
    const serverEnabled = state === ServerState.RUNNING;
    const serverClass = cn(
        s.server,
        { [s.server_enabled]: serverEnabled },
        { [s.server_setup]: setupState },
        { [s.server_list]: serverView === SERVER_VIEW.LIST },
        { [s.server_compact]: serverView === SERVER_VIEW.COMPACT },
    );

    const loading = operationProgress?.status === OperationStatus.PENDING || setupState;

    const serverContent = (
        <div className={s.body}>
            <div className={s.row}>
                <div className={s.column}>
                    <Subtitles id={id} tenant={tenant} mobile />
                    <div className={cn(s.row, s.row_ips)}>
                        <div className={s.label}>
                            {intl.getMessage('server_ip_list_ips')}
                        </div>
                        <IpList
                            server={server}
                            hideButton={setupState}
                            progress={loading}
                        />
                    </div>
                </div>
                <div className={cn(s.column, s.column_info)}>
                    <ServerInfo
                        server={server}
                        loading={loading}
                    />
                </div>
            </div>
            {!setupState && (
                <Comment server={server} loading={loading} />
            )}
        </div>
    );

    return (
        <div className={serverClass} data-server-id={id}>
            <Header
                server={server}
                setupState={setupState}
                operationProgress={operationProgress || installing}
                profile={profile}
                payer={payer}
                tenant={tenant}
            />
            {serverView === SERVER_VIEW.EXPAND && serverContent}
        </div>
    );
});

const selectServer = (store: Store, op: ServerOwnProps) => {
    return store.server.get(op.id) || store.installingServers.servers.get(op.id);
};
const selectProgress = (store: Store, op: ServerOwnProps) => store.operationProgress.commonOperations.get(op.id);
const selectProfile = (store: Store) => store.profile.info;
const selectProfilePayer = (store: Store) => store.profile.profilePayer;
const selectTenant = (store: Store, op: ServerOwnProps) => store.tenant.get(Number(op.tenantId));
const selectBatchServers = (store: Store) => store.batchOperationProgress.servers;

const selector = createSelector([
    selectServer,
    selectProgress,
    selectProfile,
    selectProfilePayer,
    selectTenant,
    selectBatchServers,
], (server, progress, profile, payer, tenant, batchServers) => ({
    server,
    profile,
    payer,
    operationProgress: progress,
    tenant,
    batchServers,
}));

const mapStateToProps = (store: Store, ownProps: ServerOwnProps) => {
    return { ...selector(store, ownProps) };
};
export default connect(mapStateToProps)(Server);
