// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerPaymentCreate {
    amount: number;
}

export default class ResellerPaymentCreate {
    readonly _amount: number;

    /**
     * Description: Payment amount, RUB
     * Example: 1000
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    constructor(props: IResellerPaymentCreate) {
        this._amount = props.amount;
    }

    serialize(): IResellerPaymentCreate {
        const data: IResellerPaymentCreate = {
            amount: this._amount,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            amount: typeof this._amount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerPaymentCreate): ResellerPaymentCreate {
        return new ResellerPaymentCreate(props);
    }

    readonly keys: { [key: string]: string } = {
        amount: 'amount',
        }
;

    mergeDeepWith(props: Partial<ResellerPaymentCreate>): ResellerPaymentCreate {
        const updateData: Partial<IResellerPaymentCreate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerPaymentCreate) => {
            const updateKey = this.keys[key] as keyof IResellerPaymentCreate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerPaymentCreate, keyof IResellerPaymentCreate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerPaymentCreate({ ...this.serialize(), ...updateData });
    }
}
