import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { resellerLogin } from 'Actions/user';
import { updateResellerClient } from 'Actions/reseller';
import { useIntl, Button, Icon } from 'Common';
import ResellerClient from 'Entities/ResellerClient';
import { TRANSITION_TIME } from 'Lib/helpers/consts';
import theme from 'Lib/theme';
import { Store } from 'Store';

import { AddFundsModal, AddDiscountModal, ClientDeleteModal, Comment } from '.';
import s from './Client.module.pcss';

interface ClientProps {
    client: ResellerClient;
}

const grayText = (text: string) => (
    <span
        className={cn(
            theme.color.grayHeaders,
            theme.text.medium,
        )}
    >
        {text}
    </span>
);

const selectAccount = (store: Store) => store.account.details;
const selectResellerDetails = (store: Store) => store.reseller.details;
const selector = createSelector(
    [selectAccount, selectResellerDetails],
    (details, resellerDetails) => ({ details, resellerDetails }),
);

const Client: FC<ClientProps> = ({ client }) => {
    const intl = useIntl();
    const [openModal, setOpenModal] = useState(false);
    const [addFundsModalOpen, setAddFundsModalOpen] = useState(false);
    const [clientDeleteModal, setClientDeleteModal] = useState(false);
    const dispatch = useDispatch();

    const profile = useSelector(selector);

    const {
        email,
        clientId,
        balance,
        marginPercent,
        emailNotConfirmed,
        accountBlocked,
        starred,
        chargeOfferAmounts,
        comment,
    } = client;

    const [favouriteClient, setFavouriteClient] = useState(starred);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    if (!profile.details || !profile.resellerDetails) {
        return null;
    }

    const { balance: accountBalance } = profile.details;
    const { balance: resellerBalance } = profile.resellerDetails;

    const addToFavourites = () => {
        const value = !favouriteClient;
        setFavouriteClient(value);
        setTooltipVisible(false);

        setTimeout(() => {
            dispatch(updateResellerClient([clientId, { starred: value }]));
        }, TRANSITION_TIME);
    };

    const updateComment = (value: string) => {
        dispatch(updateResellerClient([clientId, { comment: value }]));
    };

    const getDiscountBox = (value: number) => {
        const isDiscount = value < 0;

        return (
            <Button type="border" className={s.button} onClick={() => setOpenModal(true)}>
                <span className={s.buttonValue}>
                    {isDiscount
                        ? intl.getMessage('discount_button', { value: Math.abs(value) })
                        : intl.getMessage('clients_margin_button', { value: Math.abs(value) })}
                </span>
            </Button>
        );
    };

    const getStar = () => {
        const tooltipTitle = starred
            ? intl.getMessage('title_remove_from_favourites')
            : intl.getMessage('title_add_to_favourites');

        return (
            <div className={s.starWrap}>
                <Tooltip
                    placement="bottomLeft"
                    overlayClassName="tooltip tooltip_small"
                    title={tooltipTitle}
                    visible={tooltipVisible}
                    onVisibleChange={(v) => setTooltipVisible(v)}
                    arrowPointAtCenter
                >
                    <div>
                        <Icon
                            icon="star"
                            className={cn(s.star, { [s.star_active]: starred })}
                            onClick={addToFavourites}
                        />
                    </div>
                </Tooltip>
            </div>
        );
    };

    const onLogin = () => {
        dispatch(resellerLogin([clientId]));
    };

    if (emailNotConfirmed) {
        return (
            <div className={cn(theme.card.card, s.client)}>
                <div>
                    <div className={cn(s.email, theme.color.gray)}>
                        {email}
                    </div>
                    <div className={s.message}>
                        {intl.getMessage('client_email_not_confirmed')}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={cn(theme.card.card, s.client)}>
            <div className={cn(theme.card.grid, theme.card.grid_client)}>
                <div className={s.column}>
                    <div className={s.group}>
                        {getStar()}
                        <div className={s.info}>
                            <div className={s.email}>
                                {email}
                            </div>
                            <div className={s.id}>
                                #{clientId}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={s.column}>
                    <div className={s.group}>
                        <Button
                            type="border"
                            onClick={() => setAddFundsModalOpen(true)}
                            className={s.button}
                        >
                            {intl.getMessage('money', { value: Math.round(balance) })}
                        </Button>
                    </div>
                </div>
                <div className={s.column}>
                    <div className={s.group}>
                        {getDiscountBox(marginPercent)}
                    </div>
                </div>
                <div className={s.column}>
                    <div className={cn(s.group, s.group_spaceBetween)}>
                        <Comment comment={comment} updateComment={updateComment} />
                        <div className={s.action}>
                            {!accountBlocked && (
                                <Button
                                    type="link"
                                    onClick={onLogin}
                                    className={cn(theme.button.link_black, s.login)}
                                >
                                    {intl.getMessage('login_button')}
                                </Button>
                            )}
                            <Button
                                type="icon"
                                icon="delete"
                                className={cn(theme.card.action, s.deleteBtn)}
                                title={intl.getMessage('send_request_btn')}
                                onClick={() => setClientDeleteModal(true)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {openModal && (
                <AddDiscountModal
                    visible
                    handleClose={() => setOpenModal(false)}
                    clientId={clientId}
                    currentDiscount={marginPercent}
                    title={intl.getMessage('client_discount_rate_modal_title', { value: email, b: grayText })}
                />
            )}

            {addFundsModalOpen && (
                <AddFundsModal
                    visible
                    handleClose={() => setAddFundsModalOpen(false)}
                    title={intl.getMessage('billing_add_funds_with_email', { value: email, b: grayText })}
                    resellerAccountBalance={resellerBalance}
                    accountBalance={Math.floor(accountBalance)}
                    clientId={clientId}
                    clientChargeOfferAmounts={chargeOfferAmounts}
                />
            )}

            {clientDeleteModal && (
                <ClientDeleteModal
                    visible={clientDeleteModal}
                    handleClose={() => setClientDeleteModal(false)}
                    email={email}
                />
            )}
        </div>
    );
};

export default Client;
