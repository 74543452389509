import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { useIntl, DashboardHeader, Link, Button, notifyError } from 'Common';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { RoutePath } from 'Lib/helpers/routes';
import { NOTIFICATIONS_STATE } from 'Lib/helpers/consts';
import theme from 'Lib/theme';
import { Store } from 'Store';

import AddRecipientModal from './AddRecipientModal';

const selectProfile = (store: Store) => store.profile.info;
const selector = createSelector([selectProfile], (profile) => (profile));

interface HeaderProps {
    recipientsNum: number | undefined;
}
const Header: FC<HeaderProps> = ({ recipientsNum }) => {
    const intl = useIntl();
    const [showAddRecipientModal, setShowAddRecipientModal] = useState(false);

    const info = useSelector(selector);

    const onAddRecipient = () => {
        if (recipientsNum && recipientsNum === info?.profileLimits.maxRecipientsCount) {
            notifyError(apiErrorCodeTranslate(
                intl,
                ApiErrorCode.CLIENT_RECIPIENT_COUNT_EXCEED,
                { value: info?.profileLimits.maxRecipientsCount || '' },
            ));
        } else {
            setShowAddRecipientModal(true);
        }
    };

    return (
        <>
            <DashboardHeader
                navigation={(
                    <>
                        <Link
                            to={RoutePath.Notifications}
                            props={{ unread: NOTIFICATIONS_STATE.UNREAD }}
                            className={theme.header.link}
                        >
                            {intl.getMessage('notifications_unread')}
                        </Link>
                        <Link
                            to={RoutePath.Notifications}
                            props={{ unread: NOTIFICATIONS_STATE.HISTORY }}
                            className={theme.header.link}
                        >
                            {intl.getMessage('notifications_read')}
                        </Link>
                        <Link
                            to={RoutePath.Triggers}
                            className={cn(
                                theme.header.link,
                                theme.header.link_larger,
                            )}
                        >
                            {intl.getMessage('triggers_title')}
                        </Link>
                        <div
                            className={cn(
                                theme.header.link,
                                theme.header.link_larger,
                                theme.header.active,
                            )}
                        >
                            {intl.getMessage('notifications_recipients_settings')}
                        </div>
                    </>
                )}
                actions={(
                    <Button
                        type="primary"
                        size="medium"
                        onClick={onAddRecipient}
                    >
                        {intl.getMessage('notifications_add_recipient_title')}
                    </Button>
                )}
            />
            {showAddRecipientModal && (
                <AddRecipientModal
                    visible={showAddRecipientModal}
                    handleClose={() => setShowAddRecipientModal(false)}
                />
            )}
        </>
    );
};

export default Header;
