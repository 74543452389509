import React, { FC } from 'react';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';

import { useIntl, Select, Option, Icon } from 'Common';
import { updateProfile } from 'Actions/profile';
import Profile from 'Entities/Profile';
import { WebTheme } from 'Entities/WebTheme';
import { Store } from 'Store';

import s from './SystemSettings.module.pcss';

interface ThemeSelectorProps {
    profile: Profile | null;
}

const ThemeSelector: FC<ThemeSelectorProps> = ({ profile }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const onThemeChange = (value: WebTheme) => {
        dispatch(updateProfile([{ web_theme: value }]));
    };

    if (!profile) {
        return null;
    }

    return (
        <div className={s.select}>
            <div className={s.label}>
                {intl.getMessage('theme')}
            </div>
            <Select
                id="theme"
                size="medium"
                value={profile.webTheme}
                className="select_dm-cloud"
                onChange={onThemeChange}
                block
            >
                <Option value={WebTheme.AUTO} className="option option_small">
                    <Icon icon="history" className={s.icon} />
                    {intl.getMessage('theme_auto')}
                </Option>
                <Option value={WebTheme.LIGHT} className="option option_small">
                    <Icon icon="mode_light" className={s.icon} />
                    {intl.getMessage('theme_light')}
                </Option>
                <Option value={WebTheme.DARK} className="option option_small">
                    <Icon icon="mode_dark" className={s.icon} />
                    {intl.getMessage('theme_dark')}
                </Option>
            </Select>
        </div>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector(
    [selectProfile],
    (profile) => ({ profile }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(ThemeSelector);
