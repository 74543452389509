// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountCreditCardReorder {
    credit_card_ids_ordered: number[];
}

export default class AccountCreditCardReorder {
    readonly _credit_card_ids_ordered: number[];

    /**
     * Description: Credit cards ids ordered
     * Example: 123,234,345
     */
    get creditCardIdsOrdered(): number[] {
        return this._credit_card_ids_ordered;
    }

    static creditCardIdsOrderedValidate(creditCardIdsOrdered: number[]): boolean {
        return creditCardIdsOrdered.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    constructor(props: IAccountCreditCardReorder) {
        this._credit_card_ids_ordered = props.credit_card_ids_ordered;
    }

    serialize(): IAccountCreditCardReorder {
        const data: IAccountCreditCardReorder = {
            credit_card_ids_ordered: this._credit_card_ids_ordered,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            credit_card_ids_ordered: this._credit_card_ids_ordered.reduce((result, p) => result && typeof p === 'number', true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountCreditCardReorder): AccountCreditCardReorder {
        return new AccountCreditCardReorder(props);
    }

    readonly keys: { [key: string]: string } = {
        creditCardIdsOrdered: 'credit_card_ids_ordered',
        }
;

    mergeDeepWith(props: Partial<AccountCreditCardReorder>): AccountCreditCardReorder {
        const updateData: Partial<IAccountCreditCardReorder> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountCreditCardReorder) => {
            const updateKey = this.keys[key] as keyof IAccountCreditCardReorder;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountCreditCardReorder, keyof IAccountCreditCardReorder>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountCreditCardReorder({ ...this.serialize(), ...updateData });
    }
}
