import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import BackupDownloadLink, { IBackupDownloadLink } from 'Entities/BackupDownloadLink';
import OperationInfo, { IOperationInfo } from 'Entities/OperationInfo';
import ServerBackup, { IServerBackup } from 'Entities/ServerBackup';
import ServerBackupDownload, { IServerBackupDownload } from 'Entities/ServerBackupDownload';
import ServerBackupRollback, { IServerBackupRollback } from 'Entities/ServerBackupRollback';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class BackupsApi {
    static async downloadBackup(tenant_id: number, server_id: number, backup_id: string, serverbackupdownload: IServerBackupDownload, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverbackupdownloadValid = new ServerBackupDownload(serverbackupdownload);
        haveError.push(...serverbackupdownloadValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/backups/${backup_id}/download`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverbackupdownloadValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getBackupDownloadLink(tenant_id: number, server_id: number, backup_id: string, optionalToken?: string): Promise<IBackupDownloadLink | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/backups/${backup_id}/download_link`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listBackups(tenant_id: number, server_id: number, optionalToken?: string): Promise<IServerBackup[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/backups`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async rollbackBackup(tenant_id: number, server_id: number, backup_id: string, serverbackuprollback: IServerBackupRollback, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverbackuprollbackValid = new ServerBackupRollback(serverbackuprollback);
        haveError.push(...serverbackuprollbackValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/backups/${backup_id}/rollback`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverbackuprollbackValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
