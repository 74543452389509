// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPartnerPayment {
    amount: number;
    download_link?: string;
    paid: boolean;
    time_added: string;
    time_added_millis: number;
    transaction_id: number;
}

export default class PartnerPayment {
    readonly _amount: number;

    /**
     * Description: Payment amount, RUB
     * Example: 1200
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    readonly _download_link: string | undefined;

    /**
     * Description: Payment act PDF download link
     * Example: https://panel.serveroid.com/documents/nc348756947nfyf3g8gero/partner_act.pdf
     */
    get downloadLink(): string | undefined {
        return this._download_link;
    }

    readonly _paid: boolean;

    /**
     * Description: Has this payment  been completed or not
     * Example: true
     */
    get paid(): boolean {
        return this._paid;
    }

    static paidValidate(paid: boolean): boolean {
        return typeof paid === 'boolean';
    }

    readonly _time_added: string;

    /** */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Payment creation date, UTC millis
     * Example: 1593064584178
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    readonly _transaction_id: number;

    /**
     * Description: Payment transaction ID
     * Example: 146
     */
    get transactionId(): number {
        return this._transaction_id;
    }

    static transactionIdValidate(transactionId: number): boolean {
        return typeof transactionId === 'number';
    }

    constructor(props: IPartnerPayment) {
        this._amount = props.amount;
        if (typeof props.download_link === 'string') {
            this._download_link = props.download_link.trim();
        }
        this._paid = props.paid;
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
        this._transaction_id = props.transaction_id;
    }

    serialize(): IPartnerPayment {
        const data: IPartnerPayment = {
            amount: this._amount,
            paid: this._paid,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
            transaction_id: this._transaction_id,
        };
        if (typeof this._download_link !== 'undefined') {
            data.download_link = this._download_link;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            transaction_id: typeof this._transaction_id === 'number',
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
            amount: typeof this._amount === 'number',
            download_link: !this._download_link ? true : typeof this._download_link === 'string',
            paid: typeof this._paid === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPartnerPayment): PartnerPayment {
        return new PartnerPayment(props);
    }

    readonly keys: { [key: string]: string } = {
        amount: 'amount',
        downloadLink: 'download_link',
        paid: 'paid',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        transactionId: 'transaction_id',
        }
;

    mergeDeepWith(props: Partial<PartnerPayment>): PartnerPayment {
        const updateData: Partial<IPartnerPayment> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PartnerPayment) => {
            const updateKey = this.keys[key] as keyof IPartnerPayment;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPartnerPayment, keyof IPartnerPayment>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PartnerPayment({ ...this.serialize(), ...updateData });
    }
}
