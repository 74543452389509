// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISnapshotCreate {
    description?: string;
    name: string;
}

export default class SnapshotCreate {
    readonly _description: string | undefined;

    /**
     * Description: Description
     * Example: Default description
     */
    get description(): string | undefined {
        return this._description;
    }

    static get descriptionMinLength() {
        return 0;
    }

    static get descriptionMaxLength() {
        return 256;
    }

    static descriptionValidate(description: string | undefined): boolean {
        return (!description ? true : description.length > 0) && (!description ? true : description.length <= 256);
    }

    readonly _name: string;

    /**
     * Description: Name
     * Example: Before update
     */
    get name(): string {
        return this._name;
    }

    static get nameMinLength() {
        return 0;
    }

    static get nameMaxLength() {
        return 70;
    }

    static nameValidate(name: string): boolean {
        return (name.length > 0) && (name.length <= 70);
    }

    constructor(props: ISnapshotCreate) {
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        this._name = props.name.trim();
    }

    serialize(): ISnapshotCreate {
        const data: ISnapshotCreate = {
            name: this._name,
        };
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: (this._name.length > 0) && (this._name.length <= 70),
            description: (!this._description ? true : this._description.length > 0) && (!this._description ? true : this._description.length <= 256),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISnapshotCreate): SnapshotCreate {
        return new SnapshotCreate(props);
    }

    readonly keys: { [key: string]: string } = {
        description: 'description',
        name: 'name',
        }
;

    mergeDeepWith(props: Partial<SnapshotCreate>): SnapshotCreate {
        const updateData: Partial<ISnapshotCreate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SnapshotCreate) => {
            const updateKey = this.keys[key] as keyof ISnapshotCreate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISnapshotCreate, keyof ISnapshotCreate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SnapshotCreate({ ...this.serialize(), ...updateData });
    }
}
