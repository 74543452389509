import { SecurityActionType } from './SecurityActionType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServersBatchPublicKeysRemove {
    actionType?: SecurityActionType;
    public_keys: number[];
    security_code?: string;
    server_ids: number[];
}

export default class ServersBatchPublicKeysRemove {
    readonly _actionType: SecurityActionType | undefined;

    get actionType(): SecurityActionType | undefined {
        return this._actionType;
    }

    readonly _public_keys: number[];

    /**
     * Description: Public keys to add
     * Example: 683
     */
    get publicKeys(): number[] {
        return this._public_keys;
    }

    static publicKeysValidate(publicKeys: number[]): boolean {
        return publicKeys.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    readonly _security_code: string | undefined;

    /** */
    get securityCode(): string | undefined {
        return this._security_code;
    }

    readonly _server_ids: number[];

    /** */
    get serverIds(): number[] {
        return this._server_ids;
    }

    static serverIdsValidate(serverIds: number[]): boolean {
        return serverIds.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    constructor(props: IServersBatchPublicKeysRemove) {
        if (props.actionType) {
            this._actionType = props.actionType;
        }
        this._public_keys = props.public_keys;
        if (typeof props.security_code === 'string') {
            this._security_code = props.security_code.trim();
        }
        this._server_ids = props.server_ids;
    }

    serialize(): IServersBatchPublicKeysRemove {
        const data: IServersBatchPublicKeysRemove = {
            public_keys: this._public_keys,
            server_ids: this._server_ids,
        };
        if (typeof this._actionType !== 'undefined') {
            data.actionType = this._actionType;
        }
        if (typeof this._security_code !== 'undefined') {
            data.security_code = this._security_code;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            security_code: !this._security_code ? true : typeof this._security_code === 'string',
            server_ids: this._server_ids.reduce((result, p) => result && typeof p === 'number', true),
            public_keys: this._public_keys.reduce((result, p) => result && typeof p === 'number', true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServersBatchPublicKeysRemove): ServersBatchPublicKeysRemove {
        return new ServersBatchPublicKeysRemove(props);
    }

    readonly keys: { [key: string]: string } = {
        actionType: 'actionType',
        publicKeys: 'public_keys',
        securityCode: 'security_code',
        serverIds: 'server_ids',
        }
;

    mergeDeepWith(props: Partial<ServersBatchPublicKeysRemove>): ServersBatchPublicKeysRemove {
        const updateData: Partial<IServersBatchPublicKeysRemove> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServersBatchPublicKeysRemove) => {
            const updateKey = this.keys[key] as keyof IServersBatchPublicKeysRemove;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServersBatchPublicKeysRemove, keyof IServersBatchPublicKeysRemove>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServersBatchPublicKeysRemove({ ...this.serialize(), ...updateData });
    }
}
