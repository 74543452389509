import React, { FC, useState, ChangeEvent, useRef } from 'react';
import cn from 'classnames';
import { Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { useIntl, Icon, Switch } from 'Common';
import theme from 'Lib/theme';
import { Store } from 'Store';
import { updateTariffRecommendations, updateClientApiKey, updateProfile } from 'Actions/profile';
import { logOut, resellerLogout } from 'Actions/user';
import ApiKeyEnt from 'Entities/ApiKey';
import { useClickOutside } from 'Hooks';

import { AccountSelector, ThemeSelector } from '.';
import s from './Profile.module.pcss';

const selectProfile = (store: Store) => store.profile.info;
const selector = createSelector(
    [selectProfile],
    (info) => ({ info }),
);

const TIME_IN_MS = 2000;

const Settings: FC = () => {
    const intl = useIntl();
    const profile = useSelector(selector);
    const dispatch = useDispatch();
    const showResellerLogout = MAIN_TOKEN;

    const [apiKey, setApiKey] = useState<ApiKeyEnt | null>();
    const [isThemeSelectOpened, setThemeSelectOpened] = useState(false);
    const [isAccountSelectOpened, setAccountSelectOpened] = useState(false);

    const { info } = profile;

    const themeSelectRef = useRef<HTMLDivElement>(null);
    const accountSelectRef = useRef<HTMLDivElement>(null);

    useClickOutside([themeSelectRef], () => setThemeSelectOpened(false));
    useClickOutside([accountSelectRef], () => setAccountSelectOpened(false));

    const toggleTariffRecommendations = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateTariffRecommendations([e.target.checked]));
    };

    const toggleNewsletter = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateProfile([{ newsletter_enabled: e.target.checked }]));
    };

    const generateApiKey = () => {
        dispatch(updateClientApiKey([], {
            result: (res) => {
                const key = new ApiKeyEnt(res);
                setApiKey(key);

                navigator.clipboard.writeText(key.apiKey);
            },
        }));
    };

    const generateAndCopyToClipboard = () => {
        generateApiKey();

        setTimeout(() => {
            setApiKey(null);
        }, TIME_IN_MS);
    };

    const toggleClientStudioSection = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateProfile([{ reseller_section_hidden: !e.target.checked }]));
    };

    if (!info) {
        return null;
    }

    return (
        <div className={s.settings}>
            <div className={s.title}>
                {intl.getMessage('profile_settings_title')}
            </div>

            <div className={s.column}>
                <div className={s.item}>
                    <div className={cn(s.itemInnerwrapper)}>
                        <Switch
                            id="newsletter"
                            checked={info.newsletterEnabled}
                            handleChange={toggleNewsletter}
                            fixedWidth
                            small
                            className={s.switch}
                        >
                            {intl.getMessage('profile_newsletter_title')}
                        </Switch>
                    </div>
                    <Divider className={cn(theme.divider.divider, s.divider)} />
                </div>
                <div className={s.item}>
                    <div className={s.itemInnerwrapper}>
                        <Switch
                            id="tariff_recommendations"
                            checked={info.serverUpgradeRecommendationsEnabled}
                            handleChange={toggleTariffRecommendations}
                            fixedWidth
                            small
                            className={s.switch}
                        >
                            {intl.getMessage('profile_tariff_recommendations_title')}
                        </Switch>
                    </div>
                    <Divider className={cn(theme.divider.divider, s.divider)} />
                </div>
                {!info.reseller && (
                    <div className={s.item}>
                        <div className={s.itemInnerwrapper}>
                            <Switch
                                id="show_studio_section"
                                checked={!info.resellerSectionHidden}
                                handleChange={toggleClientStudioSection}
                                fixedWidth
                                small
                                className={s.switch}
                            >
                                {intl.getMessage('profile_settings_show_studio_section')}
                            </Switch>
                        </div>
                        <Divider className={cn(theme.divider.divider, s.divider)} />
                    </div>
                )}
                {info.profileLimits.canEditApiKey && (
                    <div className={s.item}>
                        <div
                            className={cn(s.itemInnerwrapper, s.itemInnerwrapper_settings)}
                            onClick={generateAndCopyToClipboard}
                        >
                            <div className={s.label}>
                                {intl.getMessage('api_key')}
                            </div>
                            <div className={cn(s.value, s.value_right, { [s.value_generated]: apiKey })}>
                                {!apiKey ? (
                                    intl.getMessage('generate')
                                ) : (
                                    intl.getMessage('generated_and_copied')
                                )}
                            </div>
                            <div className={cn(s.action, { [s.value_generated]: apiKey })}>
                                <Icon icon="side" className={cn(s.icon, s.icon_settings)} />
                            </div>
                        </div>
                        <Divider className={cn(theme.divider.divider, s.divider)} />
                    </div>
                )}
                <div className={s.item}>
                    <div
                        className={cn(
                            s.itemInnerwrapper,
                            s.itemInnerwrapper_settings,
                            s.itemInnerwrapper_noPadding,
                        )}
                        onClick={() => setThemeSelectOpened(!isThemeSelectOpened)}
                        ref={themeSelectRef}
                    >
                        <div className={s.label}>
                            {intl.getMessage('theme')}
                        </div>
                        <div className={cn(s.value, s.value_right, s.value_noMargin)}>
                            <ThemeSelector
                                isOpened={isThemeSelectOpened}
                                triggerNode={themeSelectRef.current}
                            />
                        </div>
                        <div className={s.action}>
                            <Icon icon="side" className={cn(s.icon, s.icon_settings)} />
                        </div>
                    </div>
                    <Divider className={cn(theme.divider.divider, s.divider)} />
                </div>
                <div className={s.item}>
                    <div
                        className={cn(
                            s.itemInnerwrapper,
                            s.itemInnerwrapper_settings,
                            s.itemInnerwrapper_noPadding,
                        )}
                        ref={accountSelectRef}
                        onClick={() => setAccountSelectOpened(!isAccountSelectOpened)}
                    >
                        <div className={s.label}>
                            {intl.getMessage('change_account_title')}
                        </div>
                        <div className={cn(
                            s.value,
                            s.value_right,
                            s.value_noMargin,
                        )}
                        >
                            <AccountSelector
                                isOpened={isAccountSelectOpened}
                                triggerNode={accountSelectRef.current}
                            />
                        </div>
                        <div className={s.action}>
                            <Icon icon="side" className={cn(s.icon, s.icon_settings)} />
                        </div>
                    </div>
                    <Divider className={cn(theme.divider.divider, s.divider)} />
                </div>
                {showResellerLogout && (
                    <div className={s.item}>
                        <div
                            className={cn(s.itemInnerwrapper, s.itemInnerwrapper_settings)}
                            onClick={() => dispatch(resellerLogout())}
                        >
                            <div className={cn(s.label, s.label_quit)}>
                                {intl.getMessage('exit_client')}
                            </div>
                            <div className={cn(s.value, s.value_right)}>
                                {intl.getMessage('exit_client_desc')}
                            </div>
                            <div className={s.action}>
                                <Icon icon="side" className={cn(s.icon, s.icon_settings)} />
                            </div>
                        </div>
                        <Divider className={cn(theme.divider.divider, s.divider)} />
                    </div>
                )}
                <div className={s.item}>
                    <div
                        className={cn(s.itemInnerwrapper, s.itemInnerwrapper_settings)}
                        onClick={() => dispatch(logOut())}
                    >
                        <div className={cn(s.label, s.label_quit)}>
                            {intl.getMessage('exit')}
                        </div>
                        <div className={cn(s.value, s.value_right)}>
                            {intl.getMessage('exit_all_accounts')}
                        </div>
                        <div className={s.action}>
                            <Icon icon="side" className={cn(s.icon, s.icon_settings)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
