import { UserActions } from './user';
import { ServerActions } from './server';
import { TenantActions } from './tenant';
import { TenantUsersActions } from './tenantUsers';
import { OperationProgressActions } from './operationProgress';
import { LocaleActions } from './locale';
import { DnsActions } from './dns';
import { ReverseDnsActions } from './reverseDns';
import { TariffActions } from './tariff';
import { DistributionActions } from './distribution';
import { InstallingServersActions } from './installingServers';
import { SnapshotsActions } from './snapshots';
import { ServerBackupActions } from './serverBackup';
import { AccountActions } from './account';
import { PublicKeysActions } from './publicKeys';
import { ServerStateEventsActions } from './serverStateEvents';
import { ErrorActions } from './error';
import { SecurityActions } from './security';
import { ProfileActions } from './profile';
import { NotificationsActions } from './notifications';
import { SupportActions } from './support';
import { UiActions } from './ui';
import { PartnerActions } from './partner';
import { ResellerActions } from './reseller';
import { MainAccountActions } from './mainAccount';
import { NewServerActions } from './newServer';
import { BatchOperationProgressActions } from './batchOperationProgress';

export enum InplaceType {
    GetResourceLimits = 'GetResourceLimits',
    GetAddCreditCard = 'GetAddCreditCard',
    ConfirmEmail = 'ConfirmEmail',
    CheckPanelVersion = 'CheckPanelVersion',
    PreloadServersAndTenants = 'PreloadServersAndTenants',
}
export type ActionType =
    UserActions |
    ServerActions |
    TenantActions |
    TenantUsersActions |
    OperationProgressActions |
    LocaleActions |
    DnsActions |
    ReverseDnsActions |
    TariffActions |
    DistributionActions |
    InstallingServersActions |
    SnapshotsActions |
    ServerBackupActions |
    AccountActions |
    PublicKeysActions |
    ServerStateEventsActions |
    ErrorActions |
    InplaceType |
    SecurityActions |
    ProfileActions |
    NotificationsActions |
    SupportActions |
    PartnerActions |
    ResellerActions |
    MainAccountActions |
    NewServerActions |
    BatchOperationProgressActions |
    UiActions;

export interface Action<T = any> {
    type: ActionType;
    payload: T;
    callback?: {
        result?: (r?: any) => any;
        error?: (e?: any) => void;
    };
}
function actionCreator<T>(type: ActionType) {
    return function action(payload?: T, callback?: {
        result?: (r: any) => void;
        error?: (e: any) => void;
    }): Action<T> {
        return {
            type,
            payload: payload as T,
            callback,
        };
    };
}
export { actionCreator };
