import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, Link, DashboardHeader, Button, notifyError } from 'Common';
import Tenant from 'Entities/Tenant';
import TenantUser from 'Entities/TenantUser';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import ModalInvite from '../ModalInvite';

interface HeaderProps {
    showActionButtons: boolean;
    currentUser: TenantUser;
    tenant: Tenant;
    usersNum: number;
}
const Header: FC<HeaderProps> = ({ showActionButtons, tenant, usersNum }) => {
    const intl = useIntl();
    const [openModal, setOpenModal] = useState(false);

    const { limits: { maxUsersInTenant } } = tenant;
    const onTenantInvite = () => {
        if (usersNum === maxUsersInTenant) {
            notifyError(intl.getMessage('project_access_max_tenant_users', { value: maxUsersInTenant }));
        } else {
            setOpenModal(true);
        }
    };

    return (
        <>
            <DashboardHeader
                navigation={(
                    <>
                        <Link to={RoutePath.ProjectsList} className={theme.header.back}>
                            <Button
                                type="icon"
                                size="medium"
                                icon="home"
                            />
                        </Link>
                        <div className={cn(theme.header.title, theme.header.title_tenant)}>
                            {tenant.description}
                        </div>
                    </>
                )}
                actions={(
                    <>
                        {showActionButtons && (
                            <Button
                                type="primary"
                                size="medium"
                                onClick={onTenantInvite}
                            >
                                {intl.getMessage('invite')}
                            </Button>
                        )}
                    </>
                )}
            />

            {openModal && (
                <ModalInvite
                    visible={openModal}
                    tenantId={tenant.id}
                    close={() => setOpenModal(false)}
                />
            )}
        </>
    );
};

export default Header;
