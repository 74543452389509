import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { useIntl, Icon, CustomSelect, IconType } from 'Common';
import { Store } from 'Store';
import { updateProfile } from 'Actions/profile';
import { WebTheme } from 'Entities/WebTheme';
import { Translator } from 'Lib/intl';

import s from './Profile.module.pcss';

const selectProfile = (store: Store) => store.profile.info;
const selector = createSelector(
    [selectProfile],
    (info) => ({ info }),
);

interface ThemeSelectorProps {
    isOpened: boolean;
    triggerNode: HTMLDivElement | null;
}

const getDefaultValue = (intl: Translator, value: WebTheme) => {
    const message: Record<WebTheme, string> = {
        [WebTheme.AUTO]: intl.getMessage('theme_auto'),
        [WebTheme.LIGHT]: intl.getMessage('theme_light'),
        [WebTheme.DARK]: intl.getMessage('theme_dark'),
    };
    return message[value];
};

const ThemeSelector: FC<ThemeSelectorProps> = ({ isOpened, triggerNode }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const profile = useSelector(selector);

    const { info } = profile;

    if (!info) {
        return null;
    }

    const getThemeIcon = (theme: WebTheme) => {
        const icon: Record<WebTheme, IconType> = {
            [WebTheme.AUTO]: 'history',
            [WebTheme.LIGHT]: 'mode_light',
            [WebTheme.DARK]: 'mode_dark',
        };
        return icon[theme];
    };

    const options = (Object.keys(WebTheme) as WebTheme[]).map((theme) => (
        {
            value: theme,
            label: (
                <div className={s.optionInnerWrapper}>
                    <Icon icon={getThemeIcon(theme)} />
                    <span className={s.text}>
                        {getDefaultValue(intl, theme)}
                    </span>
                </div>
            ),
            labelText: getDefaultValue(intl, theme),
            disabled: false,
            onSelect: () => dispatch(updateProfile([{ web_theme: theme }])),
        }
    ));

    return (
        <CustomSelect<WebTheme>
            trigger="click"
            placement="bottomRight"
            renderInParentContainer
            width={249}
            isOpened={isOpened}
            triggerNode={triggerNode}
            value={info.webTheme}
            options={options}
        />
    );
};

export default ThemeSelector;
