import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { createSelector } from 'reselect';

import { useIntl, Link, Icon } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { getGb } from 'Lib/helpers/utils';
import { updateServerRecommendations } from 'Actions/server';
import { getTariffsForServer } from 'Actions/tariff';
import Tariff from 'Entities/Tariff';
import ServerUpgradeRecommendation from 'Entities/ServerUpgradeRecommendation';
import { ResourceUsageType } from 'Entities/ResourceUsageType';
import { getResourcesLabel } from 'Lib/helpers/translationHelper';
import { Store } from 'Store';
import theme from 'Lib/theme';

import s from './RecommendationsChooseContent.module.pcss';
import { ContentType, DiffTariffValues } from './RecommendationsChooseContent';

interface RecommendationsDefaultContentProps {
    tenantId: number;
    serverId: number;
    serverListPage: boolean;
    upgradeRecommendation: ServerUpgradeRecommendation;
    currentTariff: Tariff;
    setContentType: (type: ContentType) => void;
    applyNewTariff: () => void;
}

const selectTariffs = (store: Store) => store.tariff;
const selector = createSelector([selectTariffs], (tariff) => tariff);

const RecommendationsDefaultContent: FC<RecommendationsDefaultContentProps> = ({
    tenantId,
    serverId,
    serverListPage,
    setContentType,
    upgradeRecommendation,
    currentTariff,
    applyNewTariff,
}) => {
    const intl = useIntl();
    const [recommendedTariffDiff, setRecommendedTariffDiff] = useState<DiffTariffValues>();
    const dispatch = useDispatch();

    const tariffList = useSelector(selector);

    const memoryValueFrom = upgradeRecommendation.memoryMib && getGb(upgradeRecommendation.memoryMib.fromValue);
    const memoryValueTo = upgradeRecommendation.memoryMib && getGb(upgradeRecommendation.memoryMib.toValue);

    const compareCurrentAndRecommendedTariffs = (current: Tariff, recommended: Tariff) => {
        const { cpuCores, diskGib, memoryMib, prices: { fixedPriceMonth } } = current;
        const diffValues: DiffTariffValues = {};

        if (recommended.cpuCores > cpuCores) {
            diffValues.cpuCores = recommended.cpuCores;
        }

        if (recommended.diskGib > diskGib) {
            diffValues.diskGib = recommended.diskGib;
        }

        if (recommended.memoryMib > memoryMib) {
            diffValues.memoryMib = recommended.memoryMib;
        }

        if (recommended.prices.fixedPriceMonth > fixedPriceMonth) {
            diffValues.fixedPriceMonth = recommended.prices.fixedPriceMonth;
        }

        return diffValues;
    };

    const turnOffServerRecommendations = (idTenant: number, idServer: number, refresh = false) => {
        dispatch(updateServerRecommendations([idTenant, idServer, refresh]));
    };

    useEffect(() => {
        dispatch(getTariffsForServer([tenantId, serverId]));
    }, [upgradeRecommendation.tariffId]);

    useEffect(() => {
        const getRecommendedTariffDiff = (tariff: Tariff | undefined) => {
            if (tariff) {
                const diffTariffValues = compareCurrentAndRecommendedTariffs(currentTariff, tariff);
                setRecommendedTariffDiff(diffTariffValues);
            }
        };

        if (tariffList.size > 0 && upgradeRecommendation.tariffId) {
            const recommendedTariff = tariffList.has(upgradeRecommendation.tariffId.toValue)
                ? tariffList.get(upgradeRecommendation.tariffId.toValue)
                : undefined;

            getRecommendedTariffDiff(recommendedTariff);
        }
    }, [tariffList]);

    const getLabels = (resources: ResourceUsageType[]) => (
        resources.map((r) => `“${getResourcesLabel(intl, r)}“`).join(', ')
    );

    return (
        <div className={cn(theme.popover.popover, s.popover, { [s.fullWidth]: !serverListPage })}>
            <div className={s.popoverIconWrapper}>
                <Icon icon="popover" className={s.popoverIcon} />
            </div>
            <div className={s.desc}>
                {intl.getPlural('tariff_recommendations_desc', upgradeRecommendation.exceededLimitResources.length,
                    {
                        resources: getLabels(upgradeRecommendation.exceededLimitResources),
                        b: (text: string) => (
                            <span className={theme.color.dmWhite}>
                                {text}
                            </span>
                        ),
                    })}
            </div>
            {upgradeRecommendation.tariffId && currentTariff && recommendedTariffDiff
                ? (
                    <button
                        id="tariff_rec"
                        type="button"
                        className={s.button}
                        onClick={applyNewTariff}
                    >
                        <div className={s.buttonInnerWrapper}>
                            <span className={s.title}>
                                {intl.getMessage('apply_new_tariff')}
                            </span>
                            <div className={s.buttonDesc}>
                                {recommendedTariffDiff.cpuCores && (
                                    <div className={s.buttonDescLine}>
                                        <span className={s.message}>
                                            {intl.getPlural('tariff_core_amount', currentTariff.cpuCores)}
                                        </span>
                                        <Icon
                                            icon="arrow_long"
                                            className={s.arrowIcon}
                                        />
                                        <span className={s.value}>
                                            {intl.getPlural('core', recommendedTariffDiff.cpuCores)}
                                        </span>
                                    </div>
                                )}
                                {recommendedTariffDiff.memoryMib && (
                                    <div className={s.buttonDescLine}>
                                        <span className={s.message}>
                                            {intl.getMessage('tariff_memory_amount', { value: currentTariff.memoryMib })}
                                        </span>
                                        <Icon
                                            icon="arrow_long"
                                            className={s.arrowIcon}
                                        />
                                        <span className={s.value}>
                                            {intl.getMessage('value_gb', { value: recommendedTariffDiff.memoryMib })}
                                        </span>
                                    </div>
                                )}
                                {recommendedTariffDiff.diskGib && (
                                    <div className={s.buttonDescLine}>
                                        <span className={s.message}>
                                            {intl.getMessage('tariff_disk_space_amount', { value: currentTariff.diskGib })}
                                        </span>
                                        <Icon
                                            icon="arrow_long"
                                            className={s.arrowIcon}
                                        />
                                        <span className={s.value}>
                                            {intl.getMessage('value_gb', { value: recommendedTariffDiff.diskGib })}
                                        </span>
                                    </div>
                                )}
                                {recommendedTariffDiff.fixedPriceMonth && (
                                    <div className={s.buttonDescLine}>
                                        <span className={s.message}>
                                            {intl.getMessage('tariff_monthly_price', { value: Math.round(currentTariff.prices.fixedPriceMonth) })}
                                        </span>
                                        <Icon
                                            icon="arrow_long"
                                            className={s.arrowIcon}
                                        />
                                        <span className={s.value}>
                                            {intl.getMessage('cost', { value: Math.round(recommendedTariffDiff.fixedPriceMonth) })}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </button>
                )
                : (
                    <button
                        id="tariff_rec"
                        type="button"
                        className={s.button}
                        onClick={applyNewTariff}
                    >
                        <div className={s.buttonInnerWrapper}>
                            <span className={s.title}>
                                {intl.getMessage('apply_new_tariff')}
                            </span>
                            <div className={s.buttonDesc}>
                                {upgradeRecommendation.cpuCores && (
                                    <div className={s.buttonDescLine}>
                                        <span className={s.message}>
                                            {intl.getPlural('tariff_core_amount', upgradeRecommendation.cpuCores.fromValue)}
                                        </span>
                                        <Icon
                                            icon="arrow_long"
                                            className={s.arrowIcon}
                                        />
                                        <span className={s.value}>
                                            {intl.getPlural('core', upgradeRecommendation.cpuCores.toValue)}
                                        </span>
                                    </div>
                                )}
                                {upgradeRecommendation.memoryMib && memoryValueFrom && memoryValueTo && (
                                    <div className={s.buttonDescLine}>
                                        <span className={s.message}>
                                            {intl.getMessage('tariff_memory_amount', { value: memoryValueFrom })}
                                        </span>
                                        <Icon
                                            icon="arrow_long"
                                            className={s.arrowIcon}
                                        />
                                        <span className={s.value}>
                                            {intl.getMessage('value_gb', { value: memoryValueTo })}
                                        </span>
                                    </div>
                                )}
                                {upgradeRecommendation.diskGib && (
                                    <div className={s.buttonDescLine}>
                                        <span className={s.message}>
                                            {intl.getMessage('tariff_disk_space_amount', { value: upgradeRecommendation.diskGib.fromValue })}
                                        </span>
                                        <Icon
                                            icon="arrow_long"
                                            className={s.arrowIcon}
                                        />
                                        <span className={s.value}>
                                            {intl.getMessage('value_gb', { value: upgradeRecommendation.diskGib.toValue })}
                                        </span>
                                    </div>
                                )}
                                {upgradeRecommendation.monthlyPrice && (
                                    <div className={s.buttonDescLine}>
                                        <span className={s.message}>
                                            {intl.getMessage('tariff_monthly_price', { value: Math.round(upgradeRecommendation.monthlyPrice.fromValue) })}
                                        </span>
                                        <Icon
                                            icon="arrow_long"
                                            className={s.arrowIcon}
                                        />
                                        <span className={s.value}>
                                            {intl.getMessage('cost', { value: Math.round(upgradeRecommendation.monthlyPrice.toValue) })}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </button>
                )}
            {serverListPage
                ? (
                    <Link
                        to={RoutePath.ServerTariff}
                        props={{ serverId }}
                    >
                        <button
                            type="button"
                            className={s.button}
                        >
                            {intl.getMessage('details_button')}
                        </button>
                    </Link>
                ) : (
                    <Link
                        to={RoutePath.Server}
                        props={{ tenantId, serverId }}
                    >
                        <button
                            type="button"
                            className={s.button}
                        >
                            {intl.getMessage('resources_stats_button')}
                        </button>
                    </Link>
                )}
            <button
                type="button"
                className={cn(s.button, s.button_red)}
                onClick={() => {
                    turnOffServerRecommendations(tenantId, serverId);
                    setContentType(ContentType.RECOMMENDATIONS_CANCELLED);
                }}
            >
                {intl.getMessage('cancel_recommendations_button')}
            </button>
        </div>
    );
};

export default RecommendationsDefaultContent;
