import PartnerPayment, { IPartnerPayment } from './PartnerPayment';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPartnerFinances {
    complete_payments: IPartnerPayment[];
}

export default class PartnerFinances {
    readonly _complete_payments: PartnerPayment[];

    /** */
    get completePayments(): PartnerPayment[] {
        return this._complete_payments;
    }

    constructor(props: IPartnerFinances) {
        this._complete_payments = props.complete_payments.map((p) => new PartnerPayment(p));
    }

    serialize(): IPartnerFinances {
        const data: IPartnerFinances = {
            complete_payments: this._complete_payments.map((p) => p.serialize()),
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            complete_payments: this._complete_payments.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPartnerFinances): PartnerFinances {
        return new PartnerFinances(props);
    }

    readonly keys: { [key: string]: string } = {
        completePayments: 'complete_payments',
        }
;

    mergeDeepWith(props: Partial<PartnerFinances>): PartnerFinances {
        const updateData: Partial<IPartnerFinances> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PartnerFinances) => {
            const updateKey = this.keys[key] as keyof IPartnerFinances;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPartnerFinances, keyof IPartnerFinances>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PartnerFinances({ ...this.serialize(), ...updateData });
    }
}
