import { takeEvery, put, call } from 'redux-saga/effects';

import SecurityActionsApi from 'Apis/securityActions';
import {
    SecurityActions,
    saveAllSecuritySettings,
    getAllSecuritySettings as getAllSecuritySettingsAct,
    updateSecuritySettings as updateSecuritySettingsAct,
    sendSecurityCode as sendSecurityCodeAct,
    sendBatchSecurityCode as sendBatchSecurityCodeAct,
    saveDeliveryType,
} from 'Actions/security';
import { ISecurityCodeSendResponse } from 'Entities/SecurityCodeSendResponse';
import { NotificationDeliveryType } from 'Entities/NotificationDeliveryType';
import SecurityActionSettings, { ISecurityActionSettings } from 'Entities/SecurityActionSettings';

import { errorChecker, addError } from './utils';

function* getAllSecuritySettings(action: ReturnType<typeof getAllSecuritySettingsAct>) {
    const settings: ISecurityActionSettings = yield call(SecurityActionsApi.listSettings);
    const { error, result } = errorChecker<ISecurityActionSettings[]>(settings);
    if (result) {
        yield put(saveAllSecuritySettings(new SecurityActionSettings(settings)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateSecuritySettings(action: ReturnType<typeof updateSecuritySettingsAct>) {
    const { payload, callback } = action;
    const [req] = payload;
    if (req.security_code) {
        const data: number = yield call(SecurityActionsApi.saveSettings, ...payload);
        const { result: r, error } = errorChecker<number>(data);
        if (r && callback?.result) {
            yield put(getAllSecuritySettingsAct());
            callback.result();
        } else if (error && callback?.error) {
            callback.error();
        }
        return;
    }
    const response: ISecurityCodeSendResponse = yield call(
        SecurityActionsApi.sendSecurityCodeToUpdateSettings, ...payload,
    );
    const { error, result } = errorChecker<ISecurityCodeSendResponse>(response);

    if (result) {
        if (result.delivery_type === NotificationDeliveryType.NONE) {
            const data: number = yield call(SecurityActionsApi.saveSettings, ...payload);
            const { result: r, error: e } = errorChecker<number>(data);
            if (r && callback?.result) {
                yield put(getAllSecuritySettingsAct());
                callback.result();
            } else if (e) {
                yield addError(e, action.type);
            }
        } else if (callback?.error) {
            callback.error(result.delivery_type);
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* sendSecurityCode(action: ReturnType<typeof sendSecurityCodeAct>) {
    const { payload } = action;
    const response: ISecurityCodeSendResponse = yield call(
        SecurityActionsApi.sendSecurityCode, ...payload,
    );
    const { error, result } = errorChecker<ISecurityCodeSendResponse>(response);
    if (result) {
        yield put(saveDeliveryType(result.delivery_type));
        yield put(getAllSecuritySettingsAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* sendBatchSecurityCode(action: ReturnType<typeof sendBatchSecurityCodeAct>) {
    const { payload } = action;
    const response: ISecurityCodeSendResponse = yield call(
        SecurityActionsApi.sendBatchSecurityCode, ...payload,
    );
    const { error, result } = errorChecker<ISecurityCodeSendResponse>(response);
    if (result) {
        yield put(saveDeliveryType(result.delivery_type));
        yield put(getAllSecuritySettingsAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* securitySaga(): Generator {
    yield takeEvery<ReturnType<typeof getAllSecuritySettingsAct>>(
        SecurityActions.GetAllSecuritySettings, getAllSecuritySettings,
    );
    yield takeEvery<ReturnType<typeof updateSecuritySettingsAct>>(
        SecurityActions.UpdateSecuritySettings, updateSecuritySettings,
    );
    yield takeEvery<ReturnType<typeof sendSecurityCodeAct>>(
        SecurityActions.SendSecurityCode, sendSecurityCode,
    );
    yield takeEvery<ReturnType<typeof sendBatchSecurityCodeAct>>(
        SecurityActions.SendBatchSecurityCode, sendBatchSecurityCode,
    );
}

export default securitySaga;
