// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICreateCorporation {
    corporation_accounting_email?: string;
    corporation_government_contract_id?: string;
    corporation_head_name: string;
    corporation_head_position: string;
    corporation_iec: string;
    corporation_inn: string;
    corporation_legal_address: string;
    corporation_operation_basis: string;
    corporation_post_address: string;
    corporation_trademark: string;
}

export default class CreateCorporation {
    readonly _corporation_accounting_email: string | undefined;

    /**
     * Description: Corporation payer accounting email
     * Example: kartseva.elena@rambler.ru
     */
    get corporationAccountingEmail(): string | undefined {
        return this._corporation_accounting_email;
    }

    readonly _corporation_government_contract_id: string | undefined;

    /**
     * Description: Corporation payer government contract id
     * Example: 123
     */
    get corporationGovernmentContractId(): string | undefined {
        return this._corporation_government_contract_id;
    }

    readonly _corporation_head_name: string;

    /**
     * Description: Corporation payer head name
     * Example: МАРТЮШЕВ ОЛЕГ АЛЕКСАНДРОВИЧ
     */
    get corporationHeadName(): string {
        return this._corporation_head_name;
    }

    static corporationHeadNameValidate(corporationHeadName: string): boolean {
        return typeof corporationHeadName === 'string' && !!corporationHeadName.trim();
    }

    readonly _corporation_head_position: string;

    /**
     * Description: Corporation payer head position
     * Example: Руководитель ликвидационной комиссии
     */
    get corporationHeadPosition(): string {
        return this._corporation_head_position;
    }

    static corporationHeadPositionValidate(corporationHeadPosition: string): boolean {
        return typeof corporationHeadPosition === 'string' && !!corporationHeadPosition.trim();
    }

    readonly _corporation_iec: string;

    /**
     * Description: Corporation payer IEC (KPP)
     * Example: 591401001
     */
    get corporationIec(): string {
        return this._corporation_iec;
    }

    static corporationIecValidate(corporationIec: string): boolean {
        return typeof corporationIec === 'string' && !!corporationIec.trim();
    }

    readonly _corporation_inn: string;

    /**
     * Description: Corporation payer INN parameter
     * Example: 5914020680
     */
    get corporationInn(): string {
        return this._corporation_inn;
    }

    static corporationInnValidate(corporationInn: string): boolean {
        return typeof corporationInn === 'string' && !!corporationInn.trim();
    }

    readonly _corporation_legal_address: string;

    /**
     * Description: Corporation payer legal address
     * Example: 618703, ПЕРМСКИЙ КРАЙ, ДОБРЯНКА ГОРОД, ПОЛАЗНА ПОСЕЛОК ГОРОДСКОГО ТИПА, ДРУЖБЫ УЛИЦА, 4А
     */
    get corporationLegalAddress(): string {
        return this._corporation_legal_address;
    }

    static corporationLegalAddressValidate(corporationLegalAddress: string): boolean {
        return typeof corporationLegalAddress === 'string' && !!corporationLegalAddress.trim();
    }

    readonly _corporation_operation_basis: string;

    /**
     * Description: Corporation payer operation basis
     * Example: устава
     */
    get corporationOperationBasis(): string {
        return this._corporation_operation_basis;
    }

    static corporationOperationBasisValidate(corporationOperationBasis: string): boolean {
        return typeof corporationOperationBasis === 'string' && !!corporationOperationBasis.trim();
    }

    readonly _corporation_post_address: string;

    /**
     * Description: Corporation payer post address
     * Example: 618703, ПЕРМСКИЙ КРАЙ, ДОБРЯНКА ГОРОД, ПОЛАЗНА ПОСЕЛОК ГОРОДСКОГО ТИПА, ДРУЖБЫ УЛИЦА, 4А
     */
    get corporationPostAddress(): string {
        return this._corporation_post_address;
    }

    static corporationPostAddressValidate(corporationPostAddress: string): boolean {
        return typeof corporationPostAddress === 'string' && !!corporationPostAddress.trim();
    }

    readonly _corporation_trademark: string;

    /**
     * Description: Corporation payer trademark
     * Example: АДМИНИСТРАЦИЯ ПОЛАЗНЕНСКОГО ГОРОДСКОГО ПОСЕЛЕНИЯ
     */
    get corporationTrademark(): string {
        return this._corporation_trademark;
    }

    static corporationTrademarkValidate(corporationTrademark: string): boolean {
        return typeof corporationTrademark === 'string' && !!corporationTrademark.trim();
    }

    constructor(props: ICreateCorporation) {
        if (typeof props.corporation_accounting_email === 'string') {
            this._corporation_accounting_email = props.corporation_accounting_email.trim();
        }
        if (typeof props.corporation_government_contract_id === 'string') {
            this._corporation_government_contract_id = props.corporation_government_contract_id.trim();
        }
        this._corporation_head_name = props.corporation_head_name.trim();
        this._corporation_head_position = props.corporation_head_position.trim();
        this._corporation_iec = props.corporation_iec.trim();
        this._corporation_inn = props.corporation_inn.trim();
        this._corporation_legal_address = props.corporation_legal_address.trim();
        this._corporation_operation_basis = props.corporation_operation_basis.trim();
        this._corporation_post_address = props.corporation_post_address.trim();
        this._corporation_trademark = props.corporation_trademark.trim();
    }

    serialize(): ICreateCorporation {
        const data: ICreateCorporation = {
            corporation_head_name: this._corporation_head_name,
            corporation_head_position: this._corporation_head_position,
            corporation_iec: this._corporation_iec,
            corporation_inn: this._corporation_inn,
            corporation_legal_address: this._corporation_legal_address,
            corporation_operation_basis: this._corporation_operation_basis,
            corporation_post_address: this._corporation_post_address,
            corporation_trademark: this._corporation_trademark,
        };
        if (typeof this._corporation_accounting_email !== 'undefined') {
            data.corporation_accounting_email = this._corporation_accounting_email;
        }
        if (typeof this._corporation_government_contract_id !== 'undefined') {
            data.corporation_government_contract_id = this._corporation_government_contract_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            corporation_inn: typeof this._corporation_inn === 'string' && !!this._corporation_inn.trim(),
            corporation_trademark: typeof this._corporation_trademark === 'string' && !!this._corporation_trademark.trim(),
            corporation_iec: typeof this._corporation_iec === 'string' && !!this._corporation_iec.trim(),
            corporation_legal_address: typeof this._corporation_legal_address === 'string' && !!this._corporation_legal_address.trim(),
            corporation_post_address: typeof this._corporation_post_address === 'string' && !!this._corporation_post_address.trim(),
            corporation_head_position: typeof this._corporation_head_position === 'string' && !!this._corporation_head_position.trim(),
            corporation_head_name: typeof this._corporation_head_name === 'string' && !!this._corporation_head_name.trim(),
            corporation_operation_basis: typeof this._corporation_operation_basis === 'string' && !!this._corporation_operation_basis.trim(),
            corporation_accounting_email: !this._corporation_accounting_email ? true : typeof this._corporation_accounting_email === 'string',
            corporation_government_contract_id: !this._corporation_government_contract_id ? true : typeof this._corporation_government_contract_id === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICreateCorporation): CreateCorporation {
        return new CreateCorporation(props);
    }

    readonly keys: { [key: string]: string } = {
        corporationAccountingEmail: 'corporation_accounting_email',
        corporationGovernmentContractId: 'corporation_government_contract_id',
        corporationHeadName: 'corporation_head_name',
        corporationHeadPosition: 'corporation_head_position',
        corporationIec: 'corporation_iec',
        corporationInn: 'corporation_inn',
        corporationLegalAddress: 'corporation_legal_address',
        corporationOperationBasis: 'corporation_operation_basis',
        corporationPostAddress: 'corporation_post_address',
        corporationTrademark: 'corporation_trademark',
        }
;

    mergeDeepWith(props: Partial<CreateCorporation>): CreateCorporation {
        const updateData: Partial<ICreateCorporation> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CreateCorporation) => {
            const updateKey = this.keys[key] as keyof ICreateCorporation;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICreateCorporation, keyof ICreateCorporation>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CreateCorporation({ ...this.serialize(), ...updateData });
    }
}
