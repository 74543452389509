// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPhoneInfoUpdate {
    calling_code: string;
    full_number: string;
}

export default class PhoneInfoUpdate {
    readonly _calling_code: string;

    /**
     * Description: Country phone prefix
     * Example: 7
     */
    get callingCode(): string {
        return this._calling_code;
    }

    static callingCodeValidate(callingCode: string): boolean {
        return typeof callingCode === 'string' && !!callingCode.trim();
    }

    readonly _full_number: string;

    /**
     * Description: Phone number with calling code part
     * Example: 79060824985
     */
    get fullNumber(): string {
        return this._full_number;
    }

    static fullNumberValidate(fullNumber: string): boolean {
        return typeof fullNumber === 'string' && !!fullNumber.trim();
    }

    constructor(props: IPhoneInfoUpdate) {
        this._calling_code = props.calling_code.trim();
        this._full_number = props.full_number.trim();
    }

    serialize(): IPhoneInfoUpdate {
        const data: IPhoneInfoUpdate = {
            calling_code: this._calling_code,
            full_number: this._full_number,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            calling_code: typeof this._calling_code === 'string' && !!this._calling_code.trim(),
            full_number: typeof this._full_number === 'string' && !!this._full_number.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPhoneInfoUpdate): PhoneInfoUpdate {
        return new PhoneInfoUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        callingCode: 'calling_code',
        fullNumber: 'full_number',
        }
;

    mergeDeepWith(props: Partial<PhoneInfoUpdate>): PhoneInfoUpdate {
        const updateData: Partial<IPhoneInfoUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PhoneInfoUpdate) => {
            const updateKey = this.keys[key] as keyof IPhoneInfoUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPhoneInfoUpdate, keyof IPhoneInfoUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PhoneInfoUpdate({ ...this.serialize(), ...updateData });
    }
}
