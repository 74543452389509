import React, { FC, FocusEvent, KeyboardEvent, ClipboardEvent, useState } from 'react';
import { Input as InputControl } from 'antd';
import { TextAreaProps as TextAreaControlProps } from 'antd/lib/input';
import cn from 'classnames';

import { useIntl } from 'Common';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

const { TextArea: TextAreaControl } = InputControl;

interface InputPropsBasic {
    autoFocus?: TextAreaControlProps['autoFocus'];
    autoSize?: TextAreaControlProps['autoSize'];
    className?: string;
    description?: string;
    disabled?: boolean;
    error: boolean;
    errorMessage?: string;
    id?: string;
    inputMode?: TextAreaControlProps['inputMode'];
    isGray?: boolean;
    label?: string;
    name: string;
    onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
    onChange?: (data: string) => void;
    onFocus?: (e: FocusEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
    onPaste?: (e: ClipboardEvent<HTMLTextAreaElement>) => void;
    onPressEnter?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    placeholder: string;
    ref?: (el: InstanceType<typeof TextAreaControl>) => void;
    rows?: TextAreaControlProps['rows'];
    size?: 'none' | 'small' | 'medium';
    validateMessage?: string;
    value: string;
    wrapperClassName?: string;
}

interface TextAreaPropsOptional extends InputPropsBasic {
    optional: true;
    validate?: (value: string) => boolean;
}
interface TextAreaPropsValidate extends InputPropsBasic {
    validate: (value: string) => boolean;
    optional?: boolean;
}

export type TextAreaRef = InstanceType<typeof TextAreaControl>;

const TextArea: FC<TextAreaPropsOptional | TextAreaPropsValidate> = ({
    autoFocus,
    autoSize,
    className,
    description,
    disabled,
    error,
    errorMessage,
    id,
    inputMode,
    isGray,
    label,
    wrapperClassName,
    name,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onPaste,
    onPressEnter,
    optional,
    placeholder,
    ref,
    rows,
    size,
    validate,
    validateMessage,
    value,
}) => {
    const intl = useIntl();
    const [valid, setValid] = useState<boolean | null>(null);
    const inputClass = cn(
        'textarea',
        { textarea_gray: isGray },
        { textarea_comment: size === 'small' },
        { textarea_key: size === 'medium' },
        { textarea_error: error || (typeof valid === 'boolean' && !valid) },
        className,
    );

    const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
        if (value && validate) {
            setValid(validate(value));
        }
        if (value === '') {
            setValid(null);
        }
        if (onBlur) {
            onBlur(e);
        }
    };

    let descriptionView = null;
    if (optional) {
        descriptionView = (
            <div className={theme.modal.label}>
                {intl.getMessage('optional_field')}
            </div>
        );
    }
    if (description) {
        descriptionView = (
            <div className={theme.modal.label}>
                {description} {optional && intl.getMessage('optional_field')}
            </div>
        );
    } if (validateMessage && (typeof valid === 'boolean' && !valid)) {
        descriptionView = (
            <div className={theme.modal.label}>
                {validateMessage} {optional && intl.getMessage('optional_field')}
            </div>
        );
    }
    if (errorMessage && errorMessage !== EMPTY_FIELD_ERROR) {
        descriptionView = (
            <div className={theme.modal.label}>
                {errorMessage} {optional && intl.getMessage('optional_field')}
            </div>
        );
    }

    return (
        <label htmlFor={id || name} className={cn(theme.form.group, wrapperClassName)}>
            {label && (
                <div className={theme.modal.label}>
                    {label}
                </div>
            )}
            <TextAreaControl
                autoFocus={autoFocus}
                autoSize={autoSize}
                className={inputClass}
                disabled={disabled}
                id={id || name}
                inputMode={inputMode}
                name={name}
                onBlur={handleBlur}
                onChange={(e) => onChange && onChange(e.target.value)}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onPaste={onPaste}
                onPressEnter={onPressEnter}
                placeholder={placeholder}
                ref={ref}
                rows={rows}
                value={value}
            />
            {descriptionView}
        </label>
    );
};

export default TextArea;
