import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { startServers } from 'Actions/server';
import { Loader, notifyError, notifySuccess, useIntl, whiteText } from 'Common';
import { ActionButton } from 'Common/Server/Actions/components';
import { OperationType } from 'Entities/OperationType';
import ServerEntity from 'Entities/Server';
import theme from 'Lib/theme';
import cn from 'classnames';

interface DisabledServersControlsProps {
    close: () => void;
    servers: Set<ServerEntity>;
    serversIds: number[];
    tenantId: number;
    clearSelection: () => void;
}

const DisabledServersControls: FC<DisabledServersControlsProps> = ({
    close,
    servers,
    serversIds,
    tenantId,
    clearSelection,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const callBacker = () => {
        notifyError((
            <div className={theme.notification.wrapper}>
                <div className={theme.notification.text}>
                    {intl.getPlural('batch_start_loader', servers.size, {
                        b: whiteText,
                    })}
                </div>
                <div className={theme.color.blue}>
                    <Loader circle mini className={theme.notification.loader} />
                </div>
            </div>
        ), undefined, {
            key: OperationType.START,
            duration: 0,
            className: cn(theme.notification.customNotification, 'notification_event'),
        });
        return (serversNames?: string[]) => {
            if (!serversNames) {
                notifySuccess((
                    <div className={theme.notification.text}>
                        {intl.getPlural('batch_start_success', servers.size, { b: whiteText })}
                    </div>
                ), undefined, {
                    className: theme.notification.customNotification,
                });
            } else {
                notifyError(
                    <div className={theme.notification.text}>
                        {intl.getPlural('batch_start_error', servers.size, { b: whiteText })}
                    </div>,
                );
            }
        };
    };

    const onStart = () => {
        dispatch(startServers({
            data: [tenantId, { server_ids: serversIds }],
            notificationId: OperationType.START,
        }, {
            result: callBacker,
        }));
        clearSelection();
        close();
    };
    return (
        <ActionButton
            id="on_start"
            handler={onStart}
            title={intl.getMessage('server_turn_on')}
        />
    );
};

export default DisabledServersControls;
