import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useIntl, Link, notifyError } from 'Common';
import {
    ModalCloneServer,
    ModalServerMove,
    ModalRemove,
    ModalRemoveType,
} from 'Common/Server/Modals';
import Server from 'Entities/Server';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import { DistributionFamily } from 'Entities/DistributionFamily';
import { Store } from 'Store';

interface OptionsOwnProps {
    server: Server;
    close: () => void;
}

const selectCurrentUser = (store: Store) => store.user.userId;

const selector = createSelector([
    selectCurrentUser,
], (currentUserId) => ({ currentUserId }));

const Options: FC<OptionsOwnProps> = ({ server, close }) => {
    const intl = useIntl();
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openCloneModal, setOpenCloneModal] = useState(false);
    const [openServerMove, setOpenServerMove] = useState(false);

    const { currentUserId } = useSelector(selector);

    const onConsoleClick = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!server.consoleAccess || server.serviceMode) {
            e?.preventDefault();
            notifyError(!server.consoleAccess
                ? intl.getMessage('server_console_no_access')
                : intl.getMessage('server_console_service_mode'));
            close();
        }
    };

    return (
        <>
            <div className={theme.popover.divider} />
            {server.distribution.type === DistributionFamily.WINDOWS ? (
                <a
                    target="blank"
                    href={linkPathBuilder(
                        intl.currentLocale,
                        RoutePath.Vnc,
                        { serverId: server.id },
                    )}
                    onClick={onConsoleClick}
                    className={theme.popover.item}
                >
                    {intl.getMessage('console')}
                </a>
            ) : (
                <Link
                    to={RoutePath.Console}
                    props={{ serverId: server.id }}
                    onClick={onConsoleClick}
                    className={theme.popover.item}
                >
                    {intl.getMessage('console')}
                </Link>
            )}
            <Link
                to={RoutePath.ServerReinstall}
                props={{ serverId: server.id }}
                className={theme.popover.item}
            >
                {intl.getMessage('server_reinstall')}
            </Link>
            {server.ownerClientId === currentUserId && (
                <button
                    type="button"
                    className={cn(theme.popover.item)}
                    onClick={() => {
                        close();
                        setOpenServerMove(true);
                    }}
                >
                    {intl.getMessage('to_other_project')}
                </button>
            )}
            <Link
                to={RoutePath.ServerClone}
                props={{ serverId: server.id }}
                className={theme.popover.item}
            >
                {intl.getMessage('create_server_template')}
            </Link>
            <button
                type="button"
                className={cn(theme.popover.item)}
                onClick={() => {
                    close();
                    setOpenCloneModal(true);
                }}
            >
                {intl.getMessage('clone')}
            </button>
            <button
                type="button"
                className={cn(theme.popover.item, theme.popover.item_danger)}
                onClick={() => {
                    close();
                    setOpenModalDelete(true);
                }}
            >
                {intl.getMessage('delete')}
            </button>
            {openModalDelete && (
                <ModalRemove
                    onClose={() => setOpenModalDelete(false)}
                    server={server}
                    type={ModalRemoveType.SERVER}
                    visible={openModalDelete}
                    text={server.name}
                />
            )}
            {openServerMove && (
                <ModalServerMove
                    close={() => setOpenServerMove(false)}
                    server={server}
                />
            )}
            {openCloneModal && (
                <ModalCloneServer
                    close={() => setOpenCloneModal(false)}
                    server={server}
                />
            )}
        </>
    );
};

export default Options;
