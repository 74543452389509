import React from 'react';
import { notification } from 'antd';
import cn from 'classnames';

import { Icon } from 'Common';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { DEFAULT_NOTIFICATION_DURATION } from 'Lib/helpers/consts';

export const notifySuccess = (
    title: string | JSX.Element,
    code?: string,
    options?: {
        duration?: number;
        className?: string;
    },
) => {
    notification.success({
        message: (
            <div data-notification={code || 'success'}>
                {title}
            </div>
        ),
        placement: 'bottomRight',
        duration: typeof options?.duration === 'number' ? options.duration : DEFAULT_NOTIFICATION_DURATION,
        className: cn('notification', options?.className),
        closeIcon: <Icon icon="close_big" />,
    });
};

export const notifyError = (
    title: string | JSX.Element,
    errorCode?: ApiErrorCode | string,
    options?: {
        key?: string;
        btn?: React.ReactNode;
        duration?: number;
        onClose?: () => void;
        className?: string;
    },
) => {
    const { btn, duration, onClose, key } = options || {};
    notification.error({
        onClose,
        key,
        message: (
            <div
                data-notification="error"
                data-error-message={errorCode}
            >
                {title}
            </div>
        ),
        placement: 'bottomRight',
        duration: typeof duration === 'number' ? duration : DEFAULT_NOTIFICATION_DURATION,
        className: cn('notification', options?.className),
        closeIcon: <Icon icon="close_big" />,
        btn,
    });
};
