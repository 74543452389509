import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { Select as SelectControl } from 'antd';
import { SelectProps as AntdSelectProps, SelectValue } from 'antd/lib/select';
import cn from 'classnames';

import { Icon } from 'Common';

const { Option } = SelectControl;

type SelectSize = 'small' | 'medium' | 'big';

interface SelectProps {
    id?: string;
    size?: SelectSize;
    block?: boolean;
    setting?: boolean;
    className?: string;
    dropdownClassName?: string;
    error?: boolean;
    mode?: 'multiple' | 'tags';
    placeholder?: string;
    value: any;
    onChange: (value: any) => void;
    onBlur?: (e: FocusEvent<any>) => void;
    onSearch?: (value: string) => void;
    tagRender?: AntdSelectProps<SelectValue>['tagRender'];
    dropdownRender?: AntdSelectProps<SelectValue>['dropdownRender'];
    listHeight?: AntdSelectProps<SelectValue>['listHeight'];
    optionLabelProp?: AntdSelectProps<SelectValue>['optionLabelProp'];
    menuItemSelectedIcon?: AntdSelectProps<SelectValue>['menuItemSelectedIcon'];
    filterOption?: boolean;
    showArrow?: boolean;
    center?: boolean;
    showScroll?: boolean;
    disabled?: boolean;
    open?: boolean;
    setOpen?: (e: boolean) => void;
}

const Select: FC<SelectProps> = ({
    children,
    id,
    size,
    block,
    center,
    setting,
    className,
    error,
    mode,
    placeholder,
    value,
    onChange,
    onBlur,
    onSearch,
    tagRender,
    filterOption,
    showArrow,
    dropdownRender,
    listHeight,
    optionLabelProp,
    menuItemSelectedIcon,
    showScroll,
    disabled,
    open: isOpen,
    setOpen: setIsOpen,
    dropdownClassName,
}) => {
    const [open, setOpen] = useState(!!isOpen);
    const selectClass = cn(
        'select',
        { 'select--big': size === 'big' },
        { 'select--medium': size === 'medium' },
        { 'select--block': block },
        { 'select--setting': setting },
        { 'select--multi': mode === 'multiple' },
        { select_error: error },
        { select_center: center },
        className,
    );

    let tagWrapper;
    if (tagRender) {
        tagWrapper = (props: any) => (
            <div onClick={() => setOpen(!open)}>
                {tagRender(props)}
            </div>
        );
    }

    useEffect(() => {
        if (typeof isOpen === 'boolean') {
            setOpen(isOpen);
        }
    }, [isOpen]);

    const onOpenChange = (e: boolean) => {
        if (setIsOpen) {
            setIsOpen(e);
        } else {
            setOpen(e);
        }
    };

    return (
        <SelectControl
            id={id}
            data-name={id}
            data-value={value}
            className={selectClass}
            mode={mode}
            open={open}
            onDropdownVisibleChange={onOpenChange}
            tagRender={tagWrapper}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            onSearch={onSearch}
            filterOption={filterOption}
            suffixIcon={(
                <Icon icon="down" className="select__arrow" />
            )}
            dropdownRender={dropdownRender}
            showArrow={showArrow}
            listHeight={listHeight}
            optionLabelProp={optionLabelProp}
            menuItemSelectedIcon={menuItemSelectedIcon}
            dropdownClassName={cn(
                'select-dropdown',
                { 'select-dropdown_scroll': showScroll },
                dropdownClassName,
            )}
            disabled={disabled}
        >
            {children}
        </SelectControl>
    );
};

export default Select;
export { Option };
