import React, { FC } from 'react';

import s from '../Chart.module.pcss';

interface ReferenceAreaShapeProps {
    clipPath: string;
    fill: string;
    fillOpacity: number;
    height: number;
    r: number;
    stroke: string;
    strokeWidth: number;
    width: number;
    x: number;
    x2: number;
    y: number;
}

const AreaStripes: FC<ReferenceAreaShapeProps> = ({ x, y, width, height }) => {
    return (
        <g>
            <pattern
                id="diagonalHatch"
                width={7}
                height={7}
                patternTransform="rotate(45 0 0)"
                patternUnits="userSpaceOnUse"
            >
                <line x1="0" y1="0" x2="0" y2="10" className={s.stripe} />
            </pattern>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                fill="url(#diagonalHatch)"
            />
        </g>
    );
};

export default AreaStripes;
