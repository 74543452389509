import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useTitle } from 'Hooks';
import { createTicket } from 'Actions/support';
import { useIntl, InnerPageLayout, BackHeader, Input, TextArea, Button } from 'Common';
import { RoutePath, linkPathBuilder, GuestRoutePath, externalLinkBuilder } from 'Lib/helpers/routes';
import { Subjects, subjectTranslate, getTicketSubject } from 'Lib/helpers/translationHelper';
import { ITicketCreate } from 'Entities/TicketCreate';
import { TICKETS_STATE, TICKETS_SUBJECT } from 'Lib/helpers/consts';
import { Store } from 'Store';
import theme from 'Lib/theme';

import Files from './Files';
import s from './CreateTicket.module.pcss';

interface CreateTicketProps {
    loggedIn: boolean;
    profile: Store['profile']['info'];
}

const CreateTicket: FC<CreateTicketProps> = ({ profile }) => {
    const intl = useIntl();
    const { currentLocale } = intl;
    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch<{ subject: TICKETS_SUBJECT; email: string }>();

    const { subject: ticketSubject, email } = match.params;

    useTitle(intl.getMessage('create_ticket_page_title'));

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    const getInitialMessage = () => {
        if (email) {
            return intl.getMessage('support_subject_client_delete_desc', { value: email });
        }

        if (ticketSubject === TICKETS_SUBJECT.OPEN_STUDIO_ACCESS) {
            return intl.getMessage('support_subject_open_studio_access_desc');
        }

        return '';
    };

    const [message, setMessage] = useState(getInitialMessage());
    const [messageError, setMessageError] = useState(false);
    const [subject, setSubject] = useState(
        ticketSubject
            ? getTicketSubject(intl, TICKETS_SUBJECT[ticketSubject])
            : '',
    );
    const [files, setFiles] = useState<File[]>([]);

    const KbLink = (text: string) => (
        <a href={externalLinkBuilder(currentLocale, GuestRoutePath.Kb)}>
            {text}
        </a>
    );

    const handleSubmit = async () => {
        if (!message.trim()) {
            setMessageError(true);
            return;
        }
        const data = new FormData();
        const params: ITicketCreate = {
            subject,
            close_immediately: false,
            contents: message.trim(),
        };
        data.set('params', new Blob([JSON.stringify(params)], { type: 'application/json' }));
        files.forEach((f) => {
            data.append('attachments', f);
        });
        dispatch(createTicket([data]));
        history.push(linkPathBuilder(
            intl.currentLocale,
            RoutePath.Tickets,
            { open: TICKETS_STATE.CLOSED },
        ));
    };

    return (
        <InnerPageLayout
            header={<BackHeader type="narrow" link={RoutePath.Tickets} />}
            className={theme.content.create}
        >
            <h1 className={s.title}>
                {intl.getMessage('create_ticket')}
            </h1>
            <div className={s.desc}>
                {intl.getMessage('create_ticket_desc', {
                    link: KbLink,
                })}
            </div>
            <form className={s.form} onSubmit={(e) => e.preventDefault()}>
                <div className={s.input}>
                    <Input
                        type="text"
                        name="subject"
                        size="middle"
                        placeholder={intl.getMessage('create_ticket_input_placeholder')}
                        onChange={setSubject}
                        value={subject}
                        validate={() => subject.length > 0}
                        error={false}
                        autoFocus
                    />
                </div>
                <div className={s.subjects}>
                    {Object.keys(Subjects).map((sb) => (
                        <button
                            type="button"
                            key={sb}
                            className={cn(
                                theme.Badge.badge,
                                theme.Badge.subject,
                                s.subject,
                            )}
                            onClick={() => setSubject(subjectTranslate(intl, sb as Subjects))}
                        >
                            {subjectTranslate(intl, sb as Subjects)}
                        </button>
                    ))}
                </div>
                <div className={cn(s.item, s.item_comment)}>
                    <TextArea
                        name="message"
                        value={message}
                        size="small"
                        onChange={(v) => setMessage(v)}
                        placeholder={intl.getMessage('create_ticket_textarea_placeholder')}
                        validate={(v) => v.length > 0}
                        error={messageError}
                        autoSize
                        isGray
                        autoFocus={!!ticketSubject}
                    />
                </div>
                <div className={cn(s.item, s.item_actions)}>
                    <Files
                        profile={profile}
                        files={files}
                        setFiles={setFiles}
                    />
                </div>
                <div className={s.submit}>
                    <Button
                        type="primary"
                        size="big"
                        onClick={handleSubmit}
                    >
                        {intl.getMessage('send')}
                    </Button>
                </div>
            </form>
        </InnerPageLayout>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selectLoggedIn = (store: Store) => store.user.loggedIn;

const selector = createSelector(
    [selectLoggedIn, selectProfile],
    (loggedIn, profile) => {
        return { loggedIn, profile };
    },
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(CreateTicket);
