import React, { FC } from 'react';
import { Row, Col } from 'antd';

import { Icon } from 'Common';

import s from './NotificationSettingsTable.module.pcss';

interface NotificationSettingsHeaderProps {
    title: string;
}

const NotificationSettingsHeader: FC<NotificationSettingsHeaderProps> = ({ title }) => (
    <Row gutter={[24, 0]} align="middle" className="row row_nowrap">
        <Col flex="auto">
            <div className={s.label}>
                {title}
            </div>
        </Col>
        <Col>
            <Icon icon="mail" className={s.icon} />
        </Col>
        <Col>
            <Icon icon="phone" className={s.icon} />
        </Col>
    </Row>
);

export default NotificationSettingsHeader;
