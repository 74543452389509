import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';

import { Icon, useIntl } from 'Common';
import TicketPostAttachment from 'Entities/TicketPostAttachment';
import { SUPPORT_MAX_FILES } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

interface AttachmentsProps {
    ticketId: number;
    attachments?: TicketPostAttachment[];
    upload?: File[];
    onRemove?: (files: File[]) => void;
    onAttach?: () => void;
    onMessage?: boolean;
}

const Attachments: FC<AttachmentsProps> = ({
    attachments,
    upload,
    onMessage,
    onRemove,
    onAttach,
}) => {
    const intl = useIntl();
    const [visible, setVisible] = useState(false);

    const handler = (index: number) => {
        if (upload) {
            const newFiels = [...upload];
            newFiels.splice(index, 1);
            onRemove!(newFiels);
        } else {
            const { downloadLink } = attachments![index];
            window.location.href = downloadLink!;
        }
    };

    const items: any[] = attachments! || upload!;
    const filesList = (
        <div className={theme.popover.popover}>
            {items.map((item, i) => {
                return (
                    <div
                        className={theme.popover.file}
                        key={item.id || `${item.lastModified} ${Math.random()}`}
                    >
                        <span className={theme.popover.fileName}>
                            {item.filename || item.name}
                        </span>

                        <button
                            type="button"
                            className={theme.popover.fileAction}
                            onClick={() => handler(i)}
                        >
                            <Icon icon={onMessage ? 'download' : 'delete'} />
                        </button>
                    </div>
                );
            })}
            {onAttach && items.length < SUPPORT_MAX_FILES && (
                <>
                    <div className={theme.popover.divider} />
                    <button
                        type="button"
                        className={theme.popover.item}
                        onClick={onAttach}
                    >
                        {intl.getMessage('upload_file')}
                    </button>
                </>
            )}
        </div>
    );

    return (
        <Popover
            trigger="click"
            content={filesList}
            placement={onMessage ? 'bottom' : 'topRight'}
            visible={visible}
            onVisibleChange={(e) => setVisible(e)}
            overlayClassName="popover"
        >
            {onMessage ? (
                <div
                    className={cn(
                        theme.Badge.badge,
                        theme.Badge.whiteAttachment,
                    )}
                >
                    <Icon icon="link" className={theme.Badge.linkIcon} />
                    {intl.getPlural('files_count', items.length)}
                </div>
            ) : (
                <div
                    className={cn(
                        theme.Badge.badge,
                        theme.Badge.attachment,
                    )}
                >
                    {items.length}
                </div>
            )}
        </Popover>
    );
};

export default Attachments;
