import React, { FC, useState, ClipboardEvent } from 'react';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import cn from 'classnames';

import { createPublicKey, updatePublicKey } from 'Actions/publicKeys';
import { FormModalLayout, useIntl, Checkbox, Input, Select, Option, TextArea, Warning, CodeInput } from 'Common';
import Tenant from 'Entities/Tenant';
import Error from 'Entities/Error';
import Server from 'Entities/Server';
import PublicKey, { IPublicKey } from 'Entities/PublicKey';
import { IPublicKeyUpdate } from 'Entities/PublicKeyUpdate';
import PublicKeyCreate, { IPublicKeyCreate } from 'Entities/PublicKeyCreate';
import { BATCH_PUBLIC_KEYS_NOTIFICATION_ID, REGULAR_CHARACTER_LIMIT } from 'Lib/helpers/consts';
import newPk, { ErrorType } from 'Lib/helpers/newPublicKey';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { Store } from 'Store';
import theme from 'Lib/theme';
import { useSecurityBatchActions } from 'Lib/hooks/hooks';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { addError } from 'Actions/error';
import { addPublicKeys, ServerActions } from 'Actions/server';
import { DistributionFamily } from 'Entities/DistributionFamily';

const ANY_TENANT_KEY = -1;

interface ModalDeleteStoreProps {
    tenants: Tenant[];
    servers: Server[];
}
interface ModalDeleteOwnProps {
    visible: boolean;
    handleClose: () => void;
    initialValue?: PublicKey;
}

type ModalDeleteProps = ModalDeleteStoreProps & ModalDeleteOwnProps;

const ModalAddKey: FC<ModalDeleteProps> = ({
    visible,
    handleClose,
    initialValue,
    tenants,
    servers,
}) => {
    const intl = useIntl();
    const [expiredShown, setExpiredShown] = useState(false);
    const dispatch = useDispatch();

    const setInitialTenantId = () => {
        if (initialValue?.shared) {
            return ANY_TENANT_KEY;
        }
        return initialValue?.tenantId || ANY_TENANT_KEY;
    };

    const [tenantId, setTenantId] = useState(setInitialTenantId());
    const [installKeys, setInstallKeys] = useState(false);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [currentKeyId, setCurrentKeyId] = useState<number | null>(null);

    const isDefaultTenant = tenantId === ANY_TENANT_KEY;
    const serverIds = servers
        .filter((server) => {
            const publicKeysAllowed = server.distribution.type !== DistributionFamily.WINDOWS;

            if (!isDefaultTenant) {
                return publicKeysAllowed && server.tenantId === tenantId;
            }

            return publicKeysAllowed;
        })
        .map((server) => server.id);

    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityBatchActions(SecurityActionType.SERVER_PUBLIC_KEY, true);

    const handleResult = (keyId: number, helpers: FormikHelpers<IPublicKeyCreate>) => {
        if (installKeys && serverIds.length > 0) {
            setCurrentKeyId(keyId);

            const currentTenantId = isDefaultTenant
                ? servers[0].tenantId
                : tenantId;

            if (shouldConfirmAction && !codeSent) {
                sendConfirmationCode({
                    tenantId: currentTenantId,
                    serverIds,
                });
                helpers.setSubmitting(false);
            }
            const onError = (error: Error) => {
                if (error.fields.security_code?.[0]) {
                    setCodeError(true);
                } else {
                    dispatch(addError({
                        error,
                        type: ServerActions.AddPublicKeys,
                    }));
                }
            };
            const callback = {
                result: handleClose,
                error: onError,
            };
            if (!shouldConfirmAction) {
                dispatch(addPublicKeys({
                    data: [currentTenantId, {
                        server_ids: serverIds,
                        public_keys: [keyId],
                        actionType: SecurityActionType.SERVER_PUBLIC_KEY,
                    }],
                    notificationId: BATCH_PUBLIC_KEYS_NOTIFICATION_ID,
                }, callback));
            }

            if (codeSent && shouldConfirmAction) {
                if (!code) {
                    setCodeError(true);
                }
                dispatch(addPublicKeys({
                    data: [currentTenantId, {
                        server_ids: serverIds,
                        public_keys: [keyId],
                        actionType: SecurityActionType.SERVER_PUBLIC_KEY,
                        security_code: code,
                    }],
                    notificationId: BATCH_PUBLIC_KEYS_NOTIFICATION_ID,
                }, callback));
            }
        } else {
            handleClose();
        }
    };

    const handleAdd = (values: IPublicKeyCreate, helpers: FormikHelpers<IPublicKeyCreate>) => {
        let reqEnt = new PublicKeyCreate(values);
        const errors = newPk.validate(reqEnt);
        reqEnt.validate().forEach((k) => {
            errors[k as keyof typeof errors] = ErrorType.Error;
        });
        if (Object.keys(errors).length === 1 && errors.content === ErrorType.Deprecated) {
            if (!expiredShown) {
                setExpiredShown(true);
                helpers.setErrors(errors);
                helpers.setSubmitting(false);
                return;
            }
        }
        if (Object.keys(errors).length && errors.content !== ErrorType.Deprecated) {
            helpers.setErrors(errors);
            helpers.setSubmitting(false);
            return;
        }
        let newTenantId = tenantId;
        if (isDefaultTenant) {
            reqEnt = reqEnt.mergeDeepWith({ shared: true });
            newTenantId = tenants[0]?.id;
        } else {
            reqEnt = reqEnt.mergeDeepWith({ shared: false });
        }

        const handleApiError = (e: Error) => {
            Object.keys(e.fields).forEach((ek) => {
                const [{ error_code: errCode }] = e.fields[ek];
                errors[ek as keyof IPublicKeyCreate] = apiErrorCodeTranslate(intl, errCode);
            });
            helpers.setErrors(errors);
            helpers.setSubmitting(false);
        };

        if (currentKeyId && codeSent && deliveryMessage) {
            handleResult(currentKeyId, helpers);
            return;
        }

        if (initialValue) {
            const { tenantId: updateTenanId, id: keyId } = initialValue;
            const data: IPublicKeyUpdate = { ...reqEnt.serialize(), to_tenant_id: newTenantId };
            dispatch(updatePublicKey([updateTenanId, keyId, data], {
                result: () => handleResult(keyId, helpers),
                error: handleApiError,
            }));
        } else {
            dispatch(createPublicKey([newTenantId, reqEnt.serialize()], {
                result: (key: IPublicKey) => handleResult(key.id, helpers),
                error: handleApiError,
            }));
        }
    };

    const isEdit = !!initialValue;
    const title = isEdit ? intl.getMessage('edit_key') : intl.getMessage('add_key');

    return (
        <FormModalLayout
            isEntity
            initialValues={initialValue?.serialize() ?? newPk.initialValues()}
            visible={visible}
            title={title}
            buttonText={title}
            handleSubmit={handleAdd}
            handleClose={handleClose}
            width={600}
        >
            {({
                values,
                errors,
                setFieldValue,
            }) => {
                const handleTitleChange = (value: string) => {
                    if (!(value.length > PublicKeyCreate.titleMaxLength)) {
                        setFieldValue('title', value);
                    }
                };

                const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    const text = values.title + e.clipboardData.getData('text/plain');
                    setFieldValue('title', text.slice(0, PublicKeyCreate.titleMaxLength));
                };

                return (
                    <>
                        <Input
                            autoFocus
                            error={!!errors.title}
                            name="title"
                            onChange={handleTitleChange}
                            onPaste={onPaste}
                            placeholder={intl.getPlural('character_limit_name', REGULAR_CHARACTER_LIMIT)}
                            size="large"
                            type="text"
                            validate={PublicKeyCreate.titleValidate}
                            value={values.title}
                        />
                        <div className={theme.modal.desc}>
                            <Select
                                block
                                size="big"
                                value={tenantId}
                                onChange={(v) => setTenantId(v)}
                            >
                                <Option value={ANY_TENANT_KEY}>
                                    {intl.getMessage('all_projects')}
                                </Option>
                                {tenants.map((t) => (
                                    <Option
                                        value={t.id}
                                        key={t.id}
                                    >
                                        {t.description}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <div className={theme.modal.desc}>
                            <TextArea
                                name="content"
                                className="textarea_key"
                                error={errors.content && errors.content !== ErrorType.Deprecated}
                                onChange={(e) => setFieldValue('content', e)}
                                placeholder={intl.getMessage('key')}
                                validate={(e) => (
                                    PublicKeyCreate.contentValidate(e)
                                    && newPk.validatePublicKey(e))}
                                value={values.content}
                                autoSize
                                isGray
                            />
                        </div>
                        {errors.content === ErrorType.Deprecated && (
                            <div className={cn(theme.modal.desc, theme.modal.desc_black)}>
                                <Warning text={intl.getMessage('public_key_expired')} />
                            </div>
                        )}
                        <div className={cn(theme.modal.desc, theme.modal.desc_checkbox)}>
                            <Checkbox
                                id="new_key"
                                name="new_key"
                                handleChange={(e) => setFieldValue('install_default', e.target.checked)}
                                checked={values.install_default}
                            >
                                {intl.getMessage('modal_new_key_check')}
                            </Checkbox>
                        </div>
                        <div className={cn(theme.modal.desc, theme.modal.desc_black)}>
                            <Checkbox
                                id="install_keys"
                                name="install_keys"
                                handleChange={(e) => setInstallKeys(e.target.checked)}
                                checked={installKeys}
                            >
                                {intl.getMessage('modal_install_keys')}
                            </Checkbox>
                        </div>
                        {(codeSent && deliveryMessage) && (
                            <>
                                <div className={cn(theme.modal.desc, theme.modal.desc_gray)}>
                                    {deliveryMessage}
                                </div>
                                <CodeInput
                                    value={code}
                                    setValue={(e) => setCode(e)}
                                    codeError={codeError}
                                    setCodeError={(e) => setCodeError(e)}
                                    onSendAgain={() => sendConfirmationCode({
                                        tenantId,
                                        serverIds,
                                    })}
                                />
                            </>
                        )}
                    </>
                );
            }}
        </FormModalLayout>
    );
};

const selectTenants = (store: Store) => store.tenant;
const selectServers = (store: Store) => store.server;

const selector = createSelector([
    selectTenants,
    selectServers,
], (tenants, servers) => ({
    tenants: Array.from(tenants.values()),
    servers: Array.from(servers.values()),
}));

const mapStateToProps = (store: Store) => {
    return { ...selector(store) };
};

export default connect(mapStateToProps)(ModalAddKey);
