import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { useIntl, Select, Option, Icon } from 'Common';
import AccountDetails from 'Entities/AccountDetails';
import AccountCreditCard from 'Entities/AccountCreditCard';
import { CardPlaceholder } from 'Common/Account/BoundCards';
import { Store } from 'Store';
import theme from 'Lib/theme';

import NewCardForm from './NewCardForm';
import SavedCardForm from './SavedCardForm';
import s from './Form.module.pcss';

const NEW_CARD_ID = -1;

interface CardFormStoreProps {
    cards: AccountCreditCard[] | null;
}

interface CardFormOwnProps {
    details: AccountDetails;
    noLayout?: boolean;
    isFirst: boolean;
}

type CardFormProps = CardFormStoreProps & CardFormOwnProps;

const CardForm: FC<CardFormProps> = ({ cards, details, isFirst, noLayout }) => {
    const intl = useIntl();
    const [cardId, setCardId] = useState(NEW_CARD_ID);

    useEffect(() => {
        if (cards !== null) {
            setCardId(cards.length > 0 ? cards[0].creditCardId : NEW_CARD_ID);
        }
    }, [cards]);

    if (cards === null) {
        return null;
    }

    return (
        <div className={s.form}>
            {cards.length !== 0 && (
                <div className={cn(s.group, s.group_cards)}>
                    <div className={theme.form.label}>
                        {intl.getMessage('saved_card')}
                    </div>
                    <Select
                        block
                        size="big"
                        value={cardId}
                        placeholder={intl.getMessage('select_card')}
                        optionLabelProp="label"
                        onChange={(v) => setCardId(v)}
                    >
                        {cards.map((card) => (
                            <Option
                                value={card.creditCardId}
                                key={card.creditCardId}
                                className="option option_card"
                                label={(
                                    <CardPlaceholder
                                        card={card}
                                        select
                                    />
                                )}
                            >
                                <CardPlaceholder
                                    card={card}
                                    select
                                />
                            </Option>
                        ))}
                        <Option
                            value={NEW_CARD_ID}
                            className="option option_card"
                            label={intl.getMessage('new_card')}
                        >
                            <Icon icon="plus" className={s.plus} />
                            {intl.getMessage('new_card')}
                        </Option>
                    </Select>
                </div>
            )}

            {cardId === NEW_CARD_ID ? (
                <NewCardForm
                    details={details}
                    noLayout={noLayout}
                />
            ) : (
                <SavedCardForm
                    details={details}
                    isFirst={isFirst}
                    noLayout={noLayout}
                    cardId={cardId}
                />
            )}
        </div>
    );
};

const selectCards = (store: Store) => store.account.cards;

const selector = createSelector([
    selectCards,
], (cards) => ({ cards: cards ? Array.from(cards.values()) : null }));

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(CardForm);
