import React, { FC, useState } from 'react';
import { Popover, Row, Col } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { deleteDnsRecord } from 'Actions/dns';
import { useIntl, Button } from 'Common';
import DnsRecord from 'Entities/DnsRecord';
import { getDnsLabel } from 'Lib/helpers/translationHelper';
import DnsZone from 'Entities/DnsZone';
import theme from 'Lib/theme';

import { DeleteDnsRecord, EditDnsRecord } from '../../Modals';
import s from './Record.module.pcss';

interface RecordProps {
    record: DnsRecord;
    zone: DnsZone;
}
const Record: FC<RecordProps> = ({ record, zone }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [openRecordModal, setOpenRecordModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const onDeleteRecord = () => {
        const { zoneId, id } = record;
        dispatch(deleteDnsRecord([zone.tenantId, zoneId, id]));
    };

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
            )}
            data-row="dnsRecordRow"
        >
            <Row className={s.row}>
                <Col span={24} sm={3}>
                    <div className={s.type}>
                        <div className={cn(s.value, s.value_type)}>
                            {record.type}
                            {typeof record.priority === 'number' && (
                                <div
                                    className={cn(
                                        s.badge,
                                        theme.Badge.badge,
                                        theme.Badge.yellow,
                                        theme.Badge.small,
                                    )}
                                >
                                    {record.priority}
                                </div>
                            )}
                        </div>
                        <div className={s.label}>
                            {intl.getMessage('dns_record_type')}
                        </div>
                    </div>
                </Col>
                <Col span={24} sm={6}>
                    <div className={s.host}>
                        <div className={s.value}>
                            {record.host}
                        </div>
                        <div className={s.label}>
                            {intl.getMessage('dns_name')}
                        </div>
                    </div>
                </Col>
                <Col span={24} sm={9}>
                    <div className={s.other}>
                        {typeof record.weight === 'number' && (
                            <div className={s.wrap}>
                                <div className={s.value}>
                                    {record.weight}
                                </div>
                                <div className={s.label}>
                                    {intl.getMessage('dns_weight')}
                                </div>
                            </div>
                        )}
                        {typeof record.port === 'number' && (
                            <div className={s.wrap}>
                                <div className={s.value}>
                                    {record.port}
                                </div>
                                <div className={s.label}>
                                    {intl.getMessage('dns_port')}
                                </div>
                            </div>
                        )}
                        <div className={s.wrap}>
                            <div className={s.value}>
                                {record.data}
                            </div>
                            <div className={s.label}>
                                {getDnsLabel(intl, record.type)}
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24} sm={3}>
                    <div className={s.ttl}>
                        <div className={s.value}>
                            {intl.getMessage('seconds_value', { seconds: record.ttlSeconds })}
                        </div>
                        <div className={s.label}>
                            {intl.getMessage('dns_ttl')}
                        </div>
                    </div>
                </Col>
                <Col span={24} sm={3}>
                    <div className={theme.card.actions}>
                        <Button
                            type="icon"
                            icon="edit"
                            className={cn(theme.card.action, theme.card.action_hover)}
                            onClick={() => setOpenEditModal(true)}
                            title={intl.getMessage('title_dns_record_edit')}
                        />
                        <Button
                            type="icon"
                            icon="delete"
                            className={cn(theme.card.action, theme.card.action_hover)}
                            onClick={() => setOpenRecordModal(true)}
                            title={intl.getMessage('title_dns_record_delete')}
                        />
                    </div>
                </Col>
            </Row>

            <Popover
                placement="leftTop"
                trigger="click"
                content={(
                    <div className={theme.popover.popover}>
                        <button
                            type="button"
                            className={theme.popover.item}
                            onClick={() => setOpenEditModal(true)}
                        >
                            {intl.getMessage('edit')}
                        </button>
                        <button
                            type="button"
                            className={cn(
                                theme.popover.item,
                                theme.popover.item_danger,
                            )}
                            onClick={() => setOpenRecordModal(true)}
                        >
                            {intl.getMessage('delete')}
                        </button>
                    </div>
                )}
                overlayClassName="popover"
            >
                <Button
                    type="icon"
                    icon="options"
                    className={cn(theme.card.action, theme.card.action_popover)}
                />
            </Popover>

            {openRecordModal && (
                <DeleteDnsRecord
                    visible={openRecordModal}
                    handleClose={() => setOpenRecordModal(false)}
                    onOk={onDeleteRecord}
                />
            )}
            {openEditModal && (
                <EditDnsRecord
                    record={record}
                    onClose={() => setOpenEditModal(false)}
                    zone={zone}
                />
            )}
        </div>
    );
};

export default Record;
