import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { useIntl, Button } from 'Common';
import { startServer } from 'Actions/server';
import Server from 'Entities/Server';
import Profile from 'Entities/Profile';
import { WebTheme } from 'Entities/WebTheme';
import { getCurrentTheme } from 'Lib/helpers/helpers';
import { Store } from 'Store';

import warning from 'Lib/assets/images/warning.png';
import warning2x from 'Lib/assets/images/warning@2x.png';
import warningDark from 'Lib/assets/images/warning_dark.png';
import warning2xDark from 'Lib/assets/images/warning_dark@2x.png';

import s from './Console.module.pcss';

interface DisabledOwnProps {
    server: Server;
}

interface DisabledStoreProps {
    profile: Profile | null;
}

type DisabledProps = DisabledStoreProps & DisabledOwnProps;

const Disabled: FC<DisabledProps> = ({ server, profile }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const onStart = () => {
        dispatch(startServer(server));
    };

    let images = {
        warning,
        warning2x,
    };

    const currentTheme = profile?.webTheme && getCurrentTheme(profile.webTheme);

    if (currentTheme === WebTheme.DARK) {
        images = {
            warning: warningDark,
            warning2x: warning2xDark,
        };
    }

    return (
        <div className={s.disabled}>
            <img
                src={images.warning}
                srcSet={`${images.warning} 2x`}
                className={s.image}
                alt="warning"
            />
            <div className={s.desc}>
                {intl.getMessage('server_console_disabled')}
            </div>
            <Button
                type="link"
                size="medium"
                onClick={onStart}
            >
                {intl.getMessage('start_server')}
            </Button>
        </div>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([selectProfile], (profile) => ({ profile }));

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(Disabled);
