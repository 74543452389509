import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, useIntl } from 'Common';

import theme from 'Lib/theme';
import s from './Finance.module.pcss';

interface GeneralAmountProps {
    totalPaid: number;
}

const GeneralAmount: FC<GeneralAmountProps> = ({ totalPaid }) => {
    const intl = useIntl();

    return (
        <div className={s.wrapper}>
            <Icon icon="coins" />
            <div className={s.amount}>
                <span className={s.value}>
                    {intl.getMessage('price_simple', { value: totalPaid })}
                </span>
                <span className={cn(theme.color.grayHeaders, s.postfix)}>
                    {intl.getMessage('partner_general_amount_postfix')}
                </span>
            </div>
        </div>
    );
};

export default GeneralAmount;
