import { takeEvery, put, call, fork, join } from 'redux-saga/effects';
import { Task } from 'redux-saga';

import { InplaceType } from 'Actions/.';
import ServerApi from 'Apis/servers';
import TenantApi from 'Apis/tenants';
import {
    TenantActions,
    getTenant as getTenantAct,
    getTenantsList as getTenantListAct,
    saveTenant,
    saveTenantsList,
    addUserToTenant as addUserToTenantAction,
    removeUserFromTenant,
    updateBackupPolicy as updateBackupPolicyAct,
    updateTenantDescription as updateTenantDescriptionAct,
    deleteTenant as deleteTenantAct,
    deleteTenantLocal,
    createTenant as createTenantAct,
    createTenantWithServerUpdate as createTenantWithServerUpdateAct,
} from 'Actions/tenant';
import { getTenantsUsersList } from 'Actions/tenantUsers';
import { getServerList as getServerListAct } from 'Actions/server';
import Tenant, { ITenant } from 'Entities/Tenant';

import { getServerList } from './server';

import { errorChecker, addError } from './utils';

export function* getTenantAndServerList() {
    const forked: Task = yield fork(getServerList, getServerListAct());
    yield join(forked);
    yield put(getTenantsUsersList());
    const response: ITenant[] = yield call(TenantApi.listTenants);
    const { error, result } = errorChecker<ITenant[]>(response);
    if (result) {
        const tenantEntities = result.map((t) => new Tenant(t));
        yield put(saveTenantsList(tenantEntities));
    }
    if (error) {
        yield addError(error, InplaceType.PreloadServersAndTenants);
    }
}
function* getTenantList(action: ReturnType<typeof getTenantListAct>) {
    yield put(getServerListAct());
    yield put(getTenantsUsersList());
    const response: ITenant[] = yield call(TenantApi.listTenants);
    const { error, result } = errorChecker<ITenant[]>(response);
    if (result) {
        const tenantEntities = result.map((t) => new Tenant(t));
        yield put(saveTenantsList(tenantEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getTenant(action: ReturnType<typeof getTenantAct>) {
    const { payload } = action;
    const response: ITenant = yield call(TenantApi.getTenant, ...payload);
    const { error, result } = errorChecker<ITenant>(response);
    if (result) {
        const tenantEntity = new Tenant(response);
        yield put(saveTenant(tenantEntity));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* addUserToTenant(action: ReturnType<typeof addUserToTenantAction>) {
    const { payload, callback } = action;
    const response: number = yield call(TenantApi.addUserToTenant, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getTenantsUsersList());
        if (callback?.result) {
            callback?.result();
        }
        return;
    }
    if (callback?.error) {
        callback.error(error);
    }
}

function* removeUser(action: ReturnType<typeof removeUserFromTenant>) {
    const { payload } = action;
    const { parameters, leave } = payload;
    const response: number = yield call(TenantApi.deleteUserFromTenant, ...parameters);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getTenantsUsersList());

        if (leave) {
            yield put(getTenantListAct());
            yield put(deleteTenantLocal(parameters[0]));
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateBackupPolicy(action: ReturnType<typeof updateBackupPolicyAct>) {
    const { payload } = action;
    const response: number = yield call(TenantApi.updateBackupPolicyForTenant, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getTenantAct([payload[0]]));
        // TODO: change for getServerForTenant
        yield put(getServerListAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateTenantDescription(action: ReturnType<typeof updateTenantDescriptionAct>) {
    const { payload } = action;
    const response: number = yield call(TenantApi.updateTenant, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getTenantAct([payload[0]]));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* deleteTenant(action: ReturnType<typeof deleteTenantAct>) {
    const { payload } = action;
    const response: number = yield call(TenantApi.deleteTenant, payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(deleteTenantLocal(payload));
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* createTenant(action: ReturnType<typeof createTenantAct>) {
    const { payload, callback } = action;
    const response: ITenant = yield call(TenantApi.createTenant, ...payload);
    const { error, result } = errorChecker<ITenant>(response);
    if (result) {
        if (callback?.result) {
            callback.result(new Tenant(result));
        }

        yield put(getTenantsUsersList());
        yield put(saveTenant(new Tenant(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createTenantWithServerUpdate(action: ReturnType<typeof createTenantWithServerUpdateAct>) {
    const { payload } = action;
    const { newTenant: [tenantСreate], goToServer, server } = payload;
    const response: ITenant = yield call(TenantApi.createTenant, tenantСreate);

    const { error, result } = errorChecker<ITenant>(response);
    if (result) {
        const { tenantId, id } = server;
        yield call(ServerApi.updateServer, tenantId, id, { to_tenant_id: result.id });
        const forked: Task = yield fork(getTenantAndServerList);
        yield join(forked);
        goToServer(result.id);
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* tenantSaga(): Generator {
    yield takeEvery<ReturnType<typeof getTenantListAct>>(
        TenantActions.GetTenantsList, getTenantList,
    );
    yield takeEvery<ReturnType<typeof getTenantAct>>(
        TenantActions.GetTenant, getTenant,
    );
    yield takeEvery<ReturnType<typeof addUserToTenantAction>>(
        TenantActions.AddUserToTenant, addUserToTenant,
    );
    yield takeEvery<ReturnType<typeof removeUserFromTenant>>(
        TenantActions.RemoveUserFromTenant, removeUser,
    );
    yield takeEvery<ReturnType<typeof updateBackupPolicyAct>>(
        TenantActions.UpdateBackupPolicy, updateBackupPolicy,
    );
    yield takeEvery<ReturnType<typeof updateTenantDescriptionAct>>(
        TenantActions.UpdateTenantDescription, updateTenantDescription,
    );
    yield takeEvery<ReturnType<typeof deleteTenantAct>>(
        TenantActions.DeleteTenant, deleteTenant,
    );
    yield takeEvery<ReturnType<typeof createTenantAct>>(
        TenantActions.CreateTenant, createTenant,
    );
    yield takeEvery<ReturnType<typeof createTenantWithServerUpdateAct>>(
        TenantActions.CreateTenantWithServerUpdate, createTenantWithServerUpdate,
    );
}

export default tenantSaga;
