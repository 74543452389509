import { Action } from 'store/actions';

import { saveServerStateEventsList, ServerStateEventsActions } from 'Actions/serverStateEvents';

import { ServerStateEventStore, serverStateEventInitialState as initialState } from '../initialStates';

const reducer = (
    state: ServerStateEventStore | null = initialState,
    action: Action,
) => {
    switch (action.type as ServerStateEventsActions) {
        case ServerStateEventsActions.SaveServerStateEvents: {
            const { payload } = action as ReturnType<typeof saveServerStateEventsList>;
            const sb = state === null ? [] : [...state];
            sb.push(...payload);
            return sb;
        }
        case ServerStateEventsActions.ClearStore: {
            return null;
        }
        default:
            return state;
    }
};

export default reducer;
