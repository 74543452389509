import { PublicKeyType } from './PublicKeyType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPublicKey {
    content: string;
    id: number;
    install_default: boolean;
    owner_user_id: number;
    owner_user_name: string;
    shared: boolean;
    tenant_id: number;
    time_added: string;
    time_added_millis: number;
    title: string;
    type: PublicKeyType;
}

export default class PublicKey {
    readonly _content: string;

    /**
     * Description: Pubic key content
     * Example: MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDFdSgx73/E4FcMNn3ySn6Ublygjf5jgKw1TYAXq8Xwl7umobO3TM3knOSvlI7vnueR/6KP6OQUsLFLtH9vRSArQy+wnSSruU/AOTMmuW6Xj4fsCY9flYjXztDO0kD2j25K4+Rz7Jkfl1foozfrfz1hpeLj0LIwmjJOHtn1P3TfMwIDAQAB
     */
    get content(): string {
        return this._content;
    }

    static contentValidate(content: string): boolean {
        return typeof content === 'string' && !!content.trim();
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 9284
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _install_default: boolean;

    /**
     * Description: Should be installed by default to new servers
     * Example: true
     */
    get installDefault(): boolean {
        return this._install_default;
    }

    static installDefaultValidate(installDefault: boolean): boolean {
        return typeof installDefault === 'boolean';
    }

    readonly _owner_user_id: number;

    /**
     * Description: Owner user id
     * Example: 28272
     */
    get ownerUserId(): number {
        return this._owner_user_id;
    }

    static ownerUserIdValidate(ownerUserId: number): boolean {
        return typeof ownerUserId === 'number';
    }

    readonly _owner_user_name: string;

    /**
     * Description: Owner user name
     * Example: v.emelyanov@gmail.com
     */
    get ownerUserName(): string {
        return this._owner_user_name;
    }

    static ownerUserNameValidate(ownerUserName: string): boolean {
        return typeof ownerUserName === 'string' && !!ownerUserName.trim();
    }

    readonly _shared: boolean;

    /** */
    get shared(): boolean {
        return this._shared;
    }

    static sharedValidate(shared: boolean): boolean {
        return typeof shared === 'boolean';
    }

    readonly _tenant_id: number;

    /**
     * Description: Tenant
     * Example: 12958
     */
    get tenantId(): number {
        return this._tenant_id;
    }

    static tenantIdValidate(tenantId: number): boolean {
        return typeof tenantId === 'number';
    }

    readonly _time_added: string;

    /** */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Time added, UTC millis
     * Example: 1593064584178
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    readonly _title: string;

    /**
     * Description: Title
     * Example: Personal key
     */
    get title(): string {
        return this._title;
    }

    static titleValidate(title: string): boolean {
        return typeof title === 'string' && !!title.trim();
    }

    readonly _type: PublicKeyType;

    get type(): PublicKeyType {
        return this._type;
    }

    static typeValidate(type: PublicKeyType): boolean {
        return Object.keys(PublicKeyType).includes(type);
    }

    constructor(props: IPublicKey) {
        this._content = props.content.trim();
        this._id = props.id;
        this._install_default = props.install_default;
        this._owner_user_id = props.owner_user_id;
        this._owner_user_name = props.owner_user_name.trim();
        this._shared = props.shared;
        this._tenant_id = props.tenant_id;
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
        this._title = props.title.trim();
        this._type = props.type;
    }

    serialize(): IPublicKey {
        const data: IPublicKey = {
            content: this._content,
            id: this._id,
            install_default: this._install_default,
            owner_user_id: this._owner_user_id,
            owner_user_name: this._owner_user_name,
            shared: this._shared,
            tenant_id: this._tenant_id,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
            title: this._title,
            type: this._type,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            title: typeof this._title === 'string' && !!this._title.trim(),
            content: typeof this._content === 'string' && !!this._content.trim(),
            shared: typeof this._shared === 'boolean',
            tenant_id: typeof this._tenant_id === 'number',
            owner_user_id: typeof this._owner_user_id === 'number',
            owner_user_name: typeof this._owner_user_name === 'string' && !!this._owner_user_name.trim(),
            install_default: typeof this._install_default === 'boolean',
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPublicKey): PublicKey {
        return new PublicKey(props);
    }

    readonly keys: { [key: string]: string } = {
        content: 'content',
        id: 'id',
        installDefault: 'install_default',
        ownerUserId: 'owner_user_id',
        ownerUserName: 'owner_user_name',
        shared: 'shared',
        tenantId: 'tenant_id',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        title: 'title',
        type: 'type',
        }
;

    mergeDeepWith(props: Partial<PublicKey>): PublicKey {
        const updateData: Partial<IPublicKey> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PublicKey) => {
            const updateKey = this.keys[key] as keyof IPublicKey;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPublicKey, keyof IPublicKey>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PublicKey({ ...this.serialize(), ...updateData });
    }
}
