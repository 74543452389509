import React, { FC } from 'react';
import { Button } from 'antd';
import cn from 'classnames';

import { useIntl } from 'Common';
import theme from 'Lib/theme';

import s from '../NewTrigger.module.pcss';

const Submit: FC<{update: boolean}> = ({ update }) => {
    const intl = useIntl();

    return (
        <Button
            block
            htmlType="submit"
            className={cn(
                theme.button.button,
                theme.button.primary,
                theme.button.desktop_big,
                s.submit,
            )}
        >
            {update ? intl.getMessage('save') : intl.getMessage('triggers_create')}
        </Button>
    );
};

export default Submit;
