import { EventNotificationType } from './EventNotificationType';
import { NotificationType } from './NotificationType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface INotificationDeliverySettings {
    delivery_email: boolean;
    delivery_sms: boolean;
    event_type: EventNotificationType;
    notification_type?: NotificationType;
    rule_id?: number;
}

export default class NotificationDeliverySettings {
    readonly _delivery_email: boolean;

    /**
     * Description: Is email delivery enabled
     * Example: true
     */
    get deliveryEmail(): boolean {
        return this._delivery_email;
    }

    static deliveryEmailValidate(deliveryEmail: boolean): boolean {
        return typeof deliveryEmail === 'boolean';
    }

    readonly _delivery_sms: boolean;

    /**
     * Description: Is sms delivery enabled
     * Example: true
     */
    get deliverySms(): boolean {
        return this._delivery_sms;
    }

    static deliverySmsValidate(deliverySms: boolean): boolean {
        return typeof deliverySms === 'boolean';
    }

    readonly _event_type: EventNotificationType;

    get eventType(): EventNotificationType {
        return this._event_type;
    }

    static eventTypeValidate(eventType: EventNotificationType): boolean {
        return Object.keys(EventNotificationType).includes(eventType);
    }

    readonly _notification_type: NotificationType | undefined;

    get notificationType(): NotificationType | undefined {
        return this._notification_type;
    }

    readonly _rule_id: number | undefined;

    /**
     * Description: Trigger rule id. Only for TRIGGER event type
     * Example: 3456
     */
    get ruleId(): number | undefined {
        return this._rule_id;
    }

    constructor(props: INotificationDeliverySettings) {
        this._delivery_email = props.delivery_email;
        this._delivery_sms = props.delivery_sms;
        this._event_type = props.event_type;
        if (props.notification_type) {
            this._notification_type = props.notification_type;
        }
        if (typeof props.rule_id === 'number') {
            this._rule_id = props.rule_id;
        }
    }

    serialize(): INotificationDeliverySettings {
        const data: INotificationDeliverySettings = {
            delivery_email: this._delivery_email,
            delivery_sms: this._delivery_sms,
            event_type: this._event_type,
        };
        if (typeof this._notification_type !== 'undefined') {
            data.notification_type = this._notification_type;
        }
        if (typeof this._rule_id !== 'undefined') {
            data.rule_id = this._rule_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            rule_id: !this._rule_id ? true : typeof this._rule_id === 'number',
            delivery_email: typeof this._delivery_email === 'boolean',
            delivery_sms: typeof this._delivery_sms === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: INotificationDeliverySettings): NotificationDeliverySettings {
        return new NotificationDeliverySettings(props);
    }

    readonly keys: { [key: string]: string } = {
        deliveryEmail: 'delivery_email',
        deliverySms: 'delivery_sms',
        eventType: 'event_type',
        notificationType: 'notification_type',
        ruleId: 'rule_id',
        }
;

    mergeDeepWith(props: Partial<NotificationDeliverySettings>): NotificationDeliverySettings {
        const updateData: Partial<INotificationDeliverySettings> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof NotificationDeliverySettings) => {
            const updateKey = this.keys[key] as keyof INotificationDeliverySettings;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<INotificationDeliverySettings, keyof INotificationDeliverySettings>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new NotificationDeliverySettings({ ...this.serialize(), ...updateData });
    }
}
