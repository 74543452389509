import React, { FC, useContext, useEffect, useState } from 'react';
import { countServerNumber } from 'Lib/helpers/utils';

import { MapResult, ServerListContext } from '../factories';
import ServerListElement from './ServerListElement';

interface ListRenderProps {
    servers: MapResult[];
    height: number;
}

const ListRender: FC<ListRenderProps> = React.memo(({
    servers,
    height,
}) => {
    const { serverView } = useContext(ServerListContext);
    const [renderCount, setRenderCount] = useState(countServerNumber(serverView, height));
    const [currentServerView, setCurrentServerView] = useState(serverView);

    useEffect(() => {
        setRenderCount(countServerNumber(serverView, height));
        setCurrentServerView(serverView);
    }, [serverView]);

    useEffect(() => {
        let canUpdate = true;
        if (renderCount !== servers.length) {
            setTimeout(() => {
                const newCount = countServerNumber(serverView, height) + renderCount;
                if (canUpdate) {
                    setRenderCount(newCount < servers.length ? newCount : servers.length);
                }
            }, 0);
        }
        return () => {
            canUpdate = false;
        };
    }, [renderCount, servers.length]);

    if (currentServerView !== serverView) {
        return null;
    }

    return (
        <>
            {servers
                .slice(0, Math.min(servers.length, renderCount))
                .map(({ server, filters }) => (
                    <ServerListElement
                        key={server.id}
                        server={server}
                        filters={filters}
                    />
                ))}
        </>
    );
});

export default ListRender;
