import { AffiliatePayoutType } from './AffiliatePayoutType';
import ComparativeIncome, { IComparativeIncome } from './ComparativeIncome';
import ResellerDailyStats, { IResellerDailyStats } from './ResellerDailyStats';
import ResellerLimits, { IResellerLimits } from './ResellerLimits';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerDetails {
    balance: number;
    comparative_income: IComparativeIncome;
    daily_stats: IResellerDailyStats[];
    limits: IResellerLimits;
    monthly_income_forecast: number;
    overall_income: number;
    payment_type?: AffiliatePayoutType;
    percent: number;
}

export default class ResellerDetails {
    readonly _balance: number;

    /**
     * Description: Reseller's balance
     * Example: 7370.5
     */
    get balance(): number {
        return this._balance;
    }

    static balanceValidate(balance: number): boolean {
        return typeof balance === 'number';
    }

    readonly _comparative_income: ComparativeIncome;

    get comparativeIncome(): ComparativeIncome {
        return this._comparative_income;
    }

    readonly _daily_stats: ResellerDailyStats[];

    /** */
    get dailyStats(): ResellerDailyStats[] {
        return this._daily_stats;
    }

    readonly _limits: ResellerLimits;

    get limits(): ResellerLimits {
        return this._limits;
    }

    readonly _monthly_income_forecast: number;

    /**
     * Description: Reseller's monthly revenue forecast
     * Example: 88.5
     */
    get monthlyIncomeForecast(): number {
        return this._monthly_income_forecast;
    }

    static monthlyIncomeForecastValidate(monthlyIncomeForecast: number): boolean {
        return typeof monthlyIncomeForecast === 'number';
    }

    readonly _overall_income: number;

    /**
     * Description: All time income for reseller, RUB
     * Example: 214540.2
     */
    get overallIncome(): number {
        return this._overall_income;
    }

    static overallIncomeValidate(overallIncome: number): boolean {
        return typeof overallIncome === 'number';
    }

    readonly _payment_type: AffiliatePayoutType | undefined;

    get paymentType(): AffiliatePayoutType | undefined {
        return this._payment_type;
    }

    readonly _percent: number;

    /**
     * Description: Reseller's referral percent
     * Example: 3
     */
    get percent(): number {
        return this._percent;
    }

    static percentValidate(percent: number): boolean {
        return typeof percent === 'number';
    }

    constructor(props: IResellerDetails) {
        this._balance = props.balance;
        this._comparative_income = new ComparativeIncome(props.comparative_income);
        this._daily_stats = props.daily_stats.map((p) => new ResellerDailyStats(p));
        this._limits = new ResellerLimits(props.limits);
        this._monthly_income_forecast = props.monthly_income_forecast;
        this._overall_income = props.overall_income;
        if (props.payment_type) {
            this._payment_type = props.payment_type;
        }
        this._percent = props.percent;
    }

    serialize(): IResellerDetails {
        const data: IResellerDetails = {
            balance: this._balance,
            comparative_income: this._comparative_income.serialize(),
            daily_stats: this._daily_stats.map((p) => p.serialize()),
            limits: this._limits.serialize(),
            monthly_income_forecast: this._monthly_income_forecast,
            overall_income: this._overall_income,
            percent: this._percent,
        };
        if (typeof this._payment_type !== 'undefined') {
            data.payment_type = this._payment_type;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            balance: typeof this._balance === 'number',
            monthly_income_forecast: typeof this._monthly_income_forecast === 'number',
            overall_income: typeof this._overall_income === 'number',
            comparative_income: this._comparative_income.validate().length === 0,
            percent: typeof this._percent === 'number',
            limits: this._limits.validate().length === 0,
            daily_stats: this._daily_stats.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerDetails): ResellerDetails {
        return new ResellerDetails(props);
    }

    readonly keys: { [key: string]: string } = {
        balance: 'balance',
        comparativeIncome: 'comparative_income',
        dailyStats: 'daily_stats',
        limits: 'limits',
        monthlyIncomeForecast: 'monthly_income_forecast',
        overallIncome: 'overall_income',
        paymentType: 'payment_type',
        percent: 'percent',
        }
;

    mergeDeepWith(props: Partial<ResellerDetails>): ResellerDetails {
        const updateData: Partial<IResellerDetails> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerDetails) => {
            const updateKey = this.keys[key] as keyof IResellerDetails;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerDetails, keyof IResellerDetails>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerDetails({ ...this.serialize(), ...updateData });
    }
}
