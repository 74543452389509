import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import cn from 'classnames';

import { useIntl, Input } from 'Common';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import Trigger from 'Entities/Trigger';
import NotificationRecipient from 'Entities/NotificationRecipient';
import { ITriggerCreate } from 'Entities/TriggerCreate';
import NewTrigger, { NewTriggerFormKeys } from 'Lib/helpers/newTrigger';

import TriggerNotifications from './TriggerNotifications';
import Submit from './Submit';
import s from '../NewTrigger.module.pcss';

interface HttpUrlAvailabilityFormProps {
    trigger?: Trigger;
    type: TriggerRuleType;
    recipients: NotificationRecipient[];
    onSubmit: (
        keys: NewTriggerFormKeys[],
    ) => (values: ITriggerCreate, helpers: FormikHelpers<ITriggerCreate>) => Promise<void>;
}

const HttpUrlAvailabilityForm: FC<HttpUrlAvailabilityFormProps> = (
    { type, recipients, onSubmit, trigger },
) => {
    const intl = useIntl();

    return (
        <Formik
            initialValues={NewTrigger.getInitialValues(type, recipients, trigger)}
            onSubmit={onSubmit(['value'])}
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                errors,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={cn(s.group, s.group_input)}>
                        <Input
                            cloud
                            autoFocus
                            size="large"
                            placeholder={intl.getMessage('triggers_name')}
                            name="name"
                            type="text"
                            validate={Trigger.nameValidate}
                            error={!!errors.name}
                            value={values.name}
                            onChange={(e) => setFieldValue('name', e)}
                        />
                    </div>
                    <div className={s.group}>
                        <div className={s.text}>
                            {intl.getMessage('trigger_form_http')}
                            <Input
                                rightDesktop
                                name="period_minutes"
                                type="number"
                                size="small"
                                className={s.input}
                                suffix={intl.getMessage('minute_short')}
                                value={values.period_minutes!}
                                error={!!errors.period_minutes}
                                validate={(e) => e !== '0'}
                                onChange={(e) => setFieldValue('period_minutes', e.slice(0, 2))}
                                onBlur={handleBlur}
                            />
                        </div>
                        <div className={cn(s.group, s.group_inner)}>
                            <Input
                                cloud
                                name="url"
                                type="text"
                                size="large"
                                error={!!errors.url}
                                placeholder={intl.getMessage('triggers_url')}
                                value={values.url!}
                                validate={(e) => !!e}
                                onChange={(e) => setFieldValue('url', e)}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>

                    <TriggerNotifications
                        value={values.recipient_settings}
                        handleChange={handleChange}
                        recipients={recipients}
                        setFieldValue={setFieldValue}
                    />

                    <Submit update={!!trigger} />
                </form>
            )}
        </Formik>
    );
};

export default HttpUrlAvailabilityForm;
