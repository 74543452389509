import React, { FC } from 'react';

import { useIntl, DeleteModalLayout } from 'Common';

interface DeleteDnsZoneProps {
    visible: boolean;
    host: string;
    handleClose: () => void;
    handleDelete: () => void;
}

const DeleteDnsZone: FC<DeleteDnsZoneProps> = ({ visible, host, handleClose, handleDelete }) => {
    const intl = useIntl();

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('dns_zone_delete')}
            handleDelete={handleDelete}
            handleClose={handleClose}
        >
            <div className="modal__desc">
                {intl.getMessage('dns_zone_delete_confirm', { host })}
            </div>
        </DeleteModalLayout>
    );
};

export default DeleteDnsZone;
