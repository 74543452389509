import React, { FC, useEffect } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import adminApi from 'Apis/adminClientAuthorization';
import { useIntl, Link, FormPageLayout, Button, Input } from 'Common';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import { errorChecker } from 'Lib/helpers/utils';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';

type FormValues = { username: string };

interface AdminStoreProps {
    profile: Store['profile']['info'];
}

const AdminLogin: FC<AdminStoreProps> = ({ profile }) => {
    const intl = useIntl();
    const history = useHistory();

    useEffect(() => {
        if (profile && !profile.clientLoginAvailable) {
            history.replace(linkPathBuilder(intl.currentLocale, RoutePath.Dashboard));
        }
    }, [profile]);

    const onSubmit = async (values: FormValues, helpers?: FormikHelpers<FormValues>) => {
        const response = await adminApi.adminClientLogin(values.username);
        const { result, error } = errorChecker<{ token: string; cookie: string | null }>(response);
        if (error && helpers) {
            helpers.setSubmitting(false);
            helpers.setFieldError('username', EMPTY_FIELD_ERROR);
        }
        if (result) {
            AppConfigStorage.setAdminToken(AUTH_TOKEN);
            AppConfigStorage.setAuthToken(result.token);
            window.location.href = '/';
        }
    };

    useEffect(() => {
        const query = window.location.search.replace('?', '');
        const search = qs.parse(query);
        if (search.username && profile?.clientLoginAvailable) {
            onSubmit({ username: search.username as string });
        }
    }, [profile]);

    if (!profile) {
        return null;
    }

    return (
        <FormPageLayout
            title={intl.getMessage('admin_login')}
            type="narrow"
            back={RoutePath.ProjectsList}
        >
            <Formik
                initialValues={{ username: '' }}
                onSubmit={onSubmit}
            >
                {({
                    handleSubmit,
                    setFieldValue,
                    values,
                    errors,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Input
                            type="text"
                            name="name"
                            size="large"
                            placeholder={intl.getMessage('clients_client')}
                            value={values.username}
                            onChange={(e) => setFieldValue('username', e)}
                            validate={(e) => !!e}
                            error={!!errors.username}
                            autoFocus
                        />
                        <div>
                            <Button
                                inGroup
                                id="admin_login"
                                htmlType="submit"
                                type="primary"
                                size="big"
                                disabled={isSubmitting}
                            >
                                {intl.getMessage('login_button')}
                            </Button>

                            <Link to={RoutePath.Dashboard}>
                                <Button
                                    type="link"
                                    size="big"
                                >
                                    {intl.getMessage('cancel')}
                                </Button>
                            </Link>
                        </div>
                    </form>
                )}
            </Formik>
        </FormPageLayout>
    );
};

const selectProfile = (store: Store) => store.profile.info;
const selector = createSelector([
    selectProfile,
], (profile) => {
    return {
        profile,
    };
});

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);
export default connect(mapStoreToProps)(AdminLogin);
