// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISnapshotDownloadLink {
    download_link: string;
    snapshot_id: number;
}

export default class SnapshotDownloadLink {
    readonly _download_link: string;

    /**
     * Description: Download link
     * Example: https://download5.serveroid.com/?p=2345yhgde5678&b=76rfbj8765
     */
    get downloadLink(): string {
        return this._download_link;
    }

    static downloadLinkValidate(downloadLink: string): boolean {
        return typeof downloadLink === 'string' && !!downloadLink.trim();
    }

    readonly _snapshot_id: number;

    /**
     * Description: Snapshot id
     * Example: 234567
     */
    get snapshotId(): number {
        return this._snapshot_id;
    }

    static snapshotIdValidate(snapshotId: number): boolean {
        return typeof snapshotId === 'number';
    }

    constructor(props: ISnapshotDownloadLink) {
        this._download_link = props.download_link.trim();
        this._snapshot_id = props.snapshot_id;
    }

    serialize(): ISnapshotDownloadLink {
        const data: ISnapshotDownloadLink = {
            download_link: this._download_link,
            snapshot_id: this._snapshot_id,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            snapshot_id: typeof this._snapshot_id === 'number',
            download_link: typeof this._download_link === 'string' && !!this._download_link.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISnapshotDownloadLink): SnapshotDownloadLink {
        return new SnapshotDownloadLink(props);
    }

    readonly keys: { [key: string]: string } = {
        downloadLink: 'download_link',
        snapshotId: 'snapshot_id',
        }
;

    mergeDeepWith(props: Partial<SnapshotDownloadLink>): SnapshotDownloadLink {
        const updateData: Partial<ISnapshotDownloadLink> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SnapshotDownloadLink) => {
            const updateKey = this.keys[key] as keyof ISnapshotDownloadLink;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISnapshotDownloadLink, keyof ISnapshotDownloadLink>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SnapshotDownloadLink({ ...this.serialize(), ...updateData });
    }
}
