import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useIntl, Input } from 'Common';
import { ClientType } from 'Entities/ClientType';
import CreateSoleTrader, { ICreateSoleTrader } from 'Entities/CreateSoleTrader';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import { checkVat } from './utils';
import { FormInnerProps } from '.';

interface FormValues extends ICreateSoleTrader {
    vatChecked: boolean;
}

const SoleTraderForm: FC<FormInnerProps> = ({ handleSubmit: handle, Submit }) => {
    const intl = useIntl();
    const initial: FormValues = {
        sole_trademark: '',
        sole_legal_address: '',
        sole_post_address: '',
        sole_accounting_email: '',
        sole_inn: '',
        sole_ogrnip: '',
        vatChecked: false,
    };

    const onSubmit = (
        values: FormValues,
        helpers: FormikHelpers<FormValues>,
    ) => handle({ sole_trader: values }, helpers);

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}
        >
            {({
                setValues,
                setFieldValue,
                setFieldError,
                handleSubmit,
                values,
                errors,
                isSubmitting,
                setSubmitting,
            }) => {
                const handleCheck = async () => {
                    setSubmitting(true);
                    const { result, error } = await checkVat(
                        values.sole_inn,
                        ClientType.SOLE_TRADER,
                    );
                    if (error) {
                        setFieldError('sole_inn', EMPTY_FIELD_ERROR);
                    } else {
                        setValues({ ...values, ...result!.sole_trader_details, vatChecked: true });
                    }
                    setSubmitting(false);
                };
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Input
                            autoFocus={!values.vatChecked}
                            error={!!errors.sole_inn}
                            errorMessage={errors.sole_inn}
                            label={intl.getMessage('credentials_vat')}
                            name="sole_inn"
                            onChange={(e) => setValues(
                                { ...values, sole_inn: e, vatChecked: false },
                            )}
                            placeholder={intl.getMessage('onboarding_sole_trader_vat_placeholder')}
                            size="middle"
                            type="text"
                            pattern="[0-9]{12}"
                            validate={(e) => (
                                CreateSoleTrader.soleInnValidate(e) && e.length === 12
                            )}
                            value={values.sole_inn}
                        />

                        {values.vatChecked ? (
                            <>
                                <div className={theme.modal.subtitle}>
                                    {intl.getMessage('credentials_sole_trader_info')}
                                </div>

                                <Input
                                    disabled
                                    error={!!errors.sole_trademark}
                                    label={intl.getMessage('credentials_company_name')}
                                    name="sole_trademark"
                                    placeholder={intl.getMessage('credentials_company_name')}
                                    size="middle"
                                    type="text"
                                    validate={CreateSoleTrader.soleInnValidate}
                                    value={values.sole_trademark}
                                />
                                <Input
                                    autoFocus={values.vatChecked}
                                    error={!!errors.sole_ogrnip}
                                    label={intl.getMessage('credentials_psrnsp')}
                                    name="sole_ogrnip"
                                    onChange={(e) => setFieldValue('sole_ogrnip', e)}
                                    placeholder={intl.getMessage('credentials_psrnsp')}
                                    size="middle"
                                    type="text"
                                    validate={CreateSoleTrader.soleOgrnipValidate}
                                    value={values.sole_ogrnip}
                                />
                                <Input
                                    error={!!errors.sole_legal_address}
                                    label={intl.getMessage('credentials_legal_address')}
                                    name="sole_legal_address"
                                    onChange={(e) => setFieldValue('sole_legal_address', e)}
                                    placeholder={intl.getMessage('credentials_legal_address')}
                                    size="middle"
                                    type="text"
                                    validate={CreateSoleTrader.soleLegalAddressValidate}
                                    value={values.sole_legal_address}
                                />
                                <Input
                                    error={!!errors.sole_post_address}
                                    label={intl.getMessage('credentials_correspondence_address')}
                                    name="sole_post_address"
                                    onChange={(e) => setFieldValue('sole_post_address', e)}
                                    placeholder={intl.getMessage('credentials_correspondence_address')}
                                    size="middle"
                                    type="text"
                                    validate={CreateSoleTrader.solePostAddressValidate}
                                    value={values.sole_post_address}
                                />
                                <Input
                                    optional
                                    error={!!errors.sole_accounting_email}
                                    label={intl.getMessage('credentials_accounting_correspondence_email')}
                                    name="sole_accounting_email"
                                    onChange={(e) => setFieldValue('sole_accounting_email', e)}
                                    placeholder={intl.getMessage('credentials_accounting_correspondence_email')}
                                    size="middle"
                                    type="text"
                                    value={values.sole_accounting_email}
                                />
                                <Submit handleSubmit={handleSubmit} disabled={isSubmitting} />
                            </>
                        ) : (
                            <Submit
                                handleSubmit={handleCheck}
                                disabled={!values.sole_inn || isSubmitting}
                                title={intl.getMessage('onboarding_check')}
                                id="fetch-payer-details"
                            />
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

export default SoleTraderForm;
