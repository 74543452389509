import { SourceBalance } from './SourceBalance';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerClientTransfer {
    amount: number;
    source_balance: SourceBalance;
}

export default class ResellerClientTransfer {
    readonly _amount: number;

    /**
     * Description: Transfer amount, RUB
     * Example: 50
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    readonly _source_balance: SourceBalance;

    get sourceBalance(): SourceBalance {
        return this._source_balance;
    }

    static sourceBalanceValidate(sourceBalance: SourceBalance): boolean {
        return Object.keys(SourceBalance).includes(sourceBalance);
    }

    constructor(props: IResellerClientTransfer) {
        this._amount = props.amount;
        this._source_balance = props.source_balance;
    }

    serialize(): IResellerClientTransfer {
        const data: IResellerClientTransfer = {
            amount: this._amount,
            source_balance: this._source_balance,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            amount: typeof this._amount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerClientTransfer): ResellerClientTransfer {
        return new ResellerClientTransfer(props);
    }

    readonly keys: { [key: string]: string } = {
        amount: 'amount',
        sourceBalance: 'source_balance',
        }
;

    mergeDeepWith(props: Partial<ResellerClientTransfer>): ResellerClientTransfer {
        const updateData: Partial<IResellerClientTransfer> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerClientTransfer) => {
            const updateKey = this.keys[key] as keyof IResellerClientTransfer;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerClientTransfer, keyof IResellerClientTransfer>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerClientTransfer({ ...this.serialize(), ...updateData });
    }
}
