// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerBackup {
    download_cost_forecast: number;
    id: string;
    size_gib: number;
    time_added: string;
    time_added_millis: number;
}

export default class ServerBackup {
    readonly _download_cost_forecast: number;

    /**
     * Description: Forecast for server backup download cost
     * Example: 580
     */
    get downloadCostForecast(): number {
        return this._download_cost_forecast;
    }

    static downloadCostForecastValidate(downloadCostForecast: number): boolean {
        return typeof downloadCostForecast === 'number';
    }

    readonly _id: string;

    /**
     * Description: Unique identifier
     * Example: ZGlmZi1iYWNrdXBzbmFwMjAyMDA0MTIwMDAwMTQtYmFja3Vwc25hcDIwMjAwNDE1MDAwMDEyLmx6NA==
     */
    get id(): string {
        return this._id;
    }

    static idValidate(id: string): boolean {
        return typeof id === 'string' && !!id.trim();
    }

    readonly _size_gib: number;

    /**
     * Description: Disk size in GiB at the backup time
     * Example: 16
     */
    get sizeGib(): number {
        return this._size_gib;
    }

    static sizeGibValidate(sizeGib: number): boolean {
        return typeof sizeGib === 'number';
    }

    readonly _time_added: string;

    /** */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Backup time added, UTC millis
     * Example: 1589284800000
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    constructor(props: IServerBackup) {
        this._download_cost_forecast = props.download_cost_forecast;
        this._id = props.id.trim();
        this._size_gib = props.size_gib;
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
    }

    serialize(): IServerBackup {
        const data: IServerBackup = {
            download_cost_forecast: this._download_cost_forecast,
            id: this._id,
            size_gib: this._size_gib,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'string' && !!this._id.trim(),
            size_gib: typeof this._size_gib === 'number',
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
            download_cost_forecast: typeof this._download_cost_forecast === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerBackup): ServerBackup {
        return new ServerBackup(props);
    }

    readonly keys: { [key: string]: string } = {
        downloadCostForecast: 'download_cost_forecast',
        id: 'id',
        sizeGib: 'size_gib',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        }
;

    mergeDeepWith(props: Partial<ServerBackup>): ServerBackup {
        const updateData: Partial<IServerBackup> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerBackup) => {
            const updateKey = this.keys[key] as keyof IServerBackup;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerBackup, keyof IServerBackup>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerBackup({ ...this.serialize(), ...updateData });
    }
}
