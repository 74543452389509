import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import BatchSecurityCodeSendRequest, { IBatchSecurityCodeSendRequest } from 'Entities/BatchSecurityCodeSendRequest';
import SecurityActionSetting, { ISecurityActionSetting } from 'Entities/SecurityActionSetting';
import SecurityActionSettings, { ISecurityActionSettings } from 'Entities/SecurityActionSettings';
import SecurityActionSettingsUpdate, { ISecurityActionSettingsUpdate } from 'Entities/SecurityActionSettingsUpdate';
import SecurityActionSettingsUpdateCodeSendRequest, { ISecurityActionSettingsUpdateCodeSendRequest } from 'Entities/SecurityActionSettingsUpdateCodeSendRequest';
import { SecurityActionType } from 'Entities/SecurityActionType';
import SecurityCodeSendRequest, { ISecurityCodeSendRequest } from 'Entities/SecurityCodeSendRequest';
import SecurityCodeSendResponse, { ISecurityCodeSendResponse } from 'Entities/SecurityCodeSendResponse';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class SecurityActionsApi {
    static async getSetting(action_type: SecurityActionType, optionalToken?: string): Promise<ISecurityActionSetting | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/security/settings/${action_type}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listSettings(optionalToken?: string): Promise<ISecurityActionSettings | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/security/settings`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async saveSettings(securityactionsettingsupdate: ISecurityActionSettingsUpdate, optionalToken?: string): Promise<ISecurityActionSettings | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const securityactionsettingsupdateValid = new SecurityActionSettingsUpdate(securityactionsettingsupdate);
        haveError.push(...securityactionsettingsupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/security/settings`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(securityactionsettingsupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async sendBatchSecurityCode(action_type: SecurityActionType, batchsecuritycodesendrequest: IBatchSecurityCodeSendRequest, optionalToken?: string): Promise<ISecurityCodeSendResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const batchsecuritycodesendrequestValid = new BatchSecurityCodeSendRequest(batchsecuritycodesendrequest);
        haveError.push(...batchsecuritycodesendrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/security/${action_type}/send_batch`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(batchsecuritycodesendrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async sendSecurityCode(action_type: SecurityActionType, securitycodesendrequest: ISecurityCodeSendRequest, optionalToken?: string): Promise<ISecurityCodeSendResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const securitycodesendrequestValid = new SecurityCodeSendRequest(securitycodesendrequest);
        haveError.push(...securitycodesendrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/security/${action_type}/send`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(securitycodesendrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async sendSecurityCodeToUpdateSettings(securityactionsettingsupdatecodesendrequest: ISecurityActionSettingsUpdateCodeSendRequest, optionalToken?: string): Promise<ISecurityCodeSendResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const securityactionsettingsupdatecodesendrequestValid = new SecurityActionSettingsUpdateCodeSendRequest(securityactionsettingsupdatecodesendrequest);
        haveError.push(...securityactionsettingsupdatecodesendrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/security/settings/send`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(securityactionsettingsupdatecodesendrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
