import React, { FC } from 'react';
import cn from 'classnames';

import { Icon } from 'Common';
import theme from 'Lib/theme';

interface LabelProps {
    title: string;
    discount: number;
    trial?: boolean;
}

const Label: FC<LabelProps> = ({ title, discount, trial }) => {
    return (
        <div className={cn(theme.score.item, { [theme.score.item_hoverable]: trial })}>
            <Icon
                icon="discount"
                className={cn(
                    theme.score.icon,
                    theme.color.morning,
                )}
            />
            <div className={theme.score.info}>
                <div className={theme.score.label}>
                    {title}
                </div>
                <div className={cn(theme.score.value, { [theme.score.value_muted]: trial })}>
                    {discount}%
                </div>
            </div>
        </div>
    );
};

export default Label;
