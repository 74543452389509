import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link, DashboardHeader } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

const Header: FC = () => {
    const intl = useIntl();

    return (
        <DashboardHeader
            navigation={(
                <>
                    <Link
                        to={RoutePath.Profile}
                        className={theme.header.link}
                    >
                        {intl.getMessage('menu_profile')}
                    </Link>
                    <Link
                        to={RoutePath.ProfileSettings}
                        className={theme.header.link}
                    >
                        {intl.getMessage('profile_security')}
                    </Link>
                    <div className={cn(theme.header.link, theme.header.active)}>
                        {intl.getMessage('auth_history')}
                    </div>
                </>
            )}
        />
    );
};

export default Header;
