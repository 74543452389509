// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IUpdateNonResident {
    non_resident_name: string;
}

export default class UpdateNonResident {
    readonly _non_resident_name: string;

    /**
     * Description: Non resident corporate name
     * Example: Example Corp LLC
     */
    get nonResidentName(): string {
        return this._non_resident_name;
    }

    static nonResidentNameValidate(nonResidentName: string): boolean {
        return typeof nonResidentName === 'string' && !!nonResidentName.trim();
    }

    constructor(props: IUpdateNonResident) {
        this._non_resident_name = props.non_resident_name.trim();
    }

    serialize(): IUpdateNonResident {
        const data: IUpdateNonResident = {
            non_resident_name: this._non_resident_name,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            non_resident_name: typeof this._non_resident_name === 'string' && !!this._non_resident_name.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IUpdateNonResident): UpdateNonResident {
        return new UpdateNonResident(props);
    }

    readonly keys: { [key: string]: string } = {
        nonResidentName: 'non_resident_name',
        }
;

    mergeDeepWith(props: Partial<UpdateNonResident>): UpdateNonResident {
        const updateData: Partial<IUpdateNonResident> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof UpdateNonResident) => {
            const updateKey = this.keys[key] as keyof IUpdateNonResident;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IUpdateNonResident, keyof IUpdateNonResident>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new UpdateNonResident({ ...this.serialize(), ...updateData });
    }
}
