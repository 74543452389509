import React, { FC } from 'react';
import cn from 'classnames';
import theme from 'Lib/theme';

import s from './List.module.pcss';

interface ListProps {
    title?: string;
    info?: string;
    length?: number;
    lengthTitle?: string;
    dataAttrs?: Record<string, string>;
    options?: React.ReactNode;
    mainTitle?: boolean;
}

const ListLayout: FC<ListProps> = ({
    title, length, info, children, dataAttrs, lengthTitle, options, mainTitle,
}) => {
    return (
        <div className={s.list}>
            <div
                className={cn(
                    s.list__head,
                    { [s.list__head_main]: mainTitle },
                )}
            >
                {title && (
                    <div
                        className={cn(
                            s.list__title,
                            { [s.list__title_main]: mainTitle },
                        )}
                    >
                        <div>
                            <span className={theme.common.textOverflow}>
                                {title}
                            </span>
                            {typeof length === 'number' && length > 0 && (
                                <span className={cn(s['list__title-info'], theme.Badge.badge, theme.Badge.count)} {...dataAttrs}>
                                    {lengthTitle || length}
                                </span>
                            )}
                        </div>
                        {options && options}
                    </div>
                )}
                {info && (
                    <div className={s.list__info}>
                        {info}
                    </div>
                )}
            </div>
            <div className={s.list__in}>
                {children}
            </div>
        </div>
    );
};

export default ListLayout;
