import { AffiliatePayoutType } from './AffiliatePayoutType';
import PartnerLimits, { IPartnerLimits } from './PartnerLimits';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPartnerDetails {
    active_clients: number;
    all_time_income: number;
    balance: number;
    inactive_clients: number;
    is_solar_staff_registration_complete?: boolean;
    limits: IPartnerLimits;
    overall_clients: number;
    partner_link: string;
    payment_type?: AffiliatePayoutType;
    percent: number;
    referral_users: number[];
    test_clients: number;
    today_clients: number;
    total_paid: number;
}

export default class PartnerDetails {
    readonly _active_clients: number;

    /**
     * Description: Still active clients, registered for partner's referral
     * Example: 12
     */
    get activeClients(): number {
        return this._active_clients;
    }

    static activeClientsValidate(activeClients: number): boolean {
        return typeof activeClients === 'number';
    }

    readonly _all_time_income: number;

    /**
     * Description: Partner's income over all time, RUB
     * Example: 240000.8
     */
    get allTimeIncome(): number {
        return this._all_time_income;
    }

    static allTimeIncomeValidate(allTimeIncome: number): boolean {
        return typeof allTimeIncome === 'number';
    }

    readonly _balance: number;

    /**
     * Description: Partner's balance, RUB
     * Example: 120.8
     */
    get balance(): number {
        return this._balance;
    }

    static balanceValidate(balance: number): boolean {
        return typeof balance === 'number';
    }

    readonly _inactive_clients: number;

    /**
     * Description: Inactive clients (neither active, nor testing), registered for partner's referral
     * Example: 84
     */
    get inactiveClients(): number {
        return this._inactive_clients;
    }

    static inactiveClientsValidate(inactiveClients: number): boolean {
        return typeof inactiveClients === 'number';
    }

    readonly _is_solar_staff_registration_complete: boolean | undefined;

    /** */
    get isSolarStaffRegistrationComplete(): boolean | undefined {
        return this._is_solar_staff_registration_complete;
    }

    readonly _limits: PartnerLimits;

    get limits(): PartnerLimits {
        return this._limits;
    }

    readonly _overall_clients: number;

    /**
     * Description: Overall registrations for partner's referral
     * Example: 120
     */
    get overallClients(): number {
        return this._overall_clients;
    }

    static overallClientsValidate(overallClients: number): boolean {
        return typeof overallClients === 'number';
    }

    readonly _partner_link: string;

    /**
     * Description: Partner's link for new clients referral registration
     * Example: https://serveroid.com/?refid=46046
     */
    get partnerLink(): string {
        return this._partner_link;
    }

    static partnerLinkValidate(partnerLink: string): boolean {
        return typeof partnerLink === 'string' && !!partnerLink.trim();
    }

    readonly _payment_type: AffiliatePayoutType | undefined;

    get paymentType(): AffiliatePayoutType | undefined {
        return this._payment_type;
    }

    readonly _percent: number;

    /**
     * Description: Partner's referral percent
     * Example: 15
     */
    get percent(): number {
        return this._percent;
    }

    static percentValidate(percent: number): boolean {
        return typeof percent === 'number';
    }

    readonly _referral_users: number[];

    /** */
    get referralUsers(): number[] {
        return this._referral_users;
    }

    static referralUsersValidate(referralUsers: number[]): boolean {
        return referralUsers.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    readonly _test_clients: number;

    /**
     * Description: Test active clients, registered for partner's referral
     * Example: 24
     */
    get testClients(): number {
        return this._test_clients;
    }

    static testClientsValidate(testClients: number): boolean {
        return typeof testClients === 'number';
    }

    readonly _today_clients: number;

    /**
     * Description: Today's registrations for partner's referral
     * Example: 8
     */
    get todayClients(): number {
        return this._today_clients;
    }

    static todayClientsValidate(todayClients: number): boolean {
        return typeof todayClients === 'number';
    }

    readonly _total_paid: number;

    /**
     * Description: Total amount paid for partner (all complete payments sum), RUB
     * Example: 1200
     */
    get totalPaid(): number {
        return this._total_paid;
    }

    static totalPaidValidate(totalPaid: number): boolean {
        return typeof totalPaid === 'number';
    }

    constructor(props: IPartnerDetails) {
        this._active_clients = props.active_clients;
        this._all_time_income = props.all_time_income;
        this._balance = props.balance;
        this._inactive_clients = props.inactive_clients;
        if (typeof props.is_solar_staff_registration_complete === 'boolean') {
            this._is_solar_staff_registration_complete = props.is_solar_staff_registration_complete;
        }
        this._limits = new PartnerLimits(props.limits);
        this._overall_clients = props.overall_clients;
        this._partner_link = props.partner_link.trim();
        if (props.payment_type) {
            this._payment_type = props.payment_type;
        }
        this._percent = props.percent;
        this._referral_users = props.referral_users;
        this._test_clients = props.test_clients;
        this._today_clients = props.today_clients;
        this._total_paid = props.total_paid;
    }

    serialize(): IPartnerDetails {
        const data: IPartnerDetails = {
            active_clients: this._active_clients,
            all_time_income: this._all_time_income,
            balance: this._balance,
            inactive_clients: this._inactive_clients,
            limits: this._limits.serialize(),
            overall_clients: this._overall_clients,
            partner_link: this._partner_link,
            percent: this._percent,
            referral_users: this._referral_users,
            test_clients: this._test_clients,
            today_clients: this._today_clients,
            total_paid: this._total_paid,
        };
        if (typeof this._is_solar_staff_registration_complete !== 'undefined') {
            data.is_solar_staff_registration_complete = this._is_solar_staff_registration_complete;
        }
        if (typeof this._payment_type !== 'undefined') {
            data.payment_type = this._payment_type;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            balance: typeof this._balance === 'number',
            percent: typeof this._percent === 'number',
            all_time_income: typeof this._all_time_income === 'number',
            total_paid: typeof this._total_paid === 'number',
            partner_link: typeof this._partner_link === 'string' && !!this._partner_link.trim(),
            overall_clients: typeof this._overall_clients === 'number',
            today_clients: typeof this._today_clients === 'number',
            active_clients: typeof this._active_clients === 'number',
            inactive_clients: typeof this._inactive_clients === 'number',
            test_clients: typeof this._test_clients === 'number',
            limits: this._limits.validate().length === 0,
            referral_users: this._referral_users.reduce((result, p) => result && typeof p === 'number', true),
            is_solar_staff_registration_complete: !this._is_solar_staff_registration_complete ? true : typeof this._is_solar_staff_registration_complete === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPartnerDetails): PartnerDetails {
        return new PartnerDetails(props);
    }

    readonly keys: { [key: string]: string } = {
        activeClients: 'active_clients',
        allTimeIncome: 'all_time_income',
        balance: 'balance',
        inactiveClients: 'inactive_clients',
        isSolarStaffRegistrationComplete: 'is_solar_staff_registration_complete',
        limits: 'limits',
        overallClients: 'overall_clients',
        partnerLink: 'partner_link',
        paymentType: 'payment_type',
        percent: 'percent',
        referralUsers: 'referral_users',
        testClients: 'test_clients',
        todayClients: 'today_clients',
        totalPaid: 'total_paid',
        }
;

    mergeDeepWith(props: Partial<PartnerDetails>): PartnerDetails {
        const updateData: Partial<IPartnerDetails> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PartnerDetails) => {
            const updateKey = this.keys[key] as keyof IPartnerDetails;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPartnerDetails, keyof IPartnerDetails>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PartnerDetails({ ...this.serialize(), ...updateData });
    }
}
