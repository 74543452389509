// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum CreditCardType {
    VISA = 'VISA',
    MASTERCARD = 'MASTERCARD',
    UNIONPAY = 'UNIONPAY',
    DISCOVER = 'DISCOVER',
    MIR = 'MIR',
    AMEX = 'AMEX',
    JCB = 'JCB',
    DINERSCLUB = 'DINERSCLUB',
    MAESTRO = 'MAESTRO',
    OTHER = 'OTHER'
}
