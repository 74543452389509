import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { Icon, useIntl } from 'Common';
import theme from 'Lib/theme';
import { LinkParams, linkPathBuilder, RoutePath } from 'Lib/helpers/routes';

interface BackHeaderProps {
    link: RoutePath | (() => RoutePath);
    linkParams?: LinkParams;
    type?: string;
    title?: string;
    newServer?: boolean;
}

const BackHeader: FC<BackHeaderProps> = ({ link, linkParams, type, title, newServer }) => {
    const history = useHistory();
    const { currentLocale } = useIntl();

    const onBackClick = () => {
        if (history.action === 'PUSH') {
            history.goBack();
            return;
        }
        if (typeof link === 'function') {
            history.push(linkPathBuilder(currentLocale, link(), linkParams));
        } else {
            history.push(linkPathBuilder(currentLocale, link, linkParams));
        }
    };

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Escape': {
                    onBackClick();
                    break;
                }
                default:
                    break;
            }
        };
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, []);

    return (
        <div
            className={cn(
                theme.mainHeader.header,
                theme.mainHeader.header_back,
                { [theme.mainHeader.header_narrow]: type },
            )}
        >
            <Button
                size="large"
                className={cn(
                    theme.button.button,
                    theme.button.icon,
                    theme.button.back,
                    { [theme.mainHeader.backButton]: newServer },
                )}
                onClick={onBackClick}
                icon={(
                    <Icon icon="arrow" />
                )}
            />
            {title && (
                <div className={theme.mainHeader.backTitle}>
                    {title}
                </div>
            )}
        </div>
    );
};

export default BackHeader;
