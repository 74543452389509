import React, { FC } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { updatePayerInfo } from 'Actions/profile';
import { CommonModalLayout, Link, useIntl } from 'Common';
import { ClientType } from 'Entities/ClientType';
import UpdatePayerRequest, { IUpdatePayerRequest } from 'Entities/UpdatePayerRequest';
import PayerInfo from 'Entities/PayerInfo';
import { RoutePath } from 'Lib/helpers/routes';
import { TICKETS_SUBJECT } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import { IndividualForm, CorporationForm, SoleTraderForm, NonResidentForm } from './components';

interface EditCredentialsProps {
    payer: PayerInfo;
    visible: boolean;
    handleClose: () => void;
}

const EditCredentials: FC<EditCredentialsProps> = ({
    payer,
    visible,
    handleClose,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { clientType } = payer;

    const handleSubmit = (data: IUpdatePayerRequest, setErrors: (errors: string[]) => void) => {
        const req = new UpdatePayerRequest(data);
        const notValid = req.validate();
        if (notValid.includes('individual')) {
            setErrors(req.individual!.validate());
            return;
        }
        dispatch(updatePayerInfo([payer.payerId, data]));
        handleClose();
    };

    const getForm = () => {
        const component: Record<ClientType, JSX.Element> = {
            [ClientType.INDIVIDUAL]: (
                <IndividualForm
                    payer={payer}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            ),
            [ClientType.CORPORATION]: (
                <CorporationForm
                    payer={payer}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            ),
            [ClientType.SOLE_TRADER]: (
                <SoleTraderForm
                    payer={payer}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            ),
            [ClientType.NON_RESIDENT]: (
                <NonResidentForm
                    payer={payer}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                />
            ),
        };
        return component[clientType];
    };

    const support = (text: string) => (
        <Link
            to={RoutePath.CreateTicketWithSubject}
            props={{ subject: TICKETS_SUBJECT.EDIT_PROFILE }}
        >
            {text}
        </Link>
    );

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('credentials_edit')}
            handleClose={handleClose}
            width={600}
            noFooter
        >
            <div className={cn(theme.modal.desc, theme.modal.desc_black)}>
                {clientType === ClientType.NON_RESIDENT ? (
                    intl.getMessage('credentials_edit_not_availabel_desc')
                ) : (
                    intl.getMessage('credentials_edit_desc', { support })
                )}
            </div>
            {getForm()}
        </CommonModalLayout>
    );
};

export default EditCredentials;
