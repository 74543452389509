import React, { FC } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { activateCoupon } from 'Actions/account';
import { useIntl, notifySuccess, Input } from 'Common';
import CouponActivateRequest from 'Entities/CouponActivateRequest';
import AccountDetails from 'Entities/AccountDetails';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import Error from 'Entities/Error';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { RoutePath, linkPathBuilder } from 'Lib/helpers/routes';

import s from './Form.module.pcss';
import Actions from './Actions';

interface FormProps {
    details: AccountDetails;
    noLayout?: boolean;
}

const CouponForm: FC<FormProps> = ({ noLayout }) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();

    const initial = {
        coupon: '',
    };
    const onPay = (values: typeof initial, helpers: FormikHelpers<typeof initial>) => {
        const reqEnt = new CouponActivateRequest(values);
        if (reqEnt.validate().length > 0) {
            helpers.setFieldError('coupon', EMPTY_FIELD_ERROR);
            return;
        }
        dispatch(activateCoupon([reqEnt.serialize()], {
            result: () => {
                history.push(linkPathBuilder(intl.currentLocale, RoutePath.Billing));
                notifySuccess(intl.getMessage('pay_success'), 'pay_success');
            },
            error: (e: Error) => {
                const { fields: { coupon: couponFailed } } = e;
                if (couponFailed && couponFailed[0]) {
                    const { error_code: errorCode } = couponFailed[0];
                    helpers.setFieldError('coupon', apiErrorCodeTranslate(intl, errorCode));
                    helpers.setSubmitting(false);
                }
            },
        }));
    };

    return (
        <Formik
            initialValues={initial}
            onSubmit={onPay}
        >
            {({
                setFieldValue,
                handleSubmit,
                errors,
                values,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} className={cn(s.form, s.form_coupon)} noValidate>
                    <Input
                        autoFocus
                        error={!!errors.coupon}
                        errorMessage={errors.coupon}
                        name="coupon"
                        onChange={(e) => setFieldValue('coupon', e)}
                        placeholder={intl.getMessage('coupon_form_placeholder')}
                        size="large"
                        type="text"
                        validate={CouponActivateRequest.couponValidate}
                        value={values.coupon}
                    />
                    <Actions
                        isSubmitting={isSubmitting}
                        submitText={intl.getMessage('apply')}
                        noLayout={noLayout}
                    />
                </form>
            )}
        </Formik>
    );
};

export default CouponForm;
