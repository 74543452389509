import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { Divider } from 'antd';
import cn from 'classnames';

import { useIntl, CustomSelect, Link, IOption } from 'Common';
import { checkSavedAccounts, ISavedUserAccount, resellerLogin, userAccountLogin } from 'Actions/user';
import ResellerClient from 'Entities/ResellerClient';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import theme from 'Lib/theme';

import UserAccount from './UserAccount';
import s from './Profile.module.pcss';

interface AccountSelectorProps {
    isOpened: boolean;
    triggerNode: HTMLDivElement | null;
}

const selectAccountDetails = (store: Store) => store.account.details;
const selectAccount = (store: Store) => store.account.account;
const selectResellerClients = (store: Store) => store.reseller.clients;
const selectProfile = (store: Store) => store.profile.info;
const selector = createSelector(
    [selectAccountDetails, selectAccount, selectResellerClients, selectProfile],
    (details, account, clients, profile) => ({ details, account, clients, profile }),
);

const AccountSelector: FC<AccountSelectorProps> = ({ isOpened, triggerNode }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const intl = useIntl();

    const { details, account, clients, profile } = useSelector(selector);

    const [client, setClient] = useState<number>(account!.clientId);

    useEffect(() => {
        dispatch(checkSavedAccounts());
    }, []);

    const accounts = AppConfigStorage.getAccounts();

    if (!account || !details) {
        return null;
    }

    const onClientChange = (value: number) => {
        if (value !== account.clientId) {
            setClient(value);
            dispatch(resellerLogin([value, MAIN_TOKEN || AUTH_TOKEN]));
        }
    };

    const onLogin = (userAccount: ISavedUserAccount) => {
        if (userAccount.id === account.clientId) {
            return null;
        }

        if (userAccount.authorized) {
            dispatch(userAccountLogin(userAccount, {
                result: () => {
                    window.location.reload();
                },
            }));
        } else {
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.AddAccount));
        }
    };

    const getClientMessage = (clientAccount: ResellerClient) => {
        const { accountBlocked, emailNotConfirmed, clientId } = clientAccount;

        if (accountBlocked) {
            return intl.getMessage('account_blocked');
        }

        if (emailNotConfirmed) {
            return intl.getMessage('email_not_confirmed');
        }

        return `#${clientId}`;
    };

    let options: IOption<number>[] = [
        {
            label: (
                <div className={s.accountOptionWrapper}>
                    <span className={s.email}>
                        {account.email}
                    </span>
                    <span className={s.funds}>
                        #{account.clientId}
                    </span>
                </div>
            ),
            labelText: account.email,
            value: account.clientId,
            onSelect: () => onClientChange(account.clientId),
            disabled: true,
        },
    ];

    if (accounts) {
        options = accounts.reduce((results, userAccount) => {
            const currentAccount = userAccount.id === account.clientId;
            const resellerAccount = (userAccount.id === account.clientId && profile?.reseller)
                || MAIN_TOKEN === userAccount.token;

            results.push({
                label: <UserAccount userAccount={userAccount} currentAccount={currentAccount} />,
                labelText: userAccount.email || '',
                disabled: currentAccount,
                value: userAccount.id,
                onSelect: () => onLogin(userAccount),
                withAction: !currentAccount,
            });

            if (resellerAccount && clients.length > 0) {
                clients.forEach((c) => {
                    results.push({
                        label: (
                            <div className={cn(s.accountOptionWrapper, s.client)}>
                                <span className={s.email}>{c.email}</span>
                                <span className={s.funds}>
                                    {getClientMessage(c)}
                                </span>
                            </div>
                        ),
                        labelText: c.email,
                        value: c.clientId,
                        onSelect: () => onClientChange(c.clientId),
                        disabled: c.accountBlocked || c.emailNotConfirmed,
                    });
                });
            }

            return results;
        }, [] as IOption<number>[]);
    }

    return (
        <CustomSelect<number>
            trigger="click"
            placement="bottomRight"
            renderInParentContainer
            isOpened={isOpened}
            triggerNode={triggerNode}
            value={client}
            options={[...options]}
            specificOption={{
                label: (
                    <>
                        <Divider className={cn(theme.divider.divider, s.divider)} />
                        <span className={cn(s.specificOption, s.option)}>
                            <Link to={RoutePath.AddAccount} className={s.add}>
                                <span className={s.label}>
                                    {intl.getMessage('add_account')}
                                </span>
                            </Link>
                        </span>
                    </>
                ),
                labelText: intl.getMessage('add_account'),
            }}
        />
    );
};

export default AccountSelector;
