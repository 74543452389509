import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';

import { useIntl, Button, Icon, Dots } from 'Common';
import Server from 'Entities/Server';
import { ServerState } from 'Entities/ServerState';
import { OperationType } from 'Entities/OperationType';
import { operationTypeTranslate } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';

import {
    EnabledControls,
    DisabledControls,
    LoadingControls,
    Options,
} from './components';

type ActionButtonType = 'small' | 'medium' | 'big';
interface ActionsProps {
    server: Server;
    size?: ActionButtonType;
    operationType?: OperationType;
    compact?: boolean;
}

const Actions: FC<ActionsProps> = ({ server, size, operationType, compact }) => {
    const serverEnabled = server.state === ServerState.RUNNING;
    const intl = useIntl();
    const [visible, setVisible] = useState(false);

    interface ButtonParams {
        content: JSX.Element;
        color?: string;
        buttonColor?: string;
        title: React.ReactNode;
    }

    let buttonParams: ButtonParams = {
        content: (
            <>
                <DisabledControls server={server} close={() => setVisible(false)} />
                <Options server={server} close={() => setVisible(false)} />
            </>
        ),
        color: theme.actions.dot_red,
        buttonColor: theme.button.border_red,
        title: intl.getMessage('server_turned_off'),
    };

    if (serverEnabled) {
        buttonParams = {
            content: (
                <>
                    <EnabledControls server={server} close={() => setVisible(false)} />
                    <Options server={server} close={() => setVisible(false)} />
                </>
            ),
            color: theme.actions.dot_green,
            buttonColor: theme.button.border_green,
            title: intl.getMessage('server_turned_on'),
        };
    }

    const havePopUp = (operationType && ![
        OperationType.RESET,
        OperationType.DESTROY,
        OperationType.REVERT_TO_SNAPSHOT,
        OperationType.REVERT_TO_BACKUP,
        OperationType.TARIFF_CHANGE,
        OperationType.CPU_CORES_CHANGE,
        OperationType.DISK_CHANGE,
        OperationType.MEMORY_CHANGE,
        OperationType.PUBLIC_KEY_CHANGE,
        OperationType.IP_ADD,
        OperationType.IP_DELETE,
        OperationType.REINSTALL,
    ].includes(operationType)) || !operationType;

    if (operationType) {
        buttonParams = {
            content: (
                <LoadingControls
                    server={server}
                    close={() => setVisible(false)}
                    type={operationType}
                />
            ),
            title: operationTypeTranslate(intl, operationType),
        };
    }

    const { content, color, buttonColor, title } = buttonParams;

    const innerButton = (
        <Button
            size={size}
            type="border"
            className={cn(
                theme.actions.action,
                { [theme.actions.action_compact]: compact },
                theme.button.action,
                buttonColor,
            )}
            title={intl.getMessage('title_server_actions')}
            onClick={(e) => e.stopPropagation()}
            dataAttrs={{ 'data-name': 'serverActions' }}
        >
            {!compact && (
                color ? (
                    <div className={cn(theme.actions.dot, color)} />
                ) : (
                    <Dots className={theme.actions.loader} />
                )
            )}
            <div className={theme.actions.title}>
                {title}
            </div>
            {havePopUp && (
                <Icon icon="down" className={theme.actions.down} />
            )}
        </Button>
    );

    return !(operationType && !havePopUp) ? (
        <Popover
            content={havePopUp && (
                <div
                    className={cn(theme.popover.popover, theme.popover.popover_action)}
                    onClick={(e) => e.stopPropagation()}
                >
                    {content}
                </div>
            )}
            placement="bottomRight"
            trigger="click"
            visible={visible}
            onVisibleChange={(e) => setVisible(e)}
            overlayClassName="popover"
        >
            {innerButton}
        </Popover>
    ) : innerButton;
};

export default Actions;
