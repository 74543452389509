import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl } from 'Common';

import s from '../Chart.module.pcss';
import { ChartSizeType } from '../AreaChart';

interface EmptyChartProps {
    size?: ChartSizeType;
    label?: string;
}

const EmptyChart: FC<EmptyChartProps> = ({ size, label }) => {
    const intl = useIntl();

    return (
        <div
            className={cn(
                s.empty,
                { [s.empty_large]: size === 'large' },
                { [s.empty_small]: size === 'small' },
            )}
        >
            <div className={cn(s.emptyDesc, s.emptyDesc_desktop)}>
                {label || intl.getMessage('no_data')}
            </div>
            <div className={cn(s.emptyDesc, s.emptyDesc_mobile)}>
                {intl.getMessage('no_data')}
            </div>
        </div>
    );
};

export default EmptyChart;
