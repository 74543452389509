import { Action } from 'store/actions';
import { ReverseDnsActions, savePtrRecords } from 'Actions/reverseDns';
import DnsPtrRecord from 'Entities/DnsPtrRecord';

import { ReverseDnsStore, reverseDnsInitialState as initialState } from '../initialStates';

const reducer = (state: ReverseDnsStore = initialState, action: Action): ReverseDnsStore => {
    switch (action.type as ReverseDnsActions) {
        case ReverseDnsActions.SavePtrRecords: {
            const { payload } = action as ReturnType<typeof savePtrRecords>;
            const record = new Map<string, DnsPtrRecord>();
            payload.forEach((s) => {
                record.set(`${s.serverId}${s.ipAddress}`, s);
            });
            return record;
        }

        default:
            return state;
    }
};

export default reducer;
