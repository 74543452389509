import React, { FC, useState } from 'react';
import { Popover, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import dayjs from 'dayjs';

import { deletePublicKey } from 'Actions/publicKeys';
import { useIntl, Icon, Button } from 'Common';
import PublicKey from 'Entities/PublicKey';
import { PublicKeyType } from 'Entities/PublicKeyType';
import Tenant from 'Entities/Tenant';
import theme from 'Lib/theme';

import { ModalAddKey, ModalDeleteKey } from '.';
import s from './Key.module.pcss';

interface KeyProps {
    tenant?: Tenant;
    item: PublicKey;
}

const Key: FC<KeyProps> = ({ item, tenant }) => {
    const intl = useIntl();

    const { type, title, timeAddedMillis, installDefault, shared } = item;

    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [open, setOpen] = useState(false);

    const dispatch = useDispatch();
    const onDeleteKey = (key: PublicKey) => () => {
        const { tenantId, id } = key;
        dispatch(deletePublicKey([tenantId, id]));
    };

    const parsedTime = dayjs(timeAddedMillis);
    const formattedTime = parsedTime.format(intl.getMessage('time_format_short_month_year'));
    const deprecated = type === PublicKeyType.RSA
        || type === PublicKeyType.DSA;

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.with_actions,
            )}
        >
            <div
                className={cn(
                    theme.card.grid,
                    theme.card.grid_key,
                )}
            >
                <div className={s.wrap}>
                    <Icon icon="key" className={s.icon} />
                    <div className={theme.common.textOverflow}>
                        {title}
                    </div>
                </div>
                <div className={theme.common.textOverflow}>
                    {shared ? (
                        intl.getMessage('all_projects')
                    ) : (
                        tenant?.description
                    )}
                </div>
                <div className={s.wrap}>
                    {deprecated && (
                        <Tooltip placement="left" title={intl.getMessage('public_key_expired')}>
                            <div
                                className={cn(
                                    theme.Badge.badge,
                                    theme.Badge.warning,
                                    s.badge,
                                )}
                            />
                        </Tooltip>
                    )}
                    {type}
                </div>
                <div className={cn(theme.common.textOverflow, theme.color.grayHeaders)}>
                    {installDefault && (
                        <>
                            <span className={s.mobile}>
                                {intl.getMessage('public_keys_only_new')}
                            </span>
                            <Icon icon="check" className={cn(s.desktop, theme.color.concrete)} />
                        </>
                    )}
                </div>
                <div className={theme.card.actionsWrap}>
                    <div className={s.date}>
                        {formattedTime}
                    </div>

                    <div className={theme.card.actions}>
                        <Button
                            type="icon"
                            icon="edit"
                            className={cn(theme.card.action, theme.card.action_hover)}
                            onClick={() => setOpenModalEdit(true)}
                            title={intl.getMessage('edit_key')}
                        />
                        <Button
                            type="icon"
                            icon="delete"
                            className={cn(theme.card.action, theme.card.action_hover)}
                            onClick={() => setOpenModalDelete(true)}
                            title={intl.getMessage('remove_key')}
                        />
                    </div>
                </div>
            </div>

            <Popover
                content={(
                    <div className={theme.popover.popover}>
                        <button
                            type="button"
                            className={cn(theme.popover.item)}
                            onClick={() => {
                                setOpen(false);
                                setOpenModalEdit(true);
                            }}
                        >
                            {intl.getMessage('edit')}
                        </button>
                        <button
                            type="button"
                            className={cn(
                                theme.popover.item,
                                theme.popover.item_danger,
                            )}
                            onClick={() => {
                                setOpen(false);
                                setOpenModalDelete(true);
                            }}
                        >
                            {intl.getMessage('delete')}
                        </button>
                    </div>
                )}
                visible={open}
                onVisibleChange={(v) => setOpen(v)}
                placement="left"
                trigger="click"
                overlayClassName="popover"
            >
                <Button
                    type="icon"
                    icon="options"
                    title={intl.getMessage('title_control_key')}
                    className={cn(theme.card.action, theme.card.action_popover)}
                />
            </Popover>

            {openModalDelete && (
                <ModalDeleteKey
                    visible
                    keyName={title}
                    handleClose={() => setOpenModalDelete(false)}
                    handleSubmit={onDeleteKey(item)}
                />
            )}

            {openModalEdit && (
                <ModalAddKey
                    visible
                    handleClose={() => setOpenModalEdit(false)}
                    initialValue={item}
                />
            )}
        </div>
    );
};

export default Key;
