// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITenantResourceLimits {
    max_backup_quantity: number;
    max_users_in_tenant: number;
    min_backup_quantity: number;
}

export default class TenantResourceLimits {
    readonly _max_backup_quantity: number;

    /**
     * Description: Max available backups copies
     * Example: 10
     */
    get maxBackupQuantity(): number {
        return this._max_backup_quantity;
    }

    static maxBackupQuantityValidate(maxBackupQuantity: number): boolean {
        return typeof maxBackupQuantity === 'number';
    }

    readonly _max_users_in_tenant: number;

    /**
     * Description: Max users in tenant
     * Example: 10
     */
    get maxUsersInTenant(): number {
        return this._max_users_in_tenant;
    }

    static maxUsersInTenantValidate(maxUsersInTenant: number): boolean {
        return typeof maxUsersInTenant === 'number';
    }

    readonly _min_backup_quantity: number;

    /**
     * Description: Min available backups copies
     * Example: 1
     */
    get minBackupQuantity(): number {
        return this._min_backup_quantity;
    }

    static minBackupQuantityValidate(minBackupQuantity: number): boolean {
        return typeof minBackupQuantity === 'number';
    }

    constructor(props: ITenantResourceLimits) {
        this._max_backup_quantity = props.max_backup_quantity;
        this._max_users_in_tenant = props.max_users_in_tenant;
        this._min_backup_quantity = props.min_backup_quantity;
    }

    serialize(): ITenantResourceLimits {
        const data: ITenantResourceLimits = {
            max_backup_quantity: this._max_backup_quantity,
            max_users_in_tenant: this._max_users_in_tenant,
            min_backup_quantity: this._min_backup_quantity,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            min_backup_quantity: typeof this._min_backup_quantity === 'number',
            max_backup_quantity: typeof this._max_backup_quantity === 'number',
            max_users_in_tenant: typeof this._max_users_in_tenant === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITenantResourceLimits): TenantResourceLimits {
        return new TenantResourceLimits(props);
    }

    readonly keys: { [key: string]: string } = {
        maxBackupQuantity: 'max_backup_quantity',
        maxUsersInTenant: 'max_users_in_tenant',
        minBackupQuantity: 'min_backup_quantity',
        }
;

    mergeDeepWith(props: Partial<TenantResourceLimits>): TenantResourceLimits {
        const updateData: Partial<ITenantResourceLimits> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TenantResourceLimits) => {
            const updateKey = this.keys[key] as keyof ITenantResourceLimits;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITenantResourceLimits, keyof ITenantResourceLimits>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TenantResourceLimits({ ...this.serialize(), ...updateData });
    }
}
