import { ClientType } from './ClientType';
import CorporationInfo, { ICorporationInfo } from './CorporationInfo';
import IndividualInfo, { IIndividualInfo } from './IndividualInfo';
import NonResidentInfo, { INonResidentInfo } from './NonResidentInfo';
import SoleTraderInfo, { ISoleTraderInfo } from './SoleTraderInfo';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPayerInfo {
    active: boolean;
    client_type: ClientType;
    corporation_info?: ICorporationInfo;
    full_name: string;
    individual_info?: IIndividualInfo;
    non_resident_info?: INonResidentInfo;
    payer_id: number;
    sole_trader_info?: ISoleTraderInfo;
}

export default class PayerInfo {
    readonly _active: boolean;

    /**
     * Description: If this payer is client's current payer
     * Example: true
     */
    get active(): boolean {
        return this._active;
    }

    static activeValidate(active: boolean): boolean {
        return typeof active === 'boolean';
    }

    readonly _client_type: ClientType;

    get clientType(): ClientType {
        return this._client_type;
    }

    static clientTypeValidate(clientType: ClientType): boolean {
        return Object.keys(ClientType).includes(clientType);
    }

    readonly _corporation_info: CorporationInfo | undefined;

    get corporationInfo(): CorporationInfo | undefined {
        return this._corporation_info;
    }

    readonly _full_name: string;

    /**
     * Description: Payer name
     * Example: ИП КАРЦЕВА ЕЛЕНА ВИКТОРОВНА
     */
    get fullName(): string {
        return this._full_name;
    }

    static fullNameValidate(fullName: string): boolean {
        return typeof fullName === 'string' && !!fullName.trim();
    }

    readonly _individual_info: IndividualInfo | undefined;

    get individualInfo(): IndividualInfo | undefined {
        return this._individual_info;
    }

    readonly _non_resident_info: NonResidentInfo | undefined;

    get nonResidentInfo(): NonResidentInfo | undefined {
        return this._non_resident_info;
    }

    readonly _payer_id: number;

    /**
     * Description: Payer ID
     * Example: 3724
     */
    get payerId(): number {
        return this._payer_id;
    }

    static payerIdValidate(payerId: number): boolean {
        return typeof payerId === 'number';
    }

    readonly _sole_trader_info: SoleTraderInfo | undefined;

    get soleTraderInfo(): SoleTraderInfo | undefined {
        return this._sole_trader_info;
    }

    constructor(props: IPayerInfo) {
        this._active = props.active;
        this._client_type = props.client_type;
        if (props.corporation_info) {
            this._corporation_info = new CorporationInfo(props.corporation_info);
        }
        this._full_name = props.full_name.trim();
        if (props.individual_info) {
            this._individual_info = new IndividualInfo(props.individual_info);
        }
        if (props.non_resident_info) {
            this._non_resident_info = new NonResidentInfo(props.non_resident_info);
        }
        this._payer_id = props.payer_id;
        if (props.sole_trader_info) {
            this._sole_trader_info = new SoleTraderInfo(props.sole_trader_info);
        }
    }

    serialize(): IPayerInfo {
        const data: IPayerInfo = {
            active: this._active,
            client_type: this._client_type,
            full_name: this._full_name,
            payer_id: this._payer_id,
        };
        if (typeof this._corporation_info !== 'undefined') {
            data.corporation_info = this._corporation_info.serialize();
        }
        if (typeof this._individual_info !== 'undefined') {
            data.individual_info = this._individual_info.serialize();
        }
        if (typeof this._non_resident_info !== 'undefined') {
            data.non_resident_info = this._non_resident_info.serialize();
        }
        if (typeof this._sole_trader_info !== 'undefined') {
            data.sole_trader_info = this._sole_trader_info.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            active: typeof this._active === 'boolean',
            payer_id: typeof this._payer_id === 'number',
            full_name: typeof this._full_name === 'string' && !!this._full_name.trim(),
            individual_info: !this._individual_info ? true : this._individual_info.validate().length === 0,
            sole_trader_info: !this._sole_trader_info ? true : this._sole_trader_info.validate().length === 0,
            corporation_info: !this._corporation_info ? true : this._corporation_info.validate().length === 0,
            non_resident_info: !this._non_resident_info ? true : this._non_resident_info.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPayerInfo): PayerInfo {
        return new PayerInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        active: 'active',
        clientType: 'client_type',
        corporationInfo: 'corporation_info',
        fullName: 'full_name',
        individualInfo: 'individual_info',
        nonResidentInfo: 'non_resident_info',
        payerId: 'payer_id',
        soleTraderInfo: 'sole_trader_info',
        }
;

    mergeDeepWith(props: Partial<PayerInfo>): PayerInfo {
        const updateData: Partial<IPayerInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PayerInfo) => {
            const updateKey = this.keys[key] as keyof IPayerInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPayerInfo, keyof IPayerInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PayerInfo({ ...this.serialize(), ...updateData });
    }
}
