import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useIntl, Checkbox, CodeInput, Warning, CommonModalLayout } from 'Common';
import { rollbackBackup } from 'Actions/serverBackup';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { useEnter, useSecurityActions } from 'Hooks';

interface ModalBackupProps {
    visible: boolean;
    close: () => void;
    diskGib: number;
    tenantId: number;
    serverId: number;
    backupId: string;
}

const ModalBackup: FC<ModalBackupProps> = ({
    visible, close, diskGib, tenantId, serverId, backupId,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    const [code, setCode] = useState('');
    const history = useHistory();
    const [codeError, setCodeError] = useState(false);
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityActions(SecurityActionType.REVERT_TO_BACKUP);
    const handleOk = () => {
        const link = linkPathBuilder(intl.currentLocale, RoutePath.Server, { tenantId, serverId });
        if (shouldConfirmAction && !codeSent) {
            sendConfirmationCode({ tenantId, serverId });
        }
        if (!shouldConfirmAction) {
            dispatch(rollbackBackup(
                [tenantId, serverId, backupId, { create_backup: checked }],
            ));
            history.push(link);
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(rollbackBackup([tenantId, serverId, backupId, {
                security_code: code,
                create_backup: checked,
            }], {
                error: () => setCodeError(true),
                result: () => {
                    history.push(link);
                },
            }));
        }
    };

    useEnter(handleOk, [handleOk]);

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('modal_backup_title')}
            handleSubmit={handleOk}
            handleClose={close}
            buttonText={intl.getMessage('restore')}
            width={600}
        >
            <div className="modal__desc">
                {intl.getMessage('modal_backup_desc')}
            </div>
            <div className="modal__desc">
                <Warning text={intl.getMessage('modal_backup_info')} type="info" />
            </div>
            <div className="modal__desc">
                {intl.getMessage('long_time_warning', {
                    minutes: intl.getPlural('minutes', diskGib),
                })}
            </div>
            <Checkbox
                name="save_backup"
                checked={checked}
                handleChange={(e) => setChecked(e.target.checked)}
            >
                {intl.getMessage('modal_backup_checkbox')}
            </Checkbox>
            {(codeSent && deliveryMessage) && (
                <>
                    <div className="modal__desc modal__desc--gray">
                        {deliveryMessage}
                    </div>
                    <CodeInput
                        autoFocus
                        value={code}
                        setValue={(e) => setCode(e)}
                        codeError={codeError}
                        setCodeError={(e) => setCodeError(e)}
                        onSendAgain={() => sendConfirmationCode({
                            tenantId,
                            serverId,
                        })}
                    />
                </>
            )}
        </CommonModalLayout>
    );
};

export default ModalBackup;
