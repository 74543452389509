import React, { FC } from 'react';
import theme from 'Lib/theme';
import { Layout, Tooltip, Grid } from 'antd';
import cn from 'classnames';

import s from './InnerPageLayout.module.pcss';

const { useBreakpoint } = Grid;
const { Content } = Layout;

interface InnerPageProp {
    info?: string;
    title?: string;
    tooltip?: string;
    tooltipClassName?: string;
    header?: React.ReactNode;
    className?: string;
}

const InnerPageLayout: FC<InnerPageProp> = ({
    header, info, title, tooltip, tooltipClassName, className, children,
}) => {
    const screens = useBreakpoint();
    const tooltipPosition = screens.xl ? 'rightTop' : 'bottom';

    return (
        <Layout>
            {header}
            <Content className={cn(theme.content.content, className)}>
                {title && (
                    <div className={s.title}>
                        {title}
                        {tooltip && (
                            <Tooltip
                                overlayClassName={cn('tooltip tooltip_title', tooltipClassName)}
                                placement={tooltipPosition}
                                title={tooltip}
                            >
                                <div
                                    className={cn(
                                        theme.Badge.badge,
                                        theme.Badge.question,
                                        s.badge,
                                    )}
                                />
                            </Tooltip>
                        )}
                    </div>
                )}
                {info && (
                    <div className={s.info}>
                        {info}
                    </div>
                )}
                {children}
            </Content>
        </Layout>
    );
};

export default InnerPageLayout;
