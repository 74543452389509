import React, { FC } from 'react';
import { Tooltip } from 'antd';
import { Icon, useIntl } from 'Common';
import dayjs from 'dayjs';
import cn from 'classnames';

import DatePeriod from 'Entities/DatePeriod';

import s from './DetailedComparativeValues.module.pcss';

const whiteText = (text: string) => (
    <span className={s.label}>
        {text}
    </span>
);

interface DetailedComparativeValuesProps {
    diff?: number;
    recentValue: number;
    recentPeriod: DatePeriod;
    previousValue?: number;
    previousPeriod?: DatePeriod;
    increasingLabel: string;
    decreasingLabel: string;
    withCurrency?: boolean;
}

const DetailedComparativeValues: FC<DetailedComparativeValuesProps> = ({
    diff,
    recentValue,
    recentPeriod,
    previousValue,
    previousPeriod,
    increasingLabel,
    decreasingLabel,
    withCurrency,
}) => {
    const intl = useIntl();

    const roundedRecentValue = Math.round(recentValue);
    const roundedPreviousValue = previousValue && Math.round(previousValue);

    const formatDate = (dataPeriod: DatePeriod) => {
        const { dateFromMillis, dateToMillis } = dataPeriod;
        const formattedFrom = dayjs(dateFromMillis).format(intl.getMessage('time_format_day_short'));
        const formattedTo = dayjs(dateToMillis).format(intl.getMessage('time_format_day_short'));
        return `${formattedFrom} – ${formattedTo}`;
    };

    const getFormattedPeriod = (value: number, period: DatePeriod) => {
        if (withCurrency) {
            return (
                intl.getMessage('income_period', {
                    b: whiteText,
                    value,
                    date: formatDate(period),
                })
            );
        }

        return (
            intl.getMessage('value_period', {
                b: whiteText,
                value,
                date: formatDate(period),
            })
        );
    };

    if (diff === undefined) {
        return null;
    }

    return (
        <Tooltip
            placement="bottom"
            title={() => (
                <>
                    <div className={s.tooltipTitle}>
                        {diff > 0 ? increasingLabel : decreasingLabel}
                    </div>
                    {roundedRecentValue > 0 && (
                        <div className={s.tooltipPeriod}>
                            {getFormattedPeriod(roundedRecentValue, recentPeriod)}
                        </div>
                    )}
                    {previousPeriod !== undefined
                        && roundedPreviousValue !== undefined
                        && roundedPreviousValue > 0 && (
                        <div className={s.tooltipPeriod}>
                            {getFormattedPeriod(roundedPreviousValue, previousPeriod)}
                        </div>
                    )}
                </>
            )}
            overlayClassName="tooltip tooltip_income"
        >
            <div
                className={cn(
                    s.diff,
                    { [s.diff_negative]: diff < 0 },
                    { [s.diff_up]: diff > 0 },
                    { [s.diff_positive]: diff > 0 },
                )}
            >
                {diff}%
                <Icon
                    className={s.diffIcon}
                    icon="arrow_down"
                />
            </div>
        </Tooltip>
    );
};

export default DetailedComparativeValues;
