import React, { FC, useState } from 'react';
import { Input, Row, Col } from 'antd';
import cn from 'classnames';

import profileApi from 'Apis/profile';
import { useIntl, Button } from 'Common';
import ApiKeyEnt, { IApiKey } from 'Entities/ApiKey';
import theme from 'Lib/theme';
import { errorChecker } from 'Lib/helpers/utils';

import s from './ApiKey.module.pcss';

const ApiKey: FC = () => {
    const intl = useIntl();
    const [apiKey, setApiKey] = useState<ApiKeyEnt | null>(null);

    const generateApiKey = async () => {
        const res = await profileApi.updateClientApiKey();
        const { result } = errorChecker<IApiKey>(res);
        if (result) {
            setApiKey(new ApiKeyEnt(result));
        }
    };
    const copyApiKey = () => {
        navigator.clipboard.writeText(apiKey!.apiKey);
    };

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.plate,
                theme.card.rounded,
                { [theme.card.active]: apiKey },
            )}
        >
            <Row gutter={[24, 0]}>
                <Col span={24} lg={14} xl={12}>
                    <div className={theme.card.title}>
                        {intl.getMessage('api_key')}
                    </div>
                    <div className={cn(theme.card.desc, s.desc)}>
                        {!apiKey
                            ? intl.getMessage('api_key_desc')
                            : intl.getMessage('api_key_recived_desc')}
                    </div>
                    <div className={s.form}>
                        <Input
                            size="large"
                            className={cn(
                                'input',
                                { [s.input]: !apiKey },
                            )}
                            placeholder={intl.getMessage('api_key_hidden')}
                            value={apiKey?.apiKey || ''}
                            disabled
                        />
                        {!apiKey && (
                            <Button
                                type="link"
                                size="medium"
                                className={s.action}
                                onClick={generateApiKey}
                            >
                                {intl.getMessage('generate')}
                            </Button>
                        )}
                        {apiKey && (
                            <Button
                                type="link"
                                size="medium"
                                className={s.action}
                                onClick={(copyApiKey)}
                            >
                                {intl.getMessage('copy')}
                            </Button>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default ApiKey;
