import { takeEvery, put, call, select } from 'redux-saga/effects';

import { Action } from 'Actions/index';
import {
    ServerActions,
    saveServerList,
    saveServer,
    saveServerTenantList,
    getServerList as getServerListAct,
    getServer as getServerAct,
    getServerForTenant as getServerForTenantAct,
    addIp as addIpAct,
    removeIp as removeIpAct,
    moveIp as moveIpAct,
    updateServer as updateServerAct,
    deleteServer as deleteServerAct,
    deleteServerLocal,
    updateServerBackupPolicy as updateServerBackupPolicyAct,
    updateConfiguration as updateConfigurationAct,
    serverClone as serverCloneAct,
    updatePassword as updatePasswordAct,
    grantSupportAccess as grantSupportAccessAct,
    grantConsoleAccess as grantConsoleAccessAct,
    updateServerPublicKeys as updateServerPublicKeysAct,
    forceInstall as forceInstallAct,
    cancelInstallation as cancelInstallationAct,
    install as installAct,
    reinstall as reinstallAct,
    deleteServers as deleteServersAct,
    addPublicKeys as addPublicKeysAct,
    rebootServers as rebootServersAct,
    resetServers as resetServersAct,
    startServers as startServersAct,
    shutDownServers as shutDownServersAct,
    destroyServers as destroyServersAct,
    updateServerKeys as updateServerKeysAct,
    updateServerRecommendations as updateServerRecommendationsAct,
} from 'Actions/server';
import { getServerOperationProgress } from 'Actions/operationProgress';
import { getBatchOperationProgress, saveBatchOperationProgress } from 'Actions/batchOperationProgress';
import {
    getProgress as getInstallingProgressAct,
    deleteProgress as deleteInstallingProgressAct,
    saveInstallingServer,
    deleteInstallingServer as deleteInstallingServerAct,
} from 'Actions/installingServers';
import ServerApi, { DeletedServer, EarlyServer } from 'Apis/servers';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import Error from 'Entities/Error';
import Server, { IServer } from 'Entities/Server';
import { IOperationInfo } from 'Entities/OperationInfo';
import { IServerDelete } from 'Entities/ServerDelete';
import { IOperationProgress } from 'Entities/OperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';
import { IServerConsoleAccessOpen } from 'Entities/ServerConsoleAccessOpen';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { OperationType } from 'Entities/OperationType';
import { IBatchOperationInfo } from 'Entities/BatchOperationInfo';
import { Store } from 'Store';

import { errorChecker, addError } from './utils';

export function* getServerList(action: ReturnType<typeof getServerListAct>) {
    const response: IServer[] = yield call(ServerApi.listServers);
    const { error, result } = errorChecker<IServer[]>(response);
    if (result) {
        const serverEntities = result.map((s) => new Server(s));
        yield put(saveServerList(serverEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* getServer(action: ReturnType<typeof getServerAct>) {
    const { payload: { serverId, tenantId }, callback } = action;
    const response: IServer = yield call(ServerApi.getServer, tenantId, serverId);
    const { error, result } = errorChecker<IServer | DeletedServer | EarlyServer>(response);
    if (result) {
        if ((result as DeletedServer).deleted) {
            const data = result as DeletedServer;
            callback?.error?.(new Server(data.entity));
        } else if ((result as EarlyServer).early) {
            const data = result as EarlyServer;
            yield put(saveInstallingServer(new Server(data.entity)));
            callback?.result?.();
        } else {
            yield put(saveServer(new Server(result as IServer)));
            callback?.result?.();
        }
    }
    if (error) {
        yield addError(error, action.type);
        callback?.error?.();
    }
}

function* getServerForTenant(action: ReturnType<typeof getServerForTenantAct>) {
    const { payload } = action;
    const response: IServer[] = yield call(ServerApi.listServersForTenant, ...payload);
    const { error, result } = errorChecker<IServer[]>(response);
    if (result) {
        const serverEntities = result.map((s) => new Server(s));
        yield put(saveServerTenantList(serverEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* startServer(action: Action<Server>) {
    const serv = action.payload;
    const response: IOperationInfo = yield call(ServerApi.startServer, serv.tenantId, serv.id);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: serv.id,
            tenantId: serv.tenantId,
            operationId: result.id,
            operationType: OperationType.START,
        }));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

type Operations = 'rebootServer' | 'resetServer' | 'shutdownServer' | 'destroyServer';
const serverOperation = (type: Operations) => {
    return function* operation(action: Action<Server>) {
        const serv = action.payload;
        const response: OperationType = yield call(ServerApi[type], serv.tenantId, serv.id);
        let operationType: OperationType | null = null;
        switch (type) {
            case 'rebootServer':
                operationType = OperationType.REBOOT;
                break;
            case 'resetServer':
                operationType = OperationType.RESET;
                break;
            case 'shutdownServer':
                operationType = OperationType.SHUTDOWN;
                break;
            case 'destroyServer':
                operationType = OperationType.DESTROY;
                break;
        }
        const { error, result } = errorChecker<IOperationInfo>(response);
        if (result) {
            yield put(getServerOperationProgress({
                serverId: serv.id,
                tenantId: serv.tenantId,
                operationId: result.id,
                operationType,
            }));
        }
        if (error) {
            yield addError(error, action.type);
        }
    };
};

function* addIp(action: ReturnType<typeof addIpAct>) {
    const { payload: server, payload: { id, tenantId, name }, callback } = action;
    const response: IOperationInfo = yield call(ServerApi.addIp, tenantId, id);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: server.id,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: OperationType.IP_ADD,
        }));
        if (callback?.result) {
            callback?.result();
        }
    }
    if (error) {
        if (callback?.error) {
            callback?.error();
        }
        if (error.errorCode === ApiErrorCode.INET_IP_GUEST_ADD) {
            yield put(getServerAct({
                serverId: server.id,
                tenantId: server.tenantId,
            }));
        }
        const newError = new Error({ ...error.serialize(), message: `(${name})` });
        yield addError(newError, action.type);
    }
}

function* removeIp(action: ReturnType<typeof removeIpAct>) {
    const { payload: { server, server: { id, tenantId, name }, remove } } = action;
    const response: IOperationInfo = yield call(ServerApi.removeIp, tenantId, id, remove);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: server.id,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: OperationType.IP_DELETE,
        }));
    }
    if (error) {
        if (error.errorCode === ApiErrorCode.INET_IP_GUEST_REMOVE) {
            yield put(getServerAct({
                serverId: server.id,
                tenantId: server.tenantId,
            }));
        }
        const removeError = new Error({ ...error.serialize(), message: `${remove.ip_address} (${name})` });
        yield addError(removeError, action.type);
    }
}

function* moveIp(action: ReturnType<typeof moveIpAct>) {
    const { payload: { server, server: { id, tenantId }, move, toTenantId } } = action;
    const response: IOperationInfo = yield call(ServerApi.moveIp, tenantId, id, move);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: move.to_server_id,
            tenantId: toTenantId,
            operationId: result.id,
            operationType: OperationType.IP_ADD,
        }));
        yield put(getServerAct({
            serverId: move.to_server_id,
            tenantId: toTenantId,
        }));
        yield put(getServerAct({
            serverId: server.id,
            tenantId: server.tenantId,
        }));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateServer(action: ReturnType<typeof updateServerAct>) {
    const { payload: { server, server: { id, tenantId }, update }, callback } = action;
    const response: IOperationInfo = yield call(ServerApi.updateServer, tenantId, id, update);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        if (update.to_tenant_id) {
            yield put(getServerAct({
                serverId: server.id,
                tenantId: update.to_tenant_id,
            }));
        } else {
            yield put(getServerAct({
                serverId: server.id,
                tenantId: server.tenantId,
            }));
        }
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateServerRecommendations(action: ReturnType<typeof updateServerRecommendationsAct>) {
    const { payload, payload: [tenantId, serverId, refresh] } = action;
    const response: number = yield call(
        ServerApi.updateRecommendationsSubscriptionForServer,
        ...payload,
    );
    const { result } = errorChecker<number>(response);
    if (result && refresh) {
        yield put(getServerAct({ serverId, tenantId }));
    }
}

function* deleteServer(action: ReturnType<typeof deleteServerAct>) {
    const {
        payload: { server, code }, payload: { server: { id, tenantId } },
        callback,
    } = action;
    const req: IServerDelete = {};
    if (code) {
        req.security_code = code;
    }
    const response: IOperationInfo = yield call(ServerApi.deleteServer, tenantId, id, req);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        if (callback?.result) {
            callback?.result();
        }
        yield put(getServerOperationProgress({
            serverId: server.id,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: OperationType.DELETE,
        }));
        yield put(deleteServerLocal(server.id));
    }
    if (error) {
        if (callback?.error) {
            callback.error();
            return;
        }
        yield addError(error, action.type);
    }
}
function* updateServerBackupPolicy(action: ReturnType<typeof updateServerBackupPolicyAct>) {
    const { payload } = action;
    const response: IOperationInfo = yield call(ServerApi.updateBackupPolicy, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        const server: Server = yield select((store: Store) => (store.server?.get(payload[1])));
        yield put(getServerAct({
            serverId: server.id,
            tenantId: server.tenantId,
        }));
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* updateConfiguration(action: ReturnType<typeof updateConfigurationAct>) {
    const {
        server,
        server: { tenantId, id },
        serverConfigurationUpdate,
    } = action.payload;
    const response: IOperationInfo = yield call(
        ServerApi.updateConfiguration,
        tenantId,
        id,
        serverConfigurationUpdate.serialize(),
    );
    let operationType: OperationType;
    if (serverConfigurationUpdate.diskGib) {
        operationType = OperationType.DISK_CHANGE;
    }
    if (serverConfigurationUpdate.memoryMib) {
        operationType = OperationType.MEMORY_CHANGE;
    }
    if (serverConfigurationUpdate.cpuCores) {
        operationType = OperationType.CPU_CORES_CHANGE;
    }
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: server.id,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: operationType!,
        }, { result: action.callback?.result }));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* serverClone(action: ReturnType<typeof serverCloneAct>) {
    const { payload, callback } = action;
    const [,, clone] = payload;
    const response: IOperationInfo = yield call(ServerApi.cloneServer, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getInstallingProgressAct({ ...result, tenantId: clone.to_tenant_id! }));
        callback?.result?.();
    }
    if (error) {
        yield addError(error, action.type);
        callback?.error?.(error);
    }
}

function* updatePassword(action: ReturnType<typeof updatePasswordAct>) {
    const { payload, callback } = action;
    const [, serverId] = payload;
    const server: Server = yield select((store: Store) => store.server?.get(serverId));
    const response: IOperationInfo = yield call(ServerApi.updatePassword, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: server.id,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: OperationType.PASSWORD_CHANGE,
        }));
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        if (callback?.error) {
            callback.error(error);
            return;
        }
        yield addError(error, action.type);
    }
}

function* grantSupportAccess(action: ReturnType<typeof grantSupportAccessAct>) {
    const { payload: { server, grant } } = action;
    let response: IOperationInfo | Error;
    const { tenantId, id } = server;
    if (grant) {
        response = yield call(ServerApi.addSupportPublicKey, tenantId, id);
    } else {
        response = yield call(ServerApi.removeSupportPublicKey, tenantId, id);
    }
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: server.id,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: OperationType.PUBLIC_KEY_CHANGE,
        }));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* forceInstall(action: ReturnType<typeof forceInstallAct>) {
    const { payload, payload: [tenantId] } = action;
    const response: IOperationInfo = yield call(ServerApi.forceInstallServer, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getInstallingProgressAct({
            id: result.id,
            tenantId,
        }));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* cancelInstallation(action: ReturnType<typeof cancelInstallationAct>) {
    const { payload, payload: [, serverId, operId] } = action;
    const response: number = yield call(ServerApi.cancelServerInstallation, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(deleteInstallingProgressAct({ progressId: operId }));
        yield put(deleteInstallingServerAct(serverId));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* grantConsoleAccess(action: ReturnType<typeof grantConsoleAccessAct>) {
    const { payload: { server, grant, code }, callback } = action;
    let response: IOperationInfo;
    const { tenantId, id } = server;
    if (grant) {
        const req: IServerConsoleAccessOpen = {};
        if (code) {
            req.security_code = code;
            req.actionType = SecurityActionType.SERVER_CONSOLE_ACCESS;
        }
        response = yield call(ServerApi.openConsoleAccess, tenantId, id, req);
    } else {
        response = yield call(ServerApi.closeConsoleAccess, tenantId, id);
    }
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerAct({ serverId: server.id, tenantId: server.tenantId }));
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        if (callback?.error) {
            callback.error();
            return;
        }
        yield addError(error, action.type);
    }
}

function* getServersLastOperations(action: ReturnType<typeof serverCloneAct>) {
    const response: IOperationProgress[] = yield call(ServerApi.getServersLastOperations);
    const { error, result } = errorChecker<IOperationProgress[]>(response);
    if (result) {
        const notDone = result.filter((r) => r.status !== OperationStatus.DONE);
        for (let i = 0; i < notDone.length; i += 1) {
            const {
                type, server_id: serverId, id, tenant_id: tenantId, status, error: e,
            } = notDone[i];
            switch (type) {
                case OperationType.DELETE_SNAPSHOT:
                case OperationType.DOWNLOAD_SNAPSHOT:
                    break;
                case OperationType.INSTALL:
                    yield put(getInstallingProgressAct({ id, tenantId }));
                    break;
                default:
                    yield put(getServerOperationProgress({
                        error: e,
                        serverId,
                        tenantId,
                        operationId: id,
                        operationType: type,
                        operationStatus: status,
                    }));
                    break;
            }
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateServerPublicKeys(action: ReturnType<typeof updateServerPublicKeysAct>) {
    const { payload, callback } = action;
    const response: number = yield call(ServerApi.updatePublicKeys, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        const server: Server = yield select((store: Store) => (store.server?.get(payload[1])));
        yield put(getServerAct({
            serverId: server.id,
            tenantId: server.tenantId,
        }));
        if (callback?.result) {
            callback?.result();
        }
    } else if (error) {
        if (callback?.error) {
            callback.error();
            return;
        }
        yield addError(error, action.type);
    }
}

function* install(action: ReturnType<typeof installAct>) {
    const { payload, payload: [tenantId], callback } = action;
    const response: IOperationInfo = yield call(ServerApi.installServer, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getInstallingProgressAct({ ...result, tenantId }, callback));
    }
    if (error) {
        if ((error.errorCode === ApiErrorCode.VM_LIMIT_EXCEED
            || error.errorCode === ApiErrorCode.VM_MEMORY_LIMIT_EXCEED)
            && callback?.error) {
            callback?.error(error);
        }
        yield addError(
            error,
            action.type,
            {
                noSentryErrors: [
                    ApiErrorCode.RATE_LIMIT_EXCEED,
                    ApiErrorCode.VM_LIMIT_EXCEED,
                    ApiErrorCode.VM_MEMORY_LIMIT_EXCEED,
                ],
                req: JSON.stringify(payload),
            },
        );
    }
}

function* reinstall(action: ReturnType<typeof reinstallAct>) {
    const { payload, payload: [tenantId], callback } = action;
    const response: IOperationInfo = yield call(ServerApi.reinstallServer, ...payload);
    const { error, result } = errorChecker(response);
    if (result) {
        if (callback?.result) {
            callback?.result();
        }
        yield put(getInstallingProgressAct({ ...result, tenantId }));
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* deleteServers(action: ReturnType<typeof deleteServersAct>) {
    const { payload, payload: { data: [tenantId, serverIds], notificationId }, callback } = action;
    const response: IBatchOperationInfo = yield call(ServerApi.deleteServersBatch, ...payload.data);
    yield put(saveBatchOperationProgress({
        servers: serverIds.server_ids,
        type: SecurityActionType.SERVER_PUBLIC_KEY,
    }));
    const { result } = errorChecker<IBatchOperationInfo>(response);
    if (result) {
        const batchResultCallback = callback?.result?.();
        yield put(getBatchOperationProgress({
            data: [tenantId, result.batch_id],
            notificationId,
        }, { result: batchResultCallback }));
    }
}

function* addPublicKeys(action: ReturnType<typeof addPublicKeysAct>) {
    const { payload, payload: { data: [tenantId, server], notificationId }, callback } = action;
    const response: IBatchOperationInfo = yield call(ServerApi.addPublicKeysBatch, ...payload.data);
    yield put(saveBatchOperationProgress({
        servers: server.server_ids,
        publicKeys: server.public_keys,
        type: SecurityActionType.SERVER_PUBLIC_KEY,
    }));
    const { error, result } = errorChecker<IBatchOperationInfo>(response);
    if (result) {
        yield put(getBatchOperationProgress({
            data: [tenantId, result.batch_id],
            notificationId,
        }));
        if (callback?.result) {
            callback?.result();
        }
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* rebootServers(action: ReturnType<typeof rebootServersAct>) {
    const { payload, payload: { data: [tenantId, serverIds], notificationId }, callback } = action;
    const response: IBatchOperationInfo = yield call(ServerApi.rebootServersBatch, ...payload.data);
    yield put(saveBatchOperationProgress({
        servers: serverIds.server_ids,
        type: SecurityActionType.SERVER_PUBLIC_KEY,
    }));
    const { result } = errorChecker<IBatchOperationInfo>(response);
    if (result) {
        const batchResultCallback = callback?.result?.();
        yield put(getBatchOperationProgress({
            data: [tenantId, result.batch_id],
            notificationId,
        }, { result: batchResultCallback }));
    }
}

function* resetServers(action: ReturnType<typeof resetServersAct>) {
    const { payload, payload: { data: [tenantId, serverIds], notificationId }, callback } = action;
    const response: IBatchOperationInfo = yield call(ServerApi.resetServersBatch, ...payload.data);
    yield put(saveBatchOperationProgress({
        servers: serverIds.server_ids,
        type: SecurityActionType.SERVER_PUBLIC_KEY,
    }));
    const { result } = errorChecker<IBatchOperationInfo>(response);
    if (result) {
        const batchResultCallback = callback?.result?.();
        yield put(getBatchOperationProgress({
            data: [tenantId, result.batch_id],
            notificationId,
        }, { result: batchResultCallback }));
    }
}

function* startServers(action: ReturnType<typeof startServersAct>) {
    const { payload, payload: { data: [tenantId, serverIds], notificationId }, callback } = action;
    const response: IBatchOperationInfo = yield call(ServerApi.startServersBatch, ...payload.data);
    yield put(saveBatchOperationProgress({
        servers: serverIds.server_ids,
        type: SecurityActionType.SERVER_PUBLIC_KEY,
    }));
    const { result } = errorChecker<IBatchOperationInfo>(response);
    if (result) {
        const batchResultCallback = callback?.result?.();
        yield put(getBatchOperationProgress({
            data: [tenantId, result.batch_id],
            notificationId,
        }, { result: batchResultCallback }));
    }
}

function* shutDownServers(action: ReturnType<typeof shutDownServersAct>) {
    const { payload, payload: { data: [tenantId, serverIds], notificationId }, callback } = action;
    const response: IBatchOperationInfo = yield call(ServerApi.shutdownServersBatch, ...payload.data);
    yield put(saveBatchOperationProgress({
        servers: serverIds.server_ids,
        type: SecurityActionType.SERVER_PUBLIC_KEY,
    }));
    const { result } = errorChecker<IBatchOperationInfo>(response);
    if (result) {
        const batchResultCallback = callback?.result?.();
        yield put(getBatchOperationProgress({
            data: [tenantId, result.batch_id],
            notificationId,
        }, { result: batchResultCallback }));
    }
}

function* updateServerKeys(action: ReturnType<typeof updateServerKeysAct>) {
    const { payload, payload: { data: [tenantId, serverIds], notificationId }, callback } = action;
    const response: IBatchOperationInfo = yield call(ServerApi.updatePublicKeysBatch, ...payload.data);
    yield put(saveBatchOperationProgress({
        servers: serverIds.server_ids,
        type: SecurityActionType.SERVER_PUBLIC_KEY,
    }));
    const { result } = errorChecker<IBatchOperationInfo>(response);
    if (result) {
        const batchResultCallback = callback?.result?.();
        yield put(getBatchOperationProgress({
            data: [tenantId, result.batch_id],
            notificationId,
        }, { result: batchResultCallback }));
    }
}

function* destroyServers(action: ReturnType<typeof destroyServersAct>) {
    const { payload, payload: { data: [tenantId, serverIds], notificationId }, callback } = action;
    const response: IBatchOperationInfo = yield call(ServerApi.destroyServersBatch, ...payload.data);
    yield put(saveBatchOperationProgress({
        servers: serverIds.server_ids,
        type: SecurityActionType.SERVER_PUBLIC_KEY,
    }));
    const { result } = errorChecker<IBatchOperationInfo>(response);
    if (result) {
        const batchResultCallback = callback?.result?.();
        yield put(getBatchOperationProgress({
            data: [tenantId, result.batch_id],
            notificationId,
        }, { result: batchResultCallback }));
    }
}

function* serverSaga(): Generator {
    yield takeEvery<ReturnType<typeof getServerListAct>>(
        ServerActions.GetServerList, getServerList,
    );
    yield takeEvery<ReturnType<typeof getServerAct>>(
        ServerActions.GetServer, getServer,
    );
    yield takeEvery<ReturnType<typeof getServerForTenantAct>>(
        ServerActions.GetServerForTenant, getServerForTenant,
    );
    yield takeEvery<Action<Server>>(
        ServerActions.StartServer, startServer,
    );
    yield takeEvery<Action<Server>>(
        ServerActions.RebootServer, serverOperation('rebootServer'),
    );
    yield takeEvery<Action<Server>>(
        ServerActions.ResetServer, serverOperation('resetServer'),
    );
    yield takeEvery<Action<Server>>(
        ServerActions.ShutDownServer, serverOperation('shutdownServer'),
    );
    yield takeEvery<Action<Server>>(
        ServerActions.DestroyServer, serverOperation('destroyServer'),
    );
    yield takeEvery<ReturnType<typeof addIpAct>>(
        ServerActions.AddIp, addIp,
    );
    yield takeEvery<ReturnType<typeof removeIpAct>>(
        ServerActions.RemoveIp, removeIp,
    );
    yield takeEvery<ReturnType<typeof moveIpAct>>(
        ServerActions.MoveIp, moveIp,
    );
    yield takeEvery<ReturnType<typeof updateServerAct>>(
        ServerActions.UpdateServer, updateServer,
    );
    yield takeEvery<ReturnType<typeof deleteServerAct>>(
        ServerActions.DeleteServer, deleteServer,
    );
    yield takeEvery<ReturnType<typeof updateServerBackupPolicyAct>>(
        ServerActions.UpdateServerBackupPolicy, updateServerBackupPolicy,
    );
    yield takeEvery<ReturnType<typeof updateConfigurationAct>>(
        ServerActions.UpdateConfiguration, updateConfiguration,
    );
    yield takeEvery<ReturnType<typeof serverCloneAct>>(
        ServerActions.ServerClone, serverClone,
    );
    yield takeEvery<ReturnType<typeof updatePasswordAct>>(
        ServerActions.UpdatePassword, updatePassword,
    );
    yield takeEvery<ReturnType<typeof grantSupportAccessAct>>(
        ServerActions.GrantSupportAccess, grantSupportAccess,
    );
    yield takeEvery<ReturnType<typeof grantConsoleAccessAct>>(
        ServerActions.GrantConsoleAccess, grantConsoleAccess,
    );
    yield takeEvery<ReturnType<typeof forceInstallAct>>(
        ServerActions.ForceInstall, forceInstall,
    );
    yield takeEvery<ReturnType<typeof cancelInstallationAct>>(
        ServerActions.CancelInstallation, cancelInstallation,
    );
    yield takeEvery<ReturnType<typeof updateServerPublicKeysAct>>(
        ServerActions.UpdateServerPublicKeys, updateServerPublicKeys,
    );
    yield takeEvery<ReturnType<typeof installAct>>(
        ServerActions.Install, install,
    );
    yield takeEvery<ReturnType<typeof reinstallAct>>(
        ServerActions.Reinstall, reinstall,
    );
    yield takeEvery<ReturnType<typeof deleteServersAct>>(
        ServerActions.DeleteServers, deleteServers,
    );
    yield takeEvery<ReturnType<typeof serverCloneAct>>(
        ServerActions.GetServersLastOperations,
        getServersLastOperations,
    );
    yield takeEvery<ReturnType<typeof addPublicKeysAct>>(
        ServerActions.AddPublicKeys, addPublicKeys,
    );
    yield takeEvery<ReturnType<typeof rebootServersAct>>(
        ServerActions.RebootServers, rebootServers,
    );
    yield takeEvery<ReturnType<typeof resetServersAct>>(
        ServerActions.ResetServers, resetServers,
    );
    yield takeEvery<ReturnType<typeof startServersAct>>(
        ServerActions.StartServers, startServers,
    );
    yield takeEvery<ReturnType<typeof shutDownServersAct>>(
        ServerActions.ShutDownServers, shutDownServers,
    );
    yield takeEvery<ReturnType<typeof destroyServersAct>>(
        ServerActions.DestroyServers, destroyServers,
    );
    yield takeEvery<ReturnType<typeof updateServerKeysAct>>(
        ServerActions.UpdateServerKeys, updateServerKeys,
    );
    yield takeEvery<ReturnType<typeof updateServerRecommendationsAct>>(
        ServerActions.UpdateServerRecommendations, updateServerRecommendations,
    );
}

export default serverSaga;
