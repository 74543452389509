import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link, Icon, Button } from 'Common';
import { LinkParams, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import s from './Stats.module.pcss';

interface DashboardLink {
    to: RoutePath;
    props?: LinkParams;
}

const DashboardLink: FC<DashboardLink> = ({ to, props }) => {
    const intl = useIntl();

    return (
        <Link
            to={to}
            className={cn(s.link, s.link_header)}
            props={props}
        >
            <Button type="link" className={theme.button.aligned}>
                {intl.getMessage('go_to')}
                <Icon icon="arrow" className={s.arrow} />
            </Button>
        </Link>
    );
};

export default DashboardLink;
