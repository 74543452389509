import React, { FC, ChangeEvent, useState } from 'react';
import { Row, Col, Divider } from 'antd';
import cn from 'classnames';

import { CommonModalLayout, useIntl, Checkbox, NotificationSettingsHeader } from 'Common';
import { IRecipientDeliverySettings } from 'Entities/RecipientDeliverySettings';
import NotificationRecipient from 'Entities/NotificationRecipient';
import { NewTriggerFormKeys } from 'Lib/helpers/newTrigger';
import theme from 'Lib/theme';

interface RecipientRowProps {
    i?: number;
    name: string;
    data?: {
        delivery_sms: boolean;
        delivery_email: boolean;
    };
    onAll?: (key: 'delivery_email' | 'delivery_sms') => (checked: boolean) => void;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
const RecipientRow: FC<RecipientRowProps> = ({ data, name, i, handleChange, onAll }) => {
    return (
        <Row align="middle" gutter={[24, 16]} className="row row_nowrap">
            <Col flex="auto">
                <div className={cn(theme.text.medium, theme.text.f16)}>
                    {name}
                </div>
            </Col>
            <Col>
                <Checkbox
                    id={`recipient_settings.${i}.delivery_email`}
                    name={`recipient_settings.${i}.delivery_email`}
                    checked={!!data?.delivery_email}
                    handleChange={onAll
                        ? (e) => onAll('delivery_email')(e.target.checked)
                        : handleChange}
                />
            </Col>
            <Col>
                <Checkbox
                    id={`recipient_settings.${i}.delivery_sms`}
                    name={`recipient_settings.${i}.delivery_sms`}
                    checked={!!data?.delivery_sms}
                    handleChange={onAll
                        ? (e) => onAll('delivery_sms')(e.target.checked)
                        : handleChange}
                />
            </Col>
        </Row>
    );
};

interface EditNotificationsModalProps {
    visible: boolean;
    handleClose: () => void;
    recipients: NotificationRecipient[];
    value: IRecipientDeliverySettings[];
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    setFieldValue: (field: NewTriggerFormKeys, values: IRecipientDeliverySettings[]) => void;
}

const EditNotificationsModal: FC<EditNotificationsModalProps> = ({
    visible,
    handleClose,
    value,
    handleChange,
    recipients,
    setFieldValue,
}) => {
    const intl = useIntl();
    const [initialValue] = useState(value);

    const editNotifications = () => {
        handleClose();
    };
    const onCancel = () => {
        setFieldValue('recipient_settings', initialValue);
        handleClose();
    };

    const onAll = (key: 'delivery_email' | 'delivery_sms') => (checked: boolean) => {
        const newValues = value.map((v) => ({ ...v, [key]: checked }));
        setFieldValue('recipient_settings', newValues);
    };

    const all = value.reduce<{ delivery_email: boolean; delivery_sms: boolean }>(
        (prev, v) => {
            const newAll = {
                delivery_email: prev.delivery_email && v.delivery_email,
                delivery_sms: prev.delivery_sms && v.delivery_sms,
            };
            return newAll;
        },
        { delivery_email: true, delivery_sms: true },
    );

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('triggers_notifications')}
            buttonText={intl.getMessage('save')}
            handleSubmit={() => editNotifications()}
            handleClose={onCancel}
            width={600}
        >
            <div className={theme.modal.desc}>
                {intl.getMessage('triggers_notifications_desc')}
            </div>
            <NotificationSettingsHeader title={intl.getMessage('email')} />
            <Divider className={cn(theme.divider.divider, theme.divider.divider_dashed)} />
            <RecipientRow
                name={intl.getMessage('notifications_user_all')}
                onAll={onAll}
                data={all}
                handleChange={handleChange}
            />
            {value?.length > 0 && value.map((v, i) => {
                const recipient = recipients.find((r) => r.id === v.recipient_id);
                const name = recipient?.name
                    ? recipient.name
                    : recipient?.email || recipient?.phone?.fullNumber;
                return (
                    <RecipientRow
                        key={v.recipient_id}
                        i={i}
                        data={v}
                        name={name || ''}
                        handleChange={handleChange}
                    />
                );
            })}
        </CommonModalLayout>
    );
};

export default EditNotificationsModal;
