import React, { FC, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Tag } from 'antd';
import cn from 'classnames';

import { useIntl, Icon, Select, Option, ServerIndicator } from 'Common';
import { Store } from 'Store';
import Server from 'Entities/Server';
import { getServerIcon } from 'Lib/helpers/utils';
import { handleServerSearch } from 'Lib/helpers/search';
import theme from 'Lib/theme';

import s from '../NewTrigger.module.pcss';

interface ActionSpanStoreProps {
    servers: Map<number, Server>;
}

interface ActionSpanOwnProps {
    value: number[];
    error: boolean;
    disabled?: boolean;
    setValue?: (servers: number[]) => void;
}

type ActionSpanProps = ActionSpanStoreProps & ActionSpanOwnProps;

const ActionSpan: FC<ActionSpanProps> = ({ servers, value, setValue, error, disabled }) => {
    const intl = useIntl();
    const [options, setOptions] = useState(Array.from(servers.values()));

    const handleChange = (values: number[]) => {
        if (setValue) {
            setValue(values);
        }
    };

    const tagRender = (props: any) => {
        const { value: tagValue, closable, onClose } = props;
        const tagServer = servers.get(tagValue);

        return (
            <Tag
                closable={closable}
                onClose={onClose}
                className="tag"
                closeIcon={<Icon icon="close_small" />}
            >
                <span className="tag__value">
                    {tagServer?.name || `#${tagValue}`}
                </span>
            </Tag>
        );
    };

    const resetOptions = () => {
        setOptions(Array.from(servers.values()));
    };

    const onSearch = (inputValue: string) => {
        if (!inputValue) {
            resetOptions();
            return;
        }

        setOptions(handleServerSearch(inputValue, servers, intl.currentLocale));
    };

    return (
        <div className={s.group}>
            <div className={s.subtitle}>
                {intl.getMessage('triggers_action_span')}
            </div>
            <div className={s.desc}>
                {intl.getMessage('triggers_servers')}
            </div>
            <Select
                disabled={disabled}
                id="actionSpan"
                size="big"
                mode="multiple"
                value={value}
                error={error}
                onChange={handleChange}
                tagRender={tagRender}
                placeholder={intl.getMessage('all_virtual_server')}
                className="select_dm-cloud"
                filterOption={false}
                onSearch={onSearch}
                onBlur={() => resetOptions()}
                block
            >
                {servers.size > 0 && options.map((server) => (
                    <Option
                        key={server.id}
                        value={server.id}
                        className="select__option"
                    >
                        <div className={cn(theme.search.server, theme.search.server_select)}>
                            <ServerIndicator state={server.state} />
                            <Icon icon={getServerIcon(server.distribution.type)} />
                            <div
                                className={cn(
                                    theme.search.name,
                                    theme.search.name_server,
                                )}
                            >
                                {server.name}
                            </div>
                            <div className={theme.search.id}>
                                #{server.id}
                            </div>
                        </div>
                    </Option>
                ))}
            </Select>
        </div>
    );
};

const selectServers = (store: Store) => store.server;

const serversList = createSelector(
    selectServers,
    (map) => map,
);

const mapStateToProps = (store: Store) => ({
    servers: serversList(store),
});

export default connect(mapStateToProps)(ActionSpan);
