// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IMarkNotificationsSeenRequest {
    notification_ids?: number[];
}

export default class MarkNotificationsSeenRequest {
    readonly _notification_ids: number[] | undefined;

    /** */
    get notificationIds(): number[] | undefined {
        return this._notification_ids;
    }

    constructor(props: IMarkNotificationsSeenRequest) {
        if (props.notification_ids) {
            this._notification_ids = props.notification_ids;
        }
    }

    serialize(): IMarkNotificationsSeenRequest {
        const data: IMarkNotificationsSeenRequest = {
        };
        if (typeof this._notification_ids !== 'undefined') {
            data.notification_ids = this._notification_ids;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            notification_ids: !this._notification_ids ? true : this._notification_ids.reduce((result, p) => result && typeof p === 'number', true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IMarkNotificationsSeenRequest): MarkNotificationsSeenRequest {
        return new MarkNotificationsSeenRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        notificationIds: 'notification_ids',
        }
;

    mergeDeepWith(props: Partial<MarkNotificationsSeenRequest>): MarkNotificationsSeenRequest {
        const updateData: Partial<IMarkNotificationsSeenRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof MarkNotificationsSeenRequest) => {
            const updateKey = this.keys[key] as keyof IMarkNotificationsSeenRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IMarkNotificationsSeenRequest, keyof IMarkNotificationsSeenRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new MarkNotificationsSeenRequest({ ...this.serialize(), ...updateData });
    }
}
