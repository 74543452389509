import TicketPostAttachment, { ITicketPostAttachment } from './TicketPostAttachment';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITicketPost {
    attachments?: ITicketPostAttachment[];
    contents: string;
    email: string;
    send_time: string;
    send_time_millis: number;
    staff: boolean;
    unread: boolean;
}

export default class TicketPost {
    readonly _attachments: TicketPostAttachment[] | undefined;

    /** */
    get attachments(): TicketPostAttachment[] | undefined {
        return this._attachments;
    }

    readonly _contents: string;

    /**
     * Description: Contents
     * Example: Can't top up my balance via credit card
     */
    get contents(): string {
        return this._contents;
    }

    static contentsValidate(contents: string): boolean {
        return typeof contents === 'string' && !!contents.trim();
    }

    readonly _email: string;

    /**
     * Description: Client or support email
     * Example: email@gmail.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    readonly _send_time: string;

    /** */
    get sendTime(): string {
        return this._send_time;
    }

    static sendTimeValidate(sendTime: string): boolean {
        return typeof sendTime === 'string' && !!sendTime.trim();
    }

    readonly _send_time_millis: number;

    /**
     * Description: Send time, UTC millis
     * Example: 1593064584178
     */
    get sendTimeMillis(): number {
        return this._send_time_millis;
    }

    static sendTimeMillisValidate(sendTimeMillis: number): boolean {
        return typeof sendTimeMillis === 'number';
    }

    readonly _staff: boolean;

    /** */
    get staff(): boolean {
        return this._staff;
    }

    static staffValidate(staff: boolean): boolean {
        return typeof staff === 'boolean';
    }

    readonly _unread: boolean;

    /** */
    get unread(): boolean {
        return this._unread;
    }

    static unreadValidate(unread: boolean): boolean {
        return typeof unread === 'boolean';
    }

    constructor(props: ITicketPost) {
        if (props.attachments) {
            this._attachments = props.attachments.map((p) => new TicketPostAttachment(p));
        }
        this._contents = props.contents.trim();
        this._email = props.email.trim();
        this._send_time = props.send_time.trim();
        this._send_time_millis = props.send_time_millis;
        this._staff = props.staff;
        this._unread = props.unread;
    }

    serialize(): ITicketPost {
        const data: ITicketPost = {
            contents: this._contents,
            email: this._email,
            send_time: this._send_time,
            send_time_millis: this._send_time_millis,
            staff: this._staff,
            unread: this._unread,
        };
        if (typeof this._attachments !== 'undefined') {
            data.attachments = this._attachments.map((p) => p.serialize());
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            staff: typeof this._staff === 'boolean',
            send_time: typeof this._send_time === 'string' && !!this._send_time.trim(),
            send_time_millis: typeof this._send_time_millis === 'number',
            unread: typeof this._unread === 'boolean',
            email: typeof this._email === 'string' && !!this._email.trim(),
            contents: typeof this._contents === 'string' && !!this._contents.trim(),
            attachments: !this._attachments ? true : this._attachments.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITicketPost): TicketPost {
        return new TicketPost(props);
    }

    readonly keys: { [key: string]: string } = {
        attachments: 'attachments',
        contents: 'contents',
        email: 'email',
        sendTime: 'send_time',
        sendTimeMillis: 'send_time_millis',
        staff: 'staff',
        unread: 'unread',
        }
;

    mergeDeepWith(props: Partial<TicketPost>): TicketPost {
        const updateData: Partial<ITicketPost> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TicketPost) => {
            const updateKey = this.keys[key] as keyof ITicketPost;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITicketPost, keyof ITicketPost>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TicketPost({ ...this.serialize(), ...updateData });
    }
}
