import React, { FC } from 'react';
import { FormikErrors, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';

import { updateDnsRecord } from 'Actions/dns';
import { useIntl, CommonModalLayout } from 'Common';
import Error from 'Entities/Error';
import DnsRecord from 'Entities/DnsRecord';
import DnsZone from 'Entities/DnsZone';
import DnsRecordUpdate from 'Entities/DnsRecordUpdate';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';

import { Manual } from '../Records/components';
import { RecordFormValues } from '../Records/components/Forms';

interface AddDnsZoneProps {
    onClose: () => void;
    record: DnsRecord;
    zone: DnsZone;
}

const EditDnsRecord: FC<AddDnsZoneProps> = ({ onClose, record, zone }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const handleOk = (newRecord: DnsRecord, helpers: FormikHelpers<RecordFormValues>) => {
        const { zoneId, id } = record;
        const dnsUpdate = new DnsRecordUpdate(newRecord.serialize());
        dispatch(updateDnsRecord([zone.tenantId, zoneId, id, dnsUpdate.serialize()], {
            result: () => {
                onClose();
            },
            error: (error: Error) => {
                const errors: FormikErrors<RecordFormValues> = {};
                Object.keys(error.fields).forEach((key) => {
                    const fieldError = error.fields[key][0];
                    const data = key as keyof FormikErrors<RecordFormValues>;
                    if (data === 'pointsTo' as keyof FormikErrors<RecordFormValues>) {
                        errors.data = apiErrorCodeTranslate(intl, fieldError.error_code);
                    }
                    errors[data] = apiErrorCodeTranslate(intl, fieldError.error_code);
                });
                helpers.setErrors(errors);
            },
        }));
    };

    return (
        <CommonModalLayout
            noFooter
            visible
            title={intl.getMessage('records_edit')}
            handleClose={onClose}
            width={600}
        >
            <Manual
                onEdit={handleOk}
                closeForm={onClose}
                initialValue={record}
                zone={zone}
            />
        </CommonModalLayout>
    );
};

export default EditDnsRecord;
