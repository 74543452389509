import { DistributionFamily } from './DistributionFamily';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerDistribution {
    bitness: number;
    description: string;
    id: number;
    name: string;
    popular: boolean;
    type: DistributionFamily;
    version: string;
}

export default class ServerDistribution {
    readonly _bitness: number;

    /**
     * Description: Bitness arch
     * Example: 64
     */
    get bitness(): number {
        return this._bitness;
    }

    static bitnessValidate(bitness: number): boolean {
        return typeof bitness === 'number';
    }

    readonly _description: string;

    /**
     * Description: Full description
     * Example: Ubuntu 10.3 x64
     */
    get description(): string {
        return this._description;
    }

    static descriptionValidate(description: string): boolean {
        return typeof description === 'string' && !!description.trim();
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 28684
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _name: string;

    /**
     * Description: Short name
     * Example: Ubuntu
     */
    get name(): string {
        return this._name;
    }

    static nameValidate(name: string): boolean {
        return typeof name === 'string' && !!name.trim();
    }

    readonly _popular: boolean;

    /**
     * Description: Is popular
     * Example: true
     */
    get popular(): boolean {
        return this._popular;
    }

    static popularValidate(popular: boolean): boolean {
        return typeof popular === 'boolean';
    }

    readonly _type: DistributionFamily;

    get type(): DistributionFamily {
        return this._type;
    }

    static typeValidate(type: DistributionFamily): boolean {
        return Object.keys(DistributionFamily).includes(type);
    }

    readonly _version: string;

    /**
     * Description: Version
     * Example: 10.3
     */
    get version(): string {
        return this._version;
    }

    static versionValidate(version: string): boolean {
        return typeof version === 'string' && !!version.trim();
    }

    constructor(props: IServerDistribution) {
        this._bitness = props.bitness;
        this._description = props.description.trim();
        this._id = props.id;
        this._name = props.name.trim();
        this._popular = props.popular;
        this._type = props.type;
        this._version = props.version.trim();
    }

    serialize(): IServerDistribution {
        const data: IServerDistribution = {
            bitness: this._bitness,
            description: this._description,
            id: this._id,
            name: this._name,
            popular: this._popular,
            type: this._type,
            version: this._version,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            description: typeof this._description === 'string' && !!this._description.trim(),
            version: typeof this._version === 'string' && !!this._version.trim(),
            bitness: typeof this._bitness === 'number',
            popular: typeof this._popular === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerDistribution): ServerDistribution {
        return new ServerDistribution(props);
    }

    readonly keys: { [key: string]: string } = {
        bitness: 'bitness',
        description: 'description',
        id: 'id',
        name: 'name',
        popular: 'popular',
        type: 'type',
        version: 'version',
        }
;

    mergeDeepWith(props: Partial<ServerDistribution>): ServerDistribution {
        const updateData: Partial<IServerDistribution> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerDistribution) => {
            const updateKey = this.keys[key] as keyof IServerDistribution;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerDistribution, keyof IServerDistribution>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerDistribution({ ...this.serialize(), ...updateData });
    }
}
