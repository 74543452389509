import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { Grid } from 'antd';

import { useIntl, EmptyPageLayout } from 'Common';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import OperationProgress from 'Entities/OperationProgress';
import Server from 'Entities/Server';
import theme from 'Lib/theme';

import ServerItem from './ServerItem';

const { useBreakpoint } = Grid;

const LIST_SIZE = {
    MOBILE_WIDTH: 288,
    DESKTOP_WIDTH: 552,
    HEIGHT: 280,
    ITEM_HEIGHT: 48,
    ITEMS_IN_VIEW: 6,
};

interface ListProps {
    searchQuery: string;
    server: Server;
    servers: Server[];
    operationProgress?: OperationProgress;
    handleClose: () => void;
}

const List: FC<ListProps> = ({
    server, servers, searchQuery, operationProgress, handleClose,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const screens = useBreakpoint();

    const handleLinkClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        history.push(linkPathBuilder(intl.currentLocale, RoutePath.NewServer));
    };

    const handleClick = () => {
        handleClose();
        window.scrollTo({ top: 0 });
    };

    if (servers.length === 0 && searchQuery) {
        return (
            <EmptyPageLayout
                desc={intl.getMessage('search_empty')}
                linkText={intl.getMessage('create_server')}
                handleClick={handleLinkClick}
                type="search"
            />
        );
    }

    const listHeight = servers.length < LIST_SIZE.ITEMS_IN_VIEW
        ? servers.length * LIST_SIZE.ITEM_HEIGHT : LIST_SIZE.HEIGHT;

    let listWidth = LIST_SIZE.MOBILE_WIDTH;
    if (screens?.xl) {
        listWidth = LIST_SIZE.DESKTOP_WIDTH;
    }

    return (
        <div>
            <FixedSizeList
                width={listWidth}
                height={listHeight}
                itemSize={LIST_SIZE.ITEM_HEIGHT}
                itemCount={servers.length}
                itemData={servers}
                className={theme.common.customScrollbar}
            >
                {({ index, style, data }) => (
                    <div style={style}>
                        <ServerItem
                            server={data[index]}
                            searchQuery={searchQuery}
                            currentId={server.id}
                            operationProgress={operationProgress}
                            handleClick={handleClick}
                        />
                    </div>
                )}
            </FixedSizeList>
        </div>
    );
};

export default List;
