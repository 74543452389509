import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import { useHistory, withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { useTitle } from 'Hooks';
import NotificationsApi from 'Apis/notifications';
import { getNotificationRecipients, getTriggerRules } from 'Actions/notifications';
import { InnerPageLayout, useIntl, Icon, BackHeader, notifyError, Select, Option, Warning } from 'Common';
import NotificationRecipient from 'Entities/NotificationRecipient';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import { ITriggerCreate } from 'Entities/TriggerCreate';
import Trigger, { ITrigger } from 'Entities/Trigger';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { errorChecker } from 'Lib/helpers/utils';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { apiErrorCodeTranslate, triggerFormTypeTranslate } from 'Lib/helpers/translationHelper';
import NewTriggerHelper, { NewTriggerFormKeys } from 'Lib/helpers/newTrigger';
import theme from 'Lib/theme';
import { Store } from 'Store';

import {
    CpuMonitoringForm,
    CriticalBalanceForm,
    DailyExpensesForm,
    DiskSpaceForm,
    HttpUrlAvailabilityForm,
    InodesUsageForm,
    LoadAverageForm,
    PingForm,
    TrafficTransferedUsageForm,
    TrafficRecivedUsageForm,
    HttpsCertAvailabilityForm,
    DomainExpirationForm,
} from './components';
import s from './NewTrigger.module.pcss';

type FormType = keyof typeof TriggerRuleType;

interface NewTriggerStoreProps {
    recipients: NotificationRecipient[];
    trigger?: Trigger;
}

const NewTrigger: FC<NewTriggerStoreProps> = ({ recipients, trigger }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('notification_new_triggers_page_title'));
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(getTriggerRules());
        dispatch(getNotificationRecipients());
    }, []);
    const onSubmit = (
        keys: NewTriggerFormKeys[],
    ) => async (v: ITriggerCreate, helpers: FormikHelpers<ITriggerCreate>) => {
        const { setErrors, setSubmitting } = helpers;
        const allFields: NewTriggerFormKeys[] = ['value', 'period_minutes', 'url'];
        const values = { ...v, value: Number(v.value), period_minutes: Number(v.period_minutes) };
        const validateFields: NewTriggerFormKeys[] = [];
        allFields.forEach((k) => {
            if (!keys.includes(k)) {
                validateFields.push(k);
            }
        });
        if (!NewTriggerHelper.validate(values, setErrors, validateFields)) {
            return;
        }
        const req = { ...values };
        keys.forEach((k) => {
            delete req[k];
        });
        let response = null;
        if (trigger) {
            delete (req as any).type;
            response = await NotificationsApi.updateTriggerRule(trigger.id, req);
        } else {
            response = await NotificationsApi.createTriggerRule(req);
        }
        const { result, error } = errorChecker<ITrigger>(response);
        if (result) {
            dispatch(getTriggerRules());
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.Triggers));
        } else if (error) {
            setSubmitting(false);
            const errors: Partial<Record<NewTriggerFormKeys, string>> = {};
            const fieldKeys = Object.keys(error.fields);
            fieldKeys.forEach((k) => {
                errors[k as NewTriggerFormKeys] = EMPTY_FIELD_ERROR;
            });
            if (fieldKeys.length === 0) {
                notifyError(apiErrorCodeTranslate(intl, error.errorCode));
            }
            setErrors(errors);
        }
    };
    const [
        selectedType,
        setSelectedType,
    ] = useState(trigger?.type || TriggerRuleType.DAILY_EXPENSE);

    useEffect(() => {
        if (trigger && selectedType !== trigger.type) {
            setSelectedType(trigger.type);
        }
    }, [trigger]);

    const getForm = (type: FormType): JSX.Element => {
        const components: Partial<Record<FormType, JSX.Element>> = {
            [TriggerRuleType.CPU_USAGE]: (
                <CpuMonitoringForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.MIN_BALANCE]: (
                <CriticalBalanceForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.DAILY_EXPENSE]: (
                <DailyExpensesForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.DISK_FREE_SPACE]: (
                <DiskSpaceForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.HTTP]: (
                <HttpUrlAvailabilityForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.HTTPS_SSL_CERT]: (
                <HttpsCertAvailabilityForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.INODES_USE]: (
                <InodesUsageForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.LOAD_AVERAGE]: (
                <LoadAverageForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.PING]: (
                <PingForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.RECEIVED_TRAFFIC_USAGE]: (
                <TrafficRecivedUsageForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.TRANSFERRED_TRAFFIC_USAGE]: (
                <TrafficTransferedUsageForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
            [TriggerRuleType.DOMAIN_EXPIRATION]: (
                <DomainExpirationForm
                    trigger={trigger}
                    type={selectedType}
                    recipients={recipients}
                    onSubmit={onSubmit}
                />
            ),
        };
        return components[type]!;
    };

    return (
        <InnerPageLayout
            header={<BackHeader type="narrow" link={RoutePath.Triggers} />}
            className={theme.content.create}
        >
            <h1 className={s.title}>
                <Icon icon="new_trigger" className={s.icon} />
                {trigger ? intl.getMessage('triggers_update') : intl.getMessage('triggers_create')}
            </h1>

            {selectedType === TriggerRuleType.PING && (
                <Warning text={intl.getMessage('triggers_windows_ping_warning')} className={s.warning} />
            )}

            <div className={cn(s.group, s.group_main, s.group_input)}>
                <div className={s.subtitle}>
                    {intl.getMessage('triggers_metric')}
                </div>
                <div className={s.desc}>
                    {intl.getMessage('triggers_metric_desc')}
                </div>
                <Select
                    id="type"
                    size="big"
                    data-name="selectTriggerType"
                    value={selectedType}
                    onChange={(value) => setSelectedType(value)}
                    disabled={!!trigger}
                    className="select_dm-cloud"
                    showScroll
                    block
                >
                    {Object.values(TriggerRuleType).filter((r) => !(r.indexOf('VM_') + 1)).map((type) => (
                        <Option key={type} value={type}>
                            {triggerFormTypeTranslate(intl, type)}
                        </Option>
                    ))}
                </Select>
            </div>
            <div key={recipients.length}>
                {getForm(selectedType)}
            </div>
        </InnerPageLayout>
    );
};

const getTrigger = (store: Store, ownProps: RouteComponentProps<{id: string}>) => {
    const { match: { params: { id } } } = ownProps;
    return store.notifications.triggers?.find((t) => String(t.id) === id);
};
const selectRecipients = (store: Store) => {
    return store.notifications.recipients;
};

const selector = createSelector(
    [selectRecipients, getTrigger],
    (recipients, trigger) => ({ recipients, trigger }),
);

const mapStoreToProps = (store: Store, ownProps: RouteComponentProps<{id: string}>) => ({
    ...selector(store, ownProps),
});

export default withRouter(connect(mapStoreToProps)(NewTrigger));
