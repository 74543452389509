import { Action } from 'store/actions';
import { TariffActions, saveTariffsList } from 'Actions/tariff';
import Tariff from 'Entities/Tariff';

import { TariffStore, tariffInitialState as initialState } from '../initialStates';

const reducer = (state: TariffStore = initialState, action: Action): TariffStore => {
    switch (action.type as TariffActions) {
        case TariffActions.SaveTariffs: {
            const { payload } = action as ReturnType<typeof saveTariffsList>;
            const tu = new Map<number, Tariff>();
            payload.forEach((s) => {
                tu.set(s.tariffId, s);
            });
            return tu;
        }
        default:
            return state;
    }
};

export default reducer;
