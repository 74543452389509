// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IRegistrationConfirm {
    code: string;
}

export default class RegistrationConfirm {
    readonly _code: string;

    /**
     * Description: Confirmation registration code
     * Example: 123e4567e89b12d3a456426655440000
     */
    get code(): string {
        return this._code;
    }

    static codeValidate(code: string): boolean {
        return typeof code === 'string' && !!code.trim();
    }

    constructor(props: IRegistrationConfirm) {
        this._code = props.code.trim();
    }

    serialize(): IRegistrationConfirm {
        const data: IRegistrationConfirm = {
            code: this._code,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            code: typeof this._code === 'string' && !!this._code.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IRegistrationConfirm): RegistrationConfirm {
        return new RegistrationConfirm(props);
    }

    readonly keys: { [key: string]: string } = {
        code: 'code',
        }
;

    mergeDeepWith(props: Partial<RegistrationConfirm>): RegistrationConfirm {
        const updateData: Partial<IRegistrationConfirm> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof RegistrationConfirm) => {
            const updateKey = this.keys[key] as keyof IRegistrationConfirm;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IRegistrationConfirm, keyof IRegistrationConfirm>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new RegistrationConfirm({ ...this.serialize(), ...updateData });
    }
}
