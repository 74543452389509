import React, { FC } from 'react';

import { useIntl } from 'Common';
import Tariff from 'Entities/Tariff';
import { toGb } from 'Lib/helpers/helpers';

import s from './Tariff.module.pcss';

interface StandardTariffProps {
    tariff: Tariff;
}

const StandardTariff: FC<StandardTariffProps> = ({ tariff }) => {
    const {
        memoryMib,
        cpuCores,
        diskGib,
        includedTrafficGib,
        includedIpCount,
    } = tariff;
    const intl = useIntl();

    const rows = [
        {
            label: intl.getMessage('processor'),
            value: intl.getPlural('core', cpuCores),
        },
        {
            label: intl.getMessage('memory'),
            value: intl.getMessage('value_gb', { value: toGb(memoryMib) }),
        },
        {
            label: intl.getMessage('disk'),
            value: intl.getMessage('value_gb', { value: diskGib }),
        },
        {
            label: intl.getMessage('traffic'),
            value: intl.getMessage('value_gb_per_day', { value: includedTrafficGib }),
        },
        {
            label: intl.getMessage('public_ip'),
            value: includedIpCount,
        },
    ];

    return (
        <div className={s.rows}>
            {rows.map(({ label, value }) => (
                <div className={s.row} key={label}>
                    <div className={s.label}>
                        {label}
                    </div>
                    <div className={s.value}>
                        {value}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default StandardTariff;
