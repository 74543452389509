import React, { FC, useState, ChangeEvent } from 'react';
import { Button } from 'antd';
import cn from 'classnames';

import { useIntl } from 'Common';
import { IRecipientDeliverySettings } from 'Entities/RecipientDeliverySettings';
import NotificationRecipient from 'Entities/NotificationRecipient';
import theme from 'Lib/theme';
import { NewTriggerFormKeys } from 'Lib/helpers/newTrigger';

import EditNotificationsModal from './EditNotificationsModal';
import s from '../NewTrigger.module.pcss';

interface TriggerNotificationsOwnProps {
    value: IRecipientDeliverySettings[];
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    setFieldValue: (field: NewTriggerFormKeys, values: IRecipientDeliverySettings[]) => void;
    recipients: NotificationRecipient[];
}
type TriggerNotificationsProps = TriggerNotificationsOwnProps;

const TriggerNotifications: FC<TriggerNotificationsProps> = ({
    handleChange,
    value,
    recipients,
    setFieldValue,
}) => {
    const intl = useIntl();

    let labelText = '';
    let isDefault = false;
    const selectedRecipients = value.filter((v) => v.delivery_sms || v.delivery_email).map((v) => {
        const data = recipients.find((r) => r.id === v.recipient_id);
        if (data?.isDefault) {
            isDefault = true;
        }
        return { ...v, default: !!data?.isDefault };
    });
    if (selectedRecipients.length > 1) {
        if (isDefault) {
            labelText = `${intl.getMessage('you')} ${intl.getMessage('new_trigger_and_also')} ${intl.getPlural('new_trigger_other_users', selectedRecipients.length - 1)}`;
        } else {
            labelText = intl.getPlural('new_trigger_other_users', selectedRecipients.length);
        }
    } else if (selectedRecipients.length === 1) {
        labelText = isDefault ? intl.getMessage('you') : intl.getPlural('new_trigger_other_users', 1);
    } else {
        labelText = intl.getMessage('new_trigger_noone');
    }

    const [openNotificationsModal, setOpenNotificationsModal] = useState(false);

    return (
        <div className={cn(s.group, s.group_last)}>
            <div className={s.subtitle}>
                {intl.getMessage('notifications')}
            </div>
            <div className={s.desc}>
                {intl.getMessage('triggers_notifications_desc')}
            </div>
            <Button
                size="large"
                className={cn(
                    theme.button.button,
                    theme.button.input,
                )}
                onClick={() => setOpenNotificationsModal(true)}
            >
                {labelText}
            </Button>

            {openNotificationsModal && (
                <EditNotificationsModal
                    visible={openNotificationsModal}
                    handleClose={() => setOpenNotificationsModal(false)}
                    handleChange={handleChange}
                    value={value}
                    recipients={recipients}
                    setFieldValue={setFieldValue}
                />
            )}
        </div>
    );
};

export default TriggerNotifications;
