import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { resetServer, destroyServer } from 'Actions/server';
import { useUISettings } from 'Hooks';
import { useIntl } from 'Common';
import Server from 'Entities/Server';
import { ServerState } from 'Entities/ServerState';
import { OperationType } from 'Entities/OperationType';
import { SERVER_OPERATION_NOTIFICATION } from 'Lib/helpers/consts';

import {
    DestroyModal,
    ResetModal,
    ActionButton,
} from '.';

interface LoadingControlsProps {
    server: Server;
    close: () => void;
    type?: OperationType;
}

const LoadingControls: FC<LoadingControlsProps> = ({ server, close, type }) => {
    const { operationNotifications } = useUISettings();
    const [openOperationModal, setOpenOperationModal] = useState({
        [SERVER_OPERATION_NOTIFICATION.RESET]: false,
        [SERVER_OPERATION_NOTIFICATION.DESTROY]: false,
    });
    const intl = useIntl();
    const dispatch = useDispatch();
    const onOperation: Record<keyof typeof openOperationModal, () => void> = {
        [SERVER_OPERATION_NOTIFICATION.RESET]: () => {
            dispatch(resetServer(server));
            close();
        },
        [SERVER_OPERATION_NOTIFICATION.DESTROY]: () => {
            dispatch(destroyServer(server));
            close();
        },
    };
    const closeModal = (modal: keyof typeof openOperationModal) => () => {
        setOpenOperationModal({ ...openOperationModal, [modal]: false });
    };

    const checkModalShow = (modal: keyof typeof openOperationModal) => () => {
        if (!operationNotifications[modal]) {
            onOperation[modal]();
        } else {
            setOpenOperationModal({ ...openOperationModal, [modal]: true });
        }
    };

    const showReset = type === OperationType.REBOOT;
    const showDestroy = server.state !== ServerState.SHUTDOWN
        && server.state !== ServerState.SHUTOFF;

    return (
        <>
            {showReset && (
                <ActionButton
                    id="on_reset"
                    handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.RESET)}
                    title={intl.getMessage('server_force_reboot')}
                />
            )}
            {showDestroy && (
                <ActionButton
                    id="on_destroy"
                    handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.DESTROY)}
                    title={intl.getMessage('server_force_shutdown')}
                />
            )}
            {openOperationModal.resetNotification && (
                <ResetModal
                    visible
                    close={closeModal(SERVER_OPERATION_NOTIFICATION.RESET)}
                    handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.RESET]}
                />
            )}
            {openOperationModal.destroyNotification && (
                <DestroyModal
                    visible
                    close={closeModal(SERVER_OPERATION_NOTIFICATION.DESTROY)}
                    handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.DESTROY]}
                />
            )}
        </>
    );
};

export default LoadingControls;
