// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICreateSoleTrader {
    sole_accounting_email?: string;
    sole_inn: string;
    sole_legal_address: string;
    sole_ogrnip: string;
    sole_post_address: string;
    sole_trademark: string;
}

export default class CreateSoleTrader {
    readonly _sole_accounting_email: string | undefined;

    /**
     * Description: Sole trader payer OGRNIP
     * Example: kartseva.elena@rambler.ru
     */
    get soleAccountingEmail(): string | undefined {
        return this._sole_accounting_email;
    }

    readonly _sole_inn: string;

    /**
     * Description: Sole trader payer INN parameter
     * Example: 123456789012
     */
    get soleInn(): string {
        return this._sole_inn;
    }

    static soleInnValidate(soleInn: string): boolean {
        return typeof soleInn === 'string' && !!soleInn.trim();
    }

    readonly _sole_legal_address: string;

    /**
     * Description: Sole trader payer legal address
     * Example: 123456, Россия, Москва, ул. Правды, д.11, корп.1, кв.11
     */
    get soleLegalAddress(): string {
        return this._sole_legal_address;
    }

    static soleLegalAddressValidate(soleLegalAddress: string): boolean {
        return typeof soleLegalAddress === 'string' && !!soleLegalAddress.trim();
    }

    readonly _sole_ogrnip: string;

    /**
     * Description: Sole trader payer OGRNIP
     * Example: 313784708700646
     */
    get soleOgrnip(): string {
        return this._sole_ogrnip;
    }

    static soleOgrnipValidate(soleOgrnip: string): boolean {
        return typeof soleOgrnip === 'string' && !!soleOgrnip.trim();
    }

    readonly _sole_post_address: string;

    /**
     * Description: Sole trader payer post address
     * Example: 123456, Россия, Москва, ул. Правды, д.11, корп.1, кв.11
     */
    get solePostAddress(): string {
        return this._sole_post_address;
    }

    static solePostAddressValidate(solePostAddress: string): boolean {
        return typeof solePostAddress === 'string' && !!solePostAddress.trim();
    }

    readonly _sole_trademark: string;

    /**
     * Description: Sole trader payer trademark
     * Example: КАРЦЕВА ЕЛЕНА ВИКТОРОВНА
     */
    get soleTrademark(): string {
        return this._sole_trademark;
    }

    static soleTrademarkValidate(soleTrademark: string): boolean {
        return typeof soleTrademark === 'string' && !!soleTrademark.trim();
    }

    constructor(props: ICreateSoleTrader) {
        if (typeof props.sole_accounting_email === 'string') {
            this._sole_accounting_email = props.sole_accounting_email.trim();
        }
        this._sole_inn = props.sole_inn.trim();
        this._sole_legal_address = props.sole_legal_address.trim();
        this._sole_ogrnip = props.sole_ogrnip.trim();
        this._sole_post_address = props.sole_post_address.trim();
        this._sole_trademark = props.sole_trademark.trim();
    }

    serialize(): ICreateSoleTrader {
        const data: ICreateSoleTrader = {
            sole_inn: this._sole_inn,
            sole_legal_address: this._sole_legal_address,
            sole_ogrnip: this._sole_ogrnip,
            sole_post_address: this._sole_post_address,
            sole_trademark: this._sole_trademark,
        };
        if (typeof this._sole_accounting_email !== 'undefined') {
            data.sole_accounting_email = this._sole_accounting_email;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            sole_inn: typeof this._sole_inn === 'string' && !!this._sole_inn.trim(),
            sole_trademark: typeof this._sole_trademark === 'string' && !!this._sole_trademark.trim(),
            sole_ogrnip: typeof this._sole_ogrnip === 'string' && !!this._sole_ogrnip.trim(),
            sole_legal_address: typeof this._sole_legal_address === 'string' && !!this._sole_legal_address.trim(),
            sole_post_address: typeof this._sole_post_address === 'string' && !!this._sole_post_address.trim(),
            sole_accounting_email: !this._sole_accounting_email ? true : typeof this._sole_accounting_email === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICreateSoleTrader): CreateSoleTrader {
        return new CreateSoleTrader(props);
    }

    readonly keys: { [key: string]: string } = {
        soleAccountingEmail: 'sole_accounting_email',
        soleInn: 'sole_inn',
        soleLegalAddress: 'sole_legal_address',
        soleOgrnip: 'sole_ogrnip',
        solePostAddress: 'sole_post_address',
        soleTrademark: 'sole_trademark',
        }
;

    mergeDeepWith(props: Partial<CreateSoleTrader>): CreateSoleTrader {
        const updateData: Partial<ICreateSoleTrader> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CreateSoleTrader) => {
            const updateKey = this.keys[key] as keyof ICreateSoleTrader;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICreateSoleTrader, keyof ICreateSoleTrader>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CreateSoleTrader({ ...this.serialize(), ...updateData });
    }
}
