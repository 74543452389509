import { ApiErrorCode } from './ApiErrorCode';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IError {
    error_code: ApiErrorCode;
    fields: any;
    message?: string;
}

export default class Error {
    readonly _error_code: ApiErrorCode;

    get errorCode(): ApiErrorCode {
        return this._error_code;
    }

    static errorCodeValidate(errorCode: ApiErrorCode): boolean {
        return Object.keys(ApiErrorCode).includes(errorCode);
    }

    readonly _fields: any;

    /** */
    get fields(): any {
        return this._fields;
    }

    static fieldsValidate(fields: any): boolean {
        return true;
    }

    readonly _message: string | undefined;

    /** */
    get message(): string | undefined {
        return this._message;
    }

    constructor(props: IError) {
        this._error_code = props.error_code;
        this._fields = props.fields;
        if (typeof props.message === 'string') {
            this._message = props.message.trim();
        }
    }

    serialize(): IError {
        const data: IError = {
            error_code: this._error_code,
            fields: this._fields,
        };
        if (typeof this._message !== 'undefined') {
            data.message = this._message;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            message: !this._message ? true : typeof this._message === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IError): Error {
        return new Error(props);
    }

    readonly keys: { [key: string]: string } = {
        errorCode: 'error_code',
        fields: 'fields',
        message: 'message',
        }
;

    mergeDeepWith(props: Partial<Error>): Error {
        const updateData: Partial<IError> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Error) => {
            const updateKey = this.keys[key] as keyof IError;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IError, keyof IError>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Error({ ...this.serialize(), ...updateData });
    }
}
