import pubKeysApi from 'Apis/pubKeys';

import PublicKey from 'Entities/PublicKey';

import { actionCreator } from '.';

export enum PublicKeysActions {
    GetPublicKeys = 'GetPublicKeys',
    SavePublicKeys = 'SavePublicKeys',
    CreatePublicKey = 'CreatePublicKey',
    DeletePublicKey = 'DeletePublicKey',
    UpdatePublicKey = 'UpdatePublicKey',
}

const getPublicKeys = actionCreator(PublicKeysActions.GetPublicKeys);
const savePublicKeys = actionCreator<PublicKey[]>(PublicKeysActions.SavePublicKeys);

type CreatePublicKey = Parameters<typeof pubKeysApi.createPublicKey>;
const createPublicKey = actionCreator<CreatePublicKey>(PublicKeysActions.CreatePublicKey);

type DeletePublicKey = Parameters<typeof pubKeysApi.deletePublicKey>;
const deletePublicKey = actionCreator<DeletePublicKey>(PublicKeysActions.DeletePublicKey);

type UpdatePublicKey = Parameters<typeof pubKeysApi.updatePublicKey>;
const updatePublicKey = actionCreator<UpdatePublicKey>(PublicKeysActions.UpdatePublicKey);

export {
    getPublicKeys,
    savePublicKeys,
    createPublicKey,
    deletePublicKey,
    updatePublicKey,
};
