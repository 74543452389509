import React, { FC, useEffect } from 'react';
import { Layout } from 'antd';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { useTitle } from 'Hooks';
import Form from 'Common/Requisites/Form';
import { useIntl, SimpleHeader } from 'Common';
import PayerInfo from 'Entities/PayerInfo';
import theme from 'Lib/theme';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';

import { Submit } from './components';

const { Content } = Layout;

interface OnboardingProps {
    payer: PayerInfo[] | null;
}
const Onboarding: FC<OnboardingProps> = ({ payer }) => {
    const history = useHistory();
    const intl = useIntl();
    useTitle(intl.getMessage('onboarding_page_title'));
    useEffect(() => {
        window.scrollTo({ top: 0 });
        if (payer && payer.length > 0) {
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
        }
    }, [payer]);
    return (
        <Layout>
            <SimpleHeader />
            <Content className={cn(theme.content.content, theme.content.onboarding)}>
                <Form roundBorder Submit={Submit} />
            </Content>
        </Layout>
    );
};
const selectProfilePayer = (store: Store) => store.profile.profilePayer;
const selector = createSelector([
    selectProfilePayer,
], (payer) => ({
    payer,
}));

const mapStateToProps = (store: Store) => {
    return { ...selector(store) };
};
export default connect(mapStateToProps)(Onboarding);
