// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServersCommonPublicKeysRequest {
    server_ids: number[];
}

export default class ServersCommonPublicKeysRequest {
    readonly _server_ids: number[];

    /** */
    get serverIds(): number[] {
        return this._server_ids;
    }

    static serverIdsValidate(serverIds: number[]): boolean {
        return serverIds.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    constructor(props: IServersCommonPublicKeysRequest) {
        this._server_ids = props.server_ids;
    }

    serialize(): IServersCommonPublicKeysRequest {
        const data: IServersCommonPublicKeysRequest = {
            server_ids: this._server_ids,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            server_ids: this._server_ids.reduce((result, p) => result && typeof p === 'number', true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServersCommonPublicKeysRequest): ServersCommonPublicKeysRequest {
        return new ServersCommonPublicKeysRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        serverIds: 'server_ids',
        }
;

    mergeDeepWith(props: Partial<ServersCommonPublicKeysRequest>): ServersCommonPublicKeysRequest {
        const updateData: Partial<IServersCommonPublicKeysRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServersCommonPublicKeysRequest) => {
            const updateKey = this.keys[key] as keyof IServersCommonPublicKeysRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServersCommonPublicKeysRequest, keyof IServersCommonPublicKeysRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServersCommonPublicKeysRequest({ ...this.serialize(), ...updateData });
    }
}
