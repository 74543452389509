import React, { FC } from 'react';
import { FormikErrors } from 'formik';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { nanoid } from 'nanoid';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { useIntl, Button, CodeInput } from 'Common';
import { NewServerFormValues } from 'Lib/helpers/newServer';
import ServerCostForecast from 'Entities/ServerCostForecast';
import ServerDistribution from 'Entities/ServerDistribution';
import Tariff from 'Entities/Tariff';
import { Store } from 'Store';
import theme from 'Lib/theme';

import s from './Result.module.pcss';
import Notice from './Notice';

interface ResultStoreProps {
    distribution: Map<number, ServerDistribution>;
    tariffs: Map<number, Tariff>;
}

interface ResultOwnProps {
    errors: FormikErrors<NewServerFormValues>;
    values: NewServerFormValues;
    price: ServerCostForecast | null;
    codeSent: boolean;
    deliveryMessage: string;
    isSubmitting: boolean;
    isReinstall: boolean;
    id?: number;
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
    setFieldError: (field: keyof NewServerFormValues, message: string) => void;
    sendConfirmationCode: (data: { tenantId: number; serverId?: number }) => void;
}

type ResultProps = ResultStoreProps & ResultOwnProps;

const Result: FC<ResultProps> = ({
    id,
    errors,
    values,
    price,
    codeSent,
    deliveryMessage,
    isSubmitting,
    isReinstall,
    tariffs,
    setFieldValue,
    setFieldError,
    sendConfirmationCode,
}) => {
    const intl = useIntl();

    const getButtonTitle = () => {
        if (isReinstall) {
            return intl.getMessage('server_reinstall');
        }

        return (
            <>
                <div className={s.desktop}>
                    {intl.getMessage('create')}
                </div>
                <div className={s.mobile}>
                    {intl.getMessage('new_server_create')}
                </div>
            </>
        );
    };

    const flexibleTariffNotice = (
        <Tooltip
            placement="left"
            title={intl.getMessage('flexible_tariff_notice')}
            overlayClassName="tooltip tooltip_tariff"
        >
            <div
                className={cn(
                    theme.Badge.badge,
                    theme.Badge.question,
                    s.badge,
                )}
            />
        </Tooltip>
    );

    const getPriceValue = (
        tariff: Tariff | undefined,
        monthly: boolean,
    ) => {
        if (!price || !tariff) {
            return null;
        }

        if (tariff.fixed) {
            return monthly ? intl.getPlural('price', price.overallCost) : intl.getPlural('price', price.dailyOverallCost);
        }

        if (monthly) {
            return (
                <>
                    {intl.getMessage('price_from', { value: price.overallCost })}
                    {flexibleTariffNotice}
                </>
            );
        }

        return (
            <>
                {intl.getMessage('price_from', { value: price.dailyOverallCost })}
                {flexibleTariffNotice}
            </>
        );
    };

    const getPriceRows = () => {
        const { monthly, tariff_id: tariffId } = values;
        const currentTariff = tariffs.get(tariffId);

        if (!price || !currentTariff) {
            return null;
        }

        const {
            memoryCost,
            dailyMemoryCost,
            diskCost,
            dailyDiskCost,
            ipCost,
            dailyIpCost,
            backupCost,
            dailyBackupCost,
            fixedCost,
            dailyFixedCost,
            cpuCostMax,
        } = price;

        const { prices: { rxPriceGib, txPriceGib } } = currentTariff;

        const { ip_count: ipCount } = values;

        const flexibleTariffRows = [
            {
                label: intl.getMessage('memory'),
                value: intl.getMessage('price_simple', { value: monthly ? memoryCost : dailyMemoryCost }),
            },
            {
                label: intl.getMessage('disk'),
                value: intl.getMessage('price_simple', { value: monthly ? diskCost : dailyDiskCost }),
            },
            {
                label: intl.getMessage('processor'),
                value: intl.getMessage('price_max', { value: cpuCostMax }),
            },
            {
                label: intl.getMessage('new_server_inbound_traffic_short'),
                value: intl.getMessage('price_simple', { value: rxPriceGib }),
            },
            {
                label: intl.getMessage('new_server_outbound_traffic_short'),
                value: intl.getMessage('price_simple', { value: txPriceGib }),
            },
            {
                label: intl.getPlural('tariff_ip', ipCount),
                value: intl.getMessage('price_simple', { value: monthly ? ipCost : dailyIpCost }),
            },
            {
                label: intl.getMessage('backup'),
                value: intl.getMessage('price_simple', { value: monthly ? backupCost : dailyBackupCost }),
            },
        ];

        const fixedTariffRows = [
            {
                label: intl.getMessage('tariff'),
                value: intl.getMessage('price_simple', { value: monthly ? fixedCost : dailyFixedCost }),
            },
            {
                label: intl.getMessage('new_server_inbound_traffic_short'),
                subtitle: intl.getMessage('over_limit'),
                value: intl.getMessage('price_simple', { value: rxPriceGib }),
            },
            {
                label: intl.getMessage('new_server_outbound_traffic_short'),
                subtitle: intl.getMessage('over_limit'),
                value: intl.getMessage('price_simple', { value: txPriceGib }),
            },
            {
                label: intl.getMessage('backup'),
                value: intl.getMessage('price_simple', { value: monthly ? backupCost : dailyBackupCost }),
            },
        ];

        const rows: { label: string; value: string; subtitle?: string }[] = currentTariff?.fixed
            ? fixedTariffRows : flexibleTariffRows;

        return (
            rows.map((row) => (
                <div className={s.row} key={nanoid()}>
                    <div className={s.label}>
                        {row.label}
                        {row.subtitle && (
                            <div className={s.subtitle}>
                                {row.subtitle}
                            </div>
                        )}
                    </div>
                    <div className={s.value}>
                        {row.value}
                    </div>
                </div>
            ))
        );
    };

    const getCard = () => {
        const { monthly, tariff_id: tariffId } = values;
        const currentTariff = tariffs.get(tariffId);

        return (
            <div className={s.card}>
                <div className={s.wrap}>
                    <div className={s.title}>
                        {intl.getMessage('final')}
                        {currentTariff && (
                            <Notice currentTariff={currentTariff} />
                        )}
                    </div>

                    <div
                        className={cn(
                            s.rows,
                            { [s.rows_fixed]: currentTariff?.fixed },
                        )}
                    >
                        {getPriceRows()}
                    </div>
                </div>

                <div className={s.final}>
                    <label htmlFor="monthly" className={s.checkbox}>
                        <input
                            id="monthly"
                            type="checkbox"
                            className={s.checkboxInput}
                            checked={monthly}
                            onChange={(e) => setFieldValue('monthly', e.target.checked)}
                        />
                        <span className={s.checkboxLabel}>
                            {monthly ? (
                                intl.getMessage('every_month')
                            ) : (
                                intl.getMessage('every_day')
                            )}
                        </span>
                    </label>
                    {getPriceValue(currentTariff, monthly)}
                </div>
            </div>
        );
    };

    return (
        <div className={s.result}>
            {getCard()}

            {(codeSent && deliveryMessage) && (
                <div className={cn(s.card, s.confirm)}>
                    <div className={s.desc}>
                        {deliveryMessage}
                    </div>
                    <CodeInput
                        value={values.code}
                        setValue={(e) => setFieldValue('code', e)}
                        codeError={!!errors.code}
                        setCodeError={(e) => setFieldError('code', e ? 'error' : '')}
                        onSendAgain={() => sendConfirmationCode({
                            tenantId: values.tenant_id,
                            serverId: id,
                        })}
                    />
                </div>
            )}

            <Button
                id={isReinstall ? 'reinstall_server' : 'create_server'}
                size="big"
                type="primary"
                htmlType="submit"
                className={s.submit}
                disabled={isSubmitting}
            >
                {getButtonTitle()}
                {typeof price?.overallCost === 'number' && (
                    <div className={s.total}>
                        {intl.getMessage('cost', { value: price.overallCost })}
                    </div>
                )}
            </Button>
        </div>
    );
};

const selectDistribution = (store: Store) => store.serverDistribution;
const selectTariffs = (store: Store) => store.tariff;

const selector = createSelector(
    [selectDistribution, selectTariffs], (distribution, tariffs) => ({ distribution, tariffs }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Result);
