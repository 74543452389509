import React, { FC } from 'react';

import { DeleteModalLayout, useIntl, mediumText } from 'Common';

interface ModalRemoveProjectProps {
    visible: boolean;
    close: () => void;
    onOk: () => void;
    name: string;
}

const ModalRemoveProject: FC<ModalRemoveProjectProps> = ({ visible, onOk, close, name }) => {
    const intl = useIntl();

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('project_removal')}
            handleClose={close}
            handleDelete={onOk}
        >
            <div className="modal__desc">
                {intl.getMessage('projectlist_remove_desc', { name, b: mediumText })}
            </div>
        </DeleteModalLayout>
    );
};

export default ModalRemoveProject;
