import { takeEvery, put, call, delay } from 'redux-saga/effects';

import ServerApi from 'Apis/servers';
import {
    InstallingServersActions,
    getProgress as getProgressAct,
    saveProgress as saveProgressAct,
    deleteProgress as deleteProgressAct,
    deleteInstallingServer,
} from 'Actions/installingServers';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import OperationProgress, { IOperationProgress } from 'Entities/OperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';
import { getServerForTenant, getServer } from 'Actions/server';

import { errorChecker, addError } from './utils';

function* getProgress(action: ReturnType<typeof getProgressAct>) {
    const { payload: { id, tenantId }, callback } = action;
    let loadDeleted = false;
    let wasCallback = false;
    while (true) {
        const response: IOperationProgress = yield call(
            ServerApi.getOperationProgress,
            tenantId,
            id,
        );
        const { error, result } = errorChecker<IOperationProgress>(response);
        if (result) {
            if (!loadDeleted) {
                yield put(getServer({ serverId: result.server_id, tenantId: result.tenant_id }));
                loadDeleted = true;
            }
            yield put(saveProgressAct({ progress: new OperationProgress(response) }));
            if (!wasCallback && callback) {
                if (
                    result.error?.error_code === ApiErrorCode.SETUP_NOT_ENOUGH_BALANCE
                    && callback?.error
                ) {
                    callback.error();
                } else if (callback?.result) {
                    callback.result(result);
                }
                wasCallback = true;
            }
            if (result.status !== OperationStatus.PENDING) {
                yield put(getServerForTenant([tenantId]));
                if (result.status === OperationStatus.DONE) {
                    yield put(deleteProgressAct({ progressId: id }));
                    yield put(deleteInstallingServer(result.server_id));
                }
                break;
            }
            yield delay(1000);
        } else if (error) {
            yield addError(error, action.type);
            break;
        }
    }
}

function* installingServersSaga(): Generator {
    yield takeEvery<ReturnType<typeof getProgressAct>>(
        InstallingServersActions.GetProgress, getProgress,
    );
}

export default installingServersSaga;
