import DnsNameServer, { IDnsNameServer } from './DnsNameServer';
import DnsTemplateRecord, { IDnsTemplateRecord } from './DnsTemplateRecord';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsSettings {
    default_ttl_seconds: number;
    name_servers: IDnsNameServer[];
    templates: IDnsTemplateRecord[];
}

export default class DnsSettings {
    readonly _default_ttl_seconds: number;

    /**
     * Description: Default ttl in seconds
     * Example: 3600
     */
    get defaultTtlSeconds(): number {
        return this._default_ttl_seconds;
    }

    static defaultTtlSecondsValidate(defaultTtlSeconds: number): boolean {
        return typeof defaultTtlSeconds === 'number';
    }

    readonly _name_servers: DnsNameServer[];

    /** */
    get nameServers(): DnsNameServer[] {
        return this._name_servers;
    }

    readonly _templates: DnsTemplateRecord[];

    /** */
    get templates(): DnsTemplateRecord[] {
        return this._templates;
    }

    constructor(props: IDnsSettings) {
        this._default_ttl_seconds = props.default_ttl_seconds;
        this._name_servers = props.name_servers.map((p) => new DnsNameServer(p));
        this._templates = props.templates.map((p) => new DnsTemplateRecord(p));
    }

    serialize(): IDnsSettings {
        const data: IDnsSettings = {
            default_ttl_seconds: this._default_ttl_seconds,
            name_servers: this._name_servers.map((p) => p.serialize()),
            templates: this._templates.map((p) => p.serialize()),
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            templates: this._templates.reduce((result, p) => result && p.validate().length === 0, true),
            name_servers: this._name_servers.reduce((result, p) => result && p.validate().length === 0, true),
            default_ttl_seconds: typeof this._default_ttl_seconds === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsSettings): DnsSettings {
        return new DnsSettings(props);
    }

    readonly keys: { [key: string]: string } = {
        defaultTtlSeconds: 'default_ttl_seconds',
        nameServers: 'name_servers',
        templates: 'templates',
        }
;

    mergeDeepWith(props: Partial<DnsSettings>): DnsSettings {
        const updateData: Partial<IDnsSettings> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsSettings) => {
            const updateKey = this.keys[key] as keyof IDnsSettings;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsSettings, keyof IDnsSettings>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsSettings({ ...this.serialize(), ...updateData });
    }
}
