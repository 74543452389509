import React, { FC } from 'react';
import cn from 'classnames';

import { DashboardHeader, Link, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { TICKETS_STATE } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import s from './Header.module.pcss';

interface HeaderProp {
    id: string;
    title: string;
}

const Header: FC<HeaderProp> = ({
    id, title,
}) => {
    return (
        <DashboardHeader
            navigation={(
                <>
                    <Link
                        to={RoutePath.Tickets}
                        props={{ open: TICKETS_STATE.CLOSED }}
                        className={theme.header.back}
                    >
                        <Button
                            type="icon"
                            size="medium"
                            icon="arrow"
                            className={theme.button.rotate}
                        />
                    </Link>
                    <div className={cn(theme.header.title, theme.common.textOverflow)}>
                        {title}
                    </div>
                </>
            )}
            actions={(
                <div
                    className={cn(
                        theme.Badge.badge,
                        theme.Badge.blue,
                        s.badge,
                    )}
                >
                    {id}
                </div>
            )}
        />
    );
};

export default Header;
