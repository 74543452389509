import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, EmptyPageLayout } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import Server from 'Entities/Server';
import Tenant from 'Entities/Tenant';
import theme from 'Lib/theme';

import ServerItem from './ServerItem';

interface ServerListProps {
    tenants: Tenant[];
    servers: Server[];
    currentServerId: number;
    handleSelect: (server: Server) => void;
}

const ServerList: FC<ServerListProps> = ({
    servers, tenants, currentServerId, handleSelect,
}) => {
    const intl = useIntl();

    const tenantIds = [...new Set(servers.map((server) => server.tenantId))];
    const tenantList = tenantIds
        .map((id) => tenants.filter((tenant) => tenant.id === id)[0]);

    if (servers.length === 0) {
        return (
            <EmptyPageLayout
                desc={intl.getMessage('search_empty')}
                linkText={intl.getMessage('create_server')}
                link={RoutePath.NewServer}
                type="dropdown"
            />
        );
    }

    return (
        <div className={cn(theme.search.list, theme.search.list_server)}>
            {tenantList.map(({ id, description }) => (
                <div key={id} data-server={id}>
                    <div
                        className={cn(
                            theme.search.heading,
                            theme.search.heading_server,
                        )}
                    >
                        {description}
                    </div>
                    {servers.map((server) => (
                        server.tenantId === id
                        && (
                            <ServerItem
                                key={server.id}
                                server={server}
                                currentServerId={currentServerId}
                                handleSelect={handleSelect}
                            />
                        )
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ServerList;
