import React, { FC, useContext } from 'react';

import { useIntl, Link, Button, DashboardHeader } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { ActionsSelect } from 'Components/ServersList/components/Filters';
import { ServerListContext } from 'Components/ServersList/factories';

import theme from 'Lib/theme';
import Search from './Search';

const Header: FC = () => {
    const intl = useIntl();
    const { selectedServers, clearSelection } = useContext(ServerListContext);

    return (
        <DashboardHeader
            navigation={<Search />}
            actions={(
                <div>
                    {selectedServers.size > 0 ? (
                        <>
                            <div className={theme.skeleton.desktop}>
                                <Link to={RoutePath.NewServer}>
                                    <Button
                                        type="primary"
                                        size="medium"
                                        id="data-new-server"
                                    >
                                        {intl.getMessage('create_server')}
                                    </Button>
                                </Link>
                            </div>
                            <div className={theme.skeleton.mobile}>
                                <ActionsSelect
                                    clearSelection={clearSelection}
                                    servers={selectedServers}
                                />
                            </div>
                        </>
                    ) : (
                        <Link to={RoutePath.NewServer}>
                            <Button
                                type="primary"
                                size="medium"
                                id="data-new-server"
                            >
                                {intl.getMessage('create_server')}
                            </Button>
                        </Link>
                    )}
                </div>
            )}
        />
    );
};

export default Header;
