import ServersApi from 'Apis/servers';
import { SecurityActionType } from 'Entities/SecurityActionType';
import ServerInfo from 'Entities/ServerInfo';

import { actionCreator } from '.';

export enum BatchOperationProgressActions {
    GetBatchOperationProgress = 'GetBatchOperationProgress',
    SaveBatchOperationProgress = 'SaveBatchOperationProgress',
    RemoveDoneProgressBatch = 'RemoveDoneProgressBatch',
}

type GetBatchOperationProgress = {
    data: Parameters<typeof ServersApi.getBatchOperationProgress>;
    notificationId?: string;
};
export const getBatchOperationProgress = actionCreator<GetBatchOperationProgress>(
    BatchOperationProgressActions.GetBatchOperationProgress,
);

export type ISaveBatchOperationProgress = {
    errors: ServerInfo[];
    progress: number;
    publicKeys?: number[];
    type?: SecurityActionType;
} | {
    servers: number[];
};

export const saveBatchOperationProgress = actionCreator<ISaveBatchOperationProgress>(
    BatchOperationProgressActions.SaveBatchOperationProgress,
);

export const removeDoneProgress = actionCreator<number>(
    BatchOperationProgressActions.RemoveDoneProgressBatch,
);
