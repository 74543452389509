import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import DnsRecord, { IDnsRecord } from 'Entities/DnsRecord';
import DnsRecordCreateList, { IDnsRecordCreateList } from 'Entities/DnsRecordCreateList';
import DnsRecordUpdate, { IDnsRecordUpdate } from 'Entities/DnsRecordUpdate';
import DnsSettings, { IDnsSettings } from 'Entities/DnsSettings';
import DnsZone, { IDnsZone } from 'Entities/DnsZone';
import DnsZoneCreate, { IDnsZoneCreate } from 'Entities/DnsZoneCreate';
import DnsZoneImportResult, { IDnsZoneImportResult } from 'Entities/DnsZoneImportResult';
import DnsZoneUpdate, { IDnsZoneUpdate } from 'Entities/DnsZoneUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class DnsApi {
    static async createDnsRecords(tenant_id: number, dns_zone_id: number, dnsrecordcreatelist: IDnsRecordCreateList, optionalToken?: string): Promise<IDnsRecord[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const dnsrecordcreatelistValid = new DnsRecordCreateList(dnsrecordcreatelist);
        haveError.push(...dnsrecordcreatelistValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/zones/${dns_zone_id}/records`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dnsrecordcreatelistValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async createDnsZone(tenant_id: number, dnszonecreate: IDnsZoneCreate, optionalToken?: string): Promise<IDnsZone | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const dnszonecreateValid = new DnsZoneCreate(dnszonecreate);
        haveError.push(...dnszonecreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/zones`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dnszonecreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteDnsRecord(tenant_id: number, dns_zone_id: number, dns_record_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/zones/${dns_zone_id}/records/${dns_record_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteDnsZone(tenant_id: number, dns_zone_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/zones/${dns_zone_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async exportDnsRecords(tenant_id: number, dns_zone_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/zones/${dns_zone_id}/export`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getDnsRecords(tenant_id: number, dns_zone_id: number, optionalToken?: string): Promise<IDnsRecord[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/zones/${dns_zone_id}/records`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getDnsSettings(optionalToken?: string): Promise<IDnsSettings | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/dns/settings`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getDnsZones(optionalToken?: string): Promise<IDnsZone[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/dns/zones`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async importDnsRecords(data: FormData, optionalToken?: string): Promise<IDnsZoneImportResult | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/dns/zones/import`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
            body: data,
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateDnsRecord(tenant_id: number, dns_zone_id: number, dns_record_id: number, dnsrecordupdate: IDnsRecordUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const dnsrecordupdateValid = new DnsRecordUpdate(dnsrecordupdate);
        haveError.push(...dnsrecordupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/zones/${dns_zone_id}/records/${dns_record_id}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dnsrecordupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateDnsZone(tenant_id: number, dns_zone_id: number, dnszoneupdate: IDnsZoneUpdate, optionalToken?: string): Promise<IDnsZone | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const dnszoneupdateValid = new DnsZoneUpdate(dnszoneupdate);
        haveError.push(...dnszoneupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/zones/${dns_zone_id}`, {
            method: 'PUT',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dnszoneupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
