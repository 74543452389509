import React from 'react';

import { ChartSizeType, IChartLine, KeysMatching, zoomInitialValues } from 'Common/Chart/AreaChart';
import { CHART_RANGE_MEAN, RESOURCES_TYPES } from 'Lib/helpers/consts';
import Notification from 'Entities/Notification';
import { Range } from 'Lib/helpers/utils';
import Server from 'Entities/Server';

export interface ChartType<T> {
    type: RESOURCES_TYPES;
    xAxisKey: KeysMatching<T, number>;
    lines: IChartLine<T>[];
    drawGrid?: boolean;
    drawYAxis?: boolean;
    drawXAxis?: boolean;
    size?: ChartSizeType;
    syncId?: string;
    name?: string;
    tabs?: boolean;
    mean?: CHART_RANGE_MEAN;
    valueRange?: number[];
    unit: string;
    dynamicValueRangeMax?: (max: number) => number;
    tickCount?: number;
    globalZoom?: typeof zoomInitialValues;
    setGlobalZoom?: (zoom: typeof zoomInitialValues) => void;
    initialRange?: Range;
    setRange?: (value: Range) => void;
    serverEvents?: Notification[] | null;
    serverAddedMillis?: number;
    currentLines?: Set<T>;
    verticalZoom?: boolean;
    initialEmpty?: boolean;
}

export interface IChartContext<T> {
    data: T[];
    chart: ChartType<T>;
    server: Server;
}

export const ChartContext = React.createContext({} as unknown);
