import Fuse from 'fuse.js';

import Server from 'Entities/Server';
import { Locale } from 'Lib/intl';
import { charsMapEnRu, charsMapRuEn, SEARCH_TYPE } from 'Lib/helpers/consts';
import { getServerSearch } from './utils';

type SearchEntityKeys =
    'id' |
    'name' |
    'privateAddress' |
    'ipv4Addresses' |
    'ipv6Addresses';

type SearchEntity = Record<SearchEntityKeys, any>;

export const handleServerSearch = (
    inputValue: string,
    servers: Map<number, Server>,
    locale: Locale,
    searchType?: SEARCH_TYPE,
): Server[] => {
    if (!servers) {
        return [];
    }
    if (!inputValue) {
        const localSearch = getServerSearch();
        return localSearch.map((id) => servers.get(id)!).filter((s) => !!s);
    }
    const searchEnts = Array.from(servers.values()).map((sr) => {
        const ent: SearchEntity = {
            id: sr.id,
            name: sr.name,
            privateAddress: sr.privateAddress.host,
            ipv4Addresses: sr.ipv4Addresses.map((i) => i.host),
            ipv6Addresses: sr.ipv6Addresses.map((i) => i.host),
        };
        return ent;
    });

    const keys: SearchEntityKeys[] = [
        'id',
        'name',
        'privateAddress',
        'ipv4Addresses',
        'ipv6Addresses',
    ];

    const fuse = new Fuse(searchEnts, {
        keys,
        threshold: searchType === SEARCH_TYPE.STRICT ? 0.0 : 0.3,
        ignoreLocation: true,
    });
    const fuseResults = new Set(fuse.search(inputValue).map((r) => r.item.id));
    let otherLayout: string | null = null;
    if (locale === Locale.ru) {
        const chars = inputValue.split('');
        const toRu = chars.map((c) => charsMapEnRu[c as keyof typeof charsMapEnRu] || '').join('');
        const toEn = chars.map((c) => charsMapRuEn[c as keyof typeof charsMapRuEn] || '').join('');
        otherLayout = inputValue.length === toRu.length ? toRu : toEn;
    }
    if (otherLayout) {
        const fuseOtherLayout = fuse.search(otherLayout).map((r) => r.item.id);
        fuseOtherLayout.forEach((id) => {
            fuseResults.add(id);
        });
    }
    return Array.from(fuseResults.values()).map((id) => servers.get(id)!);
};
