import React, { FC } from 'react';
import { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker, { PickerProps, RangePickerProps } from 'antd/es/date-picker/generatePicker';
import generateCalendar, { CalendarProps } from 'antd/es/calendar/generateCalendar';
import { DatePickerLocale } from 'Lib/intl';
import { connect } from 'react-redux';
import { Store } from 'Store';
import { createSelector } from 'reselect';

const DatePickerComponent = generatePicker<Dayjs>(dayjsGenerateConfig);
const { RangePicker: RangePickerComponent } = DatePickerComponent;

const DatePickerContainer: FC<PickerProps<Dayjs>> = (props) => {
    return <DatePickerComponent {...props} />;
};

const RangePickerContainer: FC<RangePickerProps<Dayjs>> = (props) => {
    return <RangePickerComponent {...props} />;
};

const CalendarComponent = generateCalendar<Dayjs>(dayjsGenerateConfig);
const CalendarContainer: FC<CalendarProps<Dayjs>> = (props) => {
    return <CalendarComponent {...props} />;
};

const storeIntl = (store: Store) => store.intl.currentLocale;
const selectCurrentLocale = createSelector(storeIntl, (locale) => locale);

const mapStateToProps = (store: Store) => ({
    locale: DatePickerLocale[selectCurrentLocale(store)],
});

const RangePicker = connect(mapStateToProps)(RangePickerContainer);
const DatePicker = connect(mapStateToProps)(DatePickerContainer);
const Calendar = connect(mapStateToProps)(CalendarContainer);
export default DatePicker;
export { RangePicker, Calendar };
