import React, { FC, useRef, useEffect, useState, KeyboardEvent } from 'react';
import { Input, Tooltip } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { updateServer } from 'Actions/server';
import { Icon, Button, useIntl } from 'Common';
import { COPY_BUTTON_TIMEOUT } from 'Lib/helpers/consts';
import { getServerIcon } from 'Lib/helpers/utils';
import theme from 'Lib/theme';
import Server from 'Entities/Server';
import { useClickOutside } from 'Hooks';
import ServerUpdate from 'Entities/ServerUpdate';
import { CopyButton } from 'Ui/CopyButton';

import s from './ServerName.module.pcss';

interface ServerNameProps {
    server: Server;
    loading: boolean;
}

const ServerName: FC<ServerNameProps> = ({ server, loading }) => {
    const dispatch = useDispatch();
    const ref = useRef<HTMLDivElement>(null);
    const intl = useIntl();
    const { name, distribution: { type } } = server;

    const [editActive, setEditActive] = useState(false);
    const [newName, setNewName] = useState(name);
    const [copyActive, setCopyActive] = useState(false);

    useEffect(() => {
        setNewName(name);
    }, [server]);

    useClickOutside(ref, () => setEditActive(false));

    const handleEdit = () => {
        if (newName.length === 0) {
            return;
        }
        if (newName === name) {
            setEditActive(false);
            return;
        }
        let newNameSized = newName.trim();
        if (newNameSized.length > ServerUpdate.nameMaxLength) {
            newNameSized = newNameSized.substring(0, ServerUpdate.nameMaxLength);
        }
        dispatch(updateServer({ server, update: { name: newNameSized } }));
        setEditActive(false);
    };

    const handleCopy = () => {
        setCopyActive(true);
        setTimeout(() => {
            setCopyActive(false);
        }, COPY_BUTTON_TIMEOUT);
        navigator.clipboard.writeText(name);
    };

    const onEditNameKeyPressed = (e: KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Enter':
                handleEdit();
                break;
            case 'Escape':
                setEditActive(false);
                break;
            default:
                break;
        }
    };

    return (
        <div className={s.wrap}>
            <Icon
                className={s.icon}
                icon={getServerIcon(type)}
            />
            <div className={theme.edit.wrap}>
                {!editActive ? (
                    <div
                        className={cn(
                            theme.edit.name,
                            theme.edit.edited,
                            theme.edit.visible,
                        )}
                        onClick={() => !loading && setEditActive(true)}
                    >
                        <div
                            id="server_name"
                            className={cn(s.title, theme.edit.title)}
                        >
                            {name}
                        </div>
                        {!loading && (
                            <Tooltip
                                placement="bottomRight"
                                overlayClassName="tooltip tooltip_small"
                                title={intl.getMessage('title_edit_name')}
                                arrowPointAtCenter
                            >
                                <span>
                                    <Icon
                                        className={theme.edit.icon}
                                        icon="edit"
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                ) : (
                    <div
                        className={theme.edit.name}
                        ref={ref}
                    >
                        <Input
                            id="serverNameInput"
                            className={cn(s.title, theme.edit.title)}
                            name="server_name"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            onKeyDown={onEditNameKeyPressed}
                            onFocus={(e) => e.target.select()}
                            autoFocus
                        />
                        <Button
                            type="edit"
                            id="serverNameEdit"
                            onClick={handleEdit}
                            className={cn(
                                theme.button.edit_light,
                                theme.button.blue,
                                theme.button.check,
                            )}
                            icon="check"
                        />
                        <Button
                            type="edit"
                            id="serverNameClose"
                            className={cn(
                                theme.button.edit_light,
                                theme.button.red,
                            )}
                            onClick={() => setEditActive(false)}
                            icon="close_small"
                            danger
                        />
                    </div>
                )}
                <div className={s.copy}>
                    <CopyButton
                        background="ice"
                        active={copyActive}
                        copy={handleCopy}
                    />
                </div>
            </div>
        </div>
    );
};

export default ServerName;
