// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerDailyStats {
    daily_income: number;
    date: string;
    date_millis: number;
}

export default class ResellerDailyStats {
    readonly _daily_income: number;

    /**
     * Description: Income for Reseller for single day, RUB
     * Example: 73.37
     */
    get dailyIncome(): number {
        return this._daily_income;
    }

    static dailyIncomeValidate(dailyIncome: number): boolean {
        return typeof dailyIncome === 'number';
    }

    readonly _date: string;

    /** */
    get date(): string {
        return this._date;
    }

    static dateValidate(date: string): boolean {
        return typeof date === 'string' && !!date.trim();
    }

    readonly _date_millis: number;

    /**
     * Description: Reseller's stats date, aligned by whole MSK date, UTC millis
     * Example: 1577826000000
     */
    get dateMillis(): number {
        return this._date_millis;
    }

    static dateMillisValidate(dateMillis: number): boolean {
        return typeof dateMillis === 'number';
    }

    constructor(props: IResellerDailyStats) {
        this._daily_income = props.daily_income;
        this._date = props.date.trim();
        this._date_millis = props.date_millis;
    }

    serialize(): IResellerDailyStats {
        const data: IResellerDailyStats = {
            daily_income: this._daily_income,
            date: this._date,
            date_millis: this._date_millis,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            date: typeof this._date === 'string' && !!this._date.trim(),
            date_millis: typeof this._date_millis === 'number',
            daily_income: typeof this._daily_income === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerDailyStats): ResellerDailyStats {
        return new ResellerDailyStats(props);
    }

    readonly keys: { [key: string]: string } = {
        dailyIncome: 'daily_income',
        date: 'date',
        dateMillis: 'date_millis',
        }
;

    mergeDeepWith(props: Partial<ResellerDailyStats>): ResellerDailyStats {
        const updateData: Partial<IResellerDailyStats> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerDailyStats) => {
            const updateKey = this.keys[key] as keyof IResellerDailyStats;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerDailyStats, keyof IResellerDailyStats>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerDailyStats({ ...this.serialize(), ...updateData });
    }
}
