import { takeEvery, put, call } from 'redux-saga/effects';

import ResellerApi from 'Apis/resellers';
import {
    ResellerActions,
    saveResellerDetails,
    saveResellerClients,
    saveResellerProcessingPayments,
    saveResellerCompletePayments,
    clearResellerCompletePayments,
    getResellerDetails as getResellerDetailsAct,
    getResellerClients as getResellerClientsAct,
    getResellerProcessingPayments as getResellerProcessingPaymentsAct,
    getResellerCompletePayments as getResellerCompletePaymentsAct,
    addResellerClient as addResellerClientAct,
    transferFromResellerToClient as transferFromResellerToClientAct,
    updateResellerClient as updateResellerClientAct,
    doResellerWithdrawalPayment as doResellerWithdrawalPaymentAct,
    doResellerTransferPayment as doResellerTransferPaymentAct,
    resellerServerClone as resellerServerCloneAct,
} from 'Actions/reseller';
import { getAccountDetails } from 'Actions/account';

import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ResellerDetails, { IResellerDetails } from 'Entities/ResellerDetails';
import ResellerClient, { IResellerClient } from 'Entities/ResellerClient';
import ResellerPayment, { IResellerPayment } from 'Entities/ResellerPayment';
import { IOperationInfo } from 'Entities/OperationInfo';

import { errorChecker, addError } from './utils';

function* getResellerDetails(action: ReturnType<typeof getResellerDetailsAct>) {
    const response: IResellerDetails = yield call(ResellerApi.getResellerDetails);
    const { result, error } = errorChecker<IResellerDetails>(response);
    if (result) {
        yield put(saveResellerDetails(new ResellerDetails(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getResellerClients(action: ReturnType<typeof getResellerClientsAct>) {
    const { payload } = action;
    const response: IResellerClient[] = yield call(ResellerApi.getResellerClients, ...payload);
    const { result, error } = errorChecker<IResellerClient[]>(response);
    if (result) {
        yield put(saveResellerClients(result.map((r) => new ResellerClient(r))));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getResellerProcessingPayments(
    action: ReturnType<typeof getResellerProcessingPaymentsAct>,
) {
    const response: IResellerPayment[] = yield call(ResellerApi.getResellerProcessingPayments);
    const { result, error } = errorChecker<IResellerPayment[]>(response);
    if (result) {
        yield put(saveResellerProcessingPayments(result.map((r) => new ResellerPayment(r))));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getResellerCompletePayments(action: ReturnType<typeof getResellerCompletePaymentsAct>) {
    const { payload } = action;
    const response: IResellerPayment[] = yield call(
        ResellerApi.getResellerCompletePayments, ...payload,
    );
    const { result, error } = errorChecker<IResellerPayment[]>(response);
    if (result) {
        yield put(saveResellerCompletePayments(result.map((r) => new ResellerPayment(r))));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* addResellerClient(action: ReturnType<typeof addResellerClientAct>) {
    const { payload, callback } = action;
    const response: IResellerClient = yield call(ResellerApi.addResellerClient, ...payload);
    const { error, result } = errorChecker<IResellerClient>(response);
    if (result && callback?.result) {
        yield put(getResellerClientsAct([]));
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* transferFromResellerToClient(action: ReturnType<typeof transferFromResellerToClientAct>) {
    const { payload, callback } = action;
    const response: number = yield call(ResellerApi.transferFromResellerToClient, ...payload);
    const { result, error } = errorChecker<number>(response);
    if (result) {
        yield put(getResellerClientsAct([]));

        yield put(getResellerDetailsAct());
        yield put(getAccountDetails());

        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        if (error.fields?.amount?.error_code === ApiErrorCode.NOT_ENOUGH_BALANCE) {
            yield addError(error.fields?.amount, action.type);
        } else {
            yield addError(error, action.type);
        }
    }
}

function* updateResellerClient(action: ReturnType<typeof updateResellerClientAct>) {
    const { payload } = action;
    const response: number = yield call(ResellerApi.updateResellerClient, ...payload);
    const { result, error } = errorChecker<number>(response);
    if (result) {
        yield put(getResellerClientsAct([]));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* doResellerWithdrawalPayment(action: ReturnType<typeof doResellerWithdrawalPaymentAct>) {
    const { payload, callback } = action;
    const response: IResellerPayment = yield call(
        ResellerApi.doResellerWithdrawalPayment, ...payload,
    );
    const { result, error } = errorChecker<IResellerPayment>(response);
    if (result) {
        yield put(clearResellerCompletePayments());
        yield put(getResellerDetailsAct([]));
        yield put(getAccountDetails());
        yield put(getResellerCompletePaymentsAct([]));
        yield put(getResellerProcessingPaymentsAct([]));
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        if (error.fields?.amount?.error_code === ApiErrorCode.NOT_ENOUGH_BALANCE) {
            yield addError(error.fields?.amount, action.type);
        } else {
            yield addError(error, action.type);
        }
        if (callback?.error) {
            callback.error();
        }
    }
}

function* doResellerTransferPayment(action: ReturnType<typeof doResellerTransferPaymentAct>) {
    const { payload, callback } = action;
    const response: number = yield call(ResellerApi.doResellerTransferPayment, ...payload);
    const { result, error } = errorChecker(response);
    if (result) {
        yield put(getResellerDetailsAct([]));
        yield put(getAccountDetails());

        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        if (error.fields?.amount?.error_code === ApiErrorCode.NOT_ENOUGH_BALANCE) {
            yield addError(error.fields?.amount, action.type);
        } else {
            yield addError(error, action.type);
        }
    }
}

function* resellerServerClone(action: ReturnType<typeof resellerServerCloneAct>) {
    const { payload, callback } = action;
    const response: IOperationInfo = yield call(ResellerApi.resellerCloneServer, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        callback?.result?.();
    }
    if (error) {
        yield addError(error, action.type);
        callback?.error?.(error);
    }
}

export default function* partnerSaga(): Generator {
    yield takeEvery<ReturnType<typeof getResellerDetailsAct>>(
        ResellerActions.GetResellerDetails,
        getResellerDetails,
    );
    yield takeEvery<ReturnType<typeof getResellerClientsAct>>(
        ResellerActions.GetResellerClients,
        getResellerClients,
    );
    yield takeEvery<ReturnType<typeof getResellerProcessingPaymentsAct>>(
        ResellerActions.GetResellerProcessingPayments,
        getResellerProcessingPayments,
    );
    yield takeEvery<ReturnType<typeof getResellerCompletePaymentsAct>>(
        ResellerActions.GetResellerCompletePayments,
        getResellerCompletePayments,
    );
    yield takeEvery<ReturnType<typeof addResellerClientAct>>(
        ResellerActions.AddResellerClient,
        addResellerClient,
    );
    yield takeEvery<ReturnType<typeof transferFromResellerToClientAct>>(
        ResellerActions.TransferFromResellerToClient,
        transferFromResellerToClient,
    );
    yield takeEvery<ReturnType<typeof updateResellerClientAct>>(
        ResellerActions.UpdateResellerClient,
        updateResellerClient,
    );
    yield takeEvery<ReturnType<typeof doResellerWithdrawalPaymentAct>>(
        ResellerActions.DoResellerWithdrawalPayment,
        doResellerWithdrawalPayment,
    );
    yield takeEvery<ReturnType<typeof doResellerTransferPaymentAct>>(
        ResellerActions.DoResellerTransferPayment,
        doResellerTransferPayment,
    );
    yield takeEvery<ReturnType<typeof resellerServerCloneAct>>(
        ResellerActions.ResellerServerClone,
        resellerServerClone,
    );
}
