import React, { FC, useContext } from 'react';
import { Col } from 'antd';

import { SERVER_VIEW } from 'Lib/helpers/consts';

import { Server } from '.';
import { MapResult, ServerListContext } from '../factories';

type ServerListElementProps = MapResult;
const ServerListElement: FC<ServerListElementProps> = React.memo(({
    server,
    filters,
}) => {
    const { serverView } = useContext(ServerListContext);
    return (
        <Col
            key={server.id}
            span={24}
            lg={serverView === SERVER_VIEW.COMPACT ? 12 : 24}
        >
            <Server
                id={server.id}
                tenantId={server.tenantId}
                installing={filters.installing}
            />
        </Col>
    );
});

export default ServerListElement;
