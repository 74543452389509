import React, { FC, useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';

import { getNotifications, clearNotifications, markNotificationSeen } from 'Actions/notifications';
import { getProfileInfo } from 'Actions/profile';
import { useIntl, LoaderPageLayout, EmptyPageLayout, Button, Icon } from 'Common';
import { useOffsetLoader, useTitle } from 'Hooks';
import NotificationsEnt from 'Entities/Notification';
import { Store } from 'Store';

import theme from 'Lib/theme';
import cn from 'classnames';
import { Notification } from './components';
import s from './Notifications.module.pcss';

interface NotificationsStoreProps {
    notifications: NotificationsEnt[] | null;
    servers: Store['server'];
}

interface NotificationsOwnProps {
    seen?: boolean;
}

type NotificationsProps = NotificationsStoreProps & NotificationsOwnProps;
const Notifications: FC<NotificationsProps> = ({ notifications, servers, seen }) => {
    const intl = useIntl();
    const [checkList, setCheckList] = useState<Set<number>>(new Set());
    useTitle(intl.getMessage('notifications_page_title'));
    const dispatch = useDispatch();
    const { reset } = useOffsetLoader(notifications, (offset, limit) => getNotifications([
        seen,
        offset * limit,
        limit,
    ]));

    useEffect(() => {
        dispatch(getProfileInfo({ onlyInfo: true }));

        const clearCheckList = (e: KeyboardEvent) => {
            e.preventDefault();
            if (e.key === 'Escape') {
                setCheckList(new Set());
            }
        };
        window.addEventListener('keyup', clearCheckList);

        return () => {
            window.removeEventListener('keyup', clearCheckList);
            dispatch(clearNotifications());
        };
    }, []);

    const onSetSeenSubmit = () => {
        const ids = Array.from(checkList);
        dispatch(markNotificationSeen(
            { parameters: [{ notification_ids: ids }], clear: true },
            { result: () => reset() },
        ));
        ids.forEach((id) => {
            checkList.delete(id);
        });
        setCheckList(new Set(checkList));
    };

    if (notifications === null) {
        return (
            <div className={s.loader}>
                <LoaderPageLayout />
            </div>
        );
    }

    const selectAll = () => {
        if (checkList.size > 0) {
            setCheckList(new Set());
        } else {
            const selected = notifications.map((n) => n.id);
            setCheckList(new Set(selected));
        }
    };

    if (notifications.length > 0) {
        return (
            <>
                {!seen && (
                    <div className={s.buttons}>
                        <Button
                            type="border"
                            id="select_all"
                            className={cn(
                                theme.button.desktop_medium,
                                theme.button.with_icon,
                                { [theme.button.border_active]: checkList.size > 0 },
                            )}
                            onClick={selectAll}
                            icon="check"
                        >
                            {checkList.size ? intl.getMessage('all_selected', { value: checkList.size }) : intl.getMessage('select_all')}
                        </Button>
                        {(checkList.size > 0) && (
                            <Button
                                type="border"
                                id="select_as_read"
                                className={cn(
                                    theme.button.desktop_medium,
                                    theme.button.with_icon,
                                    s.btn,
                                )}
                                onClick={() => onSetSeenSubmit()}
                                icon="visibility_enable"
                            >
                                {intl.getMessage('select_as_read')}
                            </Button>
                        )}
                    </div>
                )}

                <div className={cn(theme.card.labels, theme.card.labels_notification)}>
                    <div className={theme.card.label}>
                        {intl.getMessage('event')}
                    </div>
                    <div className={theme.card.label}>
                        {intl.getMessage('server')}
                    </div>
                    <div className={theme.card.label}>
                        {intl.getMessage('initiator')}
                    </div>
                    <div
                        className={cn(
                            theme.card.label,
                            theme.card.label_last,
                        )}
                    >
                        <Icon
                            icon="history"
                            className={theme.card.dateIcon}
                        />
                    </div>
                </div>

                {notifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        checkList={checkList}
                        setChecked={(set) => setCheckList(set)}
                        notification={notification}
                        server={notification.serverInfo && servers.get(notification.serverInfo?.id)}
                    />
                ))}
            </>
        );
    }

    if (seen) {
        return (
            <EmptyPageLayout
                title={intl.getMessage('support_notifications_empty')}
                desc={intl.getMessage('support_notifications_empty_read')}
                contentClassName={s.empty}
            />
        );
    }

    return (
        <EmptyPageLayout
            title={intl.getMessage('support_notifications_empty')}
            desc={intl.getMessage('support_notifications_empty_unread')}
            contentClassName={s.empty}
        />
    );
};

const selectServers = (store: Store) => store.server;
const selectNotifications = (store: Store, op: NotificationsOwnProps) => {
    const { seen } = op;

    const { notifications } = store.notifications;
    const currentNotifications = notifications?.filter(
        (n) => n.seen === seen
    );
    return notifications ? currentNotifications! : null;
};

const selector = createSelector(
    [selectNotifications, selectServers],
    (notifications, servers) => ({ notifications, servers }),
);

const mapStoreToProps = (store: Store, op: NotificationsOwnProps) => ({
    ...selector(store, op),
});

export default connect(mapStoreToProps)(Notifications);
