import { Action } from 'store/actions';
import {
    PublicKeysActions,
    savePublicKeys,
} from 'Actions/publicKeys';
import PublicKey from 'Entities/PublicKey';

import { PublicKeysStore, publicKeysInitialState as initialState } from '../initialStates';

const reducer = (state: PublicKeysStore = initialState, action: Action): PublicKeysStore => {
    switch (action.type as PublicKeysActions) {
        case PublicKeysActions.SavePublicKeys: {
            const { payload } = action as ReturnType<typeof savePublicKeys>;
            const records = new Map<number, PublicKey>();
            payload.forEach((r) => {
                records.set(r.id, r);
            });
            return records;
        }
        default:
            return state;
    }
};

export default reducer;
