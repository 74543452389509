import React, { FC, useContext, useState } from 'react';
import cn from 'classnames';

import { useIntl, Select, Option } from 'Common';
import { useEscape } from 'Hooks';
import { MapResult, ServerListContext } from 'Components/ServersList/factories';
import { ServerState } from 'Entities/ServerState';
import { SERVER_SELECT, serverSelectTranslate } from 'Lib/helpers/translationHelper';

import s from './Filters.module.pcss';

interface ServerSelectProps {
    servers: MapResult[];
    transparent?: boolean;
}

const ServerSelect: FC<ServerSelectProps> = React.memo(({
    servers,
    transparent,
}) => {
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    const {
        setShowCheckbox,
        selectedServers,
        selectType,
        onSelectTypeChange,
        clearSelection,
        tenantFilter,
        showCheckbox,
    } = useContext(ServerListContext);

    useEscape(clearSelection);

    const text = selectedServers.size > 0 ? (
        intl.getPlural('selected_short', selectedServers.size)
    ) : (
        intl.getMessage('select_by_manually')
    );
    const serversToSelect = tenantFilter.length === 0
        ? servers
        : servers.filter(({ server: { tenantId } }) => tenantFilter.includes(tenantId));
    const getSelectOptions = () => {
        const optionsMap = serversToSelect.reduce((prev, se) => {
            const newPrev = { ...prev };
            if (!newPrev.BY_ACTIVE
                && se.server.state === ServerState.RUNNING && !se.filters.isOperation) {
                newPrev.BY_ACTIVE = 1;
            }
            if (!newPrev.BY_INACTIVE
                && se.server.state === ServerState.SHUTOFF && !se.filters.isOperation) {
                newPrev.BY_INACTIVE = 1;
            }
            if (se.filters.isOperation) {
                newPrev.DEFAULT = (newPrev.DEFAULT || 0) + 1;
            }
            return newPrev;
        }, {} as Partial<Record<SERVER_SELECT, number>>);
        const options = [];
        if (optionsMap.BY_ACTIVE) {
            options.push((
                <Option value={SERVER_SELECT.BY_ACTIVE} key={SERVER_SELECT.BY_ACTIVE}>
                    {serverSelectTranslate(intl, SERVER_SELECT.BY_ACTIVE)}
                </Option>
            ));
        }
        if (optionsMap.BY_INACTIVE) {
            options.push((
                <Option value={SERVER_SELECT.BY_INACTIVE} key={SERVER_SELECT.BY_INACTIVE}>
                    {serverSelectTranslate(intl, SERVER_SELECT.BY_INACTIVE)}
                </Option>
            ));
        }
        if (optionsMap.DEFAULT !== servers.length) {
            options.push((
                <Option value={SERVER_SELECT.DEFAULT} key={SERVER_SELECT.DEFAULT}>
                    {serverSelectTranslate(intl, SERVER_SELECT.DEFAULT)}
                </Option>
            ));
        }
        return options;
    };

    const selectCheckbox = () => {
        setShowCheckbox(true);
        setOpen(false);
    };
    const options = getSelectOptions();
    return (
        <>
            <Select
                id="server_select"
                size="medium"
                disabled={options.length === 0}
                value={selectType || null}
                onChange={(v) => onSelectTypeChange(v)}
                className={cn(s.select, { 'select--transparent': transparent })}
                placeholder={text}
                block={!transparent}
                open={open}
                setOpen={setOpen}
                dropdownClassName="dropdown-render-hover"
                dropdownRender={(menu) => (
                    <div>
                        {menu}
                        {showCheckbox
                            ? (
                                <div
                                    className={s.selectButtonWrapper}
                                    onClick={() => {
                                        clearSelection();
                                        setOpen(false);
                                    }}
                                >
                                    <span className={s.selectButton}>
                                        {intl.getMessage('cancel_select')}
                                    </span>
                                </div>
                            ) : (
                                <div
                                    className={s.selectButtonWrapper}
                                    onClick={selectCheckbox}
                                >
                                    <span className={s.selectButton}>
                                        {intl.getMessage('select_by_manually')} ...
                                    </span>
                                </div>
                            )}
                    </div>
                )}
            >
                {options}
            </Select>
        </>
    );
});

export default ServerSelect;
