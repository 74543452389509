import BackupPolicy, { IBackupPolicy } from './BackupPolicy';
import IpAddress, { IIpAddress } from './IpAddress';
import ServerCostForecast, { IServerCostForecast } from './ServerCostForecast';
import ServerDistribution, { IServerDistribution } from './ServerDistribution';
import ServerResourceLimits, { IServerResourceLimits } from './ServerResourceLimits';
import { ServerState } from './ServerState';
import ServerUpgradeRecommendation, { IServerUpgradeRecommendation } from './ServerUpgradeRecommendation';
import Tariff, { ITariff } from './Tariff';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServer {
    backup_policy: IBackupPolicy;
    bandwidth_mbits: number;
    comment?: string;
    console_access: boolean;
    console_url?: string;
    cost_forecast: IServerCostForecast;
    cpu_cores: number;
    disk_gib: number;
    distribution: IServerDistribution;
    id: number;
    internal_id: string;
    ip_count: number;
    ipv4_addresses: IIpAddress[];
    ipv6_addresses: IIpAddress[];
    last_backup_time?: string;
    last_backup_time_millis?: number;
    limits: IServerResourceLimits;
    memory_mib: number;
    name: string;
    owner_client_id: number;
    private_address: IIpAddress;
    public_keys: number[];
    service_mode: boolean;
    snapshot_id?: number;
    snapshots_count: number;
    state: ServerState;
    support_access: boolean;
    tariff: ITariff;
    tenant_id: number;
    time_added: string;
    time_added_millis: number;
    time_deleted?: string;
    time_deleted_millis?: number;
    time_until_restore_available?: string;
    time_until_restore_available_millis?: number;
    upgrade_recommendation?: IServerUpgradeRecommendation;
    zram_enabled: boolean;
}

export default class Server {
    readonly _backup_policy: BackupPolicy;

    get backupPolicy(): BackupPolicy {
        return this._backup_policy;
    }

    readonly _bandwidth_mbits: number;

    /**
     * Description: Bandwidth in mbits/s
     * Example: 10
     */
    get bandwidthMbits(): number {
        return this._bandwidth_mbits;
    }

    static bandwidthMbitsValidate(bandwidthMbits: number): boolean {
        return typeof bandwidthMbits === 'number';
    }

    readonly _comment: string | undefined;

    /**
     * Description: Comment
     * Example: It's dev backend
     */
    get comment(): string | undefined {
        return this._comment;
    }

    readonly _console_access: boolean;

    /** */
    get consoleAccess(): boolean {
        return this._console_access;
    }

    static consoleAccessValidate(consoleAccess: boolean): boolean {
        return typeof consoleAccess === 'boolean';
    }

    readonly _console_url: string | undefined;

    /** */
    get consoleUrl(): string | undefined {
        return this._console_url;
    }

    readonly _cost_forecast: ServerCostForecast;

    get costForecast(): ServerCostForecast {
        return this._cost_forecast;
    }

    readonly _cpu_cores: number;

    /**
     * Description: CPU cores
     * Example: 1
     */
    get cpuCores(): number {
        return this._cpu_cores;
    }

    static cpuCoresValidate(cpuCores: number): boolean {
        return typeof cpuCores === 'number';
    }

    readonly _disk_gib: number;

    /**
     * Description: Disk capacity in gib
     * Example: 16
     */
    get diskGib(): number {
        return this._disk_gib;
    }

    static diskGibValidate(diskGib: number): boolean {
        return typeof diskGib === 'number';
    }

    readonly _distribution: ServerDistribution;

    get distribution(): ServerDistribution {
        return this._distribution;
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 51671
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _internal_id: string;

    /**
     * Description: Internal unique identifier
     * Example: vm51671
     */
    get internalId(): string {
        return this._internal_id;
    }

    static internalIdValidate(internalId: string): boolean {
        return typeof internalId === 'string' && !!internalId.trim();
    }

    readonly _ip_count: number;

    /**
     * Description: Number of allocated (or would-be-allocated for installing servers) ip addresses
     * Example: 2
     */
    get ipCount(): number {
        return this._ip_count;
    }

    static ipCountValidate(ipCount: number): boolean {
        return typeof ipCount === 'number';
    }

    readonly _ipv4_addresses: IpAddress[];

    /** */
    get ipv4Addresses(): IpAddress[] {
        return this._ipv4_addresses;
    }

    readonly _ipv6_addresses: IpAddress[];

    /** */
    get ipv6Addresses(): IpAddress[] {
        return this._ipv6_addresses;
    }

    readonly _last_backup_time: string | undefined;

    /**
     * Description: Time added of last backup
     * Example: 2020-05-12T12:00:00Z
     */
    get lastBackupTime(): string | undefined {
        return this._last_backup_time;
    }

    readonly _last_backup_time_millis: number | undefined;

    /**
     * Description: Time added of last backup, UTC millis
     * Example: 1589284800000
     */
    get lastBackupTimeMillis(): number | undefined {
        return this._last_backup_time_millis;
    }

    readonly _limits: ServerResourceLimits;

    get limits(): ServerResourceLimits {
        return this._limits;
    }

    readonly _memory_mib: number;

    /**
     * Description: Memory in mib
     * Example: 512
     */
    get memoryMib(): number {
        return this._memory_mib;
    }

    static memoryMibValidate(memoryMib: number): boolean {
        return typeof memoryMib === 'number';
    }

    readonly _name: string;

    /**
     * Description: Name
     * Example: dev_backend
     */
    get name(): string {
        return this._name;
    }

    static nameValidate(name: string): boolean {
        return typeof name === 'string' && !!name.trim();
    }

    readonly _owner_client_id: number;

    /**
     * Description: Server's owner's client id
     * Example: 19934
     */
    get ownerClientId(): number {
        return this._owner_client_id;
    }

    static ownerClientIdValidate(ownerClientId: number): boolean {
        return typeof ownerClientId === 'number';
    }

    readonly _private_address: IpAddress;

    get privateAddress(): IpAddress {
        return this._private_address;
    }

    readonly _public_keys: number[];

    /**
     * Description: Public keys ids
     * Example: 683
     */
    get publicKeys(): number[] {
        return this._public_keys;
    }

    static publicKeysValidate(publicKeys: number[]): boolean {
        return publicKeys.reduce<boolean>((result, p) => result && typeof p === 'number', true);
    }

    readonly _service_mode: boolean;

    /** */
    get serviceMode(): boolean {
        return this._service_mode;
    }

    static serviceModeValidate(serviceMode: boolean): boolean {
        return typeof serviceMode === 'boolean';
    }

    readonly _snapshot_id: number | undefined;

    /**
     * Description: Current snapshot
     * Example: 213
     */
    get snapshotId(): number | undefined {
        return this._snapshot_id;
    }

    readonly _snapshots_count: number;

    /**
     * Description: Snapshots count
     * Example: 1
     */
    get snapshotsCount(): number {
        return this._snapshots_count;
    }

    static snapshotsCountValidate(snapshotsCount: number): boolean {
        return typeof snapshotsCount === 'number';
    }

    readonly _state: ServerState;

    get state(): ServerState {
        return this._state;
    }

    static stateValidate(state: ServerState): boolean {
        return Object.keys(ServerState).includes(state);
    }

    readonly _support_access: boolean;

    /** */
    get supportAccess(): boolean {
        return this._support_access;
    }

    static supportAccessValidate(supportAccess: boolean): boolean {
        return typeof supportAccess === 'boolean';
    }

    readonly _tariff: Tariff;

    get tariff(): Tariff {
        return this._tariff;
    }

    readonly _tenant_id: number;

    /**
     * Description: Tenant id
     * Example: 19934
     */
    get tenantId(): number {
        return this._tenant_id;
    }

    static tenantIdValidate(tenantId: number): boolean {
        return typeof tenantId === 'number';
    }

    readonly _time_added: string;

    /**
     * Description: Time added
     * Example: 2020-05-08T16:41:40Z
     */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Time added, UTC millis
     * Example: 1586342500000
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    readonly _time_deleted: string | undefined;

    /**
     * Description: Time deleted
     * Example: 2020-04-08T16:41:40Z
     */
    get timeDeleted(): string | undefined {
        return this._time_deleted;
    }

    readonly _time_deleted_millis: number | undefined;

    /**
     * Description: Time deleted, UTC millis
     * Example: 1588956100000
     */
    get timeDeletedMillis(): number | undefined {
        return this._time_deleted_millis;
    }

    readonly _time_until_restore_available: string | undefined;

    /**
     * Description: Time until deleted server can still be restored, or null if restore isn't available
     * Example: 2020-05-08T16:41:40Z
     */
    get timeUntilRestoreAvailable(): string | undefined {
        return this._time_until_restore_available;
    }

    readonly _time_until_restore_available_millis: number | undefined;

    /**
     * Description: Time until deleted server can still be restored, or null if restore isn't available, UTC millis
     * Example: 1591634500000
     */
    get timeUntilRestoreAvailableMillis(): number | undefined {
        return this._time_until_restore_available_millis;
    }

    readonly _upgrade_recommendation: ServerUpgradeRecommendation | undefined;

    get upgradeRecommendation(): ServerUpgradeRecommendation | undefined {
        return this._upgrade_recommendation;
    }

    readonly _zram_enabled: boolean;

    /**
     * Description: Zram enabled
     * Example: true
     */
    get zramEnabled(): boolean {
        return this._zram_enabled;
    }

    static zramEnabledValidate(zramEnabled: boolean): boolean {
        return typeof zramEnabled === 'boolean';
    }

    constructor(props: IServer) {
        this._backup_policy = new BackupPolicy(props.backup_policy);
        this._bandwidth_mbits = props.bandwidth_mbits;
        if (typeof props.comment === 'string') {
            this._comment = props.comment.trim();
        }
        this._console_access = props.console_access;
        if (typeof props.console_url === 'string') {
            this._console_url = props.console_url.trim();
        }
        this._cost_forecast = new ServerCostForecast(props.cost_forecast);
        this._cpu_cores = props.cpu_cores;
        this._disk_gib = props.disk_gib;
        this._distribution = new ServerDistribution(props.distribution);
        this._id = props.id;
        this._internal_id = props.internal_id.trim();
        this._ip_count = props.ip_count;
        this._ipv4_addresses = props.ipv4_addresses.map((p) => new IpAddress(p));
        this._ipv6_addresses = props.ipv6_addresses.map((p) => new IpAddress(p));
        if (typeof props.last_backup_time === 'string') {
            this._last_backup_time = props.last_backup_time.trim();
        }
        if (typeof props.last_backup_time_millis === 'number') {
            this._last_backup_time_millis = props.last_backup_time_millis;
        }
        this._limits = new ServerResourceLimits(props.limits);
        this._memory_mib = props.memory_mib;
        this._name = props.name.trim();
        this._owner_client_id = props.owner_client_id;
        this._private_address = new IpAddress(props.private_address);
        this._public_keys = props.public_keys;
        this._service_mode = props.service_mode;
        if (typeof props.snapshot_id === 'number') {
            this._snapshot_id = props.snapshot_id;
        }
        this._snapshots_count = props.snapshots_count;
        this._state = props.state;
        this._support_access = props.support_access;
        this._tariff = new Tariff(props.tariff);
        this._tenant_id = props.tenant_id;
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
        if (typeof props.time_deleted === 'string') {
            this._time_deleted = props.time_deleted.trim();
        }
        if (typeof props.time_deleted_millis === 'number') {
            this._time_deleted_millis = props.time_deleted_millis;
        }
        if (typeof props.time_until_restore_available === 'string') {
            this._time_until_restore_available = props.time_until_restore_available.trim();
        }
        if (typeof props.time_until_restore_available_millis === 'number') {
            this._time_until_restore_available_millis = props.time_until_restore_available_millis;
        }
        if (props.upgrade_recommendation) {
            this._upgrade_recommendation = new ServerUpgradeRecommendation(props.upgrade_recommendation);
        }
        this._zram_enabled = props.zram_enabled;
    }

    serialize(): IServer {
        const data: IServer = {
            backup_policy: this._backup_policy.serialize(),
            bandwidth_mbits: this._bandwidth_mbits,
            console_access: this._console_access,
            cost_forecast: this._cost_forecast.serialize(),
            cpu_cores: this._cpu_cores,
            disk_gib: this._disk_gib,
            distribution: this._distribution.serialize(),
            id: this._id,
            internal_id: this._internal_id,
            ip_count: this._ip_count,
            ipv4_addresses: this._ipv4_addresses.map((p) => p.serialize()),
            ipv6_addresses: this._ipv6_addresses.map((p) => p.serialize()),
            limits: this._limits.serialize(),
            memory_mib: this._memory_mib,
            name: this._name,
            owner_client_id: this._owner_client_id,
            private_address: this._private_address.serialize(),
            public_keys: this._public_keys,
            service_mode: this._service_mode,
            snapshots_count: this._snapshots_count,
            state: this._state,
            support_access: this._support_access,
            tariff: this._tariff.serialize(),
            tenant_id: this._tenant_id,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
            zram_enabled: this._zram_enabled,
        };
        if (typeof this._comment !== 'undefined') {
            data.comment = this._comment;
        }
        if (typeof this._console_url !== 'undefined') {
            data.console_url = this._console_url;
        }
        if (typeof this._last_backup_time !== 'undefined') {
            data.last_backup_time = this._last_backup_time;
        }
        if (typeof this._last_backup_time_millis !== 'undefined') {
            data.last_backup_time_millis = this._last_backup_time_millis;
        }
        if (typeof this._snapshot_id !== 'undefined') {
            data.snapshot_id = this._snapshot_id;
        }
        if (typeof this._time_deleted !== 'undefined') {
            data.time_deleted = this._time_deleted;
        }
        if (typeof this._time_deleted_millis !== 'undefined') {
            data.time_deleted_millis = this._time_deleted_millis;
        }
        if (typeof this._time_until_restore_available !== 'undefined') {
            data.time_until_restore_available = this._time_until_restore_available;
        }
        if (typeof this._time_until_restore_available_millis !== 'undefined') {
            data.time_until_restore_available_millis = this._time_until_restore_available_millis;
        }
        if (typeof this._upgrade_recommendation !== 'undefined') {
            data.upgrade_recommendation = this._upgrade_recommendation.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: typeof this._name === 'string' && !!this._name.trim(),
            id: typeof this._id === 'number',
            tenant_id: typeof this._tenant_id === 'number',
            owner_client_id: typeof this._owner_client_id === 'number',
            internal_id: typeof this._internal_id === 'string' && !!this._internal_id.trim(),
            memory_mib: typeof this._memory_mib === 'number',
            disk_gib: typeof this._disk_gib === 'number',
            cpu_cores: typeof this._cpu_cores === 'number',
            bandwidth_mbits: typeof this._bandwidth_mbits === 'number',
            tariff: this._tariff.validate().length === 0,
            upgrade_recommendation: !this._upgrade_recommendation ? true : this._upgrade_recommendation.validate().length === 0,
            zram_enabled: typeof this._zram_enabled === 'boolean',
            ip_count: typeof this._ip_count === 'number',
            ipv4_addresses: this._ipv4_addresses.reduce((result, p) => result && p.validate().length === 0, true),
            ipv6_addresses: this._ipv6_addresses.reduce((result, p) => result && p.validate().length === 0, true),
            private_address: this._private_address.validate().length === 0,
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
            time_deleted: !this._time_deleted ? true : typeof this._time_deleted === 'string',
            time_deleted_millis: !this._time_deleted_millis ? true : typeof this._time_deleted_millis === 'number',
            time_until_restore_available: !this._time_until_restore_available ? true : typeof this._time_until_restore_available === 'string',
            time_until_restore_available_millis: !this._time_until_restore_available_millis ? true : typeof this._time_until_restore_available_millis === 'number',
            snapshot_id: !this._snapshot_id ? true : typeof this._snapshot_id === 'number',
            snapshots_count: typeof this._snapshots_count === 'number',
            backup_policy: this._backup_policy.validate().length === 0,
            distribution: this._distribution.validate().length === 0,
            public_keys: this._public_keys.reduce((result, p) => result && typeof p === 'number', true),
            last_backup_time: !this._last_backup_time ? true : typeof this._last_backup_time === 'string',
            last_backup_time_millis: !this._last_backup_time_millis ? true : typeof this._last_backup_time_millis === 'number',
            comment: !this._comment ? true : typeof this._comment === 'string',
            limits: this._limits.validate().length === 0,
            cost_forecast: this._cost_forecast.validate().length === 0,
            service_mode: typeof this._service_mode === 'boolean',
            console_access: typeof this._console_access === 'boolean',
            support_access: typeof this._support_access === 'boolean',
            console_url: !this._console_url ? true : typeof this._console_url === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServer): Server {
        return new Server(props);
    }

    readonly keys: { [key: string]: string } = {
        backupPolicy: 'backup_policy',
        bandwidthMbits: 'bandwidth_mbits',
        comment: 'comment',
        consoleAccess: 'console_access',
        consoleUrl: 'console_url',
        costForecast: 'cost_forecast',
        cpuCores: 'cpu_cores',
        diskGib: 'disk_gib',
        distribution: 'distribution',
        id: 'id',
        internalId: 'internal_id',
        ipCount: 'ip_count',
        ipv4Addresses: 'ipv4_addresses',
        ipv6Addresses: 'ipv6_addresses',
        lastBackupTime: 'last_backup_time',
        lastBackupTimeMillis: 'last_backup_time_millis',
        limits: 'limits',
        memoryMib: 'memory_mib',
        name: 'name',
        ownerClientId: 'owner_client_id',
        privateAddress: 'private_address',
        publicKeys: 'public_keys',
        serviceMode: 'service_mode',
        snapshotId: 'snapshot_id',
        snapshotsCount: 'snapshots_count',
        state: 'state',
        supportAccess: 'support_access',
        tariff: 'tariff',
        tenantId: 'tenant_id',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        timeDeleted: 'time_deleted',
        timeDeletedMillis: 'time_deleted_millis',
        timeUntilRestoreAvailable: 'time_until_restore_available',
        timeUntilRestoreAvailableMillis: 'time_until_restore_available_millis',
        upgradeRecommendation: 'upgrade_recommendation',
        zramEnabled: 'zram_enabled',
        }
;

    mergeDeepWith(props: Partial<Server>): Server {
        const updateData: Partial<IServer> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Server) => {
            const updateKey = this.keys[key] as keyof IServer;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServer, keyof IServer>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Server({ ...this.serialize(), ...updateData });
    }
}
