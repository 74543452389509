import React, { FC } from 'react';

import { useIntl, CommonModalLayout } from 'Common';

interface UpdateKeysModalLayoutProps {
    visible: boolean;
    title: string;
    buttonText?: string;
    submitButtonId?: string;
    handleClose: () => void;
    handleUpdate?: () => void;
    disabled: boolean;
}

const UpdateKeysModalLayout: FC<UpdateKeysModalLayoutProps> = ({
    visible,
    children,
    title,
    buttonText,
    submitButtonId,
    handleClose,
    handleUpdate,
    disabled,
}) => {
    const intl = useIntl();

    return (
        <CommonModalLayout
            visible={visible}
            title={title}
            submitButtonId={submitButtonId}
            buttonText={buttonText || intl.getMessage('delete')}
            handleSubmit={handleUpdate}
            handleClose={handleClose}
            disabled={disabled}
        >
            {children}
        </CommonModalLayout>
    );
};

export default UpdateKeysModalLayout;
