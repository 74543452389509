import { Action } from 'Actions/.';
import {
    MainAccountActions,
    saveAccount,
    saveAccountDetails,
    saveProfileInfo,
    saveResellerClients,
} from 'Actions/mainAccount';
import Account from 'Entities/Account';
import AccountDetails from 'Entities/AccountDetails';

import { MainAccountStore, mainAccountInitialState as initialState } from '../initialStates';

const reducer = (state: MainAccountStore = initialState, action: Action): MainAccountStore => {
    switch (action.type as MainAccountActions) {
        case MainAccountActions.SaveMainAccount: {
            const { payload } = action as ReturnType<typeof saveAccount>;
            const account: Account = payload;
            return { ...state, account };
        }
        case MainAccountActions.SaveMainAccountDetails: {
            const { payload } = action as ReturnType<typeof saveAccountDetails>;
            const details: AccountDetails = payload;
            return { ...state, details };
        }
        case MainAccountActions.SaveMainProfileInfo: {
            const { payload: profile } = action as ReturnType<typeof saveProfileInfo>;
            return { ...state, profile };
        }
        case MainAccountActions.SaveMainResellerClients: {
            const { payload: clients } = action as ReturnType<typeof saveResellerClients>;
            return { ...state, clients };
        }
        default:
            return state;
    }
};

export default reducer;
