import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useRouteMatch } from 'react-router-dom';
import { Divider } from 'antd';
import cn from 'classnames';

import { Icon, Link, mediumText, useIntl } from 'Common';
import theme from 'Lib/theme';
import { Store } from 'Store';
import { getPhoneCodes } from 'Actions/profile';
import { formatPhoneNumber } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import { TICKETS_SUBJECT } from 'Lib/helpers/consts';

import { ModalPhone, ChangePassword } from '.';
import s from './Profile.module.pcss';

interface AccountInfoProps {
    setOpenEditModal: (v: boolean) => void;
}

const selectProfile = (store: Store) => store.profile.info;
const selectPhoneCodes = (store: Store) => store.profile.phoneCodes;

const selector = createSelector(
    [selectProfile, selectPhoneCodes],
    (info, phoneCodes) => ({ info, phoneCodes }),
);

const AccountInfo: FC<AccountInfoProps> = ({ setOpenEditModal }) => {
    const { params } = useRouteMatch<{ openModal: string; openEditModal: string }>();
    const dispatch = useDispatch();

    const intl = useIntl();
    const profile = useSelector(selector);
    const shouldShowModal = !profile.info?.phone && !!params.openModal;
    const shouldShowEditModal = !!params.openEditModal;

    const [openPhoneModal, setOpenPhoneModal] = useState(shouldShowModal);
    const [openPasswordModal, setOpenPasswordModal] = useState(false);

    const { info, phoneCodes } = profile;

    const supportLink = (text: string) => {
        return (
            <Link
                to={RoutePath.CreateTicketWithSubject}
                props={{ subject: TICKETS_SUBJECT.EMAIL_EDIT }}
            >
                {text}
            </Link>
        );
    };

    useEffect(() => {
        setOpenEditModal(shouldShowEditModal);
        dispatch(getPhoneCodes());
    }, []);

    if (!info) {
        return null;
    }

    return (
        <>
            <div className={s.accountInfo}>
                <div className={s.column}>
                    <div className={s.item}>
                        <Link
                            to={RoutePath.CreateTicketWithSubject}
                            props={{ subject: TICKETS_SUBJECT.EMAIL_EDIT }}
                            className={cn(
                                s.itemInnerwrapper,
                                s.link,
                            )}
                        >
                            <div className={s.label}>
                                {intl.getMessage('email')}
                            </div>
                            <div
                                className={cn(
                                    s.value,
                                    s.value_truncatedText,
                                )}
                            >
                                {info.email}
                            </div>
                            <div className={s.action}>
                                <Icon icon="edit" className={s.icon} />
                            </div>
                        </Link>
                        <Divider className={cn(theme.divider.divider, s.divider)} />
                    </div>
                    <div className={s.item}>
                        <div
                            className={s.itemInnerwrapper}
                            onClick={() => setOpenPhoneModal(true)}
                        >
                            <div className={s.label}>
                                {intl.getMessage('phone')}
                            </div>
                            <div className={s.value}>
                                {info.phone ? (
                                    <div className={s.phone}>
                                        {formatPhoneNumber(info.phone.fullNumber)}
                                    </div>
                                ) : (
                                    <div className={s.notice}>
                                        {intl.getMessage('profile_phone_notice')}
                                    </div>
                                )}
                            </div>
                            <div className={s.action}>
                                <Icon icon="edit" className={s.icon} />
                            </div>
                        </div>
                        <Divider className={cn(theme.divider.divider, s.divider)} />
                    </div>
                    <div className={s.item}>
                        <div
                            className={s.itemInnerwrapper}
                            onClick={() => setOpenPasswordModal(true)}
                        >
                            <div className={s.label}>
                                {intl.getMessage('password')}
                            </div>
                            <div className={s.value}>
                                <div className={s.digits}>
                                    {[...Array(11).keys()]
                                        .map((elem) => (
                                            <div
                                                className={s.dot}
                                                key={elem}
                                            />
                                        ))}
                                </div>
                            </div>
                            <div className={s.action}>
                                <Icon icon="edit" className={s.icon} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.sidebar}>
                    <div className={s.sidebar_block}>
                        {intl.getMessage('profile_email_edit', { a: supportLink })}
                    </div>
                    {info.clientId && (
                        <div className={s.sidebar_block}>
                            <div className={s.sidebar_id}>
                                {intl.getMessage('profile_client_id', {
                                    b: mediumText,
                                    value: info.clientId,
                                })}
                            </div>
                        </div>
                    )}
                    {info.resellerEmail && (
                        <div className={s.sidebar_block}>
                            <div className={s.sidebar_id}>
                                {intl.getMessage('reseller_label', {
                                    black: mediumText,
                                    value: info.resellerEmail,
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {openPhoneModal && phoneCodes && (
                <ModalPhone
                    handleClose={() => setOpenPhoneModal(false)}
                    phone={info.phone}
                    phoneCodes={phoneCodes}
                />
            )}

            {openPasswordModal && (
                <ChangePassword
                    handleClose={() => setOpenPasswordModal(false)}
                />
            )}
        </>
    );
};

export default AccountInfo;
