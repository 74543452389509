import PhoneInfo, { IPhoneInfo } from './PhoneInfo';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface INotificationRecipient {
    email?: string;
    id: number;
    is_default: boolean;
    name: string;
    phone?: IPhoneInfo;
}

export default class NotificationRecipient {
    readonly _email: string | undefined;

    /**
     * Description: Recipient email
     * Example: mymail@gmail.com
     */
    get email(): string | undefined {
        return this._email;
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 123
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _is_default: boolean;

    /**
     * Description: Is this recipient default for client
     * Example: true
     */
    get isDefault(): boolean {
        return this._is_default;
    }

    static isDefaultValidate(isDefault: boolean): boolean {
        return typeof isDefault === 'boolean';
    }

    readonly _name: string;

    /**
     * Description: Recipient name
     * Example: mymail@gmail.com
     */
    get name(): string {
        return this._name;
    }

    static nameValidate(name: string): boolean {
        return typeof name === 'string' && !!name.trim();
    }

    readonly _phone: PhoneInfo | undefined;

    get phone(): PhoneInfo | undefined {
        return this._phone;
    }

    constructor(props: INotificationRecipient) {
        if (typeof props.email === 'string') {
            this._email = props.email.trim();
        }
        this._id = props.id;
        this._is_default = props.is_default;
        this._name = props.name.trim();
        if (props.phone) {
            this._phone = new PhoneInfo(props.phone);
        }
    }

    serialize(): INotificationRecipient {
        const data: INotificationRecipient = {
            id: this._id,
            is_default: this._is_default,
            name: this._name,
        };
        if (typeof this._email !== 'undefined') {
            data.email = this._email;
        }
        if (typeof this._phone !== 'undefined') {
            data.phone = this._phone.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            email: !this._email ? true : typeof this._email === 'string',
            phone: !this._phone ? true : this._phone.validate().length === 0,
            is_default: typeof this._is_default === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: INotificationRecipient): NotificationRecipient {
        return new NotificationRecipient(props);
    }

    readonly keys: { [key: string]: string } = {
        email: 'email',
        id: 'id',
        isDefault: 'is_default',
        name: 'name',
        phone: 'phone',
        }
;

    mergeDeepWith(props: Partial<NotificationRecipient>): NotificationRecipient {
        const updateData: Partial<INotificationRecipient> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof NotificationRecipient) => {
            const updateKey = this.keys[key] as keyof INotificationRecipient;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<INotificationRecipient, keyof INotificationRecipient>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new NotificationRecipient({ ...this.serialize(), ...updateData });
    }
}
