import React, { FC, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Button, Checkbox, useIntl } from 'Common';
import { clearTickets, markTicketsRead } from 'Actions/support';
import TicketEntity from 'Entities/Ticket';
import { IMarkTicketsReadRequest } from 'Entities/MarkTicketsReadRequest';
import { Store } from 'Store';
import theme from 'Lib/theme';

import s from './List.module.pcss';
import { Empty, Ticket, Contacts } from '..';

const getInitialShowUserTickets = (tickets: TicketEntity[] | null, unread: boolean) => {
    if (!unread || !tickets) {
        return false;
    }

    const filteredTickets = tickets.filter((t) => t.createdByUser);

    if (filteredTickets.length > 0) {
        return true;
    }

    return false;
};

interface ListStoreProps {
    tickets: TicketEntity[] | null;
}

interface ListOwnProps {
    reset: () => void;
    unread?: boolean;
}

type ListProps = ListStoreProps & ListOwnProps;

const List: FC<ListProps> = ({ tickets, reset, unread }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [checkList, setCheckList] = useState<Set<number>>(new Set());
    const [loading, setLoading] = useState(false);

    const initialShowUserTickets = getInitialShowUserTickets(tickets, unread || false);
    const [showUserTickets, setShowUserTickets] = useState(initialShowUserTickets);

    const selectAll = () => {
        if (checkList.size > 0) {
            setCheckList(new Set());
        } else {
            if (tickets) {
                const selected = Array.from(tickets.values()).map((n) => n.id);
                setCheckList(new Set(selected));
            }

            return false;
        }
    };

    const setTicketsRead = () => {
        setLoading(true);

        const idsArray = Array.from(checkList);

        const ids: IMarkTicketsReadRequest = {
            ticket_ids: idsArray,
        };

        dispatch(markTicketsRead(
            [ids],
            {
                result: () => {
                    setLoading(false);
                    idsArray.forEach((id) => {
                        checkList.delete(id);
                    });
                    setCheckList(new Set(checkList));
                    dispatch(clearTickets());
                    reset();
                },
            },
        ));
    };

    if (!tickets || tickets.length === 0) {
        if (unread) {
            return <Empty type="open" />;
        }

        return <Empty type="history" />;
    }

    const filteredTickets: TicketEntity[] = showUserTickets
        ? tickets.filter((t) => t.createdByUser)
        : tickets;

    return (
        <div className={s.tickets}>
            <div className={s.wrap}>
                <div className={s.header}>
                    {unread && (
                        <div className={s.buttons}>
                            <Button
                                type="border"
                                size="medium"
                                id="select_all"
                                className={cn(
                                    theme.button.with_icon,
                                    { [theme.button.border_active]:
                                        checkList.size > 0 },
                                )}
                                onClick={selectAll}
                                icon="check"
                                disabled={loading}
                            >
                                {checkList.size ? (
                                    intl.getMessage('all_selected', { value: checkList.size })
                                ) : intl.getMessage('select_all')}
                            </Button>
                            {(checkList.size > 0) && (
                                <Button
                                    type="border"
                                    size="medium"
                                    id="select_as_read"
                                    className={cn(
                                        theme.button.desktop_medium,
                                        theme.button.with_icon,
                                        s.read,
                                    )}
                                    onClick={setTicketsRead}
                                    icon="visibility_enable"
                                    disabled={loading}
                                >
                                    {intl.getMessage('select_as_read')}
                                </Button>
                            )}
                        </div>
                    )}
                    <div className={s.checkbox}>
                        <Checkbox
                            id={`user_tickets_${unread ? 'unread' : 'read'}`}
                            name={`user_tickets_${unread ? 'unread' : 'read'}`}
                            checked={showUserTickets}
                            handleChange={
                                (e) => setShowUserTickets(e.target.checked)
                            }
                            labelClassName={s.checkboxLabel}
                        >
                            {intl.getMessage('support_show_user_tickets')}
                        </Checkbox>
                    </div>
                </div>

                {filteredTickets.map((ticket) => (
                    <Ticket
                        key={ticket.id}
                        ticket={ticket}
                        checkList={checkList}
                        setCheckList={setCheckList}
                        disabledSelect={loading}
                        unread={unread}
                    />
                ))}
                {showUserTickets && filteredTickets.length === 0 && (
                    <Empty type="checkbox_checked" />
                )}
            </div>
            <Contacts />
        </div>
    );
};

const selectTickets = (store: Store, op: ListOwnProps) => {
    const { unread } = op;

    const { tickets } = store.support;

    if (tickets) {
        const ticketsArray = Array.from(tickets.values());
        return ticketsArray
            .filter((t) => (unread && t.unreadPostCount > 0)
                || (!unread && t.unreadPostCount === 0));
    }

    return null;
};

const selector = createSelector(
    [selectTickets],
    (tickets) => ({ tickets }),
);

const mapStoreToProps = (store: Store, op: ListOwnProps) => (
    { ...selector(store, op) }
);

export default connect(mapStoreToProps)(List);
