import React, { FC } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useIntl } from 'Common';
import Account from 'Entities/Account';
import { Store } from 'Store';
import { SUPPORT_MAIL, SUPPORT_PHONE } from 'Lib/helpers/consts';
import { externalLinkBuilder, GuestRoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import s from './Contacts.module.pcss';

interface ContactsProps {
    account: Account | null;
    emptyPage?: boolean;
}

const Contacts: FC<ContactsProps> = ({ account, emptyPage }) => {
    const intl = useIntl();
    const { currentLocale } = intl;

    if (!account) {
        return null;
    }
    return (
        <div className={cn(s.contacts, { [s.contacts_empty]: emptyPage })}>
            <div className={s.info}>
                <a
                    href={`mailto:${SUPPORT_MAIL}`}
                    className={cn(
                        theme.link.link,
                        theme.link.blue,
                        s.link,
                    )}
                >
                    {SUPPORT_MAIL}
                </a>
                <div className={s.label}>
                    {intl.getMessage('support_contacts_desc_email')}
                </div>
            </div>
            <div className={s.info}>
                <a
                    href={`tel:${SUPPORT_PHONE}`}
                    className={cn(
                        theme.link.link,
                        theme.link.blue,
                        s.link,
                    )}
                >
                    {SUPPORT_PHONE}
                </a>
                <div className={s.label}>
                    {intl.getMessage('support_contacts_desc_phone')}
                </div>
            </div>
            {!emptyPage && (
                <div className={s.info}>
                    <a
                        href={externalLinkBuilder(currentLocale, GuestRoutePath.Kb)}
                        className={cn(
                            theme.link.link,
                            theme.link.blue,
                            s.link,
                        )}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {intl.getMessage('menu_kb')}
                    </a>
                    <div className={s.label}>
                        {intl.getMessage('support_contacts_desc_kb')}
                    </div>
                </div>
            )}
        </div>
    );
};

const selectAccount = (store: Store) => store.account.account;

const selector = createSelector([
    selectAccount,
], (account) => {
    return {
        account,
    };
});

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);
export default connect(mapStoreToProps)(Contacts);
