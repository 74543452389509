import { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { InplaceType, Action } from 'Actions/.';
import { addError } from 'Actions/error';
import { sendSecurityCode, sendBatchSecurityCode } from 'Actions/security';
import ResourceLimitsApi from 'Apis/resourceLimits';
import { useIntl } from 'Common';
import ResourceLimits, { IServerResourceLimits } from 'Entities/ServerResourceLimits';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { ISecurityCodeSendRequest } from 'Entities/SecurityCodeSendRequest';
import { IBatchSecurityCodeSendRequest } from 'Entities/BatchSecurityCodeSendRequest';
import { NotificationDeliveryType } from 'Entities/NotificationDeliveryType';
import { errorChecker, setServerSearch } from 'Lib/helpers/utils';
import { Store } from 'Store';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { OperationStatus } from 'Entities/OperationStatus';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';

const LimitsMap = new Map<string, ResourceLimits>();
export const useResLimits = (
    tenantId: number,
    distributionId: number,
) => {
    const [resLimits, setResLimits] = useState<ResourceLimits | null>(null);
    const dispatch = useDispatch();
    useEffect(() => {
        let cancel = false;
        const loader = async () => {
            const key = `${distributionId}.${tenantId}`;
            if (LimitsMap.has(key)) {
                setResLimits(LimitsMap.get(key)!);
                return;
            }
            const response = await ResourceLimitsApi.getResourceLimitsForDraft(
                tenantId, distributionId,
            );
            const { error, result } = errorChecker<IServerResourceLimits>(response);
            if (result) {
                if (!cancel) {
                    const limits = new ResourceLimits(result);
                    LimitsMap.set(key, limits);
                    setResLimits(limits);
                }
            }
            if (error) {
                dispatch(addError({ error, type: InplaceType.GetResourceLimits }));
            }
        };
        try {
            loader();
        } catch (error) {
            setResLimits(null);
        }
        return () => {
            cancel = true;
        };
    }, [distributionId, tenantId]);
    return resLimits;
};

export const useEscape = (func: () => void) => useEffect(() => {
    const onEscPress = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            func();
        }
    };
    window.addEventListener('keyup', onEscPress);
    return () => {
        window.removeEventListener('keyup', onEscPress);
    };
}, []);

export const useEnter = (func: () => void, deps?: any[]) => {
    useEffect(() => {
        const onEnter = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                func();
            }
        };
        window.addEventListener('keyup', onEnter);
        return () => {
            window.removeEventListener('keyup', onEnter);
        };
    }, deps ? [...deps] : []);
};

const cachedScripts: string[] = [];
export const useScript = (src: string) => {
    const [state, setState] = useState({ loaded: false, error: false });
    useEffect(() => {
        if (cachedScripts.includes(src)) {
            setState({ loaded: true, error: false });
            return () => {};
        }
        cachedScripts.push(src);
        const script = document.createElement('script');
        script.src = src;
        script.async = true;

        const onScriptLoad = () => setState({ loaded: true, error: false });
        const onScriptError = () => {
            const index = cachedScripts.indexOf(src);
            if (index + 1) {
                cachedScripts.splice(index, 1);
            }
            script.remove();
            setState({ loaded: true, error: true });
        };

        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);
        document.body.appendChild(script);

        return () => {
            script.removeEventListener('load', onScriptLoad);
            script.removeEventListener('error', onScriptError);
        };
    }, [src]);
    return state;
};

export const useServerInProgress = (serverId: number) => {
    const { progress, lang, tenantId } = useSelector((s: Store) => ({
        progress: s.operationProgress?.commonOperations.get(serverId),
        tenantId: s.server.get(serverId)?.tenantId,
        lang: s.intl.currentLocale,
    }), shallowEqual);
    const history = useHistory();
    useEffect(() => {
        if (progress?.status === OperationStatus.PENDING) {
            history.push(linkPathBuilder(lang, RoutePath.Server, { tenantId, serverId }));
        }
    }, [progress]);
    return progress?.status === OperationStatus.PENDING;
};

type UseSecurityActionsReturn = {
    shouldConfirmAction: boolean;
    sendConfirmationCode: (data: {
        tenantId: ISecurityCodeSendRequest['tenant_id'];
        serverId?: ISecurityCodeSendRequest['server_id'];
    }) => void;
    codeSent: boolean;
    deliveryMessage: string;
    setCodeSent: React.Dispatch<React.SetStateAction<boolean>>;
};

type UseSecurityActionsBatch = {
    shouldConfirmAction: boolean;
    sendConfirmationCode: (data: {
        tenantId: IBatchSecurityCodeSendRequest['tenant_id'];
        serverIds: IBatchSecurityCodeSendRequest['server_ids'];
    }) => void;
    codeSent: boolean;
    deliveryMessage: string;
    setCodeSent: React.Dispatch<React.SetStateAction<boolean>>;
};

function useSecurityBatchActions(
    action: SecurityActionType, batch?: true,
): UseSecurityActionsBatch {
    const { settings, deliveryType } = useSelector(
        (store: Store) => ({
            settings: store.security.settings,
            deliveryType: store.security.deliveryType,
        }),
        shallowEqual,
    );
    const intl = useIntl();
    const dispatch = useDispatch();
    const [codeSent, setCodeSent] = useState(false);
    const actionType = settings?.settings.find((s) => {
        if (action === SecurityActionType.SERVER_PASSWORD) {
            return s.actionType === SecurityActionType.SERVER_PUBLIC_KEY;
        }
        return s.actionType === action;
    });
    const shouldConfirmAction = !!(actionType?.emailEnabled || actionType?.smsEnabled);
    let sendConfirmationCode = null;

    if (!batch) {
        sendConfirmationCode = (data: {
            tenantId: ISecurityCodeSendRequest['tenant_id'];
            serverId?: ISecurityCodeSendRequest['server_id'];
        }) => {
            setCodeSent(true);
            const req: ISecurityCodeSendRequest = {
                tenant_id: data.tenantId,
            };
            if (data.serverId) {
                req.server_id = data.serverId;
            }
            dispatch(sendSecurityCode([action, req]));
        };
    } else {
        sendConfirmationCode = (data: {
            tenantId: IBatchSecurityCodeSendRequest['tenant_id'];
            serverIds: IBatchSecurityCodeSendRequest['server_ids'];
        }) => {
            setCodeSent(true);
            const req: IBatchSecurityCodeSendRequest = {
                tenant_id: data.tenantId,
                server_ids: data.serverIds,
            };
            dispatch(sendBatchSecurityCode([action, req]));
        };
    }
    let deliveryMessage = '';
    switch (deliveryType) {
        case NotificationDeliveryType.EMAIL:
            deliveryMessage = intl.getMessage('code_by_email');
            break;
        case NotificationDeliveryType.SMS:
            deliveryMessage = intl.getMessage('code_by_sms');
            break;
        case NotificationDeliveryType.NONE:
            break;
    }
    return { shouldConfirmAction, sendConfirmationCode, codeSent, deliveryMessage, setCodeSent };
}

type UseSecurityActions = (action: SecurityActionType) => UseSecurityActionsReturn;

const useSecurityActions = useSecurityBatchActions as UseSecurityActions;

export { useSecurityActions, useSecurityBatchActions };

export const EVENTS_LIMIT = 20;
export const useOffsetLoader = <T = any>(entity: T[] | null, getLoadAction: (
    offset: number,
    limit: number,
) => Action<any>) => {
    const [offset, setOffset] = useState<number | null>(0);
    const dispatch = useDispatch();

    const offsetValue = offset || 0;

    const onScroll = () => {
        if (
            (window.scrollY + window.innerHeight) >= document.body.scrollHeight
            && entity !== null
            && entity.length / EVENTS_LIMIT === offsetValue + 1
        ) {
            setOffset(offsetValue + 1);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, [entity]);

    useEffect(() => {
        if (offset === null) {
            setOffset(0);
            return;
        }
        if (
            entity === null
            || entity.length <= offsetValue * EVENTS_LIMIT
        ) {
            dispatch(getLoadAction(offsetValue, EVENTS_LIMIT));
        } else {
            setOffset(Math.floor(entity.length / EVENTS_LIMIT));
        }
    }, [offset]);
    return { reset: () => setOffset(null) };
};

export const useTitle = (title: string) => {
    useEffect(() => {
        document.title = title;
    }, []);
};

export const useServerNotExist = () => {
    const match = useRouteMatch<{serverId: string}>();
    const { params: { serverId } } = match;

    const parsedId = Number(serverId);
    const hasServer = useSelector((s: Store) => !s.server || s.server?.has(parsedId), shallowEqual);

    return Number.isNaN(parsedId) || !hasServer;
};

export const useUISettings = () => {
    const ui = useSelector((s: Store) => s.ui, shallowEqual);
    return ui;
};

export const useClickOutside = (
    ref: React.RefObject<any> | React.RefObject<any>[],
    handler: () => void,
) => {
    useEffect(() => {
        const listener = (event: any) => {
            if (
                Array.isArray(ref)
                && ref.some((r) => !r.current || r.current.contains(event.target))
            ) {
                return;
            }

            if (
                !Array.isArray(ref)
                && (!ref.current || ref.current.contains(event.target))
            ) {
                return;
            }

            handler();
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler]);
};

export const useServerSearchSave = (id: number, notExist?: boolean) => {
    useEffect(() => {
        if (id && !notExist) {
            setServerSearch(id);
        }
    }, [id]);
};
