import React, { FC } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Icon, ServerIndicator } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import Server from 'Entities/Server';
import { getServerIcon } from 'Lib/helpers/utils';
import { Store } from 'Store';
import theme from 'Lib/theme';
import { useIntl } from 'Common/intl';

interface ServerOwnProps {
    server: Server;
    currentServerId: number;
    handleSelect: (server: Server) => void;
}

interface ServerStoreProps {
    operationProgress?: OperationProgress;
}

type ServerItemProps = ServerStoreProps & ServerOwnProps;

const ServerItem: FC<ServerItemProps> = ({
    server,
    currentServerId,
    operationProgress,
    handleSelect,
}) => {
    const intl = useIntl();
    const { id, state, distribution, name, ipv4Addresses, ipv6Addresses } = server;

    const ipList = [...ipv4Addresses, ...ipv6Addresses];

    const ipsLimitReached = server.limits.maxIpCount <= ipList.length;

    return (
        <div
            className={cn(
                theme.search.server,
                theme.search.server_modal,
                { [theme.search.server_ips_limit]: ipsLimitReached },
            )}
            onClick={() => !ipsLimitReached && handleSelect(server)}
        >
            <ServerIndicator state={state} progress={operationProgress} />
            <Icon icon={getServerIcon(distribution.type)} />
            <div
                className={cn(
                    theme.search.name,
                    theme.search.name_server,
                )}
            >
                {name}
            </div>
            {ipsLimitReached ? (
                <div className={theme.search.notification}>
                    {intl.getMessage('ips_limit_reached')}
                </div>
            ) : (
                <div className={theme.search.id}>
                    #{id}
                </div>
            )}
            {id === currentServerId && (
                <Icon icon="check" className={theme.search.check} />
            )}
        </div>
    );
};

const selectProgress = (store: Store, op: ServerOwnProps) => (
    store.operationProgress.commonOperations.get(op?.server.id)
);

const selector = createSelector([
    selectProgress,
], (progress) => ({
    operationProgress: progress,
}));

const mapStateToProps = (store: Store, ownProps: ServerOwnProps) => {
    return { ...selector(store, ownProps) };
};

export default connect(mapStateToProps)(ServerItem);
