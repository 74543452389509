import React, { FC, useEffect } from 'react';
import { Button, Divider } from 'antd';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { FormPageLayout, Icon, useIntl, notifyError, notifySuccess, mediumText } from 'Common';
import { TRIAL_MAX_SERVERS_COUNT, TRIAL_TOTAL_CAPACITY, CLOUDPAYMENTS_SRC } from 'Lib/helpers/consts';
import { getProfileInfo, getTrialPeriodActivationStatus, getConfiguration } from 'Actions/profile';
import { addError } from 'Actions/error';
import { InplaceType } from 'Actions/.';
import profileApi from 'Apis/profile';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { IProfileTrialPeriodActivateResponse } from 'Entities/ProfileTrialPeriodActivateResponse';
import { ProfileTrialPeriodActivationStatus } from 'Entities/ProfileTrialPeriodActivationStatus';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { errorChecker, getCookieToken } from 'Lib/helpers/utils';
import { useScript, useTitle } from 'Lib/hooks/hooks';
import { Store } from 'Store';
import theme from 'Lib/theme';

import s from './Trial.module.pcss';

const selectConfiguration = (store: Store) => store.profile.configuration;
const selectProfilePayer = (store: Store) => store.profile.info;
const selector = createSelector(
    [selectConfiguration, selectProfilePayer],
    (configuration, profile) => ({ configuration, profile }),
);

const Trial: FC = () => {
    const intl = useIntl();
    useTitle(intl.getMessage('trial_page_title'));
    const scriptState = useScript(CLOUDPAYMENTS_SRC);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getConfiguration());
    }, []);

    const { configuration, profile } = useSelector(selector);

    const trialBonusAmount = profile?.profileLimits.trialBonusAmount || 0;

    const successLink = window.location.origin
        + linkPathBuilder(intl.currentLocale, RoutePath.ServersList);
    const errorLink = window.location.origin
        + linkPathBuilder(intl.currentLocale, RoutePath.ServersList);

    const onBindCard = async () => {
        if (scriptState.loaded && !scriptState.error) {
            const resp = await profileApi.activateTrialPeriod({
                description: intl.getMessage('bound_cards_desc'),
                payment_success_url: successLink,
                payment_error_url: errorLink,
            }, getCookieToken());
            const { result, error } = errorChecker<IProfileTrialPeriodActivateResponse>(resp);
            if (result) {
                const onAddSuccess = () => {
                    dispatch(getTrialPeriodActivationStatus([], {
                        result: () => {
                            notifySuccess(intl.getMessage('trial_activated'), 'trial_activated');
                        },
                        error: () => notifyError(intl.getMessage('error_code_trial_period_disabled'), ApiErrorCode.TRIAL_PERIOD_DISABLED),
                    }));
                    history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
                };
                const onAddFailed = () => {
                    dispatch(getProfileInfo());
                    notifyError(intl.getMessage('error_code_trial_period_disabled'), ApiErrorCode.TRIAL_PERIOD_DISABLED);
                    history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
                };
                if (result.trial_status === ProfileTrialPeriodActivationStatus.PENDING) {
                    const widget = new cp.CloudPayments();
                    widget.pay(
                        result.cloud_payments_widget_pay_scheme,
                        result.cloud_payments_widget,
                        {
                            onSuccess: onAddSuccess,
                            onFail: onAddFailed,
                        },
                    );
                } else if (result.trial_status !== ProfileTrialPeriodActivationStatus.SUCCESS) {
                    onAddFailed();
                }
            }
            if (error) {
                dispatch(addError({ type: InplaceType.GetAddCreditCard, error }));
            }
        }
    };

    return (
        <FormPageLayout
            titleMod="center"
            title={intl.getMessage('trial')}
            back={RoutePath.OnboardingAddFunds}
        >
            <div className={s.desc}>
                {intl.getMessage('trial_desc')}
            </div>
            <div className={s.info}>
                <div className={s.item}>
                    {intl.getPlural('trial_list_opportunity', TRIAL_MAX_SERVERS_COUNT, { gb: TRIAL_TOTAL_CAPACITY, b: mediumText })}
                </div>
                <div className={s.item}>
                    {intl.getMessage('trial_list_bonus', { value: trialBonusAmount, b: mediumText })}
                </div>
            </div>
            <Divider className={cn(
                theme.divider.divider,
                theme.divider.divider_dashed,
                s.divider,
            )}
            />
            <div className={cn(s.desc, s.desc_top)}>
                {intl.getMessage('trial_list_label_card')}&nbsp;
                {configuration && (
                    intl.getPlural('trial_list_label_bound', configuration.bindCardAmount)
                )}
            </div>
            <div className={s.desc}>
                {intl.getMessage('trial_list_expiration')}
            </div>
            <Button
                onClick={onBindCard}
                className={cn(
                    theme.button.button,
                    theme.button.border,
                    theme.button.with_icon,
                    theme.button.direction,
                )}
                icon={<Icon icon="side" className={theme.color.blue} />}
            >
                {intl.getMessage('bind_card')}
            </Button>
        </FormPageLayout>
    );
};

export default Trial;
