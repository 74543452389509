import React, { FC } from 'react';
import customProtocolCheck from 'custom-protocol-check';

import { useIntl } from 'Common';
import theme from 'Lib/theme';
import { notifyError } from 'Common/ui';

const PROTOCOL_CHECK_TIMEOUT = 3000;

interface TerminalLink {
    host: string;
    disabled: boolean;
    handleClick: () => void;
}

const TerminalLink: FC<TerminalLink> = ({
    host, disabled, handleClick,
}) => {
    const intl = useIntl();

    const openTerminal = () => {
        customProtocolCheck(
            `ssh://root@${host}`,
            () => {
                notifyError(intl.getMessage('ssh_error'));
            },
            undefined,
            PROTOCOL_CHECK_TIMEOUT,
        );
        handleClick();
    };

    return (
        <button
            type="button"
            className={theme.popover.item}
            onClick={openTerminal}
            disabled={disabled}
        >
            {intl.getMessage('open_terminal')}
        </button>
    );
};

export default TerminalLink;
