import React, { FC } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { useIntl } from 'Common';
import Tariff from 'Entities/Tariff';
import theme from 'Lib/theme';

import s from './Notice.module.pcss';

interface NoticeProps {
    currentTariff: Tariff;
}

const Notice: FC<NoticeProps> = ({ currentTariff }) => {
    const intl = useIntl();

    return (
        <Tooltip
            placement="leftTop"
            overlayClassName="tooltip tooltip_result"
            title={(
                <div className={s.notice}>
                    {currentTariff.fixed ? (
                        intl.getMessage('new_server_notice_fixed')
                    ) : (
                        intl.getMessage('new_server_notice')
                    )}
                </div>
            )}
        >
            <div
                className={cn(
                    theme.Badge.badge,
                    theme.Badge.question,
                    s.badge,
                )}
            />
        </Tooltip>
    );
};

export default Notice;
