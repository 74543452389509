import CountryPhoneCode, { ICountryPhoneCode } from './CountryPhoneCode';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICountriesPhoneCodesResponse {
    codes?: ICountryPhoneCode[];
    default_code?: ICountryPhoneCode;
}

export default class CountriesPhoneCodesResponse {
    readonly _codes: CountryPhoneCode[] | undefined;

    /** */
    get codes(): CountryPhoneCode[] | undefined {
        return this._codes;
    }

    readonly _default_code: CountryPhoneCode | undefined;

    get defaultCode(): CountryPhoneCode | undefined {
        return this._default_code;
    }

    constructor(props: ICountriesPhoneCodesResponse) {
        if (props.codes) {
            this._codes = props.codes.map((p) => new CountryPhoneCode(p));
        }
        if (props.default_code) {
            this._default_code = new CountryPhoneCode(props.default_code);
        }
    }

    serialize(): ICountriesPhoneCodesResponse {
        const data: ICountriesPhoneCodesResponse = {
        };
        if (typeof this._codes !== 'undefined') {
            data.codes = this._codes.map((p) => p.serialize());
        }
        if (typeof this._default_code !== 'undefined') {
            data.default_code = this._default_code.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            default_code: !this._default_code ? true : this._default_code.validate().length === 0,
            codes: !this._codes ? true : this._codes.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICountriesPhoneCodesResponse): CountriesPhoneCodesResponse {
        return new CountriesPhoneCodesResponse(props);
    }

    readonly keys: { [key: string]: string } = {
        codes: 'codes',
        defaultCode: 'default_code',
        }
;

    mergeDeepWith(props: Partial<CountriesPhoneCodesResponse>): CountriesPhoneCodesResponse {
        const updateData: Partial<ICountriesPhoneCodesResponse> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CountriesPhoneCodesResponse) => {
            const updateKey = this.keys[key] as keyof ICountriesPhoneCodesResponse;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICountriesPhoneCodesResponse, keyof ICountriesPhoneCodesResponse>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CountriesPhoneCodesResponse({ ...this.serialize(), ...updateData });
    }
}
