// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDatePeriod {
    date_from: string;
    date_from_millis: number;
    date_to: string;
    date_to_millis: number;
    period_days: number;
}

export default class DatePeriod {
    readonly _date_from: string;

    /** */
    get dateFrom(): string {
        return this._date_from;
    }

    static dateFromValidate(dateFrom: string): boolean {
        return typeof dateFrom === 'string' && !!dateFrom.trim();
    }

    readonly _date_from_millis: number;

    /** */
    get dateFromMillis(): number {
        return this._date_from_millis;
    }

    static dateFromMillisValidate(dateFromMillis: number): boolean {
        return typeof dateFromMillis === 'number';
    }

    readonly _date_to: string;

    /** */
    get dateTo(): string {
        return this._date_to;
    }

    static dateToValidate(dateTo: string): boolean {
        return typeof dateTo === 'string' && !!dateTo.trim();
    }

    readonly _date_to_millis: number;

    /** */
    get dateToMillis(): number {
        return this._date_to_millis;
    }

    static dateToMillisValidate(dateToMillis: number): boolean {
        return typeof dateToMillis === 'number';
    }

    readonly _period_days: number;

    /**
     * Description: Period length in days
     * Example: 30
     */
    get periodDays(): number {
        return this._period_days;
    }

    static periodDaysValidate(periodDays: number): boolean {
        return typeof periodDays === 'number';
    }

    constructor(props: IDatePeriod) {
        this._date_from = props.date_from.trim();
        this._date_from_millis = props.date_from_millis;
        this._date_to = props.date_to.trim();
        this._date_to_millis = props.date_to_millis;
        this._period_days = props.period_days;
    }

    serialize(): IDatePeriod {
        const data: IDatePeriod = {
            date_from: this._date_from,
            date_from_millis: this._date_from_millis,
            date_to: this._date_to,
            date_to_millis: this._date_to_millis,
            period_days: this._period_days,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            period_days: typeof this._period_days === 'number',
            date_from: typeof this._date_from === 'string' && !!this._date_from.trim(),
            date_from_millis: typeof this._date_from_millis === 'number',
            date_to: typeof this._date_to === 'string' && !!this._date_to.trim(),
            date_to_millis: typeof this._date_to_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDatePeriod): DatePeriod {
        return new DatePeriod(props);
    }

    readonly keys: { [key: string]: string } = {
        dateFrom: 'date_from',
        dateFromMillis: 'date_from_millis',
        dateTo: 'date_to',
        dateToMillis: 'date_to_millis',
        periodDays: 'period_days',
        }
;

    mergeDeepWith(props: Partial<DatePeriod>): DatePeriod {
        const updateData: Partial<IDatePeriod> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DatePeriod) => {
            const updateKey = this.keys[key] as keyof IDatePeriod;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDatePeriod, keyof IDatePeriod>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DatePeriod({ ...this.serialize(), ...updateData });
    }
}
