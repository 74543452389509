import React, { ReactElement } from 'react';
import { Dropdown } from 'antd';
import cn from 'classnames';

import theme from 'Lib/theme';

import { CustomOption } from '.';
import s from './CustomSelect.module.pcss';

export interface IOption<T> {
    label: ReactElement;
    labelText: string;
    value: T;
    disabled: boolean;
    onSelect: (v: T) => void;
    withAction?: boolean;
}

interface SpecificOption {
    label: ReactElement;
    labelText: string;
}

interface CustomSelectProps<T> {
    trigger: 'click' | 'hover' | 'contextMenu';
    placement?: 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight' | undefined;
    triggerNode?: HTMLDivElement | null;
    renderInParentContainer?: boolean;
    width?: number;
    isOpened: boolean;
    value: T;
    options: IOption<T>[];
    specificOption?: SpecificOption;
}

const CustomSelect = <T,>({
    trigger,
    placement,
    triggerNode,
    renderInParentContainer,
    width,
    isOpened,
    value,
    options,
    specificOption,
}: React.PropsWithChildren<CustomSelectProps<T>>) => {
    const content = (
        <>
            <div className={cn(s.list, theme.common.customScrollbar)}>
                {options.map(({ value: v, label, disabled, withAction, onSelect }) => (
                    <div key={String(v)}>
                        <CustomOption<T>
                            value={v}
                            onSelect={onSelect}
                            currentValue={value}
                            label={label}
                            disabled={disabled}
                            withAction={withAction}
                        />
                    </div>
                ))}
            </div>
            {specificOption && (
                specificOption.label
            )}
        </>
    );

    const currentOption = options.find((o) => o.value === value);

    return (
        <>
            <Dropdown
                overlay={content}
                trigger={[trigger]}
                placement={placement}
                overlayStyle={width ? { width: `${width}px`, minWidth: `${width}px` } : { width: 'auto' }}
                overlayClassName="dropdownSelect"
                visible={isOpened}
                getPopupContainer={
                    renderInParentContainer
                        ? () => triggerNode!
                        : () => document.body
                }
            >
                <div className={s.label}>
                    <>
                        {currentOption?.labelText}
                        {options.length === 0 && specificOption && specificOption.labelText}
                    </>
                </div>
            </Dropdown>
        </>
    );
};

export default CustomSelect;
