import SupportApi from 'Apis/support';
import Ticket from 'Entities/Ticket';
import TicketsInfo from 'Entities/TicketsInfo';
import { actionCreator } from '.';

export enum SupportActions {
    GetTicket = 'GetTicket',
    SaveTicket = 'SaveTicket',
    SaveCurrentTicket = 'SaveCurrentTicket',
    CreateTicketPost = 'CreateTicketPost',
    ListTickets = 'ListTickets',
    SaveTickets = 'SaveTickets',
    CreateTicket = 'CreateTicket',
    ClearTicket = 'ClearTicket',
    ClearTickets = 'ClearTickets',
    GetTicketsInfo = 'GetTicketsInfo',
    SaveTicketsInfo = 'SaveTicketsInfo',
    MarkTicketsRead = 'MarkTicketsRead',
    UpdateTickets = 'UpdateTickets',
}

interface GetTicket {
    req: Parameters<typeof SupportApi.getTicket>;
    update?: boolean;
}
export const getTicket = actionCreator<GetTicket>(
    SupportActions.GetTicket,
);

export const saveTicket = actionCreator<Ticket>(
    SupportActions.SaveTicket,
);

export const saveCurrentTicket = actionCreator<Ticket>(
    SupportActions.SaveCurrentTicket,
);

type CreateTicketPost = Parameters<typeof SupportApi.createTicketPost>;
export const createTicketPost = actionCreator<CreateTicketPost>(
    SupportActions.CreateTicketPost,
);

type ListTickets = Parameters<typeof SupportApi.listTickets>;
export const listTickets = actionCreator<ListTickets>(
    SupportActions.ListTickets,
);

export const saveTickets = actionCreator<Ticket[]>(
    SupportActions.SaveTickets,
);

type CreateTicket = Parameters<typeof SupportApi.createTicket>;
export const createTicket = actionCreator<CreateTicket>(
    SupportActions.CreateTicket,
);

type GetTicketsInfo = Parameters<typeof SupportApi.getTicketsInfo>;
export const getTicketsInfo = actionCreator<GetTicketsInfo>(
    SupportActions.GetTicketsInfo,
);

type SaveTicketsInfo = TicketsInfo;
export const saveTicketsInfo = actionCreator<SaveTicketsInfo>(
    SupportActions.SaveTicketsInfo,
);

type MarkTicketsRead = Parameters<typeof SupportApi.markTicketsRead>;
export const markTicketsRead = actionCreator<MarkTicketsRead>(
    SupportActions.MarkTicketsRead,
);

export const clearTicket = actionCreator(SupportActions.ClearTicket);
export const clearTickets = actionCreator(SupportActions.ClearTickets);
export const updateTickets = actionCreator(SupportActions.UpdateTickets);
