import React, { FC, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';

import { useIntl } from 'Common';
import MiscApiApi from 'Apis/miscApi';
import { REVIEWS_KEYS } from 'Lib/helpers/consts';
import { errorChecker } from 'Lib/helpers/utils';
import ReferralInfo, { IReferralInfo } from 'Entities/ReferralInfo';
import AccountDetails from 'Entities/AccountDetails';
import Profile from 'Entities/Profile';

import Label from './Label';

interface DiscountProps {
    accountDetails: AccountDetails;
    profile: Profile;
}

const Discount: FC<DiscountProps> = ({ accountDetails, profile }) => {
    const intl = useIntl();
    const { discount } = accountDetails;
    const { discounts, refId, trialBonusActive, sentReviews } = profile;
    const showTrialNotice = refId
        && trialBonusActive
        && !sentReviews.includes(REVIEWS_KEYS.REFERRER_REG_DISCOUNT);

    const [refInfo, setRefInfo] = useState<ReferralInfo | null>(null);

    const getReferralInfo = async (id: number) => {
        const response = await MiscApiApi.getReferralInfo(id);
        const { result } = errorChecker<IReferralInfo>(response);
        if (result) {
            setRefInfo(new ReferralInfo(result));
        }
    };

    useEffect(() => {
        if (showTrialNotice && refId) {
            getReferralInfo(refId);
        }
    }, []);

    // Discount from the AccountDetails takes priority over the Profile discounts
    if (discount > 0) {
        return (
            <Label
                title={intl.getMessage('discount_score')}
                discount={discount}
            />
        );
    }

    // Show notification if discount is available for this refId
    if (showTrialNotice && refInfo?.referrerRegDiscountEnabled) {
        return (
            <Tooltip
                placement="bottom"
                overlayClassName="tooltip tooltip_notice"
                title={intl.getMessage('discount_trial_notice')}
            >
                <div>
                    <Label
                        title={intl.getMessage('discount_trial')}
                        discount={refInfo.referrerRegDiscount}
                        trial
                    />
                </div>
            </Tooltip>
        );
    }

    const referrerDiscount = discounts.find((d) => d.balance === REVIEWS_KEYS.REFERRER_REG_DISCOUNT);

    if (!referrerDiscount || showTrialNotice) {
        return null;
    }

    const { discountPercent, expirationDateMillis } = referrerDiscount;
    const title = expirationDateMillis ? (
        intl.getMessage('discount_date', {
            value: dayjs(expirationDateMillis).format(intl.getMessage('time_format_date_short')),
        })
    ) : (
        intl.getMessage('discount_score')
    );

    return (
        <Label
            title={title}
            discount={discountPercent}
        />
    );
};

export default Discount;
