import { Action } from 'Actions/.';
import {
    AccountActions,
    saveAccount,
    saveAccountDetails,
    saveCreditCards,
    deleteCreditCardLocal,
    saveDocuments,
    saveAccountDashboard,
    saveAccountExpensesDetailed,
    saveAccountExpensesJSON,
    saveAccountableServers,
} from 'Actions/account';
import Account from 'Entities/Account';
import AccountDetails from 'Entities/AccountDetails';
import AccountCreditCard from 'Entities/AccountCreditCard';
import Server from 'Entities/Server';

import { AccountStore, accountInitialState as initialState } from '../initialStates';

const reducer = (state: AccountStore = initialState, action: Action): AccountStore => {
    switch (action.type as AccountActions) {
        case AccountActions.SaveAccount: {
            const { payload } = action as ReturnType<typeof saveAccount>;
            const account: Account = payload;
            return { ...state, account };
        }

        case AccountActions.SaveAccountDetails: {
            const { payload } = action as ReturnType<typeof saveAccountDetails>;
            const details: AccountDetails = payload;
            return { ...state, details };
        }

        case AccountActions.SaveCreditCards: {
            const { payload } = action as ReturnType<typeof saveCreditCards>;
            const cards = new Map<number, AccountCreditCard>();
            payload.forEach((s) => {
                cards.set(s.creditCardId, s);
            });
            return { ...state, cards };
        }

        case AccountActions.DeleteCreditCardLocal: {
            const { payload } = action as ReturnType<typeof deleteCreditCardLocal>;
            const cards = new Map<number, AccountCreditCard>(state.cards!);
            cards.delete(payload.cardId);
            return { ...state, cards };
        }

        case AccountActions.SaveDocuments: {
            const { payload } = action as ReturnType<typeof saveDocuments>;
            const documents: any[] = [];
            payload.forEach((s) => {
                documents.push(s);
            });
            return { ...state, documents };
        }

        case AccountActions.SaveAccountDashboard: {
            const { payload } = action as ReturnType<typeof saveAccountDashboard>;
            return { ...state, dashboard: payload };
        }
        case AccountActions.ClearDashboard: {
            return { ...state, dashboard: null };
        }
        case AccountActions.SaveAccountExpensesDetailed: {
            const { payload } = action as ReturnType<typeof saveAccountExpensesDetailed>;
            return { ...state, detailedExpenses: payload };
        }
        case AccountActions.SaveAccountExpensesJSON: {
            const { payload } = action as ReturnType<typeof saveAccountExpensesJSON>;
            return { ...state, accountExpensesJSON: payload };
        }
        case AccountActions.SaveAccountableServers: {
            const { payload } = action as ReturnType<typeof saveAccountableServers>;
            const servers = new Map<number, Server>();
            payload.forEach((s) => {
                servers.set(s.id, s);
            });
            return { ...state, servers };
        }
        default:
            return state;
    }
};

export default reducer;
