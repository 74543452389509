import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, IconType } from 'Common/ui';
import AccountCreditCard from 'Entities/AccountCreditCard';
import { CreditCardType } from 'Entities/CreditCardType';
import theme from 'Lib/theme';

import s from './CreditCard.module.pcss';

interface CardPlaceholderProps {
    card?: AccountCreditCard;
    noNum?: boolean;
    select?: boolean;
}

const CardPlaceholder: FC<CardPlaceholderProps> = ({ card, noNum, select }) => {
    const { lastFourDigits, creditCardType } = card || {};

    const genericCardIcon = 'card';
    let icon: string | null = null;
    switch (creditCardType) {
        case CreditCardType.MASTERCARD:
        case CreditCardType.VISA: {
            icon = creditCardType.toLowerCase();
            break;
        }
        default:
            icon = genericCardIcon;
    }

    return (
        <div className={s.placeholder}>
            <Icon
                className={cn(
                    s.system,
                    card ? theme.color.blue : theme.color.concrete,
                    { [s.system_select]: select },
                )}
                icon={icon as IconType}
            />
            <div className={s.digits}>
                <div className={s.group}>
                    <div className={s.dot} />
                    <div className={s.dot} />
                    <div className={s.dot} />
                    <div className={s.dot} />
                </div>
                <div className={s.group}>
                    <div className={s.dot} />
                    <div className={s.dot} />
                    <div className={s.dot} />
                    <div className={s.dot} />
                </div>
                <div className={s.group}>
                    <div className={s.dot} />
                    <div className={s.dot} />
                    <div className={s.dot} />
                    <div className={s.dot} />
                </div>
                {!noNum ? (
                    <div className={s.group}>
                        {lastFourDigits}
                    </div>
                ) : (
                    <div className={s.group}>
                        <div className={s.dot} />
                        <div className={s.dot} />
                        <div className={s.dot} />
                        <div className={s.dot} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CardPlaceholder;
