// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsPtrRecord {
    host?: string;
    ip_address: string;
    server_id: number;
}

export default class DnsPtrRecord {
    readonly _host: string | undefined;

    /**
     * Description: Host
     * Example: beta.autoleader1.ru
     */
    get host(): string | undefined {
        return this._host;
    }

    readonly _ip_address: string;

    /**
     * Description: Ip address
     * Example: 91.239.26.27
     */
    get ipAddress(): string {
        return this._ip_address;
    }

    static ipAddressValidate(ipAddress: string): boolean {
        return typeof ipAddress === 'string' && !!ipAddress.trim();
    }

    readonly _server_id: number;

    /**
     * Description: Server id
     * Example: 51671
     */
    get serverId(): number {
        return this._server_id;
    }

    static serverIdValidate(serverId: number): boolean {
        return typeof serverId === 'number';
    }

    constructor(props: IDnsPtrRecord) {
        if (typeof props.host === 'string') {
            this._host = props.host.trim();
        }
        this._ip_address = props.ip_address.trim();
        this._server_id = props.server_id;
    }

    serialize(): IDnsPtrRecord {
        const data: IDnsPtrRecord = {
            ip_address: this._ip_address,
            server_id: this._server_id,
        };
        if (typeof this._host !== 'undefined') {
            data.host = this._host;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            ip_address: typeof this._ip_address === 'string' && !!this._ip_address.trim(),
            host: !this._host ? true : typeof this._host === 'string',
            server_id: typeof this._server_id === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsPtrRecord): DnsPtrRecord {
        return new DnsPtrRecord(props);
    }

    readonly keys: { [key: string]: string } = {
        host: 'host',
        ipAddress: 'ip_address',
        serverId: 'server_id',
        }
;

    mergeDeepWith(props: Partial<DnsPtrRecord>): DnsPtrRecord {
        const updateData: Partial<IDnsPtrRecord> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsPtrRecord) => {
            const updateKey = this.keys[key] as keyof IDnsPtrRecord;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsPtrRecord, keyof IDnsPtrRecord>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsPtrRecord({ ...this.serialize(), ...updateData });
    }
}
