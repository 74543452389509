import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import OperationInfo, { IOperationInfo } from 'Entities/OperationInfo';
import Snapshot, { ISnapshot } from 'Entities/Snapshot';
import SnapshotCreate, { ISnapshotCreate } from 'Entities/SnapshotCreate';
import SnapshotDelete, { ISnapshotDelete } from 'Entities/SnapshotDelete';
import SnapshotDownload, { ISnapshotDownload } from 'Entities/SnapshotDownload';
import SnapshotDownloadLink, { ISnapshotDownloadLink } from 'Entities/SnapshotDownloadLink';
import SnapshotRollback, { ISnapshotRollback } from 'Entities/SnapshotRollback';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class SnapshotsApi {
    static async createSnapshot(tenant_id: number, server_id: number, snapshotcreate: ISnapshotCreate, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const snapshotcreateValid = new SnapshotCreate(snapshotcreate);
        haveError.push(...snapshotcreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/snapshots`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(snapshotcreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteSnapshot(tenant_id: number, server_id: number, snapshot_id: number, snapshotdelete: ISnapshotDelete, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const snapshotdeleteValid = new SnapshotDelete(snapshotdelete);
        haveError.push(...snapshotdeleteValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/snapshots/${snapshot_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(snapshotdeleteValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async downloadSnapshot(tenant_id: number, server_id: number, snapshot_id: number, snapshotdownload: ISnapshotDownload, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const snapshotdownloadValid = new SnapshotDownload(snapshotdownload);
        haveError.push(...snapshotdownloadValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/snapshots/${snapshot_id}/download`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(snapshotdownloadValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getSnapshotDownloadLink(tenant_id: number, server_id: number, snapshot_id: number, optionalToken?: string): Promise<ISnapshotDownloadLink | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/snapshots/${snapshot_id}/download_link`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listSnapshots(tenant_id: number, server_id: number, optionalToken?: string): Promise<ISnapshot[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/snapshots`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async rollbackSnapshot(tenant_id: number, server_id: number, snapshot_id: number, snapshotrollback: ISnapshotRollback, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const snapshotrollbackValid = new SnapshotRollback(snapshotrollback);
        haveError.push(...snapshotrollbackValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/snapshots/${snapshot_id}/rollback`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(snapshotrollbackValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
