import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import PartnerComparativeDetails, { IPartnerComparativeDetails } from 'Entities/PartnerComparativeDetails';
import PartnerDailyIncomeStats, { IPartnerDailyIncomeStats } from 'Entities/PartnerDailyIncomeStats';
import PartnerDetails, { IPartnerDetails } from 'Entities/PartnerDetails';
import PartnerFinances, { IPartnerFinances } from 'Entities/PartnerFinances';
import PartnerPayment, { IPartnerPayment } from 'Entities/PartnerPayment';
import PartnerPaymentCreate, { IPartnerPaymentCreate } from 'Entities/PartnerPaymentCreate';
import PartnerRegistration, { IPartnerRegistration } from 'Entities/PartnerRegistration';
import PartnerStats, { IPartnerStats } from 'Entities/PartnerStats';
import PartnerTransferCreate, { IPartnerTransferCreate } from 'Entities/PartnerTransferCreate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class PartnersApi {
    static async doPartnerTransferPayment(partnertransfercreate: IPartnerTransferCreate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const partnertransfercreateValid = new PartnerTransferCreate(partnertransfercreate);
        haveError.push(...partnertransfercreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/partner/balance_transfer`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(partnertransfercreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async doPartnerWithdrawalPayment(partnerpaymentcreate: IPartnerPaymentCreate, optionalToken?: string): Promise<IPartnerPayment | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const partnerpaymentcreateValid = new PartnerPaymentCreate(partnerpaymentcreate);
        haveError.push(...partnerpaymentcreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/partner/payments`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(partnerpaymentcreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPartnerComparativeDetails(date_from?: string, date_to?: string, optionalToken?: string): Promise<IPartnerComparativeDetails | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            date_from: date_from,
            date_to: date_to,
        }
        return await fetch(`/api/v1/partner/comparative_details?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPartnerCompletePayments(offset?: number, limit?: number, optionalToken?: string): Promise<IPartnerFinances | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            offset: offset,
            limit: limit,
        }
        return await fetch(`/api/v1/partner/payments/complete?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPartnerDetails(optionalToken?: string): Promise<IPartnerDetails | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/partner/details`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPartnerIncomeStats(date_from_millis: number, date_to_millis: number, optionalToken?: string): Promise<IPartnerDailyIncomeStats[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            date_from_millis: date_from_millis,
            date_to_millis: date_to_millis,
        }
        return await fetch(`/api/v1/partner/income_stats?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPartnerProcessingPayments(optionalToken?: string): Promise<IPartnerPayment[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/partner/payments/processing`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPartnerStats(date_from: string, date_to: string, optionalToken?: string): Promise<IPartnerStats[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            date_from: date_from,
            date_to: date_to,
        }
        return await fetch(`/api/v1/partner/stats?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async registerPartner(partnerregistration: IPartnerRegistration, optionalToken?: string): Promise<IPartnerDetails | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const partnerregistrationValid = new PartnerRegistration(partnerregistration);
        haveError.push(...partnerregistrationValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/partner/register`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(partnerregistrationValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
