import { put } from 'redux-saga/effects';
import { captureEvent } from '@sentry/browser';

import { ActionType } from 'Actions/.';
import { addError as addErrorAct } from 'Actions/error';
import { logOut } from 'Actions/user';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import OperationProgress from 'Entities/OperationProgress';
import Error from 'Entities/Error';
import { UNAUTHORIZED } from 'Lib/helpers/consts';

export { errorChecker } from 'Lib/helpers/utils';

const NO_SENTRY_ERRORS: Set<ApiErrorCode> = new Set([
    ApiErrorCode.FIELD_WRONG_VALUE,
    ApiErrorCode.BAD_REQUEST,
    ApiErrorCode.OPERATION_ALREADY_STARTED,
    ApiErrorCode.OBJECT_NOT_FOUND,
    ApiErrorCode.VM_SHUTDOWN_NOT_COMPLETE,
    ApiErrorCode.VM_REBOOT_NOT_COMPLETE,
    ApiErrorCode.INET_IP_GUEST_ADD,
    ApiErrorCode.INET_IP_GUEST_REMOVE,
    ApiErrorCode.NOT_ENOUGH_BALANCE,
    ApiErrorCode.INET_IP_LIMIT_EXCEEDED,
    ApiErrorCode.NET_NO_INET_IP,
    ApiErrorCode.RATE_LIMIT_EXCEED,
    ApiErrorCode.VM_SHUTOFF_CLIENT_DEBT,
    ApiErrorCode.RECONFIGURATION_DENIED,
]);
function* addError(
    error: Error,
    type: ActionType,
    options?: {
        noSentryErrors?: ApiErrorCode[];
        req?: string;
        operation?: OperationProgress;
    },
) {
    if (error.errorCode === UNAUTHORIZED) {
        yield put(logOut());
        return;
    }
    const noSentry = options?.noSentryErrors?.includes(error.errorCode)
        || NO_SENTRY_ERRORS.has(error.errorCode);

    if (!DEV && !noSentry) {
        captureEvent({
            message: type,
            exception: {
                values: [{
                    type: `${type}: ${error.errorCode}`,
                    value: `\nX-Request-Id = ${error.message}, \n\nError fields: ${JSON.stringify(error.fields)} \n\nReq: ${options?.req ? options.req : 'Not provided'}`,
                }],
            },
            fingerprint: [type, error.errorCode],
            timestamp: Math.floor(Date.now() / 1000),
            level: 'error',
        });
    }
    yield put(addErrorAct({ error, type, operation: options?.operation }));
}

function* checkUnhandledError(error: Error, type: ActionType) {
    if (error.errorCode === ApiErrorCode.UNKNOWN && !error.fields) {
        yield addError(error, type);
    }
}
export { addError, checkUnhandledError };
