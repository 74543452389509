import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import ServerResourceLimits, { IServerResourceLimits } from 'Entities/ServerResourceLimits';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class ResourceLimitsApi {
    static async getResourceLimitsForDraft(tenant_id: number, distrib_id: number): Promise<IServerResourceLimits | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            distrib_id: distrib_id,
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/resource_limits?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
