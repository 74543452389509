// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICouponActivateRequest {
    coupon: string;
}

export default class CouponActivateRequest {
    readonly _coupon: string;

    /**
     * Description: Coupon number
     * Example: COUPON_250
     */
    get coupon(): string {
        return this._coupon;
    }

    static couponValidate(coupon: string): boolean {
        return typeof coupon === 'string' && !!coupon.trim();
    }

    constructor(props: ICouponActivateRequest) {
        this._coupon = props.coupon.trim();
    }

    serialize(): ICouponActivateRequest {
        const data: ICouponActivateRequest = {
            coupon: this._coupon,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            coupon: typeof this._coupon === 'string' && !!this._coupon.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICouponActivateRequest): CouponActivateRequest {
        return new CouponActivateRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        coupon: 'coupon',
        }
;

    mergeDeepWith(props: Partial<CouponActivateRequest>): CouponActivateRequest {
        const updateData: Partial<ICouponActivateRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CouponActivateRequest) => {
            const updateKey = this.keys[key] as keyof ICouponActivateRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICouponActivateRequest, keyof ICouponActivateRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CouponActivateRequest({ ...this.serialize(), ...updateData });
    }
}
