import React, { FC } from 'react';

import { Icon } from 'Common';
import CountryPhoneCode from 'Entities/CountryPhoneCode';

import s from './PhoneSearch.module.pcss';

interface CountryProps {
    country: CountryPhoneCode;
    currentCountry: CountryPhoneCode;
    selectCountry: (country: CountryPhoneCode) => void;
}

const Country: FC<CountryProps> = ({ country, currentCountry, selectCountry }) => {
    const { countryCode, countryName, callingCode } = country;

    return (
        <div
            className={s.country}
            onClick={() => selectCountry(country)}
            id={countryCode}
        >
            <div className={s.name}>
                {countryName}
            </div>
            <div className={s.code}>
                +{callingCode}
            </div>
            {currentCountry.countryCode === countryCode && (
                <Icon
                    icon="check"
                    color={s.check}
                />
            )}
        </div>
    );
};

export default Country;
