import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, useIntl, Link } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

const Header: FC = () => {
    const intl = useIntl();

    return (
        <div className={cn(theme.mainHeader.header, theme.mainHeader.header_simple)}>
            <Link to={RoutePath.Root} className={theme.mainHeader.link}>
                <Icon icon="main_logo" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_desktop)} />
                <Icon icon="main_logo_dark" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_desktop, theme.mainHeader.logo_dark)} />
                <Icon icon="panel_logo" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_mobile)} />
            </Link>
            <div className={theme.mainHeader.subtitle}>
                {intl.getMessage('header_subtitle')}
            </div>
        </div>
    );
};

export default Header;
