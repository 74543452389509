import React, { FC, useEffect, useState } from 'react';
import { Popover, Progress } from 'antd';
import dayjs from 'dayjs';
import cn from 'classnames';

import { Button, useIntl } from 'Common';
import Server from 'Entities/Server';
import ServerBackup from 'Entities/ServerBackup';
import OperationProgress from 'Entities/OperationProgress';
import { OperationType } from 'Entities/OperationType';
import { stepTranslate } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';

import s from './Backup.module.pcss';
import ModalBackup from '../ModalBackup';
import ModalDownload from '../ModalDownload';

interface BackupProps {
    server: Server;
    backup: ServerBackup;
    progress: Map<string, OperationProgress>;
    serverBackupProgress?: OperationProgress;
}

const Backup: FC<BackupProps> = ({
    backup,
    server,
    progress,
    serverBackupProgress,
}) => {
    const intl = useIntl();
    const [openModalBackup, setOpenModalBackup] = useState(false);
    const [openModalDownload, setOpenModalDownload] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState<OperationProgress | null>(null);

    const {
        id: serverId,
        tenantId,
    } = server;

    const {
        id,
        downloadCostForecast,
        timeAddedMillis,
        sizeGib,
    } = backup;

    useEffect(() => {
        const backupProgress = progress.get(id);
        if (backupProgress?.type === OperationType.DOWNLOAD_BACKUP) {
            setDownloadProgress(backupProgress);
        }
    }, [progress]);

    const disabledDownload = serverBackupProgress !== undefined;

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.with_actions,
            )}
        >
            <div className={cn(theme.card.grid, theme.card.grid_serverBackup)}>
                <div className={theme.common.textOverflow}>
                    {dayjs(timeAddedMillis).format(intl.getMessage('time_format_short_month_year'))}
                </div>
                <div className={s.actions}>
                    <div
                        className={cn(
                            s.size,
                            theme.common.textOverflow,
                            theme.color.grayHeaders,
                        )}
                    >
                        {intl.getMessage('value_gb', { value: sizeGib })}
                    </div>

                    {downloadProgress && !downloadProgress.result ? (
                        <div className={s.progress}>
                            <div className={s.progress_title}>
                                {stepTranslate(intl, downloadProgress.stepName)}
                            </div>
                            <Progress
                                size="small"
                                percent={(downloadProgress.stepOrder / (downloadProgress.totalSteps + 1)) * 100}
                                status="active"
                                showInfo={false}
                                className={s.progress_line}
                            />
                        </div>
                    ) : (
                        <div className={theme.card.actions}>
                            <Button
                                type="icon"
                                icon="restore"
                                className={cn(
                                    theme.card.action,
                                    theme.card.action_hover,
                                )}
                                onClick={() => setOpenModalBackup(true)}
                                title={disabledDownload ? intl.getMessage('server_operation_prepare_backup') : intl.getMessage('server_backup_restore')}
                                disabled={disabledDownload}
                                showDisabledTitle
                            />
                            <Button
                                type="icon"
                                icon="download"
                                className={cn(
                                    theme.card.action,
                                    theme.card.action_hover,
                                )}
                                onClick={() => setOpenModalDownload(true)}
                                title={disabledDownload ? intl.getMessage('server_operation_prepare_backup') : intl.getMessage('server_backup_download')}
                                disabled={disabledDownload}
                                showDisabledTitle
                            />
                        </div>
                    )}
                </div>

                <Popover
                    trigger="click"
                    content={(
                        <div className={theme.popover.popover}>
                            <button
                                type="button"
                                className={theme.popover.item}
                                onClick={() => setOpenModalBackup(true)}
                                disabled={disabledDownload}
                            >
                                {intl.getMessage('restore')}
                            </button>
                            <button
                                type="button"
                                className={theme.popover.item}
                                onClick={() => setOpenModalDownload(true)}
                            >
                                {intl.getMessage('download')}
                            </button>
                        </div>
                    )}
                    placement="left"
                    overlayClassName="popover"
                >
                    <Button
                        type="icon"
                        icon="options"
                        title={intl.getMessage('title_control_backup')}
                        className={cn(theme.card.action, theme.card.action_popover)}
                    />
                </Popover>
            </div>

            {openModalBackup && (
                <ModalBackup
                    visible
                    close={() => setOpenModalBackup(false)}
                    diskGib={server.diskGib}
                    tenantId={tenantId}
                    serverId={serverId}
                    backupId={id}
                />
            )}
            {openModalDownload && (
                <ModalDownload
                    visible
                    close={() => setOpenModalDownload(false)}
                    progress={downloadProgress}
                    tenantId={tenantId}
                    serverId={serverId}
                    backupId={id}
                    cost={downloadCostForecast}
                    sizeGib={sizeGib}
                />
            )}
        </div>
    );
};

export default Backup;
