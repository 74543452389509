import React, { FC, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import qs from 'qs';

import socialLoginApi from 'Apis/socialLogin';
import { addSocialAuthAccount, checkAuth } from 'Actions/user';
import { useIntl, Loader, notifyError } from 'Common';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { errorChecker, getCookieToken, saveCookieToken } from 'Lib/helpers/utils';
import { SOCIAL_PARAM_NAME, SOCIAL_ERROR_NAME } from 'Lib/helpers/consts';
import { setAuthToken } from 'Lib/helpers/authHelper';

// &purpose=general|multi_acc, multi_acc for the add account page
const SOCIAL_PURPOSE_NAME = 'purpose';
const SOCIAL_PURPOSE_VALUE = {
    GENERAL: 'general',
    MULTI_ACCOUNT: 'multi_acc',
};

const SocialAuthConfirm: FC = () => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const { hash } = useLocation();

    const { currentLocale: lang } = intl;
    useEffect(() => {
        if (hash.includes(SOCIAL_ERROR_NAME)) {
            notifyError(intl.getMessage('social_auth_error'));
            history.push(linkPathBuilder(lang, RoutePath.Root));
            return;
        }
        const hashParams = qs.parse(hash.replace('#', ''));
        const code = hashParams[SOCIAL_PARAM_NAME];
        if (!code) {
            history.push(linkPathBuilder(lang, RoutePath.Root));
        } else {
            const loader = async () => {
                const response = await socialLoginApi.exchangeForToken(
                    String(code), getCookieToken(),
                );
                const { result, error } = errorChecker<{ token: string; cookie: string }>(response);
                if (result) {
                    if (hashParams[SOCIAL_PURPOSE_NAME] === SOCIAL_PURPOSE_VALUE.MULTI_ACCOUNT) {
                        dispatch(addSocialAuthAccount({ token: result.token }));
                        history.push(linkPathBuilder(lang, RoutePath.Dashboard));
                    } else {
                        AppConfigStorage.setEmailConfirmed(true);
                        setAuthToken(result.token);
                        if (result.cookie) {
                            saveCookieToken(result.cookie);
                        }
                        dispatch(checkAuth());
                        history.push(linkPathBuilder(lang, RoutePath.ServersList));
                    }
                } else {
                    history.push(linkPathBuilder(lang, RoutePath.Root));
                    notifyError(intl.getMessage('social_auth_failed'), error?.errorCode);
                }
            };
            loader();
        }
    }, []);

    return (
        <Loader full />
    );
};

export default SocialAuthConfirm;
