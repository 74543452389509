import React, { FC } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';

import { useIntl, Icon, Button, Modal } from 'Common';
import theme from 'Lib/theme';

interface FormModalLayoutProps<T = any> {
    submitButtonId?: string;
    buttonText: string;
    children: FC<any>;
    className?: string;
    handleClose: () => void;
    handleSubmit: (values: T, helpers: FormikHelpers<T>) => void;
    initialValues: T;
    isEntity?: boolean;
    title: string;
    visible: boolean;
    width?: number;
    disabled?: boolean;
    noFooter?: boolean;
}

const FormModalLayout: FC<FormModalLayoutProps> = ({
    submitButtonId,
    buttonText,
    children,
    className,
    handleClose,
    handleSubmit: onSubmit,
    initialValues,
    title,
    visible,
    width,
    disabled,
    noFooter,
}) => {
    const intl = useIntl();

    return (
        <Modal
            visible={visible}
            title={title}
            wrapClassName={cn('modal', className)}
            onCancel={handleClose}
            closeIcon={<Icon icon="close_big" />}
            width={width || 480}
            centered
            footer={null}
        >
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(helpers) => (
                    <form onSubmit={helpers.handleSubmit} noValidate>
                        {children(helpers)}
                        {!noFooter && (
                            <div className={theme.modal.footer}>
                                <Button
                                    id={submitButtonId}
                                    inGroup
                                    type="primary"
                                    size="medium"
                                    htmlType="submit"
                                    disabled={helpers.isSubmitting || disabled}
                                >
                                    {buttonText}
                                </Button>
                                <Button
                                    type="link"
                                    size="medium"
                                    onClick={handleClose}
                                >
                                    {intl.getMessage('cancel')}
                                </Button>
                            </div>
                        )}
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default FormModalLayout;
