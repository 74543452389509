import { takeEvery, put, call, fork } from 'redux-saga/effects';

import AccountApi from 'Apis/account';
import ProfileApi from 'Apis/profile';
import ResellerApi from 'Apis/resellers';
import {
    MainAccountActions,
    getMainAccount as getMainAccountAct,
    saveAccount,
    saveProfileInfo,
    saveResellerClients,
    saveAccountDetails,
} from 'Actions/mainAccount';
import Account, { IAccount } from 'Entities/Account';
import AccountDetails, { IAccountDetails } from 'Entities/AccountDetails';
import Profile, { IProfile } from 'Entities/Profile';
import ResellerClient, { IResellerClient } from 'Entities/ResellerClient';

import { errorChecker, addError } from './utils';

type CommonAction = ReturnType<typeof getMainAccountAct>;

function* getAccountDetails(action: CommonAction) {
    const response: IAccountDetails = yield call(AccountApi.getAccountDetails, MAIN_TOKEN);
    const { error, result } = errorChecker<IAccountDetails>(response);
    if (result) {
        yield put(saveAccountDetails(new AccountDetails(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getAccount(action: CommonAction) {
    const account: IAccount = yield call(AccountApi.getAccount, MAIN_TOKEN);
    const { error, result } = errorChecker<IAccount>(account);
    if (result) {
        yield put(saveAccount(new Account(account)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getProfile(action: CommonAction) {
    const profileResp: IProfile = yield call(ProfileApi.getProfile, MAIN_TOKEN);
    const { result: profileResult, error: profileError } = errorChecker<IProfile>(profileResp);
    if (profileResult) {
        yield put(saveProfileInfo(new Profile(profileResult)));
    }
    if (profileError) {
        yield addError(profileError, action.type);
    }
}
function* getResellerClients(action: CommonAction) {
    const response: IResellerClient = yield call(ResellerApi.getResellerClients, MAIN_TOKEN);
    const { result, error } = errorChecker<IResellerClient[]>(response);
    if (result) {
        yield put(saveResellerClients(result.map((r) => new ResellerClient(r))));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getMainAccount(act: CommonAction) {
    if (MAIN_TOKEN) {
        yield fork(getAccountDetails, act);
        yield fork(getAccount, act);
        yield fork(getProfile, act);
        yield fork(getResellerClients, act);
    }
}

function* mainAccountSaga() {
    yield takeEvery<CommonAction>(MainAccountActions.GetMainAccount, getMainAccount);
}

export default mainAccountSaga;
