import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';

import { useEnter, useTitle } from 'Hooks';
import { transferPayment, getPartnerDetails } from 'Actions/partner';
import { useIntl, FormPageLayout, Icon, Button, notifySuccess, Input, Banner } from 'Common';
import { BONUS_AMOUNT_PERCENT, currencySymbol } from 'Lib/helpers/consts';
import { getAvailableAmountBages } from 'Lib/helpers/utils';
import {
    onChangeCheckNumberOrFloat,
    onSubmitCheckNumberOrFloat,
    parseFloatWithComma,
} from 'Lib/helpers/helpers';
import { RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import theme from 'Lib/theme';

import s from '../OrderPayment/PaymentForm.module.pcss';

interface TransferPaymentStoreProps {
    details: Store['partner']['details'];
}

const TransferPayment: FC<TransferPaymentStoreProps> = ({ details }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('partner_transfer_payment_page_title'));
    const [amount, setAmount] = useState<string | null>(null);
    const [amountError, setAmountError] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPartnerDetails());
    }, []);

    const submit = () => {
        if (!amount) {
            return;
        }

        const amountParsed = parseFloatWithComma(amount);

        if (!amountParsed || (details && amountParsed > details?.balance) || !onSubmitCheckNumberOrFloat(amount!)) {
            setAmountError(true);
            return;
        }
        dispatch(transferPayment([{ amount: amountParsed }], {
            result: () => notifySuccess(intl.getMessage('transfer_payment_successfull'), 'transfer_payment_successfull'),
        }));
        history.goBack();
    };

    useEnter(submit, [amount, details]);

    if (!details) {
        return null;
    }

    const onAmountChange = (e: string) => {
        if (onChangeCheckNumberOrFloat(e) || e === '') {
            setAmount(e);
        }
    };

    const getLabelWithBonus = () => {
        if (!amount) {
            return intl.getMessage('transfer');
        }

        const parsedAmount = parseFloatWithComma(amount);
        const bonusValue = (BONUS_AMOUNT_PERCENT * 0.01) * parsedAmount;
        const value = (parsedAmount + bonusValue).toFixed(2);
        const amountLabel = amount ? intl.getMessage('price_simple', { value }) : '';

        return `${intl.getMessage('transfer')} ${amountLabel}`;
    };

    return (
        <FormPageLayout
            title={intl.getMessage('partners_transfer_to_main_account')}
            back={RoutePath.Partner}
            banner={(
                <Banner desc={intl.getMessage('partner_banner', { value: BONUS_AMOUNT_PERCENT })} />
            )}
        >
            <div className={s.text}>
                <Icon icon="coins" className={s.icon} />
                {intl.getMessage(
                    'partners_current_balance',
                    {
                        value: intl.getMessage('money', { value: details.balance }),
                    },
                )}
            </div>
            <div className={s.input}>
                <Input
                    name="amount"
                    size="large"
                    type="text"
                    suffix={currencySymbol.RUB}
                    value={amount || ''}
                    onChange={onAmountChange}
                    placeholder={intl.getMessage('sum')}
                    error={amountError}
                    validate={(e) => {
                        const amountParsed = Number(e);
                        return !!amountParsed && amountParsed < details.balance;
                    }}
                    autoFocus
                />
            </div>
            <div className={cn(s.group, s.group_last)}>
                {getAvailableAmountBages(details.balance).map((v) => (
                    <button
                        type="button"
                        key={v}
                        className={cn(
                            theme.Badge.badge,
                            theme.Badge.subject,
                            s.badge,
                        )}
                        onClick={() => onAmountChange(String(v))}
                    >
                        {intl.getMessage('price_simple', { value: v })}
                    </button>
                ))}
            </div>
            <div>
                <Button
                    type="primary"
                    size="big"
                    disabled={!amount}
                    onClick={submit}
                    inGroup
                >
                    {getLabelWithBonus()}
                </Button>
                <Button
                    type="link"
                    size="big"
                    onClick={() => history.goBack()}
                >
                    {intl.getMessage('cancel')}
                </Button>
            </div>
        </FormPageLayout>
    );
};
const selectPartnerDetails = (store: Store) => store.partner.details;

const selector = createSelector([selectPartnerDetails], (details) => ({ details }));

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(TransferPayment);
