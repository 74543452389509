import React, { FC } from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';

import { Icon, useIntl } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { roundNumber } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

import DashboardLink from './DashboardLink';
import s from './Stats.module.pcss';

interface PersonalAccountProps {
    balance: number;
    balanceLeftDays: number;
    depleted: boolean;
}

const PersonalAccount: FC<PersonalAccountProps> = ({ balance, balanceLeftDays, depleted }) => {
    const intl = useIntl();
    const balanceRound = roundNumber(balance);
    const positiveBalance = balance > 0;
    const showBalanceLeftDays = balanceLeftDays !== -1;

    return (
        <div className={s.block}>
            <Row>
                <Col span={24} sm={showBalanceLeftDays ? 12 : 24}>
                    <Icon
                        icon="coins"
                        className={cn(
                            s.icon,
                            s.union,
                            { [s.depleted]: depleted },
                        )}
                    />
                    <div className={s.info}>
                        <div className={theme.score.label}>
                            {intl.getMessage('billing_balance_dashboard')}
                        </div>
                        <div className={cn(
                            s.val,
                            s.val_main,
                            { [s.val_red]: !positiveBalance },
                        )}
                        >
                            {intl.getMessage('price_simple', { value: balanceRound })}
                        </div>
                    </div>
                </Col>
                {showBalanceLeftDays && (
                    <Col span={24} sm={12}>
                        <Icon icon="forecast" className={cn(s.icon, theme.color.redActive)} />
                        <div className={theme.score.label}>
                            {intl.getPlural('dashboard_label_end_of_funds', balanceLeftDays)}
                        </div>
                        <div className={cn(s.val, s.val_main)}>
                            {balanceLeftDays <= 1
                                ? intl.getPlural('less_than_days', 1)
                                : intl.getPlural('days', balanceLeftDays)}
                        </div>
                    </Col>
                )}
                <DashboardLink to={RoutePath.Billing} />
            </Row>
        </div>
    );
};

export default PersonalAccount;
