// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountCreditCardAdd {
    description: string;
    payment_error_url: string;
    payment_success_url: string;
}

export default class AccountCreditCardAdd {
    readonly _description: string;

    /**
     * Description: Payment description
     * Example: Serveroid new card
     */
    get description(): string {
        return this._description;
    }

    static descriptionValidate(description: string): boolean {
        return typeof description === 'string' && !!description.trim();
    }

    readonly _payment_error_url: string;

    /**
     * Description: Fail redirect url
     * Example: https://serveroid.com/card_fail.html
     */
    get paymentErrorUrl(): string {
        return this._payment_error_url;
    }

    static paymentErrorUrlValidate(paymentErrorUrl: string): boolean {
        return typeof paymentErrorUrl === 'string' && !!paymentErrorUrl.trim();
    }

    readonly _payment_success_url: string;

    /**
     * Description: Success redirect url
     * Example: https://serveroid.com/card_success.html
     */
    get paymentSuccessUrl(): string {
        return this._payment_success_url;
    }

    static paymentSuccessUrlValidate(paymentSuccessUrl: string): boolean {
        return typeof paymentSuccessUrl === 'string' && !!paymentSuccessUrl.trim();
    }

    constructor(props: IAccountCreditCardAdd) {
        this._description = props.description.trim();
        this._payment_error_url = props.payment_error_url.trim();
        this._payment_success_url = props.payment_success_url.trim();
    }

    serialize(): IAccountCreditCardAdd {
        const data: IAccountCreditCardAdd = {
            description: this._description,
            payment_error_url: this._payment_error_url,
            payment_success_url: this._payment_success_url,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            description: typeof this._description === 'string' && !!this._description.trim(),
            payment_success_url: typeof this._payment_success_url === 'string' && !!this._payment_success_url.trim(),
            payment_error_url: typeof this._payment_error_url === 'string' && !!this._payment_error_url.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountCreditCardAdd): AccountCreditCardAdd {
        return new AccountCreditCardAdd(props);
    }

    readonly keys: { [key: string]: string } = {
        description: 'description',
        paymentErrorUrl: 'payment_error_url',
        paymentSuccessUrl: 'payment_success_url',
        }
;

    mergeDeepWith(props: Partial<AccountCreditCardAdd>): AccountCreditCardAdd {
        const updateData: Partial<IAccountCreditCardAdd> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountCreditCardAdd) => {
            const updateKey = this.keys[key] as keyof IAccountCreditCardAdd;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountCreditCardAdd, keyof IAccountCreditCardAdd>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountCreditCardAdd({ ...this.serialize(), ...updateData });
    }
}
