import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import { useIntl, Button, Link, Loader } from 'Common';
import { RoutePath, Routes } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import s from './Form.module.pcss';

interface ActionsProps {
    submitText: string;
    noLayout?: boolean;
    isSubmitting: boolean;
    disabled?: boolean;
}

const Actions: FC<ActionsProps> = ({ submitText, noLayout, isSubmitting, disabled }) => {
    const intl = useIntl();
    const route = useRouteMatch();
    const serverInstall = route.path === Routes.OnboardingAddFundsServer;

    const getSubmitLabel = () => {
        if (serverInstall) {
            return intl.getMessage('add_funds_install');
        }

        if (noLayout) {
            return intl.getMessage('billing_add_funds');
        }

        return submitText;
    };

    return (
        <div className={cn(s.actions, { [s.actions_single]: noLayout })}>
            <Button
                type="primary"
                size="big"
                htmlType="submit"
                inGroup={!noLayout}
                className={theme.button.with_icon}
                disabled={isSubmitting || disabled}
            >
                {isSubmitting && (
                    <Loader
                        className={s.loading}
                        circle
                        inButton
                    />
                )}
                {getSubmitLabel()}
            </Button>
            {!noLayout && (
                <Link to={RoutePath.Billing}>
                    <Button
                        type="link"
                        size="big"
                    >
                        {intl.getMessage('cancel')}
                    </Button>
                </Link>
            )}
        </div>
    );
};

export default Actions;
