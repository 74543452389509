import React, { FC, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Col, Row } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import serverApi from 'Apis/servers';
import notificationsApi from 'Apis/notifications';
import { useIntl, Icon, zoomInitialValues } from 'Common';
import { ChartSizeType } from 'Common/Chart/AreaChart';
import { OperationStatus } from 'Entities/OperationStatus';
import { VmStatsFields } from 'Entities/VmStatsFields';
import Server from 'Entities/Server';
import OperationProgress from 'Entities/OperationProgress';
import { IAccountExpenseRequest } from 'Entities/AccountExpenseRequest';
import { getAccountExpensesJSON } from 'Actions/account';
import { IServerJsonStats } from 'Entities/ServerJsonStats';
import { errorChecker, Range } from 'Lib/helpers/utils';
import { getDateRangeMs, toGb, toMb, getRelativeRange, getCssColors } from 'Lib/helpers/helpers';
import { RoutePath, linkPathBuilder, QueryParamsType, Routes } from 'Lib/helpers/routes';
import { CHART_RANGE_MEAN, currencySymbol, DATE_RANGE_TYPES, RESOURCES_TYPES, RESOURCES_MAX_RANGE_VALUE, Zoom } from 'Lib/helpers/consts';
import { IServersEventsRequest } from 'Entities/ServersEventsRequest';
import Notification, { INotification } from 'Entities/Notification';
import { IAccountExpense } from 'Entities/AccountExpense';
import { Store } from 'Store';
import theme from 'Lib/theme';

import ChartTabs from './ChartTabs';
import Filters from './Filters';
import { getInitialRange } from './helpers';
import ChartContainer from './ChartContainer';
import { ChartContext, IChartContext } from './ChartContext';

const CHARTS_SYNC_ID = 'resourcesCharts';

const selectAccountExpensesJSON = (store: Store) => store.account.accountExpensesJSON;
const selector = createSelector(
    selectAccountExpensesJSON,
    (accountExpensesJSON) => ({ accountExpensesJSON }),
);

interface ResourcesProps {
    server: Server;
    installing: boolean;
    progress?: OperationProgress;
}

const Resources: FC<ResourcesProps> = ({
    server,
    installing,
    progress,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const { params } = useRouteMatch<{ resourceType: RESOURCES_TYPES }>();
    const { accountExpensesJSON } = useSelector(selector);

    const { tenantId, id } = server;
    const chartColors = getCssColors();

    const { from: initialFrom, till: initialTill, name } = getInitialRange(server);
    const [rangeName, setRangeName] = useState<DATE_RANGE_TYPES | null>(name);
    const ranges = new Range(initialFrom, initialTill);
    const [range, setRange] = useState(ranges);

    const [data, setData] = useState<IServerJsonStats[] | null>(null);
    const [zoom, setZoom] = useState<Zoom>(zoomInitialValues);
    const [serverEvents, setServerEvents] = useState<Notification[] | null>(null);

    const dynamicValueRangeMax = (chartMax: number) => {
        return [1, 10, 100, 500, 1000].find((max) => chartMax < max) || chartMax;
    };

    const prevTenantId = useRef<number>();

    useEffect(() => {
        prevTenantId.current = tenantId;
    }, []);

    const dynamicCpuAndDiskOpValueRangeMax = (chartMax: number) => {
        return Math.max(chartMax, RESOURCES_MAX_RANGE_VALUE);
    };

    const setDateRangeQueryParams = (currentRangeName: DATE_RANGE_TYPES | null, rangeValue?: Range) => {
        let queryParams: QueryParamsType | undefined;

        if (currentRangeName && currentRangeName !== DATE_RANGE_TYPES.TODAY) {
            queryParams = getRelativeRange(currentRangeName);
        } else if (rangeValue) {
            const { from, till } = getDateRangeMs(range);
            queryParams = { from, till };
        }

        let link = linkPathBuilder(
            intl.currentLocale,
            RoutePath.Server,
            { serverId: id, tenantId },
            queryParams,
        );

        if (match.path === Routes[RoutePath.ServerChartModal] && params.resourceType) {
            link = linkPathBuilder(
                intl.currentLocale,
                RoutePath.ServerChartModal,
                { serverId: id, tenantId, resourceType: params.resourceType },
                queryParams,
            );
        }

        history.replace(link);
    };

    const initialEmpty = range.to.valueOf() < server.timeAddedMillis;

    const size: ChartSizeType = 'medium';
    const commonChartSettings = {
        syncId: CHARTS_SYNC_ID,
        globalZoom: zoom,
        setGlobalZoom: (v: Zoom) => {
            setZoom(v);
            setRangeName(null);
        },
        initialRange: range,
        setRange,
        drawYAxis: true,
        drawXAxis: true,
        serverEvents,
        serverAddedMillis: server.timeAddedMillis,
        size,
        initialEmpty,
    };

    const types = [
        {
            type: RESOURCES_TYPES.PROCESSOR,
            name: intl.getMessage('processor'),
            lines: [{
                id: VmStatsFields.CPU_TOTAL,
                label: intl.getMessage('server_resources_cpu_use'),
                color: chartColors.blue,
            }],
            unit: '%',
            mean: CHART_RANGE_MEAN.CPU,
            dynamicValueRangeMax: dynamicCpuAndDiskOpValueRangeMax,
            valueRange: [0, RESOURCES_MAX_RANGE_VALUE],
            xAxisKey: 'time_millis',
            ...commonChartSettings,
        },
        {
            type: RESOURCES_TYPES.MEMORY,
            name: intl.getMessage('memory_mib'),
            lines: [
                {
                    id: VmStatsFields.MEMORY_CURRENT_MIB,
                    label: intl.getMessage('server_resources_all'),
                    color: chartColors.blue,
                },
                {
                    id: VmStatsFields.MEMORY_ACTIVE_MIB,
                    label: intl.getMessage('server_resources_used_memory'),
                    color: chartColors.red,
                },
            ],
            unit: intl.getMessage('unit_mb'),
            dynamicValueRangeMax: (max: number) => {
                const mb = toMb(Math.floor(toGb(max)));
                return Math.max(server.memoryMib, mb);
            },
            valueRange: [0, server.memoryMib],
            xAxisKey: 'time_millis',
            ...commonChartSettings,
        },
        {
            type: RESOURCES_TYPES.AVERAGE_LOAD,
            name: intl.getMessage('average_load'),
            lines: [
                {
                    id: VmStatsFields.LOAD_AVERAGE,
                    label: intl.getMessage('server_resources_avegare_load'),
                    color: chartColors.blue,
                },
            ],
            unit: '',
            xAxisKey: 'time_millis',
            ...commonChartSettings,
        },
        {
            type: RESOURCES_TYPES.DISK_SPACE,
            name: intl.getMessage('disk_space'),
            lines: [
                {
                    id: VmStatsFields.DISK_FREE,
                    label: intl.getMessage('server_resources_free_space'),
                    color: chartColors.blue,
                },
            ],
            unit: '%',
            valueRange: [0, RESOURCES_MAX_RANGE_VALUE],
            xAxisKey: 'time_millis',
            ...commonChartSettings,
        },
        [
            {
                type: RESOURCES_TYPES.DISK_OPERATION_MB,
                name: intl.getMessage('disk_operations_mb'),
                lines: [
                    {
                        id: VmStatsFields.DISK_READ_MIBS,
                        label: intl.getMessage('server_resources_read'),
                        color: chartColors.blue,
                    },
                    {
                        id: VmStatsFields.DISK_WRITE_MIBS,
                        label: intl.getMessage('server_resources_write'),
                        color: chartColors.red,
                    },
                ],
                unit: intl.getMessage('unit_mbite_s'),
                xAxisKey: 'time_millis' as Extract<keyof IServerJsonStats, 'time_millis'>,
                tabs: true,
                ...commonChartSettings,
            },
            {
                type: RESOURCES_TYPES.DISK_OPERATION_OP,
                name: intl.getMessage('disk_operations_op'),
                lines: [
                    {
                        id: VmStatsFields.DISK_READ_OPS,
                        label: intl.getMessage('server_resources_read'),
                        color: chartColors.blue,
                    },
                    {
                        id: VmStatsFields.DISK_WRITE_OPS,
                        label: intl.getMessage('server_resources_write'),
                        color: chartColors.red,
                    },
                ],
                unit: intl.getMessage('unit_op_s'),
                dynamicValueRangeMax: dynamicCpuAndDiskOpValueRangeMax,
                valueRange: [0, RESOURCES_MAX_RANGE_VALUE],
                xAxisKey: 'time_millis' as Extract<keyof IServerJsonStats, 'time_millis'>,
                tabs: true,
                ...commonChartSettings,
            },
        ],
        {
            type: RESOURCES_TYPES.EXTERNAL_NETWORK,
            name: intl.getMessage('external_network'),
            lines: [
                {
                    id: VmStatsFields.RX_PUBLIC_MBITS,
                    label: intl.getMessage('server_resources_rx_traffic'),
                    color: chartColors.blue,
                },
                {
                    id: VmStatsFields.TX_PUBLIC_MBITS,
                    label: intl.getMessage('server_resources_tx_traffic'),
                    color: chartColors.red,
                },
            ],
            unit: intl.getMessage('unit_mbit_s'),
            mean: CHART_RANGE_MEAN.PUBLIC_NETWORK,
            dynamicValueRangeMax,
            xAxisKey: 'time_millis',
            ...commonChartSettings,
        },
        {
            type: RESOURCES_TYPES.LOCAL_AREA_NETWORK,
            name: intl.getMessage('local_area_network'),
            lines: [
                {
                    id: VmStatsFields.RX_LOCAL_MBITS,
                    label: intl.getMessage('server_resources_rx_traffic'),
                    color: chartColors.blue,
                },
                {
                    id: VmStatsFields.TX_LOCAL_MBITS,
                    label: intl.getMessage('server_resources_tx_traffic'),
                    color: chartColors.red,
                },
            ],
            unit: intl.getMessage('unit_mbit_s'),
            dynamicValueRangeMax,
            xAxisKey: 'time_millis',
            ...commonChartSettings,
        },
    ];

    const getServerEvents = async (rangeValue: Range, status: { update: boolean }) => {
        const { from, till } = getDateRangeMs(rangeValue);

        const serverEventsConfig: IServersEventsRequest = {
            date_from_millis: from,
            date_to_millis: till,
            server_ids: [server.id],
        };

        const response = await notificationsApi.getServersNotifications(serverEventsConfig);
        const { result } = errorChecker<INotification[]>(response);
        if (result && status.update) {
            const notifications = result.map((r) => new Notification(r));
            setServerEvents(notifications);
        }
    };

    const getResources = async (rangeValue: Range, status: { update: boolean }) => {
        const { from, till } = getDateRangeMs(rangeValue);

        const linesList = types.reduce((arr, type) => {
            if (Array.isArray(type)) {
                type.forEach((t) => arr.push(...t.lines.map((line) => line.id)));
            } else {
                arr.push(...type.lines.map((line) => line.id));
            }
            return arr;
        }, [] as VmStatsFields[]);

        let newData: IServerJsonStats[] = [];
        const response = await serverApi.getServerStatsJson(tenantId, id, from, till, linesList);
        const { result } = errorChecker<IServerJsonStats[]>(response);
        if (result) {
            newData = result;
        }

        const csv: IAccountExpenseRequest = {
            date_from_millis: from,
            date_to_millis: till,
            server_ids: [server.id],
        };
        dispatch(getAccountExpensesJSON([csv]));

        if (status.update) {
            setData(newData);
        }
    };

    useEffect(() => {
        const status = { update: true };
        getResources(range, status);
        getServerEvents(range, status);
        setZoom(zoomInitialValues);

        if (rangeName === null) {
            setDateRangeQueryParams(null, range);
        }

        return () => { status.update = false; };
    }, [range, tenantId, id]);

    useEffect(() => {
        if (rangeName && rangeName !== DATE_RANGE_TYPES.TODAY) {
            setDateRangeQueryParams(rangeName);
            UserStorage.setServerRange(server, rangeName);
        } else if (rangeName === DATE_RANGE_TYPES.TODAY) {
            setDateRangeQueryParams(null);
            UserStorage.removeServerRange(server);
        }
    }, [rangeName]);

    const loadingState = (!!progress && progress.status !== OperationStatus.ERROR) || installing;

    return (
        <>
            <Filters
                range={range}
                setRange={setRange}
                loadingState={loadingState}
                getResources={getResources}
                rangeName={rangeName}
                setRangeName={setRangeName}
                globalZoom={zoom}
            />
            {loadingState ? (
                <Row gutter={[48, 24]}>
                    <Col span={24} lg={12}>
                        <div className={cn(theme.skeleton.block, theme.skeleton.resources)}>
                            <Icon icon="lock" className={theme.skeleton.icon} />
                        </div>
                    </Col>
                    {data && types.map((t) => (
                        <Col key={!Array.isArray(t) ? t.type : t[0].type} span={24} lg={12}>
                            <div className={cn(theme.skeleton.block, theme.skeleton.resources)}>
                                <Icon icon="lock" className={theme.skeleton.icon} />
                            </div>
                        </Col>
                    ))}
                </Row>
            ) : (
                <Row gutter={[48, 24]}>
                    {data && (
                        <>
                            {accountExpensesJSON && (
                                <Col span={24} lg={12}>
                                    <div
                                        className={cn(theme.card.card, theme.card.chart)}
                                        style={{ zIndex: 8 }}
                                    >
                                        <ChartContext.Provider
                                            value={{
                                                data: accountExpensesJSON,
                                                chart: {
                                                    type: RESOURCES_TYPES.EXPENSES,
                                                    name: intl.getMessage('server_expense'),
                                                    lines: [{
                                                        id: 'amount',
                                                        color: chartColors.blue,
                                                        label: intl.getMessage('expenses'),
                                                    }],
                                                    unit: currencySymbol.RUB,
                                                    xAxisKey: 'report_time_millis',
                                                    ...commonChartSettings,
                                                },
                                                server,
                                            } as IChartContext<IAccountExpense>}
                                        >
                                            <ChartContainer />
                                        </ChartContext.Provider>
                                    </div>
                                </Col>
                            )}

                            {types.map((t, i) => {
                                if (!Array.isArray(t)) {
                                    return (
                                        <Col key={t.type} span={24} lg={12}>
                                            <div
                                                className={cn(theme.card.card, theme.card.chart)}
                                                style={{ zIndex: types.length - i }}
                                            >
                                                <ChartContext.Provider
                                                    value={{
                                                        data,
                                                        chart: t,
                                                        server,
                                                    } as IChartContext<IServerJsonStats>}
                                                >
                                                    <ChartContainer />
                                                </ChartContext.Provider>
                                            </div>
                                        </Col>
                                    );
                                }

                                return (
                                    <Col key={t[0].type} span={24} lg={12}>
                                        <div
                                            key={t[0].type}
                                            className={cn(theme.card.card, theme.card.chart)}
                                            style={{ zIndex: types.length - i }}
                                        >
                                            <ChartTabs
                                                data={data}
                                                charts={t}
                                                server={server}
                                            />
                                        </div>
                                    </Col>
                                );
                            })}
                        </>
                    )}
                </Row>
            )}
        </>
    );
};

export default Resources;
