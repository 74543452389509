import React, { FC, ChangeEvent, ReactElement } from 'react';
import cn from 'classnames';

import { Icon } from 'Common';
import theme from 'Lib/theme';

import s from './Checkbox.module.pcss';

interface CheckboxProps {
    checked: boolean;
    className?: string;
    labelClassName?: string;
    disabled?: boolean;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    id?: string;
    name: string;
    overflow?: boolean;
    customIcon?: ReactElement;
}

const Checkbox: FC<CheckboxProps> = ({
    checked,
    children,
    className,
    labelClassName,
    disabled,
    handleChange,
    id,
    name,
    overflow,
    customIcon,
}) => {
    return (
        <div>
            <label htmlFor={id} className={cn(s.checkbox, className)}>
                <input
                    id={id}
                    name={name}
                    type="checkbox"
                    className={s.input}
                    onChange={handleChange}
                    checked={checked}
                    disabled={disabled}
                />
                {customIcon ? (
                    <div className={s.handler}>
                        {customIcon}
                    </div>
                ) : (
                    <div className={s.handler}>
                        <Icon icon="check" className={s.icon} />
                    </div>
                )}
                {children && (
                    <div
                        className={cn(
                            s.label,
                            { [theme.common.textOverflow]: overflow },
                            labelClassName,
                        )}
                    >
                        {children}
                    </div>
                )}
            </label>
        </div>
    );
};

export default Checkbox;
