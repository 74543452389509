import React, { FC } from 'react';
import { Button } from 'antd';
import cn from 'classnames';

import { Icon, Link, useIntl } from 'Common';
import theme from 'Lib/theme';
import { RoutePath } from 'Lib/helpers/routes';

interface SimpleHeaderProps {
    linkData?: {
        link: RoutePath;
        title: string;
    };
}
const SimpleHeader: FC<SimpleHeaderProps> = ({ linkData, children }) => {
    const intl = useIntl();
    return (
        <div className={cn(theme.mainHeader.header, theme.mainHeader.header_simple)}>
            <Link to={RoutePath.ServersList} className={theme.mainHeader.logoLink}>
                <Icon icon="main_logo" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_desktop)} />
                <Icon icon="main_logo_dark" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_desktop, theme.mainHeader.logo_dark)} />
                <Icon icon="panel_logo" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_mobile)} />
            </Link>
            {linkData && (
                <div className={theme.mainHeader.right}>
                    <div className={theme.mainHeader.label}>
                        {intl.getMessage('trial_label')}
                    </div>
                    <Link to={linkData.link}>
                        <Button
                            type="link"
                            className={cn(
                                theme.button.button,
                                theme.button.border,
                                theme.button.desktop_big,
                            )}
                        >
                            {linkData.title}
                        </Button>
                    </Link>
                </div>
            )}
            {children}
        </div>
    );
};

export default SimpleHeader;
