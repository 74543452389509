import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import PasswordRecovery, { IPasswordRecovery } from 'Entities/PasswordRecovery';
import PasswordReset, { IPasswordReset } from 'Entities/PasswordReset';
import Registration, { IRegistration } from 'Entities/Registration';
import RegistrationConfirm, { IRegistrationConfirm } from 'Entities/RegistrationConfirm';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class RegistrationApi {
    static async confirmRegistration(registrationconfirm: IRegistrationConfirm, xauthcookie?: string): Promise<{ token: string, cookie: string | null } | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const registrationconfirmValid = new RegistrationConfirm(registrationconfirm);
        haveError.push(...registrationconfirmValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/confirm_email`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'Content-Type': 'application/json',
                'X-Auth-Cookie': xauthcookie || '',
            },
            body: JSON.stringify(registrationconfirmValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return {
                    token: res.headers.get('x-auth-token')!,
                    cookie: res.headers.get('x-auth-cookie'),
                };
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async recoverPassword(passwordrecovery: IPasswordRecovery): Promise<{ token: string, cookie: string | null } | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const passwordrecoveryValid = new PasswordRecovery(passwordrecovery);
        haveError.push(...passwordrecoveryValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/recover_password`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(passwordrecoveryValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return {
                    token: res.headers.get('x-auth-token')!,
                    cookie: res.headers.get('x-auth-cookie'),
                };
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async register(registration: IRegistration, hcaptcharesponse?: string, xauthcookie?: string): Promise<{ token: string, cookie: string | null } | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const registrationValid = new Registration(registration);
        haveError.push(...registrationValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/register`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'Content-Type': 'application/json',
                'H-Captcha-Response': hcaptcharesponse || '',
                'X-Auth-Cookie': xauthcookie || '',
            },
            body: JSON.stringify(registrationValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return {
                    token: res.headers.get('x-auth-token')!,
                    cookie: res.headers.get('x-auth-cookie'),
                };
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async resetPassword(passwordreset: IPasswordReset, hcaptcharesponse?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const passwordresetValid = new PasswordReset(passwordreset);
        haveError.push(...passwordresetValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/reset_password`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'Content-Type': 'application/json',
                'H-Captcha-Response': hcaptcharesponse || '',
            },
            body: JSON.stringify(passwordresetValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async validateRegistrationCode(reg_code: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            reg_code: reg_code,
        }
        return await fetch(`/api/v1/check_regcode?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
