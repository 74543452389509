import { InvoiceStatus } from './InvoiceStatus';
import { PaymentSystemType } from './PaymentSystemType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountInvoice {
    amount: number;
    download_url: string;
    invoice_id: number;
    number: string;
    payment_system: PaymentSystemType;
    status: InvoiceStatus;
    time_added: string;
    time_added_millis: number;
}

export default class AccountInvoice {
    readonly _amount: number;

    /**
     * Description: Recorded payment amount
     * Example: 230.5
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    readonly _download_url: string;

    /**
     * Description: Document download link path
     * Example: https://localhost:8181/documents/a364001059ef15eb21e5dcb9a94ab1b9/vat.pdf
     */
    get downloadUrl(): string {
        return this._download_url;
    }

    static downloadUrlValidate(downloadUrl: string): boolean {
        return typeof downloadUrl === 'string' && !!downloadUrl.trim();
    }

    readonly _invoice_id: number;

    /**
     * Description: Invoice identifier
     * Example: 3456
     */
    get invoiceId(): number {
        return this._invoice_id;
    }

    static invoiceIdValidate(invoiceId: number): boolean {
        return typeof invoiceId === 'number';
    }

    readonly _number: string;

    /**
     * Description: Payment number
     * Example: 2950013/1
     */
    get number(): string {
        return this._number;
    }

    static numberValidate(number: string): boolean {
        return typeof number === 'string' && !!number.trim();
    }

    readonly _payment_system: PaymentSystemType;

    get paymentSystem(): PaymentSystemType {
        return this._payment_system;
    }

    static paymentSystemValidate(paymentSystem: PaymentSystemType): boolean {
        return Object.keys(PaymentSystemType).includes(paymentSystem);
    }

    readonly _status: InvoiceStatus;

    get status(): InvoiceStatus {
        return this._status;
    }

    static statusValidate(status: InvoiceStatus): boolean {
        return Object.keys(InvoiceStatus).includes(status);
    }

    readonly _time_added: string;

    /** */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Recorded payment date, UTC millis
     * Example: 1593064584178
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    constructor(props: IAccountInvoice) {
        this._amount = props.amount;
        this._download_url = props.download_url.trim();
        this._invoice_id = props.invoice_id;
        this._number = props.number.trim();
        this._payment_system = props.payment_system;
        this._status = props.status;
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
    }

    serialize(): IAccountInvoice {
        const data: IAccountInvoice = {
            amount: this._amount,
            download_url: this._download_url,
            invoice_id: this._invoice_id,
            number: this._number,
            payment_system: this._payment_system,
            status: this._status,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            invoice_id: typeof this._invoice_id === 'number',
            amount: typeof this._amount === 'number',
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
            number: typeof this._number === 'string' && !!this._number.trim(),
            download_url: typeof this._download_url === 'string' && !!this._download_url.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountInvoice): AccountInvoice {
        return new AccountInvoice(props);
    }

    readonly keys: { [key: string]: string } = {
        amount: 'amount',
        downloadUrl: 'download_url',
        invoiceId: 'invoice_id',
        number: 'number',
        paymentSystem: 'payment_system',
        status: 'status',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        }
;

    mergeDeepWith(props: Partial<AccountInvoice>): AccountInvoice {
        const updateData: Partial<IAccountInvoice> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountInvoice) => {
            const updateKey = this.keys[key] as keyof IAccountInvoice;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountInvoice, keyof IAccountInvoice>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountInvoice({ ...this.serialize(), ...updateData });
    }
}
