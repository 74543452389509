// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IIpMove {
    ip_address: string;
    to_server_id: number;
}

export default class IpMove {
    readonly _ip_address: string;

    /**
     * Description: Ip address
     * Example: 10.0.0.1
     */
    get ipAddress(): string {
        return this._ip_address;
    }

    static ipAddressValidate(ipAddress: string): boolean {
        return typeof ipAddress === 'string' && !!ipAddress.trim();
    }

    readonly _to_server_id: number;

    /**
     * Description: Server identifier to move ip
     * Example: 51672
     */
    get toServerId(): number {
        return this._to_server_id;
    }

    static toServerIdValidate(toServerId: number): boolean {
        return typeof toServerId === 'number';
    }

    constructor(props: IIpMove) {
        this._ip_address = props.ip_address.trim();
        this._to_server_id = props.to_server_id;
    }

    serialize(): IIpMove {
        const data: IIpMove = {
            ip_address: this._ip_address,
            to_server_id: this._to_server_id,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            to_server_id: typeof this._to_server_id === 'number',
            ip_address: typeof this._ip_address === 'string' && !!this._ip_address.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IIpMove): IpMove {
        return new IpMove(props);
    }

    readonly keys: { [key: string]: string } = {
        ipAddress: 'ip_address',
        toServerId: 'to_server_id',
        }
;

    mergeDeepWith(props: Partial<IpMove>): IpMove {
        const updateData: Partial<IIpMove> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof IpMove) => {
            const updateKey = this.keys[key] as keyof IIpMove;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IIpMove, keyof IIpMove>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new IpMove({ ...this.serialize(), ...updateData });
    }
}
