import React, { FC } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Store } from 'Store';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

import { useIntl, Button } from 'Common';
import PartnerPayment from 'Entities/PartnerPayment';
import ResellerPayment from 'Entities/ResellerPayment';
import { AffiliatePayoutType } from 'Entities/AffiliatePayoutType';
import theme from 'Lib/theme';

interface PaymentProps {
    payment: ResellerPayment | PartnerPayment;
    details: Store['partner']['details'];
}
const Payment: FC<PaymentProps> = ({ details, payment }) => {
    const intl = useIntl();
    const downloadUrl = payment.downloadLink;

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.with_actions,
            )}
        >
            <div className={cn(theme.card.grid, theme.card.grid_payment)}>
                <div className={theme.common.textOverflow}>
                    {payment.paid
                        ? intl.getMessage('partners_payment_paid')
                        : intl.getMessage('partners_payment_unpaid')}
                </div>
                <div className={theme.common.textOverflow}>
                    {intl.getMessage('price_simple', { value: payment.amount })}
                </div>
                <div className={theme.card.actionsWrap}>
                    <div className={theme.color.grayHeaders}>
                        {dayjs(payment.timeAddedMillis).format(intl.getMessage('time_format_short_month_year'))}
                    </div>

                    {details?.paymentType === AffiliatePayoutType.FLOPS && (
                        <div className={theme.card.actions}>
                            <a
                                href={downloadUrl}
                                className={cn(
                                    theme.card.action,
                                    theme.card.action_hover,
                                    theme.card.action_withMobile,
                                )}
                                download
                            >
                                <Button
                                    type="icon"
                                    icon="download"
                                    title={intl.getMessage('title_dowload_file_extension', { value: 'PDF' })}
                                />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

const selectPartnerDetails = (store: Store) => store.partner.details;

const selector = createSelector(
    [selectPartnerDetails],
    (details) => ({ details }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Payment);
