import Account from 'Entities/Account';
import AccountDetails from 'Entities/AccountDetails';
import Profile from 'Entities/Profile';
import ResellerClient from 'Entities/ResellerClient';

import { actionCreator } from '.';

export enum MainAccountActions {
    GetMainAccount = 'GetMainAccount',
    SaveMainAccount = 'SaveMainAccount',
    SaveMainAccountDetails = 'SaveMainAccountDetails',
    SaveMainProfileInfo = 'SaveMainProfileInfo',
    SaveMainResellerClients = 'SaveMainResellerClients',
}
const getMainAccount = actionCreator(MainAccountActions.GetMainAccount);
const saveAccount = actionCreator<Account>(MainAccountActions.SaveMainAccount);
const saveAccountDetails = actionCreator<AccountDetails>(MainAccountActions.SaveMainAccountDetails);
const saveProfileInfo = actionCreator<Profile>(MainAccountActions.SaveMainProfileInfo);
const saveResellerClients = actionCreator<ResellerClient[]>(
    MainAccountActions.SaveMainResellerClients,
);

export {
    getMainAccount,
    saveAccount,
    saveAccountDetails,
    saveProfileInfo,
    saveResellerClients,
};
