import React, { FC } from 'react';

import { useIntl, DeleteModalLayout } from 'Common';

interface DeleteDnsRecordProps {
    visible: boolean;
    handleClose: () => void;
    onOk: () => void;
}

const DeleteDnsRecord: FC<DeleteDnsRecordProps> = ({ visible, handleClose, onOk }) => {
    const intl = useIntl();

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('records_delete')}
            handleClose={handleClose}
            handleDelete={onOk}
        >
            <div className="modal__desc">
                {intl.getMessage('records_delete_confirm')}
            </div>
        </DeleteModalLayout>
    );
};

export default DeleteDnsRecord;
