import React, { FC } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { Store } from 'Store';

interface FormattedMessageStoreProps {
    intl: Store['intl'];
}
interface FormattedMessageOwnProps {
    id: string;
    value?: number;
    params?: {[id: string]: string | number};
}
type FormattedMessageProps = FormattedMessageStoreProps & FormattedMessageOwnProps;
const FormattedMessage: FC<FormattedMessageProps> = ({ id, value, params, intl }) => {
    if (value) {
        return (
            <div>
                {intl.getPlural(id, value, params)}
            </div>
        );
    }
    return (
        <div>
            {intl.getMessage(id, params)}
        </div>
    );
};

const storeIntl = (store: Store) => store.intl;
const selectIntl = createSelector(storeIntl, (intl) => intl);

const mapStateToProps = (store: Store) => ({
    intl: selectIntl(store),
});

export default connect(mapStateToProps)(FormattedMessage);
