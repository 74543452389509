import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { createTicket } from 'Actions/support';
import { addClientReviewKey } from 'Actions/profile';
import { Icon, useIntl, Button, TextArea, Modal } from 'Common';
import Profile from 'Entities/Profile';
import { ITicketCreate } from 'Entities/TicketCreate';
import { getVisitsCount, setVisitsCount } from 'Lib/helpers/utils';
import { REVIEWS_KEYS, REVIEW_SUBJECT } from 'Lib/helpers/consts';
import { Store } from 'Store';
import theme from 'Lib/theme';

import s from './RateUs.module.pcss';
import { Star } from './components';

const selectProfileDetails = (st: Store) => st.profile.info;
const selectLoading = (st: Store) => st.user.loading;
const selector = createSelector(selectProfileDetails, selectLoading, (profile, loading) => ({ profile, loading }));

interface RateUsProps {
    profile: Profile;
}

const starsValues = [1, 2, 3, 4, 5];

const RateUs: FC<RateUsProps> = ({ profile }) => {
    const [showModal, setShowModal] = useState(false);
    const [showBad, setShowBad] = useState(false);
    const [showThanks, setShowThanks] = useState(false);
    const [message, setMessage] = useState('');
    const [star, setStar] = useState(0);
    const [highlighted, setHighlighted] = useState(0);
    const dispatch = useDispatch();

    const { discounts, sentReviews } = profile;
    const referrerDiscount = discounts.find((d) => d.balance === REVIEWS_KEYS.REFERRER_REG_DISCOUNT);
    const discountNoticeVisible = referrerDiscount
        && !sentReviews.includes(REVIEWS_KEYS.REFERRER_REG_DISCOUNT);

    useEffect(() => {
        const { times, weekTwo, firstHistory } = getVisitsCount();
        const thirdVisit = (times + 1) === 3;
        const afterTwoWeekShow = (Date.now() - firstHistory > (60 * 60 * 24 * 14 * 1000)
            && !weekTwo);
        const reviewed = profile.sentReviews.includes(REVIEWS_KEYS.MOVE_FROM_FLOPS);
        if (afterTwoWeekShow && !reviewed && !discountNoticeVisible) {
            setVisitsCount(true);
            setShowModal(true);
            return;
        }
        if (thirdVisit && !reviewed && !discountNoticeVisible) {
            setShowModal(true);
        }
        setVisitsCount();
    }, []);

    const intl = useIntl();

    if (!showModal) {
        return null;
    }

    const onSubmit = (rate: number) => {
        dispatch(addClientReviewKey([{
            review_key: REVIEWS_KEYS.MOVE_FROM_FLOPS,
            review_rating: rate,
        }]));
        if (message.trim()) {
            const contents = `Rating: ${rate === 0 ? 'NO_RATE' : rate}. Text: ${message.trim()}`;
            const data = new FormData();
            const params: ITicketCreate = {
                subject: REVIEW_SUBJECT,
                close_immediately: false,
                contents,
            };
            data.set('params', new Blob([JSON.stringify(params)], { type: 'application/json' }));
            dispatch(createTicket([data], {
                result: () => {
                    setShowThanks(true);
                },
            }));
            return;
        }
        setShowThanks(true);
    };

    const title = showThanks ? (
        <div className={s.titleThanks}>
            {intl.getMessage('rateus_thanks')}
        </div>
    ) : (
        <div className={s.title}>
            {showBad && (
                <Button
                    type="edit"
                    size="big"
                    className={cn(
                        theme.button.icon,
                        theme.button.back,
                        s.back,
                    )}
                    onClick={() => setShowBad(false)}
                    icon="arrow"
                />
            )}
            {!showBad ? intl.getMessage('rateus_title') : intl.getMessage('rateus_title_bad')}
        </div>
    );

    const onHover = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = e.target as HTMLElement;
        if (target.dataset.order) {
            setHighlighted(Number(target.dataset.order));
        }
    };

    const footer = !showBad || showThanks ? null : (
        <Button
            block
            size="big"
            type="primary"
            className={theme.button.block_oversize}
            onClick={() => onSubmit(star)}
        >
            {!message ? intl.getMessage('rateus_send_empty') : intl.getMessage('rateus_send')}
        </Button>
    );

    const onSetStar = (v: number) => () => {
        setStar(v);
        setShowBad(true);
    };

    return (
        <Modal
            centered
            visible={showModal}
            onCancel={() => setShowModal(false)}
            wrapClassName={cn('modal', 'modal_reviews', { modal_title_only: showThanks })}
            footer={footer}
            title={title}
            closeIcon={<Icon icon="close_big" className={cn({ [s.hideClose]: showThanks })} />}
            width={400}
        >
            {!showThanks && (
                <div className={s.content}>
                    {!showBad && (
                        <>
                            <div
                                className={s.icons}
                                onMouseOver={onHover}
                                onMouseLeave={() => setHighlighted(0)}
                            >
                                {starsValues.map((v) => (
                                    <Star
                                        value={v}
                                        key={v}
                                        highlighted={highlighted}
                                        setStar={onSetStar(v)}
                                        star={star}
                                    />
                                ))}
                            </div>
                            <div className={s.description}>
                                {intl.getMessage('rateus_decs')}
                            </div>
                            <div>
                                <Button
                                    type="link"
                                    size="medium"
                                    className={cn(s.writeToUs, theme.button.link_black)}
                                    onClick={() => setShowBad(true)}
                                >
                                    {intl.getMessage('rateus_write')}
                                </Button>
                            </div>
                        </>
                    )}
                    {showBad && (
                        <>
                            <div className={s.description}>
                                {intl.getMessage('rateus_desc_bad')}
                            </div>
                            <div className={s.text}>
                                <TextArea
                                    autoFocus
                                    isGray
                                    validate={() => true}
                                    error={false}
                                    wrapperClassName={s.textArea}
                                    className={s.textArea}
                                    name="rateus_message"
                                    placeholder={intl.getMessage('rateus_placeholder')}
                                    value={message}
                                    onChange={(e) => setMessage(e)}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </Modal>
    );
};

const RateUsContainer: FC = () => {
    const { profile, loading } = useSelector(selector);
    if (!profile || loading) {
        return null;
    }
    return <RateUs profile={profile} />;
};

export default RateUsContainer;
