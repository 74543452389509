import { ServerState } from './ServerState';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerStateEvent {
    state: ServerState;
    time_added: string;
    time_added_millis: number;
}

export default class ServerStateEvent {
    readonly _state: ServerState;

    get state(): ServerState {
        return this._state;
    }

    static stateValidate(state: ServerState): boolean {
        return Object.keys(ServerState).includes(state);
    }

    readonly _time_added: string;

    /** */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Time added, UTC millis
     * Example: 1593064584178
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    constructor(props: IServerStateEvent) {
        this._state = props.state;
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
    }

    serialize(): IServerStateEvent {
        const data: IServerStateEvent = {
            state: this._state,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerStateEvent): ServerStateEvent {
        return new ServerStateEvent(props);
    }

    readonly keys: { [key: string]: string } = {
        state: 'state',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        }
;

    mergeDeepWith(props: Partial<ServerStateEvent>): ServerStateEvent {
        const updateData: Partial<IServerStateEvent> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerStateEvent) => {
            const updateKey = this.keys[key] as keyof IServerStateEvent;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerStateEvent, keyof IServerStateEvent>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerStateEvent({ ...this.serialize(), ...updateData });
    }
}
