import dayjs from 'dayjs';
import React, { FC, useEffect } from 'react';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Store } from 'Store';
import { setLocale } from 'Actions/locale';
import { Locale } from 'Lib/intl';

interface LangProps {
    lang: Locale;
}
type IntlProviderProps = RouteComponentProps<LangProps> & LangProps;
const IntlProvider: FC<IntlProviderProps> = (props) => {
    const { match: { params: { lang: pLang } }, lang, children } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        if (lang !== pLang) {
            dispatch(setLocale(pLang));
            dayjs.locale(pLang);
        }
    }, [pLang, lang]);

    return (
        <>
            {children}
        </>
    );
};

const storeLocale = (store: Store) => store.intl.currentLocale;
const selectLocale = createSelector(storeLocale, (locale) => locale);

const mapStateToprops = (store: Store) => ({
    lang: selectLocale(store),
});

export default withRouter(connect(mapStateToprops)(IntlProvider));
