import React, { FC, useState } from 'react';
import { Tooltip } from 'antd';

import { useIntl } from 'Common';
import ResellerClientUpdate from 'Entities/ResellerClientUpdate';

import s from './Comment.module.pcss';

interface CommentProps {
    comment?: string;
    updateComment: (v: string) => void;
}

const Comment: FC<CommentProps> = ({ comment, updateComment }) => {
    const intl = useIntl();
    const [value, setValue] = useState(comment || '');

    const onSave = () => {
        updateComment(value);
    };

    const onChange = (v: string) => {
        if (v.length <= ResellerClientUpdate.commentMaxLength) {
            setValue(v);
        }
    };

    return (
        <Tooltip
            title={value}
            placement="bottomLeft"
            overlayClassName="tooltip tooltip_comment"
        >
            <input
                type="text"
                placeholder={intl.getMessage('add_comment')}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onSave}
                className={s.comment}
            />
        </Tooltip>
    );
};

export default Comment;
