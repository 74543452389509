import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { shutDownServer, rebootServer, resetServer, destroyServer } from 'Actions/server';
import { useUISettings } from 'Hooks';
import { useIntl } from 'Common';
import Server from 'Entities/Server';
import { OperationType } from 'Entities/OperationType';
import { SERVER_OPERATION_NOTIFICATION } from 'Lib/helpers/consts';

import {
    RebootModal,
    DestroyModal,
    ResetModal,
    ShutDownModal,
    ActionButton,
} from '.';

interface EnabledControlsProps {
    server: Server;
    close: () => void;
    type?: OperationType;
}

const EnabledControls: FC<EnabledControlsProps> = ({ server, close }) => {
    const { operationNotifications } = useUISettings();
    const [openOperationModal, setOpenOperationModal] = useState({
        [SERVER_OPERATION_NOTIFICATION.REBOOT]: false,
        [SERVER_OPERATION_NOTIFICATION.RESET]: false,
        [SERVER_OPERATION_NOTIFICATION.SHUTDOWN]: false,
        [SERVER_OPERATION_NOTIFICATION.DESTROY]: false,
    });
    const intl = useIntl();
    const dispatch = useDispatch();
    const onOperation: Record<SERVER_OPERATION_NOTIFICATION, () => void> = {
        [SERVER_OPERATION_NOTIFICATION.REBOOT]: () => {
            dispatch(rebootServer(server));
            close();
        },
        [SERVER_OPERATION_NOTIFICATION.RESET]: () => {
            dispatch(resetServer(server));
            close();
        },
        [SERVER_OPERATION_NOTIFICATION.SHUTDOWN]: () => {
            dispatch(shutDownServer(server));
            close();
        },
        [SERVER_OPERATION_NOTIFICATION.DESTROY]: () => {
            dispatch(destroyServer(server));
            close();
        },
    };
    const closeModal = (modal: SERVER_OPERATION_NOTIFICATION) => () => {
        setOpenOperationModal({ ...openOperationModal, [modal]: false });
    };

    const checkModalShow = (modal: SERVER_OPERATION_NOTIFICATION) => () => {
        if (!operationNotifications[modal]) {
            onOperation[modal]();
        } else {
            setOpenOperationModal({ ...openOperationModal, [modal]: true });
        }
    };

    return (
        <>
            <ActionButton
                id="on_reboot"
                handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.REBOOT)}
                title={intl.getMessage('server_soft_reboot')}
            />
            <ActionButton
                id="on_reset"
                handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.RESET)}
                title={intl.getMessage('server_force_reboot')}
            />
            <ActionButton
                id="on_shutdown"
                handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.SHUTDOWN)}
                title={intl.getMessage('server_soft_shutdown')}
            />
            <ActionButton
                id="on_destroy"
                handler={checkModalShow(SERVER_OPERATION_NOTIFICATION.DESTROY)}
                title={intl.getMessage('server_force_shutdown')}
            />
            {openOperationModal.rebootNotification && (
                <RebootModal
                    visible
                    close={closeModal(SERVER_OPERATION_NOTIFICATION.REBOOT)}
                    handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.REBOOT]}
                />
            )}
            {openOperationModal.resetNotification && (
                <ResetModal
                    visible
                    close={closeModal(SERVER_OPERATION_NOTIFICATION.RESET)}
                    handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.RESET]}
                />
            )}
            {openOperationModal.shutdownNotification && (
                <ShutDownModal
                    visible
                    close={closeModal(SERVER_OPERATION_NOTIFICATION.SHUTDOWN)}
                    handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.SHUTDOWN]}
                />
            )}
            {openOperationModal.destroyNotification && (
                <DestroyModal
                    visible
                    close={closeModal(SERVER_OPERATION_NOTIFICATION.DESTROY)}
                    handleSubmit={onOperation[SERVER_OPERATION_NOTIFICATION.DESTROY]}
                />
            )}
        </>
    );
};

export default EnabledControls;
