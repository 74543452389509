import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, DashboardHeader, Link, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

const Header: FC = () => {
    const intl = useIntl();
    return (
        <DashboardHeader
            navigation={(
                <div className={cn(theme.header.link, theme.header.active)}>
                    {intl.getMessage('projects')}
                </div>
            )}
            actions={(
                <Link to={RoutePath.NewProject}>
                    <Button
                        type="primary"
                        size="medium"
                    >
                        {intl.getMessage('projectlist_create')}
                    </Button>
                </Link>
            )}
        />
    );
};

export default Header;
