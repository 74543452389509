import Discount, { IDiscount } from './Discount';
import PhoneInfo, { IPhoneInfo } from './PhoneInfo';
import ProfileLimits, { IProfileLimits } from './ProfileLimits';
import { WebTheme } from './WebTheme';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IProfile {
    balance: number;
    client_id: number;
    client_login_available: boolean;
    discounts: IDiscount[];
    email: string;
    new_notifications_count: number;
    newsletter_enabled: boolean;
    phone?: IPhoneInfo;
    profile_limits: IProfileLimits;
    ref_id?: number;
    reseller: boolean;
    reseller_email?: string;
    reseller_section_hidden: boolean;
    sent_reviews: string[];
    server_upgrade_recommendations_enabled: boolean;
    trial_bonus_active: boolean;
    trial_bonus_available: boolean;
    trial_bonus_left_days?: number;
    web_theme: WebTheme;
}

export default class Profile {
    readonly _balance: number;

    /**
     * Description: User account balance
     * Example: 330.56
     */
    get balance(): number {
        return this._balance;
    }

    static balanceValidate(balance: number): boolean {
        return typeof balance === 'number';
    }

    readonly _client_id: number;

    /**
     * Description: Identifier
     * Example: 101
     */
    get clientId(): number {
        return this._client_id;
    }

    static clientIdValidate(clientId: number): boolean {
        return typeof clientId === 'number';
    }

    readonly _client_login_available: boolean;

    /** */
    get clientLoginAvailable(): boolean {
        return this._client_login_available;
    }

    static clientLoginAvailableValidate(clientLoginAvailable: boolean): boolean {
        return typeof clientLoginAvailable === 'boolean';
    }

    readonly _discounts: Discount[];

    /** */
    get discounts(): Discount[] {
        return this._discounts;
    }

    readonly _email: string;

    /**
     * Description: Email
     * Example: whitebox@google.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    readonly _new_notifications_count: number;

    /**
     * Description: New notifications count
     * Example: 2
     */
    get newNotificationsCount(): number {
        return this._new_notifications_count;
    }

    static newNotificationsCountValidate(newNotificationsCount: number): boolean {
        return typeof newNotificationsCount === 'number';
    }

    readonly _newsletter_enabled: boolean;

    /**
     * Description: If client's subscribed for newsletter
     * Example: true
     */
    get newsletterEnabled(): boolean {
        return this._newsletter_enabled;
    }

    static newsletterEnabledValidate(newsletterEnabled: boolean): boolean {
        return typeof newsletterEnabled === 'boolean';
    }

    readonly _phone: PhoneInfo | undefined;

    get phone(): PhoneInfo | undefined {
        return this._phone;
    }

    readonly _profile_limits: ProfileLimits;

    get profileLimits(): ProfileLimits {
        return this._profile_limits;
    }

    readonly _ref_id: number | undefined;

    /** */
    get refId(): number | undefined {
        return this._ref_id;
    }

    readonly _reseller: boolean;

    /** */
    get reseller(): boolean {
        return this._reseller;
    }

    static resellerValidate(reseller: boolean): boolean {
        return typeof reseller === 'boolean';
    }

    readonly _reseller_email: string | undefined;

    /**
     * Description: Email of reseller who manages this client's account
     * Example: reseller@serveroid.com
     */
    get resellerEmail(): string | undefined {
        return this._reseller_email;
    }

    readonly _reseller_section_hidden: boolean;

    /** */
    get resellerSectionHidden(): boolean {
        return this._reseller_section_hidden;
    }

    static resellerSectionHiddenValidate(resellerSectionHidden: boolean): boolean {
        return typeof resellerSectionHidden === 'boolean';
    }

    readonly _sent_reviews: string[];

    /** */
    get sentReviews(): string[] {
        return this._sent_reviews;
    }

    static sentReviewsValidate(sentReviews: string[]): boolean {
        return sentReviews.reduce<boolean>((result, p) => result && (typeof p === 'string' && !!p.trim()), true);
    }

    readonly _server_upgrade_recommendations_enabled: boolean;

    /**
     * Description: If client's subscribed for servers upgrade recommendations
     * Example: true
     */
    get serverUpgradeRecommendationsEnabled(): boolean {
        return this._server_upgrade_recommendations_enabled;
    }

    static serverUpgradeRecommendationsEnabledValidate(serverUpgradeRecommendationsEnabled: boolean): boolean {
        return typeof serverUpgradeRecommendationsEnabled === 'boolean';
    }

    readonly _trial_bonus_active: boolean;

    /** */
    get trialBonusActive(): boolean {
        return this._trial_bonus_active;
    }

    static trialBonusActiveValidate(trialBonusActive: boolean): boolean {
        return typeof trialBonusActive === 'boolean';
    }

    readonly _trial_bonus_available: boolean;

    /**
     * Description: Is trial bonus available
     * Example: true
     */
    get trialBonusAvailable(): boolean {
        return this._trial_bonus_available;
    }

    static trialBonusAvailableValidate(trialBonusAvailable: boolean): boolean {
        return typeof trialBonusAvailable === 'boolean';
    }

    readonly _trial_bonus_left_days: number | undefined;

    /**
     * Description: Remaining duration for trial period in days (null if not active)
     * Example: 7
     */
    get trialBonusLeftDays(): number | undefined {
        return this._trial_bonus_left_days;
    }

    readonly _web_theme: WebTheme;

    get webTheme(): WebTheme {
        return this._web_theme;
    }

    static webThemeValidate(webTheme: WebTheme): boolean {
        return Object.keys(WebTheme).includes(webTheme);
    }

    constructor(props: IProfile) {
        this._balance = props.balance;
        this._client_id = props.client_id;
        this._client_login_available = props.client_login_available;
        this._discounts = props.discounts.map((p) => new Discount(p));
        this._email = props.email.trim();
        this._new_notifications_count = props.new_notifications_count;
        this._newsletter_enabled = props.newsletter_enabled;
        if (props.phone) {
            this._phone = new PhoneInfo(props.phone);
        }
        this._profile_limits = new ProfileLimits(props.profile_limits);
        if (typeof props.ref_id === 'number') {
            this._ref_id = props.ref_id;
        }
        this._reseller = props.reseller;
        if (typeof props.reseller_email === 'string') {
            this._reseller_email = props.reseller_email.trim();
        }
        this._reseller_section_hidden = props.reseller_section_hidden;
        this._sent_reviews = props.sent_reviews;
        this._server_upgrade_recommendations_enabled = props.server_upgrade_recommendations_enabled;
        this._trial_bonus_active = props.trial_bonus_active;
        this._trial_bonus_available = props.trial_bonus_available;
        if (typeof props.trial_bonus_left_days === 'number') {
            this._trial_bonus_left_days = props.trial_bonus_left_days;
        }
        this._web_theme = props.web_theme;
    }

    serialize(): IProfile {
        const data: IProfile = {
            balance: this._balance,
            client_id: this._client_id,
            client_login_available: this._client_login_available,
            discounts: this._discounts.map((p) => p.serialize()),
            email: this._email,
            new_notifications_count: this._new_notifications_count,
            newsletter_enabled: this._newsletter_enabled,
            profile_limits: this._profile_limits.serialize(),
            reseller: this._reseller,
            reseller_section_hidden: this._reseller_section_hidden,
            sent_reviews: this._sent_reviews,
            server_upgrade_recommendations_enabled: this._server_upgrade_recommendations_enabled,
            trial_bonus_active: this._trial_bonus_active,
            trial_bonus_available: this._trial_bonus_available,
            web_theme: this._web_theme,
        };
        if (typeof this._phone !== 'undefined') {
            data.phone = this._phone.serialize();
        }
        if (typeof this._ref_id !== 'undefined') {
            data.ref_id = this._ref_id;
        }
        if (typeof this._reseller_email !== 'undefined') {
            data.reseller_email = this._reseller_email;
        }
        if (typeof this._trial_bonus_left_days !== 'undefined') {
            data.trial_bonus_left_days = this._trial_bonus_left_days;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            client_id: typeof this._client_id === 'number',
            balance: typeof this._balance === 'number',
            email: typeof this._email === 'string' && !!this._email.trim(),
            phone: !this._phone ? true : this._phone.validate().length === 0,
            newsletter_enabled: typeof this._newsletter_enabled === 'boolean',
            server_upgrade_recommendations_enabled: typeof this._server_upgrade_recommendations_enabled === 'boolean',
            new_notifications_count: typeof this._new_notifications_count === 'number',
            reseller_email: !this._reseller_email ? true : typeof this._reseller_email === 'string',
            reseller: typeof this._reseller === 'boolean',
            reseller_section_hidden: typeof this._reseller_section_hidden === 'boolean',
            client_login_available: typeof this._client_login_available === 'boolean',
            trial_bonus_available: typeof this._trial_bonus_available === 'boolean',
            trial_bonus_active: typeof this._trial_bonus_active === 'boolean',
            trial_bonus_left_days: !this._trial_bonus_left_days ? true : typeof this._trial_bonus_left_days === 'number',
            profile_limits: this._profile_limits.validate().length === 0,
            sent_reviews: this._sent_reviews.reduce((result, p) => result && typeof p === 'string', true),
            discounts: this._discounts.reduce((result, p) => result && p.validate().length === 0, true),
            ref_id: !this._ref_id ? true : typeof this._ref_id === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IProfile): Profile {
        return new Profile(props);
    }

    readonly keys: { [key: string]: string } = {
        balance: 'balance',
        clientId: 'client_id',
        clientLoginAvailable: 'client_login_available',
        discounts: 'discounts',
        email: 'email',
        newNotificationsCount: 'new_notifications_count',
        newsletterEnabled: 'newsletter_enabled',
        phone: 'phone',
        profileLimits: 'profile_limits',
        refId: 'ref_id',
        reseller: 'reseller',
        resellerEmail: 'reseller_email',
        resellerSectionHidden: 'reseller_section_hidden',
        sentReviews: 'sent_reviews',
        serverUpgradeRecommendationsEnabled: 'server_upgrade_recommendations_enabled',
        trialBonusActive: 'trial_bonus_active',
        trialBonusAvailable: 'trial_bonus_available',
        trialBonusLeftDays: 'trial_bonus_left_days',
        webTheme: 'web_theme',
        }
;

    mergeDeepWith(props: Partial<Profile>): Profile {
        const updateData: Partial<IProfile> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Profile) => {
            const updateKey = this.keys[key] as keyof IProfile;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IProfile, keyof IProfile>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Profile({ ...this.serialize(), ...updateData });
    }
}
