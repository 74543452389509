import React, { FC, useState, KeyboardEvent, useEffect, ChangeEvent } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Input } from 'antd';
import cn from 'classnames';

import { addError } from 'Actions/error';
import { createOrUpdatePtrRecord, deletePtrRecord, ReverseDnsActions } from 'Actions/reverseDns';
import { useIntl, ServerIndicator, Link, Icon, notifySuccess, Button, notifyError } from 'Common';
import { Store } from 'Store';
import DnsPtrRecord from 'Entities/DnsPtrRecord';
import ServerEntity from 'Entities/Server';
import Error from 'Entities/Error';
import OperationProgress from 'Entities/OperationProgress';
import { RoutePath } from 'Lib/helpers/routes';
import { hostValidate, getServerIcon } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import { DeletePtrRecord } from '../Modals';
import s from './ServerPtr.module.pcss';

interface ServerPtrProps {
    server: ServerEntity;
    ptr: DnsPtrRecord | undefined;
    ip: string;
    progress?: OperationProgress;
}
const ServerPtr: FC<ServerPtrProps> = ({ server, ip, ptr, progress }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [host, setHost] = useState(ptr?.host);
    const [hostError, setHostError] = useState(false);
    const [openPtrModal, setOpenPtrModal] = useState(false);

    useEffect(() => {
        if (ptr) {
            setHost(ptr.host);
        }
    }, [ptr]);

    const {
        distribution,
        state,
        name,
    } = server;

    const ptrHost = ptr?.host;

    const onSavePtr = () => {
        if (host && hostValidate(host)) {
            const { tenantId } = server;
            dispatch(createOrUpdatePtrRecord([tenantId, { ip_address: ip, host }], {
                result: () => {
                    notifySuccess(intl.getMessage('ptr_change'));
                },
                error: (error: Error) => {
                    if (error.fields?.host?.[0]) {
                        notifyError(intl.getMessage('prt_check_host_error'));
                    } else {
                        dispatch(addError({
                            type: ReverseDnsActions.CreateOrUpdatePtrRecord,
                            error,
                        }));
                    }
                },
            }));
            setHostError(false);
        } else {
            setHostError(true);
        }
    };

    const onDeletePtr = () => {
        setHost('');
        const { tenantId } = server;
        dispatch(deletePtrRecord([tenantId, { ip_address: ip }], {
            result: () => {
                notifySuccess(intl.getMessage('ptr_change'));
            },
        }));
    };

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
            case 'Enter': {
                onSavePtr();
                (e.target as HTMLInputElement).blur();
                break;
            }
            case 'Escape': {
                setHost(ptr?.host);
                (e.target as HTMLInputElement).blur();
                break;
            }
            default:
                break;
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setHostError(false);
        setHost(e.target.value);
    };

    return (
        <>
            <Link
                to={RoutePath.Server}
                props={{ serverId: server.id, tenantId: server.tenantId }}
                className={cn(
                    theme.card.card,
                    theme.card.list,
                    theme.card.link,
                    theme.card.with_form,
                    s.notification,
                )}
            >
                <div className={cn(theme.card.grid, theme.card.grid_ptr)}>
                    <div className={s.wrap}>
                        <ServerIndicator state={state} progress={progress} />
                        <Icon icon={getServerIcon(distribution.type)} className={s.icon} />
                        <span className={s.title}>
                            {name}
                        </span>
                    </div>

                    <div className={s.host}>
                        {ip}

                        <div className={s.form}>
                            <Input
                                size="large"
                                placeholder={intl.getMessage('host')}
                                className={cn(
                                    'input input_host',
                                    s.input,
                                    { input_error: hostError },
                                )}
                                value={host}
                                onChange={onChange}
                                onKeyDown={onKeyDown}
                                onClick={(e) => e.preventDefault()}
                            />
                            {host && ptrHost === host && (
                                <Button
                                    type="icon"
                                    icon="delete"
                                    className={cn(
                                        s.button,
                                        s.button_delete,
                                    )}
                                    onClick={(e) => {
                                        setOpenPtrModal(true);
                                        e.preventDefault();
                                    }}
                                    title={intl.getMessage('title_dns_record_delete')}
                                />
                            )}
                            {host && ptrHost !== host && (
                                <Button
                                    type="link"
                                    className={cn(
                                        s.button,
                                        s.button_save,
                                    )}
                                    onClick={(e) => {
                                        onSavePtr();
                                        e.preventDefault();
                                    }}
                                >
                                    {intl.getMessage('save')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
            {openPtrModal && (
                <DeletePtrRecord
                    visible={openPtrModal}
                    handleClose={() => setOpenPtrModal(false)}
                    onOk={onDeletePtr}
                />
            )}
        </>
    );
};

interface ServerPtrOwnProps {
    id: number;
}
interface ServerStoreProps {
    server: ServerEntity | undefined;
    ptr: DnsPtrRecord[];
    operationProgress: OperationProgress | undefined;
}

type ServerProps = ServerStoreProps & ServerPtrOwnProps;

const ServerPtrList: FC<ServerProps> = ({ server, ptr, operationProgress }) => {
    if (!server) {
        return null;
    }

    const {
        id: serverId,
        ipv4Addresses,
        ipv6Addresses,
    } = server;

    const ipList = [...ipv4Addresses, ...ipv6Addresses];

    return (
        <>
            {ipList.map((ip) => {
                const ipPtr = ptr.find((p) => p.ipAddress === ip.host && p.serverId === serverId);
                return (
                    <ServerPtr
                        key={ip.host}
                        ip={ip.host}
                        server={server}
                        ptr={ipPtr}
                        progress={operationProgress}
                    />
                );
            })}
        </>
    );
};

const serverSelect = (store: Store, op: ServerPtrOwnProps) => store.server.get(op.id);
const selectProgress = (store: Store, op: ServerPtrOwnProps) => store.operationProgress.commonOperations.get(op?.id);

const reverseDnsSelector = (store: Store, ownProps: ServerPtrOwnProps) => {
    const list: DnsPtrRecord[] = [];
    store.reverseDns?.forEach((item) => {
        if (item.serverId === ownProps.id) {
            list.push(item);
        }
    });
    return list;
};

const selector = createSelector([serverSelect, selectProgress, reverseDnsSelector], (
    server, operationProgress, ptr,
) => ({
    server, operationProgress, ptr,
}));

const mapStateToProps = (store: Store, ownProps: ServerPtrOwnProps) => ({
    ...selector(store, ownProps),
});

export default connect(mapStateToProps)(ServerPtrList);
