import { Action } from 'store/actions';
import { TenantActions, saveTenantsList, saveTenant, deleteTenantLocal } from 'Actions/tenant';
import Tenant from 'Entities/Tenant';

import { TenantStore, tenantInitialState as initialState } from '../initialStates';

const reducer = (state: TenantStore = initialState, action: Action): TenantStore => {
    switch (action.type as TenantActions) {
        case TenantActions.SaveTenantsList: {
            const { payload } = action as ReturnType<typeof saveTenantsList>;
            const tenants = new Map<number, Tenant>(state);
            payload.forEach((s) => {
                tenants.set(s.id, s);
            });
            return tenants;
        }
        case TenantActions.SaveTenant: {
            const { payload } = action as ReturnType<typeof saveTenant>;
            const tenants = new Map<number, Tenant>(state);
            tenants.set(payload.id, payload);
            return tenants;
        }
        case TenantActions.DeleteTenantLocal: {
            const { payload } = action as ReturnType<typeof deleteTenantLocal>;
            const tenants = new Map<number, Tenant>(state);
            tenants.delete(payload);
            return tenants;
        }
        default:
            return state;
    }
};

export default reducer;
