import React from 'react';

import theme from 'Lib/theme';

const blackText = (text: string) => (
    <span className={theme.color.black}>
        {text}
    </span>
);

export default blackText;
