import { takeEvery, put, call } from 'redux-saga/effects';

import DnsApi from 'Apis/dns';
import {
    DnsActions,
    saveDnsZones,
    getDnsZones as getDnsZonesAct,
    getDnsSettings as getDnsSettingsAct,
    createDnsZone as createDnsZoneAct,
    saveDnsZone,
    deleteDnsZone as deleteDnsZoneAct,
    deleteDnsZoneLocal,
    saveDnsSettings,
    getDnsRecords as getDnsRecordsAct,
    saveDnsRecords,
    createDnsRecords as createDnsRecordsAct,
    deleteDnsRecord as deleteDnsRecordAct,
    updateDnsRecord as updateDnsRecordAct,
    updateDnsZone as updateDnsZoneAct,
} from 'Actions/dns';
import DnsZone, { IDnsZone } from 'Entities/DnsZone';
import DnsSettings, { IDnsSettings } from 'Entities/DnsSettings';
import DnsRecord, { IDnsRecord } from 'Entities/DnsRecord';

import { errorChecker, addError, checkUnhandledError } from './utils';

function* getDnsZones(action: ReturnType<typeof getDnsZonesAct>) {
    const response: IDnsZone[] = yield call(DnsApi.getDnsZones);
    const { error, result } = errorChecker<IDnsZone[]>(response);
    if (result) {
        const dnsZoneEntities = result.map((s) => new DnsZone(s));
        yield put(saveDnsZones(dnsZoneEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createDnsZone(action: ReturnType<typeof createDnsZoneAct>) {
    const { payload, callback } = action;
    const response: IDnsZone = yield call(DnsApi.createDnsZone, ...payload);
    const { error, result } = errorChecker<IDnsZone>(response);
    if (result && callback?.result) {
        yield put(saveDnsZone(new DnsZone(result)));
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
        yield checkUnhandledError(error, action.type);
    }
}

function* deleteDnsZone(action: ReturnType<typeof deleteDnsZoneAct>) {
    const { payload } = action;
    const { dnsZoneId, tenantId } = payload;
    const response: number = yield call(DnsApi.deleteDnsZone, tenantId, dnsZoneId);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(deleteDnsZoneLocal(payload));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getDnsSettings(action: ReturnType<typeof getDnsSettingsAct>) {
    const response: IDnsSettings = yield call(DnsApi.getDnsSettings);
    const { error, result } = errorChecker<IDnsSettings>(response);
    if (result) {
        yield put(saveDnsSettings(new DnsSettings(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getDnsRecords(action: ReturnType<typeof getDnsRecordsAct>) {
    const { payload } = action;
    const response: IDnsRecord[] = yield call(DnsApi.getDnsRecords, ...payload);
    const { error, result } = errorChecker<IDnsRecord[]>(response);
    if (result) {
        const dnsRecordsEntities = response.map((s) => new DnsRecord(s));
        yield put(saveDnsRecords(dnsRecordsEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* createDnsRecords(action: ReturnType<typeof createDnsRecordsAct>) {
    const { payload, callback } = action;
    const response: IDnsRecord[] = yield call(DnsApi.createDnsRecords, ...payload);
    const { error, result } = errorChecker<IDnsRecord[]>(response);
    if (result && callback?.result) {
        const [tenantId, zoneId] = payload;
        callback?.result();
        yield put(getDnsRecordsAct([tenantId, zoneId]));
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* deleteDnsRecord(action: ReturnType<typeof deleteDnsRecordAct>) {
    const { payload } = action;
    const response: number = yield call(DnsApi.deleteDnsRecord, ...payload);
    const { error, result } = errorChecker<IDnsRecord[]>(response);
    if (result) {
        const [tenantId, zoneId] = payload;
        yield put(getDnsRecordsAct([tenantId, zoneId]));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateDnsRecord(action: ReturnType<typeof updateDnsRecordAct>) {
    const { payload, callback } = action;
    const response: number = yield call(DnsApi.updateDnsRecord, ...payload);
    const { error, result } = errorChecker<IDnsRecord[]>(response);
    if (result && callback?.result) {
        const [tenantId, zoneId] = payload;
        yield put(getDnsRecordsAct([tenantId, zoneId]));
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
        yield checkUnhandledError(error, action.type);
    }
}

function* updateDnsZone(action: ReturnType<typeof updateDnsZoneAct>) {
    const { payload, callback } = action;
    const response: number = yield call(DnsApi.updateDnsZone, ...payload);
    const { error, result } = errorChecker<IDnsZone[]>(response);
    if (result && callback?.result) {
        yield put(getDnsZonesAct());
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
        yield checkUnhandledError(error, action.type);
    }
}

function* dnsSaga(): Generator {
    yield takeEvery<ReturnType<typeof getDnsZonesAct>>(
        DnsActions.GetDnsZones, getDnsZones,
    );
    yield takeEvery<ReturnType<typeof createDnsZoneAct>>(
        DnsActions.CreateDnsZone, createDnsZone,
    );
    yield takeEvery<ReturnType<typeof deleteDnsZoneAct>>(
        DnsActions.DeleteDnsZone, deleteDnsZone,
    );
    yield takeEvery<ReturnType<typeof updateDnsZoneAct>>(
        DnsActions.UpdateDnsZone, updateDnsZone,
    );
    yield takeEvery<ReturnType<typeof getDnsSettingsAct>>(
        DnsActions.GetDnsSettings, getDnsSettings,
    );
    yield takeEvery<ReturnType<typeof getDnsRecordsAct>>(
        DnsActions.GetDnsRecords, getDnsRecords,
    );
    yield takeEvery<ReturnType<typeof createDnsRecordsAct>>(
        DnsActions.CreateDnsRecords, createDnsRecords,
    );
    yield takeEvery<ReturnType<typeof deleteDnsRecordAct>>(
        DnsActions.DeleteDnsRecord, deleteDnsRecord,
    );
    yield takeEvery<ReturnType<typeof updateDnsRecordAct>>(
        DnsActions.UpdateDnsRecord, updateDnsRecord,
    );
}

export default dnsSaga;
