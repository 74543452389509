import React, { FC } from 'react';
import { Affix, Col, Grid, Row } from 'antd';
import cn from 'classnames';

import { Icon, useIntl } from 'Common';
import { getHeaderHeight } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

import s from './Filters.module.pcss';

const { useBreakpoint } = Grid;

const InstallingResources: FC = () => {
    const intl = useIntl();
    const screens = useBreakpoint();
    const { headerHeight, headerHeightMobile } = getHeaderHeight();
    const currentOffset = screens.xl ? headerHeight : headerHeightMobile;

    return (
        <>
            <Affix offsetTop={currentOffset}>
                <div className={s.header}>
                    <div className={cn(s.title, s.title_single)}>
                        {intl.getMessage('server_resources')}
                    </div>
                </div>
            </Affix>
            <Row gutter={[48, 24]}>
                <Col span={24} lg={12}>
                    <div className={cn(theme.skeleton.block, theme.skeleton.resources)}>
                        <Icon icon="lock" className={theme.skeleton.icon} />
                    </div>
                </Col>
                {Array.from(Array(7).keys()).map((t) => (
                    <Col key={t} span={24} lg={12}>
                        <div className={cn(theme.skeleton.block, theme.skeleton.resources)}>
                            <Icon icon="lock" className={theme.skeleton.icon} />
                        </div>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default InstallingResources;
