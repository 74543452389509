// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum DnsRecordType {
    A = 'A',
    CNAME = 'CNAME',
    MX = 'MX',
    TXT = 'TXT',
    SRV = 'SRV',
    AAAA = 'AAAA'
}
