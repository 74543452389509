import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { deleteServer } from 'Actions/server';
import { useIntl, DeleteModalLayout, CodeInput } from 'Common';
import Server from 'Entities/Server';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { useSecurityActions } from 'Lib/hooks/hooks';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { notifySuccess, whiteText } from 'Common/ui';

export enum ModalRemoveType {
    IP,
    SERVER,
}

interface ModalRemoveProps {
    type: ModalRemoveType;
    visible: boolean;
    onClose: () => void;
    text?: string;
    server: Server;
}

const ModalRemove: FC<ModalRemoveProps> = ({ visible, onClose, type, text, server }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    let title = intl.getMessage('server_delete_modal_title');
    let buttonText = intl.getMessage('server_delete_modal_title');
    let desc = intl.getMessage('server_delete_modal_text', { text: text || '' });

    if (type === ModalRemoveType.IP) {
        title = intl.getMessage('server_ip_modal_title');
        buttonText = intl.getMessage('delete');
        desc = intl.getMessage('server_ip_modal_text');
    }

    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityActions(SecurityActionType.SERVER_DELETE);
    const handleOk = () => {
        const result = () => {
            onClose();
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.ServersList));
            notifySuccess(intl.getMessage('server_delete_successfull', { name: server.name, white: whiteText }));
            UserStorage.removeServerRange(server);
        };
        if (shouldConfirmAction && !codeSent) {
            sendConfirmationCode({
                tenantId: server.tenantId,
                serverId: server.id,
            });
        }
        if (!shouldConfirmAction) {
            dispatch(deleteServer({ server }, { result }));
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(deleteServer({ server, code }, {
                error: () => setCodeError(true),
                result,
            }));
        }
    };

    return (
        <DeleteModalLayout
            visible={visible}
            title={title}
            buttonText={buttonText}
            handleClose={onClose}
            handleDelete={handleOk}
        >
            <div className="modal__desc">
                {desc}
            </div>
            {(codeSent && deliveryMessage) && (
                <>
                    <div className="modal__desc modal__desc--gray">
                        {deliveryMessage}
                    </div>
                    <CodeInput
                        value={code}
                        setValue={(e) => setCode(e)}
                        codeError={codeError}
                        setCodeError={(e) => setCodeError(e)}
                        onSendAgain={() => sendConfirmationCode({
                            tenantId: server.tenantId,
                            serverId: server.id,
                        })}
                    />
                </>
            )}
        </DeleteModalLayout>
    );
};

export default ModalRemove;
