import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import OperationInfo, { IOperationInfo } from 'Entities/OperationInfo';
import ResellerClient, { IResellerClient } from 'Entities/ResellerClient';
import ResellerClientAdd, { IResellerClientAdd } from 'Entities/ResellerClientAdd';
import ResellerClientTransfer, { IResellerClientTransfer } from 'Entities/ResellerClientTransfer';
import ResellerClientUpdate, { IResellerClientUpdate } from 'Entities/ResellerClientUpdate';
import ResellerDetails, { IResellerDetails } from 'Entities/ResellerDetails';
import ResellerPayment, { IResellerPayment } from 'Entities/ResellerPayment';
import ResellerPaymentCreate, { IResellerPaymentCreate } from 'Entities/ResellerPaymentCreate';
import ResellerTransferCreate, { IResellerTransferCreate } from 'Entities/ResellerTransferCreate';
import ServerCloneAsReseller, { IServerCloneAsReseller } from 'Entities/ServerCloneAsReseller';
import Tenant, { ITenant } from 'Entities/Tenant';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class ResellersApi {
    static async addResellerClient(resellerclientadd: IResellerClientAdd, optionalToken?: string): Promise<IResellerClient | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const resellerclientaddValid = new ResellerClientAdd(resellerclientadd);
        haveError.push(...resellerclientaddValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/reseller/clients`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resellerclientaddValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async doResellerTransferPayment(resellertransfercreate: IResellerTransferCreate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const resellertransfercreateValid = new ResellerTransferCreate(resellertransfercreate);
        haveError.push(...resellertransfercreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/reseller/balance_transfer`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resellertransfercreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async doResellerWithdrawalPayment(resellerpaymentcreate: IResellerPaymentCreate, optionalToken?: string): Promise<IResellerPayment | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const resellerpaymentcreateValid = new ResellerPaymentCreate(resellerpaymentcreate);
        haveError.push(...resellerpaymentcreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/reseller/payments`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resellerpaymentcreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getResellerClientTenants(reseller_client_id: number, optionalToken?: string): Promise<ITenant[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/reseller/clients/${reseller_client_id}/tenants`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getResellerClients(optionalToken?: string): Promise<IResellerClient[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/reseller/clients`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getResellerCompletePayments(offset?: number, limit?: number, optionalToken?: string): Promise<IResellerPayment[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            offset: offset,
            limit: limit,
        }
        return await fetch(`/api/v1/reseller/payments/complete?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getResellerDetails(optionalToken?: string): Promise<IResellerDetails | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/reseller/details`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getResellerProcessingPayments(optionalToken?: string): Promise<IResellerPayment[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/reseller/payments/processing`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async resellerCloneServer(tenant_id: number, server_id: number, servercloneasreseller: IServerCloneAsReseller, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const servercloneasresellerValid = new ServerCloneAsReseller(servercloneasreseller);
        haveError.push(...servercloneasresellerValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/reseller_clone`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(servercloneasresellerValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async transferFromResellerToClient(reseller_client_id: number, resellerclienttransfer: IResellerClientTransfer, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const resellerclienttransferValid = new ResellerClientTransfer(resellerclienttransfer);
        haveError.push(...resellerclienttransferValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/reseller/clients/${reseller_client_id}/transfer`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resellerclienttransferValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateResellerClient(reseller_client_id: number, resellerclientupdate: IResellerClientUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const resellerclientupdateValid = new ResellerClientUpdate(resellerclientupdate);
        haveError.push(...resellerclientupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/reseller/clients/${reseller_client_id}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resellerclientupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
