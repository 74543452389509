import React, { FC } from 'react';

import { useIntl } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';

import ErrorLayout from './ErrorLayout';

const ErrorConfirmLink: FC = () => {
    const intl = useIntl();

    return (
        <ErrorLayout
            code={404}
            title={intl.getMessage('error_page_invalid_link')}
            desc={intl.getMessage('error_page_invalid_link_confirm')}
            link={RoutePath.Root}
            linkText={intl.getMessage('login_button')}
        />
    );
};

export default ErrorConfirmLink;
