import dayjs from 'dayjs';
import { Action } from 'store/actions';
import { LocaleActions, setLocale } from 'Actions/locale';
import { DEFAULT_LOCALE, Locale } from 'Lib/intl';

import { LocaleStore, localesInitialState as initialState } from '../initialStates';

dayjs.locale(DEFAULT_LOCALE);

const reducer = (
    state: LocaleStore = initialState,
    action: Action,
): LocaleStore => {
    switch (action.type as LocaleActions) {
        case LocaleActions.SetLocale: {
            const { payload } = action as ReturnType<typeof setLocale>;
            const newLang = Object.keys(Locale).find((k) => k === payload);
            if (newLang) {
                return state.updateTranslator(payload);
            }
            return state;
        }
        default:
            return state;
    }
};

export default reducer;
