import Tenant from 'Entities/Tenant';
import Server from 'Entities/Server';
import tenantApi from 'Apis/tenants';
import { actionCreator } from '.';

export enum TenantActions {
    GetTenant = 'GetTenant',
    SaveTenant = 'SaveTenant',
    GetTenantsList = 'GetTenantsList',
    SaveTenantsList = 'SaveTenantsList',
    AddUserToTenant = 'AddUserToTenant',
    RemoveUserFromTenant = 'RemoveUserFromTenant',
    UpdateBackupPolicy = 'UpdateBackupPolicy',
    UpdateTenantDescription = 'UpdateTenantDescription',
    DeleteTenant = 'DeleteTenant',
    DeleteTenantLocal = 'DeleteTenantLocal',
    CreateTenant = 'CreateTenant',
    CreateTenantWithServerUpdate = 'CreateTenantWithServerUpdate',
}

const getTenantsList = actionCreator(TenantActions.GetTenantsList);
const saveTenantsList = actionCreator<Tenant[]>(TenantActions.SaveTenantsList);

type GetTenant = Parameters<typeof tenantApi.getTenant>;
const getTenant = actionCreator<GetTenant>(TenantActions.GetTenant);
const saveTenant = actionCreator<Tenant>(TenantActions.SaveTenant);

type AddUserToTenant = Parameters<typeof tenantApi.addUserToTenant>;
const addUserToTenant = actionCreator<AddUserToTenant>(TenantActions.AddUserToTenant);

interface RemoveUserFromTenant {
    parameters: Parameters<(typeof tenantApi.deleteUserFromTenant)>;
    leave?: boolean;
}
const removeUserFromTenant = actionCreator<RemoveUserFromTenant>(
    TenantActions.RemoveUserFromTenant,
);
type UpdateBackupPolicy = Parameters<(typeof tenantApi.updateBackupPolicyForTenant)>;
const updateBackupPolicy = actionCreator<UpdateBackupPolicy>(TenantActions.UpdateBackupPolicy);

type UpdateTenantDescription = Parameters<(typeof tenantApi.updateTenant)>;
const updateTenantDescription = actionCreator<UpdateTenantDescription>(
    TenantActions.UpdateTenantDescription,
);

const deleteTenant = actionCreator<number>(TenantActions.DeleteTenant);
const deleteTenantLocal = actionCreator<number>(TenantActions.DeleteTenantLocal);

type CreateTenant = Parameters<typeof tenantApi.createTenant>;
const createTenant = actionCreator<CreateTenant>(TenantActions.CreateTenant);

type CreateTenantWithServerUpdate = {
    newTenant: Parameters<typeof tenantApi.createTenant>;
    goToServer: (tenantId: number) => void;
    server: Server;
};
const createTenantWithServerUpdate = actionCreator<CreateTenantWithServerUpdate>(
    TenantActions.CreateTenantWithServerUpdate,
);

export {
    getTenant,
    saveTenant,
    getTenantsList,
    saveTenantsList,
    addUserToTenant,
    removeUserFromTenant,
    updateBackupPolicy,
    updateTenantDescription,
    deleteTenant,
    deleteTenantLocal,
    createTenant,
    createTenantWithServerUpdate,
};
