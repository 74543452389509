import PhoneInfoUpdate, { IPhoneInfoUpdate } from './PhoneInfoUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPartnerRegistration {
    email: string;
    first_name?: string;
    last_name?: string;
    password?: string;
    phone?: IPhoneInfoUpdate;
}

export default class PartnerRegistration {
    readonly _email: string;

    /**
     * Description: Partner's email
     * Example: partner@smail.com
     */
    get email(): string {
        return this._email;
    }

    static emailValidate(email: string): boolean {
        return typeof email === 'string' && !!email.trim();
    }

    readonly _first_name: string | undefined;

    /**
     * Description: Partner's first name (deprecated)
     * Example: Nikolay
     */
    get firstName(): string | undefined {
        return this._first_name;
    }

    readonly _last_name: string | undefined;

    /**
     * Description: Partner's last name (deprecated)
     * Example: Karamzin
     */
    get lastName(): string | undefined {
        return this._last_name;
    }

    readonly _password: string | undefined;

    /**
     * Description: Partner's password (deprecated)
     * Example: qwerty123
     */
    get password(): string | undefined {
        return this._password;
    }

    readonly _phone: PhoneInfoUpdate | undefined;

    get phone(): PhoneInfoUpdate | undefined {
        return this._phone;
    }

    constructor(props: IPartnerRegistration) {
        this._email = props.email.trim();
        if (typeof props.first_name === 'string') {
            this._first_name = props.first_name.trim();
        }
        if (typeof props.last_name === 'string') {
            this._last_name = props.last_name.trim();
        }
        if (typeof props.password === 'string') {
            this._password = props.password.trim();
        }
        if (props.phone) {
            this._phone = new PhoneInfoUpdate(props.phone);
        }
    }

    serialize(): IPartnerRegistration {
        const data: IPartnerRegistration = {
            email: this._email,
        };
        if (typeof this._first_name !== 'undefined') {
            data.first_name = this._first_name;
        }
        if (typeof this._last_name !== 'undefined') {
            data.last_name = this._last_name;
        }
        if (typeof this._password !== 'undefined') {
            data.password = this._password;
        }
        if (typeof this._phone !== 'undefined') {
            data.phone = this._phone.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            email: typeof this._email === 'string' && !!this._email.trim(),
            password: !this._password ? true : typeof this._password === 'string',
            phone: !this._phone ? true : this._phone.validate().length === 0,
            first_name: !this._first_name ? true : typeof this._first_name === 'string',
            last_name: !this._last_name ? true : typeof this._last_name === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPartnerRegistration): PartnerRegistration {
        return new PartnerRegistration(props);
    }

    readonly keys: { [key: string]: string } = {
        email: 'email',
        firstName: 'first_name',
        lastName: 'last_name',
        password: 'password',
        phone: 'phone',
        }
;

    mergeDeepWith(props: Partial<PartnerRegistration>): PartnerRegistration {
        const updateData: Partial<IPartnerRegistration> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PartnerRegistration) => {
            const updateKey = this.keys[key] as keyof IPartnerRegistration;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPartnerRegistration, keyof IPartnerRegistration>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PartnerRegistration({ ...this.serialize(), ...updateData });
    }
}
