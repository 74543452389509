import BackupPolicy, { IBackupPolicy } from './BackupPolicy';
import ServerResourceLimits, { IServerResourceLimits } from './ServerResourceLimits';
import TariffPrice, { ITariffPrice } from './TariffPrice';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITariff {
    backup_policy: IBackupPolicy;
    cpu_cores: number;
    disk_gib: number;
    fixed: boolean;
    included_ip_count: number;
    included_traffic_gib: number;
    limits: IServerResourceLimits;
    memory_mib: number;
    name: string;
    prices: ITariffPrice;
    tariff_id: number;
    windows_only: boolean;
}

export default class Tariff {
    readonly _backup_policy: BackupPolicy;

    get backupPolicy(): BackupPolicy {
        return this._backup_policy;
    }

    readonly _cpu_cores: number;

    /**
     * Description: Cpu cores. For non-fixed tariffs set by user
     * Example: 2
     */
    get cpuCores(): number {
        return this._cpu_cores;
    }

    static cpuCoresValidate(cpuCores: number): boolean {
        return typeof cpuCores === 'number';
    }

    readonly _disk_gib: number;

    /**
     * Description: Disk capacity in Gib. For non-fixed tariffs set by user
     * Example: 16
     */
    get diskGib(): number {
        return this._disk_gib;
    }

    static diskGibValidate(diskGib: number): boolean {
        return typeof diskGib === 'number';
    }

    readonly _fixed: boolean;

    /** */
    get fixed(): boolean {
        return this._fixed;
    }

    static fixedValidate(fixed: boolean): boolean {
        return typeof fixed === 'boolean';
    }

    readonly _included_ip_count: number;

    /**
     * Description: IP address included by default. For non-fixed tariffs set by user
     * Example: 1
     */
    get includedIpCount(): number {
        return this._included_ip_count;
    }

    static includedIpCountValidate(includedIpCount: number): boolean {
        return typeof includedIpCount === 'number';
    }

    readonly _included_traffic_gib: number;

    /**
     * Description: Amount of free traffic included in tariff
     * Example: 30
     */
    get includedTrafficGib(): number {
        return this._included_traffic_gib;
    }

    static includedTrafficGibValidate(includedTrafficGib: number): boolean {
        return typeof includedTrafficGib === 'number';
    }

    readonly _limits: ServerResourceLimits;

    get limits(): ServerResourceLimits {
        return this._limits;
    }

    readonly _memory_mib: number;

    /**
     * Description: Memory in Mib. For non-fixed tariffs set by user
     * Example: 1024
     */
    get memoryMib(): number {
        return this._memory_mib;
    }

    static memoryMibValidate(memoryMib: number): boolean {
        return typeof memoryMib === 'number';
    }

    readonly _name: string;

    /**
     * Description: Name
     * Example: Cloud 1024
     */
    get name(): string {
        return this._name;
    }

    static nameValidate(name: string): boolean {
        return typeof name === 'string' && !!name.trim();
    }

    readonly _prices: TariffPrice;

    get prices(): TariffPrice {
        return this._prices;
    }

    readonly _tariff_id: number;

    /**
     * Description: Unique identifier
     * Example: 2
     */
    get tariffId(): number {
        return this._tariff_id;
    }

    static tariffIdValidate(tariffId: number): boolean {
        return typeof tariffId === 'number';
    }

    readonly _windows_only: boolean;

    /** */
    get windowsOnly(): boolean {
        return this._windows_only;
    }

    static windowsOnlyValidate(windowsOnly: boolean): boolean {
        return typeof windowsOnly === 'boolean';
    }

    constructor(props: ITariff) {
        this._backup_policy = new BackupPolicy(props.backup_policy);
        this._cpu_cores = props.cpu_cores;
        this._disk_gib = props.disk_gib;
        this._fixed = props.fixed;
        this._included_ip_count = props.included_ip_count;
        this._included_traffic_gib = props.included_traffic_gib;
        this._limits = new ServerResourceLimits(props.limits);
        this._memory_mib = props.memory_mib;
        this._name = props.name.trim();
        this._prices = new TariffPrice(props.prices);
        this._tariff_id = props.tariff_id;
        this._windows_only = props.windows_only;
    }

    serialize(): ITariff {
        const data: ITariff = {
            backup_policy: this._backup_policy.serialize(),
            cpu_cores: this._cpu_cores,
            disk_gib: this._disk_gib,
            fixed: this._fixed,
            included_ip_count: this._included_ip_count,
            included_traffic_gib: this._included_traffic_gib,
            limits: this._limits.serialize(),
            memory_mib: this._memory_mib,
            name: this._name,
            prices: this._prices.serialize(),
            tariff_id: this._tariff_id,
            windows_only: this._windows_only,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            tariff_id: typeof this._tariff_id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            memory_mib: typeof this._memory_mib === 'number',
            disk_gib: typeof this._disk_gib === 'number',
            cpu_cores: typeof this._cpu_cores === 'number',
            included_ip_count: typeof this._included_ip_count === 'number',
            included_traffic_gib: typeof this._included_traffic_gib === 'number',
            backup_policy: this._backup_policy.validate().length === 0,
            fixed: typeof this._fixed === 'boolean',
            windows_only: typeof this._windows_only === 'boolean',
            prices: this._prices.validate().length === 0,
            limits: this._limits.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITariff): Tariff {
        return new Tariff(props);
    }

    readonly keys: { [key: string]: string } = {
        backupPolicy: 'backup_policy',
        cpuCores: 'cpu_cores',
        diskGib: 'disk_gib',
        fixed: 'fixed',
        includedIpCount: 'included_ip_count',
        includedTrafficGib: 'included_traffic_gib',
        limits: 'limits',
        memoryMib: 'memory_mib',
        name: 'name',
        prices: 'prices',
        tariffId: 'tariff_id',
        windowsOnly: 'windows_only',
        }
;

    mergeDeepWith(props: Partial<Tariff>): Tariff {
        const updateData: Partial<ITariff> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Tariff) => {
            const updateKey = this.keys[key] as keyof ITariff;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITariff, keyof ITariff>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Tariff({ ...this.serialize(), ...updateData });
    }
}
