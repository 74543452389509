import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Row, Col } from 'antd';

import {
    InnerPageLayout,
    LoaderPageLayout,
    notifySuccess,
    ServerNotExist,
    useIntl,
    Link,
    Icon,
} from 'Common';
import { useServerInProgress, useTitle, useServerNotExist, useServerSearchSave } from 'Hooks';
import { updateConfiguration } from 'Actions/server';
import ServerConfigurationUpdate from 'Entities/ServerConfigurationUpdate';
import { DistributionFamily } from 'Entities/DistributionFamily';
import Server from 'Entities/Server';
import { Store } from 'Store';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import { Header, ChangeTariff } from './components';
import s from './ServerTariff.module.pcss';

interface ServerTariffStoreProps {
    server: Server | undefined;
}

type ServerTariffOwnProps = RouteComponentProps<{ serverId: string }>;

type ServerTariffProps = ServerTariffOwnProps & ServerTariffStoreProps;

const ServerTariff: FC<ServerTariffProps> = ({
    server, match: { params: { serverId } },
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const pageTitle = server?.name
        ? intl.getMessage('server_tariff_page_title', { value: server.name })
        : intl.getMessage('page_title');
    useTitle(pageTitle);
    const isProgress = useServerInProgress(Number(serverId));

    const noServer = useServerNotExist();

    useServerSearchSave(Number(serverId), noServer);

    if (noServer) {
        return <ServerNotExist />;
    }

    const {
        tariff,
    } = server!;

    const onChangeTariff = (newTariff: ServerConfigurationUpdate) => {
        dispatch(updateConfiguration(
            { server: server!, serverConfigurationUpdate: newTariff },
            { result: () => {
                const title = tariff?.tariffId === newTariff.tariffId || !newTariff.tariffId
                    ? intl.getMessage('server_configuration_changed')
                    : intl.getMessage('server_tariff_changed');
                notifySuccess(title);
            } },
        ));
        window.scrollTo({ top: 0 });
    };

    const support = (text: string) => (
        <Link to={RoutePath.CreateTicket}>{text}</Link>
    );

    const getContent = () => {
        if (!server || isProgress) {
            return <LoaderPageLayout />;
        }

        return (
            <Row gutter={[24, 0]}>
                <Col span={24} md={16}>
                    <ChangeTariff
                        handleSubmit={onChangeTariff}
                        server={server}
                        currentTariff={tariff}
                    />
                </Col>
                <Col span={24} md={8}>
                    <div className={s.notice}>
                        <Icon icon="attention" className={s.attention} />
                        <div className={s.paragraph}>
                            {intl.getMessage('server_change_tariff_disk', { support })}
                        </div>
                        <div className={s.paragraph}>
                            {intl.getMessage('server_change_tariff_memory')}
                        </div>
                        {server.distribution.type === DistributionFamily.WINDOWS && (
                            <div className={s.paragraph}>
                                {intl.getMessage('server_change_tariff_windows', { support })}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        );
    };

    return (
        <InnerPageLayout
            header={<Header server={server!} />}
            className={theme.content.with_actions}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

const selectServer = (store: Store, ownProps: ServerTariffOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return store.server?.get(Number(serverId));
};
const selector = createSelector([selectServer], (server) => server);
const mapStoreToProps = (store: Store, ownProps: ServerTariffOwnProps) => ({
    server: selector(store, ownProps),
});
export default withRouter(connect(mapStoreToProps)(ServerTariff));
