import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { getDnsZones } from 'Actions/dns';
import DnsApi from 'Apis/dns';
import { CommonModalLayout, useIntl, Button, Loader, Icon, mediumText, notifyError, Select, Option } from 'Common';
import DnsZoneImportResult, { IDnsZoneImportResult } from 'Entities/DnsZoneImportResult';
import DnsRecordCreate from 'Entities/DnsRecordCreate';
import { IDnsRecord } from 'Entities/DnsRecord';
import { IDnsZone } from 'Entities/DnsZone';
import theme from 'Lib/theme';
import { errorChecker } from 'Lib/helpers/utils';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { Store } from 'Store';

import s from './ModalImportZone.module.pcss';

const selectTenants = (store: Store) => store.tenant;
const selectZones = (store: Store) => store.dns.zones;
const selector = createSelector(
    [selectZones, selectTenants],
    (zones, tenants) => ({ zones, tenants }),
);

interface ModalImportZoneOwnProps {
    visible: boolean;
    handleClose: () => void;
}

const ModalImportZone: FC<ModalImportZoneOwnProps> = ({
    visible, handleClose,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { zones, tenants } = useSelector(selector);

    const [file, setFile] = useState<File>();
    const [zoneImport, setZoneImport] = useState<DnsZoneImportResult>();
    const [newTenantId, setNewTenantId] = useState<number>();

    const onFileAdd = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.[0]) {
            const [newFile] = e.target.files;
            setFile(newFile);

            const data = new FormData();
            data.set('file', newFile);
            const response = await DnsApi.importDnsRecords(data);

            const { result, error } = errorChecker<IDnsZoneImportResult>(response);
            if (result) {
                if (!result.zone_id) {
                    setNewTenantId(tenants.values().next().value.id);
                }
                if (result.records.length === 0) {
                    notifyError(intl.getMessage('dns_zone_import_zero_records_error'), undefined, { duration: 0 });
                }
                setZoneImport(new DnsZoneImportResult(result));
            }
            if (error) {
                setFile(undefined);
                notifyError(apiErrorCodeTranslate(intl, error.errorCode));
            }
        }
    };
    const handleSubmit = async () => {
        if (!zoneImport) {
            return;
        }
        const newRecords = zoneImport.records.map((r) => {
            return new DnsRecordCreate(r.serialize()).serialize();
        });
        // If zone exist - rewrite records
        if (zoneImport?.zoneId) {
            const { tenantId, id } = zones?.get(zoneImport.zoneId)!;
            const response = await DnsApi.createDnsRecords(tenantId, id, {
                records: newRecords,
                rewrite: true,
            });
            const { error } = errorChecker<IDnsRecord[]>(response);
            if (error) {
                notifyError(apiErrorCodeTranslate(intl, error.errorCode));
            } else {
                dispatch(getDnsZones());
                handleClose();
            }
            return;
        }
        // Create zone and add records there
        const newZoneResponse = await DnsApi.createDnsZone(newTenantId!, {
            host: zoneImport.host,
        });
        const { result: newZone, error: zoneError } = errorChecker<IDnsZone>(newZoneResponse);
        if (zoneError) {
            notifyError(apiErrorCodeTranslate(intl, zoneError.errorCode));
            return;
        }
        const { id, tenant_id: tenantId } = newZone!;
        const response = await DnsApi.createDnsRecords(tenantId, id, {
            records: newRecords,
            rewrite: false,
        });
        const { result, error } = errorChecker<IDnsRecord[]>(response);
        if (error) {
            notifyError(apiErrorCodeTranslate(intl, error.errorCode));
            handleClose();
        }
        if (result) {
            dispatch(getDnsZones());
            handleClose();
        }
    };

    const tenantsArray = Array.from(tenants.values());

    return (
        <CommonModalLayout
            centered
            visible={visible}
            noFooter={!file || !zoneImport}
            handleClose={handleClose}
            buttonText={zoneImport?.zoneId
                ? intl.getMessage('dns_zone_rewrite')
                : intl.getMessage('dns_zone_add')}
            handleSubmit={handleSubmit}
            disabled={zoneImport?.records.length === 0}
            title={intl.getMessage('dns_zone_import')}
        >
            {!file && (
                <>
                    <label htmlFor="attachedFiles" className={s.label}>
                        <Button
                            type="border"
                            className={cn(
                                theme.button.attach,
                                theme.button.attach_big,
                                s.attach,
                            )}
                            icon="link"
                            iconClassName={s.attachIcon}
                            title={intl.getMessage('title_file_add')}
                        >
                            <div>
                                <div className={s.attachTitle}>
                                    {intl.getMessage('file_select')}
                                </div>
                                <div className={s.attachDesc}>
                                    {intl.getMessage('dns_zone_import_file_req')}
                                </div>
                            </div>
                        </Button>
                        <input
                            id="attachedFiles"
                            type="file"
                            className={s.input}
                            onChange={onFileAdd}
                            title={intl.getMessage('title_file_add')}
                        />
                    </label>
                    <div className={s.importDesc}>
                        {intl.getMessage('dns_zone_import_desc')}
                    </div>
                </>
            )}
            {(file && !zoneImport) && (
                <div>
                    <Loader circle />
                </div>
            )}
            {(file && zoneImport) && (
                <div>
                    <div className={cn(s.file, { [s.file_yellow]: !!zoneImport.zoneId })}>
                        <Icon icon="file" />
                        <div className={s.fileDesc}>
                            <div className={s.fileName}>{file.name}</div>
                            <div className={s.fileZone}>
                                <div className={theme.dotList.item}>
                                    <div className={s.fileHost}>
                                        {zoneImport.host}
                                    </div>
                                </div>
                                <div className={theme.dotList.item}>
                                    {intl.getPlural('dns_zone_records_count_zero', zoneImport.records.length)}
                                </div>
                            </div>
                        </div>
                        <Button
                            icon="delete"
                            type="icon"
                            onClick={() => {
                                setFile(undefined);
                                setZoneImport(undefined);
                            }}
                        />
                    </div>
                    {!zoneImport.zoneId && zoneImport.records.length > 0 && (
                        <div>
                            <div className={s.selectLabel}>
                                {intl.getMessage('dns_zone_import_new_tenant')}
                            </div>
                            <Select
                                size="big"
                                block
                                value={newTenantId}
                                onChange={(v) => setNewTenantId(v)}
                                className={s.select}
                            >
                                {tenantsArray.map((t) => (
                                    <Option key={t.id} value={t.id}>{t.description}</Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    {zoneImport.records.length > 0 && (
                        <div className={s.zoneDesc}>
                            {zoneImport.zoneId
                                ? intl.getMessage('dns_zone_import_to_exist', {
                                    b: mediumText,
                                    host: zoneImport.host,
                                })
                                : intl.getMessage('dns_zone_import_new', {
                                    b: mediumText,
                                    host: zoneImport.host,
                                    records: intl.getPlural('dns_zone_records_count_zero', zoneImport.records.length),
                                })}
                        </div>
                    )}
                </div>
            )}
        </CommonModalLayout>
    );
};

export default ModalImportZone;
