import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { BackupPolicyFrequency } from 'Entities/BackupPolicyFrequency';
import { DistributionFamily } from 'Entities/DistributionFamily';

export const CLOUDPAYMENTS_SRC = 'https://widget.cloudpayments.ru/bundles/cloudpayments';
export const SOLAR_STAFF_OFFER = 'https://my.solarstaff.com/docs/Subcontractor_Offer_Agreement(HK_Russia)_07.12.2023.pdf';
export const UNAUTHORIZED = 'UNAUTHORIZED' as ApiErrorCode;

export const REQUEST_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const COMPARE_TIME_FORMAT = 'YYYY-MM-DD';

export const currencySymbol = {
    RUB: '₽',
};

export const MIN_ROUND_VALUE = 0.01;

export const SUPPORT_MAIL = 'support@serveroid.com';
export const SUPPORT_PHONE = '8 800 775-02-36';

export const EMPTY_FIELD_ERROR = 'EMPTY_FIELD_ERROR';
export const AT_LEAST_ONE_REQUIRED = 'AT_LEAST_ONE_REQUIRED';

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssz';

export const REGULAR_CHARACTER_LIMIT = 70;

export const TRIAL_MAX_SERVERS_COUNT = 3;
export const TRIAL_TOTAL_CAPACITY = 8;
export const TRIAL_AMOUNT = 500;
export const REFERRAL_DISCOUNT = 15;

export const SOCIAL_PARAM_NAME = 'exchange_code';
export const SOCIAL_ERROR_NAME = 'error_code';

export const COMMON_HEADER_HEIGHT = 130;

export const SIDEBAR_WIDTH = 192;

export const DEFAULT_NOTIFICATION_DURATION = 5;

export const REF_ID_COOKIE = 'refidcookie';

export const BONUS_AMOUNT_PERCENT = 15;

export enum SERVER_VIEW {
    LIST = 'LIST',
    COMPACT = 'COMPACT',
    EXPAND = 'EXPAND',
}

export enum TICKETS_STATE {
    OPEN = 'open',
    CLOSED = 'closed',
}

export enum TICKETS_SUBJECT {
    GENERAL_QUESTIONS = 'GENERAL_QUESTIONS',
    EMAIL_EDIT = 'EMAIL_EDIT',
    DELETE_CLIENT = 'DELETE_CLIENT',
    EDIT_PROFILE = 'EDIT_PROFILE',
    OPEN_STUDIO_ACCESS = 'OPEN_STUDIO_ACCESS',
}

export enum NOTIFICATIONS_STATE {
    UNREAD = 'unread',
    HISTORY = 'history',
}

export enum TTLValuesEnum {
    MINUTE_2,
    MINUTE_5,
    MINUTE_10,
    MINUTE_15,
    MINUTE_30,
    HOUR_1,
    HOUR_2,
    HOUR_6,
    HOUR_12,
    DAY_1,
}
export const TTLValuesDict: Record<TTLValuesEnum, number> = {
    [TTLValuesEnum.MINUTE_2]: 120,
    [TTLValuesEnum.MINUTE_5]: 300,
    [TTLValuesEnum.MINUTE_10]: 600,
    [TTLValuesEnum.MINUTE_15]: 900,
    [TTLValuesEnum.MINUTE_30]: 1800,
    [TTLValuesEnum.HOUR_1]: 3600,
    [TTLValuesEnum.HOUR_2]: 7200,
    [TTLValuesEnum.HOUR_6]: 21600,
    [TTLValuesEnum.HOUR_12]: 43200,
    [TTLValuesEnum.DAY_1]: 86400,
};
export const TTL_VALUE = Object.values(TTLValuesDict).map((value) => value);
export enum TTL_CUSTOM_VALUES {
    AUTO = 'AUTO',
    OTHER = 'OTHER',
}

export const SUPPORT_MAX_FILES = 5;
export const SUPPORT_MAX_FILE_SIZE_MB = 15;

export const CHAT_ID = 'chat';

export enum SERVER_QUERY_PARAMS {
    CPU = 'cpu',
    MEMORY = 'memory',
    DISK = 'disk',
    IP = 'ip',
    WINDOWS = 'windows',
    FIXED = 'fixed',
}

export const LOADER_PAGE_TIMEOUT = 600;

export const COPY_BUTTON_TIMEOUT = 2000;

export const REVIEW_SUBJECT = 'REVIEW';
export enum REVIEWS_KEYS {
    MOVE_FROM_FLOPS = 'MOVE_FROM_FLOPS',
    REFERRER_REG_DISCOUNT = 'REFERRER_REG_DISCOUNT',
}

export const BLOCK_SIZES: Record<SERVER_VIEW, number> = {
    [SERVER_VIEW.LIST]: 60,
    [SERVER_VIEW.EXPAND]: 250,
    [SERVER_VIEW.COMPACT]: 30,
};

export const NEW_USER_NOTICES_LIST = {
    SERVERS: 'servers',
    PROJECTS: 'projects',
    PARTNER: 'partner',
    NOTICES: 'notices',
    ACCOUNT: 'account',
};

export const SERVER_SEARCH_MAX_HISTORY = 4;

export const SERVER_PROCESSOR_NAME = 'Intel Xeon E5-2620';

export const SERVER_LOGIN = 'root';

export enum SEARCH_TYPE {
    STRICT = 'strict',
    FUZZY = 'fuzzy',
}

export const TRIGGER_TIME_VARIANTS = [5, 10, 15, 20, 25, 30];

export const BATCH_PUBLIC_KEYS_NOTIFICATION_ID = 'batchAddPublicKeys';

export const SERVER_SELECT_SIZE = {
    WIDTH_MOBILE: 320,
    HEIGHT_MOBILE: 320,
    WIDTH_DESKTOP: 432,
    HEIGHT_DESKTOP: 436,
    ITEM_HEIGHT: 48,
    TENANT_GROUP_HEIGHT: 112,
    ALL_GROUP_HEIGHT: 168,
};

export enum RESOURCES_TYPES {
    EXPENSES = 'EXPENSES',
    PROCESSOR = 'PROCESSOR',
    MEMORY = 'MEMORY',
    MEMORY_GUEST = 'MEMORY_GUEST',
    EXTERNAL_NETWORK = 'EXTERNAL_NETWORK',
    LOCAL_AREA_NETWORK = 'LOCAL_AREA_NETWORK',
    DISK_OPERATION_MB = 'DISK_OPERATION_MB',
    DISK_OPERATION_OP = 'DISK_OPERATION_OP',
    DISK_SPACE = 'DISK_SPACE',
    AVERAGE_LOAD = 'AVERAGE_LOAD',
    PARTNER_HITS = 'PARTNER_HITS',
    PARTNER_UNIQUE = 'PARTNER_UNIQUE',
    PARTNER_REGISTRATIONS = 'PARTNER_REGISTRATIONS',
    PARTNER_AMOUNT = 'PARTNER_AMOUNT',
}

export const RESOURCES_MAX_RANGE_VALUE = 100;

export enum DATE_RANGE_TYPES {
    TODAY = 'TODAY',
    HOUR_3 = 'HOUR_3',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    QUARTER = 'QUARTER',
    YEAR = 'YEAR',
}

export const ADJUST_NUMBER = {
    ZERO: 0,
    TEN: 10,
};

export enum CHART_RANGE_MEAN {
    CPU = 'CPU',
    PUBLIC_NETWORK = 'PUBLIC_NETWORK',
}

export enum ACT_RANGE_TYPE {
    QUARTER_1 = 'QUARTER_1',
    QUARTER_2 = 'QUARTER_2',
    QUARTER_3 = 'QUARTER_3',
    QUARTER_4 = 'QUARTER_4',
    HALF_YEAR_1 = 'HALF_YEAR_1',
    HALF_YEAR_2 = 'HALF_YEAR_2',
    YEAR = 'YEAR',
}

export const LOCAL_STORAGE_COOKIE_NAME = '_evchxxuej9802';

export enum SERVER_OPERATION_NOTIFICATION {
    REBOOT = 'rebootNotification',
    RESET = 'resetNotification',
    SHUTDOWN = 'shutdownNotification',
    DESTROY = 'destroyNotification',
}

export interface IServerStorage {
    tenant_id: number;
    tariff_id: number;
    send_password: boolean;
    backup_frequency: BackupPolicyFrequency;
    backup_quantity: number;
    cpu_cores: number;
    disk_gib: number;
    distribution_id: number;
    distribution_name: DistributionFamily;
    ip_count: number;
    memory_mib: number;
    open_support_access: boolean;
}

export interface IBatchErrorServer {
    id: number;
    name: string;
}

export const TRANSITION_TIME = 300;

export const ONE_BYTE = 8; // 8 Bit;

/* eslint-disable quote-props */
export const charsMapRuEn = {
    'Ё': '~',
    'ё': '`',
    '"': '@',
    '№': '#',
    'й': 'q',
    'ц': 'w',
    'у': 'e',
    'к': 'r',
    'е': 't',
    'н': 'y',
    'г': 'u',
    'ш': 'i',
    'щ': 'o',
    'з': 'p',
    'ф': 'a',
    'ы': 's',
    'в': 'd',
    'а': 'f',
    'п': 'g',
    'р': 'h',
    'о': 'j',
    'л': 'k',
    'д': 'l',
    'я': 'z',
    'ч': 'x',
    'с': 'c',
    'м': 'v',
    'и': 'b',
    'т': 'n',
    'ь': 'm',
    'Й': 'Q',
    'Ц': 'W',
    'У': 'E',
    'К': 'R',
    'Е': 'T',
    'Н': 'Y',
    'Г': 'U',
    'Ш': 'I',
    'Щ': 'O',
    'З': 'P',
    'Ф': 'A',
    'Ы': 'S',
    'В': 'D',
    'А': 'F',
    'П': 'G',
    'Р': 'H',
    'О': 'J',
    'Л': 'K',
    'Д': 'L',
    'Я': 'Z',
    'Ч': 'X',
    'С': 'C',
    'М': 'V',
    'И': 'B',
    'Т': 'N',
    'Ь': 'M',
    'х': '[',
    'Х': '{',
    'ъ': ']',
    'Ъ': '}',
    'ж': ';',
    'Ж': ':',
    'э': '\'',
    'Э': '"',
    'б': ',',
    'Б': '<',
    'ю': '.',
    'Ю': '>',
    '?': '&',
    '.': '/',
};

export const charsMapEnRu = {
    '~': 'Ё',
    '`': 'ё',
    '@': '"',
    '#': '№',
    'q': 'й',
    'w': 'ц',
    'e': 'у',
    'r': 'к',
    't': 'е',
    'y': 'н',
    'u': 'г',
    'i': 'ш',
    'o': 'щ',
    'p': 'з',
    'a': 'ф',
    's': 'ы',
    'd': 'в',
    'f': 'а',
    'g': 'п',
    'h': 'р',
    'j': 'о',
    'k': 'л',
    'l': 'д',
    'z': 'я',
    'x': 'ч',
    'c': 'с',
    'v': 'м',
    'b': 'и',
    'n': 'т',
    'm': 'ь',
    'Q': 'Й',
    'W': 'Ц',
    'E': 'У',
    'R': 'К',
    'T': 'Е',
    'Y': 'Н',
    'U': 'Г',
    'I': 'Ш',
    'O': 'Щ',
    'P': 'З',
    'A': 'Ф',
    'S': 'Ы',
    'D': 'В',
    'F': 'А',
    'G': 'П',
    'H': 'Р',
    'J': 'О',
    'K': 'Л',
    'L': 'Д',
    'Z': 'Я',
    'X': 'Ч',
    'C': 'С',
    'V': 'М',
    'B': 'И',
    'N': 'Т',
    'M': 'Ь',
    '[': 'х',
    '{': 'Х',
    ']': 'ъ',
    '}': 'Ъ',
    ';': 'ж',
    ':': 'Ж',
    '\'': 'э',
    '"': 'Э',
    ',': 'б',
    '<': 'Б',
    '.': 'ю',
    '>': 'Ю',
    '&': '?',
    '/': '.',
};
/* eslint-enable quote-props */

export enum DNS_ZONE_SORT {
    CREATED = 'CREATED',
    NAME = 'NAME',
    RECORDS = 'RECORDS'
}

export interface Zoom {
    refAreaLeft: string;
    refAreaRight: string;
    left: string;
    right: string;
}
