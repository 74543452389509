// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICorporationInfo {
    accounting_email?: string;
    government_contract_id?: string;
    head_name: string;
    head_position: string;
    iec: string;
    inn: string;
    legal_address: string;
    operation_basis: string;
    post_address: string;
    trademark: string;
}

export default class CorporationInfo {
    readonly _accounting_email: string | undefined;

    /**
     * Description: Company accounting email
     * Example: a.denovitz@d-shop.org
     */
    get accountingEmail(): string | undefined {
        return this._accounting_email;
    }

    readonly _government_contract_id: string | undefined;

    /**
     * Description: Government contract id of the company
     * Example: 14124124124
     */
    get governmentContractId(): string | undefined {
        return this._government_contract_id;
    }

    readonly _head_name: string;

    /**
     * Description: Full company head name
     * Example: Abraham "Avi" Denovitz
     */
    get headName(): string {
        return this._head_name;
    }

    static headNameValidate(headName: string): boolean {
        return typeof headName === 'string' && !!headName.trim();
    }

    readonly _head_position: string;

    /**
     * Description: Head position of the company
     * Example: CEO
     */
    get headPosition(): string {
        return this._head_position;
    }

    static headPositionValidate(headPosition: string): boolean {
        return typeof headPosition === 'string' && !!headPosition.trim();
    }

    readonly _iec: string;

    /**
     * Description: Company IEC (KPP)
     * Example: 123456789
     */
    get iec(): string {
        return this._iec;
    }

    static iecValidate(iec: string): boolean {
        return typeof iec === 'string' && !!iec.trim();
    }

    readonly _inn: string;

    /**
     * Description: Company INN number
     * Example: 781640172105
     */
    get inn(): string {
        return this._inn;
    }

    static innValidate(inn: string): boolean {
        return typeof inn === 'string' && !!inn.trim();
    }

    readonly _legal_address: string;

    /**
     * Description: Company legal address
     * Example: 123456, Russia, Moscow, Lenin st, 12, 44
     */
    get legalAddress(): string {
        return this._legal_address;
    }

    static legalAddressValidate(legalAddress: string): boolean {
        return typeof legalAddress === 'string' && !!legalAddress.trim();
    }

    readonly _operation_basis: string;

    /**
     * Description: Company operation basis
     * Example: organization chart
     */
    get operationBasis(): string {
        return this._operation_basis;
    }

    static operationBasisValidate(operationBasis: string): boolean {
        return typeof operationBasis === 'string' && !!operationBasis.trim();
    }

    readonly _post_address: string;

    /**
     * Description: Company legal address
     * Example: 123456, Russia, Moscow, Lenin st, 11, 33
     */
    get postAddress(): string {
        return this._post_address;
    }

    static postAddressValidate(postAddress: string): boolean {
        return typeof postAddress === 'string' && !!postAddress.trim();
    }

    readonly _trademark: string;

    /**
     * Description: Company trademark (name)
     * Example: Diamond Shop LLC
     */
    get trademark(): string {
        return this._trademark;
    }

    static trademarkValidate(trademark: string): boolean {
        return typeof trademark === 'string' && !!trademark.trim();
    }

    constructor(props: ICorporationInfo) {
        if (typeof props.accounting_email === 'string') {
            this._accounting_email = props.accounting_email.trim();
        }
        if (typeof props.government_contract_id === 'string') {
            this._government_contract_id = props.government_contract_id.trim();
        }
        this._head_name = props.head_name.trim();
        this._head_position = props.head_position.trim();
        this._iec = props.iec.trim();
        this._inn = props.inn.trim();
        this._legal_address = props.legal_address.trim();
        this._operation_basis = props.operation_basis.trim();
        this._post_address = props.post_address.trim();
        this._trademark = props.trademark.trim();
    }

    serialize(): ICorporationInfo {
        const data: ICorporationInfo = {
            head_name: this._head_name,
            head_position: this._head_position,
            iec: this._iec,
            inn: this._inn,
            legal_address: this._legal_address,
            operation_basis: this._operation_basis,
            post_address: this._post_address,
            trademark: this._trademark,
        };
        if (typeof this._accounting_email !== 'undefined') {
            data.accounting_email = this._accounting_email;
        }
        if (typeof this._government_contract_id !== 'undefined') {
            data.government_contract_id = this._government_contract_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            inn: typeof this._inn === 'string' && !!this._inn.trim(),
            trademark: typeof this._trademark === 'string' && !!this._trademark.trim(),
            iec: typeof this._iec === 'string' && !!this._iec.trim(),
            legal_address: typeof this._legal_address === 'string' && !!this._legal_address.trim(),
            post_address: typeof this._post_address === 'string' && !!this._post_address.trim(),
            head_position: typeof this._head_position === 'string' && !!this._head_position.trim(),
            head_name: typeof this._head_name === 'string' && !!this._head_name.trim(),
            operation_basis: typeof this._operation_basis === 'string' && !!this._operation_basis.trim(),
            accounting_email: !this._accounting_email ? true : typeof this._accounting_email === 'string',
            government_contract_id: !this._government_contract_id ? true : typeof this._government_contract_id === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICorporationInfo): CorporationInfo {
        return new CorporationInfo(props);
    }

    readonly keys: { [key: string]: string } = {
        accountingEmail: 'accounting_email',
        governmentContractId: 'government_contract_id',
        headName: 'head_name',
        headPosition: 'head_position',
        iec: 'iec',
        inn: 'inn',
        legalAddress: 'legal_address',
        operationBasis: 'operation_basis',
        postAddress: 'post_address',
        trademark: 'trademark',
        }
;

    mergeDeepWith(props: Partial<CorporationInfo>): CorporationInfo {
        const updateData: Partial<ICorporationInfo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CorporationInfo) => {
            const updateKey = this.keys[key] as keyof ICorporationInfo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICorporationInfo, keyof ICorporationInfo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CorporationInfo({ ...this.serialize(), ...updateData });
    }
}
