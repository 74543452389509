import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import Configuration, { IConfiguration } from 'Entities/Configuration';
import CountriesPhoneCodesResponse, { ICountriesPhoneCodesResponse } from 'Entities/CountriesPhoneCodesResponse';
import PanelVersion, { IPanelVersion } from 'Entities/PanelVersion';
import ReferralInfo, { IReferralInfo } from 'Entities/ReferralInfo';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class MiscApiApi {
    static async getConfiguration(): Promise<IConfiguration | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/configuration`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getCountriesPhoneCodes(): Promise<ICountriesPhoneCodesResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/country_phone_codes`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPanelVersion(): Promise<IPanelVersion | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/version`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getReferralInfo(ref_id: number): Promise<IReferralInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/referral/${ref_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
