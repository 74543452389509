import React, { FC } from 'react';

import { useIntl, Input } from 'Common';
import DnsRecordCreate from 'Entities/DnsRecordCreate';

import TtlSelect from '../TtlSelect';

import { RecordFormProps, Buttons, onBlur } from '.';

const CnameRecordForm: FC<RecordFormProps> = (props) => {
    const { values, errors, setFieldValue, onClose, zone } = props;
    const intl = useIntl();

    return (
        <>
            <Input
                error={!!errors.host}
                errorMessage={errors.host}
                label={intl.getMessage('dns_name')}
                name="host"
                onChange={(e) => setFieldValue('host', e)}
                onBlur={onBlur(zone.host, setFieldValue)}
                placeholder="www"
                size="middle"
                type="text"
                validate={DnsRecordCreate.hostValidate}
                value={values.host}
            />
            <Input
                error={!!errors.data}
                errorMessage={errors.data}
                label={intl.getMessage('dns_value')}
                name="data"
                onChange={(e) => setFieldValue('data', e)}
                placeholder={intl.getMessage('dns_value')}
                size="middle"
                type="text"
                validate={DnsRecordCreate.dataValidate}
                value={values.data}
            />
            <TtlSelect
                error={!!errors.ttl_seconds}
                value={values.ttl_seconds}
                onChange={(ttlSeconds) => setFieldValue('ttl_seconds', ttlSeconds)}
            />
            <Buttons onClose={onClose} isEdited={values.isEdited} />
        </>
    );
};

export default CnameRecordForm;
