import React, { FC } from 'react';
import { TooltipProps as TooltipChartProps } from 'recharts';
import { NameType, Payload, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import dayjs from 'dayjs';
import cn from 'classnames';

import { useIntl } from 'Common';

import s from './Tooltip.module.pcss';

const emptyData = (text: string) => (
    <span className={s.empty}>
        {text}
    </span>
);

interface TooltipOwnProps {
    xAxisKey: string;
    unit?: string;
}

type TooltipProps = TooltipChartProps<ValueType, NameType> & TooltipOwnProps;

const Tooltip: FC<TooltipProps> = ({ payload, unit, xAxisKey }) => {
    const intl = useIntl();
    if (!payload || !payload[0]) {
        return null;
    }

    const getTooltipValue = (p: Payload<ValueType, NameType>) => {
        if (p.value === 0) {
            return (
                <>
                    <span className={cn(s.value, s.value_empty)}>
                        <span
                            className={s.line}
                            style={{ backgroundColor: p.color }}
                        />
                        <span>
                            {intl.getMessage('chart_empty_data', { b: emptyData })}
                        </span>
                    </span>
                </>
            );
        }

        return (
            <>
                <span className={s.value}>
                    <span
                        className={s.line}
                        style={{ backgroundColor: p.color }}
                    />
                    {p.value}&nbsp;
                    <span className={s.unit}>{unit}</span>
                </span>
                <span className={s.label}>
                    {p.name}
                </span>
            </>
        );
    };

    return (
        <div className={s.tooltip}>
            <div>
                {payload.map((p) => (
                    <div key={p.name} className={s.row}>
                        {getTooltipValue(p)}
                    </div>
                ))}
            </div>
            <span className={s.time}>
                {dayjs(payload[0].payload[xAxisKey])
                    .format(intl.getMessage('time_format_short_month_year'))}
            </span>
        </div>
    );
};

export default Tooltip;
