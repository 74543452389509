import Server from 'Entities/Server';

export const filterServers = (servers: Server[]) => {
    const deleted: { server: Server; deleted: boolean; isFirst?: boolean }[] = [];
    const current: Map<number, { server: Server; isFirst?: boolean }[]> = new Map();
    for (let i = 0; i < servers.length; i += 1) {
        const { timeDeleted, tenantId } = servers[i];
        if (!timeDeleted) {
            if (current.has(tenantId)) {
                current.get(tenantId)!.push({ server: servers[i] });
            } else {
                current.set(tenantId, [{ server: servers[i] }]);
            }
        } else {
            deleted.push({ server: servers[i], deleted: true, isFirst: deleted.length === 0 });
        }
    }
    const serversData = [];
    current.forEach((c) => {
        const first = c[0];
        const rest = c.slice(1, c.length);
        serversData.push({ server: first.server, isFirst: true }, ...rest);
    });
    serversData.push(...deleted);

    return { deletedServers: deleted, currentServers: current, serversData };
};
