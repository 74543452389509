// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IFromTo {
    from_value: number;
    to_value: number;
}

export default class FromTo {
    readonly _from_value: number;

    /**
     * Description: Configuration value that server should be upgraded from, like current number of CPU cores or current Disk volume
     * Example: 1024
     */
    get fromValue(): number {
        return this._from_value;
    }

    static fromValueValidate(fromValue: number): boolean {
        return typeof fromValue === 'number';
    }

    readonly _to_value: number;

    /**
     * Description: Configuration value that server should be upgraded to, like greater number of CPU cores or larger Disk volume
     * Example: 2048
     */
    get toValue(): number {
        return this._to_value;
    }

    static toValueValidate(toValue: number): boolean {
        return typeof toValue === 'number';
    }

    constructor(props: IFromTo) {
        this._from_value = props.from_value;
        this._to_value = props.to_value;
    }

    serialize(): IFromTo {
        const data: IFromTo = {
            from_value: this._from_value,
            to_value: this._to_value,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            from_value: typeof this._from_value === 'number',
            to_value: typeof this._to_value === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IFromTo): FromTo {
        return new FromTo(props);
    }

    readonly keys: { [key: string]: string } = {
        fromValue: 'from_value',
        toValue: 'to_value',
        }
;

    mergeDeepWith(props: Partial<FromTo>): FromTo {
        const updateData: Partial<IFromTo> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof FromTo) => {
            const updateKey = this.keys[key] as keyof IFromTo;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IFromTo, keyof IFromTo>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new FromTo({ ...this.serialize(), ...updateData });
    }
}
