import React, { FC, MouseEvent } from 'react';
import { Link as L, LinkProps as LProps } from 'react-router-dom';
import cn from 'classnames';

import { useIntl } from 'Common';
import { RoutePath, linkPathBuilder, LinkParams, LinkParamsKeys, QueryParamsType } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

interface LinkProps {
    to: RoutePath;
    props?: LinkParams;
    className?: string;
    type?: LProps['type'];
    stop?: boolean;
    disabled?: boolean;
    onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
    id?: string;
    queryParams?: QueryParamsType;
}

const Link: FC<LinkProps> = ({
    to, children, className, props, type, stop, disabled, onClick, id, queryParams,
}) => {
    const { currentLocale } = useIntl();

    if (props) {
        Object.keys(props).forEach((key) => {
            if (!props[key as LinkParamsKeys]) {
                // TODO: handle sentry error, that we got null or undefined link prop in runtime
                throw new Error(`Got wrong ${key} propKey: ${props[key as LinkParamsKeys]} in Link`);
            }
        });
    }

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (stop) {
            e.stopPropagation();
        }
        if (onClick) {
            onClick(e);
        }
    };

    if (disabled) {
        return (
            <div
                id={id}
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                tabIndex={0}
                className={cn(className, theme.link.disabled)}
            >
                {children}
            </div>
        );
    }

    return (
        <L
            id={id}
            className={className}
            type={type}
            to={linkPathBuilder(currentLocale, to, props, queryParams)}
            onClick={handleClick}
        >
            {children}
        </L>
    );
};

export default Link;
