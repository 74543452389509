import { takeEvery, put, call } from 'redux-saga/effects';

import PublicKeysApi from 'Apis/pubKeys';
import {
    PublicKeysActions,
    getPublicKeys as getPublicKeysAct,
    savePublicKeys,
    createPublicKey as createPublicKeyAct,
    deletePublicKey as deletePublicKeyAct,
    updatePublicKey as updatePublicKeyAct,
} from 'Actions/publicKeys';
import PublicKey, { IPublicKey } from 'Entities/PublicKey';

import { errorChecker, addError, checkUnhandledError } from './utils';

function* getPublicKeys(action: ReturnType<typeof getPublicKeysAct>) {
    const response: IPublicKey[] = yield call(PublicKeysApi.getPublicKeys);
    const { error, result } = errorChecker<IPublicKey[]>(response);
    if (result) {
        const publicKeysEntities = result.map((s) => new PublicKey(s));
        yield put(savePublicKeys(publicKeysEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createPublicKey(action: ReturnType<typeof createPublicKeyAct>) {
    const { payload, callback } = action;
    const response: IPublicKey[] = yield call(PublicKeysApi.createPublicKey, ...payload);
    const { error, result } = errorChecker<IPublicKey[]>(response);
    if (result && callback?.result) {
        yield put(getPublicKeysAct());
        callback?.result(result);
    }
    if (error && callback?.error) {
        callback?.error(error);
        checkUnhandledError(error, action.type);
    }
}

function* deletePublicKey(action: ReturnType<typeof deletePublicKeyAct>) {
    const { payload } = action;
    const response: number = yield call(PublicKeysApi.deletePublicKey, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getPublicKeysAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updatePublicKey(action: ReturnType<typeof updatePublicKeyAct>) {
    const { payload, callback } = action;
    const response: number = yield call(PublicKeysApi.updatePublicKey, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result && callback?.result) {
        yield put(getPublicKeysAct());
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
        checkUnhandledError(error, action.type);
    }
}

function* publicKeySaga(): Generator {
    yield takeEvery<ReturnType<typeof getPublicKeysAct>>(
        PublicKeysActions.GetPublicKeys, getPublicKeys,
    );
    yield takeEvery<ReturnType<typeof createPublicKeyAct>>(
        PublicKeysActions.CreatePublicKey, createPublicKey,
    );
    yield takeEvery<ReturnType<typeof deletePublicKeyAct>>(
        PublicKeysActions.DeletePublicKey, deletePublicKey,
    );
    yield takeEvery<ReturnType<typeof updatePublicKeyAct>>(
        PublicKeysActions.UpdatePublicKey, updatePublicKey,
    );
}

export default publicKeySaga;
