import React, { FC } from 'react';

import { useIntl, Button } from 'Common';
import { GuestRoutePath, externalLinkBuilder } from 'Lib/helpers/routes';
import { SERVER_LOGIN } from 'Lib/helpers/consts';

import s from './Console.module.pcss';

const Sidebar: FC = () => {
    const intl = useIntl();
    const { currentLocale } = intl;

    return (
        <div className={s.sidebar}>
            <div className={s.text}>
                {intl.getMessage('server_console_password')}
            </div>
            <a href={externalLinkBuilder(currentLocale, GuestRoutePath.KbArticle, { slug: 'how-to-change-admin-password' })}>
                <Button
                    type="link"
                    size="small"
                >
                    {intl.getMessage('restore_password')}
                </Button>
            </a>
            <div className={s.divider} />
            <div className={s.text}>
                <div className={s.title}>
                    {SERVER_LOGIN}
                </div>
                {intl.getMessage('server_console_login')}
            </div>
            <div className={s.text}>
                <div className={s.title}>
                    macOS
                </div>
                <div>
                    {intl.getMessage('server_console_copy_command', { value: 'Cmd + C' })}
                </div>
                <div>
                    {intl.getMessage('server_console_paste_command', { value: 'Cmd + Shift + V' })}
                </div>
            </div>
            <div className={s.text}>
                <div className={s.title}>
                    Windows
                </div>
                <div>
                    {intl.getMessage('server_console_copy_command', { value: 'Ctrl + C' })}
                </div>
                <div>
                    {intl.getMessage('server_console_paste_command', { value: 'Ctrl + Shift + V' })}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
