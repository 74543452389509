import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Button } from 'Common';
import theme from 'Lib/theme';

import s from '../Expenses.module.pcss';

interface FooterProps {
    title: string;
    emptyStats: boolean;
    visibleList: boolean;
    setVisibleList: (v: boolean) => void;
}

const Footer: FC<FooterProps> = ({
    title,
    emptyStats,
    visibleList,
    setVisibleList,
}) => {
    const intl = useIntl();

    if (emptyStats) {
        return (
            <div className={s.footer}>
                <div className={s.label}>
                    {intl.getMessage('empty_data_for_period')}
                </div>
            </div>
        );
    }

    return (
        <div
            className={cn(s.footer, s.footer_data)}
            onClick={() => setVisibleList(!visibleList)}
        >
            <div className={s.label}>
                {visibleList
                    ? intl.getMessage('hide')
                    : title}
            </div>
            <Button
                type="icon"
                size="medium"
                icon="down"
                className={cn(
                    s.button,
                    {
                        [s.button_active]: visibleList,
                        [theme.button.active]: visibleList,
                    },
                )}
            />
        </div>
    );
};

export default Footer;
