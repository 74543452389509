import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useTitle } from 'Hooks';
import { Store } from 'Store';
import { useIntl, ListLayout, InnerPageLayout, LoaderPageLayout, Button, Select, Option } from 'Common';
import { getDnsZones, getDnsSettings } from 'Actions/dns';
import DnsZone from 'Entities/DnsZone';
import DnsSettings from 'Entities/DnsSettings';
import { DNS_ZONE_SORT } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import Header from './Header';
import Info from './Info';
import Zone from './Zone';
import { AddDnsZone } from '../Modals';

import s from './Zone.module.pcss';

interface DnsZonesStoreProps {
    dns: Map<number, DnsZone> | null;
    settings: DnsSettings | null;
    sortedZones: Map<DNS_ZONE_SORT, number[]> | null;
}

const Zones: FC<DnsZonesStoreProps> = ({ dns, settings, sortedZones }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('dns_zones_page_title'));
    const [openAddModal, setOpenAddModal] = useState(false);
    const [sort, setSort] = useState(DNS_ZONE_SORT.CREATED);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDnsZones());
        dispatch(getDnsSettings());
    }, []);

    if (!sortedZones || !settings || !dns) {
        return null;
    }

    const getContent = () => {
        if (settings === null || dns === null) {
            return <LoaderPageLayout />;
        }

        const { nameServers } = settings;
        const array = sortedZones.get(sort)!;

        return (
            <>
                <ListLayout
                    title={intl.getMessage('dns_zones')}
                    length={array.length}
                    options={(
                        <div className={s.options}>
                            <Select
                                size="medium"
                                value={sort}
                                className={cn('select--transparent', s.sort)}
                                onChange={(v) => setSort(v)}
                            >
                                <Option value={DNS_ZONE_SORT.CREATED} className="option option_small">
                                    {intl.getMessage('sort_by_created')}
                                </Option>
                                <Option value={DNS_ZONE_SORT.NAME} className="option option_small">
                                    {intl.getMessage('sort_by_name')}
                                </Option>
                                <Option value={DNS_ZONE_SORT.RECORDS} className="option option_small">
                                    {intl.getMessage('sort_by_number_of_entries')}
                                </Option>
                            </Select>
                        </div>
                    )}
                >
                    {array.length > 0 ? (
                        <>
                            <div
                                className={cn(
                                    theme.card.labels,
                                    theme.card.labels_withActions,
                                    theme.card.labels_zone,
                                )}
                            >
                                <div className={theme.card.label}>
                                    {intl.getMessage('dns_zone_placeholder')}
                                </div>
                                <div className={theme.card.label}>
                                    {intl.getMessage('dns_zone_records_label')}
                                </div>
                                <div className={theme.card.label}>
                                    {intl.getMessage('tenant')}
                                </div>
                                <div className={theme.card.label}>
                                    {intl.getMessage('dns_zone_last_change')}
                                </div>
                            </div>
                            <div data-name="dnsZonesTable">
                                {array.map((item: number) => (
                                    <Zone
                                        key={item}
                                        zone={dns.get(item)!}
                                    />
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className={s.empty}>
                            {intl.getMessage('dns_zones_empty')}&nbsp;
                            <Button
                                type="link"
                                onClick={() => setOpenAddModal(true)}
                            >
                                {intl.getMessage('dns_zone_add_short')}
                            </Button>
                        </div>
                    )}
                </ListLayout>
                <ListLayout title={intl.getMessage('ns_settings')}>
                    <Info nameServers={nameServers} />
                </ListLayout>
            </>
        );
    };

    return (
        <InnerPageLayout
            header={<Header openModal={() => setOpenAddModal(true)} />}
            className={theme.content.with_actions}
        >
            {getContent()}

            {openAddModal && (
                <AddDnsZone
                    visible={openAddModal}
                    handleClose={() => setOpenAddModal(false)}
                />
            )}
        </InnerPageLayout>
    );
};

const settingsSelector = (store: Store) => store.dns.settings;
const dnsZonesSelector = (store: Store) => store.dns.zones;
const dnsSortedZonesSelector = (store: Store) => store.dns.sortedZones;
const selector = createSelector(
    [dnsZonesSelector, settingsSelector, dnsSortedZonesSelector],
    (map, settings, sortedZones) => {
        return {
            settings,
            sortedZones,
            dns: map === null ? null : map,
        };
    },
);

const mapStoreToProps = (store: Store) => ({ ...selector(store) });

export default connect(mapStoreToProps)(Zones);
