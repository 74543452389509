import { Action, ActionType } from 'store/actions';
import { ErrorActions, addError, removeError } from 'Actions/error';
import { UserActions } from 'Actions/user';

import { ErrorStore, errorInitialState as initialState } from '../initialStates';

const reducer = (
    state: ErrorStore = initialState,
    action: Action,
): ErrorStore => {
    switch (action.type as ActionType) {
        case ErrorActions.AddError: {
            const { payload: {
                error, operation, type,
            } } = action as ReturnType<typeof addError>;

            const newState = new Map(state);
            newState.set(type, { error, operation });
            return newState;
        }
        case ErrorActions.RemoveError: {
            const { payload } = action as ReturnType<typeof removeError>;
            const newState = new Map(state);
            newState.delete(payload);
            return newState;
        }
        case UserActions.LoggedIn: {
            const newState = new Map();
            return newState;
        }
        default:
            return state;
    }
};

export default reducer;
