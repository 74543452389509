// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountExpensesDetailedRequest {
    date_from?: string;
    date_from_millis: number;
    date_to?: string;
    date_to_millis: number;
    server_ids?: number[];
    show_only_transfers: boolean;
}

export default class AccountExpensesDetailedRequest {
    readonly _date_from: string | undefined;

    /** */
    get dateFrom(): string | undefined {
        return this._date_from;
    }

    readonly _date_from_millis: number;

    /**
     * Description: Date from (inclusive), as millis since Epoch
     * Example: 1612904400000
     */
    get dateFromMillis(): number {
        return this._date_from_millis;
    }

    static dateFromMillisValidate(dateFromMillis: number): boolean {
        return typeof dateFromMillis === 'number';
    }

    readonly _date_to: string | undefined;

    /** */
    get dateTo(): string | undefined {
        return this._date_to;
    }

    readonly _date_to_millis: number;

    /**
     * Description: Date to (inclusive), as millis since Epoch
     * Example: 1613077199999
     */
    get dateToMillis(): number {
        return this._date_to_millis;
    }

    static dateToMillisValidate(dateToMillis: number): boolean {
        return typeof dateToMillis === 'number';
    }

    readonly _server_ids: number[] | undefined;

    /** */
    get serverIds(): number[] | undefined {
        return this._server_ids;
    }

    readonly _show_only_transfers: boolean;

    /** */
    get showOnlyTransfers(): boolean {
        return this._show_only_transfers;
    }

    static showOnlyTransfersValidate(showOnlyTransfers: boolean): boolean {
        return typeof showOnlyTransfers === 'boolean';
    }

    constructor(props: IAccountExpensesDetailedRequest) {
        if (typeof props.date_from === 'string') {
            this._date_from = props.date_from.trim();
        }
        this._date_from_millis = props.date_from_millis;
        if (typeof props.date_to === 'string') {
            this._date_to = props.date_to.trim();
        }
        this._date_to_millis = props.date_to_millis;
        if (props.server_ids) {
            this._server_ids = props.server_ids;
        }
        this._show_only_transfers = props.show_only_transfers;
    }

    serialize(): IAccountExpensesDetailedRequest {
        const data: IAccountExpensesDetailedRequest = {
            date_from_millis: this._date_from_millis,
            date_to_millis: this._date_to_millis,
            show_only_transfers: this._show_only_transfers,
        };
        if (typeof this._date_from !== 'undefined') {
            data.date_from = this._date_from;
        }
        if (typeof this._date_to !== 'undefined') {
            data.date_to = this._date_to;
        }
        if (typeof this._server_ids !== 'undefined') {
            data.server_ids = this._server_ids;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            server_ids: !this._server_ids ? true : this._server_ids.reduce((result, p) => result && typeof p === 'number', true),
            show_only_transfers: typeof this._show_only_transfers === 'boolean',
            date_from: !this._date_from ? true : typeof this._date_from === 'string',
            date_to: !this._date_to ? true : typeof this._date_to === 'string',
            date_from_millis: typeof this._date_from_millis === 'number',
            date_to_millis: typeof this._date_to_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountExpensesDetailedRequest): AccountExpensesDetailedRequest {
        return new AccountExpensesDetailedRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        dateFrom: 'date_from',
        dateFromMillis: 'date_from_millis',
        dateTo: 'date_to',
        dateToMillis: 'date_to_millis',
        serverIds: 'server_ids',
        showOnlyTransfers: 'show_only_transfers',
        }
;

    mergeDeepWith(props: Partial<AccountExpensesDetailedRequest>): AccountExpensesDetailedRequest {
        const updateData: Partial<IAccountExpensesDetailedRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountExpensesDetailedRequest) => {
            const updateKey = this.keys[key] as keyof IAccountExpensesDetailedRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountExpensesDetailedRequest, keyof IAccountExpensesDetailedRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountExpensesDetailedRequest({ ...this.serialize(), ...updateData });
    }
}
