import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { useIntl, Button, Loader, Icon } from 'Common';
import serverApi from 'Apis/servers';
import { getServerList, updateServer } from 'Actions/server';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import Server from 'Entities/Server';
import Tenant, { ITenant } from 'Entities/Tenant';
import { errorChecker } from 'Lib/helpers/utils';
import { TRANSITION_TIME } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import s from './ChangeTenant.module.pcss';

interface TenantServers {
    tenant: Tenant;
    servers: Server[];
}

interface ChangeTenantProps {
    tenant: Tenant;
    server: Server;
    servers: Server[];
}

const ChangeTenant: FC<ChangeTenantProps> = ({
    tenant,
    server,
    servers,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { id: serverId } = server;
    const { id: tenantId, description } = tenant;

    const [showDropdown, setShowDropdown] = useState(false);
    const [options, setOptions] = useState<TenantServers[] | null>(null);

    const currentTenantServers = servers.filter((sr) => sr.tenantId === tenant.id);

    const history = useHistory();

    useEffect(() => {
        let canUpdate = true;
        const loader = async () => {
            const resp = await serverApi.getTenantsToMoveServer(tenantId, serverId);
            const { result } = errorChecker<ITenant[]>(resp);
            if (result && canUpdate) {
                const tenantList: TenantServers[] = [];
                result.forEach((rs) => {
                    const newTenant = new Tenant(rs);
                    const tenantServers = servers.filter((sr) => sr.tenantId === newTenant.id);
                    tenantList.push({ tenant: newTenant, servers: tenantServers });
                });
                setOptions(tenantList);
            }
        };
        loader();
        return () => {
            canUpdate = false;
        };
    }, [tenant]);

    const handleSubmit = (id: number) => {
        setShowDropdown(false);

        setTimeout(() => {
            dispatch(updateServer({ server, update: { to_tenant_id: id } }));
            dispatch(getServerList());
        }, TRANSITION_TIME);
    };

    const getContent = () => {
        if (options === null) {
            return (
                <div className={cn(s.dropdown, s.dropdown_loader)}>
                    <Loader circle mini />
                </div>
            );
        }

        const createNewProjectLink = () => {
            history.push(
                linkPathBuilder(
                    intl.currentLocale,
                    RoutePath.NewProjectBackToPrevPage,
                    { back: true },
                    { serverId },
                ),
            );
        };

        if (options.length > 0) {
            return (
                <div className={cn(s.dropdown, theme.common.customScrollbar)}>
                    <p className={s.title}>
                        {intl.getMessage('change_tenant')}
                    </p>
                    <button
                        type="button"
                        className={cn(s.tenant, s.tenant_current)}
                    >
                        <div className={cn(s.name, theme.common.textOverflow)}>
                            {tenant.description}
                        </div>
                        <div className={s.servers}>
                            {intl.getPlural('servers', currentTenantServers.length)}
                        </div>
                        <Icon icon="check" className={s.check} />
                    </button>
                    {options.map((item) => (
                        <button
                            key={item.tenant.id}
                            type="button"
                            className={s.tenant}
                            onClick={() => handleSubmit(item.tenant.id)}
                        >
                            <div className={cn(s.name, theme.common.textOverflow)}>
                                {item.tenant.description}
                            </div>
                            <div className={s.servers}>
                                {intl.getPlural('servers', item.servers.length)}
                            </div>
                        </button>
                    ))}
                    <div className={s.createProjectBtn}>
                        <Button
                            type="link"
                            size="small"
                            onClick={createNewProjectLink}
                        >
                            {intl.getMessage('projectlist_create')}
                        </Button>
                    </div>
                </div>
            );
        }

        return (
            <div className={cn(s.dropdown, s.dropdown_empty)}>
                <div className={s.desc}>
                    {intl.getMessage('server_to_other_tenant_modal_empty')}
                </div>
                <Button
                    type="link"
                    size="small"
                    onClick={createNewProjectLink}
                >
                    {intl.getMessage('projectlist_create')}
                </Button>
            </div>
        );
    };

    return (
        <Dropdown
            overlay={getContent}
            placement="bottomLeft"
            trigger={['click']}
            onVisibleChange={(v) => setShowDropdown(v)}
            visible={showDropdown}
        >
            <span
                className={cn(
                    theme.link.link,
                    theme.link.blue,
                    s.link,
                )}
            >
                <span className={cn(s.trigger, theme.common.textOverflow)}>
                    {description}
                </span>
                <Icon icon="down" className={s.down} />
            </span>
        </Dropdown>
    );
};

export default ChangeTenant;
