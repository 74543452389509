import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { CommonModalLayout, useIntl, notifySuccess } from 'Common';
import { createTicket } from 'Actions/support';
import { ITicketCreate } from 'Entities/TicketCreate';
import { getTicketSubject } from 'Lib/helpers/translationHelper';
import { TICKETS_SUBJECT } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import s from './Client.module.pcss';

interface ClientDeleteModalProps {
    visible: boolean;
    handleClose: () => void;
    email: string;
}

const grayText = (text: string) => (
    <span
        className={cn(
            theme.color.grayHeaders,
            s.text,
        )}
    >
        {text}
    </span>
);

const ClientDeleteModal: FC<ClientDeleteModalProps> = ({ visible, handleClose, email }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const handleClientAccountDelete = () => {
        const data = new FormData();
        const params: ITicketCreate = {
            subject: getTicketSubject(intl, TICKETS_SUBJECT.DELETE_CLIENT),
            close_immediately: false,
            contents: intl.getMessage('support_subject_client_delete_desc', { value: email }).trim(),
        };
        data.set('params', new Blob([JSON.stringify(params)], { type: 'application/json' }));
        dispatch(createTicket([data], {
            result: () => {
                handleClose();
                notifySuccess(intl.getMessage('delete_client_account_notification'), '', { duration: 0 });
            },
        }));
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('client_delete_title')}
            buttonText={intl.getMessage('send_request_btn')}
            handleSubmit={handleClientAccountDelete}
            handleClose={handleClose}
            width={480}
        >
            <div className={s.desc}>
                {intl.getMessage('client_modal_delete_desc', { value: email, b: grayText })}
            </div>
        </CommonModalLayout>
    );
};

export default ClientDeleteModal;
