import { takeEvery, put, call, select } from 'redux-saga/effects';

import PartnersApi from 'Apis/partners';
import { getAccountDetails } from 'Actions/account';
import {
    PartnerActions,
    withdrawalPayment as withdrawalPaymentAct,
    transferPayment as transferPaymentAct,
    getPartnerDetails as getPartnerDetailsAct,
    getPartnerComparativeDetails as getPartnerComparativeDetailsAct,
    getPartnerStats as getPartnerStatsAct,
    getPartnerCompletePayments as getPartnerCompletePaymentsAct,
    getPartnerProcessingPayments as getPartnerProcessingPaymentsAct,
    savePartnerCompletePayments,
    savePartnerProcessingPayments,
    registerPartner as registerPartnerAct,
    savePartnerDetails,
    savePartnerStats,
    clearPartnerCompletePayments,
    savePartnerComparativeDetails,
} from 'Actions/partner';
import { getResellerDetails } from 'Actions/reseller';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import PartnerDetails, { IPartnerDetails } from 'Entities/PartnerDetails';
import PartnerStats, { IPartnerStats } from 'Entities/PartnerStats';
import PartnerPayment, { IPartnerPayment } from 'Entities/PartnerPayment';
import PartnerFinances, { IPartnerFinances } from 'Entities/PartnerFinances';
import PartnerComparativeDetails, { IPartnerComparativeDetails } from 'Entities/PartnerComparativeDetails';
import { Store } from 'Store';

import { errorChecker, addError } from './utils';

function* withdrawalPayment(action: ReturnType<typeof withdrawalPaymentAct>) {
    const { payload, callback } = action;
    const response: IPartnerPayment = yield call(
        PartnersApi.doPartnerWithdrawalPayment, ...payload,
    );
    const { error, result } = errorChecker<IPartnerPayment>(response);
    if (result) {
        yield put(clearPartnerCompletePayments());
        yield put(getPartnerDetailsAct([]));
        yield put(getAccountDetails());
        if (callback?.result) {
            callback?.result(new PartnerPayment(result));
        }
    }
    if (error) {
        if (error.fields?.amount?.error_code === ApiErrorCode.NOT_ENOUGH_BALANCE) {
            yield addError(error.fields?.amount, action.type);
        } else {
            yield addError(error, action.type);
        }
    }
}

function* transferPayment(action: ReturnType<typeof transferPaymentAct>) {
    const { payload, callback } = action;
    const response: number = yield call(PartnersApi.doPartnerTransferPayment, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getPartnerDetailsAct([]));
        yield put(getAccountDetails());
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        if (error.fields?.amount?.error_code === ApiErrorCode.NOT_ENOUGH_BALANCE) {
            yield addError(error.fields?.amount, action.type);
        } else {
            yield addError(error, action.type);
        }
    }
}

function* getPartnerDetails(action: ReturnType<typeof getPartnerDetailsAct>) {
    const response: IPartnerDetails = yield call(PartnersApi.getPartnerDetails);
    const { result, error } = errorChecker<IPartnerDetails>(response);
    if (result) {
        yield put(savePartnerDetails(new PartnerDetails(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getPartnerComparativeDetails(action: ReturnType<typeof getPartnerComparativeDetailsAct>) {
    const { payload } = action;
    const response: IPartnerComparativeDetails = yield call(
        PartnersApi.getPartnerComparativeDetails, ...payload,
    );
    const { result, error } = errorChecker<IPartnerComparativeDetails>(response);
    if (result) {
        yield put(savePartnerComparativeDetails(new PartnerComparativeDetails(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getPartnerStats(action: ReturnType<typeof getPartnerStatsAct>) {
    const { payload } = action;
    const response: IPartnerStats[] = yield call(PartnersApi.getPartnerStats, ...payload);
    const { result, error } = errorChecker<IPartnerStats[]>(response);
    if (result) {
        yield put(savePartnerStats(result.map((r) => new PartnerStats(r))));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getPartnerCompletePayments(action: ReturnType<typeof getPartnerCompletePaymentsAct>) {
    const { payload } = action;
    const response: IPartnerFinances = yield call(
        PartnersApi.getPartnerCompletePayments, ...payload,
    );
    const { result, error } = errorChecker<IPartnerFinances>(response);
    if (result) {
        yield put(savePartnerCompletePayments(new PartnerFinances(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* getPartnerProcessingPayments(action: ReturnType<typeof getPartnerProcessingPaymentsAct>) {
    const response: IPartnerPayment[] = yield call(PartnersApi.getPartnerProcessingPayments);
    const { result, error } = errorChecker<IPartnerPayment[]>(response);
    if (result) {
        yield put(savePartnerProcessingPayments(result.map((r) => new PartnerPayment(r))));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* registerPartner(action: ReturnType<typeof registerPartnerAct>) {
    const isReseller: boolean = yield select((s: Store) => s.profile.info?.reseller);
    const { payload, callback } = action;
    const response: IPartnerDetails = yield call(PartnersApi.registerPartner, ...payload);
    const { error, result } = errorChecker<IPartnerDetails>(response);
    if (result) {
        yield put(getPartnerDetailsAct([]));
        if (isReseller) {
            yield put(getResellerDetails([]));
        }
        if (callback?.result) {
            callback?.result(new PartnerDetails(result));
        }
    }
    if (error && callback?.error) {
        callback?.error(error);
        yield addError(error, action.type);
    }
}

export default function* partnerSaga(): Generator {
    yield takeEvery<ReturnType<typeof withdrawalPaymentAct>>(
        PartnerActions.WithdrawalPayment, withdrawalPayment,
    );
    yield takeEvery<ReturnType<typeof transferPaymentAct>>(
        PartnerActions.TransferPayment, transferPayment,
    );
    yield takeEvery<ReturnType<typeof getPartnerDetailsAct>>(
        PartnerActions.GetPartnerDetails, getPartnerDetails,
    );
    yield takeEvery<ReturnType<typeof getPartnerComparativeDetailsAct>>(
        PartnerActions.GetPartnerComparativeDetails, getPartnerComparativeDetails,
    );
    yield takeEvery<ReturnType<typeof getPartnerStatsAct>>(
        PartnerActions.GetPartnerStats, getPartnerStats,
    );
    yield takeEvery<ReturnType<typeof getPartnerCompletePaymentsAct>>(
        PartnerActions.GetPartnerCompletePayments,
        getPartnerCompletePayments,
    );
    yield takeEvery<ReturnType<typeof getPartnerProcessingPaymentsAct>>(
        PartnerActions.GetPartnerProcessingPayments,
        getPartnerProcessingPayments,
    );
    yield takeEvery<ReturnType<typeof registerPartnerAct>>(
        PartnerActions.RegisterPartner, registerPartner,
    );
}
