// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDiscount {
    activation_date: string;
    activation_date_millis: number;
    balance: string;
    discount_percent: number;
    expiration_date?: string;
    expiration_date_millis?: number;
}

export default class Discount {
    readonly _activation_date: string;

    /** */
    get activationDate(): string {
        return this._activation_date;
    }

    static activationDateValidate(activationDate: string): boolean {
        return typeof activationDate === 'string' && !!activationDate.trim();
    }

    readonly _activation_date_millis: number;

    /** */
    get activationDateMillis(): number {
        return this._activation_date_millis;
    }

    static activationDateMillisValidate(activationDateMillis: number): boolean {
        return typeof activationDateMillis === 'number';
    }

    readonly _balance: string;

    /** */
    get balance(): string {
        return this._balance;
    }

    static balanceValidate(balance: string): boolean {
        return typeof balance === 'string' && !!balance.trim();
    }

    readonly _discount_percent: number;

    /**
     * Description: Discount percent
     * Example: 15
     */
    get discountPercent(): number {
        return this._discount_percent;
    }

    static discountPercentValidate(discountPercent: number): boolean {
        return typeof discountPercent === 'number';
    }

    readonly _expiration_date: string | undefined;

    /** */
    get expirationDate(): string | undefined {
        return this._expiration_date;
    }

    readonly _expiration_date_millis: number | undefined;

    /** */
    get expirationDateMillis(): number | undefined {
        return this._expiration_date_millis;
    }

    constructor(props: IDiscount) {
        this._activation_date = props.activation_date.trim();
        this._activation_date_millis = props.activation_date_millis;
        this._balance = props.balance.trim();
        this._discount_percent = props.discount_percent;
        if (typeof props.expiration_date === 'string') {
            this._expiration_date = props.expiration_date.trim();
        }
        if (typeof props.expiration_date_millis === 'number') {
            this._expiration_date_millis = props.expiration_date_millis;
        }
    }

    serialize(): IDiscount {
        const data: IDiscount = {
            activation_date: this._activation_date,
            activation_date_millis: this._activation_date_millis,
            balance: this._balance,
            discount_percent: this._discount_percent,
        };
        if (typeof this._expiration_date !== 'undefined') {
            data.expiration_date = this._expiration_date;
        }
        if (typeof this._expiration_date_millis !== 'undefined') {
            data.expiration_date_millis = this._expiration_date_millis;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            balance: typeof this._balance === 'string' && !!this._balance.trim(),
            discount_percent: typeof this._discount_percent === 'number',
            activation_date: typeof this._activation_date === 'string' && !!this._activation_date.trim(),
            activation_date_millis: typeof this._activation_date_millis === 'number',
            expiration_date: !this._expiration_date ? true : typeof this._expiration_date === 'string',
            expiration_date_millis: !this._expiration_date_millis ? true : typeof this._expiration_date_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDiscount): Discount {
        return new Discount(props);
    }

    readonly keys: { [key: string]: string } = {
        activationDate: 'activation_date',
        activationDateMillis: 'activation_date_millis',
        balance: 'balance',
        discountPercent: 'discount_percent',
        expirationDate: 'expiration_date',
        expirationDateMillis: 'expiration_date_millis',
        }
;

    mergeDeepWith(props: Partial<Discount>): Discount {
        const updateData: Partial<IDiscount> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Discount) => {
            const updateKey = this.keys[key] as keyof IDiscount;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDiscount, keyof IDiscount>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Discount({ ...this.serialize(), ...updateData });
    }
}
