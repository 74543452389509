import PhoneInfoUpdate, { IPhoneInfoUpdate } from './PhoneInfoUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface INotificationRecipientUpdate {
    email?: string;
    name?: string;
    phone?: IPhoneInfoUpdate;
}

export default class NotificationRecipientUpdate {
    readonly _email: string | undefined;

    /**
     * Description: Recipient email, or null to keep actual
     * Example: mymail@gmail.com
     */
    get email(): string | undefined {
        return this._email;
    }

    readonly _name: string | undefined;

    /**
     * Description: Recipient name, or null to keep actual
     * Example: mymail@gmail.com
     */
    get name(): string | undefined {
        return this._name;
    }

    static get nameMinLength() {
        return 0;
    }

    static get nameMaxLength() {
        return 256;
    }

    static nameValidate(name: string | undefined): boolean {
        return (!name ? true : name.length > 0) && (!name ? true : name.length <= 256);
    }

    readonly _phone: PhoneInfoUpdate | undefined;

    get phone(): PhoneInfoUpdate | undefined {
        return this._phone;
    }

    constructor(props: INotificationRecipientUpdate) {
        if (typeof props.email === 'string') {
            this._email = props.email.trim();
        }
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
        if (props.phone) {
            this._phone = new PhoneInfoUpdate(props.phone);
        }
    }

    serialize(): INotificationRecipientUpdate {
        const data: INotificationRecipientUpdate = {
        };
        if (typeof this._email !== 'undefined') {
            data.email = this._email;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        if (typeof this._phone !== 'undefined') {
            data.phone = this._phone.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: (!this._name ? true : this._name.length > 0) && (!this._name ? true : this._name.length <= 256),
            email: !this._email ? true : typeof this._email === 'string',
            phone: !this._phone ? true : this._phone.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: INotificationRecipientUpdate): NotificationRecipientUpdate {
        return new NotificationRecipientUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        email: 'email',
        name: 'name',
        phone: 'phone',
        }
;

    mergeDeepWith(props: Partial<NotificationRecipientUpdate>): NotificationRecipientUpdate {
        const updateData: Partial<INotificationRecipientUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof NotificationRecipientUpdate) => {
            const updateKey = this.keys[key] as keyof INotificationRecipientUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<INotificationRecipientUpdate, keyof INotificationRecipientUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new NotificationRecipientUpdate({ ...this.serialize(), ...updateData });
    }
}
