import React, { FC, useState, useRef, useEffect } from 'react';
import { Input, Dropdown } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { useIntl, Icon, Button } from 'Common';
import { getServerList } from 'Actions/server';
import Server from 'Entities/Server';
import OperationProgress from 'Entities/OperationProgress';
import { handleServerSearch } from 'Lib/helpers/search';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { useClickOutside } from 'Hooks';
import { Store } from 'Store';
import theme from 'Lib/theme';

import List from './List';
import Modal from './Modal';
import s from './Search.module.pcss';

interface SearchStoreProps {
    servers: Map<number, Server>;
    operationProgress?: OperationProgress;
}

interface SearchOwnProps {
    server: Server;
}

type SearchProps = SearchStoreProps & SearchOwnProps;

const Search: FC<SearchProps> = ({ server, servers, operationProgress }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const listRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLDivElement>(null);
    const [showSearch, setShowSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);

    useClickOutside([listRef, inputRef], () => setShowSearch(false));

    useEffect(() => {
        if (showSearch) {
            dispatch(getServerList());
        }
    }, [showSearch]);

    const serversWithoutCurrent = Array
        .from(servers.values())
        .filter((sr) => sr.id !== server.id);
    const serversList = [server, ...serversWithoutCurrent];

    const searchResult = searchQuery
        ? handleServerSearch(searchQuery, servers, intl.currentLocale)
        : serversList;

    const openServerPage = () => {
        history.push(
            linkPathBuilder(
                intl.currentLocale,
                RoutePath.Server,
                { serverId: server.id, tenantId: server.tenantId },
            ),
        );
    };

    return (
        <>
            <>
                <div
                    className={cn(
                        s.trigger,
                        s.trigger_desktop,
                    )}
                    onClick={openServerPage}
                    ref={inputRef}
                >
                    <div className={s.name}>
                        {server.name}
                    </div>
                    <Dropdown
                        overlay={(
                            <div
                                ref={listRef}
                                className={cn(theme.search.menu, theme.search.menu_server)}
                            >
                                <div className={s.inputModal}>
                                    <Input
                                        id="search"
                                        type="text"
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        className="input input_medium input_borderless"
                                        value={searchQuery}
                                        placeholder={intl.getMessage('search_placeholder')}
                                        autoComplete="off"
                                        autoFocus
                                    />
                                </div>
                                <List
                                    server={server}
                                    servers={searchResult}
                                    searchQuery={searchQuery}
                                    operationProgress={operationProgress}
                                    handleClose={() => setShowSearch(false)}
                                />
                            </div>
                        )}
                        overlayClassName="dropdown_fixed"
                        placement="bottomLeft"
                        trigger={['click']}
                        visible={showSearch}
                    >
                        <Button
                            type="icon"
                            icon="down"
                            className={s.chevron}
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowSearch(!showSearch);
                            }}
                        />
                    </Dropdown>
                </div>
                <div
                    className={cn(
                        s.trigger,
                        s.trigger_mobile,
                    )}
                    onClick={openServerPage}
                >
                    <div className={cn(s.name)}>
                        {server.name}
                    </div>
                    <Icon
                        icon="down"
                        className={s.chevron}
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowModal(true);
                        }}
                    />
                </div>
            </>
            {showModal && (
                <Modal
                    server={server}
                    servers={searchResult}
                    operationProgress={operationProgress}
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                    handleClose={() => setShowModal(false)}
                />
            )}
        </>
    );
};

const selectServers = (store: Store) => store.server;

const selector = createSelector([selectServers], (servers) => ({ servers }));

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(Search);
