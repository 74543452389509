import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Dots } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import Server from 'Entities/Server';
import { VmOperationStep } from 'Entities/VmOperationStep';
import { ServerState } from 'Entities/ServerState';
import { stepTranslate } from 'Lib/helpers/translationHelper';

import s from './SetupActions.module.pcss';
import SetupOptions from './SetupOptions';

interface SetupActionsProps {
    server: Server;
    progress: OperationProgress;
    compact?: boolean;
}

const SetupActions: FC<SetupActionsProps> = ({
    server,
    progress,
    compact,
}) => {
    const intl = useIntl();
    const {
        stepName,
        totalSteps,
        stepOrder,
    } = progress;

    const startStep = stepName === VmOperationStep.START;
    const stepTranslation = startStep
        ? (
            stepTranslate(intl, stepName)
        ) : (
            intl.getMessage('server_operation_step', {
                count: stepOrder,
                total: totalSteps + 1,
                step: stepTranslate(intl, stepName),
            })
        );

    return (
        <>
            <div className={s.loading}>
                <Dots className={s.loadingDots} />
                <div className={cn(s.loadingTitle, s.loadingTitle_desktop)}>
                    {compact ? intl.getMessage('setup') : stepTranslation}
                </div>
                <div className={cn(s.loadingTitle, s.loadingTitle_mobile)}>
                    {intl.getMessage('setup')}
                </div>
            </div>

            {server.state === ServerState.PENDING_INSTALL && (
                <div className={s.setup}>
                    <SetupOptions progress={progress} />
                </div>
            )}
        </>
    );
};

export default SetupActions;
