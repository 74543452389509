import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link, DashboardHeader, Button } from 'Common';
import { externalLinkBuilder, GuestRoutePath, RoutePath } from 'Lib/helpers/routes';
import AccountDetails from 'Entities/AccountDetails';
import AccountExpensesDetailed from 'Entities/AccountExpensesDetailed';
import theme from 'Lib/theme';
import s from '../Expenses.module.pcss';

interface HeaderProps {
    details: AccountDetails | null;
    detailedExpenses: AccountExpensesDetailed | null;
}

const Header: FC<HeaderProps> = ({ details, detailedExpenses }) => {
    const intl = useIntl();
    if (!details) {
        return null;
    }
    const { currentLocale } = intl;

    return (
        <DashboardHeader
            navigation={(
                <>
                    <Link
                        className={theme.header.link}
                        to={RoutePath.Billing}
                    >
                        {intl.getMessage('menu_billing')}
                    </Link>
                    <div className={cn(theme.header.link, theme.header.active)}>
                        {intl.getMessage('billing_expenses')}
                    </div>
                    <Link
                        to={RoutePath.Documents}
                        className={theme.header.link}
                    >
                        {intl.getMessage('billing_documents')}
                    </Link>
                </>
            )}
            actions={(
                <>
                    <a
                        href={externalLinkBuilder(currentLocale, GuestRoutePath.Tariffs)}
                        className={cn(
                            theme.link.link,
                            theme.link.black,
                        )}
                    >
                        {intl.getMessage('tariff_info')}
                    </a>
                    {detailedExpenses?.expensesReportDownloadLink && (
                        <a
                            href={detailedExpenses.expensesReportDownloadLink}
                            className={s.downloadButton}
                            download
                        >
                            <Button type="primary">
                                {intl.getMessage('download_pdf')}
                            </Button>
                        </a>
                    )}
                </>
            )}
        />
    );
};

export default Header;
