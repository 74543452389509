import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import punycode from 'punycode/';

import { Store } from 'Store';
import { createDnsZone } from 'Actions/dns';
import { useIntl, Input, Select, Option, FormModalLayout, notifySuccess } from 'Common';
import Tenant from 'Entities/Tenant';
import DnsZoneCreate, { IDnsZoneCreate } from 'Entities/DnsZoneCreate';

import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import Error from 'Entities/Error';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { hostValidate } from 'Lib/helpers/utils';
import { FormikHelpers } from 'formik';

interface AddDnsZoneProps {
    visible: boolean;
    tenants: Tenant[];
    handleClose: () => void;
}

interface AddZoneForm extends IDnsZoneCreate {
    tenantId: number;
}

const AddDnsZone: FC<AddDnsZoneProps> = ({ visible, tenants, handleClose }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const formOnSubmit = (values: AddZoneForm, helpers: FormikHelpers<AddZoneForm>): void => {
        const { tenantId } = values;
        const reqEnt = new DnsZoneCreate(values);
        const validate = reqEnt.validate();
        if ((validate.length === 0) && hostValidate(values.host)) {
            helpers.setSubmitting(false);
            dispatch(createDnsZone([tenantId, reqEnt.serialize()], {
                result: () => {
                    handleClose();
                },
                error: (err: Error) => {
                    const message = err.fields?.host && err.fields?.host[0];
                    helpers.setSubmitting(false);
                    if (message || err.errorCode) {
                        helpers.setFieldError('host', apiErrorCodeTranslate(intl, message?.error_code || err.errorCode));
                    }
                },
            }));
        } else {
            helpers.setFieldError('host', EMPTY_FIELD_ERROR);
            helpers.setSubmitting(false);
        }
    };

    const initialValues: AddZoneForm = {
        host: '',
        tenantId: tenants[0].id,
    };

    return (
        <FormModalLayout
            visible={visible}
            title={intl.getMessage('dns_zone_add')}
            initialValues={initialValues}
            handleClose={handleClose}
            handleSubmit={formOnSubmit}
            buttonText={intl.getMessage('dns_zone_add_short')}
        >
            {({
                values,
                errors,
                setFieldValue,
            }) => {
                const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    const text = e.clipboardData.getData('text/plain');
                    const parsed = punycode.toUnicode(text);
                    if (text !== parsed) {
                        setFieldValue('host', parsed);
                        notifySuccess(intl.getMessage('punycode_converted'));
                    } else {
                        setFieldValue('host', text);
                    }
                };
                return (
                    <>
                        <Input
                            autoFocus
                            error={!!errors.host}
                            errorMessage={errors.host as string}
                            name="host"
                            onChange={(e) => setFieldValue('host', e)}
                            onPaste={onPaste}
                            placeholder={intl.getMessage('dns_zone_placeholder')}
                            size="large"
                            type="text"
                            validate={(e) => hostValidate(e) && DnsZoneCreate.hostValidate(e)}
                            value={values.host}
                        />
                        <div className="modal__desc">
                            <Select
                                block
                                id="tenantId"
                                size="big"
                                placeholder={intl.getMessage('tenant_select_placeholder')}
                                onChange={(e) => setFieldValue('tenantId', e)}
                                value={values.tenantId}
                            >
                                {tenants.map((t) => (
                                    <Option key={t.id} value={t.id}>
                                        {t.description}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </>
                );
            }}
        </FormModalLayout>
    );
};

const tenantsSelect = (store: Store) => store.tenant;
const selector = createSelector([tenantsSelect],
    (t) => ({
        tenants: Array.from(t.values()),
    }));
const mapStoreToProps = (store: Store) => selector(store);

export default connect(mapStoreToProps)(AddDnsZone);
