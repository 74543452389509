import React, { FC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { InnerPageLayout, LoaderPageLayout, ServerNotExist, useIntl } from 'Common';
import { useServerInProgress, useTitle, useServerNotExist, useServerSearchSave } from 'Hooks';
import Server from 'Entities/Server';
import { DistributionFamily } from 'Entities/DistributionFamily';
import theme from 'Lib/theme';
import { Store } from 'Store';

import { Header, ChangePassword, Setting, PublicKeys } from './components';

interface ServerAccessStoreProps {
    server: Server | undefined;
}
type ServerAccessOwnProps = RouteComponentProps<{ serverId: string }>;
type ServerAccessProps = ServerAccessOwnProps & ServerAccessStoreProps;
const ServerAccess: FC<ServerAccessProps> = ({ server, match: { params: { serverId } } }) => {
    const intl = useIntl();

    const pageTitle = server?.name
        ? intl.getMessage('server_access_page_title', { value: server.name })
        : intl.getMessage('page_title');
    useTitle(pageTitle);
    const isProgress = useServerInProgress(Number(serverId));

    const noServer = useServerNotExist();

    useServerSearchSave(Number(serverId), noServer);

    if (noServer) {
        return <ServerNotExist />;
    }

    const getContent = () => {
        if (!server || isProgress) {
            return <LoaderPageLayout />;
        }
        return (
            <>
                <ChangePassword server={server} />
                {server.distribution.type !== DistributionFamily.WINDOWS && (
                    <PublicKeys server={server} />
                )}
                <Setting server={server} />
            </>
        );
    };

    return (
        <InnerPageLayout
            header={<Header server={server!} />}
            className={theme.content.with_actions}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

const selectServer = (store: Store, ownProps: ServerAccessOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return store.server?.get(Number(serverId));
};
const selector = createSelector([selectServer], (server) => server);
const mapStoreToProps = (store: Store, ownProps: ServerAccessOwnProps) => ({
    server: selector(store, ownProps),
});
export default withRouter(connect(mapStoreToProps)(ServerAccess));
