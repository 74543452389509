// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerTransferCreate {
    amount: number;
}

export default class ResellerTransferCreate {
    readonly _amount: number;

    /**
     * Description: Transfer amount, RUB
     * Example: 600
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    constructor(props: IResellerTransferCreate) {
        this._amount = props.amount;
    }

    serialize(): IResellerTransferCreate {
        const data: IResellerTransferCreate = {
            amount: this._amount,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            amount: typeof this._amount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerTransferCreate): ResellerTransferCreate {
        return new ResellerTransferCreate(props);
    }

    readonly keys: { [key: string]: string } = {
        amount: 'amount',
        }
;

    mergeDeepWith(props: Partial<ResellerTransferCreate>): ResellerTransferCreate {
        const updateData: Partial<IResellerTransferCreate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerTransferCreate) => {
            const updateKey = this.keys[key] as keyof IResellerTransferCreate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerTransferCreate, keyof IResellerTransferCreate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerTransferCreate({ ...this.serialize(), ...updateData });
    }
}
