import { takeEvery, put, call, select } from 'redux-saga/effects';
import dayjs from 'dayjs';

import SupportApi from 'Apis/support';
import {
    SupportActions,
    getTicket as getTicketAct,
    createTicketPost as createTicketPostAct,
    listTickets as listTicketsAct,
    createTicket as createTicketAct,
    saveTickets,
    saveTicket,
    saveCurrentTicket,
    clearTicket,
    getTicketsInfo as getTicketsInfoAct,
    saveTicketsInfo,
    markTicketsRead as markTicketsReadAct,
} from 'Actions/support';
import { REQUEST_DATE_FORMAT } from 'Lib/helpers/consts';
import Ticket, { ITicket } from 'Entities/Ticket';
import TicketsInfo, { ITicketsInfo } from 'Entities/TicketsInfo';
import { Store } from 'Store';

import { errorChecker, addError } from './utils';

function* getTicket(action: ReturnType<typeof getTicketAct>) {
    const { payload } = action;
    const tickets: Ticket[] = yield select((s: Store) => s.support.tickets);
    const { req: [id], update } = payload;
    const current = tickets?.find((t) => t.id === id);
    if (current && !update) {
        yield put(saveCurrentTicket(current));
    }
    const response: ITicket = yield call(SupportApi.getTicket, ...payload.req);
    const { error, result } = errorChecker<ITicket>(response);
    if (result) {
        yield put(saveCurrentTicket(new Ticket(result)));
        yield put(getTicketsInfoAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createTicketPost(action: ReturnType<typeof createTicketPostAct>) {
    const { payload, callback } = action;
    const [id, post] = payload;
    const ticket: Ticket = yield select((s: Store) => s.support.currentTicket);
    const iTicket = ticket.serialize();
    const newTicket = new Ticket({
        ...iTicket,
        posts: [...(iTicket.posts || []),
            {
                contents: JSON.parse(post.get('params') as string).contents,
                staff: false,
                send_time: dayjs(new Date()).format(REQUEST_DATE_FORMAT),
                send_time_millis: Date.now(),
                unread: false,
                email: 'temp',
                attachments: [],
            },
        ],
    });
    yield put(saveCurrentTicket(newTicket));
    post.set('params', new Blob([post.get('params') || ''], { type: 'application/json' }));
    const response: number = yield call(SupportApi.createTicketPost, id, post);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getTicketAct({ req: [id], update: true }));
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* listTickets(action: ReturnType<typeof listTicketsAct>) {
    const { payload } = action;
    const response: ITicket[] = yield call(SupportApi.listTickets, ...payload);
    const { error, result } = errorChecker<ITicket[]>(response);
    if (result) {
        yield put(saveTickets(result.map((r) => new Ticket(r))));
        yield put(getTicketsInfoAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createTicket(action: ReturnType<typeof createTicketAct>) {
    yield put(clearTicket());
    const { payload, callback } = action;
    const response: ITicket = yield call(SupportApi.createTicket, ...payload);
    const { error, result } = errorChecker<ITicket>(response);
    if (result) {
        if (callback?.result) {
            // used for reviews (RateUs)
            callback.result();
        } else {
            yield put(saveTicket(new Ticket(result)));
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getTicketsInfo(action: ReturnType<typeof getTicketsInfoAct>) {
    const response: ITicketsInfo = yield call(SupportApi.getTicketsInfo);
    const { error, result } = errorChecker<ITicketsInfo>(response);
    if (result) {
        yield put(saveTicketsInfo(new TicketsInfo(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* markTicketsRead(action: ReturnType<typeof markTicketsReadAct>) {
    const { payload, callback } = action;
    const response: number = yield call(SupportApi.markTicketsRead, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getTicketsInfoAct());

        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* supportSaga(): Generator {
    yield takeEvery<ReturnType<typeof getTicketAct>>(
        SupportActions.GetTicket,
        getTicket,
    );
    yield takeEvery<ReturnType<typeof createTicketPostAct>>(
        SupportActions.CreateTicketPost,
        createTicketPost,
    );
    yield takeEvery<ReturnType<typeof listTicketsAct>>(
        SupportActions.ListTickets,
        listTickets,
    );
    yield takeEvery<ReturnType<typeof createTicketAct>>(
        SupportActions.CreateTicket,
        createTicket,
    );
    yield takeEvery<ReturnType<typeof getTicketsInfoAct>>(
        SupportActions.GetTicketsInfo,
        getTicketsInfo,
    );
    yield takeEvery<ReturnType<typeof markTicketsReadAct>>(
        SupportActions.MarkTicketsRead,
        markTicketsRead,
    );
}

export default supportSaga;
