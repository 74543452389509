// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerLimits {
    max_client_discount: number;
    max_client_transfer_amount: number;
    min_withdrawal_amount: number;
}

export default class ResellerLimits {
    readonly _max_client_discount: number;

    /**
     * Description: Maximum discount for reseller's sub-client, abs. value
     * Example: 15
     */
    get maxClientDiscount(): number {
        return this._max_client_discount;
    }

    static maxClientDiscountValidate(maxClientDiscount: number): boolean {
        return typeof maxClientDiscount === 'number';
    }

    readonly _max_client_transfer_amount: number;

    /**
     * Description: Maximum possible amount for client transfer, RUB
     * Example: 999999
     */
    get maxClientTransferAmount(): number {
        return this._max_client_transfer_amount;
    }

    static maxClientTransferAmountValidate(maxClientTransferAmount: number): boolean {
        return typeof maxClientTransferAmount === 'number';
    }

    readonly _min_withdrawal_amount: number;

    /**
     * Description: Minimal possible withdrawal amount, RUB
     * Example: 10
     */
    get minWithdrawalAmount(): number {
        return this._min_withdrawal_amount;
    }

    static minWithdrawalAmountValidate(minWithdrawalAmount: number): boolean {
        return typeof minWithdrawalAmount === 'number';
    }

    constructor(props: IResellerLimits) {
        this._max_client_discount = props.max_client_discount;
        this._max_client_transfer_amount = props.max_client_transfer_amount;
        this._min_withdrawal_amount = props.min_withdrawal_amount;
    }

    serialize(): IResellerLimits {
        const data: IResellerLimits = {
            max_client_discount: this._max_client_discount,
            max_client_transfer_amount: this._max_client_transfer_amount,
            min_withdrawal_amount: this._min_withdrawal_amount,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            min_withdrawal_amount: typeof this._min_withdrawal_amount === 'number',
            max_client_discount: typeof this._max_client_discount === 'number',
            max_client_transfer_amount: typeof this._max_client_transfer_amount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerLimits): ResellerLimits {
        return new ResellerLimits(props);
    }

    readonly keys: { [key: string]: string } = {
        maxClientDiscount: 'max_client_discount',
        maxClientTransferAmount: 'max_client_transfer_amount',
        minWithdrawalAmount: 'min_withdrawal_amount',
        }
;

    mergeDeepWith(props: Partial<ResellerLimits>): ResellerLimits {
        const updateData: Partial<IResellerLimits> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerLimits) => {
            const updateKey = this.keys[key] as keyof IResellerLimits;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerLimits, keyof IResellerLimits>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerLimits({ ...this.serialize(), ...updateData });
    }
}
