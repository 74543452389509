import TenantUser from 'Entities/TenantUser';
import { actionCreator } from '.';

export enum TenantUsersActions {
    GetTenantsUsers = 'GetTenantsUsers',
    SaveTenantsUsers = 'SaveTenantsUsers',
}

const getTenantsUsersList = actionCreator(TenantUsersActions.GetTenantsUsers);
const saveTenantsUsersList = actionCreator<TenantUser[]>(TenantUsersActions.SaveTenantsUsers);

export {
    getTenantsUsersList,
    saveTenantsUsersList,
};
