import AccountApi from 'Apis/account';
import { ClientType } from 'Entities/ClientType';
import { IOrganizationDetails } from 'Entities/OrganizationDetails';
import { errorChecker } from 'Lib/helpers/utils';

export const checkVat = async (inn: string, clientType: ClientType) => {
    const req = await AccountApi.getOrganizationDetailsByINN({
        inn,
        client_type: clientType,
    });
    return errorChecker<IOrganizationDetails>(req);
};
