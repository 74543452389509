import React, { FC, useEffect } from 'react';

import FormHelper, { NewServerFormValues } from 'Lib/helpers/newServer';
import CustomTariffRows from 'Common/Server/CustomTariffRows';
import ResourceLimits from 'Entities/ServerResourceLimits';

import s from './Tariff.module.pcss';

interface CustomTariffProps {
    resLimits: ResourceLimits;
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
    values: NewServerFormValues;
}

const CustomTariff: FC<CustomTariffProps> = ({
    setFieldValue, values, resLimits,
}) => {
    const {
        memory_mib: memoryMib,
        cpu_cores: cpuCores,
        disk_gib: diskGib,
        ip_count: ipCount,
    } = values;

    useEffect(() => {
        const countedMinMemory = FormHelper.countMinMemory(
            diskGib,
            resLimits.memoryDiskRate,
            resLimits.stepMemoryMib,
        );
        if (memoryMib < countedMinMemory) {
            setFieldValue('memory_mib', countedMinMemory);
        }
    }, [memoryMib, diskGib]);

    return (
        <div className={s.rows}>
            <CustomTariffRows
                resLimits={resLimits}
                memory={memoryMib}
                setMemory={(e) => setFieldValue('memory_mib', e)}
                processor={cpuCores}
                setProcessor={(e) => setFieldValue('cpu_cores', e)}
                disk={diskGib}
                setDisk={(e) => setFieldValue('disk_gib', e)}
                publicIp={ipCount}
                setPublicIp={(e) => setFieldValue('ip_count', e)}
                noChangeIpCount={values.noChangeIpCount}
                newServer
            />
        </div>
    );
};

export default CustomTariff;
