import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link, DashboardHeader, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

interface HeaderProps {
    canPay: boolean;
}

const Header: FC<HeaderProps> = ({ canPay }) => {
    const intl = useIntl();

    return (
        <DashboardHeader
            navigation={(
                <>
                    <div className={cn(theme.header.link, theme.header.active)}>
                        {intl.getMessage('menu_billing')}
                    </div>
                    <Link
                        to={RoutePath.Expenses}
                        className={theme.header.link}
                    >
                        {intl.getMessage('billing_expenses')}
                    </Link>
                    <Link
                        to={RoutePath.Documents}
                        className={theme.header.link}
                    >
                        {intl.getMessage('billing_documents')}
                    </Link>
                </>
            )}
            actions={(
                canPay ? (
                    <Link to={RoutePath.AddFunds} className={theme.header.button}>
                        <Button
                            type="primary"
                            size="medium"
                        >
                            {intl.getMessage('billing_add_funds')}
                        </Button>
                    </Link>
                ) : (
                    <Link to={RoutePath.Onboarding}>
                        <Button
                            type="primary"
                            size="medium"
                        >
                            {intl.getMessage('billing_add_funds')}
                        </Button>
                    </Link>
                )
            )}
        />
    );
};

export default Header;
