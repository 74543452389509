// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum CardAccounts {
    PERSONAL = 'PERSONAL',
    PERSONAL_PARTNER = 'PERSONAL_PARTNER',
    PERSONAL_RESELLER = 'PERSONAL_RESELLER',
    PERSONAL_BONUS = 'PERSONAL_BONUS',
    DEPOSIT_NOCASH = 'DEPOSIT_NOCASH',
    DEPOSIT_TRANSIT = 'DEPOSIT_TRANSIT',
    DEPOSIT_CASH = 'DEPOSIT_CASH',
    DEPOSIT_BONUS = 'DEPOSIT_BONUS',
    DEPOSIT_COMPENSATION = 'DEPOSIT_COMPENSATION',
    PSP_BANK = 'PSP_BANK',
    PSP_YAMONEY = 'PSP_YAMONEY',
    PSP_ASSIST = 'PSP_ASSIST',
    PSP_DENGI_ONLINE = 'PSP_DENGI_ONLINE',
    PSP_PLATRON = 'PSP_PLATRON',
    PSP_PLIMUS = 'PSP_PLIMUS',
    PSP_PAYPAL = 'PSP_PAYPAL',
    PSP_PAYONLINE = 'PSP_PAYONLINE',
    PSP_CLOUDPAYMENTS = 'PSP_CLOUDPAYMENTS',
    SERVICE_RENTAL_FEE = 'SERVICE_RENTAL_FEE',
    SERVICE_CPU = 'SERVICE_CPU',
    SERVICE_MEMORY = 'SERVICE_MEMORY',
    SERVICE_STORAGE = 'SERVICE_STORAGE',
    SERVICE_IP = 'SERVICE_IP',
    SERVICE_TRAFFIC = 'SERVICE_TRAFFIC',
    SERVICE_TRAFFIC_RECEIVED = 'SERVICE_TRAFFIC_RECEIVED',
    SERVICE_SMS = 'SERVICE_SMS',
    SERVICE_BACKUP = 'SERVICE_BACKUP',
    SERVICE_RESTORE = 'SERVICE_RESTORE',
    SERVICE_TRANSFER = 'SERVICE_TRANSFER',
    SERVICE_SUPPORT = 'SERVICE_SUPPORT',
    SERVICE_RESELLING = 'SERVICE_RESELLING',
    SERVICE_WIN_RDP_SVCS_SAL = 'SERVICE_WIN_RDP_SVCS_SAL',
    SERVICE_MSSQL_SERVER = 'SERVICE_MSSQL_SERVER',
    SERVICE_MSSQL_SERVICE_WEB = 'SERVICE_MSSQL_SERVICE_WEB',
    SERVICE_SOFTWARE_LICENSE = 'SERVICE_SOFTWARE_LICENSE',
    BONUS_SERVICE_RENTAL_FEE = 'BONUS_SERVICE_RENTAL_FEE',
    BONUS_SERVICE_CPU = 'BONUS_SERVICE_CPU',
    BONUS_SERVICE_MEMORY = 'BONUS_SERVICE_MEMORY',
    BONUS_SERVICE_STORAGE = 'BONUS_SERVICE_STORAGE',
    BONUS_SERVICE_IP = 'BONUS_SERVICE_IP',
    BONUS_SERVICE_TRAFFIC = 'BONUS_SERVICE_TRAFFIC',
    BONUS_SERVICE_TRAFFIC_RECEIVED = 'BONUS_SERVICE_TRAFFIC_RECEIVED',
    BONUS_SERVICE_SMS = 'BONUS_SERVICE_SMS',
    BONUS_SERVICE_TRIAL_EXPIRED = 'BONUS_SERVICE_TRIAL_EXPIRED',
    BONUS_SERVICE_BACKUP = 'BONUS_SERVICE_BACKUP',
    BONUS_SERVICE_RESTORE = 'BONUS_SERVICE_RESTORE',
    BONUS_SERVICE_TRANSFER = 'BONUS_SERVICE_TRANSFER',
    BONUS_SERVICE_SUPPORT = 'BONUS_SERVICE_SUPPORT',
    PAYOUTS_REFUNDS = 'PAYOUTS_REFUNDS',
    TOPAY = 'TOPAY',
    PAID = 'PAID',
    SOLAR_STAFF = 'SOLAR_STAFF',
    MARKETING = 'MARKETING',
    RESELLING = 'RESELLING',
    PARTNER_TRANSFER = 'PARTNER_TRANSFER',
    RESELLER_TRANSFER = 'RESELLER_TRANSFER'
}
