import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import cn from 'classnames';

import { useIntl, DashboardHeader, Link } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { NOTIFICATIONS_STATE } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

const Header: FC = () => {
    const intl = useIntl();
    const { params: { unread } } = useRouteMatch<{ unread: NOTIFICATIONS_STATE }>();

    return (
        <DashboardHeader
            navigation={(
                <>
                    <Link
                        to={RoutePath.Notifications}
                        props={{ unread: NOTIFICATIONS_STATE.UNREAD }}
                        className={cn(
                            theme.header.link,
                            { [theme.header.active]: unread === NOTIFICATIONS_STATE.UNREAD },
                        )}
                    >
                        {intl.getMessage('notifications_unread')}
                    </Link>
                    <Link
                        to={RoutePath.Notifications}
                        props={{ unread: NOTIFICATIONS_STATE.HISTORY }}
                        className={cn(
                            theme.header.link,
                            { [theme.header.active]: unread === NOTIFICATIONS_STATE.HISTORY },
                        )}
                    >
                        {intl.getMessage('notifications_read')}
                    </Link>
                    <Link
                        to={RoutePath.Triggers}
                        className={cn(
                            theme.header.link,
                            theme.header.link_larger,
                        )}
                    >
                        {intl.getMessage('triggers_title')}
                    </Link>
                    <Link
                        to={RoutePath.NotificationRecipients}
                        className={cn(
                            theme.header.link,
                            theme.header.link_larger,
                        )}
                    >
                        {intl.getMessage('notifications_recipients_settings')}
                    </Link>
                </>
            )}
        />
    );
};

export default Header;
