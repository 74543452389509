import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setOperationNotifications } from 'Actions/ui';
import { useIntl, CommonModalLayout } from 'Common';
import { SERVER_OPERATION_NOTIFICATION } from 'Lib/helpers/consts';

import { ModalCheckbox } from 'Common/Server/Actions/components';
import Server from 'Entities/Server';

interface RebootServersModalProps {
    visible: boolean;
    close: () => void;
    handleSubmit: () => void;
    servers: Set<Server>;
}

const RebootServersModal: FC<RebootServersModalProps> = ({
    visible,
    close,
    handleSubmit,
    servers,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [checked, setChecked] = useState(false);

    const handleChecked = (isChecked: boolean) => {
        setChecked(isChecked);
        dispatch(setOperationNotifications(SERVER_OPERATION_NOTIFICATION.REBOOT));
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('server_soft_reboot')}
            handleSubmit={handleSubmit}
            handleClose={close}
            buttonText={intl.getMessage('reboot')}
            width={480}
        >
            <div className="modal__desc">
                {intl.getMessage('modal_reboot_desc')}
            </div>
            <div className="modal__desc">
                <div>
                    {intl.getMessage('modal_reboot_desc_two')}
                </div>
                <div>
                    {intl.getPlural('batch_reboot_desc_three', servers.size)}
                </div>
            </div>
            <ModalCheckbox
                checked={checked}
                handleChange={handleChecked}
            />
        </CommonModalLayout>
    );
};

export default RebootServersModal;
