import React, { FC, useState } from 'react';
import { Select, Input } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';

import { useIntl, CommonModalLayout } from 'Common';
import Tenant from 'Entities/Tenant';
import Server from 'Entities/Server';
import Tariff from 'Entities/Tariff';
import { DistributionFamily } from 'Entities/DistributionFamily';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';

import Info from './Info';

const { Option } = Select;

interface ModalCreateFromSpanshotStoreProps {
    tenants: Tenant[];
}
interface ModalCreateFromSpanshotOwnProps {
    visible: boolean;
    close: () => void;
    onOk: (tenantId: number, name: string) => void;
    server: Server;
    tariff: Tariff | undefined;
}

type ModalCreateFromSpanshotProps = ModalCreateFromSpanshotOwnProps
& ModalCreateFromSpanshotStoreProps;

const ModalCreateFromSpanshot: FC<ModalCreateFromSpanshotProps> = (
    { visible, onOk, close, tenants, server, tariff },
) => {
    const {
        distribution,
        memoryMib,
        diskGib,
        cpuCores,
        costForecast,
    } = server || {};
    const intl = useIntl();
    const history = useHistory();
    const [currentTenant, setCurrentTenant] = useState(tenants[0].id);
    const [name, setName] = useState('');

    const handleOk = () => {
        const serverSnapshotName = `${server.name} - snapshot`;
        onOk(currentTenant, name || serverSnapshotName);
        close();
    };

    const handleClose = () => {
        close();
    };

    if (distribution.type === DistributionFamily.WINDOWS) {
        return (
            <CommonModalLayout
                visible
                title={intl.getMessage('snapshots_create_from_title')}
                buttonText={intl.getMessage('create_server')}
                handleClose={handleClose}
                handleSubmit={() => {
                    history.push(linkPathBuilder(intl.currentLocale, RoutePath.NewServer));
                }}
                width={600}
                centered
            >
                <div className="modal__desc">
                    {intl.getMessage('modal_server_from_snapshot_windows')}
                </div>
            </CommonModalLayout>
        );
    }

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('snapshots_create_from_title')}
            handleSubmit={handleOk}
            handleClose={handleClose}
            submitButtonId="createFromSnapshot"
            buttonText={intl.getMessage('create')}
            width={600}
            centered
        >
            <div className="modal__desc">
                {intl.getMessage('snapshots_create_from_desc')}
            </div>
            <div className="modal__desc">
                <div className="modal__label">
                    {intl.getMessage('snapshots_create_from_project')}
                </div>
                <Select
                    id="tenant_id"
                    size="large"
                    className="select select--block select--big"
                    value={currentTenant}
                    onChange={(e) => setCurrentTenant(e)}
                    suffixIcon={(
                        <svg className="icon select__arrow">
                            <use xlinkHref="#down" />
                        </svg>
                    )}
                    dropdownClassName="select-dropdown"
                >
                    {tenants.map((t) => (
                        <Option key={t.id} value={t.id}>
                            {t.description}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="modal__desc">
                <div className="modal__label">
                    {intl.getMessage('server_name_title')}
                </div>
                <Input
                    id="name"
                    size="large"
                    className="input"
                    placeholder={intl.getMessage('server_name_input')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                />
            </div>
            <div className="modal__table">
                <div className="modal__title">
                    {intl.getMessage('snapshots_create_from_parameters')}
                </div>
                <Info
                    memory={memoryMib}
                    disk={diskGib}
                    cpuCores={cpuCores}
                    distribution={distribution}
                    costForecast={costForecast}
                    tariff={tariff}
                />
            </div>
        </CommonModalLayout>
    );
};

const selectTenants = (store: Store) => store.tenant;
const selector = createSelector(selectTenants, (t) => {
    return Array.from(t.values());
});
const mapStateToProps = (store: Store) => {
    return {
        tenants: selector(store),
    };
};
export default connect(mapStateToProps)(ModalCreateFromSpanshot);
