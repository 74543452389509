import React, { FC, useEffect } from 'react';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';

import { useTitle } from 'Hooks';
import { getPartnerDetails } from 'Actions/partner';
import { SolarStaffRegistration, useIntl } from 'Common';
import { Store } from 'Store';

import PaymentForm from './PaymentForm';

interface OrderPaymentStoreProps {
    details: Store['partner']['details'];
}

const OrderPayment: FC<OrderPaymentStoreProps> = ({ details }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('partner_order_payment_page_title'));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getPartnerDetails());
    }, []);
    if (!details) {
        return null;
    }
    if (details.paymentType) {
        return <PaymentForm details={details} />;
    }

    return <SolarStaffRegistration />;
};

const selectPartnerDetails = (store: Store) => store.partner.details;

const selector = createSelector(
    [selectPartnerDetails],
    (details) => ({ details }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(OrderPayment);
