// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerConfigurationUpdate {
    allow_restart?: boolean;
    cpu_cores?: number;
    disk_gib?: number;
    memory_mib?: number;
    tariff_id?: number;
}

export default class ServerConfigurationUpdate {
    readonly _allow_restart: boolean | undefined;

    /**
     * Description: Allow restart. Should be defined for memory update
     * Example: true
     */
    get allowRestart(): boolean | undefined {
        return this._allow_restart;
    }

    readonly _cpu_cores: number | undefined;

    /**
     * Description: CPU cores
     * Example: 1
     */
    get cpuCores(): number | undefined {
        return this._cpu_cores;
    }

    readonly _disk_gib: number | undefined;

    /**
     * Description: Disk capacity in gib
     * Example: 16
     */
    get diskGib(): number | undefined {
        return this._disk_gib;
    }

    readonly _memory_mib: number | undefined;

    /**
     * Description: Memory in mib
     * Example: 512
     */
    get memoryMib(): number | undefined {
        return this._memory_mib;
    }

    readonly _tariff_id: number | undefined;

    /**
     * Description: New tariff identifier
     * Example: 10
     */
    get tariffId(): number | undefined {
        return this._tariff_id;
    }

    constructor(props: IServerConfigurationUpdate) {
        if (typeof props.allow_restart === 'boolean') {
            this._allow_restart = props.allow_restart;
        }
        if (typeof props.cpu_cores === 'number') {
            this._cpu_cores = props.cpu_cores;
        }
        if (typeof props.disk_gib === 'number') {
            this._disk_gib = props.disk_gib;
        }
        if (typeof props.memory_mib === 'number') {
            this._memory_mib = props.memory_mib;
        }
        if (typeof props.tariff_id === 'number') {
            this._tariff_id = props.tariff_id;
        }
    }

    serialize(): IServerConfigurationUpdate {
        const data: IServerConfigurationUpdate = {
        };
        if (typeof this._allow_restart !== 'undefined') {
            data.allow_restart = this._allow_restart;
        }
        if (typeof this._cpu_cores !== 'undefined') {
            data.cpu_cores = this._cpu_cores;
        }
        if (typeof this._disk_gib !== 'undefined') {
            data.disk_gib = this._disk_gib;
        }
        if (typeof this._memory_mib !== 'undefined') {
            data.memory_mib = this._memory_mib;
        }
        if (typeof this._tariff_id !== 'undefined') {
            data.tariff_id = this._tariff_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            tariff_id: !this._tariff_id ? true : typeof this._tariff_id === 'number',
            memory_mib: !this._memory_mib ? true : typeof this._memory_mib === 'number',
            allow_restart: !this._allow_restart ? true : typeof this._allow_restart === 'boolean',
            disk_gib: !this._disk_gib ? true : typeof this._disk_gib === 'number',
            cpu_cores: !this._cpu_cores ? true : typeof this._cpu_cores === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerConfigurationUpdate): ServerConfigurationUpdate {
        return new ServerConfigurationUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        allowRestart: 'allow_restart',
        cpuCores: 'cpu_cores',
        diskGib: 'disk_gib',
        memoryMib: 'memory_mib',
        tariffId: 'tariff_id',
        }
;

    mergeDeepWith(props: Partial<ServerConfigurationUpdate>): ServerConfigurationUpdate {
        const updateData: Partial<IServerConfigurationUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerConfigurationUpdate) => {
            const updateKey = this.keys[key] as keyof IServerConfigurationUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerConfigurationUpdate, keyof IServerConfigurationUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerConfigurationUpdate({ ...this.serialize(), ...updateData });
    }
}
