import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { deleteServers } from 'Actions/server';
import { CodeInput, DeleteModalLayout, Loader, notifyError, useIntl } from 'Common';
import Server from 'Entities/Server';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { useSecurityBatchActions } from 'Lib/hooks/hooks';
import { notifySuccess, whiteText } from 'Common/ui';
import { OperationType } from 'Entities/OperationType';
import { IBatchErrorServer } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

export enum ModalServersRemoveType {
    IP,
    SERVER,
}

interface ModalServersRemoveProps {
    type: ModalServersRemoveType;
    visible: boolean;
    onClose: () => void;
    servers: Set<Server>;
    setVisiblePopover: React.Dispatch<React.SetStateAction<boolean>>;
    clearSelection: () => void;
}

const ModalServersRemove: FC<ModalServersRemoveProps> = ({
    visible,
    onClose,
    servers,
    setVisiblePopover,
    clearSelection,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const serversValues = Array.from(servers.values());
    const servNames = serversValues.map((serv) => serv.name).join(', ');
    const serversIds = serversValues.map((serv) => serv.id);
    const { tenantId: id } = serversValues[0];

    const title = intl.getPlural('batch_deleting_modal_title', servers.size);
    const buttonText = title;
    const desc = intl.getPlural('batch_deleting_modal_text', servers.size, { text: servNames || '' });

    const callBacker = (props: {
        loaderTitle: JSX.Element;
        allSuccess: JSX.Element;
        withErrors: (serverNames: string[]) => JSX.Element;
        notificationId: string;
    }) => {
        return () => {
            notifyError((
                <div className={theme.notification.wrapper}>
                    <div className={theme.notification.text}>
                        {props.loaderTitle}
                    </div>
                    <div className={theme.color.blue}>
                        <Loader circle mini className={theme.notification.loader} />
                    </div>
                </div>
            ), undefined, {
                key: props.notificationId,
                duration: 0,
                className: cn(theme.notification.customNotification, 'notification_event'),
            });
            onClose();
            setVisiblePopover(false);
            clearSelection();
            return (errorServers?: IBatchErrorServer[]) => {
                if (!errorServers) {
                    serversValues.forEach((sr) => {
                        UserStorage.removeServerRange(sr);
                    });

                    notifySuccess(props.allSuccess, undefined, {
                        className: theme.notification.customNotification,
                    });
                } else {
                    const names = errorServers.map((sr) => sr.name);
                    const ids = errorServers.map((sr) => sr.id);
                    const successfullyDeleted = serversValues.filter((sr) => !ids.includes(sr.id));
                    successfullyDeleted.forEach((sr) => {
                        UserStorage.removeServerRange(sr);
                    });

                    notifyError(props.withErrors(names));
                }
            };
        };
    };

    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityBatchActions(SecurityActionType.SERVER_DELETE, true);
    const handleOk = () => {
        if (shouldConfirmAction && !codeSent) {
            sendConfirmationCode({
                tenantId: id,
                serverIds: serversIds,
            });
        }
        if (!shouldConfirmAction) {
            dispatch(deleteServers({
                data: [id, {
                    actionType: SecurityActionType.SERVER_DELETE,
                    server_ids: serversIds,
                }],
                notificationId: OperationType.DELETE,
            }, {
                result: callBacker({
                    loaderTitle: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_deleting_loader', servers.size, { b: whiteText })}
                        </div>
                    ),
                    allSuccess: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_deleting_success', servers.size, { b: whiteText })}
                        </div>
                    ),
                    withErrors: (serverNames) => {
                        setCodeError(true);
                        return (
                            <div className={theme.notification.text}>
                                {intl.getPlural('batch_deleting_error', serverNames.length, { b: whiteText })}
                            </div>
                        );
                    },
                    notificationId: OperationType.DELETE,
                }),
            }));
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(deleteServers({
                data: [id, {
                    actionType: SecurityActionType.SERVER_DELETE,
                    server_ids: serversIds,
                    security_code: code,
                }],
                notificationId: OperationType.DELETE,
            },
            {
                result: callBacker({
                    loaderTitle: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_deleting_loader', servers.size, { b: whiteText })}
                        </div>
                    ),
                    allSuccess: (
                        <div className={theme.notification.text}>
                            {intl.getPlural('batch_deleting_success', servers.size, { b: whiteText })}
                        </div>
                    ),
                    withErrors: (serverNames) => {
                        setCodeError(true);
                        return (
                            <div className={theme.notification.text}>
                                {intl.getPlural('batch_deleting_error', serverNames.length, { b: whiteText })}
                            </div>
                        );
                    },
                    notificationId: OperationType.DELETE,
                }),
            }));
        }
    };
    return (
        <DeleteModalLayout
            visible={visible}
            title={title}
            buttonText={buttonText}
            handleClose={onClose}
            handleDelete={handleOk}
        >
            <div className="modal__desc">
                {desc}
            </div>
            {(codeSent && deliveryMessage) && (
                <>
                    <div className="modal__desc modal__desc--gray">
                        {deliveryMessage}
                    </div>
                    <CodeInput
                        value={code}
                        setValue={(e) => setCode(e)}
                        codeError={codeError}
                        setCodeError={(e) => setCodeError(e)}
                        onSendAgain={() => sendConfirmationCode({
                            tenantId: id,
                            serverIds: serversIds,
                        })}
                    />
                </>
            )}
        </DeleteModalLayout>
    );
};

export default ModalServersRemove;
