import React, { FC } from 'react';
import { Modal } from 'antd';

import { Button, Icon, Link, useIntl } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';

interface ModalWindowsCloneProps {
    close: () => void;
}

const ModalWindowsClone: FC<ModalWindowsCloneProps> = ({ close }) => {
    const intl = useIntl();

    return (
        <Modal
            visible
            title={intl.getMessage('clone_server')}
            wrapClassName="modal"
            onCancel={close}
            closeIcon={<Icon icon="close_big" />}
            footer={(
                <Link to={RoutePath.NewServer} key="link">
                    <Button
                        type="primary"
                        size="medium"
                    >
                        {intl.getMessage('create_server')}
                    </Button>
                </Link>
            )}
            width={480}
            centered
        >
            <div className="modal__desc">
                {intl.getMessage('modal_clone_windows')}
            </div>
        </Modal>
    );
};

export default ModalWindowsClone;
