import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import DnsPtrCreateOrUpdate, { IDnsPtrCreateOrUpdate } from 'Entities/DnsPtrCreateOrUpdate';
import DnsPtrDelete, { IDnsPtrDelete } from 'Entities/DnsPtrDelete';
import DnsPtrRecord, { IDnsPtrRecord } from 'Entities/DnsPtrRecord';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class ReverseDnsApi {
    static async createPtrRecord(tenant_id: number, dnsptrcreateorupdate: IDnsPtrCreateOrUpdate, optionalToken?: string): Promise<IDnsPtrRecord | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const dnsptrcreateorupdateValid = new DnsPtrCreateOrUpdate(dnsptrcreateorupdate);
        haveError.push(...dnsptrcreateorupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/ptrs`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dnsptrcreateorupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deletePtrRecord(tenant_id: number, dnsptrdelete: IDnsPtrDelete, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const dnsptrdeleteValid = new DnsPtrDelete(dnsptrdelete);
        haveError.push(...dnsptrdeleteValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/ptrs`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dnsptrdeleteValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPtrRecords(optionalToken?: string): Promise<IDnsPtrRecord[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/dns/ptrs`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updatePtrRecord(tenant_id: number, dnsptrcreateorupdate: IDnsPtrCreateOrUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const dnsptrcreateorupdateValid = new DnsPtrCreateOrUpdate(dnsptrcreateorupdate);
        haveError.push(...dnsptrcreateorupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/dns/ptrs`, {
            method: 'PUT',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dnsptrcreateorupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
