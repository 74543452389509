import React, { FC } from 'react';
import { Alert as Notice } from 'antd';
import cn from 'classnames';

import { Icon, useIntl, Button } from 'Common';
import theme from 'Lib/theme';

import s from './Alert.module.pcss';

const Alert: FC<{ online?: boolean }> = ({ online }) => {
    const intl = useIntl();

    const refreshButton = (text: string) => (
        <div className={s.refresh}>
            <Button
                type="link"
                key="refresh"
                onClick={() => window.location.reload()}
                className={cn(theme.link.link, theme.link.white, theme.text.regular)}
            >
                {text}
            </Button>
        </div>
    );

    const content = (
        <div className={s.content}>
            <Icon icon="attention" className={s.icon} />
            <div className={s.message}>
                {online
                    ? intl.getMessage('offline_alert')
                    : intl.getMessage('new_version_of_panel_alert', { button: refreshButton })}
            </div>
        </div>
    );

    return (
        <div className={s.alert}>
            <Notice
                className="alert"
                message={content}
                type="warning"
            />
        </div>
    );
};

export default Alert;
