import React, { FC } from 'react';

import { useIntl, Link } from 'Common';
import { DistributionFamily } from 'Entities/DistributionFamily';
import Server from 'Entities/Server';
import { RoutePath, linkPathBuilder } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

interface ConsoleLinkProps {
    server: Server;
}

const ConsoleLink: FC<ConsoleLinkProps> = ({ server }) => {
    const intl = useIntl();

    return (
        server.distribution.type === DistributionFamily.WINDOWS ? (
            <a
                target="blank"
                href={linkPathBuilder(
                    intl.currentLocale,
                    RoutePath.Vnc,
                    { serverId: server.id },
                )}
                className={theme.header.link}
            >
                {intl.getMessage('console')}
            </a>
        ) : (
            <Link
                to={RoutePath.Console}
                props={{ serverId: server.id }}
                className={theme.header.link}
            >
                {intl.getMessage('console')}
            </Link>
        )
    );
};

export default ConsoleLink;
