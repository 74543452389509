import React, { FC } from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';

import { useIntl, Icon, DetailedComparativeValues } from 'Common';
import ComparativeIncome from 'Entities/ComparativeIncome';
import { RoutePath } from 'Lib/helpers/routes';
import { REFERRAL_DISCOUNT } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import DashboardLink from './DashboardLink';
import s from './Stats.module.pcss';

interface ReferralProps {
    totalRegistrations: number;
    newRegistrations?: number;
    reseller: boolean;
    comparativeIncome: ComparativeIncome;
}

const Referral: FC<ReferralProps> = ({
    totalRegistrations,
    newRegistrations,
    reseller,
    comparativeIncome,
}) => {
    const intl = useIntl();

    const {
        incomeChangePercent,
        recentIncome,
        recentPeriod,
        previousIncome,
        previousPeriod,
    } = comparativeIncome;

    const roundedRecentIncome = Math.round(recentIncome);

    return (
        <div
            className={cn(
                s.block,
                { [s.placeholder]: totalRegistrations <= 0 },
            )}
        >
            {totalRegistrations > 0 ? (
                <Row>
                    <Col span={24} sm={reseller ? 24 : 12}>
                        <div className={s.info}>
                            <Icon icon="graphic" className={cn(s.icon, s.chart)} />
                            <div className={theme.score.label}>
                                {intl.getMessage('dashboard_balance_referral_total')}
                            </div>
                            <div className={cn(s.val, s.val_main)}>
                                <span className={s.valInner}>
                                    {intl.getMessage('price_simple', { value: roundedRecentIncome })}
                                </span>
                                <DetailedComparativeValues
                                    diff={incomeChangePercent}
                                    recentValue={recentIncome}
                                    recentPeriod={recentPeriod}
                                    previousValue={previousIncome}
                                    previousPeriod={previousPeriod}
                                    increasingLabel={intl.getMessage('dashboard_balance_referral_increased')}
                                    decreasingLabel={intl.getMessage('dashboard_balance_referral_decreased')}
                                    withCurrency
                                />
                            </div>
                        </div>
                    </Col>
                    {!reseller && (
                        <Col span={24} sm={12}>
                            <div className={s.info}>
                                <div className={s.icon} />
                                <div className={s.title}>
                                    {intl.getPlural('dashboard_stats_registrations', totalRegistrations)}
                                </div>
                                <div className={cn(s.val, s.val_main, s.val_badge)}>
                                    {Math.round(totalRegistrations)}
                                </div>
                                {(!!newRegistrations && newRegistrations > 0) && (
                                    <div className={cn(
                                        theme.Badge.badge,
                                        theme.Badge.middle,
                                        theme.Badge.yellow,
                                        theme.Badge.bold,
                                    )}
                                    >
                                        +{newRegistrations}
                                    </div>
                                )}
                            </div>
                        </Col>
                    )}
                </Row>
            ) : (
                <>
                    <Icon icon="graphic" className={cn(s.icon, s.chart)} />
                    <div className={s.body}>
                        <div className={s.label}>
                            {intl.getMessage('dashboard_no_referral', {
                                discount: `${REFERRAL_DISCOUNT}%`,
                            })}
                        </div>
                    </div>
                </>
            )}
            <DashboardLink to={RoutePath.Partner} />
        </div>
    );
};

export default Referral;
