import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import LoginForm, { ILoginForm } from 'Entities/LoginForm';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class AuthorizationApi {
    static async login(loginform: ILoginForm, xauthcookie?: string, hcaptcharesponse?: string): Promise<{ token: string, cookie: string | null } | ApiError | Error> {
        const xReqId = String(Date.now());
        const params =  qs.stringify(loginform);
        return await fetch(`/api/v1/login`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Auth-Cookie': xauthcookie || '',
                'H-Captcha-Response': hcaptcharesponse || '',
            },
            body: params.toString(),
        }).then(async (res) => {
            if (res.status === 200) {
                return {
                    token: res.headers.get('x-auth-token')!,
                    cookie: res.headers.get('x-auth-cookie'),
                };
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async logout(xauthtoken: string, xauthresellertoken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/logout`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': xauthtoken,
                'X-Auth-Reseller-Token': xauthresellertoken || '',
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async socialLogin(provider_id: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/auth/${provider_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
