// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IUpdateCorporation {
    corporation_accounting_email?: string;
    corporation_government_contract_id?: string;
    corporation_head_name: string;
    corporation_head_position: string;
    corporation_iec: string;
    corporation_legal_address: string;
    corporation_operation_basis: string;
    corporation_post_address: string;
}

export default class UpdateCorporation {
    readonly _corporation_accounting_email: string | undefined;

    /**
     * Description: Company accounting email
     * Example: a.denovitz@d-shop.org
     */
    get corporationAccountingEmail(): string | undefined {
        return this._corporation_accounting_email;
    }

    readonly _corporation_government_contract_id: string | undefined;

    /**
     * Description: Government contract id of the company
     * Example: 14124124124
     */
    get corporationGovernmentContractId(): string | undefined {
        return this._corporation_government_contract_id;
    }

    readonly _corporation_head_name: string;

    /**
     * Description: Full company head name
     * Example: Abraham "Avi" Denovitz
     */
    get corporationHeadName(): string {
        return this._corporation_head_name;
    }

    static corporationHeadNameValidate(corporationHeadName: string): boolean {
        return typeof corporationHeadName === 'string' && !!corporationHeadName.trim();
    }

    readonly _corporation_head_position: string;

    /**
     * Description: Head position of the company
     * Example: CEO
     */
    get corporationHeadPosition(): string {
        return this._corporation_head_position;
    }

    static corporationHeadPositionValidate(corporationHeadPosition: string): boolean {
        return typeof corporationHeadPosition === 'string' && !!corporationHeadPosition.trim();
    }

    readonly _corporation_iec: string;

    /**
     * Description: Company IEC (KPP)
     * Example: 123456789
     */
    get corporationIec(): string {
        return this._corporation_iec;
    }

    static corporationIecValidate(corporationIec: string): boolean {
        return typeof corporationIec === 'string' && !!corporationIec.trim();
    }

    readonly _corporation_legal_address: string;

    /**
     * Description: Company legal address
     * Example: 123456, Russia, Moscow, Lenin st, 12, 44
     */
    get corporationLegalAddress(): string {
        return this._corporation_legal_address;
    }

    static corporationLegalAddressValidate(corporationLegalAddress: string): boolean {
        return typeof corporationLegalAddress === 'string' && !!corporationLegalAddress.trim();
    }

    readonly _corporation_operation_basis: string;

    /**
     * Description: Company operation basis
     * Example: organization chart
     */
    get corporationOperationBasis(): string {
        return this._corporation_operation_basis;
    }

    static corporationOperationBasisValidate(corporationOperationBasis: string): boolean {
        return typeof corporationOperationBasis === 'string' && !!corporationOperationBasis.trim();
    }

    readonly _corporation_post_address: string;

    /**
     * Description: Company legal address
     * Example: 123456, Russia, Moscow, Lenin st, 11, 33
     */
    get corporationPostAddress(): string {
        return this._corporation_post_address;
    }

    static corporationPostAddressValidate(corporationPostAddress: string): boolean {
        return typeof corporationPostAddress === 'string' && !!corporationPostAddress.trim();
    }

    constructor(props: IUpdateCorporation) {
        if (typeof props.corporation_accounting_email === 'string') {
            this._corporation_accounting_email = props.corporation_accounting_email.trim();
        }
        if (typeof props.corporation_government_contract_id === 'string') {
            this._corporation_government_contract_id = props.corporation_government_contract_id.trim();
        }
        this._corporation_head_name = props.corporation_head_name.trim();
        this._corporation_head_position = props.corporation_head_position.trim();
        this._corporation_iec = props.corporation_iec.trim();
        this._corporation_legal_address = props.corporation_legal_address.trim();
        this._corporation_operation_basis = props.corporation_operation_basis.trim();
        this._corporation_post_address = props.corporation_post_address.trim();
    }

    serialize(): IUpdateCorporation {
        const data: IUpdateCorporation = {
            corporation_head_name: this._corporation_head_name,
            corporation_head_position: this._corporation_head_position,
            corporation_iec: this._corporation_iec,
            corporation_legal_address: this._corporation_legal_address,
            corporation_operation_basis: this._corporation_operation_basis,
            corporation_post_address: this._corporation_post_address,
        };
        if (typeof this._corporation_accounting_email !== 'undefined') {
            data.corporation_accounting_email = this._corporation_accounting_email;
        }
        if (typeof this._corporation_government_contract_id !== 'undefined') {
            data.corporation_government_contract_id = this._corporation_government_contract_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            corporation_iec: typeof this._corporation_iec === 'string' && !!this._corporation_iec.trim(),
            corporation_legal_address: typeof this._corporation_legal_address === 'string' && !!this._corporation_legal_address.trim(),
            corporation_post_address: typeof this._corporation_post_address === 'string' && !!this._corporation_post_address.trim(),
            corporation_head_position: typeof this._corporation_head_position === 'string' && !!this._corporation_head_position.trim(),
            corporation_head_name: typeof this._corporation_head_name === 'string' && !!this._corporation_head_name.trim(),
            corporation_operation_basis: typeof this._corporation_operation_basis === 'string' && !!this._corporation_operation_basis.trim(),
            corporation_accounting_email: !this._corporation_accounting_email ? true : typeof this._corporation_accounting_email === 'string',
            corporation_government_contract_id: !this._corporation_government_contract_id ? true : typeof this._corporation_government_contract_id === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IUpdateCorporation): UpdateCorporation {
        return new UpdateCorporation(props);
    }

    readonly keys: { [key: string]: string } = {
        corporationAccountingEmail: 'corporation_accounting_email',
        corporationGovernmentContractId: 'corporation_government_contract_id',
        corporationHeadName: 'corporation_head_name',
        corporationHeadPosition: 'corporation_head_position',
        corporationIec: 'corporation_iec',
        corporationLegalAddress: 'corporation_legal_address',
        corporationOperationBasis: 'corporation_operation_basis',
        corporationPostAddress: 'corporation_post_address',
        }
;

    mergeDeepWith(props: Partial<UpdateCorporation>): UpdateCorporation {
        const updateData: Partial<IUpdateCorporation> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof UpdateCorporation) => {
            const updateKey = this.keys[key] as keyof IUpdateCorporation;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IUpdateCorporation, keyof IUpdateCorporation>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new UpdateCorporation({ ...this.serialize(), ...updateData });
    }
}
