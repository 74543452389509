import { CardAccounts } from './CardAccounts';
import ResellerClient, { IResellerClient } from './ResellerClient';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IExpenseSection {
    accounting_item: CardAccounts;
    amount: number;
    reseller_client?: IResellerClient;
    section_id: number;
    software_license?: string;
}

export default class ExpenseSection {
    readonly _accounting_item: CardAccounts;

    get accountingItem(): CardAccounts {
        return this._accounting_item;
    }

    static accountingItemValidate(accountingItem: CardAccounts): boolean {
        return Object.keys(CardAccounts).includes(accountingItem);
    }

    readonly _amount: number;

    /**
     * Description: Expended amount for item, RUB
     * Example: 27.27
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    readonly _reseller_client: ResellerClient | undefined;

    get resellerClient(): ResellerClient | undefined {
        return this._reseller_client;
    }

    readonly _section_id: number;

    /** */
    get sectionId(): number {
        return this._section_id;
    }

    static sectionIdValidate(sectionId: number): boolean {
        return typeof sectionId === 'number';
    }

    readonly _software_license: string | undefined;

    /**
     * Description: Accounting sub-item name, only for SERVICE_SOFTWARE_LICENSE
     * Example: Software name
     */
    get softwareLicense(): string | undefined {
        return this._software_license;
    }

    constructor(props: IExpenseSection) {
        this._accounting_item = props.accounting_item;
        this._amount = props.amount;
        if (props.reseller_client) {
            this._reseller_client = new ResellerClient(props.reseller_client);
        }
        this._section_id = props.section_id;
        if (typeof props.software_license === 'string') {
            this._software_license = props.software_license.trim();
        }
    }

    serialize(): IExpenseSection {
        const data: IExpenseSection = {
            accounting_item: this._accounting_item,
            amount: this._amount,
            section_id: this._section_id,
        };
        if (typeof this._reseller_client !== 'undefined') {
            data.reseller_client = this._reseller_client.serialize();
        }
        if (typeof this._software_license !== 'undefined') {
            data.software_license = this._software_license;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            section_id: typeof this._section_id === 'number',
            software_license: !this._software_license ? true : typeof this._software_license === 'string',
            reseller_client: !this._reseller_client ? true : this._reseller_client.validate().length === 0,
            amount: typeof this._amount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IExpenseSection): ExpenseSection {
        return new ExpenseSection(props);
    }

    readonly keys: { [key: string]: string } = {
        accountingItem: 'accounting_item',
        amount: 'amount',
        resellerClient: 'reseller_client',
        sectionId: 'section_id',
        softwareLicense: 'software_license',
        }
;

    mergeDeepWith(props: Partial<ExpenseSection>): ExpenseSection {
        const updateData: Partial<IExpenseSection> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ExpenseSection) => {
            const updateKey = this.keys[key] as keyof IExpenseSection;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IExpenseSection, keyof IExpenseSection>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ExpenseSection({ ...this.serialize(), ...updateData });
    }
}
