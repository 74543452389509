import { takeEvery, put, call, fork, delay, select } from 'redux-saga/effects';

import { ActionType } from 'Actions/.';
import {
    SnapshotsActions,
    getSnapshotList as getSnapshotListAct,
    createSnapshot as createSnapshotAct,
    saveList,
    saveSnapshotProgress,
    deleteSnapshotProgress,
    deleteSnapshot as deleteSnapshotAct,
    rollbackSnapshot as rollbackSnapshotAct,
    downloadSnapshot as downloadSnapshotAct,
    createServerFromSnaphot as createServerFromSnaphotAct,
    getSnapshotDownloadLink as getSnapshotDownloadLinkAct,
} from 'Actions/snapshots';
import {
    getProgress as getProgressInstalling,
} from 'Actions/installingServers';
import { getServer } from 'Actions/server';
import { getServerOperationProgress } from 'Actions/operationProgress';
import snapshotsApi from 'Apis/snapshots';
import serverApi from 'Apis/servers';

import OperationProgress, { IOperationProgress } from 'Entities/OperationProgress';
import { IOperationInfo } from 'Entities/OperationInfo';
import { OperationStatus } from 'Entities/OperationStatus';
import Snapshot, { ISnapshot } from 'Entities/Snapshot';
import Server from 'Entities/Server';
import { OperationType } from 'Entities/OperationType';
import SnapshotDownloadLink, { ISnapshotDownloadLink } from 'Entities/SnapshotDownloadLink';
import { Store } from 'Store';

import { errorChecker, addError } from './utils';

function* getSnapshotList(action: ReturnType<typeof getSnapshotListAct>) {
    const { payload } = action;
    const response: ISnapshot[] = yield call(snapshotsApi.listSnapshots, ...payload);
    const { error, result } = errorChecker<ISnapshot[]>(response);
    if (result) {
        const ents = result.map((s) => new Snapshot(s));
        yield put(saveList(ents));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

export function* getProgress(
    info: IOperationInfo, server: Server, type: ActionType, snapId?: number,
) {
    while (true) {
        const { tenantId } = server;
        const { id } = info;
        const response: IOperationProgress = yield call(
            serverApi.getOperationProgress, tenantId, id,
        );
        const { error, result } = errorChecker<IOperationProgress>(response);
        if (result) {
            if (snapId) {
                yield put(saveSnapshotProgress({
                    progress: new OperationProgress(result),
                    snapId: snapId || server.snapshotId!,
                }));
            }
            if (result.status !== OperationStatus.PENDING) {
                yield put(getServer({
                    serverId: server.id,
                    tenantId: server.tenantId,
                }));
                yield put(getSnapshotListAct([server.tenantId, server.id]));
                if (result.status === OperationStatus.DONE
                    && snapId
                    && result.type !== OperationType.DOWNLOAD_SNAPSHOT
                ) {
                    yield put(deleteSnapshotProgress(snapId));
                }
                break;
            }
            yield delay(1000);
        } else if (error) {
            yield addError(error, type);
            break;
        }
    }
}

function* deleteSnapshot(action: ReturnType<typeof deleteSnapshotAct>) {
    const { payload } = action;
    const [, serverId] = payload;
    const server: Server = yield select((store: Store) => store.server.get(serverId));
    const response: IOperationInfo[] = yield call(snapshotsApi.deleteSnapshot, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield fork(getProgress, result, server, action.type);
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createServerFromSnap(action: ReturnType<typeof createServerFromSnaphotAct>) {
    const { payload } = action;
    const [, , serverClone] = payload;
    const response: IOperationInfo = yield call(serverApi.cloneServer, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        const { to_tenant_id: tenantId } = serverClone;
        yield put(getProgressInstalling({ ...result, tenantId: tenantId! }));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* downloadSnapshot(action: ReturnType<typeof downloadSnapshotAct>) {
    const { payload, callback } = action;
    const [, serverId, snapId] = payload;
    const server: Server = yield select((store: Store) => store.server.get(serverId));
    const response: IOperationInfo = yield call(snapshotsApi.downloadSnapshot, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield fork(getProgress, result, server, action.type, snapId);
        if (callback?.result) {
            callback.result();
        }
    } else if (error) {
        if (callback?.error) {
            callback.error(error);
            return;
        }
        yield addError(error, action.type);
    }
}

function* getSnapshotDownloadLink(action: ReturnType<typeof getSnapshotDownloadLinkAct>) {
    const { payload, callback } = action;
    const response: ISnapshotDownloadLink = yield call(snapshotsApi.getSnapshotDownloadLink, ...payload);
    const { error, result } = errorChecker<ISnapshotDownloadLink>(response);
    if (result) {
        callback?.result?.(new SnapshotDownloadLink(result));
    } else if (error) {
        callback?.error?.();
    }
}

function* rollbackSnapshot(action: ReturnType<typeof rollbackSnapshotAct>) {
    const { payload, callback } = action;
    const [, serverId] = payload;
    const server: Server = yield select((store: Store) => store.server.get(serverId));
    const response: IOperationInfo = yield call(snapshotsApi.rollbackSnapshot, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: server.id,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: OperationType.REVERT_TO_SNAPSHOT,
        }));
        if (callback?.result) {
            callback.result();
        }
    } else if (error) {
        if (callback?.error) {
            callback.error();
            return;
        }
        yield addError(error, action.type);
    }
}

function* createSnapshot(action: ReturnType<typeof createSnapshotAct>) {
    const { payload } = action;
    const server: Server = yield select((store: Store) => store.server.get(payload[1]));
    const response: IOperationInfo = yield call(snapshotsApi.createSnapshot, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId: server.id,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: OperationType.CREATE_SNAPSHOT,
        }));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* snapshotSaga(): Generator {
    yield takeEvery<ReturnType<typeof getSnapshotListAct>>(
        SnapshotsActions.GetList, getSnapshotList,
    );
    yield takeEvery<ReturnType<typeof createSnapshotAct>>(
        SnapshotsActions.CreateSnapshot, createSnapshot,
    );
    yield takeEvery<ReturnType<typeof deleteSnapshotAct>>(
        SnapshotsActions.DeleteSnapshot, deleteSnapshot,
    );
    yield takeEvery<ReturnType<typeof rollbackSnapshotAct>>(
        SnapshotsActions.RollbackSnapshot, rollbackSnapshot,
    );
    yield takeEvery<ReturnType<typeof downloadSnapshotAct>>(
        SnapshotsActions.DownloadSnapshot, downloadSnapshot,
    );
    yield takeEvery<ReturnType<typeof createServerFromSnaphotAct>>(
        SnapshotsActions.CreateServerFromSnaphot,
        createServerFromSnap,
    );
    yield takeEvery<ReturnType<typeof getSnapshotDownloadLinkAct>>(
        SnapshotsActions.GetSnapshotDownloadLink, getSnapshotDownloadLink,
    );
}

export default snapshotSaga;
