import { Action } from 'store/actions';
import {
    InstallingServersActions,
    saveProgress,
    deleteProgress,
    saveInstallingServer,
    deleteInstallingServer,
} from 'Actions/installingServers';

import { InstallingServersStore, installingServersInitialState as initialState } from '../initialStates';

const reducer = (
    state: InstallingServersStore = initialState, action: Action,
): InstallingServersStore => {
    switch (action.type as InstallingServersActions) {
        case InstallingServersActions.SaveProgress: {
            const { payload: { progress } } = action as ReturnType<typeof saveProgress>;
            const tu = new Map(state.progress);
            tu.set(progress.id, progress);
            return { ...state, progress: tu };
        }
        case InstallingServersActions.DeleteProgress: {
            const {
                payload: { progressId },
            } = action as ReturnType<typeof deleteProgress>;
            const tu = new Map(state.progress);
            tu.delete(progressId);
            return { ...state, progress: tu };
        }
        case InstallingServersActions.SaveInstallingServer: {
            const {
                payload: server,
            } = action as ReturnType<typeof saveInstallingServer>;
            const tu = new Map(state.servers);
            tu.set(server.id, server);
            return { ...state, servers: tu };
        }
        case InstallingServersActions.DeleteInstallingServer: {
            const {
                payload: serverId,
            } = action as ReturnType<typeof deleteInstallingServer>;
            const tu = new Map(state.servers);
            tu.delete(serverId);
            return { ...state, servers: tu };
        }
        default:
            return state;
    }
};

export default reducer;
