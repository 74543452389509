import React, { FC, useState, useEffect } from 'react';

import PubKeysApi from 'Apis/pubKeys';
import PublicKey, { IPublicKey } from 'Entities/PublicKey';
import { Checkbox, useIntl } from 'Common';
import { NewServerFormValues } from 'Lib/helpers/newServer';
import { errorChecker } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import FormGroup from './FormGroup';

interface PublicKeysProps {
    values: NewServerFormValues;
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
}

const PublicKeys: FC<PublicKeysProps> = ({ values, setFieldValue }) => {
    const intl = useIntl();
    const [availableKeys, setAvailableKeys] = useState<PublicKey[]>([]);

    useEffect(() => {
        let canUpdate = true;
        const loadPublicKeys = async () => {
            const response = await PubKeysApi.getPublicKeysForTenant(
                values.tenant_id,
            );
            const { result } = errorChecker<IPublicKey[]>(response);
            if (result && canUpdate) {
                const resEnt = result.map((r) => new PublicKey(r));
                const keys = resEnt
                    .filter((k) => k.installDefault)
                    .map((k) => k.id);
                setFieldValue('public_key_ids', keys);
                setAvailableKeys(resEnt);
            } else {
                setAvailableKeys([]);
            }
        };
        loadPublicKeys();
        return () => {
            canUpdate = false;
        };
    }, [values.tenant_id]);

    if (availableKeys.length === 0) {
        return null;
    }

    return (
        <FormGroup
            title={intl.getMessage('new_server_public_keys_title')}
        >
            <div className={theme.keysForm.list}>
                {availableKeys.map(({ id, title, type, ownerUserName }) => (
                    <label
                        key={id}
                        htmlFor={String(id)}
                        className={theme.keysForm.key}
                    >
                        <div className={theme.keysForm.input}>
                            <Checkbox
                                id={String(id)}
                                name="public_key_ids"
                                handleChange={() => {
                                    const keys = new Set(values.public_key_ids);
                                    if (keys.has(id)) {
                                        keys.delete(id);
                                    } else {
                                        keys.add(id);
                                    }
                                    setFieldValue('public_key_ids', Array.from(keys));
                                }}
                                checked={values.public_key_ids.includes(id)}
                            />
                        </div>
                        <div className={theme.keysForm.wrap}>
                            <div className={theme.keysForm.title}>
                                {title}
                            </div>
                            {ownerUserName && (
                                <div className={theme.keysForm.email}>
                                    {ownerUserName}
                                </div>
                            )}
                        </div>
                        <div className={theme.keysForm.type}>
                            {type}
                        </div>
                    </label>
                ))}
            </div>
        </FormGroup>
    );
};

export default PublicKeys;
