import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { useServerInProgress, useServerNotExist, useTitle, useServerSearchSave } from 'Hooks';
import { updateServerBackupPolicy } from 'Actions/server';
import { InnerPageLayout, useIntl, BackupSetting, LoaderPageLayout, ServerNotExist } from 'Common';
import { IBackupPolicy } from 'Entities/BackupPolicy';
import Server from 'Entities/Server';
import { Store } from 'Store';

import Header from './Header';
import List from './List';

interface ServerBackupStoreProps {
    server: Server | undefined;
}
type ServerBackupOwnProps = RouteComponentProps<{ serverId: string }>;
type ServerBackupProps = ServerBackupOwnProps & ServerBackupStoreProps;

const ServerBackup: FC<ServerBackupProps> = ({ server, match: { params: { serverId } } }) => {
    const intl = useIntl();
    const isProgress = useServerInProgress(Number(serverId));
    const dispatch = useDispatch();

    const pageTitle = server?.name
        ? intl.getMessage('server_backup_page_title', { value: server.name })
        : intl.getMessage('page_title');
    useTitle(pageTitle);

    const noServer = useServerNotExist();

    useServerSearchSave(Number(serverId), noServer);

    if (noServer) {
        return <ServerNotExist />;
    }
    const getContent = () => {
        if (!server || isProgress) {
            return <LoaderPageLayout />;
        }
        const { backupPolicy, tenantId, id } = server;
        const onChangeBackupPolicy = (policy: IBackupPolicy) => {
            dispatch(updateServerBackupPolicy([tenantId, id, policy]));
        };
        return (
            <>
                <BackupSetting
                    backupPolicy={backupPolicy}
                    tenantId={tenantId}
                    onSubmit={onChangeBackupPolicy}
                    title={intl.getMessage('server_backup_setting_title')}
                    server={server}
                />
                <List
                    title={intl.getMessage('backups')}
                    info={intl.getMessage('server_backup_exceptions_info')}
                    server={server}
                />
            </>
        );
    };

    return (
        <InnerPageLayout
            title={intl.getMessage('backup')}
            tooltip={intl.getMessage('project_backup_servers_tooltip')}
            header={<Header server={server!} />}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

const selectServer = (store: Store, ownProps: ServerBackupOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return store.server.get(Number(serverId));
};
const selector = createSelector([selectServer], (server) => server);
const mapStoreToProps = (store: Store, ownProps: ServerBackupOwnProps) => ({
    server: selector(store, ownProps),
});
export default withRouter(connect(mapStoreToProps)(ServerBackup));
