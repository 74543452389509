import React, { FC } from 'react';
import { nanoid } from 'nanoid';

import cn from 'classnames';

import ITicketPostAttachment from 'Entities/TicketPostAttachment';

import Avatar from '../Avatar';
import s from './Message.module.pcss';
import Attachments from '../Attachments';

interface MessageProps {
    date: string;
    text: string;
    support: boolean;
    attachments: ITicketPostAttachment[] | undefined;
    ticketId: number;
}

const Message: FC<MessageProps> = ({ date, text, support, attachments, ticketId }) => {
    const splitText = text.split('\n');
    const renderText = [];
    for (let i = 0; i < splitText.length; i += 1) {
        if (i !== splitText.length) {
            renderText.push(<span key={nanoid()}>{splitText[i]} <br /></span>);
        } else {
            renderText.push(<span key={nanoid()}>{splitText[i]}</span>);
        }
    }

    return (
        <div
            className={cn(
                s.message,
                { [s.message_support]: support },
            )}
        >
            <div
                className={cn(
                    s.inner,
                    { [s.inner_support]: support },
                )}
            >
                <div className={s.date}>
                    {date}

                    {attachments && attachments.length > 0 && (
                        <div className={s.attachement}>
                            <Attachments attachments={attachments} ticketId={ticketId} onMessage />
                        </div>
                    )}
                </div>
                <div className={s.text}>
                    {renderText}
                </div>
            </div>
            <div className={s.avatar}>
                <Avatar
                    type={support ? 'support' : 'client'}
                    icon={support ? 'panel_logo' : 'profile'}
                />
            </div>
        </div>
    );
};

export default Message;
