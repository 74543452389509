import React, { FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { useTitle } from 'Hooks';
import { updateBackupPolicy } from 'Actions/tenant';
import { InnerPageLayout, useIntl, BackupSetting } from 'Common';
import { IBackupPolicy } from 'Entities/BackupPolicy';
import Tenant from 'Entities/Tenant';
import Server from 'Entities/Server';
import { Store } from 'Store';

import Header from './Header';
import ServerList from './ServerList';

interface ProjectBackupStoreProps {
    data: {
        tenant: Tenant;
        tenantServers: Server[];
    } | null;
}
type ProjectBackupRouteProps = RouteComponentProps<{ tenantId: string }>;
type ProjectBackupProps = ProjectBackupRouteProps & ProjectBackupStoreProps;

const ProjectBackup: FC<ProjectBackupProps> = ({ data }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('project_backup_page_title'));
    const dispatch = useDispatch();
    if (!data) {
        return null;
    }

    const { tenant, tenant: { backupPolicy, id }, tenantServers } = data;
    const samePolicy: Server[] = [];
    const otherPolicy: Server[] = [];

    tenantServers.forEach((ts) => {
        const { backupPolicy: servBP } = ts;
        if (servBP.frequency === backupPolicy.frequency
            && servBP.quantity === backupPolicy.quantity
        ) {
            samePolicy.push(ts);
        } else {
            otherPolicy.push(ts);
        }
    });

    const onChangeBackupPolicy = (policy: IBackupPolicy) => {
        dispatch(updateBackupPolicy([tenant.id, policy]));
    };

    return tenant ? (
        <InnerPageLayout
            title={intl.getMessage('project_backup')}
            tooltip={intl.getMessage('project_backup_info')}
            header={<Header tenant={tenant} />}
        >
            <BackupSetting
                backupPolicy={tenant.backupPolicy}
                onSubmit={onChangeBackupPolicy}
                title={intl.getMessage('project_backup_setting_title')}
                tenantId={id}
            />
            {otherPolicy.length > 0 && (
                <ServerList
                    other
                    title={intl.getMessage('exceptions')}
                    info={intl.getMessage('project_backup_exceptions_info')}
                    items={otherPolicy}
                    tenantBackupPolicy={tenant.backupPolicy}
                />
            )}
            {samePolicy.length > 0 && (
                <ServerList
                    title={intl.getMessage('project_backup_servers')}
                    info={intl.getMessage('project_backup_servers_info')}
                    items={samePolicy}
                />
            )}
        </InnerPageLayout>
    ) : null;
};

const selectTenant = (store: Store, op: ProjectBackupRouteProps) => {
    const { match: { params: { tenantId } } } = op;
    return store.tenant.get(Number(tenantId));
};
const selectServersTenant = (store: Store) => {
    return store.server;
};
const dataSelect = createSelector([
    selectTenant,
    selectServersTenant,
], (tenant, servers) => {
    if (!tenant) {
        return null;
    }
    const tenantServers: Server[] = [];
    servers.forEach((s) => {
        if (s.tenantId === tenant.id) {
            tenantServers.push(s);
        }
    });
    return { tenant, tenantServers };
});

const mapStateToProps = (store: Store, ownProps: ProjectBackupRouteProps) => ({
    data: dataSelect(store, ownProps),
});

export default withRouter(connect(mapStateToProps)(ProjectBackup));
