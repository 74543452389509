// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IRecipientDeliverySettings {
    delivery_email: boolean;
    delivery_sms: boolean;
    recipient_id: number;
}

export default class RecipientDeliverySettings {
    readonly _delivery_email: boolean;

    /**
     * Description: Is email delivery enabled
     * Example: true
     */
    get deliveryEmail(): boolean {
        return this._delivery_email;
    }

    static deliveryEmailValidate(deliveryEmail: boolean): boolean {
        return typeof deliveryEmail === 'boolean';
    }

    readonly _delivery_sms: boolean;

    /**
     * Description: Is sms delivery enabled
     * Example: true
     */
    get deliverySms(): boolean {
        return this._delivery_sms;
    }

    static deliverySmsValidate(deliverySms: boolean): boolean {
        return typeof deliverySms === 'boolean';
    }

    readonly _recipient_id: number;

    /** */
    get recipientId(): number {
        return this._recipient_id;
    }

    static recipientIdValidate(recipientId: number): boolean {
        return typeof recipientId === 'number';
    }

    constructor(props: IRecipientDeliverySettings) {
        this._delivery_email = props.delivery_email;
        this._delivery_sms = props.delivery_sms;
        this._recipient_id = props.recipient_id;
    }

    serialize(): IRecipientDeliverySettings {
        const data: IRecipientDeliverySettings = {
            delivery_email: this._delivery_email,
            delivery_sms: this._delivery_sms,
            recipient_id: this._recipient_id,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            recipient_id: typeof this._recipient_id === 'number',
            delivery_email: typeof this._delivery_email === 'boolean',
            delivery_sms: typeof this._delivery_sms === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IRecipientDeliverySettings): RecipientDeliverySettings {
        return new RecipientDeliverySettings(props);
    }

    readonly keys: { [key: string]: string } = {
        deliveryEmail: 'delivery_email',
        deliverySms: 'delivery_sms',
        recipientId: 'recipient_id',
        }
;

    mergeDeepWith(props: Partial<RecipientDeliverySettings>): RecipientDeliverySettings {
        const updateData: Partial<IRecipientDeliverySettings> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof RecipientDeliverySettings) => {
            const updateKey = this.keys[key] as keyof IRecipientDeliverySettings;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IRecipientDeliverySettings, keyof IRecipientDeliverySettings>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new RecipientDeliverySettings({ ...this.serialize(), ...updateData });
    }
}
