// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IUpdateIndividual {
    individual_address?: string;
    individual_first_name: string;
    individual_last_name: string;
}

export default class UpdateIndividual {
    readonly _individual_address: string | undefined;

    /**
     * Description: Individual payer registration address
     * Example: 123456, Россия, Москва, ул. Правды, д.11, корп.11
     */
    get individualAddress(): string | undefined {
        return this._individual_address;
    }

    readonly _individual_first_name: string;

    /**
     * Description: Individual payer first name
     * Example: ЕЛЕНА
     */
    get individualFirstName(): string {
        return this._individual_first_name;
    }

    static individualFirstNameValidate(individualFirstName: string): boolean {
        return typeof individualFirstName === 'string' && !!individualFirstName.trim();
    }

    readonly _individual_last_name: string;

    /**
     * Description: Individual payer last name
     * Example: КАРЦЕВА
     */
    get individualLastName(): string {
        return this._individual_last_name;
    }

    static individualLastNameValidate(individualLastName: string): boolean {
        return typeof individualLastName === 'string' && !!individualLastName.trim();
    }

    constructor(props: IUpdateIndividual) {
        if (typeof props.individual_address === 'string') {
            this._individual_address = props.individual_address.trim();
        }
        this._individual_first_name = props.individual_first_name.trim();
        this._individual_last_name = props.individual_last_name.trim();
    }

    serialize(): IUpdateIndividual {
        const data: IUpdateIndividual = {
            individual_first_name: this._individual_first_name,
            individual_last_name: this._individual_last_name,
        };
        if (typeof this._individual_address !== 'undefined') {
            data.individual_address = this._individual_address;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            individual_first_name: typeof this._individual_first_name === 'string' && !!this._individual_first_name.trim(),
            individual_last_name: typeof this._individual_last_name === 'string' && !!this._individual_last_name.trim(),
            individual_address: !this._individual_address ? true : typeof this._individual_address === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IUpdateIndividual): UpdateIndividual {
        return new UpdateIndividual(props);
    }

    readonly keys: { [key: string]: string } = {
        individualAddress: 'individual_address',
        individualFirstName: 'individual_first_name',
        individualLastName: 'individual_last_name',
        }
;

    mergeDeepWith(props: Partial<UpdateIndividual>): UpdateIndividual {
        const updateData: Partial<IUpdateIndividual> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof UpdateIndividual) => {
            const updateKey = this.keys[key] as keyof IUpdateIndividual;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IUpdateIndividual, keyof IUpdateIndividual>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new UpdateIndividual({ ...this.serialize(), ...updateData });
    }
}
