import React, { FC } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useTitle } from 'Hooks';
import { InnerPageLayout, useIntl } from 'Common';
import Tenant from 'Entities/Tenant';
import { Store } from 'Store';
import theme from 'Lib/theme';

import { Project, Header } from './components';

interface ProjectsListStoreProps {
    projects: Tenant[];
}
type ProjectsListProps = ProjectsListStoreProps;

const ProjectsList: FC<ProjectsListProps> = ({ projects }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('project_page_title'));

    return (
        <InnerPageLayout
            header={<Header />}
            className={theme.content.with_actions}
        >
            <div
                className={cn(
                    theme.card.labels,
                    theme.card.labels_project,
                )}
            >
                <div className={theme.card.label}>
                    {intl.getMessage('tenant')}
                </div>
                <div className={theme.card.label}>
                    {intl.getMessage('project_backup')}
                </div>
                <div className={theme.card.label}>
                    {intl.getMessage('project_access_owner_title')}
                </div>
            </div>
            {projects.map((p) => (<Project key={p.id} tenant={p} />))}
        </InnerPageLayout>
    );
};

const selectTenantList = (store: Store) => store.tenant;
const tenantList = createSelector(
    selectTenantList,
    (list) => Array.from(list.values()),
);

const mapStateToProps = (store: Store) => ({
    projects: tenantList(store),
});

export default connect(mapStateToProps)(ProjectsList);
