import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import { Dropdown, Button } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useIntl, Icon, Checkbox } from 'Common';
import { setSearchType } from 'Actions/ui';
import { getServerList } from 'Actions/server';
import { handleServerSearch } from 'Lib/helpers/search';
import { SEARCH_TYPE } from 'Lib/helpers/consts';
import Server from 'Entities/Server';
import Tenant from 'Entities/Tenant';
import { Store } from 'Store';
import theme from 'Lib/theme';

import ServerList from './components';

interface SearchProps {
    tenants: Tenant[];
    servers: Map<number, Server>;
    installingServers: Map<number, Server>;
    searchType: SEARCH_TYPE;
}

type SearchEntityKeys =
    'id' |
    'name' |
    'privateAddress' |
    'ipv4Addresses' |
    'ipv6Addresses';

type SearchEntity = Record<SearchEntityKeys, any>;

const Search: FC<SearchProps> = React.memo(({
    tenants,
    servers,
    installingServers,
    searchType,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [visibleDropdown, setVisibleDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleOutClick = (ev: any) => {
            if (
                ev.target.id !== 'searchInput'
                && !ev.target.closest('#menu-dropdown')
                && !ev.target.closest('#searchInputLabel')
            ) {
                setVisibleDropdown(false);
                inputRef.current?.blur();
                setSearchQuery('');
            }
        };
        const tabClick = (e: KeyboardEvent) => {
            e.preventDefault();
            if (e.key === 'Tab') {
                inputRef.current?.focus();
            }
            if (e.key === 'Escape') {
                inputRef.current?.blur();
                setVisibleDropdown(false);
                setSearchQuery('');
            }
        };
        window.addEventListener('keyup', tabClick);
        document.body.addEventListener('click', handleOutClick);
        return () => {
            document.body.removeEventListener('click', handleOutClick);
            window.removeEventListener('keyup', tabClick);
        };
    });

    useEffect(() => {
        if (visibleDropdown) {
            dispatch(getServerList());
        }
    }, [visibleDropdown]);

    const searchResult = useMemo(
        () => handleServerSearch(searchQuery, servers, intl.currentLocale, searchType),
        [searchQuery, servers],
    );
    const searchInstallingResult = useMemo(
        () => handleServerSearch(searchQuery, installingServers, intl.currentLocale, searchType),
        [searchQuery, installingServers],
    );

    const clearSearch = () => {
        inputRef.current?.focus();
        setSearchQuery('');
        setVisibleDropdown(true);
    };

    const changeVisibility = (e: boolean) => {
        if (document.activeElement !== inputRef.current) {
            setVisibleDropdown(e);
        }
    };

    const handleSearchType = (checked: boolean) => {
        const type = checked ? SEARCH_TYPE.FUZZY : SEARCH_TYPE.STRICT;
        dispatch(setSearchType(type));
    };

    let result = [...searchResult, ...searchInstallingResult];
    if (searchQuery) {
        result = result.sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });
    }
    return (
        <>
            <Dropdown
                overlay={(
                    <div className={theme.search.menu} id="menu-dropdown">
                        <ServerList
                            tenants={tenants}
                            servers={result}
                            search={searchQuery}
                        />
                    </div>
                )}
                overlayClassName="dropdown_fixed"
                visible={visibleDropdown && (result.length > 0 || !!searchQuery)}
                onVisibleChange={changeVisibility}
                trigger={['click']}
            >
                <label
                    id="searchInputLabel"
                    htmlFor="searchInput"
                    className={theme.search.wrap}
                >
                    {searchQuery ? (
                        <Button
                            id="clearSearch"
                            className={cn(
                                theme.button.button,
                                theme.button.icon,
                                theme.button.for_input,
                                theme.button.small,
                                theme.search.clear,
                            )}
                            icon={<Icon icon="clear" />}
                            onClick={clearSearch}
                        />
                    ) : (
                        <Icon
                            icon="search"
                            className={theme.search.icon}
                            onClick={() => inputRef.current?.focus()}
                        />
                    )}
                    <input
                        id="searchInput"
                        ref={inputRef}
                        className={cn(
                            theme.search.input,
                            { [theme.search.input_active]: searchQuery },
                        )}
                        onChange={(e) => {
                            if (e.target.value && !visibleDropdown) {
                                setVisibleDropdown(true);
                            }
                            setSearchQuery(e.target.value);
                        }}
                        value={searchQuery}
                        onFocus={() => {
                            setVisibleDropdown(true);
                        }}
                        placeholder={intl.getMessage('search_placeholder')}
                        autoComplete="off"
                    />
                    {searchQuery.length > 0 && (
                        <div className={theme.search.type} onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                                id="searchType"
                                name="searchType"
                                checked={searchType === SEARCH_TYPE.FUZZY}
                                handleChange={(e) => handleSearchType(e.target.checked)}
                                labelClassName={theme.search.typeLabel}
                            >
                                {intl.getMessage('fuzzy_search')}
                            </Checkbox>
                        </div>
                    )}
                </label>
            </Dropdown>
        </>
    );
});

const selectTenants = (store: Store) => store.tenant;
const selectServers = (store: Store) => store.server;
const selectInstallingServers = (store: Store) => store.installingServers.servers;
const selectSearchType = (store: Store) => store.ui.searchType;

const selector = createSelector(
    [selectTenants, selectServers, selectInstallingServers, selectSearchType],
    (tenants, servers, installingServers, searchType) => ({
        tenants: Array.from(tenants.values()),
        servers,
        installingServers,
        searchType,
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Search);
