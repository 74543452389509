import React, { FC } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useIntl, notifyError, notifySuccess, Input } from 'Common';
import { getAccountInvoice, createAccountCardInvoice } from 'Actions/account';
import CreateChargeFromCardInvoiceRequest from 'Entities/CreateChargeFromCardInvoiceRequest';
import Error from 'Entities/Error';
import { useScript } from 'Lib/hooks/hooks';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import AccountDetails from 'Entities/AccountDetails';
import { IAccountInvoice } from 'Entities/AccountInvoice';
import {
    onChangeCheckNumberOrFloat,
    onSubmitCheckNumberOrFloat,
    parseFloatWithComma,
} from 'Lib/helpers/helpers';
import { RoutePath, linkPathBuilder } from 'Lib/helpers/routes';
import { currencySymbol, CLOUDPAYMENTS_SRC, EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import s from './Form.module.pcss';
import { useIsPayer } from './utils';
import Actions from './Actions';

interface SavedCardFormProps {
    details: AccountDetails;
    noLayout?: boolean;
    isFirst: boolean;
    cardId: number;
}

type FormValues = { amount: string };

const SavedCardForm: FC<SavedCardFormProps> = ({ cardId, details, noLayout }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();
    const scriptState = useScript(CLOUDPAYMENTS_SRC);
    const { canPay } = useIsPayer();

    const initial = {
        amount: '',
    };

    const {
        accountLimits: { minCashPaymentAmount, maxCashPaymentAmount },
        chargeOfferAmounts,
    } = details;

    const onPaySuccess = (invoiceId: number) => {
        dispatch(getAccountInvoice({
            req: [invoiceId],
            callback: () => {
                notifySuccess(intl.getMessage('pay_success'), 'pay_success');
            },
        }, {
            error: () => {
                notifyError(intl.getMessage('failed_to_pay'));
            },
        }));
        history.push(linkPathBuilder(intl.currentLocale, RoutePath.Billing));
    };

    const onPay = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!scriptState.loaded || scriptState.error) {
            return;
        }
        if (!canPay) {
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.Onboarding));
            return;
        }
        const { amount } = values;
        const amountParsed = parseFloatWithComma(amount);

        if (!amountParsed || amountParsed < minCashPaymentAmount
            || amountParsed > maxCashPaymentAmount || !onSubmitCheckNumberOrFloat(amount)) {
            helpers.setFieldError('amount', EMPTY_FIELD_ERROR);
            helpers.setSubmitting(false);
            return;
        }

        const reqEnt = new CreateChargeFromCardInvoiceRequest({
            amount: amountParsed,
            card_id: cardId,
            description: intl.getMessage('bound_cards_desc'),
        });

        dispatch(createAccountCardInvoice([reqEnt.serialize()], {
            result: (config: IAccountInvoice) => {
                onPaySuccess(config.invoice_id);
            },
            error: (error: Error) => {
                history.push(linkPathBuilder(intl.currentLocale, RoutePath.Billing));
                notifyError(apiErrorCodeTranslate(intl, error.errorCode), error.errorCode);
            },
        }));
    };

    return (
        <Formik
            initialValues={initial}
            onSubmit={onPay}
        >
            {({
                setFieldValue,
                handleSubmit,
                errors,
                values,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Input
                        autoFocus
                        error={!!errors.amount}
                        label={intl.getMessage('sum')}
                        name="amount"
                        onChange={(e) => {
                            if (onChangeCheckNumberOrFloat(e) || e === '') {
                                setFieldValue('amount', e);
                            }
                        }}
                        placeholder={intl.getMessage('add_funds_placeholder', { min: minCashPaymentAmount, max: maxCashPaymentAmount })}
                        size="large"
                        suffix={currencySymbol.RUB}
                        type="text"
                        validate={(e) => !!e}
                        value={values.amount}
                        wrapperClassName={s.group_amount}
                    />
                    <div className={cn(s.group, s.group_last)}>
                        {chargeOfferAmounts.map((amount) => (
                            <button
                                type="button"
                                key={amount}
                                className={cn(
                                    theme.Badge.badge,
                                    theme.Badge.subject,
                                    s.badge,
                                )}
                                onClick={() => setFieldValue('amount', amount)}
                            >
                                {intl.getMessage('price_simple', { value: amount })}
                            </button>
                        ))}
                    </div>
                    <Actions
                        isSubmitting={isSubmitting}
                        submitText={intl.getMessage('billing_add_funds_short')}
                        noLayout={noLayout}
                        disabled={!values.amount}
                    />
                </form>
            )}
        </Formik>
    );
};

export default SavedCardForm;
