import { Version } from './Version';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IIpAddress {
    gateway?: string;
    host: string;
    netmask: string;
    version: Version;
}

export default class IpAddress {
    readonly _gateway: string | undefined;

    /**
     * Description: Gateway
     * Example: 1.2.3.4
     */
    get gateway(): string | undefined {
        return this._gateway;
    }

    readonly _host: string;

    /**
     * Description: IP address
     * Example: 194.177.20.24
     */
    get host(): string {
        return this._host;
    }

    static hostValidate(host: string): boolean {
        return typeof host === 'string' && !!host.trim();
    }

    readonly _netmask: string;

    /**
     * Description: IP net mask
     * Example: 255.255.254.0
     */
    get netmask(): string {
        return this._netmask;
    }

    static netmaskValidate(netmask: string): boolean {
        return typeof netmask === 'string' && !!netmask.trim();
    }

    readonly _version: Version;

    get version(): Version {
        return this._version;
    }

    static versionValidate(version: Version): boolean {
        return Object.keys(Version).includes(version);
    }

    constructor(props: IIpAddress) {
        if (typeof props.gateway === 'string') {
            this._gateway = props.gateway.trim();
        }
        this._host = props.host.trim();
        this._netmask = props.netmask.trim();
        this._version = props.version;
    }

    serialize(): IIpAddress {
        const data: IIpAddress = {
            host: this._host,
            netmask: this._netmask,
            version: this._version,
        };
        if (typeof this._gateway !== 'undefined') {
            data.gateway = this._gateway;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            host: typeof this._host === 'string' && !!this._host.trim(),
            netmask: typeof this._netmask === 'string' && !!this._netmask.trim(),
            gateway: !this._gateway ? true : typeof this._gateway === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IIpAddress): IpAddress {
        return new IpAddress(props);
    }

    readonly keys: { [key: string]: string } = {
        gateway: 'gateway',
        host: 'host',
        netmask: 'netmask',
        version: 'version',
        }
;

    mergeDeepWith(props: Partial<IpAddress>): IpAddress {
        const updateData: Partial<IIpAddress> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof IpAddress) => {
            const updateKey = this.keys[key] as keyof IIpAddress;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IIpAddress, keyof IIpAddress>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new IpAddress({ ...this.serialize(), ...updateData });
    }
}
