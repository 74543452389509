import React, { FC, useState, FormEvent } from 'react';
import { Row, Col } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { updateProfile } from 'Actions/profile';
import ProfileUpdate from 'Entities/ProfileUpdate';
import { useIntl, notifySuccess, notifyError, Input, Button } from 'Common';
import { passwordValidate } from 'Lib/helpers/utils';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import ApiError from 'Entities/Error';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { useEnter, useEscape } from 'Hooks';
import theme from 'Lib/theme';

import s from './ChangePassword.module.pcss';

const ChangePassword: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [formVisible, setFormVisible] = useState(false);
    const [password, setPassword] = useState({ old: '', new: '' });
    const [passwordError, setPasswordError] = useState({ old: false, new: false });

    const handleApply = (e?: FormEvent) => {
        if (e) {
            e.preventDefault();
        }
        const valid = passwordValidate(password.new, ProfileUpdate.newPasswordMinLength, true);
        const errors = { ...passwordError };
        if (!valid) {
            errors.new = true;
        }
        if (!password.old) {
            errors.old = true;
        }
        if (errors.new || errors.old) {
            setPasswordError(errors);
            return;
        }

        dispatch(updateProfile([{
            current_password: password.old,
            new_password: password.new,
        }], {
            result: () => {
                notifySuccess(intl.getMessage('password_changed'), 'password_changed');
                setPassword({ old: '', new: '' });
                setFormVisible(false);
            },
            error: (error: ApiError) => {
                const err = { ...passwordError };
                if (error.fields?.current_password) {
                    const code: ApiErrorCode = error.fields?.current_password[0]?.error_code;
                    notifyError(apiErrorCodeTranslate(intl, code));
                    err.old = true;
                }
                if (error.fields?.new_password) {
                    const code: ApiErrorCode = error.fields?.new_password[0]?.error_code;
                    notifyError(apiErrorCodeTranslate(intl, code));
                    err.new = true;
                }
                setPasswordError(err);
            },
        }));
    };

    const handleCancel = () => {
        setPassword({ old: '', new: '' });
        setPasswordError({ old: false, new: false });
        setFormVisible(false);
    };

    useEscape(handleCancel);
    useEnter(handleApply);

    const handleChangePassword = (
        key: keyof typeof password,
        value: string,
    ) => {
        if (passwordError[key]) {
            setPasswordError({ ...passwordError, [key]: false });
        }
        setPassword({ ...password, [key]: value });
    };

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.plate,
                theme.card.rounded,
                { [theme.card.active]: formVisible },
            )}
        >
            <Row gutter={[24, 0]}>
                <Col span={24} lg={14} xl={12}>
                    <div className={theme.card.title}>
                        {intl.getMessage('change_password')}
                    </div>
                    <div className={cn(theme.card.desc, s.desc)}>
                        {intl.getMessage('settings_password_desc', { min: ProfileUpdate.newPasswordMinLength })}
                    </div>
                    {formVisible ? (
                        <form onSubmit={handleApply}>
                            <div className={s.wrap}>
                                <Input
                                    id="old_password"
                                    name="old_password"
                                    autoComplete="current-password"
                                    error={passwordError.old}
                                    onChange={(value) => handleChangePassword('old', value)}
                                    placeholder={intl.getMessage('password_old')}
                                    size="large"
                                    type="password"
                                    value={password.old}
                                    className={cn('input', { input_error: passwordError.old })}
                                    optional={false}
                                />
                            </div>
                            <div className={s.wrap}>
                                <Input
                                    id="password_new"
                                    name="password_new"
                                    autoComplete="new-password"
                                    error={passwordError.new}
                                    onChange={(value) => handleChangePassword('new', value)}
                                    placeholder={intl.getMessage('password_new')}
                                    size="large"
                                    type="password"
                                    value={password.new}
                                    className={cn('input', { input_error: passwordError.new })}
                                    optional={false}
                                />
                            </div>
                            <div className={s.actions}>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    size="medium"
                                    onClick={handleApply}
                                    inGroup
                                >
                                    {intl.getMessage('apply')}
                                </Button>
                                <Button
                                    type="link"
                                    size="medium"
                                    onClick={handleCancel}
                                >
                                    {intl.getMessage('cancel')}
                                </Button>
                            </div>
                        </form>
                    ) : (
                        <Button
                            type="outlined"
                            size="medium"
                            className={s.button}
                            onClick={() => setFormVisible(true)}
                        >
                            {intl.getMessage('change_password')}
                        </Button>
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default ChangePassword;
