import React, { FC, useState } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { useIntl, Switch } from 'Common';
import { grantSupportAccess, grantConsoleAccess } from 'Actions/server';
import Server from 'Entities/Server';
import { DistributionFamily } from 'Entities/DistributionFamily';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { useSecurityActions } from 'Lib/hooks/hooks';

import ModalTermAccess from '../ModalTermAccess';
import style from './Setting.module.pcss';

interface SettingProps {
    server: Server;
}
const Setting: FC<SettingProps> = ({ server }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
        setCodeSent,
    } = useSecurityActions(SecurityActionType.SERVER_CONSOLE_ACCESS);

    const handleSupportAccess = (grant: boolean) => {
        dispatch(grantSupportAccess({ server, grant }));
    };

    const handleConsoleAccess = (grant: boolean) => {
        if (!grant || !shouldConfirmAction) {
            dispatch(grantConsoleAccess({ server, grant }));
            return;
        }
        if (shouldConfirmAction && !codeSent) {
            setOpenModal(true);
            sendConfirmationCode({
                tenantId: server.tenantId,
                serverId: server.id,
            });
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(grantConsoleAccess({ server, grant, code }, {
                error: () => {
                    setCodeError(true);
                    setCodeSent(false);
                },
                result: () => setOpenModal(false),
            }));
        }
    };

    const windowsDistribution = server.distribution.type === DistributionFamily.WINDOWS;

    return (
        <>
            <div className={style.title}>
                {intl.getMessage('server_setting_access_title')}
            </div>
            <div className={style.setting}>
                {!windowsDistribution && (
                    <div className={style.item}>
                        <div className={style.item__header}>
                            <Switch
                                id="support_access"
                                handleChange={(e) => handleSupportAccess(e.target.checked)}
                                checked={server.supportAccess}
                            />
                            <div className={style.item__title}>
                                {intl.getMessage('server_setting_support_access_title')}
                            </div>
                        </div>
                        <div className={style.item__desc}>
                            {intl.getMessage('server_setting_support_access_desc')}
                        </div>
                    </div>
                )}
                <div className={cn(style.item, { [style.block]: windowsDistribution })}>
                    <div className={style.item__header}>
                        <Switch
                            id="console_access"
                            handleChange={(e) => handleConsoleAccess(e.target.checked)}
                            checked={server.consoleAccess}
                        />
                        <div className={style.item__title}>
                            {intl.getMessage('server_setting_term_access_title')}
                        </div>
                    </div>
                    <div className={style.item__desc}>
                        {intl.getMessage('server_setting_term_access_desc')}
                    </div>
                </div>
            </div>
            {openModal && (
                <ModalTermAccess
                    visible={openModal}
                    close={() => {
                        setCodeError(false);
                        setCode('');
                        setOpenModal(false);
                    }}
                    code={code}
                    deliveryMessage={deliveryMessage}
                    codeError={codeError}
                    setCode={(e) => {
                        setCodeError(false);
                        setCode(e);
                    }}
                    onOk={() => handleConsoleAccess(true)}
                    setCodeError={(e) => setCodeError(e)}
                    onAgain={() => sendConfirmationCode({
                        tenantId: server.tenantId,
                        serverId: server.id,
                    })}
                />
            )}
        </>
    );
};

export default Setting;
