import React, { FC } from 'react';
import cn from 'classnames';

import { ListLayout, useIntl } from 'Common';
import Server from 'Entities/Server';
import BackupPolicy from 'Entities/BackupPolicy';
import theme from 'Lib/theme';

import ServerBackup from './ServerBackup';

interface ServerListProps {
    title: string;
    info: string;
    items: Server[];
    other?: boolean;
    tenantBackupPolicy?: BackupPolicy;
}

const ServerList: FC<ServerListProps> = ({ title, info, items, other, tenantBackupPolicy }) => {
    const intl = useIntl();

    return (
        <ListLayout
            title={title}
            info={info}
            length={items.length}
        >
            <div
                className={cn(
                    theme.card.labels,
                    theme.card.labels_withActions,
                    theme.card.labels_projectBackup,
                )}
            >
                <div className={theme.card.label}>
                    {intl.getMessage('server')}
                </div>
                <div className={theme.card.label}>
                    {intl.getMessage('project_backup_setting_label')}
                </div>
            </div>
            {items.map((server) => (
                <ServerBackup
                    key={server.id}
                    server={server}
                    tenantBackupPolicy={tenantBackupPolicy}
                    other={other}
                />
            ))}
        </ListLayout>
    );
};

export default ServerList;
