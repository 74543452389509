import React, { FC, useState, useRef, useEffect, useCallback, FocusEvent } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Dropdown } from 'antd';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import cn from 'classnames';

import { useIntl, Calendar, Icon, Button } from 'Common';
import { useClickOutside } from 'Hooks';
import { ACT_RANGE_TYPE, DATE_RANGE_TYPES, Zoom } from 'Lib/helpers/consts';
import { getActRangeLabel, getRangeLabel } from 'Lib/helpers/translationHelper';
import { Range } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import s from './DropdownRangePicker.module.pcss';

const FULL_YEAR_MONTH_COUNT = 12;

type SetRangeName<T> = (name: T | null) => void;
interface DropdownRangePickerBaseProps {
    range: Range;
    borderless?: boolean;
    disabled?: boolean;
    block?: boolean;
    rangeName?: string;
    setDateRange: (values: Range) => void;
    mode?: CalendarMode;
    minDate?: Dayjs;
    onlyRanges?: boolean;
    globalZoom?: Zoom;
}

interface DropdownRangePickerYearProps extends DropdownRangePickerBaseProps{
    mode: 'year';
    setRangeName?: SetRangeName<ACT_RANGE_TYPE>;
}

interface DropdownRangePickerMonthProps extends DropdownRangePickerBaseProps{
    mode: 'month';
    setRangeName?: SetRangeName<DATE_RANGE_TYPES>;
}

type DropdownRangePickerProps = DropdownRangePickerMonthProps | DropdownRangePickerYearProps;

enum RangeElementName {
    START = 'START',
    END = 'END'
}

let selectPeriod = false;

const DropdownRangePicker: FC<DropdownRangePickerProps> = ({
    range,
    borderless,
    disabled,
    block,
    mode,
    rangeName,
    onlyRanges,
    setDateRange,
    setRangeName,
    minDate,
    globalZoom,
}) => {
    const intl = useIntl();
    const listRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLButtonElement>(null);
    const endRef = useRef<HTMLInputElement>(null);
    const [startRef, setStartRef] = useState<HTMLInputElement>();
    const monthRanges = mode === 'year';

    const DATE_CHANGE_REGEXP = /^[\d\.]*$/;
    const DATE_FORMAT_REGEXP = monthRanges ? /^\d{2}\.\d{4}$/ : /^\d{2}\.\d{2}\.\d{4}$/;
    const VIEW_FORMAT = monthRanges ? 'MM.YYYY' : 'DD.MM.YYYY';
    const DATE_LENGTH = monthRanges ? 7 : 10;
    const DAY_MONTH_LENGTH = monthRanges ? 2 : 5;
    const DAY_LENGTH = 2;

    const saveStartRef = useCallback((node: HTMLInputElement) => {
        if (node) {
            node.focus({ preventScroll: true });
            setStartRef(node);
        }
    }, []);

    const [visibleDropdown, setVisibleDropdown] = useState(false);
    const [calendarValue, setCalendarValue] = useState(range.from);
    const [focusedElement, setFocusedElement] = useState(RangeElementName.START);

    const getFormattedRange = (rangeArg: Range) => {
        let dateFormatType = intl.getMessage('time_format_date');

        if (monthRanges) {
            dateFormatType = intl.getMessage('time_format_month');
        }

        let formattedStart = rangeArg.from.format(dateFormatType);
        let formattedEnd = rangeArg.to.format(dateFormatType);

        if (rangeArg.toDiff('day') <= 0 && (typeof globalZoom?.left === 'number' || onlyRanges)) {
            formattedStart = rangeArg.from.format(intl.getMessage('time_format_month_time'));
            formattedEnd = rangeArg.to.format(intl.getMessage('time_format_month_time'));
        }

        return (`${formattedStart} - ${formattedEnd}`);
    };
    const initialFrom = minDate ? dayjs(minDate) : dayjs().startOf('day');
    let title = range.from.valueOf() === initialFrom.valueOf() ? intl.getMessage('today') : getFormattedRange(range);

    if (rangeName) {
        title = rangeName;
    }

    const [buttonTitle, setButtonTitle] = useState(title);

    useClickOutside([listRef, selectRef], () => setVisibleDropdown(false));

    useEffect(() => {
        if (!rangeName) {
            setButtonTitle(title);
        }
    }, [rangeName]);

    useEffect(() => {
        if (visibleDropdown) {
            setFocusedElement(RangeElementName.START);
        }
    }, [visibleDropdown]);

    useEffect(() => {
        if (!rangeName && !visibleDropdown) {
            setButtonTitle(getFormattedRange(range));
        }
    }, [globalZoom, range]);

    const today = dayjs();
    const monthCount = calendarValue.year() >= today.year() ? today.month() : FULL_YEAR_MONTH_COUNT;

    let ranges: { type: DATE_RANGE_TYPES; value: [Dayjs, Dayjs] }[] = [
        {
            type: DATE_RANGE_TYPES.HOUR_3,
            value: [today.add(-3, 'hour'), today],
        },
        {
            type: DATE_RANGE_TYPES.TODAY,
            value: [today.startOf('day'), today],
        },
        {
            type: DATE_RANGE_TYPES.DAY,
            value: [today.add(-1, 'day'), today],
        },
        {
            type: DATE_RANGE_TYPES.WEEK,
            value: [today.add(-1, 'week'), today],
        },
        {
            type: DATE_RANGE_TYPES.MONTH,
            value: [today.add(-1, 'month'), today],
        },
        {
            type: DATE_RANGE_TYPES.QUARTER,
            value: [today.add(-3, 'month'), today],
        },
        {
            type: DATE_RANGE_TYPES.YEAR,
            value: [today.add(-1, 'year'), today],
        },
    ];

    if (onlyRanges) {
        ranges = ranges.filter((r) => r.type !== DATE_RANGE_TYPES.YEAR);
    }

    const actRanges: { type: ACT_RANGE_TYPE; value: [Dayjs, Dayjs]; isAvailable: boolean }[] = [
        {
            type: ACT_RANGE_TYPE.QUARTER_1,
            value: [calendarValue.startOf('year'), calendarValue.startOf('year').add(2, 'month')],
            isAvailable: (monthCount >= 2),
        },
        {
            type: ACT_RANGE_TYPE.QUARTER_2,
            value: [calendarValue.startOf('year').add(3, 'month'), calendarValue.startOf('year').add(5, 'month')],
            isAvailable: (monthCount >= 5),
        },
        {
            type: ACT_RANGE_TYPE.QUARTER_3,
            value: [calendarValue.startOf('year').add(6, 'month'), calendarValue.startOf('year').add(8, 'month')],
            isAvailable: (monthCount >= 8),
        },
        {
            type: ACT_RANGE_TYPE.QUARTER_4,
            value: [calendarValue.startOf('year').add(9, 'month'), calendarValue.startOf('year').add(11, 'month')],
            isAvailable: (monthCount >= 11),
        },
        {
            type: ACT_RANGE_TYPE.HALF_YEAR_1,
            value: [calendarValue.startOf('year'), calendarValue.startOf('year').add(5, 'month')],
            isAvailable: (monthCount >= 5),
        },
        {
            type: ACT_RANGE_TYPE.HALF_YEAR_2,
            value: [calendarValue.startOf('year').add(6, 'month'), calendarValue.startOf('year').add(11, 'month')],
            isAvailable: (monthCount >= 11),
        },
        {
            type: ACT_RANGE_TYPE.YEAR,
            value: [calendarValue.startOf('year'), calendarValue.startOf('year').add(11, 'month')],
            isAvailable: (monthCount >= 11),
        },
    ];

    const [startDate, setStartDate] = useState(range.from.format(VIEW_FORMAT));
    const [endDate, setEndDate] = useState(range.to.format(VIEW_FORMAT));

    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);

    const handleInputChange = (focused: RangeElementName) => (
        e: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const setValue = focused === RangeElementName.START ? setStartDate : setEndDate;
        const prevValue = focused === RangeElementName.START ? startDate : endDate;
        const { target: { value } } = e;
        if (DATE_CHANGE_REGEXP.test(value) && value.length <= DATE_LENGTH) {
            if (
                ((!monthRanges && value.length === DAY_LENGTH)
                    || value.length === DAY_MONTH_LENGTH)
                && !prevValue.endsWith('.')
            ) {
                setValue(`${value}.`);
            } else {
                setValue(value);
            }
        }
        if (value.length === DATE_LENGTH && focused === RangeElementName.START) {
            setTimeout(() => {
                endRef.current?.focus({ preventScroll: true });
            }, 0);
        }
    };

    const normalizeEndValue = (value: Dayjs) => {
        if (value > today) {
            return today;
        }

        return value.endOf('day');
    };

    const handleInputBlur = (focused: RangeElementName) => (e: FocusEvent<HTMLInputElement>) => {
        if (e.relatedTarget
            && ((e.relatedTarget as HTMLElement).closest('#ranges')
                || (e.relatedTarget as HTMLElement).closest('#calendar'))
        ) {
            return;
        }
        if (!DATE_FORMAT_REGEXP.test(startDate)) {
            setStartDateError(true);
            return;
        }
        setStartDateError(false);
        if (!DATE_FORMAT_REGEXP.test(endDate)) {
            setEndDateError(true);
            return;
        }
        setEndDateError(false);

        const now = dayjs();
        let start = dayjs(startDate, VIEW_FORMAT);
        let end = dayjs(endDate, VIEW_FORMAT).endOf('day');
        start = start > now ? now : start;
        end = end > now ? now : end;
        if (end < start && focused === RangeElementName.END && !selectPeriod) {
            setDateRange(range.update({ from: end, to: start }));
            setStartDate(end.format(VIEW_FORMAT));
            setEndDate(start.format(VIEW_FORMAT));
            setVisibleDropdown(false);
            setButtonTitle(getFormattedRange(range));
            return;
        }
        if (focused === RangeElementName.START) {
            setStartDate(start.format(VIEW_FORMAT));
        } else {
            setEndDate(end.format(VIEW_FORMAT));
            setVisibleDropdown(false);
        }
        const rangeVals = range.areBoundsValid({ from: start, to: end });
        setButtonTitle(getFormattedRange(rangeVals));
        setDateRange(range.update({ from: start, to: end }));

        setRangeName?.(null);
    };

    const handleCalendarChange = () => {
        selectPeriod = true;
    };

    const handleCalendarSelected = (value: Dayjs) => {
        setStartDateError(false);
        setEndDateError(false);
        if (focusedElement === RangeElementName.START) {
            const endValue = range.to.isAfter(value) ? range.to : normalizeEndValue(value);
            setDateRange(range.update({ from: value, to: endValue }));
            setStartDate(value.format(VIEW_FORMAT));
            setEndDate(endValue.format(VIEW_FORMAT));
            if (!selectPeriod) {
                setFocusedElement(RangeElementName.END);
            }
        } else {
            const endValue = normalizeEndValue(value);
            setDateRange(range.update({ to: endValue }));
            setEndDate(endValue.format(VIEW_FORMAT));
            if (!selectPeriod) {
                setVisibleDropdown(false);
                setFocusedElement(RangeElementName.START);
            }
        }
        setCalendarValue(value);
        selectPeriod = false;

        setRangeName?.(null);
    };

    const onDefaultRangeClick = (
        v: [Dayjs, Dayjs],
        type: DATE_RANGE_TYPES,
    ) => () => {
        (setRangeName as DropdownRangePickerMonthProps['setRangeName'])?.(type);
        const label = getRangeLabel(intl, type);

        setDateRange(range.update({ from: v[0], to: v[1] }));
        setVisibleDropdown(false);
        setButtonTitle(label);
        const [start, end] = v;
        setStartDate(start.format(VIEW_FORMAT));
        setEndDate(end.format(VIEW_FORMAT));
    };

    const allTimeRangeClick = (v: Range) => {
        const label = intl.getMessage('all_time');

        setDateRange(range.update({ from: v.from, to: v.to }));
        setVisibleDropdown(false);
        setButtonTitle(label);
        const { from, to } = v;
        setStartDate(from.format(VIEW_FORMAT));
        setEndDate(to.format(VIEW_FORMAT));
        title = label;
    };

    const onActRangeClick = (
        v: [Dayjs, Dayjs],
        type: ACT_RANGE_TYPE,
        activeStatus: boolean,
    ) => () => {
        if (activeStatus) {
            const values = new Range(...v);
            (setRangeName as DropdownRangePickerYearProps['setRangeName'])?.(type);
            setDateRange(range.update({ from: v[0], to: v[1] }));
            setVisibleDropdown(false);
            setButtonTitle(getFormattedRange(values));
            const [start, end] = v;
            setStartDate(start.format(VIEW_FORMAT));
            setEndDate(end.format(VIEW_FORMAT));
            setCalendarValue(end);
        }
    };

    const overlay = (
        <div
            className={cn(
                s.dropdown,
                { [s.dropdown_month]: monthRanges },
                { [s.dropdown_ranges]: onlyRanges },
            )}
            ref={listRef}
        >
            {!monthRanges && (
                <div className={s.ranges} id="ranges">
                    {ranges.map(({ value, type }) => (
                        <div
                            role="button"
                            key={type}
                            className={cn(
                                theme.button.button,
                                theme.button.link,
                                theme.button.link_black,
                                s.range,
                            )}
                            onClick={onDefaultRangeClick(value, type)}
                            tabIndex={0}
                        >
                            {getRangeLabel(intl, type)}
                        </div>
                    ))}
                    {minDate && (
                        <div
                            role="button"
                            key="ALL_TIME"
                            className={cn(
                                theme.button.button,
                                theme.button.link,
                                theme.button.link_black,
                                s.range,
                            )}
                            onClick={() => allTimeRangeClick(new Range(dayjs(minDate), today))}
                            tabIndex={0}
                        >
                            {intl.getMessage('all_time')}
                        </div>
                    )}
                </div>
            )}
            {monthRanges && (
                <div className={s.actRanges} id="actRanges">
                    {actRanges.map(({ value, type, isAvailable }) => (
                        <div
                            role="button"
                            key={type}
                            className={cn(
                                theme.button.button,
                                theme.button.link,
                                theme.button.link_black,
                                s.range,
                                { [s.disabled]: !isAvailable },
                            )}
                            onClick={onActRangeClick(value, type, isAvailable)}
                            tabIndex={0}
                        >
                            {getActRangeLabel(intl, type)}
                        </div>
                    ))}
                </div>
            )}
            {!onlyRanges && (
                <div className={s.picker}>
                    <div className={s.header}>
                        <div className={s.label}>
                            {intl.getMessage('select_date_range')}
                        </div>
                        <div className={s.inputs}>
                            <input
                                ref={saveStartRef}
                                className={cn(
                                    s.input,
                                    {
                                        [s.focused]: focusedElement === RangeElementName.START,
                                        [s.error]: startDateError,
                                    },
                                )}
                                value={startDate}
                                onChange={handleInputChange(RangeElementName.START)}
                                onFocus={(e) => {
                                    setFocusedElement(RangeElementName.START);
                                    e.target.select();
                                }}
                                onBlur={handleInputBlur(RangeElementName.START)}
                            />
                            <span className={s.divider}>–</span>
                            <input
                                ref={endRef}
                                className={cn(
                                    s.input,
                                    {
                                        [s.focused]: focusedElement === RangeElementName.END,
                                        [s.error]: endDateError,
                                    },
                                )}
                                value={endDate}
                                onChange={handleInputChange(RangeElementName.END)}
                                onFocus={(e) => {
                                    setFocusedElement(RangeElementName.END);
                                    e.target.select();
                                }}
                                onBlur={handleInputBlur(RangeElementName.END)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        (e.target as HTMLInputElement).blur();
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className={s.calendarWrapper} id="calendar">
                        <Calendar
                            fullscreen={false}
                            value={calendarValue}
                            disabledDate={(e) => (
                                (e.valueOf() > Date.now())
                                || (focusedElement === RangeElementName.END
                                    && e < dayjs(startDate, VIEW_FORMAT))
                            )}
                            onPanelChange={handleCalendarChange}
                            onSelect={handleCalendarSelected}
                            className={cn(
                                'calendar',
                                { calendar_month: monthRanges },
                                s.calendar,
                            )}
                            mode={mode}
                            monthFullCellRender={(date: Dayjs) => (
                                <div className="ant-picker-cell-inner ant-picker-calendar-date">
                                    {date.format(intl.getMessage('time_format_only_month'))}
                                </div>
                            )}
                            headerRender={({ value, onChange }) => {
                                const current = value.clone();
                                return (
                                    <div className={s.month}>
                                        <Button
                                            type="icon"
                                            icon="down"
                                            className={s.prev}
                                            onClick={() => onChange(current.add(-1, monthRanges ? 'year' : 'month'))}
                                        />
                                        <span className={s.monthName}>
                                            {monthRanges ? (
                                                value.format(intl.getMessage('time_format_year'))
                                            ) : (
                                                value.format(intl.getMessage('time_format_month'))
                                            )}
                                        </span>
                                        <Button
                                            type="icon"
                                            icon="down"
                                            className={s.next}
                                            onClick={() => onChange(current.add(1, monthRanges ? 'year' : 'month'))}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );

    return (
        <Dropdown
            overlay={overlay}
            visible={!disabled && visibleDropdown}
            onVisibleChange={(v: boolean) => {
                setVisibleDropdown(v);
                if (v && startRef) {
                    setTimeout(() => {
                        startRef.focus({ preventScroll: true });
                    }, 0);
                }
            }}
            trigger={['click']}
        >
            <button
                type="button"
                className={cn(
                    s.trigger,
                    {
                        [s.trigger_open]: visibleDropdown,
                        [s.trigger_disabled]: disabled,
                        [s.trigger_borderless]: borderless,
                        [s.trigger_block]: block,
                        [s.trigger_month]: monthRanges,
                    },
                )}
                ref={selectRef}
                disabled={disabled}
            >
                {buttonTitle}
                <Icon icon="down" className={s.arrow} />
            </button>
        </Dropdown>
    );
};

export default DropdownRangePicker;
