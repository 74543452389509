import React, { FC } from 'react';

import { useIntl, ServerIndicator, Link, Icon } from 'Common';
import Server from 'Entities/Server';
import OperationProgress from 'Entities/OperationProgress';
import { getServerIcon } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import s from './ServerItem.module.pcss';

interface ServerItemProps {
    server: Server;
    progress: OperationProgress | undefined;
}

const ServerItem: FC<ServerItemProps> = ({ server, progress }) => {
    const intl = useIntl();
    const {
        id,
        tenantId,
        name,
        distribution,
        costForecast: { overallCost },
        ipv4Addresses,
        ipv6Addresses,
    } = server;
    const ips = [...ipv4Addresses, ...ipv6Addresses];

    const getIps = () => {
        if (ips.length === 0) {
            return null;
        }

        if (ips.length > 1) {
            return intl.getPlural('public_ip_count', ips.length);
        }

        return ips[0].host;
    };

    return (
        <Link
            to={RoutePath.Server}
            props={{ serverId: id, tenantId }}
            className={s.server}
        >
            <div className={s.wrap}>
                <ServerIndicator
                    state={server.state}
                    progress={progress}
                    className={s.indicator}
                />
                <Icon
                    icon={getServerIcon(distribution.type)}
                    className={s.type}
                />
                <div className={theme.common.textOverflow}>
                    {name}
                </div>
            </div>
            <div className={s.cost}>
                {intl.getMessage('cost', { value: Math.round(overallCost) })}
            </div>
            <div className={s.ips}>
                {getIps()}
            </div>
        </Link>
    );
};

export default ServerItem;
