import React, { FC, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Popover } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';

import { Store } from 'Store';
import { useIntl, Link, Button } from 'Common';
import Tenant from 'Entities/Tenant';
import DnsZone from 'Entities/DnsZone';
import { RoutePath } from 'Lib/helpers/routes';
import { DATE_FORMAT } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import { deleteDnsZone } from 'Actions/dns';

import { DeleteDnsZone } from '../Modals';

interface ZoneOwnProps {
    zone: DnsZone;
}
interface ZoneStoreProps {
    tenant?: Tenant;
}
type ZoneProps = ZoneOwnProps & ZoneStoreProps;

const Zone: FC<ZoneProps> = ({ zone, tenant }) => {
    const { id, host, tenantId, timeUpdated, recordsCount } = zone;
    const intl = useIntl();
    const dispatch = useDispatch();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const parsedTime = dayjs(timeUpdated, DATE_FORMAT);
    const formattedTime = parsedTime.format(intl.getMessage('time_format_short_month_year'));

    const handleDelete = () => {
        dispatch(deleteDnsZone({ dnsZoneId: id, tenantId }));
    };

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.with_actions,
            )}
            data-row="dnsZoneRow"
        >
            <div className={cn(theme.card.grid, theme.card.grid_zone)}>
                <div className={theme.common.textOverflow}>
                    {host}
                </div>
                <div className={cn({ [theme.color.grayHeaders]: recordsCount === 0 })}>
                    {recordsCount}
                </div>
                <div className={theme.common.textOverflow}>
                    {tenant?.description}
                </div>
                <div className={theme.card.actionsWrap}>
                    <div className={theme.color.grayHeaders}>
                        {formattedTime}
                    </div>

                    <div className={theme.card.actions}>
                        <Link
                            to={RoutePath.DnsZonesRecords}
                            className={cn(theme.card.action, theme.card.action_hover)}
                            props={{ zoneId: id }}
                        >
                            <Button
                                type="icon"
                                icon="edit"
                                title={intl.getMessage('title_dns_zone_editor')}
                            />
                        </Link>
                        <a
                            href={zone.exportDnsZoneUrl}
                            className={cn(theme.card.action, theme.card.action_hover)}
                        >
                            <Button
                                type="icon"
                                icon="download"
                                className={cn(theme.card.action, theme.card.action_hover)}
                                title={intl.getMessage('title_dns_zone_export')}
                            />
                        </a>
                        <Button
                            type="icon"
                            icon="delete"
                            className={cn(theme.card.action, theme.card.action_hover)}
                            onClick={() => setOpenDeleteModal(true)}
                            title={intl.getMessage('title_dns_zone_delete')}
                        />
                    </div>
                </div>
            </div>

            <Popover
                placement="leftTop"
                trigger="click"
                content={(
                    <div className={theme.popover.popover}>
                        <Link
                            to={RoutePath.DnsZonesRecords}
                            props={{ zoneId: id }}
                            className={theme.popover.item}
                        >
                            {intl.getMessage('edit')}
                        </Link>
                        <a
                            href={zone.exportDnsZoneUrl}
                            className={theme.popover.item}
                        >
                            {intl.getMessage('title_dns_zone_export')}
                        </a>
                        <button
                            type="button"
                            onClick={() => setOpenDeleteModal(true)}
                            className={cn(
                                theme.popover.item,
                                theme.popover.item_danger,
                            )}
                        >
                            {intl.getMessage('delete')}
                        </button>
                    </div>
                )}
                overlayClassName="popover"
            >
                <Button
                    type="icon"
                    icon="options"
                    className={cn(theme.card.action, theme.card.action_popover)}
                />
            </Popover>

            {openDeleteModal && (
                <DeleteDnsZone
                    visible={openDeleteModal}
                    host={host}
                    handleDelete={() => handleDelete()}
                    handleClose={() => setOpenDeleteModal(false)}
                />
            )}
        </div>
    );
};

const tenantSelector = (store: Store, ownProps: ZoneOwnProps) => {
    const list: Tenant[] = [];
    store.tenant.forEach((item) => {
        const { zone: { tenantId } } = ownProps;
        if (item.id === tenantId) {
            list.push(item);
        }
    });
    return list[0];
};

const mapStateToProps = (store: Store, ownProps: ZoneOwnProps) => ({
    tenant: tenantSelector(store, ownProps),
});

export default connect(mapStateToProps)(Zone);
