// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerUpdate {
    comment?: string;
    name?: string;
    to_tenant_id?: number;
}

export default class ServerUpdate {
    readonly _comment: string | undefined;

    /**
     * Description: New comment
     * Example: It's dev backend
     */
    get comment(): string | undefined {
        return this._comment;
    }

    static get commentMinLength() {
        return 0;
    }

    static get commentMaxLength() {
        return 200;
    }

    static commentValidate(comment: string | undefined): boolean {
        return (!comment ? true : comment.length > 0) && (!comment ? true : comment.length <= 200);
    }

    readonly _name: string | undefined;

    /**
     * Description: New name
     * Example: dev_backend
     */
    get name(): string | undefined {
        return this._name;
    }

    static get nameMinLength() {
        return 0;
    }

    static get nameMaxLength() {
        return 70;
    }

    static nameValidate(name: string | undefined): boolean {
        return (!name ? true : name.length > 0) && (!name ? true : name.length <= 70);
    }

    readonly _to_tenant_id: number | undefined;

    /**
     * Description: Move to another tenant
     * Example: 48142
     */
    get toTenantId(): number | undefined {
        return this._to_tenant_id;
    }

    constructor(props: IServerUpdate) {
        if (typeof props.comment === 'string') {
            this._comment = props.comment.trim();
        }
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
        if (typeof props.to_tenant_id === 'number') {
            this._to_tenant_id = props.to_tenant_id;
        }
    }

    serialize(): IServerUpdate {
        const data: IServerUpdate = {
        };
        if (typeof this._comment !== 'undefined') {
            data.comment = this._comment;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        if (typeof this._to_tenant_id !== 'undefined') {
            data.to_tenant_id = this._to_tenant_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            name: (!this._name ? true : this._name.length > 0) && (!this._name ? true : this._name.length <= 70),
            to_tenant_id: !this._to_tenant_id ? true : typeof this._to_tenant_id === 'number',
            comment: (!this._comment ? true : this._comment.length > 0) && (!this._comment ? true : this._comment.length <= 200),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerUpdate): ServerUpdate {
        return new ServerUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        comment: 'comment',
        name: 'name',
        toTenantId: 'to_tenant_id',
        }
;

    mergeDeepWith(props: Partial<ServerUpdate>): ServerUpdate {
        const updateData: Partial<IServerUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerUpdate) => {
            const updateKey = this.keys[key] as keyof IServerUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerUpdate, keyof IServerUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerUpdate({ ...this.serialize(), ...updateData });
    }
}
