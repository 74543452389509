import React, { FC } from 'react';

import s from '../Chart.module.pcss';

interface TickType {
    className: string;
    fill: string;
    height: number;
    index: number;
    orientation: string;
    payload: {
        coordinate: number;
        value: number;
        offset: number;
    };
    stroke: string;
    textAnchor: string;
    tickFormatter: (value: number) => number;
    type: string;
    verticalAnchor: string;
    visibleTicksCount: number;
    width: number;
    x: number;
    y: number;
}

interface CustomTickOwnProps {
    unit?: string;
}

type CustomTickProps = TickType & CustomTickOwnProps;

const CustomTick: FC<CustomTickProps> = ({
    x, y, payload, tickFormatter, unit,
}) => {
    const value = tickFormatter(payload.value);

    return (
        <foreignObject transform={`translate(${x},${y})`} className={s.tickWrapper}>
            <div className={s.tick}>
                {value > 0 && (
                    <>
                        {value}&nbsp;{unit}
                    </>
                )}
            </div>
        </foreignObject>
    );
};

export default CustomTick;
