import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Grid } from 'antd';
import cn from 'classnames';

import { useIntl, Icon, Button, Link } from 'Common';
import { getResellerClients } from 'Actions/reseller';
import { toggleSidebar } from 'Actions/ui';
import { RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import theme from 'Lib/theme';

import ChangeAccount from './ChangeAccount';
import s from './Client.module.pcss';

const { useBreakpoint } = Grid;

const selectAccount = (store: Store) => store.account.account;
const selectAccountDetails = (store: Store) => store.account.details;
const selectResellerClients = (store: Store) => store.reseller.clients;
const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([
    selectAccount,
    selectAccountDetails,
    selectResellerClients,
    selectProfile,
], (account, details, clients, profile) => (
    { account, details, clients, profile }
));

const Client: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const screens = useBreakpoint();

    const { account, details, clients, profile } = useSelector(selector);

    const [showChangeAccount, setShowChangeAccount] = useState(false);

    const balance = (details && Math.trunc(details.balance)) || 0;

    useEffect(() => {
        if (profile?.reseller || MAIN_TOKEN) {
            dispatch(getResellerClients([MAIN_TOKEN || AUTH_TOKEN]));
        }
    }, []);

    return (
        <>
            <div className={s.link}>
                <Link
                    to={RoutePath.Dashboard}
                    className={s.logo}
                >
                    <Icon icon="panel_logo" className={s.icon} />
                    <span className={s.name}>
                        {intl.getMessage('dashboard')}
                    </span>
                </Link>
                <div
                    className={s.account}
                    role="button"
                    tabIndex={0}
                    onClick={(e) => {
                        e.stopPropagation();
                        setShowChangeAccount(true);
                        if (!screens.xl) {
                            dispatch(toggleSidebar());
                        }
                    }}
                >
                    <div className={s.row}>
                        <div className={s.email}>
                            {account?.email || ''}
                        </div>
                        <Button
                            type="icon"
                            size="small"
                            icon="side"
                            className={cn(
                                theme.button.dark,
                                theme.button.small,
                                s.changeAccount,
                                { [theme.button.active]: showChangeAccount },
                            )}
                        />
                    </div>
                    <div className={s.client} onClick={(e) => e.stopPropagation()}>
                        {intl.getMessage('client', { id: account?.clientId ?? '' })}
                    </div>
                    <div className={s.funds}>
                        <Icon icon="coins" className={s.coins} />
                        {intl.getMessage('money', { value: balance })}
                    </div>
                </div>
            </div>

            {account && showChangeAccount && (
                <ChangeAccount
                    currentEmail={account.email}
                    currentId={account.clientId}
                    clients={clients}
                    visible={showChangeAccount}
                    handleClose={() => setShowChangeAccount(false)}
                    reseller={profile?.reseller}
                />
            )}
        </>
    );
};

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);
export default connect(mapStoreToProps)(Client);
