import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { useEnter, useTitle } from 'Hooks';
import { getResellerDetails, doResellerTransferPayment } from 'Actions/reseller';
import { useIntl, FormPageLayout, Link, Icon, Button, Input } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { onChangeCheckNumberOrFloat, onSubmitCheckNumberOrFloat } from 'Lib/helpers/helpers';
import { currencySymbol } from 'Lib/helpers/consts';
import { getAvailableAmountBages } from 'Lib/helpers/utils';
import theme from 'Lib/theme';
import { Store } from 'Store';

import s from './Payment.module.pcss';

interface TransferPaymentProps {
    details: Store['reseller']['details'];
}
const TransferPayment: FC<TransferPaymentProps> = ({ details }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('clients_transfer_payment_page_title'));
    const [paymentAmount, setPaymentAmount] = useState<string | null>(null);
    const [paymentError, setPaymentError] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(getResellerDetails());
    }, []);
    const submit = () => {
        if (!paymentAmount || !parseFloat(paymentAmount) || !onSubmitCheckNumberOrFloat(paymentAmount)) {
            setPaymentError(true);
            return;
        }
        dispatch(doResellerTransferPayment(
            [{ amount: parseFloat(paymentAmount!) }],
            { result: () => {
                dispatch(getResellerDetails());
                history.goBack();
            } },
        ));
    };
    useEnter(submit, [paymentAmount]);
    if (!details) {
        return null;
    }

    const handleChange = (e: string) => {
        if (onChangeCheckNumberOrFloat(e) || e === '') {
            setPaymentAmount(e);
        }
    };

    const getLabel = () => {
        const amountLabel = paymentAmount ? intl.getMessage('price_simple', { value: paymentAmount }) : '';
        return `${intl.getMessage('transfer')} ${amountLabel}`;
    };

    return (
        <FormPageLayout
            title={intl.getMessage('partners_transfer_to_main_account')}
            back={RoutePath.Clients}
        >
            <div className={s.text}>
                <Icon icon="coins" className={s.icon} />
                {intl.getMessage('partners_current_balance', { value: intl.getMessage('money', { value: details.balance }) })}
            </div>
            <div className={s.input}>
                <Input
                    name="paymentAmount"
                    size="large"
                    type="text"
                    className={cn('input', 'input_big', { input_error: paymentError })}
                    suffix={currencySymbol.RUB}
                    value={paymentAmount || ''}
                    onChange={handleChange}
                    placeholder={intl.getMessage('sum')}
                    error={paymentError}
                    validate={(e) => !!e}
                    autoFocus
                />
            </div>
            <div className={cn(s.group, s.group_last)}>
                {getAvailableAmountBages(details.balance).map((v) => (
                    <button
                        type="button"
                        key={v}
                        className={cn(
                            theme.Badge.badge,
                            theme.Badge.subject,
                            s.badge,
                        )}
                        onClick={() => setPaymentAmount(String(v))}
                    >
                        {intl.getMessage('price_simple', { value: v })}
                    </button>
                ))}
            </div>
            <div>
                <Button
                    type="primary"
                    size="big"
                    disabled={!paymentAmount}
                    onClick={submit}
                    inGroup
                >
                    {getLabel()}
                </Button>
                <Link to={RoutePath.Clients}>
                    <Button
                        type="link"
                        size="big"
                    >
                        {intl.getMessage('cancel')}
                    </Button>
                </Link>
            </div>
        </FormPageLayout>
    );
};

const selectResellerDetails = (store: Store) => store.reseller.details;

const selector = createSelector(
    [selectResellerDetails], (details) => ({
        details,
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(TransferPayment);
