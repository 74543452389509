import React, { FC } from 'react';

import { useIntl, Input, Select, Option } from 'Common';
import DnsRecordCreate from 'Entities/DnsRecordCreate';

import TtlSelect from '../TtlSelect';

import { RecordFormProps, Buttons } from '.';
import s from '../Form.module.pcss';

enum Protocols {
    TCP = '_tcp',
    UDP = '_udp',
    TLS = '_tls',
}

const SrvRecordForm: FC<RecordFormProps> = (props) => {
    const { values, errors, setFieldValue, onClose, zone } = props;
    const intl = useIntl();
    const placeholder = intl.getMessage('dns_host_placeholder', { name: zone.host });

    const hostData = values.host?.split('.') || ['', Protocols.TCP, ''];
    const service = hostData[0] ?? '';
    const protocol = hostData[1] ?? Protocols.TCP;
    const name = hostData.slice(2).join('.') ?? '';

    const onHostChange = (field: 'service' | 'protocol' | 'name') => (value: string) => {
        const newHost = [field === 'service' ? value : service, field === 'protocol' ? value : protocol, field === 'name' ? value : name];
        setFieldValue('host', newHost.join('.'));
    };

    const protocols = Object.entries(Protocols);
    return (
        <>
            <Input
                error={!!errors.host}
                errorMessage={errors.host}
                label={intl.getMessage('dns_value')}
                name="service"
                onChange={onHostChange('service')}
                placeholder="_xmpp-server"
                size="middle"
                type="text"
                validate={DnsRecordCreate.hostValidate}
                value={service}
            />
            <div className={s.group}>
                <div className={s.label}>
                    {intl.getMessage('dns_protocol')}
                </div>
                <Select
                    block
                    size="big"
                    id="protocol"
                    className="select--setting"
                    placeholder={intl.getMessage('dns_ttl')}
                    value={protocol}
                    onChange={onHostChange('protocol')}
                >
                    {protocols.map(([label, value]) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>
            <Input
                error={!!errors.host}
                errorMessage={errors.host}
                label={intl.getMessage('dns_name')}
                name="name"
                onChange={onHostChange('name')}
                onBlur={() => {
                    if (values.host === zone.host || values.host.endsWith(`.${zone.host}`)) {
                        setFieldValue('host', `${values.host}.`);
                    }
                }}
                placeholder={placeholder}
                size="middle"
                type="text"
                validate={DnsRecordCreate.hostValidate}
                value={name}
            />
            <Input
                error={!!errors.data}
                errorMessage={errors.data}
                label={intl.getMessage('dns_value')}
                name="data"
                onChange={(e) => setFieldValue('data', e)}
                placeholder="xmpp-provider.ru."
                size="middle"
                type="text"
                validate={DnsRecordCreate.dataValidate}
                value={values.data}
            />
            <Input
                error={!!errors.priority}
                errorMessage={errors.priority}
                label={intl.getMessage('dns_priority')}
                name="priority"
                onChange={(e) => setFieldValue('priority', e)}
                placeholder={`${DnsRecordCreate.priorityMinValue} - ${DnsRecordCreate.priorityMaxValue}`}
                size="middle"
                type="number"
                validate={(e) => DnsRecordCreate.priorityValidate(Number(e))}
                value={values.priority}
            />
            <Input
                error={!!errors.weight}
                errorMessage={errors.weight}
                label={intl.getMessage('dns_weight')}
                name="weight"
                onChange={(e) => setFieldValue('weight', e)}
                placeholder={`${DnsRecordCreate.weightMinValue} - ${DnsRecordCreate.weightMaxValue}`}
                size="middle"
                type="number"
                validate={(e) => DnsRecordCreate.weightValidate(Number(e))}
                value={values.weight}
            />
            <Input
                error={!!errors.port}
                errorMessage={errors.port}
                label={intl.getMessage('dns_port')}
                name="port"
                onChange={(e) => setFieldValue('port', e)}
                placeholder={`${DnsRecordCreate.portMinValue} - ${DnsRecordCreate.portMaxValue}`}
                size="middle"
                type="number"
                validate={(e) => DnsRecordCreate.portValidate(Number(e))}
                value={values.port}
            />
            <TtlSelect
                error={!!errors.ttl_seconds}
                value={values.ttl_seconds}
                onChange={(ttlSeconds) => setFieldValue('ttl_seconds', ttlSeconds)}
            />
            <Buttons onClose={onClose} isEdited={values.isEdited} />
        </>
    );
};

export default SrvRecordForm;
