// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsZoneUpdate {
    to_tenant_id?: number;
}

export default class DnsZoneUpdate {
    readonly _to_tenant_id: number | undefined;

    /**
     * Description: Move to another tenant
     * Example: 48142
     */
    get toTenantId(): number | undefined {
        return this._to_tenant_id;
    }

    constructor(props: IDnsZoneUpdate) {
        if (typeof props.to_tenant_id === 'number') {
            this._to_tenant_id = props.to_tenant_id;
        }
    }

    serialize(): IDnsZoneUpdate {
        const data: IDnsZoneUpdate = {
        };
        if (typeof this._to_tenant_id !== 'undefined') {
            data.to_tenant_id = this._to_tenant_id;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            to_tenant_id: !this._to_tenant_id ? true : typeof this._to_tenant_id === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsZoneUpdate): DnsZoneUpdate {
        return new DnsZoneUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        toTenantId: 'to_tenant_id',
        }
;

    mergeDeepWith(props: Partial<DnsZoneUpdate>): DnsZoneUpdate {
        const updateData: Partial<IDnsZoneUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsZoneUpdate) => {
            const updateKey = this.keys[key] as keyof IDnsZoneUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsZoneUpdate, keyof IDnsZoneUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsZoneUpdate({ ...this.serialize(), ...updateData });
    }
}
