import React, { FC } from 'react';

import { CommonModalLayout, useIntl } from 'Common';
import theme from 'Lib/theme';

interface CancelAutoTopUpProps {
    visible: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
    titleModal: string;
    descModal: string;
}

const CancelAutoTopUp: FC<CancelAutoTopUpProps> = ({
    visible, handleClose, handleSubmit, titleModal, descModal,
}) => {
    const intl = useIntl();

    return (
        <CommonModalLayout
            visible={visible}
            title={titleModal}
            buttonText={intl.getMessage('billing_auto_invoice_cancel')}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
        >
            <div className={theme.modal.desc}>
                {descModal}
            </div>
        </CommonModalLayout>
    );
};

export default CancelAutoTopUp;
