import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import serverApi from 'Apis/servers';
import { CommonModalLayout, useIntl, Icon, Button, ServerIndicator, Loader } from 'Common';
import Server, { IServer } from 'Entities/Server';
import Tenant from 'Entities/Tenant';
import { errorChecker, getServerIcon } from 'Lib/helpers/utils';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import theme from 'Lib/theme';

import Search from './Search';

interface ModalIpMoveStoreProps {
    tenants: Tenant[];
}

interface ModalIpMoveOwnProps {
    visible: boolean;
    onClose: () => void;
    onOk: () => void;
    currentServer: Server;
    setCurrentServer: (server: Server) => void;
    server: Server;
}

type ModalIpMoveProps = ModalIpMoveOwnProps & ModalIpMoveStoreProps;

const ModalIpMove: FC<ModalIpMoveProps> = ({
    setCurrentServer,
    currentServer,
    onClose,
    onOk,
    server,
    tenants,
}) => {
    const intl = useIntl();
    const history = useHistory();
    const { id: serverId, tenantId } = server;
    const [options, setOptions] = useState<Map<number, Server> | null>(null);
    const [showSearch, setShowSearch] = useState(false);
    const [localServer, setLocalServer] = useState<Server | null>(null);

    useEffect(() => {
        let canUpdate = true;
        const loader = async () => {
            const resp = await serverApi.getServersToMoveIp(tenantId, serverId);
            const { result } = errorChecker<IServer[]>(resp);
            if (result && canUpdate) {
                const servers = new Map<number, Server>();
                result.forEach((s) => {
                    servers.set(s.id, new Server(s));
                });
                setOptions(servers);
            }
        };
        loader();
        return () => {
            canUpdate = false;
        };
    }, []);

    const handleSubmit = () => {
        if (options && options?.size > 0) {
            onOk();
        } else {
            history.push(linkPathBuilder(intl.currentLocale, RoutePath.NewServer));
        }
    };

    const handleSelect = (s: Server) => {
        setCurrentServer(s);
        setLocalServer(s);
        setShowSearch(false);
    };

    const getButtonText = () => {
        if (options?.size === 0) {
            return intl.getMessage('create_server');
        }

        return intl.getMessage('server_to_other_tenant_modal_ok');
    };

    const getContent = () => {
        if (options === null) {
            return (
                <div className={theme.modal.search}>
                    <Loader circle mini />
                </div>
            );
        }

        if (options?.size === 0) {
            return (
                <div className={theme.modal.desc}>
                    {intl.getMessage('server_ip_to_other_server_empty')}
                </div>
            );
        }

        if (showSearch) {
            return (
                <Search
                    servers={options}
                    tenants={tenants}
                    currentServerId={currentServer.id}
                    handleClose={() => setShowSearch(false)}
                    handleSelect={handleSelect}
                />
            );
        }

        return (
            <>
                <div className={theme.modal.desc}>
                    {intl.getMessage('server_ip_to_other_server_modal_text')}
                </div>
                <Button
                    type="input"
                    className={theme.button.input_search}
                    onClick={() => setShowSearch(true)}
                >
                    {localServer ? (
                        <>
                            <ServerIndicator state={localServer.state} />
                            <Icon icon={getServerIcon(localServer.distribution.type)} />
                            <div
                                className={cn(
                                    theme.search.name,
                                    theme.search.name_server,
                                )}
                            >
                                {localServer.name}
                            </div>
                            <div className={theme.search.id}>
                                #{localServer.id}
                            </div>
                        </>
                    ) : (
                        intl.getMessage('server_ip_to_other_server_modal_placeholder')
                    )}
                    <Icon icon="side" className={theme.search.arrow} />
                </Button>
            </>
        );
    };

    return (
        <CommonModalLayout
            visible
            title={!showSearch && intl.getMessage('server_ip_to_other_server_modal_title')}
            handleSubmit={handleSubmit}
            handleClose={onClose}
            buttonText={getButtonText()}
            noFooter={showSearch}
            className={cn({ modal_search: showSearch })}
        >
            {getContent()}
        </CommonModalLayout>
    );
};

const selectTenants = (store: Store) => store.tenant;

const selector = createSelector(
    [selectTenants],
    (tenants) => ({
        tenants: Array.from(tenants.values()),
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(ModalIpMove);
