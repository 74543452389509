import { Action } from 'store/actions';
import {
    NewServerActions,
    saveCostForecastForDraft,
    saveCostForecastForServer,
    saveResourseLimints,
} from 'Actions/newServer';

import { NewServerStore, newServerInitialState as initialState } from '../initialStates';

const reducer = (state: NewServerStore = initialState, action: Action): NewServerStore => {
    switch (action.type as NewServerActions) {
        case NewServerActions.SaveCostForecastForDraft: {
            const { payload } = action as ReturnType<typeof saveCostForecastForDraft>;
            return { ...state, price: payload };
        }
        case NewServerActions.SaveCostForecastForServer: {
            const { payload } = action as ReturnType<typeof saveCostForecastForServer>;
            return { ...state, price: payload };
        }
        case NewServerActions.SaveResourseLimints: {
            const { payload } = action as ReturnType<typeof saveResourseLimints>;
            return { ...state, resourseLimits: payload };
        }
        default:
            return state;
    }
};

export default reducer;
