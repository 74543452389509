import NotificationsApi from 'Apis/notifications';
import Notification from 'Entities/Notification';
import NotificationRecipient from 'Entities/NotificationRecipient';
import NotificationSettings from 'Entities/NotificationSettings';
import Trigger from 'Entities/Trigger';

import { actionCreator } from '.';

export enum NotificationsActions {
    GetNotifications = 'GetNotifications',
    SaveNotifications = 'SaveNotifications',
    UpdateNotifications = 'UpdateNotifications',
    GetNotificationRecipients = 'GetNotificationRecipients',
    SaveNotificationRecipients = 'SaveNotificationRecipients',
    DeleteNotificationRecipient = 'DeleteNotificationRecipient',
    CreateNotificationRecipient = 'CreateNotificationRecipient',
    UpdateNotificationRecipient = 'UpdateNotificationRecipient',
    GetNotificationsSettings = 'GetNotificationsSettings',
    SaveNotificationsSettings = 'SaveNotificationsSettings',
    UpdateNotificationSettings = 'UpdateNotificationSettings',
    ClearNotificationSettings = 'ClearNotificationSettings',
    GetTriggerRules = 'GetTriggerRules',
    SaveTriggerRules = 'SaveTriggerRules',
    DeleteTriggerRule = 'DeleteTriggerRule',
    CreateTriggerRule = 'CreateTriggerRule',
    UpdateTriggerRule = 'UpdateTriggerRule',
    ClearNotifications = 'ClearNotifications',
    MarkNotificationSeen = 'MarkNotificationSeen',
}

type GetNotifications = Parameters<typeof NotificationsApi.getNotifications>;
const getNotifications = actionCreator<GetNotifications>(NotificationsActions.GetNotifications);
const saveNotifications = actionCreator<Notification[]>(
    NotificationsActions.SaveNotifications,
);
type GetNotificationRecipients = Parameters<typeof NotificationsApi.getNotificationRecipients>;
const getNotificationRecipients = actionCreator<GetNotificationRecipients>(
    NotificationsActions.GetNotificationRecipients,
);
const saveNotificationRecipients = actionCreator<NotificationRecipient[]>(
    NotificationsActions.SaveNotificationRecipients,
);
type DeleteNotificationRecipient = Parameters<typeof NotificationsApi.deleteNotificationRecipient>;
const deleteNotificationRecipient = actionCreator<DeleteNotificationRecipient>(
    NotificationsActions.DeleteNotificationRecipient,
);
type CreateNotificationRecipient = Parameters<typeof NotificationsApi.createNotificationRecipient>;
const createNotificationRecipient = actionCreator<CreateNotificationRecipient>(
    NotificationsActions.CreateNotificationRecipient,
);
type UpdateNotificationRecipient = Parameters<typeof NotificationsApi.updateNotificationRecipient>;
const updateNotificationRecipient = actionCreator<UpdateNotificationRecipient>(
    NotificationsActions.UpdateNotificationRecipient,
);

type GetNotificationsSettings = Parameters<typeof NotificationsApi.getNotificationsSettings>;
const getNotificationsSettings = actionCreator<GetNotificationsSettings>(
    NotificationsActions.GetNotificationsSettings,
);
const saveNotificationsSettings = actionCreator<NotificationSettings>(
    NotificationsActions.SaveNotificationsSettings,
);
type UpdateNotificationSettings = Parameters<typeof NotificationsApi.updateNotificationSettings>;
const updateNotificationSettings = actionCreator<UpdateNotificationSettings>(
    NotificationsActions.UpdateNotificationSettings,
);

const clearNotificationSettings = actionCreator(NotificationsActions.ClearNotificationSettings);

type GetTriggerRules = Parameters<typeof NotificationsApi.getTriggerRules>;
const getTriggerRules = actionCreator<GetTriggerRules>(
    NotificationsActions.GetTriggerRules,
);
const saveTriggerRules = actionCreator<Trigger[]>(
    NotificationsActions.SaveTriggerRules,
);
type DeleteTriggerRule = Parameters<typeof NotificationsApi.removeTriggerRule>;
const deleteTriggerRule = actionCreator<DeleteTriggerRule>(
    NotificationsActions.DeleteTriggerRule,
);
type CreateTriggerRule = Parameters<typeof NotificationsApi.createTriggerRule>;
const createTriggerRule = actionCreator<CreateTriggerRule>(
    NotificationsActions.CreateTriggerRule,
);
type UpdateTriggerRule = Parameters<typeof NotificationsApi.updateTriggerRule>;
const updateTriggerRule = actionCreator<UpdateTriggerRule>(
    NotificationsActions.UpdateTriggerRule,
);

interface MarkNotificationSeen {
    parameters: Parameters<typeof NotificationsApi.markNotificationSeen>;
    clear?: boolean;
}
const markNotificationSeen = actionCreator<MarkNotificationSeen>(
    NotificationsActions.MarkNotificationSeen,
);

const clearNotifications = actionCreator(NotificationsActions.ClearNotifications);

export {
    getNotifications,
    saveNotifications,
    getNotificationRecipients,
    saveNotificationRecipients,
    deleteNotificationRecipient,
    createNotificationRecipient,
    updateNotificationRecipient,
    getNotificationsSettings,
    saveNotificationsSettings,
    updateNotificationSettings,
    clearNotificationSettings,
    getTriggerRules,
    saveTriggerRules,
    deleteTriggerRule,
    createTriggerRule,
    updateTriggerRule,
    clearNotifications,
    markNotificationSeen,
};
