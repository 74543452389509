import { takeEvery, put, call } from 'redux-saga/effects';

import serverApi from 'Apis/servers';
import {
    ServerStateEventsActions,
    getServerStateEventsList as getServerStateEventsListAct,
    saveServerStateEventsList,
} from 'Actions/serverStateEvents';
import ServerStateEvent, { IServerStateEvent } from 'Entities/ServerStateEvent';

import { errorChecker, addError } from './utils';

function* getServerStateEventsList(action: ReturnType<typeof getServerStateEventsListAct>) {
    const { payload } = action;
    const response: IServerStateEvent[] = yield call(serverApi.getServerStateEvents, ...payload);
    const { error, result } = errorChecker<IServerStateEvent[]>(response);
    if (result) {
        const eventEntities = result.map((t) => new ServerStateEvent(t));
        yield put(saveServerStateEventsList(eventEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* serverStateEventsSaga(): Generator {
    yield takeEvery<ReturnType<typeof getServerStateEventsListAct>>(
        ServerStateEventsActions.GetServerStateEventsList,
        getServerStateEventsList,
    );
}

export default serverStateEventsSaga;
