import React, { FC, Fragment, useEffect } from 'react';
import cn from 'classnames';
import dayjs from 'dayjs';

import { DashboardHeader, InnerPageLayout, Link, useIntl } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { useTitle } from 'Lib/hooks/hooks';
import theme from 'Lib/theme';

import s from './ChangeLog.module.pcss';
import logJson from './log.json';

const ChangeLog: FC = () => {
    const intl = useIntl();
    useTitle(intl.getMessage('changelog_page_title'));

    useEffect(() => {
        if (logJson.length > 0) {
            const logLastElement = logJson[0];
            AppConfigStorage.setLastChangeLogDate(logLastElement.timeAdded);
        }
    }, []);

    return (
        <InnerPageLayout
            header={(
                <DashboardHeader
                    navigation={(
                        <>
                            <Link
                                to={RoutePath.Dashboard}
                                className={theme.header.link}
                            >
                                {intl.getMessage('menu_dashboard')}
                            </Link>
                            <div className={cn(theme.header.link, theme.header.active)}>
                                {intl.getMessage('changelog_title')}
                            </div>
                        </>
                    )}
                />
            )}
        >
            <div className={s.wrap}>
                {logJson.map(({ timeAdded, description }, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={s.item} key={index}>
                        {description.map((desc: string, i: number) => {
                            const date = dayjs(timeAdded).format(intl.getMessage('time_format_date_short'));

                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className={s.row} key={i}>
                                    <div className={s.info}>
                                        {i === 0 && (
                                            <>
                                                <div className={s.dot} />
                                                <div className={s.date}>
                                                    {date}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <div className={s.desc}>
                                            {i === 0 && (
                                                <div className={cn(s.date, s.date_mobile)}>
                                                    {date}
                                                </div>
                                            )}

                                            {desc}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </InnerPageLayout>
    );
};

export default ChangeLog;
