import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setOperationNotifications } from 'Actions/ui';
import { useIntl, CommonModalLayout } from 'Common';
import { SERVER_OPERATION_NOTIFICATION } from 'Lib/helpers/consts';

import ModalCheckbox from './ModalCheckbox';

interface ResetModalProps {
    visible: boolean;
    close: () => void;
    handleSubmit: () => void;
}

const ResetModal: FC<ResetModalProps> = ({ visible, close, handleSubmit }) => {
    const intl = useIntl();
    const [checked, setChecked] = useState(false);
    const dispatch = useDispatch();

    const handleChecked = (isChecked: boolean) => {
        setChecked(isChecked);
        dispatch(setOperationNotifications(SERVER_OPERATION_NOTIFICATION.RESET));
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('server_force_reboot')}
            handleSubmit={handleSubmit}
            handleClose={close}
            buttonText={intl.getMessage('reboot')}
            width={480}
        >
            <div className="modal__desc">
                {intl.getMessage('modal_reset_desc')}
            </div>
            <div className="modal__desc">
                <div>
                    {intl.getMessage('modal_reset_desc_two')}
                </div>
                <div>
                    {intl.getMessage('modal_reset_desc_three')}
                </div>
            </div>
            <ModalCheckbox
                checked={checked}
                handleChange={handleChecked}
            />
        </CommonModalLayout>
    );
};

export default ResetModal;
