import SecurityActionSettings from 'Entities/SecurityActionSettings';
import SecurityActionsApi from 'Apis/securityActions';
import { NotificationDeliveryType } from 'Entities/NotificationDeliveryType';

import { actionCreator } from '.';

export enum SecurityActions {
    GetAllSecuritySettings = 'GetAllSecuritySettings',
    SaveAllSecuritySettings = 'SaveAllSecuritySettings',
    UpdateSecuritySettings = 'UpdateSecuritySettings',
    SendSecurityCode = 'SendSecurityCode',
    SaveDeliveryType = 'SaveDeliveryType',
    SendBatchSecurityCode = 'SendBatchSecurityCode',
}

const getAllSecuritySettings = actionCreator(SecurityActions.GetAllSecuritySettings);
const saveAllSecuritySettings = actionCreator<SecurityActionSettings>(
    SecurityActions.SaveAllSecuritySettings,
);

type UpdateSecuritySettings = Parameters<typeof SecurityActionsApi.saveSettings>;
const updateSecuritySettings = actionCreator<UpdateSecuritySettings>(
    SecurityActions.UpdateSecuritySettings,
);

type SendSecurityCode = Parameters<typeof SecurityActionsApi.sendSecurityCode>;
const sendSecurityCode = actionCreator<SendSecurityCode>(
    SecurityActions.SendSecurityCode,
);

type SendBatchSecurityCode = Parameters<typeof SecurityActionsApi.sendBatchSecurityCode>;
const sendBatchSecurityCode = actionCreator<SendBatchSecurityCode>(
    SecurityActions.SendBatchSecurityCode,
);

const saveDeliveryType = actionCreator<NotificationDeliveryType>(SecurityActions.SaveDeliveryType);

export {
    getAllSecuritySettings,
    saveAllSecuritySettings,
    updateSecuritySettings,
    sendSecurityCode,
    saveDeliveryType,
    sendBatchSecurityCode,
};
