import React, { FC, useMemo } from 'react';
import { VariableSizeList as List } from 'react-window';
import { Grid } from 'antd';
import cn from 'classnames';

import { useIntl, EmptyPageLayout, Checkbox } from 'Common';
import Server from 'Entities/Server';
import Tenant from 'Entities/Tenant';
import { RoutePath } from 'Lib/helpers/routes';
import { SERVER_SELECT_SIZE } from 'Lib/helpers/consts';
import { Store } from 'Store';
import { filterServers } from './filterServers';

import ServerItem from './ServerItem';
import s from './ServersSelect.module.pcss';

const { useBreakpoint } = Grid;

type RowData = {
    servers: { server: Server; deleted: boolean; isFirst?: boolean }[];
    tenants: Map<number, Tenant>;
    currentServers: Map<number, {
        server: Server;
        isFirst?: boolean | undefined;
    }[]>;
    deletedServers: {
        server: Server;
        deleted: boolean;
        isFirst?: boolean | undefined;
    }[];
    selectedServers: Set<Server>;
    intl: Store['intl'];
    selectServers: (s: Server) => void;
    selectAllServers: () => void;
    selectTenantServers: (s: Server[], c: boolean) => void;
};

interface RowProps {
    style: object;
    index: number;
    data: RowData;
}

const Row: FC<RowProps> = ({ style, index, data }) => {
    const {
        intl,
        servers,
        tenants,
        currentServers,
        selectedServers,
        deletedServers,
        selectServers,
        selectAllServers,
        selectTenantServers,
    } = data;
    const { server, isFirst, deleted } = servers[index];
    if (isFirst && !deleted) {
        const { description } = tenants.get(server.tenantId)!;

        const serversInTenant = currentServers.get(server.tenantId)!;
        const tenantServerList = serversInTenant.map((d) => d.server);

        const checkedTenant = serversInTenant
            .every((sr) => selectedServers.has(sr.server));

        return (
            <div style={style}>
                {index === 0 && (
                    <Checkbox
                        name="all"
                        checked={selectedServers.size === servers.length}
                        handleChange={selectAllServers}
                        className={cn(s.tenant, s.tenant_first)}
                        overflow
                    >
                        <div className={s.title}>
                            {intl.getMessage('select_all')}
                        </div>
                    </Checkbox>
                )}
                <div className={s.divider} />
                <Checkbox
                    name={description}
                    checked={checkedTenant}
                    handleChange={() => selectTenantServers(tenantServerList, checkedTenant)}
                    className={s.tenant}
                    overflow
                >
                    <div className={s.title}>
                        {description}
                    </div>
                </Checkbox>
                <ServerItem
                    server={server}
                    checked={selectedServers.has(server)}
                    selectServers={selectServers}
                />
            </div>
        );
    }

    if (isFirst && deleted) {
        const checkedDeleted = deletedServers.every((d) => selectedServers.has(d.server));
        const deletedServerList = deletedServers.map((d) => d.server);

        return (
            <div style={style}>
                {index === 0 && (
                    <Checkbox
                        name="all"
                        checked={selectedServers.size === servers.length}
                        handleChange={() => selectAllServers()}
                        className={cn(s.tenant, s.tenant_first)}
                        overflow
                    >
                        <div className={s.title}>
                            {intl.getMessage('select_all')}
                        </div>
                    </Checkbox>
                )}
                <div className={s.divider} />
                <Checkbox
                    name="deleted"
                    checked={checkedDeleted}
                    handleChange={() => selectTenantServers(deletedServerList, checkedDeleted)}
                    className={s.tenant}
                    overflow
                >
                    <div className={s.title}>
                        {intl.getMessage('deleted_servers')}
                    </div>
                </Checkbox>
                <ServerItem
                    server={server}
                    checked={selectedServers.has(server)}
                    selectServers={selectServers}
                />
            </div>
        );
    }

    return (
        <div style={style}>
            <ServerItem
                server={server}
                checked={selectedServers.has(server)}
                selectServers={selectServers}
            />
        </div>
    );
};

interface ServerListProps {
    servers: Server[];
    tenants: Map<number, Tenant>;
    selectedServers: Set<Server>;
    selectServers: (s: Server) => void;
    selectAllServers: () => void;
    selectTenantServers: (s: Server[], c: boolean) => void;
}

const ServerList: FC<ServerListProps> = ({
    servers, tenants, selectedServers, selectServers, selectAllServers, selectTenantServers,
}) => {
    const intl = useIntl();
    const {
        currentServers,
        deletedServers,
        serversData,
    } = useMemo(() => filterServers(servers), [servers]);

    const screens = useBreakpoint();

    const data: RowData = useMemo(() => ({
        servers: serversData,
        tenants,
        currentServers,
        deletedServers,
        selectedServers,
        intl,
        selectServers,
        selectAllServers,
        selectTenantServers,
    }), [selectedServers]);

    let listSize = {
        width: SERVER_SELECT_SIZE.WIDTH_MOBILE,
        height: SERVER_SELECT_SIZE.HEIGHT_MOBILE,
        itemHeight: SERVER_SELECT_SIZE.ITEM_HEIGHT,
        itemHeightTenant: SERVER_SELECT_SIZE.TENANT_GROUP_HEIGHT,
        itemHeightAll: SERVER_SELECT_SIZE.ALL_GROUP_HEIGHT,
    };

    if (screens?.sm) {
        listSize = {
            ...listSize,
            width: SERVER_SELECT_SIZE.WIDTH_DESKTOP,
            height: SERVER_SELECT_SIZE.HEIGHT_DESKTOP,
        };
    }

    if (servers.length === 0) {
        return (
            <EmptyPageLayout
                desc={intl.getMessage('search_no_servers')}
                linkText={intl.getMessage('create_server')}
                link={RoutePath.NewServer}
                type="dropdown"
            />
        );
    }

    return (
        <List
            className={s.list}
            width={listSize.width}
            height={listSize.height}
            itemSize={(index) => {
                if (index === 0) {
                    return listSize.itemHeightAll;
                }

                return serversData[index].isFirst
                    ? listSize.itemHeightTenant : listSize.itemHeight;
            }}
            itemCount={serversData.length}
            itemData={data}
        >
            {Row}
        </List>
    );
};

export default ServerList;
