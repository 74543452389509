import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import cn from 'classnames';

import { useIntl, CodeInput, notifyError, CommonModalLayout, DownloadButton, blackText, Button } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import { useSecurityActions } from 'Lib/hooks/hooks';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { downloadBackup, getBackupDownloadLink } from 'Actions/serverBackup';
import Error from 'Entities/Error';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import BackupDownloadLink from 'Entities/BackupDownloadLink';
import { OperationStatus } from 'Entities/OperationStatus';
import theme from 'Lib/theme';

interface ModalDownloadProps {
    visible: boolean;
    close: () => void;
    progress: OperationProgress | null;
    tenantId: number;
    serverId: number;
    backupId: string;
    cost: number;
    sizeGib: number;
}

const ModalDownload: FC<ModalDownloadProps> = ({
    visible, close, progress, tenantId, serverId, backupId, cost, sizeGib,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [backupDownloadLink, setBackupDownloadLink] = useState<BackupDownloadLink | null>(null);

    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
    } = useSecurityActions(SecurityActionType.DOWNLOAD_BACKUP);

    const handleOk = () => {
        if (shouldConfirmAction && !codeSent) {
            sendConfirmationCode({
                tenantId,
                serverId,
            });
        }
        if (!shouldConfirmAction) {
            dispatch(downloadBackup([tenantId, serverId, backupId, {}]));
        }
        if (codeSent && shouldConfirmAction) {
            if (!code) {
                setCodeError(true);
                return;
            }
            dispatch(downloadBackup([tenantId, serverId, backupId, {
                security_code: code,
            }], {
                error: (e: Error) => {
                    if (e.errorCode === ApiErrorCode.DOWNLOAD_BACKUP_NOT_ENOUGH_BALANCE) {
                        notifyError(
                            apiErrorCodeTranslate(intl, e.errorCode),
                            e.errorCode,
                            { duration: 0 },
                        );
                        close();
                        return;
                    }
                    setCodeError(true);
                },
            }));
        }
    };

    useEffect(() => {
        let canUpdate = true;
        dispatch(getBackupDownloadLink([tenantId, serverId, backupId], {
            result: (result) => {
                if (canUpdate) {
                    setBackupDownloadLink(result);
                }
            },
            error: () => {
                handleOk();
            },
        }));
        return () => {
            canUpdate = false;
        };
    }, []);

    useEffect(() => {
        if (progress?.status === OperationStatus.DONE) {
            dispatch(getBackupDownloadLink([tenantId, serverId, backupId], {
                result: (result) => {
                    setBackupDownloadLink(result);
                },
            }));
        }
    }, [progress]);

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('modal_upload_title')}
            handleClose={close}
            width={480}
            noFooter
            centered
        >
            {shouldConfirmAction && !backupDownloadLink && !progress ? (
                <>
                    {(codeSent && deliveryMessage) && (
                        <>
                            <div
                                className={cn(
                                    theme.modal.desc,
                                    theme.modal.desc_notice,
                                )}
                            >
                                {deliveryMessage}
                            </div>
                            <CodeInput
                                autoFocus
                                value={code}
                                setValue={(e) => setCode(e)}
                                codeError={codeError}
                                setCodeError={(e) => setCodeError(e)}
                                onSendAgain={() => sendConfirmationCode({
                                    tenantId,
                                    serverId,
                                })}
                            />
                            <Button
                                type="primary"
                                size="medium"
                                onClick={handleOk}
                            >
                                {intl.getMessage('download')}
                            </Button>
                        </>
                    )}
                </>
            ) : (
                <>
                    <div
                        className={cn(
                            theme.modal.desc,
                            theme.modal.desc_notice,
                        )}
                    >
                        {intl.getMessage('modal_upload_cost', { value: cost })}
                    </div>
                    <div className={theme.modal.desc}>
                        <DownloadButton
                            link={backupDownloadLink?.downloadLink}
                            label={intl.getMessage('server_backup_download')}
                            sizeGib={sizeGib}
                            loading={progress?.status === OperationStatus.PENDING}
                        />
                    </div>
                    {backupDownloadLink && (
                        <div
                            className={cn(
                                theme.modal.desc,
                                theme.modal.desc_notice,
                            )}
                        >
                            {intl.getMessage('server_backup_download_desc', {
                                date: dayjs(backupDownloadLink.expiryTime).format(intl.getMessage('time_format_backup_expiration')),
                                b: blackText,
                            })}
                        </div>
                    )}
                </>
            )}
        </CommonModalLayout>
    );
};

export default ModalDownload;
