import { Action } from 'store/actions';
import { SnapshotsActions, saveList, saveSnapshotProgress, deleteSnapshotProgress } from 'Actions/snapshots';
import Snapshot from 'Entities/Snapshot';

import { SnapshotsStore, snapshotsInitialState as initialState } from '../initialStates';

const reducer = (state: SnapshotsStore = initialState, action: Action): SnapshotsStore => {
    switch (action.type as SnapshotsActions) {
        case SnapshotsActions.SaveList: {
            const { payload } = action as ReturnType<typeof saveList>;
            const snapshots = new Map<number, Snapshot>();
            payload.forEach((s) => snapshots.set(s.id, s));
            return { ...state, snapshots };
        }
        case SnapshotsActions.SaveSnapshotProgress: {
            const {
                payload: {
                    snapId,
                    progress,
                },
            } = action as ReturnType<typeof saveSnapshotProgress>;
            const prog = new Map(state.progress);
            prog.set(snapId, progress);
            return { ...state, progress: prog };
        }
        case SnapshotsActions.DeleteSnapshotProgress: {
            const { payload } = action as ReturnType<typeof deleteSnapshotProgress>;
            const prog = new Map(state.progress);
            prog.delete(payload);
            return { ...state, progress: prog };
        }
        default:
            return state;
    }
};

export default reducer;
