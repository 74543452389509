import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getPartnerDetails, withdrawalPayment } from 'Actions/partner';
import { useIntl, FormPageLayout, Link, Icon, Button, Input, Banner } from 'Common';
import PartnerDetails from 'Entities/PartnerDetails';
import PartnerPayment from 'Entities/PartnerPayment';
import { AffiliatePayoutType } from 'Entities/AffiliatePayoutType';
import { RoutePath } from 'Lib/helpers/routes';
import { getAvailableAmountBages } from 'Lib/helpers/utils';
import { BONUS_AMOUNT_PERCENT, currencySymbol } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import ModalAct from './ModalAct';
import s from './PaymentForm.module.pcss';

interface FormValues {
    paymentAmount: string;
}
interface PaymentFormProps {
    details: PartnerDetails;
}
const PaymentForm: FC<PaymentFormProps> = ({ details }) => {
    const intl = useIntl();
    const [showActModal, setShowActModal] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState<PartnerPayment | null>(null);
    const { balance, limits: { minWithdrawalAmount } } = details;
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPartnerDetails());
    }, []);

    const initialValues: FormValues = {
        paymentAmount: '',
    };

    const validPaymentAmount = (v: string) => {
        if (!v
            || Number.isNaN(Number(v))
            || Number(v) < minWithdrawalAmount
            || Number(v) > balance
        ) {
            return false;
        }

        return true;
    };

    const formOnSubmit = (values: FormValues, actions: FormikHelpers<FormValues>): void => {
        const valid = validPaymentAmount(values.paymentAmount);
        if (!valid) {
            actions.setSubmitting(false);
            return;
        }

        dispatch(withdrawalPayment([{ amount: Number(values.paymentAmount) }], {
            result: (result: PartnerPayment) => {
                if (details.paymentType === AffiliatePayoutType.FLOPS) {
                    setPaymentDetails(result);
                    setShowActModal(true);
                    return;
                }
                history.goBack();
            },
            error: () => {
                actions.setSubmitting(false);
            },
        }));
    };

    if (showActModal && paymentDetails) {
        return (
            <ModalAct
                partnerPayment={paymentDetails}
                onClose={() => history.goBack()}
            />
        );
    }

    return (
        <FormPageLayout
            title={intl.getMessage('partners_order_payment')}
            back={RoutePath.Partner}
            banner={<Banner desc={intl.getMessage('partner_banner', { value: BONUS_AMOUNT_PERCENT })} />}
        >
            <Formik
                initialValues={initialValues}
                onSubmit={formOnSubmit}
            >
                {({
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                    errors,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={s.text}>
                            <Icon icon="coins" className={s.icon} />
                            {intl.getMessage('partners_current_balance', { value: intl.getMessage('money', { value: balance }) })}
                        </div>
                        <div className={s.input}>
                            <Input
                                name="paymentAmount"
                                type="number"
                                size="large"
                                placeholder={intl.getMessage('partners_payment_placeholder', { min: minWithdrawalAmount })}
                                suffix={currencySymbol.RUB}
                                value={values.paymentAmount}
                                onChange={(v) => setFieldValue('paymentAmount', v)}
                                validate={validPaymentAmount}
                                error={!!errors.paymentAmount}
                                autoFocus
                            />
                        </div>
                        <div className={cn(s.group, s.group_last)}>
                            {getAvailableAmountBages(balance, minWithdrawalAmount).map((amount) => (
                                <button
                                    type="button"
                                    key={amount}
                                    className={cn(
                                        theme.Badge.badge,
                                        theme.Badge.subject,
                                        s.badge,
                                    )}
                                    onClick={() => setFieldValue('paymentAmount', amount)}
                                >
                                    {intl.getMessage('price_simple', { value: amount })}
                                </button>
                            ))}
                        </div>
                        <div>
                            <Button
                                htmlType="submit"
                                type="primary"
                                size="big"
                                disabled={isSubmitting}
                                inGroup
                            >
                                {`${intl.getMessage('order')} ${values.paymentAmount ? intl.getMessage('price_simple', { value: values.paymentAmount }) : ''}`}
                            </Button>
                            <Link to={RoutePath.PartnerFinance}>
                                <Button
                                    type="link"
                                    size="big"
                                >
                                    {intl.getMessage('cancel')}
                                </Button>
                            </Link>
                        </div>
                    </form>
                )}
            </Formik>
        </FormPageLayout>
    );
};

export default PaymentForm;
