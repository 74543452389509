import React, { FC, useState } from 'react';
import { Select } from 'antd';
import { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import qs from 'qs';

import { createAccountPayer } from 'Actions/account';
import { useIntl } from 'Common';
import { ClientType } from 'Entities/ClientType';
import CreatePayerRequest, { ICreatePayerRequest } from 'Entities/CreatePayerRequest';
import { ICreateIndividual } from 'Entities/CreateIndividual';
import { ICreateSoleTrader } from 'Entities/CreateSoleTrader';
import { ICreateCorporation } from 'Entities/CreateCorporation';
import { ICreateNonResident } from 'Entities/CreateNonResident';
import Error from 'Entities/Error';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { linkPathBuilder, RoutePath, Routes } from 'Lib/helpers/routes';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';

import { IndividualForm, CorporationForm, SoleTraderForm, NonResidentForm, FormInnerProps } from '.';
import s from './Form.module.pcss';

const { Option } = Select;

export type Value = ICreateIndividual | ICreateSoleTrader | ICreateCorporation | ICreateNonResident;

export interface FormProps {
    handleSubmit?: (
        values: ICreatePayerRequest,
        setErrors: (error: Error) => void,
    ) => void;
    Submit: FC<any>;
    noTitle?: boolean;
    roundBorder?: boolean;
}
const Form: FC<FormProps> = ({ handleSubmit: handleProp, Submit, noTitle, roundBorder }) => {
    const intl = useIntl();
    const [clientType, setClientType] = useState(ClientType.INDIVIDUAL);
    const dispatch = useDispatch();
    const history = useHistory();
    const route = useRouteMatch();
    const serverInstall = route.path === Routes.OnboardingServer;

    const setFormikError = (helpers: FormikHelpers<Value>) => (error: Error) => {
        const formikErrors: any = {};
        Object.keys(error.fields).forEach((field) => {
            const key = field.split('.')[1];
            formikErrors[key] = apiErrorCodeTranslate(intl, error.fields[field][0].error_code);
        });
        helpers.setErrors(formikErrors);
    };
    const handleFormik = async (value: ICreatePayerRequest, helpers: FormikHelpers<Value>) => {
        const reqEnt = new CreatePayerRequest(value);
        let validate: string[] = [];
        if (reqEnt.individual) {
            validate = reqEnt.individual.validate();
        }
        if (reqEnt.soleTrader) {
            validate = reqEnt.soleTrader.validate();
        }
        if (reqEnt.corporation) {
            validate = reqEnt.corporation.validate();
        }
        if (reqEnt.nonResident) {
            validate = reqEnt.nonResident.validate();
        }
        if (validate.length > 0) {
            const errors: any = {};
            validate.forEach((field) => {
                errors[field] = EMPTY_FIELD_ERROR;
            });
            helpers.setErrors(errors);
        }
        if (handleProp) {
            handleProp({ ...reqEnt.serialize() }, setFormikError(helpers));
            return;
        }
        if (validate.length === 0) {
            dispatch(createAccountPayer([reqEnt.serialize()], {
                result: () => {
                    if (route.path === Routes.OnboardingRequisites) {
                        history.push(linkPathBuilder(
                            intl.currentLocale,
                            RoutePath.Profile,
                        ));
                    } else if (serverInstall) {
                        history.push(linkPathBuilder(
                            intl.currentLocale,
                            RoutePath.OnboardingAddFundsServer,
                        ));
                    } else {
                        const query = qs.parse(window.location.search.replace('?', ''));
                        history.push(linkPathBuilder(
                            intl.currentLocale,
                            RoutePath.OnboardingAddFunds,
                            undefined,
                            { showBanner: query.showBanner === 'true' },
                        ));
                    }
                },
                error: setFormikError(helpers),
            }));
        }
    };

    const getForm = () => {
        const component: Record<ClientType, FC<FormInnerProps>> = {
            [ClientType.INDIVIDUAL]: IndividualForm,
            [ClientType.CORPORATION]: CorporationForm,
            [ClientType.SOLE_TRADER]: SoleTraderForm,
            [ClientType.NON_RESIDENT]: NonResidentForm,
        };
        const Elem = component[clientType];
        return (
            <Elem
                handleSubmit={handleFormik}
                Submit={Submit}
            />
        );
    };

    const formClassNames = cn(
        { [s.form]: !noTitle },
        { [s.form_roundBorder]: roundBorder },
    );

    const getFormTitle = () => {
        if (route.path === Routes.OnboardingRequisites) {
            return intl.getMessage('onboarding_title');
        }

        if (serverInstall) {
            return intl.getMessage('onboarding_title_server');
        }

        return intl.getMessage('onboarding_title_billing');
    };

    return (
        <div className={formClassNames}>
            {!noTitle && (
                <div className={s.title}>
                    {getFormTitle()}
                </div>
            )}
            <label htmlFor="clientType" className={theme.modal.group}>
                <div className={theme.modal.label}>
                    {intl.getMessage('credentials_account_type')}
                </div>
                <Select
                    data-name="client_type"
                    id="client_type"
                    size="large"
                    className="select select--medium select--block"
                    value={clientType}
                    onChange={(value) => setClientType(value)}
                    suffixIcon={(
                        <svg className="icon select__arrow">
                            <use xlinkHref="#down" />
                        </svg>
                    )}
                    dropdownClassName="select-dropdown"
                >
                    <Option value={ClientType.INDIVIDUAL}>
                        {intl.getMessage('credentials_private_person')}
                    </Option>
                    <Option value={ClientType.CORPORATION}>
                        {intl.getMessage('credentials_legal_entity')}
                    </Option>
                    <Option value={ClientType.SOLE_TRADER}>
                        {intl.getMessage('credentials_sole_proprietor')}
                    </Option>
                    <Option value={ClientType.NON_RESIDENT}>
                        {intl.getMessage('credentials_non_resident')}
                    </Option>
                </Select>
            </label>
            {getForm()}
        </div>
    );
};

export default Form;
