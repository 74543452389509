import React, { FC, useEffect } from 'react';
import cn from 'classnames';

import { useIntl, EmptyPageLayout } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import Server from 'Entities/Server';
import Tenant from 'Entities/Tenant';
import theme from 'Lib/theme';

import ServerItem, { SERVER_ITEM_ID_PREFIX } from './ServerItem';

interface ServerListProps {
    tenants: Tenant[];
    servers: Server[];
    search: string;
}

const ServerList: FC<ServerListProps> = ({ servers, tenants, search }) => {
    const intl = useIntl();

    const tenantIds = [...new Set(servers.map((server) => server.tenantId))];
    const tenantList = tenantIds
        .map((id) => tenants.filter((tenant) => tenant.id === id)[0]);

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            const initial = (tenantIndex: number, last?: boolean) => {
                const { id: tenantId } = tenantList[tenantIndex];
                let server;
                if (!last) {
                    server = servers.find((s) => s.tenantId === tenantId);
                } else {
                    const revertedServers = [...servers].reverse();
                    server = revertedServers.find((s) => s.tenantId === tenantId);
                }
                const link = document.getElementById(`${tenantId}${SERVER_ITEM_ID_PREFIX}${server?.id}`);
                if (link) {
                    e.preventDefault();
                    link?.focus();
                }
            };
            const onNext = (iterer: number) => {
                const [tenantId, serverId] = document.activeElement?.id
                    .split(SERVER_ITEM_ID_PREFIX)
                    .map((s) => Number(s)) || [];
                const nextServers = servers.filter((s) => s.tenantId === tenantId);
                const index = nextServers.findIndex((s) => s.id === serverId);
                // if not last
                if (nextServers[index + iterer]) {
                    const { id } = nextServers[index + iterer];
                    const link = document.getElementById(`${tenantId}${SERVER_ITEM_ID_PREFIX}${id}`);
                    if (link) {
                        link?.focus();
                    }
                } else {
                    const nextTenantIndex = tenantList.findIndex((t) => t.id === tenantId) + iterer;
                    if (tenantList[nextTenantIndex]) {
                        initial(nextTenantIndex, iterer < 0);
                    } else {
                        initial(iterer < 0 ? tenantList.length - 1 : 0, iterer < 0);
                    }
                }
            };
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                if (!document.activeElement?.id.includes(SERVER_ITEM_ID_PREFIX)) {
                    initial(0);
                } else {
                    onNext(1);
                }
            }
            if (e.key === 'ArrowUp') {
                e.preventDefault();
                if (!document.activeElement?.id.includes(SERVER_ITEM_ID_PREFIX)) {
                    initial(0);
                } else {
                    onNext(-1);
                }
            }
        };
        window.addEventListener('keydown', onKeyDown);
        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };
    }, [servers]);

    if (servers.length === 0 && search) {
        return (
            <EmptyPageLayout
                desc={intl.getMessage('search_empty')}
                linkText={intl.getMessage('create_server')}
                link={RoutePath.NewServer}
                type="dropdown"
            />
        );
    }
    return (
        <div className={cn(theme.search.list, theme.search.list_server)}>
            {!search && servers.length > 0 && (
                <div className={cn(
                    theme.search.heading,
                    theme.search.heading_server,
                )}
                >
                    {intl.getMessage('last_server')}
                </div>
            )}
            {tenantList.map(({ id, description }) => (
                <div key={id} data-server={id}>
                    {search && (
                        <div
                            className={cn(
                                theme.search.heading,
                                theme.search.heading_server,
                            )}
                        >
                            {description}
                        </div>
                    )}
                    {servers.map((server) => (
                        server.tenantId === id
                        && <ServerItem key={server.id} server={server} search={search} />
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ServerList;
