import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { FormPageLayout, Icon, Link, useIntl } from 'Common';
import { addAccount } from 'Actions/user';
import LoginForm from 'Entities/LoginForm';
import ApiError from 'Entities/Error';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { getCookieToken } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { useTitle } from 'Lib/hooks/hooks';
import { Store } from 'Store';
import theme from 'Lib/theme';

import SocialAuth from 'Components/Auth/SocialAuth';
import LoginFormContainer from 'Components/Auth/LoginFormContainer';
import { LoginFormValues } from 'Components/Auth/Login';

import s from './AddAccount.module.pcss';

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([
    selectProfile,
], (profile) => ({ profile }));

const AddAccount: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const captchaRef = useRef<any>(null);
    const [shouldUseCaptcha, setShouldUseCaptcha] = useState(false);
    const [firstAttempt, setFirstAttempt] = useState(true);
    const history = useHistory();

    const { profile } = useSelector(selector);

    useTitle(intl.getMessage('add_account_page_title'));

    const formOnSubmit = (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>) => {
        if (!NO_CAPTCHA) {
            if (!values.captcha && shouldUseCaptcha) {
                actions.setSubmitting(false);
                captchaRef.current?.execute();
                return;
            }
        }
        const reqEnt = new LoginForm(values);
        dispatch(addAccount([reqEnt.serialize(), getCookieToken(), values.captcha || ''], {
            error: (error: ApiError) => {
                actions.setSubmitting(false);
                if (error.errorCode === ApiErrorCode.CAPTCHA_REQUIRED) {
                    setShouldUseCaptcha(true);
                    if (firstAttempt) {
                        setFirstAttempt(false);
                        actions.submitForm();
                        return;
                    }
                    actions.setErrors({
                        username: EMPTY_FIELD_ERROR,
                        password: apiErrorCodeTranslate(intl, ApiErrorCode.LOGIN_BAD_CREDENTIALS),
                    });
                } else {
                    actions.setErrors({
                        username: EMPTY_FIELD_ERROR,
                        password: apiErrorCodeTranslate(intl, error.errorCode),
                    });
                    actions.setFieldValue('captcha', '', false);
                }
            },
            result: () => {
                actions.setSubmitting(false);
                history.goBack();
            },
        }));
    };

    return (
        <>
            <FormPageLayout
                title={intl.getMessage('add_account')}
                type="narrow"
                back={RoutePath.Dashboard}
                link={(
                    profile?.reseller ? (
                        <Link
                            to={RoutePath.ClientsAdd}
                            className={cn(
                                s.addClient,
                                theme.link.link,
                                theme.link.black,
                            )}
                        >
                            <Icon icon="add_user" className={s.icon} />
                            {intl.getMessage('clients_create')}
                        </Link>
                    ) : undefined
                )}
            >
                <div className={s.desc}>
                    {intl.getMessage('add_account_desc')}
                </div>

                <LoginFormContainer
                    formOnSubmit={formOnSubmit}
                    captchaRef={captchaRef}
                />

                <SocialAuth addAccount />
            </FormPageLayout>
        </>
    );
};

export default AddAccount;
