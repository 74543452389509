// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IProfileLimits {
    can_create_windows_server: boolean;
    can_edit_api_key: boolean;
    max_attachments_size_bytes: number;
    max_recipients_count: number;
    max_trial_servers_count: number;
    max_trial_servers_memory_mib: number;
    max_triggers_count: number;
    trial_bonus_amount?: number;
}

export default class ProfileLimits {
    readonly _can_create_windows_server: boolean;

    /**
     * Description: If client is allowed to create Windows server
     * Example: true
     */
    get canCreateWindowsServer(): boolean {
        return this._can_create_windows_server;
    }

    static canCreateWindowsServerValidate(canCreateWindowsServer: boolean): boolean {
        return typeof canCreateWindowsServer === 'boolean';
    }

    readonly _can_edit_api_key: boolean;

    /** */
    get canEditApiKey(): boolean {
        return this._can_edit_api_key;
    }

    static canEditApiKeyValidate(canEditApiKey: boolean): boolean {
        return typeof canEditApiKey === 'boolean';
    }

    readonly _max_attachments_size_bytes: number;

    /**
     * Description: Maximum total size of support attachments in bytes
     * Example: 15728640
     */
    get maxAttachmentsSizeBytes(): number {
        return this._max_attachments_size_bytes;
    }

    static maxAttachmentsSizeBytesValidate(maxAttachmentsSizeBytes: number): boolean {
        return typeof maxAttachmentsSizeBytes === 'number';
    }

    readonly _max_recipients_count: number;

    /**
     * Description: Maximum number of client recipients
     * Example: 5
     */
    get maxRecipientsCount(): number {
        return this._max_recipients_count;
    }

    static maxRecipientsCountValidate(maxRecipientsCount: number): boolean {
        return typeof maxRecipientsCount === 'number';
    }

    readonly _max_trial_servers_count: number;

    /**
     * Description: Max servers for trial users
     * Example: 3
     */
    get maxTrialServersCount(): number {
        return this._max_trial_servers_count;
    }

    static maxTrialServersCountValidate(maxTrialServersCount: number): boolean {
        return typeof maxTrialServersCount === 'number';
    }

    readonly _max_trial_servers_memory_mib: number;

    /**
     * Description: Max overall memory for trial users
     * Example: 8192
     */
    get maxTrialServersMemoryMib(): number {
        return this._max_trial_servers_memory_mib;
    }

    static maxTrialServersMemoryMibValidate(maxTrialServersMemoryMib: number): boolean {
        return typeof maxTrialServersMemoryMib === 'number';
    }

    readonly _max_triggers_count: number;

    /**
     * Description: Maximum number of client triggers
     * Example: 30
     */
    get maxTriggersCount(): number {
        return this._max_triggers_count;
    }

    static maxTriggersCountValidate(maxTriggersCount: number): boolean {
        return typeof maxTriggersCount === 'number';
    }

    readonly _trial_bonus_amount: number | undefined;

    /**
     * Description: Trial bonus amount
     * Example: 500
     */
    get trialBonusAmount(): number | undefined {
        return this._trial_bonus_amount;
    }

    constructor(props: IProfileLimits) {
        this._can_create_windows_server = props.can_create_windows_server;
        this._can_edit_api_key = props.can_edit_api_key;
        this._max_attachments_size_bytes = props.max_attachments_size_bytes;
        this._max_recipients_count = props.max_recipients_count;
        this._max_trial_servers_count = props.max_trial_servers_count;
        this._max_trial_servers_memory_mib = props.max_trial_servers_memory_mib;
        this._max_triggers_count = props.max_triggers_count;
        if (typeof props.trial_bonus_amount === 'number') {
            this._trial_bonus_amount = props.trial_bonus_amount;
        }
    }

    serialize(): IProfileLimits {
        const data: IProfileLimits = {
            can_create_windows_server: this._can_create_windows_server,
            can_edit_api_key: this._can_edit_api_key,
            max_attachments_size_bytes: this._max_attachments_size_bytes,
            max_recipients_count: this._max_recipients_count,
            max_trial_servers_count: this._max_trial_servers_count,
            max_trial_servers_memory_mib: this._max_trial_servers_memory_mib,
            max_triggers_count: this._max_triggers_count,
        };
        if (typeof this._trial_bonus_amount !== 'undefined') {
            data.trial_bonus_amount = this._trial_bonus_amount;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            max_triggers_count: typeof this._max_triggers_count === 'number',
            max_recipients_count: typeof this._max_recipients_count === 'number',
            can_edit_api_key: typeof this._can_edit_api_key === 'boolean',
            max_attachments_size_bytes: typeof this._max_attachments_size_bytes === 'number',
            trial_bonus_amount: !this._trial_bonus_amount ? true : typeof this._trial_bonus_amount === 'number',
            max_trial_servers_count: typeof this._max_trial_servers_count === 'number',
            max_trial_servers_memory_mib: typeof this._max_trial_servers_memory_mib === 'number',
            can_create_windows_server: typeof this._can_create_windows_server === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IProfileLimits): ProfileLimits {
        return new ProfileLimits(props);
    }

    readonly keys: { [key: string]: string } = {
        canCreateWindowsServer: 'can_create_windows_server',
        canEditApiKey: 'can_edit_api_key',
        maxAttachmentsSizeBytes: 'max_attachments_size_bytes',
        maxRecipientsCount: 'max_recipients_count',
        maxTrialServersCount: 'max_trial_servers_count',
        maxTrialServersMemoryMib: 'max_trial_servers_memory_mib',
        maxTriggersCount: 'max_triggers_count',
        trialBonusAmount: 'trial_bonus_amount',
        }
;

    mergeDeepWith(props: Partial<ProfileLimits>): ProfileLimits {
        const updateData: Partial<IProfileLimits> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ProfileLimits) => {
            const updateKey = this.keys[key] as keyof IProfileLimits;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IProfileLimits, keyof IProfileLimits>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ProfileLimits({ ...this.serialize(), ...updateData });
    }
}
