import React, { FC } from 'react';
import cn from 'classnames';

import { CommonModalLayout, useIntl, CodeInput } from 'Common';
import theme from 'Lib/theme';

interface ModalBackupProps {
    visible: boolean;
    close: () => void;
    code: string;
    codeError: boolean;
    setCode: (e: string) => void;
    onOk: () => void;
    deliveryMessage: string;
    setCodeError: (e: boolean) => void;
    onAgain: () => void;
}

const ModalTermAccess: FC<ModalBackupProps> = ({
    visible, close, code, codeError, setCode, onOk, deliveryMessage, setCodeError, onAgain,
}) => {
    const intl = useIntl();

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('server_setting_term_access_title')}
            handleSubmit={onOk}
            handleClose={close}
            buttonText={intl.getMessage('confirm')}
        >
            <div className={cn(theme.modal.desc, theme.modal.desc_gray)}>
                {deliveryMessage}
            </div>
            <CodeInput
                value={code}
                setValue={(e) => setCode(e)}
                codeError={codeError}
                setCodeError={(e) => setCodeError(e)}
                onSendAgain={onAgain}
            />
        </CommonModalLayout>
    );
};

export default ModalTermAccess;
