import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, Icon, AreaChart } from 'Common';
import { currencySymbol, DATE_RANGE_TYPES, RESOURCES_TYPES, Zoom } from 'Lib/helpers/consts';
import ExpenseSummary from 'Entities/ExpenseSummary';
import { IAccountExpense } from 'Entities/AccountExpense';
import { getCssColors, roundNumber } from 'Lib/helpers/helpers';
import { Range } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import ExpensesList from './ExpensesList';
import Footer from './Footer';
import s from '../Expenses.module.pcss';

interface OverallExpensesProps {
    overallExpense: ExpenseSummary;
    accountExpensesJSON: IAccountExpense[] | null;
    range: Range;
    setRange: (v: Range) => void;
    zoom: Zoom;
    setZoom: (v: Zoom) => void;
    setRangeName: (v: DATE_RANGE_TYPES | null) => void;
}

const OverallExpenses: FC<OverallExpensesProps> = ({
    overallExpense,
    accountExpensesJSON,
    range,
    setRange,
    zoom,
    setZoom,
    setRangeName,
}) => {
    const intl = useIntl();
    const [visibleList, setVisibleList] = useState(false);
    const chartColors = getCssColors();

    const emptyStats = overallExpense.overallAmount === 0;

    const getBoundaryTicks = () => {
        if (!accountExpensesJSON || accountExpensesJSON.length === 0) {
            return undefined;
        }

        const first = accountExpensesJSON[0].report_time_millis;
        const last = accountExpensesJSON[accountExpensesJSON.length - 1].report_time_millis;

        return [first, last];
    };

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.rounded,
                s.card,
            )}
        >
            <div className={cn(s.header, s.header_chart)}>
                <div className={s.titleWrap}>
                    <div className={s.title}>
                        <Icon icon="coins" className={cn(s.icon, theme.color.blue)} />
                        {intl.getMessage('total_consumption_for_period')}
                    </div>
                    <div className={cn(s.total, { [theme.color.grayHeaders]: emptyStats })}>
                        {intl.getMessage('price_simple', { value: roundNumber(overallExpense.overallAmount) })}
                    </div>
                </div>
                {accountExpensesJSON && accountExpensesJSON.length > 0 && (
                    <div className={s.chart}>
                        <AreaChart
                            type={RESOURCES_TYPES.EXPENSES}
                            data={accountExpensesJSON}
                            lines={[{
                                id: 'amount',
                                color: chartColors.blue,
                                label: intl.getMessage('expenses'),
                            }]}
                            unit={currencySymbol.RUB}
                            xAxisKey="report_time_millis"
                            boundaryXAxisTicks={getBoundaryTicks()}
                            initialRange={range}
                            setRange={setRange}
                            globalZoom={zoom}
                            setGlobalZoom={(v: Zoom) => {
                                setZoom(v);
                                setRangeName(null);
                            }}
                            drawXAxis
                            size="small"
                        />
                    </div>
                )}
            </div>

            {!emptyStats && (
                <ExpensesList
                    visibleList={visibleList}
                    expenses={overallExpense.expenses}
                />
            )}

            <Footer
                title={intl.getMessage('show_detailed_expenses')}
                visibleList={visibleList}
                setVisibleList={setVisibleList}
                emptyStats={emptyStats}
            />
        </div>
    );
};

export default OverallExpenses;
