import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import AddClientReviewKeyRequest, { IAddClientReviewKeyRequest } from 'Entities/AddClientReviewKeyRequest';
import ApiKey, { IApiKey } from 'Entities/ApiKey';
import PhoneConfirmationInfo, { IPhoneConfirmationInfo } from 'Entities/PhoneConfirmationInfo';
import PhoneInfoUpdate, { IPhoneInfoUpdate } from 'Entities/PhoneInfoUpdate';
import Profile, { IProfile } from 'Entities/Profile';
import ProfileLoginAttempt, { IProfileLoginAttempt } from 'Entities/ProfileLoginAttempt';
import ProfileTrialPeriodActivate, { IProfileTrialPeriodActivate } from 'Entities/ProfileTrialPeriodActivate';
import ProfileTrialPeriodActivateResponse, { IProfileTrialPeriodActivateResponse } from 'Entities/ProfileTrialPeriodActivateResponse';
import ProfileTrialPeriodActivationStatusResponse, { IProfileTrialPeriodActivationStatusResponse } from 'Entities/ProfileTrialPeriodActivationStatusResponse';
import ProfileUpdate, { IProfileUpdate } from 'Entities/ProfileUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class ProfileApi {
    static async activateTrialPeriod(profiletrialperiodactivate: IProfileTrialPeriodActivate, xauthcookie?: string, optionalToken?: string): Promise<IProfileTrialPeriodActivateResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const profiletrialperiodactivateValid = new ProfileTrialPeriodActivate(profiletrialperiodactivate);
        haveError.push(...profiletrialperiodactivateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/profile/trial_period`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
                'X-Auth-Cookie': xauthcookie || '',
            },
            body: JSON.stringify(profiletrialperiodactivateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async addClientReviewKey(addclientreviewkeyrequest: IAddClientReviewKeyRequest, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const addclientreviewkeyrequestValid = new AddClientReviewKeyRequest(addclientreviewkeyrequest);
        haveError.push(...addclientreviewkeyrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/profile/reviews`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(addclientreviewkeyrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async confirmPhoneWithCode(phoneconfirmationinfo: IPhoneConfirmationInfo, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const phoneconfirmationinfoValid = new PhoneConfirmationInfo(phoneconfirmationinfo);
        haveError.push(...phoneconfirmationinfoValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/profile/phone`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(phoneconfirmationinfoValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getLoginAttempts(offset?: number, limit?: number, optionalToken?: string): Promise<IProfileLoginAttempt[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            offset: offset,
            limit: limit,
        }
        return await fetch(`/api/v1/profile/login_attempts?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getProfile(optionalToken?: string): Promise<IProfile | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/profile`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getTrialPeriodActivationStatus(optionalToken?: string): Promise<IProfileTrialPeriodActivationStatusResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/profile/trial_period`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async rejectTrialPeriod(xauthcookie?: string, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/profile/trial_period`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'X-Auth-Cookie': xauthcookie || '',
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async sendPhoneConfirmationCode(phoneinfoupdate: IPhoneInfoUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const phoneinfoupdateValid = new PhoneInfoUpdate(phoneinfoupdate);
        haveError.push(...phoneinfoupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/profile/phone/send_code`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(phoneinfoupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateClientApiKey(optionalToken?: string): Promise<IApiKey | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/profile/api_key`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateProfile(profileupdate: IProfileUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const profileupdateValid = new ProfileUpdate(profileupdate);
        haveError.push(...profileupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/profile`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(profileupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateRecommendationsSubscriptionForClient(enabled: boolean, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            enabled: enabled,
        }
        return await fetch(`/api/v1/profile/server_upgrade_recommendations?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
