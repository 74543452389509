import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { useIntl, notifySuccess, Button, TerminalLink } from 'Common';
import { addIp } from 'Actions/server';
import Server from 'Entities/Server';
import { DistributionFamily } from 'Entities/DistributionFamily';
import theme from 'Lib/theme';

import { ModalIpAdd } from '../Modals';
import IpOptions from './IpOptions';
import OptionButton from './OptionButton';

import s from './IpList.module.pcss';

interface IpListProps {
    server: Server;
    progress: boolean;
    hideButton?: boolean;
    type?: 'row' | 'compact';
}

const IpList: FC<IpListProps> = React.memo(({ server, hideButton, progress, type }) => {
    const intl = useIntl();
    const [showAddIp, setShowAddIp] = useState(false);
    const [visiblePrivate, setVisiblePrivate] = useState(false);
    const { privateAddress, ipv4Addresses, ipv6Addresses } = server;
    const dispatch = useDispatch();

    const handleAddIp = () => {
        dispatch(addIp(server, {
            result: () => {
                notifySuccess(intl.getMessage('server_ip_added_successfully'), 'server_ip_added_successfully');
                setShowAddIp(false);
            },
            error: () => {
                setShowAddIp(false);
            },
        }));
    };

    const ipList = [...ipv4Addresses, ...ipv6Addresses];

    const { host, netmask, gateway } = privateAddress;

    const onCopyPrivate = () => {
        setVisiblePrivate(false);
        notifySuccess(intl.getMessage('copied_ip'), 'copied_ip');
        navigator.clipboard.writeText(host);
    };
    const content = (
        <div className={theme.popover.popover}>
            <div className={cn(theme.popover.info, { [theme.popover.info_last]: !gateway })}>
                <div className={theme.popover.value}>
                    {netmask}
                </div>
                <div className={theme.popover.label}>
                    {intl.getMessage('netmask')}
                </div>
            </div>
            {gateway && (
                <div className={theme.popover.info}>
                    <div className={theme.popover.value}>
                        {gateway}
                    </div>
                    <div className={theme.popover.label}>
                        {intl.getMessage('gateway')}
                    </div>
                </div>
            )}
            <div className={theme.popover.divider} />
            <TerminalLink
                host={host}
                handleClick={() => setVisiblePrivate(false)}
                disabled={server.distribution.type === DistributionFamily.WINDOWS}
            />
            <OptionButton
                handler={onCopyPrivate}
                title={intl.getMessage('server_ip_option_copy')}
            />
        </div>
    );

    const listClass = cn(
        s.list,
        { [s.list_row]: type === 'row' },
        { [s.list_compact]: type === 'compact' },
    );

    const getActionButton = () => {
        if (hideButton) {
            return null;
        }

        if (server.limits.maxIpCount <= ipList.length) {
            return (
                <div className={s.text}>
                    {intl.getPlural('server_ip_max', server.limits.maxIpCount)}
                </div>
            );
        }

        return (
            <Button
                size="small"
                type="link"
                className={s.add}
                onClick={() => setShowAddIp(true)}
                dataAttrs={{ 'data-enabled': progress ? 'disabled' : 'enabled' }}
                disabled={progress}
            >
                <div className={s.mobile}>
                    {intl.getMessage('add_ip_short')}
                </div>
                <div className={s.desktop}>
                    {intl.getMessage('add_ip')}
                </div>
            </Button>
        );
    };

    return (
        <div className={listClass}>
            <div className={s.values} id="server_ip_list">
                <div className={s.ip}>
                    <Popover
                        content={content}
                        placement="bottom"
                        trigger="click"
                        visible={visiblePrivate}
                        onVisibleChange={(e) => setVisiblePrivate(e)}
                        overlayClassName="popover"
                    >
                        <span className={cn(theme.link.link, theme.link.blue)}>
                            {host}&nbsp;
                        </span>
                    </Popover>
                    <span className={s.label}>
                        {intl.getMessage('server_ip_list_ip_local')}
                    </span>
                </div>
                {ipList.map((ip) => (
                    <IpOptions
                        progress={progress}
                        key={ip.host}
                        ip={ip}
                        server={server}
                    />
                ))}
            </div>
            {getActionButton()}
            {showAddIp && (
                <ModalIpAdd
                    visible
                    onClose={() => setShowAddIp(false)}
                    onSubmit={handleAddIp}
                    serverName={server.name}
                />
            )}
        </div>
    );
});

export default IpList;
