import React, { FC, useState } from 'react';

import { useIntl, CommonModalLayout, mediumText } from 'Common';
import theme from 'Lib/theme';

interface ModalIpAddProps {
    visible: boolean;
    onClose: () => void;
    onSubmit: () => void;
    serverName: string;
}

const ModalIpAdd: FC<ModalIpAddProps> = ({ visible, onClose, onSubmit, serverName }) => {
    const intl = useIntl();

    const [clicked, setClicked] = useState(false);

    const preSubmit = () => {
        setClicked(true);
        onSubmit();
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('server_ip_add_modal_title')}
            handleClose={onClose}
            handleSubmit={preSubmit}
            disabled={clicked}
            buttonText={intl.getMessage('add')}
        >
            <div className={theme.modal.desc}>
                {intl.getMessage('server_ip_add_modal_text', {
                    b: mediumText,
                    server: serverName,
                })}
            </div>
        </CommonModalLayout>
    );
};

export default ModalIpAdd;
