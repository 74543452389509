// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum VmStatsFields {
    CPU_TOTAL = 'CPU_TOTAL',
    CPU_LOAD_PERCENT = 'CPU_LOAD_PERCENT',
    RX_PUBLIC_MBITS = 'RX_PUBLIC_MBITS',
    TX_PUBLIC_MBITS = 'TX_PUBLIC_MBITS',
    RX_LOCAL_MBITS = 'RX_LOCAL_MBITS',
    TX_LOCAL_MBITS = 'TX_LOCAL_MBITS',
    RX_PUBLIC_PACKETS = 'RX_PUBLIC_PACKETS',
    TX_PUBLIC_PACKETS = 'TX_PUBLIC_PACKETS',
    RX_LOCAL_PACKETS = 'RX_LOCAL_PACKETS',
    TX_LOCAL_PACKETS = 'TX_LOCAL_PACKETS',
    DISK_READ_OPS = 'DISK_READ_OPS',
    DISK_WRITE_OPS = 'DISK_WRITE_OPS',
    DISK_READ_MIBS = 'DISK_READ_MIBS',
    DISK_WRITE_MIBS = 'DISK_WRITE_MIBS',
    DISK_USE = 'DISK_USE',
    DISK_FREE = 'DISK_FREE',
    MEMORY_ACTIVE_MIB = 'MEMORY_ACTIVE_MIB',
    MEMORY_CURRENT_MIB = 'MEMORY_CURRENT_MIB',
    MEMORY_FREE_MIB = 'MEMORY_FREE_MIB',
    MEMORY_TOTAL_MIB = 'MEMORY_TOTAL_MIB',
    MEMORY_BUFFERS_MIB = 'MEMORY_BUFFERS_MIB',
    MEMORY_CACHED_MIB = 'MEMORY_CACHED_MIB',
    LOAD_AVERAGE = 'LOAD_AVERAGE',
    CPU_USAGE = 'CPU_USAGE',
    CPU_STEAL = 'CPU_STEAL',
    CPU_SHARES = 'CPU_SHARES',
    INODES_USE = 'INODES_USE'
}
