import React, { FC } from 'react';
import { RoutePath, linkPathBuilder } from 'Lib/helpers/routes';
import cn from 'classnames';

import { useIntl, Link, DashboardHeader, Button, ServerSearch, notifyError, SetupActions, Icon } from 'Common';
import ServerEntity from 'Entities/Server';
import { DistributionFamily } from 'Entities/DistributionFamily';
import theme from 'Lib/theme';
import Actions from 'Common/Server/Actions';
import OperationProgress from 'Entities/OperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';

interface HeaderOwnProps {
    server: ServerEntity;
    progress?: OperationProgress;
    installingProgress?: OperationProgress;
}

type HeaderProps = HeaderOwnProps;
const Header: FC<HeaderProps> = ({ server, progress, installingProgress }) => {
    const intl = useIntl();

    const links = [
        {
            url: RoutePath.ServerTariff,
            name: intl.getMessage('tariff_and_resources_title'),
        },
        {
            url: RoutePath.ServerBackup,
            name: intl.getMessage('backup'),
        },
        {
            url: RoutePath.Snapshots,
            name: intl.getMessage('snapshots'),
        },
        {
            url: RoutePath.ServerAccess,
            name: intl.getMessage('access'),
        },
    ];

    const onLinkClick = (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!server.consoleAccess || server.serviceMode) {
            e?.preventDefault();
            notifyError(!server.consoleAccess
                ? intl.getMessage('server_console_no_access')
                : intl.getMessage('server_console_service_mode'));
        }
    };

    const getActions = () => {
        if (installingProgress) {
            return (
                <SetupActions
                    server={server}
                    progress={installingProgress}
                />
            );
        }

        return (
            <Actions
                server={server}
                size="medium"
                operationType={progress?.type}
            />
        );
    };

    const linksDisabled = progress?.status === OperationStatus.PENDING || installingProgress;

    return (
        <>
            <DashboardHeader
                navigation={(
                    <>
                        <Link to={RoutePath.ServersList} className={theme.header.back}>
                            <Button
                                type="icon"
                                size="medium"
                                icon="home"
                            />
                        </Link>
                        <div className={cn(theme.header.search, theme.header.active)}>
                            <ServerSearch server={server} />
                        </div>
                        {!linksDisabled && (
                            <div className={theme.header.links}>
                                {links.map((i) => (
                                    <Link
                                        key={i.name}
                                        to={i.url}
                                        className={cn(
                                            theme.header.link,
                                            theme.link.link,
                                            theme.link.black,
                                        )}
                                        props={{ serverId: server.id }}
                                    >
                                        {i.name}
                                        {server.upgradeRecommendation && i.url === RoutePath.ServerTariff && (
                                            <Icon icon="attention_no_border" className={theme.header.icon} />
                                        )}
                                    </Link>
                                ))}
                                {server.distribution.type === DistributionFamily.WINDOWS ? (
                                    <a
                                        target="blank"
                                        href={linkPathBuilder(
                                            intl.currentLocale,
                                            RoutePath.Vnc,
                                            { serverId: server.id },
                                        )}
                                        onClick={onLinkClick}
                                        className={cn(
                                            theme.header.link,
                                            theme.link.link,
                                            theme.link.black,
                                        )}
                                    >
                                        {intl.getMessage('console')}
                                    </a>
                                ) : (
                                    <Link
                                        disabled={linksDisabled}
                                        to={RoutePath.Console}
                                        onClick={onLinkClick}
                                        props={{ serverId: server.id }}
                                        className={cn(
                                            theme.header.link,
                                            theme.link.link,
                                            theme.link.black,
                                        )}
                                    >
                                        {intl.getMessage('console')}
                                    </Link>
                                )}
                                <Link
                                    to={RoutePath.ServerHistory}
                                    disabled={linksDisabled}
                                    className={cn(
                                        theme.header.link,
                                        theme.link.link,
                                        theme.link.black,
                                    )}
                                    props={{ serverId: server.id }}
                                >
                                    {intl.getMessage('history')}
                                </Link>
                            </div>
                        )}
                    </>
                )}
                actions={(
                    <div className={cn(theme.header.mobile, theme.header.mobile_actions)}>
                        {getActions()}
                    </div>
                )}
            />
        </>
    );
};

export default Header;
