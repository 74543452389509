import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHistory } from 'react-router-dom';
import qs from 'qs';

import { setServerView } from 'Actions/ui';
import { useIntl } from 'Common';
import { useTitle, useUISettings } from 'Hooks';
import OperationProgress from 'Entities/OperationProgress';
import Tenant from 'Entities/Tenant';
import Server from 'Entities/Server';
import { SERVER_VIEW } from 'Lib/helpers/consts';
import { SERVER_SELECT, SERVER_SORT } from 'Lib/helpers/translationHelper';
import { linkPathBuilder, QueryParams, RoutePath } from 'Lib/helpers/routes';
import { Store } from 'Store';
import { ServerState } from 'Entities/ServerState';

import { filterFactory, mapFactory, sortFactory, tenantFactory, ServerListContext } from './factories';
import ServerList from './ServerList';

type Params = Partial<Record<QueryParams, string>>;

const getInitialValues = (
    tenantList: Map<number, Tenant>,
) => {
    const params: Params = qs.parse(window.location.search.replace('?', ''));
    const { sort, tenants, order } = params;
    const initial: { sort: SERVER_SORT; tenants: number[]; reverse: boolean } = {
        sort: SERVER_SORT.BY_CREATED,
        tenants: [],
        reverse: order === 'desc',
    };

    if (sort && Object.values(SERVER_SORT).includes(sort as SERVER_SORT)) {
        initial.sort = sort as SERVER_SORT;
    }

    if (tenants) {
        const tenantsArray = tenants
            .split(',')
            .map((t) => Number(t))
            .filter((t) => tenantList.has(t));
        initial.tenants = tenantsArray;
    }

    return { tenants: initial.tenants, sort: initial.sort, reverse: initial.reverse };
};

interface ServerListContainerStoreProps {
    tenantList: Map<number, Tenant>;
    servers: Map<number, Server>;
    loadingServers: Map<number, Server>;
    installingProgress: Map<number, OperationProgress>;
    operationsProgress: Map<number, OperationProgress>;
}

const ServerListContainer: FC<ServerListContainerStoreProps> = React.memo(({
    tenantList,
    servers,
    loadingServers,
    installingProgress,
    operationsProgress,
}) => {
    const { serverView } = useUISettings();
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();

    const initialValues = getInitialValues(tenantList);

    const [tenantFilter, setTenantFilter] = useState<number[]>(initialValues.tenants);
    const [sortType, setSortType] = useState<{ sortType: SERVER_SORT; reverseSort: boolean }>(
        { sortType: initialValues.sort, reverseSort: initialValues.reverse },
    );
    const [selectType, setSelectType] = useState<SERVER_SELECT | null>(null);
    const [selectedServers, setSelectedServers] = useState<Set<Server> | null>(null);
    const [showCheckbox, setShowCheckbox] = useState(false);

    useTitle(intl.getMessage('servers_page_title'));

    useEffect(() => {
        if (showCheckbox && selectedServers?.size === 0) {
            setShowCheckbox(false);
            setSelectedServers(null);
        }
    }, [showCheckbox, selectedServers]);

    const separateProgressServers = sortType.sortType === SERVER_SORT.BY_STATUS;

    const tenantIds = useMemo(
        () => tenantFactory(loadingServers, servers, tenantList),
        [loadingServers, servers],
    );
    const { progressed, working } = useMemo(
        () => mapFactory(
            installingProgress,
            operationsProgress,
            loadingServers,
            servers,
            separateProgressServers,
        ),
        [installingProgress, operationsProgress, loadingServers, servers],
    );
    const sortedProgress = useMemo(() => sortFactory(sortType, progressed),
        [sortType, progressed, tenantFilter]);
    const sortedWorking = useMemo(() => sortFactory(sortType, working),
        [sortType, working, tenantFilter]);

    const filteredProgress = useMemo(
        () => filterFactory(tenantFilter, sortedProgress!),
        [sortType, tenantFilter, sortedProgress],
    );
    const filteredWorking = useMemo(
        () => filterFactory(tenantFilter, sortedWorking!),
        [sortType, tenantFilter, sortedWorking],
    );

    const setFilterParams = (sort: SERVER_SORT, tenants: number[], reverse: boolean) => {
        const tenantsParam = tenants.length > 0 ? tenants.join(',') : undefined;
        history.replace(linkPathBuilder(
            intl.currentLocale,
            RoutePath.ServersList,
            undefined,
            { sort, tenants: tenantsParam, order: reverse ? 'desc' : 'asc' },
        ));
    };

    const onTenantFilterChange = (tenants: number[]) => {
        setTenantFilter(tenants);
        setFilterParams(sortType.sortType, tenants, sortType.reverseSort);
    };

    const onSortTypeChange = (type: SERVER_SORT) => {
        setSortType({ sortType: type, reverseSort: false });
        setFilterParams(type, tenantFilter, false);
    };

    const onReverseSort = () => {
        setSortType({ ...sortType, reverseSort: !sortType.reverseSort });
        setFilterParams(sortType.sortType, tenantFilter, !sortType.reverseSort);
    };

    const onSelectServers = (server: Server) => {
        const newSelect = new Set(selectedServers || []);
        if (newSelect.has(server)) {
            newSelect.delete(server);
        } else {
            newSelect.add(server);
        }
        if (selectType !== null) {
            setSelectType(null);
        }
        setSelectedServers(newSelect);
    };

    const onSelectTypeChange = (type: SERVER_SELECT) => {
        setSelectType(type);
        setShowCheckbox(true);
        const serversArray = Array.from(servers.values());
        const serversToSelect = tenantFilter.length === 0
            ? serversArray
            : serversArray.filter(({ tenantId }) => tenantFilter.includes(tenantId));
        switch (type) {
            case SERVER_SELECT.DEFAULT:
                return setSelectedServers(new Set(serversToSelect
                    .filter((s) => !operationsProgress.has(s.id))));
            case SERVER_SELECT.BY_ACTIVE:
                return setSelectedServers(new Set(serversToSelect
                    .filter((s) => s.state === ServerState.RUNNING
                        && !operationsProgress.has(s.id))));
            case SERVER_SELECT.BY_INACTIVE:
                return setSelectedServers(new Set(serversToSelect
                    .filter((s) => s.state === ServerState.SHUTOFF
                        && !operationsProgress.has(s.id))));
            default:
                break;
        }
    };

    const clearSelection = () => {
        setSelectedServers(null);
        setShowCheckbox(false);
        setSelectType(null);
    };

    const setViewType = (type: SERVER_VIEW) => {
        dispatch(setServerView(type));
    };

    return (
        <ServerListContext.Provider value={{
            filteredProgress,
            filteredWorking,
            tenantIds,
            serverView,
            setViewType,
            tenantFilter,
            onTenantFilterChange,
            sortType,
            onSortTypeChange,
            selectType,
            onSelectTypeChange,
            onSelectServers,
            clearSelection,
            showCheckbox,
            setShowCheckbox,
            onReverseSort,
            selectedServers: selectedServers || new Set(),
        }}
        >
            <ServerList />
        </ServerListContext.Provider>
    );
});

const selectLoadingServers = (store: Store) => store.installingServers.servers;
const selectLoadingInstallingProgress = (store: Store) => store.installingServers.progress;

const selectServers = (store: Store) => store.server;
const selectTenantList = (store: Store) => store.tenant;
const selectOperations = (store: Store) => store.operationProgress.commonOperations;

const selector = createSelector(
    [
        selectTenantList,
        selectServers,
        selectLoadingServers,
        selectLoadingInstallingProgress,
        selectOperations,
    ],
    (
        tenantList,
        servers,
        loadingServers,
        installingProgress,
        operationsProgress,
    ) => ({
        tenantList,
        servers,
        loadingServers,
        installingProgress,
        operationsProgress,
    }),
);

const mapStateToProps = (store: Store) => (selector(store));

export default connect(mapStateToProps)(ServerListContainer);
