import React, { FC, useEffect, useRef, useState } from 'react';

import { Layout } from 'antd';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';

import { useTitle } from 'Hooks';
import { register } from 'Actions/user';
import Error from 'Entities/Error';
import { useIntl, MainHeader, Input, Button } from 'Common';
import RegistrationEnt, { IRegistration } from 'Entities/Registration';
import { emailValidate, errorChecker, getRefId, passwordValidate, saveCookieToken, saveRefId } from 'Lib/helpers/utils';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { GuestRoutePath, externalLinkBuilder } from 'Lib/helpers/routes';
import MiscApiApi from 'Apis/miscApi';
import ReferralInfo, { IReferralInfo } from 'Entities/ReferralInfo';
import theme from 'Lib/theme';

import SocialAuth from './SocialAuth';
import s from './Auth.module.pcss';
import Captcha from './Captcha';
import RefNotification from './RefNotification';

const { Content } = Layout;

type FormValues = IRegistration & { captcha: string };
const Registration: FC = () => {
    const intl = useIntl();
    const { currentLocale } = intl;
    const captchaRef = useRef<any>(null);
    const dispatch = useDispatch();
    const refId = getRefId();

    useTitle(intl.getMessage('registration_page_title'));

    const [sent, setSent] = useState(false);
    const [refInfo, setRefInfo] = useState<ReferralInfo | null>(null);

    const showRefNotification = !sent
        && refId
        && refInfo?.referrerRegDiscountEnabled;

    const getReferralInfo = async (id: number) => {
        const response = await MiscApiApi.getReferralInfo(id);
        const { result } = errorChecker<IReferralInfo>(response);
        if (result) {
            setRefInfo(new ReferralInfo(result));
        }
    };

    useEffect(() => {
        if (refId && !Number.isNaN(refId)) {
            getReferralInfo(Number(refId));
        }
    }, []);

    const onSubmit = async (v: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!NO_CAPTCHA) {
            if (!v.captcha) {
                helpers.setSubmitting(false);
                captchaRef.current?.execute();
                return;
            }
        }
        const validate = passwordValidate(v.password, RegistrationEnt.passwordMinLength, true);
        if (!validate) {
            helpers.setFieldError('password', 'error');
            return;
        }
        const req = new RegistrationEnt({
            ...v,
            ref_id: refId && Number(refId)
                ? Number(refId)
                : undefined,
        });
        dispatch(register([req.serialize(), v.captcha], {
            result: (result) => {
                helpers.setSubmitting(false);
                setSent(true);
                saveRefId('', true);

                if (result.cookie) {
                    saveCookieToken(result.cookie);
                }
            },
            error: (error: Error) => {
                const { fields } = error;
                helpers.setFieldValue('captcha', '', false);
                if (fields.password) {
                    const passwordError = fields.password[0];
                    helpers.setFieldError('password', apiErrorCodeTranslate(intl, passwordError.error_code));
                }
                if (fields.email) {
                    const emailError = fields.email[0];
                    helpers.setFieldError('email', apiErrorCodeTranslate(intl, emailError.error_code));
                }
            },
        }));
    };
    const policy = (text: string) => (
        <a
            href={externalLinkBuilder(currentLocale, GuestRoutePath.Documents, { document: 'policy' })}
        >
            {text}
        </a>
    );
    const agreement = (text: string) => (
        <a
            href={externalLinkBuilder(currentLocale, GuestRoutePath.Documents, { document: 'offer_ru' })}
        >
            {text}
        </a>
    );

    const form = !sent && (
        <Formik
            initialValues={{ email: '', password: '' } as FormValues}
            onSubmit={onSubmit}
        >
            {({
                values,
                errors,
                touched,
                setFieldValue,
                handleBlur,
                handleSubmit,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={s.auth__group}>
                        <Input
                            autoFocus
                            autoComplete="username"
                            className={cn('input', { input_error: errors.email })}
                            error={!!errors.email}
                            errorMessage={errors.email}
                            name="email"
                            onChange={(e) => setFieldValue('email', e)}
                            placeholder={intl.getMessage('email')}
                            size="large"
                            type="email"
                            validate={(str) => (
                                RegistrationEnt.emailValidate(str)
                                && emailValidate(str)
                            )}
                            value={values.email}
                        />
                    </div>

                    <div className={s.auth__group}>
                        <Input
                            validateInstant
                            autoComplete="new-password"
                            error={!!errors.password}
                            name="password"
                            onChange={(e) => setFieldValue('password', e)}
                            placeholder={intl.getMessage('password')}
                            size="large"
                            type="password"
                            validate={(e) => (
                                RegistrationEnt.passwordValidate(e)
                                && passwordValidate(e, RegistrationEnt.passwordMinLength, true)
                            )}
                            value={values.password}
                            className={cn('input', { input_error: errors.password })}
                            onBlur={handleBlur}
                        />
                        {errors.password && touched.password && errors.password !== 'error' && (
                            <div className={s.auth__error}>
                                {errors.password}
                            </div>
                        )}
                        <div className={cn(s.auth__text, s.auth__text_notice)}>
                            {intl.getMessage('password_notice_easy', { min: RegistrationEnt.passwordMinLength })}
                        </div>
                    </div>
                    <Captcha
                        captchaRef={captchaRef}
                        setValue={(e) => setFieldValue('captcha', e)}
                    />
                    <div className={s.auth__group}>
                        <Button
                            id="register_account"
                            size="medium"
                            type="primary"
                            htmlType="submit"
                            disabled={isSubmitting || !touched}
                        >
                            {intl.getMessage('registration_button')}
                        </Button>
                    </div>

                    <div className={cn(s.auth__text, s['auth__text--legal'])}>
                        {intl.getMessage('registration_disclaimer', {
                            policy,
                            agreement,
                        })}
                    </div>
                </form>
            )}
        </Formik>
    );

    return (
        <Layout>
            <MainHeader />
            <Content className={cn(theme.content.content, theme.content.auth)}>
                <div className={cn(s.wrapper, { [s.wrapper_ref]: showRefNotification })}>
                    <div id={sent ? 'registration-email-sent' : undefined} className={cn(s.auth__panel, s.auth__panel_register)}>
                        <div className={cn(s.auth__title, s['auth__title--registration'])}>
                            {!sent
                                ? intl.getMessage('registration_title')
                                : intl.getMessage('registration_title_sent')}
                        </div>
                        {sent && (
                            <div className={cn(s.auth__text, s['auth__text--top'])}>
                                {intl.getMessage('registration_desc_sent')}
                            </div>
                        )}
                        {form}
                        {!sent && (
                            <SocialAuth />
                        )}
                    </div>
                    {showRefNotification && refInfo && (
                        <RefNotification referralInfo={refInfo} />
                    )}
                </div>
            </Content>
        </Layout>
    );
};

export default Registration;
