import React, { FC, useState, useEffect, FocusEvent, createRef } from 'react';
import { Dropdown, Input } from 'antd';
import cn from 'classnames';
import InputMask from 'react-input-mask';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { getPhoneCodes } from 'Actions/profile';
import CountriesPhoneCodesResponse from 'Entities/CountriesPhoneCodesResponse';
import CountryPhoneCode from 'Entities/CountryPhoneCode';
import { Icon, useIntl } from 'Common';
import { getFullPhoneNumber, onPhonePaste } from 'Lib/helpers/utils';
import { EMPTY_FIELD_ERROR, AT_LEAST_ONE_REQUIRED } from 'Lib/helpers/consts';
import { IPhoneInfo } from 'Entities/PhoneInfo';
import theme from 'Lib/theme';
import { Store } from 'Store';

import PhoneSearch from './PhoneSearch';

const preparePhonePattern = (pattern: string) => {
    return pattern.replace(/_/g, '9');
};

const preparePhonePlaceholder = (pattern: string) => {
    return pattern.replace(/_/g, '0');
};

interface PhoneInputProps {
    value: IPhoneInfo;
    error: boolean;
    setValue: (v: IPhoneInfo) => void;
    validateMessage?: string;
    errorMessage?: string;
    validate?: (value: string) => boolean;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    phoneCodes: {
        codes: CountryPhoneCode[];
        defaultCode?: CountryPhoneCode;
    };
}

const PhoneInput: FC<PhoneInputProps> = ({
    phoneCodes,
    error,
    errorMessage,
    validateMessage,
    setValue,
    value,
    validate,
    onBlur,
}) => {
    const intl = useIntl();

    const { codes, defaultCode } = phoneCodes;
    const countedCountry = value.calling_code === defaultCode?.callingCode
        ? defaultCode
        : codes.find((c) => c.callingCode === value.calling_code)! || defaultCode;

    const phone = value.full_number.replace(value.calling_code, '');

    const [country, setCountry] = useState(countedCountry);
    const [visibleDropdown, setVisibleDropdown] = useState(false);
    const [valid, setValid] = useState<boolean | null>(null);
    const inputRef = createRef<HTMLInputElement>();

    useEffect(() => {
        const handleOutClick = (ev: any) => {
            let elemId = ev.target.id || ev.target.parentNode.id;
            if (!elemId) {
                elemId = ev.target.closest('#searchClear')?.id;
            }
            if (elemId !== 'searchInput'
                && elemId !== 'searchButton'
                && elemId !== 'searchClear'
            ) {
                setVisibleDropdown(false);
            }
        };
        document.body.addEventListener('click', handleOutClick);
        return () => {
            document.body.removeEventListener('click', handleOutClick);
        };
    });

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        if (validate) {
            setValid(phone !== undefined && validate(String(phone)));
        }
        if (onBlur) {
            onBlur(e);
        }
    };

    const validSuffix = (
        <>
            {(typeof valid === 'boolean' && (!valid || error))
                && <Icon icon="close_small" className={cn(theme.color.red, theme.form.suffix)} />}
            {(typeof valid === 'boolean' && (valid && !error))
                && <Icon icon="check" className={cn(theme.color.blue, theme.form.suffix)} />}
        </>
    );

    let descriptionView = null;
    if (validateMessage && (typeof valid === 'boolean' && !valid)) {
        descriptionView = (
            <div className={theme.form.label}>
                {validateMessage}
            </div>
        );
    }
    if (errorMessage && errorMessage !== EMPTY_FIELD_ERROR) {
        descriptionView = (
            <div className={cn(theme.form.label)}>
                {errorMessage === AT_LEAST_ONE_REQUIRED ? intl.getMessage('at_least_one_required') : errorMessage}
            </div>
        );
    }

    return (
        <label htmlFor="phoneNumber" className={theme.form.group}>
            <InputMask
                id="phoneNumber"
                mask={preparePhonePattern(country.pattern!)}
                placeholder={preparePhonePlaceholder(country.pattern!)}
                maskChar={null}
                value={phone}
                onChange={(e) => setValue(
                    {
                        calling_code: value.calling_code || countedCountry.callingCode!,
                        full_number: getFullPhoneNumber(
                            value.calling_code || countedCountry.callingCode!,
                            e.target.value,
                        ),
                    },
                )}
                onBlur={handleBlur}
                onPaste={onPhonePaste((v, c) => {
                    setValue(v);
                    setCountry(c);
                }, codes, defaultCode!)}
            >
                {(inputProps: any) => (
                    <Input
                        {...inputProps}
                        size="large"
                        ref={inputRef}
                        type="tel"
                        className={cn('input input_phone', { input_error: error })}
                        suffix={validSuffix}
                        addonBefore={(
                            <Dropdown
                                placement="bottomLeft"
                                overlay={(
                                    <PhoneSearch
                                        codes={codes}
                                        currentCountry={country}
                                        setCountryValue={(e) => {
                                            setCountry(e);
                                            setValue({
                                                ...value,
                                                calling_code: e.callingCode!,
                                            });
                                        }}
                                        setVisibleDropdown={
                                            (e: boolean) => setVisibleDropdown(e)
                                        }
                                    />
                                )}
                                trigger={['click']}
                                visible={visibleDropdown}
                            >
                                <span
                                    className={theme.form.addon}
                                    onClick={() => setVisibleDropdown(true)}
                                    id="searchButton"
                                >
                                    <span className={theme.form.addonCountry}>
                                        {country.countryCode}
                                    </span>
                                    <Icon icon="down" className={theme.form.addonIcon} />
                                    <span className={theme.form.addonCode}>
                                        +{country.callingCode}
                                    </span>
                                </span>
                            </Dropdown>
                        )}
                        data-valid={typeof valid === 'boolean' ? valid : ''}
                        data-error={error}
                        autoFocus
                    />
                )}
            </InputMask>
            {descriptionView}
        </label>
    );
};

interface PhoneInputContainerStoreProps {
    phoneCodes: CountriesPhoneCodesResponse | null;
}
type PhoneInputContainerProps = Omit<PhoneInputProps, 'phoneCodes'> & PhoneInputContainerStoreProps;

const PhoneInputContainer: FC<PhoneInputContainerProps> = (props) => {
    const dispatch = useDispatch();
    const { phoneCodes } = props;

    useEffect(() => {
        dispatch(getPhoneCodes());
    }, []);

    if (!phoneCodes || !phoneCodes?.codes || !phoneCodes?.defaultCode) {
        return null;
    }
    return <PhoneInput {...props} phoneCodes={phoneCodes as PhoneInputProps['phoneCodes']} />;
};

const selectPhoneCodes = (store: Store) => store.profile.phoneCodes;

const selector = createSelector(
    [selectPhoneCodes],
    (phoneCodes) => ({ phoneCodes }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(PhoneInputContainer);
