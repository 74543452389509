import React, { FC, useEffect, useState, DragEvent } from 'react';
import { Tooltip } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import accountApi from 'Apis/account';
import { InplaceType } from 'Actions/.';
import { addError } from 'Actions/error';
import { getCreditCards, reorderAccountCreditCards, getAccountInvoice } from 'Actions/account';
import AccountDetails from 'Entities/AccountDetails';
import { ListLayout, useIntl, Icon, Loader, Button } from 'Common';
import AccountCreditCard from 'Entities/AccountCreditCard';
import { ICreateInvoiceResponse } from 'Entities/CreateInvoiceResponse';
import { useScript } from 'Lib/hooks/hooks';
import { CLOUDPAYMENTS_SRC } from 'Lib/helpers/consts';
import { errorChecker } from 'Lib/helpers/utils';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import { Store } from 'Store';

import CreditCard from './CreditCard';
import CardPending, { Status } from './CardPending';

interface BoundCardsStoreProps {
    cards: AccountCreditCard[] | null;
    payers: Store['profile']['profilePayer'];
}
interface BoundCardsOwnProps {
    details: AccountDetails | null;
}

type BoundCardsProps = BoundCardsStoreProps & BoundCardsOwnProps;
const BoundCards: FC<BoundCardsProps> = ({ cards, payers, details }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [localCards, setLocalCards] = useState(cards);
    const [draggable, setDraggable] = useState(-1);
    const [isAdding, setIsAdding] = useState(false);
    const [status, setStatus] = useState(Status.NO);

    const scriptState = useScript(CLOUDPAYMENTS_SRC);

    const successLink = window.location.origin
        + linkPathBuilder(intl.currentLocale, RoutePath.Billing);
    const errorLink = window.location.origin
        + linkPathBuilder(intl.currentLocale, RoutePath.Billing);

    const onAddCard = async () => {
        setIsAdding(true);
        if (scriptState.loaded && !scriptState.error) {
            const resp = await accountApi.addAccountCreditCard({
                description: intl.getMessage('bound_cards_desc'),
                payment_success_url: successLink,
                payment_error_url: errorLink,
            });
            const { result, error } = errorChecker<ICreateInvoiceResponse>(resp);
            if (result) {
                const widget = new cp.CloudPayments();
                const onAddSuccess = () => {
                    dispatch(getAccountInvoice({
                        req: [result.invoice_id],
                        callback: () => setStatus(Status.NO),
                    }));
                    setStatus(Status.SUCCESS);
                    setIsAdding(false);
                };
                const onAddFailed = () => {
                    setStatus(Status.FAILED);
                    setIsAdding(false);
                };
                widget.pay(
                    result.cloud_payments_widget_pay_scheme,
                    result.cloud_payments_widget,
                    {
                        onSuccess: onAddSuccess,
                        onFail: onAddFailed,
                    },
                );
            }
            if (error) {
                setIsAdding(false);
                dispatch(addError({ type: InplaceType.GetAddCreditCard, error }));
            }
        }
    };

    useEffect(() => {
        setLocalCards(cards);
    }, [cards]);

    useEffect(() => {
        dispatch(getCreditCards());
    }, []);

    if (cards === null || localCards === null) {
        return null;
    }

    const onDragEnd = () => {
        dispatch(reorderAccountCreditCards([{
            credit_card_ids_ordered: localCards.map((c) => c.creditCardId),
        }]));
        setDraggable(-1);
    };
    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        const target = e.target as HTMLDivElement;
        const changeIndex = localCards.findIndex((el) => el.creditCardId === Number(target.id));
        const elemIndex = localCards.findIndex((el) => el.creditCardId === draggable);
        if (changeIndex + 1 && elemIndex + 1) {
            const newLocalCards = [...localCards];
            newLocalCards[elemIndex] = localCards[changeIndex];
            newLocalCards[changeIndex] = localCards[elemIndex];
            setLocalCards(newLocalCards);
        }
    };

    const getAddCardButton = () => {
        if (payers?.length === 0) {
            return (
                <Tooltip
                    placement="right"
                    title={intl.getMessage('bound_cards_notice')}
                    overlayClassName="tooltip tooltip_notice"
                >
                    <div className={theme.common.inlineFlex}>
                        <Button
                            id="add_card"
                            type="outlined"
                            size="medium"
                            className={cn(
                                theme.button.with_icon,
                                theme.button.disabled,
                            )}
                        >
                            <Icon icon="plus" />
                            {intl.getMessage('add_card')}
                        </Button>
                    </div>
                </Tooltip>
            );
        }

        return (
            <Button
                id="add_card"
                type="outlined"
                size="medium"
                className={theme.button.with_icon}
                onClick={onAddCard}
            >
                {isAdding ? (
                    <Loader circle inButton />
                ) : (
                    <Icon icon="plus" />
                )}
                {intl.getMessage('add_card')}
            </Button>
        );
    };

    return (
        <>
            <ListLayout
                title={intl.getMessage('bound_cards_title')}
                info={intl.getMessage('bound_cards_desc')}
                length={cards.length}
                dataAttrs={{ 'data-cards': String(cards.length) }}
            >
                {localCards?.length > 0 && (
                    <div
                        onDragEnd={onDragEnd}
                        onDragOver={onDragOver}
                    >
                        {localCards.map((card) => (
                            <CreditCard
                                key={card.creditCardId}
                                card={card}
                                draggable={draggable}
                                setDraggable={(id) => () => setDraggable(id)}
                            />
                        ))}
                        {status !== Status.NO && (
                            <CardPending
                                status={status}
                                removeStatus={() => setStatus(Status.NO)}
                                onRetry={() => onAddCard()}
                            />
                        )}
                    </div>
                )}
            </ListLayout>
            {details && details.accountLimits?.maxActiveSubscriptions > localCards?.length
                && getAddCardButton()}
        </>
    );
};

const selectCards = (store: Store) => store.account.cards;
const selectPayers = (store: Store) => store.profile.profilePayer;

const cardsList = createSelector(
    [selectCards, selectPayers],
    (cards, payers) => ({
        cards: cards ? Array.from(cards.values()) : null,
        payers,
    }),
);

const mapStateToProps = (store: Store) => (cardsList(store));

export default connect(mapStateToProps)(BoundCards);
