import Server from 'Entities/Server';
import {
    SERVER_VIEW,
    SEARCH_TYPE,
    SERVER_OPERATION_NOTIFICATION,
    IServerStorage,
    DATE_RANGE_TYPES,
} from './consts';

export default class UserPreferencesStorage {
    private LOCAL_STORAGE_SERVER_VIEW_KEY = '_1afg3bkdfa13d';

    private LOCAL_STORAGE_SERVER_CONFIG_KEY = '_3enjkjkfkln3er';

    private LOCAL_STORAGE_SEARCH_TYPE = '_mqxtm4lzcepm5';

    private LOCAL_STORAGE_DARK_THEME_NOTICE = '_zertju7zxek89';

    private LOCAL_STORAGE_NEW_USER_NOTICE = '_new_user_notice';

    private LOCAL_STORAGE_SERVER_LAST_SEARCH = '_selasead483';

    private LOCAL_STORAGE_RATE_US_KEY = '_rateus';

    private LOCAL_STORAGE_SERVER_RANGE_NAME = '_range_name';

    private OBSOLETE_LOCAL_STORAGE_SERVER_RANGE = '_server_range';

    private OBSOLETE_LOCAL_STORAGE_NEW_YEAR_BANNER = '_new_year_banner';

    userId: number;

    constructor(userId: number) {
        this.userId = userId;
    }

    private setStorageItem(key: string, value: string) {
        window.localStorage.setItem(`${this.userId}_${key}`, value);
    }

    private getStorageItem(key: string) {
        return window.localStorage.getItem(`${this.userId}_${key}`);
    }

    private removeStorageItem(key: string) {
        return window.localStorage.removeItem(`${this.userId}_${key}`);
    }

    private getServerRangeNameKey(server: Server) {
        return `${server.id}_${this.LOCAL_STORAGE_SERVER_RANGE_NAME}`;
    }

    setServerViewType(value: SERVER_VIEW) {
        this.setStorageItem(this.LOCAL_STORAGE_SERVER_VIEW_KEY, value);
    }

    getServerViewType(): SERVER_VIEW {
        return this.getStorageItem(this.LOCAL_STORAGE_SERVER_VIEW_KEY) as SERVER_VIEW || SERVER_VIEW.EXPAND;
    }

    setServerConfig(value: IServerStorage) {
        this.setStorageItem(this.LOCAL_STORAGE_SERVER_CONFIG_KEY, JSON.stringify(value));
    }

    removeServerConfig() {
        this.removeStorageItem(this.LOCAL_STORAGE_SERVER_CONFIG_KEY);
    }

    getServerConfig(): IServerStorage | null {
        const value = this.getStorageItem(this.LOCAL_STORAGE_SERVER_CONFIG_KEY);

        try {
            const parsed: IServerStorage | null = value && JSON.parse(value);

            if (parsed?.distribution_id && parsed?.distribution_name) {
                return parsed;
            }

            return null;
        } catch (error) {
            this.removeServerConfig();
            return null;
        }
    }

    setSearchType(value: SEARCH_TYPE) {
        this.setStorageItem(this.LOCAL_STORAGE_SEARCH_TYPE, value);
    }

    getSearchType(): SEARCH_TYPE {
        return this.getStorageItem(this.LOCAL_STORAGE_SEARCH_TYPE) as SEARCH_TYPE || SEARCH_TYPE.STRICT;
    }

    setDarkThemeNoticeShown(value: boolean) {
        this.setStorageItem(this.LOCAL_STORAGE_DARK_THEME_NOTICE, value.toString());
    }

    isDarkThemeNoticeShown() {
        return this.getStorageItem(this.LOCAL_STORAGE_DARK_THEME_NOTICE);
    }

    setNewUserNotice(value: boolean) {
        this.setStorageItem(this.LOCAL_STORAGE_NEW_USER_NOTICE, value.toString());
    }

    getNewUserNotice() {
        return this.getStorageItem(this.LOCAL_STORAGE_NEW_USER_NOTICE);
    }

    setServerLastSearch(value: number[]) {
        this.setStorageItem(this.LOCAL_STORAGE_SERVER_LAST_SEARCH, JSON.stringify(value));
    }

    getServerLastSearch() {
        return this.getStorageItem(this.LOCAL_STORAGE_SERVER_LAST_SEARCH);
    }

    setServerOperationNotification(key: SERVER_OPERATION_NOTIFICATION, value: boolean) {
        this.setStorageItem(key, value.toString());
    }

    getServerOperationNotification(key: SERVER_OPERATION_NOTIFICATION) {
        return this.getStorageItem(key);
    }

    removeServerOperationNotification(key: SERVER_OPERATION_NOTIFICATION) {
        this.removeStorageItem(key);
    }

    getRateUs() {
        return this.getStorageItem(this.LOCAL_STORAGE_RATE_US_KEY);
    }

    setRateUs(value: string) {
        this.setStorageItem(this.LOCAL_STORAGE_RATE_US_KEY, value);
    }

    getServerRange(server: Server): DATE_RANGE_TYPES | null {
        return this.getStorageItem(this.getServerRangeNameKey(server)) as DATE_RANGE_TYPES;
    }

    setServerRange(server: Server, rangeName: DATE_RANGE_TYPES) {
        this.setStorageItem(this.getServerRangeNameKey(server), rangeName);
    }

    removeServerRange(server: Server) {
        this.removeStorageItem(this.getServerRangeNameKey(server));
    }

    // Remove server range settings for all users and servers
    removeObsoleteServerRanges() {
        const keys = Object.keys(localStorage).filter((v) => v.includes(this.OBSOLETE_LOCAL_STORAGE_SERVER_RANGE));

        keys.forEach((key) => {
            if (!window.localStorage.getItem(key)) {
                return;
            }

            window.localStorage.removeItem(key);
        });
    }

    removeObsoleteSettings() {
        const obsoleteSettings = [
            this.OBSOLETE_LOCAL_STORAGE_NEW_YEAR_BANNER,
        ];

        obsoleteSettings.forEach((setting) => {
            if (!this.getStorageItem(setting)) {
                return;
            }

            this.removeStorageItem(setting);
        });

        this.removeObsoleteServerRanges();
    }

    migrateUserSettings() {
        const settingsToMigrate = [
            this.LOCAL_STORAGE_SERVER_VIEW_KEY,
            this.LOCAL_STORAGE_SERVER_CONFIG_KEY,
            this.LOCAL_STORAGE_SEARCH_TYPE,
            this.LOCAL_STORAGE_DARK_THEME_NOTICE,
            this.LOCAL_STORAGE_NEW_USER_NOTICE,
            this.LOCAL_STORAGE_SERVER_LAST_SEARCH,
            ...Object.values(SERVER_OPERATION_NOTIFICATION),
        ];

        settingsToMigrate.forEach((setting) => {
            const prevValue = window.localStorage.getItem(setting);

            if (!prevValue) {
                return;
            }

            window.localStorage.removeItem(setting);

            switch (setting) {
                case this.LOCAL_STORAGE_SERVER_VIEW_KEY:
                    if (!this.getServerViewType()) {
                        this.setServerViewType(prevValue as SERVER_VIEW);
                    }
                    break;
                case this.LOCAL_STORAGE_SERVER_CONFIG_KEY:
                    if (!this.getServerConfig()) {
                        this.setServerConfig(JSON.parse(prevValue));
                    }
                    break;
                case this.LOCAL_STORAGE_SEARCH_TYPE:
                    if (!this.getSearchType()) {
                        this.setSearchType(prevValue as SEARCH_TYPE);
                    }
                    break;
                case this.LOCAL_STORAGE_DARK_THEME_NOTICE:
                    if (!this.isDarkThemeNoticeShown()) {
                        this.setDarkThemeNoticeShown(prevValue === 'true');
                    }
                    break;
                case this.LOCAL_STORAGE_NEW_USER_NOTICE:
                    if (!this.getNewUserNotice()) {
                        this.setNewUserNotice(prevValue === 'true');
                    }
                    break;
                case this.LOCAL_STORAGE_SERVER_LAST_SEARCH:
                    if (!this.getServerLastSearch()) {
                        this.setServerLastSearch(JSON.parse(prevValue));
                    }
                    break;
                case SERVER_OPERATION_NOTIFICATION.REBOOT:
                case SERVER_OPERATION_NOTIFICATION.RESET:
                case SERVER_OPERATION_NOTIFICATION.SHUTDOWN:
                case SERVER_OPERATION_NOTIFICATION.DESTROY:
                    if (!this.getServerOperationNotification(setting)) {
                        this.setServerOperationNotification(setting, prevValue === 'true');
                    }
                    break;
                default:
                    break;
            }
        });
    }
}
