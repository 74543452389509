import React, { FC } from 'react';

import { useIntl, CommonModalLayout } from 'Common';
import theme from 'Lib/theme';

interface RebootNotifyProps {
    handleSubmit: () => void;
    handleClose: () => void;
}

const RebootNotify: FC<RebootNotifyProps> = ({
    handleSubmit,
    handleClose,
}) => {
    const intl = useIntl();

    return (
        <CommonModalLayout
            visible
            title={intl.getMessage('server_change_tariff_reboot')}
            buttonText={intl.getMessage('server_change_tariff_reboot_save')}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
        >
            <div className={theme.modal.desc}>
                {intl.getMessage('server_change_tariff_reboot_desc')}
            </div>
        </CommonModalLayout>
    );
};

export default RebootNotify;
