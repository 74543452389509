import React, { FC } from 'react';

import { useIntl, Button } from 'Common';
import { useEnter } from 'Hooks';

import s from '../Credentials.module.pcss';

interface SubmitProps {
    handleSubmit: () => void;
    handleClose: () => void;
    disabled?: boolean;
}

const Submit: FC<SubmitProps> = ({ handleSubmit, handleClose, disabled }) => {
    const intl = useIntl();

    useEnter(handleSubmit, [handleSubmit]);

    return (
        <div className={s.actions}>
            <Button
                type="primary"
                size="big"
                key="submit"
                onClick={handleSubmit}
                disabled={disabled}
                inGroup
            >
                {intl.getMessage('save')}
            </Button>
            <Button
                type="link"
                size="big"
                key="cancel"
                onClick={handleClose}
            >
                {intl.getMessage('cancel')}
            </Button>
        </div>
    );
};

export default Submit;
