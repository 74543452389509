import React, { FC } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';

import AccountBillingDocument from 'Entities/AccountBillingDocument';
import theme from 'Lib/theme';
import { DATE_FORMAT } from 'Lib/helpers/consts';
import { Button, useIntl } from 'Common';

interface DocumentProps {
    act: AccountBillingDocument;
}

const Document: FC<DocumentProps> = ({ act }) => {
    const {
        actNumber,
        vatNumber,
        downloadUrl,
        amount,
        periodEndDate,
    } = act;

    const intl = useIntl();

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.with_actions,
            )}
        >
            <div className={cn(theme.card.grid, theme.card.grid_invoice)}>
                <div className={theme.common.textOverflow}>
                    {actNumber && (
                        <span className={theme.dotList.item}>
                            {actNumber}
                        </span>
                    )}
                    <span className={theme.dotList.item}>
                        {vatNumber}
                    </span>
                </div>
                <div className={theme.common.textOverflow} />
                <div className={theme.common.textOverflow}>
                    {intl.getMessage('price_simple', { value: amount })}
                </div>
                <div className={theme.card.actionsWrap}>
                    <div className={theme.color.grayHeaders}>
                        {dayjs(periodEndDate, DATE_FORMAT).format(intl.getMessage('time_format_month'))}
                    </div>

                    <div className={theme.card.actions}>
                        <a
                            href={downloadUrl}
                            className={cn(theme.card.action, theme.card.action_hover)}
                            download
                        >
                            <Button
                                type="icon"
                                icon="download"
                                className={cn(theme.card.action, theme.card.action_hover)}
                                title={intl.getMessage('title_dowload_file_extension', { value: 'PDF' })}
                            />
                        </a>
                    </div>

                    <Popover
                        placement="leftTop"
                        trigger="click"
                        content={(
                            <div className={theme.popover.popover}>
                                <a
                                    href={downloadUrl}
                                    className={theme.popover.item}
                                    download
                                >
                                    {intl.getMessage('title_dowload_file_extension', { value: 'PDF' })}
                                </a>
                            </div>
                        )}
                        overlayClassName="popover"
                    >
                        <Button
                            type="icon"
                            icon="options"
                            className={cn(theme.card.action, theme.card.action_popover)}
                        />
                    </Popover>
                </div>
            </div>
        </div>
    );
};

export default Document;
