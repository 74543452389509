import React, { FC, useState } from 'react';
import { Modal as ModalControl } from 'antd';

interface ModalProps {
    visible: boolean;
    title?: React.ReactNode;
    submitButtonId?: string;
    buttonText?: string;
    wrapClassName?: string;
    width?: number;
    onCancel: () => void;
    handleSubmit?: () => void;
    footer: React.ReactNode | null;
    disabled?: boolean;
    centered?: boolean;
    closeIcon?: React.ReactNode;
    mask?: boolean;
    maskClosable?: boolean;
    maskStyle?: React.CSSProperties;
    onOk?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Modal: FC<ModalProps> = ({
    visible,
    children,
    title,
    wrapClassName,
    width,
    onCancel,
    footer,
    centered,
    closeIcon,
    mask,
    maskClosable,
    maskStyle,
    onOk,
}) => {
    const [modalBodyClicked, setModalBodyClicked] = useState(false);
    const ANT_MODAL_BODY = '.ant-modal-body';

    const cancelClick = () => {
        return modalBodyClicked ? setModalBodyClicked(false) : onCancel();
    };

    const onMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const isModalClosestBody = !!(e.target as HTMLElement).closest(ANT_MODAL_BODY);
        setModalBodyClicked(isModalClosestBody);
    };

    return (
        <div onMouseDown={onMouseDown}>
            <ModalControl
                visible={visible}
                title={title}
                wrapClassName={wrapClassName}
                onCancel={cancelClick}
                footer={footer}
                closeIcon={closeIcon}
                width={width || 480}
                centered={centered}
                mask={mask}
                maskClosable={maskClosable}
                maskStyle={maskStyle}
                onOk={onOk}
            >
                {children}
            </ModalControl>
        </div>
    );
};

export default Modal;
