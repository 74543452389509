import React, { FC } from 'react';

import { useIntl, CommonModalLayout } from 'Common';

interface DeleteModalLayoutProps {
    visible: boolean;
    title: string;
    buttonText?: string;
    submitButtonId?: string;
    handleClose: () => void;
    handleDelete?: () => void;
}

const DeleteModalLayout: FC<DeleteModalLayoutProps> = ({
    visible,
    children,
    title,
    buttonText,
    submitButtonId,
    handleClose,
    handleDelete,
}) => {
    const intl = useIntl();

    return (
        <CommonModalLayout
            visible={visible}
            title={title}
            submitButtonId={submitButtonId}
            buttonText={buttonText || intl.getMessage('delete')}
            handleSubmit={handleDelete}
            handleClose={handleClose}
        >
            {children}
        </CommonModalLayout>
    );
};

export default DeleteModalLayout;
