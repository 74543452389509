import React, { FC } from 'react';
import cn from 'classnames';

import { Link, Icon } from 'Common';
import Server from 'Entities/Server';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

interface HeaderLinkProps {
    name: string;
    server: Server;
    active?: boolean;
    url?: RoutePath;
}

const HeaderLink: FC<HeaderLinkProps> = ({ name, server, active, url }) => {
    if (active) {
        return (
            <div className={cn(theme.header.link, theme.header.active)}>
                {name}
                {server.upgradeRecommendation && url === RoutePath.ServerTariff && (
                    <Icon icon="attention_no_border" className={theme.header.icon} />
                )}
            </div>
        );
    }

    return url ? (
        <Link
            to={url}
            props={{ serverId: server.id, tenantId: server.tenantId }}
            className={theme.header.link}
        >
            {server.upgradeRecommendation && url === RoutePath.ServerTariff ? (
                <>
                    {name}
                    <Icon icon="attention_no_border" className={theme.header.icon} />
                </>
            ) : (
                name
            )}
        </Link>
    ) : null;
};

export default HeaderLink;
