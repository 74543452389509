import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import cn from 'classnames';

import { addClientReviewKey } from 'Actions/profile';
import { useIntl, CommonModalLayout, blackText, Button } from 'Common';
import Profile from 'Entities/Profile';
import { REVIEWS_KEYS } from 'Lib/helpers/consts';
import { Store } from 'Store';
import theme from 'Lib/theme';

const selectProfileDetails = (st: Store) => st.profile.info;
const selectLoading = (st: Store) => st.user.loading;
const selector = createSelector(selectProfileDetails, selectLoading, (profile, loading) => ({ profile, loading }));

interface DiscountNoticeProps {
    profile: Profile;
}

const DiscountNotice: FC<DiscountNoticeProps> = ({ profile }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { discounts, sentReviews } = profile;

    const referrerDiscount = discounts.find((d) => d.balance === REVIEWS_KEYS.REFERRER_REG_DISCOUNT);
    const visible = referrerDiscount
        && !sentReviews.includes(REVIEWS_KEYS.REFERRER_REG_DISCOUNT);
    const [showModal, setShowModal] = useState(visible);

    if (!showModal || !referrerDiscount) {
        return null;
    }

    const { discountPercent, expirationDateMillis } = referrerDiscount;

    const handleClose = () => {
        dispatch(addClientReviewKey([{
            review_key: REVIEWS_KEYS.REFERRER_REG_DISCOUNT,
        }]));
        setShowModal(false);
    };

    return (
        <CommonModalLayout
            centered
            visible={showModal}
            handleClose={handleClose}
            handleSubmit={handleClose}
            buttonText={intl.getMessage('discount_notice_start')}
            title={intl.getMessage('discount_notice_activated')}
            width={480}
            noFooter
        >
            <div className={cn(theme.modal.desc, theme.modal.desc_notice)}>
                {expirationDateMillis ? (
                    intl.getMessage('discount_notice_desc_with_expiration', {
                        b: blackText,
                        discount: `${discountPercent}%`,
                        date: dayjs(expirationDateMillis).format(intl.getMessage('time_format_date_short')),
                    })
                ) : (
                    intl.getMessage('discount_notice_desc', {
                        b: blackText,
                        discount: `${discountPercent}%`,
                    })
                )}
            </div>
            <div className={theme.modal.footer}>
                <Button
                    type="primary"
                    size="big"
                    onClick={handleClose}
                >
                    {intl.getMessage('discount_notice_start')}
                </Button>
            </div>
        </CommonModalLayout>
    );
};

const DiscountNoticeContainer: FC = () => {
    const { profile, loading } = useSelector(selector);
    if (!profile || loading) {
        return null;
    }
    return <DiscountNotice profile={profile} />;
};

export default DiscountNoticeContainer;
