import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';

import { useIntl, Link, Button } from 'Common';
import Server from 'Entities/Server';
import PubKeysApi from 'Apis/pubKeys';
import PublicKey, { IPublicKey } from 'Entities/PublicKey';
import { errorChecker } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import KeysForm from './KeysForm';
import s from './PublicKeys.module.pcss';

interface PublicKeysProps {
    server: Server;
}

const PublicKeys: FC<PublicKeysProps> = ({ server }) => {
    const intl = useIntl();
    const { tenantId } = server;
    const [changed, setChanged] = useState(false);
    const [availableKeys, setAvailableKeys] = useState<PublicKey[] | null>(null);

    useEffect(() => {
        const loadPublicKeys = async () => {
            const response = await PubKeysApi.getPublicKeysForTenant(
                tenantId,
            );
            const { result } = errorChecker<IPublicKey[]>(response);
            if (result) {
                const resEnt = result.map((r) => new PublicKey(r));
                setAvailableKeys(resEnt);
            } else {
                setAvailableKeys([]);
            }
        };
        loadPublicKeys();
    }, []);

    const handleChanged = (value: boolean) => {
        setChanged(value);
    };

    if (!availableKeys) {
        return null;
    }

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.plate,
                theme.card.rounded,
                s.block,
                { [theme.card.active]: changed },
            )}
        >
            <div className={s.wrap}>
                <div className={s.title}>
                    {intl.getMessage('public_keys')}
                </div>
                <div className={s.desc}>
                    {availableKeys?.length > 0 ? (
                        intl.getMessage('server_access_public_keys')
                    ) : (
                        intl.getMessage('public_keys_empty_form')
                    )}
                </div>

                {availableKeys?.length > 0 ? (
                    <KeysForm
                        server={server}
                        tenantPublicKeys={availableKeys}
                        changed={changed}
                        setChanged={handleChanged}
                    />
                ) : (
                    <Link to={RoutePath.PublicKeys}>
                        <Button
                            type="link"
                            size="medium"
                        >
                            {intl.getMessage('add_key')}
                        </Button>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default PublicKeys;
