import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import { useIntl, CommonModalLayout, DropdownRangePicker } from 'Common';
import { getAccountVerificationAct } from 'Actions/account';
import { REQUEST_DATE_FORMAT } from 'Lib/helpers/consts';
import { Range } from 'Lib/helpers/utils';

interface ModalActProps {
    visible: boolean;
    close: () => void;
}

const ModalAct: FC<ModalActProps> = ({ visible, close }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const ranges = new Range(dayjs().add(-1, 'month'), dayjs());
    const [range, setRange] = useState(ranges);

    const handleOk = () => {
        const { from: fday, to: tday } = range;
        const from = fday.format(REQUEST_DATE_FORMAT);
        const to = tday.format(REQUEST_DATE_FORMAT);
        dispatch(getAccountVerificationAct([from, to]));
        close();
    };

    return (
        <CommonModalLayout
            visible={visible}
            title={intl.getMessage('verification_act')}
            handleSubmit={handleOk}
            handleClose={close}
            buttonText={intl.getMessage('verification_act_generate')}
        >
            <div className="modal__desc modal__desc--gray">
                {intl.getMessage('verification_act_desc')}
            </div>
            <div className="modal__label">
                {intl.getMessage('verification_act_interval')}
            </div>
            <DropdownRangePicker
                range={range}
                setDateRange={setRange}
                mode="year"
                block
            />
        </CommonModalLayout>
    );
};

export default ModalAct;
