import React, { FC } from 'react';
import cn from 'classnames';

import { Row, Col } from 'antd';
import { Link, useIntl } from 'Common';
import NotificationEntity from 'Entities/Notification';
import { EventNotificationLevel } from 'Entities/EventNotificationLevel';
import Server from 'Entities/Server';
import { RoutePath } from 'Lib/helpers/routes';
import { NOTIFICATIONS_STATE } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import DashboardLink from '../DashboardLink';
import Notification from './Notification';
import s from '../Stats.module.pcss';

interface NotificationsProps {
    newNotifications: number;
    notifications: NotificationEntity[];
    servers: Map<number, Server>;
}

const Notifications: FC<NotificationsProps> = ({ newNotifications, notifications }) => {
    const intl = useIntl();

    const ImportantAlerts = () => {
        let alerts = 0;
        notifications.map((notification) => {
            if (notification.level === EventNotificationLevel.WARN) {
                alerts += 1;
            }
            return null;
        });
        return (
            <>
                <div className={s.title}>
                    {intl.getPlural('important_notifications', alerts)}
                </div>
                <div className={cn(s.val, s.val_main)}>
                    {alerts}
                </div>
            </>
        );
    };

    return (
        <div className={s.block}>
            <Row>
                <Col span={24} sm={12}>
                    <div className={s.info}>
                        <div className={s.title}>
                            {intl.getPlural('notifications_plural', newNotifications)}
                        </div>
                        <div className={cn(s.val, s.val_main)}>
                            {newNotifications}
                        </div>
                    </div>
                </Col>
                <Col span={24} sm={12}>
                    <ImportantAlerts />
                </Col>
            </Row>
            <div className={s.notifications}>
                {notifications.length > 0 ? (
                    <>
                        <div className={cn(s.row, s.row_head)}>
                            <Row>
                                <Col span={18}>
                                    <div className={cn(
                                        s.col, s.col_head, s.label, s.label_head,
                                    )}
                                    >
                                        {intl.getMessage('event')}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={cn(s.col, s.col_last, s.label, s.label_head)}>
                                        {intl.getMessage('time')}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            {notifications.map((notification) => (
                                <Notification
                                    key={notification.id}
                                    notification={notification}
                                />
                            ))}
                        </div>
                        {!!(newNotifications - notifications.length) && (
                            <Link
                                to={RoutePath.Notifications}
                                props={{ unread: NOTIFICATIONS_STATE.UNREAD }}
                                className={cn(
                                    s.label,
                                    s.more,
                                    theme.link.link,
                                    theme.link.gray,
                                )}
                            >
                                {intl.getPlural('more_notifications', newNotifications - notifications.length)}
                            </Link>
                        )}
                    </>
                ) : (
                    <div className={s.label}>
                        {intl.getMessage('dashboard_no_notifications')}
                    </div>
                )}
            </div>
            <DashboardLink
                to={RoutePath.Notifications}
                props={{ unread: NOTIFICATIONS_STATE.UNREAD }}
            />
        </div>
    );
};

export default Notifications;
