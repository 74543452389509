// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IResellerClientUpdate {
    comment?: string;
    margin_percent?: number;
    starred?: boolean;
}

export default class ResellerClientUpdate {
    readonly _comment: string | undefined;

    /**
     * Description: Set ot update reseller's comment on a client
     * Example: New comment
     */
    get comment(): string | undefined {
        return this._comment;
    }

    static get commentMinLength() {
        return 0;
    }

    static get commentMaxLength() {
        return 200;
    }

    static commentValidate(comment: string | undefined): boolean {
        return (!comment ? true : comment.length > 0) && (!comment ? true : comment.length <= 200);
    }

    readonly _margin_percent: number | undefined;

    /**
     * Description: New margin value
     * Example: 5
     */
    get marginPercent(): number | undefined {
        return this._margin_percent;
    }

    static get marginPercentMaxValue() {
        return 100;
    }

    static marginPercentValidate(marginPercent: number | undefined): boolean {
        return !marginPercent ? true : typeof marginPercent === 'number';
    }

    readonly _starred: boolean | undefined;

    /**
     * Description: Is client favourite
     * Example: true
     */
    get starred(): boolean | undefined {
        return this._starred;
    }

    constructor(props: IResellerClientUpdate) {
        if (typeof props.comment === 'string') {
            this._comment = props.comment.trim();
        }
        if (typeof props.margin_percent === 'number') {
            this._margin_percent = props.margin_percent;
        }
        if (typeof props.starred === 'boolean') {
            this._starred = props.starred;
        }
    }

    serialize(): IResellerClientUpdate {
        const data: IResellerClientUpdate = {
        };
        if (typeof this._comment !== 'undefined') {
            data.comment = this._comment;
        }
        if (typeof this._margin_percent !== 'undefined') {
            data.margin_percent = this._margin_percent;
        }
        if (typeof this._starred !== 'undefined') {
            data.starred = this._starred;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            margin_percent: !this._margin_percent ? true : typeof this._margin_percent === 'number',
            starred: !this._starred ? true : typeof this._starred === 'boolean',
            comment: (!this._comment ? true : this._comment.length > 0) && (!this._comment ? true : this._comment.length <= 200),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IResellerClientUpdate): ResellerClientUpdate {
        return new ResellerClientUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        comment: 'comment',
        marginPercent: 'margin_percent',
        starred: 'starred',
        }
;

    mergeDeepWith(props: Partial<ResellerClientUpdate>): ResellerClientUpdate {
        const updateData: Partial<IResellerClientUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ResellerClientUpdate) => {
            const updateKey = this.keys[key] as keyof IResellerClientUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IResellerClientUpdate, keyof IResellerClientUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ResellerClientUpdate({ ...this.serialize(), ...updateData });
    }
}
