import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FormikHelpers, FormikProps } from 'formik';
import cn from 'classnames';

import { useIntl, CodeInput, FormModalLayout, PhoneInput } from 'Common';
import { sendPhoneConfirmationCode, confirmPhoneWithCode } from 'Actions/profile';
import CountriesPhoneCodesResponse from 'Entities/CountriesPhoneCodesResponse';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import Error from 'Entities/Error';
import PhoneInfo, { IPhoneInfo } from 'Entities/PhoneInfo';
import theme from 'Lib/theme';

interface FormValues {
    phone: IPhoneInfo;
    confirmationCode: string;
    showCode: boolean;
}
interface ModalPhoneProps {
    phone?: PhoneInfo;
    phoneCodes: CountriesPhoneCodesResponse;
    handleClose: () => void;
}

const ModalPhone: FC<ModalPhoneProps> = ({ phone, handleClose }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const title = phone ? intl.getMessage('profile_phone_edit') : intl.getMessage('profile_phone_add');
    const buttonText = phone ? intl.getMessage('save') : intl.getMessage('profile_phone_submit');

    const onSendAgain = (
        values: FormValues,
        setFieldError: (field: string, value: any, shouldValidate?: boolean) => void,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
    ) => {
        const { phone: reqPhone } = values;
        dispatch(sendPhoneConfirmationCode([reqPhone], {
            error: (error: Error) => {
                if (error?.fields?.phone) {
                    setFieldError('phone', EMPTY_FIELD_ERROR);
                }
            },
            result: () => {
                setFieldValue('showCode', true);
                setFieldError('phone', '');
            },
        }));
    };

    const onSubmit = (
        values: FormValues,
        { setFieldError, setFieldValue, setSubmitting }: FormikHelpers<FormValues>,
    ) => {
        const { confirmationCode, showCode, phone: vPhone } = values;
        if (phone?.callingCode === vPhone.calling_code
            && phone?.fullNumber === vPhone.full_number) {
            handleClose();
            return;
        }
        if (showCode) {
            if (!confirmationCode) {
                setFieldError('confirmationCode', EMPTY_FIELD_ERROR);
                return;
            }
            dispatch(confirmPhoneWithCode(
                [{ confirmation_code: confirmationCode }],
                {
                    error: () => {
                        setSubmitting(false);
                        setFieldError('confirmationCode', EMPTY_FIELD_ERROR);
                    },
                    result: () => {
                        handleClose();
                    },
                },
            ));
        } else {
            onSendAgain(values, setFieldError, setFieldValue);
            setSubmitting(false);
        }
    };

    const getInitialValues = (): FormValues => {
        return {
            confirmationCode: '',
            showCode: false,
            phone: phone?.serialize() || { calling_code: '', full_number: '' },
        };
    };

    return (
        <FormModalLayout
            visible
            title={title}
            initialValues={getInitialValues()}
            handleClose={handleClose}
            handleSubmit={onSubmit}
            buttonText={buttonText}
            width={600}
        >
            {({
                values,
                errors,
                setFieldValue,
                setFieldError,
            }: FormikProps<FormValues>) => (
                <>
                    <div className={theme.modal.group}>
                        <div className={cn(theme.modal.label, theme.modal.label_phone)}>
                            {intl.getMessage('profile_phone_add_desc')}
                        </div>
                        <PhoneInput
                            value={values.phone}
                            setValue={(e) => setFieldValue('phone', e)}
                            error={!!errors.phone}
                            validate={() => values.phone.full_number.length > 0}
                        />
                    </div>
                    {values.showCode && (
                        <div className={theme.modal.group}>
                            <div className={cn(theme.modal.label, theme.modal.label_phone)}>
                                {intl.getMessage('profile_phone_confirm_label')}
                            </div>
                            <CodeInput
                                value={values.confirmationCode}
                                setValue={(e) => setFieldValue('confirmationCode', e)}
                                codeError={!!errors.confirmationCode}
                                setCodeError={(e) => setFieldError('confirmationCode', e ? EMPTY_FIELD_ERROR : '')}
                                onSendAgain={() => onSendAgain(
                                    values,
                                    setFieldError,
                                    setFieldValue,
                                )}
                            />
                        </div>
                    )}
                </>
            )}
        </FormModalLayout>
    );
};

export default ModalPhone;
