import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import Account, { IAccount } from 'Entities/Account';
import AccountAutoTopUpSettingsUpdate, { IAccountAutoTopUpSettingsUpdate } from 'Entities/AccountAutoTopUpSettingsUpdate';
import AccountBillingDocument, { IAccountBillingDocument } from 'Entities/AccountBillingDocument';
import AccountCreditCard, { IAccountCreditCard } from 'Entities/AccountCreditCard';
import AccountCreditCardAdd, { IAccountCreditCardAdd } from 'Entities/AccountCreditCardAdd';
import AccountCreditCardReorder, { IAccountCreditCardReorder } from 'Entities/AccountCreditCardReorder';
import AccountDashboard, { IAccountDashboard } from 'Entities/AccountDashboard';
import AccountDetails, { IAccountDetails } from 'Entities/AccountDetails';
import AccountDocument, { IAccountDocument } from 'Entities/AccountDocument';
import AccountExpense, { IAccountExpense } from 'Entities/AccountExpense';
import AccountExpenseRequest, { IAccountExpenseRequest } from 'Entities/AccountExpenseRequest';
import AccountExpensesDetailed, { IAccountExpensesDetailed } from 'Entities/AccountExpensesDetailed';
import AccountExpensesDetailedRequest, { IAccountExpensesDetailedRequest } from 'Entities/AccountExpensesDetailedRequest';
import AccountInvoice, { IAccountInvoice } from 'Entities/AccountInvoice';
import AccountVerificationActURL, { IAccountVerificationActURL } from 'Entities/AccountVerificationActURL';
import CouponActivateRequest, { ICouponActivateRequest } from 'Entities/CouponActivateRequest';
import CreateChargeFromCardInvoiceRequest, { ICreateChargeFromCardInvoiceRequest } from 'Entities/CreateChargeFromCardInvoiceRequest';
import CreateInvoiceRequest, { ICreateInvoiceRequest } from 'Entities/CreateInvoiceRequest';
import CreateInvoiceResponse, { ICreateInvoiceResponse } from 'Entities/CreateInvoiceResponse';
import CreateNoCashInvoiceRequest, { ICreateNoCashInvoiceRequest } from 'Entities/CreateNoCashInvoiceRequest';
import CreatePayerRequest, { ICreatePayerRequest } from 'Entities/CreatePayerRequest';
import OrganizationDetails, { IOrganizationDetails } from 'Entities/OrganizationDetails';
import OrganizationDetailsRequest, { IOrganizationDetailsRequest } from 'Entities/OrganizationDetailsRequest';
import PayerInfo, { IPayerInfo } from 'Entities/PayerInfo';
import Server, { IServer } from 'Entities/Server';
import UpdatePayerRequest, { IUpdatePayerRequest } from 'Entities/UpdatePayerRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class AccountApi {
    static async activateCoupon(couponactivaterequest: ICouponActivateRequest, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const couponactivaterequestValid = new CouponActivateRequest(couponactivaterequest);
        haveError.push(...couponactivaterequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/coupon`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(couponactivaterequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async addAccountCreditCard(accountcreditcardadd: IAccountCreditCardAdd, optionalToken?: string): Promise<ICreateInvoiceResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const accountcreditcardaddValid = new AccountCreditCardAdd(accountcreditcardadd);
        haveError.push(...accountcreditcardaddValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/credit_cards`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(accountcreditcardaddValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async cancelAccountInvoice(invoice_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/invoices/${invoice_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async createAccountInvoice(createinvoicerequest: ICreateInvoiceRequest, optionalToken?: string): Promise<ICreateInvoiceResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const createinvoicerequestValid = new CreateInvoiceRequest(createinvoicerequest);
        haveError.push(...createinvoicerequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/invoices`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(createinvoicerequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async createAccountInvoiceWithCreditCard(createchargefromcardinvoicerequest: ICreateChargeFromCardInvoiceRequest, optionalToken?: string): Promise<IAccountInvoice | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const createchargefromcardinvoicerequestValid = new CreateChargeFromCardInvoiceRequest(createchargefromcardinvoicerequest);
        haveError.push(...createchargefromcardinvoicerequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/invoices/card`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(createchargefromcardinvoicerequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async createAccountNoCashInvoice(createnocashinvoicerequest: ICreateNoCashInvoiceRequest, optionalToken?: string): Promise<ICreateInvoiceResponse | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const createnocashinvoicerequestValid = new CreateNoCashInvoiceRequest(createnocashinvoicerequest);
        haveError.push(...createnocashinvoicerequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/invoices/no_cash`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(createnocashinvoicerequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async createAccountPayer(createpayerrequest: ICreatePayerRequest, optionalToken?: string): Promise<IPayerInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const createpayerrequestValid = new CreatePayerRequest(createpayerrequest);
        haveError.push(...createpayerrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/payers`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(createpayerrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteAccountCreditCard(card_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/credit_cards/${card_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccount(optionalToken?: string): Promise<IAccount | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountBillingDocuments(optionalToken?: string): Promise<IAccountBillingDocument[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/billing_documents`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountCreditCards(optionalToken?: string): Promise<IAccountCreditCard[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/credit_cards`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountDashboard(optionalToken?: string): Promise<IAccountDashboard | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/dashboard`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountDetails(optionalToken?: string): Promise<IAccountDetails | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/details`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountDocuments(optionalToken?: string): Promise<IAccountDocument[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/documents`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountExpensesCSV(accountexpenserequest: IAccountExpenseRequest, optionalToken?: string): Promise<string | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const accountexpenserequestValid = new AccountExpenseRequest(accountexpenserequest);
        haveError.push(...accountexpenserequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/expenses`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(accountexpenserequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.text();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountExpensesDetailed(accountexpensesdetailedrequest: IAccountExpensesDetailedRequest, optionalToken?: string): Promise<IAccountExpensesDetailed | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const accountexpensesdetailedrequestValid = new AccountExpensesDetailedRequest(accountexpensesdetailedrequest);
        haveError.push(...accountexpensesdetailedrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/expenses/detailed`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(accountexpensesdetailedrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountExpensesStats(accountexpenserequest: IAccountExpenseRequest, optionalToken?: string): Promise<IAccountExpense[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const accountexpenserequestValid = new AccountExpenseRequest(accountexpenserequest);
        haveError.push(...accountexpenserequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/expenses.json`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(accountexpenserequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountInvoice(invoice_id: number, optionalToken?: string): Promise<IAccountInvoice | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/invoices/${invoice_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountInvoices(optionalToken?: string): Promise<IAccountInvoice[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/invoices`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountPayer(payer_id: number, optionalToken?: string): Promise<IPayerInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/payers/${payer_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountPayers(optionalToken?: string): Promise<IPayerInfo[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/payers`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getAccountVerificationAct(date_from: string, date_to: string, optionalToken?: string): Promise<IAccountVerificationActURL | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            date_from: date_from,
            date_to: date_to,
        }
        return await fetch(`/api/v1/account/account_verification?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getClientAccountableServers(optionalToken?: string): Promise<IServer[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/account/expenses/servers`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getOrganizationDetailsByINN(organizationdetailsrequest: IOrganizationDetailsRequest, optionalToken?: string): Promise<IOrganizationDetails | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const organizationdetailsrequestValid = new OrganizationDetailsRequest(organizationdetailsrequest);
        haveError.push(...organizationdetailsrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/payers/org_details`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(organizationdetailsrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async reorderAccountCreditCards(accountcreditcardreorder: IAccountCreditCardReorder, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const accountcreditcardreorderValid = new AccountCreditCardReorder(accountcreditcardreorder);
        haveError.push(...accountcreditcardreorderValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/credit_cards/reorder`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(accountcreditcardreorderValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateAccountAutoTopUpSettings(accountautotopupsettingsupdate: IAccountAutoTopUpSettingsUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const accountautotopupsettingsupdateValid = new AccountAutoTopUpSettingsUpdate(accountautotopupsettingsupdate);
        haveError.push(...accountautotopupsettingsupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/details/auto_top_up`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(accountautotopupsettingsupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateAccountPayer(payer_id: number, updatepayerrequest: IUpdatePayerRequest, optionalToken?: string): Promise<IPayerInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const updatepayerrequestValid = new UpdatePayerRequest(updatepayerrequest);
        haveError.push(...updatepayerrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/account/payers/${payer_id}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatepayerrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
