import React, { FC } from 'react';
import { Divider } from 'antd';
import cn from 'classnames';

import { Icon, useIntl } from 'Common';
import theme from 'Lib/theme';

import cardImage from 'Lib/assets/images/card.png';
import card2xImage from 'Lib/assets/images/card@2x.png';

import s from './Banner.module.pcss';

interface BannerProps {
    desc: string;
    link?: string;
}

const Banner: FC<BannerProps> = ({
    desc,
    link,
}) => {
    const intl = useIntl();

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.rounded,
                theme.card.blue,
                s.card,
            )}
        >
            <img
                src={cardImage}
                srcSet={`${card2xImage} 2x`}
                className={s.img}
                alt="boxes"
            />
            <div className={s.text}>
                {desc}
            </div>
            {link && (
                <>
                    <Divider
                        className={cn(theme.divider.divider, s.divider)}
                    />
                    <a
                        href={link}
                        className={cn(
                            theme.link.link,
                            theme.link.white,
                            s.link,
                        )}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {intl.getMessage('details_button')}
                        <Icon className={s.arrow} icon="arrow" />
                    </a>
                </>
            )}
        </div>
    );
};

export default Banner;
