// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IUpdateSoleTrader {
    sole_accounting_email?: string;
    sole_legal_address: string;
    sole_post_address: string;
}

export default class UpdateSoleTrader {
    readonly _sole_accounting_email: string | undefined;

    /**
     * Description: Sole trader accounting email
     * Example: kartseva.elena@rambler.ru
     */
    get soleAccountingEmail(): string | undefined {
        return this._sole_accounting_email;
    }

    readonly _sole_legal_address: string;

    /**
     * Description: Sole trader legal address
     * Example: 123456, Россия, Москва, ул. Правды, д.11, корп.1, кв.11
     */
    get soleLegalAddress(): string {
        return this._sole_legal_address;
    }

    static soleLegalAddressValidate(soleLegalAddress: string): boolean {
        return typeof soleLegalAddress === 'string' && !!soleLegalAddress.trim();
    }

    readonly _sole_post_address: string;

    /**
     * Description: Sole trader address for correspondence
     * Example: 123456, Россия, Москва, ул. Правды, д.11, корп.1, кв.11
     */
    get solePostAddress(): string {
        return this._sole_post_address;
    }

    static solePostAddressValidate(solePostAddress: string): boolean {
        return typeof solePostAddress === 'string' && !!solePostAddress.trim();
    }

    constructor(props: IUpdateSoleTrader) {
        if (typeof props.sole_accounting_email === 'string') {
            this._sole_accounting_email = props.sole_accounting_email.trim();
        }
        this._sole_legal_address = props.sole_legal_address.trim();
        this._sole_post_address = props.sole_post_address.trim();
    }

    serialize(): IUpdateSoleTrader {
        const data: IUpdateSoleTrader = {
            sole_legal_address: this._sole_legal_address,
            sole_post_address: this._sole_post_address,
        };
        if (typeof this._sole_accounting_email !== 'undefined') {
            data.sole_accounting_email = this._sole_accounting_email;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            sole_legal_address: typeof this._sole_legal_address === 'string' && !!this._sole_legal_address.trim(),
            sole_post_address: typeof this._sole_post_address === 'string' && !!this._sole_post_address.trim(),
            sole_accounting_email: !this._sole_accounting_email ? true : typeof this._sole_accounting_email === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IUpdateSoleTrader): UpdateSoleTrader {
        return new UpdateSoleTrader(props);
    }

    readonly keys: { [key: string]: string } = {
        soleAccountingEmail: 'sole_accounting_email',
        soleLegalAddress: 'sole_legal_address',
        solePostAddress: 'sole_post_address',
        }
;

    mergeDeepWith(props: Partial<UpdateSoleTrader>): UpdateSoleTrader {
        const updateData: Partial<IUpdateSoleTrader> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof UpdateSoleTrader) => {
            const updateKey = this.keys[key] as keyof IUpdateSoleTrader;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IUpdateSoleTrader, keyof IUpdateSoleTrader>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new UpdateSoleTrader({ ...this.serialize(), ...updateData });
    }
}
