import React, { FC, useRef, useState } from 'react';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { useIntl, Icon } from 'Common';
import RecipientDeliverySettings from 'Entities/RecipientDeliverySettings';
import NotificationRecipient from 'Entities/NotificationRecipient';
import theme from 'Lib/theme';

import s from './Trigger.module.pcss';

interface RecipientsProps {
    recipients: NotificationRecipient[];
    recipientSettings: RecipientDeliverySettings[];
}

const Recipients: FC<RecipientsProps> = ({
    recipients,
    recipientSettings,
}) => {
    const intl = useIntl();
    const listRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLSpanElement>(null);
    const [showList, setShowList] = useState(false);

    let isDefault = false;
    let showDropdown = false;
    let labelText = '';
    const selectedRecipients = recipientSettings
        .filter((v) => v.deliverySms || v.deliveryEmail)
        .map((v) => {
            const data = recipients.find((r) => r.id === v.recipientId);
            if (data?.isDefault) {
                isDefault = true;
            }
            return { ...v, email: data?.email, default: !!data?.isDefault };
        });

    if (selectedRecipients.length > 1) {
        showDropdown = true;
        if (isDefault) {
            labelText = intl.getMessage('new_trigger_you_and_users', { value: selectedRecipients.length - 1 });
        } else {
            labelText = intl.getPlural('new_trigger_other_users', selectedRecipients.length);
        }
    } else if (selectedRecipients.length === 1) {
        if (isDefault) {
            labelText = intl.getMessage('you');
        } else {
            showDropdown = true;
            labelText = intl.getPlural('new_trigger_other_users', 1);
        }
    } else {
        labelText = intl.getMessage('new_trigger_noone');
    }

    const usersList = (
        <div className={cn(s.dropdown, theme.common.customScrollbar)} ref={listRef}>
            {selectedRecipients.map((r) => (
                <div key={r.email} className={s.item}>
                    <Icon icon="profile" className={s.icon} />
                    {r.default ? intl.getMessage('you') : r.email}
                </div>
            ))}
        </div>
    );

    if (showDropdown) {
        return (
            <div className={s.value}>
                <Dropdown
                    overlay={usersList}
                    placement="bottomLeft"
                    trigger={['click']}
                    onVisibleChange={(v: boolean) => setShowList(v)}
                    visible={showList}
                >
                    <span
                        className={cn(
                            theme.link.link,
                            theme.link.blue,
                        )}
                        ref={triggerRef}
                    >
                        {labelText}
                    </span>
                </Dropdown>
            </div>
        );
    }

    return (
        <div className={s.value}>
            {labelText}
        </div>
    );
};

export default Recipients;
