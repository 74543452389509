import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import cn from 'classnames';

import { useIntl, Input } from 'Common';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import Trigger from 'Entities/Trigger';
import NotificationRecipient from 'Entities/NotificationRecipient';
import { ITriggerCreate } from 'Entities/TriggerCreate';
import NewTrigger, { NewTriggerFormKeys } from 'Lib/helpers/newTrigger';

import TriggerNotifications from './TriggerNotifications';
import Submit from './Submit';
import s from '../NewTrigger.module.pcss';

interface DomainExpirationFormProps {
    trigger?: Trigger;
    type: TriggerRuleType;
    recipients: NotificationRecipient[];
    onSubmit: (
        keys: NewTriggerFormKeys[],
    ) => (values: ITriggerCreate, helpers: FormikHelpers<ITriggerCreate>) => Promise<void>;
}

const DomainExpirationForm: FC<DomainExpirationFormProps> = (
    { type, recipients, onSubmit, trigger },
) => {
    const intl = useIntl();

    return (
        <Formik
            initialValues={NewTrigger.getInitialValues(type, recipients, trigger)}
            onSubmit={onSubmit(['period_minutes'])}
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                errors,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={cn(s.group, s.group_input)}>
                        <Input
                            cloud
                            autoFocus
                            size="large"
                            placeholder={intl.getMessage('triggers_name')}
                            name="name"
                            type="text"
                            validate={Trigger.nameValidate}
                            error={!!errors.name}
                            value={values.name}
                            onChange={(e) => setFieldValue('name', e)}
                        />
                    </div>
                    <div className={s.group}>
                        <div className={s.text}>
                            <div>
                                {intl.getMessage('trigger_form_day_https')}
                            </div>
                            <Input
                                cloud
                                rightDesktop
                                name="value"
                                size="small"
                                type="number"
                                wrapperClassName={s.input}
                                suffix={intl.getMessage('day_short')}
                                error={!!errors.value}
                                validate={(e) => e !== '0'}
                                value={values.value!}
                                onChange={(e) => setFieldValue('value', e.slice(0, 6))}
                                onBlur={handleBlur}
                            />
                            <div>
                                {intl.getMessage('trigger_form_domain_expiration')}
                            </div>
                            <Input
                                cloud
                                name="value"
                                size="small"
                                type="url"
                                wrapperClassName={cn(s.input, s.domain)}
                                placeholder={intl.getMessage('triggers_domain')}
                                error={!!errors.url}
                                value={values.url!}
                                validate={(e) => !!e}
                                onChange={(e) => setFieldValue('url', e)}
                                onBlur={handleBlur}
                            />
                        </div>
                    </div>

                    <TriggerNotifications
                        value={values.recipient_settings}
                        handleChange={handleChange}
                        recipients={recipients}
                        setFieldValue={setFieldValue}
                    />

                    <Submit update={!!trigger} />
                </form>
            )}
        </Formik>
    );
};

export default DomainExpirationForm;
