import dnsApi from 'Apis/dns';

import DnsZone from 'Entities/DnsZone';
import DnsSettings from 'Entities/DnsSettings';
import DnsRecord from 'Entities/DnsRecord';

import { actionCreator } from '.';

export enum DnsActions {
    GetDnsZones = 'GetDnsZones',
    SaveDnsZones = 'SaveDnsZones',
    GetDnsSettings = 'GetDnsSettings',
    SaveDnsSettings = 'SaveDnsSettings',
    CreateDnsZone = 'CreateDnsZone',
    SaveDnsZone = 'SaveDnsZone',
    DeleteDnsZone = 'DeleteDnsZone',
    DeleteDnsZoneLocal = 'DeleteDnsZoneLocal',
    UpdateDnsZone = 'UpdateDnsZone',
    GetDnsRecords = 'GetDnsRecords',
    SaveDnsRecords = 'SaveDnsRecords',
    CreateDnsRecords = 'CreateDnsRecords',
    DeleteDnsRecord = 'DeleteDnsRecord',
    UpdateDnsRecord = 'UpdateDnsRecord',
}

const getDnsZones = actionCreator(DnsActions.GetDnsZones);
const saveDnsZones = actionCreator<DnsZone[]>(DnsActions.SaveDnsZones);

const getDnsSettings = actionCreator(DnsActions.GetDnsSettings);
const saveDnsSettings = actionCreator<DnsSettings>(DnsActions.SaveDnsSettings);

type CreateDnsZone = Parameters<typeof dnsApi.createDnsZone>;
const createDnsZone = actionCreator<CreateDnsZone>(DnsActions.CreateDnsZone);
const saveDnsZone = actionCreator<DnsZone>(DnsActions.SaveDnsZone);

type DeleteDnsZone = {
    tenantId: number;
    dnsZoneId: number;
};
const deleteDnsZone = actionCreator<DeleteDnsZone>(DnsActions.DeleteDnsZone);
const deleteDnsZoneLocal = actionCreator<DeleteDnsZone>(DnsActions.DeleteDnsZoneLocal);

type UpdateDnsZone = Parameters<typeof dnsApi.updateDnsZone>;
const updateDnsZone = actionCreator<UpdateDnsZone>(DnsActions.UpdateDnsZone);

type GetDnsRecords = Parameters<typeof dnsApi.getDnsRecords>;
const getDnsRecords = actionCreator<GetDnsRecords>(DnsActions.GetDnsRecords);
const saveDnsRecords = actionCreator<DnsRecord[]>(DnsActions.SaveDnsRecords);

type CreateDnsRecords = Parameters<typeof dnsApi.createDnsRecords>;
const createDnsRecords = actionCreator<CreateDnsRecords>(DnsActions.CreateDnsRecords);

type DeleteDnsRecord = Parameters<typeof dnsApi.deleteDnsRecord>;
const deleteDnsRecord = actionCreator<DeleteDnsRecord>(DnsActions.DeleteDnsRecord);

type UpdateDnsRecord = Parameters<typeof dnsApi.updateDnsRecord>;
const updateDnsRecord = actionCreator<UpdateDnsRecord>(DnsActions.UpdateDnsRecord);

export {
    getDnsZones,
    saveDnsZones,
    getDnsSettings,
    saveDnsSettings,
    createDnsZone,
    saveDnsZone,
    deleteDnsZone,
    deleteDnsZoneLocal,
    updateDnsZone,
    getDnsRecords,
    saveDnsRecords,
    createDnsRecords,
    deleteDnsRecord,
    updateDnsRecord,
};
