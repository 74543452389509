import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl } from 'Common';
import ExpenseSection from 'Entities/ExpenseSection';
import { getAccountTypeText } from 'Lib/helpers/translationHelper';
import { roundNumber } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

import s from '../Expenses.module.pcss';

interface ExpensesListProps {
    visibleList: boolean;
    expenses: ExpenseSection[];
}

const ExpensesList: FC<ExpensesListProps> = ({
    visibleList, expenses,
}) => {
    const intl = useIntl();

    return (
        <div className={cn(s.list, { [s.list_visible]: visibleList })}>
            {expenses.map(({
                sectionId, accountingItem, amount, softwareLicense,
            }) => (
                <div key={sectionId} className={s.row}>
                    <div className={theme.common.textOverflow}>
                        {softwareLicense || getAccountTypeText(intl, accountingItem)}
                    </div>
                    <div className={cn(s.value, theme.common.textOverflow)}>
                        {intl.getMessage('price_simple', { value: roundNumber(amount) })}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ExpensesList;
