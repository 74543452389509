import React, { FC, useState } from 'react';

import { Icon, IconType, Loader } from 'Common';

import styles from './Auth.module.pcss';

const SOCIAL_BUTTONS: { name: IconType; link: string }[] = [
    { name: 'social_vk', link: 'vk' },
    { name: 'social_yandex', link: 'yandex' },
];

interface SocialAuthProps {
    title?: string;
    addAccount?: boolean;
}

const SocialAuth: FC<SocialAuthProps> = ({ title, addAccount }) => {
    const [isSocial, setIsSocial] = useState('');

    const getSocialAuthLink = (social: string) => {
        if (addAccount) {
            return `/social/acc/${social}`;
        }

        return `/social/${social}`;
    };

    return (
        <div className={styles.auth__social}>
            {title && (
                <div className={styles['auth__social-title']}>
                    {title}
                </div>
            )}
            <div className={styles['auth__social-icons']}>
                {SOCIAL_BUTTONS.map((sb) => (
                    <a
                        href={getSocialAuthLink(sb.link)}
                        key={sb.link}
                        className={styles['auth__social-icon']}
                        onClick={() => setIsSocial(sb.link)}
                    >
                        {isSocial === sb.link ? (
                            <Loader circle mini />
                        ) : (
                            <Icon icon={sb.name} />
                        )}
                    </a>
                ))}
            </div>
        </div>
    );
};

export default SocialAuth;
