// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IConfiguration {
    bind_card_amount: number;
}

export default class Configuration {
    readonly _bind_card_amount: number;

    /**
     * Description: Money amount that will be charged and returned during card binding, RUB
     * Example: 11
     */
    get bindCardAmount(): number {
        return this._bind_card_amount;
    }

    static bindCardAmountValidate(bindCardAmount: number): boolean {
        return typeof bindCardAmount === 'number';
    }

    constructor(props: IConfiguration) {
        this._bind_card_amount = props.bind_card_amount;
    }

    serialize(): IConfiguration {
        const data: IConfiguration = {
            bind_card_amount: this._bind_card_amount,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            bind_card_amount: typeof this._bind_card_amount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IConfiguration): Configuration {
        return new Configuration(props);
    }

    readonly keys: { [key: string]: string } = {
        bindCardAmount: 'bind_card_amount',
        }
;

    mergeDeepWith(props: Partial<Configuration>): Configuration {
        const updateData: Partial<IConfiguration> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Configuration) => {
            const updateKey = this.keys[key] as keyof IConfiguration;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IConfiguration, keyof IConfiguration>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Configuration({ ...this.serialize(), ...updateData });
    }
}
