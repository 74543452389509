import SecurityActionSettingUpdate, { ISecurityActionSettingUpdate } from './SecurityActionSettingUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISecurityActionSettingsUpdateCodeSendRequest {
    settings: ISecurityActionSettingUpdate[];
}

export default class SecurityActionSettingsUpdateCodeSendRequest {
    readonly _settings: SecurityActionSettingUpdate[];

    /** */
    get settings(): SecurityActionSettingUpdate[] {
        return this._settings;
    }

    constructor(props: ISecurityActionSettingsUpdateCodeSendRequest) {
        this._settings = props.settings.map((p) => new SecurityActionSettingUpdate(p));
    }

    serialize(): ISecurityActionSettingsUpdateCodeSendRequest {
        const data: ISecurityActionSettingsUpdateCodeSendRequest = {
            settings: this._settings.map((p) => p.serialize()),
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            settings: this._settings.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISecurityActionSettingsUpdateCodeSendRequest): SecurityActionSettingsUpdateCodeSendRequest {
        return new SecurityActionSettingsUpdateCodeSendRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        settings: 'settings',
        }
;

    mergeDeepWith(props: Partial<SecurityActionSettingsUpdateCodeSendRequest>): SecurityActionSettingsUpdateCodeSendRequest {
        const updateData: Partial<ISecurityActionSettingsUpdateCodeSendRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SecurityActionSettingsUpdateCodeSendRequest) => {
            const updateKey = this.keys[key] as keyof ISecurityActionSettingsUpdateCodeSendRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISecurityActionSettingsUpdateCodeSendRequest, keyof ISecurityActionSettingsUpdateCodeSendRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SecurityActionSettingsUpdateCodeSendRequest({ ...this.serialize(), ...updateData });
    }
}
