import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FormikHelpers, FormikProps } from 'formik';
import { createSelector } from 'reselect';

import { FormModalLayout, useIntl, Input, PhoneInput, notifyError } from 'Common';
import { createNotificationRecipient, updateNotificationRecipient } from 'Actions/notifications';
import NotificationRecipientCreate, { INotificationRecipientCreate } from 'Entities/NotificationRecipientCreate';
import NotificationRecipient from 'Entities/NotificationRecipient';
import Error from 'Entities/Error';
import Profile from 'Entities/Profile';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { IPhoneInfoUpdate } from 'Entities/PhoneInfoUpdate';
import { AT_LEAST_ONE_REQUIRED, EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { emailValidate } from 'Lib/helpers/utils';
import theme from 'Lib/theme';
import { Store } from 'Store';

interface AddRecipientModalStoreProps {
    profile: Profile | null;
}

interface AddRecipientModalOwnProps {
    visible: boolean;
    handleClose: () => void;
    update?: NotificationRecipient;
}

type AddRecipientModalProps = AddRecipientModalStoreProps & AddRecipientModalOwnProps;

const AddRecipientModal: FC<AddRecipientModalProps> = ({
    visible,
    handleClose,
    update,
    profile,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const addRecipient = (
        values: INotificationRecipientCreate,
        { setSubmitting, setErrors }: FormikHelpers<INotificationRecipientCreate>,
    ) => {
        const ireq: INotificationRecipientCreate = {
            name: values.name,
        };
        if (values.email) {
            ireq.email = values.email;
        }
        if (values.phone?.calling_code !== '' && values.phone?.full_number !== '') {
            ireq.phone = values.phone;
        }
        const reqEnt = new NotificationRecipientCreate(ireq);
        const errors: any = {};
        const notValid = reqEnt.validate();
        if (notValid.length > 0) {
            notValid.forEach((v) => {
                errors[v] = EMPTY_FIELD_ERROR;
            });
        }
        if (reqEnt.email && !emailValidate(reqEnt.email)) {
            errors.email = EMPTY_FIELD_ERROR;
        }
        if ((!reqEnt.phone?.fullNumber || !reqEnt.phone?.callingCode) && !reqEnt.email) {
            errors.phone = AT_LEAST_ONE_REQUIRED;
            errors.email = AT_LEAST_ONE_REQUIRED;
        }
        if (Object.keys(errors).length > 0) {
            setSubmitting(false);
            setErrors(errors);
            return;
        }
        const checkError = (e: Error) => {
            if (e.fields) {
                const newErrors: any = {};
                Object.keys(e.fields).forEach((f) => {
                    newErrors[f] = apiErrorCodeTranslate(intl, e.fields[f][0]?.error_code);
                });
                setErrors(newErrors);
                setSubmitting(false);
                notifyError(apiErrorCodeTranslate(
                    intl,
                    e.errorCode,
                    { value: profile?.profileLimits.maxRecipientsCount || '' },
                ));
            }
        };
        const callback = {
            error: checkError,
            result: () => {
                handleClose();
            },
        };
        if (update) {
            dispatch(updateNotificationRecipient([update.id, reqEnt.serialize()], callback));
        } else {
            dispatch(createNotificationRecipient([reqEnt.serialize()], callback));
        }
    };

    const initialValues = update?.serialize() || {
        name: '',
        phone: { calling_code: '', full_number: '' },
        email: '',
    };

    if (!initialValues.phone) {
        initialValues.phone = { calling_code: '', full_number: '' };
    }

    if (!profile) {
        return null;
    }

    return (
        <FormModalLayout
            visible={visible}
            title={update
                ? intl.getMessage('notifications_change_recipient_title')
                : intl.getMessage('notifications_add_recipient_title')}
            buttonText={update
                ? intl.getMessage('save')
                : intl.getMessage('add')}
            handleClose={handleClose}
            initialValues={initialValues}
            handleSubmit={addRecipient}
        >
            {({
                values,
                errors,
                setFieldValue,
            }: FormikProps<INotificationRecipientCreate>) => (
                <>
                    <div className={theme.modal.desc}>
                        {intl.getMessage('notifications_add_recipient_desc', { value: profile?.profileLimits.maxRecipientsCount || '' })}
                    </div>
                    <div className={theme.modal.group}>
                        <Input
                            autoFocus
                            error={!!errors.name}
                            errorMessage={errors.name as string}
                            name="name"
                            onChange={(v) => setFieldValue('name', v)}
                            placeholder={intl.getMessage('name')}
                            size="large"
                            type="text"
                            value={values.name}
                            validate={NotificationRecipientCreate.nameValidate}
                        />
                    </div>
                    <div className={theme.modal.group}>
                        <PhoneInput
                            value={values.phone as IPhoneInfoUpdate}
                            setValue={(v) => setFieldValue('phone', v)}
                            error={!!errors.phone}
                            errorMessage={errors.phone as string}
                            validate={(e) => e.length > 0}
                        />
                    </div>
                    <div className={theme.modal.group}>
                        <Input
                            error={!!errors.email}
                            errorMessage={errors.email as string}
                            name="email"
                            onChange={(v) => setFieldValue('email', v)}
                            placeholder={intl.getMessage('email')}
                            size="large"
                            type="email"
                            value={values.email}
                            validate={emailValidate}
                        />
                    </div>
                </>
            )}
        </FormModalLayout>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([selectProfile], (profile) => ({ profile }));

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(AddRecipientModal);
