import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';

import { useIntl, Input, Button } from 'Common';
import theme from 'Lib/theme';

interface CodeInputProps {
    value: string | undefined;
    codeError: boolean;
    setCodeError: (e: boolean) => void;
    setValue: (value: string) => void;
    onSendAgain: () => void;
    className?: string;
    autoFocus?: boolean;
}
const TIME_LIMIT = 60;
const CodeInput: FC<CodeInputProps> = ({
    value,
    setValue,
    codeError,
    setCodeError,
    onSendAgain,
    className,
    autoFocus,
}) => {
    const [timer, setTimer] = useState(TIME_LIMIT);
    const intl = useIntl();
    useEffect(() => {
        let canUpdate = true;
        setTimeout(() => {
            if (timer !== 0 && canUpdate) {
                setTimer(timer - 1);
            }
        }, 1000);
        return () => {
            canUpdate = false;
        };
    }, [timer]);
    const onAgain = () => {
        setTimer(TIME_LIMIT);
        onSendAgain();
    };
    return (
        <Input
            autoFocus={autoFocus}
            name="code"
            autoComplete="one-time-code"
            placeholder="000000"
            size="large"
            className={className}
            value={value}
            onChange={(e) => {
                setCodeError(false);
                setValue(e);
            }}
            error={codeError}
            type="text"
            validate={(e) => !!e}
            suffix={timer > 0 ? (
                <span className={cn(theme.color.gray, theme.text.f14)}>
                    {intl.getMessage('send_after', { sec: timer })}
                </span>
            ) : (
                <Button
                    size="small"
                    type="link"
                    htmlType="submit"
                    className={theme.button.suffix}
                    onClick={onAgain}
                >
                    {intl.getMessage('send_again')}
                </Button>
            )}
        />
    );
};
export default CodeInput;
