import React, { FC } from 'react';
import cn from 'classnames';

import theme from 'Lib/theme';

interface DotsProps {
    className?: string;
}

const Dots: FC<DotsProps> = ({ className }) => (
    <div className={cn(theme.loading.dots, className)}>
        <div className={theme.loading.dot} />
        <div className={theme.loading.dot} />
        <div className={theme.loading.dot} />
    </div>
);

export default Dots;
