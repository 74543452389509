import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import MarkNotificationsSeenRequest, { IMarkNotificationsSeenRequest } from 'Entities/MarkNotificationsSeenRequest';
import Notification, { INotification } from 'Entities/Notification';
import NotificationRecipient, { INotificationRecipient } from 'Entities/NotificationRecipient';
import NotificationRecipientCreate, { INotificationRecipientCreate } from 'Entities/NotificationRecipientCreate';
import NotificationRecipientUpdate, { INotificationRecipientUpdate } from 'Entities/NotificationRecipientUpdate';
import NotificationSettings, { INotificationSettings } from 'Entities/NotificationSettings';
import NotificationSettingsUpdate, { INotificationSettingsUpdate } from 'Entities/NotificationSettingsUpdate';
import ServersEventsRequest, { IServersEventsRequest } from 'Entities/ServersEventsRequest';
import Trigger, { ITrigger } from 'Entities/Trigger';
import TriggerCreate, { ITriggerCreate } from 'Entities/TriggerCreate';
import TriggerUpdate, { ITriggerUpdate } from 'Entities/TriggerUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class NotificationsApi {
    static async createNotificationRecipient(notificationrecipientcreate: INotificationRecipientCreate, optionalToken?: string): Promise<INotificationRecipient | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const notificationrecipientcreateValid = new NotificationRecipientCreate(notificationrecipientcreate);
        haveError.push(...notificationrecipientcreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/notifications/recipients`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(notificationrecipientcreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async createTriggerRule(triggercreate: ITriggerCreate, optionalToken?: string): Promise<ITrigger | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const triggercreateValid = new TriggerCreate(triggercreate);
        haveError.push(...triggercreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/notifications/triggers`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(triggercreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteNotificationRecipient(recipient_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/notifications/recipients/${recipient_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getNotificationRecipient(recipient_id: number, optionalToken?: string): Promise<INotificationRecipient | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/notifications/recipients/${recipient_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getNotificationRecipients(optionalToken?: string): Promise<INotificationRecipient[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/notifications/recipients`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getNotifications(is_seen?: boolean, offset?: number, limit?: number, optionalToken?: string): Promise<INotification[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            is_seen: is_seen,
            offset: offset,
            limit: limit,
        }
        return await fetch(`/api/v1/notifications?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getNotificationsSettings(recipient_id: number, optionalToken?: string): Promise<INotificationSettings | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/notifications/recipients/${recipient_id}/settings`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServersNotifications(serverseventsrequest: IServersEventsRequest, optionalToken?: string): Promise<INotification[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverseventsrequestValid = new ServersEventsRequest(serverseventsrequest);
        haveError.push(...serverseventsrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/notifications/servers_events`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverseventsrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getTriggerRules(optionalToken?: string): Promise<ITrigger[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/notifications/triggers`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async markNotificationSeen(marknotificationsseenrequest: IMarkNotificationsSeenRequest, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const marknotificationsseenrequestValid = new MarkNotificationsSeenRequest(marknotificationsseenrequest);
        haveError.push(...marknotificationsseenrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/notifications`, {
            method: 'PUT',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(marknotificationsseenrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async removeTriggerRule(rule_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/notifications/triggers/${rule_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async unsubscribeFromLearningCurve(encrypted_email: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/unsubscribe_learning_curve/${encrypted_email}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async unsubscribeFromPromoAndDiscounts(encrypted_email: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/unsubscribe_promo_discounts/${encrypted_email}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async unsubscribeNotificationRecipient(code: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            code: code,
        }
        return await fetch(`/api/v1/unsubscribe_notification_recipient?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateNotificationRecipient(recipient_id: number, notificationrecipientupdate: INotificationRecipientUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const notificationrecipientupdateValid = new NotificationRecipientUpdate(notificationrecipientupdate);
        haveError.push(...notificationrecipientupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/notifications/recipients/${recipient_id}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(notificationrecipientupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateNotificationSettings(recipient_id: number, notificationsettingsupdate: INotificationSettingsUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const notificationsettingsupdateValid = new NotificationSettingsUpdate(notificationsettingsupdate);
        haveError.push(...notificationsettingsupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/notifications/recipients/${recipient_id}/settings`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(notificationsettingsupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateTriggerRule(rule_id: number, triggerupdate: ITriggerUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const triggerupdateValid = new TriggerUpdate(triggerupdate);
        haveError.push(...triggerupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/notifications/triggers/${rule_id}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(triggerupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
