// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITicketPostAttachment {
    download_link?: string;
    filename: string;
    id: number;
}

export default class TicketPostAttachment {
    readonly _download_link: string | undefined;

    /**
     * Description: Attachment download link
     * Example: https://panel.serveroid.com/documents/a364001059ef15eb21e5dcb9a94ab1b9/ticket-attachment
     */
    get downloadLink(): string | undefined {
        return this._download_link;
    }

    readonly _filename: string;

    /** */
    get filename(): string {
        return this._filename;
    }

    static filenameValidate(filename: string): boolean {
        return typeof filename === 'string' && !!filename.trim();
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 4523
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    constructor(props: ITicketPostAttachment) {
        if (typeof props.download_link === 'string') {
            this._download_link = props.download_link.trim();
        }
        this._filename = props.filename.trim();
        this._id = props.id;
    }

    serialize(): ITicketPostAttachment {
        const data: ITicketPostAttachment = {
            filename: this._filename,
            id: this._id,
        };
        if (typeof this._download_link !== 'undefined') {
            data.download_link = this._download_link;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            filename: typeof this._filename === 'string' && !!this._filename.trim(),
            download_link: !this._download_link ? true : typeof this._download_link === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITicketPostAttachment): TicketPostAttachment {
        return new TicketPostAttachment(props);
    }

    readonly keys: { [key: string]: string } = {
        downloadLink: 'download_link',
        filename: 'filename',
        id: 'id',
        }
;

    mergeDeepWith(props: Partial<TicketPostAttachment>): TicketPostAttachment {
        const updateData: Partial<ITicketPostAttachment> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TicketPostAttachment) => {
            const updateKey = this.keys[key] as keyof ITicketPostAttachment;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITicketPostAttachment, keyof ITicketPostAttachment>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TicketPostAttachment({ ...this.serialize(), ...updateData });
    }
}
