import React, { FC } from 'react';

import { useIntl, DeleteModalLayout } from 'Common';
import theme from 'Lib/theme';

interface ModalIpRemoveProps {
    visible: boolean;
    onClose: () => void;
    onDelete: () => void;
}

const ModalIpRemove: FC<ModalIpRemoveProps> = ({ visible, onClose, onDelete }) => {
    const intl = useIntl();

    return (
        <DeleteModalLayout
            visible={visible}
            title={intl.getMessage('server_ip_modal_title')}
            handleClose={onClose}
            handleDelete={onDelete}
        >
            <div className={theme.modal.desc}>
                {intl.getMessage('server_ip_modal_text')}
            </div>
        </DeleteModalLayout>
    );
};

export default ModalIpRemove;
