import FromTo, { IFromTo } from './FromTo';
import { ResourceUsageType } from './ResourceUsageType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerUpgradeRecommendation {
    cpu_cores?: IFromTo;
    disk_gib?: IFromTo;
    exceeded_limit_resources: ResourceUsageType[];
    memory_mib?: IFromTo;
    monthly_price: IFromTo;
    server_id: number;
    tariff_id?: IFromTo;
}

export default class ServerUpgradeRecommendation {
    readonly _cpu_cores: FromTo | undefined;

    get cpuCores(): FromTo | undefined {
        return this._cpu_cores;
    }

    readonly _disk_gib: FromTo | undefined;

    get diskGib(): FromTo | undefined {
        return this._disk_gib;
    }

    readonly _exceeded_limit_resources: ResourceUsageType[];

    /** */
    get exceededLimitResources(): ResourceUsageType[] {
        return this._exceeded_limit_resources;
    }

    static exceededLimitResourcesValidate(exceededLimitResources: ResourceUsageType[]): boolean {
        return exceededLimitResources.reduce<boolean>((result, p, i) => result && Object.keys(ResourceUsageType).includes(exceededLimitResources[i]), true);
    }

    readonly _memory_mib: FromTo | undefined;

    get memoryMib(): FromTo | undefined {
        return this._memory_mib;
    }

    readonly _monthly_price: FromTo;

    get monthlyPrice(): FromTo {
        return this._monthly_price;
    }

    readonly _server_id: number;

    /**
     * Description: Identifier of server this recommendation relates to
     * Example: 51671
     */
    get serverId(): number {
        return this._server_id;
    }

    static serverIdValidate(serverId: number): boolean {
        return typeof serverId === 'number';
    }

    readonly _tariff_id: FromTo | undefined;

    get tariffId(): FromTo | undefined {
        return this._tariff_id;
    }

    constructor(props: IServerUpgradeRecommendation) {
        if (props.cpu_cores) {
            this._cpu_cores = new FromTo(props.cpu_cores);
        }
        if (props.disk_gib) {
            this._disk_gib = new FromTo(props.disk_gib);
        }
        this._exceeded_limit_resources = props.exceeded_limit_resources;
        if (props.memory_mib) {
            this._memory_mib = new FromTo(props.memory_mib);
        }
        this._monthly_price = new FromTo(props.monthly_price);
        this._server_id = props.server_id;
        if (props.tariff_id) {
            this._tariff_id = new FromTo(props.tariff_id);
        }
    }

    serialize(): IServerUpgradeRecommendation {
        const data: IServerUpgradeRecommendation = {
            exceeded_limit_resources: this._exceeded_limit_resources,
            monthly_price: this._monthly_price.serialize(),
            server_id: this._server_id,
        };
        if (typeof this._cpu_cores !== 'undefined') {
            data.cpu_cores = this._cpu_cores.serialize();
        }
        if (typeof this._disk_gib !== 'undefined') {
            data.disk_gib = this._disk_gib.serialize();
        }
        if (typeof this._memory_mib !== 'undefined') {
            data.memory_mib = this._memory_mib.serialize();
        }
        if (typeof this._tariff_id !== 'undefined') {
            data.tariff_id = this._tariff_id.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            server_id: typeof this._server_id === 'number',
            cpu_cores: !this._cpu_cores ? true : this._cpu_cores.validate().length === 0,
            disk_gib: !this._disk_gib ? true : this._disk_gib.validate().length === 0,
            memory_mib: !this._memory_mib ? true : this._memory_mib.validate().length === 0,
            tariff_id: !this._tariff_id ? true : this._tariff_id.validate().length === 0,
            monthly_price: this._monthly_price.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerUpgradeRecommendation): ServerUpgradeRecommendation {
        return new ServerUpgradeRecommendation(props);
    }

    readonly keys: { [key: string]: string } = {
        cpuCores: 'cpu_cores',
        diskGib: 'disk_gib',
        exceededLimitResources: 'exceeded_limit_resources',
        memoryMib: 'memory_mib',
        monthlyPrice: 'monthly_price',
        serverId: 'server_id',
        tariffId: 'tariff_id',
        }
;

    mergeDeepWith(props: Partial<ServerUpgradeRecommendation>): ServerUpgradeRecommendation {
        const updateData: Partial<IServerUpgradeRecommendation> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerUpgradeRecommendation) => {
            const updateKey = this.keys[key] as keyof IServerUpgradeRecommendation;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerUpgradeRecommendation, keyof IServerUpgradeRecommendation>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerUpgradeRecommendation({ ...this.serialize(), ...updateData });
    }
}
