import React, { FC } from 'react';
import cn from 'classnames';
import { Icon, useIntl } from 'Common';
import theme from 'Lib/theme';

interface DiffProps {
    value: number;
    diff?: number;
}

const Diff: FC<DiffProps> = ({ value, diff }) => {
    const intl = useIntl();

    return (
        <div className={theme.score.value}>
            <span className={theme.score.valueInner}>
                {intl.getMessage('price_simple', { value })}
            </span>
            {diff ? (
                <div className={cn(
                    theme.score.diff,
                    { [theme.score.diff_negative]:
                        diff < 0 },
                    { [theme.score.diff_up]:
                        diff > 0 },
                    { [theme.score.diff_positive]:
                        diff > 0 },
                )}
                >
                    {diff}%
                    <Icon
                        className={theme.score.diffIcon}
                        icon="arrow_down"
                    />
                </div>
            ) : null}
        </div>
    );
};

export default Diff;
