import { DnsRecordType } from './DnsRecordType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsRecordCreate {
    data: string;
    host: string;
    port?: number;
    priority?: number;
    ttl_seconds: number;
    type: DnsRecordType;
    weight?: number;
}

export default class DnsRecordCreate {
    readonly _data: string;

    /**
     * Description: Points to
     * Example: xmpp-server.l.google.com
     */
    get data(): string {
        return this._data;
    }

    static dataValidate(data: string): boolean {
        return typeof data === 'string' && !!data.trim();
    }

    readonly _host: string;

    /**
     * Description: Host or @ for root
     * Example: @
     */
    get host(): string {
        return this._host;
    }

    static hostValidate(host: string): boolean {
        return typeof host === 'string' && !!host.trim();
    }

    readonly _port: number | undefined;

    /**
     * Description: Port. Only for SRV record type
     * Example: 5269
     */
    get port(): number | undefined {
        return this._port;
    }

    static get portMinValue() {
        return 0;
    }

    static get portMaxValue() {
        return 65535;
    }

    static portValidate(port: number | undefined): boolean {
        return !port ? true : ((port >= 0) && (port <= 65535));
    }

    readonly _priority: number | undefined;

    /** */
    get priority(): number | undefined {
        return this._priority;
    }

    static get priorityMinValue() {
        return 0;
    }

    static get priorityMaxValue() {
        return 65535;
    }

    static priorityValidate(priority: number | undefined): boolean {
        return !priority ? true : ((priority >= 0) && (priority <= 65535));
    }

    readonly _ttl_seconds: number;

    /**
     * Description: Ttl in seconds
     * Example: 3600
     */
    get ttlSeconds(): number {
        return this._ttl_seconds;
    }

    static get ttlSecondsMinValue() {
        return 120;
    }

    static get ttlSecondsMaxValue() {
        return 864000;
    }

    static ttlSecondsValidate(ttlSeconds: number): boolean {
        return ttlSeconds >= 120 && ttlSeconds <= 864000;
    }

    readonly _type: DnsRecordType;

    get type(): DnsRecordType {
        return this._type;
    }

    static typeValidate(type: DnsRecordType): boolean {
        return Object.keys(DnsRecordType).includes(type);
    }

    readonly _weight: number | undefined;

    /** */
    get weight(): number | undefined {
        return this._weight;
    }

    static get weightMinValue() {
        return 0;
    }

    static get weightMaxValue() {
        return 65535;
    }

    static weightValidate(weight: number | undefined): boolean {
        return !weight ? true : ((weight >= 0) && (weight <= 65535));
    }

    constructor(props: IDnsRecordCreate) {
        this._data = props.data.trim();
        this._host = props.host.trim();
        if (typeof props.port === 'number') {
            this._port = props.port;
        }
        if (typeof props.priority === 'number') {
            this._priority = props.priority;
        }
        this._ttl_seconds = props.ttl_seconds;
        this._type = props.type;
        if (typeof props.weight === 'number') {
            this._weight = props.weight;
        }
    }

    serialize(): IDnsRecordCreate {
        const data: IDnsRecordCreate = {
            data: this._data,
            host: this._host,
            ttl_seconds: this._ttl_seconds,
            type: this._type,
        };
        if (typeof this._port !== 'undefined') {
            data.port = this._port;
        }
        if (typeof this._priority !== 'undefined') {
            data.priority = this._priority;
        }
        if (typeof this._weight !== 'undefined') {
            data.weight = this._weight;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            host: typeof this._host === 'string' && !!this._host.trim(),
            data: typeof this._data === 'string' && !!this._data.trim(),
            ttl_seconds: this._ttl_seconds >= 120 && this._ttl_seconds <= 864000,
            priority: !this._priority ? true : ((this._priority >= 0) && (this._priority <= 65535)),
            weight: !this._weight ? true : ((this._weight >= 0) && (this._weight <= 65535)),
            port: !this._port ? true : ((this._port >= 0) && (this._port <= 65535)),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsRecordCreate): DnsRecordCreate {
        return new DnsRecordCreate(props);
    }

    readonly keys: { [key: string]: string } = {
        data: 'data',
        host: 'host',
        port: 'port',
        priority: 'priority',
        ttlSeconds: 'ttl_seconds',
        type: 'type',
        weight: 'weight',
        }
;

    mergeDeepWith(props: Partial<DnsRecordCreate>): DnsRecordCreate {
        const updateData: Partial<IDnsRecordCreate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsRecordCreate) => {
            const updateKey = this.keys[key] as keyof IDnsRecordCreate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsRecordCreate, keyof IDnsRecordCreate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsRecordCreate({ ...this.serialize(), ...updateData });
    }
}
