import React, { FC, useContext, useState } from 'react';
import cn from 'classnames';

import { useIntl, Icon } from 'Common';
import { SERVER_SORT, serverSortTranslate } from 'Lib/helpers/translationHelper';
import { ServerListContext } from 'Components/ServersList/factories';
import { Dropdown } from 'antd';

import s from './SortDropdown.module.pcss';

interface SortDropdownProps {
    transparent?: boolean;
    modalMode?: boolean;
}

const SortDropdown: FC<SortDropdownProps> = React.memo(({
    modalMode,
}) => {
    const { sortType, onSortTypeChange, onReverseSort } = useContext(ServerListContext);
    const intl = useIntl();
    const initialSortType = sortType.sortType || SERVER_SORT.BY_CREATED;
    const [visibleDropdown, setVisibleDropdown] = useState(false);
    const [activeSort, setActiveSort] = useState(initialSortType);

    const handleSort = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();
        onReverseSort();
    };

    const handleChange = (type: SERVER_SORT) => {
        if (type === activeSort) {
            onReverseSort();
        } else {
            setActiveSort(type);
            onSortTypeChange(type);
        }
    };

    const optionList = (
        <div className={s.dropdownOptionWrapper}>
            {Object.values(SERVER_SORT).map((type) => (
                <div
                    key={type}
                    className={cn(s.dropdownOption, { [s.active]: type === activeSort })}
                    onClick={() => handleChange(type)}
                >
                    <div>{serverSortTranslate(intl, type)}</div>
                    <div onClick={handleSort} className={cn(s.sortArrowWrapper)}>
                        <Icon className={cn(s.sortArrow, { [s.reverseFilter]: sortType.reverseSort })} icon="arrow_middle" />
                    </div>
                </div>
            ))}
        </div>
    );
    return (
        <Dropdown
            overlay={optionList}
            placement="bottomLeft"
            visible={visibleDropdown}
            trigger={['click']}
            className={cn({ [s.modalMode]: modalMode }, s.sortDropdown)}
            onVisibleChange={() => setVisibleDropdown(!visibleDropdown)}
        >
            <div>
                {visibleDropdown ? (
                    <div className={cn(s.sortDropdownButton, s.visible)}>
                        <span className={s.text}>{serverSortTranslate(intl, activeSort as SERVER_SORT)}</span>
                        <Icon icon="down" className={s.arrow} />
                    </div>
                ) : (
                    <div
                        className={s.sortDropdownButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            setVisibleDropdown(true);
                        }}
                    >
                        <span className={s.text}>{serverSortTranslate(intl, activeSort as SERVER_SORT)}</span>
                        <Icon icon="down" className={s.arrow} />
                    </div>
                )}
            </div>
        </Dropdown>

    );
});

export default SortDropdown;
