import { Action } from 'store/actions';
import {
    ServerActions,
    saveServerList,
    saveServerTenantList,
    saveServer,
    deleteServerLocal,
} from 'Actions/server';
import Server from 'Entities/Server';

import { ServerStore, serverInitialState as initialState } from '../initialStates';

const reducer = (state: ServerStore = initialState, action: Action): ServerStore => {
    switch (action.type as ServerActions) {
        case ServerActions.SaveServerList: {
            const { payload } = action as ReturnType<typeof saveServerList>;
            const serv = new Map<number, Server>();
            payload.forEach((s) => {
                serv.set(s.id, s);
            });
            return serv;
        }
        case ServerActions.SaveServerTenantList: {
            const { payload } = action as ReturnType<typeof saveServerTenantList>;
            const servers = new Map<number, Server>();
            payload.forEach((s) => {
                servers.set(s.id, s);
            });
            return new Map<number, Server>([...servers.entries(), ...state.entries()]);
        }
        case ServerActions.SaveServer: {
            const { payload } = action as ReturnType<typeof saveServer>;
            const serv = new Map<number, Server>(state!);
            serv.set(payload.id, payload);
            return serv;
        }
        case ServerActions.DeleteServerLocal: {
            const { payload } = action as ReturnType<typeof deleteServerLocal>;
            const serv = new Map<number, Server>(state!);
            serv.delete(payload);
            return serv;
        }
        default:
            return state;
    }
};

export default reducer;
