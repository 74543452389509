// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAddClientReviewKeyRequest {
    review_key?: string;
    review_rating?: number;
}

export default class AddClientReviewKeyRequest {
    readonly _review_key: string | undefined;

    /** */
    get reviewKey(): string | undefined {
        return this._review_key;
    }

    readonly _review_rating: number | undefined;

    /** */
    get reviewRating(): number | undefined {
        return this._review_rating;
    }

    constructor(props: IAddClientReviewKeyRequest) {
        if (typeof props.review_key === 'string') {
            this._review_key = props.review_key.trim();
        }
        if (typeof props.review_rating === 'number') {
            this._review_rating = props.review_rating;
        }
    }

    serialize(): IAddClientReviewKeyRequest {
        const data: IAddClientReviewKeyRequest = {
        };
        if (typeof this._review_key !== 'undefined') {
            data.review_key = this._review_key;
        }
        if (typeof this._review_rating !== 'undefined') {
            data.review_rating = this._review_rating;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            review_key: !this._review_key ? true : typeof this._review_key === 'string',
            review_rating: !this._review_rating ? true : typeof this._review_rating === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAddClientReviewKeyRequest): AddClientReviewKeyRequest {
        return new AddClientReviewKeyRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        reviewKey: 'review_key',
        reviewRating: 'review_rating',
        }
;

    mergeDeepWith(props: Partial<AddClientReviewKeyRequest>): AddClientReviewKeyRequest {
        const updateData: Partial<IAddClientReviewKeyRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AddClientReviewKeyRequest) => {
            const updateKey = this.keys[key] as keyof IAddClientReviewKeyRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAddClientReviewKeyRequest, keyof IAddClientReviewKeyRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AddClientReviewKeyRequest({ ...this.serialize(), ...updateData });
    }
}
