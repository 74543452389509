import DnsRecordCreate, { IDnsRecordCreate } from './DnsRecordCreate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IDnsRecordCreateList {
    records: IDnsRecordCreate[];
    rewrite: boolean;
}

export default class DnsRecordCreateList {
    readonly _records: DnsRecordCreate[];

    /** */
    get records(): DnsRecordCreate[] {
        return this._records;
    }

    readonly _rewrite: boolean;

    /** */
    get rewrite(): boolean {
        return this._rewrite;
    }

    static rewriteValidate(rewrite: boolean): boolean {
        return typeof rewrite === 'boolean';
    }

    constructor(props: IDnsRecordCreateList) {
        this._records = props.records.map((p) => new DnsRecordCreate(p));
        this._rewrite = props.rewrite;
    }

    serialize(): IDnsRecordCreateList {
        const data: IDnsRecordCreateList = {
            records: this._records.map((p) => p.serialize()),
            rewrite: this._rewrite,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            records: this._records.reduce((result, p) => result && p.validate().length === 0, true),
            rewrite: typeof this._rewrite === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IDnsRecordCreateList): DnsRecordCreateList {
        return new DnsRecordCreateList(props);
    }

    readonly keys: { [key: string]: string } = {
        records: 'records',
        rewrite: 'rewrite',
        }
;

    mergeDeepWith(props: Partial<DnsRecordCreateList>): DnsRecordCreateList {
        const updateData: Partial<IDnsRecordCreateList> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof DnsRecordCreateList) => {
            const updateKey = this.keys[key] as keyof IDnsRecordCreateList;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IDnsRecordCreateList, keyof IDnsRecordCreateList>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new DnsRecordCreateList({ ...this.serialize(), ...updateData });
    }
}
