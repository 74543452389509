import serverApi from 'Apis/servers';
import ServerStateEvent from 'Entities/ServerStateEvent';

import { actionCreator } from '.';

export enum ServerStateEventsActions {
    GetServerStateEventsList = 'GetServerStateEventsList',
    SaveServerStateEvents = 'SaveServerStateEvents',
    ClearStore = 'ClearStore',
}

type GetServerStateEventList = Parameters<typeof serverApi.getServerStateEvents>;
const getServerStateEventsList = actionCreator<GetServerStateEventList>(
    ServerStateEventsActions.GetServerStateEventsList,
);
const saveServerStateEventsList = actionCreator<ServerStateEvent[]>(
    ServerStateEventsActions.SaveServerStateEvents,
);
const clearStore = actionCreator(ServerStateEventsActions.ClearStore);

export {
    getServerStateEventsList,
    saveServerStateEventsList,
    clearStore,
};
