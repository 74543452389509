import React, { FC } from 'react';

import { useIntl, Button } from 'Common';

import s from '../Form.module.pcss';

interface ButtonProps {
    onClose: () => void;
    isEdited: boolean;
}
const Buttons: FC<ButtonProps> = ({ onClose, isEdited }) => {
    const intl = useIntl();

    return (
        <div className={s.buttonContainer}>
            <Button
                type="primary"
                size="medium"
                htmlType="submit"
                inGroup
            >
                {isEdited ? (
                    intl.getMessage('save_changes')
                ) : (
                    intl.getMessage('add')
                )}
            </Button>
            <Button
                type="link"
                size="medium"
                onClick={onClose}
            >
                {intl.getMessage('cancel')}
            </Button>
        </div>
    );
};

export default Buttons;
