import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';

import { updateServerPublicKeys } from 'Actions/server';
import { useIntl, Checkbox, Button, CodeInput } from 'Common';
import PublicKey from 'Entities/PublicKey';
import { SecurityActionType } from 'Entities/SecurityActionType';
import Server from 'Entities/Server';
import ServerPublicKeysUpdate, { IServerPublicKeysUpdate } from 'Entities/ServerPublicKeysUpdate';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { useEnter, useEscape, useSecurityActions } from 'Lib/hooks/hooks';
import theme from 'Lib/theme';

interface KeysFormProps {
    tenantPublicKeys: PublicKey[];
    changed: boolean;
    setChanged: (value: boolean) => void;
    server: Server;
}
type FormProps = Omit<IServerPublicKeysUpdate, 'public_keys'> & { public_keys: Set<number> };

const KeysForm: FC<KeysFormProps> = ({ tenantPublicKeys, server, changed, setChanged }) => {
    const intl = useIntl();
    const { publicKeys: serverKeys, tenantId, id: serverId } = server;
    const dispatch = useDispatch();
    const {
        sendConfirmationCode,
        shouldConfirmAction,
        codeSent,
        deliveryMessage,
        setCodeSent,
    } = useSecurityActions(SecurityActionType.SERVER_PUBLIC_KEY);

    const handleChangeReset = () => {
        setCodeSent(false);
        setChanged(false);
    };

    const onSubmit = (
        values: FormProps,
        { setFieldError, setSubmitting }: FormikHelpers<FormProps>,
    ) => {
        if (!changed) {
            setSubmitting(false);
            return;
        }
        const keys = Array.from(values.public_keys);
        const reqEnt = new ServerPublicKeysUpdate({ ...values, public_keys: keys });
        if (!shouldConfirmAction) {
            const req = reqEnt.serialize();
            delete req.security_code;
            dispatch(updateServerPublicKeys([tenantId, serverId, req], {
                result: () => {
                    handleChangeReset();
                    setSubmitting(false);
                },
            }));
            return;
        }
        if (!codeSent) {
            sendConfirmationCode({ tenantId, serverId });
        } else {
            if (!reqEnt.securityCode) {
                setFieldError('security_code', EMPTY_FIELD_ERROR);
                setSubmitting(false);
                return;
            }
            dispatch(updateServerPublicKeys([tenantId, serverId, reqEnt.serialize()], {
                error: () => setFieldError('security_code', EMPTY_FIELD_ERROR),
                result: () => {
                    handleChangeReset();
                    setSubmitting(false);
                },
            }));
        }
    };

    const initialValues: FormProps = { public_keys: new Set(serverKeys), security_code: undefined };

    const getList = () => {
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                onReset={handleChangeReset}
            >
                {({
                    values,
                    handleSubmit,
                    handleReset,
                    setFieldValue,
                    setFieldError,
                    isSubmitting,
                    errors,
                }) => {
                    /* eslint-disable react-hooks/rules-of-hooks */
                    useEnter(handleSubmit, [handleSubmit]);
                    useEscape(handleReset);
                    /* eslint-enable react-hooks/rules-of-hooks */
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <div className={theme.keysForm.list}>
                                {tenantPublicKeys.map(({ id, title, type, ownerUserName }) => (
                                    <label
                                        key={id}
                                        htmlFor={String(id)}
                                        className={theme.keysForm.key}
                                    >
                                        <div className={theme.keysForm.input}>
                                            <Checkbox
                                                id={String(id)}
                                                name="public_keys"
                                                handleChange={() => {
                                                    const newKeys = new Set(values.public_keys);
                                                    if (values.public_keys.has(id)) {
                                                        newKeys.delete(id);
                                                    } else {
                                                        newKeys.add(id);
                                                    }
                                                    setFieldValue('public_keys', newKeys);
                                                    setChanged(true);
                                                }}
                                                checked={values.public_keys.has(id)}
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className={theme.keysForm.wrap}>
                                            <div className={theme.keysForm.title}>
                                                {title}
                                            </div>
                                            {ownerUserName && (
                                                <div className={theme.keysForm.email}>
                                                    {ownerUserName}
                                                </div>
                                            )}
                                        </div>
                                        <div className={theme.keysForm.type}>
                                            {type}
                                        </div>
                                    </label>
                                ))}
                            </div>
                            {(changed && codeSent && deliveryMessage) && (
                                <div className={theme.keysForm.code}>
                                    <div className={theme.keysForm.desc}>
                                        {deliveryMessage}
                                    </div>
                                    <CodeInput
                                        value={values.security_code}
                                        setValue={(e) => setFieldValue('security_code', e)}
                                        codeError={!!errors.security_code}
                                        setCodeError={(e) => setFieldError('security_code', e ? EMPTY_FIELD_ERROR : '')}
                                        onSendAgain={
                                            () => sendConfirmationCode({ tenantId, serverId })
                                        }
                                    />
                                </div>
                            )}
                            {changed && (
                                <div className={theme.keysForm.actions}>
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        size="medium"
                                        inGroup
                                    >
                                        {intl.getMessage('apply')}
                                    </Button>
                                    <Button
                                        type="link"
                                        size="medium"
                                        onClick={handleReset}
                                    >
                                        {intl.getMessage('cancel')}
                                    </Button>
                                </div>
                            )}
                        </form>
                    );
                }}
            </Formik>
        );
    };

    return getList();
};

export default KeysForm;
