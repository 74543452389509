import React, { FC } from 'react';
import cn from 'classnames';

import theme from 'Lib/theme';

import s from './Skeleton.module.pcss';

const Skeleton: FC = () => {
    const smallGroup = (
        <>
            <div className={theme.skeleton.dark} />
            <div className={theme.skeleton.dark} />
            <div className={theme.skeleton.dark} />
        </>
    );

    const mediumGroup = (
        <>
            <div className={theme.skeleton.dark} />
            <div className={theme.skeleton.dark} />
            <div className={theme.skeleton.dark} />
            <div className={theme.skeleton.dark} />
        </>
    );

    return (
        <>
            <div className={s.top}>
                {smallGroup}
            </div>
            <div className={s.group}>
                <div className={cn(theme.skeleton.dark, theme.skeleton.dark_button)} />
            </div>
            <div className={s.group}>
                {mediumGroup}
            </div>
            <div className={s.group}>
                {mediumGroup}
            </div>
            <div className={s.group}>
                {smallGroup}
            </div>
            <div className={s.group}>
                {smallGroup}
            </div>
            <div className={s.group}>
                {smallGroup}
            </div>
        </>
    );
};

export default Skeleton;
