import React, { FC, useState } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import cn from 'classnames';

import Tenant from 'Entities/Tenant';
import Server from 'Entities/Server';
import TenantUser from 'Entities/TenantUser';
import OperationProgress from 'Entities/OperationProgress';
import { Store } from 'Store';
import theme from 'Lib/theme';

import Info from './Info';
import List from './List';
import s from './Project.module.pcss';

interface ProjectOwnProps {
    tenant: Tenant;
}

interface ProjectStoreProps {
    servers: Server[];
    users: TenantUser[];
    currentUser: TenantUser | undefined;
    progress: Map<number, OperationProgress>;
}

type ProjectProps = ProjectStoreProps & ProjectOwnProps;

const Project: FC<ProjectProps> = ({
    tenant, servers, users, currentUser, progress,
}) => {
    const [visibleList, setVisibleList] = useState(false);

    return (
        <div className={cn(theme.card.card, theme.card.rounded, s.card)}>
            <Info
                tenant={tenant}
                users={users}
                currentUser={currentUser}
                ownerUserId={tenant.ownerUserId}
                isOwner={tenant.ownerUserId === currentUser?.userId}
                visibleList={visibleList}
                setVisibleList={setVisibleList}
                titleCursor={!!servers.length}
            />
            <div className={s.divider} />
            <List
                servers={servers}
                progress={progress}
                visibleList={visibleList}
                setVisibleList={setVisibleList}
            />
        </div>
    );
};

const selectProjectServers = (store: Store, ownProps: ProjectOwnProps) => {
    const servers: Server[] = [];
    store.server.forEach((sr) => {
        if (sr.tenantId === ownProps.tenant.id) {
            servers.push(sr);
        }
    });
    return servers;
};

const selectUsers = (store: Store, ownProps: ProjectOwnProps) => {
    const users = store.tenantUser.get(ownProps.tenant.id);
    return users ?? [];
};
const selectCurrentUser = (store: Store) => store.user.email;
const selectProgress = (store: Store) => store.operationProgress.commonOperations;
const selector = createSelector([
    selectProjectServers,
    selectUsers,
    selectCurrentUser,
    selectProgress,
], (servers, users, currentUserEmail, progress) => {
    const currentUser = users.find((user) => user.email === currentUserEmail);
    return { servers, users, currentUser, progress };
});

const mapStoreToProps = (store: Store, ownProps: ProjectOwnProps) => {
    const { servers, users, currentUser, progress } = selector(store, ownProps);
    return { servers, users, currentUser, progress };
};

export default connect(mapStoreToProps)(Project);
