import React, { FC } from 'react';

import { useIntl, Select, Option } from 'Common';
import { frequencyMessage } from 'Lib/helpers/utils';
import FormHelper, { NewServerFormValues } from 'Lib/helpers/newServer';
import { BackupPolicyFrequency } from 'Entities/BackupPolicyFrequency';

import s from './Backup.module.pcss';

interface BackupProps {
    values: NewServerFormValues;
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
}

const Backup: FC<BackupProps> = ({ values, setFieldValue }) => {
    const intl = useIntl();

    return (
        <div>
            <div className={s.group}>
                <label
                    className={s.label}
                    htmlFor="backupQuantity"
                >
                    {intl.getMessage('backup_setting_keep_copy')}
                </label>
                <Select
                    id="backupQuantity"
                    value={values.backup_quantity}
                    onChange={(e) => setFieldValue('backup_quantity', e)}
                    showScroll
                    setting
                >
                    {FormHelper.availableBackupQuantities.map((quantity) => (
                        <Option value={quantity} key={quantity}>
                            {intl.getPlural('count_saved_copy', quantity)}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className={s.group}>
                <label
                    className={s.label}
                    htmlFor="backupFrequencyHours"
                >
                    {intl.getMessage('backup_setting_interval')}
                </label>
                <Select
                    id="backupFrequencyHours"
                    value={values.backup_frequency}
                    onChange={(e) => setFieldValue('backup_frequency', e)}
                    setting
                >
                    {Object.keys(frequencyMessage).map((key) => (
                        <Option key={key} value={key}>
                            {intl.getPlural('count_hour', frequencyMessage[key as BackupPolicyFrequency])}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>
    );
};

export default Backup;
