import React, { FC } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import cn from 'classnames';

import { Icon } from 'Common';

import s from './ScrollLabel.module.pcss';

interface ScrollLabelProps {
    position: 'top' | 'bottom';
    sideBarRef: React.RefObject<HTMLDivElement>;
    showLabel: boolean;
}

const ScrollLabel: FC<ScrollLabelProps> = ({ position, sideBarRef, showLabel }) => {
    smoothscroll.polyfill();
    const handleScroll = () => {
        const sidebar = sideBarRef.current;
        if (sidebar) {
            const { scrollHeight } = sidebar;
            const scrollPosition = position === 'top' ? 0 : scrollHeight;
            sidebar.scrollTo({ top: scrollPosition, left: 0, behavior: 'smooth' });
        }
    };

    return (
        <div className={cn(s.scrollLabel, s[position], { [s.show]: showLabel })}>
            <button
                type="button"
                onClick={handleScroll}
                className={s.scrollLabelButton}
            >
                <Icon icon="down" className={s.scrollLabelIn} />
            </button>
        </div>
    );
};

export default ScrollLabel;
