import { takeEvery, put, fork, join, call, delay, spawn } from 'redux-saga/effects';
import { Task } from 'redux-saga';

import { InplaceType } from 'Actions/./';
import { getAccountDetails, saveAccount } from 'Actions/account';
import AuthorizationApi from 'Apis/authorization';
import AdminAuthorizationApi from 'Apis/adminClientAuthorization';
import RegisterationApi from 'Apis/registration';
import ResellerClientApi from 'Apis/resellerClientAuthorization';
import AccountApi from 'Apis/account';
import MiscApi from 'Apis/miscApi';
import { getAllSecuritySettings } from 'Actions/security';
import { UserActions,
    login as loginAct,
    addAccount as addAccountAct,
    userAccountLogin as userAccountLoginAct,
    loggedIn,
    resellerLogin as resellerLoginAct,
    resellerLogout as resellerLogoutAct,
    logOut as logOutAct,
    register as registerAct,
    checkAuthFailed,
    resetPassword as resetPasswordAct,
    haveNewPanelVersion,
    checkAuthSuccess,
    addSocialAuthAccount as addSocialAuthAccountAct,
    checkSavedAccounts as checkSavedAccountsAct,
} from 'Actions/user';
import { initUiSettings } from 'Actions/ui';
import { getPartnerDetails } from 'Actions/partner';
import { getProfileInfo } from 'Actions/profile';
import { getTicketsInfo } from 'Actions/support';
import { getServersLastOperations } from 'Actions/server';
import { getMainAccount } from 'Actions/mainAccount';
import Account, { IAccount } from 'Entities/Account';
import { IPanelVersion } from 'Entities/PanelVersion';
import { saveCookieToken } from 'Lib/helpers/utils';
import { setAuthToken, setMainToken } from 'Lib/helpers/authHelper';

import { getTenantAndServerList } from './tenant';
import { errorChecker, addError, checkUnhandledError } from './utils';

function* logout(action: ReturnType<typeof logOutAct>) {
    let token: number;
    const adminToken = AppConfigStorage.getAdminToken();
    if (adminToken) {
        token = yield call(AdminAuthorizationApi.adminClientLogout);
    } else {
        token = yield call(AuthorizationApi.logout, AUTH_TOKEN, MAIN_TOKEN || '');
    }
    const { result, error } = errorChecker<number>(token);
    if (result) {
        if (adminToken) {
            setAuthToken(adminToken);
            AppConfigStorage.removeAdminToken();
        } else {
            AppConfigStorage.removeAuthToken();
            AppConfigStorage.removeMainToken();
        }
        AppConfigStorage.clearAccountsList();
        window.location.href = window.location.origin;
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* checkNewVersion() {
    while (true) {
        const response: IPanelVersion = yield call(MiscApi.getPanelVersion);
        const { result, error } = errorChecker<IPanelVersion>(response);
        if (result) {
            if (VERSION !== result.version) {
                yield put(haveNewPanelVersion());
            }
            yield delay(60000);
        }
        if (error) {
            yield addError(error, InplaceType.CheckPanelVersion);
            break;
        }
    }
}

function* checkSavedAccounts() {
    const userAccounts = AppConfigStorage.getAccounts();
    if (userAccounts && userAccounts?.length > 0) {
        for (let i = 0; i < userAccounts.length; i += 1) {
            const currentAccount = userAccounts[i];
            const response: IAccount = yield call(AccountApi.getAccount, currentAccount.token);
            const { error } = errorChecker<IAccount>(response);
            if (error) {
                currentAccount.authorized = false;
                AppConfigStorage.saveAccount(currentAccount);
            }
        }
    }
}

interface ILogin {
    token: string;
    cookie: string;
}

function* login(action: ReturnType<typeof loginAct>) {
    const { payload, callback } = action;
    const token: ILogin = yield AuthorizationApi.login(...payload);
    const { result, error } = errorChecker<ILogin>(token);
    if (result) {
        setAuthToken(result.token);
        if (result.cookie) {
            saveCookieToken(result.cookie);
        }
        yield put(getServersLastOperations());
        yield put(getMainAccount());
        const account: IAccount = (yield AccountApi.getAccount());
        yield put(saveAccount(new Account(account)));
        yield put(getAccountDetails());
        yield put(getAllSecuritySettings());
        yield put(getProfileInfo());
        yield put(getPartnerDetails([]));
        yield put(getTicketsInfo([]));
        const forked: Task = yield fork(getTenantAndServerList);
        yield join(forked);
        yield spawn(checkNewVersion);
        yield put(loggedIn({ token: AUTH_TOKEN, email: account.email, id: account.client_id }));
        yield spawn(checkSavedAccounts);
        yield put(initUiSettings());

        AppConfigStorage.saveAccount({
            token: AUTH_TOKEN,
            email: account.email,
            id: account.client_id,
            authorized: true,
        });

        if (callback?.result) {
            callback?.result();
        }
    } else {
        if (callback?.error) {
            callback?.error(error);
        }
        yield checkUnhandledError(error!, action.type);
    }
}

function* addAccount(action: ReturnType<typeof addAccountAct>) {
    const { payload, callback } = action;
    const token: ILogin = yield AuthorizationApi.login(...payload);
    const { result, error } = errorChecker<ILogin>(token);
    if (result) {
        const account: IAccount = (yield AccountApi.getAccount(result.token));
        AppConfigStorage.saveAccount({
            token: result.token,
            email: account.email,
            id: account.client_id,
            authorized: true,
        });
        if (callback?.result) {
            callback?.result();
        }
    } else {
        if (callback?.error) {
            callback?.error(error);
        }
        yield checkUnhandledError(error!, action.type);
    }
}

function* addSocialAuthAccount(action: ReturnType<typeof addSocialAuthAccountAct>) {
    const { payload, callback } = action;
    const account: IAccount = (yield AccountApi.getAccount(payload.token));
    const { result, error } = errorChecker<IAccount>(account);
    if (result) {
        AppConfigStorage.saveAccount({
            token: payload.token,
            email: account.email,
            id: account.client_id,
            authorized: true,
        });
        if (callback?.result) {
            callback?.result();
        }
    } else {
        if (callback?.error) {
            callback?.error(error);
        }
        yield checkUnhandledError(error!, action.type);
    }
}

function* userAccountLogin(action: ReturnType<typeof userAccountLoginAct>) {
    const { payload, callback } = action;
    setAuthToken(payload.token);

    if (MAIN_TOKEN) {
        AppConfigStorage.removeMainToken();
    }

    const adminToken = AppConfigStorage.getAdminToken();
    if (adminToken) {
        AppConfigStorage.removeAdminToken();
    }

    yield put(initUiSettings());
    callback?.result?.();
}

function* checkAuth() {
    const account: IAccount = (yield AccountApi.getAccount());
    const { result, error } = errorChecker<IAccount>(account);
    if (result) {
        yield put(checkAuthSuccess());
        yield put(getServersLastOperations());
        yield put(getMainAccount());
        yield put(getAccountDetails());
        yield put(getAllSecuritySettings());
        yield put(getProfileInfo());
        yield put(getPartnerDetails([]));
        yield put(getTicketsInfo([]));
        const forked: Task = yield fork(getTenantAndServerList);
        yield join(forked);
        yield put(saveAccount(new Account(account)));
        yield spawn(checkNewVersion);
        yield put(loggedIn({ token: AUTH_TOKEN, email: result.email, id: result.client_id }));
        yield spawn(checkSavedAccounts);
        yield put(initUiSettings());
    }
    if (error) {
        yield put(checkAuthFailed());
    }
}

function* resellerLogin(action: ReturnType<typeof resellerLoginAct>) {
    const { payload } = action;
    if (MAIN_TOKEN) {
        yield call(ResellerClientApi.resellerLogout);
    }
    const response: ILogin = yield call(ResellerClientApi.resellerLogin, ...payload);
    const { result, error } = errorChecker<ILogin>(response);
    if (result) {
        if (result.cookie) {
            saveCookieToken(result.cookie);
        }
        AppConfigStorage.setAuthToken(result.token);
        if (!MAIN_TOKEN) {
            setMainToken(AUTH_TOKEN);
        }
        window.location.href = '/';
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* resellerLogout(action: ReturnType<typeof resellerLogoutAct>) {
    const response: number = yield call(ResellerClientApi.resellerLogout);
    const { result, error } = errorChecker<number>(response);
    if (result) {
        if (MAIN_TOKEN) {
            setAuthToken(MAIN_TOKEN);
        }
        AppConfigStorage.removeMainToken();
        window.location.href = '/';
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* register(action: ReturnType<typeof registerAct>) {
    const { payload, callback } = action;
    const response: number = yield call(RegisterationApi.register, ...payload);
    const { result, error } = errorChecker<number>(response);
    if (result && callback?.result) {
        callback?.result(result);
    }
    if (error && callback?.error) {
        callback?.error(error);
        yield checkUnhandledError(error, action.type);
    }
}
function* resetPassword(action: ReturnType<typeof resetPasswordAct>) {
    const { payload, callback } = action;
    const response: number = yield call(RegisterationApi.resetPassword, ...payload);
    const { result, error } = errorChecker<number>(response);
    if (result && callback?.result) {
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
        yield checkUnhandledError(error, action.type);
    }
}
let authChecked = false;
function* userSaga(): Generator {
    if (!authChecked) {
        yield checkAuth();
        authChecked = true;
    }
    yield takeEvery<ReturnType<typeof loginAct>>(UserActions.Login, login);
    yield takeEvery<ReturnType<typeof addAccountAct>>(UserActions.AddAccount, addAccount);
    yield takeEvery<ReturnType<typeof addSocialAuthAccountAct>>(UserActions.AddSocialAuthAccount, addSocialAuthAccount);
    yield takeEvery<ReturnType<typeof userAccountLoginAct>>(UserActions.UserAccountLogin, userAccountLogin);
    yield takeEvery<ReturnType<typeof checkSavedAccountsAct>>(UserActions.CheckSavedAccounts, checkSavedAccounts);
    yield takeEvery<ReturnType<typeof logOutAct>>(UserActions.LogOut, logout);
    yield takeEvery<UserActions>(UserActions.CheckAuth, checkAuth);
    yield takeEvery<ReturnType<typeof resellerLoginAct>>(UserActions.ResellerLogin, resellerLogin);
    yield takeEvery<ReturnType<typeof resellerLogoutAct>>(
        UserActions.ResellerLogout, resellerLogout,
    );
    yield takeEvery<ReturnType<typeof registerAct>>(UserActions.Register, register);
    yield takeEvery<ReturnType<typeof resetPasswordAct>>(UserActions.ResetPassword, resetPassword);
}

export default userSaga;
