import qs from 'qs';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import BackupPolicyUpdate, { IBackupPolicyUpdate } from 'Entities/BackupPolicyUpdate';
import BatchOperationInfo, { IBatchOperationInfo } from 'Entities/BatchOperationInfo';
import BatchOperationProgress, { IBatchOperationProgress } from 'Entities/BatchOperationProgress';
import IpMove, { IIpMove } from 'Entities/IpMove';
import IpRemove, { IIpRemove } from 'Entities/IpRemove';
import OperationInfo, { IOperationInfo } from 'Entities/OperationInfo';
import OperationProgress, { IOperationProgress } from 'Entities/OperationProgress';
import Server, { IServer } from 'Entities/Server';
import ServerClone, { IServerClone } from 'Entities/ServerClone';
import ServerConfigurationUpdate, { IServerConfigurationUpdate } from 'Entities/ServerConfigurationUpdate';
import ServerConsoleAccessOpen, { IServerConsoleAccessOpen } from 'Entities/ServerConsoleAccessOpen';
import ServerConsoleState, { IServerConsoleState } from 'Entities/ServerConsoleState';
import ServerCostForecast, { IServerCostForecast } from 'Entities/ServerCostForecast';
import ServerCostForecastRequest, { IServerCostForecastRequest } from 'Entities/ServerCostForecastRequest';
import ServerDelete, { IServerDelete } from 'Entities/ServerDelete';
import ServerJsonStats, { IServerJsonStats } from 'Entities/ServerJsonStats';
import ServerPasswordUpdate, { IServerPasswordUpdate } from 'Entities/ServerPasswordUpdate';
import ServerPublicKeysAdd, { IServerPublicKeysAdd } from 'Entities/ServerPublicKeysAdd';
import ServerPublicKeysRemove, { IServerPublicKeysRemove } from 'Entities/ServerPublicKeysRemove';
import ServerPublicKeysUpdate, { IServerPublicKeysUpdate } from 'Entities/ServerPublicKeysUpdate';
import ServerReinstall, { IServerReinstall } from 'Entities/ServerReinstall';
import ServerSetup, { IServerSetup } from 'Entities/ServerSetup';
import ServerStateEvent, { IServerStateEvent } from 'Entities/ServerStateEvent';
import ServerUpdate, { IServerUpdate } from 'Entities/ServerUpdate';
import ServersBatchDelete, { IServersBatchDelete } from 'Entities/ServersBatchDelete';
import ServersBatchLifecycleAction, { IServersBatchLifecycleAction } from 'Entities/ServersBatchLifecycleAction';
import ServersBatchPublicKeysAdd, { IServersBatchPublicKeysAdd } from 'Entities/ServersBatchPublicKeysAdd';
import ServersBatchPublicKeysRemove, { IServersBatchPublicKeysRemove } from 'Entities/ServersBatchPublicKeysRemove';
import ServersBatchPublicKeysUpdate, { IServersBatchPublicKeysUpdate } from 'Entities/ServersBatchPublicKeysUpdate';
import ServersCommonPublicKeys, { IServersCommonPublicKeys } from 'Entities/ServersCommonPublicKeys';
import ServersCommonPublicKeysRequest, { IServersCommonPublicKeysRequest } from 'Entities/ServersCommonPublicKeysRequest';
import Tenant, { ITenant } from 'Entities/Tenant';
import { VmStatsFields } from 'Entities/VmStatsFields';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class ServersApi {
    static async addIp(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/ips`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async addPublicKeys(tenant_id: number, server_id: number, serverpublickeysadd: IServerPublicKeysAdd, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverpublickeysaddValid = new ServerPublicKeysAdd(serverpublickeysadd);
        haveError.push(...serverpublickeysaddValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/pubkeys`, {
            method: 'PUT',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverpublickeysaddValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async addPublicKeysBatch(tenant_id: number, serversbatchpublickeysadd: IServersBatchPublicKeysAdd, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchpublickeysaddValid = new ServersBatchPublicKeysAdd(serversbatchpublickeysadd);
        haveError.push(...serversbatchpublickeysaddValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/pubkeys`, {
            method: 'PUT',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchpublickeysaddValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async addSupportPublicKey(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/pubkeys/support`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async calculateCostForecastForDraft(tenant_id: number, servercostforecastrequest: IServerCostForecastRequest): Promise<IServerCostForecast | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const servercostforecastrequestValid = new ServerCostForecastRequest(servercostforecastrequest);
        haveError.push(...servercostforecastrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/forecast`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(servercostforecastrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async calculateCostForecastForServer(tenant_id: number, server_id: number, servercostforecastrequest: IServerCostForecastRequest, optionalToken?: string): Promise<IServerCostForecast | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const servercostforecastrequestValid = new ServerCostForecastRequest(servercostforecastrequest);
        haveError.push(...servercostforecastrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/forecast`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(servercostforecastrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async cancelServerInstallation(tenant_id: number, server_id: number, operation_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/operations/${operation_id}/cancel`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async cloneServer(tenant_id: number, server_id: number, serverclone: IServerClone, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const servercloneValid = new ServerClone(serverclone);
        haveError.push(...servercloneValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/clone`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(servercloneValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async closeConsoleAccess(tenant_id: number, server_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/console_access`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteServer(tenant_id: number, server_id: number, serverdelete: IServerDelete, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverdeleteValid = new ServerDelete(serverdelete);
        haveError.push(...serverdeleteValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverdeleteValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deleteServersBatch(tenant_id: number, serversbatchdelete: IServersBatchDelete, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchdeleteValid = new ServersBatchDelete(serversbatchdelete);
        haveError.push(...serversbatchdeleteValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchdeleteValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async destroyServer(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/destroy`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async destroyServersBatch(tenant_id: number, serversbatchlifecycleaction: IServersBatchLifecycleAction, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchlifecycleactionValid = new ServersBatchLifecycleAction(serversbatchlifecycleaction);
        haveError.push(...serversbatchlifecycleactionValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/destroy`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchlifecycleactionValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async forceInstallServer(tenant_id: number, server_id: number, operation_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/operations/${operation_id}/install`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getBatchOperationProgress(tenant_id: number, batch_operation_id: number, optionalToken?: string): Promise<IBatchOperationProgress | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/batch_operations/${batch_operation_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getOperationProgress(tenant_id: number, operation_id: number, optionalToken?: string): Promise<IOperationProgress | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/operations/${operation_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServer(tenant_id: number, server_id: number, optionalToken?: string): Promise<IServer| DeletedServer| EarlyServer | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status === 410) {
                    return { deleted: true, entity: await res.json() };
                } else if (res.status === 425) {
                    return { early: true, entity: await res.json() };
                } else if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServerConsoleStatus(tenant_id: number, server_id: number, optionalToken?: string): Promise<IServerConsoleState | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/console_status`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServerLastOperation(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationProgress | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/operations/latest`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServerStateEvents(tenant_id: number, server_id: number, offset?: number, limit?: number, optionalToken?: string): Promise<IServerStateEvent[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            offset: offset,
            limit: limit,
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/state_events?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServerStats(tenant_id: number, server_id: number, date_from_millis: number, date_to_millis: number, fields: VmStatsFields[], optionalToken?: string): Promise<string | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            date_from_millis: date_from_millis,
            date_to_millis: date_to_millis,
            fields: fields,
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/stats?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.text();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServerStatsJson(tenant_id: number, server_id: number, date_from_millis: number, date_to_millis: number, fields: VmStatsFields[], optionalToken?: string): Promise<IServerJsonStats[] | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            date_from_millis: date_from_millis,
            date_to_millis: date_to_millis,
            fields: fields,
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/stats.json?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServersCommonPublicKeys(tenant_id: number, serverscommonpublickeysrequest: IServersCommonPublicKeysRequest, optionalToken?: string): Promise<IServersCommonPublicKeys | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverscommonpublickeysrequestValid = new ServersCommonPublicKeysRequest(serverscommonpublickeysrequest);
        haveError.push(...serverscommonpublickeysrequestValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/pubkeys/common`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverscommonpublickeysrequestValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServersLastOperations(optionalToken?: string): Promise<IOperationProgress[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/servers/operations/latest`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getServersToMoveIp(tenant_id: number, server_id: number, optionalToken?: string): Promise<IServer[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/ips/move`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getTenantsToMoveServer(tenant_id: number, server_id: number, optionalToken?: string): Promise<ITenant[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/change_tenant`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async installServer(tenant_id: number, serversetup: IServerSetup, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversetupValid = new ServerSetup(serversetup);
        haveError.push(...serversetupValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversetupValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listServers(optionalToken?: string): Promise<IServer[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/servers`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async listServersForTenant(tenant_id: number, optionalToken?: string): Promise<IServer[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async markOperationChecked(tenant_id: number, operation_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/operations/${operation_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async moveIp(tenant_id: number, server_id: number, ipmove: IIpMove, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const ipmoveValid = new IpMove(ipmove);
        haveError.push(...ipmoveValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/ips/move`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ipmoveValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async openConsoleAccess(tenant_id: number, server_id: number, serverconsoleaccessopen: IServerConsoleAccessOpen, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverconsoleaccessopenValid = new ServerConsoleAccessOpen(serverconsoleaccessopen);
        haveError.push(...serverconsoleaccessopenValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/console_access`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverconsoleaccessopenValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async rebootServer(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/reboot`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async rebootServersBatch(tenant_id: number, serversbatchlifecycleaction: IServersBatchLifecycleAction, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchlifecycleactionValid = new ServersBatchLifecycleAction(serversbatchlifecycleaction);
        haveError.push(...serversbatchlifecycleactionValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/reboot`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchlifecycleactionValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async reinstallServer(tenant_id: number, server_id: number, serverreinstall: IServerReinstall, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverreinstallValid = new ServerReinstall(serverreinstall);
        haveError.push(...serverreinstallValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/reinstall`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverreinstallValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async removeIp(tenant_id: number, server_id: number, ipremove: IIpRemove, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const ipremoveValid = new IpRemove(ipremove);
        haveError.push(...ipremoveValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/ips`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ipremoveValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async removePublicKeys(tenant_id: number, server_id: number, serverpublickeysremove: IServerPublicKeysRemove, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverpublickeysremoveValid = new ServerPublicKeysRemove(serverpublickeysremove);
        haveError.push(...serverpublickeysremoveValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/pubkeys`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverpublickeysremoveValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async removePublicKeysBatch(tenant_id: number, serversbatchpublickeysremove: IServersBatchPublicKeysRemove, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchpublickeysremoveValid = new ServersBatchPublicKeysRemove(serversbatchpublickeysremove);
        haveError.push(...serversbatchpublickeysremoveValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/pubkeys`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchpublickeysremoveValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async removeSupportPublicKey(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/pubkeys/support`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async resetServer(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/reset`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async resetServersBatch(tenant_id: number, serversbatchlifecycleaction: IServersBatchLifecycleAction, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchlifecycleactionValid = new ServersBatchLifecycleAction(serversbatchlifecycleaction);
        haveError.push(...serversbatchlifecycleactionValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/reset`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchlifecycleactionValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async shutdownServer(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/shutdown`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async shutdownServersBatch(tenant_id: number, serversbatchlifecycleaction: IServersBatchLifecycleAction, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchlifecycleactionValid = new ServersBatchLifecycleAction(serversbatchlifecycleaction);
        haveError.push(...serversbatchlifecycleactionValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/shutdown`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchlifecycleactionValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async startServer(tenant_id: number, server_id: number, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/start`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async startServersBatch(tenant_id: number, serversbatchlifecycleaction: IServersBatchLifecycleAction, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchlifecycleactionValid = new ServersBatchLifecycleAction(serversbatchlifecycleaction);
        haveError.push(...serversbatchlifecycleactionValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/start`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchlifecycleactionValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateBackupPolicy(tenant_id: number, server_id: number, backuppolicyupdate: IBackupPolicyUpdate, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const backuppolicyupdateValid = new BackupPolicyUpdate(backuppolicyupdate);
        haveError.push(...backuppolicyupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/backup_policy`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(backuppolicyupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateConfiguration(tenant_id: number, server_id: number, serverconfigurationupdate: IServerConfigurationUpdate, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverconfigurationupdateValid = new ServerConfigurationUpdate(serverconfigurationupdate);
        haveError.push(...serverconfigurationupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/configuration`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverconfigurationupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updatePassword(tenant_id: number, server_id: number, serverpasswordupdate: IServerPasswordUpdate, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverpasswordupdateValid = new ServerPasswordUpdate(serverpasswordupdate);
        haveError.push(...serverpasswordupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/password`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverpasswordupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updatePublicKeys(tenant_id: number, server_id: number, serverpublickeysupdate: IServerPublicKeysUpdate, optionalToken?: string): Promise<IOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverpublickeysupdateValid = new ServerPublicKeysUpdate(serverpublickeysupdate);
        haveError.push(...serverpublickeysupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/pubkeys`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverpublickeysupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updatePublicKeysBatch(tenant_id: number, serversbatchpublickeysupdate: IServersBatchPublicKeysUpdate, optionalToken?: string): Promise<IBatchOperationInfo | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serversbatchpublickeysupdateValid = new ServersBatchPublicKeysUpdate(serversbatchpublickeysupdate);
        haveError.push(...serversbatchpublickeysupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/pubkeys`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serversbatchpublickeysupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateRecommendationsSubscriptionForServer(tenant_id: number, server_id: number, enabled: boolean, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const queryParams = {
            enabled: enabled,
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}/server_upgrade_recommendations?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updateServer(tenant_id: number, server_id: number, serverupdate: IServerUpdate, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const serverupdateValid = new ServerUpdate(serverupdate);
        haveError.push(...serverupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/servers/${server_id}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(serverupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}

export interface DeletedServer {
    deleted: true;
    entity: IServer;
}

export interface EarlyServer {
    early: true;
    entity: IServer;
}
