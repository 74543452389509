import React, { FC, useState, useRef } from 'react';
import cn from 'classnames';
import { Formik, FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';

import { useTitle } from 'Hooks';
import { resetPassword } from 'Actions/user';
import { useIntl, Input, Button, InnerPageLayout, BackHeader } from 'Common';
import Error from 'Entities/Error';
import PasswordReset, { IPasswordReset } from 'Entities/PasswordReset';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import { RoutePath } from 'Lib/helpers/routes';
import { emailValidate } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import s from './Auth.module.pcss';
import Captcha from './Captcha';

type FormValues = IPasswordReset & { captcha: string };
const RecoveryPassword: FC = () => {
    const intl = useIntl();
    useTitle(intl.getMessage('recovery_password_page_title'));
    const [sentTo, setSentTo] = useState('');
    const dispatch = useDispatch();
    const captchaRef = useRef<any>(null);

    const onSubmit = (v: FormValues, helpers: FormikHelpers<FormValues>) => {
        if (!NO_CAPTCHA) {
            if (!v.captcha) {
                helpers.setSubmitting(false);
                captchaRef.current?.execute();
                return;
            }
        }
        const reqEnt = new PasswordReset(v);
        if (reqEnt.validate().length === 0 && emailValidate(reqEnt.email)) {
            dispatch(resetPassword([reqEnt.serialize(), v.captcha], {
                result: () => {
                    helpers.setSubmitting(false);
                    setSentTo(v.email);
                },
                error: (error: Error) => {
                    helpers.setSubmitting(false);
                    helpers.setFieldValue('captcha', '', false);
                    const { fields } = error;
                    if (fields.email) {
                        const emailError = fields.email[0];
                        helpers.setFieldError('email', apiErrorCodeTranslate(intl, emailError.error_code));
                    }
                },
            }));
        } else {
            helpers.setSubmitting(false);
            helpers.setFieldError(
                'email',
                !reqEnt.email ? EMPTY_FIELD_ERROR : intl.getMessage('wrong_email'),
            );
        }
    };
    const validateEmailField = (v: string) => {
        return emailValidate(v) && PasswordReset.emailValidate(v);
    };
    return (
        <InnerPageLayout
            header={<BackHeader link={RoutePath.Root} />}
            className={theme.content.auth}
        >
            <div id={sentTo ? 'recovery-email-sent' : undefined} className={cn(s.auth__panel, s.auth__panel_recovery)}>
                <div className={cn(s.auth__title, s['auth__title--registration'])}>
                    {!sentTo
                        ? intl.getMessage('recovery_password_title')
                        : intl.getMessage('recovery_password_sent_title')}
                </div>
                <div className={cn(s.auth__text, s['auth__text--top'])}>
                    {!sentTo
                        ? intl.getMessage('recovery_password_desc')
                        : intl.getMessage('recovery_password_sent_desc', { email: sentTo })}
                </div>
                {!sentTo && (
                    <Formik
                        initialValues={{ email: '', captcha: '' }}
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit} noValidate>
                                <div className={s.auth__group}>
                                    <Input
                                        autoFocus
                                        autoComplete="username"
                                        className={cn('input', { input_error: errors.email })}
                                        error={!!errors.email}
                                        errorMessage={errors.email}
                                        name="email"
                                        onChange={(e) => setFieldValue('email', e)}
                                        placeholder={intl.getMessage('email')}
                                        size="large"
                                        type="email"
                                        validate={validateEmailField}
                                        value={values.email}
                                    />
                                </div>
                                <Captcha
                                    captchaRef={captchaRef}
                                    setValue={(e) => setFieldValue('captcha', e)}
                                />
                                <div className={s.auth__group}>
                                    <Button
                                        disabled={isSubmitting || !touched}
                                        htmlType="submit"
                                        size="medium"
                                        type="primary"
                                    >
                                        {intl.getMessage('recovery_password_button')}
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                )}
            </div>
        </InnerPageLayout>
    );
};

export default RecoveryPassword;
