import React, { FC } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { useIntl } from 'Common';
import s from './CopyButton.module.pcss';

interface CopyButtonProps {
    copy: () => void;
    active: boolean;
    color?: 'gray' | 'black';
    background: 'cloud' | 'ice';
}

const CopyButton: FC<CopyButtonProps> = ({ copy, active, color = 'black', background }) => {
    const intl = useIntl();

    return (
        <Tooltip
            placement="bottomRight"
            overlayClassName="tooltip tooltip_small"
            title={intl.getMessage('title_copy_name')}
            arrowPointAtCenter
        >
            <button
                type="button"
                className={cn(
                    s.button,
                    s[color],
                    { [s.active]: active },
                )}
                onClick={copy}
            >
                <div className={cn(s.bgFirst, s[background])} />
                <div className={s.bgSecond} />
                <div className={s.icon}>
                    <svg viewBox="0 0 24 24">
                        <path d="M2 2H22V22H2V2Z" fill="currentColor" />
                        <path d="M6.5 11.5L10.5 15.5L17.5 8.5" stroke="currentColor" strokeWidth="2" className={s.path} fill="none" />
                    </svg>
                </div>
            </button>
        </Tooltip>
    );
};

export default CopyButton;
