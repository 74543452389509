import React, { FC, useEffect } from 'react';
import { Button } from 'antd';
import cn from 'classnames';

import { useIntl, Icon } from 'Common';
import theme from 'Lib/theme';

import CardPlaceholder from './CardPlaceholder';
import s from './CreditCard.module.pcss';

export enum Status {
    NO,
    SUCCESS,
    FAILED,
}

interface CardPendingProps {
    status: Status;
    removeStatus: () => void;
    onRetry?: () => void;
}

const CardPending: FC<CardPendingProps> = ({ status, removeStatus, onRetry }) => {
    const intl = useIntl();
    useEffect(() => {
        let canUpdate = true;
        setTimeout(() => {
            if (canUpdate) {
                removeStatus();
            }
        }, 15000);
        return () => {
            canUpdate = false;
        };
    });
    return (
        <div className={cn(theme.card.card, theme.card.list, theme.card.row)}>
            <CardPlaceholder noNum />
            <div className={s.message}>
                {status === Status.SUCCESS && (
                    intl.getMessage('add_card_success')
                )}
                {status === Status.FAILED && (
                    intl.getMessage('add_card_fail')
                )}
                {status === Status.FAILED && (
                    <Button
                        type="link"
                        className={cn(
                            theme.button.button,
                            theme.button.desktop_small,
                            s.retry,
                        )}
                        onClick={onRetry}
                    >
                        {intl.getMessage('retry')}
                    </Button>
                )}
            </div>
            <div className={theme.card.actions}>
                <Button
                    size="large"
                    className={cn(
                        theme.card.action,
                        theme.button.button,
                        theme.button.icon,
                    )}
                    onClick={removeStatus}
                    icon={(<Icon icon="close_big" />)}
                />
            </div>
        </div>
    );
};

export default CardPending;
