import React, { FC } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Link } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import Profile from 'Entities/Profile';
import { WebTheme } from 'Entities/WebTheme';
import { Store } from 'Store';
import { getCurrentTheme } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

import warning from 'Lib/assets/images/warning.png';
import warning2x from 'Lib/assets/images/warning@2x.png';
import warningDark from 'Lib/assets/images/warning_dark.png';
import warning2xDark from 'Lib/assets/images/warning_dark@2x.png';

import s from '../Errors.module.pcss';
import Header from './Header';

const { Content } = Layout;

interface ErrorLayoutOwnProps {
    title: string;
    desc?: string;
    code?: number;
    link?: RoutePath;
    linkText?: string;
    noHeader?: boolean;
}

interface ErrorLayoutStoreProps {
    profile: Profile | null;
}

type ErrorLayoutProps = ErrorLayoutStoreProps & ErrorLayoutOwnProps;

const ErrorLayout: FC<ErrorLayoutProps> = ({
    title, desc, link, linkText, code, profile, noHeader,
}) => {
    let images = {
        warning,
        warning2x,
    };

    const currentTheme = profile?.webTheme && getCurrentTheme(profile.webTheme);

    if (currentTheme === WebTheme.DARK) {
        images = {
            warning: warningDark,
            warning2x: warning2xDark,
        };
    }

    return (
        <Layout>
            {!noHeader && <Header />}
            <Content className={cn(theme.content.content, theme.content.error)}>
                <div className={s.content}>
                    {code ? (
                        <div className={s.code}>
                            {code}
                            <img
                                src={images.warning}
                                srcSet={`${images.warning2x} 2x`}
                                className={cn(s.warning, s.warning_code)}
                                alt="warning"
                            />
                        </div>
                    ) : (
                        <img
                            src={images.warning}
                            srcSet={`${images.warning2x} 2x`}
                            className={s.warning}
                            alt="warning"
                        />
                    )}
                    <div className={s.title}>
                        {title}
                    </div>
                    {desc && (
                        <div className={s.desc}>
                            {desc}
                        </div>
                    )}
                    {link && (
                        <Link
                            to={link}
                            className={cn(
                                theme.button.button,
                                theme.button.desktop_medium,
                                theme.button.link,
                            )}
                        >
                            {linkText}
                        </Link>
                    )}
                </div>
            </Content>
        </Layout>
    );
};

const selectProfile = (store: Store) => store.profile.info;

const selector = createSelector([selectProfile], (profile) => ({ profile }));

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(ErrorLayout);
