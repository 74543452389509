import React, { FC, useState } from 'react';
import { Popover } from 'antd';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { deleteNotificationRecipient } from 'Actions/notifications';
import { useIntl, Link, Button } from 'Common';
import NotificationRecipient from 'Entities/NotificationRecipient';
import { RoutePath } from 'Lib/helpers/routes';
import { formatPhoneNumber } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import RemoveRecipientModal from './RemoveRecipientModal';
import AddRecipientModal from './AddRecipientModal';

interface RecipientProps {
    recipient: NotificationRecipient;
}
const Recipient: FC<RecipientProps> = ({ recipient }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showRemoveRecipientModal, setShowRemoveRecipientModal] = useState(false);
    const [showEditRecipientModal, setShowEditRecipientModal] = useState(false);

    const removeRecipient = () => {
        dispatch(deleteNotificationRecipient([recipient.id]));
        setShowRemoveRecipientModal(false);
    };

    const content = (
        <div className={cn(theme.popover.popover, theme.popover.popover_small)}>
            {!recipient.isDefault && (
                <button
                    type="button"
                    className={theme.popover.item}
                    onClick={() => setShowEditRecipientModal(true)}
                >
                    {intl.getMessage('edit')}
                </button>
            )}
            <Link
                to={RoutePath.NotificationUserSettings}
                className={theme.popover.item}
                props={{ userId: recipient.id }}
            >
                {intl.getMessage('notifications_settings')}
            </Link>
            {!recipient.isDefault && (
                <button
                    type="button"
                    className={cn(
                        theme.popover.item,
                        theme.popover.item_danger,
                    )}
                    onClick={() => setShowRemoveRecipientModal(true)}
                >
                    {intl.getMessage('delete')}
                </button>
            )}
        </div>
    );

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.with_actions,
            )}
        >
            <div className={cn(theme.card.grid, theme.card.grid_recipient)}>
                <div className={theme.common.textOverflow}>
                    {recipient.isDefault ? (
                        intl.getMessage('you')
                    ) : (
                        recipient.name
                    )}
                </div>

                <div className={theme.common.textOverflow}>
                    {recipient.email}
                </div>

                <div className={theme.card.actionsWrap}>
                    <div className={theme.common.textOverflow}>
                        {recipient.phone?.fullNumber && formatPhoneNumber(recipient.phone.fullNumber)}
                    </div>

                    <div className={theme.card.actions}>
                        <Link
                            to={RoutePath.NotificationUserSettings}
                            props={{ userId: recipient.id }}
                            className={cn(theme.card.action, theme.card.action_hover)}
                        >
                            <Button
                                type="icon"
                                icon="notifications"
                                title={intl.getMessage('notifications_settings')}
                            />
                        </Link>
                        {!recipient.isDefault && (
                            <>
                                <Button
                                    type="icon"
                                    icon="edit"
                                    className={cn(theme.card.action, theme.card.action_hover)}
                                    onClick={() => setShowEditRecipientModal(true)}
                                    title={intl.getMessage('notifications_change_recipient_title')}
                                />
                                <Button
                                    type="icon"
                                    icon="delete"
                                    className={cn(theme.card.action, theme.card.action_hover)}
                                    onClick={() => setShowRemoveRecipientModal(true)}
                                    title={intl.getMessage('notifications_remove_recipient_title')}
                                />
                            </>
                        )}
                    </div>
                </div>

                <Popover
                    content={content}
                    placement="left"
                    trigger="click"
                    overlayClassName="popover"
                >
                    <Button
                        type="icon"
                        icon="options"
                        title={intl.getMessage('title_control_recipient')}
                        className={cn(theme.card.action, theme.card.action_popover)}
                    />
                </Popover>
            </div>

            {showRemoveRecipientModal && (
                <RemoveRecipientModal
                    visible={showRemoveRecipientModal}
                    email={recipient.email || formatPhoneNumber(recipient.phone!.fullNumber)}
                    handleDelete={() => removeRecipient()}
                    handleClose={() => setShowRemoveRecipientModal(false)}
                />
            )}
            {showEditRecipientModal && (
                <AddRecipientModal
                    visible={showEditRecipientModal}
                    update={recipient}
                    handleClose={() => setShowEditRecipientModal(false)}
                />
            )}
        </div>
    );
};

export default Recipient;
