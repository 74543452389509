import React, { FC, useContext, useState } from 'react';
import cn from 'classnames';

import { useIntl, Button, Select, Option } from 'Common';
import { SERVER_VIEW } from 'Lib/helpers/consts';
import theme from 'Lib/theme';
import { MapResult, ServerListContext } from 'Components/ServersList/factories';
import { ActionsSelect, ModalFilters, ServerSelect, SortDropdown, TenantSelect } from '.';

import s from './Filters.module.pcss';

interface FiltersProps {
    serversLength: number;
    servers: MapResult[];
}

const Filters: FC<FiltersProps> = React.memo(({
    serversLength,
    servers,
}) => {
    const intl = useIntl();
    const [showModal, setShowModal] = useState(false);
    const {
        setViewType,
        selectedServers,
        serverView,
        clearSelection,
    } = useContext(ServerListContext);

    return (
        <div className={s.filters}>
            <div className={s.title}>
                {intl.getMessage('menu_servers')}
                <div className={cn(s.count, theme.Badge.badge, theme.Badge.count)}>
                    {serversLength}
                </div>
            </div>
            <div className={s.serverSelect}>
                <ServerSelect
                    servers={servers}
                    transparent
                />
            </div>
            {selectedServers.size > 0 ? (
                <div className={cn(theme.skeleton.desktop, s.actionSelectDesktop)}>
                    <ActionsSelect
                        clearSelection={clearSelection}
                        servers={selectedServers}
                    />
                </div>
            ) : (
                <>
                    <div className={s.wrapper}>
                        <SortDropdown />
                        <TenantSelect
                            transparent
                        />
                        <Select
                            value={serverView}
                            id="view"
                            size="medium"
                            onChange={(v) => setViewType(v)}
                            className={cn(s.select, s.select_view, 'select--transparent')}
                        >
                            <Option value={SERVER_VIEW.EXPAND}>
                                {intl.getMessage('server_view_expand')}
                            </Option>
                            <Option value={SERVER_VIEW.LIST}>
                                {intl.getMessage('server_view_list')}
                            </Option>
                            <Option value={SERVER_VIEW.COMPACT}>
                                {intl.getMessage('server_view_tiles')}
                            </Option>
                        </Select>
                    </div>
                    <div className={cn(s.actions, s.actions_mobile)}>
                        <Button
                            type="border"
                            icon="filters"
                            className={theme.button.only_icon}
                            onClick={() => setShowModal(true)}
                        />
                    </div>
                </>
            )}
            {showModal && (
                <ModalFilters
                    handleClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
});

export default Filters;
