import serversApi from 'Apis/servers';
import snapApi from 'Apis/snapshots';
import Snapshot from 'Entities/Snapshot';
import OperationProgress from 'Entities/OperationProgress';
import { actionCreator } from '.';

export enum SnapshotsActions {
    GetList = 'GetList',
    SaveList = 'SaveList',
    CreateSnapshot = 'CreateSnapshot',
    SaveSnapshotProgress = 'SaveSnapshotProgress',
    DeleteSnapshotProgress = 'DeleteSnapshotProgress',
    DeleteSnapshot = 'DeleteSnapshot',
    RollbackSnapshot = 'RollbackSnapshot',
    DownloadSnapshot = 'DownloadSnapshot',
    CreateServerFromSnaphot = 'CreateServerFromSnaphot',
    GetSnapshotDownloadLink = 'GetSnapshotDownloadLink',
}

type GetList = Parameters<typeof snapApi.listSnapshots>;
const getSnapshotList = actionCreator<GetList>(SnapshotsActions.GetList);
const saveList = actionCreator<Snapshot[]>(SnapshotsActions.SaveList);

type CreateSnapshot = Parameters<typeof snapApi.createSnapshot>;
const createSnapshot = actionCreator<CreateSnapshot>(SnapshotsActions.CreateSnapshot);

interface SaveSnapshotProgress {
    progress: OperationProgress;
    snapId: number;
}
const saveSnapshotProgress = actionCreator<SaveSnapshotProgress>(
    SnapshotsActions.SaveSnapshotProgress,
);
const deleteSnapshotProgress = actionCreator<number>(
    SnapshotsActions.DeleteSnapshotProgress,
);

type DeleteSnapshot = Parameters<typeof snapApi.deleteSnapshot>;
const deleteSnapshot = actionCreator<DeleteSnapshot>(SnapshotsActions.DeleteSnapshot);

type RollbackSnapshot = Parameters<typeof snapApi.rollbackSnapshot>;
const rollbackSnapshot = actionCreator<RollbackSnapshot>(SnapshotsActions.RollbackSnapshot);

type DownloadSnapshot = Parameters<typeof snapApi.downloadSnapshot>;
const downloadSnapshot = actionCreator<DownloadSnapshot>(SnapshotsActions.DownloadSnapshot);

type CreateServerFromSnaphot = Parameters<typeof serversApi.cloneServer>;
const createServerFromSnaphot = actionCreator<CreateServerFromSnaphot>(
    SnapshotsActions.CreateServerFromSnaphot,
);

type GetSnapshotDownloadLink = Parameters<typeof snapApi.getSnapshotDownloadLink>;
const getSnapshotDownloadLink = actionCreator<GetSnapshotDownloadLink>(SnapshotsActions.GetSnapshotDownloadLink);

export {
    getSnapshotList,
    saveList,
    createSnapshot,
    deleteSnapshot,
    rollbackSnapshot,
    downloadSnapshot,
    saveSnapshotProgress,
    deleteSnapshotProgress,
    createServerFromSnaphot,
    getSnapshotDownloadLink,
};
