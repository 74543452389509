// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IIpRemove {
    ip_address: string;
}

export default class IpRemove {
    readonly _ip_address: string;

    /**
     * Description: Ip address
     * Example: 10.0.0.1
     */
    get ipAddress(): string {
        return this._ip_address;
    }

    static ipAddressValidate(ipAddress: string): boolean {
        return typeof ipAddress === 'string' && !!ipAddress.trim();
    }

    constructor(props: IIpRemove) {
        this._ip_address = props.ip_address.trim();
    }

    serialize(): IIpRemove {
        const data: IIpRemove = {
            ip_address: this._ip_address,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            ip_address: typeof this._ip_address === 'string' && !!this._ip_address.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IIpRemove): IpRemove {
        return new IpRemove(props);
    }

    readonly keys: { [key: string]: string } = {
        ipAddress: 'ip_address',
        }
;

    mergeDeepWith(props: Partial<IpRemove>): IpRemove {
        const updateData: Partial<IIpRemove> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof IpRemove) => {
            const updateKey = this.keys[key] as keyof IIpRemove;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IIpRemove, keyof IIpRemove>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new IpRemove({ ...this.serialize(), ...updateData });
    }
}
