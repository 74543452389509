import React, { FC } from 'react';

import { useIntl } from 'Common';
import Tariff from 'Entities/Tariff';
import Server from 'Entities/Server';
import { toGb } from 'Lib/helpers/helpers';

import s from './ChangeTariff.module.pcss';

interface FixedTariffProps {
    server: Server;
    tariff: Tariff;
}

const FixedTariff: FC<FixedTariffProps> = ({ server, tariff }) => {
    const {
        memoryMib,
        cpuCores,
        diskGib,
        includedTrafficGib,
    } = tariff;
    const intl = useIntl();

    const rows = [
        {
            label: intl.getMessage('processor'),
            value: intl.getPlural('core', cpuCores),
        },
        {
            label: intl.getMessage('memory'),
            value: intl.getMessage('value_gb', { value: toGb(memoryMib) }),
        },
        {
            label: intl.getMessage('disk'),
            value: intl.getMessage('value_gb', { value: diskGib }),
        },
        {
            label: intl.getMessage('server_resources_rx_traffic'),
            value: intl.getMessage('value_gb', { value: includedTrafficGib }),
        },
        {
            label: intl.getMessage('server_resources_tx_traffic'),
            value: intl.getMessage('value_gb', { value: includedTrafficGib }),
        },
        {
            label: intl.getMessage('public_ip'),
            value: server.ipCount,
        },
    ];

    return (
        <div className={s.fixed}>
            {rows.map(({ label, value }) => (
                <div className={s.row} key={label}>
                    <div className={s.label}>
                        {label}
                    </div>
                    <div className={s.value}>
                        {value}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FixedTariff;
