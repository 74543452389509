import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useIntl, Input } from 'Common';
import CreateIndividual, { ICreateIndividual } from 'Entities/CreateIndividual';
import { nameValidate } from 'Lib/helpers/utils';

import { FormInnerProps } from '.';

const IndividualForm: FC<FormInnerProps> = ({ handleSubmit: handle, Submit }) => {
    const intl = useIntl();
    const initialValues: ICreateIndividual = {
        individual_first_name: '',
        individual_last_name: '',
        individual_address: '',
    };

    const onSubmit = (
        values: ICreateIndividual,
        helpers: FormikHelpers<ICreateIndividual>,
    ) => {
        handle({ individual: values }, helpers);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({
                setFieldValue,
                handleSubmit,
                values: {
                    individual_first_name: individualFirst,
                    individual_last_name: individualLast,
                    individual_address: individualAddress,
                },
                errors,
            }) => (
                <form onSubmit={handleSubmit} noValidate>
                    <Input
                        autoFocus
                        name="name"
                        label={intl.getMessage('credentials_name')}
                        placeholder={intl.getMessage('credentials_name')}
                        size="middle"
                        type="text"
                        value={individualFirst}
                        onChange={(v) => setFieldValue('individual_first_name', v)}
                        error={!!errors.individual_first_name}
                        validate={(e) => (
                            CreateIndividual.individualFirstNameValidate(e)
                            && nameValidate(e)
                        )}
                    />
                    <Input
                        name="lastName"
                        label={intl.getMessage('credentials_last_name')}
                        placeholder={intl.getMessage('credentials_last_name')}
                        size="middle"
                        type="text"
                        value={individualLast}
                        onChange={(v) => setFieldValue('individual_last_name', v)}
                        error={!!errors.individual_last_name}
                        validate={(e) => (
                            CreateIndividual.individualLastNameValidate(e)
                            && nameValidate(e)
                        )}
                    />
                    <Input
                        optional
                        name="address"
                        label={intl.getMessage('credentials_address')}
                        placeholder={intl.getMessage('credentials_address')}
                        size="middle"
                        type="text"
                        value={individualAddress}
                        onChange={(v) => setFieldValue('individual_address', v)}
                        error={!!errors.individual_address}
                    />
                    <Submit />
                </form>
            )}
        </Formik>
    );
};

export default IndividualForm;
