import React, { FC } from 'react';

import ResourceLimits from 'Entities/ServerResourceLimits';
import FormHelper from 'Lib/helpers/newServer';
import { toGb, toMb } from 'Lib/helpers/helpers';
import { useIntl } from 'Common';
import TariffRow, { TariffRowProps } from './TariffRow';

interface CustomTariffRowsProps {
    resLimits: ResourceLimits;
    memory: number;
    setMemory: (value: number) => void;
    processor: number;
    setProcessor: (value: number) => void;
    disk: number;
    setDisk: (value: number) => void;
    publicIp?: number;
    setPublicIp?: (value: number) => void;
    minDisk?: number;
    noChangeIpCount?: boolean;
    newServer?: boolean;
}

const CustomTariffRows: FC<CustomTariffRowsProps> = ({
    resLimits,
    memory,
    setMemory,
    processor,
    setProcessor,
    disk,
    setDisk,
    publicIp,
    setPublicIp,
    minDisk,
    noChangeIpCount,
    newServer,
}) => {
    const intl = useIntl();

    let countedMinMemory = FormHelper.countMinMemory(
        minDisk && (minDisk > disk) ? minDisk : disk,
        resLimits.memoryDiskRate,
        resLimits.stepMemoryMib,
    );

    if (resLimits.minMemoryMib > countedMinMemory) {
        countedMinMemory = resLimits.minMemoryMib;
    }

    const rows: TariffRowProps['data'][] = [
        {
            label: intl.getMessage('processor'),
            min: resLimits.minCpuCores,
            max: resLimits.maxCpuCores,
            step: FormHelper.defaultStep,
            value: processor,
            setValue: setProcessor,
            getTransMessage: (value: number) => intl.getPlural('core_short', value),
            type: 'processor',
        },
        {
            label: intl.getMessage('memory'),
            min: toGb(countedMinMemory),
            max: toGb(resLimits.maxMemoryMib),
            step: toGb(resLimits.stepMemoryMib),
            value: toGb(memory),
            setValue: (v) => setMemory(toMb(v)),
            getTransMessage: () => intl.getMessage('gb'),
            type: 'memory',
            isFloatable: true,
        },
        {
            label: intl.getMessage('disk'),
            min: minDisk ?? resLimits.minDiskGib,
            max: resLimits.maxDiskGib,
            value: disk,
            step: FormHelper.defaultStep,
            setValue: setDisk,
            desc: intl.getMessage('custom_tariff_disk'),
            getTransMessage: () => intl.getMessage('gb'),
            type: 'disk',
            noSlider: (minDisk && minDisk === resLimits.maxDiskGib) ? intl.getMessage('tariff_disk_change_impossible') : undefined,
        },
    ];

    if (!minDisk && !noChangeIpCount) {
        rows.push({
            label: intl.getMessage('public_ip'),
            min: resLimits.minIpCount,
            max: resLimits.maxIpCount,
            step: FormHelper.defaultStep,
            value: publicIp!,
            setValue: setPublicIp!,
            type: 'public_ip',
        });
    }

    return (
        <>
            {rows.map((data) => (
                <TariffRow data={data} key={data.label} newServer={newServer} />
            ))}
        </>
    );
};

export default CustomTariffRows;
