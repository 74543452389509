import React, { FC } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { useIntl, Input } from 'Common';
import { ClientType } from 'Entities/ClientType';
import { EMPTY_FIELD_ERROR } from 'Lib/helpers/consts';
import CreateCorporation, { ICreateCorporation } from 'Entities/CreateCorporation';
import theme from 'Lib/theme';

import { checkVat } from './utils';
import { FormInnerProps } from '.';

interface FormValues extends ICreateCorporation {
    vatChecked: boolean;
}

const CorporationForm: FC<FormInnerProps> = ({
    handleSubmit: handle,
    Submit,
}) => {
    const intl = useIntl();
    const initial: FormValues = {
        corporation_trademark: '',
        corporation_legal_address: '',
        corporation_post_address: '',
        corporation_head_position: '',
        corporation_head_name: '',
        corporation_operation_basis: '',
        corporation_accounting_email: '',
        corporation_inn: '',
        corporation_iec: '',
        corporation_government_contract_id: '',
        vatChecked: false,
    };

    const onSubmit = (
        values: FormValues,
        helpers: FormikHelpers<FormValues>,
    ) => handle({ corporation: values }, helpers);

    return (
        <Formik
            initialValues={initial}
            onSubmit={onSubmit}
        >
            {({
                setValues,
                setFieldValue,
                setFieldError,
                handleSubmit,
                values,
                errors,
                isSubmitting,
                setSubmitting,
            }) => {
                const handleCheck = async () => {
                    setSubmitting(true);
                    const { result, error } = await checkVat(
                        values.corporation_inn,
                        ClientType.CORPORATION,
                    );
                    if (error) {
                        setFieldError('corporation_inn', EMPTY_FIELD_ERROR);
                    } else {
                        setValues({ ...values, ...result!.corporation_details, vatChecked: true });
                    }
                    setSubmitting(false);
                };
                return (
                    <form onSubmit={handleSubmit} noValidate>
                        <Input
                            autoFocus={!values.vatChecked}
                            error={!!errors.corporation_inn}
                            label={intl.getMessage('credentials_vat')}
                            name="corporation_inn"
                            onChange={(e) => setValues(
                                { ...values, corporation_inn: e, vatChecked: false },
                            )}
                            placeholder={intl.getMessage('onboarding_corporation_vat_placeholder')}
                            size="middle"
                            type="text"
                            validate={(e) => (
                                CreateCorporation.corporationInnValidate(e) && e.length === 10
                            )}
                            value={values.corporation_inn}
                            inputMode="numeric"
                            pattern="[0-9]{10}"
                        />

                        {values.vatChecked ? (
                            <>
                                <div className={theme.modal.subtitle}>
                                    {intl.getMessage('credentials_company_info')}
                                </div>

                                <Input
                                    disabled
                                    error={!!errors.corporation_trademark}
                                    label={intl.getMessage('credentials_company_name')}
                                    name="corporation_trademark"
                                    placeholder={intl.getMessage('credentials_company_name')}
                                    size="middle"
                                    type="text"
                                    validate={CreateCorporation.corporationTrademarkValidate}
                                    value={values.corporation_trademark}
                                />
                                <Input
                                    autoFocus={values.vatChecked}
                                    error={!!errors.corporation_iec}
                                    label={intl.getMessage('credentials_iec')}
                                    name="corporation_iec"
                                    onChange={(e) => setFieldValue('corporation_iec', e)}
                                    placeholder={intl.getMessage('credentials_iec')}
                                    size="middle"
                                    type="text"
                                    validate={CreateCorporation.corporationInnValidate}
                                    value={values.corporation_iec}
                                />
                                <Input
                                    error={!!errors.corporation_legal_address}
                                    label={intl.getMessage('credentials_legal_address')}
                                    name="corporation_legal_address"
                                    onChange={(e) => setFieldValue('corporation_legal_address', e)}
                                    placeholder={intl.getMessage('credentials_legal_address')}
                                    size="middle"
                                    type="text"
                                    validate={CreateCorporation.corporationInnValidate}
                                    value={values.corporation_legal_address}
                                />
                                <Input
                                    error={!!errors.corporation_post_address}
                                    label={intl.getMessage('credentials_correspondence_address')}
                                    name="corporation_post_address"
                                    onChange={(e) => setFieldValue('corporation_post_address', e)}
                                    placeholder={intl.getMessage('credentials_correspondence_address')}
                                    size="middle"
                                    type="text"
                                    validate={CreateCorporation.corporationInnValidate}
                                    value={values.corporation_post_address}
                                />
                                <Input
                                    error={!!errors.corporation_head_position}
                                    label={intl.getMessage('credentials_head_position')}
                                    name="corporation_head_position"
                                    onChange={(e) => setFieldValue('corporation_head_position', e)}
                                    placeholder={intl.getMessage('credentials_head_position')}
                                    size="middle"
                                    type="text"
                                    validate={CreateCorporation.corporationInnValidate}
                                    value={values.corporation_head_position}
                                />
                                <Input
                                    error={!!errors.corporation_head_name}
                                    label={intl.getMessage('credentials_head_name')}
                                    name="corporation_head_name"
                                    onChange={(e) => setFieldValue('corporation_head_name', e)}
                                    placeholder={intl.getMessage('credentials_head_name')}
                                    size="middle"
                                    type="text"
                                    validate={CreateCorporation.corporationInnValidate}
                                    value={values.corporation_head_name}
                                />
                                <Input
                                    error={!!errors.corporation_operation_basis}
                                    label={intl.getMessage('credentials_operation_basis')}
                                    name="corporation_operation_basis"
                                    onChange={(e) => setFieldValue('corporation_operation_basis', e)}
                                    placeholder={intl.getMessage('credentials_operation_basis')}
                                    size="middle"
                                    type="text"
                                    validate={CreateCorporation.corporationInnValidate}
                                    value={values.corporation_operation_basis}
                                />
                                <Input
                                    optional
                                    error={!!errors.corporation_accounting_email}
                                    label={intl.getMessage('credentials_accounting_correspondence_email')}
                                    name="corporation_accounting_email"
                                    onChange={(e) => setFieldValue('corporation_accounting_email', e)}
                                    placeholder={intl.getMessage('credentials_accounting_correspondence_email')}
                                    size="middle"
                                    type="text"
                                    value={values.corporation_accounting_email}
                                />
                                <Input
                                    optional
                                    error={!!errors.corporation_government_contract_id}
                                    label={intl.getMessage('credentials_government_contract_identifier')}
                                    name="corporation_government_contract_id"
                                    onChange={(e) => setFieldValue('corporation_government_contract_id', e)}
                                    placeholder={intl.getMessage('credentials_government_contract_identifier')}
                                    size="middle"
                                    type="text"
                                    value={values.corporation_government_contract_id}
                                />
                                <Submit disabled={isSubmitting} />
                            </>
                        ) : (
                            <Submit
                                disabled={!values.corporation_inn || isSubmitting}
                                handleSubmit={handleCheck}
                                id="fetch-payer-details"
                                title={intl.getMessage('onboarding_check')}
                            />
                        )}
                    </form>
                );
            }}
        </Formik>
    );
};

export default CorporationForm;
