import React, { FC } from 'react';

import { Link, DashboardHeader, Button } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

interface HeaderProps {
    host: string;
}

const Header: FC<HeaderProps> = ({ host }) => {
    return (
        <DashboardHeader
            navigation={(
                <>
                    <Link to={RoutePath.DnsZones} className={theme.header.back}>
                        <Button
                            type="icon"
                            size="medium"
                            icon="arrow"
                            className={theme.button.rotate}
                        />
                    </Link>
                    <div className={theme.header.title}>
                        {host}
                    </div>
                </>
            )}
        />
    );
};

export default Header;
