import { Action } from 'store/actions';
import {
    ProfileActions,
    saveProfileInfo,
    saveLoginAttempts,
    savePayerInfo,
    savePhoneCodes,
    saveConfiguration,
} from 'Actions/profile';
import Profile from 'Entities/Profile';

import { ProfileStore, profileInitialState as initialState } from '../initialStates';

const reducer = (state: ProfileStore = initialState, action: Action): ProfileStore => {
    switch (action.type as ProfileActions) {
        case ProfileActions.SaveProfileInfo: {
            const { payload } = action as ReturnType<typeof saveProfileInfo>;
            const info: Profile = payload;
            return { ...state, info };
        }

        case ProfileActions.SaveLoginAttempts: {
            const { payload } = action as ReturnType<typeof saveLoginAttempts>;
            const loginAttempts = [...state.loginAttempts];
            loginAttempts.push(...payload);
            return { ...state, loginAttempts };
        }
        case ProfileActions.ClearLoginAttempts: {
            return { ...state, loginAttempts: [] };
        }
        case ProfileActions.SavePayerInfo: {
            const { payload } = action as ReturnType<typeof savePayerInfo>;
            return { ...state, profilePayer: [...payload] };
        }
        case ProfileActions.SavePhoneCodes: {
            const { payload } = action as ReturnType<typeof savePhoneCodes>;
            return { ...state, phoneCodes: payload };
        }
        case ProfileActions.SaveConfiguration: {
            const { payload } = action as ReturnType<typeof saveConfiguration>;
            return { ...state, configuration: payload };
        }

        default:
            return state;
    }
};

export default reducer;
