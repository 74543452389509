import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import { notification } from 'antd';

import ServerApi from 'Apis/servers';
import {
    BatchOperationProgressActions,
    getBatchOperationProgress as getBatchOperationProgressAct,
    saveBatchOperationProgress,
} from 'Actions/batchOperationProgress';
import { markOperationChecked, removeDoneProgress, saveServerOperationProgressBatch } from 'Actions/operationProgress';
import { getServerList } from 'Actions/server';
import ServerInfo, { IServerInfo } from 'Entities/ServerInfo';
import OperationProgress from 'Entities/OperationProgress';
import { IBatchOperationProgress } from 'Entities/BatchOperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';
import { IBatchErrorServer } from 'Lib/helpers/consts';
import { Store } from 'Store';

import { ServerStore } from '../initialStates';
import { addError, errorChecker } from './utils';

export function* getBatchOperationProgress(
    action: ReturnType<typeof getBatchOperationProgressAct>,
) {
    const { payload: { data, notificationId }, callback } = action;
    while (true) {
        yield delay(1000);
        const response: IBatchOperationProgress = yield call(
            ServerApi.getBatchOperationProgress, ...data,
        );
        const { error, result } = errorChecker<IBatchOperationProgress>(response);
        if (error) {
            yield addError(error, BatchOperationProgressActions.GetBatchOperationProgress);
            break;
        }
        const done: number[] = [];
        const errors: ServerInfo[] = [];
        const { servers }: {
            servers: ServerStore;
        } = yield select(
            (store: Store) => ({
                servers: store.server,
            }),
        );
        if (result) {
            const operations = result.operations.map((r) => (new OperationProgress(r)));
            yield put(saveServerOperationProgressBatch(operations));

            for (let i = 0; i < result.operations.length; i += 1) {
                const o = result.operations[i];
                if (o.status !== OperationStatus.PENDING) {
                    done.push(o.server_id);
                }
                if (o.status === OperationStatus.ERROR) {
                    const errorServer = servers.get(o.server_id);
                    if (errorServer) {
                        const errorServerInfo: IServerInfo = {
                            deleted: false,
                            distribution: errorServer.distribution.type,
                            id: errorServer.id,
                            internal_id: errorServer.internalId,
                            name: errorServer.name,
                            state: errorServer.state,
                            tenant_id: errorServer.tenantId,
                        };
                        errors.push(new ServerInfo(errorServerInfo));
                    }
                }
                if (o.status === OperationStatus.DONE) {
                    yield put(removeDoneProgress({ serverId: o.server_id, type: o.type }));
                }
            }

            const progress = (done.length / result.operations.length) * 100;
            if (progress === 100 && notificationId) {
                notification.close(notificationId);
            }
            yield put(saveBatchOperationProgress({
                errors,
                progress,
            }));

            if (done.length === result.operations.length) {
                yield put(getServerList());
                if (callback?.result) {
                    const errorServers: IBatchErrorServer[] | undefined = errors.length
                        ? errors.map(({ id, name }) => ({ id, name }))
                        : undefined;
                    callback.result(errorServers);
                    for (let i = 0; i < operations.length; i += 1) {
                        if (operations[i].status === OperationStatus.ERROR) {
                            const { serverId, tenantId, id, type } = operations[i];
                            yield put(removeDoneProgress({ serverId, type }));
                            yield put(markOperationChecked([tenantId, id]));
                        }
                    }
                }
                break;
            }
        }
    }
}

function* batchOperationProgressSaga(): Generator {
    yield takeEvery<ReturnType<typeof getBatchOperationProgressAct>>(
        BatchOperationProgressActions.GetBatchOperationProgress, getBatchOperationProgress,
    );
}

export default batchOperationProgressSaga;
