import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useTitle } from 'Hooks';
import { getDocuments } from 'Actions/account';
import { Checkbox, EmptyPageLayout, InnerPageLayout, ListLayout, LoaderPageLayout, useIntl } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import AccountDocument from 'Entities/AccountDocument';
import { AccountDocumentType } from 'Entities/AccountDocumentType';
import AccountDetails from 'Entities/AccountDetails';
import theme from 'Lib/theme';
import { Store } from 'Store';

import Header from './Header';
import Payment from './Payment';
import s from '../Billing.module.pcss';

interface DocumentsProps {
    details: AccountDetails | null;
    invoices: AccountDocument[] | null;
}

const Documents: FC<DocumentsProps> = ({ invoices, details }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [checked, setChecked] = useState(false);
    useTitle(intl.getMessage('documents_page_title'));

    useEffect(() => {
        dispatch(getDocuments());
    }, []);
    const getContent = () => {
        if (invoices === null) {
            return <LoaderPageLayout />;
        }

        const items = checked
            ? invoices?.filter((i) => i.documentType === AccountDocumentType.ACT)
            : invoices;

        if (invoices.length > 0) {
            return (
                <ListLayout
                    title={intl.getMessage('billing_documents')}
                    options={(
                        details?.showBillingDocuments && (
                            <div className={s.showActCheckbox}>
                                <Checkbox
                                    name="save_backup"
                                    checked={checked}
                                    handleChange={(e) => setChecked(e.target.checked)}
                                >
                                    {intl.getMessage('show_act_only')}
                                </Checkbox>
                            </div>
                        )
                    )}
                    mainTitle
                >
                    <div className={cn(theme.card.labels, theme.card.labels_invoice)}>
                        <div className={theme.card.label}>
                            {intl.getMessage('invoice_id_act')}
                        </div>
                        <div className={theme.card.label}>
                            {intl.getMessage('invoice_state')}
                        </div>
                        <div className={theme.card.label}>
                            {intl.getMessage('amount')}
                        </div>
                        <div className={theme.card.label}>
                            {intl.getMessage('date_period')}
                        </div>
                    </div>
                    {items.map((i: AccountDocument) => (
                        <Payment
                            key={i.documentId}
                            document={i}
                        />
                    ))}
                </ListLayout>
            );
        }

        return (
            <EmptyPageLayout
                title={intl.getMessage('invoice_empty_title')}
                desc={intl.getMessage('invoice_empty_desc')}
                linkText={intl.getMessage('billing_add_funds_short')}
                link={RoutePath.AddFunds}
            />
        );
    };

    return (
        <InnerPageLayout
            header={<Header details={details} />}
            className={theme.content.with_actions}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

const selectAccount = (store: Store) => store.account.details;
const selectInvoices = (store: Store) => store.account.documents;

const selector = createSelector(
    selectAccount,
    selectInvoices,
    (details, invoices) => ({
        details,
        invoices: invoices === null ? null : invoices,
    }),
);

const mapStateToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStateToProps)(Documents);
