import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateConfiguration } from 'Actions/server';
import Server from 'Entities/Server';
import ServerConfigurationUpdate from 'Entities/ServerConfigurationUpdate';

import RecommendationsCancelledContent from './RecommendationsCancelledContent';
import RecommendationsDefaultContent from './RecommendationsDefaultContent';

export enum ContentType {
    SERVER_DEFAULT = 'SERVER_DEFAULT',
    RECOMMENDATIONS_CANCELLED = 'RECOMMENDATIONS_CANCELLED',
    TARIFF_DEFAULT = 'TARIFF_DEFAULT',
}

interface RecommendationsChooseContentProps {
    server: Server;
    serverListPage?: boolean;
}

export interface DiffTariffValues {
    cpuCores?: number;
    diskGib?: number;
    memoryMib?: number;
    fixedPriceMonth?: number;
}

const RecommendationsChooseContent: FC<RecommendationsChooseContentProps> = ({
    server, serverListPage,
}) => {
    const [contentType, setContentType] = useState(
        serverListPage
            ? ContentType.SERVER_DEFAULT
            : ContentType.TARIFF_DEFAULT,
    );

    const {
        upgradeRecommendation,
        tariff,
    } = server;

    const dispatch = useDispatch();

    if (!upgradeRecommendation) {
        return null;
    }

    const applyNewTariff = () => {
        if (upgradeRecommendation.tariffId) {
            dispatch(updateConfiguration({
                server,
                serverConfigurationUpdate: new ServerConfigurationUpdate({
                    tariff_id: upgradeRecommendation.tariffId.toValue,
                }),
            }));
        } else {
            dispatch(updateConfiguration({
                server,
                serverConfigurationUpdate: new ServerConfigurationUpdate({
                    cpu_cores: upgradeRecommendation.cpuCores?.toValue,
                    disk_gib: upgradeRecommendation.diskGib?.toValue,
                    memory_mib: upgradeRecommendation.memoryMib?.toValue,
                }),
            }));
        }
    };

    const chooseContentType = (typePopover: string, idTenant: number, idServer: number) => {
        switch (typePopover) {
            case ContentType.SERVER_DEFAULT:
                return (
                    <RecommendationsDefaultContent
                        tenantId={idTenant}
                        serverId={idServer}
                        serverListPage={!!serverListPage}
                        setContentType={setContentType}
                        upgradeRecommendation={upgradeRecommendation}
                        currentTariff={tariff}
                        applyNewTariff={applyNewTariff}
                    />
                );
            case ContentType.RECOMMENDATIONS_CANCELLED:
                return (
                    <RecommendationsCancelledContent
                        tenantId={idTenant}
                        serverId={idServer}
                        serverListPage={!!serverListPage}
                        setContentType={setContentType}
                    />
                );
            case ContentType.TARIFF_DEFAULT:
                return (
                    <RecommendationsDefaultContent
                        tenantId={idTenant}
                        serverId={idServer}
                        serverListPage={!!serverListPage}
                        setContentType={setContentType}
                        upgradeRecommendation={upgradeRecommendation}
                        currentTariff={tariff}
                        applyNewTariff={applyNewTariff}
                    />
                );
            default:
                return (
                    <RecommendationsDefaultContent
                        tenantId={idTenant}
                        serverId={idServer}
                        serverListPage={!!serverListPage}
                        setContentType={setContentType}
                        upgradeRecommendation={upgradeRecommendation}
                        currentTariff={tariff}
                        applyNewTariff={applyNewTariff}
                    />
                );
        }
    };

    return chooseContentType(contentType, server.tenantId, server.id);
};

export default RecommendationsChooseContent;
