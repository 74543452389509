import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Tabs } from 'antd';

import { InnerPageLayout } from 'Common';
import { NOTIFICATIONS_STATE } from 'Lib/helpers/consts';
import theme from 'Lib/theme';

import { Header } from './components';
import Notifications from './Notifications';

const { TabPane } = Tabs;

const NotificationsContainer: FC = () => {
    const { params: { unread } } = useRouteMatch<{ unread: NOTIFICATIONS_STATE }>();

    const activeKey = unread === NOTIFICATIONS_STATE.UNREAD
        ? NOTIFICATIONS_STATE.UNREAD : NOTIFICATIONS_STATE.HISTORY;

    return (
        <InnerPageLayout
            header={<Header />}
            className={theme.content.with_actions}
        >
            <Tabs
                className="tabs tabs--hidden-bar"
                activeKey={activeKey}
            >
                <TabPane key={NOTIFICATIONS_STATE.UNREAD}>
                    <Notifications seen={false} />
                </TabPane>
                <TabPane key={NOTIFICATIONS_STATE.HISTORY}>
                    <Notifications seen />
                </TabPane>
            </Tabs>
        </InnerPageLayout>
    );
};

export default NotificationsContainer;
