import React, { FC, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';

import { clearStore, getServerStateEventsList } from 'Actions/serverStateEvents';
import { InnerPageLayout, useIntl, LoaderPageLayout, EmptyPageLayout } from 'Common';
import ServerStateEvent from 'Entities/ServerStateEvent';
import Server from 'Entities/Server';
import { useOffsetLoader, useTitle, useServerInProgress, useServerSearchSave } from 'Hooks';
import { Store } from 'Store';

import { Header, List } from './components';

interface ServerHistoryStoreProps {
    serverEvents: ServerStateEvent[] | null;
    server: Server;
}

const ServerHistory: FC<ServerHistoryStoreProps> = ({ serverEvents, server }) => {
    const intl = useIntl();
    useServerInProgress(server?.id);

    const pageTitle = server?.name
        ? intl.getMessage('server_history_page_title', { value: server.name })
        : intl.getMessage('page_title');
    useTitle(pageTitle);

    const { tenantId, id } = server;
    const dispatch = useDispatch();

    useServerSearchSave(server?.id);

    useOffsetLoader(
        serverEvents,
        (offset: number, limits: number) => getServerStateEventsList([
            tenantId,
            id,
            offset * limits,
            limits,
        ]),
    );

    useEffect(() => {
        return () => {
            dispatch(clearStore());
        };
    }, []);

    const getContent = () => {
        if (serverEvents === null) {
            return <LoaderPageLayout />;
        }

        if (serverEvents.length > 0) {
            return (
                <List items={serverEvents} />
            );
        }

        return (
            <EmptyPageLayout
                title={intl.getMessage('server_history_empty_title')}
                desc={intl.getMessage('server_history_empty_desc')}
            />
        );
    };

    return (
        <InnerPageLayout
            header={<Header server={server} />}
            title={intl.getMessage('history')}
            tooltip={intl.getMessage('server_history_info')}
            tooltipClassName="tooltip_title-small"
        >
            {getContent()}
        </InnerPageLayout>
    );
};

interface ServerHistoryContainerStoreProps {
    serverEvents: ServerStateEvent[] | null;
    server: Server | undefined;
}

type ServerHistoryOwnProps = RouteComponentProps<{ serverId: string }>;
type ServerHistoryProps = ServerHistoryContainerStoreProps & ServerHistoryOwnProps;

const ServerHistoryContainer: FC<ServerHistoryProps> = ({ server, serverEvents }) => {
    if (!server) {
        return null;
    }
    return <ServerHistory server={server} serverEvents={serverEvents} />;
};

const selectServer = (store: Store, ownProps: ServerHistoryOwnProps) => {
    const { match: { params: { serverId } } } = ownProps;
    return store.server.get(Number(serverId));
};
const selectServerHistory = (store: Store) => store.serverStateEvents;

const selector = createSelector(
    selectServerHistory,
    selectServer,
    (serverEvents, server) => ({
        serverEvents: serverEvents === null ? null : Array.from(serverEvents.values()),
        server,
    }),
);

const mapStateToProps = (store: Store, ownProps: ServerHistoryOwnProps) => ({
    ...selector(store, ownProps),
});

export default withRouter(connect(mapStateToProps)(ServerHistoryContainer));
