import DnsPtrRecord from 'Entities/DnsPtrRecord';
import reversDnsApi from 'Apis/reverseDns';
import { actionCreator } from '.';

export enum ReverseDnsActions {
    GetPtrRecords = 'GetPtrRecords',
    SavePtrRecords = 'SavePtrRecords',
    CreateOrUpdatePtrRecord = 'CreateOrUpdatePtrRecord',
    DeletePtrRecord = 'DeletePtrRecord',
}

const getPtrRecords = actionCreator(ReverseDnsActions.GetPtrRecords);
const savePtrRecords = actionCreator<DnsPtrRecord[]>(ReverseDnsActions.SavePtrRecords);

type CreateOrUpdatePtrRecord = Parameters<typeof reversDnsApi.createPtrRecord>;
const createOrUpdatePtrRecord = actionCreator<CreateOrUpdatePtrRecord>(
    ReverseDnsActions.CreateOrUpdatePtrRecord,
);

type DeletePtrRecord = Parameters<typeof reversDnsApi.deletePtrRecord>;
const deletePtrRecord = actionCreator<DeletePtrRecord>(
    ReverseDnsActions.DeletePtrRecord,
);

export {
    getPtrRecords,
    savePtrRecords,
    createOrUpdatePtrRecord,
    deletePtrRecord,
};
