import React, { FC, useState } from 'react';
import { Col, Row } from 'antd';
import cn from 'classnames';

import { useIntl, Icon, Link } from 'Common';
import AccountServersComparativeExpenses from 'Entities/AccountServersComparativeExpenses';
import { getServerIcon } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import { roundDiff, roundNumber } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

import DashboardLink from './DashboardLink';
import s from './Stats.module.pcss';

interface ServersProps {
    totalServersCount: number;
    comparativeExpensesDaily: AccountServersComparativeExpenses;
    comparativeExpensesMonthly: AccountServersComparativeExpenses;
}

const Servers: FC<ServersProps> = React.memo(({
    totalServersCount, comparativeExpensesDaily, comparativeExpensesMonthly,
}) => {
    const [monthPeriod, setMonthPeriod] = useState(true);
    const {
        serverComparativeExpenses,
        overallComparativeExpense,
    } = monthPeriod ? comparativeExpensesMonthly : comparativeExpensesDaily;

    const intl = useIntl();

    const handleChangeExpensesPeriod = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.stopPropagation();
        setMonthPeriod(!monthPeriod);
    };
    const blueText = (text: string) => (
        <span
            className={cn(theme.link.link, theme.link.blue)}
            onClick={(e) => handleChangeExpensesPeriod(e)}
        >
            {text}
        </span>
    );

    const getExpenseForecast = (dailyExpenseForecast: number) => {
        const str = intl.getMessage('price_simple', { value: roundNumber(dailyExpenseForecast) });
        return (
            dailyExpenseForecast > 0 ? str : <span className={theme.color.grayHeaders}>{str}</span>
        );
    };

    return (
        <div className={s.block}>
            {totalServersCount !== 0 ? (
                <div className={cn(s.info, s.info_servers)}>
                    <div className={s.title}>
                        {monthPeriod
                            ? intl.getMessage('expenses_per_month_marked', { b: blueText })
                            : intl.getMessage('expenses_per_day_marked', { b: blueText })}
                    </div>
                    <div className={cn(s.val, s.val_main)}>
                        <span className={s.valInner}>
                            {intl.getMessage('price_simple', { value: roundNumber(overallComparativeExpense.expenseForecast) })}
                        </span>
                        {overallComparativeExpense.expenseDiff !== 0 && (
                            <div className={cn(
                                s.diff,
                                { [s.diff_negative]:
                                    overallComparativeExpense.expenseDiff > 0 },
                                { [s.diff_up]:
                                    overallComparativeExpense.expenseDiff > 0 },
                                { [s.diff_positive]:
                                    overallComparativeExpense.expenseDiff < 0 },
                            )}
                            >
                                {overallComparativeExpense.expenseDiff > 0 && '+'}
                                {intl.getMessage('price_simple', { value: roundDiff(overallComparativeExpense.expenseDiff) })}
                                <Icon
                                    className={s.diffIcon}
                                    icon="arrow_down"
                                />
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className={s.info}>
                    <div className={cn(s.val, s.val_main)}>
                        <span className={s.valInner}>
                            {intl.getMessage('price_simple', { value: 0 })}
                        </span>
                    </div>
                    <div className={s.title}>
                        {intl.getMessage('daily_consumption')}
                    </div>
                </div>
            )}

            <div className={s.notifications}>
                {serverComparativeExpenses.length > 0 ? (
                    <>
                        <div className={cn(s.row, s.row_head)}>
                            <Row>
                                <Col span={12}>
                                    <div className={cn(
                                        s.col, s.col_head, s.label, s.label_head,
                                    )}
                                    >
                                        {intl.getMessage('server')}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div
                                        className={cn(s.col, s.label, s.label_head)}
                                    >
                                        {intl.getMessage('expenses')}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className={cn(
                                        s.col, s.col_last, s.label, s.label_head,
                                    )}
                                    >
                                        {intl.getMessage('diff')}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div>
                            {serverComparativeExpenses.map(({
                                expenseForecast,
                                expenseDiff,
                                expenseEntity,
                            }) => {
                                const serverInfo = expenseEntity?.serverInfo;
                                if (serverInfo) {
                                    const {
                                        id: serverId,
                                        name,
                                        tenantId,
                                        distribution,
                                    } = serverInfo;
                                    return (
                                        <Link
                                            to={RoutePath.Server}
                                            props={{ serverId, tenantId }}
                                            className={s.row}
                                            key={serverId}
                                            stop
                                        >
                                            <Row>
                                                <Col span={12}>
                                                    <div className={cn(s.serverName, s.col)}>
                                                        <Icon
                                                            className={s.serverIcon}
                                                            icon={getServerIcon(
                                                                distribution,
                                                            )}
                                                        />
                                                        {name}
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className={cn(s.col, s.serverName)}>
                                                        {getExpenseForecast(expenseForecast)}
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className={cn(s.col, s.col_last)}>
                                                        {expenseDiff !== 0 ? (
                                                            <div className={cn(
                                                                s.diff,
                                                                { [s.diff_negative]:
                                                                    expenseDiff > 0 },
                                                                { [s.diff_up]:
                                                                    expenseDiff > 0 },
                                                                { [s.diff_positive]:
                                                                    expenseDiff < 0 },
                                                            )}
                                                            >
                                                                {expenseDiff > 0 && '+'}
                                                                {intl.getMessage('price_simple', { value: roundDiff(expenseDiff) }) }
                                                                <Icon
                                                                    className={s.diffIcon}
                                                                    icon="arrow_down"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className={s.diff}>
                                                                {intl.getMessage('price_simple', { value: 0 }) }
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Link>
                                    );
                                }
                                return null;
                            })}
                            {(totalServersCount - serverComparativeExpenses.length) > 0 && (
                                <Link
                                    to={RoutePath.Expenses}
                                    className={cn(
                                        s.label,
                                        s.more,
                                        theme.link.link,
                                        theme.link.gray,
                                    )}
                                >
                                    {intl.getPlural('more_servers', totalServersCount - serverComparativeExpenses.length)}
                                </Link>
                            )}
                        </div>
                    </>
                ) : (
                    <div className={s.label}>
                        {intl.getMessage('dashboard_no_servers')}
                    </div>
                )}
            </div>
            <DashboardLink to={RoutePath.Expenses} />
        </div>
    );
});

export default Servers;
