import React, { FC } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';

import ServerStateEvent from 'Entities/ServerStateEvent';
import { ListLayout, useIntl, Icon } from 'Common';
import { serverStateTranslate } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';

interface ListProps {
    items: ServerStateEvent[];
}

const List: FC<ListProps> = ({ items }) => {
    const intl = useIntl();
    return (
        <ListLayout>
            <div className={cn(theme.card.labels, theme.card.labels_history)}>
                <div className={theme.card.label}>
                    {intl.getMessage('history_state')}
                </div>
                <div className={theme.card.label}>
                    <Icon icon="history" className={theme.card.dateIcon} />
                </div>
            </div>
            {items.map((item) => {
                const { timeAddedMillis, state } = item;
                const currentState = serverStateTranslate(intl, state);
                return (
                    <div
                        key={`${timeAddedMillis}${state}`}
                        className={cn(
                            theme.card.card,
                            theme.card.list,
                            theme.card.row,
                        )}
                    >
                        <div className={cn(theme.card.grid, theme.card.grid_history)}>
                            <div className={theme.common.textOverflow}>
                                {currentState}
                            </div>
                            <div className={theme.color.grayHeaders}>
                                {dayjs(timeAddedMillis).format(intl.getMessage('time_format_short_month_year'))}
                            </div>
                        </div>
                    </div>
                );
            })}
        </ListLayout>
    );
};

export default List;
