import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import { Store } from 'Store';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

import { Link, useIntl, Icon, Button } from 'Common';
import { externalLinkBuilder, GuestRoutePath, linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { Locale } from 'Lib/intl';
import theme from 'Lib/theme';
import Account from 'Entities/Account';

interface MainHeaderProps {
    account: Account | null;
}

const MainHeader: FC<MainHeaderProps> = () => {
    const intl = useIntl();
    const { currentLocale } = intl;
    const { params: { lang }, path } = useRouteMatch<{lang: Locale}>();

    const pathname = path.replace('/:lang/', `/${lang}/`);

    const Auth = () => (
        pathname === linkPathBuilder(lang, RoutePath.AuthRegistration) ? (
            <>
                <div className={theme.mainHeader.label}>
                    {intl.getMessage('header_already_registered')}
                </div>

                <Link to={RoutePath.Root}>
                    <Button
                        type="outlined"
                        size="big"
                    >
                        {intl.getMessage('login_button')}
                    </Button>
                </Link>
            </>
        ) : (
            <>
                <div className={theme.mainHeader.label}>
                    {intl.getMessage('header_not_registered')}
                </div>

                <Link to={RoutePath.AuthRegistration}>
                    <Button
                        type="outlined"
                        size="big"
                    >
                        {intl.getMessage('registration_button')}
                    </Button>
                </Link>
            </>
        )
    );

    return (
        <div className={theme.mainHeader.header}>
            <a
                href={externalLinkBuilder(currentLocale, GuestRoutePath.Welcome)}
                className={theme.mainHeader.logoLink}
            >
                <Icon icon="main_logo" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_desktop)} />
                <Icon icon="main_logo_dark" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_desktop, theme.mainHeader.logo_dark)} />
                <Icon icon="panel_logo" className={cn(theme.mainHeader.logo, theme.mainHeader.logo_mobile)} />
            </a>
            <div className={theme.mainHeader.subtitle}>
                {intl.getMessage('header_subtitle')}
            </div>
            <div className={theme.mainHeader.right}>
                <Auth />
            </div>
        </div>
    );
};

const selectAccount = (store: Store) => store.account.account;

const selector = createSelector([
    selectAccount,
], (account) => {
    return {
        account,
    };
});

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(MainHeader);
