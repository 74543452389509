import { ApiErrorCode } from 'Entities/ApiErrorCode';
import ApiError from 'Entities/Error';
import PublicKey, { IPublicKey } from 'Entities/PublicKey';
import PublicKeyCreate, { IPublicKeyCreate } from 'Entities/PublicKeyCreate';
import PublicKeyUpdate, { IPublicKeyUpdate } from 'Entities/PublicKeyUpdate';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class PubKeysApi {
    static async createPublicKey(tenant_id: number, publickeycreate: IPublicKeyCreate, optionalToken?: string): Promise<IPublicKey | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const publickeycreateValid = new PublicKeyCreate(publickeycreate);
        haveError.push(...publickeycreateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/pubkeys`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(publickeycreateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async deletePublicKey(tenant_id: number, key_id: number, optionalToken?: string): Promise<number | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/pubkeys/${key_id}`, {
            method: 'DELETE',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.status;
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPublicKeys(optionalToken?: string): Promise<IPublicKey[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/pubkeys`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async getPublicKeysForTenant(tenant_id: number, optionalToken?: string): Promise<IPublicKey[] | ApiError | Error> {
        const xReqId = String(Date.now());
        return await fetch(`/api/v1/tenants/${tenant_id}/pubkeys`, {
            method: 'GET',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
            },
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }

    static async updatePublicKey(tenant_id: number, key_id: number, publickeyupdate: IPublicKeyUpdate, optionalToken?: string): Promise<IPublicKey | ApiError | Error> {
        const xReqId = String(Date.now());
        const haveError: string[] = [];
        const publickeyupdateValid = new PublicKeyUpdate(publickeyupdate);
        haveError.push(...publickeyupdateValid.validate());
        if (haveError.length > 0) {
            const fields: any = {}
            haveError.forEach((key) => fields[key] = { error_code: ApiErrorCode.FIELD_WRONG_VALUE })
            return Promise.resolve(new ApiError({
                error_code: ApiErrorCode.FIELD_WRONG_VALUE,
                fields,
            }));
        }
        return await fetch(`/api/v1/tenants/${tenant_id}/pubkeys/${key_id}`, {
            method: 'POST',
            headers: {
                'X-Request-Id': xReqId,
                'X-Auth-Token': optionalToken || AUTH_TOKEN,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(publickeyupdateValid.serialize()),
        }).then(async (res) => {
            if (res.status === 200) {
                return res.json();
            } else {
                if (res.status !== 500 && res.status !== 401) {
                    const body = await res.json();
                    return new ApiError({ ...body, message: xReqId })
                } else {
                    return new Error(JSON.stringify({ status: String(res.status), xReqId }));
                }
            }
        })
    }
}
