import { takeEvery, put, call, delay } from 'redux-saga/effects';

import AccountApi from 'Apis/account';
import {
    AccountActions,
    cancelInvoice as cancelInvoiceAct,
    createAccountInvoice as createAccountInvoiceAct,
    createAccountNoCashInvoice as createAccountNoCashInvoiceAct,
    createAccountCardInvoice as createAccountCardInvoiceAct,
    createAccountPayer as createAccountPayerAct,
    deleteCreditCard as deleteCreditCardAct,
    deleteCreditCardLocal,
    getAccountDashboard as getAccountDashboardAct,
    getAccountDetails as getAccountDetailsAct,
    getAccountInvoice as getAccountInvoiceAct,
    getAccountVerificationAct as getAccountVerificationActA,
    getDocuments as getDocumentsAct,
    getCreditCards as getCreditCardsAct,
    reorderAccountCreditCards as reorderAccountCreditCardsAct,
    saveAccountDashboard,
    saveAccountDetails,
    saveDocuments,
    saveCreditCards,
    updateAutoTopUpSettings as updateAutoTopUpSettingsAct,
    activateCoupon as activateCouponAct,
    getAccountExpensesDetailed as getAccountExpensesDetailedAct,
    saveAccountExpensesDetailed,
    getAccountExpensesJSON as getAccountExpensesJSONAct,
    saveAccountExpensesJSON,
    getAccountableServers as getAccountableServersAct,
    saveAccountableServers,
} from 'Actions/account';
import AccountDocument, { IAccountDocument } from 'Entities/AccountDocument';
import AccountCreditCard, { IAccountCreditCard } from 'Entities/AccountCreditCard';
import AccountDashboard, { IAccountDashboard } from 'Entities/AccountDashboard';
import AccountDetails, { IAccountDetails } from 'Entities/AccountDetails';
import { IAccountInvoice } from 'Entities/AccountInvoice';
import { getProfileInfo } from 'Actions/profile';
import { getServersLastOperations, getServerList } from 'Actions/server';
import { IAccountVerificationActURL } from 'Entities/AccountVerificationActURL';
import { ICreateInvoiceResponse } from 'Entities/CreateInvoiceResponse';
import { InvoiceStatus } from 'Entities/InvoiceStatus';
import AccountExpensesDetailed, { IAccountExpensesDetailed } from 'Entities/AccountExpensesDetailed';
import Server, { IServer } from 'Entities/Server';
import { IAccountExpense } from 'Entities/AccountExpense';

import { errorChecker, addError } from './utils';

function* getAccountDetails(action: ReturnType<typeof getAccountDetailsAct>) {
    const response: IAccountDetails = yield call(AccountApi.getAccountDetails);
    const { error, result } = errorChecker<IAccountDetails>(response);
    if (result) {
        yield put(saveAccountDetails(new AccountDetails(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getCreditCards(action: ReturnType<typeof getCreditCardsAct>) {
    const response: IAccountCreditCard[] = yield call(AccountApi.getAccountCreditCards);
    const { error, result } = errorChecker<IAccountCreditCard[]>(response);
    if (result) {
        const cardEntities = result.map((s) => new AccountCreditCard(s));
        yield put(saveCreditCards(cardEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* deleteCreditCard(action: ReturnType<typeof deleteCreditCardAct>) {
    const { payload, payload: { cardId } } = action;
    const response: number = yield call(AccountApi.deleteAccountCreditCard, cardId);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(deleteCreditCardLocal(payload));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getDocuments(action: ReturnType<typeof getDocumentsAct>) {
    const response: IAccountDocument[] = yield call(AccountApi.getAccountDocuments);
    const { error, result } = errorChecker<IAccountDocument[]>(response);
    if (result) {
        const documentEntities = result.map((s) => new AccountDocument(s));
        yield put(saveDocuments(documentEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateAutoTopUpSettings(action: ReturnType<typeof updateAutoTopUpSettingsAct>) {
    const { payload, callback } = action;
    const response: number = yield call(AccountApi.updateAccountAutoTopUpSettings, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getAccountDetailsAct());
        yield put(getProfileInfo({ onlyInfo: true }));
        callback?.result?.();
    }
    if (error) {
        yield addError(error, action.type);
        callback?.error?.(error);
    }
}

function* reorderAccountCreditCards(action: ReturnType<typeof reorderAccountCreditCardsAct>) {
    const { payload } = action;
    const response: number = yield call(AccountApi.reorderAccountCreditCards, ...payload);
    const { result, error } = errorChecker<number>(response);
    if (result) {
        yield put(getCreditCardsAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getAccountInvoice(action: ReturnType<typeof getAccountInvoiceAct>) {
    const { payload: { req, callback }, callback: actionCallback } = action;
    while (true) {
        const response: IAccountInvoice = yield call(AccountApi.getAccountInvoice, ...req);
        const { error, result } = errorChecker<IAccountInvoice>(response);
        if (result && result.status !== InvoiceStatus.ERROR) {
            if (result.status === InvoiceStatus.SUCCESS || result.status === InvoiceStatus.VOIDED) {
                yield put(getCreditCardsAct());
                yield put(getAccountDetailsAct());
                yield put(getServersLastOperations());
                yield put(getServerList());
                yield put(getProfileInfo({ onlyInfo: true }));
                if (callback) {
                    callback();
                }
                break;
            }
            yield delay(1000);
        } else {
            actionCallback?.error?.();
            break;
        }
        if (error) {
            yield addError(error, action.type);
            break;
        }
    }
}

function* cancelInvoice(action: ReturnType<typeof cancelInvoiceAct>) {
    const { payload } = action;
    const response: number = yield call(AccountApi.cancelAccountInvoice, ...payload);
    const { result, error } = errorChecker<number>(response);
    if (result) {
        // yield put(getInvoicesAct());
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getAccountVerification(action: ReturnType<typeof getAccountVerificationActA>) {
    const { payload } = action;
    const response: IAccountVerificationActURL = yield call(
        AccountApi.getAccountVerificationAct, ...payload,
    );
    const { result, error } = errorChecker<IAccountVerificationActURL>(response);
    if (result) {
        const { download_url: link } = result;
        window.location.href = link;
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getAccountDashboard(action: ReturnType<typeof getAccountDashboardAct>) {
    const response: IAccountDashboard = yield call(AccountApi.getAccountDashboard);
    const { result, error } = errorChecker<IAccountDashboard>(response);
    if (result) {
        yield put(saveAccountDashboard(new AccountDashboard(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createAccountPayer(action: ReturnType<typeof createAccountPayerAct>) {
    const { payload, callback } = action;
    const response: IAccountDashboard = yield call(AccountApi.createAccountPayer, ...payload);
    const { result, error } = errorChecker<IAccountDashboard>(response);
    if (result && callback?.result) {
        yield put(getProfileInfo());
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* createAccountInvoice(action: ReturnType<typeof createAccountInvoiceAct>) {
    const { payload, callback } = action;
    const response: ICreateInvoiceResponse = yield call(
        AccountApi.createAccountInvoice, ...payload,
    );
    const { result, error } = errorChecker<ICreateInvoiceResponse>(response);
    if (result && callback?.result) {
        callback?.result(result);
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* createAccountNoCashInvoice(action: ReturnType<typeof createAccountNoCashInvoiceAct>) {
    const { payload, callback } = action;
    const response: ICreateInvoiceResponse = yield call(
        AccountApi.createAccountNoCashInvoice, ...payload,
    );
    const { result, error } = errorChecker<ICreateInvoiceResponse>(response);
    if (result && callback?.result) {
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error();
    }
}

function* createAccountCardInvoice(action: ReturnType<typeof createAccountCardInvoiceAct>) {
    const { payload, callback } = action;
    const response: IAccountInvoice = yield call(
        AccountApi.createAccountInvoiceWithCreditCard, ...payload,
    );
    const { result, error } = errorChecker<IAccountInvoice>(response);
    if (result && callback?.result) {
        callback?.result(result);
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* activateCoupon(action: ReturnType<typeof activateCouponAct>) {
    const { payload, callback } = action;
    const response: ICreateInvoiceResponse = yield call(AccountApi.activateCoupon, ...payload);
    const { result, error } = errorChecker<ICreateInvoiceResponse>(response);
    if (result && callback?.result) {
        callback?.result();
        yield put(getAccountDetailsAct());
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* getAccountExpensesDetailed(action: ReturnType<typeof getAccountExpensesDetailedAct>) {
    const { payload } = action;
    const response: IAccountExpensesDetailed = yield call(
        AccountApi.getAccountExpensesDetailed, ...payload,
    );
    const { result, error } = errorChecker<IAccountExpensesDetailed>(response);
    if (result) {
        yield put(saveAccountExpensesDetailed(new AccountExpensesDetailed(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getAccountExpensesJSON(action: ReturnType<typeof getAccountExpensesJSONAct>) {
    const { payload } = action;
    const response: IAccountExpense[] = yield call(AccountApi.getAccountExpensesStats, ...payload);
    const { result, error } = errorChecker<IAccountExpense[]>(response);
    if (Array.isArray(result)) {
        yield put(saveAccountExpensesJSON(result));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getAccountableServers(action: ReturnType<typeof getAccountableServersAct>) {
    const response: IServer[] = yield call(AccountApi.getClientAccountableServers);
    const { result, error } = errorChecker<IServer[]>(response);
    if (result) {
        const serverEntities = result.map((s) => new Server(s));
        yield put(saveAccountableServers(serverEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* accountSaga(): Generator {
    yield takeEvery<ReturnType <typeof getAccountDetailsAct>>(
        AccountActions.GetAccountDetails, getAccountDetails,
    );
    yield takeEvery<ReturnType <typeof getCreditCardsAct>>(
        AccountActions.GetCreditCards, getCreditCards,
    );
    yield takeEvery<ReturnType <typeof deleteCreditCardAct>>(
        AccountActions.DeleteCreditCard, deleteCreditCard,
    );
    yield takeEvery<ReturnType <typeof getDocumentsAct>>(
        AccountActions.GetDocuments, getDocuments,
    );
    yield takeEvery<ReturnType<typeof updateAutoTopUpSettingsAct>>(
        AccountActions.UpdateAutoTopUpSettings,
        updateAutoTopUpSettings,
    );
    yield takeEvery<ReturnType<typeof reorderAccountCreditCardsAct>>(
        AccountActions.ReorderAccountCreditCards,
        reorderAccountCreditCards,
    );
    yield takeEvery<ReturnType<typeof getAccountInvoiceAct>>(
        AccountActions.GetAccountInvoice,
        getAccountInvoice,
    );
    yield takeEvery<ReturnType<typeof cancelInvoiceAct>>(
        AccountActions.CancelInvoice, cancelInvoice,
    );
    yield takeEvery<ReturnType<typeof getAccountVerificationActA>>(
        AccountActions.GetAccountVerificationAct,
        getAccountVerification,
    );
    yield takeEvery<ReturnType<typeof getAccountDashboardAct>>(
        AccountActions.GetAccountDashboard, getAccountDashboard,
    );
    yield takeEvery<ReturnType<typeof createAccountPayerAct>>(
        AccountActions.CreateAccountPayer, createAccountPayer,
    );
    yield takeEvery<ReturnType<typeof createAccountInvoiceAct>>(
        AccountActions.CreateAccountInvoice, createAccountInvoice,
    );
    yield takeEvery<ReturnType<typeof createAccountNoCashInvoiceAct>>(
        AccountActions.CreateAccountNoCashInvoice,
        createAccountNoCashInvoice,
    );
    yield takeEvery<ReturnType<typeof createAccountCardInvoiceAct>>(
        AccountActions.CreateAccountCardInvoice,
        createAccountCardInvoice,
    );
    yield takeEvery<ReturnType<typeof activateCouponAct>>(
        AccountActions.ActivateCoupon,
        activateCoupon,
    );
    yield takeEvery<ReturnType<typeof getAccountExpensesDetailedAct>>(
        AccountActions.GetAccountExpensesDetailed,
        getAccountExpensesDetailed,
    );
    yield takeEvery<ReturnType<typeof getAccountExpensesJSONAct>>(
        AccountActions.GetAccountExpensesJSON,
        getAccountExpensesJSON,
    );
    yield takeEvery<ReturnType<typeof getAccountableServersAct>>(
        AccountActions.GetAccountableServers,
        getAccountableServers,
    );
}

export default accountSaga;
