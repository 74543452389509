import React, { FC, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { Select, Option, useIntl } from 'Common';
import { getTariffsList } from 'Actions/tariff';
import TariffEnt from 'Entities/Tariff';
import { NewServerFormValues } from 'Lib/helpers/newServer';
import ResourceLimits from 'Entities/ServerResourceLimits';
import Server from 'Entities/Server';
import { Store } from 'Store';
import { countLimits } from 'Lib/helpers/utils';
import { toGb } from 'Lib/helpers/helpers';
import theme from 'Lib/theme';

import StandardTariff from './StandardTariff';
import CustomTariff from './CustomTariff';

import s from './Tariff.module.pcss';

interface TariffOwnProps {
    setValues: (values: NewServerFormValues) => void;
    setFieldValue: (field: keyof NewServerFormValues, value: any) => void;
    values: NewServerFormValues;
    resLimits: ResourceLimits;
}
interface TariffStoreProps {
    tariffs: Map<number, TariffEnt>;
    servers: Server[];
}

type TariffProps = TariffOwnProps & TariffStoreProps;

const Tariff: FC<TariffProps> = ({
    resLimits, tariffs, setValues, setFieldValue, values, servers,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTariffsList(values.distribution_id));
    }, [values.distribution_id]);

    const tariffsArray = Array.from(tariffs.values());
    const currentTariff = tariffs.get(values.tariff_id);
    const latestServer = servers[0];

    const {
        minDiskGib,
        minMemoryMib,
    } = resLimits;
    useEffect(() => {
        if (tariffsArray.length > 0 && values.prevValues) {
            const foundTariff = tariffsArray.find((t) => !t.fixed)!;
            const { cpuCores, memoryMib, diskGib, includedIpCount } = foundTariff;
            setValues({
                ...values,
                prevValues: {
                    ...values.prevValues,
                    tariff_id: foundTariff.tariffId,
                    cpu_cores: cpuCores,
                    memory_mib: memoryMib,
                    disk_gib: diskGib,
                    ip_count: includedIpCount,
                },
            });
        }
        if (tariffsArray.length > 0
            && (
                values.tariff_id === -1
                || !tariffsArray.find((t) => t.tariffId === values.tariff_id)
            )
        ) {
            let foundTariff = tariffsArray[0];
            let notFixedValues = {} as any;
            if (values.qsIsFixed) {
                const tariff = tariffsArray
                    .find((t) => t.memoryMib === values.parsedSearch.memory_mib);
                foundTariff = tariff || foundTariff;
            } else {
                foundTariff = tariffsArray.find((t) => !t.fixed)!;
                const { parsedSearch } = values;

                const { cpuCores, memoryMib, diskGib, includedIpCount } = foundTariff;
                notFixedValues = {
                    cpu_cores: parsedSearch.cpu_cores && parsedSearch.cpu_cores < cpuCores
                        ? cpuCores : parsedSearch.cpu_cores,
                    memory_mib: parsedSearch.memory_mib && parsedSearch.memory_mib < memoryMib
                        ? memoryMib : parsedSearch.memory_mib,
                    disk_gib: parsedSearch.disk_gib && parsedSearch.disk_gib < diskGib
                        ? diskGib : parsedSearch.disk_gib,
                    ip_count: parsedSearch.ip_count && parsedSearch.ip_count < includedIpCount
                        ? includedIpCount : parsedSearch.ip_count,
                };
                notFixedValues = countLimits(resLimits, notFixedValues);
            }
            let memoryMib = notFixedValues.memory_mib || foundTariff.memoryMib;
            memoryMib = memoryMib < minMemoryMib ? minMemoryMib : memoryMib;
            setValues({
                ...values,
                tariff_id: foundTariff.tariffId,
                cpu_cores: notFixedValues.cpu_cores || foundTariff.cpuCores,
                memory_mib: memoryMib,
                disk_gib: notFixedValues.disk_gib || foundTariff.diskGib,
                ip_count: typeof notFixedValues.ip_count === 'number' ? notFixedValues.ip_count : 1,
            });
        }
    }, [tariffs]);

    if (!tariffsArray.length) {
        return null;
    }

    const setTariff = (id: number) => {
        setFieldValue('tariff_id', id);

        const selectedTariff = tariffs.get(id);
        if (selectedTariff?.fixed) {
            setValues({
                ...values,
                tariff_id: id,
                cpu_cores: selectedTariff.cpuCores,
                memory_mib: selectedTariff.memoryMib,
                disk_gib: selectedTariff.diskGib,
                ip_count: selectedTariff.includedIpCount,
            });
        }
    };

    const getTariffPrice = (tariff: TariffEnt) => {
        if (tariff.fixed) {
            return intl.getMessage('cost', { value: tariff.prices.fixedPriceMonth });
        }

        const { memoryPriceGib, diskPriceGib } = tariff.prices;
        const flexiblePrice = toGb(minMemoryMib) * memoryPriceGib + minDiskGib * diskPriceGib;

        return intl.getPlural('cost_start_from', flexiblePrice);
    };

    const getLatestBadge = (tariff: TariffEnt) => {
        if (latestServer?.tariff?.tariffId === tariff.tariffId) {
            return (
                <div className={cn(theme.Badge.badge, theme.Badge.last, s.badge)}>
                    {intl.getMessage('latest_choice')}
                </div>
            );
        }

        return null;
    };

    const getOption = (tariff: TariffEnt) => {
        const tariffPrice = getTariffPrice(tariff);

        return (
            <>
                <div className={s.tariffInfo}>
                    <div className={s.tariffName}>
                        {tariff.name}
                    </div>
                    <div className={s.tariffWrap}>
                        {tariff.fixed && (
                            <>
                                <div className={s.tariffData}>
                                    {intl.getMessage('value_gb', { value: toGb(tariff.memoryMib) })}
                                </div>
                                <div className={s.tariffData}>
                                    {intl.getPlural('core', tariff.cpuCores)}
                                </div>
                            </>
                        )}
                        <div className={cn(s.tariffPrice, s.mobile)}>
                            {tariffPrice}
                        </div>
                    </div>
                </div>
                <div className={s.tariffPrice}>
                    {tariffPrice}
                </div>
                {getLatestBadge(tariff)}
            </>
        );
    };

    return (
        <div data-tariff="block">
            <Select
                value={values.tariff_id}
                onChange={setTariff}
                className={s.select}
                optionLabelProp="label"
                listHeight={300}
                size="big"
                block
            >
                {tariffsArray.filter((t) => !t.fixed).map((t) => (
                    <Option
                        key={t.tariffId}
                        value={t.tariffId}
                        label={t.name}
                        className="option option_server"
                    >
                        {getOption(t)}
                    </Option>
                ))}
                {tariffsArray.filter((t) => t.fixed).map((t) => (
                    <Option
                        key={t.tariffId}
                        value={t.tariffId}
                        label={t.name}
                        className="option option_server"
                    >
                        {getOption(t)}
                    </Option>
                ))}
            </Select>
            {currentTariff && (
                currentTariff.fixed ? (
                    <StandardTariff tariff={currentTariff} />
                ) : (
                    <CustomTariff
                        resLimits={resLimits}
                        setFieldValue={setFieldValue}
                        values={values}
                    />
                )
            )}
        </div>
    );
};

interface TariffContainerOwnProps {
    resLimits: ResourceLimits | null;
}

type TariffContainerProps = Omit<TariffProps, 'resLimits'> & TariffContainerOwnProps;

const TariffContainer: FC<TariffContainerProps> = (props) => {
    const { resLimits } = props;

    if (!resLimits) {
        return null;
    }

    return <Tariff {...props} resLimits={resLimits} />;
};

const selectTariffs = (store: Store) => store.tariff;
const selectServer = (store: Store) => store.server;

const selector = createSelector(
    [selectTariffs, selectServer],
    (tariffs, servers) => ({
        tariffs,
        servers: Array.from(servers.values()),
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(TariffContainer);
