import React, { FC } from 'react';
import cn from 'classnames';

import { Dots } from 'Common';
import { ServerState } from 'Entities/ServerState';
import OperationProgress from 'Entities/OperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';
import { OperationType } from 'Entities/OperationType';

import s from './ServerIndicator.module.pcss';

interface ServerIndicatorProps {
    state: ServerState;
    progress?: OperationProgress | null;
    className?: string;
}

const ServerIndicator: FC<ServerIndicatorProps> = ({ state, progress, className }) => {
    if (progress?.status === OperationStatus.PENDING || progress?.type === OperationType.INSTALL) {
        return (
            <div className={cn(s.indicator, className)}>
                <Dots />
            </div>
        );
    }

    if (state === ServerState.RUNNING) {
        return (
            <div className={cn(s.indicator, className)}>
                <div className={cn(s.dot, s.dot_green)} />
            </div>
        );
    }

    return (
        <div className={cn(s.indicator, className)}>
            <div className={cn(s.dot, s.dot_red)} />
        </div>
    );
};

export default ServerIndicator;
