import { SecurityActionType } from './SecurityActionType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IServerPasswordUpdate {
    actionType?: SecurityActionType;
    password: string;
    security_code?: string;
    send_password: boolean;
}

export default class ServerPasswordUpdate {
    readonly _actionType: SecurityActionType | undefined;

    get actionType(): SecurityActionType | undefined {
        return this._actionType;
    }

    readonly _password: string;

    /**
     * Description: New superuser password
     * Example: ]u77EPd.
     */
    get password(): string {
        return this._password;
    }

    static get passwordMinLength() {
        return 8;
    }

    static get passwordMaxLength() {
        return 2147483647;
    }

    static passwordValidate(password: string): boolean {
        return (password.length >= 8) && (password.length <= 2147483647);
    }

    readonly _security_code: string | undefined;

    /** */
    get securityCode(): string | undefined {
        return this._security_code;
    }

    readonly _send_password: boolean;

    /** */
    get sendPassword(): boolean {
        return this._send_password;
    }

    static sendPasswordValidate(sendPassword: boolean): boolean {
        return typeof sendPassword === 'boolean';
    }

    constructor(props: IServerPasswordUpdate) {
        if (props.actionType) {
            this._actionType = props.actionType;
        }
        this._password = props.password.trim();
        if (typeof props.security_code === 'string') {
            this._security_code = props.security_code.trim();
        }
        this._send_password = props.send_password;
    }

    serialize(): IServerPasswordUpdate {
        const data: IServerPasswordUpdate = {
            password: this._password,
            send_password: this._send_password,
        };
        if (typeof this._actionType !== 'undefined') {
            data.actionType = this._actionType;
        }
        if (typeof this._security_code !== 'undefined') {
            data.security_code = this._security_code;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            password: (this._password.length >= 8) && (this._password.length <= 2147483647),
            send_password: typeof this._send_password === 'boolean',
            security_code: !this._security_code ? true : typeof this._security_code === 'string',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IServerPasswordUpdate): ServerPasswordUpdate {
        return new ServerPasswordUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        actionType: 'actionType',
        password: 'password',
        securityCode: 'security_code',
        sendPassword: 'send_password',
        }
;

    mergeDeepWith(props: Partial<ServerPasswordUpdate>): ServerPasswordUpdate {
        const updateData: Partial<IServerPasswordUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ServerPasswordUpdate) => {
            const updateKey = this.keys[key] as keyof IServerPasswordUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IServerPasswordUpdate, keyof IServerPasswordUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ServerPasswordUpdate({ ...this.serialize(), ...updateData });
    }
}
