import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';

import { useIntl, Icon, Checkbox } from 'Common';
import ExpenseSummary from 'Entities/ExpenseSummary';
import ExpenseSection from 'Entities/ExpenseSection';
import { CardAccounts } from 'Entities/CardAccounts';
import { getAccountTypeText } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';

import s from './ExpensesTable.module.pcss';

interface ExpensesTableProps {
    dailyExpenses: ExpenseSummary[];
    onlySubAccounts: boolean;
    setOnlySubAccounts: (v: boolean) => void;
}

const ExpensesTable: FC<ExpensesTableProps> = ({
    dailyExpenses, onlySubAccounts, setOnlySubAccounts,
}) => {
    const intl = useIntl();
    const [tableVisible, setTableVisible] = useState(true);

    const checkboxVisible = dailyExpenses.find((dailyExpense) => {
        return !!dailyExpense.expenses.find((e) => e.accountingItem === CardAccounts.SERVICE_TRANSFER);
    });

    if (dailyExpenses.length === 0) {
        return null;
    }

    const getExpensesList = (overallAmount: number, expenses: ExpenseSection[]) => (
        <div className={s.list}>
            {expenses.length !== 1 && (
                <div className={cn(s.row, s.row_heading)}>
                    <div className={s.rowLabel}>
                        {intl.getMessage('total_consumption')}
                    </div>
                    <div className={s.rowValue}>
                        {intl.getMessage('money', { value: overallAmount })}
                    </div>
                </div>
            )}
            {expenses.map(({
                sectionId, accountingItem, amount, softwareLicense, resellerClient,
            }) => (
                <div className={s.row} key={sectionId}>
                    <div className={s.rowLabel}>
                        {softwareLicense
                        || getAccountTypeText(intl, accountingItem)}
                    </div>
                    {accountingItem === CardAccounts.SERVICE_TRANSFER && resellerClient && (
                        <div className={s.subaccountInfo}>
                            <span className={s.subaccountInfo_email}>
                                {resellerClient.email}
                            </span>
                            <span className={s.subaccountInfo_id}>
                                {`#${resellerClient.clientId}`}
                            </span>
                        </div>
                    )}
                    <div className={s.rowValue}>
                        {intl.getMessage('money', { value: amount })}
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <>
            <div className={s.reveal} onClick={() => setTableVisible(!tableVisible)}>
                {intl.getMessage('expenses_details')}
                <Icon icon="down" className={cn(s.icon, { [s.icon_rotate]: tableVisible })} />
            </div>
            {dailyExpenses.map(({
                summaryId, expenses, overallAmount, reportTime,
            }, i) => {
                const date = dayjs(reportTime);
                return (
                    <div
                        className={cn(s.table, { [s.table_visible]: tableVisible })}
                        key={summaryId}
                    >
                        <div className={s.heading}>
                            <span className={s.headingDate}>
                                {date.format(intl.getMessage('time_format_day'))}&nbsp;
                                <span className={theme.color.grayHeaders}>
                                    {date.format(intl.getMessage('time_format_year'))}
                                </span>
                            </span>
                            {i === 0 && checkboxVisible && (
                                <div className={s.checkboxWrapper}>
                                    <Checkbox
                                        id="subaccount_expenses"
                                        name="show_subaccount_expenses"
                                        className={s.checkbox}
                                        checked={onlySubAccounts}
                                        handleChange={(e) => setOnlySubAccounts(e.target.checked)}
                                    >
                                        {intl.getMessage('subaccount_expenses')}
                                    </Checkbox>
                                </div>
                            )}
                        </div>
                        {getExpensesList(overallAmount, expenses)}
                    </div>
                );
            })}
        </>
    );
};

export default ExpensesTable;
