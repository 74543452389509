// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum OperationType {
    INSTALL = 'INSTALL',
    CLONE = 'CLONE',
    REINSTALL = 'REINSTALL',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE',
    START = 'START',
    RESET = 'RESET',
    REBOOT = 'REBOOT',
    DESTROY = 'DESTROY',
    SHUTDOWN = 'SHUTDOWN',
    PASSWORD_CHANGE = 'PASSWORD_CHANGE',
    PUBLIC_KEY_CHANGE = 'PUBLIC_KEY_CHANGE',
    MEMORY_CHANGE = 'MEMORY_CHANGE',
    DISK_CHANGE = 'DISK_CHANGE',
    TARIFF_CHANGE = 'TARIFF_CHANGE',
    BACKUP_POLICY_CHANGE = 'BACKUP_POLICY_CHANGE',
    CPU_CORES_CHANGE = 'CPU_CORES_CHANGE',
    IP_ADD = 'IP_ADD',
    IP_DELETE = 'IP_DELETE',
    REVERT_TO_BACKUP = 'REVERT_TO_BACKUP',
    DOWNLOAD_BACKUP = 'DOWNLOAD_BACKUP',
    REVERT_TO_SNAPSHOT = 'REVERT_TO_SNAPSHOT',
    CREATE_SNAPSHOT = 'CREATE_SNAPSHOT',
    DELETE_SNAPSHOT = 'DELETE_SNAPSHOT',
    DOWNLOAD_SNAPSHOT = 'DOWNLOAD_SNAPSHOT',
    UNSUPPORTED = 'UNSUPPORTED'
}
