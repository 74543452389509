import React, { FC } from 'react';

import { InnerPageLayout, EmptyPageLayout, useIntl } from 'Common';
import { EmptyHeader } from 'Components/Server/components';
import { RoutePath } from 'Lib/helpers/routes';

const ServerNotExist: FC = () => {
    const intl = useIntl();

    return (
        <InnerPageLayout header={<EmptyHeader />}>
            <EmptyPageLayout
                title={intl.getMessage('empty_server_title')}
                linkText={intl.getMessage('create_new_server')}
                link={RoutePath.NewServer}
                imageType="servers"
            />
        </InnerPageLayout>
    );
};

export default ServerNotExist;
