import React, { FC, useState, useRef } from 'react';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { Icon, useIntl } from 'Common';
import { RESOURCES_TYPES } from 'Lib/helpers/consts';
import { IServerJsonStats } from 'Entities/ServerJsonStats';
import Server from 'Entities/Server';
import theme from 'Lib/theme';

import { ChartContext, ChartType } from './ChartContext';

import s from './ChartTabs.module.pcss';
import ChartContainer from './ChartContainer';

interface ChartTabsProps {
    data: IServerJsonStats[];
    charts: ChartType<IServerJsonStats>[];
    server: Server;
}

const ChartTabs: FC<ChartTabsProps> = ({
    data,
    charts,
    server,
}) => {
    const intl = useIntl();
    const tabs = useRef<HTMLDivElement>(null);
    const [activeTab, setActiveTab] = useState<RESOURCES_TYPES>(RESOURCES_TYPES.DISK_OPERATION_MB);

    const onTabChange = (tab: RESOURCES_TYPES) => {
        setActiveTab(tab);
    };

    const dropdownContent = (
        <div className={cn(theme.dropdown.content, theme.dropdown.content_medium)}>
            {charts.map((i) => (
                <button
                    type="button"
                    key={String(i.type)}
                    onClick={() => onTabChange(i.type)}
                    className={theme.dropdown.listItem}
                >
                    {i.name}
                </button>
            ))}
        </div>
    );

    return (
        <div className={s.tabs}>
            <div className={s.nav} ref={tabs}>
                <Dropdown
                    overlay={dropdownContent}
                    placement="bottomRight"
                    trigger={['click']}
                >
                    <div
                        className={cn(
                            theme.dropdown.trigger,
                            theme.dropdown.trigger_desktop,
                            theme.link.link,
                            theme.link.black,
                        )}
                    >
                        {intl.getMessage('disk_operations_title')}
                        <Icon icon="down" className={theme.dropdown.down} />
                    </div>
                </Dropdown>
            </div>

            {charts.map((t) => (
                <div key={String(t.type)}>
                    {activeTab === t.type && (
                        <ChartContext.Provider
                            value={{
                                data,
                                chart: t,
                                server,
                            }}
                        >
                            <ChartContainer />
                        </ChartContext.Provider>
                    )}
                </div>
            ))}
        </div>
    );
};

export default ChartTabs;
