import React, { FC, useState } from 'react';
import { connect } from 'react-redux';

import { useIntl, Select, Option, Input } from 'Common';
import DnsRecordCreate from 'Entities/DnsRecordCreate';
import { TTL_VALUE, TTL_CUSTOM_VALUES } from 'Lib/helpers/consts';
import { Store } from 'Store';

import s from './Form.module.pcss';

interface TTLSelectStoreProps {
    defaultTtl: number | undefined;
}
interface TTLSelectOwnProps {
    value: number | TTL_CUSTOM_VALUES;
    onChange: (value: number) => void;
    error: boolean;
}
type TTLSelectProps = TTLSelectStoreProps & TTLSelectOwnProps;
const TtlSelect: FC<TTLSelectProps> = ({ value, onChange, defaultTtl, error }) => {
    const intl = useIntl();
    const isValueIncluded = TTL_VALUE.includes(value as number);
    const [customTtlValue, setCustomTtlValue] = useState<string | null>(isValueIncluded ? null : `${value}`);

    const onSelectChange = (v: number | TTL_CUSTOM_VALUES) => {
        // in case of Auto value
        if (v === TTL_CUSTOM_VALUES.AUTO) {
            onChange(defaultTtl!);
            setCustomTtlValue(null);
        } else if (v === TTL_CUSTOM_VALUES.OTHER) {
            onChange(-1);
            setCustomTtlValue('');
        } else {
            onChange(v);
            setCustomTtlValue(null);
        }
    };
    const onCustomChange = (v: string) => {
        onChange(Number(v));
        setCustomTtlValue(v);
    };

    const currentValue = isValueIncluded && customTtlValue === null
        ? value : TTL_CUSTOM_VALUES.OTHER;

    return (
        <>
            <div className={s.group}>
                <div className={s.label}>
                    {intl.getMessage('dns_ttl')}
                </div>

                <Select
                    block
                    id="ttl"
                    size="medium"
                    className="select--setting"
                    placeholder={intl.getMessage('dns_ttl')}
                    value={currentValue}
                    onChange={onSelectChange}
                    showScroll
                >
                    <Option value={TTL_CUSTOM_VALUES.AUTO}>
                        {intl.getMessage('ttl_auto')}
                    </Option>
                    {TTL_VALUE.map((ttl) => (
                        <Option key={ttl} value={ttl}>
                            {intl.getMessage('seconds_value', { seconds: ttl })}
                        </Option>
                    ))}
                    <Option value={TTL_CUSTOM_VALUES.OTHER}>
                        {intl.getMessage('ttl_other')}
                    </Option>
                </Select>
            </div>
            {(currentValue === TTL_CUSTOM_VALUES.OTHER || customTtlValue !== null) && (
                <div className={s.group}>
                    <Input
                        value={customTtlValue || ''}
                        onChange={onCustomChange}
                        type="number"
                        validate={(e) => DnsRecordCreate.ttlSecondsValidate(Number(e))}
                        error={error}
                        name="custom_ttl"
                        placeholder={intl.getMessage('ttl_other_placeholder')}
                        size="middle"
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = (store: Store) => {
    return {
        defaultTtl: store.dns.settings?.defaultTtlSeconds,
    };
};

export default connect(mapStateToProps)(TtlSelect);
