// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum DistributionFamily {
    DEBIAN = 'DEBIAN',
    UBUNTU = 'UBUNTU',
    SUSE = 'SUSE',
    CENTOS = 'CENTOS',
    ROCKY = 'ROCKY',
    FEDORA = 'FEDORA',
    WINDOWS = 'WINDOWS',
    UNKNOWN = 'UNKNOWN'
}
