import { takeEvery, put, call, delay, select, fork } from 'redux-saga/effects';

import { ActionType } from 'Actions/.';
import serverBackupsApi from 'Apis/backups';
import serverApi from 'Apis/servers';
import {
    ServerBackupActions,
    getServerBackupsList as getServerBackupsListAct,
    saveServerBackupsList,
    saveBackupProgress,
    deleteBackupProgress,
    rollbackBackup as rollbackBackupAct,
    downloadBackup as downloadBackupAct,
    getBackupDownloadLink as getBackupDownloadLinkAct,
} from 'Actions/serverBackup';
import { getServerOperationProgress } from 'Actions/operationProgress';
import { getServer } from 'Actions/server';

import ServerBackup, { IServerBackup } from 'Entities/ServerBackup';
import { IOperationInfo } from 'Entities/OperationInfo';
import Server from 'Entities/Server';
import OperationProgress, { IOperationProgress } from 'Entities/OperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';
import { OperationType } from 'Entities/OperationType';
import BackupDownloadLink, { IBackupDownloadLink } from 'Entities/BackupDownloadLink';
import { Store } from 'Store';

import { errorChecker, addError } from './utils';

function* getServerBackupList(action: ReturnType<typeof getServerBackupsListAct>) {
    const { payload } = action;
    const response: IServerBackup[] = yield call(serverBackupsApi.listBackups, ...payload);
    const { error, result } = errorChecker<IServerBackup[]>(response);
    if (result) {
        const tariffEntities = result.map((t) => new ServerBackup(t));
        yield put(saveServerBackupsList(tariffEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

export function* getProgress(
    info: IOperationInfo, server: Server, backupId: string, type: ActionType,
) {
    while (true) {
        const response: IOperationProgress = yield call(
            serverApi.getOperationProgress,
            server.tenantId,
            info.id,
        );
        const { error, result } = errorChecker<IOperationProgress>(response);
        if (result) {
            yield put(saveBackupProgress({
                progress: new OperationProgress(result),
                backupId,
            }));
            if (result.status !== OperationStatus.PENDING) {
                yield put(getServer({
                    serverId: server.id,
                    tenantId: server.tenantId,
                }));
                yield put(getServerBackupsListAct([server.tenantId, server.id]));
                if (result.status === OperationStatus.DONE
                    && result.type !== OperationType.DOWNLOAD_BACKUP
                ) {
                    yield put(deleteBackupProgress(backupId));
                }
                break;
            }
            yield delay(1000);
        } else if (error) {
            yield addError(error, type);
        }
    }
}

function* downloadBackup(action: ReturnType<typeof downloadBackupAct>) {
    const { payload, callback } = action;
    const [, serverId, backupId] = payload;
    const server: Server = yield select((store: Store) => store.server.get(serverId));
    const response: IOperationInfo = yield call(serverBackupsApi.downloadBackup, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield fork(getProgress, response, server, backupId, action.type);
        if (callback?.result) {
            callback.result();
        }
    } else if (error) {
        if (callback?.error) {
            callback.error(error);
            return;
        }
        yield addError(error, action.type);
    }
}

function* getBackupDownloadLink(action: ReturnType<typeof getBackupDownloadLinkAct>) {
    const { payload, callback } = action;
    const response: IBackupDownloadLink = yield call(serverBackupsApi.getBackupDownloadLink, ...payload);
    const { error, result } = errorChecker<IBackupDownloadLink>(response);
    if (result) {
        callback?.result?.(new BackupDownloadLink(result));
    } else if (error) {
        callback?.error?.();
    }
}

function* rollbackBackup(action: ReturnType<typeof rollbackBackupAct>) {
    const { payload, callback } = action;
    const [, serverId] = payload;
    const server: Server = yield select((store: Store) => store.server.get(serverId));
    const response: IOperationInfo = yield call(serverBackupsApi.rollbackBackup, ...payload);
    const { error, result } = errorChecker<IOperationInfo>(response);
    if (result) {
        yield put(getServerOperationProgress({
            serverId,
            tenantId: server.tenantId,
            operationId: result.id,
            operationType: OperationType.REVERT_TO_BACKUP,
        }));
        if (callback?.result) {
            callback.result();
        }
    } else if (error) {
        if (callback?.error) {
            callback.error();
            return;
        }
        yield addError(error, action.type);
    }
}

function* serverBackupSaga(): Generator {
    yield takeEvery<ReturnType<typeof getServerBackupsListAct>>(
        ServerBackupActions.GetServerBackups, getServerBackupList,
    );
    yield takeEvery<ReturnType<typeof downloadBackupAct>>(
        ServerBackupActions.DownloadBackup, downloadBackup,
    );
    yield takeEvery<ReturnType<typeof rollbackBackupAct>>(
        ServerBackupActions.RollbackBackup, rollbackBackup,
    );
    yield takeEvery<ReturnType<typeof getBackupDownloadLinkAct>>(
        ServerBackupActions.GetBackupDownloadLink, getBackupDownloadLink,
    );
}

export default serverBackupSaga;
