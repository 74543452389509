import React, { FC, useState } from 'react';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { useIntl, Switch, Link, mediumText, Button } from 'Common';
import { deleteTriggerRule, updateTriggerRule } from 'Actions/notifications';
import NotificationRecipient from 'Entities/NotificationRecipient';
import TriggerEnt from 'Entities/Trigger';
import { TriggerRuleType } from 'Entities/TriggerRuleType';
import { RoutePath } from 'Lib/helpers/routes';
import { triggerDescriptionTranslate } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';

import ActionSpan from './ActionSpan';
import Recipients from './Recipients';
import DeleteTriggerModal from './DeleteTriggerModal';
import s from './Trigger.module.pcss';

interface TriggerProps {
    trigger: TriggerEnt;
    recipients: NotificationRecipient[];
}
const Trigger: FC<TriggerProps> = ({ trigger, recipients }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = () => {
        dispatch(deleteTriggerRule([trigger.id]));
    };

    const changeEnabled = (disabled: boolean) => {
        dispatch(updateTriggerRule([trigger.id, { disabled }]));
    };

    const { recipientSettings, serversScope, type } = trigger;

    if (recipientSettings.length > 0 && recipients.length === 0) {
        return null;
    }

    const showActionSpan = type !== TriggerRuleType.HTTP
        && type !== TriggerRuleType.MIN_BALANCE;

    return (
        <div className={cn(theme.card.card, theme.card.rounded, s.wrap)}>
            <div className={s.control}>
                <div className={s.switchBlock}>
                    <Switch
                        id={String(trigger.id)}
                        checked={!trigger.disabled}
                        handleChange={(e) => changeEnabled(!e.target.checked)}
                        labelType="large"
                    />
                    <div className={theme.common.textOverflow}>
                        {trigger.name}
                    </div>
                </div>
                <div className={s.actionBlock}>
                    <Link
                        className={s.editButton}
                        to={RoutePath.EditTrigger}
                        props={{ id: trigger.id }}
                    >
                        <Button
                            type="icon"
                            size="medium"
                            icon="edit"
                            className={cn(
                                s.add,
                                theme.button.only_icon,
                            )}
                            title={intl.getMessage('triggers_update')}
                        />
                    </Link>
                    <Button
                        type="icon"
                        size="medium"
                        icon="delete"
                        className={cn(
                            s.add,
                            theme.button.only_icon,
                        )}
                        title={intl.getMessage('trigger_delete')}
                        dataAttrs={{ 'data-enabled': 'enabled' }}
                        onClick={() => setShowDeleteModal(true)}
                    />
                </div>
            </div>
            <div className={s.desc}>
                {triggerDescriptionTranslate(intl, trigger.type, {
                    value: trigger.value,
                    min: trigger.periodMinutes,
                    url: trigger.url,
                    b: mediumText,
                })}
            </div>
            <Row gutter={[48, 0]}>
                {showActionSpan && (
                    <Col className={s.triggerAction}>
                        <ActionSpan serversScope={serversScope} />
                        <div className={s.label}>
                            {intl.getMessage('triggers_action_span')}
                        </div>
                    </Col>
                )}

                <Col className={s.notificationsRecipients}>
                    <Recipients recipients={recipients} recipientSettings={recipientSettings} />
                    <div className={s.label}>
                        {intl.getMessage('notifications_recipients')}
                    </div>
                </Col>
            </Row>

            {showDeleteModal && (
                <DeleteTriggerModal
                    visible={showDeleteModal}
                    name={trigger.name}
                    handleDelete={handleDelete}
                    handleClose={() => setShowDeleteModal(false)}
                />
            )}
        </div>
    );
};

export default Trigger;
