import { Action } from 'store/actions';
import { BatchOperationProgressActions, saveBatchOperationProgress } from 'Actions/batchOperationProgress';

import { BatchOperationProgressStore, batchOperationProgressInitialState as initialState } from '../initialStates';

const reducer = (
    state: BatchOperationProgressStore = initialState,
    action: Action,
): BatchOperationProgressStore => {
    switch (action.type as BatchOperationProgressActions) {
        case BatchOperationProgressActions.SaveBatchOperationProgress: {
            const { payload } = action as ReturnType<typeof saveBatchOperationProgress>;
            return { ...state, ...payload };
        }
        case BatchOperationProgressActions.RemoveDoneProgressBatch: {
            return { ...initialState };
        }
        default:
            return state;
    }
};

export default reducer;
