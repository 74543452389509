import React, { FC, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateServer } from 'Actions/server';
import serverApi from 'Apis/servers';
import { Link, useIntl, CommonModalLayout, Select, Option, Button, Loader } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import Server from 'Entities/Server';
import Tenant, { ITenant } from 'Entities/Tenant';
import { errorChecker } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import s from './Modals.module.pcss';

interface ModalServerMoveProps {
    close: () => void;
    server: Server;
}

const ModalServerMove: FC<ModalServerMoveProps> = (props) => {
    const {
        close,
        server,
    } = props;

    const intl = useIntl();
    const { id: serverId, tenantId } = server;
    const [options, setOptions] = useState<Tenant[] | null>(null);
    const [currentTenantId, setCurrentTenantId] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {
        let canUpdate = true;
        const loader = async () => {
            const resp = await serverApi.getTenantsToMoveServer(tenantId, serverId);
            const { result } = errorChecker<ITenant[]>(resp);
            if (result && canUpdate) {
                const tenants = result.map((rs) => new Tenant(rs));
                setOptions(tenants);
            }
            // TODO: handle error localy from errorChecker;
        };
        loader();
        return () => {
            canUpdate = false;
        };
    }, []);

    const handleOk = () => {
        dispatch(updateServer({ server, update: { to_tenant_id: currentTenantId! } }));
        close();
    };

    const getContent = () => {
        if (options === null) {
            return (
                <div className={theme.modal.search}>
                    <Loader circle mini />
                </div>
            );
        }

        if (options.length > 0) {
            return (
                <>
                    <p className={s.text}>
                        {intl.getMessage('server_to_other_tenant_modal_text')}
                    </p>
                    <Select
                        value={currentTenantId}
                        onChange={(v) => setCurrentTenantId(v)}
                        placeholder={intl.getMessage('tenant_select_placeholder')}
                        size="big"
                        block
                    >
                        {options.map((item) => (
                            <Option value={item.id} key={item.id}>
                                {item.description}
                            </Option>
                        ))}
                    </Select>
                </>
            );
        }

        return (
            <>
                <div className={theme.modal.desc}>
                    {intl.getMessage('server_to_other_tenant_modal_empty')}
                </div>
                <Link to={RoutePath.NewProject}>
                    <Button type="link" size="medium">
                        {intl.getMessage('projectlist_create')}
                    </Button>
                </Link>
            </>
        );
    };

    return (
        <CommonModalLayout
            visible
            title={intl.getMessage('move_to_project')}
            buttonText={intl.getMessage('server_to_other_tenant_modal_ok')}
            handleSubmit={handleOk}
            handleClose={close}
            noFooter={!!options && options?.length <= 0}
            disabled={!currentTenantId}
        >
            {getContent()}
        </CommonModalLayout>
    );
};

export default ModalServerMove;
