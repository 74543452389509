import { CardAccounts } from './CardAccounts';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountExpenseRequest {
    accounting_item?: CardAccounts;
    date_from_millis: number;
    date_to_millis: number;
    server_ids?: number[];
}

export default class AccountExpenseRequest {
    readonly _accounting_item: CardAccounts | undefined;

    get accountingItem(): CardAccounts | undefined {
        return this._accounting_item;
    }

    readonly _date_from_millis: number;

    /**
     * Description: Date from (inclusive), as millis since Epoch
     * Example: 1612904400000
     */
    get dateFromMillis(): number {
        return this._date_from_millis;
    }

    static dateFromMillisValidate(dateFromMillis: number): boolean {
        return typeof dateFromMillis === 'number';
    }

    readonly _date_to_millis: number;

    /**
     * Description: Date to (inclusive), as millis since Epoch
     * Example: 1613077199999
     */
    get dateToMillis(): number {
        return this._date_to_millis;
    }

    static dateToMillisValidate(dateToMillis: number): boolean {
        return typeof dateToMillis === 'number';
    }

    readonly _server_ids: number[] | undefined;

    /** */
    get serverIds(): number[] | undefined {
        return this._server_ids;
    }

    constructor(props: IAccountExpenseRequest) {
        if (props.accounting_item) {
            this._accounting_item = props.accounting_item;
        }
        this._date_from_millis = props.date_from_millis;
        this._date_to_millis = props.date_to_millis;
        if (props.server_ids) {
            this._server_ids = props.server_ids;
        }
    }

    serialize(): IAccountExpenseRequest {
        const data: IAccountExpenseRequest = {
            date_from_millis: this._date_from_millis,
            date_to_millis: this._date_to_millis,
        };
        if (typeof this._accounting_item !== 'undefined') {
            data.accounting_item = this._accounting_item;
        }
        if (typeof this._server_ids !== 'undefined') {
            data.server_ids = this._server_ids;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            server_ids: !this._server_ids ? true : this._server_ids.reduce((result, p) => result && typeof p === 'number', true),
            date_from_millis: typeof this._date_from_millis === 'number',
            date_to_millis: typeof this._date_to_millis === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountExpenseRequest): AccountExpenseRequest {
        return new AccountExpenseRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        accountingItem: 'accounting_item',
        dateFromMillis: 'date_from_millis',
        dateToMillis: 'date_to_millis',
        serverIds: 'server_ids',
        }
;

    mergeDeepWith(props: Partial<AccountExpenseRequest>): AccountExpenseRequest {
        const updateData: Partial<IAccountExpenseRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountExpenseRequest) => {
            const updateKey = this.keys[key] as keyof IAccountExpenseRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountExpenseRequest, keyof IAccountExpenseRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountExpenseRequest({ ...this.serialize(), ...updateData });
    }
}
