import React, { FC } from 'react';
import cn from 'classnames';

import { Button, useIntl } from 'Common';
import Server from 'Entities/Server';
import OperationProgress from 'Entities/OperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';
import { ServerState } from 'Entities/ServerState';
import theme from 'Lib/theme';

import ServerItem from './ServerItem';
import s from './List.module.pcss';

interface ListProps {
    servers: Server[];
    progress: Map<number, OperationProgress>;
    visibleList: boolean;
    setVisibleList: (v: boolean) => void;
}

const List: FC<ListProps> = ({ servers, progress, visibleList, setVisibleList }) => {
    const intl = useIntl();

    const serverProgress = new Map<number, OperationProgress>();
    progress.forEach((p) => {
        if (p.status === OperationStatus.PENDING) {
            serverProgress.set(p.serverId, p);
        }
    });

    const countWeight = (server: Server) => {
        if (serverProgress.get(server.id)) {
            return 2;
        }
        if (server.state === ServerState.RUNNING) {
            return 1;
        }
        return -1;
    };

    const serversAvailable = servers.length > 0;

    return (
        <>
            <div
                className={cn(s.header, { [s.header_withServers]: serversAvailable })}
                onClick={() => setVisibleList(!visibleList)}
            >
                <div className={s.label}>
                    {intl.getPlural('servers', servers.length)}
                </div>
                {serversAvailable && (
                    <Button
                        type="icon"
                        size="medium"
                        icon="down"
                        className={cn(
                            s.button,
                            {
                                [s.button_active]: visibleList,
                                [theme.button.active]: visibleList,
                            },
                        )}
                    />
                )}
            </div>
            {serversAvailable && visibleList && (
                <div className={s.list}>
                    {servers.sort((a, b) => countWeight(b) - countWeight(a)).map((server) => (
                        <ServerItem
                            key={server.id}
                            server={server}
                            progress={progress.get(server.id)}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default List;
