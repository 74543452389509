import React, { FC } from 'react';

import { useIntl } from 'Common';
import Tariff from 'Entities/Tariff';
import ServerCostForecast from 'Entities/ServerCostForecast';
import ServerDistribution from 'Entities/ServerDistribution';
import { SERVER_PROCESSOR_NAME } from 'Lib/helpers/consts';
import { getGb } from 'Lib/helpers/utils';

import s from './Info.module.pcss';

interface InfoProps {
    memory: number;
    disk: number;
    cpuCores: number;
    distribution: ServerDistribution;
    tariff: Tariff | undefined;
    costForecast: ServerCostForecast;
    tenantName?: string;
}

const Info: FC<InfoProps> = React.memo(({
    memory,
    disk,
    cpuCores,
    distribution,
    costForecast,
    tariff,
    tenantName,
}) => {
    const { description } = distribution;
    const intl = useIntl();

    const memoryValue = getGb(memory);
    const memoryMessage = `${intl.getMessage('value_gb', { value: memoryValue })} RAM`;
    const coresMessage = intl.getPlural('core', cpuCores);
    const diskMessage = `${intl.getMessage('value_gb', { value: disk })} SSD`;

    const data = [
        {
            title: tenantName ? intl.getMessage('tenant') : intl.getMessage('server_os'),
            text: tenantName || description,
        },
        {
            title: intl.getMessage('config'),
            text: (
                <>
                    <span className={s.noBr}>
                        {SERVER_PROCESSOR_NAME}
                    </span><span>, </span>
                    <span className={s.noBr}>
                        {coresMessage}
                    </span><span>, </span>
                    <span className={s.noBr}>
                        {memoryMessage}
                    </span><span>, </span>
                    <span className={s.noBr}>
                        {diskMessage}
                    </span>
                </>
            ),
        },
        {
            title: intl.getMessage('server_expense'),
            text: intl.getMessage('cost', { value: Math.round(costForecast.overallCost) }),
        },
        {
            title: intl.getMessage('tariff'),
            text: tariff?.name || intl.getMessage('unavailable_tariff'),
        },
    ];

    return (
        <div className={s.list}>
            {data.map((item) => (
                <div className={s.row} key={item.title}>
                    <div className={s.key}>
                        {item.title}
                    </div>
                    <div className={s.value}>
                        {item.text}
                    </div>
                </div>
            ))}
        </div>
    );
});

export default Info;
