import React, { FC, useEffect } from 'react';

import CustomTariffRows from 'Common/Server/CustomTariffRows';
import Server from 'Entities/Server';
import ServerConfigurationUpdate from 'Entities/ServerConfigurationUpdate';
import ServerResourceLimits from 'Entities/ServerResourceLimits';
import { useResLimits } from 'Hooks';
import FormHelper from 'Lib/helpers/newServer';

interface CustomTariffOwnProps {
    server: Server;
    values: Required<ServerConfigurationUpdate>;
    setValues: (v: ServerConfigurationUpdate) => void;
    setChanged: (c: boolean) => void;
}
interface CustomTariffHookProps {
    resLimits: ServerResourceLimits;
}

type CustomTariffProps = CustomTariffOwnProps & CustomTariffHookProps;

const CustomTariff: FC<CustomTariffProps> = ({
    resLimits, server, values, setValues, setChanged,
}) => {
    const { memoryMib, diskGib, cpuCores } = values;
    const updateValues = (field: keyof ServerConfigurationUpdate) => (value: number) => {
        setChanged(true);
        setValues(values.mergeDeepWith({ [field]: value }));
    };
    useEffect(() => {
        const countedMinMemory = FormHelper.countMinMemory(
            diskGib!,
            resLimits.memoryDiskRate,
            resLimits.stepMemoryMib,
        );
        if (memoryMib! < countedMinMemory) {
            updateValues('memoryMib')(countedMinMemory);
        }
    }, [memoryMib, diskGib]);
    return (
        <CustomTariffRows
            resLimits={resLimits}
            memory={memoryMib!}
            setMemory={updateValues('memoryMib')}
            processor={cpuCores!}
            setProcessor={updateValues('cpuCores')}
            disk={diskGib!}
            setDisk={updateValues('diskGib')}
            minDisk={server.diskGib}
        />
    );
};

const CustomTariffContainer: FC<CustomTariffOwnProps> = (props) => {
    const { server } = props;
    const { id } = server.distribution;
    const resLimits = useResLimits(server.tenantId, id);
    if (!resLimits) {
        return null;
    }
    return <CustomTariff {...props} resLimits={resLimits} />;
};

export default CustomTariffContainer;
