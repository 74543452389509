import { Action } from 'Actions/.';
import {
    ResellerActions,
    saveResellerClients,
    saveResellerCompletePayments,
    saveResellerDetails,
    saveResellerProcessingPayments,
} from 'Actions/reseller';

import { ResellerStore, resellerInitialState as initialState } from '../initialStates';

const reducer = (state: ResellerStore = initialState, action: Action): ResellerStore => {
    switch (action.type as ResellerActions) {
        case ResellerActions.SaveResellerDetails: {
            const { payload } = action as ReturnType<typeof saveResellerDetails>;
            return { ...state, details: payload };
        }

        case ResellerActions.SaveResellerProcessingPayments: {
            const { payload } = action as ReturnType<typeof saveResellerProcessingPayments>;
            return { ...state, paymentsIncomplete: payload };
        }
        case ResellerActions.SaveResellerCompletePayments: {
            const { payload } = action as ReturnType<typeof saveResellerCompletePayments>;
            const newPayment = [...state.paymentsComplete, ...payload];
            return { ...state, paymentsComplete: newPayment };
        }
        case ResellerActions.SaveResellerClients: {
            const { payload } = action as ReturnType<typeof saveResellerClients>;
            return { ...state, clients: payload };
        }
        case ResellerActions.ClearResellerCompletePayments: {
            return { ...state, paymentsComplete: [] };
        }
        default:
            return state;
    }
};

export default reducer;
