import { Action } from 'store/actions';
import { TenantUsersActions, saveTenantsUsersList } from 'Actions/tenantUsers';
import TenantUser from 'Entities/TenantUser';

import { TenantUsersStore, tenantUserInitialState as initialState } from '../initialStates';

const reducer = (state: TenantUsersStore = initialState, action: Action): TenantUsersStore => {
    switch (action.type as TenantUsersActions) {
        case TenantUsersActions.SaveTenantsUsers: {
            const { payload } = action as ReturnType<typeof saveTenantsUsersList>;
            const tu = new Map<number, TenantUser[]>();

            payload.forEach((s) => {
                const tenantUsers = tu.get(s.tenantId);
                if (tenantUsers && tenantUsers.length > 0) {
                    tenantUsers.push(s);
                    tu.set(s.tenantId, [...tenantUsers]);
                } else {
                    tu.set(s.tenantId, [s]);
                }
            });
            return tu;
        }
        default:
            return state;
    }
};

export default reducer;
