import React, { FC, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Popover } from 'antd';
import cn from 'classnames';

import Actions from 'Common/Server/Actions';
import { forceInstall } from 'Actions/server';
import { useIntl, Icon, RecommendationsChooseContent } from 'Common';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import OperationProgress from 'Entities/OperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';
import Server from 'Entities/Server';
import Profile from 'Entities/Profile';
import { SERVER_VIEW } from 'Lib/helpers/consts';
import { RoutePath } from 'Lib/helpers/routes';
import PayerInfo from 'Entities/PayerInfo';
import { ServerListContext } from 'Components/ServersList/factories';
import Tenant from 'Entities/Tenant';
import { DistributionFamily } from 'Entities/DistributionFamily';

import s from './Header.module.pcss';

import TrialHint from './TrialHint';
import { ServerName } from './ServerName';
import {
    SetupState,
    ErrorState,
    CommonHeader,
} from './HeaderState';

interface HeaderProps {
    server: Server;
    setupState?: boolean;
    operationProgress: OperationProgress | undefined;
    profile: Profile | null;
    payer: PayerInfo[] | null;
    tenant?: Tenant;
}

const Header: FC<HeaderProps> = React.memo(({
    server,
    operationProgress,
    setupState,
    profile,
    payer,
    tenant,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { serverView, showCheckbox } = useContext(ServerListContext);
    const [popoverVisible, setPopoverVisible] = useState(false);

    if (!tenant) {
        return null;
    }
    const {
        status,
        type,
        error,
        tenantId,
        serverId,
        id: operationId,
    } = operationProgress || {};
    const {
        costForecast: { overallCost },
    } = server;
    const isPending = status === OperationStatus.PENDING;
    const isCompactView = serverView !== SERVER_VIEW.EXPAND;

    if (setupState && isPending) {
        return (
            <SetupState
                server={server}
                progress={operationProgress!}
                compact={isCompactView}
                tenant={tenant}
            />
        );
    }

    if (setupState && status === OperationStatus.ERROR) {
        let link: RoutePath | null = null;
        if (error?.errorCode === ApiErrorCode.SETUP_NOT_ENOUGH_BALANCE
            || error?.errorCode === ApiErrorCode.NOT_ENOUGH_BALANCE) {
            link = RoutePath.AddFunds;
        }
        if (profile?.trialBonusAvailable) {
            link = RoutePath.OnboardingAddFundsServer;
        }
        if (payer?.length === 0) {
            link = RoutePath.OnboardingServer;
        }
        const onClick = () => {
            dispatch(forceInstall([tenantId!, serverId!, operationId!]));
        };

        if (
            error?.errorCode === ApiErrorCode.VM_WINDOWS_CREATE_TEST_DISABLED
            || (
                error?.errorCode === ApiErrorCode.SETUP_NOT_ENOUGH_BALANCE
                && profile?.trialBonusActive
                && server.distribution.type === DistributionFamily.WINDOWS
            )
        ) {
            return (
                <CommonHeader
                    server={server}
                    className={s.header_trial}
                    tenant={tenant}
                    disabled
                >
                    <TrialHint
                        progress={operationProgress!}
                        server={server}
                        compact={isCompactView}
                    />
                </CommonHeader>
            );
        }

        return (
            <ErrorState
                server={server}
                error={error!}
                onboardingLink={link}
                onClick={onClick}
                progress={operationProgress!}
                compact={isCompactView}
                tenant={tenant}
            />
        );
    }

    return (
        <div
            className={cn(
                s.header,
                { [s.header_compact]: serverView === SERVER_VIEW.COMPACT },
                { [s.header_list]: serverView === SERVER_VIEW.LIST },
                { [s.header_select]: showCheckbox },
            )}
        >
            <ServerName
                server={server}
                tenant={tenant}
                operationProgress={operationProgress}
            />
            <div className={s.column}>
                {serverView !== SERVER_VIEW.COMPACT && (
                    <>
                        {server.upgradeRecommendation ? (
                            <Popover
                                placement="bottomLeft"
                                trigger="hover"
                                content={(
                                    <RecommendationsChooseContent
                                        server={server}
                                        serverListPage
                                    />
                                )}
                                visible={popoverVisible}
                                onVisibleChange={(v) => setPopoverVisible(v)}
                                overlayClassName={cn(
                                    s.popoverWrapperServers,
                                    { popoverWrapper: true },
                                )}
                                getPopupContainer={(triggerNode) => triggerNode}
                            >
                                <div className={s.costWrapper}>
                                    <div className={s.cost}>
                                        {intl.getMessage('cost', { value: Math.round(overallCost) })}
                                    </div>
                                    <div className={s.notice}>
                                        <Icon
                                            icon="attention_no_border"
                                            className={s.attention}
                                        />
                                    </div>
                                </div>
                            </Popover>
                        ) : (
                            <div className={s.cost}>
                                {intl.getMessage('cost', { value: Math.round(overallCost) })}
                            </div>
                        )}
                    </>
                )}
                {!showCheckbox && (
                    <Actions
                        server={server}
                        size="medium"
                        operationType={type}
                        compact={serverView === SERVER_VIEW.COMPACT}
                    />
                )}
            </div>
        </div>
    );
});

export default Header;
