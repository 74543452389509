import React, { FC } from 'react';

import { useIntl } from 'Common';

import ErrorLayout from './components/ErrorLayout';

const ErrorNotFound: FC = () => {
    const intl = useIntl();

    return (
        <ErrorLayout
            noHeader
            code={404}
            title={intl.getMessage('not_found_title')}
            desc={intl.getMessage('not_found_desc')}
        />
    );
};

export default ErrorNotFound;
