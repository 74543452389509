import React, { FC } from 'react';
import cn from 'classnames';

import { ListLayout, Payment, useIntl } from 'Common';
import ResellerPayment from 'Entities/ResellerPayment';
import PartnerPayment from 'Entities/PartnerPayment';
import theme from 'Lib/theme';

interface FinanceTableProps {
    complete: ResellerPayment[] | PartnerPayment[];
    incomplete: ResellerPayment[] | PartnerPayment[];
}

const FinanceTable: FC<FinanceTableProps> = ({
    incomplete,
    complete,
}) => {
    const intl = useIntl();

    const labels = (
        <div
            className={cn(
                theme.card.labels,
                theme.card.labels_withActions,
                theme.card.labels_payment,
            )}
        >
            <div className={theme.card.label}>
                {intl.getMessage('status')}
            </div>
            <div className={theme.card.label}>
                {intl.getMessage('amount')}
            </div>
            <div className={theme.card.label}>
                {intl.getMessage('created_at')}
            </div>
        </div>
    );

    return (
        <>
            {incomplete.length > 0 && (
                <ListLayout
                    title={intl.getMessage('partners_processing_payment')}
                    info={intl.getMessage('partners_processing_payment_desc')}
                    length={incomplete.length}
                >
                    {labels}
                    {incomplete.map((u) => (
                        <Payment key={`${u.timeAddedMillis}${u.amount}`} payment={u} />
                    ))}
                </ListLayout>
            )}
            {complete.length > 0 && (
                <ListLayout
                    title={intl.getMessage('partners_completed_payment')}
                    info={intl.getMessage('partners_completed_payment_desc')}
                    length={complete.length}
                >
                    {labels}
                    {complete.map((u) => (
                        <Payment key={`${u.timeAddedMillis}${u.amount}`} payment={u} />
                    ))}
                </ListLayout>
            )}
        </>
    );
};

export default FinanceTable;
