import dayjs, { Dayjs } from 'dayjs';
import qs from 'qs';

import { getRelativeRange, parseRelativeDate, relativeFromValues } from 'Lib/helpers/helpers';
import { QueryParams } from 'Lib/helpers/routes';
import { DATE_RANGE_TYPES } from 'Lib/helpers/consts';
import Server from 'Entities/Server';

type Params = Partial<Record<QueryParams, string>>;

interface InitialDates {
    from: Dayjs;
    till: Dayjs;
    name: DATE_RANGE_TYPES | null;
}

export const getInitialRange = (server: Server): InitialDates => {
    const params: Params = qs.parse(window.location.search.replace('?', ''));
    const dates: InitialDates = {
        from: dayjs().startOf('day'),
        till: dayjs(),
        name: DATE_RANGE_TYPES.TODAY,
    };

    const { from, till } = params;

    if (from) {
        const parsedRelativeFrom = parseRelativeDate(from);

        if (parsedRelativeFrom) {
            dates.from = parsedRelativeFrom;
            const rangeName = relativeFromValues[from];
            if (rangeName) {
                dates.name = rangeName;
            }
        } else if (dayjs(Number(from)).isValid()) {
            dates.from = dayjs(Number(from));
            dates.name = null;
        }
    }

    if (till) {
        const parsedRelativeTill = parseRelativeDate(till);

        if (parsedRelativeTill) {
            dates.till = parsedRelativeTill;
        } else if (dayjs(Number(till)).isValid()) {
            dates.till = dayjs(Number(till));
        }
    }

    if (!from && !till) {
        const rangeNameFromStorage = UserStorage.getServerRange(server);

        if (rangeNameFromStorage) {
            const relativeRange = getRelativeRange(rangeNameFromStorage);

            if (!relativeRange.from || !relativeRange.till) {
                return dates;
            }

            const parsedRelativeFrom = parseRelativeDate(relativeRange.from);
            const parsedRelativeTill = parseRelativeDate(relativeRange.till);

            if (parsedRelativeFrom) {
                dates.from = parsedRelativeFrom;
                dates.name = rangeNameFromStorage;
            }

            if (parsedRelativeTill) {
                dates.till = parsedRelativeTill;
            }
        }
    }

    return dates;
};
