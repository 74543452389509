// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISnapshotDelete {
    delete_children: boolean;
}

export default class SnapshotDelete {
    readonly _delete_children: boolean;

    /** */
    get deleteChildren(): boolean {
        return this._delete_children;
    }

    static deleteChildrenValidate(deleteChildren: boolean): boolean {
        return typeof deleteChildren === 'boolean';
    }

    constructor(props: ISnapshotDelete) {
        this._delete_children = props.delete_children;
    }

    serialize(): ISnapshotDelete {
        const data: ISnapshotDelete = {
            delete_children: this._delete_children,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            delete_children: typeof this._delete_children === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISnapshotDelete): SnapshotDelete {
        return new SnapshotDelete(props);
    }

    readonly keys: { [key: string]: string } = {
        deleteChildren: 'delete_children',
        }
;

    mergeDeepWith(props: Partial<SnapshotDelete>): SnapshotDelete {
        const updateData: Partial<ISnapshotDelete> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SnapshotDelete) => {
            const updateKey = this.keys[key] as keyof ISnapshotDelete;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISnapshotDelete, keyof ISnapshotDelete>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SnapshotDelete({ ...this.serialize(), ...updateData });
    }
}
