import { ClientType } from './ClientType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IOrganizationDetailsRequest {
    client_type: ClientType;
    inn: string;
}

export default class OrganizationDetailsRequest {
    readonly _client_type: ClientType;

    get clientType(): ClientType {
        return this._client_type;
    }

    static clientTypeValidate(clientType: ClientType): boolean {
        return Object.keys(ClientType).includes(clientType);
    }

    readonly _inn: string;

    /**
     * Description: Payer INN
     * Example: 1234567890
     */
    get inn(): string {
        return this._inn;
    }

    static innValidate(inn: string): boolean {
        return typeof inn === 'string' && !!inn.trim();
    }

    constructor(props: IOrganizationDetailsRequest) {
        this._client_type = props.client_type;
        this._inn = props.inn.trim();
    }

    serialize(): IOrganizationDetailsRequest {
        const data: IOrganizationDetailsRequest = {
            client_type: this._client_type,
            inn: this._inn,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            inn: typeof this._inn === 'string' && !!this._inn.trim(),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IOrganizationDetailsRequest): OrganizationDetailsRequest {
        return new OrganizationDetailsRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        clientType: 'client_type',
        inn: 'inn',
        }
;

    mergeDeepWith(props: Partial<OrganizationDetailsRequest>): OrganizationDetailsRequest {
        const updateData: Partial<IOrganizationDetailsRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof OrganizationDetailsRequest) => {
            const updateKey = this.keys[key] as keyof IOrganizationDetailsRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IOrganizationDetailsRequest, keyof IOrganizationDetailsRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new OrganizationDetailsRequest({ ...this.serialize(), ...updateData });
    }
}
