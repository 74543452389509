import { BackupPolicyFrequency } from './BackupPolicyFrequency';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITenantCostForecastRequest {
    backup_frequency?: BackupPolicyFrequency;
    backup_quantity?: number;
}

export default class TenantCostForecastRequest {
    readonly _backup_frequency: BackupPolicyFrequency | undefined;

    get backupFrequency(): BackupPolicyFrequency | undefined {
        return this._backup_frequency;
    }

    readonly _backup_quantity: number | undefined;

    /**
     * Description: Number of preserved backups
     * Example: 1
     */
    get backupQuantity(): number | undefined {
        return this._backup_quantity;
    }

    constructor(props: ITenantCostForecastRequest) {
        if (props.backup_frequency) {
            this._backup_frequency = props.backup_frequency;
        }
        if (typeof props.backup_quantity === 'number') {
            this._backup_quantity = props.backup_quantity;
        }
    }

    serialize(): ITenantCostForecastRequest {
        const data: ITenantCostForecastRequest = {
        };
        if (typeof this._backup_frequency !== 'undefined') {
            data.backup_frequency = this._backup_frequency;
        }
        if (typeof this._backup_quantity !== 'undefined') {
            data.backup_quantity = this._backup_quantity;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            backup_quantity: !this._backup_quantity ? true : typeof this._backup_quantity === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITenantCostForecastRequest): TenantCostForecastRequest {
        return new TenantCostForecastRequest(props);
    }

    readonly keys: { [key: string]: string } = {
        backupFrequency: 'backup_frequency',
        backupQuantity: 'backup_quantity',
        }
;

    mergeDeepWith(props: Partial<TenantCostForecastRequest>): TenantCostForecastRequest {
        const updateData: Partial<ITenantCostForecastRequest> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof TenantCostForecastRequest) => {
            const updateKey = this.keys[key] as keyof ITenantCostForecastRequest;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITenantCostForecastRequest, keyof ITenantCostForecastRequest>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new TenantCostForecastRequest({ ...this.serialize(), ...updateData });
    }
}
