import React, { FC, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import tariffApi from 'Apis/tariffs';
import serversApi from 'Apis/servers';
import { useIntl, Select, Option, Button, RecommendationsChooseContent } from 'Common';
import Tariff, { ITariff } from 'Entities/Tariff';
import Server from 'Entities/Server';
import ServerConfigurationUpdate from 'Entities/ServerConfigurationUpdate';
import ServerResourceLimits from 'Entities/ServerResourceLimits';
import ServerCostForecast, { IServerCostForecast } from 'Entities/ServerCostForecast';
import { errorChecker } from 'Lib/helpers/utils';
import { toGb } from 'Lib/helpers/helpers';
import { useResLimits } from 'Lib/hooks/hooks';
import FormHelper from 'Lib/helpers/newServer';
import theme from 'Lib/theme';

import s from './ChangeTariff.module.pcss';
import { CustomTariff, FixedTariff, RebootNotify } from '.';

interface ChangeTariffOwnProps {
    handleSubmit: (tariff: ServerConfigurationUpdate) => void;
    server: Server;
    currentTariff: Tariff;
}

interface ChangeTariffHookProps {
    resLimits: ServerResourceLimits;
}

type ChangeTariffProps = ChangeTariffOwnProps & ChangeTariffHookProps;

const ChangeTariff: FC<ChangeTariffProps> = ({
    handleSubmit, server, currentTariff, resLimits,
}) => {
    const intl = useIntl();
    const [showRebootNotify, setShowRebootNotify] = useState(false);

    const {
        minDiskGib,
        minMemoryMib,
    } = resLimits;

    const [options, setOptions] = useState<Tariff[]>([]);
    const [price, setPrice] = useState<number>(0);
    const [changed, setChanged] = useState(false);
    const [newTariff, setNewTariff] = useState(
        new ServerConfigurationUpdate({
            ...currentTariff.serialize(),
            ...server.serialize(),
            allow_restart: true,
        }),
    );

    const { diskGib, memoryMib, cpuCores, tariffId } = newTariff;

    useEffect(() => {
        let canUpdate = true;
        const loader = async () => {
            const { id, tenantId } = server;
            const response = await tariffApi.listTariffsForServer(tenantId, id);
            const { result } = errorChecker<ITariff[]>(response);
            if (result && canUpdate) {
                const tariffs = result.map((r) => new Tariff(r));
                setOptions(tariffs);
            }
            // TODO: handle error locally from errorChecker
        };
        loader();
        return () => {
            canUpdate = false;
        };
    }, [server.id, server.tenantId]);

    const notFixedTariff = options.find((t) => !t.fixed);

    useEffect(() => {
        let canceled = false;
        const loadPrice = async () => {
            let response = null;
            response = await serversApi.calculateCostForecastForServer(
                server.tenantId,
                server.id,
                {
                    tariff_id: tariffId,
                    disk_gib: diskGib,
                    memory_mib: memoryMib,
                    cpu_cores: cpuCores,
                    ip_count: server.ipCount,
                },
            );
            const { result } = errorChecker<IServerCostForecast>(response);
            if (!canceled && result) {
                const sPrice = new ServerCostForecast(result);
                setPrice(sPrice.overallCost);
            }
        };
        loadPrice();
        return () => {
            canceled = true;
        };
    }, [
        tariffId,
        diskGib,
        memoryMib,
        cpuCores,
    ]);

    const onSubmit = () => {
        if (newTariff.tariffId === currentTariff.tariffId
            && !currentTariff.fixed
            && !options.find((t) => t.tariffId === currentTariff.tariffId)
        ) {
            handleSubmit(newTariff);
            return;
        }
        if (newTariff.tariffId !== notFixedTariff?.tariffId) {
            handleSubmit(new ServerConfigurationUpdate({ tariff_id: newTariff.tariffId }));
        } else {
            handleSubmit(newTariff);
        }
        setShowRebootNotify(false);
    };

    const checkSubmit = () => {
        if (newTariff.cpuCores !== server.cpuCores
            || (newTariff.memoryMib && newTariff.memoryMib < server.memoryMib)) {
            setShowRebootNotify(true);
        } else {
            onSubmit();
        }
    };

    const onReset = () => {
        setNewTariff(
            new ServerConfigurationUpdate({
                ...currentTariff.serialize(),
                ...server.serialize(),
            }),
        );
        setChanged(false);
    };

    const onSetNewTariffId = (id: number) => {
        const newTariffEntity = options.find((t) => t.tariffId === id);
        const availableMinMemory = FormHelper.countMinMemory(
            server.diskGib,
            resLimits.memoryDiskRate,
            resLimits.stepMemoryMib,
        );
        const newTariffMemoryMib = availableMinMemory > newTariffEntity?.memoryMib!
            ? availableMinMemory
            : newTariffEntity?.memoryMib;

        setNewTariff(newTariff.mergeDeepWith({
            tariffId: id,
            memoryMib: newTariffMemoryMib,
            diskGib: server.diskGib > newTariffEntity?.diskGib!
                ? server.diskGib : newTariffEntity?.diskGib,
            cpuCores: newTariffEntity?.cpuCores,
        }));

        setChanged(true);
    };

    const getTariffPrice = (tariff: Tariff) => {
        if (tariff.fixed) {
            return intl.getMessage('cost', { value: tariff.prices.fixedPriceMonth });
        }

        const { memoryPriceGib, diskPriceGib } = tariff.prices;
        const flexiblePrice = toGb(minMemoryMib) * memoryPriceGib + minDiskGib * diskPriceGib;

        return intl.getPlural('cost_start_from', flexiblePrice);
    };

    const getOption = (tariff: Tariff) => {
        const tariffPrice = getTariffPrice(tariff);

        return (
            <>
                <div className={s.tariffInfo}>
                    <div className={s.tariffName}>
                        {tariff.name}
                    </div>
                    <div className={s.tariffWrap}>
                        {tariff.fixed && (
                            <>
                                <div className={s.tariffData}>
                                    {intl.getMessage('value_gb', { value: toGb(tariff.memoryMib) })}
                                </div>
                                <div className={s.tariffData}>
                                    {intl.getPlural('core', tariff.cpuCores)}
                                </div>
                            </>
                        )}
                        <div className={cn(s.tariffPrice, s.mobile)}>
                            {tariffPrice}
                        </div>
                    </div>
                </div>
                <div className={s.tariffPrice}>
                    {tariffPrice}
                </div>
            </>
        );
    };

    if (options.length === 0) {
        return null;
    }

    const newTariffEntity = options.find((t) => t.tariffId === tariffId);
    const hideTariffTooltip = newTariffEntity ? newTariffEntity.fixed : currentTariff.fixed;

    return (
        <div className={s.cardWrapper}>
            <div className={cn(s.card, { [s.card_changed]: changed })}>
                <div className={s.title}>
                    {intl.getMessage('tariff')}
                </div>
                <Select
                    size="big"
                    value={newTariff.tariffId}
                    onChange={onSetNewTariffId}
                    className={s.select}
                    optionLabelProp="label"
                    listHeight={300}
                    block
                >
                    {options.filter((t) => !t.fixed).map((t) => (
                        <Option
                            value={t.tariffId}
                            key={t.tariffId}
                            label={t.name}
                            className="option option_server"
                        >
                            {getOption(t)}
                        </Option>
                    ))}
                    {options.filter((t) => t.fixed).map((t) => (
                        <Option
                            value={t.tariffId}
                            key={t.tariffId}
                            label={t.name}
                            disabled={currentTariff.tariffId === t.tariffId}
                            className="option option_server"
                        >
                            {getOption(t)}
                        </Option>
                    ))}
                </Select>

                {newTariffEntity?.fixed ? (
                    <FixedTariff
                        server={server}
                        tariff={newTariffEntity}
                    />
                ) : (
                    <div className={s.sliders}>
                        <CustomTariff
                            server={server}
                            values={newTariff}
                            setValues={setNewTariff}
                            setChanged={setChanged}
                        />
                    </div>
                )}

                <div className={s.total}>
                    <div>
                        {intl.getMessage('total_cost')}
                    </div>
                    {price && (
                        <div className={s.cost}>
                            {!hideTariffTooltip ? (
                                <>
                                    {intl.getPlural('cost_start_from', Math.round(price))}
                                    <Tooltip
                                        placement="right"
                                        title={intl.getMessage('flexible_tariff_notice')}
                                        overlayClassName="tooltip tooltip_tariff"
                                    >
                                        <div
                                            className={cn(
                                                theme.Badge.badge,
                                                theme.Badge.question,
                                                s.badge,
                                            )}
                                        />
                                    </Tooltip>
                                </>
                            ) : (
                                intl.getMessage('cost', { value: Math.round(price) })
                            )}
                        </div>
                    )}
                </div>

                <div className={s.actions}>
                    <Button
                        type="primary"
                        size="medium"
                        onClick={checkSubmit}
                        inGroup
                    >
                        {intl.getMessage('save')}
                    </Button>
                    <Button
                        type="link"
                        size="medium"
                        onClick={onReset}
                    >
                        {intl.getMessage('cancel')}
                    </Button>
                </div>
                {server.upgradeRecommendation && (
                    <div className={s.pointer}>
                        <div className={s.inner} />
                        <div className={s.outer} />
                    </div>
                )}
            </div>
            <div className={s.recommendationsWrapper}>
                {server.upgradeRecommendation && (
                    <div className={s.popoverLikeBlock}>
                        <RecommendationsChooseContent
                            server={server}
                        />
                    </div>
                )}
            </div>

            {showRebootNotify && (
                <RebootNotify
                    handleSubmit={onSubmit}
                    handleClose={() => setShowRebootNotify(false)}
                />
            )}
        </div>
    );
};

const ChangeTariffContainer: FC<ChangeTariffOwnProps> = (props) => {
    const { server } = props;
    const { id } = server.distribution;
    const resLimits = useResLimits(server.tenantId, id);
    if (!resLimits) {
        return null;
    }
    return <ChangeTariff {...props} resLimits={resLimits} />;
};

export default ChangeTariffContainer;
