import { Action } from 'store/actions';
import {
    NotificationsActions,
    saveNotifications,
    saveNotificationRecipients,
    saveNotificationsSettings,
    saveTriggerRules,
} from 'Actions/notifications';
import Notification from 'Entities/Notification';

import { NotificationsStore, notificationsInitialState as initialState } from '../initialStates';

const reducer = (state: NotificationsStore = initialState, action: Action): NotificationsStore => {
    switch (action.type as NotificationsActions) {
        case NotificationsActions.SaveNotifications: {
            const { payload } = action as ReturnType<typeof saveNotifications>;
            const { notifications: n } = state;
            let notifications: Notification[] = [...payload];
            if (n !== null) {
                notifications = [...n, ...payload];
            }
            return { ...state, notifications };
        }
        case NotificationsActions.SaveNotificationRecipients: {
            const { payload } = action as ReturnType<typeof saveNotificationRecipients>;
            return { ...state, recipients: payload };
        }
        case NotificationsActions.SaveNotificationsSettings: {
            const { payload } = action as ReturnType<typeof saveNotificationsSettings>;
            return { ...state, settings: payload };
        }
        case NotificationsActions.SaveTriggerRules: {
            const { payload } = action as ReturnType<typeof saveTriggerRules>;
            return { ...state, triggers: payload };
        }
        case NotificationsActions.ClearNotifications: {
            return { ...state, notifications: null };
        }
        case NotificationsActions.ClearNotificationSettings: {
            return { ...state, settings: null };
        }

        default:
            return state;
    }
};

export default reducer;
