import RecipientDeliverySettings, { IRecipientDeliverySettings } from './RecipientDeliverySettings';
import { TriggerRuleType } from './TriggerRuleType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITrigger {
    disabled: boolean;
    id: number;
    name: string;
    period_minutes?: number;
    recipient_settings: IRecipientDeliverySettings[];
    servers_scope?: number[];
    type: TriggerRuleType;
    url?: string;
    value?: number;
}

export default class Trigger {
    readonly _disabled: boolean;

    /** */
    get disabled(): boolean {
        return this._disabled;
    }

    static disabledValidate(disabled: boolean): boolean {
        return typeof disabled === 'boolean';
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 3456
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _name: string;

    /**
     * Description: Name
     * Example: Excess of expenses for the day
     */
    get name(): string {
        return this._name;
    }

    static nameValidate(name: string): boolean {
        return typeof name === 'string' && !!name.trim();
    }

    readonly _period_minutes: number | undefined;

    /**
     * Description: Period in minutes. Only for CPU_USAGE, HTTP, HTTPS_SSL_CERT, LOAD_AVERAGE types
     * Example: 5
     */
    get periodMinutes(): number | undefined {
        return this._period_minutes;
    }

    readonly _recipient_settings: RecipientDeliverySettings[];

    /** */
    get recipientSettings(): RecipientDeliverySettings[] {
        return this._recipient_settings;
    }

    readonly _servers_scope: number[] | undefined;

    /**
     * Description: Null if trigger rule applies to all servers, otherwise list of server that rule applies to (and empty list signals that this rule used to have some servers scoped which are now deleted). Not applicable for MIN_BALANCE. Not passed directly for HTTP, HTTPS_SSL_CERT - only via url param
     * Example: 54277,54656
     */
    get serversScope(): number[] | undefined {
        return this._servers_scope;
    }

    readonly _type: TriggerRuleType;

    get type(): TriggerRuleType {
        return this._type;
    }

    static typeValidate(type: TriggerRuleType): boolean {
        return Object.keys(TriggerRuleType).includes(type);
    }

    readonly _url: string | undefined;

    /**
     * Description: Url. Only for HTTP and DOMAIN_EXPIRATION type
     * Example: https://srv.flops.com/, flops.com
     */
    get url(): string | undefined {
        return this._url;
    }

    readonly _value: number | undefined;

    /**
     * Description: Number value. Only for CPU_USAGE, DAILY_EXPENSE, DISK_FREE_SPACE, INODES_USE, LOAD_AVERAGE, MIN_BALANCE, PING, RECEIVED_TRAFFIC_USAGE/TRANSFERRED_TRAFFIC_USAGE, HTTPS_SSL_CERT, DOMAIN_EXPIRATION types
     * Example: 100
     */
    get value(): number | undefined {
        return this._value;
    }

    constructor(props: ITrigger) {
        this._disabled = props.disabled;
        this._id = props.id;
        this._name = props.name.trim();
        if (typeof props.period_minutes === 'number') {
            this._period_minutes = props.period_minutes;
        }
        this._recipient_settings = props.recipient_settings.map((p) => new RecipientDeliverySettings(p));
        if (props.servers_scope) {
            this._servers_scope = props.servers_scope;
        }
        this._type = props.type;
        if (typeof props.url === 'string') {
            this._url = props.url.trim();
        }
        if (typeof props.value === 'number') {
            this._value = props.value;
        }
    }

    serialize(): ITrigger {
        const data: ITrigger = {
            disabled: this._disabled,
            id: this._id,
            name: this._name,
            recipient_settings: this._recipient_settings.map((p) => p.serialize()),
            type: this._type,
        };
        if (typeof this._period_minutes !== 'undefined') {
            data.period_minutes = this._period_minutes;
        }
        if (typeof this._servers_scope !== 'undefined') {
            data.servers_scope = this._servers_scope;
        }
        if (typeof this._url !== 'undefined') {
            data.url = this._url;
        }
        if (typeof this._value !== 'undefined') {
            data.value = this._value;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            disabled: typeof this._disabled === 'boolean',
            value: !this._value ? true : typeof this._value === 'number',
            period_minutes: !this._period_minutes ? true : typeof this._period_minutes === 'number',
            url: !this._url ? true : typeof this._url === 'string',
            servers_scope: !this._servers_scope ? true : this._servers_scope.reduce((result, p) => result && typeof p === 'number', true),
            recipient_settings: this._recipient_settings.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITrigger): Trigger {
        return new Trigger(props);
    }

    readonly keys: { [key: string]: string } = {
        disabled: 'disabled',
        id: 'id',
        name: 'name',
        periodMinutes: 'period_minutes',
        recipientSettings: 'recipient_settings',
        serversScope: 'servers_scope',
        type: 'type',
        url: 'url',
        value: 'value',
        }
;

    mergeDeepWith(props: Partial<Trigger>): Trigger {
        const updateData: Partial<ITrigger> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Trigger) => {
            const updateKey = this.keys[key] as keyof ITrigger;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITrigger, keyof ITrigger>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Trigger({ ...this.serialize(), ...updateData });
    }
}
