import React, { FC, useEffect, Fragment } from 'react';
import cn from 'classnames';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';

import { useServerInProgress, useServerNotExist, useTitle, useServerSearchSave } from 'Hooks';
import { getAllTariffs } from 'Actions/tariff';
import { getSnapshotList } from 'Actions/snapshots';
import { InnerPageLayout, useIntl, Icon, LoaderPageLayout, ServerNotExist } from 'Common';
import OperationProgress from 'Entities/OperationProgress';
import { OperationType } from 'Entities/OperationType';
import Server from 'Entities/Server';
import SnapTreeBuilder, { SnapshotTreeElem } from 'Lib/helpers/snapshotHelpers';
import { getServerIcon } from 'Lib/helpers/utils';
import { Store } from 'Store';
import theme from 'Lib/theme';

import CurrentState from './CurrentState';
import Header from './Header';
import Snapshot from './Snapshot';
import Restore from './Restore';

import s from './Snapshots.module.pcss';

interface SnapshotsStoreProps {
    server: Server;
    snapshots: SnapshotTreeElem[];
    progress: Map<number, OperationProgress>;
}
type SnapshotsRouteProps = RouteComponentProps<{ serverId: string }>;
type SnapshotsProps = SnapshotsRouteProps & SnapshotsStoreProps;

const Snapshots: FC<SnapshotsProps> = ({ server, snapshots, progress }) => {
    const intl = useIntl();

    const pageTitle = server?.name
        ? intl.getMessage('snapshots_page_title', { value: server.name })
        : intl.getMessage('page_title');
    useTitle(pageTitle);
    const isProgress = useServerInProgress(server?.id);

    const dispatch = useDispatch();
    useEffect(() => {
        if (server) {
            const { tenantId, id } = server;
            dispatch(getSnapshotList([tenantId, id]));
            dispatch(getAllTariffs());
        }
    }, [server?.id, server?.tenantId]);

    const noServer = useServerNotExist();

    useServerSearchSave(server?.id, noServer);

    if (noServer) {
        return <ServerNotExist />;
    }

    const getContent = () => {
        if (!server || isProgress) {
            return <LoaderPageLayout />;
        }
        const { limits: { maxSnapshotsCount } } = server;
        const disabledCurrent = progress.size > 0 || maxSnapshotsCount <= snapshots.length;
        const disabled = progress.size > 0;

        const renderSnapshots = (snapProgress: Map<number, OperationProgress>) => {
            const mapper = ({ snap, children }: SnapshotTreeElem) => {
                const sp = snapProgress.get(snap.id);
                let renderer = null;

                const isLastActive = children[children.length - 1]?.snap.id === server.snapshotId;
                if (sp && sp.type === OperationType.REVERT_TO_SNAPSHOT) {
                    renderer = <Restore percent={(sp.stepOrder / (sp.totalSteps + 1)) * 100} />;
                } else {
                    renderer = (
                        <>
                            <Snapshot
                                tariff={server.tariff}
                                snapshot={snap}
                                server={server}
                                disabled={disabled}
                                progress={sp}
                            />
                        </>
                    );
                }
                return (
                    <Fragment key={snap.id}>
                        <div className={cn(s.block, s.item)}>
                            {renderer}
                        </div>
                        {snap.id === server.snapshotId && (
                            <div className={cn(s.block, s.wrapper)}>
                                <div className={cn(s.block, s.inner)}>
                                    <div className={s.item}>
                                        <CurrentState server={server} disabled={disabledCurrent} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {children.length > 0 && (
                            <div className={cn(s.block, s.wrapper)} key={snap.id}>
                                <div className={cn(s.block, isLastActive ? s.cutInner : s.inner)}>
                                    {children.map(mapper)}
                                </div>
                            </div>
                        )}
                    </Fragment>
                );
            };
            const isLastActive = snapshots[snapshots.length - 1]?.snap.id === server.snapshotId;
            return (
                <div className={cn(s.block, s.wrapper)}>
                    <div className={cn(s.block, isLastActive ? s.cutInner : s.inner)}>
                        {snapshots.map(mapper)}
                    </div>
                </div>
            );
        };
        return (
            <div className={s.snapshots}>
                <div className={s.server}>
                    <Icon icon={getServerIcon(server.distribution.type)} className={s.icon} />
                    <div className={cn(theme.common.textOverflow, s.name)}>
                        {server.name}
                    </div>
                </div>
                {snapshots.length === 0 && (
                    <div className={cn(s.block, s.wrapper)}>
                        <div className={cn(s.block, s.inner)}>
                            <div className={cn(s.block, s.item)}>
                                <CurrentState disabled={disabledCurrent} server={server} />
                            </div>
                        </div>
                    </div>
                )}
                {snapshots.length !== 0 && renderSnapshots(progress)}
            </div>
        );
    };
    const { limits: { maxSnapshotsCount } } = server;
    return (
        <InnerPageLayout
            header={<Header server={server} />}
            title={intl.getMessage('snapshots_state')}
            tooltip={intl.getMessage('snapshots_info', { max: maxSnapshotsCount })}
            tooltipClassName="tooltip_title-small"
            className={theme.content.with_actions}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

interface SnapshotsContainerStoreProps {
    server: Server | undefined;
}
type SnapshotsContainerProps = Omit<SnapshotsProps, 'server'> & SnapshotsContainerStoreProps;

const SnapshotsContainer: FC<SnapshotsContainerProps> = ({ server, ...props }) => {
    if (!server) {
        return <LoaderPageLayout />;
    }
    return <Snapshots {...props} server={server} />;
};

const selectServer = (store: Store, op: SnapshotsRouteProps) => {
    const { match: { params: { serverId } } } = op;
    return store.server.get(Number(serverId));
};
const selectSnapshots = (store: Store) => store.snapshots;
const selector = createSelector([
    selectServer,
    selectSnapshots,
], (server, snapshots) => {
    const snapData = Array.from(snapshots.snapshots.values());
    const snapTree = (new SnapTreeBuilder({ snapshots: snapData })).toTree();
    return {
        server,
        snapshots: snapTree,
        progress: snapshots.progress,
    };
});

const mapStateToProps = (store: Store, ownProps: SnapshotsRouteProps) => ({
    ...selector(store, ownProps),
});

export default withRouter(connect(mapStateToProps)(SnapshotsContainer));
