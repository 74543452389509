import React, { FC, useState, useEffect } from 'react';
import cn from 'classnames';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useEnter, useTitle } from 'Hooks';
import { getResellerDetails, doResellerWithdrawalPayment } from 'Actions/reseller';
import { useIntl, FormPageLayout, Link, Icon, SolarStaffRegistration, Button, Input, Banner } from 'Common';
import { externalLinkBuilder, GuestRoutePath, RoutePath } from 'Lib/helpers/routes';
import { getAvailableAmountBages } from 'Lib/helpers/utils';
import { currencySymbol } from 'Lib/helpers/consts';
import theme from 'Lib/theme';
import { Store } from 'Store';

import s from './Payment.module.pcss';

interface OrderPaymentStoreProps {
    details: Store['reseller']['details'];
}
const OrderPayment: FC<OrderPaymentStoreProps> = ({ details }) => {
    const intl = useIntl();
    useTitle(intl.getMessage('partner_order_payment_page_title'));
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentError, setPaymentError] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(getResellerDetails());
    }, []);
    const submit = () => {
        if (!parseFloat(paymentAmount) || !paymentAmount) {
            setPaymentError(true);
        }
        dispatch(doResellerWithdrawalPayment([{ amount: parseFloat(paymentAmount) }], {
            result: () => {
                history.goBack();
            },
            error: () => {
                setPaymentError(true);
            },
        }));
        history.goBack();
    };
    useEnter(submit, [paymentAmount]);

    if (!details) {
        return null;
    }
    if (!details.paymentType) {
        return <SolarStaffRegistration isClientPage />;
    }

    const validPaymentAmount = (v: string) => {
        if (!v
            || Number.isNaN(Number(v))
            || Number(v) < details.limits.minWithdrawalAmount
            || Number(v) > details.balance
        ) {
            return false;
        }

        return true;
    };

    return (
        <FormPageLayout
            title={intl.getMessage('partners_order_payment')}
            back={RoutePath.Clients}
            banner={(
                <Banner
                    desc={intl.getMessage('solar_staff_desc')}
                    link={externalLinkBuilder(intl.currentLocale, GuestRoutePath.KbArticle, { slug: 'partnership-solar-staff' })}
                />
            )}
        >
            <div className={s.text}>
                <Icon icon="coins" className={s.icon} />
                {intl.getMessage('partners_current_balance', { value: intl.getMessage('money', { value: details.balance }) })}
            </div>
            <div className={s.input}>
                <Input
                    type="number"
                    name="payment"
                    size="large"
                    error={paymentError}
                    placeholder={intl.getMessage('partners_payment_placeholder', { min: details.limits.minWithdrawalAmount })}
                    suffix={currencySymbol.RUB}
                    value={paymentAmount}
                    validate={validPaymentAmount}
                    onChange={(e) => setPaymentAmount(e)}
                    autoFocus
                />
            </div>
            <div className={cn(s.group, s.group_last)}>
                {getAvailableAmountBages(details.balance, details.limits.minWithdrawalAmount).map((amount) => (
                    <button
                        type="button"
                        key={amount}
                        className={cn(
                            theme.Badge.badge,
                            theme.Badge.subject,
                            s.badge,
                        )}
                        onClick={() => setPaymentAmount(String(amount))}
                    >
                        {intl.getMessage('price_simple', { value: amount })}
                    </button>
                ))}
            </div>
            <div>
                <Button
                    type="primary"
                    size="big"
                    disabled={
                        (!paymentAmount
                            || parseFloat(paymentAmount) < details.limits.minWithdrawalAmount)
                    }
                    onClick={submit}
                    inGroup
                >
                    {`${intl.getMessage('order')} ${paymentAmount ? intl.getMessage('price_simple', { value: paymentAmount }) : ''}`}
                </Button>
                <Link to={RoutePath.ClientsFinance}>
                    <Button
                        type="link"
                        size="big"
                    >
                        {intl.getMessage('cancel')}
                    </Button>
                </Link>
            </div>
        </FormPageLayout>
    );
};

const selectResellerDetails = (store: Store) => store.reseller.details;

const selector = createSelector(
    [selectResellerDetails], (details) => ({
        details,
    }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(OrderPayment);
