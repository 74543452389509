// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPartnerStats {
    amount: number;
    hits: number;
    referral_avg_amount: number;
    registrations: number;
    report_date: string;
    report_date_millis: number;
    uniques: number;
}

export default class PartnerStats {
    readonly _amount: number;

    /**
     * Description: Partner's fee, RUB
     * Example: 12
     */
    get amount(): number {
        return this._amount;
    }

    static amountValidate(amount: number): boolean {
        return typeof amount === 'number';
    }

    readonly _hits: number;

    /**
     * Description: Times partner's referral link has been hitted
     * Example: 10
     */
    get hits(): number {
        return this._hits;
    }

    static hitsValidate(hits: number): boolean {
        return typeof hits === 'number';
    }

    readonly _referral_avg_amount: number;

    /**
     * Description: Partner's fee, averaged by referrals, RUB
     * Example: 12
     */
    get referralAvgAmount(): number {
        return this._referral_avg_amount;
    }

    static referralAvgAmountValidate(referralAvgAmount: number): boolean {
        return typeof referralAvgAmount === 'number';
    }

    readonly _registrations: number;

    /**
     * Description: Number of users registered by partner's referral
     * Example: 9
     */
    get registrations(): number {
        return this._registrations;
    }

    static registrationsValidate(registrations: number): boolean {
        return typeof registrations === 'number';
    }

    readonly _report_date: string;

    /** */
    get reportDate(): string {
        return this._report_date;
    }

    static reportDateValidate(reportDate: string): boolean {
        return typeof reportDate === 'string' && !!reportDate.trim();
    }

    readonly _report_date_millis: number;

    /**
     * Description: Stats report date, UTC millis
     * Example: 1593064584178
     */
    get reportDateMillis(): number {
        return this._report_date_millis;
    }

    static reportDateMillisValidate(reportDateMillis: number): boolean {
        return typeof reportDateMillis === 'number';
    }

    readonly _uniques: number;

    /**
     * Description: Number of unique users that used referral link
     * Example: 9
     */
    get uniques(): number {
        return this._uniques;
    }

    static uniquesValidate(uniques: number): boolean {
        return typeof uniques === 'number';
    }

    constructor(props: IPartnerStats) {
        this._amount = props.amount;
        this._hits = props.hits;
        this._referral_avg_amount = props.referral_avg_amount;
        this._registrations = props.registrations;
        this._report_date = props.report_date.trim();
        this._report_date_millis = props.report_date_millis;
        this._uniques = props.uniques;
    }

    serialize(): IPartnerStats {
        const data: IPartnerStats = {
            amount: this._amount,
            hits: this._hits,
            referral_avg_amount: this._referral_avg_amount,
            registrations: this._registrations,
            report_date: this._report_date,
            report_date_millis: this._report_date_millis,
            uniques: this._uniques,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            report_date: typeof this._report_date === 'string' && !!this._report_date.trim(),
            report_date_millis: typeof this._report_date_millis === 'number',
            hits: typeof this._hits === 'number',
            uniques: typeof this._uniques === 'number',
            registrations: typeof this._registrations === 'number',
            amount: typeof this._amount === 'number',
            referral_avg_amount: typeof this._referral_avg_amount === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPartnerStats): PartnerStats {
        return new PartnerStats(props);
    }

    readonly keys: { [key: string]: string } = {
        amount: 'amount',
        hits: 'hits',
        referralAvgAmount: 'referral_avg_amount',
        registrations: 'registrations',
        reportDate: 'report_date',
        reportDateMillis: 'report_date_millis',
        uniques: 'uniques',
        }
;

    mergeDeepWith(props: Partial<PartnerStats>): PartnerStats {
        const updateData: Partial<IPartnerStats> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PartnerStats) => {
            const updateKey = this.keys[key] as keyof IPartnerStats;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPartnerStats, keyof IPartnerStats>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PartnerStats({ ...this.serialize(), ...updateData });
    }
}
