import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Icon } from 'Common';
import { roundNumber } from 'Lib/helpers/helpers';
import AccountDetails from 'Entities/AccountDetails';
import Profile from 'Entities/Profile';
import theme from 'Lib/theme';

import Discount from './Discount';

interface FundsStoreProps {
    details: AccountDetails;
    profile: Profile;
}

const Funds: FC<FundsStoreProps> = ({ details, profile }) => {
    const intl = useIntl();

    const { balance, balanceLeftDays } = details;

    const balanceRound = roundNumber(balance);
    const showBalanceLeftDays = balanceLeftDays !== -1;

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.rounded,
                theme.card.wrapper,
            )}
        >
            <div className={theme.score.wrapper}>
                <div className={theme.score.item}>
                    <Icon
                        icon="coins"
                        className={cn(
                            theme.score.icon,
                            theme.color.blue,
                        )}
                    />
                    <div className={theme.score.info}>
                        <div className={theme.score.label}>
                            {intl.getMessage('billing_balance_dashboard')}
                        </div>
                        <div
                            className={cn(
                                theme.score.value,
                                { [theme.score.value_red]: balance <= 0 },
                            )}
                        >
                            {intl.getMessage('price_simple', { value: balanceRound })}
                        </div>
                    </div>
                </div>
                {showBalanceLeftDays && (
                    <div className={theme.score.item}>
                        <Icon
                            icon="forecast"
                            className={cn(
                                theme.score.icon,
                                theme.color.redActive,
                            )}
                        />
                        <div className={theme.score.info}>
                            <div className={theme.score.label}>
                                {intl.getPlural('dashboard_label_end_of_funds', balanceLeftDays)}
                            </div>
                            <div className={theme.score.value}>
                                {balanceLeftDays <= 1
                                    ? intl.getPlural('less_than_days', 1)
                                    : intl.getPlural('days', balanceLeftDays)}
                            </div>
                        </div>
                    </div>
                )}
                <Discount
                    accountDetails={details}
                    profile={profile}
                />
            </div>
        </div>
    );
};

export default Funds;
