import React, { FC, useContext, useState } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { Checkbox, Icon, Link, useIntl } from 'Common';
import { COPY_BUTTON_TIMEOUT, SERVER_VIEW } from 'Lib/helpers/consts';
import { useUISettings } from 'Lib/hooks/hooks';
import { getServerIcon } from 'Lib/helpers/utils';
import { RoutePath } from 'Lib/helpers/routes';
import OperationProgress from 'Entities/OperationProgress';
import Tenant from 'Entities/Tenant';

import Server from 'Entities/Server';
import { CopyButton } from 'Ui/CopyButton';
import theme from 'Lib/theme';

import { ServerState } from 'Entities/ServerState';
import { ServerListContext } from 'Components/ServersList/factories';
import Subtitles from './Subtitles';

import s from '../Header.module.pcss';

interface ServerNameProps {
    server: Server;
    tenant?: Tenant;
    disabled?: boolean;
    operationProgress?: OperationProgress | undefined;
}

const ServerName: FC<ServerNameProps> = ({
    server,
    disabled,
    tenant,
    operationProgress,
}) => {
    const { id, name, distribution: { type, version }, tenantId } = server;
    const [copyActive, setCopyActive] = useState(false);
    const { serverView } = useUISettings();
    const intl = useIntl();

    const { selectedServers, onSelectServers, showCheckbox } = useContext(ServerListContext);

    const handleCopy = () => {
        setCopyActive(true);
        setTimeout(() => {
            setCopyActive(false);
        }, COPY_BUTTON_TIMEOUT);
        navigator.clipboard.writeText(name);
    };

    const icon = (
        <Icon icon={getServerIcon(type)} className={s.icon} />
    );

    return (
        <>
            <div
                className={cn(
                    s.linkWrap,
                    { [s.compact]: serverView !== SERVER_VIEW.EXPAND },
                )}
            >
                <div
                    className={cn(
                        s.checkboxWrapper, { [s.open]: showCheckbox
                            && !operationProgress
                            && (server.state !== ServerState.INSTALLING) },
                    )}
                    onClick={(e) => e.stopPropagation()}
                >
                    <Checkbox
                        name={String(server.id)}
                        checked={selectedServers.has(server)}
                        handleChange={() => onSelectServers(server)}
                        className={s.checkbox}
                    />
                </div>
                {icon}
                <div className={s.nameWrap}>
                    <div className={s.top}>
                        <Link
                            to={RoutePath.Server}
                            props={{ serverId: id, tenantId }}
                            className={cn(
                                s.title,
                                theme.link.link,
                                theme.link.blue,
                            )}
                        >
                            {name}
                        </Link>
                        {!disabled && (
                            <div className={s.copy}>
                                <CopyButton
                                    background="cloud"
                                    active={copyActive}
                                    copy={handleCopy}
                                />
                            </div>
                        )}
                    </div>
                    {!disabled && (
                        <Subtitles id={id} tenant={tenant} />
                    )}
                </div>
            </div>
            <Tooltip
                title={intl.getMessage('title_distribution_version')}
                placement="bottomLeft"
                overlayClassName="tooltip tooltip_small"
            >
                <div className={s.version}>
                    {version}
                </div>
            </Tooltip>
        </>
    );
};

export default ServerName;
