import React, { FC } from 'react';
import cn from 'classnames';

import { useIntl, Link, DashboardHeader, ServerHeaderLinks } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import { DistributionFamily } from 'Entities/DistributionFamily';
import Server from 'Entities/Server';
import theme from 'Lib/theme';

interface HeaderProps {
    server: Server;
}

const Header: FC<HeaderProps> = ({ server }) => {
    const intl = useIntl();
    const { id } = server;

    return (
        <DashboardHeader
            navigation={(
                <ServerHeaderLinks server={server} active={RoutePath.ServerAccess} />
            )}
            actions={server.distribution.type !== DistributionFamily.WINDOWS && (
                <Link
                    to={RoutePath.PublicKeys}
                    className={cn(
                        theme.link.link,
                        theme.link.blue,
                    )}
                    props={{ serverId: id }}
                >
                    {intl.getMessage('public_keys')}
                </Link>
            )}
        />
    );
};

export default Header;
