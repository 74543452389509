import React, { useState } from 'react';
import { Dropdown } from 'antd';
import cn from 'classnames';

import { Button, Icon, useIntl } from 'Common';
import { IChartLine } from 'Common/Chart/AreaChart';
import PartnerStats from 'Entities/PartnerStats';
import { IServerJsonStats } from 'Entities/ServerJsonStats';
import { IAccountExpense } from 'Entities/AccountExpense';
import theme from 'Lib/theme';

import s from './ChartCard.module.pcss';

const LAST_LINE = 1;

interface SelectLinesProps<T> {
    lines: IChartLine<T>[];
    currentLines: Set<keyof T>;
    onCurrentLinesSelect: (line: keyof T) => void;
}

const SelectLines = <T extends PartnerStats | IServerJsonStats | IAccountExpense>({
    lines,
    currentLines,
    onCurrentLinesSelect,
}: SelectLinesProps<T>) => {
    const intl = useIntl();
    const [showDropdown, setShowDropdown] = useState(false);

    const dropdownContent = (
        <div className={theme.dropdown.content}>
            {lines.map(({ id, color, label }) => {
                const active = currentLines.has(id);
                const disabled = currentLines.size === LAST_LINE && active;

                return (
                    <button
                        key={id as string}
                        type="button"
                        className={cn(
                            theme.dropdown.listItem,
                            { [theme.dropdown.listItem_muted]: !active },
                            { [theme.dropdown.listItem_disabled]: disabled },
                        )}
                        onClick={() => onCurrentLinesSelect(id)}
                        disabled={disabled}
                    >
                        {active ? (
                            <span
                                className={s.line}
                                style={{ backgroundColor: color }}
                            />
                        ) : (
                            <span className={cn(s.line, s.line_muted)} />
                        )}
                        {label}
                    </button>
                );
            })}
        </div>
    );

    return (
        <Dropdown
            overlay={dropdownContent}
            placement="bottomRight"
            trigger={['click']}
            onVisibleChange={(v) => setShowDropdown(v)}
            visible={showDropdown}
        >
            <div>
                <div
                    className={cn(
                        theme.dropdown.trigger,
                        theme.link.link,
                        theme.link.black,
                        s.lines,
                        s.lines_desktop,
                    )}
                >
                    {intl.getMessage('chart_lines')}
                    <Icon icon="down" className={theme.dropdown.down} />
                </div>
                <div
                    className={cn(
                        s.lines,
                        s.lines_mobile,
                    )}
                >
                    <Button
                        type="icon"
                        icon="curve"
                        size="small"
                    />
                </div>
            </div>
        </Dropdown>
    );
};

export default SelectLines;
