import { WebTheme } from './WebTheme';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IProfileUpdate {
    current_password?: string;
    new_password?: string;
    newsletter_enabled?: boolean;
    reseller_section_hidden?: boolean;
    web_theme?: WebTheme;
}

export default class ProfileUpdate {
    readonly _current_password: string | undefined;

    /**
     * Description: Current password, required only if new password is being set
     * Example: de43Rds45
     */
    get currentPassword(): string | undefined {
        return this._current_password;
    }

    readonly _new_password: string | undefined;

    /**
     * Description: New password, or null for keeping current one
     * Example: 1ef34eRs
     */
    get newPassword(): string | undefined {
        return this._new_password;
    }

    static get newPasswordMinLength() {
        return 8;
    }

    static get newPasswordMaxLength() {
        return 2147483647;
    }

    static newPasswordValidate(newPassword: string | undefined): boolean {
        return (!newPassword ? true : newPassword.length >= 8) && (!newPassword ? true : newPassword.length <= 2147483647);
    }

    readonly _newsletter_enabled: boolean | undefined;

    /**
     * Description: If client's subscribed for newsletter, or null for keeping old settings
     * Example: true
     */
    get newsletterEnabled(): boolean | undefined {
        return this._newsletter_enabled;
    }

    readonly _reseller_section_hidden: boolean | undefined;

    /**
     * Description: If reseller section preview is visible to client, or null for keeping old settings
     * Example: true
     */
    get resellerSectionHidden(): boolean | undefined {
        return this._reseller_section_hidden;
    }

    readonly _web_theme: WebTheme | undefined;

    get webTheme(): WebTheme | undefined {
        return this._web_theme;
    }

    constructor(props: IProfileUpdate) {
        if (typeof props.current_password === 'string') {
            this._current_password = props.current_password.trim();
        }
        if (typeof props.new_password === 'string') {
            this._new_password = props.new_password.trim();
        }
        if (typeof props.newsletter_enabled === 'boolean') {
            this._newsletter_enabled = props.newsletter_enabled;
        }
        if (typeof props.reseller_section_hidden === 'boolean') {
            this._reseller_section_hidden = props.reseller_section_hidden;
        }
        if (props.web_theme) {
            this._web_theme = props.web_theme;
        }
    }

    serialize(): IProfileUpdate {
        const data: IProfileUpdate = {
        };
        if (typeof this._current_password !== 'undefined') {
            data.current_password = this._current_password;
        }
        if (typeof this._new_password !== 'undefined') {
            data.new_password = this._new_password;
        }
        if (typeof this._newsletter_enabled !== 'undefined') {
            data.newsletter_enabled = this._newsletter_enabled;
        }
        if (typeof this._reseller_section_hidden !== 'undefined') {
            data.reseller_section_hidden = this._reseller_section_hidden;
        }
        if (typeof this._web_theme !== 'undefined') {
            data.web_theme = this._web_theme;
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            current_password: !this._current_password ? true : typeof this._current_password === 'string',
            new_password: (!this._new_password ? true : this._new_password.length >= 8) && (!this._new_password ? true : this._new_password.length <= 2147483647),
            newsletter_enabled: !this._newsletter_enabled ? true : typeof this._newsletter_enabled === 'boolean',
            reseller_section_hidden: !this._reseller_section_hidden ? true : typeof this._reseller_section_hidden === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IProfileUpdate): ProfileUpdate {
        return new ProfileUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        currentPassword: 'current_password',
        newPassword: 'new_password',
        newsletterEnabled: 'newsletter_enabled',
        resellerSectionHidden: 'reseller_section_hidden',
        webTheme: 'web_theme',
        }
;

    mergeDeepWith(props: Partial<ProfileUpdate>): ProfileUpdate {
        const updateData: Partial<IProfileUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof ProfileUpdate) => {
            const updateKey = this.keys[key] as keyof IProfileUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IProfileUpdate, keyof IProfileUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new ProfileUpdate({ ...this.serialize(), ...updateData });
    }
}
