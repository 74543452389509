import React, { FC, useState } from 'react';
import cn from 'classnames';

import { useIntl, Icon } from 'Common';
import ComparativeExpenses from 'Entities/ComparativeExpenses';
import { EntityType } from 'Entities/EntityType';
import Server from 'Entities/Server';
import theme from 'Lib/theme';

import ServerForecast from './ServerForecast';
import Diff from './Diff';
import Footer from './Footer';

import s from '../Expenses.module.pcss';

interface DailyForecastProps {
    averageDailyExpense: ComparativeExpenses;
    serverAverageDailyExpenses: ComparativeExpenses[];
    servers: Map<number, Server>;
    showForecast: boolean;
}

const DailyForecast: FC<DailyForecastProps> = ({
    averageDailyExpense, serverAverageDailyExpenses, servers, showForecast,
}) => {
    const intl = useIntl();
    const [visibleList, setVisibleList] = useState(false);

    const emptyStats = serverAverageDailyExpenses.length === 0;

    const getContent = () => {
        if (!showForecast) {
            return (
                <div className={s.notice}>
                    {intl.getMessage('daily_consumption_forecast_for_period_tooltip')}
                </div>
            );
        }

        if (emptyStats) {
            return null;
        }

        return (
            <div className={cn(s.list, { [s.list_visible]: visibleList })}>
                <div
                    className={cn(
                        theme.card.labels,
                        theme.card.labels_forecast,
                    )}
                >
                    <div className={theme.card.label}>
                        {intl.getMessage('server')}
                    </div>
                    <div className={theme.card.label}>
                        {intl.getMessage('expenses_for_period')}
                    </div>
                </div>

                {serverAverageDailyExpenses.map((stats) => {
                    if (stats.expenseEntity?.entityType !== EntityType.SERVER_INFO
                        || !stats.expenseEntity?.serverInfo
                    ) {
                        return null;
                    }
                    const { serverInfo } = stats.expenseEntity;
                    return (
                        <ServerForecast
                            key={serverInfo.id}
                            stats={stats}
                            isLink={servers.has(serverInfo.id)}
                        />
                    );
                })}
            </div>
        );
    };

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.rounded,
                s.card,
            )}
        >
            <div className={s.header}>
                <div className={s.title}>
                    <Icon icon="forecast" className={cn(s.icon, s.icon_forecast, theme.color.redActive)} />
                    {intl.getMessage('daily_consumption_forecast_for_period')}
                </div>
                {showForecast && (
                    <Diff
                        value={averageDailyExpense.expenseForecast || 0}
                        diff={averageDailyExpense.expenseDiff}
                    />
                )}
            </div>

            {getContent()}

            {showForecast && (
                <Footer
                    title={intl.getMessage('show_servers_forecast')}
                    visibleList={visibleList}
                    setVisibleList={setVisibleList}
                    emptyStats={emptyStats}
                />
            )}
        </div>
    );
};

export default DailyForecast;
