import React, { FC, useEffect, ChangeEvent, useState } from 'react';
import { Divider, Row, Col, Popover } from 'antd';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Formik, Form, FormikHelpers } from 'formik';

import cn from 'classnames';

import { getAllSecuritySettings, updateSecuritySettings } from 'Actions/security';
import { useIntl, Icon, Checkbox, CodeInput, Button, Link } from 'Common';
import SecurityActionSettings from 'Entities/SecurityActionSettings';
import { SecurityActionType } from 'Entities/SecurityActionType';
import { NotificationDeliveryType } from 'Entities/NotificationDeliveryType';
import SecurityActionSettingsUpdate from 'Entities/SecurityActionSettingsUpdate';
import { useEnter, useEscape } from 'Hooks';
import { RoutePath } from 'Lib/helpers/routes';
import { securityActionTranslate } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';
import { Store } from 'Store';

import s from './SecurityActions.module.pcss';

interface SecurityActionsProps {
    settings: SecurityActionSettings | null;
    havePhone: boolean;
}

interface FormSettings {
    type: SecurityActionType;
    email: boolean;
    sms: boolean;
}

interface FormValues {
    settings: FormSettings[];
    allEmail: boolean;
    allSms: boolean;
    code: string;
}

const SecurityActions: FC<SecurityActionsProps> = ({ settings, havePhone }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [changed, setChanged] = useState(false);

    const [codeSent, setCodeSent] = useState(false);
    const [deliveryType, setDeliveryType] = useState(NotificationDeliveryType.NONE);
    let deliveryMessage = '';
    switch (deliveryType) {
        case NotificationDeliveryType.EMAIL:
            deliveryMessage = intl.getMessage('code_by_email');
            break;
        case NotificationDeliveryType.SMS:
            deliveryMessage = intl.getMessage('code_by_sms');
            break;
        case NotificationDeliveryType.NONE:
            break;
    }

    useEffect(() => {
        dispatch(getAllSecuritySettings());
    }, []);

    if (!settings) {
        return null;
    }

    const formOnSubmit = (
        values: FormValues,
        actions: FormikHelpers<FormValues>,
    ): void => {
        const reqClass = new SecurityActionSettingsUpdate({
            settings: values.settings.map((v) => ({
                action_type: v.type,
                email_enabled: v.email,
                sms_enabled: v.sms,
            })),
            security_code: values.code || undefined,
        });
        dispatch(updateSecuritySettings(
            [reqClass.serialize()],
            !values.code ? {
                result: () => {
                    actions.setSubmitting(false);
                    setChanged(false);
                },
                error: (type: NotificationDeliveryType) => {
                    setCodeSent(true);
                    setDeliveryType(type);
                },
            } : {
                result: () => {
                    actions.setSubmitting(false);
                    setChanged(false);
                    setCodeSent(false);
                },
                error: () => {
                    actions.setFieldError('code', 'error');
                    actions.setSubmitting(false);
                },
            },
        ));
        actions.setSubmitting(false);
    };

    const initSettings = settings.settings.map((se) => ({
        email: se.emailEnabled, sms: se.smsEnabled, type: se.actionType,
    }));

    const initialValues: FormValues = {
        settings: initSettings,
        allEmail: false,
        allSms: false,
        code: '',
    };
    const content = (
        <div className={theme.popover.popover}>
            <div className={theme.popover.description}>
                {intl.getMessage('security_actions_add_phone_to_receive_sms')}
            </div>
            <div className={theme.popover.value}>
                <Link
                    to={RoutePath.ProfileModal}
                    props={{ openModal: true }}
                    className={theme.popover.item}
                >
                    {intl.getMessage('security_actions_add_phone')}
                </Link>
            </div>
        </div>
    );
    const popover = (
        <Popover
            placement="leftTop"
            content={content}
            overlayClassName="popover"
        >
            <div className={s.lockedIcon}>
                <Icon icon="lock" className={s.icon} />
            </div>
        </Popover>
    );

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.plate,
                theme.card.rounded,
                { [theme.card.active]: changed },
            )}
        >
            <div className={s.wrapper}>
                <div className={theme.card.title}>
                    {intl.getMessage('settings_security')}
                </div>
                <div className={cn(theme.card.desc, s.desc)}>
                    {intl.getMessage('settings_security_desc')}
                </div>
                <div>
                    <Row gutter={[24, 0]} align="middle" className="row row_nowrap">
                        <Col flex="auto">
                            <div className={s.label}>
                                {intl.getMessage('settings_security_type')}
                            </div>
                        </Col>
                        <Col>
                            <Icon icon="mail" className={s.icon} />
                        </Col>
                        <Col>
                            <Icon icon="phone" className={s.icon} />
                        </Col>
                    </Row>
                    <Divider
                        className={cn(theme.divider.divider, theme.divider.divider_dashed)}
                    />
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        onSubmit={formOnSubmit}
                        component={({
                            values,
                            errors,
                            handleChange: hc,
                            handleSubmit,
                            handleReset,
                            isSubmitting,
                            setFieldValue,
                            submitForm,
                            setFieldError,
                            setValues,
                        }) => {
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEscape(() => {
                                setChanged(false);
                                handleReset();
                            });
                            // eslint-disable-next-line react-hooks/rules-of-hooks
                            useEnter(() => handleSubmit());
                            const onAllEmails = (e: ChangeEvent<HTMLInputElement>) => {
                                const newSettings = values.settings.map((k) => ({
                                    ...k, email: e.target.checked,
                                }));
                                setChanged(true);
                                setValues({
                                    ...values,
                                    settings: newSettings,
                                    allEmail: e.target.checked,
                                });
                            };
                            const onAllSMS = (e: ChangeEvent<HTMLInputElement>) => {
                                const newSettings = values.settings.map((k) => ({
                                    ...k, sms: e.target.checked,
                                }));
                                setChanged(true);
                                setValues({
                                    ...values,
                                    settings: newSettings,
                                    allSms: e.target.checked,
                                });
                            };
                            const handleChange = (e: ChangeEvent<any>) => {
                                setChanged(true);
                                hc(e);
                            };
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Row align="middle" gutter={[24, 16]} className="row row_nowrap">
                                        <Col flex="auto">
                                            <div className={s.setting}>
                                                {intl.getMessage('settings_security_all')}
                                            </div>
                                        </Col>
                                        <Col>
                                            <Checkbox
                                                id="allEmail"
                                                name="allEmail"
                                                checked={values.allEmail}
                                                handleChange={onAllEmails}
                                            />
                                        </Col>
                                        <Col>
                                            {havePhone ? (
                                                <Checkbox
                                                    id="allSms"
                                                    name="allSms"
                                                    checked={values.allSms}
                                                    handleChange={onAllSMS}
                                                />
                                            ) : (popover)}
                                        </Col>
                                    </Row>
                                    {values.settings.map((
                                        setting: FormSettings, index: number,
                                    ) => {
                                        return (
                                            <Row
                                                align="middle"
                                                gutter={[24, 16]}
                                                className="row row_nowrap"
                                                key={setting.type}
                                            >
                                                <Col flex="auto">
                                                    <div className={s.setting}>
                                                        {securityActionTranslate(
                                                            intl,
                                                            setting.type,
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <Checkbox
                                                        id={`settings.${index}.email`}
                                                        name={`settings.${index}.email`}
                                                        checked={values.settings[index].email}
                                                        handleChange={handleChange}
                                                    />
                                                </Col>
                                                <Col>
                                                    {havePhone ? (
                                                        <Checkbox
                                                            id={`settings.${index}.sms`}
                                                            name={`settings.${index}.sms`}
                                                            checked={values.settings[index].sms}
                                                            handleChange={handleChange}
                                                        />
                                                    ) : popover}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                    {(codeSent && deliveryMessage) && (
                                        <>
                                            <div className="modal__desc modal__desc--gray">
                                                {deliveryMessage}
                                            </div>
                                            <CodeInput
                                                value={values.code}
                                                setValue={(e) => setFieldValue('code', e)}
                                                codeError={!!errors.code}
                                                setCodeError={(e) => setFieldError('code', e ? 'error' : '')}
                                                onSendAgain={() => submitForm()}
                                            />
                                        </>
                                    )}
                                    {changed && (
                                        <div className={s.actions}>
                                            <Button
                                                htmlType="submit"
                                                type="primary"
                                                size="medium"
                                                disabled={isSubmitting}
                                                inGroup
                                            >
                                                {intl.getMessage('save')}
                                            </Button>
                                            <Button
                                                type="link"
                                                size="medium"
                                                onClick={() => {
                                                    setCodeSent(false);
                                                    handleReset();
                                                    setChanged(false);
                                                }}
                                            >
                                                {intl.getMessage('cancel')}
                                            </Button>
                                        </div>
                                    )}
                                </Form>
                            );
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const selectSettings = (store: Store) => store.security.settings;
const selectHavePhone = (store: Store) => !!store.profile.info?.phone;

const selector = createSelector([
    selectSettings, selectHavePhone,
], (settings, havePhone) => ({ settings, havePhone }));

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(SecurityActions);
