import React from 'react';
import OperationProgress from 'Entities/OperationProgress';
import ServerEntity from 'Entities/Server';
import { OperationStatus } from 'Entities/OperationStatus';
import Tenant from 'Entities/Tenant';
import { SERVER_SELECT, SERVER_SORT } from 'Lib/helpers/translationHelper';
import { orderServerState } from 'Lib/helpers/utils';
import { SERVER_VIEW } from 'Lib/helpers/consts';

interface IServerListContext {
    filteredProgress: MapResult[];
    filteredWorking: MapResult[];
    tenantIds: Tenant[];
    serverView: SERVER_VIEW;
    setViewType: (view: SERVER_VIEW) => void;
    tenantFilter: number[];
    onTenantFilterChange: (ids: number[]) => void;
    sortType: {sortType: SERVER_SORT; reverseSort: boolean};
    onSortTypeChange: (sort: SERVER_SORT) => void;
    selectType?: SERVER_SELECT | null;
    onSelectTypeChange: (sort: SERVER_SELECT) => void;
    selectedServers: Set<ServerEntity>;
    onSelectServers: (s: ServerEntity) => void;
    clearSelection: () => void;
    showCheckbox: boolean;
    setShowCheckbox: (type: boolean) => void;
    onReverseSort: () => void;
}

export const ServerListContext = React.createContext({} as IServerListContext);

const mapper = (
    options: {
        installingProgress?: OperationProgress[];
        operationsProgress?: OperationProgress[];
    },
) => (server: ServerEntity) => {
    const { installingProgress, operationsProgress } = options;
    const progress = operationsProgress?.find((ip) => (
        ip.serverId === server.id && ip.status === OperationStatus.PENDING)
    );
    const installing = installingProgress?.find((ip) => ip.serverId === server.id);
    return {
        server,
        filters: {
            status: orderServerState(server.state, progress?.type || installing?.type),
            created: server.timeAddedMillis,
            name: server.name,
            distribution: server.distribution.name,
            isOperation: progress || installing,
            diskGib: server.diskGib,
            memoryMib: server.memoryMib,
            costForecast: server.costForecast.overallCost,
            installing,
        },
    };
};

export type MapResult = ReturnType<ReturnType<typeof mapper>>;

export const mapFactory = (
    installProgress: Map<number, OperationProgress>,
    progress: Map<number, OperationProgress>,
    installingServers: Map<number, ServerEntity>,
    servers: Map<number, ServerEntity>,
    separateProgressServers: boolean,
) => {
    const operationsProgress = Array.from(progress.values());
    const installingProgress = Array.from(installProgress.values());
    const allServers = Array.from(servers.values()).map(mapper({ operationsProgress }));
    const progressed: MapResult[] = [];
    const working: MapResult[] = [];
    allServers.forEach((s) => {
        if (s.filters.isOperation && separateProgressServers) {
            progressed.push(s);
        } else {
            working.push(s);
        }
    });

    const installing = Array.from(installingServers.values())
        .map(mapper({ installingProgress }));

    if (separateProgressServers) {
        progressed.push(...installing);
    } else {
        working.push(...installing);
    }

    return { progressed, working };
};

export const sortFactory = (
    sortType: { sortType: SERVER_SORT | undefined; reverseSort?: boolean }, servers: MapResult[],
) => {
    if (!sortType.sortType) {
        return servers;
    }

    if (!sortType.reverseSort) {
        const sorter: (a: MapResult, b: MapResult) =>
        number = (({ filters: a }, { filters: b }) => {
            switch (sortType.sortType) {
                case SERVER_SORT.BY_CREATED:
                    return b.created - a.created;
                case SERVER_SORT.BY_NAME:
                    return a.name > b.name ? 1 : -1;
                case SERVER_SORT.BY_CONSUMPTION:
                    return a.costForecast > b.costForecast ? -1 : 1;
                case SERVER_SORT.BY_MEMORY:
                    return a.memoryMib > b.memoryMib ? -1 : 1;
                case SERVER_SORT.BY_STORAGE:
                    return a.diskGib > b.diskGib ? -1 : 1;
                case SERVER_SORT.BY_STATUS:
                    return a.status - b.status;
                default:
                    return 0;
            }
        });
        return servers.sort(sorter);
    }

    if (sortType.reverseSort) {
        const sorter: (a: MapResult, b: MapResult) =>
        number = (({ filters: a }, { filters: b }) => {
            switch (sortType.sortType) {
                case SERVER_SORT.BY_CREATED:
                    return a.created - b.created;
                case SERVER_SORT.BY_NAME:
                    return a.name < b.name ? 1 : -1;
                case SERVER_SORT.BY_CONSUMPTION:
                    return a.costForecast < b.costForecast ? -1 : 1;
                case SERVER_SORT.BY_MEMORY:
                    return a.memoryMib < b.memoryMib ? -1 : 1;
                case SERVER_SORT.BY_STORAGE:
                    return a.diskGib < b.diskGib ? -1 : 1;
                case SERVER_SORT.BY_STATUS:
                    return b.status - a.status;
                default:
                    return 0;
            }
        });
        return servers.sort(sorter);
    }
};

export const filterFactory = (tenantFilter: number[], servers: MapResult[]) => {
    if (tenantFilter.length > 0) {
        return servers.filter(({ server }) => tenantFilter.includes(server.tenantId));
    }
    return servers;
};

export const tenantFactory = (
    installingServers: Map<number, ServerEntity>,
    servers: Map<number, ServerEntity>,
    tenants: Map<number, Tenant>,
) => {
    const tenantIds: Tenant[] = [];
    const set = new Set<number>();
    installingServers.forEach((s) => {
        set.add(s.tenantId);
    });
    servers.forEach((s) => {
        set.add(s.tenantId);
    });
    set.forEach((id) => {
        tenantIds.push(tenants.get(id)!);
    });
    return tenantIds;
};
