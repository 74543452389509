import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Store } from 'Store';
import { createSelector } from 'reselect';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { useIntl } from 'Common';
import Tenant from 'Entities/Tenant';
import { SERVER_VIEW } from 'Lib/helpers/consts';
import { useUISettings } from 'Lib/hooks/hooks';

import s from '../Header.module.pcss';

interface ServerNameStoreProps {
    profile: Store['profile']['info'];
}

interface SubtitlesOwnProps {
    id: number;
    tenant?: Tenant;
    mobile?: boolean;
}

type SubtitlesProps = ServerNameStoreProps & SubtitlesOwnProps;

const Subtitles: FC<SubtitlesProps> = ({
    profile,
    id,
    tenant,
    mobile,
}) => {
    const intl = useIntl();
    const { email } = profile || {};
    const { serverView } = useUISettings();

    return (
        <div
            className={cn(
                s.subtitles,
                {
                    [s.subtitles_compact]: serverView === SERVER_VIEW.COMPACT,
                    [s.subtitles_mobile]: mobile,
                },
            )}
        >
            <div className={cn(s.subtitle, s.subtitle_id)}>
                #{id}
            </div>
            {tenant && (
                <>
                    <div className={cn(s.subtitle, s.subtitle_tenant)}>
                        {tenant.description}
                    </div>
                    <div className={cn(s.subtitle, s.subtitle_owner)}>
                        <Tooltip
                            placement="bottom"
                            overlayClassName="tooltip tooltip_small"
                            title={intl.getMessage('title_server_owner')}
                            arrowPointAtCenter
                        >
                            <span>
                                {tenant.ownerUserEmail === email ? intl.getMessage('you') : tenant.ownerUserEmail}
                            </span>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>
    );
};

const selectProfileInfo = (store: Store) => store.profile.info;

const selector = createSelector(
    [selectProfileInfo], (profile) => ({ profile }),
);

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Subtitles);
