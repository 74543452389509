import React, { FC, useEffect } from 'react';
import { createSelector } from 'reselect';
import { connect, useDispatch } from 'react-redux';

import { useTitle } from 'Hooks';
import { getTriggerRules, getNotificationRecipients } from 'Actions/notifications';
import { InnerPageLayout, LoaderPageLayout, EmptyPageLayout, useIntl, Button } from 'Common';
import TriggerEnt from 'Entities/Trigger';
import NotificationRecipient from 'Entities/NotificationRecipient';
import { externalLinkBuilder, GuestRoutePath, RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';
import { Store } from 'Store';

import Header from './Header';
import Trigger from './Trigger';

import s from './Triggers.module.pcss';

interface TriggersStoreProps {
    triggers: TriggerEnt[] | null;
    recipients: NotificationRecipient[];
}

const Triggers: FC<TriggersStoreProps> = ({ triggers, recipients }) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    useTitle(intl.getMessage('notification_triggers_page_title'));
    useEffect(() => {
        dispatch(getTriggerRules());
        dispatch(getNotificationRecipients());
    }, []);

    const getContent = () => {
        if (triggers === null) {
            return <LoaderPageLayout />;
        }

        if (triggers.length > 0) {
            return (
                triggers.map((t) => (
                    <Trigger
                        key={t.id}
                        trigger={t}
                        recipients={recipients}
                    />
                ))
            );
        }

        return (
            <EmptyPageLayout
                title={intl.getMessage('triggers_empty_title')}
                desc={intl.getMessage('triggers_desc')}
                linkText={intl.getMessage('triggers_create')}
                link={RoutePath.NewTrigger}
                additionalLink={(
                    <a
                        href={externalLinkBuilder(
                            intl.currentLocale,
                            GuestRoutePath.KbArticle,
                            { slug: 'monitoring-and-notifications' },
                        )}
                        className={s.link}
                    >
                        <Button
                            type="link"
                            size="medium"
                        >
                            {intl.getMessage('how_working')}
                        </Button>
                    </a>
                )}
            />
        );
    };

    return (
        <InnerPageLayout
            header={<Header />}
            className={theme.content.with_actions}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

const selectRecipients = (store: Store) => store.notifications.recipients;
const selectTriggers = (store: Store) => store.notifications.triggers;

const selector = createSelector(
    [selectTriggers, selectRecipients],
    (triggers, recipients) => ({ triggers, recipients }),
);

const mapStoreToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStoreToProps)(Triggers);
