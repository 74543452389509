import PublicKey, { IPublicKey } from './PublicKey';
import { PublicKeyInstallationState } from './PublicKeyInstallationState';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ICommonPublicKey {
    installation_state: PublicKeyInstallationState;
    key: IPublicKey;
}

export default class CommonPublicKey {
    readonly _installation_state: PublicKeyInstallationState;

    get installationState(): PublicKeyInstallationState {
        return this._installation_state;
    }

    static installationStateValidate(installationState: PublicKeyInstallationState): boolean {
        return Object.keys(PublicKeyInstallationState).includes(installationState);
    }

    readonly _key: PublicKey;

    get key(): PublicKey {
        return this._key;
    }

    constructor(props: ICommonPublicKey) {
        this._installation_state = props.installation_state;
        this._key = new PublicKey(props.key);
    }

    serialize(): ICommonPublicKey {
        const data: ICommonPublicKey = {
            installation_state: this._installation_state,
            key: this._key.serialize(),
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            key: this._key.validate().length === 0,
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ICommonPublicKey): CommonPublicKey {
        return new CommonPublicKey(props);
    }

    readonly keys: { [key: string]: string } = {
        installationState: 'installation_state',
        key: 'key',
        }
;

    mergeDeepWith(props: Partial<CommonPublicKey>): CommonPublicKey {
        const updateData: Partial<ICommonPublicKey> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof CommonPublicKey) => {
            const updateKey = this.keys[key] as keyof ICommonPublicKey;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ICommonPublicKey, keyof ICommonPublicKey>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new CommonPublicKey({ ...this.serialize(), ...updateData });
    }
}
