import { call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { Action } from 'Actions/index';
import ServerApi from 'Apis/servers';
import {
    IGetServerOperationStatus,
    OperationProgressActions,
    removeDoneProgress,
    saveServerOperationProgress,
    markOperationChecked as markOperationCheckedAct,
} from 'Actions/operationProgress';
import { getServer } from 'Actions/server';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { getAccountDashboard } from 'Actions/account';
import { getProfileInfo } from 'Actions/profile';
import OperationProgress, { IOperationProgress } from 'Entities/OperationProgress';
import { OperationType } from 'Entities/OperationType';
import { OperationStatus } from 'Entities/OperationStatus';
import { VmOperationStep } from 'Entities/VmOperationStep';
import Error from 'Entities/Error';
import { Store } from 'Store';

import { addError, errorChecker } from './utils';

export function* getServerOperationStatus(action: Action<IGetServerOperationStatus>) {
    const {
        tenantId, serverId, operationId, operationType, operationStatus, error: e,
    } = action.payload;
    const tempProgress = new OperationProgress({
        error: e,
        type: operationType,
        id: operationId,
        tenant_id: tenantId,
        server_id: serverId,
        status: operationStatus || OperationStatus.PENDING,
        step_name: VmOperationStep.START,
        step_order: 0,
        total_steps: 1,
    });
    yield put(saveServerOperationProgress(tempProgress));
    while (true) {
        const response: IOperationProgress = yield call(
            ServerApi.getOperationProgress,
            tenantId,
            operationId,
        );
        const { error, result } = errorChecker<IOperationProgress>(response);
        if (result) {
            if (result.status !== OperationStatus.PENDING) {
                if (operationType !== OperationType.DELETE) {
                    yield put(getServer({ serverId, tenantId }));
                }
                yield delay(1000);
                if (response.status === OperationStatus.DONE) {
                    yield put(getProfileInfo({ onlyInfo: true }));
                    yield put(getAccountDashboard());
                    yield put(removeDoneProgress({ serverId, type: operationType }));
                    if (action.callback?.result) {
                        action.callback?.result();
                    }
                }
                if (result.status === OperationStatus.ERROR) {
                    const errorOperation = new OperationProgress(result);
                    yield put(saveServerOperationProgress(errorOperation));
                    switch (result.error?.error_code) {
                        case ApiErrorCode.VM_SHUTDOWN_NOT_COMPLETE:
                        case ApiErrorCode.VM_REBOOT_NOT_COMPLETE: {
                            yield addError(
                                new Error(result.error),
                                action.type,
                                { operation: errorOperation },
                            );
                            break;
                        }
                        case ApiErrorCode.INET_IP_GUEST_ADD:
                        case ApiErrorCode.INET_IP_GUEST_REMOVE: {
                            const serverName: string = yield select(
                                (s: Store) => s.server.get(result.server_id)?.name,
                            );
                            yield addError(
                                new Error({
                                    ...result.error,
                                    message:
                                        serverName
                                            ? `${result.result} (${serverName})`
                                            : result.result,
                                }),
                                action.type,
                                {
                                    operation: errorOperation,
                                },
                            );
                            break;
                        }
                        default:
                            if (result.error) {
                                yield addError(
                                    new Error(result.error),
                                    action.type,
                                    {
                                        noSentryErrors: Object.keys(ApiErrorCode) as ApiErrorCode[],
                                        operation: errorOperation,
                                    },
                                );
                            }
                            break;
                    }
                }
                break;
            } else {
                yield put(saveServerOperationProgress(new OperationProgress(response)));
            }
            yield delay(500);
        } else if (error) {
            yield addError(error, action.type);
            break;
        }
    }
}

function* markOperationChecked(action: ReturnType<typeof markOperationCheckedAct>) {
    const { payload } = action;
    yield call(ServerApi.markOperationChecked, ...payload);
}

function* operationProgressSaga(): Generator {
    yield takeEvery<Action<IGetServerOperationStatus>>(
        OperationProgressActions.GetServerOperationStatus, getServerOperationStatus,
    );
    yield takeEvery<ReturnType<typeof markOperationCheckedAct>>(
        OperationProgressActions.MarkOperationChecked, markOperationChecked,
    );
}

export default operationProgressSaga;
