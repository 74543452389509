import { FormikErrors } from 'formik';
import PublicKeyCreate, { IPublicKeyCreate } from 'Entities/PublicKeyCreate';

export enum ErrorType {
    Error = 'error',
    Deprecated = 'deprecated',
}
export type IsValid = FormikErrors<IPublicKeyCreate>;

const SSHKEY_RE = /^([a-z0-9-]+)[ \t]+([a-zA-Z0-9+\/]+[=]*)([ \t]+([^ \t][^\n]*[\n]*)?)?$/;
const SSHKEY_RE2 = /^([a-z0-9-]+)[ \t\n]+([a-zA-Z0-9+\/][a-zA-Z0-9+\/ \t\n=]*)([^a-zA-Z0-9+\/ \t\n=].*)?$/;

enum SSH_KEY_TYPE {
    DSA,
    RSA,
    ED25519,
    CURVE25519,
    ECDSA,
    UNKNOWN,
}

export default class NewPublicKey {
    static initialValues = () => {
        const newKey: IPublicKeyCreate = {
            title: '',
            content: '',
            shared: false,
            install_default: false,
        };
        return newKey;
    };

    static algToKeyType(alg: string): SSH_KEY_TYPE {
        switch (alg) {
            case 'ssh-dss':
                return (SSH_KEY_TYPE.DSA);
            case 'ssh-rsa':
                return (SSH_KEY_TYPE.RSA);
            case 'ssh-ed25519':
                return (SSH_KEY_TYPE.ED25519);
            case 'ssh-curve25519':
                return (SSH_KEY_TYPE.CURVE25519);
            default:
                break;
        }
        if (alg.match(/^ecdsa-sha2-/)) {
            return (SSH_KEY_TYPE.ECDSA);
        }
        return SSH_KEY_TYPE.UNKNOWN;
    }

    static validate = (key: PublicKeyCreate): IsValid => {
        const isValid: IsValid = {};
        if (key.title === '') {
            isValid.title = ErrorType.Error;
        }
        const trimmed = key.content.trim().replace(/[\\\r]/g, '');
        let m = trimmed.match(SSHKEY_RE);
        if (!m) {
            m = trimmed.match(SSHKEY_RE2);
        }
        if (m) {
            const type = NewPublicKey.algToKeyType(m[1]);
            if (type === SSH_KEY_TYPE.RSA || type === SSH_KEY_TYPE.DSA) {
                isValid.content = ErrorType.Deprecated;
            }
            if (type === SSH_KEY_TYPE.UNKNOWN) {
                isValid.content = ErrorType.Error;
            }
        } else {
            isValid.content = ErrorType.Error;
        }
        return isValid;
    };

    static validatePublicKey = (key: string) => {
        const trimmed = key.trim().replace(/[\\\r]/, '');
        let m = trimmed.match(SSHKEY_RE);
        if (!m) {
            m = trimmed.match(SSHKEY_RE2);
        }
        if (m && NewPublicKey.algToKeyType(m[1]) !== SSH_KEY_TYPE.UNKNOWN) {
            return true;
        }
        return false;
    };
}
