import { SecurityActionType } from './SecurityActionType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ISecurityActionSettingUpdate {
    action_type: SecurityActionType;
    email_enabled: boolean;
    sms_enabled: boolean;
}

export default class SecurityActionSettingUpdate {
    readonly _action_type: SecurityActionType;

    get actionType(): SecurityActionType {
        return this._action_type;
    }

    static actionTypeValidate(actionType: SecurityActionType): boolean {
        return Object.keys(SecurityActionType).includes(actionType);
    }

    readonly _email_enabled: boolean;

    /** */
    get emailEnabled(): boolean {
        return this._email_enabled;
    }

    static emailEnabledValidate(emailEnabled: boolean): boolean {
        return typeof emailEnabled === 'boolean';
    }

    readonly _sms_enabled: boolean;

    /** */
    get smsEnabled(): boolean {
        return this._sms_enabled;
    }

    static smsEnabledValidate(smsEnabled: boolean): boolean {
        return typeof smsEnabled === 'boolean';
    }

    constructor(props: ISecurityActionSettingUpdate) {
        this._action_type = props.action_type;
        this._email_enabled = props.email_enabled;
        this._sms_enabled = props.sms_enabled;
    }

    serialize(): ISecurityActionSettingUpdate {
        const data: ISecurityActionSettingUpdate = {
            action_type: this._action_type,
            email_enabled: this._email_enabled,
            sms_enabled: this._sms_enabled,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            email_enabled: typeof this._email_enabled === 'boolean',
            sms_enabled: typeof this._sms_enabled === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ISecurityActionSettingUpdate): SecurityActionSettingUpdate {
        return new SecurityActionSettingUpdate(props);
    }

    readonly keys: { [key: string]: string } = {
        actionType: 'action_type',
        emailEnabled: 'email_enabled',
        smsEnabled: 'sms_enabled',
        }
;

    mergeDeepWith(props: Partial<SecurityActionSettingUpdate>): SecurityActionSettingUpdate {
        const updateData: Partial<ISecurityActionSettingUpdate> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof SecurityActionSettingUpdate) => {
            const updateKey = this.keys[key] as keyof ISecurityActionSettingUpdate;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ISecurityActionSettingUpdate, keyof ISecurityActionSettingUpdate>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new SecurityActionSettingUpdate({ ...this.serialize(), ...updateData });
    }
}
