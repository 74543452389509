import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { FormikHelpers } from 'formik';
import cn from 'classnames';

import { useIntl, FormModalLayout, Select, Option, blackText } from 'Common';
import DnsZoneUpdate, { IDnsZoneUpdate } from 'Entities/DnsZoneUpdate';
import { updateDnsZone } from 'Actions/dns';
import Tenant from 'Entities/Tenant';
import DnsZone from 'Entities/DnsZone';
import theme from 'Lib/theme';

interface EditZoneProps {
    zone: DnsZone;
    tenant: Tenant;
    tenants: Tenant[];
    handleClose: () => void;
}

const EditZone: FC<EditZoneProps> = ({
    zone,
    tenant,
    tenants,
    handleClose,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const formOnSubmit = (values: IDnsZoneUpdate, helpers: FormikHelpers<IDnsZoneUpdate>): void => {
        const reqEnt = new DnsZoneUpdate(values);
        const validate = reqEnt.validate();
        if (validate.length === 0) {
            helpers.setSubmitting(false);

            dispatch(updateDnsZone([zone.tenantId, zone.id, reqEnt.serialize()], {
                result: () => handleClose(),
                error: () => {
                    helpers.setSubmitting(false);
                },
            }));
        } else {
            helpers.setSubmitting(false);
        }
    };

    const initialValues: IDnsZoneUpdate = {
        to_tenant_id: tenant.id,
    };

    return (
        <FormModalLayout
            visible
            title={intl.getMessage('dns_zone_edit_title')}
            initialValues={initialValues}
            handleClose={handleClose}
            handleSubmit={formOnSubmit}
            buttonText={intl.getMessage('save')}
        >
            {({
                values,
                errors,
                setFieldValue,
            }) => (
                <>
                    <div className={theme.modal.desc}>
                        <div className={cn(theme.modal.label, theme.modal.label_select)}>
                            {intl.getMessage('dns_zone_edit_desc', { host: zone.host, b: blackText })}
                        </div>
                        <Select
                            block
                            id="to_tenant_id"
                            size="big"
                            placeholder={intl.getMessage('tenant_select_placeholder')}
                            onChange={(e) => setFieldValue('to_tenant_id', e)}
                            value={values.to_tenant_id}
                            error={!!errors.to_tenant_id}
                        >
                            {tenants.map((t) => (
                                <Option key={t.id} value={t.id}>
                                    {t.description}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </>
            )}
        </FormModalLayout>
    );
};

export default EditZone;
