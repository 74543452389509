import Error from 'Entities/Error';
import OperationProgress from 'Entities/OperationProgress';
import { actionCreator, ActionType } from '.';

export enum ErrorActions {
    AddError = 'AddError',
    RemoveError = 'RemoveError',
}

interface ErrorPayload {
    type: ActionType;
    error: Error;
    operation?: OperationProgress;
}
export const addError = actionCreator<ErrorPayload>(ErrorActions.AddError);
export const removeError = actionCreator<ActionType>(ErrorActions.RemoveError);
