import { RecurringSubscriptionRebillSettings } from './RecurringSubscriptionRebillSettings';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IAccountAutoTopUpSettings {
    active: boolean;
    min_balance: number;
    top_up_amount_card: number;
    top_up_amount_partner: number;
    top_up_amount_reseller: number;
    top_up_settings_card: RecurringSubscriptionRebillSettings;
    top_up_settings_partner: RecurringSubscriptionRebillSettings;
    top_up_settings_reseller: RecurringSubscriptionRebillSettings;
}

export default class AccountAutoTopUpSettings {
    readonly _active: boolean;

    /**
     * Description: If account has automatic top-up feature activated
     * Example: true
     */
    get active(): boolean {
        return this._active;
    }

    static activeValidate(active: boolean): boolean {
        return typeof active === 'boolean';
    }

    readonly _min_balance: number;

    /**
     * Description: User account balance threshold for auto top-up
     * Example: 100
     */
    get minBalance(): number {
        return this._min_balance;
    }

    static minBalanceValidate(minBalance: number): boolean {
        return typeof minBalance === 'number';
    }

    readonly _top_up_amount_card: number;

    /**
     * Description: Top-up amount to charge from card
     * Example: 2430
     */
    get topUpAmountCard(): number {
        return this._top_up_amount_card;
    }

    static topUpAmountCardValidate(topUpAmountCard: number): boolean {
        return typeof topUpAmountCard === 'number';
    }

    readonly _top_up_amount_partner: number;

    /**
     * Description: Top-up amount to charge from partner balance
     * Example: 2430
     */
    get topUpAmountPartner(): number {
        return this._top_up_amount_partner;
    }

    static topUpAmountPartnerValidate(topUpAmountPartner: number): boolean {
        return typeof topUpAmountPartner === 'number';
    }

    readonly _top_up_amount_reseller: number;

    /**
     * Description: Top-up amount to charge from reseller balance
     * Example: 2430
     */
    get topUpAmountReseller(): number {
        return this._top_up_amount_reseller;
    }

    static topUpAmountResellerValidate(topUpAmountReseller: number): boolean {
        return typeof topUpAmountReseller === 'number';
    }

    readonly _top_up_settings_card: RecurringSubscriptionRebillSettings;

    get topUpSettingsCard(): RecurringSubscriptionRebillSettings {
        return this._top_up_settings_card;
    }

    static topUpSettingsCardValidate(topUpSettingsCard: RecurringSubscriptionRebillSettings): boolean {
        return Object.keys(RecurringSubscriptionRebillSettings).includes(topUpSettingsCard);
    }

    readonly _top_up_settings_partner: RecurringSubscriptionRebillSettings;

    get topUpSettingsPartner(): RecurringSubscriptionRebillSettings {
        return this._top_up_settings_partner;
    }

    static topUpSettingsPartnerValidate(topUpSettingsPartner: RecurringSubscriptionRebillSettings): boolean {
        return Object.keys(RecurringSubscriptionRebillSettings).includes(topUpSettingsPartner);
    }

    readonly _top_up_settings_reseller: RecurringSubscriptionRebillSettings;

    get topUpSettingsReseller(): RecurringSubscriptionRebillSettings {
        return this._top_up_settings_reseller;
    }

    static topUpSettingsResellerValidate(topUpSettingsReseller: RecurringSubscriptionRebillSettings): boolean {
        return Object.keys(RecurringSubscriptionRebillSettings).includes(topUpSettingsReseller);
    }

    constructor(props: IAccountAutoTopUpSettings) {
        this._active = props.active;
        this._min_balance = props.min_balance;
        this._top_up_amount_card = props.top_up_amount_card;
        this._top_up_amount_partner = props.top_up_amount_partner;
        this._top_up_amount_reseller = props.top_up_amount_reseller;
        this._top_up_settings_card = props.top_up_settings_card;
        this._top_up_settings_partner = props.top_up_settings_partner;
        this._top_up_settings_reseller = props.top_up_settings_reseller;
    }

    serialize(): IAccountAutoTopUpSettings {
        const data: IAccountAutoTopUpSettings = {
            active: this._active,
            min_balance: this._min_balance,
            top_up_amount_card: this._top_up_amount_card,
            top_up_amount_partner: this._top_up_amount_partner,
            top_up_amount_reseller: this._top_up_amount_reseller,
            top_up_settings_card: this._top_up_settings_card,
            top_up_settings_partner: this._top_up_settings_partner,
            top_up_settings_reseller: this._top_up_settings_reseller,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            active: typeof this._active === 'boolean',
            min_balance: typeof this._min_balance === 'number',
            top_up_amount_card: typeof this._top_up_amount_card === 'number',
            top_up_amount_partner: typeof this._top_up_amount_partner === 'number',
            top_up_amount_reseller: typeof this._top_up_amount_reseller === 'number',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IAccountAutoTopUpSettings): AccountAutoTopUpSettings {
        return new AccountAutoTopUpSettings(props);
    }

    readonly keys: { [key: string]: string } = {
        active: 'active',
        minBalance: 'min_balance',
        topUpAmountCard: 'top_up_amount_card',
        topUpAmountPartner: 'top_up_amount_partner',
        topUpAmountReseller: 'top_up_amount_reseller',
        topUpSettingsCard: 'top_up_settings_card',
        topUpSettingsPartner: 'top_up_settings_partner',
        topUpSettingsReseller: 'top_up_settings_reseller',
        }
;

    mergeDeepWith(props: Partial<AccountAutoTopUpSettings>): AccountAutoTopUpSettings {
        const updateData: Partial<IAccountAutoTopUpSettings> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof AccountAutoTopUpSettings) => {
            const updateKey = this.keys[key] as keyof IAccountAutoTopUpSettings;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IAccountAutoTopUpSettings, keyof IAccountAutoTopUpSettings>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new AccountAutoTopUpSettings({ ...this.serialize(), ...updateData });
    }
}
