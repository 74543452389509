import React, { FC, useState } from 'react';

import { useIntl } from 'Common';
import theme from 'Lib/theme';
import PayerInfo from 'Entities/PayerInfo';
import { IUpdatePayerRequest } from 'Entities/UpdatePayerRequest';
import { IUpdateNonResident } from 'Entities/UpdateNonResident';

import Field from './Field';
import Submit from './Submit';

interface NonResidentFormProps {
    payer: PayerInfo;
    handleClose: () => void;
    handleSubmit: (data: IUpdatePayerRequest, setErrors: (errors: string[]) => void) => void;
}

const NonResidentForm: FC<NonResidentFormProps> = ({ payer, handleClose, handleSubmit }) => {
    const intl = useIntl();
    const [state, setState] = useState<IUpdateNonResident>({
        non_resident_name: payer.nonResidentInfo!.name,
    });
    const [errors, setErrors] = useState<Set<string>>(new Set());
    const onChange = (field: keyof typeof state) => (value: string) => {
        if (errors.has(field)) {
            errors.delete(field);
            setErrors(new Set(errors));
        }
        setState({ ...state, [field]: value });
    };

    return (
        <div className={theme.modal.form}>
            <Field
                id="type"
                label={intl.getMessage('credentials_account_type')}
                value={intl.getMessage('credentials_non_resident')}
                disabled
            />
            <Field
                id="name"
                label={intl.getMessage('credentials_company_name')}
                value={state.non_resident_name}
                onChange={onChange('non_resident_name')}
                error={errors.has('non_resident_name')}
            />
            <Submit
                handleSubmit={() => handleSubmit(
                    { non_resident: state }, (e) => setErrors(new Set(e)),
                )}
                handleClose={handleClose}
            />
        </div>
    );
};

export default NonResidentForm;
