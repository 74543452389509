import { EventInitiatorType } from './EventInitiatorType';
import { EventNotificationLevel } from './EventNotificationLevel';
import { NotificationPayload } from './NotificationPayload';
import ServerInfo, { IServerInfo } from './ServerInfo';
import { TriggerRuleType } from './TriggerRuleType';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface INotification {
    event_initiator_name?: string;
    event_initiator_type: EventInitiatorType;
    has_initiator: boolean;
    id: number;
    level: EventNotificationLevel;
    payload?: NotificationPayload;
    seen: boolean;
    server_info?: IServerInfo;
    time_added: string;
    time_added_millis: number;
    type: TriggerRuleType;
}

export default class Notification {
    readonly _event_initiator_name: string | undefined;

    /**
     * Description: If client is permitted to know the initiator, contains display name of user who initiated this event, otherwise null
     * Example: user@example.com
     */
    get eventInitiatorName(): string | undefined {
        return this._event_initiator_name;
    }

    readonly _event_initiator_type: EventInitiatorType;

    get eventInitiatorType(): EventInitiatorType {
        return this._event_initiator_type;
    }

    static eventInitiatorTypeValidate(eventInitiatorType: EventInitiatorType): boolean {
        return Object.keys(EventInitiatorType).includes(eventInitiatorType);
    }

    readonly _has_initiator: boolean;

    /** */
    get hasInitiator(): boolean {
        return this._has_initiator;
    }

    static hasInitiatorValidate(hasInitiator: boolean): boolean {
        return typeof hasInitiator === 'boolean';
    }

    readonly _id: number;

    /**
     * Description: Notification unique id
     * Example: 1234
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _level: EventNotificationLevel;

    get level(): EventNotificationLevel {
        return this._level;
    }

    static levelValidate(level: EventNotificationLevel): boolean {
        return Object.keys(EventNotificationLevel).includes(level);
    }

    readonly _payload: NotificationPayload | undefined;

    get payload(): NotificationPayload | undefined {
        return this._payload;
    }

    readonly _seen: boolean;

    /**
     * Description: Is notification was seen
     * Example: true
     */
    get seen(): boolean {
        return this._seen;
    }

    static seenValidate(seen: boolean): boolean {
        return typeof seen === 'boolean';
    }

    readonly _server_info: ServerInfo | undefined;

    get serverInfo(): ServerInfo | undefined {
        return this._server_info;
    }

    readonly _time_added: string;

    /** */
    get timeAdded(): string {
        return this._time_added;
    }

    static timeAddedValidate(timeAdded: string): boolean {
        return typeof timeAdded === 'string' && !!timeAdded.trim();
    }

    readonly _time_added_millis: number;

    /**
     * Description: Event date and time, UTC millis
     * Example: 1584175334651
     */
    get timeAddedMillis(): number {
        return this._time_added_millis;
    }

    static timeAddedMillisValidate(timeAddedMillis: number): boolean {
        return typeof timeAddedMillis === 'number';
    }

    readonly _type: TriggerRuleType;

    get type(): TriggerRuleType {
        return this._type;
    }

    static typeValidate(type: TriggerRuleType): boolean {
        return Object.keys(TriggerRuleType).includes(type);
    }

    constructor(props: INotification) {
        if (typeof props.event_initiator_name === 'string') {
            this._event_initiator_name = props.event_initiator_name.trim();
        }
        this._event_initiator_type = props.event_initiator_type;
        this._has_initiator = props.has_initiator;
        this._id = props.id;
        this._level = props.level;
        if (props.payload) {
            this._payload = props.payload;
        }
        this._seen = props.seen;
        if (props.server_info) {
            this._server_info = new ServerInfo(props.server_info);
        }
        this._time_added = props.time_added.trim();
        this._time_added_millis = props.time_added_millis;
        this._type = props.type;
    }

    serialize(): INotification {
        const data: INotification = {
            event_initiator_type: this._event_initiator_type,
            has_initiator: this._has_initiator,
            id: this._id,
            level: this._level,
            seen: this._seen,
            time_added: this._time_added,
            time_added_millis: this._time_added_millis,
            type: this._type,
        };
        if (typeof this._event_initiator_name !== 'undefined') {
            data.event_initiator_name = this._event_initiator_name;
        }
        if (typeof this._payload !== 'undefined') {
            data.payload = this._payload;
        }
        if (typeof this._server_info !== 'undefined') {
            data.server_info = this._server_info.serialize();
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            time_added: typeof this._time_added === 'string' && !!this._time_added.trim(),
            time_added_millis: typeof this._time_added_millis === 'number',
            server_info: !this._server_info ? true : this._server_info.validate().length === 0,
            has_initiator: typeof this._has_initiator === 'boolean',
            event_initiator_name: !this._event_initiator_name ? true : typeof this._event_initiator_name === 'string',
            seen: typeof this._seen === 'boolean',
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: INotification): Notification {
        return new Notification(props);
    }

    readonly keys: { [key: string]: string } = {
        eventInitiatorName: 'event_initiator_name',
        eventInitiatorType: 'event_initiator_type',
        hasInitiator: 'has_initiator',
        id: 'id',
        level: 'level',
        payload: 'payload',
        seen: 'seen',
        serverInfo: 'server_info',
        timeAdded: 'time_added',
        timeAddedMillis: 'time_added_millis',
        type: 'type',
        }
;

    mergeDeepWith(props: Partial<Notification>): Notification {
        const updateData: Partial<INotification> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Notification) => {
            const updateKey = this.keys[key] as keyof INotification;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<INotification, keyof INotification>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Notification({ ...this.serialize(), ...updateData });
    }
}
