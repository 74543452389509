import React, { FC } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import cn from 'classnames';

import { useIntl, notifyError } from 'Common';

import s from './Auth.module.pcss';

interface CaptchaProps {
    setValue: (e: string) => void;
    captchaRef: React.LegacyRef<HCaptcha>;
    invisible?: boolean;
}

const Captcha: FC<CaptchaProps> = ({ setValue, captchaRef, invisible }) => {
    const intl = useIntl();

    return (
        <div className={cn({ [s.captchaContainer]: !invisible })}>
            <div className={cn({ [s.captchaWrapper]: !invisible })}>
                <HCaptcha
                    sitekey={CAPTCHA_KEY}
                    size={invisible ? 'invisible' : 'normal'}
                    onVerify={setValue}
                    ref={captchaRef}
                    onError={() => notifyError(intl.getMessage('captcha_error'))}
                />
            </div>
        </div>
    );
};

export default Captcha;
