import { KayakoTicketStatus } from './KayakoTicketStatus';
import TicketPost, { ITicketPost } from './TicketPost';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface ITicket {
    created_by_user: boolean;
    display_id: string;
    id: number;
    last_activity_time: string;
    last_activity_time_millis: number;
    posts?: ITicketPost[];
    status: KayakoTicketStatus;
    subject: string;
    unread_post_count: number;
}

export default class Ticket {
    readonly _created_by_user: boolean;

    /** */
    get createdByUser(): boolean {
        return this._created_by_user;
    }

    static createdByUserValidate(createdByUser: boolean): boolean {
        return typeof createdByUser === 'boolean';
    }

    readonly _display_id: string;

    /**
     * Description: Display identifier
     * Example: CKP-390-66480
     */
    get displayId(): string {
        return this._display_id;
    }

    static displayIdValidate(displayId: string): boolean {
        return typeof displayId === 'string' && !!displayId.trim();
    }

    readonly _id: number;

    /**
     * Description: Unique identifier
     * Example: 424238
     */
    get id(): number {
        return this._id;
    }

    static idValidate(id: number): boolean {
        return typeof id === 'number';
    }

    readonly _last_activity_time: string;

    /** */
    get lastActivityTime(): string {
        return this._last_activity_time;
    }

    static lastActivityTimeValidate(lastActivityTime: string): boolean {
        return typeof lastActivityTime === 'string' && !!lastActivityTime.trim();
    }

    readonly _last_activity_time_millis: number;

    /**
     * Description: Last activity time, UTC millis
     * Example: 1593064584178
     */
    get lastActivityTimeMillis(): number {
        return this._last_activity_time_millis;
    }

    static lastActivityTimeMillisValidate(lastActivityTimeMillis: number): boolean {
        return typeof lastActivityTimeMillis === 'number';
    }

    readonly _posts: TicketPost[] | undefined;

    /** */
    get posts(): TicketPost[] | undefined {
        return this._posts;
    }

    readonly _status: KayakoTicketStatus;

    get status(): KayakoTicketStatus {
        return this._status;
    }

    static statusValidate(status: KayakoTicketStatus): boolean {
        return Object.keys(KayakoTicketStatus).includes(status);
    }

    readonly _subject: string;

    /**
     * Description: Ticket subject
     * Example: Account and data changes
     */
    get subject(): string {
        return this._subject;
    }

    static subjectValidate(subject: string): boolean {
        return typeof subject === 'string' && !!subject.trim();
    }

    readonly _unread_post_count: number;

    /**
     * Description: Unread post count
     * Example: 2
     */
    get unreadPostCount(): number {
        return this._unread_post_count;
    }

    static unreadPostCountValidate(unreadPostCount: number): boolean {
        return typeof unreadPostCount === 'number';
    }

    constructor(props: ITicket) {
        this._created_by_user = props.created_by_user;
        this._display_id = props.display_id.trim();
        this._id = props.id;
        this._last_activity_time = props.last_activity_time.trim();
        this._last_activity_time_millis = props.last_activity_time_millis;
        if (props.posts) {
            this._posts = props.posts.map((p) => new TicketPost(p));
        }
        this._status = props.status;
        this._subject = props.subject.trim();
        this._unread_post_count = props.unread_post_count;
    }

    serialize(): ITicket {
        const data: ITicket = {
            created_by_user: this._created_by_user,
            display_id: this._display_id,
            id: this._id,
            last_activity_time: this._last_activity_time,
            last_activity_time_millis: this._last_activity_time_millis,
            status: this._status,
            subject: this._subject,
            unread_post_count: this._unread_post_count,
        };
        if (typeof this._posts !== 'undefined') {
            data.posts = this._posts.map((p) => p.serialize());
        }
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            subject: typeof this._subject === 'string' && !!this._subject.trim(),
            unread_post_count: typeof this._unread_post_count === 'number',
            last_activity_time: typeof this._last_activity_time === 'string' && !!this._last_activity_time.trim(),
            last_activity_time_millis: typeof this._last_activity_time_millis === 'number',
            display_id: typeof this._display_id === 'string' && !!this._display_id.trim(),
            created_by_user: typeof this._created_by_user === 'boolean',
            posts: !this._posts ? true : this._posts.reduce((result, p) => result && p.validate().length === 0, true),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: ITicket): Ticket {
        return new Ticket(props);
    }

    readonly keys: { [key: string]: string } = {
        createdByUser: 'created_by_user',
        displayId: 'display_id',
        id: 'id',
        lastActivityTime: 'last_activity_time',
        lastActivityTimeMillis: 'last_activity_time_millis',
        posts: 'posts',
        status: 'status',
        subject: 'subject',
        unreadPostCount: 'unread_post_count',
        }
;

    mergeDeepWith(props: Partial<Ticket>): Ticket {
        const updateData: Partial<ITicket> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof Ticket) => {
            const updateKey = this.keys[key] as keyof ITicket;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<ITicket, keyof ITicket>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new Ticket({ ...this.serialize(), ...updateData });
    }
}
