import ServersApi from 'Apis/servers';
import { IError } from 'Entities/Error';
import OperationProgress from 'Entities/OperationProgress';
import { OperationStatus } from 'Entities/OperationStatus';
import { OperationType } from 'Entities/OperationType';

import { actionCreator } from '.';

export enum OperationProgressActions {
    GetServerOperationStatus = 'GetServerOperationStatus',
    SaveServerOperationProgress = 'SaveServerOperationProgress',
    SaveServerOperationProgressBatch = 'SaveServerOperationProgressBatch',
    RemoveDoneProgress = 'RemoveDoneProgress',
    MarkOperationChecked = 'MarkOperationChecked',
}

export interface IGetServerOperationStatus {
    serverId: number;
    tenantId: number;
    operationId: number;
    operationType: OperationType;
    operationStatus?: OperationStatus;
    error?: IError;
}
const getServerOperationProgress = actionCreator<IGetServerOperationStatus>(
    OperationProgressActions.GetServerOperationStatus,
);
const saveServerOperationProgress = actionCreator<OperationProgress>(
    OperationProgressActions.SaveServerOperationProgress,
);

const saveServerOperationProgressBatch = actionCreator<OperationProgress[]>(
    OperationProgressActions.SaveServerOperationProgressBatch,
);

type removeDownProgressType = {
    serverId: number;
    type: OperationType;
};

const removeDoneProgress = actionCreator<removeDownProgressType>(OperationProgressActions.RemoveDoneProgress);

type MarkOperationChecked = Parameters<typeof ServersApi.markOperationChecked>;
const markOperationChecked = actionCreator<MarkOperationChecked>(
    OperationProgressActions.MarkOperationChecked,
);

export {
    getServerOperationProgress,
    saveServerOperationProgress,
    saveServerOperationProgressBatch,
    removeDoneProgress,
    markOperationChecked,
};
