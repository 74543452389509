import React, { FC, useCallback, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import cn from 'classnames';

import { clearTickets, getTicket } from 'Actions/support';
import { LoaderPageLayout, useIntl, InnerPageLayout } from 'Common';
import Ticket from 'Entities/Ticket';
import { CHAT_ID } from 'Lib/helpers/consts';
import { Store } from 'Store';
import theme from 'Lib/theme';

import { Message, InputMessage, Header } from './components';
import s from './Chat.module.pcss';

const CHAT_POLLING_TIMEOUT = 120000;

interface ChatStoreProps {
    ticket: Ticket | null | undefined;
    profile: Store['profile']['info'];
}
type ChatOwnProps = RouteComponentProps<{ id: string }>;
type ChatProps = ChatStoreProps & ChatOwnProps;

const Chat: FC<ChatProps> = ({ ticket, match, profile }) => {
    const intl = useIntl();
    const { params: { id } } = match;
    const dispatch = useDispatch();

    const chatRef = useCallback((node: HTMLDivElement) => {
        const elem = node;
        if (elem) {
            elem.scrollTop = elem.scrollHeight;
        }
    }, []);

    useEffect(() => {
        dispatch(getTicket({ req: [Number(id)] }));

        const timer = setInterval(
            () => dispatch(getTicket({ req: [Number(id)], update: true })),
            CHAT_POLLING_TIMEOUT,
        );
        return () => {
            clearInterval(timer);
        };
    }, [id]);

    useEffect(() => {
        const chat = document.getElementById(CHAT_ID);
        if (chat) {
            chat.scrollTo({ top: chat.scrollHeight });
        }
    }, [ticket?.posts?.length]);

    useEffect(() => {
        dispatch(clearTickets());
    }, []);

    const isCurrentTicket = ticket && ticket.id === Number(id);

    if (!isCurrentTicket || !ticket || !profile) {
        return <LoaderPageLayout />;
    }

    return (
        <InnerPageLayout
            header={(
                <Header
                    id={ticket.displayId}
                    title={ticket.subject}
                />
            )}
            className={cn(theme.content.with_actions, theme.content.chat)}
        >
            <div className={s.block}>
                <div
                    id={CHAT_ID}
                    className={cn(s.body, theme.common.customScrollbar)}
                    ref={chatRef}
                >
                    {ticket.posts?.map((p) => (
                        <Message
                            key={p.sendTime + p.contents.substring(0, 5)}
                            support={p.staff}
                            date={dayjs(p.sendTimeMillis).format(intl.getMessage('time_format_full'))}
                            text={p.contents}
                            attachments={p.attachments}
                            ticketId={ticket.id}
                        />
                    ))}
                </div>
                <InputMessage ticketId={ticket.id} profile={profile} />
            </div>
        </InnerPageLayout>
    );
};

const selectTicket = (store: Store) => {
    return store.support.currentTicket;
};
const selectProfile = (store: Store) => {
    return store.profile.info;
};

const selector = createSelector([
    selectTicket, selectProfile,
], (ticket, profile) => ({ ticket, profile }));

const mapStateToProps = (store: Store) => {
    return { ...selector(store) };
};
export default withRouter(connect(mapStateToProps)(Chat));
