import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import dayjs from 'dayjs';

import { useIntl, Link, Checkbox, Icon } from 'Common';
import { RoutePath } from 'Lib/helpers/routes';
import NotificationEnt from 'Entities/Notification';
import { EventInitiatorType } from 'Entities/EventInitiatorType';
import Server from 'Entities/Server';
import theme from 'Lib/theme';
import { getNotificationIndicatorColor } from 'Lib/helpers/utils';
import { triggerTranslator, triggerDescTranslator } from 'Lib/helpers/translationHelper';
import { markNotificationSeen } from 'Actions/notifications';

import s from './Notification.module.pcss';

interface NotificationProps {
    notification: NotificationEnt;
    server?: Server;
    checkList: Set<number>;
    setChecked: (set: Set<number>) => void;
}

const Notification: FC<NotificationProps> = ({
    notification,
    server,
    checkList,
    setChecked,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [read, setRead] = useState(false);

    const {
        type,
        timeAddedMillis,
        level,
        payload,
        seen,
        serverInfo,
        eventInitiatorName,
        eventInitiatorType,
        hasInitiator,
        id,
    } = notification;

    const isChecked = checkList.has(id);

    const onCheckChange = () => {
        if (checkList.has(id)) {
            checkList.delete(id);
        } else {
            checkList.add(id);
        }
        setChecked(new Set(checkList));
    };

    const getInitiatorType = () => {
        let initiatorName;

        const { SELF, TENANT_GUEST, TENANT_OWNER, ADMIN, SYSTEM } = EventInitiatorType;

        switch (eventInitiatorType) {
            case SELF:
                initiatorName = intl.getMessage('you');
                break;
            case TENANT_GUEST:
                initiatorName = eventInitiatorName || intl.getMessage('tenant_guest');
                break;
            case TENANT_OWNER:
                initiatorName = intl.getMessage('tenant_owner');
                break;
            case ADMIN:
                initiatorName = intl.getMessage('admin');
                break;
            case SYSTEM:
                initiatorName = intl.getMessage('system_notification');
                break;
            default:
                initiatorName = '';
        }

        return initiatorName;
    };

    const handleNotificationRead = () => {
        if (seen) {
            return;
        }

        setRead(true);
        dispatch(markNotificationSeen({ parameters: [{ notification_ids: [id] }] }));
    };

    const content = (
        <div className={s.row}>
            <div className={s.wrap}>
                {!seen && !read && checkList.size > 0 && (
                    <div
                        className={s.checkbox}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Checkbox
                            id={`seen_notification_${id}`}
                            name={`seen_notification_${id}`}
                            checked={isChecked}
                            handleChange={onCheckChange}
                        />
                    </div>
                )}
                {!seen && read ? (
                    <Icon icon="visibility_disable" className={s.read} />
                ) : (
                    <div
                        className={cn(
                            theme.card.indicator,
                            s.indicator,
                            getNotificationIndicatorColor(level),
                        )}
                    />
                )}
                <div className={s.name}>
                    <div className={s.title}>
                        {triggerTranslator(intl, type, payload!)}
                    </div>
                    <div className={cn(s.subtitle, s.subtitle_desc)}>
                        {triggerDescTranslator(intl, type, payload!)}
                    </div>
                </div>
            </div>
            <div className={s.server}>
                {server ? (
                    <Link
                        to={RoutePath.Server}
                        props={{ serverId: server.id, tenantId: server.tenantId }}
                        className={cn(
                            theme.link.link,
                            theme.link.blue,
                            theme.common.textOverflow,
                        )}
                    >
                        <span className={s.serverName}>
                            {server.name}
                        </span>
                    </Link>
                ) : (
                    serverInfo?.name && (
                        <Link
                            to={RoutePath.Server}
                            props={{ serverId: serverInfo.id, tenantId: serverInfo.tenantId }}
                            className={cn(
                                theme.link.link,
                                theme.link.blue,
                                theme.common.textOverflow,
                            )}
                        >
                            <span className={s.serverName}>
                                {serverInfo.name}
                            </span>
                        </Link>
                    )
                )}
            </div>
            <div className={cn(s.user, { [theme.color.grayHeaders]: !hasInitiator })}>
                {getInitiatorType()}
            </div>
            <div className={s.date}>
                {dayjs(timeAddedMillis).format(intl.getMessage('time_format_short_month_year'))}
            </div>
        </div>
    );

    return (
        <div
            className={cn(
                theme.card.card,
                theme.card.list,
                theme.card.in_column,
                s.notification,
                { [theme.card.link]: !read && !seen },
            )}
            onClick={handleNotificationRead}
        >
            {content}
        </div>
    );
};

export default Notification;
