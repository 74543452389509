import React, { FC, Fragment, useEffect } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { checkSavedAccounts, ISavedUserAccount, logOut, resellerLogin, userAccountLogin } from 'Actions/user';
import { useIntl, Link, Icon, Button } from 'Common';
import ResellerClient from 'Entities/ResellerClient';
import { RoutePath } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import UserAccount from './UserAccount';
import s from './ChangeAccount.module.pcss';
import ClientAccount from './ClientAccount';

interface ChangeAccountProps {
    visible: boolean;
    currentEmail: string;
    currentId: number;
    handleClose: () => void;
    clients: ResellerClient[];
    reseller?: boolean;
}

const ChangeAccount: FC<ChangeAccountProps> = ({
    visible,
    currentEmail,
    currentId,
    handleClose,
    clients,
    reseller,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(checkSavedAccounts());
    }, []);

    const accounts = AppConfigStorage.getAccounts();

    const onLogin = (userAccount: ISavedUserAccount) => {
        handleClose();

        dispatch(userAccountLogin(userAccount, {
            result: () => {
                window.location.reload();
            },
        }));
    };

    const removeAccount = (account: ISavedUserAccount) => {
        AppConfigStorage.removeAccount(account);
    };

    const rollbackAccount = (account: ISavedUserAccount) => {
        AppConfigStorage.saveAccount(account);
    };

    const onClientLogin = (id: number) => {
        dispatch(resellerLogin([id, MAIN_TOKEN || AUTH_TOKEN]));
    };

    const onExit = () => {
        AppConfigStorage.removeTheme();
        dispatch(logOut());
    };

    const listLength = clients.length + (accounts?.length || 0);

    return (
        <Modal
            visible={visible}
            wrapClassName="modal modal_account"
            onCancel={handleClose}
            title={null}
            footer={null}
            width={365}
        >
            <div className={s.wrap} onClick={(e) => e.stopPropagation()}>
                <div className={cn(s.list, theme.common.customScrollbar)}>
                    {accounts ? (
                        accounts.map((userAccount) => {
                            const resellerAccount = (userAccount.id === currentId && reseller)
                                || MAIN_TOKEN === userAccount.token;

                            return (
                                <Fragment key={userAccount.id}>
                                    <UserAccount
                                        userAccount={userAccount}
                                        onLogin={onLogin}
                                        removeAccount={removeAccount}
                                        rollbackAccount={rollbackAccount}
                                        currentId={currentId}
                                        reseller={resellerAccount}
                                    />
                                    {resellerAccount && clients.length > 0 && (
                                        clients.map((client) => (
                                            <ClientAccount
                                                key={client.clientId}
                                                client={client}
                                                onClientLogin={onClientLogin}
                                                currentId={currentId}
                                            />
                                        ))
                                    )}
                                </Fragment>
                            );
                        })
                    ) : (
                        <div className={s.current}>
                            <div className={cn(s.account, s.account_disabled)}>
                                <div className={s.info}>
                                    <div className={s.email}>
                                        {currentEmail}
                                    </div>
                                    <div className={s.desc}>
                                        #{currentId}
                                    </div>
                                </div>
                                <Icon icon="check" className={s.check} />
                            </div>
                        </div>
                    )}
                </div>
                <div className={s.footer}>
                    <Link
                        to={RoutePath.AddAccount}
                        className={cn(
                            theme.link.link,
                            theme.link.black,
                            s.add,
                        )}
                    >
                        {intl.getMessage('add_account')}
                    </Link>
                    <Button
                        type="link"
                        onClick={onExit}
                        className={cn(
                            theme.link.link,
                            theme.link.red,
                            s.exit,
                        )}
                    >
                        {listLength === 1
                            ? intl.getMessage('exit')
                            : intl.getMessage('exit_all_short')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ChangeAccount;
