import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Tooltip } from 'antd';
import cn from 'classnames';

import { useIntl, ChangeTenant } from 'Common';
import Server from 'Entities/Server';
import { Store } from 'Store';
import theme from 'Lib/theme';

import CostTooltip from './CostTooltip';
import s from '../Body.module.pcss';

interface MainProps {
    server: Server;
    installing: boolean;
}

const selectTenants = (store: Store) => store.tenant;
const selectProfileInfo = (store: Store) => store.profile.info;
const selectServers = (store: Store) => store.server;
const selectCurrentUser = (store: Store) => store.user.userId;

const selector = createSelector(
    [selectTenants, selectProfileInfo, selectServers, selectCurrentUser],
    (tenants, profile, servers, currentUserId) => ({ tenants, profile, servers, currentUserId }),
);

const Main: FC<MainProps> = ({ server, installing }) => {
    const intl = useIntl();

    const {
        id,
        distribution: {
            description,
        },
        tenantId,
        costForecast: {
            overallCost,
        },
        tariff,
    } = server;

    const { tenants, profile, servers, currentUserId } = useSelector(selector);

    const tenant = tenants.get(tenantId);

    const tenantInfo = tenant ? (
        <>
            {server.ownerClientId === currentUserId && !installing ? (
                <ChangeTenant
                    tenant={tenant}
                    server={server}
                    servers={Array.from(servers.values())}
                />
            ) : (
                <span className={s.projectName}>{tenant.description}</span>
            )}
            <span className={cn(s.owner, theme.common.textOverflow)}>
                <Tooltip
                    title={intl.getMessage('title_server_owner')}
                    placement="bottom"
                    overlayClassName="tooltip tooltip_small"
                >
                    <span>
                        {profile?.email === tenant.ownerUserEmail ? (
                            intl.getMessage('you')
                        ) : (
                            tenant.ownerUserEmail
                        )}
                    </span>
                </Tooltip>
            </span>
        </>
    ) : null;

    const cost = tariff.fixed
        ? intl.getMessage('cost', { value: Math.round(overallCost) })
        : intl.getMessage('cost_from', { value: Math.round(overallCost) });

    const info = [
        {
            label: intl.getMessage('server_id'),
            value: `#${id}`,
        },
        {
            label: intl.getMessage('tenant'),
            value: tenantInfo,
            className: s.value_tenant,
        },
        {
            label: intl.getMessage('server_os'),
            value: description,
        },
        {
            label: intl.getMessage('server_expense'),
            value: cost,
            hint: <CostTooltip server={server} />,
        },
    ];

    return (
        <div className={s.main}>
            {info.map(({ label, value, hint, className }) => (
                <div key={label} className={s.row}>
                    <div className={s.label}>
                        {label}
                    </div>
                    <div className={cn(s.value, s.value_max, className)}>
                        {value}
                        {hint && hint}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Main;
