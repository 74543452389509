import React, { FC, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import qs from 'qs';

import { ClientType } from 'Entities/ClientType';
import { useTitle } from 'Hooks';
import { getAccountInvoice } from 'Actions/account';
import { useIntl, InnerPageLayout, notifyError, notifySuccess } from 'Common';
import { BoundCards } from 'Common/Account/BoundCards';
import { Store } from 'Store';
import { QueryParams } from 'Lib/helpers/routes';
import theme from 'Lib/theme';

import { Header, AutoTopUp, Funds } from './components';
import s from './Billing.module.pcss';

const selectAccountDetails = (store: Store) => store.account.details;
const selectProfile = (store: Store) => store.profile.info;
const selectPayers = (store: Store) => {
    return store.profile.profilePayer ? store.profile.profilePayer.length > 0 : false;
};

const selector = createSelector(
    [selectAccountDetails, selectProfile, selectPayers],
    (details, profile, canPay) => ({
        details,
        profile,
        canPay,
    }),
);

type Params = Partial<Record<QueryParams, string | number | boolean>>;

const Billing: FC = () => {
    const intl = useIntl();
    useTitle(intl.getMessage('account_page_title'));

    const { details, profile, canPay } = useSelector(selector);
    const dispatch = useDispatch();
    useEffect(() => {
        const params: Params = qs.parse(window.location.search);
        if (params.error) {
            notifyError(intl.getMessage('pay_failed'), 'pay_failed');
        }
        if (params.srv_invoice_id) {
            dispatch(getAccountInvoice({
                req: [Number(params.srv_invoice_id)],
                callback: () => {
                    notifySuccess(intl.getMessage('pay_success'), 'pay_success');
                },
            }));
        }
    }, []);
    if (!details || !profile) {
        return null;
    }

    const { clientType } = details;

    return (
        <InnerPageLayout
            header={<Header canPay={canPay} />}
            className={theme.content.with_actions}
        >
            <div className={s.group}>
                <Funds details={details} profile={profile} />
            </div>
            {clientType !== ClientType.NON_RESIDENT && (
                <div className={s.group}>
                    <AutoTopUp details={details} />
                </div>
            )}
            <div className={s.group}>
                {clientType !== ClientType.CORPORATION
                && clientType !== ClientType.SOLE_TRADER && (
                    <BoundCards details={details} />
                )}
            </div>
        </InnerPageLayout>
    );
};

const mapStoreToProps = (store: Store) => (
    { ...selector(store) }
);

export default connect(mapStoreToProps)(Billing);
