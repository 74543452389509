import React, { FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import cn from 'classnames';

import { Icon } from 'Common';
import { toggleSidebar } from 'Actions/ui';
import theme from 'Lib/theme';

interface DashboardHeaderProps {
    title?: string;
    navigation?: ReactNode;
    actions?: ReactNode;
    mobile?: boolean;
    loading?: boolean;
}

const DashboardHeader: FC<DashboardHeaderProps> = ({
    navigation,
    title,
    actions,
    mobile,
    loading,
}) => {
    const dispatch = useDispatch();

    const handleSidebar = () => {
        dispatch(toggleSidebar());
    };

    if (loading) {
        return (
            <header className={theme.header.header}>
                <div className={cn(theme.header.top, theme.header.top_loading)}>
                    <Button
                        className={cn(
                            theme.button.button,
                            theme.header.open,
                        )}
                        icon={<Icon icon="burger" />}
                        onClick={handleSidebar}
                    />
                </div>
                <div className={theme.header.bottom}>
                    <div className={theme.skeleton.bar} />
                </div>
            </header>
        );
    }

    return (
        <header className={cn(theme.header.header, { [theme.header.header_mobile]: mobile })}>
            <div className={theme.header.top}>
                <Button
                    className={cn(
                        theme.button.button,
                        theme.header.open,
                    )}
                    icon={<Icon icon="burger" />}
                    onClick={handleSidebar}
                />
                {actions && (
                    <div className={cn(theme.header.actions, theme.header.actions_mobile)}>
                        {actions}
                    </div>
                )}
            </div>
            <div className={theme.header.bottom}>
                {title && (
                    <div className={theme.header.title}>
                        {title}
                    </div>
                )}
                {navigation && (
                    <div className={theme.header.navigation}>
                        {navigation}
                    </div>
                )}
                {actions && (
                    <div className={theme.header.actions}>
                        {actions}
                    </div>
                )}
            </div>
        </header>
    );
};

export default DashboardHeader;
