import { takeEvery, put, call } from 'redux-saga/effects';

import DistributionsApi from 'Apis/distributions';
import {
    DistributionActions,
    getDistributionsList as getDistributionsListAct,
    saveDistributionsList,
} from 'Actions/distribution';
import ServerDistribution, { IServerDistribution } from 'Entities/ServerDistribution';

import { errorChecker, addError } from './utils';

function* getDistributionList(action: ReturnType<typeof getDistributionsListAct>) {
    const distributionList: IServerDistribution[] = yield call(DistributionsApi.listDistributions);
    const { error, result } = errorChecker<IServerDistribution[]>(distributionList);
    if (result) {
        const distributionEntities = result.map((t) => new ServerDistribution(t));
        yield put(saveDistributionsList(distributionEntities));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* distributionSaga(): Generator {
    yield takeEvery<ReturnType<typeof getDistributionsListAct>>(
        DistributionActions.GetDistributions, getDistributionList,
    );
}

export default distributionSaga;
