// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum EventInitiatorType {
    SELF = 'SELF',
    TENANT_GUEST = 'TENANT_GUEST',
    TENANT_OWNER = 'TENANT_OWNER',
    ADMIN = 'ADMIN',
    SYSTEM = 'SYSTEM',
    UNKNOWN = 'UNKNOWN'
}
