import ServersApi from 'Apis/servers';
import ResourceLimitsApi from 'Apis/resourceLimits';

import ServerCostForecast from 'Entities/ServerCostForecast';
import ServerResourceLimits from 'Entities/ServerResourceLimits';

import { actionCreator } from '.';

export enum NewServerActions {
    CalculateCostForecastForDraft = 'CalculateCostForecastForDraft',
    SaveCostForecastForDraft = 'SaveCostForecastForDraft',
    CalculateCostForecastForServer = 'CalculateCostForecastForServer',
    SaveCostForecastForServer = 'SaveCostForecastForServer',
    GetResourseLimits = 'GetResourseLimits',
    SaveResourseLimints = 'SaveResourseLimints',
}

type CalculateCostForecastForDraft = Parameters<typeof ServersApi.calculateCostForecastForDraft>;
export const calculateCostForecastForDraft = actionCreator<CalculateCostForecastForDraft>(
    NewServerActions.CalculateCostForecastForDraft,
);

export const saveCostForecastForDraft = actionCreator<ServerCostForecast>(
    NewServerActions.SaveCostForecastForDraft,
);

type CalculateCostForecastForServer = Parameters<typeof ServersApi.calculateCostForecastForServer>;
export const calculateCostForecastForServer = actionCreator<CalculateCostForecastForServer>(
    NewServerActions.CalculateCostForecastForServer,
);

export const saveCostForecastForServer = actionCreator<ServerCostForecast>(
    NewServerActions.SaveCostForecastForServer,
);

type getResourseLimits = Parameters<typeof ResourceLimitsApi.getResourceLimitsForDraft>;
export const getResourseLimits = actionCreator<getResourseLimits>(
    NewServerActions.GetResourseLimits,
);

export const saveResourseLimints = actionCreator<ServerResourceLimits>(
    NewServerActions.SaveResourseLimints,
);
