import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { startServer } from 'Actions/server';
import { useIntl } from 'Common';
import Server from 'Entities/Server';
import { OperationType } from 'Entities/OperationType';

import { ActionButton } from '.';

interface DisabledControlsProps {
    server: Server;
    close: () => void;
    type?: OperationType;
}

const DisabledControls: FC<DisabledControlsProps> = ({ server, close }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const onStart = () => {
        dispatch(startServer(server));
        close();
    };
    return (
        <ActionButton
            id="on_start"
            handler={onStart}
            title={intl.getMessage('server_turn_on')}
        />
    );
};

export default DisabledControls;
