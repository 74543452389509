// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export interface IPasswordRecovery {
    code: string;
    password: string;
}

export default class PasswordRecovery {
    readonly _code: string;

    /**
     * Description: Password change code
     * Example: 123e4567e89b12d3a456426655440000
     */
    get code(): string {
        return this._code;
    }

    static codeValidate(code: string): boolean {
        return typeof code === 'string' && !!code.trim();
    }

    readonly _password: string;

    /**
     * Description: New password
     * Example: de43Rds45
     */
    get password(): string {
        return this._password;
    }

    static get passwordMinLength() {
        return 8;
    }

    static get passwordMaxLength() {
        return 2147483647;
    }

    static passwordValidate(password: string): boolean {
        return (password.length >= 8) && (password.length <= 2147483647);
    }

    constructor(props: IPasswordRecovery) {
        this._code = props.code.trim();
        this._password = props.password.trim();
    }

    serialize(): IPasswordRecovery {
        const data: IPasswordRecovery = {
            code: this._code,
            password: this._password,
        };
        return data;
    }

    validate(): string[] {
        const validateRequired = {
            code: typeof this._code === 'string' && !!this._code.trim(),
            password: (this._password.length >= 8) && (this._password.length <= 2147483647),
        };
        const isError: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                isError.push(key);
            }
        });
        return isError;
    }

    update(props: IPasswordRecovery): PasswordRecovery {
        return new PasswordRecovery(props);
    }

    readonly keys: { [key: string]: string } = {
        code: 'code',
        password: 'password',
        }
;

    mergeDeepWith(props: Partial<PasswordRecovery>): PasswordRecovery {
        const updateData: Partial<IPasswordRecovery> = {};
        // @ts-ignore
        Object.keys(props).forEach((key: keyof PasswordRecovery) => {
            const updateKey = this.keys[key] as keyof IPasswordRecovery;
            if ((props[key] as any).serialize) {
                (updateData[updateKey] as any) = (props[key] as any).serialize() as Pick<IPasswordRecovery, keyof IPasswordRecovery>;
            } else {
                (updateData[updateKey] as any) = props[key];
            }
        });
        return new PasswordRecovery({ ...this.serialize(), ...updateData });
    }
}
