import serverBackupApi from 'Apis/backups';
import ServerBackup from 'Entities/ServerBackup';
import OperationProgress from 'Entities/OperationProgress';

import { actionCreator } from '.';

export enum ServerBackupActions {
    GetServerBackups = 'GetServerBackups',
    SaveServerBackups = 'SaveServerBackups',
    SaveBackupProgress = 'SaveBackupProgress',
    DeleteBackupProgress = 'DeleteBackupProgress',
    RollbackBackup = 'RollbackBackup',
    DownloadBackup = 'DownloadBackup',
    GetBackupDownloadLink = 'GetBackupDownloadLink',
}

type GetServerBackupsList = Parameters<typeof serverBackupApi.listBackups>;
const getServerBackupsList = actionCreator<GetServerBackupsList>(
    ServerBackupActions.GetServerBackups,
);
const saveServerBackupsList = actionCreator<ServerBackup[]>(ServerBackupActions.SaveServerBackups);

interface SaveBackupProgress {
    progress: OperationProgress;
    backupId: string;
}
const saveBackupProgress = actionCreator<SaveBackupProgress>(
    ServerBackupActions.SaveBackupProgress,
);
const deleteBackupProgress = actionCreator<string>(
    ServerBackupActions.DeleteBackupProgress,
);

type RollbackBackup = Parameters<typeof serverBackupApi.rollbackBackup>;
const rollbackBackup = actionCreator<RollbackBackup>(ServerBackupActions.RollbackBackup);

type DownloadBackup = Parameters<typeof serverBackupApi.downloadBackup>;
const downloadBackup = actionCreator<DownloadBackup>(ServerBackupActions.DownloadBackup);

type GetBackupDownloadLink = Parameters<typeof serverBackupApi.getBackupDownloadLink>;
const getBackupDownloadLink = actionCreator<GetBackupDownloadLink>(ServerBackupActions.GetBackupDownloadLink);

export {
    getServerBackupsList,
    saveServerBackupsList,
    saveBackupProgress,
    deleteBackupProgress,
    rollbackBackup,
    downloadBackup,
    getBackupDownloadLink,
};
