// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export enum InvoiceStatus {
    PROCESS = 'PROCESS',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    CANCEL = 'CANCEL',
    SUCCESS_TRANSIT = 'SUCCESS_TRANSIT',
    VOIDED = 'VOIDED'
}
