import React, { FC, useRef, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTitle } from 'Hooks';
import { InnerPageLayout, MainHeader, useIntl } from 'Common';
import { login } from 'Actions/user';
import LoginForm, { ILoginForm } from 'Entities/LoginForm';
import ApiError from 'Entities/Error';
import { ApiErrorCode } from 'Entities/ApiErrorCode';
import { linkPathBuilder, RoutePath } from 'Lib/helpers/routes';
import { apiErrorCodeTranslate } from 'Lib/helpers/translationHelper';
import {
    EMPTY_FIELD_ERROR,
} from 'Lib/helpers/consts';
import { getCookieToken } from 'Lib/helpers/utils';
import theme from 'Lib/theme';

import SocialAuth from './SocialAuth';
import s from './Auth.module.pcss';
import LoginFormContainer from './LoginFormContainer';

export type LoginFormValues = ILoginForm & { captcha: string };

const Login: FC = React.memo(() => {
    const dispatch = useDispatch();
    const captchaRef = useRef<any>(null);
    const intl = useIntl();
    useTitle(intl.getMessage('login_page_title'));
    const [shouldUseCaptcha, setShouldUseCaptcha] = useState(false);
    const [firstAttempt, setFirstAttempt] = useState(true);
    const history = useHistory();

    const formOnSubmit = (values: LoginFormValues, actions: FormikHelpers<LoginFormValues>): void => {
        if (!NO_CAPTCHA) {
            if (!values.captcha && shouldUseCaptcha) {
                actions.setSubmitting(false);
                captchaRef.current?.execute();
                return;
            }
        }
        const reqEnt = new LoginForm(values);
        dispatch(login([reqEnt.serialize(), getCookieToken(), values.captcha || ''], {
            error: (error: ApiError) => {
                actions.setSubmitting(false);
                if (error.errorCode === ApiErrorCode.CAPTCHA_REQUIRED) {
                    setShouldUseCaptcha(true);
                    if (firstAttempt) {
                        setFirstAttempt(false);
                        actions.submitForm();
                        return;
                    }
                    actions.setErrors({
                        username: EMPTY_FIELD_ERROR,
                        password: apiErrorCodeTranslate(intl, ApiErrorCode.LOGIN_BAD_CREDENTIALS),
                    });
                } else {
                    actions.setErrors({
                        username: EMPTY_FIELD_ERROR,
                        password: apiErrorCodeTranslate(intl, error.errorCode),
                    });
                    actions.setFieldValue('captcha', '', false);
                }
            },
            result: () => {
                history.push(linkPathBuilder(intl.currentLocale, RoutePath.Dashboard));
            },
        }));
    };

    return (
        <InnerPageLayout header={<MainHeader />} className={theme.content.auth}>
            <div className={s.auth__panel}>
                <div className={s.auth__title}>
                    {intl.getMessage('login_title')}
                </div>

                <LoginFormContainer
                    formOnSubmit={formOnSubmit}
                    captchaRef={captchaRef}
                />

                <SocialAuth />
            </div>
        </InnerPageLayout>
    );
});

export default Login;
