import React, { FC, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { useTitle } from 'Hooks';
import {
    useIntl,
    InnerPageLayout,
    LoaderPageLayout,
    ServersSelect,
    DropdownRangePicker,
    zoomInitialValues,
} from 'Common';
import Server from 'Entities/Server';
import AccountDetails from 'Entities/AccountDetails';
import { IAccountExpense } from 'Entities/AccountExpense';
import AccountExpensesDetailed from 'Entities/AccountExpensesDetailed';
import { IAccountExpenseRequest } from 'Entities/AccountExpenseRequest';
import { IAccountExpensesDetailedRequest } from 'Entities/AccountExpensesDetailedRequest';
import { getAccountExpensesDetailed, getAccountExpensesJSON, getAccountableServers } from 'Actions/account';
import { Store } from 'Store';
import { getDateRangeMs } from 'Lib/helpers/helpers';
import { Range } from 'Lib/helpers/utils';
import { DATE_RANGE_TYPES } from 'Lib/helpers/consts';
import { getRangeLabel } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';

import { OverallExpenses, DailyForecast, Header, ExpensesTable } from './components';
import s from './Expenses.module.pcss';

interface ExpensesProps {
    servers: Map<number, Server>;
    accountServers: Map<number, Server>;
    tenants: Store['tenant'];
    details: AccountDetails | null;
    detailedExpenses: AccountExpensesDetailed | null;
    accountExpensesJSON: IAccountExpense[] | null;
}

const Expenses: FC<ExpensesProps> = ({
    servers, accountServers, tenants, details, detailedExpenses, accountExpensesJSON,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    useTitle(intl.getMessage('expenses_page_title'));

    const today = dayjs().startOf('day');
    const [selectedServers, setSelectedServers] = useState<Set<Server>>(new Set());

    const ranges = new Range(today.add(-1, 'week'), today);
    const [range, setRange] = useState(ranges);
    const [zoom, setZoom] = useState(zoomInitialValues);
    const [onlySubAccounts, setOnlySubAccounts] = useState(false);
    const [rangeName, setRangeName] = useState<DATE_RANGE_TYPES | null>(DATE_RANGE_TYPES.WEEK);

    useEffect(() => {
        dispatch(getAccountableServers());
    }, []);

    useEffect(() => {
        const { from, till } = getDateRangeMs(range);

        const selectedServersIds = Array.from(selectedServers.values()).map((sr) => sr.id);
        const detailed: IAccountExpensesDetailedRequest = {
            date_from_millis: from,
            date_to_millis: till,
            server_ids: selectedServers.size > 0 ? selectedServersIds : undefined,
            show_only_transfers: onlySubAccounts,
        };
        dispatch(getAccountExpensesDetailed([detailed]));

        const req: IAccountExpenseRequest = {
            date_from_millis: from,
            date_to_millis: till,
            server_ids: selectedServers.size > 0 ? selectedServersIds : undefined,
        };
        dispatch(getAccountExpensesJSON([req]));
    }, [range, selectedServers, onlySubAccounts]);

    const selectServers = (server: Server) => {
        if (selectedServers.has(server)) {
            selectedServers.delete(server);
        } else {
            selectedServers.add(server);
        }
        setSelectedServers(new Set(selectedServers));
    };

    const selectTenantServers = (tenantServers: Server[], checked: boolean) => {
        tenantServers.forEach((server) => (
            selectedServers.has(server) && selectedServers.delete(server)
        ));
        if (!checked) {
            tenantServers.forEach((server) => (
                selectedServers.add(server)
            ));
        }
        setSelectedServers(new Set(selectedServers));
    };

    const selectAllServers = () => {
        if (selectedServers.size === accountServers.size) {
            setSelectedServers(new Set());
        } else {
            setSelectedServers(new Set(Array.from(accountServers.values())));
        }
    };

    const clearSelection = () => {
        setSelectedServers(new Set());
    };

    const getContent = () => {
        if (detailedExpenses === null) {
            return <LoaderPageLayout />;
        }

        const {
            overallExpense,
            dailyExpenses,
            overallServerExpense,
            serverExpenses,
        } = detailedExpenses;

        const showForecast = today.isSame(range.to.startOf('day'));

        return (
            <>
                <div className={s.filters}>
                    <div className={s.servers}>
                        <ServersSelect
                            servers={accountServers}
                            tenants={tenants}
                            selectedServers={selectedServers}
                            selectServers={selectServers}
                            selectAllServers={selectAllServers}
                            selectTenantServers={selectTenantServers}
                            clearSelection={clearSelection}
                        />
                    </div>
                    <DropdownRangePicker
                        mode="month"
                        range={range}
                        setDateRange={setRange}
                        globalZoom={zoom}
                        rangeName={rangeName ? getRangeLabel(intl, rangeName) : undefined}
                        setRangeName={setRangeName}
                    />
                </div>
                <DailyForecast
                    averageDailyExpense={overallServerExpense}
                    serverAverageDailyExpenses={serverExpenses}
                    servers={servers}
                    showForecast={showForecast}
                />
                <OverallExpenses
                    overallExpense={overallExpense}
                    accountExpensesJSON={accountExpensesJSON}
                    range={range}
                    setRange={setRange}
                    zoom={zoom}
                    setZoom={setZoom}
                    setRangeName={setRangeName}
                />
                <ExpensesTable
                    dailyExpenses={dailyExpenses}
                    onlySubAccounts={onlySubAccounts}
                    setOnlySubAccounts={setOnlySubAccounts}
                />
            </>
        );
    };

    return (
        <InnerPageLayout
            header={<Header details={details} detailedExpenses={detailedExpenses} />}
            className={theme.content.with_actions}
        >
            {getContent()}
        </InnerPageLayout>
    );
};

const selectAccountServers = (store: Store) => store.account.servers;
const selectTenants = (store: Store) => store.tenant;
const selectAccount = (store: Store) => store.account.details;
const selectAccountExpensesDetailed = (store: Store) => store.account.detailedExpenses;
const selectAccountExpensesJSON = (store: Store) => store.account.accountExpensesJSON;
const selectStoreServers = (store: Store) => store.server;

const selector = createSelector(
    selectAccountServers,
    selectTenants,
    selectAccount,
    selectAccountExpensesDetailed,
    selectAccountExpensesJSON,
    selectStoreServers,
    (accountServers, tenants, details, detailedExpenses, accountExpensesJSON, servers) => ({
        accountServers,
        tenants,
        details,
        detailedExpenses,
        accountExpensesJSON,
        servers,
    }),
);

const mapStateToProps = (store: Store) => ({
    ...selector(store),
});

export default connect(mapStateToProps)(Expenses);
