import React, { FC } from 'react';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import cn from 'classnames';

import { Link, useIntl } from 'Common';
import NotificationEntity from 'Entities/Notification';
import { RoutePath } from 'Lib/helpers/routes';
import { getNotificationIndicatorColor } from 'Lib/helpers/utils';
import { triggerTranslator } from 'Lib/helpers/translationHelper';
import theme from 'Lib/theme';

import s from '../Stats.module.pcss';

interface NotificationProps {
    notification: NotificationEntity;
}

const Notification: FC<NotificationProps> = ({ notification }) => {
    const intl = useIntl();
    const {
        type,
        payload,
        timeAddedMillis,
        seen,
        level,
        serverInfo,
    } = notification;

    const title = (
        <div className={cn(s.status)}>
            {!seen && (
                <div
                    className={cn(
                        theme.card.indicator,
                        s.indicator,
                        getNotificationIndicatorColor(
                            level,
                        ),
                    )}
                />
            )}
            {serverInfo && (
                <Link
                    to={RoutePath.Server}
                    props={{ serverId: serverInfo.id, tenantId: serverInfo.tenantId }}
                    className={cn(s.row, theme.link.link, theme.link.blue, s.infoLink)}
                    stop
                >
                    {serverInfo.name}
                </Link>
            )}
            <span className={s.statusDescription}>{triggerTranslator(intl, type, payload!)}</span>
        </div>
    );

    const date = (
        <div className={cn(s.col, s.date)}>
            {dayjs(timeAddedMillis).format(intl.getMessage('time_format_short_month'))}
        </div>
    );

    return (
        <div className={s.row}>
            <Row>
                <Col span={18}>
                    {title}
                </Col>
                <Col span={6}>
                    {date}
                </Col>
            </Row>
        </div>
    );
};

export default Notification;
