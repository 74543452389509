import React, { FC } from 'react';
import cn from 'classnames';

import { Icon, useIntl } from 'Common';
import ResellerClient from 'Entities/ResellerClient';
import theme from 'Lib/theme';

import s from './ChangeAccount.module.pcss';

interface ClientAccountProps {
    currentId: number;
    client: ResellerClient;
    onClientLogin: (id: number) => void;
}

const ClientAccount: FC<ClientAccountProps> = ({
    currentId,
    client,
    onClientLogin,
}) => {
    const intl = useIntl();
    const currentAccount = currentId === client.clientId;

    const {
        clientId,
        email,
        emailNotConfirmed,
        accountBlocked,
    } = client;

    const availableForLogin = !emailNotConfirmed && !accountBlocked && !currentAccount;

    const onLogin = () => {
        if (availableForLogin) {
            onClientLogin(clientId);
        }
    };

    let text = `#${clientId}`;

    if (emailNotConfirmed) {
        text = intl.getMessage('email_not_confirmed');
    }

    if (accountBlocked) {
        text = intl.getMessage('account_blocked');
    }

    return (
        <div
            className={cn(
                s.account,
                { [s.account_disabled]: !availableForLogin },
            )}
            key={clientId}
            onClick={onLogin}
        >
            <div className={cn(s.info, s.info_client)}>
                <div className={s.email}>
                    {email}
                </div>
                <div className={cn(s.desc, s.desc_lower)}>
                    <span className={cn(theme.dotList.item, theme.dotList.item_small)}>
                        {intl.getMessage('clients_client')}
                    </span>
                    <span className={cn(theme.dotList.item, theme.dotList.item_small)}>
                        {text}
                    </span>
                </div>
            </div>
            {currentAccount && (
                <Icon icon="check" className={s.check} />
            )}
        </div>
    );
};

export default ClientAccount;
