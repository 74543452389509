import React, { createRef, FC, useEffect, useState } from 'react';
import cn from 'classnames';

import { useIntl, Button } from 'Common';
import { handleServerSearch } from 'Lib/helpers/search';
import Server from 'Entities/Server';
import Tenant from 'Entities/Tenant';
import theme from 'Lib/theme';

import ServerList from './ServerList';

interface SearchProps {
    servers: Map<number, Server>;
    tenants: Tenant[];
    currentServerId: number;
    handleClose: () => void;
    handleSelect: (server: Server) => void;
}

const Search: FC<SearchProps> = ({
    servers, tenants, currentServerId, handleClose, handleSelect,
}) => {
    const intl = useIntl();
    const inputRef = createRef<HTMLInputElement>();
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current?.focus();
        }
    }, [inputRef]);

    const clearSearch = () => {
        setSearchQuery('');
    };

    const searchResult = handleServerSearch(searchQuery, servers, intl.currentLocale);

    return (
        <>
            <label
                htmlFor="searchServer"
                className={theme.search.header}
            >
                {searchQuery ? (
                    <Button
                        type="icon"
                        className={cn(
                            theme.button.for_input,
                            theme.button.small,
                            theme.search.clear,
                            theme.search.clear_modal,
                        )}
                        icon="clear"
                        onClick={clearSearch}
                    />
                ) : (
                    <Button
                        type="icon"
                        icon="arrow"
                        onClick={handleClose}
                        className={cn(theme.button.back, theme.search.back)}
                    />
                )}
                <input
                    autoFocus
                    id="searchServer"
                    className={theme.search.modalInput}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                    placeholder={intl.getMessage('server_ip_to_other_server_search')}
                    autoComplete="off"
                />
            </label>
            <ServerList
                servers={searchQuery ? searchResult : Array.from(servers.values())}
                tenants={tenants}
                currentServerId={currentServerId}
                handleSelect={handleSelect}
            />
        </>
    );
};

export default Search;
