import React, { FC, useState, useEffect } from 'react';
import { Layout } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import cn from 'classnames';

import { useEscape, useTitle } from 'Hooks';
import { getDnsRecords, getDnsZones, getDnsSettings } from 'Actions/dns';
import { useIntl, ListLayout, blackText, Button } from 'Common';
import DnsRecord from 'Entities/DnsRecord';
import DnsZone from 'Entities/DnsZone';
import DnsSettings from 'Entities/DnsSettings';
import Tenant from 'Entities/Tenant';
import theme from 'Lib/theme';
import { Store } from 'Store';

import { Header, Record, Form, EditZone } from './components';
import s from '../Dns.module.pcss';

const { Content } = Layout;

type RecordsOwnProps = RouteComponentProps<{ zoneId: string }>;

interface RecordStoreProps {
    zone: DnsZone | null;
    records: DnsRecord[] | null;
    settings: DnsSettings | null;
    tenants: Map<number, Tenant>;
    currentUserId?: number;
}

type RecordProps = RecordStoreProps & RecordsOwnProps;

const Records: FC<RecordProps> = ({
    zone,
    records,
    settings,
    tenants,
    currentUserId,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [formVisible, setFormVisible] = useState(false);
    const [editZoneVisible, setEditZoneVisible] = useState(false);

    useTitle(intl.getMessage('dns_zones_records_page_title'));

    useEscape(() => setFormVisible(false));

    useEffect(() => {
        dispatch(getDnsZones());
        dispatch(getDnsSettings());
    }, []);

    useEffect(() => {
        if (zone) {
            const { tenantId, id } = zone;
            dispatch(getDnsRecords([tenantId, id]));
        }
    }, [zone]);

    if (!zone || !records || !settings) {
        return null;
    }

    const { host, tenantId } = zone;
    const tenant = tenants.get(tenantId)!;

    return (
        <Layout>
            <Header host={host} />
            <Content className={theme.content.content}>
                <div className={s.header}>
                    <div className={s.desc}>
                        {records?.length > 0 ? (
                            intl.getMessage('records_desc', { host, b: blackText })
                        ) : (
                            intl.getMessage('records_empty_desc', { host, b: blackText })
                        )}
                    </div>
                    <div className={s.tenant}>
                        {intl.getMessage('tenant')}&nbsp;

                        {tenant.ownerUserId === currentUserId ? (
                            <Button
                                type="link"
                                onClick={() => setEditZoneVisible(true)}
                                className={s.tenantChange}
                            >
                                <span className={s.tenantName}>
                                    {tenant?.description}
                                </span>
                            </Button>
                        ) : (
                            <span className={cn(s.tenantName, s.nowrap)}>
                                {tenant?.description}
                            </span>
                        )}
                    </div>
                </div>

                {formVisible ? (
                    <Form
                        zone={zone}
                        setFormVisible={setFormVisible}
                        settings={settings}
                    />
                ) : (
                    <Button
                        type="border"
                        id="add"
                        className={cn(
                            theme.button.with_icon,
                            s.add,
                        )}
                        onClick={() => setFormVisible(true)}
                        icon="plus"
                        block
                    >
                        {intl.getMessage('records_add')}
                    </Button>
                )}

                {records.length > 0 && (
                    <ListLayout
                        title={intl.getMessage('records_title')}
                        length={records.length}
                    >
                        <div data-name="dnsRecordsTable">
                            {records.sort((a, b) => (a.type > b.type ? 1 : -1)).map((r) => (
                                <Record record={r} key={r.id} zone={zone} />
                            ))}
                        </div>
                    </ListLayout>
                )}

                {editZoneVisible && (
                    <EditZone
                        zone={zone}
                        tenant={tenant}
                        tenants={Array.from(tenants.values()).filter((t) => t.ownerUserId === currentUserId)}
                        handleClose={() => setEditZoneVisible(false)}
                    />
                )}
            </Content>
        </Layout>
    );
};

const selectDnsSettings = (store: Store) => store.dns.settings;
const selectDnsRecords = (store: Store) => store.dns.records;
const selectDnsZone = (store: Store, ownProps: RecordsOwnProps) => {
    const { match: { params: { zoneId } } } = ownProps;
    return store.dns.zones?.get(Number(zoneId)) || null;
};
const selectTenants = (store: Store) => store.tenant;
const selectCurrentUser = (store: Store) => store.user.userId;

const selector = createSelector([
    selectDnsZone,
    selectDnsRecords,
    selectDnsSettings,
    selectTenants,
    selectCurrentUser,
], (zone, records, settings, tenants, currentUserId) => {
    return {
        zone,
        settings,
        records: records ? Array.from(records.values()) : null,
        tenants,
        currentUserId,
    };
});
const mapStoreToProps = (store: Store, ownProps: RecordsOwnProps) => (
    { ...selector(store, ownProps) }
);
export default withRouter(connect(mapStoreToProps)(Records));
