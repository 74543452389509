import { takeEvery, put, call } from 'redux-saga/effects';

import NotificationsApi from 'Apis/notifications';
import {
    NotificationsActions,
    saveNotifications,
    getNotifications as getNotificationsAct,
    getNotificationRecipients as getNotificationRecipientsAct,
    saveNotificationRecipients,
    deleteNotificationRecipient as deleteNotificationRecipientAct,
    createNotificationRecipient as createNotificationRecipientAct,
    updateNotificationRecipient as updateNotificationRecipientAct,
    getNotificationsSettings as getNotificationsSettingsAct,
    saveNotificationsSettings,
    getTriggerRules as getTriggerRulesAct,
    saveTriggerRules,
    deleteTriggerRule as deleteTriggerRuleAct,
    createTriggerRule as createTriggerRuleAct,
    updateTriggerRule as updateTriggerRuleAct,
    updateNotificationSettings as updateNotificationSettingsAct,
    markNotificationSeen as markNotificationSeenAct,
    clearNotifications,
} from 'Actions/notifications';
import {
    getProfileInfo,
} from 'Actions/profile';
import Notification, { INotification } from 'Entities/Notification';
import NotificationRecipient, { INotificationRecipient } from 'Entities/NotificationRecipient';
import NotificationSettings, { INotificationSettings } from 'Entities/NotificationSettings';
import Trigger, { ITrigger } from 'Entities/Trigger';

import { errorChecker, addError } from './utils';

function* getNotifications(action: ReturnType<typeof getNotificationsAct>) {
    const { payload } = action;
    const response: INotification[] = yield call(NotificationsApi.getNotifications, ...payload);
    const { error, result } = errorChecker<INotification[]>(response);
    if (result) {
        yield put(saveNotifications(result.map((r) => new Notification(r))));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* getNotificationRecipients(action: ReturnType<typeof getNotificationRecipientsAct>) {
    const response: INotificationRecipient[] = yield call(
        NotificationsApi.getNotificationRecipients,
    );
    const { error, result } = errorChecker<INotificationRecipient[]>(response);
    if (result) {
        yield put(saveNotificationRecipients(result.map((r) => new NotificationRecipient(r))));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* deleteNotificationRecipient(action: ReturnType<typeof deleteNotificationRecipientAct>) {
    const { payload } = action;
    const response: INotificationRecipient[] = yield call(
        NotificationsApi.deleteNotificationRecipient, ...payload,
    );
    const { error, result } = errorChecker<INotificationRecipient[]>(response);
    if (result) {
        yield put(getNotificationRecipientsAct([]));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createNotificationRecipient(action: ReturnType<typeof createNotificationRecipientAct>) {
    const { payload, callback } = action;
    const response: INotificationRecipient = yield call(
        NotificationsApi.createNotificationRecipient, ...payload,
    );
    const { error, result } = errorChecker<INotificationRecipient>(response);
    if (result && callback?.result) {
        yield put(getNotificationRecipientsAct([]));
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* updateNotificationRecipient(action: ReturnType<typeof updateNotificationRecipientAct>) {
    const { payload, callback } = action;
    const response: INotificationRecipient = yield call(
        NotificationsApi.updateNotificationRecipient, ...payload,
    );
    const { error, result } = errorChecker<INotificationRecipient>(response);
    if (result && callback?.result) {
        yield put(getNotificationRecipientsAct([]));
        callback?.result();
    }
    if (error && callback?.error) {
        callback?.error(error);
    }
}

function* getNotificationsSettings(action: ReturnType<typeof getNotificationsSettingsAct>) {
    const { payload } = action;
    const response: INotificationSettings = yield call(
        NotificationsApi.getNotificationsSettings, ...payload,
    );
    const { error, result } = errorChecker<INotificationSettings>(response);
    if (result) {
        yield put(saveNotificationsSettings(new NotificationSettings(result)));
    }
    if (error) {
        yield addError(error, action.type);
    }
}
function* updateNotificationSettings(action: ReturnType<typeof updateNotificationSettingsAct>) {
    const { payload } = action;
    const response: number = yield call(NotificationsApi.updateNotificationSettings, ...payload);
    const { error } = errorChecker<number>(response);
    if (error) {
        yield addError(error, action.type);
    }
}

function* getTriggerRules(action: ReturnType<typeof getTriggerRulesAct>) {
    const response: ITrigger[] = yield call(NotificationsApi.getTriggerRules);
    const { error, result } = errorChecker<ITrigger[]>(response);
    if (result) {
        const rules = result.map((r) => new Trigger(r));
        yield put(saveTriggerRules(rules));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* deleteTriggerRule(action: ReturnType<typeof deleteTriggerRuleAct>) {
    const { payload } = action;
    const response: number = yield call(NotificationsApi.removeTriggerRule, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getTriggerRulesAct([]));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* createTriggerRule(action: ReturnType<typeof createTriggerRuleAct>) {
    const { payload } = action;
    const response: ITrigger = yield call(NotificationsApi.createTriggerRule, ...payload);
    const { error, result } = errorChecker<ITrigger>(response);
    if (result) {
        yield put(getTriggerRulesAct([]));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* updateTriggerRule(action: ReturnType<typeof updateTriggerRuleAct>) {
    const { payload } = action;
    const response: number = yield call(NotificationsApi.updateTriggerRule, ...payload);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getTriggerRulesAct([]));
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* markNotificationSeen(action: ReturnType<typeof markNotificationSeenAct>) {
    const { payload, callback } = action;
    const { parameters, clear } = payload;
    const response: number = yield call(NotificationsApi.markNotificationSeen, ...parameters);
    const { error, result } = errorChecker<number>(response);
    if (result) {
        yield put(getProfileInfo({ onlyInfo: true }));
        if (clear) {
            yield put(clearNotifications());
        }
        if (callback?.result) {
            callback.result();
        }
    }
    if (error) {
        yield addError(error, action.type);
    }
}

function* notificationsSaga(): Generator {
    yield takeEvery<ReturnType<typeof getNotificationsAct>>(
        NotificationsActions.GetNotifications, getNotifications,
    );
    yield takeEvery<ReturnType<typeof getNotificationRecipientsAct>>(
        NotificationsActions.GetNotificationRecipients, getNotificationRecipients,
    );
    yield takeEvery<ReturnType<typeof deleteNotificationRecipientAct>>(
        NotificationsActions.DeleteNotificationRecipient, deleteNotificationRecipient,
    );
    yield takeEvery<ReturnType<typeof createNotificationRecipientAct>>(
        NotificationsActions.CreateNotificationRecipient, createNotificationRecipient,
    );
    yield takeEvery<ReturnType<typeof updateNotificationRecipientAct>>(
        NotificationsActions.UpdateNotificationRecipient, updateNotificationRecipient,
    );

    yield takeEvery<ReturnType<typeof getNotificationsSettingsAct>>(
        NotificationsActions.GetNotificationsSettings, getNotificationsSettings,
    );

    yield takeEvery<ReturnType<typeof updateNotificationSettingsAct>>(
        NotificationsActions.UpdateNotificationSettings, updateNotificationSettings,
    );

    yield takeEvery<ReturnType<typeof getTriggerRulesAct>>(
        NotificationsActions.GetTriggerRules, getTriggerRules,
    );
    yield takeEvery<ReturnType<typeof deleteTriggerRuleAct>>(
        NotificationsActions.DeleteTriggerRule, deleteTriggerRule,
    );
    yield takeEvery<ReturnType<typeof createTriggerRuleAct>>(
        NotificationsActions.CreateTriggerRule, createTriggerRule,
    );
    yield takeEvery<ReturnType<typeof updateTriggerRuleAct>>(
        NotificationsActions.UpdateTriggerRule, updateTriggerRule,
    );
    yield takeEvery<ReturnType<typeof markNotificationSeenAct>>(
        NotificationsActions.MarkNotificationSeen, markNotificationSeen,
    );
}

export default notificationsSaga;
